/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 *
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 *
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphCreditRepairWelcomeSkeleton.module.css';
import useLazyComponent from '@common/hooks/useLazyComponent';

const MorphCreditRepairWelcomeFallback = () => {
  return <div className={Skeleton.loader + ' ' + Styles.style} />;
};

// The render component below lazy loads the corresponding component using the skeleton above

type TRenderMorphCreditRepairWelcomeProps = {
  handleSubmit: ((e: Event) => void);
};

const MorphCreditRepairWelcomeSkeleton = ({
    handleSubmit
  }: TRenderMorphCreditRepairWelcomeProps) => {
    const MorphCreditRepairWelcomeProps = {
      handleSubmit
    };

  const MorphCreditRepairWelcome = useLazyComponent(
    import('@components/MorphCreditRepairWelcome').then(module => ({ default: module.MorphCreditRepairWelcome })),
  <MorphCreditRepairWelcomeFallback />,
  `./src/Components/Skeletons/MorphCreditRepairWelcomeSkeleton.tsx`
);

  return <MorphCreditRepairWelcome {...MorphCreditRepairWelcomeProps} />;
};

export default MorphCreditRepairWelcomeSkeleton;
