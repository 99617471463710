/**
 * @file    This file defines a private API for performing language-related operations, such as language detection, and
 *          translation.
 * @author  Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

/*
========================================================================================================================
Imports
========================================================================================================================
*/

// Local imports
import axiosCall from '../Services/axios';
import MorganCoreError from '../Exception/MorganCoreError';
import UninitializedError from '../Exception/UninitializedError';
import TranslationDisabledError from '../Exception/TranslationDisabledError';

/*
========================================================================================================================
Module-level constants
========================================================================================================================
*/

const DETECT_URL = 'language/detect';
const DETECT_METHOD = 'post';
const TRANSLATE_URL = 'google/lang/translate';
const TRANSLATE_METHOD = 'post';

/*
========================================================================================================================
Private module-level variables
========================================================================================================================
*/

let _translationEnabledCache = true;
let _localizationLanguageCache = null;

/*
========================================================================================================================
Private functions
========================================================================================================================
*/


/*
========================================================================================================================
Class definition
========================================================================================================================
*/

/**
 * This class defines a static API for performing common language related operations, such as language detection, and
 * translation.
 */
class LanguageUtils {

  /**
   * This static method detects the language of an array of messages.
   * 
   * @param   {[String]}  messages    The messages which are to be language detected. 
   * @returns {[String]}              Returns an array where each element contains the code of the detected language of
   *                                  the corresponding message in `messages` (i.e. the one at the same index).
   * @throws  {MorganCoreError}       Thrown if Morgan-Core reports an error (non-2XX response) while attempting to
   *                                  detect the language of the given messages.
   * @throws  {InvalidUserTypeError}  Thrown if `userType` is not an instance of `UserType`.
   */
  static async detectMany(messages) {

    // Make the request:
    let resp;
    try {
      resp = await axiosCall({
        url: DETECT_URL,
        method: DETECT_METHOD,
        data: {
          data: messages,
        },
      });
    } catch (err) {
      // removed throw as this was kicking operators to the login screen
      console.error(`Morgan-Core reported an error while attempting to perform language detection: `
      + `[${err}] - ${err.message}`)
    }

    // Build return data:
    let ret = [];
    // since we are no longer throwing an error we must verify that the response is an array
    if (Array.isArray(resp)) {
    for (let i = 0; i < resp.length; i++) {
      ret.push(resp[i].detectedLanguage);
    }
    }

    return ret;
  }

  /**
   * This static method detects the language of a single message.
   * 
   * @param   {String}    message     The message to be language detected.
   * @returns {String}                Returns the code of the detected language.
   * @throws  {MorganCoreError}       Thrown if Morgan-Core reports an error (non-2XX response) while attempting to
   *                                  detect the language of the given message.
   * @throws  {InvalidUserTypeError}  Thrown if `userType` is not an instance of `UserType`.
   */
  static async detectOne(message) {

    let resp = await this.detectMany([message]);      // THROWS InvalidUserTypeError, MorganCoreError! 
    
    return resp[0];
  }

  /**
   * This static method translates an array of messages into a given language.
   * 
   * @param   {string[] | {message: string, source: string}[]}  messages        The messages which are to be translated. 
   * @param   {string}    targetLanguage  The code of the language that the messages will be translated to.
   * @returns {Promise<string[]>}                  Returns an array where each element contains the translation of the 
   *                                      corresponding message in `messages` (i.e. the one at the same index).
   * @throws  {MorganCoreError}           Thrown if Morgan-Core reports an error (non-2XX response) while attempting to
   *                                      translate the given messages.
   * @throws  {InvalidUserTypeError}      Thrown if `userType` is not an instance of `UserType`.
   * @throws  {TranslationDisabledError}  Thrown if translation cannot be performed because the system setting flag
   *                                      `translationEnabled` is currently set to `false`.
   * @throws  {UninitializedError}        Thrown if the `translationEnabled` setting is uninitialized.
   */
  static async translateMany(messages, targetLanguage, config) {

    // Check if translation is enabled:
    if (!this.fetchTranslationEnabledSetting()) {                               // THROWS UninitializedError!
      throw new TranslationDisabledError("Translation is currently disabled");
    }
    if (!Array.isArray(messages) || messages.length < 1) return [];
    let data = (messages)?.map((d) => {
      if (!d) {
        return { message: '', source: null }
      }
      if (typeof d === 'string') {
        return { message: d, source: null }
      }
      if (!d.message || !d.source) {
        return { message: d || '', source: d.source || null }
      }
      return d;
    });

    // Make the request:
    let resp;
    try {
      resp = await axiosCall(
        {
          url: TRANSLATE_URL,
          method: TRANSLATE_METHOD,
          data: {
            data: data,
            language: targetLanguage,
          },
        }, 
        config
      );
    } catch (err) {
      throw new MorganCoreError(`Morgan-Core reported an error while attempting to perform language translation: `
        + `[${err}] - ${err.message}`);
    }

    // Build return data:
    let ret = [];
    for (let i = 0; i < resp.length; i++) {
      ret.push(resp[i].translatedMessage);
    }

    return ret;
  }

  /**
   * This static method translates a single message into a given language.
   * 
   * @param   {String}    message         The message to be translated. 
   * @param   {String}    targetLanguage  The code of the language that the message will be translated to.
   * @returns {String}                    Returns the translation of the given message.
   * @throws  {MorganCoreError}           Thrown if Morgan-Core reports an error (non-2XX response) while attempting to
   *                                      translate the given message.
   * @throws  {TranslationDisabledError}  Thrown if translation cannot be performed because the system setting flag
   *                                      `translationEnabled` is currently set to `false`.
   * @throws  {UninitializedError}        Thrown if the `translationEnabled` setting is uninitialized.
   */
  static async translateOne(message, targetLanguage) {

    let resp = await this.translateMany([{message,source: 'en'}], targetLanguage);  // THROWS MorganCoreError,

    return resp[0];
  }

  /**
   * Store the translationEnabled system settings value into local storage.
   * 
   * @param   {Boolean}   value     The value being stored.
   */
  static storeTranslationEnabledSetting(value) {

    console.log("translationEnabled set in local storage.");
    localStorage.setItem('translationEnabled', value);

    // Cache the value.
    _translationEnabledCache = value;
  }

  /**
   * This static method fetches the translationEnabled setting from local storage. Note that 
   * storeTranslationEnabledSetting must be called first, in order to initialize the settings data into local
   * storage.
   * 
   * @returns   {Boolean}                   A boolean value which indicates whether or not translation is enabled in the 
   *                                        system settings.
   * @throws    {UninitializedError}        Thrown if the translationEnabled setting was not found in local storage. 
   *                                        This is generally due to storeTranslationEnabledSetting never having been 
   *                                        called.
   */
  static fetchTranslationEnabledSetting() {

    // Use cached copy if available to avoid overhead of localStorage access:
    if (_translationEnabledCache !== null) {
      return _translationEnabledCache;
    }
    let ret = null;
    try{
      ret = localStorage.getItem('translationEnabled');
      if (ret === null) {
        throw new UninitializedError('translationEnabled not found in local storage! Please call '
          + 'LanguageUtils.storeTranslationEnabledSetting first.')
      }
    } catch (e) {
      LanguageUtils.storeTranslationEnabledSetting(true);
      ret = localStorage.getItem('translationEnabled');
    }
    return ret;
  }

  /**
   * Store the localization language value into local storage.
   * 
   * @param   {Boolean}   value     The value being stored.
   */
  static storeLocalizationLanguageSetting(value) {

    console.log("i18nextLng set in local storage.");
    localStorage.setItem('i18nextLng', value);

    // Cache the value.
    _localizationLanguageCache = value;
  }

  /**
   * This static method fetches the i18nextLng setting from local storage. Note that 
   * storeLocalizationLanguageSetting must be called first, in order to initialize the settings data into local
   * storage.
   * 
   * @returns   {String}              The current localization language setting.
   * @throws    {UninitializedError}  Thrown if the i18nextLng setting was not found in local storage. This is generally
   *                                  due to storeLocalizationLanguageSetting never having been called.
   */
  static fetchLocalizationLanguageSetting() {

    // Use cached copy if available to avoid overhead of localStorage access:
    if (typeof _localizationLanguageCache === 'string') {
      return _localizationLanguageCache.split('-')[0];
    }

    const i18nextLng = localStorage.getItem('i18nextLng') || 'en';
    const ret = i18nextLng.split('-')[0];
    if (!localStorage.getItem('i18nextLng')) {
      console.warn('i18nextLng not found in local storage! Please call '
        + 'LanguageUtils.storeLocalizationLanguageSetting first.');
    }
    return ret;
  }

}

export default LanguageUtils;
