import {
  EsignFormType,
  FormBodySizeType,
  FormBodyType,
  FormBottomButtonType,
  ElectronicSignatureEventType,
  IElectronicSignatureState,
} from './commonForms';
import { MorphType } from '@common/MorphType';

const noneHandler = (
  state: IElectronicSignatureState,
  event: ElectronicSignatureEventType
): IElectronicSignatureState => {
  return {
    body: FormBodyType.None,
    bodySize: FormBodySizeType.None,
    morph: MorphType.None,
    bottomButton: FormBottomButtonType.None,
  };
};

const CreateSignatureDesktopHandler = (
  state: IElectronicSignatureState,
  event: ElectronicSignatureEventType
): IElectronicSignatureState => {
  if (event === ElectronicSignatureEventType.ArrowDown) {
    return ElectronicSignatureNextState(
      state,
      FormBodyType.CreateSignatureDesktop,
      FormBodySizeType.Full,
      MorphType.FormInsertSignature,
      FormBottomButtonType.ArrowUp
    );
  }

  if (event === ElectronicSignatureEventType.OpenCreateSignatureMobile) {
    return ElectronicSignatureNextState(
      state,
      FormBodyType.CreateSignatureMobile,
      FormBodySizeType.Full,
      MorphType.None,
      FormBottomButtonType.Rotate
    );
  }

  if (event === ElectronicSignatureEventType.GoToInsertSignatureView) {
    return ElectronicSignatureNextState(
      state,
      FormBodyType.InsertSignatureDefault,
      FormBodySizeType.Full,
      MorphType.FormNavigateInitials,
      FormBottomButtonType.ArrowUp
    );
  }

  return state;
};

const CreateSignatureMobileHandler = (
  state: IElectronicSignatureState,
  event: ElectronicSignatureEventType
): IElectronicSignatureState => {
  if (event === ElectronicSignatureEventType.CloseCreateSignatureMobile) {
    return ElectronicSignatureNextState(
      state,
      FormBodyType.CreateSignatureDesktop,
      FormBodySizeType.Full,
      MorphType.FormInsertSignature,
      FormBottomButtonType.ArrowUp
    );
  }

  return state;
};

const InsertSignatureDefaultHandler = (
  state: IElectronicSignatureState,
  event: ElectronicSignatureEventType
): IElectronicSignatureState => {
  if (event === ElectronicSignatureEventType.ArrowDown) {
    return ElectronicSignatureNextState(
      state,
      FormBodyType.InsertSignatureDefault,
      FormBodySizeType.Full,
      MorphType.FormNavigateInitials,
      FormBottomButtonType.ArrowUp
    );
  }

  return state;
};

const InsertSignatureReadyToSendDocumentHandler = (
  state: IElectronicSignatureState,
  event: ElectronicSignatureEventType
): IElectronicSignatureState => {
  if (event === ElectronicSignatureEventType.ArrowDown) {
    return ElectronicSignatureNextState(
      state,
      FormBodyType.InsertSignatureReadyToSendDocument,
      FormBodySizeType.Full,
      MorphType.FormReadyToSend,
      FormBottomButtonType.ArrowUp
    );
  }

  return state;
};

const logicMap: {
  [key in FormBodyType]: (
    state: IElectronicSignatureState,
    event: ElectronicSignatureEventType
  ) => IElectronicSignatureState;
} = {
  [FormBodyType.None]: noneHandler,
  [FormBodyType.CreateSignatureDesktop]: CreateSignatureDesktopHandler,
  [FormBodyType.CreateSignatureMobile]: CreateSignatureMobileHandler,
  [FormBodyType.InsertSignatureDefault]: InsertSignatureDefaultHandler,
  [FormBodyType.InsertSignatureReadyToSendDocument]: InsertSignatureReadyToSendDocumentHandler,
};

const ElectronicSignatureStateManager = (
  formName: EsignFormType,
  state: IElectronicSignatureState,
  event: ElectronicSignatureEventType
): IElectronicSignatureState => {
  switch (event) {
    case ElectronicSignatureEventType.ReadyToSendDocument:
      return ElectronicSignatureNextState(
        state,
        FormBodyType.InsertSignatureReadyToSendDocument,
        FormBodySizeType.Full,
        MorphType.FormReadyToSend,
        FormBottomButtonType.ArrowUp
      );
    case ElectronicSignatureEventType.ArrowUp:
      return ElectronicSignatureNextState(
        state,
        undefined,
        FormBodySizeType.Small,
        MorphType.FormActiveChat,
        FormBottomButtonType.ArrowDown
      );
    case ElectronicSignatureEventType.GoToInsertSignatureView:
      return ElectronicSignatureNextState(
        state,
        FormBodyType.InsertSignatureDefault,
        FormBodySizeType.Full,
        MorphType.FormNavigateInitials,
        FormBottomButtonType.ArrowUp
      );
    case ElectronicSignatureEventType.EditSign:
      return ElectronicSignatureNextState(
        state,
        FormBodyType.CreateSignatureDesktop,
        FormBodySizeType.Full,
        MorphType.FormInsertSignature,
        FormBottomButtonType.ArrowUp
      );
    case ElectronicSignatureEventType.FormJustOpened:
      return ElectronicSignatureDefaultState(formName);
    default:
      return logicMap[state.body](state, event);
  }
};

const ElectronicSignatureNextState = (
  state: IElectronicSignatureState,
  body: FormBodyType,
  bodySize: FormBodySizeType,
  morph: MorphType,
  bottomButton: FormBottomButtonType
): IElectronicSignatureState => {
  if (body !== undefined) state.body = body;
  if (bodySize !== undefined) state.bodySize = bodySize;
  if (morph !== undefined) state.morph = morph;
  if (bottomButton !== undefined) state.bottomButton = bottomButton;
  return state;
};

/**
 * Default state for specified form
 * @param formName valid Form name
 * @returns 
 */
const ElectronicSignatureDefaultState = (formName: EsignFormType): IElectronicSignatureState => {
  const result = {
    body: FormBodyType.CreateSignatureDesktop,
    bodySize: FormBodySizeType.Full,
    morph: MorphType.FormInsertSignature,
    bottomButton: FormBottomButtonType.ArrowUp,
  };

  if (formName === EsignFormType.ECONSENT) {
    result.body = FormBodyType.InsertSignatureDefault;
    result.morph = MorphType.FormNavigateInitials;
  }

  return result;
};

export { ElectronicSignatureStateManager };
