/**
 * This file contains the definition of the `EForms` component. This class
 * controls the view and edit of Document anchors (for E-sign) within the
 * Angel-AI admin panel.
 * 
 * It is possible to render all of the pages at once and scroll through them
 * like the hard coded docs.  You just need to loop through the pages and
 * append them. There are tutorials online.
 */

import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Dialog,
  IconButton,
  AppBar,
  Toolbar,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import DateRangeIcon from '@mui/icons-material/DateRange'; // Example icon for 'dateSignedTab'
import CreateIcon from '@mui/icons-material/Create'; // Example icon for 'signHereTab'
import EditIcon from '@mui/icons-material/Edit'; // Example icon for 'initialHereTab'
import CheckBoxIcon from '@mui/icons-material/CheckBox'; // Example icon for 'checkBoxTab'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'; // Example icon for 'radioGroupTab'
import Slide, { SlideProps } from "@mui/material/Slide";
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import axiosCall from '../Services/axios';
import { format } from 'date-fns';
import * as pdfjs from 'pdfjs-dist';

const anchorTypeIcons = {
  dateSignedTab: <DateRangeIcon />,
  signHereTab: <CreateIcon />,
  initialHereTab: <EditIcon />,
  checkBoxTab: <CheckBoxIcon />,
  radioGroupTab: <RadioButtonCheckedIcon />
};

/**
 * Using a 3rd party worker may pose a security threat. We are sending the
 * data of secure documents with private information to a 3rd party. Our
 * documents could contain SSNs, income information, etc. It may not be a risk
 * now but it could be in the future. This concern is also applied to Ai Sign
 * as it also uses this worker. To solve this the worker needs to be deployed
 * internally. PDF.js has documentation on this.
 */
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Transition = React.forwardRef(function Transition(
  props: SlideProps,
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EForms = () => {
  const [forms, setForms] = useState([]);
  const [formView, setFormView] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [zoom, setZoom] = useState(1.0);
  const pdfViewerRef = useRef(null);
  const [hasAlteredDocument, setHasAlteredDocument] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [keyboardInput, setKeyboardInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const dialogRef = useRef(null);
  const signerColors = ['red', 'purple', 'green', 'orange', 'blue', 'yellow', 'black']
  const [selectedAnchorType, setSelectedAnchorType] = useState('initialHereTab');
  const anchorTypes = ['dateSignedTab', 'signHereTab', 'initialHereTab', 'checkBoxTab'] //'radioGroupTab'] //todo; implement radio fns (easy)

  const handleAnchorTypeChange = (event) => {
    setSelectedAnchorType(event.target.value);
  };

  const handleKeyDown = (event) => {
    setKeyboardInput(event.key);
  };

  const updateFormViewWithNewPosition = (anchorId, newX, newY) => {
    // Find the signer and anchor in your formView state using the anchorId
    const updatedSigners = formView.signers.map(signer => ({
      ...signer,
      anchorPositions: signer.anchorPositions.map(anchor => {
        if (anchor.uniqueId === anchorId) { // Assuming each anchor has a unique ID
          return { ...anchor, xpos: newX, ypos: newY };
        }
        return anchor;
      }),
    }));
    setFormView({ ...formView, signers: updatedSigners });
  };

  const deleteAnchor = (anchorId) => {
    console.log('function triggered')
    setHasAlteredDocument(true);
    setHasSaved(false);

    // Filter the anchor positions and then filter out signers with no anchors
    console.log('previous signers are ', formView.signers)
    const updatedSigners = formView.signers.map(signer => ({
      ...signer,
      anchorPositions: signer.anchorPositions.filter(anchor => anchor.uniqueId !== anchorId)
    })).filter(signer => signer.anchorPositions.length > 0);
    //final filter will delete the signer object if deleting the field made the signer go to 0. otherwise the API will return an error.
    // Update the formView state with the new signers array
    console.log('signers now are signers are ', updatedSigners)

    setFormView({ ...formView, signers: updatedSigners });
  };


  const addAnchor = () => {
    setHasSaved(false);
    setHasAlteredDocument(true);
    // Determine the next anchorId
    const allAnchors = formView.signers.flatMap(signer => signer.anchorPositions);
    const maxAnchorId = allAnchors.reduce((maxId, anchor) => Math.max(maxId, anchor.anchorId), 0);
    const newAnchorId = maxAnchorId + 1;

    // Define the new anchor object
    const newAnchor = {
      anchorId: newAnchorId,
      anchorIgnoreIfNotPresent: true,
      anchorString: "@L^",
      anchorType: selectedAnchorType,
      color: "red",
      height: 0,
      pageHeight: 792,
      pageNumber: currentPage, // Assuming currentPage is defined in your context
      pageWidth: 612,
      required: true,
      uniqueId: Math.random(), // Generate a random uniqueId
      width: 0,
      xOffset: 0,
      xpos: 50,
      yOffset: 0,
      ypos: 50
    };

    // Add the new anchor to the first signer's anchorPositions
    const updatedSigners = formView.signers.map((signer, index) => {
      if (index === 0) {
        return {
          ...signer,
          anchorPositions: [...signer.anchorPositions, newAnchor]
        };
      }
      return signer;
    });

    // Update the formView state
    setFormView({ ...formView, signers: updatedSigners });
  };

  const makeDraggable = (element, anchorId, canvas, viewport) => {
    let startX = 0;
    let startY = 0;
    let originalX = 0;
    let originalY = 0;

    const startDrag = (e) => {
      // Get the mouse position at the start
      startX = e.clientX;
      startY = e.clientY;
      // Get the original position of the element
      originalX = parseInt(element.style.left, 10);
      originalY = parseInt(element.style.top, 10);
      // Attach the listeners to `document` to allow for dragging outside the element
      document.addEventListener('mousemove', onDrag);
      document.addEventListener('mouseup', endDrag);
      // Prevent the default dragging behavior
      e.preventDefault();
    };

    const onDrag = (e) => {
      // Calculate the distance moved by the mouse
      setHasAlteredDocument(true);
      setHasSaved(false);
      const dx = e.clientX - startX;
      const dy = e.clientY - startY;
      // Set the new position of the element
      element.style.left = `${originalX + dx}px`;
      element.style.top = `${originalY + dy}px`;
    };

    const endDrag = (e) => {
      // Calculate the new position
      const scrollOffset = pdfViewerRef.current.scrollTop;
      const finalY = originalY + (e.clientY - startY);
      element.dataset.initialTop = `${finalY + scrollOffset}px`;

      const newX = (parseInt(element.style.left, 10) - canvas.offsetLeft) / viewport.scale;
      const newY = (parseInt(element.style.top, 10) - canvas.offsetTop) / viewport.scale;

      // Update the formView state with the new position
      updateFormViewWithNewPosition(anchorId, newX, newY);

      // Remove the move and up listeners from the `document`
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('mouseup', endDrag);
    };

    element.addEventListener('mousedown', startDrag);
  };

  useEffect(() => {
  }, [formView]); // Make sure to adjust the dependency array according to your needs

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  useEffect(() => {
    if (keyboardInput === 'ArrowLeft') {
      goToPrevPage();
    } else if (keyboardInput === 'ArrowRight') {
      goToNextPage();
    }
    else if (keyboardInput === 'ArrowUp') {
      increaseZoom();
    } else if (keyboardInput === 'ArrowDown') {
      decreaseZoom();
    }
    else if (formView && (keyboardInput === 'f' || keyboardInput === 'F')) {
      toggleFullScreen();
    }
    // Reset the state to null after processing the key press
    setTimeout(() => {
      setKeyboardInput(null);
    }, 100); // Adjust the delay as needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyboardInput]);

  const getFormData = async (method, templateId) => {
    try {
      setLoading(true);
      const response = await axiosCall({
        url: `ai-sign/forms`,
        method,
        data: { templateId }
      });
      setLoading(false);
      console.log('forms are', response);
      if (method === 'GET') {
        setForms(response);
      }
      if (method === 'POST') {
        response.templates[0].signers.forEach((anchorPositionsArray, index) => {
          anchorPositionsArray.anchorPositions?.forEach((anchor) => {
            anchor.uniqueId = Math.random();
            anchor.color = signerColors[index];
          })
        })
        setFormView(response.templates[0]);
        setOpenDialog(true);
        renderPDF(response.templates[0].documentBase64);
      }
    } catch (error) {
      console.error('Failed to fetch forms:', error);
      setLoading(false);
      setError('Failed to fetch forms. Please try again later.');
    }
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Template ID copied to clipboard');
        setInfo('Template ID ' + text + ' copied to clipboard')
        //  show some visual feedback like a tooltip or a Snackbar.
      },
      (err) => {
        console.error('Could not copy text: ', err);
        // Handle the error (show an error message to the user)
      }
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
    setCurrentPage(1);
    setHasSaved(false);
    setHasAlteredDocument(false);
  };

  const handleClickSaveButton = async () => {
    if (hasSaved) return;
    setSaving(true);
    setHasSaved(true);
    console.log('formview is', formView)
    const payload = {
      request: 'editTemplate', templateId: formView.templateId, template: {
        templateName: formView.templateName, templateDescription: formView.templateDescription,
        signers: formView.signers, carbonCopy: formView.carbonCopy
      }
    }
    const response = await axiosCall({
      url: `ai-sign/forms`,
      method: 'PUT',
      data: payload
    });
    setSaving(false);
    console.log('response is', response)
  }


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    getFormData('GET', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleResize();
  }, [formView?.signers])

  const handleResize = () => {
    if (formView) {
      renderPDF(formView.documentBase64);
    }
  }

  const formatDate = (dateString) => {
    if (dateString) return format(new Date(dateString), 'PPPp');
    return '';
  };

  const handleScroll = () => {
    const scrollOffset = pdfViewerRef.current.scrollTop;
    const horizontalScrollOffset = pdfViewerRef.current.scrollLeft;
    const anchorElements = pdfViewerRef.current?.getElementsByClassName('anchor-tag');
    for (let anchorElement of anchorElements) {
      const initialTop = parseFloat(anchorElement.dataset.initialTop);
      const initialLeft = parseFloat(anchorElement.dataset.initialLeft);
      anchorElement.style.top = `${initialTop - scrollOffset}px`;
      anchorElement.style.left = `${initialLeft - horizontalScrollOffset}px`;
    }
  };

  //this function will render the anchors based on data from the formView object. formView holds positional anchor info.
  const renderAnchors = (page, viewport, canvas) => {
    const anchors = formView?.signers?.map(signer => signer.anchorPositions);
    if (!formView) {
      return;
    }
    //scroll the viewer ref to the top every time.
    pdfViewerRef.current.scrollTop = 0;
    const flattenedAnchors = [].concat(...anchors);
    flattenedAnchors?.forEach((anchor) => {
      if (anchor.pageNumber === currentPage) {
        const anchorElement = document.createElement('div');
        anchorElement.className = 'anchor-tag';
        anchorElement.style.position = 'absolute';

        // Convert PDF space coordinates to viewport (canvas) coordinates
        const x = anchor.xpos * viewport.scale;
        const y = anchor.ypos * viewport.scale;
        anchorElement.style.top = `${canvas.offsetTop + y}px`;
        anchorElement.style.cursor = 'move';
        anchorElement.dataset.initialTop = `${canvas.offsetTop + y}px`;
        anchorElement.dataset.initialLeft = `${canvas.offsetLeft + x}px`;


        anchorElement.style.left = `${canvas.offsetLeft + x}px`;
        anchorElement.style.top = `${canvas.offsetTop + y}px`;
        anchorElement.style.width = `${(anchor.width || 20) * viewport.scale}px`;
        anchorElement.style.height = `${(anchor.height || 20) * viewport.scale}px`;

        anchorElement.style.backgroundColor = anchor.color;
        anchorElement.style.opacity = '0.9';
        anchorElement.style.zIndex = '1000';

        try{
          //each anchor node will have a delete icon 
          addDeleteButtonToAnchor(anchorElement, anchor);
          //each anchor node will have a type (anchor.anchorType)
          addIconToAnchor(anchorElement,anchor)
        }
        catch(e){
          console.log('error appending children: ', e);
        }

        // Append the anchorElement to the canvas parent
        canvas.parentNode.appendChild(anchorElement);

        makeDraggable(anchorElement, anchor.uniqueId, canvas, viewport);
      }
    });
  };

  const addDeleteButtonToAnchor = (anchorElement, anchor) => {
    // Create delete button
    const deleteButton = document.createElement('div');
    deleteButton.className = 'delete-anchor';
    deleteButton.style.position = 'absolute';
    deleteButton.style.top = '-15px';
    deleteButton.style.right = '18px';
    deleteButton.style.width = '20px'; // adjust size as needed
    deleteButton.style.height = '20px'; // adjust size as needed
    deleteButton.style.borderRadius = '50%'; // to make it circular
    deleteButton.style.backgroundColor = 'gray';
    deleteButton.style.color = 'var(--gray000)';
    deleteButton.style.textAlign = 'center';
    deleteButton.style.lineHeight = '20px'; // to center the 'X' vertically
    deleteButton.style.cursor = 'pointer';
    deleteButton.textContent = 'X';

    // Append delete button to anchorElement
    anchorElement.appendChild(deleteButton);
    deleteButton.addEventListener('click', function () {
      console.log('deleting anchor')
      deleteAnchor(anchor.uniqueId);
    });
  }

  const addIconToAnchor = (anchorElement, anchor) => {
  
    const getIconForAnchorType = (type) => {
      //we need ReactDOM.render(*) if we wanted to append the MUI icons instead.
      switch(type) {
        case 'dateSignedTab':
          return `<svg style="fill: var(--gray000)" className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DateRangeIcon"><path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"></path></svg>`;
        case 'signHereTab':
          return `<svg style="fill: var(--gray000)" className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CreateIcon"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>`;
        case 'initialHereTab':
          return `<svg style="fill: var(--gray000)" className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CreateIcon"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>`;
        case 'checkBoxTab':
          return `<svg style="fill: var(--gray000)" className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckBoxIcon"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg>`;
        default:
          // Handle default case, maybe returning a default SVG or an empty string
          return '';
      }
    };
    const iconSVGString = getIconForAnchorType(anchor.anchorType);
    const iconDiv = document.createElement('div'); // Create a new div to hold the SVG
    iconDiv.innerHTML = iconSVGString; // Set the innerHTML of this div to the SVG string
  
    // Append the new div with the SVG to the anchorElement
    anchorElement.appendChild(iconDiv.firstChild);
  };

  const renderPDF = (base64) => {
    const loadingTask = pdfjs.getDocument({ data: base64ToUint8Array(base64) });
    loadingTask.promise.then((pdf) => {
      setNumPages(pdf.numPages);

      pdf.getPage(currentPage).then((page) => {
        const viewport = page.getViewport({ scale: zoom });
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };

        // Render the page onto the canvas
        page.render(renderContext).promise.then(() => {
          // Ensure the DOM is ready before rendering anchors
          if (pdfViewerRef.current) {
            // Remove any existing anchors before adding new ones
            const existingAnchors = pdfViewerRef.current.getElementsByClassName('anchor-tag');
            while (existingAnchors.length > 0) {
              existingAnchors[0].parentNode.removeChild(existingAnchors[0]);
            }
            pdfViewerRef.current.innerHTML = ''; // Clear the container
            pdfViewerRef.current.appendChild(canvas);
            // Center the canvas
            canvas.style.margin = 'auto';
            canvas.style.display = 'block';
            // Set greyish background for area out of view
            pdfViewerRef.current.style.backgroundColor = 'var(--gray110)';

            // Now that the PDF page is rendered and the DOM has been updated, render anchors
            renderAnchors(page, viewport, canvas);
          } else {
            console.error('pdfViewerRef is not initialized');
          }
        });
      });
    });
  };

  const goToPrevPage = () => {
    setCurrentPage(Math.max(currentPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage(Math.min(currentPage + 1, numPages));
  };

  const increaseZoom = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 2));
  };

  const decreaseZoom = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.4));
  };

  const toggleFullScreen = () => {
    if (!isFullscreen) {
      enterFullScreen();
    } else {
      exitFullScreen();
    }
  };

  interface FullscreenDocument extends Document {
    mozCancelFullScreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
  }

  const enterFullScreen = () => {
    setIsFullscreen(true);
    const elem = dialogRef.current;
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    }
  };

  const fullscreenDocument = document as FullscreenDocument;

  const exitFullScreen = () => {
    setIsFullscreen(false);
    if (document.fullscreenElement != null) { // Check if the document is in fullscreen mode
      if (fullscreenDocument.exitFullscreen) {
        fullscreenDocument.exitFullscreen();
      } else if (fullscreenDocument.mozCancelFullScreen) {
        fullscreenDocument.mozCancelFullScreen();
      } else if (fullscreenDocument.webkitExitFullscreen) {
        fullscreenDocument.webkitExitFullscreen();
      } else if (fullscreenDocument.msExitFullscreen) {
        fullscreenDocument.msExitFullscreen();
      }
    }
  };


  // When currentPage or zoom changes, re-render the PDF page
  useEffect(() => {
    if (formView && pdfViewerRef.current) {
      renderPDF(formView.documentBase64);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, zoom, pdfViewerRef.current]);

  const base64ToUint8Array = (base64) => {
    const raw = atob(base64);
    const uint8Array = new Uint8Array(raw.length);
    for (let i = 0; i < raw.length; i++) {
      uint8Array[i] = raw.charCodeAt(i);
    }
    return uint8Array;
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Snackbar open={!!info} autoHideDuration={6000} onClose={() => setInfo('')}>
        <Alert onClose={() => setInfo('')} severity="success" sx={{ width: '100%' }}>
          {info}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        {forms && Array.isArray(forms) && forms.map((form) => (
          <Grid item xs={12} sm={6} md={4} key={form.templateId}>
            <Card raised sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {form.templateName}
                </Typography>
                <Typography gutterBottom variant="body1">
                  {'Template Id: ' + form.templateId}
                </Typography>
                <IconButton
                  size="small"
                  onClick={() => handleCopyToClipboard(form.templateId)}
                  aria-label="copy"
                  sx={{ marginLeft: '8px' }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
                <Typography variant="body2">
                  {form.templateDescription}
                </Typography>
                <Typography variant="body2">
                  Created: {formatDate(form.createdDate)}
                </Typography>
                <Typography variant="body2">
                  Last Updated: {formatDate(form.lastUpdate)}
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => getFormData('POST', form.templateId)} size="small">View</Button>
                {/* <Button size="small">Edit</Button> */}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      {formView && (
        <Dialog
          open={openDialog}
          onClose={handleClose}
          fullWidth
          maxWidth="xl"
          TransitionComponent={Transition as React.ComponentType<any>}
          ref={dialogRef}
          sx={{ "& .MuiDialog-paper": { width: "100%", height: "100%", maxHeight: "calc(100% - 64px)" } }}
        >
          <AppBar position="sticky" color="default">
            <Toolbar>
              {saving && <CircularProgress />}
              {hasAlteredDocument && !saving && <Button
                color={'info'}
                sx={{ marginRight: '15px' }}
                variant="outlined"
                onClick={() => {
                  handleClickSaveButton();
                }}
              >
                {hasSaved ? 'SAVED' : 'SAVE'}
              </Button>}
              {/* Grouping Select and IconButton together */}
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <FormControl sx={{ minWidth: 120, mr: 1 }}>
                  <InputLabel id="anchor-type-select-label">Anchor Type</InputLabel>
                  <Select
                    sx ={{padding: '0px'}}
                    labelId="anchor-type-select-label"
                    value={selectedAnchorType}
                    onChange={handleAnchorTypeChange}
                    label="Anchor Type"
                  >
                    {anchorTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {anchorTypeIcons[type]}
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <IconButton onClick={addAnchor} title="Add Anchor">
                  <AddRoadIcon />
                </IconButton>
              </Box>
              <IconButton onClick={toggleFullScreen}>
                {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              </IconButton>
              {/* 11/13 removed zoom for now & should re-visit */}
              {/* <IconButton onClick={decreaseZoom} disabled={zoom === 0.5}>
                <RemoveIcon />
              </IconButton> */}
              {/* <Typography sx={{ margin: '0 15px' }}>Zoom: {Math.round(zoom * 100)}%</Typography> */}
              {/* <IconButton onClick={increaseZoom} disabled={zoom === 2}>
                <AddIcon />
              </IconButton> */}
              <Box sx={{ flexGrow: 1 }} />
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Box sx={{ flexGrow: 1, overflow: 'auto' }} onScroll={handleScroll} ref={pdfViewerRef} />

          <AppBar position="sticky" color="default" sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar>
              <Button onClick={goToPrevPage} disabled={currentPage === 1}>Previous</Button>
              <Box sx={{ flexGrow: 1 }} />
              <Typography>{`Page ${currentPage} of ${numPages}`}</Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Button onClick={goToNextPage} disabled={currentPage === numPages}>Next</Button>
            </Toolbar>
          </AppBar>
        </Dialog>
      )}
    </Box>
  );
};

export default EForms;
