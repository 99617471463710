import React, { createContext, MutableRefObject } from 'react';

/**
 * This context will store data that unique to each form
 *
 * This includes elements related to the following
 * editMessageId, inputRef, conversationRef, etc.
 *
 * NOTICE: Avoid adding useState variables when possible
 */

export interface IFormContext {
  documentReaderRef;
  documentRef;
  signatureMapRef: MutableRefObject<Array<string>>;

  timeZoneGrabber: () => void;
  browserTypeGrabber: () => void;
  mainSignatureInsertedEventHandler: () => void;
  handleCustomScroll: (input: number) => void;
  abortControllerWrapper: () => { signal: any };
  EsignBody: () => JSX.Element;

  eConsentRadioButtonState: string;
  mainSignatureInserted: boolean;
  scrollPosition: number;
  bcertaData: any;

  setMainSignatureInserted: any;
  setEConsentRadioButtonState: any;
  setFormMetaData: any;
  setFormData: any;
  setBcertaData: any;
}

export const FormContext = createContext<IFormContext | null>(null);
