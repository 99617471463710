import { useState, useEffect } from 'react';

const useOnlineStatus = () => {
  // State to hold the online status
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  // Effect to handle the online status changes
  useEffect(() => {
    // Event handler for online status
    const handleOnline = () => setIsOnline(true);
    // Event handler for offline status
    const handleOffline = () => setIsOnline(false);

    // Adding event listeners
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOnline };
};

export default useOnlineStatus;
