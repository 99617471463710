import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { sectionNameTranslationKeys, settingsSectionNameTranslationKeys } from '../Constants/consoleConstants';

const CustomListItem = styled(ListItem)(({ theme }) => ({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: 'var(--gray000)',
    '&, & .MuiListItemIcon-root': {
      color: theme.palette.blue['200'],
    },
  },
}));

/**
 * The navigation menu for this component. Generates links based on the
 * subsections of this component defined in {@link settingsSectionNameTranslationKeys}
 */
const SystemNavigationMenu = ({ closeMenu }) => {
  const baseModule = `${sectionNameTranslationKeys['Admin']}/${sectionNameTranslationKeys['System']}`;

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card>
      {Object.values(settingsSectionNameTranslationKeys).map((subModule) => (
        <CustomListItem key={subModule} theme={theme} sx={{ paddingY: '0.1rem' }}>
          <ListItemButton
            sx={{ width: 'max-content' }}
            onClick={() => {
              navigate('/console', {
                state: {
                  module: `${baseModule}/${subModule}`,
                },
              });
              closeMenu();
            }}
            selected={location.state?.module === `${baseModule}/${subModule}`}
          >
            <ListItemText primary={t(subModule)} />
          </ListItemButton>
        </CustomListItem>
      ))}
    </Card>
  );
};

export default SystemNavigationMenu;
