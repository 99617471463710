/**
 * @file This component is a stylized Box component
 * for styling the ActionListBadge.
 *
 * @author Quinley James <quinley.james@swmc.com>
 */

import { styled } from '@mui/system';
import Card from '@mui/material/Card';
// sx = {{ backgroundColor: theme.palette.orange['110'], paddingLeft: 1, display: 'flex', justifyContent: 'flex-start' }}
// This avoids the error with invalid html property.
const ALBadge = styled(Card)(
  ({ theme }) => `
    border-color: ${theme.palette.orange['110']};
    border-style:solid;
    border-width:1;
    border-radius:.4em;
    margin:10px;
    .ALBFields {
      background-color: ${theme.palette.orange['110']};
      padding-left: 8px;
      display:flex;
      justify-content:flex-start;
    }
    .ALBFieldSelector {
      border: none;
      background-color: var(--gray050);
      color: var(--gray050);
      box-shadow: 0 0 0 1px var(--gray050);
      position: relative;
      -webkit-transition: -webkit-transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      bottom: auto;
      right: auto;
      display: flex;
      width: 1rem;
      height: 1rem;
      margin-top: 10px;
      border-radius: 50%;  
      margin-right: 10px;    
      -webkit-transform: none;
      -moz-transform: initial;
      -ms-transform: initial;
      transform: none;
      transform-origin: center;
      border: 1px solid var(--gray000);
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        margin: -1px;
        border: 1px solid currentColor;
        border-radius: 50%;
        content: '';
      }
    }
    .ALBFieldSelector.foster {
      background-color: var(--orange110);
      animation: 'ripple 1.2s infinite ease-in-out';
    }
    .ALBFieldSelector.active {
      background-color:var(--green420);
      color: var(--green420);
      box-shadow: 0 0 0 1px var(--green420); 
      border: 1px solid var(--green420);
      &::after {
        animation: ripple 1.2s infinite ease-in-out;
      }
    }
    @keyframes ripple {
      0% {
        transform: scale(0.5);
        opacity: 1;
      }
      100% {
        transform: scale(2);
        opacity: 0;
      }
    }
    .ALBFieldValues {
      display: flex;
      .ALBSelectedValueStyling {
        font-size: 0.825em;
        font-family: Poppins;
        color: var(--gray000);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        gap: 2.5px;
        align-items: center;
        div {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        svg {
          width: 0.75em;
          height: 0.75em;
        }
      }
    }
  `
);
export default ALBadge;
