import React from 'react';
import { Stack } from '@mui/material';
const logo = process.env.REACT_APP_MORGAN_CDN + '/Images/Brandmark.png';

// This is the Morgan logo centered on the screen
const Brandmark = (props) => {
  return (
    <Stack alignItems="center" justifyContent="center" width="50vw">
      <img alt="AngelAi" src={logo} height={250} />
      <p style={{color: 'var(--red400)', fontWeight: '500', textAlign: 'center', width: '80%', margin: '0 auto' }}>{props.systemMessage}</p>
    </Stack>
  );
};

export default Brandmark;
