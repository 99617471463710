import { Box, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import React, { useState, FC, memo } from 'react';
import useInitialMount from '@common/hooks/useInitialMount';

import axiosCall from '../Services/axios';
import { getStatusColors } from '../Common/StatusColors';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface StatusPickerProps {
  aiOperatorId: string;
  aiOperatorStatus?: string;
}

const StatusBox = styled(Select)(() => ({
  backgroundColor: 'var(--gray050)',
  borderRadius: '16px',
  border: '1px solid var(--gray210)',
  height: '30px',
  textTransform: 'capitalize',
  ':hover': {
    backgroundColor: 'var(--gray040)',
  },
  '.MuiTouchRipple-child': {
    backgroundColor: 'var(--red400)',
  },
}));

const StatusPicker: FC<StatusPickerProps> = ({ aiOperatorId, aiOperatorStatus }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [status, setStatus] = useState(aiOperatorStatus);
  const [statusOptions, setStatusOptions] = useState(null);

  const getStatusOptions = async () => {
    const res = await axiosCall({url: 'operator/status', method: 'GET'}) 
    setStatusOptions(res)
  }

  useInitialMount(getStatusOptions);
  
  const updateStatus = async (newStatus: string) => {
    try {
      if (aiOperatorId == null || newStatus == null) return; // guard clause to avoid sending incorrect requests.

      console.log(`Updating operator status to ${newStatus}`);
      const result = await axiosCall({
        url: `operator/${aiOperatorId}`,
        method: 'PUT',
        data: {
          status: newStatus,
        },
      });
      if (result) {
        setStatus(newStatus);
      }
    } catch (error: any) {
      console.error(
        `Error setting the operator's status to ${newStatus}`,
        error.response?.data?.message
      );
      return false;
    }
  };

  const handleChange = async (event: any) => {
    const newStatus = event.target.value;
    updateStatus(newStatus);
};


  return (
    statusOptions && <StatusBox value={status ? status : 'online'} onChange={handleChange}>
      {statusOptions.map((st) => {
        return (
          <MenuItem value={st} key={st}>
            <Stack justifyContent="center" direction="row" alignItems="center" spacing={1}>
              <Box
                sx={{
                    backgroundColor: getStatusColors(st, theme).main,
                    borderRadius: '50%',
                    width: '10px',
                    height: '10px'
                  }}
              />
              <Typography sx={{ textTransform: 'capitalize' }}>{t(`${st}Status`)}</Typography>
            </Stack>
          </MenuItem>
        );
      })}
    </StatusBox>
  );
};

export default memo(StatusPicker);