/**
 * @file LoanConditionsLinkContent.tsx
 * @description This file defines the message content bubble specifically for housing the link for opening
 * loan conditions urls in a new errand for the Get Loan Conditions Workflow
 * @author Harrison Fales <harrison.fales@swmc.com>
 */
import { Fade, Stack } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import Sanitized from '@components/Sanitized';
import MessageTextStyle from '@styles/MessageTextStyle';
import OriginalLangStyle from '@styles/OriginalLangStyle';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import TranslationTabLeft from '@styles/TranslationTabLeft';
import TranslationTabRight from '@styles/TranslationTabRight';
import { uiTranslationController, getLangName } from '@common/common';
import Allign from '@styles/Allign';
import MessageTime from '@components/MessageTime';
import useInitialMount from '@common/hooks/useInitialMount';
import styles from '@styles/MessageContent/LoanConditionsLinkContent.module.css';
import {
    IErrand,
    IMessage,
} from '@interfaces/Conversation';
import { ChatType } from '@common/ChatType';
import axiosCall from '@services/axios';
import { useRootContext } from '@contexts/RootContext';

interface LoanConditionsLinkContentProps {
    message: IMessage;
}

const LoanConditionsLinkContent = ({
    message,
}: LoanConditionsLinkContentProps) => {
    const rootContext = useRootContext();
    const [isFading, setIsFading] = useState(true);
    const [revealEnabled, setRevealEnabled] = useState(false);
    const showPointer = !message.operatorView && message.message.indexOf('data-chat-id') !== -1;
    const messageRef = useRef(null);
    const { dispMessage, isTranslated } = uiTranslationController(message);
    const [parsedMessage, setParsedMessage] = useState(null);
    const [loanConditionsLink, setLoanConditionsLink] = useState(null);
    const [loanNumber, setLoanNumber] = useState(null);

    const parseMessageAndLink = useCallback((dispMessage) => {
        if (!dispMessage || typeof dispMessage !== 'string') {
            return;
        } else {
            let splitMessage = dispMessage.includes('&lt;&gt;') ? dispMessage.split('&lt;&gt;') : dispMessage.split('<>');
            setParsedMessage(splitMessage[0]);
            setLoanConditionsLink(splitMessage[1]);
            setLoanNumber(splitMessage[2]);
        }
    }, []);

    useInitialMount(() => parseMessageAndLink(dispMessage));

    useEffect(() => {
        parseMessageAndLink(dispMessage)
    }, [dispMessage, parseMessageAndLink])

    /**
     * This function is a handler callback for the Original Language component's
     * onClick event. It is responsible for temporarily revealing the origianl
     * message.
     */
    const handleClickOriginalLang = useCallback(async (e) => {
        e?.stopPropagation();
        if (!message.visible) return;
        if (revealEnabled) {
            return;
        }
        const reset = messageRef.current.innerHTML;

        // Start the inital fadeout:
        setRevealEnabled(true);
        setIsFading(false);

        // Wait until the fadeout is done to fade in the original message:
        setTimeout((reset) => {
            let editedMessage = message.message;

            let [firstSection, secondSection] = message.message.split(`<i class="messageArrow"/>`).map((x) => x.replace(/(<([^>]+)>)/gi, ''));

            if (secondSection) {
                editedMessage = `<s>${firstSection}</s>&nbsp;<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1r4vxry-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowCircleRightRoundedIcon"><path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.31-.85.09-.85-.36z"></path></svg> <i>${secondSection}</i>`;
            }

            // Style the original message:
            editedMessage = `<div style="font-size: 0.8em; font-family: Poppins; font-weight: 400;">${editedMessage}</div>`;

            messageRef.current.innerHTML = editedMessage;
            setIsFading(true);

            // Allow the revealed message to display for some time, then begin fading
            // it out:
            setTimeout((reset) => {
                setIsFading(false);

                // Finally, wait until the fadeout is done, then fade in the translated
                // message:
                setTimeout((reset) => {
                    messageRef.current.innerHTML = reset;
                    setIsFading(true);
                    setRevealEnabled(false);
                }, 500, reset);
            }, 3000, reset);
        }, 500, reset);
    }, [revealEnabled, message, messageRef]);

    const openLoanConditionsErrand = useCallback(async (e) => {
        e?.stopPropagation();
        e.preventDefault();

        let conditionsIndex = rootContext.errands.map(e => {
            if (e.preview) {
                return e.preview?.replace(/\s/g, '')
            } else if (e.lastMessageData && typeof e.lastMessageData?.message === 'string') {
                return e.lastMessageData?.message?.replace(/\s/g, '')
            } else {
                return '';
            }
        }).indexOf(loanConditionsLink?.replace(/\s/g, ''));

        if (conditionsIndex !== -1) {
            if (rootContext.selectedIndex.length > 1) {
                rootContext.setSelectedIndex([rootContext.selectedIndex[0], conditionsIndex])
            } else {
                rootContext.setSelectedIndex([conditionsIndex])
            }
        } else {
            await axiosCall({
                url: 'chat',
                method: 'post',
                data: {
                    displayName: `Loan Conditions: ${loanNumber}<>${loanConditionsLink}`,
                    type: ChatType.Conditions,
                    preview: loanConditionsLink,
                    participants: [
                        {
                            userId: message.userId,
                            userType: 'User',
                            primary: true,
                            messageHistoryAllowed: true,
                        },
                    ],
                },
            }).catch((error) => {
                console.error(`setupErrands: Unable to create user chat ${error.message}`);
            });
        }
    }, [rootContext, loanNumber, loanConditionsLink,])

    return (
        <Stack display='flex' flexDirection='column'>
            <Stack flexDirection="row">
                <ChatBubbleStyle
                    className={`${styles.messageBubble} ${showPointer ? styles.showPointer : styles.noPointer}`}
                    onClick={(e) => openLoanConditionsErrand(e)}
                    style={{ cursor: 'pointer', pointerEvents: 'all' }}
                >
                    <Fade in={isFading} appear={false}>
                        <Stack className={styles.fadeStack} ref={messageRef}>
                            <MessageTextStyle>
                                <Sanitized
                                    highlight={message?.searchWords ? message?.searchWords : undefined}
                                    html={parsedMessage}
                                />
                            </MessageTextStyle>
                        </Stack>
                    </Fade>
                    <Stack className={styles.timeStack}>
                        <MessageTime message={message} />
                    </Stack>
                </ChatBubbleStyle>
            </Stack>
            {
                isTranslated &&
                (message.alignByCurrentUser ? (
                    <TranslationTabRight>
                        <OriginalLangStyle onClick={handleClickOriginalLang}>
                            <u>{getLangName(message.detectedLanguage)}</u>
                        </OriginalLangStyle>
                        <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
                    </TranslationTabRight>
                ) : (
                    <TranslationTabLeft>
                        <OriginalLangStyle onClick={handleClickOriginalLang}>
                            <u>{getLangName(message.detectedLanguage)}</u>
                        </OriginalLangStyle>
                        <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
                    </TranslationTabLeft>
                ))
            }
        </Stack >
    );
};

export default LoanConditionsLinkContent;
