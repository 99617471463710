import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { styled, useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import StandardTablePagination from '@components/StandardTablePagination';
import DialogHeader from '@components/DialogHeader';

import axiosCall from '@services/axios';
import TableHeaderBar from '@components/TableHeaderBar';
import useControllerCallback from '@common/hooks/useControllerCallback';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '8px',
  },
}));

/**
 * Modal for editing a call to action
 */
const EditCallToActionDialog = ({ callToAction, open, onClose, refreshData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [input, setInput] = useState(callToAction);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    const url = `cta/${callToAction._id}`;
    setErrorMessage('');
    try {
      const submitData = { ...input };
      // Do not submit the userWorkflow or data properties
      submitData.userWorkflow = undefined;
      submitData.data = undefined;

      await axiosCall({ url, method: 'PUT', data: submitData });
      onClose();
      refreshData();
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogHeader title={t('editCallToAction')} onClose={onClose} />
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, mt: 1, minWidth: '400px', maxWidth: '700px' }}>
          <TextField
            size="small"
           
            variant="outlined"
            label={t('itemNameCol')}
            onChange={(e) => setInput((prev) => ({ ...prev, itemName: e.target.value }))}
            value={input.itemName}
          />
          <TextField
            size="small"
           
            variant="outlined"
            label={t('itemDescriptionCol')}
            onChange={(e) => setInput((prev) => ({ ...prev, itemDescription: e.target.value }))}
            value={input.itemDescription}
          />
          <TextField
            size="small"
           
            variant="outlined"
            label={t('responsibleUserCol')}
            onChange={(e) => setInput((prev) => ({ ...prev, responsibleUser: e.target.value }))}
            value={input.responsibleUser}
          />
          <TextField
            size="small"
           
            variant="outlined"
            label={t('responsibleTeamCol')}
            onChange={(e) => setInput((prev) => ({ ...prev, responsibleTeam: e.target.value }))}
            value={input.responsibleTeam}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              renderInput={(props) => <TextField sx={{ width: '15rem' }} size="small" color="info" {...props} />}
              label={t('completionDateCol')}
              inputFormat="MM/DD/YYYY"
              onChange={(newValue) => setInput((prev) => ({ ...prev, completionDate: newValue }))}
              value={input.completionDate}
            />
          </LocalizationProvider>
          {errorMessage && <Typography color={theme.palette.red['400']}>{errorMessage}</Typography>}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onClose}>
          {t('cancelButton')}
        </Button>
        <Button variant="contained" color="info" onClick={handleSubmit}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * Modal for viewing the data associated with a call to action
 */
const CallToActionDataDialog = ({ callToAction, open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogHeader title={t('callToActionData')} onClose={onClose} />
      <DialogContent sx={{ minWidth: '350px', maxWidth: '700px' }}>
        {callToAction?.data ? (
          <pre>{JSON.stringify(callToAction.data, null, 2)}</pre>
        ) : (
          <Stack alignItems="center" justifyContent="center" height="100px">
            <Paper sx={{ p: 1.5 }}>
              <Typography>{t('noData')}</Typography>
            </Paper>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={onClose}>
          {t('closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type TCallToAction = {
  _id: string;
  itemName: string;
  itemDescription: string;
  responsibleUser: string;
  responsibleTeam: string;
  callToActionDate: Date | null | undefined;
  completionDate: Date | null | undefined;
  data: {
    [key: string]: any;
  };
};

const CallsToAction = () => {
  const paginationSteps = [15, 50, 100];
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [callsToActionData, setCallsToAction] = useState<TCallToAction[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [editModalState, setEditModalState] = useState({ callToAction: {}, open: false });
  const [dataModalState, setDataModalState] = useState({ callToAction: {}, open: false });
  const pageRef = useRef(0);
  const rowsPerPageRef = useRef(paginationSteps[0]);
  const showInactiveRef = useRef(false);
  const noResults = callsToActionData.length === 0;

  const loadCallsToActionData = useCallback(
    async (controller?: AbortController) => {
      let url = 'cta/';
      if (searchValue) {
        url += searchValue;
      }
      url += '?orderBy=createdAt&order=desc';

      let offset = rowsPerPageRef.current === -1 ? 0 : pageRef.current * rowsPerPageRef.current;
      // Attempt to load one more document to check if there are more available
      let limit = rowsPerPageRef.current + 1;
      url += `&offset=${offset}`;
      if (rowsPerPageRef.current !== -1) {
        url += `&limit=${limit}`;
      }
      if (showInactiveRef.current) {
        url += `&showCompleted=true`;
      }
      let config = controller ? { signal: controller.signal } : {};

      setLoading(true);
      try {
        const result = await axiosCall({ url }, config);
        if (Array.isArray(result)) {
          setCallsToAction(result);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [searchValue]
  );

  const handleShowInactiveClick = () => {
    showInactiveRef.current = !showInactiveRef.current;
    pageRef.current = 0;
    loadCallsToActionData();
  };

  const handlePageChange = (_, newPage) => {
    pageRef.current = newPage;
    loadCallsToActionData();
  };

  const handleRowsPerPageChange = (e) => {
    rowsPerPageRef.current = e.target.value;
    pageRef.current = 0;
    loadCallsToActionData();
  };

  const handleEditModalClose = () => {
    setEditModalState({ callToAction: {}, open: false });
  };

  const handleEditClick = (callToAction) => {
    setEditModalState({ callToAction, open: true });
  };

  const handleViewDataClick = (callToAction) => {
    setDataModalState({ callToAction, open: true });
  };

  const handleDataModalClose = () => {
    setDataModalState({ callToAction: {}, open: false });
  };

  const handleSearchChange = (newSearchValue: string) => {
    // We want to reset back to the first page when searching so we don't miss any documents (for example, we don't want
    // to begin our search on the second page as we would miss those on the first)
    pageRef.current = 0;
    // Now we can update the search value
    setSearchValue(newSearchValue);
  };

  useControllerCallback(loadCallsToActionData);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} height="calc(100vh - 175px)" padding={1}>
      <TableHeaderBar
        onRefresh={loadCallsToActionData}
        shouldHaveSearch={true}
        searchValue={searchValue}
        setSearch={handleSearchChange}
        showInactiveClick={handleShowInactiveClick}
        isActive={!showInactiveRef.current}
      />
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : noResults ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <Paper sx={{ p: 1.5 }}>
              <Typography>{t('noData')}</Typography>
            </Paper>
          </Stack>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('itemNameCol')}</StyledTableCell>
                <StyledTableCell>{t('itemDescriptionCol')}</StyledTableCell>
                <StyledTableCell>{t('responsibleUserCol')}</StyledTableCell>
                <StyledTableCell>{t('responsibleTeamCol')}</StyledTableCell>
                <StyledTableCell>{t('callToActionDateCol')}</StyledTableCell>
                <StyledTableCell>{t('completionDateCol')}</StyledTableCell>
                <StyledTableCell width="50px">{t('tData')}</StyledTableCell>
                <StyledTableCell width="50px">{t('actionsCol')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callsToActionData.map((callToAction, index) => {
                // If we are in paginated mode and there is more data available than the page limit (because we attempt
                // to fetch one more row than the limit to check if more data is available), don't display the last row
                if (
                  rowsPerPageRef.current !== -1 &&
                  callsToActionData.length > rowsPerPageRef.current &&
                  index === rowsPerPageRef.current
                ) {
                  return null;
                }

                const { callToActionDate, completionDate } = callToAction;
                const callToActionDateString = !callToActionDate ? '' : new Date(callToActionDate).toLocaleDateString();
                const completionDateString = !completionDate ? '' : new Date(completionDate).toLocaleDateString();

                return (
                  <TableRow
                    sx={{ backgroundColor: !completionDate ? 'var(--gray000)' : 'var(--peach030)' }}
                    hover
                    key={callToAction._id}
                  >
                    <StyledTableCell>{callToAction.itemName}</StyledTableCell>
                    <StyledTableCell>{callToAction.itemDescription}</StyledTableCell>
                    <StyledTableCell>{callToAction.responsibleUser}</StyledTableCell>
                    <StyledTableCell>{callToAction.responsibleTeam}</StyledTableCell>
                    <StyledTableCell>{callToActionDateString}</StyledTableCell>
                    <StyledTableCell>{completionDateString}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: 1, pr: 1 }}
                      >
                        {Object.keys(callToAction.data ?? {}).length}
                        <Tooltip title={t('viewDataTooltip')}>
                          <VisibilityOutlinedIcon
                            onClick={() => handleViewDataClick(callToAction)}
                            sx={{ color: 'var(--blue210)', cursor: 'pointer' }}
                          />
                        </Tooltip>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign: 'center' }}>
                      <Tooltip
                        title={t('editCallToAction')}
                        placement="top-start"
                        enterDelay={1000}
                        enterNextDelay={1000}
                      >
                        <BorderColorOutlinedIcon
                          onClick={() => handleEditClick(callToAction)}
                          fontSize="small"
                          sx={{ cursor: 'pointer', color: 'var(--blue210)' }}
                        />
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StandardTablePagination
                  steps={paginationSteps}
                  rowsPerPage={rowsPerPageRef.current}
                  page={pageRef.current}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  isMore={rowsPerPageRef.current === -1 ? false : callsToActionData.length > rowsPerPageRef.current}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {editModalState.open && (
        <EditCallToActionDialog
          open={editModalState.open}
          callToAction={editModalState.callToAction}
          onClose={handleEditModalClose}
          refreshData={loadCallsToActionData}
        />
      )}
      {dataModalState.open && (
        <CallToActionDataDialog
          open={dataModalState.open}
          callToAction={dataModalState.callToAction}
          onClose={handleDataModalClose}
        />
      )}
    </Box>
  );
};

export default CallsToAction;
