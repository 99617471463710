import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses
} from "@mui/material"

import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { DialogPropsWithChildren } from "@mTypes/Console";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--gray010)",
    align: "left"
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: "1px solid var(--gray010)",

  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "1px solid var(--gray010)"
  }
}));

const StatusDialog: FC<DialogPropsWithChildren> = (props) => {
  const { t } = useTranslation();

  const formatDateTime = (value) => {
    if (Date.parse(value)) {
      return dayjs(value).format('MM/DD/YY hh:mm:ss A');
    }
    return '';
  };

  return (
    <Dialog maxWidth="md" open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {t("statusLogTitle")}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <TableContainer sx={{ width: "500px", height: "400px", border: "1px solid var(--gray010)" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("accessTimeCol")}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                props.statuses.map(status =>
                  <TableRow hover key={status?._id}>
                    <StyledTableCell>{formatDateTime(status.createdAt)}</StyledTableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={() => props.onClose()}>{t("closeButton")}</Button>
      </DialogActions>
    </Dialog >
  )
}

export default StatusDialog;
