import { IErrand } from '@interfaces/Conversation';
import { Swiper } from 'swiper/react';

export const enum VideoListMenuState {
    VIDEO_NOT_SELECTED = 'VIDEO_NOT_SELECTED',
    VIDEO_SELECTED = 'VIDEO_SELECTED',
    VIDEO_FETCH_ERROR = 'VIDEO_FETCH_ERROR',
    VIDEO_FETCH_LOADING = 'VIDEO_FETCH_LOADING'
} 
export type TVideoListMenuState = keyof typeof VideoListMenuState;
export type TVideListMessageParsed = {
    message: string,
    publicRecipients: string[]
} 

export type TTranslationsData = {
  en: string;
  es: string;
  ko: string;
};

export type TWorkflowData_UPmenu = {
  description: string;
  displayName: string;
  name: TTranslationsData;
  workflowIcon: string;
  _id: string;
};

export type TWorkflowDataFetched_UPmenu = {
  description: string;
  displayName: string;
  name: string;
  workflowIcon: string;
  _id: string;
};

// General
export interface MorphVideoListMenuProps {
  cancelAction: (key: any, clear: boolean, withMorphTypeChange: boolean) => void;
  errand: IErrand;
}

// Arrows
export interface ArrowsProps {
  swiperInstance: Swiper | null;
  hasMounted: boolean;
  isLoading: boolean;
  onSingleSlideClickHandler: (e: any, idx: any) => void;
  selectedIdx: number | null;
  handleExpandMenu: () => void;
}
export type ArrowDirection = 'left' | 'right';
