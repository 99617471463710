import React from 'react';

export const TagLine = ({ children }) => {
  return (
    <>
      <div
        className='tagLine'
        style={{
          fontSize: 'clamp(20px, 2.3vh, 23px)',
          color: 'var(--gray950)',
          fontWeight: '600',
          textAlign: 'center',
          padding: '0 6px'
        }}
      >
        {children}
      </div>
    </>
  );
};
