import React from 'react';

import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Default from '@styles/Skeletons/MessageContentSkeleton.module.css';
import Coupon from '@styles/Skeletons/CouponMessageContentSkeleton.module.css';
import { IMessage } from '@interfaces/Conversation';

// The render component below lazy loads the corresponding component using the skeleton above
type TMessageContentFallbackProps = {
  message: IMessage;
};

const DeletedMessageContentFallback = ({ message }: TMessageContentFallbackProps) => {
  return (
    <div
      className={[
        Skeleton.loader,
        Default.base,
        Default.deletedMessageContent,
        ...(message.alignByCurrentUser ? [Default.alignByCurrentUser] : []),
      ].join(' ')}
    />
  );
};

const PrivateMessageContentFallback = ({ message }: TMessageContentFallbackProps) => {
  return (
    <div
      className={[
        Skeleton.loader,
        Default.base,
        Default.privateMessageContent,
        ...(message.alignByCurrentUser ? [Default.alignByCurrentUser] : []),
      ].join(' ')}
    />
  );
};

// @Harrison Please fix the style error where the coupon message aligns with current user on console side
const CouponMessageContentFallback = () => {
  return (
    <div className={Coupon.skeletonWrapper}>
      <div className={[Skeleton.loader, Coupon.codeSkeleton].join(' ')} />
      <div className={[Skeleton.loader, Coupon.textSkeleton].join(' ')} />
    </div>
  );
};

const MessageContentFallback = ({ message }: TMessageContentFallbackProps) => {
  return (
    <div
      className={[
        Skeleton.loader,
        Default.base,
        Default[message?.messageType],
        ...(message.alignByCurrentUser ? [Default.alignByCurrentUser] : []),
      ].join(' ')}
    />
  );
};

export {
  DeletedMessageContentFallback,
  PrivateMessageContentFallback,
  CouponMessageContentFallback,
  MessageContentFallback,
};
