import * as THREE from 'three'
import React, { useState, useRef, useEffect, Suspense } from 'react'
import { useTranslation } from 'react-i18next';
import { GlobeContainer } from './GlobeStyle';
import { Canvas } from '@react-three/fiber'
import {
Image,
Environment,
useTexture,
} from '@react-three/drei'
import { CameraControls } from '@react-three/drei'
const globeLoader = process.env.REACT_APP_MORGAN_CDN + '/splash/globeLoader.gif'
const earthBump = process.env.REACT_APP_MORGAN_CDN + '/splash/Assets/EarthbumpMirror.png'
const earthMap = process.env.REACT_APP_MORGAN_CDN + '/splash/Assets/EarthmapMirror.png'
const earthRoughness = process.env.REACT_APP_MORGAN_CDN + '/splash/Assets/EarthRoughnessMirror.png'
const americanFlag = process.env.REACT_APP_MORGAN_CDN + '/splash/Assets/globe-america.png'
const koreanFlag = process.env.REACT_APP_MORGAN_CDN + '/splash/Assets/globe-korea.png'
const puertoricoFlag = process.env.REACT_APP_MORGAN_CDN + '/splash/Assets/globe-puerto-rico.png'
const potsdamer = process.env.REACT_APP_MORGAN_CDN + '/splash/Assets/potsdamer_platz_1k.hdr'



// function MyCamera() {
// const ref = useRef(null)

// useFrame(() => {
// ref.current?.position.lerp(new THREE.Vector3().setFromSphericalCoords(2.7, -0.97, 0.74), 0.04)
// ref.current?.lookAt(new THREE.Vector3().setFromSphericalCoords(2, -0.97, 0.74))
// })

//     return <PerspectiveCamera ref={ref} position={[0, 0, 6]} fov={55} matrixWorldAutoUpdate getObjectsByProperty={() => { }} />;
// }

function Earth() {
    const texture = useTexture(earthMap) as unknown as THREE.Texture;
    const bump = useTexture(earthBump) as unknown as THREE.Texture;
    const roughness = useTexture(earthRoughness) as unknown as THREE.Texture;

    texture.anisotropy = 16
    texture.flipY = false
    bump.flipY = false
    roughness.flipY = false

return (
<>
    <Image
        scale={[2.1 * 0.14, 1.2 * 0.14]}
        rotation={[-Math.PI * 2.2, Math.PI * 2, 0]}
        position={new THREE.Vector3().setFromSphericalCoords(1.53, 0.84, -6.4)}
        url={`${americanFlag}`}
        transparent
        matrixWorldAutoUpdate={false}
    />

    <Image
        scale={[2.1 * 0.14, 1.2 * 0.14]}
        rotation={[0.4, -Math.PI * 0.9, 0]}
        position={new THREE.Vector3().setFromSphericalCoords(1.55, -0.96, 0.76)}
        url={`${koreanFlag}`}
        transparent
        matrixWorldAutoUpdate={false}
    />
        
    <Image
        scale={[2.1 * 0.13, 1.2 * 0.13]}
        rotation={[-Math.PI * 2.1, Math.PI * 2.1, 0]}
        position={new THREE.Vector3().setFromSphericalCoords(1.54, 1.264, -5.785)}
        url={`${puertoricoFlag}`}
        transparent
        matrixWorldAutoUpdate={false}
    />

    {/* <Image
    scale={[2.1 * 0.14, 1.2 * 0.14, 1 * 0.14]}
    rotation={[0.45, -Math.PI * 0.9, 0]}
    position={new THREE.Vector3().setFromSphericalCoords(1.55, -1, 0.29)}
    url={'China-flag.png'}
    transparent
    /> */}
        
    {/* <Image
    scale={[2.1 * 0.13, 1.2 * 0.13, 1 * 0.13]}
    rotation={[-Math.PI * 2, Math.PI * 0.5, 0.1]}
    position={new THREE.Vector3().setFromSphericalCoords(1.6, -0.73, -1.32)}
    url={'France-flag.png'}
    transparent
    /> */}

    {/* <Image
    scale={[2.1 * 0.13, 1.2 * 0.13, 1 * 0.13]}
    rotation={[0.38, -Math.PI, 0]}
    position={new THREE.Vector3().setFromSphericalCoords(1.55, -1.1, -0.07)}
    url={'India-flag.png'}
    transparent
    /> */}

    <mesh position={[0, 0, 0]}>
    <sphereGeometry args={[1.5, 100, 100]} />
    <meshStandardMaterial
        side={THREE.DoubleSide}
        roughnessMap={roughness}
        roughness={0.75}
        map={texture}
        displacementScale={0.075}
        displacementMap={bump}
    />
    </mesh>


</>
)
}

export default function Viewer() {
    // const usaButtonRef = useRef<THREE.Object3D | null>(null);
    // const koreaButtonRef = useRef<THREE.Object3D | null>(null);
    // const puertoRicoButtonRef = useRef<THREE.Object3D | null>(null);
    const canvasRef = useRef<CameraControls | null>(null);
    const { i18n } = useTranslation();
    const [defaultPosition, setDefaultPosition] = useState<[number, number, number]>([0, 0, 0])

    useEffect(() => {
        console.log('resolved language: ' + i18n.resolvedLanguage)
        if (i18n.resolvedLanguage === 'en') {
            usaButton()
            setDefaultPosition([-0.4, 1.8, 2.5])
        } else if (i18n.resolvedLanguage === 'ko') {
            koreaButton()
            setDefaultPosition([-1.8, 2, -2])
        } else if (i18n.resolvedLanguage === 'es') {
            puertoRicoButton()
            setDefaultPosition([1.4, 0.8, 2.5])
        } else {
            return
        }
    }, [i18n.resolvedLanguage])

    const usaButton = () => {
        const position = new THREE.Vector3().setFromSphericalCoords(2.65, 0.85, -6.4);
        if (canvasRef.current) {
            const cameraControls = canvasRef.current;
            cameraControls.setPosition(position.x, position.y, position.z, true);
        }
    }
        
    const koreaButton = () => {
        const position = new THREE.Vector3().setFromSphericalCoords(2.5, -0.97, 0.77);
        if (canvasRef.current) {
        const cameraControls = canvasRef.current;
        cameraControls.setPosition(position.x, position.y, position.z, true);
        }
    }

    const puertoRicoButton = () => {
        const position = new THREE.Vector3().setFromSphericalCoords(2.5, 1.26, -5.77);
        if (canvasRef.current) {
        const cameraControls = canvasRef.current;
        cameraControls.setPosition(position.x, position.y, position.z, true);
        }
    }

    /* SphericalCoords for...
        India: (2.7, -1.15, -0.067)
        China: (2.8, -0.98, 0.3)
        France: (2.6, -0.76, -1.4) */
    


    
return (
<GlobeContainer>
    <div className='Globe-container'>
        <div className='globe' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Suspense fallback={
                    <img
                        className='globeLoader'
                        src={globeLoader}
                        width='100%'
                        height='100%'
                        alt='Spinning globe'
                    />}>
                <Canvas className='canvas' dpr={2} camera={{ position: defaultPosition, fov: 55 }}>
                    <ambientLight intensity={0.5} />
                    <Earth />
                    {/* <Environment preset='city' /> */}
                        <Environment files={`${potsdamer}`} />
                    <CameraControls ref={canvasRef} truckSpeed={0.0} maxDistance={3.5} minDistance={3.5} />
                </Canvas>
            </Suspense>
        </div>
    </div>
</GlobeContainer>
)
}
