import React from 'react';
import { styled } from '@mui/system';
import ShimmerEffect from '@components/ShimmerEffect';

function ParticipantsSkeletonLoader() {

// Keep track of the user's resolution to display different margins
    const isDesktop = window.innerWidth > 900;


// This creates the skeleton for the ConversationBody box which holds all the messages
    const Container = styled('div')({
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        height: '50%',
        width: '250px',
        marginTop: `${isDesktop ? '5px' : '0'}`,
        marginLeft: `${isDesktop ? '6px' : '0'}`,
    })

// This creates the circle skeleton to represent the person icon
    const IconSkeleton = styled('div')({
        backgroundColor: 'var(--gray000)',
        borderRadius: '50%',
        height: '24px',
        width: '24px',
        marginLeft: '21px',
        overflow: 'hidden'
    })
    
// This creates the bubble skeleton for particpants' name (Default: Morgan)
    const NameSkeleton = styled('div')({
        backgroundColor: 'var(--gray000)',
        borderRadius: '8px',
        height: '100%',
        width: '140px',
        marginLeft: '6px',
        overflow: 'hidden'
    })

    return (
        <Container>
            <IconSkeleton><ShimmerEffect color='var(--shadow602)' width='70%'/></IconSkeleton>
            <NameSkeleton><ShimmerEffect color='var(--shadow602)' width='35%'/></NameSkeleton>
        </Container>
    )
}

export default ParticipantsSkeletonLoader