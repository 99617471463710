import React from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AttachmentMessageContentStyle from '@styles/AttachmentMessageContentStyle'
import { IErrand, IMessage } from '@interfaces/Conversation';
import DocumentBubble from '../DocumentBubble';

type TDocumentFieldMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const DocumentFieldMessageContent = ({
  errand, message
}: TDocumentFieldMessageContentProps) => {
  return (
    <AttachmentMessageContentStyle>
      <span>
        <p>
          {message.documents?.map((doc, index) =>
          (<><DocumentBubble key={index} errand={errand} document={doc} message={message} isLastFileAttachment={index === message.documents.length - 1} />
            {index !== message.documents.length - 1 && (
              message?.alignByCurrentUser ? (
                <div className="dottedLines"
                />
              ) : (
                <div className="dottedLinesThem" />
              )

            )}</>
          )
          )}
        </p>
      </span>

      <span>
        <div>
          {typeof message.icon === 'string' && message.icon !== '' ? (
            <img src={message.icon} alt='Field Icon' />
          ) : (
            <QuestionMarkIcon />
          )}
        </div>
      </span>
    </AttachmentMessageContentStyle>
  );
};

export default DocumentFieldMessageContent;