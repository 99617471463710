import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';

import { IErrand, IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import axiosCall from '@services/axios';
import { AccessType } from '@common/AccessType';

import Styles from '@styles/MorphMessageOptions.module.css';

type TMorphMessageOptionSetInternalProps = {
  errand: IErrand;
  handleClose: () => void;
  message: IMessage;
};

const MorphMessageOptionSetInternal = ({
  errand, handleClose, message
}: TMorphMessageOptionSetInternalProps) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();

  const handleClickSetInternal = useCallback(async () => {
    // regardless of acces, don't do anything unless clicked by an operator
    if (!message || !message.operatorView || !errand?._id || !message?._id) return;
    const response = await axiosCall({
      url: `chat/${errand._id}/message/${message._id}`,
      method: 'put',
      data: { accessType: message.accessType === AccessType.internal ? AccessType.public : AccessType.internal },
    }).catch((e) => console.error(e));
    console.log(
      `Message ${message._id} set to internal on chat ${errand._id}`,
      response
    );
    handleClose();
  }, [errand?._id, message?._id, message?.operatorView, message?.accessType]);

  return (
    <button className={Styles.btn} onClick={handleClickSetInternal}>
      <div className={Styles.btnBorder}>
        {message.accessType === AccessType.internal || message.accessType === AccessType.private ? (
          <VisibilityOffOutlined />
        ) : (
          <VisibilityOutlined />
        )}
        <p className={Styles.label}>{message.accessType === AccessType.internal ? t('tInternal') : message.accessType === AccessType.private ? t('tPrivate') : t('tPublic')}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionSetInternal;
