/**
* @file This component is a stylized Typography component
* for generating translated text. 
* 
* Extracted from ConversationMessage.js 10/11/2022
* 
* @author Harrison Fales <harrison.fales@swmc.com>
* Original Component Author
* @author Kristoffer Wright <kristoffer.wright@swmc.com>
*/

import { Box, styled } from '@mui/system';

const Curve = process.env.REACT_APP_MORGAN_CDN + '/Images/TranslationCurve.svg';

const TranslationTabLeft = styled(Box)({
  backgroundImage: `url(${Curve})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain 100%',
  backgroundPosition: '50% 100%',
  backgroundColor: 'inherit',
  width: 'auto',
  minWidth: '220px',
  paddingLeft: '65px',
  paddingBottom: '4px',
  position: 'relative',
  display: 'flex',
  textAlign: 'left',
  gap: '10px',
  color: 'var(--blue999)',
  alignSelf: 'center'
});

export default TranslationTabLeft;