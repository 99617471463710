import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Chip,
  Select,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import React, { useState, useCallback, useRef } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import DialogHeader from './DialogHeader';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import TableHeaderBar from './TableHeaderBar';
import AITasksDialogEdit from './AITasksDialogEdit';
import StandardTablePagination from './StandardTablePagination';
import AIViewStepsDialog from './AIViewStepsDialog';
import LinearProgress from '@mui/material/LinearProgress';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import axiosCall from '../Services/axios';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import {
  StyledTableCell,
  StyledTable,
  StyledProgressCircle,
  StyledPaper,
  StyledButton,
  StyledInputLabel,
  StyledFormControl,
} from '@styles/DashboardTab';
import Styles from '@styles/DashboardTab.module.css';
import useInitialMount from '@common/hooks/useInitialMount';
let operatorAxios = axiosCall;

const DEFAULT_ENTRY_PER_PAGE = 15;

enum AiTaskChildWindowType {
  None = 'None',
  EditTask = 'EditTask',
  ViewSteps = 'ViewSteps',
  BulkTaskViewer = 'BulkTaskViewer',
}

enum AiTaskSortType {
  None = 'None',
  category = 'category',
  subCategory = 'subCategory',
  name = 'name',
  instructions = 'instructions',
  actions = 'actions',
}

enum AITaskSortDirection {
  None = 'None',
  Ascending = 'Ascending',
  Descending = 'Descending',
}

interface BulkTaskViewerProps {
  data: any;
  onSubmit: () => void;
  onClose: () => void;
}

const BulkTaskViewer: React.FC<BulkTaskViewerProps> = (props) => {
  const { data, onSubmit, onClose } = props;

  const { t } = useTranslation();
  return (
    <Dialog open={true} onClose={() => onClose()}>
      <DialogHeader title={t('Task Import Preview')} onClose={() => onClose()} />
      <DialogContent sx={{ paddingBottom: '0px' }}>
        <Table sx={{ height: 'auto' }} size="medium" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('taskTranslation')}</StyledTableCell>
              <StyledTableCell>{t('categoryTranslation')}</StyledTableCell>
              <StyledTableCell>{t('subcategory')}</StyledTableCell>
              <StyledTableCell>{t('instructionsTranslation')}</StyledTableCell>
              <StyledTableCell>{t('answerTranslation')}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data?.map((taskAction, index) => (
              <TableRow hover key={index}>
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>{taskAction?.category}</StyledTableCell>
                <StyledTableCell>{taskAction?.subCategory}</StyledTableCell>
                <StyledTableCell>{taskAction?.Query}</StyledTableCell>
                <StyledTableCell>{taskAction?.Response}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit}>{t('Confirm Import')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const searchOptions = ['Category', 'Subcategory', 'Name', 'Instructions', 'Answer'];

const AITasksTab = () => {
  const { t } = useTranslation();

  // background data, following variables should not trigger re-render
  const importedTasksRef = useRef({} as any);
  const taskSingleRef = useRef({} as any);
  const taskManyRef = useRef([]);
  const searchTextRef = useRef('');
  const searchActiveStatusRef = useRef(false);
  const pageIndexRef = useRef(0);
  const limitPerPageRef = useRef(DEFAULT_ENTRY_PER_PAGE);
  const sortFieldRef = useRef(AiTaskSortType.None);
  const sortDirectionRef = useRef(AITaskSortDirection.None);
  const searchOptionRef = useRef(searchOptions);
  const staticDataRef = useRef({
    ActionData: [],
    WorkflowData: [],
    HelpTipData: [],
    HelpTipGroupData: [],
  });

  // Triggers re-render when dashboard re-loads
  const [loading, setLoading] = useState(false);

  // Triggers re-render when child component open/close
  const [childWindow, setChildWindow] = useState(AiTaskChildWindowType.None);

  // Triggers re-render when column is pressed and sorting applies to that specific column
  const [sortedData, setSortedData] = useState(null);

  // Triggers re-render when check box is checked or unchecked
  const [checkedTasks, setCheckedTasks] = useState([]);

  // Triggers re-render when Task File is uploaded
  const [uploading, setUploading] = useState(false);

  // This needs to be reworked to process when multiple files are uploaded. -Jaden
  const onDrop = useCallback(async (acceptedFiles) => {
    for (const file of acceptedFiles) {
      if (file.size > 2000000) {
        window.alert('Error: File too big.');
        return;
      }

      let reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function () {
        try {
          let data = JSON.parse(reader.result.toString());

          for (let i = 0; i < data.data.length; i++) {
            if (data.data[i].Query === undefined) {
              throw new Error('Error parsing Query attribute for task ' + i.toString());
            }
          }

          data.fileName = file.name;

          importedTasksRef.current = data;

          setChildWindow(AiTaskChildWindowType.BulkTaskViewer);

          return reader.result;
        } catch (e) {
          window.alert(t('Error: Invalid Task File : ') + e);
          return null;
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    accept: {
      'application/json': ['.json'],
    },
  });

  const downloadTasks = useCallback(async () => {
    let output: any = [];

    for (const task of getCurrentTasks()) {
      const actions = task?.actions;
      const instructions = task?.instructions;
      const category = task?.category;
      const subCategory = task?.subCategory;

      if (Array.isArray(actions) && actions.length > 0) {
        const lastElementActionsAnswer = actions[actions.length - 1].answer;

        //Instructions and response are mandatory, so only output those ones.
        if (instructions && lastElementActionsAnswer) {
          output.push({
            Response: lastElementActionsAnswer || '',
            category: category,
            subCategory: subCategory,
            Query: instructions,
          });
        }
      }
    }

    output = { data: output };

    const today: Date | string = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();

    const fileName = 'TASK_FILE_' + yyyy + '/' + mm + '/' + dd;

    const json = JSON.stringify(output, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + '.json';
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * load dashboard
   *
   * @param searchField Which fields search are gonna be performed?
   * @param newStatus aiTask status requirement
   * @param searchText search ttext
   * @param pageNumber which page are we gonna show?
   * @param limit row limit for page
   */
  const populateDashboard = useCallback(
    async (searchField: Array<string>, newStatus: boolean, searchText: string, pageNumber: number, limit: number) => {
      if (searchField !== null) {
        searchOptionRef.current = searchField;
      }

      if (newStatus !== null) {
        searchActiveStatusRef.current = newStatus;
      }

      if (searchText !== null) {
        searchTextRef.current = searchText;
      }

      if (pageNumber !== null) {
        pageIndexRef.current = pageNumber;
      }

      if (limit !== null) {
        limitPerPageRef.current = limit;
      }

      const offset = pageIndexRef.current * limitPerPageRef.current;

      const data = {
        search: searchTextRef.current,
        active: searchActiveStatusRef.current,
        fields: searchOptionRef.current,
      };

      const request = {
        url: `ai/task/search?offset=${offset}&limit=${limitPerPageRef.current}`,
        method: 'POST',
        data: data,
      };

      // start loading UI
      setLoading(true);

      try {
        const result = await operatorAxios(request, {});

        if (sortDirectionRef.current !== AITaskSortDirection.None) {
          const sorted = sortView(result, sortFieldRef.current, sortDirectionRef.current);
          setSortedData(sorted);
        }

        // update data
        taskManyRef.current = result;

        // update check box ---> unchecked
        setCheckedTasks(result.map(() => false));
      } catch (error) {
        console.log(error);
      }

      // stop loading
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAiTaskOpen = useCallback((task = { actions: [] }) => {
    taskSingleRef.current = task;

    setChildWindow(AiTaskChildWindowType.EditTask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAiTaskClose = useCallback(async () => {
    taskSingleRef.current = { actions: [] };

    childWindowClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //edit or add ai task.
  const createOrUpdateAiTask = useCallback(async (data) => {
    try {
      await operatorAxios({
        url: 'ai/task/upsert/single',
        method: 'PUT',
        data: {
          _id: data._id,
          category: data.category,
          subCategory: data.subCategory,
          name: data.name,
          instructions: data.instructions,
          actions: data.actions,
          active: true,
        },
      });
    } catch (err) {
      console.error('AITasksTab-createOrUpdateAiTask: ai task create or update single failed!');
    }

    // close child window
    handleAiTaskClose();

    // reload dashboard
    populateDashboard(null, null, null, null, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewSteps = useCallback((task) => {
    taskSingleRef.current = task;

    setChildWindow(AiTaskChildWindowType.ViewSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const childWindowClose = useCallback(() => {
    setChildWindow(AiTaskChildWindowType.None);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewBulkSubmit = useCallback(async () => {
    // show progress bar
    setUploading(true);

    console.log('imported tasks are', importedTasksRef.current);

    childWindowClose();

    try {
      await operatorAxios({
        url: 'ai/task/upsert/many',
        method: 'PUT',
        data: importedTasksRef.current,
      });
    } catch (err) {
      window.alert('error importing tasks: ' + err.message);
    }

    // hide progress bar
    setUploading(false);

    populateDashboard(null, null, null, 0, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortView = useCallback((taskMany, field: AiTaskSortType, direction: AITaskSortDirection) => {
    if (direction === AITaskSortDirection.None) {
      return;
    }

    // Sort the array of objects based on the field
    return [...taskMany].sort((a, b) => {
      let catA = '';
      let catB = '';

      try {
        if (field === AiTaskSortType.actions) {
          catA = a[field][0].answer.toLowerCase();
        } else {
          catA = a[field].toLowerCase();
        }
      } catch (err) {
        console.error('AITasksTab-sortView target reference failed catA', a);
      }

      try {
        if (field === AiTaskSortType.actions) {
          catB = b[field][0].answer.toLowerCase();
        } else {
          catB = b[field].toLowerCase();
        }
      } catch (err) {
        console.error('AITasksTab-sortView target reference failed catB', b);
      }

      if (direction === AITaskSortDirection.Descending) {
        // Ascending order
        return catA.localeCompare(catB);
      } else {
        // Descending order
        return catB.localeCompare(catA);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = useCallback((nextField: AiTaskSortType) => {
    let nextDirection;

    if (sortDirectionRef.current === AITaskSortDirection.None) {
      nextDirection = AITaskSortDirection.Descending;
    } else if (sortDirectionRef.current === AITaskSortDirection.Descending) {
      nextDirection = AITaskSortDirection.Ascending;
    } else {
      nextDirection = AITaskSortDirection.None;
    }

    const sorted = sortView(taskManyRef.current, nextField, nextDirection);

    sortDirectionRef.current = nextDirection;
    sortFieldRef.current = nextField;
    setSortedData(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback((event, newPage) => {
    populateDashboard(null, null, null, newPage, null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    const newRowSize = parseInt(event.target.value, 10);

    populateDashboard(null, null, null, 0, newRowSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentTasks = useCallback(() => {
    return sortedData || taskManyRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedData]);

  const getCurrentTasksLength = useCallback(() => {
    const currentTasks = getCurrentTasks();

    return currentTasks.length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentTaskByIndex = useCallback((index) => {
    const currentTasks = getCurrentTasks();

    return currentTasks[index];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initial call to populate static data and populate dashboard
  const loadPageFirstTime = useCallback(() => {
    populateDashboard(null, null, null, 0, null);

    // concurrent requests, Follow requests should not trigger re-render
    Promise.all([
      operatorAxios({ url: `action/?order=asc&active=1&fields=description` }),
      operatorAxios({ url: `workflow/?order=asc&active=1&fields=name` }),
      operatorAxios({ url: `helptip/?order=desc&orderby=createdAt&active=1&fields=name` }),
      operatorAxios({ url: `helptipgroup/?order=desc&orderby=createdAt&active=1&fields=name` }),
    ])
      .then((data) => {
        staticDataRef.current.ActionData = data[0];
        staticDataRef.current.WorkflowData = data[1];
        staticDataRef.current.HelpTipData = data[2];
        staticDataRef.current.HelpTipGroupData = data[3];
      })
      .catch((error) => {
        console.error(`AITAsksTab first render ${error}`);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInitialMount(loadPageFirstTime);

  const handleSwitch = useCallback(
    async (task, activeValue) => {
      const dataArray = [];

      if (checkedTasks.includes(true)) {
        checkedTasks.forEach((task, index) => {
          if (task === true) {
            const data = getCurrentTaskByIndex(index);

            dataArray.push({
              category: data.category,
              subCategory: data.subCategory,
              name: data.name,
              instructions: data.instructions,
              actions: data.actions,
              active: !data.active,
            });
          }
        });
      } else {
        dataArray.push({
          category: task.category,
          subCategory: task.subCategory,
          name: task.name,
          instructions: task.instructions,
          actions: task.actions,
          active: !activeValue,
        });
      }

      const workArray = dataArray.map((x) => {
        return operatorAxios({
          url: 'ai/task/upsert/single',
          method: 'PUT',
          data: x,
        });
      });

      try {
        await Promise.all(workArray);
      } catch (err) {
        console.error('AITasksTab-handleSwitch: ai task create or update single failed!');
      }

      // refresh dashboard
      populateDashboard(null, null, null, null, null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedTasks]
  );

  const bulkCheckGet = useCallback(() => {
    if (checkedTasks.length > 0 && checkedTasks.includes(false)) {
      return false;
    }
    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTasks]);

  const handleCheckbox = useCallback(
    (event, index) => {
      checkedTasks[index] = event.target.checked;

      setCheckedTasks(checkedTasks.map((x) => x));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedTasks]
  );

  const handleBulkCheck = useCallback(() => {
    if (bulkCheckGet()) {
      // bulk checked ---> bulk unchecked
      setCheckedTasks(checkedTasks.map((x) => false));
    } else {
      // bulk unchecked ---> bulk cheched
      setCheckedTasks(checkedTasks.map((x) => true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTasks]);

  const ChildWindowMap = useCallback(() => {
    // if it's loading then don't show child window
    if (loading) {
      return <></>;
    }

    switch (childWindow) {
      case AiTaskChildWindowType.None:
        return <></>;
      case AiTaskChildWindowType.EditTask:
        return (
          <AITasksDialogEdit
            createOrUpdateAiTask={createOrUpdateAiTask}
            handleAiTaskClose={handleAiTaskClose}
            allActions={staticDataRef.current.ActionData}
            allWorkflows={staticDataRef.current.WorkflowData}
            allHelpTips={staticDataRef.current.HelpTipData}
            allHelpTipGroups={staticDataRef.current.HelpTipGroupData}
            taskSingle={taskSingleRef.current}
          />
        );
      case AiTaskChildWindowType.ViewSteps:
        return (
          <AIViewStepsDialog
            allActions={staticDataRef.current.ActionData}
            allWorkflows={staticDataRef.current.WorkflowData}
            allHelpTips={staticDataRef.current.HelpTipData}
            allHelpTipGroups={staticDataRef.current.HelpTipGroupData}
            taskSingle={taskSingleRef.current}
            onClose={childWindowClose}
          />
        );

      case AiTaskChildWindowType.BulkTaskViewer:
        return (
          <BulkTaskViewer data={importedTasksRef.current} onClose={childWindowClose} onSubmit={handleViewBulkSubmit} />
        );
      default:
        console.error(`Unhandled child tab ${childWindow}`);
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, childWindow]);

  return (
    <div className={Styles.ContainerColumnMain}>
      <div {...getRootProps()} className={Styles.ContainerRow} style={{ marginBottom: '8px' }}>
        <input {...getInputProps()} />
        <StyledButton startIcon={<AddIcon />} variant="contained" color="info" onClick={() => handleAiTaskOpen()}>
          {t('addTask')}
        </StyledButton>
        <StyledButton startIcon={<FileDownloadIcon />} variant="contained" color="info" onClick={() => open()}>
          {t('importTasks')}
        </StyledButton>
        <StyledButton
          startIcon={<DriveFolderUploadIcon />}
          variant="contained"
          color="info"
          onClick={() => downloadTasks()}
        >
          {t('exportTasks')}
        </StyledButton>
        <div style={{ marginBottom: '-8px' }}>
          <TableHeaderBar
            uploadIcon={false}
            onRefresh={void 0}
            addLabel={''}
            setSearch={(newSearch) => populateDashboard(null, null, newSearch, 0, null)}
            searchValue={searchTextRef.current}
            shouldHaveRefresh={false}
          />
        </div>
      </div>
      <div className={Styles.ContainerRow}>
        <StyledFormControl variant="outlined">
          <StyledInputLabel>Search By</StyledInputLabel>
          <Select
            multiple
            value={searchOptionRef.current}
            onChange={(event) => populateDashboard(event.target.value as string[], null, null, 0, null)}
            renderValue={(selected) => (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                {selected.map((value) => (
                  <Chip key={value} label={value} />
                ))}
              </div>
            )}
          >
            {searchOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <div className={Styles.ContainerRow}>
          <StyledButton
            variant="outlined"
            onClick={() => populateDashboard(null, !searchActiveStatusRef.current, null, 0, null)}
          >
            {searchActiveStatusRef.current ? t('showInactive') : t('hideInactive')}
          </StyledButton>
          <Tooltip title={t('refreshButtonTooltip')} placement="bottom">
            <CachedIcon className={Styles.IconCached} onClick={() => populateDashboard(null, null, null, null, null)} />
          </Tooltip>
        </div>
      </div>
      {uploading && (
        <>
          <Typography>{t('Task Upload in Progress... Please Wait')}</Typography>
          <div className={Styles.WidthMax}>
            <LinearProgress />
          </div>
        </>
      )}
      <TableContainer className={Styles.ContainerTable}>
        {loading ? (
          <div className={Styles.ContainerNoEntry}>
            <StyledProgressCircle disableShrink />
          </div>
        ) : (
          <>
            {getCurrentTasksLength() === 0 ? (
              <div className={Styles.ContainerNoEntry}>
                <StyledPaper>
                  <Typography> {t('noData')} </Typography>
                </StyledPaper>
              </div>
            ) : (
              <StyledTable stickyHeader>
                {checkedTasks.includes(true) && (
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <DeleteOutlinedIcon
                          className={`${Styles.IconDeleteOutlined} ${Styles.IconTopTrash}`}
                          onClick={() => handleSwitch(1, 1)}
                        />
                      </StyledTableCell>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell> </StyledTableCell>
                    </TableRow>
                  </TableHead>
                )}

                <TableHead>
                  <TableRow>
                    <StyledTableCell className={Styles.CellWidthFix}>
                      <Checkbox checked={bulkCheckGet()} onChange={handleBulkCheck}></Checkbox>
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${Styles.CellShowCursor} ${Styles.CellWidthFix}`}
                      onClick={() => handleSort(AiTaskSortType.category)}
                    >
                      {t('categoryTranslation')}
                      {sortFieldRef.current === AiTaskSortType.category &&
                        sortDirectionRef.current === AITaskSortDirection.Descending && <ArrowDropDownIcon />}
                      {sortFieldRef.current === AiTaskSortType.category &&
                        sortDirectionRef.current === AITaskSortDirection.Ascending && <ArrowDropUpIcon />}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${Styles.CellShowCursor} ${Styles.CellWidthFix}`}
                      onClick={() => handleSort(AiTaskSortType.subCategory)}
                    >
                      {t('subcategory')}
                      {sortFieldRef.current === AiTaskSortType.subCategory &&
                        sortDirectionRef.current === AITaskSortDirection.Descending && <ArrowDropDownIcon />}
                      {sortFieldRef.current === AiTaskSortType.subCategory &&
                        sortDirectionRef.current === AITaskSortDirection.Ascending && <ArrowDropUpIcon />}
                    </StyledTableCell>
                    <StyledTableCell className={Styles.CellShowCursor} onClick={() => handleSort(AiTaskSortType.name)}>
                      {t('nameCol')}
                      {sortFieldRef.current === AiTaskSortType.name &&
                        sortDirectionRef.current === AITaskSortDirection.Descending && <ArrowDropDownIcon />}
                      {sortFieldRef.current === AiTaskSortType.name &&
                        sortDirectionRef.current === AITaskSortDirection.Ascending && <ArrowDropUpIcon />}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${Styles.CellShowCursor} ${Styles.CellWidthMax}`}
                      onClick={() => handleSort(AiTaskSortType.instructions)}
                    >
                      {t('instructionsTranslation')}
                      {sortFieldRef.current === AiTaskSortType.instructions &&
                        sortDirectionRef.current === AITaskSortDirection.Descending && <ArrowDropDownIcon />}
                      {sortFieldRef.current === AiTaskSortType.instructions &&
                        sortDirectionRef.current === AITaskSortDirection.Ascending && <ArrowDropUpIcon />}
                    </StyledTableCell>
                    <StyledTableCell
                      className={Styles.CellShowCursor}
                      onClick={() => handleSort(AiTaskSortType.actions)}
                    >
                      {t('answerTranslation')}
                      {sortFieldRef.current === AiTaskSortType.actions &&
                        sortDirectionRef.current === AITaskSortDirection.Descending && <ArrowDropDownIcon />}
                      {sortFieldRef.current === AiTaskSortType.actions &&
                        sortDirectionRef.current === AITaskSortDirection.Ascending && <ArrowDropUpIcon />}
                    </StyledTableCell>
                    <StyledTableCell> {t('workflowSteps')}</StyledTableCell>
                    <StyledTableCell className={Styles.CellWidthFix}> </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getCurrentTasks()?.map((task, index) => {
                    let active = task.active ? 1 : 0;
                    return (
                      <TableRow
                        className={active ? Styles.ContainerRowActive : Styles.ContainerRowInactive}
                        hover
                        key={task?._id}
                      >
                        <StyledTableCell>
                          <Checkbox checked={checkedTasks[index]} onChange={(e) => handleCheckbox(e, index)}></Checkbox>
                        </StyledTableCell>
                        <StyledTableCell>{task.category}</StyledTableCell>
                        <StyledTableCell>{task.subCategory}</StyledTableCell>
                        <StyledTableCell>{task.name}</StyledTableCell>

                        <StyledTableCell className={Styles.CellInstruction}>
                          {/* every task trigger will have the same instructions field for now */}
                          {task?.instructions || ''}
                        </StyledTableCell>
                        <StyledTableCell>
                          {task?.actions?.map((action, index) => {
                            return (
                              <p key={index}>
                                {action.answer?.length > 100 ? action.answer.substring(0, 100) + ' ...' : action.answer}
                              </p>
                            );
                          })}
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className={Styles.ContainerRowStepView}>
                            {task.actions?.length || 0}
                            {task.actions?.length > 0 && (
                              <Tooltip title={t('viewStepsTooltip')} placement="left">
                                <VisibilityOutlinedIcon
                                  className={Styles.IconBorderColorOutlined}
                                  onClick={() => handleViewSteps(task)}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Tooltip
                            title={t('editAITasksDetails')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}
                          >
                            <BorderColorOutlinedIcon
                              className={Styles.IconBorderColorOutlined}
                              onClick={() => handleAiTaskOpen(task)}
                            />
                          </Tooltip>
                          {active ? (
                            <DeleteOutlinedIcon
                              className={Styles.IconDeleteOutlined}
                              onClick={() => handleSwitch(task, active)}
                            />
                          ) : (
                            <RestoreFromTrashOutlinedIcon
                              className={Styles.IconRestoreFromTrashOutlined}
                              onClick={() => handleSwitch(task, active)}
                            />
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </StyledTable>
            )}
          </>
        )}
      </TableContainer>
      <div className={Styles.ContainerFooter}>
        <StandardTablePagination
          steps={[15, 50, 100]}
          rowsPerPage={limitPerPageRef.current}
          page={pageIndexRef.current}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          isMore={getCurrentTasksLength() === limitPerPageRef.current}
        />
      </div>
      {ChildWindowMap()}
    </div>
  );
};

export default AITasksTab;
