import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses
} from "@mui/material";
import React, { useCallback, useState, useRef } from 'react';

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditActionDialog from "./EditActionDialog";
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import StandardTablePagination from './StandardTablePagination';
import TableHeaderBar from './TableHeaderBar';
import downloadActionReport from './DownloadActionReport';
import getImageSource from '../Common/getImageSource';
import axiosCall from '@services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useAbortController from "@common/hooks/useAbortController";
import { APIServiceType } from "@common/APIServiceType";
import useControllerCallback from '@common/hooks/useControllerCallback';

interface RefObject<T> {
  current: T | null;
}

type ActionDataParams = {
  search: string; 
  pageRef: RefObject<number>;
  rowsPerPageRef: RefObject<number>; 
  showInactiveRef: boolean;
}


interface OverflowBoxProps {
  maxWidth: string | number;
}

const OverflowBox = styled(Box)<OverflowBoxProps>(({ maxWidth }) => ({
  display: 'block',
  maxWidth: typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const Actions = (props) => {

  //adjust the styling based on if we are in the console view
  const isConsoleView = window.location.pathname.indexOf('/console') !== -1;

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: isConsoleView && 'var(--gray010)',
      align: 'left',
    },
    [`&.${tableCellClasses.root}`]: {
      borderBottom: isConsoleView && '1px solid var(--gray010)',
      padding: '4px 8px',
    },
  }));

  const { t } = useTranslation();
  const paginationSteps = [15, 50, 100];
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [actionData, setActionData] = useState([]);
  const [search, setSearch] = useState('');
  const pageRef = useRef(0);
  const rowsPerPageRef = useRef(paginationSteps[0]);
  const showInactiveRef = useRef(false);
  
  const [actionCount, setActionCount] = useState(0);
  const [scopeOptions, setScopeOptions] = useState([]);
  const [fieldAttributesData, setFieldAttributesData] = useState([]);
  const [functionsData, setFunctionsData] = useState([]);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [editActionState, setEditActionState] = useState({ edit: false, open: false, data: null });

  const noResults = !loading && !actionData.length;

  const loadData = useCallback(async (controller?: AbortController) => {
    setLoading(true);

    let offset = rowsPerPageRef.current === -1 ? 0 : pageRef.current * rowsPerPageRef.current;
    let limit = rowsPerPageRef.current + 1;
    const data = search ? { search: search } : '';

    let url = `action/db/search?order=asc&orderBy=description&offset=${offset}`;
    if (rowsPerPageRef.current !== -1) {
      url += `&limit=${limit}`;
    }
    // This determines if to show inactive actions or not.
    if (!showInactiveRef.current) {
      url += '&active=true';
    }
    const config = controller ? { signal: controller.signal } : {};
    try {
      const fieldAttributesResult = await axiosCall({ url: `fieldAttribute` }, config);
      
      const actionResults = await axiosCall({
        url,
        method: 'POST',
        data: data,
      }, config);

      if (!actionResults) return;
      const ad = (rowsPerPageRef.current > 0 ? actionResults?.slice(0, rowsPerPageRef.current) : actionResults).map((obj) => {
        const faDesc = fieldAttributesResult?.filter((el) => el?._id === obj.fieldAttribute)[0]?.description ?? '';
        return { ...obj, fieldAttributeDescription: faDesc };
      });
      const a = showInactiveRef.current ? "" : "active=true"
      const actionCountResult = await axiosCall({ url: `action/count?${a}` ,method: 'GET',});
      
      if (actionCountResult) setActionCount(actionCountResult);
      if (fieldAttributesResult) setFieldAttributesData(fieldAttributesResult);

      // get Functions data
      const functionsRequest = {
        service: APIServiceType.cells,
        url: 'functions',
        method: 'GET'
      }
      const functionsDataResult = await axiosCall(functionsRequest);
      if (functionsDataResult) setFunctionsData(functionsDataResult);
      setActionData(ad);
      setIsMoreDataAvailable(rowsPerPageRef.current > 0 ? actionResults?.length > rowsPerPageRef.current : false);

      // get Scope options
      const scopeResult = await axiosCall({
        url: `action/options/scopes`,
        method: 'GET',
      });
      if (scopeResult) setScopeOptions(scopeResult);
      
    } catch(e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  },[search]);

  const addAction = async (data) => {
    let returnValue = false;
    await axiosCall({
      url: `action`,
      method: 'POST',
      data: data,
    })
      .then(async () => {
        loadData();
        returnValue = true;
      })
      .catch((e) => {
        setErrorMessage(e.response?.data?.message || 'Error');
        returnValue = false;
      })
    return returnValue;
  };

  const editAction = async (data) => {
    const { id, ...sendData } = data;
    let returnValue = false;
    await axiosCall({
      url: `action/${id}`,
      method: 'PUT',
      data: sendData,
    })
      .then(async () => {
        loadData();
        returnValue = true;
      })
      .catch((e) => {
        setErrorMessage(e.response?.data?.message || 'Error');
        returnValue = false;
      });

    return returnValue;
  };

  const handleReactivate = async (id) => {
    await axiosCall({
      url: `action/${id}`,
      method: 'PUT',
      data: {
        active: true,
      },
    })
      .then(async () => {
        loadData();
      })
      .catch((e) => {
        console.log(`Error reactivating user ${id}`, e);
      });
  };

  const handleAddAction = () => setEditActionState({ edit: false, open: true, data: null });
  const handleDeleteAction = async (id) => {
    try {
      const result = await axiosCall({
        url: `action/${id}`,
        method: 'DELETE',
      });
      if (result) {
        loadData();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditAction = (data) => setEditActionState({ edit: true, open: true, data });
  const handleEditActionClose = () => {
    setEditActionState({ edit: false, open: false, data: null });
    setErrorMessage('');
  };

  const handleEditActionSubmit = async (actionData) => {
    if (await editAction(actionData)) {
      setEditActionState({ edit: false, open: false, data: null });
      setErrorMessage('');
    }
  };

  const handleAddActionSubmit = async (actionData) => {
    console.log('test');
    if (await addAction(actionData)) {
      setEditActionState({ edit: false, open: false, data: null });
      setErrorMessage('');
    }
  };

  const handleShowInactive = () => {
    showInactiveRef.current = !showInactiveRef.current;
    pageRef.current = 0;
    loadData();
  }

  const handleChangePage = (_, newPage) => {
    pageRef.current = newPage;
    loadData();
  };

  const handleChangeRowsPerPage = (e) => {
    rowsPerPageRef.current = e.target.value;
    pageRef.current = 0;
    loadData();
  };

  const handleSearchChange = (newSearchValue: string) => {
    // We want to reset to the first page when searching so we don't miss any documents 
    // (for example, we don't want to begin our search on the second page 
    // as we would miss those on the first)
    pageRef.current = 0;
    // Now we can update the search value
    setSearch(newSearchValue);
  };

  const handleDownloadActionReport = async () => {
    try {
      const result = await axiosCall({ url: `action/?order=asc&orderBy=name` });
      
      if (!result) return;
      const ad = result?.map((obj) => {
        const faDesc = fieldAttributesData?.filter((el) => el?._id === obj.fieldAttribute)[0]?.description ??
          '';
        return { ...obj, fieldAttributeDescription: faDesc };
      });

      downloadActionReport(ad);
    } catch (error) {
      console.log(error);
    }
  };

  // load initial data on mount
  useControllerCallback(loadData);

  return (
    <Box display="flex" flexDirection="column" height={isConsoleView ? "calc(100vh - 175px)": "50vh"} padding={isConsoleView && 1}>
      <TableHeaderBar
        onAdd={handleAddAction}
        setSearch={handleSearchChange}
        onRefresh={loadData}
        onExport={handleDownloadActionReport}
        addLabel={t('addAction')}
        searchValue={search}
        showInactiveClick={handleShowInactive}
        uploadIcon={false}
        isActive={!showInactiveRef.current}
      />
      <TableContainer sx={{ border: isConsoleView && '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="50px"></StyledTableCell>
                    <StyledTableCell width="100px">{t('typeCol')}</StyledTableCell>
                    <StyledTableCell width="150px">{t('fieldNameCol')}</StyledTableCell>
                    {isConsoleView && <StyledTableCell width="150px">{t('displayNameCol')}</StyledTableCell>}
                    {isConsoleView && <StyledTableCell width="150px">{t('fieldAttributeCol')}</StyledTableCell>}
                    {isConsoleView && <StyledTableCell width="100px">{t('fieldQidCol')}</StyledTableCell>}
                    {isConsoleView && <StyledTableCell sx={{ minWidth: "100px" }}>{t('defaultQidOptionCol')}</StyledTableCell>}
                    {isConsoleView && <StyledTableCell width="100px">{t('fieldEndpointCol')}</StyledTableCell>}
                    {isConsoleView && <StyledTableCell width="100px">{t('descriptionCol')}</StyledTableCell>}
                    <StyledTableCell width="95px">{t('messageCol')}</StyledTableCell>
                    {isConsoleView && <StyledTableCell width="80px">{t('aiVisibleCol')}</StyledTableCell>}
                    {isConsoleView && <StyledTableCell width="80px">{t('operatorVisibleCol')}</StyledTableCell>}
                    {isConsoleView && <StyledTableCell width="80px">{t('userVisibleCol')}</StyledTableCell>}
                    <StyledTableCell width="90px">{t('actionsCol')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actionData?.map((action) => {
                    const active = action.active ? 1 : 0;
                    return (
                      <TableRow
                        sx={{ backgroundColor: active ? isConsoleView && 'var(--gray000)' : isConsoleView ?'var(--peach030)' : 'var(--red600)' }}
                        data-id={action?._id}
                        hover
                        key={action?._id}>
                        <StyledTableCell>
                          {action.actionIcon && (
                            <Box
                              component="img"
                              style={{ filter: 'grayscale(100%)' }}
                              width="20px"
                              height="20px"
                              src={getImageSource(action.actionIcon)}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell>{action.type}</StyledTableCell>
                        <StyledTableCell>{action.fieldName}</StyledTableCell>
                        {isConsoleView && <StyledTableCell>{action.displayName}</StyledTableCell>}
                        {isConsoleView && <StyledTableCell>{action.fieldAttributeDescription}</StyledTableCell>}
                        {isConsoleView &&<StyledTableCell>
                          <OverflowBox maxWidth="150px">{action.fieldQid}</OverflowBox>
                        </StyledTableCell>}
                        {isConsoleView &&<StyledTableCell>
                          <OverflowBox maxWidth="150px">{action.defaultQidOption}</OverflowBox>
                        </StyledTableCell>}
                        {isConsoleView && <StyledTableCell>
                          <OverflowBox maxWidth="250px">{action.fieldEndpoint}</OverflowBox>
                        </StyledTableCell>}
                        {isConsoleView && <StyledTableCell>
                          <OverflowBox maxWidth="250px">{action.description}</OverflowBox>
                        </StyledTableCell>}
                        <StyledTableCell>
                          <OverflowBox maxWidth="250px">{action.message}</OverflowBox>
                        </StyledTableCell>
                        {isConsoleView && <StyledTableCell>
                          <OverflowBox maxWidth="250px">{action.visibility.ai ? t('yes') : t('no')}</OverflowBox>
                        </StyledTableCell>}
                        {isConsoleView &&<StyledTableCell>
                          <OverflowBox maxWidth="250px">{action.visibility.operator ? t('yes') : t('no')}</OverflowBox>
                        </StyledTableCell>}
                        {isConsoleView && <StyledTableCell>
                          <OverflowBox maxWidth="250px">{action.visibility.user ? t('yes') : t('no')}</OverflowBox>
                        </StyledTableCell>}
                        <StyledTableCell>
                          <Tooltip
                            title={t('editAction')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <BorderColorOutlinedIcon
                              onClick={() => handleEditAction(action)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '15px', color: 'var(--blue210)' }}
                            />
                          </Tooltip>
                          {active ? (
                            <Tooltip
                              title={t('deleteAction')}
                              placement="top-start"
                              enterDelay={1000}
                              enterNextDelay={1000}>
                              <DeleteOutlinedIcon
                                onClick={() => handleDeleteAction(action?._id)}
                                fontSize="small"
                                sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t('reactivateAction')}
                              placement="top-start"
                              enterDelay={1000}
                              enterNextDelay={1000}>
                              <RestoreFromTrashOutlinedIcon
                                onClick={() => handleReactivate(action?._id)}
                                fontSize="small"
                                sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                              />
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      dataSize={search ? actionData.length : actionCount}
                      steps={paginationSteps}
                      rowsPerPage={rowsPerPageRef.current}
                      page={pageRef.current}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </>
        )}
      </TableContainer>
      {editActionState.open && (
        <EditActionDialog
          edit={editActionState.edit}
          open={editActionState.open}
          action={editActionState.data}
          scopeOptions={scopeOptions}
          error={errorMessage}
          fieldAttributes={fieldAttributesData}
          functions={functionsData}
          onClose={handleEditActionClose}
          onSubmit={handleEditActionSubmit}
          onAdd={handleAddActionSubmit}
        />
      )}
    </Box>
  );
}

export default Actions;