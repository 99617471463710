import React from 'react';
import { Snackbar } from '@mui/material';
import Banner from '@components/PullCreditMenu/Banner';
import Form from '@components/PullCreditMenu/Form';
import PullCreditMenuStyles from '@styles/PullCreditMenu.module.css';

export interface Option {
  title: string;
  value: any;
}

interface Props {
  togglePullCreditMenu: () => void;
  setActionListSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Gets the class name of html that has disabled or missing flags. If the value
 * disabled that css is used of the missing css as if its disabled it cannot
 * be missing.
 */
export function getClassName(classNames: [string], disabled?: boolean, missing?: boolean): string {
  if (disabled) {
    classNames.push(PullCreditMenuStyles.disabled);
  } else if (missing) {
    classNames.push(PullCreditMenuStyles.missing);
  }

  return classNames.join(' ');
}

/**
 * Exported component.
 */
export default function PullCreditMenu(props: Props) {
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  function handleClose() {
    setSnackbarMessage('');
  }

  return (
    <div className={PullCreditMenuStyles.overlay}>
      <div className={PullCreditMenuStyles.menu}>
        <Banner togglePullCreditMenu={props.togglePullCreditMenu} />
        <Form
          setSnackbarMessage={setSnackbarMessage}
          setActionListSnackbarMessage={props.setActionListSnackbarMessage}
          togglePullCreditMenu={props.togglePullCreditMenu}
        />
      </div>
      <Snackbar open={!!snackbarMessage} message={snackbarMessage} autoHideDuration={6000} onClose={handleClose} />
    </div>
  );
}
