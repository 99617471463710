import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box, styled } from '@mui/system';
import BCERTA from '../Forms/BCERTA';
import PDFSign from '../Forms/PDFSign';
import EConsent from '../Forms/EConsent';
import CreateSignatureDesktop from '../Forms/CreateSignatureDesktop';
import CreateSignatureMobile from '../Forms/CreateSignatureMobile';
import ESignSliderElement from './ESignSliderElement';
import ScrollBox from './ScrollBox';
import { MorphType } from '@common/MorphType';
import { FormContext } from '@contexts/FormContext';
import { getBrowserType } from '@common/EngageUtils';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import useAbortController from '@common/hooks/useAbortController';
import useInitialMount from '@common/hooks/useInitialMount';
import {
  FormBodyType,
  FormBodyScrollStateType,
  FormBodySizeType,
  EsignFormType,
  FormTypeDetector,
  ElectronicSignatureEventType,
  FormClickEventType,
  EsignActionStateType,
} from '../Forms/commonForms';
import axiosCall from '../Services/axios';
import eventBus from '../Common/eventBus';
import { Timeout } from 'react-number-format/types/types';
import ElectronicSignatureUser from './ElectronicSignatureUser';
import ElectronicSignatureOperator from './ElectronicSignatureOperator';
import { useUserContext } from '@contexts/user';

// left and right side border pixel size has to be substracted from parent component dimention
// in order to make slider work perfectly
const divWidthOffset = 4;
const divHeightOffset = 72;
const minBodyHeight = 35;
const pageActionTolerance = 5;

enum PageActionType {
  Next,
  Prev,
}

const DocumentReader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDesktop' && prop !== 'isCreateSignatureMobile',
})<any>(
  ({ height, isCreateSignatureMobile }) => `
  position: relative;
  width: ${isCreateSignatureMobile ? '100%' : '95%'};
  height: ${height}px;
  overflow: visible;
  align-items: center;
  margin: 0 auto 0 auto;
  touchAction: none;
  transition: 0.3s;

  padding-top: ${isCreateSignatureMobile ? '0' : '15px'};
  padding-bottom: 10px;
  border-bottom: 2px solid var(--orange700);
  border-left: 2px solid var(--orange700);
  border-right: 2px solid var(--orange700);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background: var(--gray000);
`
);

const ElectronicSignatureWrapper = (props) => {
  const { errand, isDesktop, setValue } = props;

  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const { isOperator } = useUserContext();

  const primaryParticipantData = errandContext.getPrimaryParticipantFullName();
  const firstName = primaryParticipantData.userData.firstname;
  const lastName = primaryParticipantData.userData.lastname;
  const formName = FormTypeDetector(errand.name);

  const documentReaderRef = useRef(null);
  const documentRef = useRef(null);
  const signatureMapRef = useRef([]);

  // Scroll debounce timer
  const scrollDebounceTimer = useRef<Timeout>();

  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [height, setHeight] = useState<number>(minBodyHeight);
  const [formData, setFormData] = useState(null);
  const [mainSignatureInserted, setMainSignatureInserted] = useState<boolean>(false);

  const abortController = useAbortController();

  // EConsent Radio Button state
  const [eConsentRadioButtonState, setEConsentRadioButtonState] = useState<string>(null);

  const [PDFData, setPDFData ] = useState(null);
  const [loadingPdf, setLoadingPDF] = useState(false);

  const [formMetaData, setFormMetaData] = useState({
    purpose10_11_14: null,
    refinanceLoan: null,
    nonRetailLoan: null,
    correspondentLoan: null,
    allInitialsSigned: null,
    signedImageId: null,
    docStatus: null,
  });

  const [bcertaData, setBcertaData] = useState({
    initials: {
      auth1: {
        signed: false,
        signedDate: null,
      },
      auth2: {
        signed: false,
        signedDate: null,
      },
      auth3: {
        signed: false,
        signedDate: null,
      },
      auth4: {
        signed: false,
        signedDate: null,
      },
      auth5: {
        signed: false,
        signedDate: null,
      },
      auth6: {
        signed: false,
        signedDate: null,
      },
      auth7: {
        signed: false,
        signedDate: null,
      },
      auth8: {
        signed: false,
        signedDate: null,
      },
      auth9: {
        signed: false,
        signedDate: null,
      },
      auth10: {
        signed: false,
        signedDate: null,
      },
      auth11: {
        signed: false,
        signedDate: null,
      },
      auth12: {
        signed: false,
        signedDate: null,
      },
      auth13: {
        signed: false,
        signedDate: null,
      },
      auth14: {
        signed: false,
        signedDate: null,
      },
      auth15: {
        signed: false,
        signedDate: null,
      },
      auth16: {
        signed: false,
        signedDate: null,
      },
      auth17: {
        signed: false,
        signedDate: null,
      },
    },
  });

  // This will be used for our customized pagination and it paginates based on parent div height.
  // It will be further explained in <ESignSliderElement />
  // Change parameter from left --> top and change 'top: 0,' to 'top,' and 'left,' to 'left: 0'
  // if vertical scrolling is preferred
  const handleCustomScroll = (input: number): void => {
    documentRef.current.scrollTo({
      top: input,
      left: 0,
      behavior: 'smooth',
    });
  };

  const findGapToInputField = (type: PageActionType): number => {
    // get document reader dimention from component id
    const documentReadDimention = documentRef.current.getBoundingClientRect();

    // calculate middle point(row) of document reader
    const documentReaderMiddle = (documentReadDimention.top + documentReadDimention.bottom) / 2;

    let gap = type === PageActionType.Next ? Infinity : -Infinity;

    // find closest signature id and index
    for (let i = 0; i < signatureMapRef.current.length; i++) {
      const signatureDimention = document.getElementById(signatureMapRef.current[i]).getBoundingClientRect();
      const signatureDimentionMiddle = (signatureDimention.bottom + signatureDimention.top) / 2;

      const tempGap = signatureDimentionMiddle - documentReaderMiddle;

      if (type === PageActionType.Next && tempGap < gap && tempGap > pageActionTolerance) gap = tempGap;
      if (type === PageActionType.Prev && tempGap > gap && tempGap < -pageActionTolerance) gap = tempGap;
    }

    return gap;
  };

  const findGapToPage = (type: PageActionType): number => {
    const divHeight = height - divHeightOffset;

    let currentPage =
      type === PageActionType.Next ? Math.ceil(scrollPosition / divHeight) : Math.floor(scrollPosition / divHeight);

    let gap = currentPage * divHeight - scrollPosition;

    if (type === PageActionType.Next && gap < pageActionTolerance) gap += divHeight;
    if (type === PageActionType.Prev && gap > -pageActionTolerance) gap -= divHeight;

    return gap;
  };

  const findGapToTargetInputField = (targetId: string) => {
    // calculate middle point(row) of document reader
    const documentReadDimention = documentRef.current.getBoundingClientRect();
    const documentReaderMiddle = (documentReadDimention.top + documentReadDimention.bottom) / 2;
    const signatureDimention = document.getElementById(targetId).getBoundingClientRect();

    const signatureDimentionMiddle = (signatureDimention.bottom + signatureDimention.top) / 2;

    return signatureDimentionMiddle - documentReaderMiddle;
  };

  const formNavScrollWrapper = (type: PageActionType, gapToTarget: number): void => {
    const divHeight = height - divHeightOffset;

    if (
      (type === PageActionType.Next && gapToTarget < divHeight) ||
      (type === PageActionType.Prev && gapToTarget > -divHeight)
      ) {
      handleCustomScroll(documentRef.current.scrollTop + gapToTarget);
    } else {
      let gapToPage = findGapToPage(type);
      // Icy: To fix bug where 'next' button does not work on initial load of form.
      if (type === PageActionType.Next && gapToPage < 0) {
        gapToPage *= -1
      }
      handleCustomScroll(documentRef.current.scrollTop + gapToPage);
    }
  };

  /**
   * Handler for when Input Field is filled, scroll to next input field
   */
  const formNavInsertSignatureHandler = (targetId: string) => {
    const gapToTargetInputField = findGapToTargetInputField(targetId);

    formNavScrollWrapper(PageActionType.Next, gapToTargetInputField);
  };

  const handlePDFNavigation = (direction) => {
    if (direction === PageActionType.Next){
      const boundedPage = Math.min(Math.max(1, errandContext.formCurrentPage + 1), Math.max(errandContext.formTotalPages,1));
      console.log('next page is', boundedPage);
      console.log('total pages are', errandContext.formTotalPages)
      errandContext.setFormCurrentPage(boundedPage);
    }
    if (direction === PageActionType.Prev){
      console.log('prev clicked')
      const boundedPage = Math.min(Math.max(1, errandContext.formCurrentPage - 1), Math.max(errandContext.formTotalPages,1));
      errandContext.setFormCurrentPage(boundedPage);
    }
  }

  /**
   * Handler for morphedFooter Previous Button
   */
  const formNavPrevHandler = () => {
    
    if (FormTypeDetector(errand.name) === EsignFormType.AISIGN){
      console.log('prev clicked')
      handlePDFNavigation(PageActionType.Prev);
      return;
    }

    const gapToInputField = findGapToInputField(PageActionType.Prev);
    formNavScrollWrapper(PageActionType.Prev, gapToInputField);
  };

  /**
   * handler for morphedFooter Next Button
   */
  const formNavNextHandler = () => {
    errandContext.setFormBodyScrollState(FormBodyScrollStateType.Middle);
    // Forms of type PDF will have TRUE multiple pages. If we are not at the end of the PDF we should not show FormBodyScrollStateType.end
    if (FormTypeDetector(errand.name) === EsignFormType.AISIGN){
      console.log('next clicked')
      handlePDFNavigation(PageActionType.Next);
      return;
    }

    const gapToInputField = findGapToInputField(PageActionType.Next);

    formNavScrollWrapper(PageActionType.Next, gapToInputField);
  };

  // Grabs timezone from client
  const timeZoneGrabber = () => {
    const currentDate = new Date(Date.now());
    const dateString = currentDate.toString();

    let timeZone = dateString.split('(')[1];
    timeZone = timeZone.split(')')[0];
    return timeZone;
  };

  // Grabs browser type from url
  const browserTypeGrabber = () => {
    const result = getBrowserType().name;

    return result;
  };

  const mainSignatureInsertedEventHandler = () => {
    setMainSignatureInserted(true);
    errandContext.newFormEvent(ElectronicSignatureEventType.ReadyToSendDocument);
  };

  const loadAISignData = async (templateId) => {
    //make axioscall to retrieve the template.
    setLoadingPDF(true);
    const response = await axiosCall({
      url: `ai-sign/forms`,
      method: 'POST',
      //passing in the chat var lets us query for a saved document.
      data: { templateId, chat: errandContext.errandId}
    });
    //first thing we will guide the user to the insert signature morph.
    errandContext.setMorphType(MorphType.FormInsertSignature);
    //it will be 'response' if the user has saved a form previously. [0] if it's directly from ai-sign
    const data = response.templates?.[0] || response
    if (data.submitted){
      errandContext.newFormEvent(ElectronicSignatureEventType.GoToInsertSignatureView)
      errandContext.setEsignActionState(EsignActionStateType.Signed);
    }
    //populate the chat input with the users first name for easy signature generation & customization.
    if (firstName){
      if (lastName){
        setValue(firstName + ' ' + lastName)
      } else {
        setValue(firstName);
      }
    }
    setPDFData(data);
    setLoadingPDF(false);
  }

  const abortControllerWrapper = (): { signal: any } => {
    // If it's aborted ---> created new abort controller
    // This particular scenario happens with react strict mode
    if (abortController.get().signal.aborted) {
      abortController.set(new AbortController());
    }

    const signal = abortController.get().signal;

    return { signal };
  };

  /**
   * depending on scroll position, set scroll state
   */
  const scrollStateHandle = (position: number) => {
    if (FormTypeDetector(errand.name) === EsignFormType.AISIGN){
      return;
    }
    const divHeight = documentReaderRef.current?.getBoundingClientRect().height - divHeightOffset;
    const docHeight = documentRef.current?.children[0]?.getBoundingClientRect().height;

    // last page won't fit in 1 whole pagination
    if (position > docHeight - divHeight) {
      errandContext.setFormBodyScrollState(FormBodyScrollStateType.End);
    } else if (position === 0) {
      if (formName === EsignFormType.BCERTA) {
        // if it's BCERTA show edit signature
        errandContext.setFormBodyScrollState(FormBodyScrollStateType.StartEditSignature);
      } else {
        // Default Start Welcome button
        errandContext.setFormBodyScrollState(FormBodyScrollStateType.Start);
      }
    } else {
      errandContext.setFormBodyScrollState(FormBodyScrollStateType.Middle);
    }
  };

  const debouncedScrollHandle = (scrollTop) => {
    clearTimeout(scrollDebounceTimer.current);

    // debounced scroll handle
    scrollDebounceTimer.current = setTimeout(() => {
      scrollStateHandle(scrollTop);
      setScrollPosition(scrollTop);
    }, 200);
  };

  const handleResize = () => {
    const footerHeight = errandContext.footerRef.current?.clientHeight;
    const titleHeight = errandContext.titleContainerRef.current?.clientHeight;
    const viewHeight = errandContext.boundaryRef.current?.clientHeight;

    const availableHeight = viewHeight - footerHeight - titleHeight;

    let newHeight;

    if (errandContext.formBodySize === FormBodySizeType.Small) {
      newHeight = minBodyHeight;
    } else if (errandContext.formBodySize === FormBodySizeType.Full) {
      // its NaN when there is no footer or title on conversation
      newHeight = isNaN(availableHeight) ? viewHeight : availableHeight - minBodyHeight * 0.9;
    }

    setHeight(newHeight);
  };

  const signFormBCERTA = async () => {
    try {
      errandContext.setEsignActionState(EsignActionStateType.Signing);

      const requestData = {
        url: `chat/${errandContext.errandId}/signdocument`,
        method: 'post',
        data: {
          formName: EsignFormType.BCERTA,
          trueTimeZone: timeZoneGrabber(),
          trueBrowser: browserTypeGrabber(),
          initialImageData: errandContext.wetInitial,
          signatureImageData: errandContext.wetSignature,
          borrowerName: `${firstName} ${lastName}`,
          initialsInfo: {
            bcertaData,
          },
        },
      };

      const signal = abortControllerWrapper();

      const response = await axiosCall(requestData, signal);

      const { purpose10_11_14, refinanceLoan, nonRetailLoan, correspondentLoan, allInitialsSigned } =
        response.initialsInfo;

      const { signedImageId, docStatus } = response.documentData;
      const grabbedFormMetaData = {
        purpose10_11_14,
        refinanceLoan,
        nonRetailLoan,
        correspondentLoan,
        allInitialsSigned,
        signedImageId,
        docStatus,
      };
      setFormMetaData(grabbedFormMetaData);
      errandContext.setEsignActionState(EsignActionStateType.Signed);
    } catch (error) {
      errandContext.setEsignActionState(EsignActionStateType.Failed);
      console.error('Error signing BCERTA', error);
    }
  };

  const signFormECONSENT = async () => {
    try {
      // Don't allow EConsent re-sign
      if (errandContext.esignActionState !== EsignActionStateType.Unsigned) {
        return;
      }

      errandContext.setEsignActionState(EsignActionStateType.Signing);

      const eConsentData = {
        url: `chat/${errandContext.errandId}/signdocument`,
        method: 'post',
        data: {
          formName: EsignFormType.ECONSENT_2,
          trueTimeZone: timeZoneGrabber(),
          trueBrowser: browserTypeGrabber(),
          borrowerName: `${firstName} ${lastName}`,
          optionSelected: eConsentRadioButtonState === 't',
        },
      };

      const signal = abortControllerWrapper();

      const response = await axiosCall(eConsentData, signal);

      setFormData(response.documentData);
      errandContext.setEsignActionState(EsignActionStateType.Signed);
    } catch (error) {
      errandContext.setEsignActionState(EsignActionStateType.Failed);
      console.error('Error signing ECONSENT', error);
    }
  };

  const downloadFormECONSENT = async () => {
    try {
      errandContext.setEsignActionState(EsignActionStateType.Downloading);

      const eConsentData = {
        url: `chat/${errandContext.errandId}/getfinisheddocument`,
        method: 'post',
        data: {
          signedImageId: formData.signedImageId,
          formName: EsignFormType.ECONSENT_3,
          borrowerName: `${firstName} ${lastName}`,
        },
      };

      const signal = abortControllerWrapper().signal;

      const response = await axiosCall(eConsentData, { responseType: 'arraybuffer', signal });

      const blob = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ESIGN Consent -  ${firstName} ${lastName}.pdf`);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
      errandContext.setEsignActionState(EsignActionStateType.Signed);
    } catch (error) {
      errandContext.setEsignActionState(EsignActionStateType.Failed);
      console.error('Error downloading ECONSENT data', error);
    }
  };

  const downloadFormBCERTA = async () => {
    try {
      // disable download button
      errandContext.setEsignActionState(EsignActionStateType.Downloading);

      const bcertaData = {
        url: `chat/${errandContext.errandId}/getfinisheddocument`,
        method: 'post',
        data: {
          signedImageId: formMetaData.signedImageId,
          formName: EsignFormType.BCERTA,
          borrowerName: `${firstName} ${lastName}`,
        },
      };

      const signal = abortControllerWrapper().signal;

      const response = await axiosCall(bcertaData, { responseType: 'arraybuffer', signal });

      const blob = new Blob([response], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `BCERTA -  ${firstName} ${lastName}.pdf`);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);
      errandContext.setEsignActionState(EsignActionStateType.Signed);
    } catch (error) {
      errandContext.setEsignActionState(EsignActionStateType.Failed);
      console.error('Error downloading BCERTA data', error);
    }
  };

  const downloadForm = () => {
    if (formName === EsignFormType.ECONSENT) downloadFormECONSENT();
    else if (formName === EsignFormType.BCERTA) downloadFormBCERTA();
    else console.error('Wrong formName', formName);
  };

  const signForm = () => {
    if (formName === EsignFormType.ECONSENT) signFormECONSENT();
    else if (formName === EsignFormType.BCERTA) signFormBCERTA();
    else console.error('Wrong formName', formName);
  };

  const handleResizeWrapper = useCallback(
    handleResize,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errandContext.formBodySize]
  );

  useWindowDimensions(handleResizeWrapper);

  useEffect(() => {
    if (formName === EsignFormType.AISIGN) loadAISignData(errand.name);
  },[]);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandContext.formBodySize, errandContext.morphType, rootContext.selectedIndex]);

  useEffect(() => {
    // subscribe event bus
    eventBus.on(FormClickEventType.NavPrev, formNavPrevHandler);
    eventBus.on(FormClickEventType.NavNext, formNavNextHandler);

    return () => {
      eventBus.remove(FormClickEventType.NavPrev, formNavPrevHandler);
      eventBus.remove(FormClickEventType.NavNext, formNavNextHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition, errandContext?.formCurrentPage, errandContext.formTotalPages]);

  useEffect(() => {
    // subscribe event bus
    eventBus.on(FormClickEventType.FormDownload, downloadForm);
    eventBus.on(FormClickEventType.FormSign, signForm);

    return () => {
      eventBus.remove(FormClickEventType.FormDownload, downloadForm);
      eventBus.remove(FormClickEventType.FormSign, signForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bcertaData,
    formMetaData,
    eConsentRadioButtonState,
    formData,
    errandContext.esignActionState,
    errandContext.wetInitial,
    errandContext.wetSignature,
  ]);

  // Initial call to sunsoft to get the state of the form
  const tryToUnfocusInputFieldAndSetScrollState = useCallback(() => {
    // Try to unfocus chat input field
    errandContext.footerInputRef.current?.blur();

    // Set scroll State
    scrollStateHandle(0);

    errandContext.newFormEvent(ElectronicSignatureEventType.FormJustOpened);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInitialMount(tryToUnfocusInputFieldAndSetScrollState);

  const RenderFormMainBodyContent = () => {
    // if BodySize is small don't render any content
    if (errandContext.formBodySize !== FormBodySizeType.Small) {
      switch (errandContext.formBody) {
        case FormBodyType.CreateSignatureMobile:
          return <CreateSignatureMobile />;
        case FormBodyType.CreateSignatureDesktop:
          return <CreateSignatureDesktop />;
        case FormBodyType.InsertSignatureReadyToSendDocument:
        case FormBodyType.InsertSignatureDefault:
          if (formName === EsignFormType.ECONSENT) return <EConsent />;
          if (formName === EsignFormType.BCERTA)
            return (
              <BCERTA
                bcertaData={bcertaData}
                setBcertaData={setBcertaData}
                formMetaData={formMetaData}
                setFormMetaData={setFormMetaData}
                formNavInsertSignatureHandler={formNavInsertSignatureHandler}
              />
            );
          if (formName === EsignFormType.AISIGN)
            return (
              <PDFSign
                errandName={errand.name}
                loadingPdf={loadingPdf}
                templateId={errand.name}
                PDFData={PDFData}
                setPDFData={setPDFData}
                parentId={errand.parentId}
              />
            );
          break;
      }
    }

    return <></>;
  };

  const EsignBody = (): JSX.Element => {
    return (
      <DocumentReader
        id='documentReader'
        isDesktop={isDesktop}
        ref={documentReaderRef}
        height={
          errandContext.formBody === FormBodyType.CreateSignatureMobile && window.innerWidth < window.innerHeight
            ? height - 50
            : height
        }
        isCreateSignatureMobile={errandContext.formBody === FormBodyType.CreateSignatureMobile}
        width='100%'
        sx={{ zIndex: '1' }}
      >
        <ScrollBox
          ref={documentRef}
          sx={{
            maxHeight: errandContext.formBody === FormBodyType.CreateSignatureMobile ? 'none' : 'calc(100% - 45px)',
            height: errandContext.formBody === FormBodyType.CreateSignatureMobile ? '100%' : 'inherit',
            borderRadius: errandContext.formBody === FormBodyType.CreateSignatureMobile ? '30px' : '0',
            paddingBottom: '5px',
          }}
          onScroll={(e) => debouncedScrollHandle(e.target.scrollTop)}
        >
          {RenderFormMainBodyContent()}
        </ScrollBox>
        { 
        (errandContext.formBody !== FormBodyType.CreateSignatureMobile ||
          (errandContext.formBody === FormBodyType.CreateSignatureMobile &&
            window.innerWidth < window.innerHeight)) && (
          <ESignSliderElement
            formName={errand.name}
            handleCustomScroll={handleCustomScroll}
            divWidthOffset={divWidthOffset}
            divHeightOffset={divHeightOffset}
          />
        )}
      </DocumentReader>
    );
  };

  return (
    <FormContext.Provider
      value={{
        documentReaderRef,
        documentRef,
        signatureMapRef,

        timeZoneGrabber,
        browserTypeGrabber,
        mainSignatureInsertedEventHandler,
        handleCustomScroll,
        abortControllerWrapper,
        EsignBody,

        eConsentRadioButtonState,
        mainSignatureInserted,
        scrollPosition,
        bcertaData,

        setMainSignatureInserted,
        setEConsentRadioButtonState,
        setFormMetaData,
        setFormData,
        setBcertaData,
      }}
    >
      {isOperator ? (
        <ElectronicSignatureOperator></ElectronicSignatureOperator>
      ) : (
        <ElectronicSignatureUser errand={errand}></ElectronicSignatureUser>
      )}
    </FormContext.Provider>
  );
};

export default ElectronicSignatureWrapper;
