import React, { useEffect, useState } from "react";
import eventBus from "@common/eventBus";
import Sanitized from "@components/Sanitized";
import { useTheme, useMediaQuery } from "@mui/material";
import { getUserConsent } from "@storage/userStorage";
import { ExpandAnimationBox } from "./utils";
import { IErrand, IMessage } from "@interfaces/Conversation";

const ConsentPortionStyles = {
    wrappingP: (mediumMediaQueryResult): React.CSSProperties => ({
        padding: '6px 12px 0px',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: `${mediumMediaQueryResult ? '0.9rem' : '0.95rem'}`,
        borderTop: '1px solid var(--orange700)',
        borderRadius: '10px 10px',
        color: '#717171',
        marginTop: '6px',
        lineHeight: '26px'
    })
};

const ConsentContent = (props) => {
    const consentContentRef = React.useRef(null);

    return (
        <div style={ConsentPortionStyles.wrappingP(props.mediumMediaQueryResult)}>
            <span ref={consentContentRef}>
                <Sanitized html={props.html} />
            </span>
        </div>
    )
}

export const getConsentGivenMsgId = () => {
    return localStorage.getItem('consentGivenMsgId');
}

export const saveConsentGivenMsgId = (_id: string) => {
    return localStorage.setItem('consentGivenMsgId', _id);
}

type TConsentContainerProps = {
    // Static args
    html: { accepted: string, initial: string } | null;
    isOperator: boolean;
    renderFinal: boolean; // means if we want to get the final static render. (true -> static, false -> animated)
    errand: IErrand;
    message: IMessage;
    parentMsgType: string;
    // Animated args (if renderFinal is false)
    measuring?: boolean; // means if we want to get UI for the measuring purposes. (renders static version)
    show?: boolean; // controls when to show
    measuredHeight?: number; // provide only if you know the exact height value 
}

// check if the curr msg is the last instance among the given message type.
// ONLY if not given.
export function findLastByMessageType(array: IMessage[], givenMessageType: string) {
    if (!array || !givenMessageType) return null;

    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].messageType === givenMessageType) {
            return array[i]; // Return the message object itself
        }
    }
    return null; // Return null if no match is found
}

export function getFirstMsgWelcomeTypeInChat(msgArray): string | null {
    for (let i = msgArray.length - 1; i >= 0; i--) {
        if (msgArray[i].messageType.toLowerCase().includes('welcome')) {
            return msgArray[i].messageType; // Return the message object itself
        }
    }

    return null;
}

const ConsentContainer = (props: TConsentContainerProps) => {
    const theme = useTheme();
    const mediumMediaQueryResult = useMediaQuery(theme.breakpoints.up('md'))
    const [consentGiven, setConsentGiven] = useState(getUserConsent() === 'true');
    const consentGivenOnCurrMessage = getConsentGivenMsgId() === props?.message?._id; // get from local storage _id and check id to be the curr message ic

    // Business logic for consent content render rule.
    const renderConsentPart =
        props.measuring === true // if measuring, always render
        || consentGivenOnCurrMessage === true // OR if consent given on this message
        // OR if not operator && html exists && last message type of parentMsg type in chat is our current message && consent is NOT yet given.
        || (!props.isOperator
            && props.html !== null
            // check if this message instance is the last one currently in chat.
            && findLastByMessageType(props?.errand?.messages, props?.parentMsgType)?._id === props?.message?._id
            && consentGiven === false); // only of consent is not given

    // Mount/Unmount.
    // Consent Logic Part.
    useEffect(() => {
        const consentGiven = () => {
            // Consent Given
            setConsentGiven(true);
        }
        eventBus.on('consentGiven', consentGiven);
        return () => {
            eventBus.remove('consentGiven', consentGiven);
        }
    }, []);

    // Business logic render check.
    if (!renderConsentPart)
        return null;

    let initialHtml = null;
    let acceptedHtml = null;
    if(props.html) {
        // Extract accepted and initial views from props.
        initialHtml = props.html?.initial;
        acceptedHtml = props.html?.accepted;
    }
    // based on consentGiven and consentGivenOnCurrMessage render the needed portion.
    let html = null;
    if (!consentGiven) {
        html = initialHtml;
    } else {
        // if for measuring, assign accepted view.
        if (props.measuring) {
            html = acceptedHtml;
        }
        // if not for measuring proceed with needed rules
        else {
            // consent given and given on curr message
            if (consentGivenOnCurrMessage) {
                html = acceptedHtml;
            } else {
                html = null;
            }
        }
    }

    // If for measuring OR final render, render static consent content.
    if (props.measuring || props.renderFinal) {
        return (
            <div style={{ width: "100%" }}>
                <ConsentContent mediumMediaQueryResult={mediumMediaQueryResult} html={html} />
            </div>
        )
    }
    // otherwise, render animated one.
    else {
        if (!props.show) {
            return null;
        } else {
            return (
                <ExpandAnimationBox sx={{ width: "100%", overflow: 'hidden' }} height={Math.round(props.measuredHeight)} className={'initialConsentPortion'}>
                    <ConsentContent mediumMediaQueryResult={mediumMediaQueryResult} html={html} />
                </ExpandAnimationBox>
            )
        }
    }
}

export { ConsentContainer };