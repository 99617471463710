import { Button, Dialog, DialogActions, DialogContent, Divider, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import DialogHeader from './DialogHeader';
import axiosCall from '@services/axios';
import { useTranslation } from 'react-i18next';
import { sendAction } from '@common/WorkflowsUtils';
import { IParticipant, IErrand } from '@interfaces/Conversation';
import { AccessType } from '@common/AccessType';

/*
 *  This component is the dialog that is shown to an operator when they click on the
 * name of a user in the console conversation screen. This dialog allows the operator
 * to rename a user. It takes the following props:
 *    - open: boolean whether or not to be open
 *    - participant: the participant object for the participant which this dialog is editing
 *    - onClose: The function to run when this dialog wants to close
 */

const RenameUserDialog = (props: 
  { // Specified the prop types to avoid unnecessary errors due to incorrect use.
    participant: IParticipant; open: boolean; errand: IErrand;
    onClose: () => void; operatorData: { _id: string; }; userId: string; 
    setParticipants: (arg0: (prev: IParticipant[]) => IParticipant[]) => void; 
    setErrands: (arg0: (prev: any) => any) => void; 
  }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const ai = props.errand?.participants?.find((x) => x?.active && x?.ai)?.userData?._id;

  useEffect(() => {
    if (props.participant) {
      setName(`${props.participant?.userData?.firstname} ${props.participant?.userData?.middlename} ${props.participant?.userData?.lastname}`);
    }
  }, [props.participant]);

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('renameUserDialogTitle')} onClose={() => props.onClose()} />
      <DialogContent sx={{ width: '450px' }}>
        {/* Main content */}
        <Stack spacing={2} pt={2}>
          <TextField
            label={t('renameUserDialogName')}
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
          <Divider> {t('renameUserDialogOr')} </Divider>
          <Stack alignItems="center">
            <Button
              sx={{ width: '33%' }}
              variant="contained"
              disabled={ai === undefined}
              onClick={async () => {
                await sendAction("", "fullName", props.participant?.chat, [props.participant?.userData?._id], AccessType.public, props.operatorData !== undefined ? props.operatorData?._id : props.userId, props.operatorData !== undefined, null);
                props.onClose();
              }}>
              {t('renameUserDialogAskButton')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>

      {/* Action buttons */}
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose()}>
          {t('cancelButton')}
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            let names = name.split(' ');
            let data: { firstname?: string, middlename?: string, lastname?: string } = {};

            if (names.length === 0) return;
            if (names.length === 1) {
              data.firstname = names[0];
              data.middlename = '';
              data.lastname = '';
            }
            if (names.length === 2) {
              data.firstname = names[0];
              data.middlename = '';
              data.lastname = names[1];
            }
            if (names.length === 3) {
              data.firstname = names[0];
              data.middlename = names[1];
              data.lastname = names[2];
            }

            let payload = {
              url: `user/${props.participant?.userData?._id}`,
              method: 'put',
              data: data,
            };

            // Make the call and update parents state
            let res = await axiosCall(payload);

            // check if setParticipants exists
            if (props.setParticipants) {
              // if so, update participants directly
              props.setParticipants((prev) => {
                if (!Array.isArray(prev)) return prev;
                // get the corresponding user
                let index = prev.findIndex((e) => e.userData?._id === res?._id);
                // check if the corresponding index was found
                if (index === -1) return prev; 
                // if so, update the participants for the corresponding index
                let update = [...prev];
                update[index].userData = { ...res };
                return update;
              });
            }
            // check if setErrands exists
            if (props.setErrands) {
              // if so, update the participants for the corresponding errand
              props.setErrands((prev) => {
                if (!Array.isArray(prev)) {
                  console.warn('setErrands prev is not an array');
                  prev = [];
                }
                  // get the index for the current errand
                  let errandIndex = prev.findIndex((e) => e._id === props.participant?.chat);
                  // check if the current errand is in the array
                  if (errandIndex === -1) return prev;
                  // if so, update the participants for the corresponding index
                  let participantIndex = prev[errandIndex].participants.findIndex((p) => p.userData?._id === res?._id);
                  if (participantIndex === -1) return prev;
                  prev[errandIndex].participants[participantIndex].userData = { ...res };
                  return [...prev];
              });
            }

            // First clear the current chat
            // navigate('/console', {
            //   state: {
            //     module: 'conversations',
            //     tab: location.state?.tab,
            //     chat: undefined
            //   },
            // });
            // Close the dialog
            props.onClose();
            // Reload the chat, just have to wait long enough for management to realize we are reloading
            // setTimeout(() => navigate('/console', {
            //   state: {
            //     module: 'conversations',
            //     tab: location.state?.tab,
            //     chat: chat
            //   },
            // }), 50);
          }}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameUserDialog;
