import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Email as EmailIcon, Phone as PhoneIcon, SMSIcon } from '../Assets/Icons';
import { styled, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { PatternFormat } from 'react-number-format';
import { StyledInputBase } from '../Styles/ChatInputFieldStyle';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation } from 'react-router-dom';
import axiosCall from '../Services/axios';
import { useTranslation } from 'react-i18next';
import formatPhoneNumber from '../Common/formatPhoneNumber';
import { capitalize }  from '../Common/StringUtils';
import { useQuery } from '@tanstack/react-query';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { ChatType } from '@common/ChatType';
import { useUserContext } from '@contexts/user';

const filter = createFilterOptions();

const CenteredSMSIcon = styled(SMSIcon)(() => ({
  display: 'block',
  margin: 'auto',
  height: 40,
  width: 40,
  padding: 4,
  color: 'var(--blue010)',
}));

const CenteredEmailIcon = styled(EmailIcon)(() => ({
  display: 'block',
  margin: 'auto',
  height: 40,
  width: 40,
  padding: 4,
  color: 'var(--blue010)',
}));

const CenteredPhoneIcon = styled(PhoneIcon)(() => ({
  display: 'block',
  margin: 'auto',
  height: 40,
  width: 40,
  padding: 4,
  color: 'var(--blue010)',
}));

const SMSEmailComposer = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const { firstname, lastname, _id } = useUserContext();
  const [selectedTab, setSelectedTab] = useState('sms');
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [smsTemplates, setSmsTemplates] = useState([]);
  const [isFull, setFull] = useState(false);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [loanNumber, setLoanNumber] = useState(undefined);
  const [recipients, setRecipients] = useState([]);
  const [sending, setSending] = useState(false);

  const emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneValidationRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
  const validateRecipients = (recipients) => {
    if(recipients?.length === 0) { return false; }
    const values = recipients.map((recipient) => {
      let val = (selectedTab === 'sms' && recipient.valuePhone) ? recipient.valuePhone : recipient.value;
      if(!val){ val = recipient.label; }
      return val;
    });
    for (let value of values) {
      if (!value.toLowerCase().match((selectedTab === 'sms') ? phoneValidationRegex : emailValidationRegex)) {
        return false;
      }
    }
    return true;
  }

  const {
    data,
    //isLoading
  } = useQuery([`SMSEmailComposer`], async () => {
    const systemData = await axiosCall({url:`system/data?lender=${theme.system.name}&active=true&fields=message,status,lender,translationEnabled,contacts`});
    const contacts = (systemData) ? systemData.contacts : [];
    return contacts.map((contact) => {
      return { valuePhone: contact.phone, value: contact.email, label: `${contact.name}` };
    });
  });

  const users = (conversation) => {
    // TODO: This should be updated to include the search for 'isPrimary'
    return conversation.participants.filter((u) => u.userType === 'User') || [];
  };

  const sendTemplate = async () => {
    setSending(true);
    // Make the chat that we are invititing them to
    const conv = await axiosCall({
      url: 'chat',
      method: 'post',
      data: {
        participants: [
          {
            userId: _id,
            userType: 'Operator',
          }
        ],
        type: ChatType.Conversation,
        status: 'active'
      },
    });

    //set the context for that chat if a loan number was included.
    // Attach this loan number to the chats context 
    if (loanNumber){
      await axiosCall({url:'context', method: 'post', data : {
        chat: conv._id,
        loanNumber: loanNumber.replaceAll('-','')
      }});
     }

    let workflowResult = {};
    if (selectedTab !== 'sms' && emailTemplates[selectedTemplate]?.workflow){
      workflowResult = await axiosCall({url: `workflow/${emailTemplates[selectedTemplate]?.workflow}`});
    }
    // Hash that chat id
    const hash = await axiosCall({
      url: 'hashkey',
      method: 'post',
      data: {
        parameters: {
          workflow: workflowResult.name,
          chatId: conv?._id
        }
      }
    });

    let sendPayload = async (target) => {
      // Send them the hash in a template
      let payload;
      if (selectedTab === 'sms') {
        // Create payload
        payload = {
          url: 'sms/send',
          method: 'post',
          data: {
            senderId: _id,
            to: target,
            chatId: conv?._id,
            templateId: selectedTemplate === '' ? undefined : smsTemplates[selectedTemplate]?._id,
            templateModelMap: selectedTemplate === '' ? undefined : {
              User: users(conv)[0]?.userData?._id
            },
            templateAddtlVars: selectedTemplate === '' ? undefined : {
              inviteUrl: `${window.location.origin}/invite/${hash.key}`,
              senderName: capitalize(firstname) + ' ' + capitalize(lastname)
            }
          }
        }
        if (selectedTemplate === '') payload.data.message = message;

      } else {
        // Create payload
        payload = {
          url: 'email/send',
          method: 'post',
          data: {
            senderId: _id,
            senderType: 'Operator',
            to: target,
            subject: subject,
            chatId: conv?._id,
            templateId: selectedTemplate === '' ? undefined : emailTemplates[selectedTemplate]?._id,
            templateModelMap: selectedTemplate === '' ? undefined : {
              User: users(conv)[0]?.userData?._id
            },
            templateAddtlVars: selectedTemplate === '' ? undefined : {
              inviteUrl: `${window.location.origin}/invite/${hash.key}`,
              senderName: capitalize(firstname) + ' ' + capitalize(lastname)
            }
          }
        }
        if (selectedTemplate === '') payload.data.message = message;
      }
      try {
        await axiosCall(payload);
      } catch (e) {
        console.warn(`Failed to send to "${target}"`, e);
      }
    }

    for (let recipient of recipients) {
      console.log('Sending to: ', recipient);
      let value = (selectedTab === 'sms' && recipient.valuePhone) ? recipient.valuePhone : recipient.value;
      if(!value){ value = recipient.label; }
      await sendPayload(value);
    }

    // Clear state
    setMessage('');
    setSelectedTemplate('');
    setRecipients([]);
    setSubject('');
    setLoanNumber(undefined);
    setSending(false);
  }

  useEffect(() => {
    /* Run in async so we can await the API */
    (async () => {
      // Only load this data when we first open the conversations tab
      if (location.state?.module === 'conversations' && emailTemplates?.length === 0) {
        const ets = await axiosCall({ url: 'emailTemplate?visible=true&active=true' });
        setEmailTemplates(ets);
        console.log(`Loaded ${ets?.length} email templates`);
      }
      if (location.state?.module === 'conversations' && smsTemplates?.length === 0) {
        const sts = await axiosCall({ url: 'smsTemplate?visible=true&active=true' });
        setSmsTemplates(sts);
        console.log(`Loaded ${sts?.length} SMS templates`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.module]);

  return (
    <Card sx={{ borderRadius: '20px 20px 0 0', border: `1px solid var(--peach900)`, marginTop: '16px', width: '100%' }}>
      <CardContent>
        <Stack spacing={2}>
          {/* Pull out button */}
          <Container align="center">
            <IconButton
              onClick={() => setFull((prev) => !prev)}
              variant="small"
              sx={{ padding: 0 }}>
              {isFull ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
          </Container>

          {!isFull && (
            <Typography variant="caption" align="center">
              {t('smsEmailComposerOpenPrompt')}
            </Typography>
          )}

          {/* Button group across the top */}
          <ButtonGroup size="small" fullWidth>
            <Button
              variant={selectedTab === 'sms' ? 'contained' : 'outlined'}
              sx={{ color: selectedTab === 'sms' ? 'var(--blue010)' : 'var(--peach900)', backgroundColor: selectedTab === 'sms' ? 'var(--peach900)' : 'var(--gray000)' }}
              onClick={async () => {
                setSelectedTab('sms');
                setMessage('');
                setSelectedTemplate('');
                setRecipients([]);
                setSubject('');
                setLoanNumber(undefined);
              }}>
              {t('smsEmailComposerSMS')}
            </Button>
            <Button
              variant={selectedTab === 'email' ? 'contained' : 'outlined'}
              sx={{ color: selectedTab === 'email' ? 'var(--blue010)' : 'var(--peach900)', backgroundColor: selectedTab === 'email' ? 'var(--peach900)' : 'var(--gray000)' }}
              onClick={async () => {
                setSelectedTab('email');
                setMessage('');
                setSelectedTemplate('');
                setRecipients([]);
                setSubject('');
                setLoanNumber(undefined);
              }}>
              {t('smsEmailComposerEmail')}
            </Button>
          </ButtonGroup>

          {/* Content region that hides when not full */}
          {isFull && (
            <>
              {/* SMS/Email region */}
              <Stack
                sx={{ backgroundColor: 'var(--peach900)', borderRadius: '0.4em' }}
                direction="row"
                spacing={1}>
                {selectedTab === 'sms' ? <CenteredPhoneIcon fontSize="medium" color="var(--blue999)" /> : <CenteredEmailIcon fontSize="medium" color="var(--blue999)" />}
                <Box
                  sx={{ backgroundColor: 'var(--gray000)', borderRadius: '0.4em 0 0 0.4em', width: '90%' }}>

                  <Autocomplete
                    size="small"
                    multiple
                    limitTags={1}
                    freeSolo
                    id="multiple-limit-tags"
                    value={recipients}
                    options={data}
                    onChange={(e, value, reason, details) => {
                      setRecipients(value.map((val) => {
                        if (typeof val === 'string') {
                          if (selectedTab === 'sms') {
                            return { valuePhone: val, value: '', label: `${val}` };
                          } else {
                            return { valuePhone: '', value: val, label: `${val}` };
                          }
                        } else {
                          return val;
                        }
                      }));
                    }}
                    getOptionLabel={(option) => {
                      // If the option is custom, set it in the object structure
                      if (typeof option === 'string') {
                        if (selectedTab === 'sms') {
                          option = { valuePhone: option, value: '', label: `` };
                        } else {
                          option = { valuePhone: '', value: option, label: `` };
                        }
                      }

                      if (option.label?.startsWith('<') && option.label?.endsWith('>')) { return `${option.label}`; }
                      if (selectedTab === 'sms') {
                        if(option.label){
                          return `<${formatPhoneNumber(option.label)}>`;
                        } else {
                          return `<${formatPhoneNumber(option.valuePhone)}>`;
                        }
                      } else {
                        if(option.label){
                          return `<${option.label}>`;
                        } else {
                          return `<${option.value}>`;
                        }
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => {
                        return inputValue === option.label;
                      });
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          valuePhone: '',
                          value: '',
                          label: `${inputValue}`,
                        });
                      }
                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select contacts"
                        inputRef={input => {
                          if(input && recipients?.length > 0 && !validateRecipients(recipients)){
                            input.focus();
                          }
                        }}
                        color={(recipients?.length > 0 && !validateRecipients(recipients)) ? 'error' : 'primary'}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />

                </Box>
              </Stack>

              {/* Template selection */}
              <Stack
                sx={{ backgroundColor: 'var(--peach900)', borderRadius: '0.4em' }}
                direction="row"
                spacing={1}>
                <CenteredSMSIcon fontSize="medium" color="var(--blue999)"/>
                <Box
                  sx={{ backgroundColor: 'var(--gray000)', borderRadius: '0.4em 0 0 0.4em', width: '90%' }}>
                  <TextField
                    value={selectedTemplate}
                    onChange={(event) => {
                      let foundIndex = event.target.value;

                      if (foundIndex > -1) {
                        setSelectedTemplate(foundIndex);
                        let msg = selectedTab === "sms" ?
                          smsTemplates[foundIndex].text
                          :
                          emailTemplates[foundIndex].text;
                        let subj = selectedTab === "email" ?
                          emailTemplates[foundIndex].subject
                          :
                          null
                        //set the recipient field to the default value, if there is one that exists.
                        if ((selectedTab === 'email') && (emailTemplates[foundIndex].defaultRecipient)){
                          setRecipients([{"valuePhone": "", value: emailTemplates[foundIndex].defaultRecipient, label: emailTemplates[foundIndex].defaultRecipient}])
                        }
                        setMessage(msg);
                        if (subj){
                          setSubject(subj)
                        }
                      } else {
                        setSelectedTemplate('');
                        setMessage('');
                      }
                    }}
                    select fullWidth size="small"
                    label={t("SMSEmailComposerTemplate")}
                    InputProps={{
                      endAdornment: selectedTemplate !== '' ? 
                        <Stack direction="row" spacing={0.25} sx={{ paddingRight: '24px' }}>
                          <IconButton 
                            onClick={() => { 
                              setSelectedTemplate(''); 
                              setMessage('');
                            }}>
                            <ClearIcon sx={{ height: 15, width: 15, strokeWidth: 2, stroke: 'lightgray' }}/>
                          </IconButton>
                          <Divider orientation="vertical" flexItem />
                        </Stack> : 
                        undefined
                    }}>
                    {selectedTab === 'sms'
                      ? smsTemplates?.length > 0 &&
                      smsTemplates.map((template, index) => (
                        <MenuItem key={index} value={index}>
                          {template.name}
                        </MenuItem>
                      ))
                      : emailTemplates?.length > 0 &&
                      emailTemplates.map((template, index) => (
                        <MenuItem key={index} value={index}>
                          {template.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Box>
              </Stack>

              {/* Optional Loan Number */}
              {
                selectedTab === 'email' && emailTemplates[selectedTemplate]?.name === 'payForAppraisal' && 

                <PatternFormat
                  customInput={StyledInputBase}
                  autoFocus
                  format={'##########-##'}
                  mask='_'
                  onChange={(event) => setLoanNumber(event.target.value)}
                  placeholder={`${t('smlsDialogLoanNumber')}*`}
                  value={loanNumber}
                  />
              }

              {/* Subject */}
              {
                selectedTab === 'email' &&
                <TextField
                  label={t('smsEmailComposerSubject')}
                  size="small"
                  fullWidth
                  onChange={(event) => setSubject(event.target.value)}
                  value={subject}
                />
              }

              {/* Message body */}
              <TextField label={t('smsEmailComposerPrompt')} multiline rows={3} value={message} onChange={(event) => setMessage(event.target.value)} />

              {/* Send button */}
              {
                sending ?
                  <Container sx={{ display: 'flex', justifyContent: 'center', height: 24 }}>
                    <CircularProgress />
                  </Container>
                  :
                  <Button
                    fullWidth
                    onClick={sendTemplate}
                    variant="contained"
                    sx={{ color: 'var(--blue010)' }}
                    disabled={message?.length <= 0 || !validateRecipients(recipients) || (selectedTab === 'email' && !subject)}
                  >
                    {t('smsEmailComposerSend')}
                  </Button>
              }
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SMSEmailComposer;
