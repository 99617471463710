import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/MorphFormOpen.module.css'
import { useFooterContext } from '@contexts/FooterContext';

const MorphRefinanceCalculatorWelcome = ({handleSubmit}) => {
  const { t } = useTranslation();
  const footerContext = useFooterContext();

  useEffect(() => {
    // These values will hold an intent for the workflow so the workflow starts once the message is sent
    setTimeout(() => {
      footerContext.sendButtonRef.current?.update(t('refinanceCalculatorWelcomeMessage'));
    }, 100)
    footerContext.sendButtonRef.current?.update(t('refinanceCalculatorWelcomeMessage'));
    footerContext.chatInputFieldRef.current?.update(t('refinanceCalculatorWelcomeMessage'))
  }, [])

  const submitMessage = (e) => {
    handleSubmit(e);
  }

  // This is the button that appears on top of the input box
  return (
    <Button className={Styles.button} variant="contained" onClick={submitMessage}>
      {t('clickToBeginRefinance')}
    </Button>
  );
};

export { MorphRefinanceCalculatorWelcome };
