import React, { createContext, ReactNode, MutableRefObject, useContext, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Socket } from 'socket.io-client';

import useEventsSocket, { useEventsListener } from '../Common/hooks/useEventsSocket';
import useMessagesSocket, { useMessagesListener } from '../Common/hooks/useMessagesSocket';
import ReconnectingPopup from '../Components/ReconnectingPopup';
import { useUserContext } from '../Contexts/user';
import { SocketListenerType } from '../Types/TSocket';

import type { TSocketCallback } from '../Types/TSocket';

/**
 * payload: passPayload | failPayload | resetPayload, could be added to 
 * dynamically add arguements for each action type while adhering to 
 * strict type safety.
 */
type socketValue = null | {
  isMessagesConnected: boolean,
  isEventsConnected: boolean,
  isMessagesCreated: boolean,
  isEventsCreated: boolean,
  disconnectMessageSocket: any,
  disconnectEventSocket: any,
  isMessagesReconnecting: boolean,
  isEventsReconnecting: boolean,
  messagesSocket: MutableRefObject<Socket | null>,
  eventsSocket: MutableRefObject<Socket | null>,
  useEventsListener: <T extends SocketListenerType>( origin: string, event: T, callback: TSocketCallback<T>, ) => void,
  useMessagesListener: <T extends SocketListenerType>( origin: string, event: T, callback: TSocketCallback<T>, ) => void,
  setShowReconnecting: Dispatch<SetStateAction<boolean>>;
};

type socketProviderProps = {
  children: ReactNode;
  userConsent?: string;
};

const SocketContext = createContext<socketValue | null>(null);

const useSocketContext = () => {
  const socketContext = useContext(SocketContext);
  if (socketContext === undefined) {
    // throwing this error helps in development when the context is out of scope.
    throw new Error('useSocketContext must be inside a SocketProvider');
  }
  return socketContext;
};

/**
 * This provider function localizes states and improves maintainability whereever
 * this producer is implemented. In this case, we have it at the root level to
 * better allow users to understand why they were blocked from the system by 
 * having the socket model appear immediately on redirect.
 * @param children (ReactNode): always a default state for context providers
 * @returns 
 */
const SocketProvider = ({ children, userConsent }: socketProviderProps): JSX.Element => {
  const { _id, accessToken, accessLevel } = useUserContext();
  const { isEventsCreated, isEventsConnected, isEventsReconnecting, eventsSocket, disconnectEventSocket } = useEventsSocket(_id, accessToken, accessLevel);
  const { isMessagesCreated, isMessagesConnected, isMessagesReconnecting, messagesSocket, disconnectMessageSocket } = useMessagesSocket(_id, accessToken, accessLevel);
  const [showReconnecting, setShowReconnecting] = useState(false);

  useEffect(() => {
    setShowReconnecting(isMessagesReconnecting || isEventsReconnecting);
  }, [isMessagesReconnecting, isEventsReconnecting]);
  
  return (
    <SocketContext.Provider value={{ isEventsCreated, isEventsConnected, isEventsReconnecting, isMessagesCreated, isMessagesConnected, isMessagesReconnecting, eventsSocket, messagesSocket, useEventsListener, useMessagesListener, setShowReconnecting, disconnectEventSocket, disconnectMessageSocket }}>
      {children}
      <ReconnectingPopup userConsent={userConsent} showReconnecting={showReconnecting} />
    </SocketContext.Provider>
  );
};

export { SocketProvider, useSocketContext };
