import React, { memo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { IconStyles } from '../Constants';
import { DefaultElement } from './templates/DefaultElement';

export const EditingModeElement = memo(() => {
  const { t } = useTranslation();

  return <DefaultElement text={t('AT_editingMode')} icon={<EditIcon style={{ ...IconStyles.default }} />} />;
});
