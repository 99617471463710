import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useState } from 'react';

import Avatar from './Avatar';
import DialogHeader from './DialogHeader';
import ScrollBox from './ScrollBox';
import getImageSource from '../Common/getImageSource';
import axiosCall from '../Services/axios';
import { useTranslation } from 'react-i18next';
import { IParticipant, IErrand } from '@interfaces/Conversation';
import ErrorBoundary from '@components/ErrorBoundary'
import useInitialMount from '@common/hooks/useInitialMount';
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

/*
 *  This component is the popup dialog that the user interacts with when creating
 * a group chat in the operator console on the conversations page under the group
 * subheader.
 *
 *  This component has the following properties:
 *    - open: The state of the modal, whether or not to be open
 *    - onClose: the function to call when closing, since state managment is out of
 *      the scope of this component.
 */

const UserStripe = (props: { data: { status: any; name: string; avatar: any; }; addRemoveUser: (arg0: any) => void; add: any; }) => {
  const { t } = useTranslation();
  return (
    <ErrorBoundary debug={`./src/Components/UserDialog.tsx: UserStripe`}>
      <article style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: "8px 16px", justifyContent: "space-between" }}>
        <span style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: 'flex-start' }}>
          <Avatar sx={{ width: "40px", marginRight: "8px" }} status={props.data.status} alt={props.data.name} img={getImageSource(props.data.avatar)} />
          <Typography> {props.data.name} </Typography>
        </span>
        <span>
          <Button
            variant="outlined"
            size="small"
            onClick={() => { props.addRemoveUser(props) }}>
            {props.add ? t("createGroupAdd") : t("createGroupRemove")}
          </Button>
        </span>
      </article>
      <Divider sx={{ marginLeft: "15px", marginRight: "15px" }} />
    </ErrorBoundary>
  );
};

const UserDialog = (props: { errand: IErrand; handleSubmit: (arg0: any[]) => void; open: boolean; onClose: () => void}) => {
  const [users, setUsers] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [search, setSearch] = useState("");

  const { t } = useTranslation();

  const getUserData = async () => {
    if (!props.errand._id) { // avoid making API calls with undefined.
      console.info("UserDialog.tsx:72 ~ props.errand._id:", props.errand?._id);
      return null;
    }
    const participants = await axiosCall({
      url: `chat/${props.errand?._id}/participant`,
    });

    console.log(participants);
    if (participants) {
      let userData = participants
        ?.filter((x: IParticipant) => x.userType === 'User' && x.active)
        ?.map((x: IParticipant) => {
          return {
            name: `${x.userData?.firstname} ${x.userData?.lastname}`,
            status: x.userData?.status,
            avatar: x.userData?.avatar,
            _id: x.userData?._id,
            visible: true,
          };
        });

      setUsers(userData);
      setDataToDisplay(userData);
    }
  }

  useInitialMount(getUserData);

  const addRemoveUser = (p: { add: any; data: any; }) => {
    let arr = selectedUsers.slice();

    if (p.add && !arr.includes(p.data)) {
      arr.push(p.data);
      let dtd = dataToDisplay.slice();
      let index = dtd.indexOf(p.data);
      if (index > -1) dtd[index].visible = false;
      setDataToDisplay(dtd)
    }
    else {
      let index = arr.indexOf(p.data);
      if (index > -1) arr.splice(index, 1);

      let dtd = dataToDisplay.slice();
      let dtdIndex = dtd.indexOf(p.data);
      if (dtdIndex > -1) dtd[dtdIndex].visible = true;
      setDataToDisplay(dtd)
    }

    setSelectedUsers(arr);
  }

  const handleSetSearch = (value: string) => {
    setSearch(value);
    if (value) {
      setDataToDisplay(users.filter((el) => {
        return (
          el?.name?.toLowerCase()?.includes(value?.toLowerCase())
        );
      }));
    }
    else {
      setDataToDisplay(users);
    }
  }

  const handleSubmit = () => {
    if (selectedUsers.length > 0) {
      const recipients = [...selectedUsers?.map((x) => x?._id)];
      props.handleSubmit(recipients)
    }
  }

  return (
    <ErrorBoundary debug={`./src/Components/UserDialog.tsx: level 0`}>
    <Dialog maxWidth="lg" open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('selectUsersTitle')} onClose={() => props.onClose()} />
      <DialogContent>
        <Stack height="540px" width="900px" marginTop="10px">
          {/* Main Content Area */}
          <Grid container spacing={3}>

            {/* Left panel */}
            <Grid item xs={5}>
              <Box sx={{ border: "1px solid var(--gray210)" }}>
                <TextField
                  sx={{ margin: "8px 0 8px 16px", paddingRight: "32px" }}
                  fullWidth
                  label={t('createGroupSearch')}
                  variant="outlined"
                  size="small"
                  value={search}
                  onChange={(e) => { handleSetSearch(e.target.value) }}
                />
                <ScrollBox sx={{ borderTop: "1px solid var(--gray210)" }}>
                  {dataToDisplay.length > 0 && dataToDisplay?.map((x, index) => {
                    return (
                      <Fragment key={index}>
                        {x.visible && <UserStripe data={x} add addRemoveUser={addRemoveUser} />}
                      </Fragment>
                    );
                  })}
                </ScrollBox>
              </Box>
            </Grid>

            {/* Center Panel */}
            <Grid item xs={7}>
              <ScrollBox>
                <Divider sx={{ marginTop: "8px" }} >
                  <Typography fontWeight={600}>
                    {t('selectOwnerHeading')}
                  </Typography>
                </Divider>
                {
                  selectedUsers.length > 0 ?
                    [selectedUsers[0]]?.map((x) => {
                      return (
                        <Fragment key={x?._id}>
                          <UserStripe
                            data={x} addRemoveUser={addRemoveUser} add={undefined} />
                        </Fragment>
                      )
                    })
                    :
                    <Typography fontSize="12px" padding="20px 30px 12px 20px" textAlign="center"> {t('selectUsersNone')} </Typography>
                }

                <Divider sx={{ marginTop: "8px" }} >
                  <Typography fontWeight={600}>
                    {t('selectRecipientsHeading')}
                  </Typography>
                </Divider>
                {
                  selectedUsers.length > 0 ?
                    selectedUsers?.map((x) => {
                      return (
                        <Fragment key={x?._id}>
                          <UserStripe
                            data={x} addRemoveUser={addRemoveUser} add={undefined} />
                        </Fragment>
                      )
                    })
                    : <></>
                }
              </ScrollBox>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="outlined"> {t('createGroupCancel')} </Button>
        <Button disabled={selectedUsers.length === 0} onClick={() => handleSubmit()} variant="contained"> {t('createGroupCreate')} </Button>
      </DialogActions>
    </Dialog>
    </ErrorBoundary>
  );
};

export default UserDialog;
