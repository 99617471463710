import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IErrand, IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';

import Styles from '@styles/MorphMessageOptions.module.css';
import { MorphType } from '@common/MorphType';
import { getAudienceString } from '@common/msgUtils';
const Shush = process.env.REACT_APP_MORGAN_CDN + '/Images/Shush.png';

type TMorphMessageOptionPrivateModeProps = {
  errand: IErrand;
  handleClose: (morphType: MorphType) => void;
  message: IMessage;
};

const MorphMessageOptionPrivateMode = ({
  errand, handleClose, message
}: TMorphMessageOptionPrivateModeProps) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const rootContext = useRootContext();

  const handleClickPrivateMode = useCallback((e) => {
    e.stopPropagation();
    rootContext.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let index = prev.findIndex((e) => e._id === message?.chat);
      if (index === -1) return prev; // state was not modified, do not spread
      const audience = message?.operatorView ? `${message?.sender?._id},${message?.userId}` : getAudienceString(message?.sender?._id, message?.intendedAudience, prev[index].participants, 'id', Boolean(message?.operatorView), false, message?.userId, true);
      if (audience.length === 0) return prev;
      errandContext.setMorphType(MorphType.PrivateChat);
      prev[index].recipients = audience.split(',').filter((v,i,a) => v !== '' && a.indexOf(v) === i).sort();
      return [...prev]; // spread to trigger dependency arrays as state was modified
    });
    errandContext.footerInputRef.current?.focus();
    handleClose(MorphType.PrivateChat);
  }, [errand._id, message?._id, message?.intendedAudience, errand.participants, handleClose]);

  return (
    <button className={Styles.btn} onClick={handleClickPrivateMode}>
      <div className={Styles.btnBorder}>
        <img src={Shush} alt="Angel with index finger on lips asking you to keep her secrets" />
        <p className={Styles.label}>{t('tPrivate')}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionPrivateMode;
