/**
 * @file DownloadTheAppMessageContent.tsx
 * @description This file defines the DownloadTheAppMessageContent component used to
 * display the message to the user when they reject a thin client permission
 * workflow
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */
import React, { useRef } from 'react';
import MessageTextStyle from '@styles/MessageTextStyle';
import { Trans, useTranslation } from 'react-i18next';
import Styles from '@styles/MessageContent/DownloadTheAppMessageContent.module.css';
import { uiTranslationController } from '@common/common';
import { IErrand, IMessage } from '@interfaces/Conversation';
import useInitialMount from '@common/hooks/useInitialMount';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { UAParser } from 'ua-parser-js';
const QRCode = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAiAppQRCode.png';

const ANDROID_DOWNLOAD_URL =
  'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.swmc.morganthinclientandroid'; // URL of the android store download page for the thin client.
const IOS_DOWNLOAD_URL = 'https://apps.apple.com/us/app/angelai/id1667401139'; // URL of the apple store download page for the thin client.

type TDownloadTheAppMessageContentProps = {
  errand: IErrand;
  message: IMessage;
};

const DownloadTheAppMessageContent = ({ errand, message }: TDownloadTheAppMessageContentProps) => {
  const { t } = useTranslation();
  const { dispMessage, isTranslated } = uiTranslationController(message);
  const messageRef = useRef(null);
  const errandContext = useErrandContext();
  const uaParser = UAParser();
  const isIosOrAndroid = ['iOS', 'Android'].includes(uaParser.os.name);

  const handleClick = (e) => {
    // Use UAParser to detect OS and navigate user to the right storefront
    if (uaParser.os.name === 'Android') {
      window.open(ANDROID_DOWNLOAD_URL, '_blank');
      return;
    }
    if (uaParser.os.name === 'iOS') {
      window.open(IOS_DOWNLOAD_URL, '_blank');
      return;
    }
  };

  useInitialMount(() => {
    if (isIosOrAndroid && errand.lastMessageData?.messageType === 'DownloadTheApp') {
      errandContext.setMorphType(MorphType.DownloadAppBanner);
    }
  });

  if (isIosOrAndroid) {
    // Show message on mobile devices
    return (
      <div className={Styles.bubble} ref={messageRef}>
        <MessageTextStyle>
          <p>
            <Trans i18nKey="downloadTheAppMessageMobile">
              links
              <a href="javascript:void(0);" onClick={handleClick}>
                here
              </a>
            </Trans>
          </p>
        </MessageTextStyle>
      </div>
    );
  } else {
    // Show QR Code on desktop devices
    return (
      <div className={Styles.container}>
        <MessageTextStyle className={Styles.text}>{t('downloadTheAppMessageQR')}</MessageTextStyle>
        <img className={Styles.QRCode} src={QRCode}/>
      </div>
    );
  }
};

export default DownloadTheAppMessageContent;
