const thumbnail1 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/1NewEngland'
const thumbnail2 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/2Suncoast'
const thumbnail3 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/3Limitless'
const thumbnail4 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/4Texas'
const thumbnail5 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/5Atlanta'
const thumbnail6 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/6MidSouth'
const thumbnail7 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/7Originator'
const thumbnail8 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/8NewYork'
const thumbnail9 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/9Chicago'
const thumbnail10 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/10Coastal'
const thumbnail11 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/11Cali'
const thumbnail12 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/12Utah'
const thumbnail13 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/13Rev'
const thumbnail14 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/14TenX'
const thumbnail15 = process.env.REACT_APP_MORGAN_CDN + '/splash/film/15Magic'

export const ThumbnailsArray = [
    {
        id: 1,
        type: 'image',
        src: `${thumbnail1}_medium.png`,
        srcset: `${thumbnail1}_small.png 1x, ${thumbnail1}_medium.png 2x, ${thumbnail1}_large.png 3x`,
        url: 'https://www.swmc.com/mortgageexpo/',
        alt: 'New England Mortgage Expo',
    },
    {
        id: 2,
        type: 'image',
        src: `${thumbnail2}_medium.png`,
        srcset: `${thumbnail2}_small.png 1x, ${thumbnail2}_medium.png 2x, ${thumbnail2}_large.png 3x`,
        url: 'https://www.swmc.com/suncoast/',
        alt: 'Suncoast Expo',
    },
    {
        id: 3,
        type: 'video'
    },
    {
        id: 4,
        type: 'image',
        src: `${thumbnail3}_medium.png`,
        srcset: `${thumbnail3}_small.png 1x, ${thumbnail3}_medium.png 2x, ${thumbnail3}_large.png 3x`,
        url: 'https://www.swmc.com/limitless/',
        alt: 'Limitless Expo',
    },
    {
        id: 5,
        type: 'image',
        src: `${thumbnail4}_medium.png`,
        srcset: `${thumbnail4}_small.png 1x, ${thumbnail4}_medium.png 2x, ${thumbnail4}_large.png 3x`,
        url: 'https://www.swmc.com/txroundup/',
        alt: 'Texas Mortgage Roundup',
    },
    {
        id: 6,
        type: 'image',
        src: `${thumbnail5}_medium.png`,
        srcset: `${thumbnail5}_small.png 1x, ${thumbnail5}_medium.png 2x, ${thumbnail5}_large.png 3x`,
        url: 'https://www.swmc.com/atlantaexpo/',
        alt: 'Atlanta Mortgage Expo',
    },
    {
        id: 7,
        type: 'video',
    },
    {
        id: 8,
        type: 'image',
        src: `${thumbnail6}_medium.png`,
        srcset: `${thumbnail6}_small.png 1x, ${thumbnail6}_medium.png 2x, ${thumbnail6}_large.png 3x`,
        url: 'https://www.swmc.com/mid-me/',
        alt: 'Mid-South Mortgage Expo',
    },
    {
        id: 9,
        type: 'image',
        src: `${thumbnail7}_medium.png`,
        srcset: `${thumbnail7}_small.png 1x, ${thumbnail7}_medium.png 2x, ${thumbnail7}_large.png 3x`,
        url: 'https://www.swmc.com/otech/',
        alt: 'Originator Tech Event',
    },
    {
        id: 10,
        type: 'image',
        src: `${thumbnail8}_medium.png`,
        srcset: `${thumbnail8}_small.png 1x, ${thumbnail8}_medium.png 2x, ${thumbnail8}_large.png 3x`,
        url: 'https://www.swmc.com/nyexpo/',
        alt: 'New York Mortgage Expo',
    },
    {
        id: 11,
        type: 'video',
    },
    {
        id: 12,
        type: 'image',
        src: `${thumbnail9}_medium.png`,
        srcset: `${thumbnail9}_small.png 1x, ${thumbnail9}_medium.png 2x, ${thumbnail9}_large.png 3x`,
        url: 'https://www.swmc.com/chicagoexpo/',
        alt: 'Chicago Mortgage Originators Expo',
    },
    {
        id: 13,
        type: 'image',
        src: `${thumbnail10}_medium.png`,
        srcset: `${thumbnail10}_small.png 1x, ${thumbnail10}_medium.png 2x, ${thumbnail10}_large.png 3x`,
        url: 'https://www.swmc.com/ccexpo/',
        alt: 'Coastal Connect Mortgage Expo',
    },
    {
        id: 14,
        type: 'image',
        src: `${thumbnail11}_medium.png`,
        srcset: `${thumbnail11}_small.png 1x, ${thumbnail11}_medium.png 2x, ${thumbnail11}_large.png 3x`,
        url: 'https://www.swmc.com/irvineexpo/',
        alt: 'California Mortgage Expo',
    },
    {
        id: 15,
        type: 'video'
    },
    {
        id: 16,
        type: 'image',
        src: `${thumbnail12}_medium.png`,
        srcset: `${thumbnail12}_small.png 1x, ${thumbnail12}_medium.png 2x, ${thumbnail12}_large.png 3x`,
        url: 'https://www.swmc.com/utexpo/',
        alt: 'Utah Mortgage Show',
    },
    {
        id: 17,
        type: 'image',
        src: `${thumbnail13}_medium.png`,
        srcset: `${thumbnail13}_small.png 1x, ${thumbnail13}_medium.png 2x, ${thumbnail13}_large.png 3x`,
        url: 'https://www.youtube.com/watch?v=BBm3VdtzXZk',
        alt: 'Revolutionize Your Real Estate Business',
    },
    {
        id: 18,
        type: 'image',
        src: `${thumbnail14}_medium.png`,
        srcset: `${thumbnail14}_small.png 1x, ${thumbnail14}_medium.png 2x, ${thumbnail14}_large.png 3x`,
        url: 'https://www.youtube.com/watch?v=kJ-SluFDScc',
        alt: '10X Your Business',
    },
    {
        id: 19,
        type: 'video'
    },
    {
        id: 20,
        type: 'image',
        src: `${thumbnail15}_medium.png`,
        srcset: `${thumbnail15}_small.png 1x, ${thumbnail15}_medium.png 2x, ${thumbnail15}_large.png 3x`,
        url: 'https://www.youtube.com/watch?v=ViVy0b7h7Co',
        alt: 'Unleash Your Inner Magic',
    },
    {
        id: 21,
        type: 'image',
        src: `${thumbnail1}_medium.png`,
        srcset: `${thumbnail1}_small.png 1x, ${thumbnail1}_medium.png 2x, ${thumbnail1}_large.png 3x`,
        url: 'https://www.swmc.com/mortgageexpo/',
        alt: 'New England Mortgage Expo',
    },
    {
        id: 22,
        type: 'image',
        src: `${thumbnail2}_medium.png`,
        srcset: `${thumbnail2}_small.png 1x, ${thumbnail2}_medium.png 2x, ${thumbnail2}_large.png 3x`,
        url: 'https://www.swmc.com/suncoast/',
        alt: 'Suncoast Expo',
    },
    {
        id: 23,
        type: 'video'
    },
    {
        id: 24,
        type: 'image',
        src: `${thumbnail3}_medium.png`,
        srcset: `${thumbnail3}_small.png 1x, ${thumbnail3}_medium.png 2x, ${thumbnail3}_large.png 3x`,
        url: 'https://www.swmc.com/limitless/',
        alt: 'Limitless Expo',
    },
    {
        id: 25,
        type: 'image',
        src: `${thumbnail4}_medium.png`,
        srcset: `${thumbnail4}_small.png 1x, ${thumbnail4}_medium.png 2x, ${thumbnail4}_large.png 3x`,
        url: 'https://www.swmc.com/txroundup/',
        alt: 'Texas Mortgage Roundup',
    },
    {
        id: 26,
        type: 'image',
        src: `${thumbnail5}_medium.png`,
        srcset: `${thumbnail5}_small.png 1x, ${thumbnail5}_medium.png 2x, ${thumbnail5}_large.png 3x`,
        url: 'https://www.swmc.com/atlantaexpo/',
        alt: 'Atlanta Mortgage Expo',
    },
    {
        id: 27,
        type: 'video',
    },
    {
        id: 28,
        type: 'image',
        src: `${thumbnail6}_medium.png`,
        srcset: `${thumbnail6}_small.png 1x, ${thumbnail6}_medium.png 2x, ${thumbnail6}_large.png 3x`,
        url: 'https://www.swmc.com/mid-me/',
        alt: 'Mid-South Mortgage Expo',
    },
    {
        id: 29,
        type: 'image',
        src: `${thumbnail7}_medium.png`,
        srcset: `${thumbnail7}_small.png 1x, ${thumbnail7}_medium.png 2x, ${thumbnail7}_large.png 3x`,
        url: 'https://www.swmc.com/otech/',
        alt: 'Originator Tech Event',
    },
    {
        id: 30,
        type: 'image',
        src: `${thumbnail8}_medium.png`,
        srcset: `${thumbnail8}_small.png 1x, ${thumbnail8}_medium.png 2x, ${thumbnail8}_large.png 3x`,
        url: 'https://www.swmc.com/nyexpo/',
        alt: 'New York Mortgage Expo',
    },
    {
        id: 31,
        type: 'video',
    },
    {
        id: 32,
        type: 'image',
        src: `${thumbnail9}_medium.png`,
        srcset: `${thumbnail9}_small.png 1x, ${thumbnail9}_medium.png 2x, ${thumbnail9}_large.png 3x`,
        url: 'https://www.swmc.com/chicagoexpo/',
        alt: 'Chicago Mortgage Originators Expo',
    },
    {
        id: 33,
        type: 'image',
        src: `${thumbnail10}_medium.png`,
        srcset: `${thumbnail10}_small.png 1x, ${thumbnail10}_medium.png 2x, ${thumbnail10}_large.png 3x`,
        url: 'https://www.swmc.com/ccexpo/',
        alt: 'Coastal Connect Mortgage Expo',
    },
    {
        id: 34,
        type: 'image',
        src: `${thumbnail11}_medium.png`,
        srcset: `${thumbnail11}_small.png 1x, ${thumbnail11}_medium.png 2x, ${thumbnail11}_large.png 3x`,
        url: 'https://www.swmc.com/irvineexpo/',
        alt: 'California Mortgage Expo',
    },
    {
        id: 35,
        type: 'video'
    },
    {
        id: 36,
        type: 'image',
        src: `${thumbnail12}_medium.png`,
        srcset: `${thumbnail12}_small.png 1x, ${thumbnail12}_medium.png 2x, ${thumbnail12}_large.png 3x`,
        url: 'https://www.swmc.com/utexpo/',
        alt: 'Utah Mortgage Show',
    },
    {
        id: 37,
        type: 'image',
        src: `${thumbnail13}_medium.png`,
        srcset: `${thumbnail13}_small.png 1x, ${thumbnail13}_medium.png 2x, ${thumbnail13}_large.png 3x`,
        url: 'https://www.youtube.com/watch?v=BBm3VdtzXZk',
        alt: 'Revolutionize Your Real Estate Business',
    },
    {
        id: 38,
        type: 'image',
        src: `${thumbnail14}_medium.png`,
        srcset: `${thumbnail14}_small.png 1x, ${thumbnail14}_medium.png 2x, ${thumbnail14}_large.png 3x`,
        url: 'https://www.youtube.com/watch?v=kJ-SluFDScc',
        alt: '10X Your Business',
    },
    {
        id: 39,
        type: 'video'
    },
    {
        id: 40,
        type: 'image',
        src: `${thumbnail15}_medium.png`,
        srcset: `${thumbnail15}_small.png 1x, ${thumbnail15}_medium.png 2x, ${thumbnail15}_large.png 3x`,
        url: 'https://www.youtube.com/watch?v=ViVy0b7h7Co',
        alt: 'Unleash Your Inner Magic',
    },
];
