import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Menu,
  MenuItem,
  Checkbox,
  Tooltip,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from '@mui/material';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import SearchIcon from '@mui/icons-material/Search';
import axiosCall from '../Services/axios';
import { useTranslation } from 'react-i18next';
import { IErrand, IChatlog } from '@interfaces/Conversation';
import ErrorBoundary from '@components/ErrorBoundary';
import useInitialMount from '@common/hooks/useInitialMount';
import Styles from '@styles/ConsoleDialog.module.css';

/**
 * This component is the popup dialog that the shows the chat logs when the
 * user clicks on View Console.
 */
const ConsoleDialog = (props: { errand: IErrand; open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();
  const [chatlogsToShow, setChatlogsToShow] = useState([]);
  const [expandedCells, setExpandedCells] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    system: {
      '': true,
      'Morgan-UI': true,
      'Morgan-Core': true,
      'Morgan-Events': true,
      'Morgan-Messages': true,
      'Morgan-Cells': true,
      'Morgan-AI': true
    },
    level: {
      info: true,
      debug: true,
      warn: true,
      error: true,
    },
  });

  const [anchorElSystem, setAnchorElSystem] = useState(null);
  const [anchorElLevel, setAnchorElLevel] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const tableRef = useRef(null);
  const prevScrollHeightRef = useRef(0);
  const prevScrollPositionRef = useRef(0);
  const [atBottom, setAtBottom] = useState(false);
  const [isReverseOrder, setIsReverseOrder] = useState(false);

  const handleSystemClick = useCallback((event) => {
    setAnchorElSystem(event.currentTarget);
  }, []);

  const handleLevelClick = useCallback((event) => {
    setAnchorElLevel(event.currentTarget);
  }, []);

  const handleCloseSystem = useCallback(() => {
    setAnchorElSystem(null);
  }, []);

  const handleCloseLevel = useCallback(() => {
    setAnchorElLevel(null);
  }, []);

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const getLogData = useCallback(async () => {
    try {
      if (!props.errand._id) {
        // avoid making API calls with undefined.
        console.info('ConsoleDialog props.errand._id is undefined:', props.errand?._id);
        return null;
      }
      const chatlogs = await axiosCall({
        url: `chatLog?chat=${props.errand._id}`,
      });

      if (chatlogs) {
        let chatlog = chatlogs
          ?.map((x: IChatlog) => {
            return {
              level: x.level,
              message: x.message,
              system: x.system,
              createdAt: x.createdAt,
            };
          });

        setChatlogsToShow(chatlog);
      }
    } catch (err) {
      console.error('ConsoleDialog: Error: ' + err);
    }
  }, []);

  useInitialMount(getLogData);

  const toggleFilterOption = (type, option) => {
    setFilterOptions({
      ...filterOptions,
      [type]: {
        ...filterOptions[type],
        [option]: !filterOptions[type][option],
      },
    });
  };

  const filterLogs = (log) => {
    return (
      filterOptions.system[log.system] &&
      filterOptions.level[log.level] &&
      (searchTerm === '' || log.message.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

  const handleCellClick = (index) => {
    if (expandedCells.includes(index)) {
      setExpandedCells(expandedCells.filter((item) => item !== index));
    } else {
      setExpandedCells([...expandedCells, index]);
    }
  };

  const handleScrollDownClick = () => {
    tableRef.current.scrollTo({ behavior: 'smooth', top: tableRef.current.scrollHeight })
    setAtBottom(true);
  }

  const convertToLocalTime = useCallback((utcTime) => {
    const date = new Date(utcTime);
    return date.toLocaleString();
  }, []);

  // Automatically pull log data every second
  useEffect(() => {
    getLogData();
    const intervalId = setInterval(() => {
      getLogData();
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [getLogData]);

  // If isReverseOrder is true, we don't need any auto-scrolling to happen. Default
  // behavior is exactly what we want
  useEffect(() => {
    if (!tableRef.current) return;
    // Store the current scroll position and scroll height
    const { scrollTop, scrollHeight } = tableRef.current;
    const currentScrollHeight = scrollHeight;

    const totalHeight = scrollTop + tableRef.current.clientHeight;
    const marginOfError = 1; // adding margin of error for decimal differences causing discrepancy depending on browser and window size / scale

    // If the user is at the bottom and hasn't scrolled up, set 'atBottom' state to true
    if (Math.abs(totalHeight - scrollHeight) <= marginOfError && prevScrollPositionRef.current <= scrollTop) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }

    // If the user is at the bottom and the scroll height has increased, scroll to the bottom
    if (atBottom && currentScrollHeight > prevScrollHeightRef.current && !isReverseOrder) {
      tableRef.current.scrollTo({ behavior: 'smooth', top: tableRef.current.scrollHeight });
    }

    // Update previous scroll height and position
    prevScrollHeightRef.current = currentScrollHeight;
    prevScrollPositionRef.current = scrollTop;
  }, [chatlogsToShow, atBottom, isReverseOrder]);

  return (
    <ErrorBoundary debug={`./src/Components/ConsoleDialog.tsx`}>
      <Dialog maxWidth="lg" open={props.open} onClose={() => props.onClose()}>
        <DialogContent>
          <Stack height="540px" width="900px">
            <div className={Styles.chatlogSearch}>
              <TextField
                label={t('searchMessages')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              {` _id: ${props.errand._id}`}
            </div>
            {chatlogsToShow.length > 0 && (
              <div className={Styles.tableWrapper} ref={tableRef}>
                <table className={Styles.chatlogsTable}>
                  <thead>
                    <tr>
                      <th>
                        <Tooltip title={t('filterOptions')} placement="bottom">
                          <Button
                            className={Styles.filterTitle}
                            aria-haspopup="listbox"
                            aria-controls="system-menu"
                            aria-label="filter options"
                            onClick={handleSystemClick}
                          >
                            {t('chatLogsSystem')}
                          </Button>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorElSystem}
                          open={Boolean(anchorElSystem)}
                          onClose={handleCloseSystem}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          MenuListProps={{
                            'aria-labelledby': 'system-menu',
                          }}
                        >
                          {Object.keys(filterOptions.system).map((option, index) => (
                            <MenuItem key={index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={filterOptions.system[option]}
                                    onChange={() => toggleFilterOption('system', option)}
                                    onClick={(event) => event.stopPropagation()}
                                    id={`system-checkbox-${index}`}
                                  />
                                }
                                label={option}
                                htmlFor={`system-checkbox-${index}`}
                              />
                            </MenuItem>
                          ))}
                        </Menu>
                      </th>
                      <th>
                        <Tooltip title={t('filterOptions')} placement="bottom">
                          <Button
                            className={Styles.filterTitle}
                            aria-haspopup="listbox"
                            aria-controls="level-menu"
                            aria-label="filter options"
                            onClick={handleLevelClick}
                          >
                            {t('chatLogsLevel')}
                          </Button>
                        </Tooltip>
                        <Menu
                          anchorEl={anchorElLevel}
                          open={Boolean(anchorElLevel)}
                          onClose={handleCloseLevel}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          MenuListProps={{
                            'aria-labelledby': 'level-menu',
                          }}
                        >
                          {Object.keys(filterOptions.level).map((option, index) => (
                            <MenuItem key={index}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={filterOptions.level[option]}
                                    onChange={() => toggleFilterOption('level', option)}
                                    onClick={(event) => event.stopPropagation()}
                                    id={`level-checkbox-${index}`}
                                  />
                                }
                                label={option}
                                htmlFor={`level-checkbox-${index}`}
                              />
                            </MenuItem>
                          ))}
                        </Menu>
                      </th>
                      <th>{t('chatLogsMessage')}</th>
                      <th>
                        {t('chatLogsDate')}
                        <IconButton
                          aria-label={t('sortBy')}
                          onClick={() => setIsReverseOrder(!isReverseOrder)}
                          sx={{ marginRight: '-14px', marginBottom: '3px' }}
                        >
                          {isReverseOrder ? <ArrowDropUp /> : <ArrowDropUp sx={{ transform: 'rotate(180deg)', color: 'var(--blue010)'}} />}
                        </IconButton>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isReverseOrder
                      ? chatlogsToShow
                          .filter(filterLogs)
                          .sort((a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)))
                          .map((log, index) => (
                            <tr key={index}>
                              <td>{log.system}</td>
                              <td>{log.level}</td>
                              <td
                                className={expandedCells.includes(index) ? Styles.expanded : ''}
                                style={expandedCells.includes(index) ? { textOverflow: 'unset' } : undefined }
                                onClick={() => handleCellClick(index)}
                              >
                                {log.message}
                              </td>
                              <td>{convertToLocalTime(log.createdAt)}</td>
                            </tr>
                          ))
                      : chatlogsToShow.filter(filterLogs).map((log, index) => (
                          <tr key={index}>
                            <td>{log.system}</td>
                            <td>{log.level}</td>
                            <td
                              className={expandedCells.includes(index) ? Styles.expanded : ''}
                              style={expandedCells.includes(index) ? { textOverflow: 'unset' } : undefined }
                              onClick={() => handleCellClick(index)}
                            >
                              {log.message}
                            </td>
                            <td>{convertToLocalTime(log.createdAt)}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            )}
            {chatlogsToShow.filter(filterLogs).length === 0 && <div className={Styles.noLogs}>{t('noLogsToShow')}</div>}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.onClose()} variant="outlined">
            {' '}
            {t('closeButton')}{' '}
          </Button>
          {!atBottom && chatlogsToShow.filter(filterLogs).length !== 0 && (
            <IconButton className={Styles.chatDownButton}>
              <ExpandCircleDownIcon
                className={Styles.chatDownIcon}
                onClick={handleScrollDownClick}
              />
            </IconButton>
          )}
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  );
};

export default ConsoleDialog;
