import dayjs from 'dayjs';
import writeXlsxFile from 'write-excel-file';

const downloadOperatorReport = async (operatorData) => {
  const schema = [
    // Column #1
    {
      column: 'Name',
      type: String,
      value: (operator) => operator.name,
      width: 25,
    },
    {
      column: 'Team',
      type: String,
      value: (operator) => operator.team,
      width: 25,
    },
    {
      column: 'Sub Team',
      type: String,
      value: (operator) => operator.subTeam,
      width: 25,
    },
    {
      column: 'Active chats',
      type: Number,
      format: '0',
      value: (operator) => operator.chatCount,
      width: 20,
    },
    {
      column: 'Access Level',
      type: String,
      value: (operator) => operator.accessLevel,
      width: 25,
    },
    {
      column: 'Status',
      type: String,
      value: (operator) => operator.status,
      width: 25,
    },
    {
      column: 'Last Access',
      type: String,
      value: (operator) => operator.lastAccess,
      width: 25,
    },
  ];

  if (operatorData?.length > 0) {
    const objects = operatorData?.map((operator) => {
      return {
        name: `${operator.firstname} ${operator.lastname}`,
        team: operator?.team?.[0],
        subTeam: operator?.subTeam?.[0],
        chatCount: operator?.activeChats,
        accessLevel: operator.accessLevel,
        status: operator.status,
        lastAccess: operator?.lastAccess,
      };
    });
    const date = dayjs(new Date()).format('DD/MM/YYYY');
    await writeXlsxFile(objects, {
      schema,
      fileName: `Morgan Operator Report : ${date}.xlsx`,
    });
  }
};

export default downloadOperatorReport;
