
const userLogoStyle = {
    left: '32px'
}

const operatorLogoStyle = {
    right: '32px'
}

const defaultLogoStyle = {
    position: 'absolute',
    top: '-80px',
    zIndex: 1,
    cursor: 'pointer',
}

const userDragNoneStyle = {
    userDrag: 'none',
    WebkitUserDrag: 'none'
}

export {
    userLogoStyle,
    operatorLogoStyle,
    defaultLogoStyle,
    userDragNoneStyle,
}