import { INotification } from '@interfaces/Conversation';
import React, { SetStateAction } from 'react';
import axiosCall from '../Services/axios';
import { ValidatorFunctions } from '@common/Validators';

/**
 * Fetches undread notification via axiosCall and builds notification array.
 * @param {string} userId - The objectId of the user or operator.
 */
async function getUnreadNotifications(
  userId: string,
  setNotifications: { (value: SetStateAction<INotification[]>): void },
  showNotificationAlert?: { (value: SetStateAction<boolean>): void },
) {
  const type = window.location.pathname.indexOf('/console') !== -1 ? 'operator' : 'user';
  try {
    const response = await axiosCall({ url: `${type}/${userId}/notification` });
    
    // make sure notifications array is not empty
    if (ValidatorFunctions.isNotEmptyArray(response.data)) {
      setNotifications(response.data);

      if (typeof showNotificationAlert === 'function') {
        showNotificationAlert(true);
      }
    }
  } catch (err) {
    console.error(`Could not get unread notifications for ${type} ${userId}`, err);
  }
}

export default getUnreadNotifications;
