import React, { useEffect, useCallback, useRef, useState, MutableRefObject } from 'react';

/**
 * Hook that returns whether the document is visible as a boolean
 * This is updated when the corresponding event listener is triggered
 * @param {Function} onChange - callback function to be called when visibility changes
 * @returns {boolean} isDocumentVisible - whether the document is visible
 * @returns {MutableRefObject<string>} visibilityStateRef - read only document visibility state as string
 */
const useDocumentVisibility = (onChange: (visibilityState: string) => void) => {
  const [isDocumentVisible, setIsDocumentVisible] = useState<boolean>(document?.visibilityState === 'visible');
  const visibilityStateRef = useRef<string>(document?.visibilityState);

  // useCallback caches the function based on the dependencies so it isn't redefined each render 
  const handleChange = useCallback(() => {
    const isVisible = document?.visibilityState === 'visible';
    setIsDocumentVisible(isVisible);
    visibilityStateRef.current = document?.visibilityState;

    if (typeof onChange === 'function') {
      onChange(visibilityStateRef.current);
    }
  }, [onChange]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleChange);

    return () => {
      document.removeEventListener('visibilitychange', handleChange);
    };
  }, [handleChange]);

  return { isDocumentVisible, visibilityStateRef};
}

export default useDocumentVisibility;
