import React, { useState, useRef, useEffect } from 'react';
import { ImageContainer } from './FilmStyle';
import { ThumbnailsArray } from '@components/Splash/ThumbnailsArray'
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import useInitialMount from '@common/hooks/useInitialMount';

const Film = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollDirection, setScrollDirection] = useState(1); // 1 for scrolling right, -1 for scrolling left
  const [isMobileDevice, setIsMobileDevice] = useState(false) // If not mobile, hide horizontal scrollbar
  const { innerWidth } = useWindowDimensions();
  const isSmallScreen = innerWidth < 900;
  const boxContainerRef = useRef(null);
  const scrollSpeed = 1; // set to 1 for slowest speed, combined with intervalId for even slower speeds
  const ballerinaVid = process.env.REACT_APP_MORGAN_CDN + '/splash/film/BallerinaGifSmall.gif'
  const ballerinaStatic = process.env.REACT_APP_MORGAN_CDN + '/splash/film/BallerinaStatic.jpg'
  const film = process.env.REACT_APP_MORGAN_CDN + '/splash/Assets/blackFilm.png'

  useEffect(() => {
    let intervalId;

    // This creates the autoscroll effect
    const scroll = () => {
      if (!isDragging && boxContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = boxContainerRef.current;
        const maxScrollLeft = scrollWidth - clientWidth;
        let newScrollLeft = scrollLeft + scrollSpeed * scrollDirection;
  
        // Ensure the new scroll position stays within the bounds of the container
        if (newScrollLeft < 0) {
          newScrollLeft = 0;
          setScrollDirection(1); // Reverse scroll direction to right
        } else if (newScrollLeft > maxScrollLeft) {
          newScrollLeft = maxScrollLeft;
          setScrollDirection(-1); // Reverse scroll direction to left
        }
        boxContainerRef.current.scrollLeft = newScrollLeft;
      }
    };

    // using setInterval to slow down the speed to feel like less than 1 because scrollLeft only accepts integer values, not decimals
    intervalId = setInterval(scroll, 50); // Adjust the interval here for smoother scrolling

    return () => {
      clearInterval(intervalId);
    };
  }, [isDragging, scrollDirection]);

  //ensure the dragging is turned off if cursor leaves the film
  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false)
    }
  }

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - boxContainerRef.current.offsetLeft); //set initial X position of mouse
    setScrollLeft(boxContainerRef.current.scrollLeft); //set the scroll position
  };

  const handleMouseUp = (e) => {
    setIsDragging(false);
  
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - boxContainerRef.current.offsetLeft;
    const walk = (x - startX) //calculate the distance the mouse has moved horizontally when dragging occurs
    boxContainerRef.current.scrollLeft = scrollLeft - walk; //update the scrollLeft property
  };
  
  // check to make sure user isn't dragging before opening a link
  const handleClick = (e, thumbnail) => {
    const currentX = e.pageX - boxContainerRef.current.offsetLeft;
    const movementX = Math.abs(currentX - startX);
    if (movementX < 5) {
      window.open(thumbnail.url);
    } else {
      return
    }
  };

  useInitialMount(() => {
    setIsMobileDevice(isMobileOrTablet);
  })

  return (
    <ImageContainer>
      <div
        className="boxes scale"
        ref={boxContainerRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{ overflowX: isMobileDevice ? 'auto' : 'hidden' }} // Hide horizontal scrollbar on non-mobile devices
      >

        {ThumbnailsArray.map((thumbnail, index) => (
          thumbnail.type === 'image' ? (
            <div
              onMouseUp={(e) => handleClick(e, thumbnail)}
              key={thumbnail.id}
              id={`thumbnail${thumbnail.id}`}
              className={`box type${(index % 5) + 1}`}
            >
              <img
                className={`types img${(index % 5) + 1}`} 
                src={thumbnail.src}
                srcSet={thumbnail.srcset}
                alt={thumbnail.alt}
              />
            </div>
          ) : (
            <div key={thumbnail.id} className={`vidBox box type${(index % 5) + 1}`}>
                <img src={`${isSmallScreen ? ballerinaStatic : ballerinaVid}`}
                  alt='Ballerina Dancing'
                  className={`vid thumbnail${thumbnail.id} vidType${(index % 5) + 1}`}
                />
            </div>
          )
        ))}

        <img src={film} alt="Film" className="film1" />
        <img src={film} alt="Film" className="film2" />
      </div>
    </ImageContainer>
  );
};

export default Film;
