import { styled } from '@mui/material/styles';

export const ImageContainer = styled('div', {
})(() => `

.film1{
  z-index: 0;
  position: absolute;
  width: 2530px;
  height: 163px;
}

.film2{
  position: absolute;
  left: 2529px;
  z-index: 0;
  width: 2536px;
  height: 163px;
}

.types{
  max-width: 100%;
  position: relative;
}
.boxes {
  display: flex;
  overflow-x: scroll;
  cursor: grab;
  user-select: none;
  height: 180px;
  position: absolute;
  left: 0;
  right: 0;
}

.box {
  flex: 0 0 122px;
  height: 93px;
  width: 122px;
  position: relative;
  top: 47px;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-right: 5px;
}

.box img{
  cursor: pointer;
}

.type1 {
  top: 38px;
}

.type2 {
  height: 85px;
  top: 48px;
}

.type3 {
  height: 92px;
  top: 28px;
  width: 121px;
}

.type4 {
  height: 87px;
  top: 16px;
}

.type5 {
  height: 92px;
  top: 16px;
}

#thumbnail1, #thumbnail21{
  flex: 0 0 125px;
  top: 39px;
}

#thumbnail2, #thumbnail8, #thumbnail13, #thumbnail16,
#thumbnail22, #thumbnail33{
  margin-left: -2px;
}

#thumbnail12, #thumbnail32{
  left: -2px;
}

#thumbnail6, #thumbnail26{
  margin-left: -1px;
}

#thumbnail20{
  flex: 0 0 121px;
  top: 17px;
  margin-left: -1px;
}

#thumbnail21{
  margin-left: -2px;
}

#thumbnail32{
  left: -1px;
}

.vidBox{
  z-index: 0;
}

.vid{
  width: 141%;
  height: 100%;
  position: relative;
}

.vidType1{
  transform: rotate(9deg);
  left: -14px;
  top: 10px;
}

.vidType2{
  top: 5px;
  transform: rotate(-5deg);
  left: -15px;
  width: 144%;
  height: 104%;
}

.vidType3{
  transform: rotate(-10deg);
  left: -12px;
  top: 5px;
}

.vidType4{
  transform: rotate(-6deg);
  left: -14px;
  height: 105%;
  width: 145%;
  top: 1px;
}

.vidType5{
  transform: rotate(10deg);
  top: 4px;
  left: -17px;
  height: 107%;
  width: 146%;
}




  `);
