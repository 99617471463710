import React from 'react';
import { IconButton, Stack, TablePagination, Tooltip } from '@mui/material';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTranslation } from 'react-i18next';

const StandardTablePagination = (props) => {
  const { t } = useTranslation();
  const steps = props.steps || [5,10,15];
  let pageRangeLabel = t('paginationOfMany');

  if (props.dataSize) {
    pageRangeLabel = t('paginationOf') + props.dataSize;
  }

  const Actions = () => {
    return (
      <Stack direction="row" sx={{ paddingLeft: '1em' }}>
        <Tooltip title={t('standardTableFirstPage')}>
          <span>
            <IconButton
              onClick={(e) => {
                props.onPageChange(e, 0)
              }}
              disabled={props.page === 0}>
              <FirstPageIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={t('standardTablePrevPage')}>
          <span>
            <IconButton
              onClick={(e) => {
                props.onPageChange(e, props.page - 1)
              }}
              disabled={props.page === 0}>
              <ChevronLeftIcon />
            </IconButton>
          </span>
        </Tooltip>

        <Tooltip title={t('standardTableNextPage')}>
          <span>
            <IconButton
              onClick={(e) => {
                props.onPageChange(e, props.page + 1)
              }}
              disabled={!props.isMore}>
              <ChevronRightIcon />
            </IconButton>
          </span>
        </Tooltip>

      </Stack>
    )
  }

  return (
    <TablePagination
      rowsPerPageOptions={[{ label: t('paginationAll'), value: -1 }, steps[0], steps[1], steps[2]]}
      count={-1}
      rowsPerPage={props.rowsPerPage}
      page={props.page}
      onPageChange={props.onPageChange}
      onRowsPerPageChange={props.onRowsPerPageChange}
      showFirstButton={true}
      nextIconButtonProps={{ disabled: !props.isMore }}
      labelRowsPerPage={t('paginationRowsPerPage')}
      labelDisplayedRows={({ from, to }) => {
        return to > 0 ? `${from}-${props.dataSize? (to > props.dataSize? props.dataSize: to) : to} ${pageRangeLabel}`
          : props.dataSize ? (`${props.dataSize} ${t('paginationOf')}${props.dataSize}`) : '';
      }}
      ActionsComponent={Actions}
    />
  );
};

export default StandardTablePagination;
