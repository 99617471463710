/**
 * @file This file contains the definition of the `ScreenType` enum class. This class contains enumerators which
 * indicate the size of the user's device's screen.
 * @author Kristoffer A. Wright
 */

/**
 * This class is an enum class, the members of which represent the size of the user's device's screen.
 */
 class ScreenType {

  name : string = '';
  static EXTRA_SMALL = new ScreenType("EXTRA_SMALL");
  static SMALL = new ScreenType("SMALL");
  static MEDIUM = new ScreenType("MEDIUM");
  static LARGE = new ScreenType("LARGE");

  constructor(name: string) {
    this.name = name;
  }
}

export default ScreenType;