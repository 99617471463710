import { createTheme } from '@mui/material/styles';

const darkModeTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: "#121212", // Dark background for main areas
      paper: "#1e1e1e", // Slightly lighter background for components like Paper
    },
    primary: {
      main: "var(--peach900)", // Primary color for elements like Buttons, Icons
      contrastText: "var(--peach000)", // Text color on primary elements for readability
    },
    secondary: {
      main: "var(--peach000)", // Secondary color for less prominent elements
      contrastText: "#141414", // Text color on secondary elements
    },
    text: {
      primary: "var(--peach000)", // Primary text color, highly readable against dark background
      secondary: "var(--peach600)", // Secondary text color for less emphasis
    },
    error: {
      main: "var(--red400)", // Error color for error states and icons
    },
  },
  typography: {
    fontFamily: "Poppins", // Consistent font family across the interface
  },
  components: {
    // Customizing specific MUI components to better fit the dark theme
    MuiTextField: { // Add this section to customize TextField
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiTextField-root': {
            color: "var(--peach600)", // Use the custom color for text
            '& label.Mui-focused': {
              color: "var(--peach600)", // Custom color for label when focused
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: "var(--peach600)", // Custom color for border when focused
              },
            },
          },
        }),
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--gray890)", // Darker background for table headers
          '.MuiTableCell-root': {
            color: "var(--gray000)", // White text for headers for contrast
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '.MuiTableRow-root:hover': {
            backgroundColor: "#2a2a2a", // Slightly lighter row hover state
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #555555', // Darker border for table cells
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: "var(--peach900)", // Primary color for progress spinner
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#424242", // Dark background for tooltips
          color: "var(--gray000)", // White text for readability
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#1e1e1e", // Ensure Paper components match the dark theme
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--gray890)",
          '.MuiTableCell-root': {
            color: "var(--gray000)",
          },
        },
      },
    },
    // Customizing TableBody...
    MuiTableBody: {
      styleOverrides: {
        root: {
          '.MuiTableRow-root:hover': {
            backgroundColor: "#2a2a2a",
          },
        },
      },
    },
    // Customizing TableCell...
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #555555',
        },
      },
    },
    // Customizing TableRow...
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': {
            backgroundColor: "var(--gray890)", // Slightly darker for hover state
          },
        },
      },
    },
    // Customizing TableContainer...
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--gray890)", // Matching the dark theme paper background
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)', // Optional: adding some shadow for depth
        },
      },
    },
    // Add other component customizations as needed
  },
});

export default darkModeTheme;
