/**
 * This wrapper component detects when it's children throw an error and
 * prevents the error from crashing the page altogether. Instead, this
 * component will display a user friendly error message in their language
 */

import React, { Component, ErrorInfo, ReactNode } from "react";
import axiosCall from '@services/axios';
import { t } from 'i18next';

export interface Props {
  debug?: string;
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const sendError = async (message, subject) => {
      if (window.location.hostname === 'localhost') return;
      const payload = {
        url: 'email/error',
        method: 'post',
        data: {
          message,
          subject
        },
      };
      try {
        await axiosCall(payload);
      } catch (err: any) {
        console.error('Could not send report', err);
        error = err?.response?.data?.message;
      }
    };

    const localStorageItems = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i); // Get the key at index i
      const value = localStorage.getItem(key); // Get the value associated with the key
      localStorageItems[key] = value; // Store the key-value pair in the object
    }

    const message =`
Debug: ${(this.props.debug || '').replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;')};<br/><br/>
LocalStorage: ${JSON.stringify(localStorageItems).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;')};<br/><br/>
${error.stack}`;
    const subject = `Something went wrong! ${process.env.PUBLIC_URL} ${error.message}`;
    sendError(message, subject);
    console.error(this.props.debug, '\n\n', error.stack);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="meditating-morgan-error">
          <span aria-hidden="true">
            <img alt={t('loginErrorOther')} src={`${process.env.REACT_APP_MORGAN_CDN}/Images/MorganMeditating.gif`} />
          </span>
          <p role="alert"><span className="sr-only">{t('tError') as string}: </span>{t('loginErrorOther') as string}</p>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
