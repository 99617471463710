/**
 * @file This component is a stylized Box component
 * for styling the Slot Machine Component.
 *
 * @author Harrison Fales <harrison.fales@swmc.com>
 * 
 */

import { Box, styled } from '@mui/system';

import { Button } from "@mui/base";
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const SlotMachineScreenSpotlight = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/SlotMachineScreenSpotlight.gif';
const BrickBg = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/BrickBg.png';
const ScreenSVG = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Screen.svg';
const ScreenPlaceholder = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/SlotMachineScreenPlaceholder.png';

const defaultOrange = "#ffdebf";
const selectedButtonColor = "#f5bb84";
const hoverColor = "#ebccb0";

interface SlotMachineContainerProps {
    playState?: number;
    theme?: Theme
}

export const SlotMachineContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'playState',
})(
    ({ playState, theme}: SlotMachineContainerProps ) => `
   background-image: url(${BrickBg});
   width: 100%;
   height: 101%;
   position: absolute;
   justify-content: center;
   align-items: center;
   display: flex;
   z-index: 999999;
   .creatingOrder {
       z-index: 99;
       color: white;
       position: absolute;
       top:50%;
       left: 50%;
       height: 10px;
       width: 10px;
   }
   .slotMachineShadow {
        width: ${useMediaQuery(theme.breakpoints.up('sm')) ? '375.56px' : '275.56px'};
        height: ${useMediaQuery(theme.breakpoints.up('sm')) ? '539.75px' : '439.74px'};
        background-color: rgba(244, 191, 97, 0.2);
        border-radius: 272.08px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
   }
   .slotMachine {
        position: relative;
        width: 342.18px;
        height: 480px;
        margin-top: 100px;
        justify-content: center;
        align-items: center;
        z-index: 2;
        transform: scale(${useMediaQuery(theme.breakpoints.up('sm')) ? '1' : '.8'});
        .slotMachineAnimationHolder {
            background-color: #404092;
            background-image: ${playState === 0 || !playState ? `url(${ScreenPlaceholder})` : 'none'};
            position: absolute;
            left: 41px;
            top: 20px; 
            z-index: 8;
            height: 165px;
            & img {
                height: 167px;
                width: 263px;
            }
        }
        .slotMachineWindow {
            width: 100.18px;
            z-index: 8;
            .staticLever {
                height: 59px;
                position: absolute;
                left: 326px;
                top: 20.5px;
            }
            .activeLever {
                position: absolute; 
                left: 325px;
                top: 15px;
            }
            .slotMachineScreenBackground {
                margin: 0 auto;
                width: 343.24px;
                height: 200.68px;
                background-image: url(${ScreenSVG});
                zIndex: 99;
            }
            .slotMachineSpotlight {
                background-image: url(${SlotMachineScreenSpotlight});
                height: 167px;
                width: 263px;
                background-repeat: no-repeat;
            }

        }
   }

  `
);

export const SlotMachineBody = styled(Box)(
    ({ theme }) => `
        position: absolute;
        left: 7.5px;
        margin-top: -16px; 
        width: 327.21px; 
        z-index: -1;
        .bodyDropShadowBorder {
            background-color: #f26f24; 
            padding: 12.82px 0;
             border-radius: 14.6562px;
            .bodyBackground {
                background-color: #FF993D;
                padding: 10px;
                border-radius: 14.6562px;
                .blockButtonBox {
                    width: 328.5px;
                    height: 75px;
                    border-radius: 14.66px;
                    background-color: rgba(48, 46, 43, 0.88);
                    position: absolute;
                    left: -0.2px;
                    z-index: 9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .blockMessage {
                        color: #ffffff;
                        font-weight: bold;
                    }
                }
                .quantityButtonGrid {
                    display: grid;
                    grid-template-columns: .25fr .5fr .25fr;
                    gap: 10px;
                    width: 100%;
                    height: 75px;
                    align-items: center;
                    padding: 0 15px 0 15px;
                    .countSelector {
                        transform: skew(-10deg);
                        height: 29.89px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        .countSelectorFlex {
                            transform: skew(10deg);
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            padding: 0 10px;
                        }
                    }
                }
                .sizeSelection {
                    display: grid;
                    grid-template-columns: .25fr .25fr .25fr .25fr;
                    gap: 10px;
                    height: 75px;
                    width: 100%;
                    align-items: center;
                    padding: 0 15px 0 15px;
                    .optionNotAvailable {
                        width: 328.5px;
                        height: 30px;
                        border-radius: 4px;
                        background-color: rgba(48, 46, 43, 0.88);
                        position: absolute;
                        left: -0.2px;
                        z-index: 9;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .optionNotAvailableText {
                            color: #ffffff;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
     `
);


export const ColorSelection = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: ' space-between',
    alignItems: 'center',
    minHeight: '46.69px',
    height: '75px',
    padding: '0 15px 0 15px',
    '& .optionNotAvailable': {
        width: '328.5px',
        height: '30px',
        borderRadius: '4px',
        backgroundColor: 'rgba(48, 46, 43, 0.88)',
        position: 'absolute',
        left: '-0.2px',
        zIndex: '9',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .optionNotAvailableText': {
            color: '#ffffff',
            fontWeight: 'bold',
        }
    },
    '& .colorButton': {
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '46.49px',
        width: '46.69px',
    },
    '& .innerColorButton': {
        position: 'absolute',
        top: '6px',
        left: '6px',
        display: 'block',
        borderRadius: '50%',
        width: '34.79px',
        height: '34.79px',
        boxShadow: '0px 0px 2.95431px 1.96954px rgba(0, 0, 0, 0.25)'
    }
})


export const PlayAndExit = styled('div')({
    display: 'flex',
    justifyContent: 'space-evenly',
    '& .playBackground': {
        position: 'relative',
        backgroundColor: '#F26F24',
        width: '121px',
        height: '41.56px',
        borderRadius: '10.5px',
        '& .playButton': {
            position: 'relative',
            top: '0px',
            right: '0px',
            borderRadius: '10.5px',
            backgroundColor: `${defaultOrange}`,
            width: '116px',
            height: '38.57px'
        }
    },
    '& .exitBackground': {
        position: 'relative',
        backgroundColor: `${defaultOrange}`,
        width: '111.45px',
        height: '41.56px',
        borderRadius: '10.5px',
        "& .exitButton": {
            position: 'absolute',
            top: '0px',
            right: '0px',
            borderRadius: '10.5px',
            backgroundColor: '#F26F24',
            width: '106.67px',
            height: '38.57px',
        }
    }

})

// Color selection button
export const ColorButton = styled(Button)({
    position: "relative",
    border: "none",
    borderRadius: "50%",
    width: "46.69px",
    height: "46.69px",
    boxShadow: "4.73629px 4.73629px 4.73629px rgba(0, 0, 0, 0.25)",
    cursor: "pointer",
    transition: "background 0.8s",
    // These are responsible for OnClick and OnHover ripple effect
    "&:hover": {
        background: `radial-gradient(circle, transparent 1%, ${hoverColor} 1%) center/15000%`,
    },
    "&:active": {
        backgroundSize: "100%",
        transition: "background 0s",
    },
});

interface OptionButtonProps {
    limitQuantity?: boolean
}

//Option button used for size, min, and max options
export const OptionButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'limitQuantity'
})(
    ({ limitQuantity }: OptionButtonProps) => `
    border: none;
    background-color: ${limitQuantity ? selectedButtonColor : defaultOrange};
    transform: skew(-10deg);
    -webkit-box-shadow: 5px 5px 5px 1px rgba(0,0,0,0.29);
    -moz-box-shadow: 5px 5px 5px 1px rgba(0,0,0,0.29);
    boxShadow: 5px 5px 5px 1px rgba(0,0,0,0.29);
    height: 29.89px;
    width: 100%;
    cursor: pointer;
    transition: background 0.8s;
    &:hover {
        background: radial-gradient(circle, transparent 1%, ${hoverColor} 1%) center/15000%;
    },
    &:active: {
        background-size: 100%;
        transition: background 0s;
    },
`);

export const MessageDisplayHolder = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '75px'
})

export const MessageDisplay = styled('div')({
    display: 'flex',
    border: 'none',
    backgroundColor: defaultOrange,
    transform: 'skew(-10deg)',
    height: '29.89px',
    width: '284.3px',
    transition: 'background 0.8s',
    '-webkit-box-shadow': '5px 5px 5px 1px rgba(0,0,0,0.29)',
    '-moz-box-shadow': '5px 5px 5px 1px rgba(0,0,0,0.29)',
    boxShadow: '5px 5px 5px 1px rgba(0,0,0,0.29)',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center'
})

export const OptionButtonPlaceholder = styled(Button)({
    border: "none",
    backgroundColor: "#FF993D",
    transform: "skew(-10deg)",
    height: "29.89px",
    width: "100%",
    transition: "background 0.8s",
});

// Plus or Minus button
export const QuantityButton = styled(Button)({
    backgroundColor: defaultOrange,
    border: "1px solid black",
    borderRadius: "50%",
    height: "25px",
    width: "25px",
    cursor: "pointer",
    transition: "background 0.8s",
    fontWeight: '700',
    "&:hover": {
        background: `radial-gradient(circle, transparent 1%, ${hoverColor} 1%) center/15000%`,
    },
    "&:active": {
        backgroundSize: "100%",
        transition: "background 0s",
    },
});