import { useEffect } from "react"
import useTimeout from "./useTimeout"

export default function useDebounce<T>(callback: T, delay: number, dependencies: unknown[]) {
  const { reset, clear } = useTimeout(callback, delay)
  useEffect(reset, [...dependencies, reset])
  useEffect(() => {
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
