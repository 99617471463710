import React, { createContext, useMemo, Dispatch, SetStateAction, ReactNode, useCallback, useContext, useState } from 'react';
import { IUserData } from '@interfaces/Conversation';
import { getOperatorAccessToken, getOperatorAuthenticated, getOperatorId, removeOperatorAccessToken, removeOperatorSession, getOperatorSessionToken } from '@storage/operatorStorage';
import { removeUserAccessToken, removeUserSession, setPreselectedLanguage, setUserSession, setWindowLocationPath } from '@storage/userStorage';
import { useNavigate, useParams } from 'react-router-dom';
import { SUPPORTED_LANGUAGES } from '@assets/Languages';
import axiosCall from '@services/axios';
import useAbortController from '@common/hooks/useAbortController';
import { CheckLocationInformationExistenceInLocalStorage } from '@common/LocationInformation';
import { setChatId } from '../Storage/userStorage';

/**
 * payload: passPayload | failPayload | resetPayload, could be added to 
 * dynamically add arguements for each action type while adhering to 
 * strict type safety.
 */
type userValue = null | IUserData & {
  activeErrandCount: number,
  logout: (session?: boolean, redirect?: boolean, reason?: string, error?: string) => void;
  setUser: Dispatch<SetStateAction<IUserData>>;
  login: (hashKey?: string) => Promise<Boolean>;
}

type userProviderProps = {
  children: ReactNode;
};

const UserContext = createContext<userValue | null>(null);

const useUserContext = () => {
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    // throwing this error helps in development when the context is out of scope.
    throw new Error('useUserContext must be inside a UserProvider');
  }
  return userContext;
};

/**
 * This provider function localizes states and improves maintainability whereever
 * this producer is implemented.
 * @param children (ReactNode): always a default state for context providers
 * @returns 
 */
const UserProvider = ({ children }: userProviderProps): JSX.Element => {
  const navigate = useNavigate();
  const { hashkey, referrer } = useParams();
  const { reset } = useAbortController();
  const [user, setUser] = useState<IUserData>({});
  const isUser = useMemo(() => { 
    // When an error is thrown we logout sometimes without a user object created, which
    // results in isUser being undefined, and failing the condition check.
    if(user && typeof user.isUser !== 'undefined'){ 
      return user.isUser; 
    } else return !window.location.pathname.includes('console') 
  }, [user]);

  const logout = useCallback(async (session = true, redirect = true, reason = '', error = '') => {
    if (session) {
      // invalidate session to remove session token
      if (isUser) {
        removeUserAccessToken();
      } else {
        removeOperatorAccessToken();
      }
      setUser((prev) => ({ ...prev, accessToken: '', }));
    } else {
      // invalidate user to remove everything
      if (isUser) {
        removeUserSession();
      } else {
        removeOperatorSession();
      }
      setUser({});
    }
    if (!redirect) {
      return;
    }
    if (isUser) {
      navigate(`/notices`, {
        state: {
          disableEngage: reason.toLowerCase() === 'banned',
          disableEngageReason: reason,
          systemMessage: error,
        },
      });
      return;
    }
    else {
      window.location.href = `${process.env.REACT_APP_PUBLIC_URL}/console/login`;
      return;
    }
  }, [isUser]);

  const login = useCallback(async (hashKey = '') => {
    if (isUser) {
      try {
        const locationData = await CheckLocationInformationExistenceInLocalStorage();
        const searchParams = new URLSearchParams(window.location.href);
        const referrerStr = (referrer || '').toLowerCase();
        const hashkeyStr = hashkey || hashKey || '';
        const isHashkeySupportedLanguage = SUPPORTED_LANGUAGES.includes(hashkeyStr.toLowerCase());
        const isReferrerSupportedLanguage = SUPPORTED_LANGUAGES.includes(referrerStr);
        const language = (isHashkeySupportedLanguage ? hashkeyStr.toLowerCase() : isReferrerSupportedLanguage ? referrerStr : (searchParams.get('lang') || '')).split('-')[0].toLowerCase();
        const lenderCompany = searchParams.get('t') !== '' ? searchParams.get('t') : 'SUNWST000';
        const request = {
          url: `hashkey/hash`,
          method: `POST`,
          data: {
            ...locationData,
            ...(language ? { language } : {}),
            ...(lenderCompany ? { lenderCompany } : {}),
            company: searchParams.get('company'),
            branch: searchParams.get('branch'),
            hashkey: isHashkeySupportedLanguage ? '' : hashkeyStr,
            referrer: isReferrerSupportedLanguage ? '' : referrerStr,
            // hashKey (Not hashkey!) exists on session restore and smls login
            isInitialLogin: !hashKey,
          }
        };
        const config = reset();
        const decodedHashkey = await axiosCall(request, config);

        if (decodedHashkey.message && decodedHashkey.reason) {
          console.error({request, response: decodedHashkey});
          logout(true, true, decodedHashkey.reason, decodedHashkey.message);
          return false;
        }
    
        const user = decodedHashkey.user;
        if (!user) {
          console.error('Operator object is not valid, unable to load the dashboard.')
          logout(true, true, '', 'Something went wrong while loading your account details.');
          return false;
        }

        if (user.banned) {
          logout(true, true, 'Your access has been temporarily disabled.', 'banned');
          return false;
        }

        setUserSession(user.accessToken, user._id);
        setPreselectedLanguage(user.language);
        setUser({ ...user, ssAccessToken: user.sessionToken, isOperator: Boolean(user.accessLevel), isUser: Boolean(!user.accessLevel)});
        setChatId(decodedHashkey?.parameters?.chatId);

        // remove hash key so user can't do the action again on refresh, especially on mobile
        // Append the basename before, but check to make sure its not '/'
        try {
          // cache the pathname for later use in redirecting to app
          setWindowLocationPath(window.location.pathname);
          navigate('/');
        } catch (error) {
          // added try/catch wrapper due to production error
          console.error(`User login replaceState encountered error`, error);
          logout();
          return false;
        }
        return true;
      } catch (error) {
        console.error(`User login encountered error`, error);
        logout();
        return false;
      }
    } else {
      try {
        if (!getOperatorAuthenticated()) {
          logout();
          return false;
        }
    
        const accessToken = getOperatorAccessToken();
        const ssAccessToken = getOperatorSessionToken();
        const userId = getOperatorId();
    
        if (!accessToken || !userId) {
          console.error('Operator token is not valid, unable to load the dashboard.')
          logout();
          return false;
        }

        const request = { url: `operator/${userId}` };
        const config = reset();
        const operator = await axiosCall(request, config) as IUserData;
        if (!operator) {
          console.error('Operator object is not valid, unable to load the dashboard.')
          logout();
          return false;
        }

        setUser({ ...operator, accessToken, ssAccessToken: ssAccessToken, isOperator: Boolean(operator.accessLevel), isUser: Boolean(!operator.accessLevel)});
        return true;
      } catch (error) {
        console.error(`Operator login encountered error`, error);
        logout();
        return false;
      }
    }
  }, [isUser, hashkey, referrer, logout, reset]);

  return (
    <UserContext.Provider value={{ ...user, setUser, logout, login }}>
      {children}
      {/* The new /notices page should be rendered here */}
    </UserContext.Provider>
  );
};

export { UserProvider, useUserContext };
