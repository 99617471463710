// These functions help set and remove field values based on their field names and chatID.
// Useful for setting fields so that they don't get asked in workflows
// For example, setting name, phone number for invite friend

import axiosCall from '@services/axios';


export async function insertField(fieldName, value, errand, fieldAttribute, _id) {
    console.log(`Inserting field '${fieldName}' into chat ${errand._id} with value: `, value)
    let data = {
        chat: errand._id,
        name: fieldName,
        value: value,
        owner: _id,
        ...(errand.activeContext ? {context: errand.activeContext} : {}),
        ...(errand.action.action.scope ? {scope: errand.action.action.scope} : {}),
        ...(errand.action._id ? {userAction: errand.action._id} : {}),
        ...(fieldAttribute ? {fieldAttribute: fieldAttribute._id} : {}),
    }

    let request = {
        url: `field/create`,
        method: 'POST',
        data: data,
    }
    
    try {
        let response = await axiosCall(request);
        console.log(`Message sent: `, response);
    } catch (error: any) {
        console.error(`Message Error: ${error.message}`);
    }
}


export const removeField = async (fieldName: string, errand) => {
    try {
        const result = await axiosCall({
            url: `field/delete/${fieldName}/${errand._id}`,
            method: 'DELETE',
        });
        if (result) {
            console.log(`${fieldName} field removed`);
        }
    } catch (error) {
    console.log(error);
    }
};