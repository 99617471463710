/**
 * The UserWay Accessibility Menu. Loaded on multiple pages to ensure ada compliance.
 * This includes both the script to attach the widget and the custom trigger to open it.
 * More information can be found here: https://userway.org, https://userway.org/tutorials/product-guides/custom-trigger/, https://userway.org/docs.
 */
import React from 'react';

import { ReactComponent as AccessibilityIcon } from '@assets/Icons/AccessibilityIcon.svg';

export const UserWayAccessibilityMenu = ({
  color,
  height,
}: {
  color: 'white' | 'black' | 'orange' | 'gray' | 'inherit';
  height: number;
}) => {
  return (
    <>
      <button
        id="UserWayAccessibilityMenu"
        style={{color: color, zIndex: 1}}
        onClick={() => {
          /**
           * This function was provided by UserWay. It needs the ignores to prevent compilation errors.
           * It allows the trigger to still function when routing without a full refresh.
           */
          // @ts-ignore
          // eslint-disable-next-line no-undef
          UserWay.widgetToggle();
        }}
      >
        <AccessibilityIcon height={height} width={height} />
      </button>
    </>
  );
};
