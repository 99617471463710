import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
import { styled /*, useTheme*/ } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { Close } from '../Assets/Icons';
import { useDropzone } from 'react-dropzone';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getImageSource from '../Common/getImageSource';
import themeFile from '@assets/Themes/default.json';
import { DialogPropsWithChildren } from '@mTypes/Console';

const mt = {
  mt:"12px"
};

const StyledStepper = styled(Stepper)(() => ({
  '& .MuiStepIcon-root.Mui-completed': {
    color: themeFile.palette.blue['210'],
  },
  '& .MuiStepIcon-root.Mui-active': {
    display: "none",
    color: themeFile.palette.blue['210'],
  },
}));

const EditProductDialog: FC<DialogPropsWithChildren> = (props) => {
  const [productIcon, setProductIcon] = useState(props.Product?.picture || "");
  // const theme = useTheme();
  const { t } = useTranslation();
  const activeStep = 0;
  const [unitSize, setUnitSize] = useState(props?.Product?.unitSize || '');
  const [unitColor, setUnitColor] = useState(props?.Product?.unitColor || '');
  const [type, setType] = useState(props?.Product?.type || '');
  const [ProductName, setProductName] = useState(props?.Product?.name || '');
  const [quantity, setQuantity] = useState(props?.Product?.quantity || 0);
  const [fileError,setFileError] = useState('');
  const [ProductDescription, setProductDescription] = useState(
    props?.Product?.description || ''
  );
  const steps = [t('ProductDetails')] //, t('ProductBody')];

  const onDrop = async (acceptedFiles) => {
    console.log(acceptedFiles);
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      if (file.size > 100000){
        return setFileError('Error: file size must be less than 100 kB.');
      }
      setFileError('');
      getBase64(file, async (result) => {
        // Get the data portion of the DataURL
        const fileData = result?.split(',')[1];
        console.log('setting product icon')
        setProductIcon(fileData);
      });
    }
  };

  /*************************************
   * Submit Function
   *************************************/
  const handleSubmit = () => {
    let data = {
      id: '',
      name: ProductName,
      description: ProductDescription,
      picture: productIcon,
      unitQuantity: quantity,
      unitSize: unitSize,
      unitColor: unitColor,
      type : type,
    };

    if (!props.edit) {
      props.onAdd(data);
    } else {
      data = { ...data, id: props.Product.id };
      props.onSubmit(data);
    }
  };

  const removeIcon = () => {
    setProductIcon("");
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    accept: {
      'image/svg+xml': ['.svg'],
      'image/jpeg' : ['.jpeg'],
      'image/jpg' : ['.jpg'],
      'image/png' : ['.png']
    }
  });

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {props.edit ? t('editProduct') : t('createProduct')}
          </Typography>
          <Close
            style={{ width: '12px', cursor: 'pointer' }}
            color="var(--gray210)"
            onClick={() => props.onClose()}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '40vw' }}>
        <StyledStepper
          activeStep={activeStep}
          alternativeLabel>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </StyledStepper>
          <Stack>
            <TextField
              autoFocus
              color="info"
              label={`${t('nameCol')}*`}
              onChange={(e) => setProductName(e.target.value)}
              size="small"
              sx={{...mt}}
              value={ProductName}
              variant="outlined"
            />
            <TextField
              color="info"
              label={`${t('descriptionCol')}*`}
              multiline
              onChange={(e) => setProductDescription(e.target.value)}
              rows={1}
              size="small"
              sx={{...mt}}
              value={ProductDescription}
              variant="outlined"
            />
            <TextField
              color="info"
              label={`${t('UnitColor')}`}
              multiline
              onChange={(e) => setUnitColor(e.target.value)}
              rows={1}
              size="medium"
              sx={{...mt}}
              value={unitColor}
              variant="outlined"
            />
            <TextField
              color="info"
              label={`${t('unitSize')}`}
              multiline
              onChange={(e) => setUnitSize(e.target.value)}
              rows={1}
              size="medium"
              sx={{...mt}}
              value={unitSize}
              variant="outlined"
            />
            <TextField
              color="info"
              label={`${t('unitType')}`}
              multiline
              onChange={(e) => setType(e.target.value)}
              rows={1}
              size="medium"
              sx={{...mt}}
              value={type}
              variant="outlined"
            />
            <br/>
            <TextField
            type="number"
            error={quantity < 0}
            size="small"
            color="info"
            variant="outlined"
            label={t("quantityCol")}
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
          <br/>
                    <Stack direction="row" justifyContent="space-between">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button variant="outlined" color="info" onClick={() => open()}>{t("uploadPicture")}</Button>
            </div>
            {productIcon &&
              <Stack
                sx={{
                  border: "1px solid var(--gray040)",
                  borderRadius: "4px"
                }}
                width="150px"
                direction="row"
                justifyContent="space-around"
                alignItems="center">
                <Tooltip title={t("removeIcon")} placement="left">
                  <Close style={{ width: "12px", cursor: "pointer" }} color="var(--gray210)" onClick={() => removeIcon()} />
                </Tooltip>
                <Typography sx={{ mt: "2px", textTransform: "Uppercase", color: "var(--gray210)", fontSize: "14px", fontWeight: "500" }} >{t("currentIcon")} </Typography>
                <Box component="img" width="20px" color="black" height="20px" style={{ filter: "grayscale(0%)" }} src={getImageSource(productIcon)}>
                </Box>
              </Stack>
            }
          </Stack>
          </Stack>
        <Typography color={themeFile.palette.red['400']}>{fileError}</Typography>
      </DialogContent>
      <DialogActions>
          <Button
            disabled={ProductName === '' || ProductDescription === '' || quantity < 0 || quantity === ''}
            variant="contained" color="info"
            onClick={() => handleSubmit()}>
            {t('doneButton')}
          </Button>

      </DialogActions>
    </Dialog>
  );
};

export default EditProductDialog;
