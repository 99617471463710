import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import { Authenticated, NotAuthenticated } from '@assets/Icons';
import Avatar from './Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogHeader from './DialogHeader';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import ScrollBox from './ScrollBox';
import getImageSource from '../Common/getImageSource';
import axiosCall from '../Services/axios';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IParticipant } from '@interfaces/Conversation';
import ReferrerModal from './ReferrerModal';
import useInitialMount from '@common/hooks/useInitialMount';

const ParticipantsDialog = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [participants, setParticipants] = useState<IParticipant[]>([...props.errand.participants.filter((p) => p.active)]);

  const handleBlur = async (participant) => {
    console.log('DEBUG setting blur to', !participant?.messageHistoryAllowed);
    await axiosCall({
      url: `chat/${props.errand?._id}/participant/${participant?._id}`,
      method: 'put',
      data: {
        messageHistoryAllowed: !participant?.messageHistoryAllowed
      }
    });
    setParticipants((prev) => {
      let index = prev.findIndex((p) => p.userData?._id === participant.userData?._id);
      participants[index].messageHistoryAllowed = !participant.messageHistoryAllowed;
      return [...prev];
    });
  }

  const getParticipants = useCallback(async () => {
    try {
      // make an API call to get the participants of the chat
      const participants = await axiosCall({
        url: `chat/${props.errand._id}/participant`,
      });
      if (participants && participants.length > 0) {
        setParticipants(participants.filter((p) => p.active));
      }
    } catch (error) {
      console.error(`Could not get participants`, error)
    }
  }, []);

  useInitialMount(getParticipants);
  
  console.error(participants);
  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t("participantsDialogTitle")} onClose={() => props.onClose()} />
      <DialogContent> {/*p={5}>*/}
        <ScrollBox height={250}>
          <List>
            {participants
              .filter((x) => location.state?.tab === 'history' ? !!x.userData : x.userData && x.userData?._id !== props.operatorData?._id)
              .map((p, index) => {
                const isUser = p.userType === 'User';
                let onlineList = isUser ? props.onlineUsers : props.onlineOperators;
                let onlineStatus = onlineList?.filter((ou) => ou?._id === p.userData?._id)?.[0]?.status;
                const access = isUser ? props.userAccessData.find((a) => a.user === p.userData?._id) : {};
                const authenticated = Boolean(access?.ssAccessToken);

                return (
                <ListItem
                  key={index}>
                  <Avatar
                    status={onlineStatus}
                    alt={`${p.userData?.firstname} ${p.userData?.lastname}`}
                    img={getImageSource(p.userData?.avatar) || null} />
                  <ListItemText
                    primary={(p.userData?.firstname + ' ' + p.userData?.lastname).toLowerCase()}
                    sx={{ paddingLeft: 2, textTransform: 'capitalize' }}
                  />
                  {
                    location.state?.tab !== 'history' &&
                    location.state?.tab !== 'live' &&
                    <Stack direction="row" justifyContent="flex-end" width="auto">
                      {isUser && (
                        <>
                          <Tooltip title={authenticated ? "Authenticated" : "Not Authenticated"}>
                          <IconButton
                            sx={{ width: '40px' }}
                            onClick={async () => {
                              if (authenticated) return;
                              props.shieldClickHandler(p.userData?._id)
                            }}>
                              {authenticated
                                ? <Authenticated style={{ height: '24px', width: 'auto' }} />
                                : <NotAuthenticated style={{ height: '24px', width: 'auto' }} />}
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      {isUser && <ReferrerModal user={p.userData} />}
                      {isUser &&
                        <Tooltip title={p.userData?.banned ? t('participantsDialogUnbanTooltip') : t('participantsDialogBanTooltip')}>
                          <IconButton
                            onClick={async () => {
                              console.log('DEBUG setting banned to', !p.userData?.banned);
                              let update = await axiosCall({
                                url: `user/${p.userData?._id}`,
                                method: 'put',
                                data: {
                                  banned: !p.userData?.banned
                                }
                              });
                              p.userData = update;
                              props.setErrands((prev) => {
                                if (!Array.isArray(prev)) {
                                  console.warn('setErrands prev is not an array');
                                  prev = [];
                                }
                                let index = prev.findIndex((e) => e._id === props.errand._id);
                                if (index === -1) return prev; // don't spread so we don't trigger dependency arrays
                                prev[index].participants = [...prev[index].participants.filter((e) => e?._id !== p?._id), p];
                                return [...prev];
                              });
                              setParticipants((prev) => {
                                return [...prev.filter((e) => e?._id !== p?._id), p];
                              });
                            }}>
                            {
                              p.userData?.banned ?
                                <PersonIcon />
                                :
                                <NoAccountsIcon />
                            }
                          </IconButton>
                        </Tooltip>
                      }
                      {isUser && 
                        <Tooltip title={p?.messageHistoryAllowed ? t('participantsDialogBlurTooltip') : t('participantsDialogUnblurTooltip')}>
                          <IconButton onClick={async () => {
                            await handleBlur(p);
                          }}>
                          {
                          p?.messageHistoryAllowed ? 
                            <PublicIcon />
                            :
                            <PublicOffIcon />
                          } 
                          </IconButton>
                        </Tooltip>
                      }
                      {!p.primary && (
                        <Tooltip title={t('participantsDialogDeleteTooltip')}>
                          <IconButton
                            onClick={async () => {
                              await axiosCall({
                                url: `chat/${props.errand?._id}/participant/${p?._id}`,
                                method: 'delete',
                                data: {
                                  removed: `${props.operatorData?.firstname.charAt(0).toLocaleUpperCase() +
                                    props.operatorData?.firstname.substr(1).toLocaleLowerCase()
                                    }`,
                                },
                              });

                              // Remove that user we just deleted
                              props.setErrands((prev) => {
                                if (!Array.isArray(prev)) {
                                  console.warn('setErrands prev is not an array');
                                  prev = [];
                                }
                                let index = prev.findIndex((e) => e._id === props.errand._id);
                                if (index === -1) return prev; // don't spread so we don't trigger dependency arrays
                                prev[index].participants = prev[index].participants.filter((e) => e?._id !== p?._id);
                                return [...prev];
                              });
                              console.log(`Removed participant ${p?._id} from ${props.errand?._id}`);
                              //props.onClose();
                              setParticipants((prev) => {
                                prev = prev.filter((pa) => pa._id !== p._id);
                                return [...prev];
                              });
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  }
                </ListItem>
              )})}
          </List>
        </ScrollBox>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose()}>
          {t('closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParticipantsDialog;
