// React
import React, { 
  PropsWithChildren,
  useEffect, 
  useRef, 
  useState 
} from 'react';

// Material UI 
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import { isMobileOrTablet } from '@common/deviceTypeHelper';

// Border Radius of slider as well as thumb
const SLIDER_BORDER_RADIUS = 10;
// Bg Color of a draggable thumb element
const THUMB_BG_COLOR = 'var(--orange700)';

const ConfirmSliderContainer: React.FC<PropsWithChildren<any>> = styled(Box, {
  shouldForwardProp: (prop: any) => 
    prop !== 'sliderBgColor' &&
    prop !== 'sliderWidth' &&
    prop !== 'sliderHeight' &&
    prop !== 'trailBgColor'
  })<any>(({ theme, sliderBgColor, trailBgColor, sliderWidth, sliderHeight }) => `
    height: ${sliderHeight}px;
    width: ${sliderWidth}px;
    position: relative;
    pointer-events: all;
    z-index: 290000000;
    background-color: ${sliderBgColor};
    border-radius: ${SLIDER_BORDER_RADIUS}px;
    & #slider-text {
      position: absolute;
      color: grey;
      margin: 0;
    }
    & #slider-thumb-container {
      position: relative; 
      height: 100%;
      // thumb width
      width: 30px; 
      pointer-events: all;
      display: flex;
      justify-content: center;
      align-items: center;
      // disables accidental verticall scroll on mobile view
      touch-action: none;
    }
    & #slider-trail {
      background-color: ${trailBgColor};
      position: absolute;
      height: 100%;
      border-radius: ${SLIDER_BORDER_RADIUS}px;
    }
    & p::selection {
      color: initial;
      background: none;
    }
    & #slider-thumb::selection {
      color: initial;
      background: none;
    }
    & .pulsate {
        -webkit-animation: pulsate 2.0s ease;
        -webkit-animation-iteration-count: infinite; 
        opacity: 0.3;
        transform: translateX(0px);
    }
    @-webkit-keyframes pulsate {
        0% { 
            transform: translateX(0px);
            opacity: 0.3;
        }
        50% { 
            transform: translateX(3px);
            opacity: 1.0;
        }
        100% { 
            transform: translateX(0px);
            opacity: 0.3;
        }
    }
  `
);

/**
 * @param {{
 *  confirmedHandler: () => void,
 *  children: JSX.Element,
 *  placehodlerText: string,
 *  sliderBgColor: string,
 *  trailBgColor: string,
 *  sliderHeight: number,
 * }} props
 */
const ConfirmSlider: React.FC<PropsWithChildren<any>> = (props: any) => {
  // all elements refs
  const sliderRef = useRef<any>();
  const sliderThumbRef = useRef<any>();
  const sliderTextRef = useRef<any>();
  // destructure props
  const { 
    confirmedHandler, 
    placeholderText, 
    sliderBgColor, 
    trailBgColor, 
    sliderHeight 
  } = props;
  // (left: ${thumbX}px)
  const [thumbX, setThumbX] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(300);
  const [isDragging, setIsDragging] = useState(false);
  const [thumbWidth, setThumbWidth] = useState(0);
  const [textOffsets, setTextOffsets] = useState({left: 0, top: 0})
  const [showPlaceholderText, setShowPlaceholderText] = useState(true);
  // additional size in pixels for confirmation space
  const confirmationSpace = 30;
  // confirm flag to not to run a multiple times the user confirmed handler
  let confirmedHandlerExecuted = false;

  // determine user device type
  // if current device is either mobile or tablet 
  // set isDestop to false, if not mobile nor tablet
  // set isDesktop to true.
  const isDesktop = !isMobileOrTablet();

  // Helper functions for calculating dimensions of different slider UI elements.
  const getHandleWidth = () => sliderThumbRef.current.offsetWidth;
  const getSliderTextWidth = () => sliderTextRef.current.offsetWidth;
  const getSliderWidth = () => sliderRef.current.offsetWidth;
  const getXMin = () => sliderRef.current.offsetLeft + getHandleWidth() / 2;
  const getXMax = () => getXMin() + getSliderWidth();
  const calcLeftCorner = (x) => x - getHandleWidth() / 2;
  const calcRightCorner = (x) => x + getHandleWidth() / 2;
  const calcDiff = (a, b) => Math.abs(a - b); 
  const isSliderTextFullySeen = () => getSliderTextWidth() <= getSliderWidth() - getHandleWidth();
  const calcSliderWidth = () => getSliderTextWidth() + getHandleWidth() + 20; // 20 additional width

  // calculates top/left offset for slider text element.
  // returns (difference between thumbWidth/thumbHeight and sliderTextParagraphWidth/sliderTextParagraphHeight) / 2.
  // fDim is thumb width/height, sDim is sliderTextParagraph width/height.
  const calcTextOffset = (fDim, sDim) => Math.floor(calcDiff(fDim, sDim) / 2);
  const calcTextLeftOffset = () => {
    return getHandleWidth() + 6; // small additional space
  };
  const calcTextTopOffset = () => {
    return calcTextOffset(sliderRef.current.offsetHeight, sliderTextRef.current.offsetHeight)
  }

  const userConfirmedHandler = () => {
    // get the x coordinate of where the left edge of thumb 
    // should be in such way that it is correctly displayed ar the right most position.
    const leftCornerEndPosX = getXMax() - getHandleWidth() - getXMin();
    // move thumb to the very right end position.
    setThumbX(leftCornerEndPosX);
    // disable drag
    setIsDragging(false);
    // call parent confirm handler from props.
    confirmedHandler();
  }

  /**
   * @summary Checks X coordinate to be in confirmed space. (the most right position).
   * @param {number} x cursor coordinate
   * @returns boolean
   */
  const isInConfirmedPosition = (x) => {
    const handleWidth = getHandleWidth();
    // calculate the left corner x coordinate of a thumb
    const leftCornerX = calcLeftCorner(x);
    // confirmed space boundary x coordinate = (max - thumb width - additional confirmation space)
    const confirmSpaceStartX = getXMax() - handleWidth - confirmationSpace;
    // check if left corner is in confirmed space
    return leftCornerX > confirmSpaceStartX;
  }

  /**
   * @summary Ends tracking of mouse position.
   * @param {number} xPos
   * @returns void
   */
  const endMouseTracker = (xPos) => {
    // if not in dragging, do nothing
    if(!isDragging) return;
    
    // if not at the confirmation position
    // move to the very start
    if(isInConfirmedPosition(xPos) === false)
      setThumbX(0);

    // disable dragging
    setIsDragging(false);
  }

  const startMouseTracker = () => {
    setIsDragging(true)
  };
  
  /**
   * @summary Main function that udpates the position of thumb on each mousemove event and checks for confirmation position of a thumb.
   * @param {number} xPos
   * @returns void
   */
  const handleMouseMove = (xPos) => {
    // if User is not dragging anything
    // do nothing
    if(!isDragging) return;

    // if user is currently dragging the thumb
    // get all locals
    const handleWidth = getHandleWidth();
    // get coords of left/right corners of a draggable thumb.
    const leftCornerX = calcLeftCorner(xPos);
    const rightCornerX = calcRightCorner(xPos)

    // calculate updated thumb x coordinate and floor it.
    const updatedX = Math.floor(xPos - getXMin() - handleWidth / 2);

    // check if User dragged thumb to confirmed space (very right position)
    // set it to be not draggable.
    if(isInConfirmedPosition(xPos)) {
      // Check if it was not executed yet to execute it only once
      if(!confirmedHandlerExecuted) {
        userConfirmedHandler();
        // set flag to avoid calling confirmedHandler multiple times
        confirmedHandlerExecuted = true;
      }
    } 
    // if not dragged to confirmed space
    // just update the position of thumb
    else {
      // check boundaries and move thumb 
      if(rightCornerX <= getXMax() && leftCornerX >= getXMin()) {
        // console.log("All good....")
        // update the pos of thumb 
        setThumbX(updatedX);
      }
      
    }
      
  }

  // component did mount logic
  useEffect(() => {
    const handleWidth = getHandleWidth();
    // set all slider txt/elements css props
    setTextOffsets({
      left: calcTextLeftOffset(), 
      top: calcTextTopOffset()
    });
    setThumbWidth(handleWidth);
    // check if slider placeholder text is not fitting into the slider width, hide it.
    if(!isSliderTextFullySeen()) {
      setShowPlaceholderText(false);
    }

    // set slider width
    // for all desktop, mobile and tablet the width should be as min as possible.
    setSliderWidth(calcSliderWidth());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Desktop Wrapped handlers 
  // These are created for properly adding/removing handlers from window object
  const desktopMouseMoveHandler = (e) => {
    handleMouseMove(e.clientX);
  }

  const desktopEndMouseTrackerHandler = (e) => {
    endMouseTracker(e.clientX);
  }
  // re-runs whenever isDragging is changed
  useEffect(() => {
    // if current device is not desktop, omit setting up the event listeners for desktop mouse events.
    if(isDesktop) {
      // setup 2 event listeners for slider logic
      //  mouseTracker: for tracking the mouse coords and move the slider thumb accordingly
      //  endMouseTracker: left mouse release click logic 
      window.addEventListener('mousemove', desktopMouseMoveHandler);
      window.addEventListener('mouseup', desktopEndMouseTrackerHandler)

      // unmount logic
      return () => {
        console.log("unmounting....")
        // remove all listeners
        window.removeEventListener('mousemove', desktopMouseMoveHandler);
        window.removeEventListener('mouseup', desktopEndMouseTrackerHandler); 
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDragging])


  // UI parts
  // renders placeholder text if it fits the width of the slider. (for small screens)
  const renderPlaceholderText = () => {
    if(!showPlaceholderText) return null;

    return (
      <p 
        ref={sliderTextRef}
        id='slider-text'
        className='pulsate'
        style={{
          top: `${textOffsets.top}px`,
          left: `${textOffsets.left}px`,
        }}
      >
        {placeholderText}
      </p> 
    );
  }

  return (
    <ConfirmSliderContainer
      // Important!!! 
      // This prevents any elements under ConfirmSliderContainer from selection when user starts dragging the thumb.
      onMouseDown={(e: { preventDefault: () => any; }) => e.preventDefault()}
      sliderWidth={sliderWidth}
      sliderBgColor={sliderBgColor}
      trailBgColor={trailBgColor}
      sliderHeight={sliderHeight}
      ref={sliderRef}>

      {/* Slider text */}
      {renderPlaceholderText()}
      
      {/* Slider thumb Trail Div */}
      <div 
        id='slider-trail'
        style={{
          width: `${thumbX + thumbWidth}px`,
        }}
      />
  
      <Box
        ref={sliderThumbRef}
        sx={{ 
          left: `${thumbX}px`,
          backgroundColor: `${THUMB_BG_COLOR}`,
          borderRadius: `${SLIDER_BORDER_RADIUS}px`,
        }}
        id='slider-thumb-container'
        onMouseDown={(e) => {
          startMouseTracker();
        }}
        // Mobile touch event handlers
        onTouchStart={(e) => {
          startMouseTracker();
        }}
        onTouchMove={(e) => {
          handleMouseMove(e.changedTouches[0].clientX)
        }}
        onTouchEnd={(e) => {
          endMouseTracker(e.changedTouches[0].clientX);
        }}>
        {props.children}
      </Box>
    </ConfirmSliderContainer>
  );
}

export default ConfirmSlider;
