import { Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AttendDialog from '@components/AttendDialog';
import axiosCall from '@services/axios';
import { handlePreviews } from '@common/loadPreview';
import { getDropdownDescription } from '@common/StringUtils';
import triggerBrowserNotificationAlert from '@common/triggerBrowserNotificationAlert';
import { ChatType } from '@common/ChatType';
import { useSocketContext } from '@contexts/socket';
import { getPrimaryParticipant } from '@common/errandUtils';
import { useUserContext } from '@contexts/user';
const newUserAudio = new Audio(process.env.REACT_APP_MORGAN_CDN + '/sound/newuser.mp3');
const newMessageAudio = new Audio(process.env.REACT_APP_MORGAN_CDN + '/sound/notification.mp3');

const SnackbarComponent = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { eventsSocket, messagesSocket, isEventsConnected, isMessagesConnected } = useSocketContext();
  const { accessLevel, _id } = useUserContext();
  const [attendedChat, setAttendedChat] = useState('');
  const [notificationQueue, setNotificationQueue] = useState([]);
  // react cannon requires this to be a useEffect rather than a let variable. This was allowing notifications to appear for the current chat.
  const joinedChat = useRef([]); // used to control new message alerts, prevent sending new message notifications to chats were the operator is actively listening on.

  /**
   * This function is called when the operator presses the x button on any of the snackbars
   */
  const handleClose = (notification) => {
    // 4/10/2023 - Dennis, this way of removing the notification from the queue is more precise now and it won't removed queued new messages notifications due to direct chat id comparison.
    setNotificationQueue((prev) => {
      const index = prev.findIndex(
        (x) =>
          x.chat === notification.chat &&
          x.message === notification.message &&
          x.status === notification.status
      );

      return [...prev.filter((e, i) => i !== index)];
    });
  };

  /**
   * This function is called when the operator presses the reply button on the bottom right hand side snackbar
   */
  const openChat = async (notification) => {
    handleClose(notification);

    if (notification?.type === 'sms') {
      return navigate('/console', {
        state: {
          module: 'dashboard',
          tab: 'sms',
        },
      });
    } else {
      const chat = await axiosCall({
        url: `chat/${notification?.chat}`,
        method: 'GET',
      });

      let tab = 'mine';
      if (chat.type === ChatType.Team || chat.type === ChatType.Group) {
        tab = chat.type;
      }

      navigate('/console', {
        state: {
          module: 'conversations',
          tab: tab,
          chat: notification?.chat,
        },
      });
    }
  };

  /**
   * This function is called when the operator presses the attend button on the top right hand side snackbar
   */
  const attendChat = async (notification) => {
    let attend = await axiosCall({
      url: `chat/${notification?.chat}/attend`,
      method: 'post',
      data: {
        userId: _id,
        userType: 'Operator',
        chat: notification?.chat,
      },
    });

    if (attend?.attend === true) {
      navigate('/console', {
        state: {
          module: 'conversations',
          tab: 'mine',
          chat: notification?.chat,
        },
      });
    } else {
      // There are other operators so just open the dialog explaining this
      setAttendedChat(notification?.chat);
    }
  };

  useEffect(() => {
    if (!isEventsConnected) return;

    props.setIsSnackbarReady(true);

    // #starteventssocketfunctions
    const snackbarUnattendedConversationAlert = (payload) => {
      // Get unattended conversations alerts
      console.log(`Events Socket - SnackbarComponent - (unattended-conversation-alert)`, payload);
  
      // Show the primary participant's name on the alert
      // const primaryUser = payload?.data?.participants?.filter((el) => el.primary === true)[0];
      const primaryUser = getPrimaryParticipant(payload?.data);
      const user = primaryUser ? primaryUser : payload?.data?.participants[0];
      const name =
        user?.userData?.firstname !== undefined
          ? `${user?.userData?.firstname} ${user?.userData?.lastname}`
          : 'Anonymous user';
      const message = t('snackBarAttendMessage').replace('[name]', name);
      const browserMessage = t('snackBarBrowserAttendMessage');
  
      if (document.visibilityState === 'hidden') {
        triggerBrowserNotificationAlert({
          status: 'unattended',
          title: name,
          chat: payload?.data?._id,
          message: browserMessage,
          notificationType: 'unattended',
          callback: attendChat,
        });
      } else if (joinedChat.current?.indexOf(payload?.data?.chat?._id) === -1) {
        try {
          newUserAudio.play();
        } catch (err) {
          console.log(err);
        }
      }
      setNotificationQueue((prev) => [
        ...prev,
        {
          status: 'unattended',
          type: payload?.data?.type,
          userName: name,
          chat: payload?.data?._id,
          message,
        },
      ]);
    };
  
    const snackbarUnattendedErrandAlert = (payload) => {
      // Get unattended errands alerts
      console.log(`Events Socket - SnackbarComponent - (unattended-errand-alert)`, payload);
  
      // Show the primary participant's name on the alert
      // const primaryUser = payload?.data?.participants?.filter((el) => el.primary === true)[0];
      const primaryUser = getPrimaryParticipant(payload?.data);
      const user = primaryUser ? primaryUser : payload?.data?.participants[0];
      const name =
        user?.userData?.firstname !== undefined
          ? `${user?.userData?.firstname} ${user?.userData?.lastname}`
          : 'Anonymous user';
      const message = t('snackBarAttendMessage').replace('[name]', name);
      const browserMessage = t('snackBarBrowserAttendMessage');
  
      if (document.visibilityState === 'hidden') {
        triggerBrowserNotificationAlert({
          status: 'unattended',
          title: name,
          chat: payload?.data?._id,
          message: browserMessage,
          notificationType: 'unattended',
          callback: attendChat,
        });
      } else if (joinedChat.current?.indexOf(payload?.data?.chat?._id) === -1) {
        try {
          newUserAudio.play();
        } catch (err) {
          console.log(err);
        }
      }
      setNotificationQueue((prev) => [
        ...prev,
        {
          status: 'unattended',
          type: payload?.data?.type,
          userName: name,
          chat: payload?.data?._id,
          message,
        },
      ]);
    };
  
    const snackbarRemoveChatAlert = (payload) => {
      // Remove unattended and notifications
      console.log(`Events Socket - SnackbarComponent - (remove-chat-alert)`, payload);
      if (typeof payload?.data?._id === 'string') {
        // Remove this chat from all lists and notification queues
        setNotificationQueue((prev) => [...prev.filter((n) => n?.chat !== payload?.data?._id)]);
      }
    };
  
    const snackbarNewMessageAlert = (payload) => {
      console.log(`Events Socket - SnackbarComponent - (notifications-update)`, payload);
      let data = payload?.data;
      const name = data?.messageId
        ? data?.messageId?.sender?._id !== _id
          ? data?.messageId?.sender?.firstname.charAt(0).toUpperCase() +
              data?.messageId?.sender?.firstname.substr(1).toLowerCase()?.slice(0, 25) +
              ': ' || 'Anonymous User: '
          : ''
        : data?.smsId?.from + ': ';

      let message = handlePreviews(data?.messageId);

      if (message.length > 30) {
        message = message.trim().slice(0, 30) + '...';
      }
  
      // Parse the field data to show the description by extracting it from the message. 
      message = getDropdownDescription(message);
  
      if (document.visibilityState === 'hidden') {
        triggerBrowserNotificationAlert({
          status: 'newMesssage',
          title: name,
          chat: data?.chat?._id,
          message: message,
          notificationType: 'newMessage',
          callback: openChat,
        });  
      } else if (joinedChat.current?.indexOf(data?.chat?._id) === -1) {
        try {
          newMessageAudio.play();
        } catch (err) {
          console.log(err);
        }
      }
      // 4/5/2023 - Dennis, Morgan Messages should not send notification updates for chats this user is listening on
      // moved this statement down as this should only affect the notifications that appear in the snackbar alerts, not the browser notifications
      if (joinedChat.current?.indexOf(data?.chat?._id) === -1) {
        setNotificationQueue((prev) => [
          ...prev,
          {
            status: 'newMesssage',
            type: data?.messageId ? data?.chat.type : 'sms',
            userName: name,
            chat: data?.chat?._id,
            message: `${name}${message}`,
          },
        ]);
      }
    };
    // #endeventssocketfunctions

    console.log(`Events Socket - SnackbarComponent - (on)`);
    if (accessLevel !== 'Level 2') {
      eventsSocket.current?.on('unattended-conversation-alert', snackbarUnattendedConversationAlert);
      eventsSocket.current?.on('unattended-errand-alert', snackbarUnattendedErrandAlert);
    }
    eventsSocket.current?.on('remove-chat-alert', snackbarRemoveChatAlert);
    eventsSocket.current?.on('notifications-update', snackbarNewMessageAlert);
    return () => {
      console.log(`Events Socket - SnackbarComponent - (off)`);
      if (accessLevel !== 'Level 2') {
        eventsSocket.current?.off('unattended-conversation-alert', snackbarUnattendedConversationAlert);
        eventsSocket.current?.off('unattended-errand-alert', snackbarUnattendedErrandAlert);
      }
      eventsSocket.current?.off('remove-chat-alert', snackbarRemoveChatAlert);
      eventsSocket.current?.off('notifications-update', snackbarNewMessageAlert);
      props.setIsSnackbarReady(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected]);

  useEffect(() => {
    if (!isMessagesConnected) return;

    // #startmessagessocketfunctions
    const snackbarJoinChat = (payload) => {
      console.log(`Messages Socket - SnackbarComponent - (joined-chat)`, payload);
      joinedChat.current = [...joinedChat.current, payload?.data];
      // clear all notificaitons from this chat automatically so the operator doesn't have to click X on each unread message
      setNotificationQueue((prev) => [...prev.filter((n) => n.chat !== payload?.data)]);
    };
  
    const snackbarLeaveChat = (payload) => {
      console.log(`Messages Socket - SnackbarComponent - (left-chat)`, payload);
      joinedChat.current = joinedChat.current.filter((chat) => chat !== payload?.data);
      // clear all notificaitons from this chat automatically so the operator doesn't have to click X on each unread message
      setNotificationQueue((prev) => [...prev.filter((n) => n.chat !== payload?.data)]);
    };
    // #endmessagessocketfunctions

    console.log(`Messages Socket - SnackbarComponent - (on)`);
    messagesSocket.current?.on('joined-chat', snackbarJoinChat);
    messagesSocket.current?.on('left-chat', snackbarLeaveChat);
    return () => {
      console.log(`Messages Socket - SnackbarComponent - (off)`);
      messagesSocket.current?.off('joined-chat', snackbarJoinChat);
      messagesSocket.current?.off('left-chat', snackbarLeaveChat);

      //#startondisconnectlogic
      joinedChat.current = [];
      //#endondisconnectlogic
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected]);

  return (
    <>
      {attendedChat && (
        <AttendDialog attendedChat={attendedChat} setAttendedChat={setAttendedChat} />
      )}

      {notificationQueue
        .filter((x) => x.status === 'unattended')
        .slice(0, 1)
        .map((notification, index) => (
          <Snackbar
            key={index}
            open={notification?.message !== '' ? true : false}
            onClose={(e, reason) => reason !== 'clickaway' && handleClose(notification)}
            message={notification?.message}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  size='small'
                  sx={{ backgroundColor: 'var(--green900)', color: 'var(--gray000)', marginRight: 1 }}
                  onClick={() => attendChat(notification)}>
                  {t('snackbarAttend')}
                </Button>
                <IconButton onClick={() => handleClose(notification)} sx={{ color: 'var(--gray000)' }}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </div>
            }
          />
        ))}
      {notificationQueue
        // prevents message notifications from showing while you're in a chat.
        .filter((x) => x.status === 'newMesssage')
        .slice(0, 1)
        .map((notification, index) => (
          <Snackbar
            key={index}
            open={notification?.message !== '' ? true : false}
            onClose={(e, reason) => reason !== 'clickaway' && handleClose(notification)}
            message={notification?.message}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  size='small'
                  sx={{ backgroundColor: 'var(--green900)', color: 'var(--gray000)', marginRight: 1 }}
                  onClick={() => openChat(notification)}>
                  {t('snackbarReply')}
                </Button>
                <IconButton onClick={() => handleClose(notification)} sx={{ color: 'var(--gray000)' }}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </div>
            }
          />
        ))}
    </>
  );
};

export default memo(SnackbarComponent);
