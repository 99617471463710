import React, { Dispatch, SetStateAction } from 'react';
import { MorganTheme, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import {
    IconButton,
    InputBase
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import Styles from '@styles/UserPromptList.module.css';

type TUserPromptListHeaderProps = {
    searchString: string;
    setSearchString: Dispatch<SetStateAction<string>>;
    isSearchOpen: boolean;
    setIsSearchOpen: Dispatch<SetStateAction<boolean>>;
    handleSearchClose: () => void;
}

const UserPromptListHeader = ({
    searchString, setSearchString, isSearchOpen, setIsSearchOpen, handleSearchClose
}: TUserPromptListHeaderProps) => {
    const { t } = useTranslation();
    const theme: MorganTheme = useTheme();

    return (
        <>
            {
                isSearchOpen ?
                    <header className={Styles.openSearchHeader}>
                        <Box className={Styles.openSearchContainer}>
                            <IconButton className={Styles.searchIcon}>
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                value={searchString}
                                onChange={(e) => setSearchString(e.target.value)}
                                className={Styles.inputBase}
                                placeholder={t('conversationTitleSearchPlaceholder')}
                                autoComplete='off'
                            />
                            <IconButton
                                sx={{ color: theme.palette.blue['999'] }}
                                className={Styles.closeIcon}
                                onClick={handleSearchClose}>
                                <CloseIcon className='closeIcon' sx={{ width: '.5em', height: '.5em' }} />
                            </IconButton>
                        </Box>
                    </header>
                    :
                    <header className={Styles.closeSearchHeader} >{t('userPromptListHeader')}
                        <IconButton
                            size="small"
                            className='CTIconButton'
                            aria-label="search"
                            onClick={() => setIsSearchOpen(!isSearchOpen)}
                        >
                            <SearchIcon className='searchIcon' />
                        </IconButton>
                    </header>
            }
        </>
    )

};
export default UserPromptListHeader;