import React, { useEffect, useCallback } from 'react';
import useAbortController from './useAbortController';

type CallbackFunction = (controller: AbortController) => Promise<void>;

const useControllerCallback = (callback: CallbackFunction) => {
  const abortController = useAbortController();
  const awaitCallback = useCallback(async () => {
    const controller = abortController.reset();
    try {
      await callback(controller);
    } catch (error) {
      if (!controller?.signal?.aborted) {
        console.error(callback.name, error);
      }
    }
  }, [callback]);

  useEffect(() => {
    awaitCallback();
  }, [awaitCallback]);
};

export default useControllerCallback;
