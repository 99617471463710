/**
 * @file Developer panel for AngelAi Workflow Engine
 * @author Hunter Gordon
 */

import React from 'react';
import Grid from '@mui/material/Grid';
import Workflows from '../Components/Workflows';
import Actions from '../Components/Actions';
import AIFunctions from '@components/AIFunctions';
import darkModeTheme from '../Assets/Themes/DarkModeTheme';
import { ThemeProvider } from '@mui/material/styles';
import { getOperatorAccessToken } from '@storage/operatorStorage';
import useInitialMount from '@common/hooks/useInitialMount';


const EngineDev = () => {
    //soft check if the user is an operator. The window will close if any of 
    //the calls in these components fails, but this is just an extra layer of security.
    useInitialMount(() => {
        const isOperator = getOperatorAccessToken();
        if (!isOperator){
            window.location.replace(process.env.REACT_APP_PUBLIC_URL + '/console/login');
            alert('Please log in, then come back to this panel.')
        }
      });
    
    return (
        <ThemeProvider theme={darkModeTheme}>
        <Grid container style={{ backgroundColor: "var(--gray940)", height: '100vh', overflow: 'hidden', boxSizing: 'border-box', margin: 0 }}>
            <Grid item xs={6} style={{ 
                height: '50vh', 
                overflow: 'hidden', 
                borderRight: '10px solid var(--gray950)', // Adds a right border
                borderBottom: '10px solid var(--gray950)', // Adds a bottom border
            }}>
                <Workflows />
            </Grid>
        <Grid item xs={6} style={{ 
                height: '100%', 
                overflow: 'hidden',
                borderLeft: '10px solid var(--gray950)',
                borderBottom: '10px solid var(--gray950)', // Adds a bottom border to only the bottom part
            }}>
                <AIFunctions />
            </Grid>
            <Grid item xs={6} style={{ 
                height: '117vh',
                overflow: 'hidden',
                transform: 'translateY(-43%)',
                borderRight: '10px solid var(--gray950)', // Adds a right border
            }}>
                <Actions />
            </Grid>
        </Grid>
        </ThemeProvider>

    );
};

export default EngineDev;
