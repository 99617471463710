import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ShareIcon from '@mui/icons-material/Share';
import { useErrandContext } from '@contexts/ErrandContext';
import { useUserContext } from '@contexts/user';
import { MorphType } from '@common/MorphType';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import { useCustomLinkContext } from '@contexts/CustomLinkContext';
import { useQuery } from '@tanstack/react-query';
import MessageTime from '@components/MessageTime';
import axiosCall from '@services/axios';
import { IErrand, IMessage } from '@interfaces/Conversation';
import Styles from '@styles/CustomLinkDialog.module.css';
import ThinClientUtils from '@common/ThinClientUtils';
import useAbortController from '@common/hooks/useAbortController';
const DocumentPreview = process.env.REACT_APP_MORGAN_CDN + '/Images/DOC.png';


type TCustomLinkDialogProps = {
  errand: IErrand;
  message: IMessage;
  document: number;
}

const CustomLinkDialog = ({
  errand, message, document
}: TCustomLinkDialogProps) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const customLinkContext = useCustomLinkContext();
  const { _id, firstname } = useUserContext();
  const { reset } = useAbortController();
  const [isClickable, setIsClickable] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  const response = useQuery([document, message.updatedAt], async ({ signal }) => {
    let request = {
      url: `chat/${errand._id}/document/${document}`,
      };
    let config = {
      signal,
    };
    try {
      return await axiosCall(request, config);
    } catch (error) {
      console.log('Error with CustomLinkDialog: ' + error)
    }finally {
    //Icy 10/8/24: Adding this finally block fixes the bug where the QR code image does not update from
    // DocumentPreview after it is retrieved from the response, requiring a refresh. It also does not reintroduce the
    // bug where having multiple QR codes in the same conversation will intermittently load the incorrect one
    // (which is what would happen if we tried to update QrImgSrc in the useMemo or by using a useEffect)
      customLinkContext.setQrImgSrc(`url(${response?.data?.mimeType?.split('/')[0] === 'image'
      ? `data:${response?.data?.mimeType};base64,${response?.data?.data}`
      : DocumentPreview
      })`);
    }
  });

  const qrImg = useMemo(() => {
    return `url(${response?.data?.mimeType?.split('/')[0] === 'image'
      ? `data:${response?.data?.mimeType};base64,${response?.data?.data}`
      : DocumentPreview
      })`
  }, [response])

  let msgArray = message?.message?.split(' ')

  let typeOfCustomLink = msgArray[0]
  let customLinkURL = msgArray[1] || 'AngelAi Custom Link'
  let otherFirstName = msgArray[2] || 'Your friend'
  
  let customLinkMsg;
  switch (typeOfCustomLink) {
    case "selfNew":
      customLinkMsg = t('selfLink');
      break;
    case "selfExisting":
      customLinkMsg = t('selfLink');
      break;
    case "otherNew":
      customLinkMsg = t('otherNewLink').replace('[name]', otherFirstName);
      break;
    case "otherExisting":
      customLinkMsg = t('otherExistingLink').replace('[name]', otherFirstName);
      break;
    case "shareNew":
      customLinkMsg = t('shareSelfNewLink');
      break;
    case "shareSelfExisting":
      customLinkMsg = t('shareSelfExistingLink');
      break;
    default: 
      customLinkMsg = 'Error'
  }

  const handleShareClick = useCallback(async (e) => {
    e.stopPropagation();
    customLinkContext.setCustomLink(customLinkURL);
    customLinkContext.setQrImgSrc(qrImg);
    errandContext.setMorphType((prev) => {
      if (prev === MorphType.ShareCustomLink) {
        return MorphType.None;
      }
      return MorphType.ShareCustomLink;
    })
    // Do post request to set the field in the database so it can be used
    // regardless of who created the custom link

    const body = {
      customLinkUrl: customLinkURL,
      firstname: firstname,
      chatId: errandContext.errandId,
      userId: _id,
    }
    
    const payload = {
      url: `referrer/insertCustomLinkUrlField`,
      method: 'POST',
      data: body,
    };

    const config = reset();
    try{
      await axiosCall(payload, config);
    }catch(err){
      console.log(`Could not insert field for customLinkUrl: ${err}`)
    }
  }, [errandContext, reset])

  /* On click handler for downloading a QR Code */
  const handleClickDownload = useCallback(async (e) => {
    e?.stopPropagation();
    if (!message.visible || !response?.data) return;
    if (!response?.data) return;
    const url = `data:${response?.data?.mimeType};base64,${response?.data?.data}`;
    const link = window.document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'QRCode.png');
    window.document.body.appendChild(link);
    link.click();

    // Thin Client specific event to allow document download
    if (ThinClientUtils.isThinClient()){
      window.dispatchEvent(new CustomEvent('documentDownload', {
        detail: {
          key: 'documentDownload',
          url: url,
          mimeType: response?.data?.mimeType,
          filename: 'QRCode.png',
        }
      }));
    }
  }, [message.visible, response?.data?.mimeType, response?.data?.data, response?.data?.filename]);

  useEffect(() => {
    let timer;
    
    if (!qrImg.includes('Images/DOC.png')) {
      setIsAnimated(true);
      timer = setTimeout(() => {
        setIsClickable(true);
      }, 2000);
    }
  
    return () => clearTimeout(timer);
  }, [customLinkURL, qrImg]);
  

  return (
    <div className={`${Styles.customLinkOperator} ${isMobileOrTablet() && Styles.customLinkDialogIsMobileOrTablet} ${message?.alignByCurrentUser ? Styles.customLinkFlexEnd : Styles.customLinkFlexStart}`} onClick={isClickable ? handleShareClick : null}>
      <div className={Styles.customLinkDialogContainer}>
        <div className={Styles.qrContainer}>
          <div className={Styles.qr}
            style={{ backgroundImage: qrImg }}
            onClick={handleClickDownload}
          ></div>
        </div>
        
        <div className={Styles.messageBox}>
          <div className={Styles.message}>
            {message.visible 
                ? `${customLinkMsg} `
                : `${customLinkMsg} `.replace(/./g, '*')}
            <span className={Styles.url}>
              {message.visible
                ? customLinkURL
                : customLinkURL.replace(/./g, '*')
              }
            </span>
          </div>
          <div className={Styles.timeStamp}>
            <MessageTime message={message} />
          </div>
        </div>
        <div className={isClickable ? Styles.share : Styles.notYet} onClick={isClickable ? handleShareClick : null}>
          <div className={isAnimated && Styles.shareIcon}><ShareIcon /></div>
          <span>{t('tShare')}</span>
        </div>
      </div>
    </div>
  )
};

export default CustomLinkDialog;