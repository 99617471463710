/**
 * @file RatePricingResultMessageContent.tsx
 * @description This file defines the RatePricingResultMessageContent component
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */
// resultData

import React from 'react';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useTranslation } from 'react-i18next';
import { PricingLoanProduct, RatePricingResultMessage } from '@mTypes/Conversation';
import BubbleWithExpandableLower from '@components/MessageBubbles/BubbleWithExpandableLower/BubbleWithExpandableLower';
import Styles from '@styles/RatePricingResultMessageContentStyles.module.css';
import { LoanTermIcon } from '@assets/Icons';

type TRatePricingResultMessageContentProps = {
  errand: IErrand;
  message: RatePricingResultMessage;
};

const RatePricingResultMessageContent = ({ errand, message }: TRatePricingResultMessageContentProps) => {
  const { t } = useTranslation();
  const resultData = message.resultData;
  const { pricingResult, loanTerm, todayDate, propertyState, generalRate } = resultData;
  const totalProducts = Object.keys(pricingResult).length;

  const translateTodayDate = (localeString: string) => {
    var [weekday, monthAndDay] = localeString.split(',', 2);
    const [month] = monthAndDay.trim().split(' ', 1);

    const translated1 = localeString.replace(weekday, t(weekday.toLowerCase()));
    const translated2 = translated1.replace(month, t(month.toLowerCase()));
    const translated3 = translated2.replace('at ', '');
    return translated3;
  };

  type LoanProductProps = {
    product: PricingLoanProduct;
    headingSize: string;
    fontSize: string;
  };
  const LoanProduct = ({ product, headingSize, fontSize }: LoanProductProps) => {
    return (
      <div className={Styles.loanProduct}>
        <rect className={Styles.bar} />
        <div className={Styles.productInfoContainer}>
          <h6 style={{ fontSize: headingSize }}>{product.productName.replace(loanTerm, '')}</h6>
          <div className={Styles.productRateContainer}>
            <div>
              <p style={{ fontSize: fontSize }}>
                {product.rate} / {product.apr}%
              </p>
            </div>
            <div>
              <p style={{ fontSize: fontSize }}>( {t('rate')}* / APR )</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PricingResults = () => {
    // Calculate the heading and font-size based on the number of products
    const headingSize = totalProducts === 1 ? '1rem' : `${1 / totalProducts + 0.5}rem`;
    const fontSize = totalProducts === 1 ? '0.8rem' : `${1 / totalProducts + 0.4}rem`;

    return (
      <div className={Styles.resultsContainer}>
        <div className={Styles.left}>
          <LoanTermIcon />
          <p>{loanTerm}</p>
        </div>
        <div className={Styles.right}>
          {pricingResult.map((product) => (
            <LoanProduct product={product} headingSize={headingSize} fontSize={fontSize} />
          ))}
        </div>
      </div>
    );
  };
  const Disclaimer = () => {
    return (
      <div className={Styles.disclaimer}>
        <h5>{t('disclaimer')}</h5>
        {generalRate && (
          <p>{`${t('rateDisclaimerBeforeState')} ${propertyState}. ${t(
            'rateDisclaimerAfterState'
          )} ${translateTodayDate(todayDate)}`}</p>
        )}
        {!generalRate && <p>{t('customRateDisclaimer')}</p>}
      </div>
    );
  };

  return (
    <BubbleWithExpandableLower
      upperContents={<PricingResults />}
      lowerContents={<Disclaimer />}
    ></BubbleWithExpandableLower>
  );
};

export default RatePricingResultMessageContent;
