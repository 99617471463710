import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  FormControl
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInitialMount from '@common/hooks/useInitialMount';
import DialogHeader from '@components/DialogHeader';
import axiosCall from '@services/axios';

import type { DialogPropsWithChildren } from '@mTypes/Console';

const mt = {
  mt:"12px"
};

/*
 *  This is the component that defines the dialog which pops up on the console
 * monitoring page when a user is clicked.
 *
 *  This component has the following properties:
 *    - open: boolean, whether or not the dialog is open.
 *    - selected: the data for the selected user, if provided. Will be useful to
 *      make calls to the API from within this component.
 *    - onClose: The function for closing the dialog, since we are offloading state
 *      managment to the parent component.
 */

const EditOperatorDialogMonitoring: FC<DialogPropsWithChildren> = (props) => {
  // const theme = useTheme();
  const { t } = useTranslation();
  const [accessLevel, setAccessLevel] = useState(props.operator?.accessLevel);

  useInitialMount(() => {
    // we have to call this because what might come from the operator's data table might not be up to date
    async function refresh () {
      const operator = await axiosCall({url: `operator/${props.operator?._id}`});
      setAccessLevel(operator?.accessLevel);
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleSubmit = () => {
    const data = {
      id: props.operator?._id,
      accessLevel: accessLevel
    };
    props.onSubmit(data);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      {/* <DialogTitle> {t('monitoringEditDialogTitle')} </DialogTitle> */}
      <DialogHeader title={t('monitoringEditDialogTitle')} onClose={() => props.onClose()} />
      <DialogContent sx={{ padding: 4 }}>
        {/* Main content */}
        <FormControl fullWidth>
        <TextField
            sx={{...mt}}
            select
            autoFocus
            size="small"
            color="info"
            variant="outlined"
            label={t('monitoringEditDialogLevel')}
            onChange={(e) => setAccessLevel(e.target.value)}
            value={accessLevel}>
            {props.accessLevels.map((level) => {
              return (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={props.onClose}> {t('monitoringEditDialogCancel')} </Button>
        <Button variant="contained" color="info" onClick={() => handleSubmit()}>
        {t('monitoringEditDialogOK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOperatorDialogMonitoring;
