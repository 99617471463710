import { Box } from '@mui/material';
import React, { useCallback, useEffect, useState, memo} from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

import Actions from './Actions';
import AITab from './AITab'
import EmailTemplates from './EmailTemplates';
import FieldAttributes from './FieldAttributes';
import Operators from './Operators';
import Products from './Products';
import SMSTemplates from './SMSTemplates';
import System from './System';
import Users from './Users';
import Workflows from './Workflows';
import Orders from './Orders';
import HelpTips from './HelpTips';
import HelpTipGroups from './HelpTipGroups';
import EForms from './EForms';
import CallsToAction from './CallsToAction';
import { sectionNameTranslationKeys } from '../Constants/consoleConstants'

const Admin = ({ selectedAI, setSelectedAI }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const getCurrentAdminModule = useCallback(() => { 
    return location.state.module.split('/')[1];
  }, [location.state.module]);
  const [selectedTab, setSelectedTab] = useState(getCurrentAdminModule() ?? sectionNameTranslationKeys['System']);

  useEffect(() => {
    if (!location.state?.module) {
      throw new Error('location.state.module must be defined when rendering the Admin component');
    }

    // Default admin page is System, so redirect user accordingly if the
    // requested path is simply 'admin'
    if (location.state.module === sectionNameTranslationKeys['Admin']) {
      navigate('/console', { 
        state: { module: `${sectionNameTranslationKeys['Admin']}/${sectionNameTranslationKeys['System']}` } 
      });
      return;
    }

    setSelectedTab(getCurrentAdminModule());
  }, [location, getCurrentAdminModule, navigate]);

  const contentRender = () => {

    switch (selectedTab) {
      case sectionNameTranslationKeys['System']:
        return <System></System>;

      case sectionNameTranslationKeys['Operators']:
        return <Operators></Operators>;

      case sectionNameTranslationKeys['Users']:
        return <Users></Users>;

      case sectionNameTranslationKeys['Workflows']:
        return <Workflows></Workflows>;

      case sectionNameTranslationKeys['Actions']:
        return <Actions></Actions>;

      case sectionNameTranslationKeys['FieldAttributes']:
        return <FieldAttributes></FieldAttributes>;
      
      case sectionNameTranslationKeys['SMSTemplates']:
        return <SMSTemplates></SMSTemplates>;

      case sectionNameTranslationKeys['EmailTemplates']:
        return <EmailTemplates></EmailTemplates>;

      case sectionNameTranslationKeys['Products']:
        return <Products></Products>;

      case sectionNameTranslationKeys['Orders']:
        return <Orders></Orders>;

      case sectionNameTranslationKeys['AI']:
        return <AITab selectedAI={selectedAI} setSelectedAI={setSelectedAI}></AITab>;

      case sectionNameTranslationKeys['HelpTips']:
        return <HelpTips />

      case sectionNameTranslationKeys['HelpTipGroups']:
        return <HelpTipGroups />

      case sectionNameTranslationKeys['E-Forms']:
        return <EForms />
      
      case sectionNameTranslationKeys['CallsToAction']:
        return <CallsToAction />

      default:
        break;
    }
  };

  return <Box>{contentRender()}</Box>;
};

export default memo(Admin);