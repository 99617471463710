import React from 'react';
import { MEASURING_CLASS_LIST } from './Constants';

/**
 *
 * @summary Serves as a wrapper for SingleActivityElement to ensure the proper classes applied as well as inline styles
 * based on whether measures were already taken or not.
 */
export const SingleActivityElementContainer = ({ activityData, activityStyle, wereDimensionsMeasured, children }) => {
  // if dimensions were measured then get our dynamic class list, else get measuring class list.
  const classNameStr =
    wereDimensionsMeasured === true ? activityData.classList.join(' ') : MEASURING_CLASS_LIST.join(' ');

  return (
    <div className={classNameStr} style={activityStyle}>
      {children}
    </div>
  );
};
