import React, { Dispatch, SetStateAction } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { QuickActionIcon } from '@assets/Icons';
import { useRootContext } from '@contexts/RootContext';

import Styles from '@styles/UserPromptListItem.module.css';
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

type TUserPromptListItemProps = {
  _id: string;
  description: string;
  name: string;
  showUserPromptList: boolean;
  setShowUserPromptList: Dispatch<SetStateAction<boolean>>;
  src: string;
  type: 'action' | 'workflow' | 'loading' | 'empty';
  handleSearchClose?: () => void;
}

const UserPromptListItem = ({
  _id, description, name, setShowUserPromptList, showUserPromptList, src, type, handleSearchClose 
}: TUserPromptListItemProps) => {
  const { t } = useTranslation();
  const rootContext = useRootContext();
  const [, dragRef] = useDrag(() => ({
    type: 'userPrompt',
    item: {
      id: _id,
      name: name,
      type: type,
    }
  }), [_id, name, type]);

  /**
   * Checks the last used split screen errand, or uses current selected errand to send
   * the action or workflow in that chat
   */
  const handleSelectAction = async () => {
    if (['empty','loading'].indexOf(type) !== -1) return;

    setShowUserPromptList(false);
    // hide the errands drawer on mobile devices. On desktop it is always visible
    rootContext.drawerRef.current?.click();
    // If split screen is open, and two chats are on the screen
    const sendPrompt = (errands, selectedIndex) => {
      //split screen
      if (selectedIndex.length > 1) {

        // compare the two screens: which one had the most recent msg?
        const indexFirstScreen = selectedIndex[0];
        const firstScreenLastMessageDate = Date.parse(errands[indexFirstScreen].lastMessageData.createdAt);

        const indexSecondScreen = selectedIndex[1];
        const secondScreenLastMessageDate = Date.parse(errands[indexSecondScreen].lastMessageData.createdAt);
        
        //from the two screens get the most recent message.
        const target = firstScreenLastMessageDate < secondScreenLastMessageDate  ? errands[indexSecondScreen] : errands[indexFirstScreen];

        rootContext.handleActionWorkflow(_id, type, target._id, target.recipients);

        //single screen
      } else {
        // otherwise get the chat Id of the only open chat
        rootContext.handleActionWorkflow(_id, type, errands[selectedIndex[0]]._id, errands[selectedIndex[0]].recipients);
      }
    }

    rootContext.setErrands((prev) => {
      const errands = [...prev];
      rootContext.setSelectedIndex((prevIndexes) => {
        const selectedIndex = [...prevIndexes];
        sendPrompt(errands, selectedIndex);
        handleSearchClose();
        return prevIndexes;
      });
      return prev;
    });

  };

  const typeMap = {
    'action': <QuickActionIcon />,
    'workflow': <QuestionMarkIcon />,
    'loading': <CircularProgress size='small' />,
    'empty': <img src={AngelAi} alt={t('userPomptListItemIcon')} />
  }

  const Image = src ? <img src={src} alt={t('userPomptListItemIcon')} /> : typeMap[type];

  return (
    <li className={Styles.listItem}>
      <button ref={dragRef} onClick={handleSelectAction} tabIndex={showUserPromptList ? undefined : -1}>
        {Image}
        <div>
          <p>{name?.toUpperCase() || t('userPomptListItemName')}</p>
          <small>{description || t('userPomptListItemDescription')}</small>
        </div>
      </button>
    </li>
  );
};

export default UserPromptListItem;
