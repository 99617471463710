import React from 'react';
import Styles from '@styles/MessageContent/AppointmentMessageContent.module.css'
import { CalendarIcon} from '@assets/Icons';
import { sendWorkflow } from '@common/WorkflowsUtils';
import { AccessType } from '@common/AccessType';
import { useTranslation } from 'react-i18next';
import { MONTHS } from '../../Constants/Constants';
import { WORKFLOW_NAMES } from '@constants/WorkflowNames';
const CalendarCurve = process.env.REACT_APP_MORGAN_CDN + '/Images/calendarShape.svg';

const AppointmentMessageContent = ({
  errand, message, 
}) => {
  const { t } = useTranslation();

  if (!message || !message.time){
    console.info('Time is undefined')
    return <></>
  }

  if (!message.date){
    console.info('Date is undefined')
    return <></>
  }

  if (!message.month){
    console.info('Month is undefined');
    return <></>
  }

  // reference variables
  let apptMonth = message?.month;
  let apptDate = message?.date;
  let apptTime = message?.time;
  let apptMessage = message?.apptMsg;
  
  const formattedTime = apptTime.split(" ")

  const handleRescheduleClick = async (e) => {
    const activeUsers = errand?.participants?.filter((x) => x.active && x.userType === 'User');
    const recipients = activeUsers?.length > 1 ? [message.userId] : [];
    e.stopPropagation();
    await sendWorkflow('', WORKFLOW_NAMES.RESCHEDULE_WORKSHOP, errand._id, recipients, AccessType.public, message?.userId, message?.operatorView, null);
  }


  return (
    <div className={message?.alignByCurrentUser ? Styles.appointmentFlexEnd : Styles.appointmentFlexStart} onClick={handleRescheduleClick}>
      <div className={Styles.appointmentContainer}>
        <div className={Styles.appointmentTop}>
          <div className={Styles.appointmentMessage}>
            {apptMessage}
          </div>
          <div className={Styles.dateBox}>
            <div className={`${Styles.center} ${Styles.weekday}`}>{t(`t${MONTHS[message?.month - 1] || MONTHS[0]}`).slice(0, 3)}</div>
            <div className={`${Styles.center} ${Styles.date}`}>{apptDate}</div>
            <div className={`${Styles.center} ${Styles.time}`}>{`${formattedTime[0]} ${formattedTime[1]}`}</div>
          </div>
        </div>
        <div className={Styles.appointmentBottom}>
          <div className={Styles.calWrapper} style={{backgroundImage: `url(${CalendarCurve})`}}>
            <CalendarIcon className={Styles.calIcon} width={50}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentMessageContent;
