import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import DialogHeader from './DialogHeader';
import {PhoneNumberPatternField } from './FormattedTextField';
import React, { FC, useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';

const mt = {
  mt:"12px"
};

const EditUserDialog: FC<DialogPropsWithChildren> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState(props.user?.firstname || '');
  const [lastName, setLastName] = useState(props.user?.lastname || '');
  const [email, setEmail] = useState(props.user?.email || '');
  const [phone, setPhone] = useState(props.user?.phone || '');
  const [unsubSms, setUnsubSms] = useState(!props.user?.smsNotifications);
  const [unsubEmail, setUnsubEmail] = useState(!props.user?.emailNotifications);
  const [banned, setBanned] = useState(props.user?.banned);

  const handleSubmit = () => {
    const data = {
      id: props.user?._id,
      firstname: firstName,
      lastname: lastName,
      email: email,
      phone: phone,
      emailNotifications: !unsubEmail,
      smsNotifications: !unsubSms,
      banned: banned,
    };
    props.onSubmit(data);
  };

  return (
    <Dialog maxWidth="md" open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('editUserTooltip')} onClose={() => props.onClose()} />
      <DialogContent>
        <Stack width="400px">
          <small>_id: {props.user._id}<br/></small>
          <TextField
            sx={{...mt}}
            autoFocus
            size="small"
            color="info"
            variant="outlined"
            label={t('firstNameLabel')}
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          <TextField
            sx={{...mt}}
            size="small"
            color="info"
            variant="outlined"
            label={t('lastNameLabel')}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            sx={{...mt}}
            size="small"
            color="info"
            variant="outlined"
            label={t('emailCol')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Box sx={{ width: '1', ...mt }}>
            <PhoneNumberPatternField
              // sx={{ width: '1' }}
              defaultValue={phone}
              value={phone}
              label={t('phoneCol')}
              onChange={(event) => setPhone(event.target.value.replace(/[^0-9]+/g, ''))}
            />
          </Box>
          <FormControlLabel
            sx={{ marginLeft: '0' }}
            control={
              <Checkbox
                color="info"
                checked={unsubSms}
                onChange={(e) => {
                  setUnsubSms(e.target.checked);
                }}
              />
            }
            label={t('unsubscribeSmsLabel')}
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ marginLeft: '0' }}
            control={
              <Checkbox
                color="info"
                checked={unsubEmail}
                onChange={(e) => {
                  setUnsubEmail(e.target.checked);
                }}
              />
            }
            label={t('unsubscribeEmailLabel')}
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ marginLeft: '0' }}
            control={
              <Checkbox
                color="info"
                checked={banned}
                onChange={(e) => {
                  setBanned(e.target.checked);
                }}
              />
            }
            label={t('bannedLabel')}
            labelPlacement="end"
          />
          <Typography color={theme.palette.red['400']}>{props.error}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={() => props.onClose()}>
          {t('cancelButton')}
        </Button>
        <Button variant="contained" color="info" onClick={() => handleSubmit()}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserDialog;
