/**
 * @file This component is a stylized Box component
 * for generating Stream Bubbles. 
 * 
 * Extracted from ConversationMessage.js 10/11/2022
 * 
 * @author Harrison Fales <harrison.fales@swmc.com>
 */

import { Box, styled } from '@mui/system';

const StreamBubbleStyle = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'multi-line',
  height: "125px",
  width: "458.33",
  borderRadius: '10px',
  boxShadow: 'var(--shadow100) 0px 5px 35px',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: "var(--gray000)",
  minHeight: "90px",
  justifyContent: "space-between",
  padding: "5px 5px 10px 5px",
  border: "1px solid var(--orange700)",
  marginTop: "18px"
});

export default StreamBubbleStyle