import { Box, styled } from '@mui/system';
import Slider from '@mui/material/Slider';
import React, { PropsWithChildren, useContext, useRef } from 'react';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';


const StyledSlider: React.FC<PropsWithChildren<any>> = styled(Slider, {
  // shouldForwardProp allows props to be appended to the html if they pass the filter
  // isDekstop, and length are not valid html props so they should be filtered out.
  shouldForwardProp: (prop) =>
    prop !== 'isDesktop' && prop !== 'length',
})<any>(({ theme, isDesktop, length }) => `
  color: var(--orange050);
  height: 6px;
  width: inherit;
  max-width: 87%;
  margin-top: 25px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 16px 0;
  display: inline-block;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 1;
  }

  & .MuiSlider-rail {
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--peach900);
  }

  // Future TODO:: use the below styling for matching progress on the timeline
  //               with scrolling through the conversation

  // Controls the "active" portion of the rail as we slide the slider
  // & 	.MuiSlider-track {
  //   display: block;
  //   position: absolute;
  //   height: 4px;
  //   border-radius: 2px;
  //   background-color: currentColor;
  // }

  & 	.MuiSlider-thumb {
    position: absolute;
    width: 10px;
    height: 10px;
    box-sizing: border-box;
    border-radius: 50%;
    top: 40%;
    background-color: var(--orange700);
    transform: translateX(-50%);
  },
    
  & .MuiSlider-markActive {
    background-color: var(--gray000);
  }

  // Styling for default marks
  & .MuiSlider-mark {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--peach900);
    top: 40%;
    transform: translateX(-50%);
  }

  // Styling for the "Past" bubble/mark on the timeline
  & .MuiSlider-mark[data-index="0"] {
    position: absolute;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    box-sizing: border-box;
    border-radius: 50%;
    top: 53%;
    outline: 0;
    border: 3px solid currentColor;
    background-color: var(--gray000);
    opacity: 1;
  }

  // Styling for the "Now" bubble/mark on the timeline
  & .MuiSlider-mark[data-index="${length}"] {
    position: absolute;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    box-sizing: border-box;
    border-radius: 50%;
    top: 53%;
    outline: 0;
    border: 3px solid currentColor;
    background-color: var(--gray000);
    opacity: 1;
  }

  // Determines the set styling on the given labels
  & .MuiSlider-markLabel {
    font-family: "-apple-system, BlinkMacSystemFont", "Segoe UI", "Roboto",
      "Poppins", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    top: -2.65em;
    transform: translateX(-50%);
    margin-top: 8px;
    visibility: hidden;
  }

  // Styling for the "Past" label on the timeline
  & .MuiSlider-markLabel[data-index="0"] {
    font-family: "-apple-system, BlinkMacSystemFont", "Segoe UI", "Roboto",
      "Poppins", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    top: -1.25em;
    transform: translateX(-50%);
    margin-top: 8px;
    visibility: visible;
  }

  // Styling for the "Now" label on the timeline
  & .MuiSlider-markLabel[data-index="${length}"] {
    font-family: "-apple-system, BlinkMacSystemFont", "Segoe UI", "Roboto",
      "Poppins", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    font-size: 12px;
    position: absolute;
    bottom: 20px;
    top: -1.25em;
    transform: translateX(-50%);
    margin-top: 8px;
    visibility: visible;
  }
`/*
NOTE: valueLabel is not present in the SliderUnstyleClasses TypeScript module. This will need to be fixed.
  // Determines where the value's label (Date) go when sliding across the bar
  & .${sliderUnstyledClasses.valueLabel} {
    font-family: "-apple-system, BlinkMacSystemFont", "Segoe UI", "Roboto",
      "Poppins", Helvetica, Arial, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", sans-serif;
    font-size: 14px;
    display: block;
    width: max-content;
    position: relative;
    top: -2.65em;
    text-align: center;
    transform: translateX(calc(-50% + 5px));
  }

  // The "+" is a css selector that we're using to grab the first and last sibling respectively
  & .${sliderUnstyledClasses.thumb} > input[value="0"] + .${sliderUnstyledClasses.valueLabel} {
    transform: translateX(-7%);
  },

  // The "+" is a css selector that we're using to grab the first and last sibling respectively
  & .${sliderUnstyledClasses.thumb} > input[value="${length}"] + .${sliderUnstyledClasses.valueLabel} {
    transform: translateX(-76%);
  },*/
);

const months = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec"
}

// Future TODO::Have the timeline match when the user is scrolling through the conversation.

const Timeline: React.FC<PropsWithChildren<any>> = (props) => {
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const datesRef = useRef([]);
  const startDay = props.startDate?.split("T")[0]?.split("-")?.join("");
  const endDay = props.latestDate?.split("T")[0]?.split("-")?.join("");
  const start = {
    label: 'Past',
    value: 0,
    scaledValue: startDay,
  };

  // Creates a map of [dates]: count
  let messageDateCounts = new Map();
  for (let i = 0; i < props.result?.length; i++) {
    if (props.result[i]?.createdAt === undefined) return;
    const date = parseInt(props.result[i].createdAt.split("T")[0].split("-").join(""));
    if (messageDateCounts.get(date)) {
      let newVal = messageDateCounts.get(date);
      newVal += 1;
      messageDateCounts.set(date, newVal);
    } else {
      messageDateCounts.set(date, 1)
    }
  }

  // Grabs the 5 dates that have the most instances of searched message
  let correctedDates = [...messageDateCounts].sort((a, b) =>
    b[1] - a[1]
  ).slice(0, 5);

  // Sorts by date ascending
  correctedDates.sort((x, y) => x[0] - y[0]);

  let listedDates = [start];
  for (let i = 0; i < correctedDates.length; i++) {
    let entry = correctedDates[i];
    let createdAt = entry[0].toString();
    listedDates.push({
      value: i + 1,
      scaledValue: createdAt,
      label: start.label,
    });
  }

  const end = {
    label: 'Now',
    value: listedDates.length,
    scaledValue: endDay,
  };

  listedDates.push(end);
  datesRef.current = listedDates;

  // Formats the scaledValue from above which is given as e.g., "20221005"
  // Into Month Date, Year
  const dateFormatter = (date) => {
    return ``;
  }

  // Logic for scrolling the conversation component when timeline bubbles are pressed
  const handleScrollIntoView = (value) => {
    // Using {behavior: 'smooth'} causes the scroll to behave erratically for borrowers.
    // Seems to work 65% of the time for operators, but still erratic. Removing it completely
    // has the scroll working 100%, but obviously not smooth.
    if (value === 0) {
      return errandContext?.bodyRef?.current?.scroll(0, 0);
    }
    const datesRefLenth = datesRef?.current?.length > 1 ? datesRef?.current?.length - 1 : 0;
    const searchRefLength = errandContext?.searchRefs?.current?.length > 1 ? errandContext?.searchRefs?.current?.length - 1 : 0.0001;
    const scale = Math.floor(searchRefLength / datesRefLenth);
    const newValue = Math.floor(value * scale);
    if (errandContext?.searchRefs?.current?.[newValue]?.htmlElement) {
      return errandContext?.searchRefs?.current?.[newValue]?.htmlElement?.scrollIntoView();
    }
    return errandContext?.bodyRef?.current?.scroll(0, errandContext?.bodyRef?.current?.scrollHeight);
  }

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
    }}>
      <StyledSlider
        length={datesRef?.current?.length - 1}
        marks={datesRef?.current}
        defaultValue={datesRef?.current[datesRef?.current?.length - 1].value}
        valueLabelFormat={dateFormatter}
        onChange={(e) => handleScrollIntoView(e.target.value)}
        min={0}
        max={datesRef?.current?.length - 1}
      />
    </Box>
  )
};

export default Timeline;