import writeXlsxFile from 'write-excel-file';

const downloadMonitoringReport = async (data, filename) => {
  const schema = [
    // Column #1
    {
      column: 'Level',
      type: String,
      value: (row) => row.level,
      width: 25,
    },
    {
      column: 'Name',
      type: String,
      value: (row) => row.name,
      width: 25,
    },
    {
      column: 'Conversations',
      type: Number,
      value: (row) => row.conversations,
      width: 25,
    },
    {
      column: 'Guests',
      type: String,
      value: (row) => row.guests,
      width: 20,
    }
  ];

  if (data?.length > 0) {
    const objects = data?.map((d) => {
      return {
        level: d.operator.accessLevel,
        name: `${d.operator.firstname} ${d.operator.lastname}`,
        conversations: d.numberOfChats,
        guests: d.guestList.map(g => `${g.firstname} ${g.lastname}`).join(", "),
      };
    });
    await writeXlsxFile(objects, {
      schema,
      fileName: `${filename}.xlsx`,
    });
  }
};

export default downloadMonitoringReport;
