import { Box, Stack, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import { setPreselectedLanguage } from '../Storage/userStorage';
import { useTranslation } from 'react-i18next';
import { map, SetLanguageFromGeolocation } from '../Common/LocationInformation';
import Globe from '@components/Splash/Globe'
import { GlobeStyles } from '@components/LanguagePickerGlobeStyle';
import useInitialMount from '@common/hooks/useInitialMount';

const LanguagePickerGlobe = ({setIsLoading}) => {
  const [index, setIndex] = useState(0);
  const { i18n } = useTranslation();
  const storedLanguage = useRef(i18n.resolvedLanguage);

  // Runs on load, ensures the correct flag is on
  // top when the default isn't english
  const handleLanguageOnLoad = () => {
    // Set Default Language
    let i;
    for (let i = 0; i < map.length; i++) {
      if (map[i].lang === storedLanguage.current) {
        setIndex(i);
        i18n.changeLanguage(map[i].lang);
        setPreselectedLanguage(map[i].lang);
        break;
      }
    }

    if (i === map.length) {
      // Sets the language based on the users's location or from local storage
      SetLanguageFromGeolocation()
        .then((res) => {
          setIndex(res);
          i18n.changeLanguage(map[res].lang);
          setPreselectedLanguage(map[res].lang);
        })
        .catch((error) => {
          console.error("endpoint location/stored API doesn't work" + error.message);
        });
    }
  }

  useInitialMount(() => handleLanguageOnLoad());

  const handleFlagClick = (flagIndex) => {
    setIsLoading(true);
    //ensure positive indexing
    const actualIndex = (index + flagIndex + map.length * 2) % map.length;
    setIndex(actualIndex);
    i18n.changeLanguage(map[actualIndex].lang);
    setPreselectedLanguage(map[actualIndex].lang);
  };

  const noDrag = {
    userSelect: 'none',
    userDrag: 'none',
    WebkitUserDrag: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    MsUserSelect: 'none',
  };

  /**
   * Icy-6/7/23: these are no longer needed with new 3D globe:
   * const globeRotate = {
   * WebkitTransition: '-webkit-transform .3s ease-in-out' /* Chrome < 26, Safari < 7,
   * MozTransition: '-moz-transform .3s ease-in-out' /* Firefox < 16,
   * OTransition: '-o-transform .3s ease-in-out' /* Opera < 12.10,
   * transition: 'transform .3s ease-in-out' /* IE10+, Firefox 16+, Chrome 36+, Opera 12.10 ,
   * };
   * 
   * Handles swiping -- commented out with the introduction of swipable globe
   * const swipeHandlers = useSwipeable({
   * onSwipedLeft: () => {
   *   swipeLeft();
   * },
   * onSwipedRight: () => {
   *   swipeRight();
   * },
   * onSwiped: (eventData) => {
   *   Stops clicks from getting through when were just swiping
   *   eventData.event.stopImmediatePropagation();
   * },
   * trackMouse: true,
   * });
   * 
   *---------------------------------------------------------------------------
   */

  return (
    <GlobeStyles style={{ position: 'absolute' }}>
      <Stack justifyContent="center" alignItems="center" marginTop={2} display="flex">
        <Box className='flagContainer'>

        <Box className='leftFlag scale' onClick={() => handleFlagClick(-2)} sx={{ ...noDrag }}>
          {/* Ensuring positive indexing for flags to the left */}
          {map[(index - 2 + map.length) % map.length]?.flag}
        </Box>

        <Box className='additionalFlag1 scale' onClick={() => handleFlagClick(-1)} sx={{ ...noDrag }}>
          {/* Ensuring positive indexing for flags to the left */}
          {map[(index - 1 + map.length) % map.length]?.flag}
        </Box>

        {/* Existing and additional flags */}
        <Box className='topFlag scale' sx={{ ...noDrag }}>
          {map[index]?.flag}
          {/* Language tag for top flag */}
          <div className='scale languageBadge'>
            {map[index].nativeName}
          </div>
        </Box>

        <Box className='additionalFlag2 scale' onClick={() => handleFlagClick(1)} sx={{ ...noDrag }}>
          {map[(index + 1) % map.length]?.flag}
        </Box>
        
        <Box className='rightFlag scale' onClick={() => handleFlagClick(2)} sx={{ ...noDrag }}>
          {map[(index + 2) % map.length]?.flag}
        </Box>

        {/* Globe and dots */}
        <Box className='globeContainer' sx={{ ...noDrag }}>
          <Globe/>
        </Box>

          <Box className='dotContainer scale' sx={{ ...noDrag }}>
            <Box className='dot dotTopOuter' />
            <Box className='dot dotTopInner' />
            <Box className='dot dotLeft' />
            <Box className='dot dotRight' />

          </Box>
        </Box>
      </Stack>
    </GlobeStyles>
  );
};

export default LanguagePickerGlobe;
