/**
 * @file This file contains the definition of the `BrowserType` enum class. This class contains enumerators for
 * all supported browsers.
 * @author Kristoffer A. Wright
 */

/**
 * This class is an enum class, the members of which represent all supported browsers.
 */
 class BrowserType {

  static CHROME = new BrowserType("CHROME");
  static EDGE = new BrowserType("EDGE");
  static FIREFOX = new BrowserType("FIREFOX");
  static OPERA = new BrowserType("OPERA");
  static SAFARI = new BrowserType("SAFARI");
  static SAMSUNG = new BrowserType("SAMSUNG");
  static IE = new BrowserType("IE");
  static OTHER = new BrowserType("OTHER");

  constructor(name) {
    this.name = name;
  }
}

export default BrowserType;