import React from 'react';
import { IMessage } from '@interfaces/Conversation';
import styles from '@styles/MessageContent/ProfileBubbleMessageContent/ProfileBubbleMessageContent.module.css';
import Header from '@components/MessageContent/ProfileBubbleMessageContent/Header';
import Message from '@components/MessageContent/ProfileBubbleMessageContent/Message';

interface Props {
  message: IMessage;
}

const COMPONENT = 'ProfileBubbleMessageContent';

/**
 * Message Type: ProfileBubble
 * Used to show profile information along with the users profile picture.
 * The profile picture is fetched from the RestServer.
 */
export default function ProfileBubbleMessageContent(props: Props): React.ReactElement {
  function getClassName(): string {
    const classNames = [];

    classNames.push(styles.container);

    if (props.message.alignByCurrentUser) {
      classNames.push(styles.containerMarginLeft);
    }

    return classNames.join(' ');
  }

  return (
    <div data-component={COMPONENT} className={getClassName()}>
      <Header chatId={props.message.chat} documentId={props.message.documents?.[0]} />
      <Message message={props.message} />
    </div>
  );
}
