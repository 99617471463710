/**
 *                               *** HIGHLY CONFIDENTIAL ***
 * 
 * All code written here is confidential and proprietary of Celligence International, LLC.
 * The algorithms on this file are considered undisclosed material information and should
 * not be disclosed to any third party without proper legal advise. Any misuse, including
 * but not limited to the disclosures of such algorithms contained herein to third parties
 * are subject to legal actions. Only executive members are allowed to review and update
 * parts of this code.
 * 
 *                    *** Copyright© Celligence International, LLC - 2024 ***
 * 
*/
import axios from 'axios';
import { getUserAccessToken, getIsSessionRestoring } from '@storage/userStorage';
import { getOperatorAccessToken } from '@storage/operatorStorage';
import { APIServiceType } from '@common/APIServiceType';

/**
 * Axios wrapper to call the API's
 * @param request: holds the service, url, method, and data to call the API
 * @param config (optional): config that you want in the request
 * @param headers (optional): headers to be included in the request
 * @returns response or error
 */

const axiosCall = async (request: { url: string, method?: string, data?: any, service?: APIServiceType }, config = {}, headers = {}) => {
    const isOperator = window.location.pathname.indexOf('/console') !== -1 || window.location.pathname.indexOf('/engineDev') !== -1;
    const token = isOperator ? getOperatorAccessToken() : getUserAccessToken();

    const { url: URL, method, data} = request;
    const service = request.service || APIServiceType.core;

    if (URL === undefined || URL === null || URL === '') {
      throw new Error('Unable to fulfill the request, endpoint not defined.');
    }

    try {
      const url = process.env[`REACT_APP_${service}`] + (`/${URL}` || '');
      const response = await axios({
        ...config,
        method: method || 'get',
        headers: { ...headers, Authorization: `Bearer ${token}` },
        url,
        data
      });
      return response && response.data ? response.data : null;
    }
    catch (err: any) {
      if (err?.response?.status === 401 && err?.response?.headers?.['referrer-policy'] !== 'strict-origin-when-cross-origin') {
        if (process.env.NODE_ENV === 'development') {
          alert(`!development environment would have redirected to ${(isOperator ? '/console/login' : '/notices')}`);
        } else if (token) {
          window.location.replace(process.env.REACT_APP_PUBLIC_URL + (isOperator ? '/console/login' : '/notices'));
        }
      }
      throw new Error(err?.response?.data?.error || err?.response?.data?.message || err);
    }
  };

  export default axiosCall;