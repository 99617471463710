import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import DialogHeader from '@components/DialogHeader';

type TEditIntentDialogProps = {
  open: boolean;
  edit: boolean;
  intentData: any;
  error: string;
  onAdd: (intentData: any) => Promise<void>;
  onEdit: (intentData: any) => Promise<void>;
  onClose: () => void;
  refreshData: () => Promise<void>;
  functions: any[];
};

const emptyIntent = {
  intent: '',
  microIntent: '',
  description: '',
  payload: '',
  samples: [],
  active: false,
};

const EditIntentDialog = ({
  open,
  edit,
  intentData,
  error,
  onClose,
  onEdit,
  onAdd,
  refreshData,
  functions,
}: TEditIntentDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [input, setInput] = useState(intentData ?? emptyIntent);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Remove blank commands and samples
    const submitData = { ...input };
    submitData.samples = submitData.samples.filter((sample) => sample.trim());

    if (edit) {
      onEdit(submitData);
    } else {
      onAdd(submitData);
    }
    refreshData();
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogHeader title={edit ? t('editIntent') : t('addIntent')} onClose={onClose} />
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: '400px', mt: 1 }}>
            <TextField
              required
              size="small"
             
              variant="outlined"
              label={t('intentCol')}
              onChange={(e) => setInput((prev) => ({ ...prev, intent: e.target.value }))}
              value={input.intent}
            />
            <TextField
              size="small"
             
              variant="outlined"
              label={t('microIntentCol')}
              onChange={(e) => setInput((prev) => ({ ...prev, microIntent: e.target.value }))}
              value={input.microIntent}
            />
            <TextField
              size="small"
             
              variant="outlined"
              label={t('descriptionCol')}
              onChange={(e) => setInput((prev) => ({ ...prev, description: e.target.value }))}
              value={input.description}
            />
            <TextField
              select
              size="small"
             
              variant="outlined"
              label={t('tFunction')}
              value={input.command}
              SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500 } } } }}
              onChange={(e) => setInput((prev) => ({ ...prev, command: e.target.value }))}
            >
              <MenuItem key={0} value={null}>
                &nbsp;
              </MenuItem>
              {functions?.map((func) => (
                <MenuItem key={func?._id} value={func?._id}>
                  {func.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              size="small"
             
              variant="outlined"
              label={t('payloadCol')}
              onChange={(e) => setInput((prev) => ({ ...prev, payload: e.target.value }))}
              value={input.payload}
            />
            <Button
              variant="outlined"
              color="info"
              onClick={() => {
                setInput((prev) => {
                  const newSamples = [...prev.samples, ''];
                  return { ...prev, samples: newSamples };
                });
              }}
            >
              {t('addSample')}
            </Button>
            {!!input.samples.length &&
              input.samples.map((sample, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <DeleteOutlineIcon
                    onClick={() => {
                      setInput((prev) => {
                        prev.samples.splice(index, 1);
                        return { ...prev, samples: [...prev.samples] };
                      });
                    }}
                    fontSize="small"
                    sx={{ cursor: 'pointer' }}
                  />
                  <TextField
                    sx={{ flexGrow: 1 }}
                    size="small"
                   
                    variant="outlined"
                    label={t('tSample')}
                    onChange={(e) => {
                      setInput((prev) => {
                        prev.samples[index] = e.target.value;
                        return { ...prev, samples: [...prev.samples] };
                      });
                    }}
                    value={sample}
                  />
                </Box>
              ))}
            <FormControlLabel
              control={
                <Checkbox
                  color="info"
                  checked={input.active}
                  onChange={(e) => {
                    setInput((prev) => ({ ...prev, active: e.target.checked }));
                  }}
                />
              }
              label={t('activeTranslation')}
              labelPlacement="end"
            />
            <Typography color={theme.palette.red['400']}>{error}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={onClose}>
            {t('cancelButton')}
          </Button>
          <Button type="submit" variant="contained" color="info">
            {t('okButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditIntentDialog;
