import React, { useCallback, useState, forwardRef, PropsWithChildren } from 'react';
import useInitialMount from '@common/hooks/useInitialMount';
const CoffeeSingleGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/CoffeeSingle.gif';
const CapSingleGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/CapSingle.gif';
const ShirtOneSingleGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/ShirtOneSingle.gif';
const ShirtTwoSingleGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/ShirtTwoSingle.gif';
const GiftBoxSingleGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/GiftBoxSingle.gif';
const Spinning = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Spinning.gif';

const SlotMachineNoPrizeDisplay: React.FC<PropsWithChildren<any>> = forwardRef((props, ref: any) => {
    // These state variable are as three independent values, to better stagger their display
    const [firstCol, setFirstCol] = useState(Spinning);
    const [secondCol, setSecondCol] = useState(Spinning);
    const [thirdCol, setThirdCol] = useState(Spinning);

    const randomizeOnMount = useCallback(() => {

        const randomizeColumns = () => {
            let gifs = [CoffeeSingleGif, CapSingleGif, ShirtOneSingleGif, ShirtTwoSingleGif, GiftBoxSingleGif];
            let firstCol = gifs[Math.floor(Math.random() * (gifs.length - 1))];
            setTimeout(() => {
                setFirstCol(firstCol);
            }, 500)
            let secondCol = gifs.filter( g => g !== firstCol)[Math.floor(Math.random() * (gifs.length - 2))];
            setTimeout(() => {
                setSecondCol(secondCol);
            }, 1500)
            let thirdCol = gifs.filter( g => g !== secondCol)[Math.floor(Math.random() * (gifs.length - 2))];
            setTimeout(() => {
                setThirdCol(thirdCol);
            }, 2500)
        }

        randomizeColumns();
    }, [])

    useInitialMount(randomizeOnMount);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', height: '167px', width: '263px' }}>
            <img src={firstCol} alt="First Column" />
            <img src={secondCol} alt="Seccond Column" />
            <img src={thirdCol} alt="Third Column" />
        </div>
    );
});

export default SlotMachineNoPrizeDisplay;
