import { MorganTheme } from '@mui/material';
import ServiceWorkerWrapper from './Components/ServiceWorker';
import { CaptchaProvider } from '@contexts/captcha';
import { AppContext } from '@contexts/AppContext';
import { useRef } from 'react';

const React = await import('react');

const { ThemeProvider, createTheme } = await import ('@mui/material');
const { StyledEngineProvider } = await import('@mui/material/styles');

const { Route, Routes, useSearchParams } = await import('react-router-dom');

const {DndProvider} = await import ('react-dnd');
const {HTML5Backend} = await import('react-dnd-html5-backend');

const WebFont = await import('webfontloader');

const { setIsThinClient } = await import('./Storage/userStorage');

const Console = React.lazy(() => import('./Pages/Console/Console'));
const Conversation = React.lazy(() => import('./Pages/Conversation/Consent'));
const Login = React.lazy(() => import('./Pages/Console/Login'));
const MorganWebHome = React.lazy(() => import('./Pages/Home/MorganWebHome'));
const Unsubscribe = React.lazy(() => import('./Pages/Unsubscribe'));
const OrderUpdate = React.lazy(() => import('./Pages/OrderUpdate'));
const WidgetTest = React.lazy(() => import('./Pages/WidgetTest'));
const EngineDev = React.lazy(() => import('./Pages/EngineDev'));

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700', 'Poppins:400,500,600,800', 'Barlow Condensed:400'],
  },
});

const IOS_APP_STORE_URL = 'https://apps.apple.com/us/app/morgan-empathetic-technology/id1667401139'; 
const ANDROID_APP_STORE_URL =
  'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.swmc.morganthinclientandroid';

export const App = () => {
  /*
   * This function gets the query parameters and checks to see if the user is
   * requesting a specific theme. If they are, we attempt to load it from the
   * Themes directory. If there is no matching theme then we simply keep using
   * the default!
   */
  const [searchParams] = useSearchParams();
  // new UI Version available Reload Button/Banner state variable
  const [showReloadButton, setShowReloadButton] = React.useState(false);
  const serviceWorkerWrapperRef = useRef(null);

  // Attempt to detect Thin Client by looking for the `thinClientKey` query
  // parameter:
  const tcKey = searchParams.get('thinClientKey');
  if (tcKey === process.env.REACT_APP_THIN_CLIENT_KEY) {
    setIsThinClient('true');
  } 

  // This removes the console logs for the specified environments. 
  // The console.error was left to allow errors to be reported by users via the console if needed.
  if (['swmc'].includes(process.env.REACT_APP_ENVIRONMENT)) {
    console.debug = () => {}
    console.log = () => {}
    console.warn = () => {}
  }
  // Function checks if theme file exists, otherwise set theme to default.
  // Then appends path.
  const resolveThemePath = React.useMemo(() => {
    const themePath = './Assets/Themes/';
    let themeName = 'default';
    let fullThemePath = '';

    try {
      
      if(searchParams.get('t')){
        themeName = searchParams.get('t');
      } else if (searchParams.get('company')){
        
        const company = searchParams.get('company');
        const branch = searchParams.get('branch');
  
        themeName = [company, branch].join('');
      }

      fullThemePath = `${themePath}${themeName}.json`;
      require.resolve(`${fullThemePath}`);
    } catch (e) {
      themeName = 'default';
      fullThemePath = `${themePath}${themeName}.json`;
    }
    console.log('Using theme:', fullThemePath)
    return fullThemePath;
  }, []);

  const theme = React.useMemo(() => createTheme(require(`${resolveThemePath}`)) as MorganTheme, [resolveThemePath]);

  React.useLayoutEffect(() => {
    console.warn(`Environment: ${process.env.REACT_APP_ENVIRONMENT}`);
    function setCssVariables(obj, parentKey = '') {
      for (let key in obj) {
        let newKey = parentKey + key.charAt(0).toUpperCase() + key.slice(1);
    
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          setCssVariables(obj[key], newKey);
        } else {
          const cssVariableName = `--${newKey.charAt(0).toLowerCase() + newKey.slice(1)}`;
          document.body.style.setProperty(cssVariableName, obj[key]);
        }
      }
    }
    setCssVariables(theme.palette);
    document.body.style.setProperty('--typographyFontFamily', theme.typography.fontFamily);
  },[theme]);

  // Use custom URL scheme to request users to open app if already installed
  // The app opens to the same link that the user clicked.
  // If the app is not installed, redirect user to the app listing on the
  // Apple App Store or Google Play Store to install the AngelAi app
  React.useEffect(() => {
    const userAgent = navigator.userAgent;
    const isIAB = /FBAN|FBAV|LinkedInApp|Instagram|Twitter/.test(userAgent);
    if (!isIAB) {
      return;
    }

    const isIos = /iPod|iPhone|iPad/.test(userAgent);
    const isAndroid = /android/.test(userAgent);
    // If user clicks an AngelAi link with the `/ce` pathname, redirect
    // URL will be `angelai://ce` which requests the user to open up AngelAi.
    // The app then handles the `angelai://ce` URL and serves the following
    // link `https://www.swmc.com/angelai/ce`
    // If theres no pathname, redirect URL will be `angelai://conversation`
    // This is necessary since `angelai://` alone will not redirect the user
    const base = 'angelai://';
    const pathname = window.location.pathname.slice(1) || 'conversation';
    const redirect = base + pathname;
    window.location.replace(redirect);

    const openAppStore = () => {
      if (isIos) {
        window.location.replace(IOS_APP_STORE_URL);
      }
      if (isAndroid) {
        window.location.replace(ANDROID_APP_STORE_URL);
      }
    };
    // If the user doesn't have the AngelAi app installed, redirect them to
    // the store after a short delay
    setTimeout(openAppStore, 25)
  }, []);

  return theme && (
    <StyledEngineProvider injectFirst>
      <CaptchaProvider>
        <ThemeProvider theme={theme}>
          <ServiceWorkerWrapper setShowReloadButton={setShowReloadButton} showReloadButton={showReloadButton} ref={serviceWorkerWrapperRef}/>
          <AppContext.Provider value={{ showReloadButton, setShowReloadButton, serviceWorkerWrapperRef }}>
            <DndProvider backend={HTML5Backend}>
              <Routes>
                <Route path="/conversation/:hashkey" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/conversation" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/notices" element={<React.Suspense fallback={<></>}><MorganWebHome /></React.Suspense>} />
                <Route path="/notices/:hashkey" element={<React.Suspense fallback={<></>}><MorganWebHome /></React.Suspense>} />
                <Route path="/invite/:hashkey" element={<React.Suspense fallback={<></>}><Conversation/></React.Suspense>} />
                <Route path="/invite" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/reply/:hashkey" element={<React.Suspense fallback={<></>}><Conversation/></React.Suspense>} />
                <Route path="/reply" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/uploadDocuments/:hashkey" element={<React.Suspense fallback={<></>}><Conversation/></React.Suspense>} />
                <Route path="/uploadDocuments" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/console" element={<React.Suspense fallback={<></>}><Console /></React.Suspense>} />
                <Route path="/console/:hashkey" element={<React.Suspense fallback={<></>}><Console /></React.Suspense>} />
                <Route path="/console/login" element={<React.Suspense fallback={<></>}><Login /></React.Suspense>} />
                <Route path="/console/login/:hashkey" element={<React.Suspense fallback={<></>}><Login /></React.Suspense>} />
                <Route path="/unsubscribe/:hashkey" element={<React.Suspense fallback={<></>}><Unsubscribe /></React.Suspense>} />
                <Route path="/unsubscribe" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/order/:hashkey" element={<React.Suspense fallback={<></>}><OrderUpdate /></React.Suspense>} />
                <Route path="/order" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/widget" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path='/widget-test' element={<React.Suspense fallback={<></>}><WidgetTest /></React.Suspense>} />
                <Route path="/:referrer" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
                <Route path="/:referrer/:hashkey" element={<React.Suspense fallback={<></>}><Conversation /></React.Suspense>} />
              </Routes>
            </DndProvider>
          </AppContext.Provider>
        </ThemeProvider>
      </CaptchaProvider>
    </StyledEngineProvider>
  );
}
