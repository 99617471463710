import React from 'react';
import Item from '@components/MessageContent/ProfileBubbleMessageContent/Item';
import styles from '@styles/MessageContent/ProfileBubbleMessageContent/List.module.css';
import * as ProfileBubbleTypes from "@mTypes/ProfileBubbleTypes";

interface Props {
  values: ProfileBubbleTypes.Values;
}

const COMPONENT = 'List';

/**
 * The ordered list of the message. If there are more than 99 values do not load
 * them as they will load outside the circle.
 */
export default function List(props: Props): React.ReactElement {
  return (
    <ol data-component={COMPONENT} className={styles.list}>
      {props.values.map((value, index) => {
        if (index + 1 > 99) {
          return null;
        } else {
          return <Item key={index} value={value} />;
        }
      })}
    </ol>
  );
}
