import { CircularProgress } from '@mui/material';
import { SendButton, HiddenButton } from '@styles/ChatSendButtonStyle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React, { useState, forwardRef, useImperativeHandle, PropsWithChildren, useRef } from 'react';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import useDebounce from '@common/hooks/useDebounce';
import { SendArrow } from '@assets/Icons';
// const SendGif = process.env.REACT_APP_MORGAN_CDN + '/Images/send_arrow.gif';
// const DisableMessageIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/DisableMessage.png';
const SendMessageIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/SendMessage.png';


const ChatSendButton: React.FC<PropsWithChildren<any>> = forwardRef((props, ref: any) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [imageError, setImageError] = useState(false);
  const htmlRef = useRef(null);
  // Adding functions to this component's ref
  useImperativeHandle(
    ref,
    () => {
      return {
        startAnimation() {
          if (!inputValue && !showAnimation) {
            setShowAnimation(true);
          }
        },
        update(newInputValue) {
          if (!inputValue && !showAnimation) {
            setShowAnimation(true);
          }
          setInputValue(newInputValue);
        },
        handleSubmit: props.handleSubmit,
        focus: () => {
          htmlRef?.current?.focus();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [inputValue, showAnimation, props.handleSubmit]
  );

  const revertShowAnimation = () => {
    if (showAnimation) {
      setShowAnimation(false);
    }
  };

  // setShowAnimation to false after the gif plays for 1150ms;
  useDebounce(revertShowAnimation, 1150, [showAnimation]);

  return (
    <>
      <SendButton
        className={`${props.disabled ? 'disabled ' : ''}`}
        ref={htmlRef}
        onClick={props.handleSubmit}
        onMouseDown={(e) => {e.preventDefault()}}
      >
        {/* forced the morgan icon to load quickly with the component */}
        {/* <link rel='preload' as='image' href={DisableMessageIcon} />
        {
          imageError ? <SendRoundedIcon className='sendRounded' /> : (
            <img
              src={props.disabled && !inputValue ? DisableMessageIcon : showAnimation ? SendGif : SendMessageIcon}
              alt="Send Button"
              style={{width: props.disabled && !inputValue ? '106%' : '100%', height: props.disabled && !inputValue ? '106%' : '100%'}}
              onError={() => setImageError(true)}
            />
          )
        }
        {props.workflow && props.userActions.length > 0 && (
          <CircularProgress
            size={50}
            variant='determinate'
            value={
              (props.userActions.filter((ua) => ua.status === 'resolved').length /
                props.userActions.length) *
              100
            }
            sx={{
              position: 'absolute',
            }}
          />
        )} */}
        <img
          src={SendMessageIcon}
          alt="Send Button"
          style={{width: props.disabled ? '106%' : '100%', height: props.disabled ? '106%' : '100%'}}
          onError={() => setImageError(true)}
        />
      </SendButton>

      {/* Hidden icon until user tabs over to it. For enhanced keyboard navigation */}
      {!props.operatorData && (
        <HiddenButton onClick={() => props.startOfErrands?.current?.focus()}>
          <KeyboardArrowRightIcon />
        </HiddenButton>
      )}
    </>
  );
});

export default ChatSendButton;
