import React, { createContext, ReactNode, useCallback, useContext, useReducer, useRef } from 'react';
import Captcha from '@components/Captcha';
import recaptcha from '@common/recaptchaHelper';
import ThinClientUtils from '@common/ThinClientUtils';
import useInterval from '@common/hooks/useInterval';

enum captchaActionType {
  PASS,
  FAIL,
  RESET,
};

/**
 * payload: passPayload | failPayload | resetPayload, could be added to 
 * dynamically add arguements for each action type while adhering to 
 * strict type safety.
 */
type reduceCaptchaAction = {
  type: captchaActionType,
};
type reduceCaptchaState = {
  isOpen: boolean;
  passed: boolean;
};
type captchaValue = reduceCaptchaState & {
  // useReducer should be typed as follows, not a (any, any) => void;
  // dispatchCaptcha: Dispatch<reduceCaptchaAction>; // do not include in value, use helpers
  failCaptcha: () => void; // open the recaptcha modal
  passCaptcha: () => void; // close the recaptcha modal
  resetCaptcha: () => void; // close recaptcha model and set passed to null
  testCaptcha: (action: String, callback?: (passed: Boolean) => void) => Promise<Boolean>; // detect bots and show modal as needed
  addSentMessage: (isOperator: Boolean) => void; // detect and handle message spam
};

type captchaProviderProps = {
  children: ReactNode;
};

/**
 * As MRGN-265 asks for, on the frontend display Peter's Captcha when
 * the user sends more than 3 consecutive messages in less 2 seconds.
 */
const LIMITER_RESET_TIME = 2 * 1000; // 2 seconds
const LIMITER_MAX_MSG = 3;

const CaptchaContext = createContext<captchaValue | null>(null);

const useCaptchaContext = () => {
  const captchaContext = useContext(CaptchaContext);
  if (captchaContext === undefined) {
    // throwing this error helps in development when the context is out of scope.
    throw new Error('useCaptchaContext must be inside a CaptchaProvider');
  }
  return captchaContext;
};

/**
 * This reducer function is called with every dispatch event and allows you to
 * set up predefined logic for how each action should change the state. 
 * Using this method over setState provides a single source of truth for all
 * state interactions and makes it easier to maintain the code as we move forward.
 * The goal will be to get all of our core states switched over to reducers but
 * first we need to elimate any instances where setState is being called simply
 * to obtain the value of the state.
 * @param prev akin to setState((prev)...
 * @param action the action data being dispatched
 * @returns reduceCaptchaState
 * Notice: the dispatch function returns void, you cannot get the state from dispatch
 */
const reduceCaptcha = (prev: reduceCaptchaState, action: reduceCaptchaAction): reduceCaptchaState => {
  switch (action.type) {
    case captchaActionType.FAIL:
      return {
        isOpen: true,
        passed: false,
      };
    case captchaActionType.PASS:
      return {
        isOpen: false,
        passed: true,
      };
    default:
      return {
        isOpen: false,
        passed: null,
      };
  }
};

/**
 * This provider function localizes states and improves maintainability whereever
 * this producer is implemented. In this case, we have it at the root level to
 * better allow users to understand why they were blocked from the system by 
 * having the captcha model appear immediately on redirect.
 * @param children (ReactNode): always a default state for context providers
 * @returns 
 */
const CaptchaProvider = ({ children }: captchaProviderProps): JSX.Element => {
  const callbackRef = useRef<(passed: Boolean) => void>((passed) => {});
  const messageCountRef = useRef<number>(0);
  const [captcha, dispatchCaptcha] = useReducer(
    reduceCaptcha, 
    {
      isOpen: false,
      passed: null,
    }
  );

  // helper function to easily allow children to fail captcha
  const failCaptcha = useCallback(() => {
    dispatchCaptcha({ type: captchaActionType.FAIL });
    callbackRef.current(false);
  }, []);

  // helper function to easily allow children to pass captcha
  const passCaptcha = useCallback(() => {
    dispatchCaptcha({ type: captchaActionType.PASS });
    callbackRef.current(true);
  }, []);

  // helper function to easily allow children to reset captcha
  const resetCaptcha = useCallback(() => {
    dispatchCaptcha({ type: captchaActionType.RESET });
    callbackRef.current = (passed) => {};
  }, []);

  // triggers grecaptcha to detect bots, automatically passes or fails captcha as needed
  const testCaptcha = useCallback(async (action: String, callback?: (boolean) => void): Promise<Boolean> => {
    callbackRef.current = typeof callback === 'function' ? callback : (passed) => {};

    // If recaptcha not enabled or the user is on the app, pass captcha by default
    if (process.env.REACT_APP_GOOGLE_RECAPTCHA_ENABLED !== 'true' || ThinClientUtils.isThinClient()) {
      passCaptcha();
      return true;
    }
    /**
     * grecaptcha is an object that is loaded by the google api in index.html. eslint doesn't understand this, so the
     * disable comments are to allow the app to run without error
     */
    const result = await new Promise<boolean>((resolve) => {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      grecaptcha.enterprise.ready(function () {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        grecaptcha.enterprise
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY, { action })
          .then(async (token: String) => {
            const result = await recaptcha(token, action);
            console.log(`grecaptcha result: ${result}`);
            resolve(result);
          })
          .catch((error) => {
            console.error(`grecaptcha encountered an error`, error);
            resolve(false);
          });
      });
    });
    (action === 'spam' ? failCaptcha : result ? passCaptcha : failCaptcha)();
    return result;
  }, [failCaptcha, passCaptcha]);

  const resetMessageCountRef = useCallback(() => messageCountRef.current = 0, []);
  useInterval(resetMessageCountRef, LIMITER_RESET_TIME);

  const addSentMessage = useCallback((isOperator) => {
    if (captcha.isOpen || captcha.passed) return;
    messageCountRef.current = messageCountRef.current + 1;

    if (isOperator || messageCountRef.current <= LIMITER_MAX_MSG) return;
    testCaptcha('spam');
  }, [captcha.isOpen, captcha.passed, testCaptcha]);

  return (
    <CaptchaContext.Provider value={{ ...captcha, failCaptcha, passCaptcha, resetCaptcha, testCaptcha, addSentMessage }}>
      {children}
      {/* conditional render required to prevent loadCaptchaEnginge */}
      {captcha.isOpen && <Captcha />}
    </CaptchaContext.Provider>
  );
};

export { CaptchaProvider, useCaptchaContext };
