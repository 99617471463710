import React, { memo } from 'react';

import { QuickActionIcon } from '@assets/Icons/index';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import getImageSource from '@common/getImageSource';
import Styles from '@styles/MorphUserSuggestions.module.css';
import { useTranslation } from 'react-i18next';

const getSingleSlideElementStyle = () => {
  return {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // border: props?.isLoadingElement ? 'none' : '2px solid var(--blue950)',
    boxShadow: `0 0 6px var(--shadow300)`,
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '6px',
    fontFamily: 'Poppins',
    fontWeight: 600,
    color: 'rgb(255, 116, 0)'
  } as React.CSSProperties;
};

const SlideIcon = (props) => {
  const { t } = useTranslation();
  const imgSrc = props?.workflowIcon ? getImageSource(props.workflowIcon) : '';
  return imgSrc !== '' ? (
    <img
      src={imgSrc}
      style={{
        width: '1.875rem',
        height: '1.875rem',
        filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
      }}
      alt={t('UPM_Icon_displaying_a_workflow')}
    />
  ) : (
    <QuickActionIcon style={{ color: 'var(--gray000)' }} />
  );
};

// default classes for SingleSlideElement
const defaultClasses = [Styles.UserSelectNone, Styles.singleSlideElement];
const finalClasses = [Styles.SingleSlideElementHover, ...defaultClasses];

const getPStyle = () => {
  return {
    fontSize: '0.8rem',
    textAlign: 'center',
    lineHeight: '13px',
    overflowWrap: 'break-word',
    maxWidth: '100%',
  } as React.CSSProperties;
};

interface Props {
  isLoadingElement?: boolean;
  text: string;
  className: string;
}

const SingleSlideElement = memo((props: Props) => {
  // turn on hovering effect only on desktop
  let classNameStr = (props?.isLoadingElement || isMobileOrTablet() === true ? defaultClasses : finalClasses).join(' ');

  return (
    <div style={getSingleSlideElementStyle()} className={`${classNameStr} ${props.className}`}>
      {/* Title */}
      <p style={getPStyle()}>{props.text}</p>
    </div>
  );
});

export { SingleSlideElement };
