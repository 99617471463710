import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import DialogHeader from '@components/DialogHeader';

type TEditEntityDialogProps = {
  open: boolean;
  edit: boolean;
  entityData: any;
  error: string;
  onAdd: (entityData: any) => Promise<void>;
  onEdit: (entityData: any) => Promise<void>;
  onClose: () => void;
};

const emptyEntity = {
  name: '',
  metadata: { endpoint: '', score: 0 },
  words: [],
  synonyms: [],
};

const EditEntityDialog = ({ open, edit, entityData, error, onClose, onEdit, onAdd }: TEditEntityDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [input, setInput] = useState(() => {
    if (!entityData) {
      return emptyEntity;
    }
    if (!Array.isArray(entityData.words)) {
      if (typeof entityData.words === 'object') {
        entityData.words = Object.keys(entityData.words ?? {});
      } else {
        entityData.words = [];
      }
    }
    if (!entityData.metadata) {
      entityData.metadata = emptyEntity.metadata;
    }
    return entityData;
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Remove blank words and synonyms
    const submitData = { ...input };
    submitData.words = submitData.words.filter((word) => word.trim());
    submitData.synonyms = submitData.synonyms.filter((synonym) => synonym.trim());

    if (edit) {
      onEdit(submitData);
    } else {
      onAdd(submitData);
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogHeader title={edit ? t('editEntity') : t('addEntity')} onClose={onClose} />
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: '400px', mt: 1 }}>
            <TextField
              required
              size="small"
             
              variant="outlined"
              label={t('nameCol')}
              onChange={(e) => setInput((prev) => ({ ...prev, name: e.target.value }))}
              value={input.name}
            />
            <TextField
              size="small"
             
              variant="outlined"
              label={t('endpointCol')}
              onChange={(e) =>
                setInput((prev) => ({ ...prev, metadata: { ...prev.metadata, endpoint: e.target.value } }))
              }
              value={input.metadata.endpoint}
            />
            <TextField
              type="number"
              size="small"
             
              variant="outlined"
              label={t('scoreCol')}
              onChange={(e) => setInput((prev) => ({ ...prev, metadata: { ...prev.metadata, score: e.target.value } }))}
              value={input.metadata.score}
            />
            <Button
              variant="outlined"
              color="info"
              onClick={() => {
                setInput((prev) => {
                  const newSynonyms = [...prev.synonyms, ''];
                  return { ...prev, synonyms: newSynonyms };
                });
              }}
            >
              {t('addSynonym')}
            </Button>
            {!!input.synonyms.length &&
              input.synonyms.map((synonym, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <DeleteOutlineIcon
                    onClick={() => {
                      setInput((prev) => {
                        prev.synonyms.splice(index, 1);
                        return { ...prev, synonyms: [...prev.synonyms] };
                      });
                    }}
                    fontSize="small"
                    sx={{ cursor: 'pointer' }}
                  />
                  <TextField
                    spellCheck={false}
                    multiline={true}
                    sx={{ flexGrow: 1 }}
                    size="small"
                   
                    variant="outlined"
                    label={t('tSynonym')}
                    onChange={(e) => {
                      setInput((prev) => {
                        prev.synonyms[index] = e.target.value;
                        return { ...prev, synonyms: [...prev.synonyms] };
                      });
                    }}
                    value={synonym}
                  />
                </Box>
              ))}
            <Button
              variant="outlined"
              color="info"
              onClick={() => {
                setInput((prev) => {
                  const newWords = [...prev.words, ''];
                  return { ...prev, words: newWords };
                });
              }}
            >
              {t('addWord')}
            </Button>
            {!!input.words.length &&
              input.words.map((word, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <DeleteOutlineIcon
                    onClick={() => {
                      setInput((prev) => {
                        prev.words.splice(index, 1);
                        return { ...prev, words: [...prev.words] };
                      });
                    }}
                    fontSize="small"
                    sx={{ cursor: 'pointer' }}
                  />
                  <TextField
                    spellCheck={false}
                    multiline={true}
                    sx={{ flexGrow: 1 }}
                    size="small"
                   
                    variant="outlined"
                    label={t('tWord')}
                    onChange={(e) => {
                      setInput((prev) => {
                        prev.words[index] = e.target.value;
                        return { ...prev, words: [...prev.words] };
                      });
                    }}
                    value={word}
                  />
                </Box>
              ))}
            <Typography color={theme.palette.red['400']}>{error}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="info" onClick={onClose}>
            {t('cancelButton')}
          </Button>
          <Button type="submit" variant="contained" color="info">
            {t('okButton')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditEntityDialog;
