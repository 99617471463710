import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Close } from '@assets/Icons';
import InfiniteScroll from 'react-infinite-scroller';
import RefreshIcon from '@mui/icons-material/Refresh';
import ScrollBox from '@components/ScrollBox';
import SearchIcon from '@mui/icons-material/Search';
import axiosCall from '@services/axios';
import { useTranslation } from 'react-i18next';


const AddOperatorToConvDialog = (props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [operators, setOperators] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');
  const [searched, setSearched] = useState(false);
  const LIMIT = 10;

  const loadOperatorData = async () => {
    setOperators(null);
    let data;
    if (search.length > 0) {
      data = { search: search };
      setSearched(true);
    } else {
      data = "";
      setSearched(false);
    }

    let res = await axiosCall( {
      url: `operator/participants/access/?fields=firstname,lastname,team,subTeam,status,accessLevel&order=asc&orderBy=lastname&offset=0&limit=${LIMIT}&active=1&status=-offline`,
      method: "POST",
      data: data
    });

    setOffset(1);
    setHasMore(res.length >= LIMIT);
    // Filter out any operators that are already participants
    // 8/1/2024 - Dennis don't need to check active == true on the res object because the endpoint returns the list of active participants
    // But when comparing with the errand participants we need to check the active flag because this object returns all participants that
    // were added at any given point
    setOperators(res.filter(o => props.errand?.participants.findIndex(p => p?.active && p?.userData?._id === o?._id) === -1));
  }

  const handleClose = () => {
    setSelected([]);
    props.onClose();
  }

  const loadMore = async () => {
    // If were already loading dont do it again
    if (isLoading) return;

    let data;
    if (search.length > 0) {
      data = { search: search };
      setSearched(true);
    } else {
      data = "";
      setSearched(false);
    }

    setIsLoading(true); // lock
    let res = await axiosCall( {
      url: `operator/participants/access/?fields=firstname,lastname,team,subTeam,status,accessLevel&order=asc&orderBy=lastname&offset=${offset * LIMIT}&limit=${LIMIT}&active=1&status=-offline`,
      method: "POST",
      data: data
    });
    setOffset(prev => prev + 1);
    setHasMore(res.length >= LIMIT);
    // Filter out any operators that are already participants
    // 8/1/2024 - Dennis don't need to check active == true on the res object because the endpoint returns the list of active participants
    // But when comparing with the errand participants we need to check the active flag because this object returns all participants that
    // were added at any given point
    let filteredOps = res.filter(o => props.errand?.participants.findIndex(p => p?.active && p?.userData?._id === o?._id) === -1);
    setOperators((prev) => {
      if (prev) {
        return [...prev, ...filteredOps]
      } else {
        return [...filteredOps];
      }
    });

    setIsLoading(false); // unlock
  }

  useEffect(() => {
    // Only get the operators when the dialog is opened
    //  not everytime conversation title is loaded.
    if (props.open) {
      loadOperatorData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  // search 500ms after user gets done typing
  useEffect(() => {
    if (search.length > 0 || searched) {
      const timeoutId = setTimeout(() => { loadOperatorData() }, 500);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Dialog maxWidth={'lg'} open={props.open} onClose={() => handleClose()}>

      <DialogContent>
        <Stack direction="row" spacing={1}>
          <TextField
            fullWidth
            label={t('actionListSearch')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            onChange={(e) => { setSearch(e.target.value); }}
          />
          <IconButton size="small" onClick={async () => {
            setIsLoading(true);
            setOffset(0);
            await loadOperatorData();
            setIsLoading(false);
          }}>
            <RefreshIcon />
          </IconButton>
          <IconButton onClick={() => handleClose()} >
            <Close
              style={{ width: '16px', height: '16px', cursor: 'pointer' }}
              color="var(--gray210)"
            />
          </IconButton>
        </Stack>
        <ScrollBox sx={{ minWidth: '50vw', maxWidth: '90vw' }}>
          {operators ? (
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={hasMore}
              useWindow={false}
              loader={
                <Stack key={operators?.length + 1} justifyContent="center" alignItems="center">
                  <CircularProgress size="2em" />
                </Stack>
              }>
              {operators.length === 0 ? (
                <>
                  {!isLoading && (
                    <Stack
                      paddingTop="200px"
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: '100%',
                        width: '100%',
                      }}>
                      <Paper sx={{ p: 1.5 }}>
                        <Typography> {t('noData')} </Typography>
                      </Paper>
                    </Stack>
                  )}
                </>
              ) : (
                <TableContainer >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> {t('addOperatorSelect')} </TableCell>
                        <TableCell> {t('addOperatorName')} </TableCell>
                        <TableCell> {t('addOperatorGuestCount')} </TableCell>
                        <TableCell> {t('addOperatorTeam')} </TableCell>
                        <TableCell> {t('addOperatorSubteam')} </TableCell>
                        <TableCell> {t('addOperatorAccess')} </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {operators?.length > 0 &&
                        operators
                          .map((op, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Checkbox
                                  checked={selected.findIndex((s) => s?._id === op?._id) !== -1}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      // If we are checked that means that this op as just chosen. add it!
                                      setSelected((prev) => [...prev, { _id: op?._id, accessLevel: op?.accessLevel }]);
                                    } else {
                                      // The box just got unchecked so we should remove this operator
                                      setSelected((prev) => prev.filter((e) => e?._id !== op?._id));
                                    }
                                    console.log(selected);
                                  }}
                                />
                              </TableCell>
                              <TableCell> {`${op.firstname} ${op.lastname}`} </TableCell>
                              <TableCell> {op.activeChats} </TableCell>
                              <TableCell> {op.team?.[0]} </TableCell>
                              <TableCell> {op.subTeam?.[0]} </TableCell>
                              <TableCell> {op.accessLevel} </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </InfiniteScroll>
          ) : (
            <Stack
              key={props.messages?.length + 1}
              justifyContent="center"
              alignItems="center"
              height="100%">
              <CircularProgress size="2em" />
            </Stack>
          )}
        </ScrollBox>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>
          {t('cancelButton')}
        </Button>
        <Button
          disabled={selected.length === 0}
          variant="contained"
          onClick={() => {
            props.onClose();
            //alert(`This would add the following operators: ${selected}`);
            console.log(`Adding the following operators: ${selected} to chat ${props.errand?._id}`);
            selected.map(async (s) => {
              try {
                let res = await axiosCall( {
                  url: `chat/${props.errand?._id}/participant`,
                  method: 'POST',
                  data: {
                    userId: s?._id,
                    userType: 'Operator',
                    notify: props.operatorData,
                    ...(s?.accessLevel === 'Level 0' ? {ai:true} : {}),
                  },
                });
                // check if setParticipants exists
                if (props.setParticipants) {
                  // if so, update participants directly
                  props.setParticipants((prev) => [...prev, res]);
                }
                // check if setErrands exists
                if (props.setErrands) {
                  // if so, update the participants for the corresponding errand
                  props.setErrands((prev) => {
                    const chatObj = prev.find((e) => e._id === props.errand?._id);

                    if (chatObj) {
                      // if so, update the participants for the corresponding index
                      chatObj.participants = [...chatObj.participants, res];
                    }

                    return [...prev];
                  });
                }
                setSelected([]);
              } catch (e) {
                console.error(e);
              }
            });
          }}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOperatorToConvDialog;
