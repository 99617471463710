import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditFieldAttributeDialog from './EditFieldAttributeDialog';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import StandardTablePagination from './StandardTablePagination';
import TableHeaderBar from './TableHeaderBar';
import downloadFieldAttributeReport from './DownloadFieldAttributeReport';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

const FieldAttributes = (props) => {
  const { t } = useTranslation();
  const [editFieldAttributeState, setEditFieldAttributeState] = useState({
    edit: false,
    open: false,
    data: null,
  });
  const [fieldAttributesData, setFieldAttributesData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [search, setSearch] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [getActive, setGetActive] = useState(true);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searched, setSearched] = useState(false);
  const [fieldAttributeCount, setFieldAttributeCount] = useState(0);

  const loadFieldAttributesData = async (controller) => {
    setLoading(true);
    // Load one more row than required to test if more data exists
    const offset = page * rowsPerPage;
    const limit = rowsPerPage > 0 ? `&limit=${rowsPerPage + 1}` : '';
    const active = getActive ? '&active=1' : '';
    let data;
    if (search.length > 0) {
      data = { search: search };
      setSearched(true);
    } else {
      data = '';
      setSearched(false);
    }

    let request = {
      url: `fieldAttribute/db/search/?order=asc&orderBy=description&offset=${offset}${limit}${active}`,
      method: 'POST',
      data: data,
    };
    let config = controller
      ? { signal: controller.signal }
      : {};

    try {
      const result = await axiosCall(request, config);
      if (result?.length > 0) {
        // See if we were able to fetch the one additional row, therefore indicating more data is available
        setIsMoreDataAvailable(rowsPerPage > 0 ? result?.length > rowsPerPage : false);
        setFieldAttributesData(rowsPerPage > 0 ? result?.slice(0, rowsPerPage) : result); // Only display the number specified
        try {
          const a = active ? "active=true" : ""
          const result = await axiosCall({ url: `fieldAttribute/count?${a}` ,method: 'GET',});
          if (result) {
            setFieldAttributeCount(result)
          }
        } catch (error) {
          console.log(error);
        }
        setNoResults(false);
      } else {
        setNoResults(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDownloadFieldAttributesReport = async () => {
    try {
      const result = await axiosCall({ url: `fieldAttribute/?order=asc&orderBy=name` });
      if (result) {
        downloadFieldAttributeReport(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*************************************
   * Add/Edit Field Attribute Dialog Functions
   *************************************/
  const handleAddFieldAttributeOpen = () => {
    setEditFieldAttributeState({ edit: false, open: true, data: null });
  };

  const handleEditFieldAttributeOpen = (action) => {
    setEditFieldAttributeState({ edit: true, open: true, data: action });
  };

  const handleEditFieldAttributeClose = () => {
    setEditFieldAttributeState({ open: false, data: null });
    setErrorMessage('');
  };

  const handleEditFieldAttributeSubmit = async (fieldAttributeData) => {
    if (editFieldAttribute(fieldAttributeData)) {
      setEditFieldAttributeState({ open: false, data: null });
      setErrorMessage('');
    }
  };

  const handleAddFieldAttributeSubmit = async (fieldAttributeData) => {
    if (addFieldAttribute(fieldAttributeData)) {
      setEditFieldAttributeState({ open: false, data: null });
      setErrorMessage('');
    }
  };

  const editFieldAttribute = async (data) => {
    const { id, ...sendData } = data;
    try {
      let result = await axiosCall({
        url: `fieldAttribute/${id}`,
        method: 'PUT',
        data: sendData,
      });

      if (result) {
        await loadFieldAttributesData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  const addFieldAttribute = async (data) => {
    try {
      let result = await axiosCall({
        url: `fieldAttribute`,
        method: 'POST',
        data: data,
      });

      if (result) {
        await loadFieldAttributesData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  /*************************************
   * Delete Dialog Functions
   *************************************/
  const handleDelete = async (id) => {
    try {
      const result = await axiosCall({
        url: `fieldAttribute/${id}`,
        method: 'DELETE',
      });
      if (result) {
        await loadFieldAttributesData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowInactive = () => {
    //Invert this value
    setGetActive(!getActive);
    setPage(0);
  };

  const handleReactivate = async (id) => {
    try {
      let result = await axiosCall({
        url: `fieldAttribute/${id}`,
        method: 'PUT',
        data: {
          active: true,
        },
      });

      if (result) {
        await loadFieldAttributesData();
      }
    } catch (error) { }
  };

  /*************************************
   * Pagination Functions
   *************************************/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (newSearchValue) => {
    // We want to reset to the first page when searching so we don't miss any documents 
    // (for example, we don't want to begin our search on the second page 
    // as we would miss those on the first)
    setPage(0);
    // Now we can update the search value
    setSearch(newSearchValue);
  };

  //When fieldAttributesData changes, update datatoDisplay
  useEffect(() => {
    setDataToDisplay(fieldAttributesData);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldAttributesData]);

  // Reload data when page or rowsPerPage changes
  useEffect(() => {
    const controller = new AbortController();
    loadFieldAttributesData(controller);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, getActive]);

  // search 500ms after user gets done typing
  useEffect(() => {
    if (search.length > 0 || searched) {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => { loadFieldAttributesData(controller); }, 500, controller);

      return () => {
        clearTimeout(timeoutId);
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 175px)" padding={1}>
      <TableHeaderBar
        onAdd={handleAddFieldAttributeOpen}
        setSearch={handleSearchChange}
        setPage={setPage}
        onRefresh={loadFieldAttributesData}
        onExport={handleDownloadFieldAttributesReport}
        addLabel={t('addFieldAttribute')}
        searchValue={search}
        showInactiveClick={handleShowInactive}
        isActive={getActive}
      />
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="180px">{t('typeCol')}</StyledTableCell>
                    <StyledTableCell width="180px">{t('descriptionCol')}</StyledTableCell>
                    <StyledTableCell width="180px">{t('formatCol')}</StyledTableCell>
                    <StyledTableCell width="180px">{t('maskCol')}</StyledTableCell>
                    <StyledTableCell width="180px">{t('actionsCol')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToDisplay?.map((fa) => {
                    const active = fa.active ? 1 : 0;
                    return (
                      <TableRow
                        sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }}
                        data-id={fa?._id}
                        hover
                        key={fa?._id}>
                        <StyledTableCell>{fa.type}</StyledTableCell>
                        <StyledTableCell>{fa.description}</StyledTableCell>
                        <StyledTableCell>{fa.format}</StyledTableCell>
                        <StyledTableCell>{fa.mask}</StyledTableCell>
                        <StyledTableCell>
                          <Tooltip
                            title={t('editFieldAttribute')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <BorderColorOutlinedIcon
                              onClick={() => handleEditFieldAttributeOpen(fa)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '15px', color: 'var(--blue210)' }}
                            />
                          </Tooltip>
                          {active ? (
                            <Tooltip
                              title={t('deleteFieldAttribute')}
                              placement="top-start"
                              enterDelay={1000}
                              enterNextDelay={1000}>
                              <DeleteOutlinedIcon
                                onClick={() => handleDelete(fa?._id)}
                                fontSize="small"
                                sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t('reactivateFieldAttribute')}
                              placement="top-start"
                              enterDelay={1000}
                              enterNextDelay={1000}>
                              <RestoreFromTrashOutlinedIcon
                                onClick={() => handleReactivate(fa?._id)}
                                fontSize="small"
                                sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                              />
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      dataSize={fieldAttributeCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </>
        )}
      </TableContainer>
      {editFieldAttributeState.open && (
        <EditFieldAttributeDialog
          edit={editFieldAttributeState.edit}
          open={editFieldAttributeState.open}
          fieldAttribute={editFieldAttributeState.data}
          error={errorMessage}
          onClose={handleEditFieldAttributeClose}
          onSubmit={handleEditFieldAttributeSubmit}
          onAdd={handleAddFieldAttributeSubmit}
        />
      )}
    </Box>
  );
};

export default FieldAttributes;