import React, { useEffect, useState, useContext, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
import Styles from "../Styles/MorphPhotoMenu.module.css";
import Snackbar from '@mui/material/Snackbar';
import { useErrandContext } from '@contexts/ErrandContext';
import { useFooterContext } from '@contexts/FooterContext';

type MorphCalendarProps = {
  setText: (text: string) => void;
  setShowPermissionReminder: (value: boolean) => void;
  setIconToShow: (value: string) => void;
  setSelectedFiles: (value: any) => void;
  selectedFiles: File[];
};

const MorphCalendar: React.FC<React.PropsWithChildren<MorphCalendarProps>> = ({ setText, children, selectedFiles, ...props }) => {
  const errandContext = useErrandContext();
  const footerContext = useFooterContext();
  const { t, i18n } = useTranslation();
  const [isWarningActive, setIsWarningActive] = useState<string | null>(null);
  const swiperRef = useRef(null);
  const middleSlideIndex = Math.floor((errandContext.photoLimit || 5) / 2);
  const [centeredIndex, setCenteredIndex] = useState(middleSlideIndex); // Add state to track centered slide index

  const arrowLeft = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M8.08594 6.78063L6.40594 8.46063L8.08594 10.1406" stroke="var(--gray000)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13.125 8.46094L6.45094 8.46094" stroke="var(--gray000)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M7.875 13.75C4.97438 13.75 2.625 11.7813 2.625 8.5C2.625 5.21875 4.97438 3.25 7.875 3.25" stroke="var(--gray000)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

  const arrowRight = <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.78906 10.2194L9.46906 8.53937L7.78906 6.85938" stroke="var(--gray000)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M2.75 8.53906L9.42406 8.53906" stroke="var(--gray000)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 3.25C10.9006 3.25 13.25 5.21875 13.25 8.5C13.25 11.7812 10.9006 13.75 8 13.75" stroke="var(--gray000)" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  
  

  function renderDayLabel(index, isCentered) {
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + index);
  
    const dayOfWeek = targetDate.toLocaleDateString(i18n.language, { weekday: 'short' });
    const dayOfMonth = targetDate.getDate();

    return (
      <>
        <p className={Styles.dayOfMonth}>{dayOfMonth}</p>
        {isCentered && <p className={Styles.selectDay}>Select</p>}
        <p className={Styles.dayLabel}>{dayOfWeek}</p>

      </>
    );
  }

  const getDateForSlide = (index) => {
    const today = new Date();
    const targetDate = new Date(today);
    targetDate.setDate(today.getDate() + index);
    return targetDate;
  }
  
  const renderDayButton = (index) => {
    const isCentered = index === centeredIndex;
    const handleClick = () => {
      const date = getDateForSlide(index);
      const formattedDate = date.toLocaleDateString(i18n.language, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      if (formattedDate.includes('Saturday') || formattedDate.includes('Sunday')) {
        setIsWarningActive('Please select a weekday.');
        setTimeout(() => {
          setIsWarningActive(null); // Clears the warning message after 4 seconds
        }, 4000); // 4000 milliseconds = 4 seconds
      } else {
        footerContext.chatInputFieldRef.current?.update(formattedDate || '');
        footerContext.sendButtonRef.current?.update(formattedDate || '');
        isCentered && console.log(formattedDate); // Logs the date in the format "Wednesday, October 23, 2023"
      }
    };
    return (
      <button className={Styles.btn} key={index} onClick={handleClick}>
        <div className={Styles.btnBorder}>
          {renderDayLabel(index, isCentered)}
        </div>
      </button>
    );
  };


  const setMorphText = () => {
    const centeredSlideDate = getDateForSlide(centeredIndex);
    const monthName = centeredSlideDate.toLocaleDateString(i18n.language, { month: 'long' });
    setText(monthName.toUpperCase()); // You can capitalize the month name if needed
  };

  const handleSlideChange = () => {
    if (swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;

      // Reset all slides to normal scale
      swiperInstance.slides.forEach(slide => {
        if (slide && slide.style) {
          slide.style.transform = 'scale(1)';
          slide.style.marginTop = '0px';
        }
      });

      // Scale the centered slide
      const centeredSlide = swiperInstance.slides[swiperInstance.realIndex];
      setCenteredIndex(swiperInstance.realIndex);
      if (centeredSlide && centeredSlide.style) {
        centeredSlide.style.transform = 'scale(1.2)';
        centeredSlide.style.marginTop = '10px';
      }
    }
  };

  useEffect(() => {
    setMorphText();
  }, [errandContext.morphType, centeredIndex]);

  useEffect(() => {
    handleSlideChange();
    setMorphText();
    //these handle the cases in which the main photo was removed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandContext.mainPhoto, selectedFiles]);


  return (
    <>
      <Snackbar
        open={isWarningActive ? true : false}
        autoHideDuration={6000}
        onClose={() => setIsWarningActive(null)}
        message={isWarningActive}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      <div className={Styles.wrapper} style={{minWidth: '340px'}}>
      { <div className={Styles.swiperButtonPrev} onClick={() => swiperRef.current.swiper.slidePrev()}><p style={{marginTop: '43%', position: 'absolute', left:'-7px'}}>{arrowLeft}</p></div> }
        <Swiper
          ref={swiperRef}
          slidesPerView={5}
          centeredSlides={true}
          modules={[FreeMode, Navigation]}
          initialSlide={middleSlideIndex}
          onSlideChange={() => handleSlideChange()}
          slideToClickedSlide={true}
          freeMode={{
            enabled: true,
            sticky: true,
            momentumBounce: false,
            momentumRatio: 0.25,
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          style={{
            marginLeft: '7px',
            display: 'flex',
            width: '100%',
            position: 'absolute',
            height: '100%',
            marginTop: '15px',
            zIndex: '5',

            overflowY: 'hidden',
            scrollbarWidth: 'none', //hide scrollbar for Firefox
            msOverflowStyle: 'none', // hide scrollbar for IE and Edge
          }}
        >
          {Array.from({ length: 30 }, (_, i) => i + 1).map((contact, index) => (
            <SwiperSlide key={index}>
            {renderDayButton(index)}
          </SwiperSlide>
          ))}
        </Swiper>
        { <div className={Styles.swiperButtonNext} onClick={() => swiperRef.current.swiper.slideNext()}><p style={{fontSize: '15px', marginTop: '43%', position: 'absolute', right:'-8px'}}>{arrowRight}</p></div> }
        </div>

      <div className={Styles.bottomBorder}></div>
    </>
  );
};

export default MorphCalendar;