import {
  Box,
  CircularProgress,
  Switch,
  TextField,
  Typography,
  InputAdornment,
  // @ts-ignore
} from '@mui/material';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState, useRef } from 'react';

import useControllerCallback from '@common/hooks/useControllerCallback';
import axiosCall from '@services/axios';
import AIStatusPicker from '@components/AIStatusPicker';

let operatorAxios = axiosCall;

interface EditableFieldCellProps {
  currVal: string;
  standard?: boolean;
  unit?: string;
  url: string;
  reloadFunction: () => void;
  attribute: string;
  label: string;
}
/*************************************
 * EDITABLE FIELD CELL COMPONENT
 * On click, turns into a text field
 * Off click, makes a PUT request
 * To props.url to save
 * its value. Optionally, a refresh
 * function is passed in to reload the
 * page data.
 *************************************/
const EditableFieldCell: React.FC<EditableFieldCellProps> = (props) => {
  const [currVal, setCurrVal] = useState(props.currVal || '');
  const [clicked, setClicked] = useState(false);
  const [fieldSelected, setFieldSelected] = useState(false);
  const impactRef = useRef();

  useEffect(() => {
    setCurrVal(props.currVal || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currVal]);

  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleClickOutside = (evt) => {
        if (ref.current && !ref.current.contains(evt.target)) {
          if (fieldSelected) {
            callback();
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, callback, fieldSelected]);
  };

  const handleClick = async () => {
    if (clicked === true && props.currVal !== currVal) {
      try {
        let data = {};
        data[props.attribute] = currVal;
        let result = await operatorAxios({
          method: 'PUT',
          url: props.url,
          data: data,
        });
        if (result) {
          if (props.reloadFunction) {
            props.reloadFunction();
          }
        }
      } catch (error) {
        props.reloadFunction();
        console.log(error);
      }
    }
    setClicked(!clicked);
    setFieldSelected(true);
  };

  useOutsideClick(impactRef, handleClick);

  const handleChangeText = (e) => {
    setCurrVal(e.target.value);
  };

  return !clicked ? (
    <TextField
      label={props.label}
      color="info"
      variant={props.standard ? 'standard' : 'outlined'}
      sx={{
        borderRadius: `10px 10px 10px 10px`,
        backgroundColor: !props.standard ? 'var(--gray000)' : 'transparent',
      }}
      fullWidth
      ref={impactRef}
      value={currVal}
      onClick={handleClick}
      onBlur={() => setFieldSelected(false)}
      InputProps={{
        style: { backgroundColor: 'transparent' },
        endAdornment: (
          <InputAdornment position="end">
            <sub>{props.unit}</sub>
          </InputAdornment>
        ),
      }}
    ></TextField>
  ) : (
    <TextField
      label={props.label}
      color="info"
      variant={props.standard ? 'standard' : 'outlined'}
      sx={{
        borderRadius: `10px 10px 10px 10px`,
        backgroundColor: 'var(--gray000)',
      }}
      fullWidth
      ref={impactRef}
      value={currVal}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <sub>{props.unit}</sub>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        handleChangeText(e);
      }}
    ></TextField>
  );
};

// interface AIRolesEntryProps {
//   initTaskTriggers: Array<string>;
//   operatorId: string;
// }

// const AIRolesEntry: React.FC<AIRolesEntryProps> = (props) => {
//   const [recipients, setRecipients] = useState([{ label: '', value: '' }]);
//   const { t } = useTranslation();
//   const createOption = (label, value) => ({
//     label,
//     value,
//   });

//   useEffect(() => {
//     let options = [];
//     for (let i = 0; i < props.initTaskTriggers?.length; i++) {
//       if (props.initTaskTriggers[i]) {
//         options.push(createOption(props.initTaskTriggers[i], i));
//       } else {
//         options.push(createOption('', i));
//       }
//     }
//     setRecipients(options);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [props.initTaskTriggers]);

//   const handleChange = async (e, value, reason, details) => {
//     let roles = [];
//     for (let i = 0; i < value.length; i++) {
//       if (typeof value[i] === 'object') {
//         roles.push(value[i].label);
//       } else {
//         roles.push(value[i]);
//       }
//     }
//     if (reason === 'createOption' || reason === 'removeOption' || reason === 'selectOption') {
//       let inputValue = details.option;
//       let data = {
//         roles: roles,
//       };
//       try {
//         let result = await operatorAxios({
//           url: `ai/` + props.operatorId,
//           method: 'PUT',
//           data: data,
//         });

//         if (result) {
//           setRecipients((prev) => [...prev, createOption(inputValue, result?._id)]);
//         }
//       } catch (error) {
//         return false;
//       }
//     }
//     if (reason === 'removeOption') {
//       setRecipients(
//         roles.map((val) => {
//           return createOption(val, val);
//         })
//       );
//     }
//   };

//   return (
//     <Autocomplete
//       size="small"
//       noOptionsText={'Your Customized No Options Text'}
//       multiple
//       freeSolo
//       id="multiple-limit-tags"
//       options={['trainer', 'supervisor', 'processor', 'actions', 'tester']}
//       value={recipients}
//       onChange={(e, value, reason, details) => handleChange(e, value, reason, details)}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label={t('aiRoles')}
//           inputRef={(input) => {}}
//           inputProps={{
//             style: {
//               height: 38,
//             },
//             ...params.inputProps,
//           }}
//         />
//       )}
//     />
//   );
// };

const AISettings = ({ selectedAI, setSelectedAI }) => {
  const { t } = useTranslation();
  const [AIData, setAIData] = useState([] as any);
  const [aiOperatorData, setaiOperatorData] = useState([] as any);
  const [loading, setLoading] = useState(false);

  const loadAIData = useCallback(async (controller?: AbortController) => {
    const config = controller ? { signal: controller.signal } : {};
    setLoading(true);
    try {
      const result = await operatorAxios({
        url: 'ai',
      }, config);
      try {
        const operatorResult = await operatorAxios({
          url: `operator/${selectedAI}`,
        });
        setaiOperatorData(operatorResult);
      } catch (error) {
        console.log('operator data is undefined');
      }
      if (result) {
        if (selectedAI) {
          setAIData(result.find(config => config.operatorId === selectedAI));
        } else {
          setAIData(result[0]);
          setSelectedAI(result[0].operatorId);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [selectedAI, setSelectedAI]);

  const handleSwitchAIActive = async (id, activeValue) => {
    try {
      const result = await operatorAxios({
        url: `ai/` + id,
        method: 'PUT',
        data: {
          active: !activeValue,
        },
      });
      if (result) {
        await loadAIData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitchAIInference = async (id, activeValue) => {
    try {
      const result = await operatorAxios({
        url: `ai/` + id,
        method: 'PUT',
        data: {
          inference: !activeValue,
        },
      });
      if (result) {
        await loadAIData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitchAIExtraction = async (id, activeValue) => {
    try {
      const result = await operatorAxios({
        url: `ai/` + id,
        method: 'PUT',
        data: {
          extraction: !activeValue,
        },
      });
      if (result) {
        await loadAIData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitchAIAutoPilot = async (id, activeValue) => {
    try {
      const result = await operatorAxios({
        url: `ai/` + id,
        method: 'PUT',
        data: {
          autopilot: !activeValue,
        },
      });
      if (result) {
        await loadAIData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSwitchAIPrimary = async (id, activeValue) => {
    try {
      const result = await operatorAxios({
        url: `ai/` + id,
        method: 'PUT',
        data: {
          primary: !activeValue,
        },
      });
      if (result) {
        await loadAIData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useControllerCallback(loadAIData);

  return (
    <Box sx ={{ display: 'flex', flexDirection: 'column', gap: 2, width: 'fit-content' }}>
      {loading ? ( 
        // Loading
        <Box sx={{ marginLeft: 14 }}>
          <CircularProgress disableShrink />
        </Box>
      ) : (
        <>
          {aiOperatorData?.status && (
            <AIStatusPicker aiOperatorStatus={aiOperatorData.status} aiOperatorId={aiOperatorData._id} />
          )}
          {/* <AIRolesEntry operatorId={AIData.operatorId} initTaskTriggers={AIData.roles || ''}></AIRolesEntry> */}
          <EditableFieldCell
            label={t('domainUrl')}
            reloadFunction={loadAIData}
            url={'ai/' + AIData.operatorId}
            attribute={'domainUrl'}
            currVal={AIData.domainUrl || ''}
          />
          <EditableFieldCell
            label={t('firstNotification')}
            reloadFunction={loadAIData}
            url={'ai/' + AIData.operatorId}
            attribute={'firstReminder'}
            currVal={AIData.firstReminder || ''}
            unit={'(ms)'}
          />
          <EditableFieldCell
            label={t('secondNotification')}
            reloadFunction={loadAIData}
            url={'ai/' + AIData.operatorId}
            attribute={'secondReminder'}
            currVal={AIData.secondReminder || ''}
            unit={'(ms)'}
          />
          <EditableFieldCell
            label={t('sessionTimeout')}
            reloadFunction={loadAIData}
            url={'ai/' + AIData.operatorId}
            attribute={'sessionTimeout'}
            currVal={AIData.sessionTimeout || ''}
            unit={'(ms)'}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
              <Typography>{t('primaryTranslation')}</Typography>
              <Switch
                checked={AIData.primary === undefined ? false : AIData.primary}
                onChange={() => handleSwitchAIPrimary(AIData.operatorId, AIData.primary)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
              <Typography>{t('autoPilot')}</Typography>
              <Switch
                checked={AIData.autopilot === undefined ? false : AIData.autopilot}
                onChange={() => handleSwitchAIAutoPilot(AIData.operatorId, AIData.autopilot)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
              <Typography>{t('tInference')}</Typography>
              <Switch
                checked={AIData.active === undefined ? false : AIData.inference}
                onChange={() => handleSwitchAIInference(AIData.operatorId, AIData.inference)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
              <Typography>{t('tExtraction')}</Typography>
              <Switch
                checked={AIData.active === undefined ? false : AIData.extraction}
                onChange={() => handleSwitchAIExtraction(AIData.operatorId, AIData.extraction)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
              <Typography>{t('activeTranslation')}</Typography>
              <Switch
                checked={AIData.active === undefined ? false : AIData.active}
                onChange={() => handleSwitchAIActive(AIData.operatorId, AIData.active)}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AISettings;
