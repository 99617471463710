/**
 * @file SelectAllBorrowerButton.tsx
 * @description This button is rendered left of input when the morph type is
 * MorphType.BorrowerSelector. Clicking this button allows for the user to
 * select all borrowers.
 */

import React, { useCallback } from 'react';
import { useMorphContext } from '@contexts/MorphContext';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SelectAllBorrowersButton = ({ sendButtonRef }) => {
  const { setSelectedBorrowers, borrowerList } = useMorphContext();
  const { t } = useTranslation();

  const handleSelectAll = useCallback(() => {
    const borrowerListSet = new Set(borrowerList);
    setSelectedBorrowers(borrowerListSet);
    sendButtonRef.current.update('1');
  }, [borrowerList, sendButtonRef, setSelectedBorrowers]);

  return (
    <Button variant="outlined" onClick={handleSelectAll}>
      {t('selectAll')}
    </Button>
  );
};

export default SelectAllBorrowersButton;
