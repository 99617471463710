/* TODO  guillermo 5/31/2023 I do not find this component used, shall I delete it? */
import React, { FC } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import DialogHeader from './DialogHeader';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';


const DeleteDialog: FC<DialogPropsWithChildren> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('deleteConfirmationTitle')} onClose={() => props.onClose()} />
      <DialogContent>
        <Typography>{t('deleteConfirmationMessage')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose()}>
          {t('cancelButton')}
        </Button>
        <Button variant="contained" color="error" onClick={() => props.onDelete(props.id)}>
          {t('deleteButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
