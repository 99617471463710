/**
 * @file MorphLoanProductPriceTable.tsx
 * @author Eric Velepucha
 */

import React from 'react';
import { useMorphContext } from '@contexts/MorphContext';
import Styles from '@styles/ChooseLoanProduct/MorphLoanProductPriceTable.module.css';
import { useTranslation } from 'react-i18next';

const MorphLoanProductPriceTable = () => {
  const { hoveredLoanProduct, selectedPrices, setSelectedPrices } = useMorphContext();
  const { t } = useTranslation();
  const priceMatrix = hoveredLoanProduct?.currentPriceMatrixFinal;
  const lockPeriods = Object.keys(priceMatrix);
  const firstLoanProduct = Object.values(priceMatrix)[0];
  const rates = Object.keys(firstLoanProduct);

  const handleClickCell = (lockPeriod, rate, price) => {
    const productId = hoveredLoanProduct.id;
    // unselect if same cell is selected
    if (
      selectedPrices[productId] &&
      selectedPrices[productId].lockPeriod === lockPeriod &&
      selectedPrices[productId].rate === rate &&
      selectedPrices[productId].price === price
    ) {
      setSelectedPrices((prev) => {
        const updated = {
          ...prev,
        };
        delete updated[productId];
        return updated;
      });
      return;
    }

    const selected = {
      lockPeriod: lockPeriod,
      rate: rate,
      price: price,
    };
    setSelectedPrices((prev) => {
      const updated = {
        ...prev,
        [productId]: { ...selected },
      };
      return updated;
    });
  };

  const isCellSelected = (lockPeriod: string, rate: string, price: number) => {
    const isSelected =
      selectedPrices[hoveredLoanProduct.id] &&
      String(selectedPrices[hoveredLoanProduct.id].lockPeriod) === lockPeriod &&
      String(selectedPrices[hoveredLoanProduct.id].rate) === rate &&
      selectedPrices[hoveredLoanProduct.id].price === price;
    return isSelected;
  };

  return (
    <div className={Styles.priceTableContainer}>
      <div className={Styles.leftContent}>
        <p className={Styles.top}>{t('rate')}</p>
        <p className={Styles.bot}>{t('lockPeriod')}</p>
      </div>
      <div className={Styles.tableContainer}>
        <table>
          <thead>
            <tr>
              <th></th>
              {rates.map((rate, index) => (
                <th key={index}>{rate}%</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {lockPeriods.map((lockPeriod, lockPeriodIndex) => (
              <tr key={lockPeriodIndex}>
                <td>{lockPeriod}</td>
                {Object.entries(priceMatrix[lockPeriod]).map(([rate, price]: [string, number], priceIndex) => (
                  <td
                    key={priceIndex}
                    onClick={() => handleClickCell(lockPeriod, rate, price)}
                    className={isCellSelected(lockPeriod, rate, price) ? Styles.selectedCell : ''}
                  >
                    {price}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MorphLoanProductPriceTable;
