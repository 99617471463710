import { Box, Stack, Typography } from '@mui/material';
import { GroupIcon, SingleIcon } from '@assets/Icons';

import { styled } from '@mui/material/styles';
import React, { ReactElement, JSXElementConstructor, ReactFragment, ReactPortal } from 'react';

/*
 *  This component is the multicolored numerical readouts seen underneath the
 * welcome text in the console dashboard. For more see the Figma:
 * https://www.figma.com/file/5HrQkz2QKGTb97ZvTsqfOC/Dashbord?node-id=613%3A71
 *
 *  This component uses the following properties:
 *    - bg: this is the background color of the badge
 *    - number: this is the number displayed in large text on the right of the
 *      component.
 *    - label: this is the label underneath the large number readout.
 *    - group: this property is used when you want to have the GroupIcon.
 *    - single: this property is used when you want to have the SingleIcon
 *
 */

const ColoredCard = styled(Box)(() => ({
  padding: '2px 16px',
  borderRadius: '0.8em',
  height: 100,
  display: 'flex',
  alignItems: 'center',
}));

const NumberBadge = (props: { bg: any; number: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal; label: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal; group: any; single: any; }) => {
  return (
    <ColoredCard sx={{ backgroundColor: props.bg, width: '100%', position: "relative" }}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Stack>
          <Typography
            variant="h2"
            sx={{ textAlign: 'left', color: 'var(--gray000)', fontSize: 26, fontWeight: 600 }}>
            {props.number}
          </Typography>
          <Typography
            sx={{ textAlign: 'left', color: 'var(--gray000)', whiteSpace: 'nowrap', fontSize: 14 }}>
            {props.label}
          </Typography>
        </Stack>
        <Stack sx={{ position: 'absolute', top: '50%', right: '20px', transform: "translateY(-50%)", margin: '0 !important' }}>
          {props.group && <GroupIcon color='var(--gray000)' />}
          {props.single && <SingleIcon color='var(--gray000)' />}
        </Stack>
      </Stack>
    </ColoredCard>
  );
};

export default NumberBadge;
