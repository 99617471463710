// @ts-check

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";

import DialogHeader from "./DialogHeader";
import { PhoneNumberPatternField } from "./FormattedTextField";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from 'lodash';

const emailValidationRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneValidationRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

/**
 * @param {import('../../jsdocs').GenericDialogProps} props
 */
const GenericDialog = (props) => {
  const { t } = useTranslation();

  const [valid, setValid] = useState(false);

  const inputs = useRef(new Map());

  function addRef(key, ref){
    inputs.current.set(key, ref);
  }

  /**
   * @param {import('../../jsdocs').ItemData | null} item
   */
  function buildFields(item) {
    if (!item) {
      return [];
    }
    let list = [];
    for (let field of item.fields) {
      let infer = !field.type || field.type === "infer";
      let type = infer ? typeof field.value : field.type;
      if (field.hidden === true) { continue; }
      switch (type) {
        case "string":
          list.push(
            <TextField
              key={item.id + "-" + field.key}
              inputRef={(ref) => addRef(item.id + "-" + field.key, ref)}
              required={field.required === true}
              disabled={field.disabled === true}
              sx={{ marginTop: "12px" }}
              size="small"
              color="info"
              variant="outlined"
              label={field.key}
              defaultValue={field.value}
              onChange={(e) => {
                //field.value = e.target.value;
                checkIfValid();
              }}
              InputLabelProps={{ shrink: true }}
            />
          );

          break;
        case "number":
          list.push(
            <TextField
              key={item.id + "-" + field.key}
              inputRef={(ref) => addRef(item.id + "-" + field.key, ref)}
              required={field.required === true}
              disabled={field.disabled === true}
              sx={{ marginTop: "12px" }}
              size="small"
              color="info"
              variant="outlined"
              type="number"
              label={field.key}
              defaultValue={field.value}
              onChange={(e) => {
                //field.value = e.target.value;
                checkIfValid();
              }}
              InputLabelProps={{ shrink: true }}
            />
          );

          break;
        case "boolean":
          list.push(
            <FormControlLabel
              key={item.id + "-" + field.key}
              sx={{ marginLeft: "0" }}
              disabled={field.disabled === true}
              label={field.key}
              labelPlacement="end"
              control={
                <Checkbox
                  color="info"
                  inputRef={(ref) => addRef(item.id + "-" + field.key, ref)}
                  required={field.required === true}
                  defaultChecked={field.value + "" === "true"}
                  onClick={(e) => {
                    //field.value = /** @type {HTMLInputElement} */ (e.target).checked;
                    checkIfValid();
                  }}
                />
              }
            />
          );

          break;
        case "phone":
          list.push(
            <PhoneNumberPatternField
              key={item.id + "-" + field.key}
              inputRef={(ref) => addRef(item.id + "-" + field.key, ref)}
              required={field.required === true}
              disabled={field.disabled === true}
              label={field.key}
              defaultValue={(field.value+'').replace(/\D/g,'')}
              onChange={(e) => {
                //field.value = e.target.value;
                checkIfValid();
              }}
            />
          );

          break;
        case "email":
          // No existing field component for email, so just use 'string' instead
          list.push(
            <TextField
              key={item.id + "-" + field.key}
              inputRef={(ref) => addRef(item.id + "-" + field.key, ref)}
              required={field.required === true}
              disabled={field.disabled === true}
              sx={{ marginTop: "12px" }}
              size="small"
              color="info"
              variant="outlined"
              label={field.key}
              defaultValue={field.value}
              onChange={(e) => {
                //field.value = e.target.value;
                checkIfValid();
              }}
              InputLabelProps={{ shrink: true }}
            />
          );

          break;
        default:
          break;
      }
    }

    return list;
  }

  function checkIfValid() {

    for (let field of props.item?.fields) {
      if (field.hidden === true) { continue; }
      let value = inputs.current.get(props.item.id + "-" + field.key)?.value;
      if(value === undefined){
        console.info(`Input field for "${props.item.id + "-" + field.key}" does not exist!1`, field)
        setValid(false);
        return false;
      }

      if(value.length === 0){
        if(field.required === true){
          setValid(false);
          return false;
        } else {
          continue;
        }
      }

      let infer = !field.type || field.type === "infer";
      let type = infer ? typeof field.value : field.type;

      if (type === "email") {
        if (!value.toLowerCase().match(emailValidationRegex)) {
          setValid(false);
          return false;
        }
      }

      if (type === "phone") {
        if (!value.toLowerCase().match(phoneValidationRegex)) {
          setValid(false);
          return false;
        }
      }

      if (type === "number") {
        if (!value.toLowerCase().match(/^[0-9]*$/)) {
          setValid(false);
          return false;
        }
      }

    }
    setValid(true);
    return true;
  }

  function onComplete(){
    if(!props.item) { return; }

    let item = _.cloneDeep(props.item);
    for (let field of item.fields) {
      if (field.hidden === true) { continue; }

      let infer = !field.type || field.type === "infer";
      let type = infer ? typeof field.value : field.type;

      let inputRef = inputs.current.get(item.id + "-" + field.key);
      let value = inputRef?.value;
      if(type === 'boolean') {
        value = inputRef?.checked;
      }

      if(value === undefined){
        console.info(`Input field for "${item.id + "-" + field.key}" does not exist!2`, field)
        return;
      }

      if (type === "phone") {
        value = value.replace(/\D/g,'');
      }

      field.value = value;
    }

    setValid(false);
    props.onSubmit(item);
  }

  function onClose(){
    props.onClose();
    setValid(false);
  }

  return (
    <Dialog maxWidth="md" open={props.isOpen} onClose={() => onClose()}>
      <DialogHeader title={props.title} onClose={() => onClose()} />
      <DialogContent>
        <Stack width="400px">{buildFields(props.item)}</Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={() => onClose()}>
          {t("cancelButton")}
        </Button>
        <Button disabled={!valid} variant="contained" color="info" onClick={() => onComplete()}>
          {t("okButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericDialog;
