import React from 'react';
import { useTranslation } from 'react-i18next';

const MorphMessageOptionsTab = () => {
  const { t } = useTranslation();

  return (
    <>{t('chooseYourOption')}</>
  );
};

export default MorphMessageOptionsTab;
