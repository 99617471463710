import React from 'react';
import axios from 'axios';
import { KR as FlagIconKR3x2 } from 'country-flag-icons/react/3x2';
import { PR as FlagIconPR3x2 } from 'country-flag-icons/react/3x2';
import { US as FlagIconUS3x2 } from 'country-flag-icons/react/3x2';
import { VN as FlagIconVN3x2 } from 'country-flag-icons/react/3x2';
import { FR as FlagIconFR3x2 } from 'country-flag-icons/react/3x2';
import { SA as FlagIconSA3x2 } from 'country-flag-icons/react/3x2';
import { DE as FlagIconDE3x2 } from 'country-flag-icons/react/3x2';
import { RU as FlagIconRU3x2 } from 'country-flag-icons/react/3x2';
import { PH as FlagIconPH3x2 } from 'country-flag-icons/react/3x2';
import { CN as FlagIconCN3x2 } from 'country-flag-icons/react/3x2';
import { PT as FlagIconPT3x2 } from 'country-flag-icons/react/3x2';
import { HT as FlagIconHT3x2 } from 'country-flag-icons/react/3x2';
import { PL as FlagIconPL3x2 } from 'country-flag-icons/react/3x2';
import { IN as FlagIconIN3x2 } from 'country-flag-icons/react/3x2';
import { JP as FlagIconJP3x2 } from 'country-flag-icons/react/3x2';

import {
  getPreselectedLanguage,
  setCountryCode,
  setStateISO,
  setZipCode,
  setTimezone,
  setUserLocation,
  getCountryCode,
  getStateISO,
  getZipCode,
  getTimezone,
  getUserLocation,
} from '../Storage/userStorage';

const flagIcons = {
  en: <FlagIconUS3x2 />,
  es: <FlagIconPR3x2 />,
  ko: <FlagIconKR3x2 />,
  vi: <FlagIconVN3x2 />,
  fr: <FlagIconFR3x2 />,
  ar: <FlagIconSA3x2 />,
  de: <FlagIconDE3x2 />,
  ru: <FlagIconRU3x2 />,
  tl: <FlagIconPH3x2 />,
  zh: <FlagIconCN3x2 />,
  pt: <FlagIconPT3x2 />,
  ht: <FlagIconHT3x2 />,
  pl: <FlagIconPL3x2 />,
  hi: <FlagIconIN3x2 />,
  ja: <FlagIconJP3x2 />,
  // Add more as needed
};

const map = [
  { countryCode: 'US', lang: 'en', nativeName: 'English', flag: <FlagIconUS3x2 /> },
  { countryCode: 'PR', lang: 'es', nativeName: 'Español', flag: <FlagIconPR3x2 /> },
  { countryCode: 'KR', lang: 'ko', nativeName: '한국인', flag: <FlagIconKR3x2 /> },
  { countryCode: 'VN', lang: 'vi', nativeName: 'Tiếng Việt', flag: <FlagIconVN3x2 /> },
  { countryCode: 'FR', lang: 'fr', nativeName: 'Français', flag: <FlagIconFR3x2 /> },
  { countryCode: 'SA', lang: 'ar', nativeName: 'العربية', flag: <FlagIconSA3x2 /> },
  { countryCode: 'DE', lang: 'de', nativeName: 'Deutsch', flag: <FlagIconDE3x2 /> },
  { countryCode: 'RU', lang: 'ru', nativeName: 'русский', flag: <FlagIconRU3x2 /> },
  { countryCode: 'PH', lang: 'tl', nativeName: 'Tagalog', flag: <FlagIconPH3x2 /> },
  { countryCode: 'CN', lang: 'zh', nativeName: '中文', flag: <FlagIconCN3x2 /> },
  { countryCode: 'PT', lang: 'pt', nativeName: 'Português', flag: <FlagIconPT3x2 /> },
  { countryCode: 'HT', lang: 'ht', nativeName: 'Kreyòl Ayisyen', flag: <FlagIconHT3x2 /> },
  { countryCode: 'PL', lang: 'pl', nativeName: 'Polski', flag: <FlagIconPL3x2 /> },
  { countryCode: 'IN', lang: 'hi', nativeName: 'हिन्दी', flag: <FlagIconIN3x2 /> },
  { countryCode: 'JP', lang: 'ja', nativeName: '日本語', flag: <FlagIconJP3x2 /> },
  // { countryCode: 'XX', lang: 'yi', nativeName: 'ייִדיש', flag: <FlagIconXX3x2 /> }, // Yiddish does not have a country code
];

const SetLanguageFromGeolocation = async () => {
  try {
    const storedLanguage = getPreselectedLanguage();

    if (storedLanguage) {
      for (let i = 0; i < map.length; i++) {
        if (map[i].lang === storedLanguage) {
          // if stored language found in cache ---> change language
          return i;
        }
      }
    }

    // if stored language is not found in cache, fetch it from Morgan-Core
    const res = await axios.post(`${process.env.REACT_APP_MORGAN_CORE}/location/paid`);

    const data = res?.data;

    // make sure response data is defined
    if (data === undefined || data === null) {
      throw new Error('somehow response data undefined!');
    }

    // get fields from resonse data
    const countryCode = data.countryCode || '';
    const stateISO = data.stateISO || '';
    const zipCode = data.zipCode || '';
    const timezone = data.timezone || '';
    const userLocation = data.userLocation || '';

    // store these information in local cache
    setCountryCode(countryCode);
    setStateISO(stateISO);
    setZipCode(zipCode);
    setTimezone(timezone);
    setUserLocation(userLocation);

    // search country in map array and find index for it
    for (let i = 0; i < map.length; i++) {
      if (map[i].countryCode === countryCode) {
        return i;
      }
    }
  } catch (err) {
    console.error("SetLanguageFromGeolocation endpoint location/paid API doesn't work " + err.message);
  }

  // default language english returned
  return 0;
};

/**
 * This function checks location information existence in local storage, if all 5 information exists,
 * it returns all of those info. If it doesn't exist then MaxMind end point fetched and information is stored
 * @returns location information as a object
 */
const CheckLocationInformationExistenceInLocalStorage = async () => {
  let countryCode;
  let stateISO;
  let zipCode;
  let timezone;
  let userLocation;

  try {
    // fetch data from local storage
    countryCode = getCountryCode();
    stateISO = getStateISO();
    zipCode = getZipCode();
    timezone = getTimezone();
    userLocation = getUserLocation();

    // if all parameters are defined return it immediately
    if (countryCode !== null && stateISO !== null && timezone !== null && userLocation !== null && zipCode !== null) {
      console.log("location info found in local cache");
      return {
        countryCode,
        stateISO,
        zipCode,
        timezone,
        userLocation,
      };
    }

    // get location info fron backend
    const res = await axios.post(`${process.env.REACT_APP_MORGAN_CORE}/location/paid`);

    const data = res?.data;

    // make sure response data is defined
    if (data === undefined || data === null) {
      throw new Error('somehow response data undefined!');
    }

    // get fields from response data
    countryCode = data.countryCode || '';
    stateISO = data.stateISO || '';
    zipCode = data.zipCode || '';
    timezone = data.timezone || '';
    userLocation = data.userLocation || '';

    // store these information in local cache
    setCountryCode(countryCode);
    setStateISO(stateISO);
    setZipCode(zipCode);
    setTimezone(timezone);
    setUserLocation(userLocation);
  } catch (err) {
    console.error(
      "CheckLocationInformationExistenceInLocalStorage endpoint location/paid API doesn't work " + err.message
    );

    countryCode = countryCode || '';
    stateISO = stateISO || '';
    zipCode = zipCode || '';
    timezone = timezone || '';
    userLocation = userLocation || '';
  }

  return {
    countryCode,
    stateISO,
    zipCode,
    timezone,
    userLocation,
  };
};
export { SetLanguageFromGeolocation, map, flagIcons, CheckLocationInformationExistenceInLocalStorage };
