import { Stack } from '@mui/system';
import React, { useEffect } from 'react';
import { PlayButton } from '@assets/Icons';
import styles from '@styles/SongOfTheDay.module.css';
import { MorphType } from '@common/MorphType';

import { Cancel, Forward10, Pause, Replay10, VolumeOff, VolumeUp } from '@mui/icons-material';
import { useErrandContext } from '@contexts/ErrandContext';
const SongOfTheDayControls = process.env.REACT_APP_MORGAN_CDN + '/Images/SongOfTheDayControls.svg';

const MorphSongOfTheDay = () => {
  const errandContext = useErrandContext();

  // Reset player/song variables, and close song player
  const stopAndCloseVideo = () => {
    errandContext.setSongPlayerData({
      id: '',
      artist: '',
      title: '',
      thumbnail: '',
      url: '',
      pause: false,
      mute: false,
      show: false,
      closing: true,
    });
    errandContext.setMorphType((prev) => {
      if (prev === MorphType.SongOfTheDay) {
        // if current MorphType is SongOfTheDay then close it
        return MorphType.None;
      } else {
        // if above 2 condition doesn't match no changes needed
        return prev;
      }
    });
  };

  // Function for skipping ahead 10 seconds on song of the day player
  const goForward = () => {
    const player = errandContext.playerRef.current;
    if (typeof player?.seekTo !== 'function') return;
    if (typeof player?.getCurrentTime !== 'function') return;
    player?.seekTo(player?.getCurrentTime() + 10)
  }
  // Function for rewinding 10 seconds on song of the day player
  const goBack = () => {
    const player = errandContext.playerRef.current;
    if (typeof player?.seekTo !== 'function') return;
    if (typeof player?.getCurrentTime !== 'function') return;
    player?.seekTo(player?.getCurrentTime() - 10);
  }

  useEffect(() => {
    return () => {
      errandContext.setSongPlayerData((prev) => {
        return { ...prev, pause: false, mute: false };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack className={styles.controlContainer}>
      <Stack className={styles.playerControlsWrapper} style={{backgroundImage: `url(${SongOfTheDayControls})`}}>
        <Stack className={styles.leftControls}>
          {errandContext.songPlayerData?.mute ? 
          <VolumeUp style={{ fill: 'var(--gray000)', height: '20px' }} onClick={() => errandContext.setSongPlayerData((prev) => { return { ...prev, mute: false } })} />
          :
          <VolumeOff style={{ fill: 'var(--gray000)', height: '20px' }} onClick={() => errandContext.setSongPlayerData((prev) => { return { ...prev, mute: true } })} />
          }
          <Replay10 style={{ height: '15px' }} onClick={goBack} />
        </Stack>
        <Stack className={styles.centerControls}>
        {errandContext.songPlayerData?.pause ?
          <PlayButton className={styles.playButton} style={{ fontSize: '30px' }}  onClick={() => errandContext.setSongPlayerData((prev) => { return { ...prev, pause: false } })} />
          :
          <Pause style={{ fontSize: '40px' }} onClick={() => errandContext.setSongPlayerData((prev) => { return { ...prev, pause: true } })} />
          }
        </Stack>
        <Stack className={styles.rightControls}>
          <Forward10 style={{ height: '15px' }} onClick={goForward}/>
          <Cancel style={{ height: '20px' }} onClick={stopAndCloseVideo} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MorphSongOfTheDay;