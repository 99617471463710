import { Dialog, DialogContent } from '@mui/material';

import DialogHeader from './DialogHeader';
import React, { useEffect } from 'react';

const IFrameDialog = (props: { src: string; open: boolean; onClose: () => void; }) => {

  useEffect(() => {
    console.log('DEBUG iframe props.src', props.src);
  }, [props.src]);

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false}>
      <DialogHeader title="See My Loan Status" onClose={() => props.onClose()} />
      <DialogContent sx={{ height: '90vh', overflow: 'hidden' }}>
        <iframe src={props.src} style={{ width: '90vw', height: '100%' }} title="IFrameDialog">
        </iframe>
      </DialogContent>
    </Dialog>
  );
}

export default IFrameDialog;
