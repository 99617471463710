import React from 'react';
import { useQuery } from '@tanstack/react-query';

import axiosCall from '@services/axios';
import styles from '@styles/MessageContent/PropertyListingMessageContent.module.css'
import Sanitized from '@components/Sanitized';
import { IMessage, IErrand } from '@interfaces/Conversation';
import { ChatType } from '@common/ChatType';
import { useTranslation } from 'react-i18next';
import TextMessageContent from './TextMessageContent';
import { useRootContext } from '@contexts/RootContext';
const DocumentPreview = process.env.REACT_APP_MORGAN_CDN + '/Images/DOC.png';

const PropertyListingPhotos = (props) => {
  const response = useQuery([props.thumbnail, props.message?.updatedAt], async ({ signal }) => {
    let request = {
      url: `chat/${props.message?.chatId}/document/${props.thumbnail}`,
    };
    let config = {
      signal,
    };
    return await axiosCall(request, config);
  });

  return (
    <div>
      <img
        src={response?.data?.mimeType?.split('/')[0] === 'image'
          ? `data:${response?.data?.mimeType};base64,${response?.data?.data}`
          : DocumentPreview}
      />
    </div>
  );
};

type TPropertyListingMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const PropertyListingMessageContent = ({
  errand, message, 
}: TPropertyListingMessageContentProps) => {
  const { t } = useTranslation();
  const rootContext = useRootContext();

  const handleCreateErrand = async () => {
    const payload = {
      url: 'chat',
      method: 'post',
      data: {
        displayName: `${message.message.split('<>')[0]}<>${message.url}`,
        type: ChatType.Page,
        status: 'waiting-updates',
        position: rootContext.getNewChatPosition(),
        participants: [
          {
            userId: message.userId,
            userType: 'User',
            primary: true,
            messageHistoryAllowed: true,
          },
        ],
      },
    };

    try {
      const response: IErrand = await axiosCall(payload);

    } catch (err) {
      console.error(err);
    }
  }

  const handleClick = async (
    e: any,
  ) => {
    e.preventDefault();

    rootContext.setErrands((prev) => {
      //if new url it will open a new page!
      let index = prev.findIndex((e) => e.displayName === `${message.message.split('<>')[0]}<>${message.url}`);
      if (index === -1) {
        handleCreateErrand();
      } else {
        rootContext.setSelectedIndex((prevState) => {
          if (window.innerWidth < 900) {
            return [index];
          }
          if (prevState.length === 1) {
            return [index]
          } else {
            return [prevState[0], index];
          }
        })
      }
      return prev;
    });
  };

  return (
    <div className={[styles.wrapper, ...(message?.alignByCurrentUser ? [styles.alignByCurrentUser] : [])].join(' ')}>
      <TextMessageContent errand={errand} message={{...message, message: t('propertyInMyDatabase')}} />
      <div className={styles.dottedLines} />
      <button onClick={handleClick}>
        <div>
          <div className={styles.img}>
            <PropertyListingPhotos thumbnail={message.documents?.[0]} message={message}></PropertyListingPhotos>
          </div>
          <div className={styles.meta}>
            <div className={styles.price}><Sanitized html={!message.message.split('<>')[1] ? t('callNow') : message.message.split('<>')[1]}></Sanitized></div>
            <div className={styles.content}>
              <h3>{message.message.split('<>')[0]}</h3>
              <div className={styles.imgs}>
                <>
                  {message?.documents?.slice(1, 5)?.map(
                    (document, index) =>
                      <PropertyListingPhotos thumbnail={document} message={message}></PropertyListingPhotos>
                  )}
                </>
              </div>
              <p>
                {t('clickThisListing')}</p>
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};

export default PropertyListingMessageContent;