import eventBus from '@common/eventBus';
import { IActivityID } from '../interfaces';
import axiosCall from '@services/axios';
import { getChatId, getUserId } from '@storage/userStorage';
import { ValidatorFunctions } from '@common/Validators';

export const tapHandlers: Record<IActivityID, ((...args: any[]) => any) | null> = {
  Consent: () => {
    eventBus.dispatch('showConsentContent');
  },
  Auth: null,
  Context: null,
  Action: null,
  PrivateMode: null,
  EditingMode: null,
  Order: null,
  SlotMachine: null,
  Live: null,
  PropertyListing: null,
  Esign: null,
  ExpandFullMode: null,
};
