import React, { useState, useRef, useEffect, useCallback } from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import Styles from '@styles/MorphVideoListMenu.module.css';

import { ArrowsProps, ArrowDirection } from './types';
const UserPromptsMenuArrowStatic = process.env.REACT_APP_MORGAN_CDN + '/Images/UserPromptsMenuArrowStatic.png';


// filter cb fn
const notNull = (el) => el !== null;

enum AnimationState {
  Start = 'Start',
  End = 'End',
}

type TAnimationState = (typeof AnimationState)[keyof typeof AnimationState];

const getVisibleArrowStyle = (side) => {
  return {
    visibility: 'visible',
    opacity: '1',
    transform: `translateX(0px) scale(1) rotate(${side === 'left' ? '90deg' : '-90deg'})`,
  };
};

const getSecondArrowStyles = (side) => {
  return {
    start: {
      visibility: 'hidden',
      opacity: '0',
      transform: `translateX(${side === 'left' ? '8px' : '-8px'}) scale(0) rotate(${
        side === 'left' ? '90deg' : '-90deg'
      })`,
    },
    end: getVisibleArrowStyle(side),
  };
};

const getFirstArrowStyles = (side) => {
  return {
    start: getVisibleArrowStyle(side),
    end: {
      visibility: 'hidden',
      opacity: '0',
      transform: `translateX(${side === 'left' ? '-10px' : '10px'}) scale(0) rotate(${
        side === 'left' ? '90deg' : '-90deg'
      })`,
    },
  };
};

const StaticClassNames = {
  DefaultArrow: {
    right: [Styles.arrowImg, Styles.arrowImgRight, Styles.posAbs].join(' '),
    left: [Styles.arrowImg, Styles.arrowImgLeft, Styles.posAbs].join(' '),
  },
  InvisibleArrow: {
    right: [Styles.arrowImg, Styles.arrowImgRight, Styles.invisibleArrow].join(' '),
    left: [Styles.arrowImg, Styles.arrowImgLeft, Styles.invisibleArrow].join(' '),
  },
};

const InvisibleArrow = (props) => {
  return (
    <img
      className={StaticClassNames.InvisibleArrow[props.side]}
      src={UserPromptsMenuArrowStatic}
      alt={`User prompts menu navigation ${props.side} Arrow`}
    />
  );
};

const StaticArrow = (props) => {
  return (
    <img
      className={StaticClassNames.DefaultArrow[props.side]}
      src={UserPromptsMenuArrowStatic}
      alt={`User prompts menu navigation ${props.side} Arrow`}
    />
  );
};

const AnimatedArrowImg = (props) => {
  return (
    <img
      className={`${StaticClassNames.DefaultArrow[props.side]}${props.isSomeVideoSelected ? Styles.hidden : ''}`}
      src={UserPromptsMenuArrowStatic}
      alt={`User prompts menu navigation animated ${props.side} Arrow`}
      style={props.style}
    />
  );
};

const getCurrAnimStyleOf = (arrowStyles, animationState) => {
  return animationState === AnimationState.Start ? arrowStyles.start : arrowStyles.end;
};

const allArrowStyles = {
  first: {
    left: getFirstArrowStyles('left'),
    right: getFirstArrowStyles('right'),
  },
  second: {
    left: getSecondArrowStyles('left'),
    right: getSecondArrowStyles('right'),
  },
};

const AnimatedArrow = (props) => {
  const [animationState, setAnimationState] = useState<TAnimationState>(AnimationState.Start);

  const tmEndIdref = useRef(null);
  const tmStartIdref = useRef(null);

  const isAnimationPlaying = useRef(false);
  const getAnimationPaying = () => isAnimationPlaying.current;
  const setAnimationPlayingRef = (b: boolean) => (isAnimationPlaying.current = b);

  const firstArrowStyles = allArrowStyles.first[props.side];
  const secondArrowStyles = allArrowStyles.second[props.side];

  const doPlayAnimation = () => {
    if (getAnimationPaying() === true) return;

    setAnimationPlayingRef(true);
    // play actual
    setAnimationState(AnimationState.End);
    // set as ended in 500
    tmEndIdref.current = setTimeout(() => {
      setAnimationPlayingRef(false);
      props.onAnimationEnd();
      props.animationFinishedRef.current = true;
    }, 550);
  };

  useEffect(() => {
    // mount
    // queue
    tmStartIdref.current = setTimeout(() => {
      doPlayAnimation();
    }, 50);

    return () => {
      clearTimeout(tmEndIdref.current);
      clearTimeout(tmStartIdref.current);
    };
  }, []);

  const currStyles = {
    first: getCurrAnimStyleOf(firstArrowStyles, animationState),
    second: getCurrAnimStyleOf(secondArrowStyles, animationState),
  };

  return (
    <>
      <AnimatedArrowImg style={{ ...currStyles.first }} side={props.side} />
      <AnimatedArrowImg style={{ ...currStyles.second,  }} side={props.side} />
    </>
  );
};

const DynamicArrow = (props) => {
  return props.renderAnimatedArrow === true ? (
    <AnimatedArrow
      onAnimationEnd={props.onAnimationEnd}
      side={props.side}
      animationFinishedRef={props.animationFinishedRef}
      isSomeVideoSelected={props.isSomeVideoSelected}
    />
  ) : (
    <StaticArrow side={props.side} />
  );
};

const Arrow = (props) => {
  const [renderAnimatedArrow, setRenderAnimatedArrow] = useState(false);
  const animationFinishedRef = useRef(true);
  const htmlRef = useRef(null);
  const setAnimationFinishedRef = (val) => (animationFinishedRef.current = val);

  const onAnimationEnd = () => {
    setRenderAnimatedArrow((_) => false);
  };

  const classArr = [
    props.isSomeVideoSelected ? Styles.hidden : null,
    props.side === 'left' ? Styles.leftArrow : Styles.rightArrow,
    Styles.arrow,
  ].filter(notNull);
  const className = classArr.join(' ');

  const playAnimation = useCallback(() => {
    if (animationFinishedRef.current === true) {
      // start animation
      setAnimationFinishedRef(false);
      setRenderAnimatedArrow((_) => true);
    }
  }, []);

  useEffect(() => {
    playAnimation();
  }, []);

  return (
    <div
      ref={htmlRef}
      className={className}
      onClick={() => {
        props.onClick();
      }}
      onMouseEnter={(e) => {
        playAnimation();
      }}
    >
      <InvisibleArrow side={props.side} />
      <DynamicArrow
        side={props.side}
        onAnimationEnd={onAnimationEnd}
        animationFinishedRef={animationFinishedRef}
        renderAnimatedArrow={renderAnimatedArrow}
        isSomeVideoSelected={props.isSomeVideoSelected}
      />
    </div>
  );
};

const ButtonUp = (props) => {
  const buttonUpClassArr = [props.isSomeVideoSelected ? Styles.shown : null, Styles.buttonUp].filter(notNull);
  const buttonUpClassName = buttonUpClassArr.join(' ');

  return (
    <div onClick={props.onClick} className={buttonUpClassName}>
      <KeyboardDoubleArrowUpIcon className={Styles.buttonUpIcon} />
    </div>
  );
};


const Arrows = (props: ArrowsProps) => {
  const isSomeVideoSelected = props.selectedIdx !== null;

  const onArrowClick = useCallback(
    (dir: ArrowDirection) => {
      if (dir === 'left') {
        props.swiperInstance?.slidePrev();
      } else {
        props.swiperInstance?.slideNext();
      }
    },
    [props.swiperInstance]
  );

  const onLeftArrowClick = useCallback(() => {
    onArrowClick('left');
  }, [onArrowClick]);

  const onRightArrowClick = useCallback(() => {
    onArrowClick('right');
  }, [onArrowClick]);

  // on load, return nothing AND if not yet mounted. (after first render)
  if (props.isLoading || !props.hasMounted) return null;

  return (
    <div className={Styles.arrowContainer}>
      <Arrow side="left" isSomeVideoSelected={isSomeVideoSelected} onClick={onLeftArrowClick} />
      <ButtonUp isSomeVideoSelected={isSomeVideoSelected} onClick={props.handleExpandMenu} />
      <Arrow side="right" isSomeVideoSelected={isSomeVideoSelected} onClick={onRightArrowClick} />
    </div>
  );
};

export { Arrows };
