import React from 'react';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined';
import Emoji from '@components/Emoji.js';
import { Sentiments, SentimentEmojiProps } from '@mTypes/TSentiment';
import styles from '../Styles/SentimentEmoji.module.css';

const SentimentEmoji = ({ sentiment = Sentiments.Unvailable, regularEmoji=false }: SentimentEmojiProps) => {
  const renderIcon = () => {
    switch (sentiment) {
      case Sentiments.Negative:
        return regularEmoji ? <Emoji symbol="🙁" label="frown" /> : <SentimentDissatisfiedOutlinedIcon />;
      case Sentiments.Positive:
        return regularEmoji ? <Emoji symbol="😃" label="smiley" /> : <SentimentVerySatisfiedOutlinedIcon />;
      default:
        return regularEmoji ? <Emoji symbol="🙂" label="slightly-smiling-face" /> : <SentimentSatisfiedOutlinedIcon />;
    }
  }

  return (
    <div className={styles.Style + ' ' + (styles[sentiment] || "")}>
      {renderIcon()}
    </div>
  );
};

export default SentimentEmoji;
