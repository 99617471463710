import { ValidatorFunctions } from '@common/Validators';
import { IMessage } from '@interfaces/Conversation';

enum MessageFetchState {
  Error = 'Error',
  Start = 'Start',
  Fetching = 'Fetching',
  HasMore = 'HasMore',
  NothingToFetch = 'NothingToFetch',
}

enum MessageFetchEvent {
  RequestRestart = 'RequestRestart',
  RequestMore = 'RequestMore',

  EventFetchCompleted = 'EventFetchCompleted',
}

enum MessageFetchAction {
  Error = 'Error',
  Idle = 'Idle',
  LoadMore = 'LoadMore',
}
enum ScrollDirectionType {
  Up = 'Up',
  Down = 'Down',
}

interface ScrollStateType {
  direction: ScrollDirectionType;
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
  lockedToBottom: boolean;
}

const ScrollDefaultState: ScrollStateType = {
  direction: ScrollDirectionType.Down,
  scrollTop: 0,
  scrollHeight: 1,
  clientHeight: 0,
  lockedToBottom: true,
};

const ResetScrollState = (data: ScrollStateType) => {
  data.direction = ScrollDefaultState.direction;
  data.scrollTop = ScrollDefaultState.scrollTop;
  data.scrollHeight = ScrollDefaultState.scrollHeight;
  data.lockedToBottom = ScrollDefaultState.lockedToBottom;
};

interface MessageFetchParamType {
  state: MessageFetchState;
  lastBatchLength: number;
  limit: number;
  messagesLoaded: Set<string>;
}

const MESSAGE_BATCH_SIZE = 15;

const MessageFetchDefaultState: MessageFetchParamType = {
  state: MessageFetchState.Start,
  lastBatchLength: 0,
  limit: MESSAGE_BATCH_SIZE,
  messagesLoaded: new Set(),
};

const ResetData = (data: MessageFetchParamType) => {
  data.state = MessageFetchDefaultState.state;
  data.lastBatchLength = MessageFetchDefaultState.lastBatchLength;
  data.limit = MessageFetchDefaultState.limit;
  data.messagesLoaded = new Set();
};

const StateHandlerError = (data: MessageFetchParamType, event: MessageFetchEvent): MessageFetchAction => {
  switch (event) {
    case MessageFetchEvent.RequestRestart:
      ResetData(data);
      return MessageFetchAction.LoadMore;
    default:
      console.error(`Error state unexpected event: |${event}|`);
      return MessageFetchAction.Error;
  }
};

const StateHandlerStart = (data: MessageFetchParamType, event: MessageFetchEvent): MessageFetchAction => {
  switch (event) {
    case MessageFetchEvent.RequestRestart:
      ResetData(data);
      return MessageFetchAction.LoadMore;
    case MessageFetchEvent.RequestMore:
      return MessageFetchAction.LoadMore;
    default:
      console.error(`Start state unexpected event: |${event}|`);
      return MessageFetchAction.Error;
  }
};

const StateHandlerFetching = (data: MessageFetchParamType, event: MessageFetchEvent): MessageFetchAction => {
  switch (event) {
    case MessageFetchEvent.RequestRestart:
      return MessageFetchAction.Idle;
    case MessageFetchEvent.RequestMore:
      return MessageFetchAction.Idle;
    case MessageFetchEvent.EventFetchCompleted:
      if (data.lastBatchLength < data.limit) {
        data.state = MessageFetchState.NothingToFetch;
      } else {
        data.state = MessageFetchState.HasMore;
      }

      return MessageFetchAction.Idle;
    default:
      console.error(`Fetching state unexpected event: |${event}|`);
      return MessageFetchAction.Error;
  }
};

const StateHandlerHasMore = (data: MessageFetchParamType, event: MessageFetchEvent): MessageFetchAction => {
  switch (event) {
    case MessageFetchEvent.RequestRestart:
      ResetData(data);
      return MessageFetchAction.LoadMore;
    case MessageFetchEvent.RequestMore:
      return MessageFetchAction.LoadMore;
    default:
      console.error(`HasMore state unexpected event: |${event}|`);
      return MessageFetchAction.Error;
  }
};

const StateHandlerNothingToFetch = (data: MessageFetchParamType, event: MessageFetchEvent): MessageFetchAction => {
  switch (event) {
    case MessageFetchEvent.RequestRestart:
      ResetData(data);
      return MessageFetchAction.LoadMore;
    case MessageFetchEvent.RequestMore:
      return MessageFetchAction.Idle;
    default:
      console.error(`NothingToFetch  state unexpected event: |${event}|`);
      return MessageFetchAction.Error;
  }
};

const logicMap: {
  [key in MessageFetchState]: (data: MessageFetchParamType, event: MessageFetchEvent) => MessageFetchAction;
} = {
  [MessageFetchState.Error]: StateHandlerError,
  [MessageFetchState.Start]: StateHandlerStart,
  [MessageFetchState.Fetching]: StateHandlerFetching,
  [MessageFetchState.HasMore]: StateHandlerHasMore,
  [MessageFetchState.NothingToFetch]: StateHandlerNothingToFetch,
};

/**
 * Here we handle all message fetch related logic. There are 2 main functionality
 * Reload: Nuke current fetched messages and restart message fetch process
 * LoadMore: load more messages
 *
 * @param data Message fetch related all parameters
 * @param event Event coming from reach component
 * @returns actino
 */
const MessageFetchStateManager = (data: MessageFetchParamType, event: MessageFetchEvent): MessageFetchAction => {
  const state = data?.state;

  if (ValidatorFunctions.isUndefinedOrNull(state)) {
    console.error(`Unexpected state: |${state}|`);
    return MessageFetchAction.Error;
  }

  const action = logicMap?.[state];

  if (ValidatorFunctions.isUndefinedOrNull(action)) {
    console.error(`Unexpected action: |${state}|`);
    return MessageFetchAction.Error;
  }

  const result = action(data, event);

  switch (result) {
    case MessageFetchAction.Error:
      data.state = MessageFetchState.Error;
      break;
    case MessageFetchAction.Idle:
      break;
    case MessageFetchAction.LoadMore:
      data.state = MessageFetchState.Fetching;
      break;
    default:
      console.error(`Unexpected result: |${result}|`);
  }

  return result;
};

/**
 * Checks if system can fetch more messages
 */
const MessageFetchHasMore = (action: MessageFetchAction): boolean => {
  if (action === MessageFetchAction.LoadMore) {
    return true;
  } else {
    return false;
  }
};

/**
 * Whenever endpoint request is finished this fn must me called in order to sync state
 */
const MessageFetchRequestCallback = (data: MessageFetchParamType, messages: Array<IMessage>): Array<IMessage> => {
  if (Array.isArray(messages) === false) {
    messages = [];
  }

  data.lastBatchLength = messages.length;
  MessageFetchStateManager(data, MessageFetchEvent.EventFetchCompleted);

  const newMessages: Array<IMessage> = [];

  for (const elm of messages) {
    if (MessageFetchAdd(data, elm)) {
      newMessages.push(elm);
    }
  }

  return newMessages;
};

/**
 * Add single message, While we insert new message we need to keep track of few parameters
 * positionMax: we will keep track of current loaded message's max position number
 *
 * @param data state manager parameters
 * @param message message that needs to be added
 * @returns
 */
const MessageFetchAdd = (data: MessageFetchParamType, message: IMessage): boolean => {
  const messageId = message?._id;

  if (typeof messageId !== 'string') {
    return false;
  }

  const alreadyExists = data.messagesLoaded.has(messageId);

  data.messagesLoaded.add(messageId);

  if (alreadyExists) {
    return false;
  }

  return true;
};

const MessageFetchDelete = (data: MessageFetchParamType, messageId: string) => {
  data.messagesLoaded.delete(messageId);
};

const MessageFetchCountLoaded = (data: MessageFetchParamType): number => {
  return data.messagesLoaded.size;
};

const MessageFetchCheckIfAlreadyLoaded = (data: MessageFetchParamType, messageId: string): boolean => {
  if (data.messagesLoaded.has(messageId)) {
    return true;
  }
  return false;
};

export {
  MessageFetchStateManager,
  MessageFetchRequestCallback,
  MessageFetchAdd,
  MessageFetchDelete,
  MessageFetchCountLoaded,
  MessageFetchHasMore,
  MessageFetchCheckIfAlreadyLoaded,
  MessageFetchParamType,
  MessageFetchDefaultState,
  MessageFetchState,
  MessageFetchAction,
  MessageFetchEvent,
  ScrollDirectionType,
  ScrollStateType,
  ScrollDefaultState,
  ResetScrollState,
};
