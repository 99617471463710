import dayjs from 'dayjs';
import writeXlsxFile from 'write-excel-file';

const downloadWorkflowReport = async (workflowData) => {
  const schema = [
    // Column #1
    {
      column: 'Name',
      type: String,
      value: (workflow) => workflow.name,
      width: 25,
    },
    {
      column: 'Description',
      type: String,
      value: (workflow) => workflow.description,
      width: 150,
    },
    {
      column: 'Steps',
      type: Number,
      value: (workflow) => workflow.steps,
      width: 25,
    },
  ];

  if (workflowData?.length > 0) {
    const objects = workflowData?.map((workflow) => {
      return {
        name: workflow?.name,
        description: workflow?.description,
        steps: workflow?.actions?.length || 0,
      };
    });
    const date = dayjs(new Date()).format('DD/MM/YYYY');
    await writeXlsxFile(objects, {
      schema,
      fileName: `Morgan Workflow Report : ${date}.xlsx`,
    });
  }
};

export default downloadWorkflowReport;
