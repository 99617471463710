import { Box, CircularProgress, Stack, MorganTheme, useTheme } from '@mui/material';

import { Airplane } from '@assets/Icons';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import MessageTextStyle from '@styles/MessageTextStyle';
import getImageSource from '@common/getImageSource';
import { useQuery } from '@tanstack/react-query';
import React, { useState, useRef } from 'react';
import axiosCall from '@services/axios';
import { ErrandMessageProps } from '@mTypes/Conversation';
import { IMessage } from '@interfaces/Conversation';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import TranslationTabLeft from '@styles/TranslationTabLeft';
import TranslationTabRight from '@styles/TranslationTabRight';
import { uiTranslationController, getLangName } from '@common/common';
import { Typography, Fade } from '@mui/material';
import { styled } from "@mui/system";
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';

type TOrderMessageContentProps = {
  message: IMessage;
}

const OrderMessageContent = ({
  message, 
}: TOrderMessageContentProps) => {
  const theme: MorganTheme = useTheme();
  const [isFading, setIsFading] = useState(true);
  const [revealEnabled, setRevealEnabled] = useState(false);
  const messageRef = useRef(null);
  const { dispMessage, isTranslated } = uiTranslationController(message);
  const { isOperator } = useUserContext();

  const OriginalLangStyle = styled(Typography)({
    //we are using a custom padding of 15% compared to the OriginalLangStyle Component
    paddingLeft: '15%',
    cursor: 'pointer',
    paddingTop: '5px',
    fontSize: '0.50em',
    font: 'Poppins',
  });

  /**
   * This function is a handler callback for the Original Language component's
   * onClick event. It is responsible for temporarily revealing the origianl
   * message.
   */
   const handleClickOriginalLang = async (e) => {
    e?.stopPropagation();
    if (!message.visible) return;
    if (revealEnabled) {
      return;
    }
    const reset = messageRef?.current?.innerHTML;

    // Start the inital fadeout:
    setRevealEnabled(true);
    setIsFading(false);

    // Wait until the fadeout is done to fade in the original message:
    setTimeout((reset) => {
      let editedMessage = message.message;
      
      messageRef.current.innerHTML = editedMessage;
      setIsFading(true);

      // Allow the revealed message to display for some time, then begin fading
      // it out:
      setTimeout((reset) => {
        setIsFading(false);

        // Finally, wait until the fadeout is done, then fade in the translated
        // message:
        setTimeout((reset) => {
          messageRef.current.innerHTML = reset;
          setIsFading(true);
          setRevealEnabled(false);
        }, 500, reset);
      }, 3000, reset);
    }, 500, reset);
  };

  const product = useQuery([message.message], async ({ signal }) => {
    let orderRequest = {
      url: `order/${message.message?.split("\"")[1]}`
    };
    let config = {
      signal,
    };
    let orderData = await axiosCall(orderRequest, config);
    let productRequest = {
      url: `product/${orderData?.productId}`
    };
    return await axiosCall(productRequest, config);
  });

  return (
    <div style={{
      position: "relative",
      width: 'fit-content',
      maxWidth: 'calc(90% - 40px)',
      marginRight: isOperator ? '55px' : 0,
      marginTop: isOperator ? '30px' : 0,
    }}
    >
      <ChatBubbleStyle
        sx={{
          minWidth: "220px",
          width: '100%',
          minHeight: 58,
          borderColor: message.accessType === AccessType.internal ? "var(--gray400)" : "var(--orange700)",
          background: message.accessType === AccessType.internal
            ? 'var(--peach020)'
            : message.sentByCurrentUser
              ? 'var(--gray000)'
              : 'var(--peach600)',
          position: "relative",
          paddingRight: '5px',
          overflow: 'visible'
        }}
      >
        <Stack flexDirection="column" width="100%">
          <Stack flexDirection="column">
            <Stack
              alignItems="center"
              sx={{
                d: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                p: "6px 7px 8px 9px",
                maxWidth: "100%",
                position: "relative",
                width: "90%",
                paddingRight: '25px'
              }}
            >
              {/* Message text area */}
              <Fade in={isFading} appear={false}>
              <MessageTextStyle ref ={messageRef}>{dispMessage?.replace(/(<([^>]+)>)/gi, '')}</MessageTextStyle>
              </Fade>
            </Stack>
          </Stack>
        </Stack>
        <Box sx={{
          position: "absolute",
          bottom: "-1px",
          right: '55px',
        }}>
          <svg
            width="105"
            height="105"
            viewBox="0 0 105 105"
            xmlns="http://www.w3.org/2000/svg"
            fillOpacity="1"
            style={{ position: "absolute", bottom: 0 }}
          >
            <path
              d="M54.872 11.8686L57.8322 11.8468C83.5504 11.6577 104.5 32.4539 104.5 58.1728C104.5 83.7586 83.7586 104.5 58.1728 104.5H11H0.5V47.4769C0.695126 47.4626 0.942891 47.4395 1.23519 47.4022C1.9938 47.3054 3.05488 47.1123 4.27557 46.7268C6.71689 45.9558 9.80804 44.4114 12.3841 41.3201C13.2846 40.2395 14.0494 39.0907 15.08 37.4788C15.2868 37.1554 15.5046 36.8128 15.7367 36.4478C16.6638 34.9898 17.8193 33.1724 19.416 30.7773C20.5371 29.0958 22.4774 26.8299 24.6962 24.4366C32.4026 16.1239 43.4936 11.9523 54.872 11.8686Z"
              fill={
                message.accessType === AccessType.internal
                  ? 'var(--peach020)'
                  : message.sentByCurrentUser
                    ? 'var(--gray000)'
                    : 'var(--peach600)'
              }
              stroke="var(--orange700)"
              fillOpacity="1"
            />
            <path
              d="M0 48L1 47.9336V104H0V48Z"
              fill={
                message.accessType === AccessType.internal
                  ? 'var(--peach020)'
                  : message.sentByCurrentUser
                    ? 'var(--gray000)'
                    : 'var(--peach600)'
              }
            />
          </svg>
          <Box
            sx={{
              width: 85,
              height: 85,
              background: product?.data !== undefined && product?.data?.picture !== '' ? `url(${getImageSource(product?.data?.picture)})` : undefined,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              borderRadius: "40px",
              border: `2px ${message.accessType === AccessType.internal ? "var(--gray400)" : "var(--orange700)"} solid`,
              position: "absolute",
              bottom: 4,
              left: 15,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {
              product?.data !== undefined ?
                product?.data?.picture === '' ?
                  <svg width="75px" height="75px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                      <g transform="translate(-325.000000, -80.000000)">
                        <g transform="translate(325.000000, 80.000000)">
                          <polygon fill="var(--gray000)" fillOpacity="0.01" fillRule="nonzero" id="路径" points="24 0 0 0 0 24 24 24" />
                          <polygon points="22 7 12 2 2 7 2 17 12 22 22 17" stroke={message.accessType === AccessType.internal ? "var(--gray400)" : "var(--orange700)"} strokeLinejoin="round" strokeWidth="1.5" />
                          <line stroke={message.accessType === AccessType.internal ? "var(--gray400)" : "var(--orange700)"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="2" x2="12" y1="7" y2="12" />
                          <line stroke={message.accessType === AccessType.internal ? "var(--gray400)" : "var(--orange700)"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="12" x2="12" y1="22" y2="12" />
                          <line stroke={message.accessType === AccessType.internal ? "var(--gray400)" : "var(--orange700)"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="22" x2="12" y1="7" y2="12" />
                          <line stroke={message.accessType === AccessType.internal ? "var(--gray400)" : "var(--orange700)"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" x1="17" x2="7" y1="4.5" y2="9.5" />
                        </g>
                      </g>
                    </g>
                  </svg>
                  :
                  undefined
                :
                <CircularProgress />
            }
            <Box sx={{ position: "absolute", bottom: -15, right: 5 }}>
              <Airplane />
            </Box>
          </Box>
        </Box>
      </ChatBubbleStyle>
      {isTranslated &&
        (message?.alignByCurrentUser ? (
          <TranslationTabRight>
            <OriginalLangStyle onClick={handleClickOriginalLang}>
              <u>{getLangName(message.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
          </TranslationTabRight>
        ) : (
          <TranslationTabLeft>
            <OriginalLangStyle onClick={handleClickOriginalLang}>
              <u>{getLangName(message.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
          </TranslationTabLeft>
        ))}
    </div>
  );
};

export default OrderMessageContent;
