import React from 'react';
import * as PullCreditMenu from '@components/PullCreditMenu/PullCreditMenu';
import CheckboxStyles from '@styles/Checkbox.module.css';
import PullCreditMenuStyles from '@styles/PullCreditMenu.module.css';

interface Props {
  title: string;
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  missing: boolean;
  disabled: boolean;
}

/**
 * Checkbox sub component.
 */
export default function Checkbox(props: Props) {
  return (
    <div className={PullCreditMenuStyles.formItem}>
      <h2 className={PullCreditMenuStyles.h2}>{props.title}</h2>
      <input
        className={PullCreditMenu.getClassName([CheckboxStyles.checkbox], props.disabled, props.missing)}
        type="checkbox"
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </div>
  );
}
