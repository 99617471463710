/**
 * @file This component is a stylized Box component
 * for styling the Link Preview.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

 import { styled } from '@mui/system';
 
 const LinkPreviewStyle = styled('div')(
   ({ theme }) => `
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   overflow: hidden;
   z-index: 290000000;
   -webkit-backface-visibility: hidden;
   @media (min-width: 801px) {
     max-height: calc(100vh - 24px);
   }
   &:before {
     content: "";
     background-color: var(--peach900);
     position: absolute;
     z-index: 2;
     border-top-right-radius: 8px;
     border-bottom-right-radius: 8px;
   }
   
   @keyframes rise-desktop {
     0% {
       right: -10px;
     }
     100% {
       right: 0;
     }
   }
   
   @keyframes rise-mobile {
     0% {
       bottom: -10px;
     }
     100% {
       bottom: 0;
     }
   }
   
   @media (min-width: 801px) {
     &:before {
       right: -10px;
       width: 10px;
       height: 100%;
       animation: 0.5s rise-desktop forwards;
     }
   }
   
   @media (max-width: 900px) {
     &:before {
       bottom -10px;
       width: 100%;
       height: 10px;
       animation: 0.5s rise-mobile forwards;
     }
   }
   
   &:after {
     content: "";
     background-color: var(--peach900);
     width: 42px;
     height: 42px;
     position: absolute;
     border-radius: 50%;
     transform: translate(50%, 50%);
     animation: 1.5s bounce forwards;
     z-index: 3;
   }
   
   @keyframes bounce-desktop {
     0% {
       bottom: 100%;
       right: 50%;
     }
     100% {
       bottom: 50%;
       right: -42px;
     }
   }
   
   @keyframes bounce-alt {
     0% {
       bottom: 100%;
       right: 50%;
     }
     40% {
       transform: translate(50%, 50%);
     }
     41% {
       transform: translate(55%, 50%);
     }
     42% {
       transform: translate(60%, 50%);
     }
     43% {
       transform: translate(65%, 50%);
     }
     44% {
       transform: translate(70%, 50%);
     }
     45% {
       transform: translate(75%, 50%);
     }
     46% {
       transform: translate(80%, 50%);
     }
     47% {
       transform: translate(85%, 50%);
     }
     48% {
       transform: translate(90%, 50%);
     }
     49% {
       transform: translate(95%, 50%);
     }
     50% {
       transform: translate(100%, 50%);
     }
     51% {
       transform: translate(105%, 50%);
     }
     52% {
       transform: translate(110%, 50%);
     }
     53% {
       transform: translate(115%, 50%);
     }
     54% {
       transform: translate(120%, 50%);
     }
     55% {
       transform: translate(125%, 50%);
     }
     56% {
       transform: translate(130%, 50%);
     }
     57% {
       transform: translate(135%, 50%);
     }
     58% {
       transform: translate(140%, 50%);
     }
     59% {
       transform: translate(145%, 50%);
     }
     60% {
       bottom: 50%;
       right: 50%;
     }
     100% {
       bottom: 50%;
       right: -42px;
     }
   }
   
   @keyframes bounce-mobile {
     0% {
       bottom: 100%;
       right: 50%;
     }
     100% {
       bottom: -42px;
       right: 50%;
     }
   }
   
   @media (min-width: 801px) {
     &:after {
       animation: 1.5s bounce-desktop forwards;
     }
   }
   
   @media (max-width: 900px) {
     &:after {
       animation: 1.5s bounce-mobile forwards;
     }
   }

   @keyframes circle-desktop {
     0% {
       clip-path: circle(0% at right center);
     }
     50% {
       clip-path: circle(0% at right center);
     }
     100% {
       clip-path: circle(180% at right center);
     }
   }
   
   @keyframes circle-mobile {
     0% {
       clip-path: circle(0% at bottom center);
     }
     50% {
       clip-path: circle(0% at bottom center);
     }
     100% {
       clip-path: circle(180% at bottom center);
     }
   }

   @keyframes close-desktop {
     0% {
       clip-path: circle(180% at right center);
     }
     100% {
       clip-path: circle(0% at right center);
     }
   }
   
   @keyframes close-mobile {
     0% {
       clip-path: circle(180% at bottom center);
     }
     100% {
       clip-path: circle(0% at bottom center);
     }
   }
   
   @media (min-width: 801px) {
     iframe {
       border: none;
       width: calc(100% - 10px);
       height: 100%;
       animation: 3.6s circle-desktop forwards;
     }
     iframe.close {
       animation: 1.8s close-desktop forwards;
     }
   }
   
   @media (max-width: 900px) {
     iframe {
       border: none;
       width: 100%;
       height: 100%;
       animation: 3.6s circle-mobile forwards;
     }
     iframe.close {
       animation: 1.8s close-mobile forwards;
     }
   }

   & > div {
     overflow: hidden;
     position: absolute;
     background-size: 100% 100%;
   }
   
   @keyframes tabRise-desktop {
     0% {
       right: -42px;
     }
     100% {
       right: 0px;
     }
   }
   
   @keyframes tabRise-mobile {
     0% {
       bottom: -42px;
     }
     80.332% {
       bottom: -42px;
     }
     80.333% {
       bottom: -37px;
     }
     100% {
       bottom: 0px;
     }
   }
   
   @media (min-width: 801px) {
    & > div {
       top: 50%;
       right: -42px;
       height: 222px;
       width: 42px;
       transform: translateY(-50%);
       background-image: var(--svg-desktop);
       animation: 0.5s tabRise-desktop forwards;
       animation-delay: 1.5s;
     }
   }
   
   @media (max-width: 900px) {
    & > div {
       bottom: -42px;
       right: 50%;
       height: 42px;
       width: 222px;
       transform: translateX(37.25%);
       background-image: var(--svg-mobile);
       animation: 0.5s tabRise-mobile forwards;
       animation-delay: 1.5s;
     }
   }
 `
 );
 
 export default LinkPreviewStyle;
 