import React, { memo } from 'react';
import { PhoneTilted } from '@assets/Icons';
import { useTranslation } from 'react-i18next';
import { DefaultElement } from './templates/DefaultElement';

export const LiveElement = memo(() => {
  const { t } = useTranslation();

  return <DefaultElement text={t('AT_Live')} icon={<PhoneTilted />} />;
});
