export type InputState = {
  value: string;
  unformattedValue: string;
  formattedValue: string;
};

class FooterInputStorage {
  private storage: Map<string, InputState>;

  constructor() {
    // chat_id to value mapping storage
    this.storage = new Map<string, InputState>();
  }

  private set(chat_id: string, input_state: InputState): void {
    this.storage.set(chat_id, input_state);
  }

  private get(chat_id: string): InputState | null {
    if (this.storage.has(chat_id)) {
      return this.storage.get(chat_id);
    }
    return null;
  }

  private delete(chat_id: string): void {
    if (this.storage.has(chat_id)) {
      this.storage.delete(chat_id);
    }
  }

  save(input_state: InputState, chat_id: string) {
    this.set(chat_id, input_state);
  }

  fetch(chat_id: string): InputState {
    return this.get(chat_id);
  }

  clear(chat_id: string): void {
    this.delete(chat_id);
  }
}

// singleton
export default new FooterInputStorage();
