/**
 * @file TipsMessageContent.tsx
 * @description This file defines the TipsMessageContent component used to display help tips
 * received from AI
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */

import { Box, Button, Divider, Stack, Typography } from '@mui/material';

import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import { NextTipIcon, HideTipIcon, ShowTipIcon, PrevTipIcon } from '@assets/Icons';
import React, { useEffect, useRef, useState } from 'react';
import MessageTextStyle from '@styles/MessageTextStyle';
import { HelpTipMessage } from '@mTypes/Conversation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useTranslation } from 'react-i18next';
import useTimeout from '@common/hooks/useTimeout';
import { AccessType } from '@common/AccessType';
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

type TTipsMessageContentProps = {
  message: HelpTipMessage;
}

const TipsMessageContent = ({
  message
}: TTipsMessageContentProps) => {
  // const { dispMessage, isTranslated } = uiTranslationController(message);
  const [isVisible, setIsVisible] = useState(true);
  const singleTipImageUrl = message.helpTipImageUrl;
  // const [timeoutId, setTimeoutId] = useState(null);
  const { reset, clear } = useTimeout(() => setIsVisible(false), 15000);
  const swiperRef = useRef(null);

  const { t } = useTranslation();

  const handleHide = (e) => {
    e.stopPropagation();
    setIsVisible(false);
  };
  const handleShow = (e) => {
    e.stopPropagation();
    // restartTimer();
    reset();
    setIsVisible(true);
  };

  const handleNextTip = (e) => {
    e.stopPropagation();
    swiperRef.current.slideNext();
  };

  const handlePreviousTip = (e) => {
    e.stopPropagation();
    swiperRef.current.slidePrev();
  };

  // const restartTimer = () => {
  // if (timeoutId) {
  //   clearTimeout(timeoutId);
  // }

  // const newTimeout = setTimeout(() => setIsVisible(false), 15000);
  // setTimeoutId(newTimeout);
  // };

  useEffect(() => {
    // const timer = setTimeout(() => setIsVisible(false), 15000);
    // setTimeoutId(timer);
    return () => {
      // clearTimeout(timer);
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSlides = () => {
    return message.helpTipList.map((tipObj, index) => {
      if (tipObj.text) {
        return (
          <SwiperSlide key={index} style={{ height: 'auto', padding: '0px 25px 15px 25px' }}>
            <Stack alignItems="center" height={'100%'}>
              <img
                src={AngelAi}
                aria-hidden='true'
                style={{width: '24px', height: '24px'}}
              />
              <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <MessageTextStyle height={'auto'}>{tipObj.text}</MessageTextStyle>
              </div>
            </Stack>
          </SwiperSlide>
        );
      }
      if (tipObj.imageUrl) {
        // fdisplay slide with img tag
        return (
          <SwiperSlide
            key={index}
            style={{ display: 'flex', height: 'auto', justifyContent: 'center', alignItems: 'center' }}
          >
            <img
              alt="help tip image preview"
              src={tipObj.imageUrl}
              style={{
                maxWidth: '100%',
                maxHeight: 'auto',
              }}
            />
          </SwiperSlide>
        );
      }
    });
  };

  if (isVisible) {
    return (
      <div
        style={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <ChatBubbleStyle
          sx={{
            minWidth: '280px',
            minHeight: '101px',
            position: 'relative',
            maxWidth: '40%',
            alignItems: 'center',
            borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
            background: 'var(--peach600)',
            // p: '0px 25px 15px 25px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack direction="column" spacing={0} alignItems="center" maxWidth={'100%'}>
            {message.helpTipText && (
              <img
                src={AngelAi}
                aria-hidden='true'
                style={{width: '24px', height: '24px'}}
              />
            )}
            {message.helpTipText && (
              <MessageTextStyle style={{ padding: '0px 25px 15px 25px' }}>{message.helpTipText}</MessageTextStyle>
            )}
            {message.helpTipImageUrl && (
              <img
                alt="help tip image preview"
                src={singleTipImageUrl}
                style={{
                  maxWidth: '100%',
                  maxHeight: 'auto',
                }}
              />
            )}
            {message.helpTipList && (
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}
                spaceBetween={20}
                onSlideChange={reset}
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  minHeight: 0,
                  minWidth: 0,
                }}
              >
                {renderSlides()}
              </Swiper>
            )}
          </Stack>
        </ChatBubbleStyle>
        <Box
          sx={{
            height: 'fit-content',
            width: 'fit-content',
            borderRadius: '6px',
            border: '1px solid var(--orange700)',
            background: 'var(--gray000)',
            position: 'relative',
            top: '-15px',
            zIndex: 1,
          }}
        >
          <Stack
            direction="row"
            sx={{ justifyContent: 'space-around', alignItems: 'center', height: '100%' }}
            divider={
              <Divider
                orientation="vertical"
                variant="middle"
                light={true}
                flexItem
                sx={{ backgroundColor: 'var(--orange700)' }}
              />
            }
          >
            {message.helpTipList && (
              <Button onClick={handlePreviousTip} sx={{ gap: '3px', height: '100%', pointerEvents: 'all' }}>
                <PrevTipIcon
                  style={{
                    backgroundColor: 'F26F24',
                    borderRadius: '15%',
                  }}
                />
                <Typography fontSize={12} fontWeight={500} textTransform={'capitalize'} color={'var(--orange700)'}>
                  {t('previousTip')}
                </Typography>
              </Button>
            )}
            {message.helpTipList && (
              <Button onClick={handleNextTip} sx={{ gap: '3px', height: '100%', pointerEvents: 'all' }}>
                <NextTipIcon
                  style={{
                    backgroundColor: 'F26F24',
                    borderRadius: '15%',
                  }}
                />
                <Typography fontSize={12} fontWeight={500} textTransform={'capitalize'} color={'var(--orange700)'}>
                  {t('nextTip')}
                </Typography>
              </Button>
            )}
            <Button onClick={handleHide} sx={{ gap: '3px', pointerEvents: 'all' }}>
              <HideTipIcon
                style={{
                  backgroundColor: 'F26F24',
                  borderRadius: '15%',
                }}
              />
              <Typography fontSize={12} fontWeight={500} textTransform="capitalize" color={'var(--orange700)'}>
                {t('tHide')}
              </Typography>
            </Button>
          </Stack>
        </Box>
      </div>
    );
  }

  return (
    <Button onClick={handleShow} sx={{ minWidth: 0, pointerEvents: 'all' }}>
      <ShowTipIcon />
    </Button>
  );
};

export default TipsMessageContent;
