import React, { useEffect, useState } from 'react';
import Styles from '@styles/MessageContent/BlockchainWelcomeMessageContent.module.css';
import { useUserContext } from '@contexts/user';
import Sanitized from '@components/Sanitized';
import useInitialMount from '@common/hooks/useInitialMount';
import { setUserConsent } from '@storage/userStorage';
import { getWindowLocationPath } from '@storage/userStorage';
import useAbortController from '@common/hooks/useAbortController';
import axiosCall from '@services/axios';
import { useTranslation } from 'react-i18next';


// import { ConsentContainer } from './WelcomeUserMessageContent/ConsentContainer';

/**
 * Returns the full CDN path of the icon
 * @param iconFileName The name or relative path of the icon
 */
const getIconPath = (iconFileName: string) => `${process.env.REACT_APP_MORGAN_CDN}/Images/${iconFileName}`;

const BlockchainWelcomeMessageContent = ({ message, errand }) => {
  const { isOperator } = useUserContext();
  const box = `${process.env.REACT_APP_MORGAN_CDN}/Images/WUM_Images/accepted.png`;
  const sendButton = `${process.env.REACT_APP_MORGAN_CDN}/Images/WUM_Images/sendButtonIcon.png`;
  const russellImage = `${process.env.REACT_APP_MORGAN_CDN}/Images/WUM_Images/russellpetersfull.png`;
  const [localConsent, setLocalConsent] = useState(false);
  const [isRelaxToken, setIsRelaxToken] = useState(false);
  const [currentWorkflowIsRelax, setCurrentWorkflowIsRelax] = useState(false);
  const [isRelax, setIsRelax] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const abortController = useAbortController();
  const { t } = useTranslation();


  type Workflow = {
    _id: string;
    name: string;
  };

  useEffect(() => {
    if (isOperator) return;

    const fetchWorkflowIds = async () => {
      const workflows = ['Welcome Relax TShirt', 'Welcome Relax', 'Welcome Relax Win'];

      try {
        const results = await Promise.all(
          workflows.map(async (search) => {
            const fetchFunction = async () => {
              let searchString = search;
              const config = abortController.get();
              const request = {
                url: 'workflow/db/search?active=true&fields=_id,name',
                method: 'post',
                data: { search: searchString },
              };
              const allWorkflows = await axiosCall(request, config);

              const exactMatch = allWorkflows.find((workflow) => workflow.name === search);
              return exactMatch;
            };

            return fetchFunction();
          })
        );

        // Flatten results into a single array if each response contains an array
        const combinedResults = results.flat();
        console.log("Combined results are ", combinedResults)
        return combinedResults;
      } catch (e) {
        console.error('Error fetching workflows:', e);
        return [];
      }
    };

    const fetchCurrentUserWorkflow = async () => {
      const body = {
        userActionId: errand?.action?.userActionId,
      };

      const payload = {
        url: `userworkflow/getCurrentWorkflowId`,
        method: 'POST',
        data: body,
      };

      return axiosCall(payload);
    };

    (async () => {
      if (!isRelax) return;
      const currentUserWorkflow = await fetchCurrentUserWorkflow();
      const workflowIds = await fetchWorkflowIds();

      for (let i = 0; i < workflowIds.length; i++){
        if (workflowIds[i]._id === currentUserWorkflow.workflow){
          setCurrentWorkflowIsRelax(true);
        }
      }
    })();
  }, [isRelax]);

  const basePath = (process.env.REACT_APP_MORGAN_BASENAME || '').replace(/\/$/, '');
  const relaxLinks = [`${basePath}/relax`, `${basePath}/relaxtshirt`, `${basePath}/relaxwin`]

  // Check to see if the URL used involved russell peters
  const checkLink = () => {
    if (isOperator) return;
    const path = getWindowLocationPath().toLowerCase();
    if (path === `${basePath}/relax`) {
      setLocalConsent(true);
      // if localStorage returns null, then set current messageId as messageIdWithConsent
      const fetchedLocalId = localStorage.getItem('messageIdWithConsent')

      //compare current messageIdWithConsent with localStorage messageId
      if (!fetchedLocalId){
        localStorage.setItem('messageIdWithConsent', message._id)
        setIsRelaxToken(true);
      }else{
        if (fetchedLocalId === message._id){
          setIsRelaxToken(true);
        }else{
          setIsRelaxToken(false);
        }
      }

    }

    if (relaxLinks.includes(path)) {
      console.log('Setting isRelax to true...')
      setIsRelax(true);
    }
  };

  useInitialMount(checkLink);

  if (!message) {
    console.info('Message is undefined');
    return <></>;
  }

  console.log(message);

  const checkConsentBox = () => {
    setUserConsent('true');
    setLocalConsent(true);
  };

  const unCheckConsentBox = () => {
    setUserConsent('false');
    setLocalConsent(false);
  };

  let messageTitle = message?.title;
  let messageSubtitle = message?.subtitle;
  let steps = message?.steps;
  let footer = message?.footer;
  let delimIdx = footer?.indexOf(':');
  const footer1 = footer?.substring(0, delimIdx);
  const footer2 = footer?.substring(delimIdx + 1);
  // If true, uses the default orange color instead of the blockchain blue
  const useDefaultColor = message?.useDefaultColor === true;
  // If true, the consent UI will be rendered with this message
  const showConsentPortion = Boolean(message.consent);

  // If this event has a special gif, it will be named after the event.
  // Otherwise, use the default gif for this component
  const gifName = (message.specialGif ? message.eventName : 'default') + 'NftEventAngel';
  const gifUrl = getIconPath(gifName + '.gif');

  const unConsentMessage = 'If you choose not to consent, please call 1-(855) 711-1770 for additional assistance.';

  return (
    <div className={[Styles.welcomeContainer, ...(isOperator ? [Styles.operatorView] : [])].join(' ')}>
      {isRelax && currentWorkflowIsRelax && (
        <div className={Styles.russellIntroImg}>
          <img src={russellImage} alt="Tick Icon" />
          <div className={Styles.swagText}>It's here! Your exclusive Relax Swag!</div>
        </div>
      )}
      <div>
        <div className={Styles.topCircle}>
          <div className={Styles.innerCircle} />
          <img src={gifUrl} alt="AngelAi logo" className={Styles.image} />
        </div>
        <div className={`${Styles.top}${useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''}`}>
          {messageTitle}
        </div>
        {!isExpanded && isRelaxToken && currentWorkflowIsRelax &&(
          <div className={Styles.readMore} onClick={() => setIsExpanded(true)}>
            Read More...
          </div>
        )}
        <div
          className={`${Styles.bottom}${useDefaultColor ? ' ' + Styles.useDefaultBorderColor : ''}
          ${showConsentPortion ? ' ' + Styles.noBottomBorderRadius : ''}
          ${isExpanded ? Styles.expandedBorderTop : ' '}
            `}
        >
          {isRelaxToken && currentWorkflowIsRelax ? (
            <>
              {isExpanded && (
                <div>
                  <div className={Styles.bottomTop}>
                    {isExpanded && <div className={Styles.bottomTopLeft}>{messageSubtitle}</div>}
                    <div className={Styles.bottomTopMiddle} />
                    <div
                      className={`${Styles.bottomTopRight}${
                        useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''
                      }`}
                    >
                      <div className={Styles.readText}>
                        <span className={Styles.readLess} onClick={() => setIsExpanded(false)}>
                          Read Less...
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.bottomBottom}>
                    {Array.isArray(steps) &&
                      isExpanded &&
                      steps.map((step, idx) => (
                        <div key={idx} className={idx === 0 ? Styles.step1 : Styles.stepAfter}>
                          <div
                            className={`${Styles.stepCircle}${
                              useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''
                            }`}
                          >
                            <div className={Styles.imgCircle}>
                              <img src={getIconPath(step.icon)} alt={step.icon} />
                            </div>
                          </div>
                          <div>
                            <Sanitized html={step.message} />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className={Styles.bottomTop}>
                <div className={Styles.bottomTopLeft}>{messageSubtitle}</div>
                <div className={Styles.bottomTopMiddle} />
                <div
                  className={`${Styles.bottomTopRight}${useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''}`}
                />
              </div>
              <div className={Styles.bottomBottom}>
                {Array.isArray(steps) &&
                  steps.map((step, idx) => (
                    <div key={idx} className={idx === 0 ? Styles.step1 : Styles.stepAfter}>
                      <div
                        className={`${Styles.stepCircle}${
                          useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''
                        }`}
                      >
                        <div className={Styles.imgCircle}>
                          <img src={getIconPath(step.icon)} alt={step.icon} />
                        </div>
                      </div>
                      <div>
                        <Sanitized html={step.message} />
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
          {footer1 && footer2 && (
            <div className={Styles.footer}>
              <span className={Styles.footer1}>{footer1}:</span> {footer2}
            </div>
          )}
        </div>
        {/* {message.consent && (
            <div className={Styles.consentContainer}>
              <ConsentContainer
                errand={errand}
                message={message}
                html={message.consent}
                isOperator={isOperator}
                parentMsgType="BlockchainWelcome"
                renderFinal={true}
              />
            </div>
          )} */}

        {isRelaxToken && (
          <div
            className={[
              Styles.relaxTokenConsentContainer,
              ...(!isExpanded ? [Styles.collapsedBorder] : [Styles.expandedBorder]),
            ].join(' ')}
          >
            {localConsent ? (
              <img
                onClick={unCheckConsentBox}
                style={{
                  display: 'inline-block',
                  position: 'relative',
                  height: '17px',
                  width: '17px',
                  marginBottom: '-3px',
                  marginRight: '2px',
                  cursor: 'pointer',
                }}
                src={box}
                alt="Tick Icon"
              />
            ) : (
              <div className={Styles.uncheckedBox} onClick={checkConsentBox} />
            )}
            {t('consentByPressingSend')}
            <img
              translate="no"
              style={{
                border: '0.5px solid #7a7a7a',
                width: '17px',
                borderRadius: '4px',
                padding: '2px',
                marginBottom: '-2px',
                marginRight: '2px',
                marginLeft: '2px',
              }}
              src={sendButton}
              alt="Send Button Icon"
            />
            , {t('consentYouAgreeTo')} i){' '}
            <a href="https://angelai.com/terms-and-conditions" style={{ color: 'rgb(113, 113, 113)' }}>
              Terms and Conditions
            </a>{' '}
            {t('consentAnd')} ii){' '}
            <a href="https://angelai.com/privacy-policy" style={{ color: 'rgb(113, 113, 113)' }}>
              Security and Privacy Policy
            </a>{' '}
            {t('consentAnd')} iii) {t('consentMonitored')}
            <p style={{ color: 'red' }}>{!localConsent && unConsentMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockchainWelcomeMessageContent;
