import dayjs from 'dayjs';
import writeXlsxFile from 'write-excel-file';

const downloadUserReport = async (userData) => {
  const schema = [
    // Column #1
    {
      column: 'Name',
      type: String,
      value: (user) => user.name,
      width: 25,
    },
    {
      column: 'Email',
      type: String,
      value: (user) => user.email,
      width: 25,
    },
    {
      column: 'Phone',
      type: String,
      value: (user) => user.phone,
      width: 25,
    },
    {
      column: 'Last Access',
      type: String,
      value: (user) => user.lastAccess,
      width: 20,
    },
    {
      column: 'TCPA',
      type: String,
      value: (user) => user.tcpa,
      width: 25,
    },
    {
      column: 'Opt-In',
      type: String,
      value: (user) => user.optIn,
      width: 25,
    },
    {
      column: 'Unsubscribed from SMS',
      type: String,
      value: (user) => user.unSubSms,
      width: 25,
    },
    {
      column: 'Unsubscribed from Email',
      type: String,
      value: (user) => user.unSubEmail,
      width: 25,
    },
    {
      column: 'Status',
      type: String,
      value: (user) => user.status,
      width: 25,
    },
  ];

  if (userData?.length > 0) {
    const objects = userData?.map((user) => {
      return {
        name: `${user.firstname} ${user.lastname}`,
        email: user?.email,
        phone: user?.phone,
        lastAccess: user?.lastAccess,
        tcpa: user?.tcpaConsentGiven ? 'Yes' : 'No',
        optIn: user?.optInConsentGiven ? 'Yes' : 'No',
        unSubSms: !user?.smsNotifications ? 'Yes' : 'No',
        unSubEmail: !user?.emailNotifications ? 'Yes' : 'No',
        status: user?.status,
      };
    });
    const date = dayjs(new Date()).format('DD/MM/YYYY');
    await writeXlsxFile(objects, {
      schema,
      fileName: `Morgan User Report : ${date}.xlsx`,
    });
  }
};

export default downloadUserReport;
