/**
 * 11/30/2023, Joshua Brusa: The speechly api is getting deprecated. Need to
 * switch to this: https://github.com/aws/aws-sdk-js-v3/tree/master/clients/client-transcribe-streaming
 * Use the browserSupportsSpeechRecognition value from the useSpeechRecognition
 * hook to determine if the browser supports speech recognition. If it does not
 * use the aws service instead. Also split the logic for the speech recognition
 * and handle listen they should function independently.
 */

import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren, useEffect, useState, useContext } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { Mic, MicOff } from '../Assets/Icons';
import { getUserConsent, getUserConsentIntro } from '../Storage/userStorage';
import { Styles, Style } from '../Styles/AudioRecorderStyles';
import axiosCall from '../Services/axios';
import eventBus from '../Common/eventBus';
import { IFooterContext, FooterContext } from '@contexts/FooterContext';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import ThinClientUtils from '@common/ThinClientUtils';
import { useUserContext } from '@contexts/user';
import ARStyles from '@styles/ARStyles.module.css';
import { ResetFooterUserAction } from '@common/common';

const AudioRecorder: React.FC<PropsWithChildren<any>> = (props) => {
  const { i18n } = useTranslation();
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const { _id } = useUserContext();
  const [audioBlob, setAudioBlob] = useState(null);
  const [userMicOff, setUserMicOff] = useState(false);
  const [recorder, setMediaRecorder] = useState(null);
  const footerContext = useContext<IFooterContext>(FooterContext);
  const errandContext = useContext<IErrandContext>(ErrandContext);

  /**
   * Stop recording and transcribing when the user toggles the
   * microphone icon. Stop all recording and speech to text
   * services but keep the audio file and transcript in component
   * buffers because they can submit them to the backend if the
   * send text arrow is pressed.
   */
  const handleTurnOffMic = async () => {
    props.setIsRecording(false);
    setUserMicOff(true); // User wants to stop recording
    recorder.stop();
    setAudioBlob(null);
    SpeechRecognition.abortListening();
    SpeechRecognition.stopListening();
    resetTranscript();
    props.resetMorph()
    props.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === props.errandId);

      if (chatObj) {
        ResetFooterUserAction(chatObj);
      }

      return [...prev]; // spread to trigger dependency arrays as state was modified
    });
  };

  /**
   * Starts recording and transcribing when the user clicks on the
   * microphone icon. Store the audio chunks in a component buffer.
   * We can always extract the current transcribe from the SpeechRecognition
   * transcript buffer and load it into the message input box using a
   * useEffect function.
   */
  const handleListen = async () => {
    // Thin Client specific event to request Camera access on click
    if (ThinClientUtils.isThinClient()) {
      window.dispatchEvent(new CustomEvent('micAccessEvent', {
        detail: {
          key: 'micAccessEvent',
        }
      }))
    }
    if (props.setOpen) {
      props.setOpen(false);
    }
    // show consent notification if needed
    if (getUserConsentIntro() !== 'true' && props.operatorData === undefined) {
      return eventBus.dispatch('showConsentIntro');
    }
    if (getUserConsent() !== 'true' && props.operatorData === undefined) {
      return eventBus.dispatch('showConsentContent');
    }
    try {
      setUserMicOff(false); // User wants to start recording again
      // Obtain permission for mic and set audio source as stream
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      let chunks = [];

      mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks);
        setAudioBlob(blob);
        chunks = [];
      };

      mediaRecorder.start();

      SpeechRecognition.startListening({
        continuous: true,
        language: i18n.language,
      });

      props.setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === props.errandId);

        if (chatObj) {
          ResetFooterUserAction(chatObj, 'Recording...');
        }

        props.setIsRecording(true);
        setMediaRecorder(mediaRecorder);
        errandContext.setMorphType(MorphType.Recording);

        return [...prev]; // spread to trigger dependency arrays as state was modified
      });
    } catch (err) {
      console.error(`The following error occurred: ${err}`);
      props.setIconToShow('mic')
      props.setShowPermissionReminder(true)
    }
  };

  useEffect(() => {
    if (!props.isRecording && recorder?.state !== 'inactive') {
      recorder?.stop();
      SpeechRecognition?.abortListening();
      SpeechRecognition?.stopListening();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isRecording, recorder, SpeechRecognition]);

  useEffect(() => {
    (async () => {
      if (!userMicOff && audioBlob?.size > 0 && audioBlob?.size < Number(process.env.REACT_APP_FILE_SIZE_LIMIT)) {
        const formData = new FormData();
        formData.append('files', audioBlob, 'audio');
        formData.append('initialMimeType', recorder?.mimeType);
        formData.append('user', _id);
        formData.append('recipients', props.recipients?.length > 0 ? props.recipients.join(',') : []);
        formData.append('userType', props.operatorData ? 'Operator' : 'User');
        formData.append('value', transcript?.toLowerCase());
        const addAudioBlob = {
          url: `chat/${props.errandId}/audio`,
          method: 'POST',
          data: formData,
        };

        try {
          await axiosCall(addAudioBlob);
        } catch (error) {
          console.error(error);
        }

        props.setErrands((prev) => {

          const chatObj = prev.find((e) => e._id === props.errandId);

          if (chatObj) {
            ResetFooterUserAction(chatObj);
          }

          setAudioBlob(null);
          resetTranscript();
          return [...prev]; // spread to trigger dependency arrays as state was modified
        });
      }
    })();
    return () => {
      setAudioBlob(null);
      resetTranscript();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioBlob, _id]);

  return (
    <div 
      className={(footerContext.chatInputFieldRef.current?.unformattedValue !== '' && !props.isRecording) || props.selectedFiles.length > 0 ? ARStyles.hide : ARStyles.show} 
      onMouseDown={(e) => {e.preventDefault()}}
    >
      {props.label ? (
        <Styles className={`label ${props.open ? 'open' : ''}`} disabled={!props.isRecording && listening} onClick={props.isRecording ? handleTurnOffMic : handleListen}>
          {props.isRecording ? (
            <MicOff />
          ) : (
            <Mic />
          )}
          <p>{props.label}</p>
        </Styles>
      ) : (
        <Style
          disabled={!props.isRecording && listening}
          onClick={props.isRecording ? handleTurnOffMic : handleListen}
        >
          {props.isRecording ? (
            <MicOff />
          ) : (
            <Mic />
          )}
        </Style>
      )}
    </div>
  )
};

export default AudioRecorder;
