import React from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import Styles from '@styles/MorphCreditRepairAccountType.module.css';
import { notNull } from '../utils';
import { useTranslation } from 'react-i18next';


export const ButtonUp = (props) => {
  const buttonUpClassArr = [props.isSomeWfSelected ? Styles.shown : null, Styles.buttonUp].filter(notNull);
  const buttonUpClassName = buttonUpClassArr.join(' ');
  const { t } = useTranslation();

  return (
    <div
      onClick={props.onClick}
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'Space') {
          props.onClick();
        }
      }}
      tabIndex={0}
      aria-label={t('UPM_Click_to_clear_the_current_selection_from_the_list')}
      role={'button'}
      className={buttonUpClassName}
    >
      <KeyboardDoubleArrowUpIcon className={Styles.buttonUpIcon} />
    </div>
  );
};