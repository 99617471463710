import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import EditOrderDialog from './EditOrderDialog';
import StandardTablePagination from './StandardTablePagination';
import TableHeaderBar from './TableHeaderBar';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// const buildString = (type, image) => {
//   return `data:image/${type};base64,${image}`;
// };

// const getImageSource = (base64Image) => {
//   if (base64Image?.startsWith('/9j/')) {
//     return buildString('jpg', base64Image);
//   }

//   if (base64Image?.startsWith('iVBOR')) {
//     return buildString('png', base64Image);
//   }

//   if (base64Image?.startsWith('PHN2Z')) {
//     return buildString('svg+xml', base64Image);
//   }

//   return "";
// }


// const loadPictureData = async (productId) => {
//   let data = {}
//   let request = {
//     url: `product/` + productId,
//     method: 'GET',
//     data: data,
//   };

//   let result;
//   try {
//      result = await axiosCall(request);
//   } catch (error) {
//     console.log(error);
//   }
//   if (result.picture){
//     console.log('PICTURE LOG')
//     console.log(result.picture)
//     return new Promise( resolve => {
//       const image = new Image();
//       image.addEventListener('load', () => {

//         resolve(getImageSource(result.picture));

//       })
      
        
//     })}else{
//           return null;
//       }
    
      
//   }

const StyledTableCell = styled(TableCell)(({ active }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

const Orders = (props) => {
  const { t } = useTranslation();
  const [editOrderState, setEditOrderState] = useState({
    edit: false,
    open: false,
    data: null,
  });
  const [OrderData, setOrderData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [errorMessage, setErrorMessage] = useState('');
  const [getActive, setGetActive] = useState(true);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searched, setSearched] = useState(false);
  const [orderCount, setOrderCount] = useState(0);

  const loadOrderData = async (controller) => {
    setLoading(true);
    // Load one more row than required to test if more data exists
    const offset = page < 0 ? 0 : page * rowsPerPage;
    const limit = rowsPerPage > 0 ? `&limit=${rowsPerPage + 1}` : '';
    const active = getActive ? '&active=1' : '';
    let data;
    if (search.length > 0) {
      data = { search: search };
      setSearched(true);
    } else {
      data = "";
      setSearched(false);
    }

    let request = {
      url: `Order?offset=${offset}${limit}${active}`,
      method: 'GET',
      data: data,
    };
    let config = controller
      ? {
          signal: controller.signal,
        }
      : {};

    try {
      const result = await axiosCall(request, config);
      
      let productName = null;
      let customerName = null;
      for (let i = 0; i < result.length ; i++){
        try{
          productName = result[i]["productId"]["name"];
        }catch{
          productName = ""
        }
        try{
          customerName = result[i]["customerId"]["firstname"] + " " + result[i]["customerId"]["lastname"];
        }catch{
          customerName = ""
        }
        result[i]["productName"] = (productName !== undefined? productName : "")
        result[i]["customerName"] = (customerName !== undefined? customerName : "")
      }
      if (result.length > 0) {
        // See if we were able to fetch the one additional row, therefore indicating more data is available
        setIsMoreDataAvailable(rowsPerPage > 0 ? result.length > rowsPerPage : false);
        setOrderData(rowsPerPage > 0 ? result.slice(0, rowsPerPage) : result); // Only display the number specified
        try {
          const a = active ? "active=true" : ""
          const result = await axiosCall({ url: `order/db/count?${a}` ,method: 'GET',});
          if (result) {
            setOrderCount(result)
          }
        } catch (error) {
          console.log(error);
        }
        setNoResults(false);
      } else {
        setNoResults(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formatDateTime = (value) => {
    if (Date.parse(value)) {
      return dayjs(value).format('MM/DD/YY hh:mm:ss A');
    }
    return '';
  };

  const handleDownloadOrderReport = async () => {
    try {
      const result = await axiosCall({ url: `Order/?order=asc&orderBy=name` });
      if (result) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*************************************
   * Add/Edit Order Dialog Functions
   *************************************/

  const handleEditOrderOpen = (Order) => {
    const data = {
      shippingAddressLine1: Order.shippingAddressLine1,
      shippingAddressLine2: Order.shippingAddressLine2,
      shippingCity: Order.shippingCity,
      shippingState: Order.shippingState,
      shippingZip: Order.shippingZip,
      status: Order.status,
      carrier: Order.carrier,
      id: Order?._id,
      quantity: Order.quantity,
      unitSize: Order.size,
      color: Order.color,
      trackingNumber: Order.trackingNumber
    };
    setEditOrderState({ edit: true, open: true, data: data });
    setErrorMessage('');
  };

  const handleEditOrderClose = () => {
    setEditOrderState({ open: false, data: null });
    setErrorMessage('');
    loadOrderData();
    
  };

  const handleEditOrderSubmit = async (data) => {
    if (await editOrder(data)) {
      setEditOrderState({ open: false, data: null });
      setErrorMessage('');
    }
    loadOrderData();
  };

  const handleAddOrderSubmit = async (data) => {
    if (await addOrder(data)) {
      setEditOrderState({ open: false, data: null });
      setErrorMessage('');
    }
    loadOrderData();
  };

  const editOrder = async (data) => {
    const { id, ...sendData } = data;
    try {
      let result = await axiosCall({
        url: `Order/${id}`,
        method: 'PUT',
        data: sendData,
      });

      if (result) {
        await loadOrderData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  const addOrder = async (data) => {
    try {
      let result = await axiosCall({
        url: `Order`,
        method: 'POST',
        data: data,
      });

      if (result) {
        await loadOrderData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  const handleDeactivate = async (id) => {
    try {
      const result = await axiosCall({
        url: `Order/${id}`,
        method: 'PUT',
        data: {
          active : false
        }
      });
      if (result) {
        await loadOrderData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReactivate = async (id) => {
    try {
      const result = await axiosCall({
        url: `Order/${id}`,
        method: 'PUT',
        data: {
          active : true
        }
      });
      if (result) {
        await loadOrderData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowInactive = () => {
    //Invert this value
    setGetActive(!getActive);
    setPage(0);
  };

  /*************************************
   * Pagination Functions
   *************************************/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //When OrderData changes, update datatoDisplay
  useEffect(() => {
    setDataToDisplay(OrderData);
    setLoading(false);
  }, [OrderData]);
  

  // Reload data when getActive, page or rowsPerPage changes
  useEffect(() => {
    const controller = new AbortController();
    loadOrderData(controller);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, getActive]);

  // search 500ms after user gets done typing
  useEffect(() => {
    if (search.length > 0 || searched) {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => { loadOrderData(controller); }, 500, controller);

      return () => {
        clearTimeout(timeoutId);
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

 return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 175px)" padding={1}>
      <TableHeaderBar
        
        setSearch={setSearch}
        onRefresh={loadOrderData}
        onExport={handleDownloadOrderReport}
        shouldHaveSearch={false}
      
        searchValue={search}
        showInactiveClick={handleShowInactive}
        isActive={getActive}
      />
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{t('orderPlacedCol')}</StyledTableCell>
                    <StyledTableCell>{t('orderStatusCol')}</StyledTableCell> 
                    <StyledTableCell>{t('nameCol')}</StyledTableCell>
                    <StyledTableCell>{t('addressCol')}</StyledTableCell>
                    <StyledTableCell>{t('products')}</StyledTableCell>
                    <StyledTableCell>{t('unitSizeCol')}</StyledTableCell>
                    <StyledTableCell>{t('unitColorCol')}</StyledTableCell>
                    <StyledTableCell>{t('quantityCol')}</StyledTableCell>
                    <StyledTableCell>{t('carrierCol')}</StyledTableCell>
                    <StyledTableCell>{t('trackingNumberCol')}</StyledTableCell>
                    <StyledTableCell>{t('actionsCol')}</StyledTableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToDisplay?.map((Order, index) => {
                    const active = Order.active ? 1 : 0;
                    const d = formatDateTime(Order.createdAt)
                    return (
                     <TableRow
                        sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }}
                        hover
                        key={Order?._id}>
                        {/* <StyledTableCell>
                        {Order?._id && (
                          <Box
                            component="img"
                            style={{ filter: 'grayscale(0%)' }}
                            width="100%"
                            src={ loadPictureData(Order.productId)}
                          />
                        )}
                        </StyledTableCell>   */}
                        <StyledTableCell>{d}</StyledTableCell>
                        <StyledTableCell>{Order.status}</StyledTableCell>
                        <StyledTableCell>{Order.customerName}</StyledTableCell>
                        <StyledTableCell>{Order.shippingAddressLine1 + ' ' + Order.shippingAddressLine2 + ' ' +
                         Order.shippingCity + ' ' + Order.shippingState + ' ' + Order.shippingZip}</StyledTableCell>
                        <StyledTableCell>{Order.productName}</StyledTableCell>
                        <StyledTableCell>{Order.size}</StyledTableCell>
                        <StyledTableCell>{Order.color}</StyledTableCell>
                        <StyledTableCell>{Order.quantity}</StyledTableCell>
                        <StyledTableCell>{Order.carrier}</StyledTableCell>
                        <StyledTableCell>{Order.trackingNumber}</StyledTableCell>
                        <StyledTableCell>
                          <Tooltip
                            title={t('editOrder')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <BorderColorOutlinedIcon
                              onClick={() => handleEditOrderOpen(Order)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'var(--blue210)' }}
                            />
                          </Tooltip> 
                          {active ? (
                            <Tooltip
                            title={t('deActivateOrder')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <RestoreFromTrashOutlinedIcon
                              onClick={() => handleDeactivate(Order?._id)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                            />
                          </Tooltip>
                          ) : (
                            <Tooltip
                            title={t('reActivateOrder')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <RestoreFromTrashOutlinedIcon
                              onClick={() => handleReactivate(Order?._id)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                            />
                          </Tooltip>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      dataSize={orderCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table >
            )}
          </>
        )}
      </TableContainer >
      {
        editOrderState.open && (
          <EditOrderDialog
            edit={editOrderState.edit}
            open={editOrderState.open}
            Order={editOrderState.data}
            error={errorMessage}
            onClose={handleEditOrderClose}
            onSubmit={handleEditOrderSubmit}
            onAdd={handleAddOrderSubmit}
          />
        )
      }
    </Box >
  );
};

export default Orders;
