import { Box, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material';
import { getOperatorStatus, setOperatorStatus } from '../Storage/operatorStorage';
import React, { useEffect, useRef, useState, memo } from 'react';

import axiosCall from '../Services/axios';
import { getStatusColors } from '../Common/StatusColors';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@contexts/user';

const StatusBox = styled(Select)(() => ({
  backgroundColor: 'var(--gray050)',
  borderRadius: '16px',
  border: '1px solid var(--gray210)',
  height: '30px',
  textTransform: 'capitalize',
  ':hover': {
    backgroundColor: 'var(--gray040)',
  },
  '.MuiTouchRipple-child': {
    backgroundColor: 'var(--red400)',
  },
}));

const StatusPicker = (props) => {
  const { _id } = useUserContext();
  const theme = useTheme();
  const { t } = useTranslation();
  const [status, setStatus] = useState(getOperatorStatus() || 'online');
  const statusOptions = ['online', 'busy', 'away'];
  const location = useLocation();
  const timer = useRef(null);
  const [previousStatus, setPreviousStatus] = useState(undefined);

  const updateStatus = async (newStatus) => {
    try {
      if (_id == null || newStatus == null) return; // guard clause to avoid sending incorrect requests.

      console.log(`Updating operator status to ${newStatus}`);
      const result = await axiosCall({
        url: `operator/${_id}`,
        method: 'PUT',
        data: {
          status: newStatus,
        },
      });
      if (result) {
        setStatus((prev) => {
          setPreviousStatus(prev);
          return newStatus;
        });
        setOperatorStatus(newStatus);
      }
    } catch (error) {
      console.error(
        `Error setting the operator's status to ${newStatus}`,
        error.response?.data?.message
      );
      return false;
    }
  };

  const handleChange = async (event) => {
    const newStatus = event.target.value;
    updateStatus(newStatus);
  };

  // This useEffect controls the auto away features. When the operator does not do anything
  // for a specified ammount of time this code will update their status to away and back again
  // when they start doing things.
  useEffect(() => {
    // If there already was a timer clear that
    if (timer.current !== null) {
      clearTimeout(timer.current);
    }

    // If our status is away and there is a previous status we should revert back to the previous
    if (previousStatus !== undefined && status === 'away') {
      updateStatus(previousStatus);
      setPreviousStatus(undefined);
    }

    // Create a new timer to wait for the status timeout time
    timer.current = setTimeout(() => {
      // If we are attached to a chat (location.state?.chat is not undefined) or if we are already
      // not marked as online we dont need to do anything. Otherwise we should update previous status
      // and set our current status to away.
      if (location.state?.chat !== undefined || status !== 'online') {
        console.log('Operator is attached to a chat or already not online, leave them alone.');
      } else {
        console.log('Operator is not attached to a chat and set to online, update to away');
        updateStatus('away');
      }
    }, process.env.REACT_APP_MORGAN_STATUS_TIMEOUT);

    return () => {
      clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  return (
    <StatusBox value={status ? status : 'online'} onChange={handleChange}>
      {statusOptions.map((st) => {
        return (
          <MenuItem value={st} key={st}>
            <Stack justifyContent="center" direction="row" alignItems="center" spacing={1}>
              <Box
                backgroundColor={getStatusColors(st, theme).main}
                borderRadius="50%"
                width="10px"
                height="10px"
              />
              <Typography sx={{ textTransform: 'capitalize' }}>{t(`${st}Status`)}</Typography>
            </Stack>
          </MenuItem>
        );
      })}
    </StatusBox>
  );
};

export default memo(StatusPicker);
