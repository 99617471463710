import {
  Box,
  Paper,
  TableFooter,
  tableCellClasses,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { Close } from '../Assets/Icons';
import React, { FC, useEffect, useState, useCallback} from 'react';
import useControllerCallback from '@common/hooks/useControllerCallback'
import { useTranslation } from 'react-i18next';
import axiosCall from '../Services/axios'
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { StatusColors, getStatusColors } from '../Common/StatusColors';
import StandardTablePagination from './StandardTablePagination';
import { DialogPropsWithChildren } from '@mTypes/Console';

const AIAddDialog: FC<DialogPropsWithChildren> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const activeStep = 0;
  const [operatorData, setOperatorData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const getActive = true;
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

    /*************************************
   * Pagination Functions
   *************************************/
     const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

  const OverflowBox = styled(Box)((/*mymaxwidth: Object */) => ({
    display: 'block',
    // maxWidth: `${mymaxwidth}`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }));

  const formatDateTime = (value: string) => {
    if (Date.parse(value)) {
      return dayjs(value).format('MM/DD/YY hh:mm:ss A');
    }
    return '';
  };


  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'var(--gray010)',
      align: 'left',
    },
    [`&.${tableCellClasses.root}`]: {
      borderBottom: '1px solid var(--gray010)',
      padding: '4px 8px',
    },
  }));

  const getAccessString = (accessLevel: string) => {
    switch (accessLevel) {
      case 'Level 0':
        return t('level0Access');
      case 'Level 1':
        return t('level1Access');
      case 'Level 2':
        return t('level2Access');
      case 'Admin':
        return t('adminAccess');
      default:
        break;
    }
  }

  const StatusBox = styled(Box)(({ statuscolor }: {statuscolor: StatusColors}) => {
    return ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: statuscolor.main,
      borderRadius: '16px',
      color: statuscolor.text,
      height: '24px',
      fontWeight: '500'
    });
  });

  const loadData = useCallback(async (controller) => {
    setLoading(true);
    // Load one more row than required to test if more data exists
    const offset = page * rowsPerPage;
    const limit = rowsPerPage > 0 ? `&limit=${rowsPerPage + 1}` : '';
    const active = getActive ? '&active=1' : '';
    let data;
        let request = {
        url: `operator/participants/access?accessLevel=Level 0&order=asc&orderBy=lastname&offset=${offset}${limit}${active}`,
        method: 'POST',
        data: data,
      };

    const config = controller ? {
      signal: controller.signal
    } : {};

    try {
      const result = await axiosCall(request, config);
      if (result?.length > 0) {
        // See if we were able to fetch the one additional row, therefore indicating more data is available
        setIsMoreDataAvailable(rowsPerPage > 0 ? result?.length > rowsPerPage : false);
        setDataToDisplay(rowsPerPage > 0 ? result?.slice(0, rowsPerPage) : result);
        setNoResults(false);
      } else {
        setNoResults(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [getActive, page, rowsPerPage]);

  // load initial data on mount

  useControllerCallback(loadData);


  const handleSubmit = () => {
    props.onSubmit();
  };


  return (
    <Dialog sx={{ zIndex: 10000 }} maxWidth="xl" fullWidth={true} open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{t('newAi')}</Typography>
          <Close style={{ width: '12px', cursor: 'pointer' }} color="var(--gray210)" onClick={() => props.onClose()} />
        </Stack>
      </DialogTitle>
      <DialogContent>
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="200px">{t('nameCol')}</StyledTableCell>
                    <StyledTableCell width="120px">{t('nicknameCol')}</StyledTableCell>
                    <StyledTableCell width="150px">{t('dutyCol')}</StyledTableCell>
                    <StyledTableCell width="100px">{t('departmentCol')}</StyledTableCell>
                    <StyledTableCell width="100px">{t('teamCol')}</StyledTableCell>
                    <StyledTableCell width="100px">{t('subTeamCol')}</StyledTableCell>
                    <StyledTableCell width="60px">{t('accessLevelCol')}</StyledTableCell>
                    <StyledTableCell width="50px">{t('statusCol')}</StyledTableCell>
                    <StyledTableCell width="130px">{t('lastAccessCol')}</StyledTableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToDisplay?.map((operator) => {
                    const active = operator.active ? 1 : 0;
                    return (
                      <TableRow
                        sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }}
                        hover
                        key={operator?._id}>
                        <StyledTableCell>
                          {operator.firstname} {operator.lastname}
                        </StyledTableCell>
                        <StyledTableCell>{operator.nickname}</StyledTableCell>
                        <StyledTableCell>{operator.dutyDescription}</StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox maxWidth="150px">
                            {operator.departmentDescription}
                          </OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox maxWidth="250px">{operator.team}</OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox maxWidth="250px">{operator.subTeam}</OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>{getAccessString(operator?.accessLevel)}</StyledTableCell>
                        <StyledTableCell>
                          <StatusBox
                            statuscolor={getStatusColors(operator.status, theme)}>
                            {t(`${operator.status}Status`)}
                          </StatusBox>
                        </StyledTableCell>
                        <StyledTableCell width="40px">{formatDateTime(operator.lastAccess?.createdAt)}</StyledTableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </>
        )}
        </TableContainer>
        <Typography color={theme.palette.red['400']}>{props.error}</Typography>
      </DialogContent>
      <DialogActions>
        {activeStep === 0 ? (
          <Button variant="contained" color="info" onClick={() => handleSubmit()}>
            {t('doneButton')}
          </Button>
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AIAddDialog;
