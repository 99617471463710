/**
 * @file This component is a stylized Box component
 * for styling the operator login page.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

 import { Box, styled } from '@mui/system';

 const Home = styled(Box)(
   ({ theme }) => `
   background: linear-gradient(135deg, var(--gray000), var(--gray050)),
   background-color: var(--peach000);
   background-position: 50% 50%;
   background-size: cover;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   overflow: hidden;
   position: relative;
   & > span:nth-of-type(1)::before {
     content: "";
     display: block;
     width: 60vw;
     height: 60vh;
     border-radius: 50%;
     position: absolute;
     top: -30vh;
     left: -20vw;
     background: radial-gradient(var(--blue983), var(--gray000));
     filter: blur(10vmin);
     z-index: -1;
   }
   & > span:nth-of-type(1)::after {
     content: "";
     display: block;
     width: 80vw;
     height: 100vh;
     border-radius: 50%;
     position: absolute;
     top: -50vh;
     left: 60vw;
     background: radial-gradient(var(--blue710), var(--gray000));
     filter: blur(10vmin);
     z-index: -1;
   }
   & > span:nth-of-type(2)::before {
     content: "";
     display: block;
     width: 60vw;
     height: 90vh;
     border-radius: 50%;
     position: absolute;
     top: 65vh;
     left: -20vw;
     background: radial-gradient(var(--peach710), var(--gray000));
     filter: blur(10vmin);
     z-index: -1;
   }
   & > span:nth-of-type(2)::after {
     content: "";
     display: block;
     width: 80vw;
     height: 100vh;
     border-radius: 50%;
     position: absolute;
     top: 50vh;
     left: 50vw;
     background: radial-gradient(var(--peach720), var(--gray000));
     filter: blur(10vmin);
     z-index: -1;
   }
   & > main {
     width: 80%;
     display: flex;
     justify-content: center;
     align-items: center;
   }
  `
 );
 
 export default Home;
 