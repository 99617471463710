import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import Avatar from '../Avatar';
import getImageSource from '@common/getImageSource';
import axiosCall from '@services/axios';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;
`;
interface guestTableProps {
  selectedOperator: any,
  loading: boolean,
}

const GuestListTable : React.FC<PropsWithChildren<any>> = (props : guestTableProps) => {
  const { selectedOperator } = props.selectedOperator;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [guestsConversations, setGuestsConversations] = useState([]);

  const loadGuestData = async () => {
    if( !selectedOperator || selectedOperator?.Provider?.guestIdList.length === 0) {
      setGuestsConversations([]);
      return;
    }
    const payload = {
      url: `reports/guestsConversationList?guestIdList=${selectedOperator?.Provider?.guestIdList}&chatIdList=${selectedOperator?.Provider?.chatsIdList}`,
    }
    let res = await axiosCall(payload);

    if (res &&  res.guestsConversations) {
      setGuestsConversations(res.guestsConversations);
    } else {
      setGuestsConversations([]);
    }
  }

  useEffect(() => {
    if (location.state?.module === 'monitoring-guests') {
      loadGuestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedOperator ]);
  //Navigate to the last conversation with a given user
  const handleClick = (chat) => {
      if(chat && chat.lastChatId) {
        navigate('/console', {
          state: {
            module: 'conversations',
            tab: 'history',
            chat: chat.lastChatId
          },
        });
      } else {
        alert("We are unable to take you to this user last conversation for this user.");
      }
  };

  return (
    <StyledDiv style={{ width: '100%', height: '100%' }} >
      <TableContainer sx={{maxHeight: 450 }}>
        <Table stickyHeader
          size="small"
          sx ={{     
            height:'auto',
            ":hover": {
              backgroundColor: 'var(--gray000)',
            },
            border:0,
            //virtualized='true',          
            //loading={ guestsConversations === null ? 'true' : 'false' }
          }
          }
    
           >
          {/*Column names*/}
          <TableHead>
            <TableRow>
              <StyledTableCell> {t("nameCol")} </StyledTableCell>
              <StyledTableCell align="left"> {t("loanAccess")}</StyledTableCell>
              <StyledTableCell> {t("workflowUsed")} </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {guestsConversations.map( (rowData, index) => (
            <TableRow key={index}>
              <TableCell>
                <TableCell sx={{border:0}}>
                  <Tooltip placement="right" key={index} title={`${rowData.firstname} ${rowData.lastname}`}>
                    <Avatar key={index} alt={`${rowData.firstname} ${rowData.lastname}`} img={getImageSource(rowData.avatar)} />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{border:0}}>
                  <IconButton
                    onClick={() => handleClick(rowData)}>
                  <Typography fontWeight="bold"> {`${rowData.firstname} ${rowData.lastname}`} </Typography>
                </IconButton>
                </TableCell>
              </TableCell>
              <TableCell>
                <div>{(rowData?.workflowLoanAccess || 0) + ' times'}</div>
              </TableCell>
              <TableCell>
                <div>{rowData.workflowName}</div>
              </TableCell>
            </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledDiv>
  );
};

export default GuestListTable;
