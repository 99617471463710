import React, { FC } from 'react';
import { DialogPropsWithChildren } from '@mTypes/Console';
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

const DisabledNotificationDialog: FC<DialogPropsWithChildren> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogContent>
        <Stack direction='column' alignItems='center' spacing={5} pt={3}>
          <Typography fontWeight='bold' align='center'>{t('disabledNotificationMessage')}</Typography>
          <DialogActions>
            <Button
              variant='contained'
              sx={{ color: 'var(--gray000)', backgroundColor: 'var(--blue941)' }}
              onClick={() => props.onClose()}>
              {t('disabledNotificationClose')}
            </Button>
          </DialogActions>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DisabledNotificationDialog;
