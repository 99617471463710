import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as CoreUtils from '@common/CoreUtils';
import * as Form from '@components/PullCreditMenu/Form';
import * as operatorStorage from '@storage/operatorStorage';
import * as PullCreditMenu from '@components/PullCreditMenu/PullCreditMenu';
import SubmitButtonStyles from '@styles/SubmitButton.module.css';
import axiosCall from '@services/axios';

const runStatusCompleted = 'COMPLETE';
const creditVendorEnum = {
  hardPull: 'CTCRED000',
  softPull: 'CTCRED0PQ',
};

interface PullCredit {
  success: boolean;
  message?: string;
}

interface Props {
  email: string;
  loanNumber: string;
  application: string;
  verbalAuthorization: boolean;
  pullType: string;
  inquiryType: string;
  loading: boolean;
  verbalAuthorizationNeeded: boolean;
  missingOrLoading: Set<boolean>;
  latestCompleteCreditPullRef: React.MutableRefObject<CoreUtils.CreditPull>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setActionListSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  togglePullCreditMenu: () => void;
}

/**
 * Submit button sub component.
 */
export default function SubmitButton(props: Props) {
  const location = useLocation();
  const { t } = useTranslation();

  /**
   * Determines if the submit button is disabled.
   */
  function getSubmitButtonDisabled(): boolean {
    if (props.missingOrLoading.has(true)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Updates the verbal authorization of the borrowers on the application. If
   * they do not have this flag set to true the credit pull will fail.
   */
  async function updateVerbalAuthorization(): Promise<boolean> {
    const log = { function: updateVerbalAuthorization.name };
    console.info({ ...log, message: 'updating verbal authorization' });

    const app = JSON.parse(props.application);
    const borrowerNumbers = [app.borrowerNumber, app.coBorrowerNumber];

    for (const borrowerNumber of borrowerNumbers) {
      if (!borrowerNumber) {
        continue;
      }

      // update borrower
      const data = {
        brwrAuthorizedPullCredit: true,
      };
      const borrower = await CoreUtils.updateBorrower(props.loanNumber, borrowerNumber, data);
      console.debug({ ...log, message: 'borrower declared', borrower });

      // check borrower
      if (!borrower) {
        console.warn({ ...log, message: 'borrower not updated' });
        return false;
      }
    }

    // continue
    console.info({ ...log, message: 'updated verbal authorization' });
    return true;
  }

  /**
   * Gets the pre qual flag for the credit pull.
   */
  function getPreQual() {
    if (props.pullType === Form.pullTypeEnum.reIssue) {
      // if previous pull and they picked re issue

      // check the credit vendor used
      if (props.latestCompleteCreditPullRef.current.creditCompany === creditVendorEnum.hardPull) {
        return false;
      } else {
        return true;
      }
    } else {
      // if new submit

      // check credit vendor selected
      if (props.inquiryType === Form.inquiryTypeEnum.hardPull) {
        return false;
      } else {
        return true;
      }
    }
  }

  /**
   * Gets the user id of the primary participant.
   */
  async function getUserId(): Promise<string | null> {
    const log = { function: getUserId.name };
    console.info({ ...log, message: 'getting user id' });

    // get who is user
    const whoIsUser = await CoreUtils.getUserByEmail(props.email);
    console.debug({ ...log, message: 'who is user declared', whoIsUser });

    // check who is user
    if (!whoIsUser) {
      console.warn({ ...log, message: 'whoIsUser not found' });
      return null;
    } else if (!whoIsUser.ID) {
      console.warn({ ...log, message: 'whoIsUser.ID not found' });
      return null;
    }

    // continue
    console.info({ ...log, message: 'got user id' });
    return whoIsUser.ID;
  }

  /**
   * Pulls credit.
   */
  async function pullCredit(): Promise<PullCredit> {
    const log = { function: pullCredit.name };
    console.info({ ...log, message: 'pulling credit' });

    // get user id
    const userId = await getUserId();
    console.debug({ ...log, message: 'user id declared', userId });

    // check user id
    if (!userId) {
      console.warn({ ...log, message: 'userId not found' });
      return { success: false, message: t('creditPullFailed') };
    }

    // get application
    const app = JSON.parse(props.application);

    // get credit pull
    const creditPull = await CoreUtils.createCreditPull(
      props.loanNumber,
      app.applicationNumber,
      props.pullType,
      getPreQual(),
      userId
    );
    console.debug({ ...log, message: 'credit pull declared', creditPull });

    // check res
    if (creditPull.status === runStatusCompleted) {
      console.info({ ...log, message: 'pulled credit' });
      return { success: true };
    } else if (creditPull.error === 'The credit report failed to generate: Your account is not set up correctly.') {
      const msg = `${t('creditPullAccountNotConfigured')}, ${t('email')}: ${props.email}, ID: ${userId}`;
      console.warn({ ...log, message: msg });
      return { success: false, message: msg };
    } else {
      console.warn({ ...log, message: 'credit pull not found' });
      return { success: false, message: t('creditPullFailed') };
    }
  }

  let currentTime = new Date()
  const adjustedTime = (currentTime.getTime() - 2000) / 1000

  async function getDocumentId(): Promise<string> {
    const log = { function: getDocumentId.name };
    let documentId;
    const maxIterations = 12;
    const iterationInterval = 5000; // Interval in milliseconds
  
    for (let iteration = 0; iteration < maxIterations; iteration++) {
  
      const loanDocuments = await CoreUtils.getLoanDocuments(props.loanNumber);
      console.debug({ ...log, message: 'loan documents declared', loanDocuments });
  
      // check loan documents and update documentId
      if (loanDocuments && Array.isArray(loanDocuments)) {
        if (loanDocuments[0].dateCreated > adjustedTime) {
          documentId = loanDocuments[0].documentId;
          console.info({ ...log, message: `Got the newest document! Doc ID: ${documentId}` });
          break;
        }
      }
  
      // Wait before next iteration
      await new Promise(resolve => setTimeout(resolve, iterationInterval));
    }
  
    if (!documentId) {
      console.debug({ ...log, message: 'Exceeded maximum iterations' });
    }
  
    return documentId;
  }
  

  /**
   * Gets the message in a formatted way.
   */
  function getMessage(docId): string {
    // get application
    const app = JSON.parse(props.application);

    const message = [
      'Credit has been pulled using the following information:',
      `Loan Number: ${props.loanNumber}`,
      `Application: ${app.applicationNumber} - ${app.namesForTitle}`,
    ];

    if (props.pullType === Form.pullTypeEnum.newSubmit) {
      message.push('Pull Type: New Submit');
    } else {
      message.push('Pull Type: Re-Issue');
    }

    if (getPreQual()) {
      message.push(`Inquiry Type: ${Form.inquiryTypeEnum.softPull}`);
    } else {
      message.push(`Inquiry Type: ${Form.inquiryTypeEnum.hardPull}`);
    }

    if (docId){
      message.push(`The resulting credit report document ID is: ${docId}`)
    }

    return message.join('\n');
  }

  /**
   * Sends the message after the credit pull is complete.
   */
  async function sendCreditPulledMessage(): Promise<boolean> {
    const log = { function: sendCreditPulledMessage.name };
    console.info({ ...log, message: 'sending credit pulled message' });

    // get operator id
    const operatorId = operatorStorage.getOperatorId();
    console.debug({ ...log, message: 'operator id declared', operatorId });

    // check operator id
    if (!operatorId) {
      console.warn({ ...log, message: 'operator id not found' });
      return false;
    }
    const documentId = await getDocumentId();

    // create message
    const data = {
      sender: operatorId,
      senderType: 'Operator',
      message: getMessage(documentId),
      accessType: "public"
    };
    const creditPulledMessage = await CoreUtils.createMessage(location.state.chat, data);
    console.debug({ ...log, message: 'credit pulled message declared', creditPulledMessage });

    // check credit pulled message
    if (!creditPulledMessage) {
      console.warn({ ...log, message: 'credit pulled message not sent' });
      return false;
    }

    // continue
    console.info({ ...log, message: 'sending credit pulled message' });
    return true;
  }

  /**
   * Handles the submit button. Pulls the credit using the given information.
   * Also sends a message in the chat and emails the user.
   */
  async function handleSubmit() {
    const log = { function: handleSubmit.name };
    console.info({ ...log, message: 'pulling credit' });
    props.setLoading(true);

    // update verbal authorization if needed
    if (props.verbalAuthorization) {
      const success = await updateVerbalAuthorization();

      if (!success) {
        props.setSnackbarMessage(t('borrowerVerbalAuthorizationNotUpdated'));
        props.setLoading(false);
        console.warn({ ...log, message: 'verbal authorization not updated' });
        return;
      }
    }

    // pull credit
    const pullCreditValue = await pullCredit();

    if (!pullCreditValue.success) {
      props.setSnackbarMessage(pullCreditValue.message);
      props.setLoading(false);
      console.warn({ ...log, message: 'credit pull failed' });
      return;
    }

    // send message
    const sendCreditPulledMessageSuccess = await sendCreditPulledMessage();

    if (!sendCreditPulledMessageSuccess) {
      props.setSnackbarMessage(t('creditPullSuccessMessageNotSent'));
      props.setLoading(false);
      console.warn({ ...log, message: 'credit pull success message not sent' });
      return;
    }

    // continue
    props.setActionListSnackbarMessage(t('creditPullSuccessful'));
    props.setLoading(false);
    console.info({ ...log, message: 'credit pull pulled' });
    props.togglePullCreditMenu();
  }

  const submitButtonDisabled = getSubmitButtonDisabled();

  return (
    <button
      className={PullCreditMenu.getClassName([SubmitButtonStyles.submitButton], submitButtonDisabled)}
      onClick={handleSubmit}
      disabled={submitButtonDisabled}
    >
      {t('submit')}
    </button>
  );
}
