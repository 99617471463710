import React from 'react'
import { Box, styled } from '@mui/system';

const Icon = styled(Box)`
  width: 35px;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  position: absolute;
  top: 0;
`

export default Icon;
