import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MorganTheme } from '@mui/material';

type TStyleProps = {
  theme?: MorganTheme; // NOTICE: Included by default with mui styled
  isLongerDate?: boolean;
};

export const ReactCalendarStyles = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isLongerDate',
})(
  ({ isLongerDate, theme}: TStyleProps ) => `

.react-calendar {
  border: none;
  margin: auto;
  padding-top: 10px;
  font-family: 'Poppins', sans-serif;
  height: 
    ${useMediaQuery(theme.breakpoints.up('md')) ? 
      '240px;' : isLongerDate ? '459px;' : '355px;'
    }
  width: ${useMediaQuery(theme.breakpoints.up('md')) ? 'fit-content;' : '300px;'}
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.react-calendar__navigation button {
  color: var(--orange900);
  min-width: 44px;
  background: var(--gray030);
  font-size: 16px;
  margin-top: 8px;
  border: none;
  font-weight: bold;
}

.react-calendar__navigation {
  background-color: var(--gray030);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 7px;
  margin-bottom: 10px;
  height: ${useMediaQuery(theme.breakpoints.up('md')) ? 'fit-content;' : '61px;'}
  width: 100%;
}

.react-calendar__navigation button[disabled] {
  border-radius: 8px;
}

.react-calendar__viewContainer {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__year-view,
.react-calendar__decade-view,
.react-calendar__century-view{
  justify-content: center;
  display: flex;
  height: 100%;
}

.react-calendar__tile {
  background: var(--gray000);
  border: none;
  padding: ${useMediaQuery(theme.breakpoints.up('md')) ? '6px;' : '15px;'}
  color: var(--orange900);
}

.react-calendar__tile[disabled]{
  color: gray;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--gray000);
  border-radius: 8px;
}

.react-calendar__navigation button[disabled] {
  background-color: var(--gray000);
}
abbr[title] {
  text-decoration: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: var(--gray030);
  color: var(--orange900);
  border-radius: 25px;
}
.react-calendar__tile--now {
  background: var(--gray030);
  border-radius: 25px;
  font-weight: bold;
  color: var(--orange900);
  border: none;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 25px;
  font-weight: bold;
  color: var(--orange900);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--gray000);
}
.react-calendar__tile--active {
  background: var(--orange900);
  border-radius: 25px;
  font-weight: bold;
  color: var(--gray000);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--orange900);
  color: var(--gray000);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--gray000);
}
.react-calendar__tile--range {
  background: var(--gray000);
  color: var(--orange900);
  border-radius: 25px;
}
.react-calendar__tile--rangeStart {
  border-radius: 25px;
  background: var(--orange900);
  color: var(--gray000);
}
.react-calendar__tile--rangeEnd {
  border-radius: 25px;
  background: var(--orange900);
  color: var(--gray000);
}
  
`
);
