import LanguageUtils from '@common/LanguageUtils';
import { ValidatorFunctions } from '@common/Validators';
import { fetchWorkflows } from './api';
import { TWorkflowDataFetched_UPmenu, TWorkflowData_UPmenu } from './types';
import { getDisplayNameFrom, parseFetchedWorkflowsData, removeQuotesAndSpaces } from './utils';

export type TLang = 'es' | 'ko';

class CTranslationModule {
  _translations: Map<string, string> = new Map();

  _set(key: string, data: string): void {
    this._translations.set(key, data);
  }

  _clear(key: string): boolean {
    return this._translations.delete(key);
  }

  get(key: string): string | undefined {
    return this._translations.get(key);
  }

  _prepareWfArray = (wfArr: TWorkflowData_UPmenu[]) =>
    wfArr.map((data) => ({ message: data?.name?.en ?? '', source: 'en' }));

  getTranslations = async (wfArr: TWorkflowData_UPmenu[], lang, abortController): Promise<string[]> => {
    const preparedArr = this._prepareWfArray(wfArr);
    return await LanguageUtils.translateMany(preparedArr, lang, abortController);
  };

  getWfKey(wf: TWorkflowDataFetched_UPmenu | TWorkflowData_UPmenu) {
    return removeQuotesAndSpaces(getDisplayNameFrom(wf));
  }

  processLanguage = async (abortController, targetLang: TLang) => {
    // workflows data
    let wfDataArr;
    try {
      wfDataArr = await fetchWorkflows(abortController);
    } catch (error) {
      console.error('Error during fetchWorkflows call in processLanguage method! Error:', error);
    }

    try {
      if (
        ValidatorFunctions.isUndefinedOrNull(wfDataArr) === true ||
        ValidatorFunctions.isTypeOfArray(wfDataArr) === false ||
        wfDataArr.length === 0
      )
        return;

      const parsedWfArr = parseFetchedWorkflowsData(wfDataArr);
      const resTranslations = await this.getTranslations(parsedWfArr, targetLang, abortController);
      // check length to be the same
      if (parsedWfArr.length !== resTranslations.length) {
        console.error('fetched translations and wfArray are not equal in length!');
        return;
      }
      // save to translations storage.
      for (let i = 0; i < parsedWfArr.length; i++) {
        const currWf = parsedWfArr[i];
        this._set(this.getWfKey(currWf), resTranslations[i]);
      }
    } catch (error) {
      console.error(
        'Error during parseFetchedWorkflowsData or getTranslations call in processLanguage method! Error:',
        error
      );
    }
  };
}

const UPM_TranslationModule = new CTranslationModule();

export default UPM_TranslationModule;
