import { isMobile } from '@common/deviceTypeHelper';

export const DEFAULT_CLASS_LIST = ['wrapper']; // default wrapper class for the swipable element from the lib
export const HIDDEN_ACTIVITY_CLASS = 'hidden'; // class for making the activity hidden
export const VISIBLE_ACTIVITY_CLASS = 'visible'; // class for making the activity visible

// Make sure the initial activity classlist has hidden
// that's because all elements should be hidden at the start
export const INITIAL_CLASS_LIST = [...DEFAULT_CLASS_LIST, HIDDEN_ACTIVITY_CLASS];
export const MEASURING_CLASS_LIST = [...DEFAULT_CLASS_LIST, VISIBLE_ACTIVITY_CLASS];

// For better readability in handleActivityChange calls.
export const WITHOUT_NAVIGATION = false;
export const REVEAL = true;
export const HIDE = false;
export const REVEAL_ANIMATION_TIMEOUT = 600;
export const CHECK_DELAYED_TIMEOUT = 600;
export const HIDE_ANIMATION_TIMEOUT = 1000;
export const NAVIGATION_TIMEOUT = 200;

const _IS_MOBILE = isMobile();
export const swipeableTrackMouseValue = _IS_MOBILE === true ? false : true;
// Calculate Style values constants (desktop / mobile)
/**
 * CONSTANTS is arr of constants.
 * Using iffie for better mem handling.
 */
export const CONSTANTS = (function () {
  let PRE_VISIBLE_ELEMENT_PADDING_CSS_VALUE = 0;
  let PRE_MAX_WIDTH = 0;
  let PRE_ACTIVITY_WRAPPER_HEIGHT = 0;
  let PRE_ACTIVITY_FONT_SIZE = 0;
  let PRE_MAX_LETTERS_IN_SINGLE_ACTIVITY = 0;
  let PRE_MAX_SCREEN_DIMS = 5;
  // Mobile style values
  if (_IS_MOBILE === true) {
    PRE_ACTIVITY_WRAPPER_HEIGHT = 25;
    PRE_VISIBLE_ELEMENT_PADDING_CSS_VALUE = 5;
    PRE_MAX_WIDTH = 131;
    PRE_ACTIVITY_FONT_SIZE = 13;
    PRE_MAX_LETTERS_IN_SINGLE_ACTIVITY = 12;
  }
  // Desktop style values
  else {
    PRE_ACTIVITY_WRAPPER_HEIGHT = 29;
    PRE_VISIBLE_ELEMENT_PADDING_CSS_VALUE = 5;
    PRE_MAX_WIDTH = 178;
    PRE_ACTIVITY_FONT_SIZE = 14;
    PRE_MAX_LETTERS_IN_SINGLE_ACTIVITY = 17;
  }

  return {
    VISIBLE_ELEMENT_PADDING_CSS_VALUE: PRE_VISIBLE_ELEMENT_PADDING_CSS_VALUE,
    MAX_WIDTH: PRE_MAX_WIDTH,
    ACTIVITY_WRAPPER_HEIGHT: PRE_ACTIVITY_WRAPPER_HEIGHT,
    ACTIVITY_FONT_SIZE: PRE_ACTIVITY_FONT_SIZE,
    MAX_LETTERS_IN_SINGLE_ACTIVITY: PRE_MAX_LETTERS_IN_SINGLE_ACTIVITY,
    MAX_SCREEN_DIMS: PRE_MAX_SCREEN_DIMS,
  } as const;
})();

const mobileFontSizeVal = '18px';

export const nonDraggableStyle = {
  userDrag: 'none',
  userSelect: 'none', // Optional: Prevent text selection on the image
  WebkitUserDrag: 'none',
  WebkitUserSelect: 'none', // Optional: Prevent text selection on the image
};

export const IconStyles = {
  default: { fontSize: _IS_MOBILE ? mobileFontSizeVal : '1.3em' },
  auth: { fontSize: _IS_MOBILE ? mobileFontSizeVal : '1.5em' },
  privateMode: { width: '18px' },
  svg: {
    filter:
      'brightness(0) saturate(100%) invert(81%) sepia(63%) saturate(2759%) hue-rotate(328deg) brightness(93%) contrast(90%)',
    fontSize: isMobile() ? '18px' : '1.3em',
    width: '1em',
    height: '1em',
    ...nonDraggableStyle
  }
};
