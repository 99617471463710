// @ts-check

import { Box, Card, CardContent } from '@mui/material';
import React, {  useRef } from 'react';
import GenericSnackbar from './GenericSnackbar';
import SlotMachinePrizes from './SlotMachinePrizes'

const PrizeRates = () => {

    const snackbarRef = useRef(/** @type {any} */(null));

    return (
        <>
            <GenericSnackbar ref={snackbarRef} />
            <Box
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card sx={{ width: '100%' }}>
                    <CardContent>
                        <SlotMachinePrizes />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default PrizeRates;