import React, { useCallback, useEffect, useState } from 'react';
import { getUserPlayedToday } from '@storage/userStorage';
import axiosCall from '@services/axios';
import Sanitized from '@components/Sanitized';
import { uiTranslationController } from '@common/common';
import { IErrand, IMessage } from '@interfaces/Conversation';
import MessageSender from '../MessageSender';
import { PlayButton } from '@assets/Icons';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import styles from '@styles/MessageContent/SlotMessageContent.module.css';
import { useRootContext } from '@contexts/RootContext';
import MessageTime from '../MessageTime';
import tStyles from '@styles/Typography.module.css';
import useInitialMount from '@common/hooks/useInitialMount';
import { MorphType } from '@common/MorphType';
import { useErrandContext } from '@contexts/ErrandContext';
import { shouldPreventPlaySlotMachine } from '@common/SlotMachineUtils';
import { useTranslation } from 'react-i18next';
const Cap = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Cap.svg';
const TShirtPolo = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/TShirtPolo.svg';
const TShirt = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/TShirt.svg';
const GiftBox = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/GiftBox.svg';
const Coffee = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Coffee.svg';
const DiwaliTickets = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/DiwaliTickets.svg';
const MindsetTickets = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/MindsetTickets.svg';

type TSlotMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const SlotMessageContent = ({
  errand, message,
}: TSlotMessageContentProps) => {
  const rootContext = useRootContext();
  const { dispMessage } = uiTranslationController(message || '');
  const [messageText, setMessageText] = useState(null);
  const [prize, setPrize] = useState(null);
  const errandContext = useErrandContext();
  const { t } = useTranslation();

  /* On click handler for clicking an action icon */
  const handleClick = useCallback(async (e) => {
    e?.preventDefault();
    try {
      // We first check to make sure the Slot Machine useraction is not resolved,
      // so the user cannot play more than once per message sent by operator
      // It is important to note we are not checking hasPlayedToday and returning, but hitting Core, because if they have played, we must have Core send the message into the chat.
      const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';
      let { preventPlay } = await shouldPreventPlaySlotMachine(errand, message?.action, timeZone)

      if (!preventPlay) {
        rootContext.triggerSlotMachine(errand._id, message.userAction?._id);
      } else {
        return;
      }
    } catch (e) {
      console.error(`Error checking for played Slot Machine games: ${e}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errand?._id, message?.action?._id, message?.userAction?._id, rootContext.triggerSlotMachine]);

  const getPrizeIcon = {
    'Coffee': () => { return (<img src={Coffee} alt='Coffee' className={styles.slotDialogImg} />) },
    'Gift Box': () => { return (<img src={GiftBox} alt='GiftBox' className={styles.slotDialogImgCenter} />) },
    'T-Shirt': () => { return (<img src={TShirt} alt='TShirt' className={styles.slotDialogImgCenter} />) },
    'Polo Shirt': () => { return (<img src={TShirtPolo} alt='Polo' className={styles.slotDialogImgCenter} />) },
    'Cap': () => { return (<img src={Cap} alt='Cap' className={styles.slotDialogImgCap} />) },
    'Diwali Party ticket': () => { return (<img src={DiwaliTickets} alt='Diwali' className={styles.slotDialogImgCap} />) },
    'Mindset Summit ticket': () => { return (<img src={MindsetTickets} alt='MindsetSummit' className={styles.slotDialogImgCap} />) },
    'Russell Peters T-Shirt': () => { return (<img src={TShirt} alt='RussellPeters' className={styles.slotDialogImgCap} />) },
  }

  const parsePrize = useCallback(() => {
    let messageText = dispMessage?.split('<>')?.[0];
    let prize = message?.message?.split('<>')?.[1];

    if (messageText) {
      setMessageText(messageText)
      setPrize(prize);
    }

    if (errandContext.morphType === MorphType.SlotMachine && getUserPlayedToday()) {
      errandContext.setMorphType(MorphType.None);
    }

  }, [dispMessage]);

  useEffect(() => {
    parsePrize();
  }, [dispMessage, parsePrize]);

  useInitialMount(parsePrize);

  return (
    <div
      className={styles.slotDialog}
      onClick={(e) => handleClick(e)}
    >
      <div className={message.operatorView ? styles.messageSenderContainerOperator : styles.messageSenderContainer}>
        <MessageSender
          errand={errand}
          message={message}
        />
        <div className={styles.container}>
          <Sanitized
            className={tStyles.base}
            html={!!prize ? messageText : dispMessage}
            visible={message.visible}
            tag="p"
          />
          <MessageTime message={message} />
        </div>
      </div>
      <div className={message.operatorView ? styles.slotMachineDialogButtonContainerOperator : styles.slotMachineDialogButtonContainer}>
        {(!prize && getUserPlayedToday()) || (!!prize && prize !== 'No Prize') ?
          <div className={!!prize && prize !== 'No Prize' ? styles.slotDialogIconButton : styles.slotDialogIconButtonDisabled}>
            {!!prize && prize !== 'No Prize' ?
              getPrizeIcon[prize]()
              :
              getUserPlayedToday() && (24 - new Date().getHours()) > 0 ?
                <span className={styles.timerText}>
                  TIMER
                  <span className={styles.timer}>{24 - new Date().getHours()}</span>
                  HOURS</span>
                :
                typeof message?.icon === 'string' && message?.icon ? (
                  <img
                    className={styles.slotDialogImgDisabled}
                    src={message?.icon}
                    alt={`Action Icon`}
                  />
                ) : (
                  <QuestionMarkIcon className={styles.slotDialogImgAlt} />
                )
            }
          </div>
          :
          <div className={!prize ? styles.slotDialogIconButton : styles.slotDialogIconButtonDisabled}>
            {getUserPlayedToday() && (24 - new Date().getHours()) > 0 ?
              <span className={styles.timerText}>
                TIMER
                <span className={styles.timer}>{24 - new Date().getHours()}</span>
                HOURS</span>
              :
              typeof message?.icon === 'string' && message?.icon ? (
                <img
                  className={!prize ? styles.slotDialogImg : styles.slotDialogImgDisabled}
                  src={message?.icon}
                  alt={`Action Icon`}
                />
              ) : (
                <QuestionMarkIcon className={styles.slotDialogImgAlt} />
              )
            }
          </div>
        }
        {!!prize ?
          prize !== 'No Prize' ?
            <span className={styles.winMessage}>{t('youWon')}</span> :
            <span className={styles.winMessage}>{t('gameLimitReached')}</span> :
          getUserPlayedToday() ?
            <span className={styles.winMessage}>Game Limit Reached</span> :
            <div className={styles.slotMachineDialogPlayButtonWrapper}>
              <div className={styles.slotMachineDialogPlayButton}>
                <PlayButton className={styles.slotPlayButton} />
              </div>
            </div>
        }
      </div>
    </div>
  );
};

export default SlotMessageContent;
