import React from 'react';
import themeFile from '@assets/Themes/default.json';
import { styled } from '@mui/system';
import ShimmerEffect from './ShimmerEffect';

function UserBadgeSkeleton() {

    const UserBox = styled('div')({
        height: '100%',
        width: '100%',
        backgroundColor: 'var(--gray000)'
    })

    const BadgeContainer = styled('div')({
        position: 'relative',
        display: 'flex',
        alignItems: 'start',
        height: '50%', //100%
        width: '100%',
        overflow: 'hidden',
        padding: '5px',
    })

    const PlaceholderBadge = styled('div')({
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        minHeight: '110px',
        width: '100%',
        backgroundColor: themeFile.palette.gray['100'],
        borderRadius: '8px'
    })

    // The left side holds the circle profile icon
    const BadgeLeft = styled('div')({
        display: 'flex',
        alignItems: 'start',
        backgroundColor: themeFile.palette.gray['100'],
        height: '110px',
        width: '89px',
        borderRadius: '8px'
    })

    // This is the skeleton for the circle profile icon
    const CircleIconSkeleton = styled('div')({
        minHeight: '40px',
        minWidth: '40px',
        backgroundColor: 'var(--gray000)',
        borderRadius: '50%',
        margin: '8px 2px 2px 5px'

    })

    // The middle holds the name of the user, the wait time, and the attend button
    const BadgeMiddle = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeFile.palette.gray['100'],
        height: '100px',
        width: '100%',
        marginLeft: '3px'
    })

    // This is the skeleton for the user's name
    const NameSkeleton = styled('div')({
        height: '20px',
        width: '70%',
        borderRadius: '8px',
        backgroundColor: 'var(--gray000)',
        marginTop: '7px'
    })

    // This is the skeleton for the wait time
    const WaitSkeleton = styled('div')({
        height: '15px',
        width: '85%',
        borderRadius: '8px',
        backgroundColor: 'var(--gray000)',
        marginTop: '15px'
    })

    // This is the skeleton for the attend button
    const AttendSkeleton = styled('div')({
        height: '29px',
        width: '79%',
        borderRadius: '16px',
        backgroundColor: 'var(--gray000)',
        marginTop: '5px'
    })


    // The right side holds the date and time
    const BadgeRight = styled('div')({
        display: 'flex',
        alignItems: 'end',
        flexDirection: 'column',
        justifyContent: 'start',
        backgroundColor: themeFile.palette.gray['100'],
        height: '100px',
        width: '194px',
    })

    //This is the skeleton for the date
    const DateSkeleton = styled('div')({
        height: '15px',
        width: '100%',
        borderRadius: '8px',
        backgroundColor: 'var(--gray000)',
        marginTop: '10px',
        marginRight: '3px'
    })

        //This is the skeleton for the time
        const TimeSkeleton = styled('div')({
            height: '15px',
            width: '50%',
            borderRadius: '8px',
            backgroundColor: 'var(--gray000)',
            marginTop: '15px',
            marginRight: '3px'
        })

    return (
        <UserBox>
        <BadgeContainer>
            <PlaceholderBadge>
                <BadgeLeft>
                    <CircleIconSkeleton />
                </BadgeLeft>
                <BadgeMiddle>
                    <NameSkeleton />
                    <WaitSkeleton />
                    <AttendSkeleton />
                </BadgeMiddle>
                <BadgeRight>
                    <DateSkeleton />
                    <TimeSkeleton />
                </BadgeRight>
            </PlaceholderBadge>
            <ShimmerEffect color='var(--shadow401)' width='50%' position='absolute'/>
        </BadgeContainer>
        </UserBox>
    )
}

export default UserBadgeSkeleton