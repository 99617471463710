import React, { FC } from 'react';
import { DialogTitle, IconButton, Stack, Typography } from '@mui/material';

import { Close } from '../Assets/Icons';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DialogPropsWithChildren } from '@mTypes/Console';

const DialogHeader: FC<DialogPropsWithChildren> = (props) => {
  return (
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={1}>
          <Typography variant="h6">{props.title}</Typography>
          {
            props.onRefresh &&
            <IconButton size="small" onClick={() => props.onRefresh()}>
              <RefreshIcon />
            </IconButton>
          }
        </Stack>
        <IconButton onClick={() => props.onClose()} >
          <Close
            style={{ width: '16px', height: '16px', cursor: 'pointer' }}
            color="var(--gray210)"
          />
        </IconButton>
      </Stack>
    </DialogTitle>
  );
};

export default DialogHeader;
