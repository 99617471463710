import { styled } from '@mui/material/styles';

export const GlobeContainer = styled('div', {
})(() => `
  canvas {
    border-radius: 50%;
  }

  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--orange410);
  }

  .country-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .country-container img {
    width: 160px;
    height: 85px;
    border-radius: 7px;
    border: 3px solid var(--orange410);
    cursor: pointer;
  }

  .countries-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translate(-50%, -50%);
  }

  .globe{
    position: relative;
    top: -52px;
  }

  @media(max-width: 620px){
    .globeLoader{
      width: 90% !important;
      height: 90% !important;
      position: relative;
      right: 7px;
      top: -7px;
    }
  }

  @media(max-width: 500px){
    .globeLoader{
      width: 87% !important;
      height: 87% !important;
      position: relative;
      right: 17px;
      top: -7px;
    }
  }

  @media(max-width: 400px){
    .globeLoader{
      width: 78% !important;
      height: 78% !important;
      position: relative;
      right: 17px;
      top: -15px;
    }
  }
}

  `);
