import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses
} from '@mui/material';
import React, { useState, useDeferredValue, useCallback } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditWorkflowDialog from './EditWorkflowDialog';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import StandardTablePagination from './StandardTablePagination';
import TableHeaderBar from './TableHeaderBar';
import ViewStepsDialog from './ViewStepsDialog';
import downloadWorkflowReport from './DownloadWorkflowReport';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import DialogHeader from './DialogHeader';
import getImageSource from '@common/getImageSource';
import useControllerCallback from '@common/hooks/useControllerCallback'
import useInitialMount from '@common/hooks/useInitialMount'

const Workflows = (props) => {
  const { t } = useTranslation();
  const [editWorkflowState, setEditWorkflowState] = useState({
    edit: false,
    open: false,
    data: null,
  });
  const [viewStepsState, setViewStepsState] = useState({ open: false, data: null });
  const [actionData, setActionData] = useState([]);
  const [fieldAttributes, setFieldAttributes] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [search, setSearch] = useState('');
  const deferredSearch = useDeferredValue(search);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [errorMessage, setErrorMessage] = useState('');
  const [getActive, setGetActive] = useState(true);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [workflowCount, setWorkflowCount] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  //adjust the styling based on if we are in the console view
  const isConsoleView = window.location.pathname.indexOf('/console') !== -1;

  const StyledTableCell = styled(TableCell)(({ active }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: isConsoleView && 'var(--gray010)',
      align: 'left',
    },
    [`&.${tableCellClasses.root}`]: {
      borderBottom: isConsoleView && '1px solid var(--gray010)',
      padding: '4px 8px',
    },
  }));

  const OverflowBox = styled(Box)(({ mymaxwidth }) => ({
    display: 'block',
    maxWidth: `${mymaxwidth}`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }));
  const loadWorkflowData = useCallback(async (controller) => {
    setLoading(true);
    // Load one more row than required to test if more data exists
    const offset = page < 0 ? 0 : page * rowsPerPage;
    const limit = rowsPerPage > 0 ? `&limit=${rowsPerPage + 1}` : '';
    const active = getActive ? '&active=1' : '';
    let data;
    if (deferredSearch.length > 0) {
      data = { search: deferredSearch };
    } else {
      data = "";
    }

    let request = {
      url: `workflow/db/search?order=asc&orderBy=name&offset=${offset}${limit}${active}`,
      method: 'POST',
      data: data,
    };
    let config = controller
      ? {
          signal: controller.signal,
        }
      : {};

    try {
      const result = await axiosCall(request, config);

      if (result?.length > 0) {
        // See if we were able to fetch the one additional row, therefore indicating more data is available
        setIsMoreDataAvailable(rowsPerPage > 0 ? result?.length > rowsPerPage : false);
        setDataToDisplay(rowsPerPage > 0 ? result?.slice(0, rowsPerPage) : result);
        setLoading(false);
        try {
          const a = active ? "active=true" : ""
          const result = await axiosCall({ url: `workflow/count?${a}` ,method: 'GET', });
          if (result) {
            setWorkflowCount(result)
          }
        } catch (error) {
          console.log(error);
        }
        setNoResults(false);
      } else {
        setNoResults(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  },[page, rowsPerPage, getActive, deferredSearch]);

  const loadActionData = async () => {
    try {
      const result = await axiosCall({ url: `action/?order=asc&orderBy=description&active=1` });
      if (result) {
        setActionData(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadFieldAttributesData = async () => {
    try {
      const result = await axiosCall({ url: `fieldAttribute` });
      if (result) {
        setFieldAttributes(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadWorkflowReport = async () => {
    try {
      const result = await axiosCall({ url: `workflow/?order=asc&orderBy=name` });
      if (result) {
        downloadWorkflowReport(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*************************************
   * Add/Edit Workflow Dialog Functions
   *************************************/
  const handleAddWorkflowOpen = () => {
    setEditWorkflowState({ edit: false, open: true, data: null });
  };

  const importWorkflow = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.workflow';
    input.onchange = async () => {
      try {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = async () => {
          const data = JSON.parse(reader.result);
          let request = {
            url: `workflow/import`,
            method: 'POST',
            data,
          };
          try{
            await axiosCall(request);
            loadWorkflowData();
          }catch(error){
            if (error.message.includes('status code 500')) {
              setShowErrorModal(true);
            }
          }
        };
        reader.readAsText(file);
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };
    input.click();
  }

  const handleEditWorkflowOpen = (workflow) => {
    const data = {
      name: workflow.name,
      displayName: workflow.displayName,
      description: workflow.description,
      id: workflow?._id,
      actions: workflow.actions?.map((actionItem, index) => {
        return {
          action: actionItem?._id,
          stepNumber: index + 1,
          stepId: Math.round(+new Date() / 1000) + index - 5000,
        };
      }),
      visibility: workflow.visibility,
      workflowIcon: workflow.workflowIcon,
      jsonRules: workflow.jsonRules
    };
    setEditWorkflowState({ edit: true, open: true, data: data });
    setErrorMessage('');
  };

  const handleEditWorkflowClose = () => {
    setEditWorkflowState({ open: false, data: null });
    setErrorMessage('');
  };

  const handleEditWorkflowSubmit = async (data) => {
    if (await editWorkflow(data)) {
      setEditWorkflowState({ open: false, data: null });
      setErrorMessage('');
    }
  };

  const handleAddWorkflowSubmit = async (data) => {
    if (await addWorkflow(data)) {
      setEditWorkflowState({ open: false, data: null });
      setErrorMessage('');
    }
  };

  const editWorkflow = async (data) => {
    const { id, ...sendData } = data;
    try {
      let result = await axiosCall({
        url: `workflow/${id}`,
        method: 'PUT',
        data: sendData,
      });

      if (result) {
        await loadWorkflowData();
        return true;
      }
    } catch (error) {
      setErrorMessage(getErrorMessage(error.message) || 'Error');
      return false;
    }
  };

  const addWorkflow = async (data) => {
    try {
      let result = await axiosCall({
        url: `workflow`,
        method: 'POST',
        data: data,
      });

      if (result) {
        await loadWorkflowData();
        return true;
      }
    } catch (error) {
      setErrorMessage(getErrorMessage(error.message) || 'Error');
      return false;
    }
  };

  const getErrorMessage = (error) => {
    const errors = {
      'code 409': 'Error: Workflow with that name already exists.',
      'code 400': 'Error: request body is missing.',
      'code 422': 'Error: Unprocessable entity',
      'code 503': 'Error: Service Unavailable',
    };

    for (let code in errors) {
      if (error.includes(code)) return errors[code];
    }
}

const exportWorkflow = async (workflow) => {
  try {
    // let request = {
    //   url: `workflow/export`,
    //   method: 'POST',
    //   data: actionIds,
    // };
    // const response = await axiosCall(request);
    // console.log('response is', response)
    // if (!response?.length) throw new Error(`HTTP error! status: ${response.status}`);
    
    const exportData = {
      name: workflow.name,
      displayName: workflow.displayName,
      description: workflow.description,
      visibility: workflow.visibility,
      jsonRules: workflow.jsonRules,
    };

    const data = JSON.stringify(exportData)
    const blob = new Blob([data], {type : 'application/json'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${workflow.name.replaceAll(' ','-')}.workflow`;
    link.click();

  } catch (error) {
    console.error('An error occurred:', error);
  }
}

  /*************************************
   * View Steps Dialog Functions
   *************************************/
  const handleViewSteps = (data) => {
    setViewStepsState({ open: true, data: data.actions });
  };

  const handleViewStepsClose = () => {
    setViewStepsState({ open: false, data: null });
  };

  /*************************************
   * Delete Dialog Functions
   *************************************/
  const handleDelete = async (id) => {
    try {
      const result = await axiosCall({
        url: `workflow/${id}`,
        method: 'DELETE',
      });
      if (result) {
        await loadWorkflowData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowInactive = () => {
    //Invert this value
    setGetActive(!getActive);
    setPage(0);
  };

  const handleReactivate = async (id) => {
    try {
      let result = await axiosCall({
        url: `workflow/${id}`,
        method: 'PUT',
        data: {
          active: true,
        },
      });

      if (result) {
        await loadWorkflowData();
      }
    } catch (error) { }
  };

  /*************************************
   * Pagination Functions
   *************************************/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (newSearchValue) => {
    // We want to reset to the first page when searching so we don't miss any documents 
    // (for example, we don't want to begin our search on the second page 
    // as we would miss those on the first)
    setPage(0);
    // Now we can update the search value
    setSearch(newSearchValue);
  };

  useInitialMount(() => {
    loadActionData();
    loadFieldAttributesData();
  })

   // load initial data on mount
   useControllerCallback(loadWorkflowData);

  return (
    <Box display="flex" flexDirection="column" height={isConsoleView ? "calc(100vh - 175px)" : '50vh'} padding={isConsoleView && 1}>
      <Box display="flex" flexDirection="row" alignItems="center">
      <Button
        startIcon={<FileDownloadIcon />}
        sx={ { marginRight: '15px', width: '260px', bottom: '4px' }}
        variant="contained"
        color="success"
        onClick={() => {
          importWorkflow();
        }}
      >
        {t('importWorkflow')}
      </Button>
      <TableHeaderBar
        onAdd={handleAddWorkflowOpen}
        setSearch={handleSearchChange}
        setPage={setPage}
        onRefresh={loadWorkflowData}
        onExport={handleDownloadWorkflowReport}
        addLabel={t('addWorkflowLabel')}
        searchValue={search}
        showInactiveClick={handleShowInactive}
        isActive={getActive}
      />
      </Box>
      <TableContainer sx={{ border: isConsoleView && '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="50px"></StyledTableCell>
                    <StyledTableCell width="400px">{t('nameCol')}</StyledTableCell>
                    <StyledTableCell width="400px">{t('displayNameCol')}</StyledTableCell>
                    <StyledTableCell width="60%">{t('descriptionCol')}</StyledTableCell>
                    {/* <StyledTableCell width="60%">{t('keywordsTranslation')}</StyledTableCell> */}
                    {/* <StyledTableCell>{t('workflowSteps')}</StyledTableCell>
                    <StyledTableCell>{t('inlineCol')}</StyledTableCell> */}
                    <StyledTableCell>{t('aiVisibleCol')}</StyledTableCell>
                    <StyledTableCell>{t('operatorVisibleCol')}</StyledTableCell>
                    <StyledTableCell>{t('userVisibleCol')}</StyledTableCell>
                    <StyledTableCell width="160px">{t('actionsCol')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToDisplay?.map((workflow) => {
                    const active = workflow.active ? 1 : 0;
                    return (
                      <TableRow
                        sx={{ backgroundColor: active ? isConsoleView && 'var(--gray000)' : isConsoleView ?'var(--peach030)' : 'var(--red600)' }}
                        data-id={workflow?._id}
                        hover
                        key={workflow?._id}>
                        <StyledTableCell>
                          {workflow.workflowIcon && (
                            <Box
                              component="img"
                              style={{ filter: 'grayscale(100%)' }}
                              width="20px"
                              height="20px"
                              src={getImageSource(workflow.workflowIcon)}
                            />
                          )}
                        </StyledTableCell>
                        <StyledTableCell>{workflow.name}</StyledTableCell>
                        <StyledTableCell>{workflow.displayName}</StyledTableCell>
                        <StyledTableCell>{workflow.description}</StyledTableCell>
                        {/* <StyledTableCell>{workflow.keywords.join(", ")}</StyledTableCell> */}
                        {/* <StyledTableCell>
                          <Stack direction="row" alignItems="center">
                            {workflow.actions?.length || 0}
                            {workflow.actions?.length > 0 && (
                              <Tooltip title={t('viewStepsTooltip')} placement="left">
                                <VisibilityOutlinedIcon
                                  onClick={() => handleViewSteps(workflow)}
                                  fontSize="small"
                                  sx={{ cursor: 'pointer', paddingLeft: '3px', color: 'var(--blue210)' }}
                                />
                              </Tooltip>
                            )}
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox>{workflow.inline ? t('yes') : t('no')}</OverflowBox>
                        </StyledTableCell> */}
                        <StyledTableCell>
                          <OverflowBox>{workflow.visibility.ai ? t('yes') : t('no')}</OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox>{workflow.visibility.operator ? t('yes') : t('no')}</OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox>{workflow.visibility.user ? t('yes') : t('no')}</OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>
                        <Tooltip
                            title={t('exportWorkflow')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <FileUploadIcon
                              onClick={() => exportWorkflow(workflow)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'purple' }}
                            />
                          </Tooltip>
                          <Tooltip
                            title={t('editWorkflowDetails')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <BorderColorOutlinedIcon
                              onClick={() => handleEditWorkflowOpen(workflow)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'var(--blue210)' }}
                            />
                          </Tooltip>
                          {active ? (
                            <Tooltip
                              title={t('deleteWorkflow')}
                              placement="top-start"
                              enterDelay={1000}
                              enterNextDelay={1000}>
                              <DeleteOutlinedIcon
                                onClick={() => handleDelete(workflow?._id)}
                                fontSize="small"
                                sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t('reactivateWorkflow')}
                              placement="top-start"
                              enterDelay={1000}
                              enterNextDelay={1000}>
                              <RestoreFromTrashOutlinedIcon
                                onClick={() => handleReactivate(workflow?._id)}
                                fontSize="small"
                                sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                              />
                            </Tooltip>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      dataSize={workflowCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table >
            )}
          </>
        )}
      </TableContainer >
      {
        editWorkflowState.open && (
          <EditWorkflowDialog
            edit={editWorkflowState.edit}
            open={editWorkflowState.open}
            workflow={editWorkflowState.data}
            actions={actionData}
            error={errorMessage}
            onClose={handleEditWorkflowClose}
            onSubmit={handleEditWorkflowSubmit}
            onAdd={handleAddWorkflowSubmit}
          />
        )
      }
      {
        viewStepsState.open && (
          <ViewStepsDialog
            open={viewStepsState.open}
            steps={viewStepsState.data}
            fieldAttributes={fieldAttributes}
            onClose={handleViewStepsClose}
          />
        )
      }
      {
        showErrorModal && 
        <Dialog maxWidth="sm" open={showErrorModal} onClose={() => setShowErrorModal(false)}>
            <DialogHeader title={t('tError')} onClose={() => setShowErrorModal(false)} />
              <DialogContent> 
              <Stack height="150px" width="150px" marginTop="10px">
              <Typography>{t('sameNameError')}</Typography> 
                </Stack>
                <Button
                  sx={{ marginRight: '15px', whiteSpace: 'nowrap', width: '150px', bottom: '4px' }}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    setShowErrorModal(false);
                  }}
                >
                  {t('tOk')}
                </Button>
              </DialogContent>
          </Dialog>
      }
    </Box >
  );
};

export default Workflows;