import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteForeverOutlined } from '@mui/icons-material';

import { IErrand, IMessage } from '@interfaces/Conversation';
import axiosCall from '@services/axios';
import useAbortController from '@common/hooks/useAbortController';

import Styles from '@styles/MorphMessageOptions.module.css';

type TMorphMessageOptionDeleteProps = {
  errand: IErrand;
  handleClose: () => void;
  message: IMessage;
};

const MorphMessageOptionDelete = ({
  errand, handleClose, message
}: TMorphMessageOptionDeleteProps) => {
  const { t } = useTranslation();
  const abortController = useAbortController();

  const handleClickDelete = useCallback(async () => {
    try {
      // setup axios call with controller
      const request = {
        url: `chat/${errand?._id}/message/${message?._id}`,
        method: 'delete',
      };
      const controller = abortController.reset();
      const config = { signal: controller.signal };
      await axiosCall(request, config).catch((e) => console.error(e));

      // clean up
      handleClose();
    } catch (error) {
      console.error(`MorphMessageOptionDelete.handleClickDelete`, error);
    }
  }, [errand?._id, message?._id, handleClose]);

  return (
    <button className={Styles.btn} onClick={handleClickDelete}>
      <div className={Styles.btnBorder}>
        <DeleteForeverOutlined />
        <p className={Styles.label}>{t('tDelete')}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionDelete;
