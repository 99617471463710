import { Box, Card, CardContent, Stack, Typography, MorganTheme, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { monitorContextType } from '@components/Monitoring';
import React, { PropsWithChildren } from 'react';

const Block = styled(Box)(() => ({
    borderRadius: '0.4em',
    textAlign: 'center',
    display: 'inline-block',
  }));

const Header : React.FC<PropsWithChildren<any>> = (props : monitorContextType) => {
  const theme : MorganTheme = useTheme();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (
    <Card  variant="outlined" >
      <CardContent>
      <Stack direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }} spacing="2" justifyContent="space-between">
        <Box sx={{ display: "inline" }}>
          <ArrowBackIosIcon
            onClick={() => {
              navigate('/console', {
                  state: {
                    module: 'monitoring',
                  },
                });
            }}
            />
          <Block sx={{backgroundColor: theme.palette.blue['400'], color: "var(--gray000)"}}>
            <Typography fontSize={12} sx={{ display: "inline", ...theme.typography.button, padding: theme.spacing(2) }} >
              {props?.operator?.Provider?.operator?.accessLevel}
            </Typography>
          </Block>
          <Typography
            fontWeight="bold" 
            sx={{ display: "inline", ...theme.typography.button, padding: theme.spacing(2)}}> 
            {`${props?.operator?.Provider?.operator?.firstname} ${props?.operator?.Provider?.operator?.lastname}`} 
            </Typography>
        </Box>
        <Box>
          <Typography sx={{ display: "inline", padding: theme.spacing(2) }}>
             {new Date(props?.startDate).toLocaleString(i18n.language).split(",")[0]} - {new Date(props?.endDate).toLocaleString(i18n.language).split(",")[0]}
          </Typography>
        </Box>
      </Stack>
      </CardContent>
    </Card>
  );
};
export default Header;
