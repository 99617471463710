import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { getOperatorAccessToken, getOperatorSessionToken } from '../Storage/operatorStorage';
import React, { useEffect, useState } from 'react';

import DialogHeader from './DialogHeader';
import { PatternFormat } from 'react-number-format';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { IParticipant } from '@interfaces/Conversation';

/*
 *  Required for originator:
 *    - loginType = 'originator'
 *    - userId
 *    - action, select from:
 *      - checkLoan (Check Loan Status)
 *      - loanApp (Process Your Application)
 *      - newLoan (Submit New Loan)
 *      - newPreapp (Submit Pre-Approval Request)
 *      - newPrequal (Submit Pre-Qualification Request)
 *      - priceLoan (Price the Loan)
 *    - loan
 *
 *  Required for borrower
 *    - loginType = 'borrower'
 *    - loan
 *    - email
 *
 *  Borrower action options:
 *    - loanStatusGraphDiv (check a loan status)
 *    - groupTable (Order Appraisal / Check Appraisal Status)
 *    - conditionTableGroup (View or Submit Conditions)
 *    - feedBackSubmitTable (Ask A Question?)
 *
 *
 */

const SMLSDialog = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState('borrower');
  const [loanNumber, setLoanNumber] = useState({ formattedValue: '', value: '' });
  const [action, setAction] = useState('loanStatusGraphDiv');
  const [participant, setParticipant] = useState<IParticipant>(null);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    setAction(tab === 'originator' ? 'checkLoan' : 'loanStatusGraphDiv');
  }
  const handleActionSelection = (e) => setAction(e.target.value);
  const handleLoanNumberChange = (values, source) => setLoanNumber(values);
  const handleOkClicked = async () => {
    let hashkeyPayload;
    if (selectedTab === 'originator') {
      hashkeyPayload = {
        loginType: 'originator',
        userId: participant.userData?.userId,
        loan: loanNumber.value !== '' ? loanNumber.value : undefined,
        action: action !== '' ? action : undefined,
      }
    } else {
      hashkeyPayload = {
        loginType: 'borrower',
        email: participant.userData?.email,
        loan: loanNumber.value !== '' ? loanNumber.value : undefined,
        action: action !== '' ? action : undefined,
      }
    }

    let hashkey = await axios({
      url: `${process.env.REACT_APP_MORGAN_CORE}/hashkey/smls`,
      method: 'POST',
      headers: {
        'Session-Token': await getOperatorSessionToken(),
        Authorization: `Bearer ${getOperatorAccessToken()}`
      },
      data: hashkeyPayload
    });

    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=1000,height=750,left=-1000,top=-1000`;
    let url = `${process.env.REACT_APP_SUNSOFT}/jsp/extSeeMyLoanStatusPostLogin.jsf?hashkey=${hashkey.data.key}`;

    //props.setIframeSource(`${process.env.REACT_APP_SUNSOFT}/jsp/extSeeMyLoanStatusPostLogin.jsf?hashkey=${hashkey.data.key}`);
    window.open(url, "See My Loan Status", params);
    props.onClose();
  }

  useEffect(() => {
    let primary = props.errand.participants.filter(p => p.active && p.primary);
    setParticipant(primary[0]);
  }, [props.errand.participants]);

  return (
    <Dialog open={props.open} onClose={props.onClose} >
      <DialogHeader title="See My Loan Status" onClose={() => props.onClose()} />
      <DialogContent>
        <Stack spacing={1} pt={1}>
          {
            participant?.userData?.userId?.length > 0 &&
            <ButtonGroup size="small" fullWidth>
              <Button
                variant={
                  selectedTab === 'borrower' ? 'contained' : 'outlined'
                }
                sx={{
                  color:
                    selectedTab === 'borrower'
                      ? 'var(--gray000)'
                      : theme.palette.peach900,
                }}
                onClick={() => { handleTabChange('borrower'); }}>
                {t('smlsDialogBorrower')}
              </Button>
              <Button
                variant={
                  selectedTab === 'originator' ? 'contained' : 'outlined'
                }
                sx={{
                  color:
                    selectedTab === 'originator'
                      ? 'var(--gray000)'
                      : theme.palette.peach900,
                }}
                onClick={() => { handleTabChange('originator'); }}>
                {t('smlsDialogOriginator')}
              </Button>
            </ButtonGroup>
          }

          <PatternFormat
            customInput={TextField}
            label={t('smlsDialogLoanNumber')}
            type="tel"
            size="small"
            value={loanNumber.formattedValue}
            onValueChange={handleLoanNumberChange}
            format="##########-##"
            mask="_"
          />

          {
            selectedTab === 'originator' &&
            <FormControl fullWidth>
              <InputLabel id="originatorActionLabel">{t('smlsDialogAction')}</InputLabel>
              <Select
                labelId="originatorActionLabel"
                label={t('smlsDialogAction')}
                value={action}
                onChange={handleActionSelection}>
                <MenuItem value="checkLoan">{t('smlsDialogCheckLoan')}</MenuItem>
                <MenuItem value="loanApp">{t('smlsDialogLoanApp')}</MenuItem>
                <MenuItem value="newLoan">{t('smlsDialogNewLoan')}</MenuItem>
                <MenuItem value="newPreapp">{t('smlsDialogNewPreapp')}</MenuItem>
                <MenuItem value="newPrequal">{t('smlsDialogNewPrequal')}</MenuItem>
                <MenuItem value="priceLoan">{t('smlsDialogPriceLoan')}</MenuItem>
              </Select>
            </FormControl>
          }

          {
            selectedTab === 'borrower' &&
            <FormControl fullWidth>
              <InputLabel id="originatorActionLabel">{t('smlsDialogAction')}</InputLabel>
              <Select
                labelId="originatorActionLabel"
                label={t('smlsDialogAction')}
                value={action}
                onChange={handleActionSelection}>
                <MenuItem value="loanStatusGraphDiv">{t('smlsDialogLoanStatusGraphDiv')}</MenuItem>
                <MenuItem value="groupTable">{t('smlsDialogGroupTable')}</MenuItem>
                <MenuItem value="conditionTableGroup">{t('smlsDialogConditionTableGroup')}</MenuItem>
                <MenuItem value="feedBackSubmitTable">{t('smlsDialogFeedBackSubmitTable')}</MenuItem>
              </Select>
            </FormControl>
          }

        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>  {/**variant="consoleOutlined" */}
          {t('cancelButton')}
        </Button>
        <Button variant="contained" onClick={handleOkClicked}> {/**variant="console" */}
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SMLSDialog;
