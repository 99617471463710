import { List, ListItem, Stack, Typography } from '@mui/material';

import { Close } from '../Assets/Icons';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import patentList from '../Assets/patents.json';
import React, { useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { useTranslation } from 'react-i18next';

const Patents = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 'medium',
          cursor: 'pointer',
          marginTop: '10px',
          color: 'var(--gray000)',
          zIndex: '2',
          bottom: '-161px',
          position: 'relative'
        }}
        onClick={handleOpen}
      >
        {t('patent')}
      </Typography>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h5">{t('patentTitle')}</Typography>
            <Close
              style={{ width: 1 + 'em', cursor: 'pointer' }}
              color={theme.palette.red['400']}
              onClick={handleClose}
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          <List>
            {patentList &&
              patentList.map((patent, index) => (
                <ListItem key={index} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Typography>
                    <strong>{patent.title}</strong> {` ${patent.appNumber}`}
                  </Typography>
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Patents;
