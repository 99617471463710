import React, { useEffect, useRef } from 'react';

const Snowing = ({ numSnowflakes }) => {
  const canvasRef = useRef(null);

  const drawSnowflakes = (ctx, snowflakes) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = 'white';

    snowflakes.forEach(snowflake => {
      ctx.save();
      ctx.translate(snowflake.x, snowflake.y);
      ctx.beginPath();
      ctx.arc(0, 0, snowflake.size * 1.3 / 2, 0, 2 * Math.PI);
      ctx.fill();
      ctx.restore();
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const ctx = canvas.getContext('2d');

    const resizeCanvas = () => {
      canvas.style.width = '100%';
      canvas.style.height = '100%';
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };

    const initSnowflakes = () => Array.from({ length: numSnowflakes }, () => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      size: Math.random() * 3 + 1,
      speed: Math.random() * 1 + 0.5,
      drift: Math.random() * 0.5 - 0.25
    }));

    let snowflakes = initSnowflakes();
    let animationFrameId;

    const update = () => {
      snowflakes.forEach(snowflake => {
        snowflake.y += snowflake.speed;
        snowflake.x += snowflake.drift;
        if (snowflake.y > canvas.height) {
          snowflake.y = -snowflake.size;
          snowflake.x = Math.random() * canvas.width;
        }
      });
      drawSnowflakes(ctx, snowflakes);
      animationFrameId = requestAnimationFrame(update);
    };

    const handleResize = () => {
      resizeCanvas();
      snowflakes = initSnowflakes(); // Reinitialize snowflakes for new dimensions
      drawSnowflakes(ctx, snowflakes);
    };

    window.addEventListener('resize', handleResize);

    resizeCanvas(); // Set initial canvas size
    update();

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener('resize', handleResize);
    };
  }, [numSnowflakes]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%', 
        height: '100%', 
        pointerEvents: 'none', // Allow clicks to pass through
      }}
    />
  );
};

export default Snowing;
