import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import DialogHeader from './DialogHeader';
import EmailIcon from '@mui/icons-material/Email';
import React from 'react';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { DialogPropsWithChildren } from '@mTypes/Console';

const AddOperatorDialog: FC<DialogPropsWithChildren> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [email, setEmail] = useState<String>('');

  const handleSubmit = () => {
    props.onSubmit({ email });
  };

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('addOperatorButton')} onClose={() => props.onClose()} />
      <DialogContent>
        <Stack>
          <TextField
            sx={{ mt: "12px" }}
            size="small"
            autoFocus
            color="info"
            variant="outlined"
            label={`${t('emailCol')}*`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <Typography color={theme.palette.red['400']}>{props.error}</Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button color="info" variant="outlined"
          onClick={() => props.onClose()}>
          {t('cancelButton')}
        </Button>
        <Button color="info" variant="contained"
          disabled={email === '' || !email.includes('@')}
          onClick={() => handleSubmit()}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOperatorDialog;
