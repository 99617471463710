import dayjs from 'dayjs';
import writeXlsxFile from 'write-excel-file';

const downloadActionReport = async (actionData) => {
  const schema = [
    // Column #1
    {
      column: 'Type',
      type: String,
      value: (action) => action.type,
      width: 8,
    },
    {
      column: 'Field Name',
      type: String,
      value: (action) => action.fieldName,
      width: 25,
    },
    {
      column: 'Field Attribute',
      type: String,
      value: (action) => action.fieldAttributeDescription,
      width: 25,
    },
    {
      column: 'Field QID',
      type: String,
      value: (action) => action.fieldQid,
      width: 25,
    },
    {
      column: 'Field Endpoint',
      type: String,
      value: (action) => action.fieldEndpoint,
      width: 25,
    },
    {
      column: 'Description',
      type: String,
      value: (action) => action.description,
      width: 50,
    },
    {
      column: 'Message',
      type: String,
      value: (action) => action.message,
      width: 150,
    },
  ];

  if (actionData?.length > 0) {
    const objects = actionData?.map((action) => {
      return {
        type: action?.type,
        fieldName: action?.fieldName,
        fieldAttributeDescription: action?.fieldAttributeDescription,
        fieldQid: action?.fieldQid,
        fieldEndpoint: action?.fieldEndpoint,
        description: action?.description,
        message: action?.message,
      };
    });
    const date = dayjs(new Date()).format('DD/MM/YYYY');
    await writeXlsxFile(objects, {
      schema,
      fileName: `Morgan Action Report : ${date}.xlsx`,
    });
  }
};

export default downloadActionReport;
