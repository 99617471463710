import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@mui/icons-material';

import { getDropdownDescription } from '@common/StringUtils';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { ResetFooterUserAction } from '@common/common';
import Styles from '@styles/MorphMessageOptions.module.css';
import { MorphType } from '@common/MorphType';

type TMorphMessageOptionEditProps = {
  errand: IErrand;
  handleClose: (morphType?: MorphType, clearAction?: boolean) => void;
  message: IMessage;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
};

const MorphMessageOptionEdit = ({
  errand, handleClose, message, setEditMessageId, setValue,
}: TMorphMessageOptionEditProps) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const rootContext = useRootContext();

  const handleClickEdit = useCallback(() => {
    // set up editable message value
    let msg = message.message || '';
    // Remove replies
    if (msg.indexOf('</blockquote>') !== -1) {
      // Remove the old message.
      msg = msg.split(`</blockquote>`)[1];
    }
    // Remove previous edits
    if (msg.indexOf('<i class="messageArrow"/>') !== -1) {
      // Remove the old message.
      msg = msg.split(`<i class="messageArrow"/>`)[1];
    }
    // Remove user message from operator corrections
    if (msg.indexOf(t('acceptedData')) !== -1) {
      // Remove the user message
      msg = msg.split(t('acceptedData'))[1];
      // Remove the ) at the end of the message
      msg = msg.substring(0, msg.length - 1).trim();
    }
    // Remove html
    if (msg.indexOf('href=') !== -1) {
      msg = msg.replace(/<\/?a[^>]*>/g, '');
    }

    // Parse the field data to show the description by extracting it from the message. 
    msg = getDropdownDescription(msg);

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(msg, 'text/html');

    msg = htmlDoc.body.textContent;

    // set states as needed
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errand._id);

      if (chatObj) {
        if (message.messageType === 'Field') {
          chatObj.icon = message.icon;
          chatObj.placeholder = message?.action?.description;
          chatObj.action = {
            ...message?.userAction,
            action: {
              ...message?.action,
            },
            userActionId: message?.userAction?._id,
            active: true,
          };
          chatObj.recipients = message.intendedAudience ? [message.sender._id, ...message.intendedAudience].sort() : [];
        } else {
          ResetFooterUserAction(chatObj);
        }
        if (message.intendedAudience) {
          chatObj.recipients = [message.sender._id, ...message.intendedAudience].sort();
        } else {
          chatObj.recipients = [];
        }
      }

      return [...prev];
    });
    setEditMessageId(message._id);
    setValue(msg);
    errandContext.footerInputRef.current?.focus();

    // set edit morph type logic
    const fieldAttributeObj = message?.action?.fieldAttribute as any;
    const nextMophType = !fieldAttributeObj || 
      (fieldAttributeObj?.description !== undefined && fieldAttributeObj.description === "DROPDOWN") 
      || message?.action?.fieldQid !== 'MORGAN.ROLE' ? MorphType.Edit : MorphType.None;
    // clean up
    // here I pass false because it will prevent clearing the current errand action.
    // I want the action to stay what it was set to on lines above.
    // Otherwise the above action setting lines have no meaning.
    handleClose(nextMophType, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errand?._id, message?._id, message?.message, handleClose, message.sender?._id, setEditMessageId, setValue, t]);

  return (
    <button className={Styles.btn} onClick={handleClickEdit}>
      <div className={Styles.btnBorder}>
        <EditOutlined />
        <p className={Styles.label}>{t('tEdit')}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionEdit;
