import { Card, CardMedia, CircularProgress, Fade, Snackbar, Stack, Tooltip, Typography, MorganTheme, useTheme } from '@mui/material';
import { DoubleTick, Tick } from '@assets/Icons';
import React, { Fragment, useRef, useState } from 'react';

import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { Box } from '@mui/system';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import MessageTextStyle from '@styles/MessageTextStyle';
import OriginalLangStyle from '@styles/OriginalLangStyle';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import TranslationTabLeft from '@styles/TranslationTabLeft';
import TranslationTabRight from '@styles/TranslationTabRight';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';
import Sanitized from '../Sanitized';
import { uiTranslationController, getLangName } from '@common/common';
import { useMessageContext } from '@contexts/MessageContext';
import { IErrand, IMessage } from '@interfaces/Conversation';
import MessageTime from '@components/MessageTime';
import { AccessType } from '@common/AccessType';
const sunWestLogo = process.env.REACT_APP_MORGAN_CDN + '/Images/SunWestLogo.jpg';
const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';

type TUrlMessageContentProps = {
  message: IMessage;
}

const UrlMessageContent = ({
  message, 
}: TUrlMessageContentProps) => {
  const { t, i18n } = useTranslation();
  const messageContext = useMessageContext();
  const theme: MorganTheme = useTheme();
  const messageRef = useRef(null);
  const [isFading, setIsFading] = useState(true);
  const [revealEnabled, setRevealEnabled] = useState(false);
  /* Set message url detected in message */
  const { dispMessage, isTranslated } = uiTranslationController(message);
  const [isWarningActive, setIsWarningActive] = useState(false);
  const isEditing = messageContext?.editMessageId === message?._id;
  const thumbnails = useQuery([message.links?.join('')], async ({ signal }) => {
    let arr = [];
    for await(const link of message.links) {
      let request = {
        url: `url/metadata`,
        method: 'post',
        data: {
          /**
          * To remain consistent with detection of URLs that are not prefixed with
          * http/s, and because the meta data parsing package we use does not handle
          * links with such prefixes, we attach one
          */
          url: link?.indexOf("http") === 0 ? link : `http://${link}`
        }
      };
      let config = {
        signal,
      };
      let data = await axiosCall(request, config).catch((error) => {
        arr.push(false);
      });
      if (data?.data?.image) {
        arr.push({image: data?.data?.image, link, description: data?.data?.description});
      } else {
        arr.push(false);
      }
    }
    return arr;
  });

  /**
   * This function is a handler callback for the Original Language component's
   * onClick event. It is responsible for temporarily revealing the origianl
   * message.
   */
  const handleClickOriginalLang = async (e) => {
    e?.stopPropagation();
    if (!message.visible) return;
    if (revealEnabled) {
      return;
    }
    const reset = messageRef.current.innerHTML;

    // Start the inital fadeout:
    setRevealEnabled(true);
    setIsFading(false);

    // Wait until the fadeout is done to fade in the original message:
    setTimeout((reset) => {
      let editedMessage = message.message;

      let [firstSection, secondSection] = message.message.split(`<i class="messageArrow"/>`).map((x) => x.replace(/(<([^>]+)>)/gi, ''));

      if (secondSection) {
        editedMessage = `<s>${firstSection}</s>&nbsp;<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1r4vxry-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowCircleRightRoundedIcon"><path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.31-.85.09-.85-.36z"></path></svg> <i>${secondSection}</i>`;
      }
      
      // Style the original message:
      editedMessage = `<div style="font-size: 0.8em; font-family: Poppins; font-weight: 400;">${editedMessage}</div>`;
      
      messageRef.current.innerHTML = editedMessage;
      setIsFading(true);

      // Allow the revealed message to display for some time, then begin fading
      // it out:
      setTimeout((reset) => {
        setIsFading(false);

        // Finally, wait until the fadeout is done, then fade in the translated
        // message:
        setTimeout((reset) => {
          messageRef.current.innerHTML = reset;
          setIsFading(true);
          setRevealEnabled(false);
        }, 500, reset);
      }, 3000, reset);
    }, 500, reset);
  };

  const hasWidgetTestLink = (link) => {
    if (process.env.REACT_APP_ENV !== 'production' && link.includes('widget-test')){
      return true;
    }
    return false;
  }

  return (
    <>
    <Snackbar
      autoHideDuration={2000}
      open={isWarningActive ? true : false}
      onClose={() => setIsWarningActive(null)}
      message={isWarningActive}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    />
    <Stack
      display="flex"
      flexDirection="column"
      alignItems={
        // On the user side, Team, and Group chats: only sender is on the right side
        // On the operator side, only sender (operator) and Morgan is on the right side
        message?.alignByCurrentUser
            ? 'flex-end'
            : 'flex-start'
      }
      width="fit-content"
      maxWidth={window.innerWidth > 900 ? "min(60%, calc(100vw - 50px))" : "min(90%, calc(100vw - 50px))" }>
      {/* Added an additional stack wrapper because the translate sub bubble will position itself
    in the center of the entire stack, and become off center, if a thumbnails is rendered */}
      <Stack
        display="flex"
        flexDirection="column"
        alignItems={
          // On the user side, Team, and Group chats: only sender is on the right side
          // On the operator side, only sender (operator) and Morgan is on the right side
          message?.alignByCurrentUser
              ? 'flex-end'
              : 'flex-start'
        }
      >
        <ChatBubbleStyle
          sx={{
            maxWidth: 'min(90%, calc(100vw - 50px))',
            borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : isEditing ? 'var(--blue050)' : 'var(--orange700)',
            background: message.accessType === AccessType.internal
              ? 'var(--peach020)'
              : message.sentByCurrentUser
                ? 'var(--gray000)'
                : 'var(--peach600)',
          }}>
          <Stack flexDirection="column" width="100%" minWidth= {isTranslated ? '220px' : '85px'}>
            <Stack
              alignItems="center"
              sx={{
                d: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                p: '6px 7px 8px 9px',
                maxWidth: '100%',
                position: 'relative',
                width: '100%',
              }}>
                {message.messageStatus === 'edited' ? (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      ref={messageRef}
                      >
                      <Fade in={isFading} appear={false}>
                        <MessageTextStyle>
                          <s><Sanitized
                            html={dispMessage.split(`<i class="messageArrow"/>`)[0]}
                          /></s>
                          <ArrowCircleRightRoundedIcon
                            sx={{
                              color: 'var(--orange700)',
                              marginRight: '5px',
                              transform: 'translateY(8px)',
                              marginTop: '-12px',
                            }}
                          />
                          <Sanitized
                            html={dispMessage.split(`<i class="messageArrow"/>`)[1]}
                          />
                        </MessageTextStyle>
                      </Fade>
                    </Stack>
                  </>
                ) : (
                  <Fade in={isFading} appear={false}>
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      ref={messageRef}>
                      <MessageTextStyle>
                        <Sanitized html={dispMessage} />
                      </MessageTextStyle>
                    </Stack>
                  </Fade>
                )}
              &nbsp;
              <MessageTime message={message} />
            </Stack>
            {message.links?.length > 0 && (
              <Stack
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                gap={1}
                sx={{
                  backgroundColor: 'var(--orange700)',
                  backgroundImage:
                    theme.system.name === 'SUNWST000' ? `url(${WoodGrain})` : 'none',
                  border: 'none',
                  p: '3px',
                  cursor: 'pointer',
                }}>
                {message.links?.map((v,i,a) => {
                  let uri = '';
                  try {
                    uri = decodeURI(v).replace(/http(s)?:\/\/(www.)?(m.)?/, '');
                  } catch (error) {
                    console.log(`Could not decode URI, ${v}`, error);
                    uri = (v || '').replace(/http(s)?:\/\/(www.)?(m.)?/, '');
                  }
                  if (!uri) return <></>;
                  return (
                  <div key={i} style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    pointerEvents: 'all'
                  }}>
                    {message.visible ? (
                      <>
                        <Tooltip title={t('copyToClipboard')}>
                          <ContentCopyRoundedIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              navigator.clipboard.writeText(
                                v?.indexOf('http') === -1 ? `https://${v}` : v
                              );
                              setIsWarningActive(t('copiedToClipboard'));
                            }}
                            sx={{ color: 'var(--gray000)', height: '.75em', width: '.75em' }}
                          />
                        </Tooltip>
                        {hasWidgetTestLink(v) || v?.indexOf('swmc.com') !== -1 || v === 'www.example.com' ? (
                          /* swmc links and example.com should open internally */
                          <div
                            style={{ textDecoration: 'none', wordBreak: 'break-all', pointerEvents: 'all' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              messageContext.setPreviewUrl(v?.indexOf('http') === -1 ? `https://${v}` : v)
                            }}>
                            <Typography variant='subtitle1' color='var(--gray000)' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' maxWidth='20rem'>
                              {uri}
                            </Typography>
                          </div>
                        ) : (
                          /* 99% of external links will not display properly in iframes so they must be opened in a new tab */
                          <a
                            href={v?.indexOf('http') === -1 ? `https://${v}` : v}
                            target='_blank'
                            rel='noreferrer'
                            style={{ textDecoration: 'none', wordBreak: 'break-all', maxWidth: window.innerWidth < 400 ? '80%' :'initial' }}>
                            <Typography variant='subtitle1' color='var(--gray000)' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' maxWidth='20rem'>
                              {uri}
                            </Typography>
                          </a>
                        )}
                      </>
                    ) : (
                      <>
                        <ContentCopyRoundedIcon
                          sx={{ color: 'var(--gray000)', height: '.75em', width: '.75em' }}
                        />
                        <Typography variant='subtitle1' color='var(--gray000)' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' maxWidth='20rem'>
                          {uri}
                        </Typography>
                      </>
                    )}
                  </div>
                )})}
              </Stack>
            )}
          </Stack>
        </ChatBubbleStyle>

        {isTranslated &&
          (message?.alignByCurrentUser ? (
            <TranslationTabRight>
              <OriginalLangStyle onClick={handleClickOriginalLang}>
                <u>{getLangName(message.detectedLanguage)}</u>
              </OriginalLangStyle>
              <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
            </TranslationTabRight>
          ) : (
            <TranslationTabLeft>
              <OriginalLangStyle onClick={handleClickOriginalLang}>
                <u>{getLangName(message.detectedLanguage)}</u>
              </OriginalLangStyle>
              <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
            </TranslationTabLeft>
          ))}
      </Stack>

      {/*
  Thumbnails rendering component. Was implemented based on a proposed design which removed link descriptionss.
  Could possibly be added back later for better context.
    */}
      {thumbnails?.data === undefined && (
        <>
          {message?.alignByCurrentUser ? (
            <Box
              sx={{
                display: 'flex',
                height: 35,
                borderColor: theme.palette.orange['600'],
                borderTop: 'none',
                width: '100%',
                boxShadow: 'none',
                borderRight: `2px dashed ${theme.palette.orange['600']}`,
                marginRight: '20px',
                marginLeft: 'none',
                marginTop: isTranslated ? '-20px' : '0px',
                borderLeft: 'none',
                borderBottom: 'none',
              }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                height: 35,
                borderColor: theme.palette.orange['600'],
                borderTop: 'none',
                width: '100%',
                boxShadow: 'none',
                borderRight: 'none',
                marginRight: 'none',
                marginLeft: '20px',
                marginTop: isTranslated ? '-20px' : '0px',
                borderLeft: `2px dashed ${theme.palette.orange['600']}`,
                borderBottom: 'none',
              }}
            />
          )}
          <Card sx={{display: 'flex', height: '100px', width: '100px', justifyContent: 'center', alignItems: 'center', boxShadow: 'none', border: '1px solid var(--orange700)'}}>
            <CircularProgress />
          </Card>
        </>
      )}
      {message.links?.length === 1 && (
        <Stack
        display='flex'
        flexDirection='column'
        alignItems="center"
        width='fit-content'
        maxWidth='100%'>
          {thumbnails?.data?.map((v, i, a) => {
            if (v === false) {
              return <></>
            }
            return (
            <Fragment key={i}>
              {message?.alignByCurrentUser ? (
                <Box
                  sx={{
                    display: 'flex',
                    height: 35,
                    borderColor: theme.palette.orange['600'],
                    borderTop: 'none',
                    width: '100%',
                    boxShadow: 'none',
                    borderRight: `2px dashed ${theme.palette.orange['600']}`,
                    marginRight: '20px',
                    marginLeft: 'none',
                    marginTop: isTranslated ? '-20px' : '0px',
                    borderLeft: 'none',
                    borderBottom: 'none',
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    height: 35,
                    borderColor: theme.palette.orange['600'],
                    borderTop: 'none',
                    width: '100%',
                    boxShadow: 'none',
                    borderRight: 'none',
                    marginRight: 'none',
                    marginLeft: '20px',
                    marginTop: isTranslated ? '-20px' : '0px',
                    borderLeft: `2px dashed ${theme.palette.orange['600']}`,
                    borderBottom: 'none',
                  }}
                />
              )}
              <Card
                onClick={(e) => {
                  e.preventDefault();
                  // swmc links and example.com should open internally
                  if (hasWidgetTestLink(v?.link) || v?.link?.indexOf('swmc.com') !== -1 || v === 'www.example.com') {
                    messageContext.setPreviewUrl(
                      v?.link?.indexOf('http') === -1 ? `https://${v?.link}` : v?.link
                    );
                  } else {
                    // 99% of external links will not display properly in iframes so they must be opened in a new tab
                    window.open(
                      v?.link?.indexOf('http') === -1 ? `https://${v?.link}` : v?.link,
                      '_blank'
                    );
                  }
                }}
                sx={{
                  display: 'flex',
                  height: 100,
                  border: '1px solid',
                  borderColor: theme.palette.orange['600'],
                  maxWidth: '333px',
                  boxShadow: 'none',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  pointerEvents: 'all',
                }}>
                {v?.image === undefined ? (
                  <CircularProgress />
                ) : (
                  <CardMedia
                    component='img'
                    sx={{
                      width: 'auto',
                      height: '100px',
                      maxWidth: '333px',
                      objectFit: 'cover',
                    }}
                    image={v?.image}
                    onError={(e)=>{ 
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src=sunWestLogo;
                  }}
                    alt='Website preview'
                  />
                )}
              </Card>
            </Fragment>
          )})}
        </Stack>
      )}
    </Stack>
    </>
  );
};

export default UrlMessageContent;
