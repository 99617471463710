import React, { useCallback, useEffect, useMemo, useRef, useState, MutableRefObject } from 'react';
import { Stack, useMediaQuery, useTheme, Grow } from '@mui/material';
import Sanitized from '@components/Sanitized';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import './main.css';
import { MorphType } from '@common/MorphType';
import { useErrandContext } from '@contexts/ErrandContext';
import { sendWorkflow } from '@common/WorkflowsUtils';
import { AccessType } from '@common/AccessType';
import useMeasureOffScreen from './useMeasureOffScreen';
import HintOption from './HintOption';
import useInOutView from './useInOutView';
import { useTranslation } from 'react-i18next';
import LanguageUtils from '@common/LanguageUtils';
import useAbortController from '@common/hooks/useAbortController';
import { useUserContext } from '@contexts/user';
import { _localStorageTools } from './localStorageTools';
import { ContentContainer } from './ContentContainer';
import MessageTime from '@components/MessageTime';
import {
    isLastMsg,
    getAllMountedInitValue,
    getFirstMountValueFromStorage,
    getIsFirstMountValue,
    ParsedMsgRenderData,
    extractCustomInstruction,
    initMessageStateBuffer,
    ErrorStatus,
    OnMountMessageWrapper
} from './utils';
import { defaultLogoStyle, operatorLogoStyle, userDragNoneStyle, userLogoStyle } from './styles';
import eventBus from '@common/eventBus';
import { getUserConsent } from '@storage/userStorage';
import { useRootContext } from '@contexts/RootContext';
import { InViewController } from './InViewController';
import { WORKFLOW_NAMES } from '@constants/WorkflowNames';
const AngelDancingLogoSrc = process.env.REACT_APP_MORGAN_CDN + '/Images/WUM_Images/AngelDancingLogo.png';

export type TWelcomeUserMessageState = {
    userSuggestions: {
        unmountStarted: boolean;
    },
    welcomeUserMessage: {
        inView: boolean;
        userChosenHintIndex: number;
        userChosenHintIndexLockedRef: MutableRefObject<boolean>;
    }
}

type TWelcomeUserMessageProps = {
    message: IMessage;
    errand: IErrand;
};

const translationTools = (function () {
    const prepareStrArrData = (strArr) => strArr.map((str) => ({ message: str, source: 'en' }))
    const translateArrOfStr = async (strArr, targetLn, abortController) => {
        return await LanguageUtils.translateMany(prepareStrArrData(strArr), targetLn, abortController)
    };
    return {
        translateArrOfStr
    }
})();

function removeQuotes(input) {
    return input.replace(/"/g, ''); // Replace all double quotes with an empty string
}

// Moving circles main animation component.
// shown: controls opacity
// displayed: controls presense in DOM
const MovingCircles = (props: { shown: boolean, displayed: boolean }) => {
    if (!props.displayed) return null;

    return (
        <div className={`wumc_circles_container ${props.shown === true ? 'opacity1' : 'opacity0'}`}>
            <div className="logoContainer-circle-1"></div>
            <div className="logoContainer-circle-2"></div>
            <div className="logoContainer-circle-3"></div>
        </div>
    )
}

const isValidUrl = (url) => {
    try {
        new URL(url); // If URL is invalid, this will throw an error
        return true;
    } catch (e) {
        return false;
    }
};

const preParseInitialState = (state: ParsedMsgRenderData | string) => {
    if (typeof state === 'string' && state.startsWith('*') && state.endsWith('*')) {
        return state;
    }
    // for proper typing
    const _state = state as ParsedMsgRenderData;
    return {
        title: _state?.title ?? "",
        options: _state?.options ?? [],
        consent: _state?.consent ?? { initial: '', accepted: '' },
    } as ParsedMsgRenderData;
}

const TopLogo = ({
    finalLogoStyle,
    onLogoClick,
    movingCirclesDisplayed,
    movingCirclesShown
}) => {
    return (
        <div
            style={finalLogoStyle}
            className="logoContainer"
            onClick={onLogoClick}
        >
            <div className="logoContainer-circle-background"></div>
            {/* SHOW MOVING CIRCLES BEHIND THE LOGO */}
            <MovingCircles displayed={movingCirclesDisplayed} shown={movingCirclesShown} />
            <img src={AngelDancingLogoSrc} style={{ width: "80px", ...userDragNoneStyle }} />
        </div>
    );
}

// Main Welcome User Message Component Logic
const WelcomeUserMessageContent = (props: TWelcomeUserMessageProps) => {
    const { t, i18n } = useTranslation();
    const [isFading, setIsFading] = useState(true);
    const messageRef = useRef(null);
    const theme = useTheme();
    const mediumMediaQueryResult = useMediaQuery(theme.breakpoints.up('md'))
    const errandContext = useErrandContext();
    const [numOfShownElements, setNumOfShownElements] = useState(-1);
    const abortController = useAbortController();
    const { isOperator } = useUserContext();
    const rootContext = useRootContext();

    // Core logic that controls initial animation
    const isCurrMsgLastInChat = isLastMsg(props.errand.messages[props.errand.messages.length - 1]._id, props.message._id)
    const [allMounted, setAllMounted] = useState(getAllMountedInitValue(isCurrMsgLastInChat, getFirstMountValueFromStorage(), isOperator));
    const [isFirstMount, setIsFirstMount] = useState(getIsFirstMountValue(isCurrMsgLastInChat, getFirstMountValueFromStorage(), isOperator));

    // Timeout id refs
    const removeCurrMorphTID = useRef(null);
    const tmIdsRef = useRef([]);

    // Moving circles
    const [movingCirclesShown, setMovingCirclesShown] = useState(false);
    const [movingCirclesDisplayed, setMovingCirclesDisplayed] = useState(getIsFirstMountValue(isCurrMsgLastInChat, getFirstMountValueFromStorage(), isOperator));

    // Main render data
    // parses props.message data into render data on intial render
    const initialState = preParseInitialState(props.message.message as unknown) as ParsedMsgRenderData;
    const [renderData, setRenderData] = useState<ParsedMsgRenderData & ErrorStatus>(initialState);

    // Consent
    const [consentGiven, setConsentGiven] = useState(getUserConsent() === 'true');

    const onLogoClick = () => {
        if (movingCirclesDisplayed === true || movingCirclesShown === true) return;

        setMovingCirclesDisplayed(true);

        tmIdsRef.current.push(
            setTimeout(() => {
                setMovingCirclesShown(true);

                tmIdsRef.current.push(
                    setTimeout(() => {
                        setMovingCirclesShown(false);

                        tmIdsRef.current.push(
                            setTimeout(() => {
                                setMovingCirclesDisplayed(false);
                            }, 1000)
                        )
                    }, 1500)
                )
            }, 100)
        );
    }

    // on mount/unmount
    useEffect(() => {
        const consentGiven = () => {
            // Consent Given
            setConsentGiven(true);
        }
        eventBus.on('consentGiven', consentGiven);

        // no need to include isFirstMount in dependency because we just need the first run (and a variable value during first redner only)
        if (!isFirstMount) _localStorageTools.setInLocalStorage(_localStorageTools.KEYS.WERE_SUGGESTIONS_ALREADY_SHOWN_KEY, JSON.stringify(true));
        // immidiately mark first mount as false
        _localStorageTools.setInLocalStorage(_localStorageTools.KEYS.IS_FIRST_MOUNT_STORAGE_KEY, JSON.stringify(false));
        // set initial message state buffer for translation purposes
        initMessageStateBuffer.set(initialState);

        return () => {
            // clear timeouts
            tmIdsRef.current.forEach((tmID) => {
                clearTimeout(tmID);
            });

            eventBus.remove('consentGiven', consentGiven);
        }
    }, []);


    // Once all mounted useEffect (last item mounts)
    useEffect(() => {
        if (typeof renderData === 'string') return;
        // only if all mounted AND its the last message in chat && its not yet played
        if (allMounted === true && isCurrMsgLastInChat === true && !isOperator) {
            // meaning it was not shown yet.
            if (_localStorageTools.getFromLocalStorage(_localStorageTools.KEYS.WERE_SUGGESTIONS_ALREADY_SHOWN_KEY) === null) {
                const items = renderData.options.map(
                    (optObj) => removeQuotes(
                        extractCustomInstruction(optObj.html)
                    )
                );
                // show the suggestions in the multiple footer typing animation component
                errandContext.setMultipleFooterTypingAnimations({ items: items, delay: 2000, speed: 30, currentActiveItem: 0 });
            }
        }
    }, [allMounted, renderData, renderData?.options?.length, isCurrMsgLastInChat, isOperator])

    // ====== START ====== 
    // Send Workflows & Handle Link Clicks Logic
    const sendCalculatorWorkflow = async (calculatorName) => {
        return await sendWorkflow('',
            calculatorName,
            props.message.chat,
            props.message.intendedAudience,
            AccessType.public,
            props.message.userId,
            props.message.operatorView,
            false
        );
    }

    const sendWorkflowHelper = async (wf, checkDup = false) => {
        return await sendWorkflow('',
            wf,
            props.message.chat,
            props.message.intendedAudience,
            AccessType.public,
            props.message.userId,
            props.message.operatorView,
            checkDup
        );
    }

    const handleWorkflowOrMorphType = async (hrefOrValue) => {
        if (isOperator) return;

        errandContext.setMultipleFooterTypingAnimations(null);
        const lowerCasedHrefOrValue = hrefOrValue.toLowerCase();
        if (hrefOrValue === '#prompts') {
            errandContext.setMorphType(MorphType.UserPromptsMenu);
        } else if (hrefOrValue === '#training') {
            errandContext.setMorphType(MorphType.VideoListMenu);
        } else if (['#creditRepair', '#creditBoost'].includes(hrefOrValue)) {
            sendWorkflowHelper(WORKFLOW_NAMES.CREDIT_BOOST, true);
        } else if (hrefOrValue.toLowerCase().includes('calculator')) {
            if (lowerCasedHrefOrValue.includes('affordability')) {
                sendCalculatorWorkflow(WORKFLOW_NAMES.AFFORDABILITY_CALCULATOR);
            }
            else if (lowerCasedHrefOrValue.includes('appraisal')) {
                sendCalculatorWorkflow(WORKFLOW_NAMES.APPRAISAL_CALCULATOR);
            }
            else if (lowerCasedHrefOrValue.includes('fixedrate')) {
                sendCalculatorWorkflow(WORKFLOW_NAMES.FIXED_RATE_CALCULATOR);
            }
            else if (lowerCasedHrefOrValue.includes('refinance')) {
                sendCalculatorWorkflow(WORKFLOW_NAMES.REFINANCE_CALCULATOR);
            }
            else if (lowerCasedHrefOrValue.includes('rentvsbuy')) {
                sendCalculatorWorkflow(WORKFLOW_NAMES.RENT_VS_BUY_CALCULATOR);
            }
            else if (lowerCasedHrefOrValue.includes('zestimate')) {
                sendCalculatorWorkflow(WORKFLOW_NAMES.ZESTIMATE);
            }
        }
        else if (lowerCasedHrefOrValue.includes('workshopregistration')) {
            sendWorkflowHelper(WORKFLOW_NAMES.WORKSHOP_REGISTRATION);
        }
        else if (lowerCasedHrefOrValue.includes('startapplication')) {
            sendWorkflowHelper(WORKFLOW_NAMES.LOAN_APPLICATION);
        } else {
            if (isValidUrl(hrefOrValue)) {
                window.open(hrefOrValue, '_blank', 'noopener,noreferrer');
            } else {
                console.debug("Invalid href passed to handleWorkflowOrMorphType! hrefOrValue:", hrefOrValue);
            }
        }
    }

    // Links on page click handlers
    useEffect(() => {
        const handleLink = async (e) => {
            if (e.target.tagName === 'A') {
                e.preventDefault();
                e.stopPropagation();
                if (props.message.operatorView) return;
                let href = e.target.getAttribute('href');
                await handleWorkflowOrMorphType(href);
            }
        };

        if (messageRef.current) {
            if (isOperator)
                messageRef.current.removeEventListener('click', handleLink);
            else
                messageRef.current.addEventListener('click', handleLink);
        }
        return () => {
            if (messageRef.current) {
                messageRef.current.removeEventListener('click', handleLink);
            }
        };
    }, [handleWorkflowOrMorphType, isOperator]);
    // ====== END ====== 

    // ====== START ====== 
    // Translations
    const renderDataOptionsToArrOfStr = () => {
        // this will always be english
        return initMessageStateBuffer.get().options.map((option) => {
            return option.html;
        });
    }
    const strArrToRenderDataOptions = (strArr) => {
        return strArr.map((str) => {
            return {
                html: str
            }
        })
    }
    // On Language Change (Translation Logic)
    useEffect(() => {
        if (typeof renderData === 'string') {
            return;
        }
        // if lang is English, set the intial state (English)
        if (i18n.language === 'en') {
            // set the init state
            setRenderData(initMessageStateBuffer.get());
            rootContext.setErrands((prev) => {
                const chatObj = prev.find((e) => e._id === props.errand._id);
                chatObj.preview = initMessageStateBuffer.get().title;
                return [...prev];
            });
        }
        // if any other Language is set, do the translation
        else {
            (async () => {
                try {
                    console.log("Translating to", i18n.language);
                    const [translatedOptionsStrArr, translatedTitleStrArr] = await Promise.all([
                        translationTools.translateArrOfStr(renderDataOptionsToArrOfStr(), i18n.language, abortController),
                        translationTools.translateArrOfStr([renderData.title], i18n.language, abortController),
                        // translationTools.translateArrOfStr([renderData.consent.accepted, renderData.consent.initial], i18n.language, abortController)
                    ]);
                    console.log('Fetched translations: ', translatedOptionsStrArr, translatedTitleStrArr);
                    const translatedTitle = translatedTitleStrArr[0];
                    // parse options translated str arr to local structure
                    const translatedOptions = strArrToRenderDataOptions(translatedOptionsStrArr);
                    // const translatedConsent = {
                    //     accepted: translatedConsentStrArr[0],
                    //     initial: translatedConsentStrArr[1]
                    // }

                    const newRenderData = {
                        title: translatedTitle,
                        options: translatedOptions,
                        consent: renderData.consent
                    };

                    // handle preview update
                    rootContext.setErrands((prev) => {
                        const chatObj = prev.find((e) => e._id === props.errand._id);
                        chatObj.preview = translatedTitle;
                        return [...prev];
                    });

                    setRenderData(newRenderData);
                } catch (err) {
                    console.error(err);
                    // set initial state back in case of an error.
                    setRenderData(initMessageStateBuffer.get());
                }
            })();
        }

        return () => {
            abortController.abort();
        }
    }, [i18n.language]);
    // ====== END ====== 

    // ====== START ====== 
    // Open/Close Suggestions Morph Footer
    const openSuggestions = () => {
        if (isOperator) return;

        // always clear out existing removing morph timeout.
        clearTimeout(removeCurrMorphTID.current);
        errandContext.setWelcomeUserMessageState((prevState) => {
            return {
                ...prevState,
                userSuggestions: {
                    unmountStarted: false
                }
            }
        })
        // set suggestions morph footer
        errandContext.setMorphType(MorphType.UserSuggestions);
    }

    const closeSuggestions = () => {
        if (isOperator) return;

        errandContext.setWelcomeUserMessageState((prevState) => {
            return {
                ...prevState,
                userSuggestions: {
                    unmountStarted: true
                }
            }
        })
        removeCurrMorphTID.current = setTimeout(() => {
            errandContext.setMorphType(MorphType.None);
        }, 1000)
    }

    const suggestionTools = useMemo(() => ({
        open: openSuggestions,
        close: closeSuggestions
    }), [
        openSuggestions,
        closeSuggestions
    ]);
    // ===== END ======

    // ===== START =====
    // Render Hints Functions (For measuring)
    const renderMeasuringHints = () => {
        let className = 'measuringHintContainer';
        if (typeof renderData === 'string') {
            return renderData;
        }

        return renderData.options.map(({ html }, idx) => {

            return (
                <HintOption
                    suggestionTools={suggestionTools}
                    postClickHandler={handleWorkflowOrMorphType}
                    html={html}
                    index={idx + 1}
                    className={className}
                    animated={false}
                    measuredHeight={null}
                />
            );
        });
    }
    // ===== END =====

    // Measuring Hints logic.
    const measureContainerAdditionalStyle = { maxWidth: window.innerWidth > 900 ? 'min(60%, calc(100vw - 50px))' : 'min(90%, calc(100vw - 50px))' };
    // automatically measures the hints off the screen and returns dimensions (width, height)
    const { dimensions, isMeasured, renderOffScreen } = useMeasureOffScreen(renderMeasuringHints(), measureContainerAdditionalStyle);

    // ====== START ====== 
    // On Message In View Handlers/Logic
    const onMessageViewHandler = useCallback(() => {
        if (!allMounted && isMeasured === false) return;
        if (isOperator) return;

        // Handle in view status
        const msgId = props.message._id;
        InViewController.add(msgId);

        // errandContext.welcomeUserMessageState.welcomeUserMessage.inView
        errandContext.setWelcomeUserMessageState((prevState) => {
            return {
                ...prevState,
                welcomeUserMessage: {
                    ...prevState.welcomeUserMessage,
                    inView: true
                }
            }
        })
    }, [isMeasured, allMounted, isOperator])

    const onMessageLostHandler = useCallback(() => {
        if (!allMounted && isMeasured === false) return;
        if (isOperator) return;

        // Handle in view status
        const msgId = props.message._id;
        InViewController.remove(msgId);

        // errandContext.welcomeUserMessageState.welcomeUserMessage.inView
        errandContext.setWelcomeUserMessageState((prevState) => {
            return {
                ...prevState,
                welcomeUserMessage: {
                    ...prevState.welcomeUserMessage,
                    inView: InViewController.checkIfExistsAtLeastOneElement() // this will provide the proper visible status of the message because one message can be not visible while the other can.
                }
            }
        })
    }, [isMeasured, allMounted, isOperator])

    useInOutView(messageRef, onMessageViewHandler, onMessageLostHandler);
    // ====== END ====== 

    // ====== START ====== 

    // First Mount REVEAL main animation logic.
    useEffect(() => {
        if (typeof renderData === 'string') return;
        if (isFirstMount === false || isOperator || isCurrMsgLastInChat === false || consentGiven === false) return;
        // only when measured, trigger start of the animation
        // because if not measured the animation will be choppy
        if (isMeasured) {
            tmIdsRef.current.push(
                setTimeout(() => {
                    renderData.options.forEach((_, index) => {
                        tmIdsRef.current.push(
                            setTimeout(() => {
                                setNumOfShownElements((prev) => {
                                    // Increment the number of shown elements up to the current index
                                    return prev + 1;
                                });

                                // If this is the last element, set setAllMounted to true
                                if (index === renderData.options.length - 1) {
                                    tmIdsRef.current.push(
                                        setTimeout(() => {
                                            setAllMounted(true);
                                            setIsFirstMount(false);
                                        }, 800)
                                    );
                                }
                            }, index * 400)
                        );
                    });

                }, 500)
            );

            // Play moving circles animation only when measured.
            tmIdsRef.current.push(
                setTimeout(
                    () => {
                        setMovingCirclesShown(true);
                    },
                    300 // WHEN first item is expanded in list
                )
            )
        }

        return () => {
            tmIdsRef.current.forEach((tmID) => {
                clearTimeout(tmID);
            });
        }
    }, [isMeasured, dimensions, isFirstMount, isOperator, isCurrMsgLastInChat, consentGiven])

    // Used when multipleFooterTypingAnimations becomes null,
    // removes the moving circles gracefully (good fado out animation)
    useEffect(() => {
        if (!allMounted) return;
        if (errandContext.multipleFooterTypingAnimations === null) {
            setMovingCirclesShown(false);
            tmIdsRef.current.push(
                setTimeout(() => {
                    setMovingCirclesDisplayed(false);
                }, 1000)
            );
        }

        return () => {
            tmIdsRef.current.forEach((tmID) => {
                clearTimeout(tmID);
            });
        }
    }, [errandContext.multipleFooterTypingAnimations, allMounted])
    // ====== END ====== 

    const styles: Record<string, React.CSSProperties> = useMemo(() => ({
        containerStyle: {
            minWidth: '85px',
            borderColor: 'var(--orange700)',
            background: props.message.sentByCurrentUser ? 'var(--gray000)' : 'var(--peach600)',
            pointerEvents: 'all',
            padding: typeof renderData === 'string' ? '4px 9px' : ''
        },
        messageStackStyle: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            maxWidth: '100%',
            position: 'relative',
            width: '100%',
            paddingBottom: '6px',
            transition: 'all 0.5s ease'
        },
        messageTimeStackStyle: {
            marginRight: '10px',
            marginLeft: 'auto',
            marginTop: '0px',
            marginBottom: '0px',
            flexWrap: 'nowrap',
            backgroundColor: 'transparent',
        },
        topSectionStyle: {
            color: 'white',
            backgroundColor: '#FF7400',
            borderRadius: '8px 8px 0px 0px',
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: `${mediumMediaQueryResult ? '0.75rem' : '0.8rem'}`,
            width: '100%',
            paddingTop: '30px',
            paddingBottom: '7px',
            paddingLeft: '7px',
            paddingRight: '7px',
        }
    }), [props.message.sentByCurrentUser, mediumMediaQueryResult, renderData])

    const mainStackProps = useMemo(() => ({
        display: "flex",
        flexDirection: "column",
        alignItems:
            // On the user side, Team, and Group chats: only sender is on the right side
            // On the operator side, only sender (operator) and Morgan is on the right side
            props.message?.alignByCurrentUser ? 'flex-end' : 'flex-start',
        width: "fit-content",
        maxWidth: window.innerWidth > 900 ? 'min(60%, calc(100vw - 50px))' : 'min(90%, calc(100vw - 50px))',
        minWidth: '85px',
        paddingTop: typeof renderData === 'string' ? '0px' : '72px'
    }), [props.message?.alignByCurrentUser, window.innerWidth, renderData])

    const finalLogoStyle = useMemo(() => ({
        ...defaultLogoStyle,
        ...(isOperator ? operatorLogoStyle : userLogoStyle)
    }), [isOperator]) as React.CSSProperties;

    if (isMeasured === false) {
        return renderOffScreen;
    }

    if (!consentGiven) {
        return null;
    }

    const renderContent = () => {
        if (typeof renderData === 'string') {
            return (
                <ChatBubbleStyle
                    sx={styles.containerStyle}
                >
                    {`${renderData}`}
                    <MessageTime message={props.message} />
                </ChatBubbleStyle>
            )
        }

        return (
            <>
                <TopLogo
                    finalLogoStyle={finalLogoStyle}
                    onLogoClick={onLogoClick}
                    movingCirclesDisplayed={movingCirclesDisplayed}
                    movingCirclesShown={movingCirclesShown}
                />
                <ChatBubbleStyle
                    sx={styles.containerStyle}
                >
                    <div style={styles.topSectionStyle}>
                        <Sanitized html={renderData.title} />
                    </div>
                    <Stack flexDirection="column" width="100%">
                        <Stack flexDirection="row">
                            <Stack
                                alignItems="start"
                                sx={styles.messageStackStyle}
                                ref={messageRef}
                            >
                                {/* Main Content (List/Plain) and (Consent Part <if needed>) */}
                                <ContentContainer
                                    isFading={isFading}
                                    errand={props.errand}
                                    message={props.message}
                                    isOperator={isOperator}
                                    allMounted={allMounted}
                                    dimensions={dimensions}
                                    isFirstMount={isFirstMount}
                                    options={renderData.options}
                                    consentHtml={renderData.consent}
                                    suggestionTools={suggestionTools}
                                    numOfShownElements={numOfShownElements}
                                    isCurrMsgLastInChat={isCurrMsgLastInChat}
                                    handleWorkflowOrMorphType={handleWorkflowOrMorphType}
                                    currentActiveItem={errandContext.multipleFooterTypingAnimations?.currentActiveItem}
                                />
                            </Stack>
                        </Stack>
                    </Stack>
                </ChatBubbleStyle>
            </>
        )
    }

    return (
        <Stack {...mainStackProps}>
            <OnMountMessageWrapper firstMount={isFirstMount}>
                <Stack display="flex" flexDirection="column" sx={{ position: 'relative' }}>
                    {/* Top Logo Portion */}
                    {renderContent()}
                </Stack>
            </OnMountMessageWrapper>
        </Stack>
    );
};

export default WelcomeUserMessageContent;