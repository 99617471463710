import React from 'react';
import { MorganTheme, useTheme } from '@mui/material';
import { styled } from '@mui/system';
function ConversationsListSkeletonLoader() {
  // Keep track of the user's resolution to display different margins
  // const isDesktop = window.innerWidth > 900;
  const theme: MorganTheme = useTheme();

  // This creates the skeleton for the ConversationBody box which holds all the messages
  const MessageBox = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    marginTop: '15px',
  });

  // This is the div that holds the bubble skeletons
  const MessagesWrapper = styled('div')({
    marginTop: '10px',
    width: '100%',
    display: 'inline-block',
  });

  // This creates the circle skeleton to represent the person icon
  const IconSkeleton = styled('div')({
    backgroundColor: theme.palette.peach['600'],
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    display: 'inline-flex',
  });

  // This creates the bubble skeleton for particpants' name (Default: Morgan)
  const UserBubbleSkeleton = styled('div')({
    backgroundColor: theme.palette.peach['600'],
    minHeight: '24px',
    minWidth: '100px',
    width: '80%',
    marginLeft: '10px',
    borderRadius: '8px',
    alignSelf: 'end',
    display: 'inline-flex',
  });

  return (
    <MessageBox>
      <MessagesWrapper>
        <IconSkeleton />
        <UserBubbleSkeleton />
      </MessagesWrapper>
    </MessageBox>
  );
}

export default ConversationsListSkeletonLoader;
