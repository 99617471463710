import React from 'react';
import DocumentBubble from '@components/DocumentBubble';
import type { IErrand, IMessage } from '@interfaces/Conversation';

type TDocumentMessageContentProps = {
  errand: IErrand;
  message: IMessage;
};

const DocumentMessageContent = ({
  errand, message, 
}: TDocumentMessageContentProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {message.documents?.map((document, index) => (
        <DocumentBubble key={`${index}`} errand={errand} message={message} document={document} />
      ))}
    </div>
  );
};

export default DocumentMessageContent;
