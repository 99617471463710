import React, { useState, useEffect, useCallback, useRef} from 'react';
import Styles from '../Styles/MorphShareCustomLink.module.css';
import { useErrandContext } from '@contexts/ErrandContext';
import { useCustomLinkContext } from '@contexts/CustomLinkContext';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';
import useAbortController from "@common/hooks/useAbortController";
import useDebounce from '@common/hooks/useDebounce';
import useInitialMount from '@common/hooks/useInitialMount';
import { MorphType } from '@common/MorphType';
import ThinClientUtils from '@common/ThinClientUtils';
import { ScanOrClickToDownload } from '@assets/Icons';


const MorphShareCustomLink = ({cancelAction}) => {
    const { t } = useTranslation();
    const errandContext = useErrandContext();
    const customLinkContext = useCustomLinkContext();
    const [isWarningActive, setIsWarningActive] = useState<boolean>(false);
    const abortController = useAbortController();
    const emailWorkflowIdRef = useRef<string>(null);
    const smsWorkflowIdRef = useRef<string>(null);
    const [selectedEmail, setSelectedEmail] = useState(customLinkContext.shareCustomLinkMethod === 'email');


    const handleWarning = () => {
        setIsWarningActive(true)
        setTimeout(() => {
            setIsWarningActive(false)
        }, 2000)
    }

    type Workflow = {
        _id: string;
        name: string;
    }

    // If the user clicks on the share icon during an action, this will cancel the Action bar first so 
    // there is no confusion with the buttons in the footer (action vs morphed footer)
    const handleCancelAction = () => {
        cancelAction();
        errandContext.setMorphType(MorphType.ShareCustomLink);
    }

    // Find the workflow id upon rendering so that user can click 'Send' immediately
    const handleInitial = () => {
        handleCancelAction();
        setTimeout(() => {
            fetchWorkflowId(customLinkContext.shareCustomLinkMethod);
        }, 500)
    }

    useInitialMount(() => handleInitial());

    const fetchWorkflowId = async (method: string) => {
        let searchString: string;
        if (method === 'email') {
            searchString = 'Share Custom Link With Email'
        } else {
            searchString = 'Share Custom Link With SMS'
        }

        return new Promise < Array < Workflow >> (async (resolve, reject) => {
            try {
                const config = abortController.get();
                let request = {
                    url: 'workflow/db/search?active=true&fields=_id,name&limit=1',
                    method: 'post',
                    data: {
                        search: searchString
                    }
                };
                // Only run this call if we need to
                if ((method === 'email' && !emailWorkflowIdRef.current) || (method === 'sms' && !smsWorkflowIdRef.current)) {
                    const methodWorkflow = await axiosCall(request, config);
                    resolve(methodWorkflow);

                    if (methodWorkflow) {
                        if (method === 'email') {
                            emailWorkflowIdRef.current = methodWorkflow[0]._id
                        } else {
                            smsWorkflowIdRef.current = methodWorkflow[0]._id                            
                        }
                    }
                    
                }

                if (method === 'email') {
                    customLinkContext.shareCustomLinkInfoRef.current = { _id: emailWorkflowIdRef.current, type: 'workflow', name: method }
                } else {
                    customLinkContext.shareCustomLinkInfoRef.current = { _id: smsWorkflowIdRef.current, type: 'workflow', name: method }
                }
                
            } catch (e) {
                console.error(e)
                reject(e);
            }
        })
    }

    const handleSetMethod = (method: string) => {
        setSelectedEmail(method === 'email')
        customLinkContext.setShareCustomLinkMethod(method);
    }
    
    useDebounce(() => fetchWorkflowId(selectedEmail ? 'email' : 'sms'), 500, [selectedEmail])

    /* On click handler for downloading a QR Code */
    const handleClickDownload = useCallback(() => {
    // Get the URL of the image
    const imageUrl = customLinkContext.qrImgSrc.match(/url\((.*?)\)/)[1];
    // Create a link element
    const link = window.document.createElement('a');
    link.href = imageUrl;
    link.download = 'QRCode.png';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    if (ThinClientUtils.isThinClient()){
        window.dispatchEvent(new CustomEvent('documentDownload', {
            detail: {
            key: 'documentDownload',
            url: imageUrl,
            mimeType: 'image/png',
            filename: 'QRCode.png',
            }
        }));
    }

}, [customLinkContext.qrImgSrc]);

    return (
        <div className={Styles.shareCustomLinkContainer}>
            <div className={Styles.qrCircle1} title={t('shareCustomLink')}>
                <ScanOrClickToDownload />
                <div className={Styles.qrCircle2} aria-hidden>
                    <div className={Styles.qrCircle3}>
                        <div className={Styles.qrCircle4}>
                            <div className={Styles.qr} style={{backgroundImage: customLinkContext.qrImgSrc}} onClick={handleClickDownload}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Styles.copyIcon}>
                <Tooltip title={isWarningActive ? t('tCopied') : t('copyToClipboard')}>
                    <ContentCopyIcon
                    className='contentCopyRoundedIcon'
                    onClick={() => {
                        navigator.clipboard.writeText(customLinkContext.customLink);
                        handleWarning();
                    }}
                    />
                </Tooltip>
            </div>
            <div className={Styles.line} />
            <div className={Styles.urlBox}>
                {customLinkContext.customLink}
            </div>
            <div className={Styles.indentSvg}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 40 8" width="192" height="38">
                    <path d="M 3 0 L 8 0 L 11 0 C 13 0 14 0 15 2 C 16 4 18 4.3 19 4.32 C 20 4.3 22 4 23 2 C 24 0 25 0 27 0 L 30 0 L 35 0 A 1 1 0 0 1 35 6 L 3 6 A 1 1 0 0 1 3 0"
                        stroke="var(--orange500)" strokeWidth="0.25" fill="var(--gray000)"/>
                </svg>
            </div>

            <div className={Styles.indentContainer}>
                <div className={selectedEmail && Styles.selected} onClick={() => handleSetMethod('email')}>Email</div>
                <div className={!selectedEmail && Styles.selected} onClick={() => handleSetMethod('sms')}>SMS</div>
                <div className={`${Styles.underline} ${selectedEmail ? Styles.underline1 : Styles.underline2}`} />
            </div>
        </div>
    )
}

export default MorphShareCustomLink