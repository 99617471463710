import { INITIAL_CLASS_LIST } from '../Constants';
import { ActivityData, IActivityOptionalDataStorage } from '../interfaces';
import { ELEMENTS_ADDITIONAL_STYLES } from './AdditionalStyles';
import { ALL_ELEMENTS, MAIN_ELEMENTS, WIDGET_ELEMENTS } from './Layouts';

export const INITIAL_ACTIVITY_OPTIONAL_DATA_STORAGE: IActivityOptionalDataStorage = {
  [ALL_ELEMENTS.Order]: null,
};

// Creator function for activity data object
const _createActivityData = (id): ActivityData => {
  const additionalStyles = ELEMENTS_ADDITIONAL_STYLES?.[id];
  
  return {
    id: id,
    data: {},
    isShown: false,
    classList: [...INITIAL_CLASS_LIST],
    fullWidth: false,
    additionalStyles: additionalStyles ?? {}
  };
};

const createInitialStateFrom = (elementsIDs) => {
  return Object.keys(elementsIDs).map((activityId) => _createActivityData(activityId));
}

// Initial activity arr state
// using arr so that we can add more activities in the future features
// structure: {id: string, data: any, isShown: boolean, classList: string[], fullWidth: boolean}
// Add any other new activities to this arr to make it included in the activity tracker
const INITIAL_ACTIVITY_ARR_WIDGET_STATE = createInitialStateFrom(WIDGET_ELEMENTS);
const INITIAL_ACTIVITY_ARR_MAIN_STATE = createInitialStateFrom(MAIN_ELEMENTS);

export const getInitialArrState = (isWidget: boolean = false) => {
  if(isWidget) return INITIAL_ACTIVITY_ARR_WIDGET_STATE;
  else return INITIAL_ACTIVITY_ARR_MAIN_STATE;
}

export const getInitialArrStateForMeasuring = () => createInitialStateFrom(ALL_ELEMENTS);

export const INITIAL_ALL_ACTIVITIES_ARR_STATE = [...INITIAL_ACTIVITY_ARR_MAIN_STATE, ...INITIAL_ACTIVITY_ARR_WIDGET_STATE];