import React from 'react';
import Sanitized from "@components/Sanitized";
import { useAppContext } from "@contexts/AppContext";
import { useTheme, useMediaQuery } from "@mui/material";
import MessageTextStyle from "@styles/MessageTextStyle";
import { useEffect, useMemo } from "react";
import { styled, keyframes } from '@mui/system';
import { useErrandContext } from '@contexts/ErrandContext';
import { useUserContext } from '@contexts/user';
import { _localStorageTools } from './localStorageTools';
import { SlideInAnimationBox } from './utils';

function extractHashtag(htmlStr) {
    // Use a regular expression to find a word starting with '#'
    const match = htmlStr.match(/#(\w+)/);
    // If a match is found, return the matched word prefixed with '#'
    if (match) {
        return `#${match[1]}`;
    }
    // If no match, return null or an empty string (depending on your use case)
    return null;
}

const HintOption = (props) => {
    const theme = useTheme();
    const mediumMediaQueryResult = useMediaQuery(theme.breakpoints.up('md'));
    const { suggestionTools } = props;
    const errandContext = useErrandContext();
    const { setWelcomeUserMessageState } = errandContext;
    const { isOperator } = useUserContext();

    const resetChosenHintIndexRef = React.useRef(null);

    useEffect(() => {
        if (errandContext.welcomeUserMessageState.welcomeUserMessage.userChosenHintIndex === -1
            || errandContext.welcomeUserMessageState.welcomeUserMessage.userChosenHintIndexLockedRef.current === true) return;

        // process the user hint chosen index
        if (errandContext.welcomeUserMessageState.welcomeUserMessage.userChosenHintIndex === props.index) {
            errandContext.welcomeUserMessageState.welcomeUserMessage.userChosenHintIndexLockedRef.current = true;
            onClickHandler();
            // set timeout to reset the userChosenHintIndex to -1
            resetChosenHintIndexRef.current = setTimeout(
                () => {
                    errandContext.welcomeUserMessageState.welcomeUserMessage.userChosenHintIndexLockedRef.current = false;
                    setWelcomeUserMessageState((prevState) => ({
                        ...prevState,
                        welcomeUserMessage: {
                            ...prevState.welcomeUserMessage,
                            userChosenHintIndex: -1
                        }
                    }))
                }, 300);
        }
    }, [errandContext.welcomeUserMessageState.welcomeUserMessage.userChosenHintIndex])

    // On Unmount
    useEffect(() => {
        return () => {
            clearTimeout(resetChosenHintIndexRef.current);
        }
    }, [])

    const styles: Record<string, React.CSSProperties> = useMemo(() => (
        {
            pointNumberStyle: {
                color: 'white',
                position: 'absolute',
                left: '-10px',
                backgroundColor: 'rgb(255, 116, 0)', // or you can use the hex value '#FF7400'
                width: '18px',
                height: '18px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '10px',
                border: '1px solid #FFF3EE',
                fontFamily: 'Poppins',
                fontSize: `${mediumMediaQueryResult ? '0.75rem' : '0.8rem'}`,
                fontWeight: '500'
            }
        }
    ), [mediumMediaQueryResult]);

    const onClickHandler = async () => {
        if (isOperator) return;

        const extractedLink = extractHashtag(props.html);
        if (extractedLink) await props.postClickHandler(extractedLink);
        else suggestionTools.open(); // remove tags from string.
        // if still empty
        if(_localStorageTools.getFromLocalStorage(_localStorageTools.KEYS.WERE_SUGGESTIONS_ALREADY_SHOWN_KEY) === null ) {
            // mark as done
            _localStorageTools.setInLocalStorage(_localStorageTools.KEYS.WERE_SUGGESTIONS_ALREADY_SHOWN_KEY, JSON.stringify(true));
        }
    }
    
    if (props.measuredHeight) {
        if (props.animated) {
            return (
                <SlideInAnimationBox
                    onClick={onClickHandler}
                    className={`${props.className}`}
                    height={props.measuredHeight}
                >
                    <div style={styles.pointNumberStyle}>
                        {props.index}
                    </div>
                    <MessageTextStyle>
                        <Sanitized html={props.html} />
                    </MessageTextStyle>
                </SlideInAnimationBox >
            );
        } else {
            return (
                <div
                    onClick={onClickHandler}
                    className={`${props.className}`}
                    style={{ height: `${props.measuredHeight}px` }}
                >
                    <div style={styles.pointNumberStyle}>
                        {props.index}
                    </div>
                    <MessageTextStyle>
                        <Sanitized html={props.html} />
                    </MessageTextStyle>
                </div >
            );
        }
    } else {
        return (
            <div
                onClick={onClickHandler}
                className={props.className}
            >
                <div style={styles.pointNumberStyle}>
                    {props.index}
                </div>
                <MessageTextStyle>
                    <Sanitized html={props.html} />
                </MessageTextStyle>
            </div >
        );
    }
}

export default HintOption;