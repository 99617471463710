/**
 * @author maverick.manasco
 * @date 07/21/2023
 * Styles to be used in conjunction with the cooresponding component.
 *
 * Styles should always be defined in their own file to prevent them from being defined within
 * funcitonal components and causing additional renders.
 * Classes should be used over props to keep static css and dynamic html. This will result in
 * better performance akin to css modules while allowing us to take advantage of mui components
 * When possible, don't uses Stacks or Boxes and define all styles by nesting tags or classes.
 * The css here should mirror the JSX as close as possible.
 * Only create multiple style components when you need to take advantage of an existing mui
 * component that will add valuable styles or functionality.
 */

import { MorganTheme } from '@mui/material';
import { styled } from '@mui/system';
const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';

type TStyleProps = {
  theme?: MorganTheme; // NOTICE: Included by default with mui styled
};

export const Style = styled('div')<TStyleProps>(
  ({ theme }) => `
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  .morphErrandSwiper {
    background-color: var(--peach300);
    border: 1px solid var(--orange700);
    border-radius: 8px;
    padding: 0.5rem;
    min-width: 220px;
    width: fit-content;
    max-width: 350px;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .morphErrandSwiper::-webkit-scrollbar {
    display: none;
  }
  .morphErrandSwiper > div {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-family: ${theme.typography.fontFamily};
    justify-content: center;
    width: fit-content;
  }
  .date,
  .details {
    border: 1px solid var(--peach900);
    background-color: var(--yellow050);
    color: black;
    border-radius: 8px;
    overflow: hidden;
    text-align: center;
    user-select: none;
    transition: 500ms all linear;
    cursor: pointer;
  }
  .date:focus,
  .date:hover,
  .details:focus,
  .details:hover {
    outline: 1px solid;
  }
  .date {
    width: 32px;
  }
  .details {
    min-width: 155px;
    width: 155px;
  }
  .details > div:nth-of-type(1) {
    display: none;
  }
  .date > div:nth-of-type(2) {
    display: none;
  }
  .details > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .details > div:nth-of-type(2) > div:nth-of-type(1) {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 155px;
    white-space: nowrap;
  }
  .details > div:nth-of-type(2) > div:nth-of-type(2) {
    font-size: 9px;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 155px;
    min-height: 27px;
  }
  .details > div:nth-of-type(2) > div:nth-of-type(3) {
    width: 100%;
    background-image: url(${WoodGrain});
    background-color: var(--orange050);
    color: var(--gray000);
    font-size: 9px;
    text-transform: uppercase;
  }
  .date > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding: .5rem;
  }
  .date > div:nth-of-type(1) > strong {
    font-size: 22px;
  }
  .date > div:nth-of-type(1) > span {
    font-size: 9px;
  }
  Swiper {
    display: flex;
    position: absolute;
    width: 100%;
    height: fit-content;
    z-index: 5;
    border-radius: 30px;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    scrollbar-width: none;
    transform: translate(0, -2rem);
    gap: 8px;
  }
  & > div:nth-of-type(2) {
    position: absolute;
    text-align: center;
    width: 110px;
    bottom: 2px;
    z-index: 2;
    margin-top: -8px;
    font-size: 10px;
    color: var(--peach900);
    animation: increase-width 2s linear forwards;
    @keyframes increaseWidth: {
      from: {
        width: 110px;
      },
      to: {
        width: 151px;
      }
    }
  }
  .noClosedErrands{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
  }

  .noArchiveIcon{
    margin-right: 10px;
  }
  `
);
