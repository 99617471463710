import React from 'react';
import { _localStorageTools } from './localStorageTools';
import { Grow, keyframes, styled } from '@mui/material';

interface ParsedMsgRenderData {
  title?: string;
  options?: { html: string }[];
  consent?: {
    accepted: string,
    initial: string
  };
}

interface ErrorStatus {
  error?: boolean;
}

// Create dynamic keyframes based on the passed height
const expandAnimation = (height) => keyframes`
  to {
    opacity: 1;
    height: ${height}px; !important;
  }
`;

// Create dynamic keyframes based on the passed height
const slideInAnimation = (height) => keyframes`
  to {
    opacity: 1;
    transform: translateX(0px);
    height: ${height}px; !important;
  }
`;

// Create a styled component with dynamic keyframes
const ExpandAnimationBox = styled('div')<{
  height: number; // Specify that height is a number
}>(({ height }) => ({
  animation: `${expandAnimation(height)} 0.6s forwards`,
}));

// Create a styled component with dynamic keyframes
const SlideInAnimationBox = styled('div')<{
  height: number; // Specify that height is a number
}>(({ height }) => ({
  animation: `${slideInAnimation(height)} 0.6s forwards`,
}));


// extracts string from data-instructions-text attribute
// which has an instruction to show in multiTypingFooterAnimation component
function extractCustomInstruction(htmlString) {
  // Create a temporary DOM element to parse the string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Find the element with the custom attribute
  const spanElement = tempDiv.querySelector('[data-instructions-text]');

  // Return the value of the custom attribute if it exists
  return spanElement ? spanElement.getAttribute('data-instructions-text') : null;
}

// msg here is strigified JSON string
const parseMessageRenderData = (msg: string): ParsedMsgRenderData & ErrorStatus => {
  try {
    const parsed = JSON.parse(decodeURIComponent(msg)) as ParsedMsgRenderData;
    // save to init store, mainly used for lang translations
    initMessageStateBuffer.set(parsed);
    console.log("Initial parsed!", parsed)
    return parsed;
  } catch (err) {
    return { title: 'Some error occured on our end. Please bear with us for a moment.', options: [], error: true };
  }
};

// Used for saving the initial state for the message (mainly for translation logic part)
const initMessageStateBuffer = (function () {
  let _data: ParsedMsgRenderData | null = null;

  const set = (data: ParsedMsgRenderData) => {
    _data = data;
  };

  const get = () => _data;

  return {
    get,
    set,
  };
})();

const OnMountMessageWrapper = (props) => {
  return (
    <Grow in={true} timeout={props.firstMount === true ? 1000 : 0}>
      {props.children}
    </Grow>
  );
};

const getFirstMountValueFromStorage = () =>
  _localStorageTools.getFromLocalStorage(_localStorageTools.KEYS.IS_FIRST_MOUNT_STORAGE_KEY);

const isLastMsg = (_lastId, _currId) => {
  return _lastId === _currId;
};

const getAllMountedInitValue = (isCurrMsgLastInChat, isFirstMountValue, isOperator) => {
  if (isOperator) {
    return true;
  }

  if (isCurrMsgLastInChat === true) {
    if (isFirstMountValue === null) {
      return false;
    }

    const isFirstMount = _localStorageTools.strBoolean(isFirstMountValue);
    if (isFirstMount) {
      // if its the first mount
      // return false (allMounted is set to false) as it is not mounted yet
      return false;
    } else {
      // if not first mount (was mounted before)
      // return true (meaning allMounted will be set to true) and no animation played
      return true;
    }
  } else {
    // if not the last message in chat
    return true; // meaning allMounted will be set to true (no animation played)
  }
};

const getIsFirstMountValue = (isCurrMsgLastInChat, isFirstMountValue, isOperator) => {
  if (isOperator) {
    return false;
  }
  // return isCurrMsgLastInChat === true ? (isFirstMountValue === null) ? true : (_localStorageTools.strBoolean(isFirstMountValue)) : false
  if (isCurrMsgLastInChat === true) {
    if (isFirstMountValue === null) {
      return true; // if no value available in storage, return true, meaning it is a first mount
    }

    const isFirstMount = _localStorageTools.strBoolean(isFirstMountValue);
    if (isFirstMount) return true; // if its a first mount (true), return true
    else return false; // if false, meaning it was mounted before, return false
  } else {
    return false; // if its not the last msg in chat, return false (meaning it is not the first mount)
  }
};

export {
  extractCustomInstruction,
  parseMessageRenderData,
  initMessageStateBuffer,
  ParsedMsgRenderData,
  ErrorStatus,
  getFirstMountValueFromStorage,
  isLastMsg,
  getAllMountedInitValue,
  getIsFirstMountValue,
  OnMountMessageWrapper,
  slideInAnimation,
  ExpandAnimationBox,
  SlideInAnimationBox
};