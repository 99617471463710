import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Terms = styled(Typography)(() => ({
  fontSize: '9px',
  color: 'var(--gray810)',
  textAlign: 'center',
  width: '85%',
  maxWidth: '800px',
  zIndex: '1',
  marginBottom: '15px',
  '& a': {
    color: 'var(--gray810)',
    '&:hover': {
      color: 'var(--gray950)0EE',
    },
  },
}));
