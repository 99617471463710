import Allign from '@styles/Allign'
import { Box, styled } from '@mui/system';
import { Stack, Typography, Icon } from '@mui/material';
const InviteBubble = process.env.REACT_APP_MORGAN_CDN + '/Images/InviteBubble.svg';

export const BubbleWrapper = styled(Box)({
    height: "85px",
    width: "200px",
    position: "relative",
    marginTop: "18px",
    marginLeft: "-25px"
  })
  
export const Bubble = styled(Box)({
    content: `url(${InviteBubble})`,
    position: "absolute",
    bottom: "0",
    right: "0",
    boxShadow: "var(--shadow100) 0px 5px 35px"
  });
  
export const ContactInitials = styled(Box)({
    fontFamily: 'var(--typographyFontFamily)',
    fontWeight: "600",
    fontSize: "24px"
  })
  
export const StyledIcon = styled(Icon)({
    height: "25px",
    width: "25px",
  });
  
export const ContactCircle = styled(Box)({
    borderRadius: "50%",
    position: "absolute",
    top: "0",
    left: "25px",
    width: "65px",
    height: "65px",
    backgroundColor: "var(--gray000)",
    border: "1px solid var(--orange700)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "var(--orange050)",
    fontWeight: "600",
    boxShadow: "var(--shadow100) 0px 5px 35px",
  });
  
export const DeclineCircle = styled<any>(Box)({
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    fontSize: "42px",
    margin: "auto",
    height: "60px",
    width: "60px",
    borderRadius: "50%"
  });
  
export const PulseCircle = styled(Box)({
    height: "60px",
    width: "60px",
    borderRadius: "50%"
  });
  
export const Pulse = styled('span')({
    display: 'flex',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    background: 'transparent',
    boxShadow: '0 0 0',
    animation: 'pulse 2s infinite',
  
    '@-webkit-keyframes pulse': {
      '0%': {
        'WebkitBoxShadow': '0 0 0 0 var(--shadow403)'
      },
      '70%': {
          'WebkitBoxShadow': '0 0 0 15px var(--shadow000)'
      },
      '100%': {
          'WebkitBoxShadow': '0 0 0 0 var(--shadow000)'
      }
    },
  
    '@keyframes pulse': {
      '0%': {
        'MozBoxShadow': '0 0 0 0 var(--shadow403)',
        'boxShadow': '0 0 0 0 var(--shadow402)'
      },
      '70%': {
          'MozBoxShadow': '0 0 0 15px var(--shadow000)',
          'boxShadow': '0 0 0 15px var(--shadow000)'
      },
      '100%': {
          'MozBoxShadow': '0 0 0 0 var(--shadow000)',
          'boxShadow': '0 0 0 0 var(--shadow000)'
      }
    }
  
  })
  
export const DialingCircle = styled<any>(Box)({
    position: 'absolute',
    top: '-1px',
    textAlign: "center",
    alignItems: "center",
    alignContent: "center",
    fontSize: "42px",
    margin: "-10px",
    width: "135%",
    borderRadius: "50%",
  });
  
export const DividerLine = styled(Box)({
    backgroundColor: "var(--peach500)",
    width: "33px",
    height: "2px",
    marginTop: "-8px",
    marginBottom: "-10px",
    borderRadius: "1px"
  });
  
export const ContactBox = styled(Stack)({
    position: "absolute",
    bottom: "0",
    right: "7px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-end",
    color: "black",
    zIndex: "1",
    textAlign: "center",
    height: "55px",
    fontSize: "11px",
    fontFamily: 'var(--typographyFontFamily)',
    fontWeight: "500"
  });
  
export const ContactDivider = styled(Box)({
    backgroundColor: "var(--peach500)",
    width: "88px",
    height: "2px",
    borderRadius: "1px"
  });
  
export const AuthorAlign = styled(Allign)({
    position: "absolute",
    right: "0",
    top: "10px",
    marginBottom: '1px',
    justifyContent: 'flex-start',
  });
  
export const AuthorTypography = styled(Typography)({
    fontSize: '0.75em',
    fontWeight: '700',
    textTransform: 'capitalize'
  });