import React from 'react';
import { styled } from '@mui/system';

import Icon from "./Icon";

const IconForeground = styled(Icon)(({ theme }) => `
  width: 33px;
  background-color: ${theme.palette.orange['700']};
  border: 2px solid ${theme.palette.peach['600']};
  display: flex;
  justify-content: center;
  align-items: center;
`);

export default IconForeground;