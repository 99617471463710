// Lookup table for console module names - the values are used for translation (e.g., for breadcrumbs and links), so
// this mapping makes the code a little more semantic as the keys represent what the subsection is about
export const sectionNameTranslationKeys = {
  Admin: 'tAdmin',
  System: 'tSystem',
  AI: 'AI',
  Operators: 'tOperators',
  Users: 'tUsers',
  Workflows: 'tWorkflows',
  Actions: 'tActions',
  FieldAttributes: 'fieldAttributes',
  SMSTemplates: 'smsTemplates',
  EmailTemplates: 'emailTemplates',
  Products: 'tProducts',
  Orders: 'tOrders',
  HelpTips: 'helpTips',
  HelpTipGroups: 'helpTipGroups',
  'E-Forms': 'E-Forms',
  CallsToAction: 'callsToAction',
};

export const settingsSectionNameTranslationKeys = {
  Settings: 'tSettings',
  'System Contacts': 'systemContacts',
  'Management Contacts': 'managementContacts',
  Blacklist: 'blacklistTableName',
  'Prize Rates': 'prizeRates',
};

export const aiSectionNameTranslationKeys = {
  Settings: 'tSettings',
  Tasks: 'tTasks',
  Intents: 'tIntents',
  Entities: 'tEntities',
  Functions: 'tFunctions',
};
