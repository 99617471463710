import React from 'react';
import { styled } from '@mui/system';
const earImg = process.env.REACT_APP_MORGAN_CDN + '/Images/Ear-Animation-transparent.gif';

const EarImg = styled('img')({
    position: 'absolute',
    width: '202px',
    bottom: '-67px',
    zIndex: '0',
    animation: 'moveUp 1s cubic-bezier(0, 1.5, 0.8, 1) forwards',     
    '@keyframes moveUp': {
        'from': {
            transform: 'translateY(0px)',
            opacity: '0'
        },
        'to': {
            transform: 'translateY(-45px)',
            opacity: '1'
        },
    },
})

const MorphRecording = () => {

    return (
        <EarImg src={earImg} />
    )
}

export default MorphRecording