import {
  Box,
  Button,
  ButtonGroup,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
  MorganTheme,
  Snackbar,
} from '@mui/material';
import React, { useCallback, useState, PropsWithChildren, FC } from 'react';

import ActionListBadge from '@components/ActionListBadge';
import CloseIcon from '@mui/icons-material/Close';
import InfiniteScroll from 'react-infinite-scroller';
import RefreshIcon from '@mui/icons-material/Refresh';
import ScrollBox from './ScrollBox';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axiosCall from '@services/axios';
import { useTranslation } from 'react-i18next';
import { useSocketContext } from '@contexts/socket';
import useLoadActions from '@common/hooks/useLoadActions';
import useLoadWorkflows from '@common/hooks/useLoadWorkflows';
import useAbortController from '@common/hooks/useAbortController';
import useDebounce from '@common/hooks/useDebounce';
import PullCreditMenu from '@components/PullCreditMenu/PullCreditMenu';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

/**
 * This component makes up the Actions and Workflows list seen on the right most side of the
 * Console Conversation screen. This component is responsible for getting the set of Actions
 * and Workflows.
 * TODO: Type-safe all variables.
 */
const LIMIT = 15;
const ActionList: FC<PropsWithChildren<any>> = (props) => {
  const { messagesSocket } = useSocketContext();
  const theme: MorganTheme = useTheme();

  /* State information */
  // Which tab is active ("consoleConversationsQuickRequests" or "consoleConversationsWorkflows")
  const [selectedTab, setSelectedTab] = useState('consoleConversationsQuickRequests');
  // Whether or not the "Send to SunSoft" dialog is visible
  const [dialogState, setDialogState] = useState(false);
  const [pullCreditMenuOpen, setPullCreditMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [workflowCancelButtonShaking, setWorkflowCancelButtonShaking] = useState(false);

  const [search, setSearch] = useState('');
  const [searching, setSearching] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { i18n, t } = useTranslation();
  const { reset } = useAbortController();

  const closeDialog = () => {
    setDialogState(false);
  };
  const openDialog = () => {
    setDialogState(true);
  };

  const togglePullCreditMenu = useCallback(() => {
    setPullCreditMenuOpen((prev) => !prev);
  }, []);

  const cancelAction = useCallback(async () => {
    try {
      const config = reset();
      await axiosCall(
        {
          url: `userAction/${props.chatId}/cancel`,
          method: 'post',
        },
        config
      );
      messagesSocket.current?.emit('emit-chat-event', {
        chatId: props.chatId,
        data: {
          type: 'cancel-action',
        },
        recipients: [],
      });
    } catch {
      setWorkflowCancelButtonShaking(true);
    }
  }, [reset]);

  const {
    actions,
    setActions,
    loadActions,
    hasMoreActions,
    setHasMoreActions,
    actionsOffset,
    setActionsOffset,
    translatedActionNames,
    setActionSearchString,
  } = useLoadActions(props.chatId, props.chatFields, props.setChatFields, selectedTab);
  const {
    workflows,
    setWorkflows,
    loadWorkflows,
    hasMoreWorkflows,
    setHasMoreWorkflows,
    workflowsOffset,
    setWorkflowsOffset,
    translatedWorkflowNames,
    setWorkflowSearchString,
  } = useLoadWorkflows(selectedTab);

  const handleRefreshValues = async () => {
    // Clear the search text field
    setSearch('');

    // Call the function you want to load the data when the text field is cleared
    if (selectedTab === 'consoleConversationsQuickRequests') {
      await loadActions();
    } else {
      await loadWorkflows();
    }
  };

  const handleInputChange = useCallback(
    (event) => {
      const newSearch = event.target.value;
      // Handle the case when the text field is cleared
      if (newSearch === '') {
        handleRefreshValues();
      }

      // Continue with your existing logic
      // (You may debounce/throttle this call if needed)
      setSearching(newSearch.length > 0);
      // Update search based on the selected tab
      selectedTab === 'consoleConversationsQuickRequests'
        ? setActionSearchString(newSearch)
        : setWorkflowSearchString(newSearch);

      // Update the search value in the state
      setSearch(newSearch);
    },
    [selectedTab]
  );

  const getNextActionChunk = async (config: AbortController) => {
    // Get the next chunk
    let nextActions = await axiosCall(
      {
        url: `action?limit=${LIMIT}&offset=${
          actionsOffset * LIMIT
        }&type=Field&active=true&visibility.operator=true&fields=_id,description,fieldName,actionIcon`,
      },
      config
    );
    return nextActions;
  };

  const getNextWorkflowChunk = async (config: AbortController) => {
    // Get the next chunk
    const nextWorkflows = await axiosCall(
      {
        url: `workflow?limit=${LIMIT}&offset=${
          workflowsOffset * LIMIT
        }&active=true&visibility.operator=true&fields=_id,name,workflowIcon`,
      },
      config
    );
    return nextWorkflows;
  };

  useDebounce(
    () => {
      setWorkflowCancelButtonShaking(false);
    },
    500,
    [workflowCancelButtonShaking]
  );

  function renderPullCreditMenu() {
    if (!pullCreditMenuOpen) {
      return;
    }

    return (
      <PullCreditMenu togglePullCreditMenu={togglePullCreditMenu} setActionListSnackbarMessage={setSnackbarMessage} />
    );
  }

  function handleSnackbarClose() {
    setSnackbarMessage('');
  }

  return (
    <>
      <Snackbar
        open={!!snackbarMessage}
        message={snackbarMessage}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      />

      <Dialog open={dialogState} onClose={() => setDialogState(false)}>
        <Box sx={{ display: 'flex', width: '100%', backgroundColor: theme.palette.peach['900'] }}>
          <Typography sx={{ flex: 1, display: 'flex', padding: '10px', color: 'var(--gray000)' }}>
            {t('actionListTransfer')}
          </Typography>
          <IconButton onClick={closeDialog} sx={{ color: 'var(--gray000)' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ height: 300, width: 400 }}>
          <Typography>
            {/* TODO: This needs to be replaced with the actual content and to be dynamic */}
            Coming soon.
          </Typography>
        </DialogContent>
      </Dialog>

      {renderPullCreditMenu()}

      <CardContent sx={{ height: '100%' }}>
        <Stack sx={{ height: '100%' }} spacing={2}>
          <Button
            disabled={true}
            variant={'contained'}
            sx={{
              color: 'var(--gray000)',
              animation: workflowCancelButtonShaking ? 'shake 0.8s linear' : 'none',
              '@keyframes shake': {
                '0%, 100%': {
                  transform: 'translateX(0)',
                },
                '10%, 30%, 50%, 70%, 90%': {
                  transform: 'translateX(-10px)',
                },
                '20%, 40%, 60%, 80%': {
                  transform: 'translateX(10px)',
                },
              },
            }}
            onClick={cancelAction}
          >
            {t('tCancelWorkflow')}
          </Button>
          {/* Quick Request / Workflow selector */}
          <ButtonGroup size="small" fullWidth>
            <Button
              variant={selectedTab === 'consoleConversationsQuickRequests' ? 'contained' : 'outlined'}
              sx={{
                color: selectedTab === 'consoleConversationsQuickRequests' ? 'var(--gray000)' : theme.palette.peach['900'],
              }}
              onClick={(event) => {
                setSelectedTab('consoleConversationsQuickRequests');
                handleInputChange(event);
              }}
            >
              {t('actionListQuickRequest')}
            </Button>
            <Button
              variant={selectedTab === 'consoleConversationsWorkflows' ? 'contained' : 'outlined'}
              sx={{
                color: selectedTab === 'consoleConversationsWorkflows' ? 'var(--gray000)' : theme.palette.peach['900'],
              }}
              onClick={(event) => {
                setSelectedTab('consoleConversationsWorkflows');
                handleInputChange(event);
              }}
            >
              {t('actionListWorkflow')}
            </Button>
          </ButtonGroup>

          {/* Quick Request / Workflow search bar */}
          <Stack direction="row">
            <TextField
              label={t('actionListSearch')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              size="small"
              value={search}
              onChange={handleInputChange}
            />
            {/* Reload action */}
            <IconButton onClick={handleRefreshValues}>
              <RefreshIcon />
            </IconButton>
          </Stack>

          {/* Scroll region */}
          {/* This is the list of Quick Requests */}
          <ScrollBox
            sx={{
              height: '100%',
              flex: '1',
              display: selectedTab === 'consoleConversationsQuickRequests' ? 'unset' : 'none',
            }}
          >
            <InfiniteScroll
              loadMore={async () => {
                if (!isLoading && !searching && hasMoreActions && selectedTab === 'consoleConversationsQuickRequests') {
                  // Lock the loading and update offset
                  setIsLoading(true);
                  if (actions?.length >= LIMIT) {
                    setActionsOffset((prev) => {
                      return prev + 1;
                    });
                  }
                  const config = reset();
                  const more = await getNextActionChunk(config);
                  setHasMoreActions(more.length === LIMIT); // If its less than our requested amount we know we almost have it all
                  setActions((prev) => [...prev, ...more]);
                  // Release
                  setIsLoading(false);
                }
              }}
              initialLoad={false}
              hasMore={hasMoreActions}
              useWindow={false}
              threshold={50}
              loader={
                isLoading &&
                hasMoreActions && (
                  <Stack key={actions?.length + 1} justifyContent="center" alignItems="center">
                    <CircularProgress size="2em" />
                  </Stack>
                )
              }
            >
              {actions.length > 0 && //Not translated
              (i18n.language === 'en' || translatedActionNames.length === 0)
                ? actions.map((action, index) => (
                    <ActionListBadge
                      action
                      onDropActionWorkflow={props.onDropActionWorkflow}
                      chatId={props.chatId}
                      key={index}
                      id={action?._id}
                      name={action.description}
                      icon={action.actionIcon}
                      fields={action?.fields}
                      contextFields={action?.contextFields}
                      errand={props.errand}
                      setErrands={props.setErrands}
                    />
                  ))
                : // Translated
                  actions.map((action, index) =>
                    translatedActionNames[index] === null ? (
                      <ActionListBadge
                        action
                        onDropActionWorkflow={props.onDropActionWorkflow}
                        chatId={props.chatId}
                        key={index}
                        id={action?._id}
                        name={action.description}
                        icon={action.actionIcon}
                        fields={action?.fields}
                        contextFields={action?.contextFields}
                        errand={props.errand}
                        setErrands={props.setErrands}
                      />
                    ) : (
                      <ActionListBadge
                        action
                        onDropActionWorkflow={props.onDropActionWorkflow}
                        chatId={props.chatId}
                        key={index}
                        id={action?._id}
                        name={translatedActionNames[index]}
                        icon={action.actionIcon}
                        fields={action?.fields}
                        contextFields={action?.contextFields}
                        errand={props.errand}
                        setErrands={props.setErrands}
                      />
                    )
                  )}
            </InfiniteScroll>
          </ScrollBox>
          {/* This is the list of Workflows */}
          <ScrollBox
            sx={{
              height: 'calc(100vh - 400px)',
              display: selectedTab === 'consoleConversationsWorkflows' ? 'unset' : 'none',
            }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={async (page) => {
                if (!isLoading && !searching && hasMoreWorkflows && selectedTab === 'consoleConversationsWorkflows') {
                  // Lock the loading and update offset
                  setIsLoading(true);
                  if (workflows?.length >= LIMIT) {
                    setWorkflowsOffset((prev) => {
                      return prev + 1;
                    });
                  }
                  const config = reset();
                  const more = await getNextWorkflowChunk(config);
                  setHasMoreWorkflows(more.length === LIMIT); // If its less than our requested amount we know we almost have it all
                  setWorkflows((prev) => [...prev, ...more]);
                  // Release
                  setIsLoading(false);
                }
              }}
              initialLoad={false}
              hasMore={hasMoreActions}
              useWindow={false}
              threshold={500}
            >
              {workflows.length > 0 && (i18n.language === 'en' || translatedWorkflowNames.length === 0)
                ? workflows.map((workflow, index) => (
                    <ActionListBadge
                      workflow
                      onDropActionWorkflow={props.onDropActionWorkflow}
                      chatId={props.chatId}
                      key={index}
                      id={workflow?._id}
                      name={workflow.name}
                      icon={workflow.actionIcon}
                    />
                  ))
                : workflows.map((workflow, index) =>
                    translatedWorkflowNames[index] === null ? (
                      <ActionListBadge
                        workflow
                        onDropActionWorkflow={props.onDropActionWorkflow}
                        chatId={props.chatId}
                        key={index}
                        id={workflow?._id}
                        name={workflow.name}
                        icon={workflow.actionIcon}
                      />
                    ) : (
                      <ActionListBadge
                        workflow
                        onDropActionWorkflow={props.onDropActionWorkflow}
                        chatId={props.chatId}
                        key={index}
                        id={workflow?._id}
                        name={translatedWorkflowNames[index]}
                        icon={workflow.actionIcon}
                      />
                    )
                  )}
            </InfiniteScroll>
          </ScrollBox>

          {/* Send to SunSoft */}
          <Button variant="outlined" startIcon={<UploadFileIcon />} onClick={openDialog}>
            {t('actionListInitTransfer')}
          </Button>

          {/* pull credit menu button */}
          <Button variant="outlined" startIcon={<CreditScoreIcon />} onClick={togglePullCreditMenu}>
            {t('pullCredit')}
          </Button>
        </Stack>
      </CardContent>
    </>
  );
};

export default ActionList;
