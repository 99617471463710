import React, { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
// UI
import { SingleActivityElementContainer } from './SingleActivityContainer';
import { MeasureElements } from '@common/MeasureElementsTools';
import { SingleActivityElement } from './SingleActivity';
import ActivityTracker from './ActivityTracker';
import { copyObj } from '@common/userMessagesUtils';

// Style
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import Tracker from '../../Styles/ActivityTrackerStyle';

// Constants & Vars
import { getInitialArrStateForMeasuring, INITIAL_ACTIVITY_OPTIONAL_DATA_STORAGE } from './Config/States';
import { getAdditionalUIData, removeAdditionalUIData, setAdditionalUIData } from '@storage/userStorage';
import { Dimensions, IActivityOptionalDataStorage } from './interfaces';
import { useHasMounted } from '@common/hooks/useHasMounted';
import { useRootContext } from '@contexts/RootContext';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from './Constants';
import { ValidatorFunctions } from '@common/Validators';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { IErrand, IUserData } from '@interfaces/Conversation';
import { isMobile } from '@common/deviceTypeHelper';
import useDocumentVisibility from '@common/hooks/useDocumentVisibility';


const { VISIBLE_ELEMENT_PADDING_CSS_VALUE, MAX_WIDTH, ACTIVITY_WRAPPER_HEIGHT, ACTIVITY_FONT_SIZE, MAX_SCREEN_DIMS } =
  CONSTANTS;

type MeasureTrackerProps = {
  initialActivityArrState: any[];
  children?: ReactNode;
};

const MeasureTracker = memo(({ initialActivityArrState, children }: MeasureTrackerProps) => {
  const trackerContainerProps = {
    activityWrapperHeight: ACTIVITY_WRAPPER_HEIGHT,
    arrayLength: initialActivityArrState.length,
    visibleElemPadding: VISIBLE_ELEMENT_PADDING_CSS_VALUE,
    maxWidth: MAX_WIDTH,
    activityFontSize: ACTIVITY_FONT_SIZE,
  };

  const SwipeableMeasureStyle = {
    height: ACTIVITY_WRAPPER_HEIGHT,
    position: 'absolute',
    left: '1999px',
    whiteSpace: 'nowrap',
  };

  const TrackerMeasureStyle: React.CSSProperties = {
    position: 'relative',
  };

  return (
    <Tracker
      style={TrackerMeasureStyle}
      // All style props that should be passed to container
      {...trackerContainerProps}
    >
      <SwipeableViews
        slideStyle={{ marginLeft: 'auto', width: 'fit-content', overflowX: 'hidden' }}
        style={SwipeableMeasureStyle}
        disabled={true}
        index={0}
        axis="y"
        animateHeight
        animateTransitions
      >
        {children}
      </SwipeableViews>
    </Tracker>
  );
});

const ForceRemount = (props) => {
  useEffect(() => {
    props.onMount();
  }, []);

  return <></>;
};

interface Props {
  isSearchOpen: boolean;
  editingMode: boolean;
  isSlotMachineShown: boolean;
  errand: IErrand;
  operatorData: IUserData;
  isWidget: boolean;
  authToken: string | null;
}

let LOG_LEVEL = "DEFAULT"; // set to "DEBUG" for full logs
const log = (mark, ...args) => LOG_LEVEL === "DEBUG" ? console.log(`÷ ActivityTrackerWrapper [ ${mark} ]`, ...args) : null;

const ActivityTrackerWrapper = (props: Props) => {
  // This state variables are used for getting the correct width values before rendering actual components on screen to make smooth animations.
  // measuredDimensions Has such structure {
  //  [activityID]: { width: value, ... },
  //  ...
  // }
  // Fetch previously measured dimensions <to not to measure it all the time>
  const INITIAL_ACTIVITY_ARR_STATE = useRef(getInitialArrStateForMeasuring());
  const additionalUIData = JSON.parse(getAdditionalUIData());
  const { messagesAreLoading, selectedIndex } = useRootContext();
  const { morphType } = useErrandContext();
  const { i18n } = useTranslation();
  // window size related logic.
  // Used for triggering resize handler (remeasure dimensions) with debounce delay for (resize event).
  const debouncedWindowDimensions = useWindowDimensions();

  /** additionalUIData has the following structure
   * {
   *    activityTracker: {
   *        dimensions: {
   *            [activityID]: {
   *              width: string
   *            }
   *        }
   *    }
   * }
   */
  const _screenDimKey = `${debouncedWindowDimensions.innerWidth}`;
  const existingDimensions: Dimensions = additionalUIData?.activityTracker?.[_screenDimKey]?.dimensions;

  const ActivityOptionalDataStorage = useRef<IActivityOptionalDataStorage>(INITIAL_ACTIVITY_OPTIONAL_DATA_STORAGE);
  // we should measure dimensions on mount IF there is no existing dimensions present OR the object is empty
  const shouldMeasureDimensions =
    ValidatorFunctions.isUndefinedOrNull(existingDimensions) || ValidatorFunctions.isEmptyObject(existingDimensions);

  const [measuredDimensions, setMeasuredDimensions] = useState<Dimensions | {}>(existingDimensions ?? {});
  // if dimensions should be measured, shouldMeasureDimensions will be true and dimMeasured should be false in this case.
  // if dimensions should not be measured, shouldMeasureDimensions will be false and dimMeasured should be true in this case.
  // thus negation is used.
  const [dimMeasured, setDimMeasured] = useState(!shouldMeasureDimensions);
  // For ensuring that resize handler is being fired after the initial mount.
  const hasMounted = useHasMounted();
  // Used for mobile is page visible check when browser is minimized(closed)/opened
  const { isDocumentVisible: isPageVisible } = useDocumentVisibility(null);
  // Used for remounting the ActivityTracker due to new props incoming that alter the UI part but leave states unnaffected.
  // Which results in occasional errors.
  const [_, setForceRemount] = useState(1);
  const prevIsWidgetRef = useRef(props.isWidget);
  const prevIsSplitScreenRef = useRef(selectedIndex?.length === 2);

  const triggerRemeasurement = useCallback((force = false) => {
    // ensure component has mounted
    if (hasMounted === true || force === true) {
      // set dims not measured to start measuring
      setDimMeasured(() => false);
    }
  }, [hasMounted, setDimMeasured]);

  const handleMeasuredDimensions = useCallback((measuresData: Dimensions) => {
    const newAdditionalUIData = {
      ...additionalUIData,
      activityTracker: {
        // make sure we include the other screenDimensions
        ...additionalUIData?.activityTracker,
      },
    };
    // check for too much data
    // if more than MAX_SCREEN_DIMS screen sizes detected. Do not write more. free up some key first
    const UIDataKeysArr = Object.keys(newAdditionalUIData.activityTracker);
    if (UIDataKeysArr.length >= MAX_SCREEN_DIMS) {
      // delete the first screen size dimension info from data object
      delete newAdditionalUIData.activityTracker[UIDataKeysArr.shift()];
    }
    // save currently measured dimensions data
    newAdditionalUIData.activityTracker[_screenDimKey] = {
      dimensions: {
        ...measuresData,
      },
    };
    // save to localStorage
    setAdditionalUIData(JSON.stringify(newAdditionalUIData));
  }, []);

  // trigger remeasurement of activity tracker elements dimensions.
  // in case of: innerHeight OR innerWidth change OR language switch.
  useEffect(() => {
    // make sure that it runs after component has mounted.
    triggerRemeasurement(true);
  }, [debouncedWindowDimensions.innerWidth, i18n.language]);

  // handler for when the measurements of width are finished for each element
  const measurementsFinishedHandler = useCallback(
    (measuresData: Dimensions) => {
      log("measurementsFinishedHandler:", measuresData);
      setMeasuredDimensions(measuresData);
      handleMeasuredDimensions(measuresData);
      setDimMeasured(true);
    },
    [setMeasuredDimensions, setDimMeasured]
  );

  const formActivityTrackerElements = () => {
    // iterate through INITIAL_ACTIVITY_ARR_STATE in order and map it to elements to be rendered
    const renderElements = INITIAL_ACTIVITY_ARR_STATE.current.map((_activityData) => {
      // push to render arr
      return (
        <SingleActivityElementContainer
          wereDimensionsMeasured={false}
          activityStyle={{}}
          activityData={_activityData}
          key={_activityData.id}
        >
          <SingleActivityElement
            activityData={_activityData}
            targetActivity={_activityData.id}
            errand={props.errand}
            operatorData={props.operatorData}
          />
        </SingleActivityElementContainer>
      );
    });

    return renderElements;
  };

  if ((isMobile() === true && isPageVisible === false) || messagesAreLoading === true) return null;

  // if isWidget is different from prev val.
  if (prevIsWidgetRef.current !== props.isWidget) {
    prevIsWidgetRef.current = props.isWidget;
    // return null to remount the ActivityTracker.
    // Using setForceRemount here to trigger rerender after prevIsWidgetRef is updated.
    // clear measured cache
    removeAdditionalUIData();
    return <ForceRemount onMount={() => setForceRemount((_) => _ + 1)} />;
  }

  // if there were no split screen before and now it swithed to split ascreen
  const isSplitScreenCurrVal = selectedIndex?.length === 2;
  if(prevIsSplitScreenRef.current !== isSplitScreenCurrVal) {
    prevIsSplitScreenRef.current = isSplitScreenCurrVal;
    // clear measured cache
    removeAdditionalUIData();
    return <ForceRemount onMount={() => setForceRemount((_) => _ + 1)} />;
  }
  log("dimMeasured", dimMeasured);
  if (dimMeasured === true) {
    return (
      <ActivityTracker
        {...props}
        privateMode={morphType === MorphType.PrivateChat}
        measuredDimensions={measuredDimensions}
        setMeasuredDimensions={setMeasuredDimensions}
        ActivityOptionalDataStorage={ActivityOptionalDataStorage}
      />
    );
  } else {
    return (
      <MeasureTracker initialActivityArrState={INITIAL_ACTIVITY_ARR_STATE.current}>
        {/* Get width values of activity tracker elements prior to rendering them on screen for smooth and proper css width transitions. */}
        <MeasureElements
          targetElements={formActivityTrackerElements()}
          onCompletedHandler={measurementsFinishedHandler}
        />
      </MeasureTracker>
    );
  }
};

export default memo(ActivityTrackerWrapper);
