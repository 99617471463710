import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useSpeechRecognition } from 'react-speech-recognition';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useRootContext } from '@contexts/RootContext';
import { FooterContext } from '@contexts/FooterContext';
import { containsUrl } from '@common/containsUrl';
import { getUserConsent, getUserConsentIntro, getUserPlayedToday, getWindowLocationPath, setUserConsent } from '@storage/userStorage';
import { Mic, InviteFriendFilled, EnterOTPIcon } from '@assets/Icons';
import PulseIcon from './PulseIcon';
import axiosCall from '@services/axios';
import ActionControls from '@components/ActionControls';
import AudioRecorder from '@components/AudioRecorder';
import AttachmentMenuCloseButton from '@components/AttachmentMenuCloseButton';
import ChatFooterModal from '@components/ChatFooterModal';
import ChatInputField from '@components/ChatInputField';
import ChatSendButton from '@components/ChatSendButton';
import FormConsentContent from '@components/FormConsentContent';
import containsVideo from '@common/containsVideo.js';
import ConversationFooterStyle, { FooterIcon, CFDivider } from '@styles/ConversationFooterStyles';
import eventBus from '@common/eventBus.js';
import EmojiSelector from '@components/EmojiSelector';
import ForgotPasswordButton from '@components/ForgotPasswordButton';
import FileSelector from '@components/FileSelector';
import HoneyPot from '@components/HoneyPot';
import LangPicker from '@components/LanguagePickerButton';
import LanguagePickerCarousel from '@components/LanguagePickerCarousel';
import LanguageUtils from '@common/LanguageUtils.js';
import MultiSelectField from '@components/MultiSelectField';
import PreviewSelectedFiles from '@components/PreviewSelectedFiles';
import PublicInternalToggle from '@components/PublicInternalToggle';
import Timeline from '@components/Timeline';
import TypingIndicator from '@components/TypingIndicator';
import ResendOTPButton from './ResendOTPButton';
import { useErrandContext } from '@contexts/ErrandContext';
import { useCustomLinkContext } from '@contexts/CustomLinkContext';
import { FooterActionIcon } from './FooterActionIcon';
import { getCurrentParticipant } from '@common/errandUtils';
import MorphedConversationFooter from './MorphedConversationFooter';
import { insertField } from '@common/hooks/useUpdateField';
import { sendWorkflow } from '@common/WorkflowsUtils';
import MorphContext from '@contexts/MorphContext';
import { shouldCurrUserReplyTo } from '@common/userMessagesUtils';
import { MorphType } from '@common/MorphType';
import { PaperClipNotepad } from '@assets/Icons';
import FooterInput from './FooterInputStorage/storage';
import { ElectronicSignatureEventType, FormBodySizeType, PaymentActionStateType } from '../Forms/commonForms';
import { UAParser } from 'ua-parser-js';
import ThinClientUtils from '@common/ThinClientUtils';
import { qidSeparatorString } from '@common/StringUtils';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Snackbar } from '@mui/material';
import { Sentiments } from '@mTypes/TSentiment';
import { ChatType } from '@common/ChatType';
import { CReqState, isActionPasswordRelated } from '@common/msgUtils';
import SelectAllBorrowersButton from '@components/SelectAllBorrowersButton';
import { TBorrower } from '@mTypes/TBorrower';
import { IErrand, IUserData, IMessage, IUserChatAction } from '@interfaces/Conversation';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import LeftButtonMorphLoanProducts from './ChooseLoanProduct/LeftButtonMorphLoanProducts';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import { ValidatorFunctions } from '@common/Validators';
import useInitialMount from '@common/hooks/useInitialMount';
import { UserPromptsMenuState } from './MorphUserPromptsMenu/types';
import { currentTimezones, getTimezoneIndex } from '@common/getTimezoneAbbrev';
import { VideoListMenuState } from './MorphVideoListMenu/types';
import { ElementPlus, SongPlayerPlay } from '@assets/Icons/index';
import UPM_TranslationModule, { TLang } from './MorphUserPromptsMenu/translations';
import useAbortController from '@common/hooks/useAbortController';
import TypingAnimation from './FooterTypingAnimation/TypingAnimation';
import { FooterTypingAnimationWrapper } from './FooterTypingAnimation/FooterTypingAnimationWrapper';
import { AccessType } from '@common/AccessType';
import { useCaptchaContext } from '@contexts/captcha';
import { useUserContext } from '@contexts/user';
import { useSocketContext } from '@contexts/socket';
import { Color as WalletColor, Event as WalletEvent } from '@components/MorphWallet/MorphWalletUtils'
import { isMobile } from '@common/deviceTypeHelper';
import { shouldPreventPlaySlotMachine } from '@common/SlotMachineUtils';
import { AppContext, useAppContext } from '@contexts/AppContext';
import ReloadBanner from './ReloadBanner/ReloadBanner';
import { fetchSampleTextData } from './MorphUserPromptsMenu/api';
import { ResetFooterUserAction } from '@common/common';
import { useLocation } from 'react-router-dom';

import MultipleFooterTypingAnimations from './FooterTypingAnimation/MultipleTypingFooterAnimations';
import { WORKFLOW_NAMES } from '@constants/WorkflowNames';
// import { findLastByMessageType, getFirstMsgWelcomeTypeInChat, saveConsentGivenMsgId } from './MessageContent/WelcomeUserMessageContent/ConsentContainer';

/*
 *  This component renders the footer at the bottom of the conversations page. It includes the
 *  The message input box, as well as the icons and the container that holds them all
 *
 *  This component does not currently take in any properties
 *  @todo Deprecate the fieldAttribute state value.
 *  @todo Fix type issues. 
 *
 */
type TConversationFooterProps = {
  dispFilterMode: string;
  editMessageId: string;
  errand: IErrand;
  operatorData?: IUserData;
  setDispFilterMode: Dispatch<SetStateAction<string>>;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setPreviewUrl: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
  showSentiment: boolean;
  triggerSlotMachine: (arg0: any, arg1: any) => void;
  value: string;
};

const ConversationFooter = ({
  dispFilterMode, editMessageId, errand, operatorData, setDispFilterMode, setEditMessageId, 
  setPreviewUrl, setValue, showSentiment, triggerSlotMachine, value
}: TConversationFooterProps) => {
  const { t, i18n } = useTranslation();
  const rootContext = useRootContext();
  const { _id, isOperator, isUser } = useUserContext();
  const { addSentMessage } = useCaptchaContext();
  const isWidget = rootContext.isWidget;
  const errandContext = useErrandContext();
  const customLinkContext = useCustomLinkContext();
  const { eventsSocket, messagesSocket, isEventsConnected, isMessagesConnected } = useSocketContext();
  const windowDimensions = useWindowDimensions();
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [fieldAttribute, setFieldAttribute] = useState(null);
  const [iconToShow, setIconToShow] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState([]);
  const [placeholder, setPlaceholder] = useState<string>(t('Type here...'));
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showPermissionReminder, setShowPermissionReminder] = useState(false);
  const [showContactsConsent, setShowContactsConsent] = useState(false)
  const [honeyPot, setHoneyPot] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const chatInputFieldRef = useRef(null);
  const inputContainerRef = useRef(null);
  const multiSelectFieldRef = useRef(null);
  const sendButtonRef = useRef(null);
  const textInputEnabled = useRef(true);
  const selectedAddress = useRef<string>('');
  const selectedFilesLengthRef = useRef(0);
  const [isWarningActive, setIsWarningActive] = useState<String | null>(null);
  const [sentimentPreview, setSentimentPreview] = useState<Sentiments>(Sentiments.Unvailable);
  const [handleOtpError, setHandleOtpError] = useState(false);
  const [typingWrapperHeight, setTypingWrapperHeight] = useState(null);
  const [consentGiven, setConsentGiven] = useState(getUserConsent() === 'true')
  const [isRussellPeters, setIsRussellPeters] = useState(false);
  const [showRussellPeters, setShowRussellPeters] = useState(false);
  const [showRPContainer, setShowRPContainer] = useState(false);
  const rphead1 = process.env.REACT_APP_MORGAN_CDN + '/Images/rphead1.png';
  const rphead2 = process.env.REACT_APP_MORGAN_CDN + '/Images/rphead2.png';
  const rphead3 = process.env.REACT_APP_MORGAN_CDN + '/Images/rphead3.png';
  const rphead4 = process.env.REACT_APP_MORGAN_CDN + '/Images/rphead4.png';
  const [randomPeekabooImage, setRandomPeekabooImage] = useState(rphead3);

  // const [consentGiven, setConsentGiven] = useState(getUserConsent() === 'true')

  // eventBus.on('showConsentContent', handleConsentContent);
  const abortController = useAbortController();
  // used for representing the curr state of 'send message' request
  // see CReqState for more info
  const msgReqStateRef = useRef(new CReqState());
  // Used for mobile view footer password edge case view
  const isCurrActionRelatedToPassword = isActionPasswordRelated(errand?.action?.action ?? errand?.action)
  const downloadBannerTimeoutsRef = useRef([]);
  const clearDownloadBannerTimeouts = useCallback(() => {
    const timeoutsArrRef = downloadBannerTimeoutsRef.current;
    // no timeouts to clear out
    if(timeoutsArrRef.length === 0) return; 
    // clear the arr
    const tmArr = timeoutsArrRef.splice(0,timeoutsArrRef.length)
    // clear each timeout id
    for(const tmID of tmArr) clearTimeout(tmID);
  }, []);

  const participant = getCurrentParticipant(errand, _id);

  // canSendText is used to keep track if the user is typing a phone number in the
  // input field instead of a name. If they enter a phone number, we can text
  // an invitation to that number even if the number is not in their phone book
  const canSendText = useRef(false);
  // when the user types while the contacts list is open, the filterName will update, this will
  // be used to filter through the contacts list when the axios call is made
  const [filterName, setFilterName] = useState('')
  // Set the default selectedContact to be empty, this will be passed in useContext to other components


  
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedBorrowers, setSelectedBorrowers] = useState<Set<TBorrower>>(new Set());
  const [borrowerList, setBorrowerList] = useState<Array<TBorrower>>([]);

  const [loanProducts, setLoanProducts] = useState<Array<LoanProduct>>([])
  const [hoveredLoanProduct, setHoveredLoanProduct] = useState<LoanProduct>(null);
  const [chosenLoanProduct, setChosenLoanProduct] = useState<LoanProduct>(null)

  const [selectedPrices, setSelectedPrices] = useState({})
  const [hideCalendarMonth, setHideCalendarMonth] = useState(false);
  const [hideTime, setHideTime] = useState(false);
  const [isInvalidWorkshopDate, setIsInvalidWorkshopDate] = useState(false);
  const [bannerAppeared, setBannerAppeared] = useState(0);

  const needToShowFormConsentRef = useRef<boolean>(true);
  const [selectedTimezoneIndex, setSelectedTimezoneIndex] = useState(getTimezoneIndex(currentTimezones()))
  const appContext = useAppContext();

  const prevLastMessageIdRef = useRef(null);
  const [attachmentTabText, setAttachmentTabText] = useState('opt')

  const senderType: string = isOperator ? 'Operator' : 'User';

  // Use this function to reset morph settings anytime it isn't being used anymore
  const resetMorph = () => {
    errandContext.setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });
    console.log("Morph settings reset!")
  }

  /**
   * After sending a message the response data provides the _id and this function
   * will add that message identifier to the beginning of the messages array.
   * This will ensure that when AngelAi reads the message and the notification
   * read event is recieved by the client the message id exists to attach the 
   * new notfications array to.
   * 
   * This process will guarantee that double ticks are shown, even on slower networks
   *
   * @param {Object} response - The response object containing the new message ID.
   */
  const addMessageIdToMessagesArray = useCallback((response) => {
    if (!response || !response._id) return;
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errand._id);

      if (chatObj) {
        let messageIndex = chatObj.messages.findIndex((e) => e._id === response._id);
        if (messageIndex !== -1) return prev;
        if (chatObj?.recipients?.length > 0) {
          chatObj.privateMessages = [ ...(chatObj.privateMessages || []), { _id: response._id, sender: {}, notifications: [] } as IMessage];
        } else {
          chatObj.messages = [ ...chatObj.messages, { _id: response._id, sender: {}, notifications: [] } as IMessage];
        }
      }


      return [...prev];
    });
  }, []);

  // Close the PrivateChat Morphed Footer
  const handleClosePrivateChat = useCallback(() => {
      rootContext.setErrands((prev) => {
          const chatObj = prev.find((e) => e._id === errand._id);

          if (chatObj) {
            chatObj.recipients = [];
          }

          return [...prev];
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errand._id, errandContext.morphType, rootContext.setErrands])

  // Close the contacts list by resetting morph, clearing input, and unselecting
  // the contact if any are chosen
  const handleCloseContacts = () => {
    setSelectedContact(null)
    resetMorph()
    resetStates()
    canSendText.current = false
  }


  // When the user clicks on the button to add a friend on Thin Client, open the contacts list
  const handleOpenContacts = () => {
    resetStates() // clear the input so that it doesn't interfere with search filter
    errandContext.setMorphType(MorphType.Contacts)
    setFilterName('')
    setIsRecording(false)
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errand?._id);

      if (chatObj) {
        ResetFooterUserAction(chatObj, 'Type a name or number...');
      }

      return [...prev];
    });
  }

  const handleEmoji = (event, emojiObject) => {
    sendButtonRef.current?.update(
      `${chatInputFieldRef.current?.unformattedValue}${emojiObject.emoji}`
    );
    chatInputFieldRef.current?.update(
      `${chatInputFieldRef.current?.unformattedValue}${emojiObject.emoji}`
    );
  };

  const handleAttachmentAction = async () => {
    errandContext.setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });
  
    //we are first going to upload to s3 and return document IDs.
    const formData = new FormData();
  
    //we will use the convention of pushing the main photo to the 
    //top of this list, which avoids any schema changes etc.
    if (errandContext?.mainPhoto !== undefined && selectedFiles[errandContext?.mainPhoto]?.name) {
      let file = selectedFiles[errandContext?.mainPhoto];
      formData.append('files', file, file.name);

    }
    for (const key in selectedFiles) {
      if (key !== errandContext?.mainPhoto?.toString() && selectedFiles[key]?.name) {
        formData.append('files', selectedFiles[key], selectedFiles[key].name);
      }
    }
  
    formData.append('recipients', errand?.recipients?.sort()?.join(',') || '');
    formData.append('accessType', !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public);
    formData.append('user', _id);
    formData.append('userType', senderType);
    formData.append('fieldDocument', 'true');
  
    const docParams = {
      url: `chat/${errand._id}/document`,
      method: 'POST',
      data: formData,
    };
    //the IDs of the documents.
    let docs = [];
    try {
      docs = await axiosCall(docParams);
    } catch (error) {
      console.error(error);
    }
    return docs;
  }

  // This function handles whether we are showing the calendar date picker
  const handleShowCalendar = () => {
    // show consent notification if needed
    if (isUser && getUserConsentIntro() !== 'true') {
      return eventBus.dispatch('showConsentIntro');
    }
    if (isUser && getUserConsent() !== 'true') {
      return eventBus.dispatch('showConsentContent');
    }

    setHideCalendarMonth((prev) => {
      return !prev
    })
  }

    // This function handles whether we are showing the time picker
    const handleShowTime = () => {
      // show consent notification if needed
      if (isUser && getUserConsentIntro() !== 'true') {
        return eventBus.dispatch('showConsentIntro');
      }
      if (isUser && getUserConsent() !== 'true') {
        return eventBus.dispatch('showConsentContent');
      }
  
      setHideTime((prev) => {
        return !prev
      })
    }


  const handleBooleanAction = async (bool) => {
    // show consent notification if needed
    if (isUser && getUserConsentIntro() !== 'true') {
      return eventBus.dispatch('showConsentIntro');
    }
    if (isUser && getUserConsent() !== 'true') {
      return eventBus.dispatch('showConsentContent');
    }

    const preparedBoolValue = bool ? 'Yes' : 'No';

    // if user is currently editing a message:
    // if this line fires, then it  means that user has started editing a message of type boolean
    // that has Yes/No buttons.
    // The logic below handles the editing logic for Yes/No boolean answers.
    if (editMessageId) {
      return submitMessageEdit(preparedBoolValue);
    }

    const data: any = {
      sender: _id,
      senderType: senderType,
      message: preparedBoolValue,
      messageType: 'Field',
      userAction: errand?.action?.userActionId,
      intendedAudience: errand?.recipients?.join(',') || '',
      accessType: !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
    };

    if (honeyPot) {
      data.a_password = honeyPot;
    }

    const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data: data };

    /* Message is sent here! */
    try {
      let response = await axiosCall(payload);
      addMessageIdToMessagesArray(response);
      console.log(`Boolean sent: `, response);
    } catch (error) {
      console.error(error);
    }
    return resetStates();
  };

  const handleConfirmFieldAttributeAction = async () => {
    const message = "Done";

    const data = {
      sender: _id,
      senderType: senderType,
      message: message,
      messageType: 'Field',
      userAction: errand?.action?.userActionId,
      intendedAudience: errand?.recipients?.join(',') || '',
      accessType: !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
    };

    const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data };

    try {
      let response = await axiosCall(payload);
      addMessageIdToMessagesArray(response);
      console.log(`Confirm field attribute action sent: `, response);
    } catch (error) {
      console.error(error);
    }
    return resetStates();
  };

  /* Triggered by clicking the x button that appears in the input bar at the bottom of the screen
     When the input is in an action state */
  const cancelAction = useCallback(async (key, clear = false, withMorphTypeChange = true) => {
    // show consent notification if needed
      if(withMorphTypeChange === true) {
        errandContext.setMorphType((prev) => {
          if (prev === MorphType.PrivateChat) return prev;
          return MorphType.None;
        });
      }
      setHideCalendarMonth(false);
      setHideTime(false);
      setSelectedFiles([]);
      setSelectedBorrowers(new Set())
      if (isUser && getUserConsentIntro() !== 'true') {
        return eventBus.dispatch('showConsentIntro');
      }
      if (isUser && getUserConsent() !== 'true') {
        return eventBus.dispatch('showConsentContent');
      }
      // If the userAction was in progress then we need to update it to
      // 9/10/2024 - Dennis, Do not do anything with the user action in the backend, let the AI handle it.
      /*if (key === undefined && errand?.action?.status === 'in-progress') {
        await axiosCall({
          url: `useraction/${errand?.action?.userActionId}`,
          method: 'put',
          data: {
            status: 'suspended',
          },
        });
      }*/

      rootContext.setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errand?._id);

        if (chatObj) {
          ResetFooterUserAction(chatObj);
          
          // force re-render
          return [...prev];
        }

        // no re-render
        return prev;
      });

      setFieldAttribute(null);
      // cancel message editing
      setEditMessageId('');
      errandContext.setIsMorphedFooterCloseButtonOnLeft(false);
      errandContext.setMessageOptionsIndex(-1);
      errandContext.replyToRef.current = { chat: errand._id, originalMessage: '', originalText: '' };

      if (clear) {
        setValue('');
        chatInputFieldRef.current?.update('');
        errandContext?.footerInputRef?.current?.focus();
      } else {
        let newVal =
          fieldAttribute?.description === 'OTP' 
          ? key :
          !key || (key?.length > 1)
            ? chatInputFieldRef.current?.unformattedValue
            : chatInputFieldRef.current?.unformattedValue + key;
        chatInputFieldRef.current?.update(newVal || '');
  
        //Added this observer to detect if the fallback/default input has
        //rendered after the action is cancelled, and set cursor to the end of the field
        const observer = new MutationObserver(() => {
          let input = errandContext?.footerInputRef?.current as HTMLInputElement;
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
            observer.disconnect();
          }
        });
        observer.observe(inputContainerRef.current || document, {
          subtree: false,
          childList: true,
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    errand?._id, 
    errandContext.setMorphType,
    rootContext.setErrands,
    isUser,
    errand?.action?.status,
    fieldAttribute?.description,
    errand?.action?.userActionId, 
    errand?.recipients?.length
  ]);

  // const acceptConsent = () => {
  //   eventBus.dispatch('consentGiven');
  //   setUserConsent('true');
  //   setConsentGiven(true);
  // }

  // const insertConsentField = async () => {

  //   const body = {
  //     chatId: errand._id,
  //     userId: _id,
  //   }

  //   const payload = {
  //     url: `chat/insertConsentGivenField`,
  //     method: 'POST',
  //     data: body,
  //   }

  //   await axiosCall(payload);
  // }


  // useEffect(() => {
  //   // Register the consentGivenOwerwriteEvent handler
  //   window.addEventListener('consentGivenOwerwriteEvent', acceptConsent);

  //   // Cleanup listener on component unmount
  //   return () => {
  //     window.removeEventListener('consentGivenOwerwriteEvent', acceptConsent);
  //   };
  // }, []);

    // Check to see if we've come for Russell Peters
    useEffect(() => {
      const russellTitle = "Russell Peters T-Shirt"
      if (errand?.displayName !== russellTitle) return;
      const basePath = (process.env.REACT_APP_MORGAN_BASENAME || '').replace(/\/$/, '');
      const peekabooRussell = [`${basePath}/relax`, `${basePath}/relaxtshirt`]
      if (isOperator) return;
      if (peekabooRussell.includes(getWindowLocationPath())){
        setShowRPContainer(true);
        setIsRussellPeters(true);
        setShowRussellPeters(true)
        setTimeout(() => {
          setShowRussellPeters(false);
          setTimeout(() => {
            setShowRPContainer(false)
          }, 750)
        }, 3000)
      }
    }, [])

  useEffect(() => {
    if (!isMessagesConnected) return;

    const chatEventHandler = (payload) => {
      if (payload?.data?.type === 'cancel-action') cancelAction(undefined, true);
    }

    messagesSocket.current?.on('chat-event-emitted', chatEventHandler);
    return () => {
      messagesSocket.current?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errand?._id]);

  const resetStates = useCallback(() => {
    /* States that are exclusive to the user screen */
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errand?._id);

      if (chatObj) {
        ResetFooterUserAction(chatObj);
      }

      return [...prev];
    });
    setValue('');
    chatInputFieldRef.current?.update('');
    sendButtonRef.current?.update('');
    textInputEnabled.current = true;
    setFieldAttribute(null);
    setIsSending(false);
    setIsRecording(false);
    setSentimentPreview(Sentiments.Unvailable);
    errandContext.setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });
    errandContext.setIsMorphedFooterCloseButtonOnLeft(false);
    errandContext.setMessageOptionsIndex(-1);
    errandContext.replyToRef.current = { chat: errand?._id, originalMessage: '', originalText: '' };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errand?._id, 
    errand?.recipients?.length, 
    handleClosePrivateChat, 
    errandContext.setIsMorphedFooterCloseButtonOnLeft, 
    errandContext.setMessageOptionsIndex, 
    setValue,
    setIsSending,
    setIsRecording,
    setSentimentPreview
  ])

  const submitFileUpload = async (id) => {
    const formData = new FormData();
    for (let i = 0; i < selectedFiles?.length; i++) {
      if (selectedFiles[i]){
        formData.append('files', selectedFiles[i], selectedFiles[i].name);
      }
    }
    formData.append('recipients', errand?.recipients?.sort()?.join(',') || '');
    formData.append('accessType', !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public);
    formData.append('user', id);
    formData.append('userType', senderType);
    const docParams = {
      url: `chat/${errand._id}/document`,
      method: 'POST',
      data: formData,
    };

    let sendDocuments;
    try {
      sendDocuments = await axiosCall(docParams);
    } catch (error) {
      console.error(error);
    }

    console.log('Documents sent: ', sendDocuments);
    setSelectedFiles([]);
    return resetStates();
  };

  const submitMessageEdit = async (overrideValue?: string) => {
    let newMsgValue = chatInputFieldRef.current?.formattedValue;
    // this is only used for DROPDOWN message edit edge case, 
    // because there is an empty string in chatInputFieldRef.current?.formattedValue
    // when user edits the dropdown message and clicks send.
    // This fixes it.
    if (overrideValue) {
      newMsgValue = overrideValue;
    }
    if (await containsUrl(newMsgValue)) {
      newMsgValue = await containsUrl(newMsgValue);
    }
    const request = {
      url: `chat/${errand?._id}/message/${editMessageId}`,
      method: 'put',
      data: { message: newMsgValue },
    };

    try {
      let response = await axiosCall(request);
      console.log(`Edit sent: `, response);
    } catch (error: any) {
      console.error(`Edit Error: ${error.message}`);
      return resetStates();
    }
    setIsSending(false);

    /* These are only present on the User's view, ignore if we are on the console page */
    if (isUser) {
      setFieldAttribute(null);
      rootContext.setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === errand?._id);

        if (chatObj) {
          ResetFooterUserAction(chatObj);
        }

        return [...prev];
      });
    }

    // Exit the editing state
    setEditMessageId('');
    return resetStates();
  };

  const processWelcomeInputValue = (numValue: string) => {
    // Attempt to convert the input to a number
    const parsedNum = typeof numValue === 'string' ? parseFloat(numValue) : numValue;

    // Check if the parsed value is a valid number
    if (typeof parsedNum !== 'number' || isNaN(parsedNum)) {
      return false; // Return false if not a valid number
    }

    // Check if the number is in the range of 1 to 6 (inclusive)
    if (parsedNum >= 1 && parsedNum <= 6) {
      errandContext.setWelcomeUserMessageState((prevState) => ({
          ...prevState,
          welcomeUserMessage: {
            ...prevState.welcomeUserMessage,
            userChosenHintIndex: parsedNum
          }
      }));

      return true;
    }
    
    return false;
  }

  // keep track of which page is open in MorphWallet (we may want to send the chat there, instead of to the AngelAi chat)
  const handleWalletPageChange = (e: any) => {
    if (e?.type !== WalletEvent.PageChange) {
      return;
    }

    setActiveWalletPage(e?.detail?.page);
  }

  // listen for page changes in MorphWallet
  window.addEventListener(WalletEvent.PageChange, handleWalletPageChange);

  const handleSubmit = async (e) => {
    e?.preventDefault();

    if (fieldAttribute?.description === 'MORPH CREDIT REPAIR DISPUTE ACCOUNT TYPE' && rootContext.userSelectedPromptRef.current === null) {
      return;
    }

    errandContext?.footerInputRef?.current?.focus();

    if (!textInputEnabled.current){
      chatInputFieldRef.current?.update('');
    }

    // if (isUser && consentGiven === false) {
    //   setTimeout(() => {
    //     // Makes sure that it saves the needed message welcome _id in chat that had that consent shown when user accepted it.
    //     // find the last message in chat with type that includes ("welcome")
    //     const currWelcomeMessageTypeInChat = getFirstMsgWelcomeTypeInChat(errand.messages);
    //     if(currWelcomeMessageTypeInChat !== null) {
    //       const lastWelcomeMsgInChat = findLastByMessageType(errand.messages, currWelcomeMessageTypeInChat);
    //       saveConsentGivenMsgId(lastWelcomeMsgInChat._id);
    //     }
    //     acceptConsent();
    //     insertConsentField();
    //   }, 0);
    //   // return;
    // }
    // 
    // show consent notification if needed
    if (isUser && getUserConsentIntro() !== 'true') {
      return eventBus.dispatch('showConsentIntro');
    }
    if (isUser && getUserConsent() !== 'true') {
      return eventBus.dispatch('showConsentContent');
    }

    // consent for chat type form
    if (isUser && errand.type === ChatType.Form) {
      if (needToShowFormConsentRef.current) {
        return eventBus.dispatch('showFormConsent');
      }

      if (errandContext.formBodySize !== FormBodySizeType.Small) {
        errandContext.newFormEvent(ElectronicSignatureEventType.ArrowUp);
      }
    }

    // If our honeypot has been triggered as a borrower, don't do anything
    if (honeyPot) {
      return;
    }

    //no sending of text if we're making a payment
    if (errandContext.morphType === MorphType.Payment){
      return;
    }

    //only allow user to send doc field if files are attached
    if (fieldAttribute) {
      if (['DOCUMENT', 'PHOTO', 'MAINPHOTO', 'SKIPPABLE PHOTO'].includes(fieldAttribute?.description) && selectedFiles?.length === 0) {
        console.log('handleSubmit: there are no files attached to the action');
        return;
      }
      // do not allow an address to be sent if it was not selected from part of google autocomplete.
      // we do 'startsWith' so users can enter apartment numbers as well.
      // REQUEST - removed at Dennis' request
      // if (fieldAttribute?.description === 'ADDRESS' && (!chatInputFieldRef.current?.formattedValue?.startsWith(selectedAddress.current) || !selectedAddress.current)) {
      //   return
      // }
    }

    // Track that a message was sent (for spam reasons)
    addSentMessage(isOperator);

    const isCustomRollerInputValue = fieldAttribute?.description === 'DROPDOWN' && multiSelectFieldRef.current?.isCustomUserValue === true;
    // This allows the dropdown values to be submitted since the dropdown overrides the ChatInputField component and that leaves the chatInputFieldRef null as is the default
    let inputValue =
      fieldAttribute?.description === 'DROPDOWN'
        ? `${multiSelectFieldRef.current?.getValue?.description} ${qidSeparatorString} ${multiSelectFieldRef.current?.getValue?.data}`
        : fieldAttribute?.description === 'MORPH CREDIT REPAIR DISPUTE ACCOUNT TYPE' ? rootContext.userSelectedPromptRef.current
        : chatInputFieldRef.current?.formattedValue;

    if(errandContext.morphType === MorphType.UserPromptsMenu) {
      const { data } = await fetchSampleTextData(errandContext.morphUserPromptsMenuRef.current.getWfIdName()); // data will have wfIdName value
      const { sample } = data;
      inputValue = sample;
    } else if(
      (errandContext.welcomeUserMessageState.welcomeUserMessage.inView === true)
      && !isOperator && !errand?.action) {
      // check inputValue to be 1 -> 6
      const success = processWelcomeInputValue(inputValue);
      // if processed, do nothing
      if(success) {
        chatInputFieldRef.current?.update('');
        return;
      } 
      // // if there is a multiple footer on going animation
      // // on submit: send the currently shown text shown in multiple footer animation to the chat.
      // if(errandContext.multipleFooterTypingAnimations !== null) {
      //   // if null undefined set '' to not to send anything
      //   const currentlyShownPromptText = errandContext.multipleFooterTypingAnimations.items[errandContext.multipleFooterTypingAnimations.currentActiveItem] ?? '';
      //   // remove multiple footer animation component by setting to null
      //   errandContext.setMultipleFooterTypingAnimations(null);
      //   // update local storage key so that the multiple footer animation (on welcome message first mount) was already shown.
      //   // so that it does not show the next time on mount.
      //   localStorage.setItem("wumc_were_suggestions_already_shown", JSON.stringify(true));
      //   // set the inputbox val to currentlyShownPromptText before sending to chat.
      //   inputValue = currentlyShownPromptText;
      // }
    }

    chatInputFieldRef.current?.update(''); //fix the appearance that we've cached the input
    if (isSending) {
      console.log('handleSubmit: in the process of submitting a message.');
      return;
    }
    // below if statements means that if there is no text, file, audio then there is nothing to send
    if (inputValue === '' && selectedFiles?.length === 0 && !isRecording &&
      // if there is a selected contact, we'll want to send that contact's name, so check this too
      !selectedContact && !selectedBorrowers.size) {
      console.log('handleSubmit: there is nothing to submit.');
      setHandleOtpError(true);
      return;
    }

    // send to the wallet instead of the AngelAi chat
    if (errandContext.morphType === MorphType.Wallet) {
      // send the input value to MorphWallet
      window.dispatchEvent(new CustomEvent(WalletEvent.ChatSend, {
        detail: {
          input: inputValue
            }
          }));
          return;
    }

    // if there are transcripts or audio then submit whatever is available
    if (isRecording) {
      setIsRecording(false);
      resetMorph()
      return;
    }

    if (errandContext.morphType === MorphType.CalendarMonth && isInvalidWorkshopDate){
      console.log('handleSubmit: An invalid workshop date was submitted.')
      return;
    }

    setIsSending(true);

    // upload documents
    //docId will be set if the attachment is
    //in response to a fieldAttribute
    let docIds;
    if ((selectedFiles?.length > 0) && fieldAttribute !== null) {
      //if we are looking at the main photo selector, then we need to 
      // make sure that there was a main photo selected.
      if (errandContext.morphType === MorphType.PhotoMain && errandContext?.mainPhoto === null) {setIsWarningActive(t('pleaseSelectValidMainPhoto')); setIsSending(false);return;}
      docIds = await handleAttachmentAction();
    }
    else if (selectedFiles?.length > 0) {
      // upload documents, normal attachment
      await submitFileUpload(_id);
      // only return if there is no message value
      if (inputValue === '') {
        return;
      }
    }
    errandContext.setMorphType((prev) => {
      if (prev === MorphType.PrivateChat) return prev;
      return MorphType.None;
    });

    /* If the user is editing a message, update it instead of sending a new message */
    if (editMessageId && editMessageId) {
      // check if the editing message is a dropdown
      if (fieldAttribute?.description === 'DROPDOWN') {
        // take input from dropDown because chatInputValue will be ''
        return submitMessageEdit(inputValue);
      }
      return submitMessageEdit();
    }

    /* Stores our payload */
    let data;
    /* If there is an action being interacted with, and we are on the user side,
        send the message as a field Type */
    if (fieldAttribute !== null) {
      if (isUser && errand?.action?.action?.description === 'Full Name') {
        let prev = errand?.workflow;

        if (prev !== undefined) {
          (async (prev) => {
            // Load the correct workflow, first use search then filter to ensure
            let wfs = await axiosCall({
              url: `workflow/db/search?active=true&fields=_id,name`,
              method: 'post',
              data: {
                search: prev,
              },
            });
            let wf = wfs.filter((w) => w.name === prev);

            // If we have something after the filter send it!
            if (wf.length > 0) {
              console.log('Sending workflow', wf[0]);
              await axiosCall({
                url: `chat/${errand?._id}/workflow/${wf[0]?._id}`,
                method: 'POST',
                data: {
                  userId: _id,
                  userType: 'User',
                  owner: _id,
                },
              });
            }
          })(prev);
        }
      }
      if(isCustomRollerInputValue === true) {
        data = {
          sender: _id,
          senderType: senderType,
          intendedAudience: errand?.recipients?.join(',') || '',
          accessType: !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
          message: multiSelectFieldRef.current?.getValue?.description,
          messageType: 'Text',
        };
      } else {
        data = {
          sender: _id,
          senderType: senderType,
          intendedAudience: errand?.recipients?.join(',') || '',
          accessType: !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
          message: inputValue,
          documents: docIds || undefined,
          messageType: 'Field',
          userAction: errand?.action?.userActionId,
        };
      }
    } else if (await containsVideo(inputValue /*, operatorData*/)) {
      // If a youtube video/ID is found in the message, it is logged in
      // the database as a Video messageType
      data = {
        sender: _id,
        senderType: senderType,
        intendedAudience: errand?.recipients?.join(',') || '',
        accessType: !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
        message: inputValue,
        messageType: 'Video',
      };
    } else if (await containsUrl(inputValue)) {
      let formattedMessage = await containsUrl(inputValue);
      data = {
        sender: _id,
        senderType: senderType,
        intendedAudience: errand?.recipients?.join(',') || '',
        accessType: !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
        message: formattedMessage,
        messageType: 'Url',
      };
    } else {
      data = {
        sender: _id,
        senderType: senderType,
        intendedAudience: errand?.recipients?.join(',') || '',
        accessType: !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
        message: inputValue,
        messageType: 'Text',
      };
    }

    // TODO Clean up.
    const recipients = [_id];

    // If there's a selected contact, send that contact's name as a message and send an invitation
    if (selectedContact) {
      let justNumbers;
      let contactEmail;
      if (selectedContact.phoneNumbers && selectedContact.phoneNumbers.length) {
        justNumbers = selectedContact.phoneNumbers[0].number.replace(/[^0-9]+/g, '');
      }
      if (selectedContact.emailAddresses && selectedContact.emailAddresses.length) {
        contactEmail = selectedContact.emailAddresses[0].email;
      }
      const firstAndLastName = `${selectedContact.givenName} ${selectedContact.familyName}`;

      // do Post requests to set the fields needed to complete invite friend workflow without asking
      // the questions in the workflow. This will let the user send the invite immediately if they
      // press enter or submit the selected contact
      const body = {
        contactFirstName: selectedContact.givenName,
        contactLastName: selectedContact.familyName,
        contactFullName: firstAndLastName,
        contactEmail: contactEmail,
        contactPhone: justNumbers,
        chatId: errand._id,
        userId: _id,
      };
      const payload = {
        url: `contact/insertInviteFields`,
        method: 'POST',
        data: body,
      };
      await axiosCall(payload);

      // data = {
      //   sender: _id,
      //   senderType: senderType,
      //   intendedAudience: recipients?.filter((userId) => userId !== id).join(','),
      //   accessType: AccessType.public,
      //   message: selectedContact?.displayName,
      //   messageType: 'Text',
      // };
      // Trigger the Thin Client's Invite Contact workflow. All the needed fields are set when user selects a contact. Dialer should appear immediately.
      await sendWorkflow('', WORKFLOW_NAMES.INVITE_CONTACT, errand._id, recipients, AccessType.public, _id, isOperator, null);
      handleCloseContacts();
      return resetStates();
    }
    // If user is responding to 'chooseBorrowers' action field, send msg
    // of type 'Field' and include the selectedBorrowers array after the
    // <separator/> tag in the message
    if(MorphType.BorrowerSelector && selectedBorrowers.size){
      errandContext.setMorphType(MorphType.None);
      // await insertField('chooseBorrowers', Array.from(selectedBorrowers), errand);

      // const resolveAction = async () => {
      //   // sets the corresponding slot machine useraction status to resolved
      //   const payload = {
      //     url: `useraction/${errand.action?.userActionId}`,
      //     method: 'put',
      //     data: {
      //       value: selectedBorrowers,
      //       status: 'resolved',
      //     },
      //   };
      //   await axiosCall(payload);
      // };

      data = {
        sender: _id,
        senderType: senderType,
        message: `${Array.from(selectedBorrowers)
          .map((borrower) => `${borrower.firstName} ${borrower.lastName}`)
          .join(', ')}
          <separator/>
          ${JSON.stringify(Array.from(selectedBorrowers))}`,
        accessType: AccessType.public,
        messageType: 'Field',
        userAction: errand?.action?.userActionId
      };
      const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data: data };
      try {
        // mark current message post request as sent
        msgReqStateRef.current.sent();
        let response = await axiosCall(payload);
        addMessageIdToMessagesArray(response);
        // once response is received, mark as finished
        msgReqStateRef.current.finished();
        console.log(`Message sent: `, response);
      } catch (error: any) {
        // mark current message post request to have errors
        msgReqStateRef.current.finished(error as Error);
        console.error(`Message Error: ${error.message}`);
      }
      setSelectedBorrowers(new Set());
      // await resolveAction();
      return resetStates();
    }

    // If user submits a time or date, we should close the morphType
    if (errandContext.morphType === MorphType.CalendarMonth || errandContext.morphType === MorphType.Time || errandContext.morphType === MorphType.DOB){
      resetStates();
    }
    
    // If we can send a text, insert the provided phone number as the friend's phone number and email,
    // and then trigger the invite friend workflow. Morgan will ask for the friend's name and then will send the invite.
    if (canSendText.current) {
      const justNumbers = inputValue.replace(/[^0-9]+/g, '')
      const insertPhoneFields = async () => {
        insertField('invitePhoneNumber', justNumbers, errand, undefined, _id); // change fieldName to invitePhoneMorph when MRGN-496 is implemented
        insertField('inviteEmailAddress', 'placeholder@email.com', errand, undefined, _id); //using placeholder email for now for testing text. Can remove after MRGN-496.
      };
      await insertPhoneFields();
      await sendWorkflow('', WORKFLOW_NAMES.INVITE_FRIEND, errand._id, recipients, AccessType.public, _id, isOperator, null);
      handleCloseContacts()
      // normally, we would want to also remove the saved invite fields after the invitation is sent. However, this is already 
      // implemented in MRGN-496. So for now, just test inviting 1 friend per conversation or delete manually with mongodb compass.
    }

    if (honeyPot) {
      data.a_password = honeyPot;
    }

    if (errandContext.replyToRef.current.originalMessage) {
      data.replyTo = errandContext.replyToRef.current;

      errandContext.replyToRef.current = { chat: errand?._id, originalMessage: '', originalText: '' };
    }
    const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data: data };

    /* Message is sent here!
     *
     * Moved logic applied to response into the try catch to
     * handle errors and prevent crashing if message post request
     * does not succeed
     *
     */
    try {
      // mark current message post request as sent
      msgReqStateRef.current.sent();
      let response = await axiosCall(payload);
      addMessageIdToMessagesArray(response);
      // once response is received, mark as finished
      msgReqStateRef.current.finished();
      console.log(`Message sent: `, response);
    } catch (error: any) {
      // mark current message post request to have errors
      msgReqStateRef.current.finished(error as Error);
      console.error(`Message Error: ${error.message}`);
    }
    // if custom roller input value
    // cancel curr action
    if(isCustomRollerInputValue === true) {
      // first cancel current action, then send message in chat as a free message
      await cancelAction(undefined, false, true);
    }
    setSelectedFiles([])
    return resetStates();
  };

  const handleMorphClose = useCallback(() => {
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errand?._id);

      if (chatObj) {
        ResetFooterUserAction(chatObj);

        chatObj.recipients = [];
      }

      // reset message reply cache
      errandContext.replyToRef.current = { chat: errand._id, originalMessage: '', originalText: '' };

      // set all re-render trigger at the same time
      errandContext.setIsMorphedFooterCloseButtonOnLeft(false);
      errandContext.setMorphType(MorphType.None);
      return [...prev];
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    errand?._id, 
    errandContext.setIsMorphedFooterCloseButtonOnLeft,
    errandContext.setMorphType,
    rootContext.setErrands
  ]);

  const handlePopup = () => {
    return (
      <Stack flexDirection='row' gap={1} sx={{ ml: 0.5, mr: 1 }}>
        <Button
          sx={{ color: 'var(--gray000)', textAlign: 'capitalize' }}
          variant='contained'
          //set input back to what it was previously
          onClick={() => {errandContext.setIsPopupOpen(false); resetStates()}}
        >
          {t('tHide')}
        </Button>
      </Stack>
    );
  };

  const handleBoolean = () => {
    if (errand?.action === undefined || errand?.action === null) {
      setFieldAttribute(() => null);
    }

    return (
      <Stack flexDirection='row' gap={1} sx={{ ml: 0.5, mr: 1 }}>
        <Button
          sx={{ color: 'var(--gray000)', textAlign: 'capitalize' }}
          variant='contained'
          onClick={() => handleBooleanAction(true)}
          onMouseDown={(e) => {e.preventDefault()}}
        >
          {t('yes')}
        </Button>
        <Button 
          sx={{ textAlign: 'capitalize' }}
          variant='outlined'
          onClick={() => handleBooleanAction(false)}
          onMouseDown={(e) => {e.preventDefault()}}
        >
          {t('no')}
        </Button>
      </Stack>
    );
  };

  const handleConfirmFieldAttribute = () => {
    if (errand?.action === undefined || errand?.action === null) {
      setFieldAttribute(() => null);
    }

    return (
      <Stack flexDirection='row' gap={1} sx={{ ml: 0.5, mr: 1 }}>
        <Button
          sx={{ color: 'var(--gray000)', textAlign: 'capitalize' }}
          variant='contained'
          onClick={handleConfirmFieldAttributeAction}
        >
          {t('confirmButton')}
        </Button>
      </Stack>
    );
  };

  const handleDocument = () => {
    return (
      <FileSelector
        selectedFilesLength={selectedFiles?.length || 0}
        setSelectedFiles={setSelectedFiles}
        setIconToShow={setIconToShow}
        setShowPermissionReminder={setShowPermissionReminder}
        setShowContactsConsent={setShowContactsConsent}
        handleCloseContacts={handleCloseContacts}
        handleOpenContacts={handleOpenContacts}
        operatorData={operatorData}
    />
    );
  }
  const handlePhoto = () => {
    return (
      <FileSelector
        selectedFilesLength={selectedFiles?.length || 0}
        setSelectedFiles={setSelectedFiles}
        setIconToShow={setIconToShow}
        setShowPermissionReminder={setShowPermissionReminder}
        setShowContactsConsent={setShowContactsConsent}
        handleCloseContacts={handleCloseContacts}
        handleOpenContacts={handleOpenContacts}
        operatorData={operatorData}
    />
    );
  };

  /**
   * Run this click event to switch from email submission to phone submission in Invite Friend workflow
   * Clicking on "Phone" or "Email" button will submit this as a message to the conversation
   * from the user, which triggers a different workflow with CHECK_USE_PHONE_OR_EMAIL.
   */
  const handleChoosePhoneOrEmail = async (phoneOrEmail) => {
    if (isUser && getUserConsentIntro() !== 'true') {
      return eventBus.dispatch('showConsentIntro');
    }
    if (isUser && getUserConsent() !== 'true') {
      return eventBus.dispatch('showConsentContent');
    }

    const data: any = {
      sender: _id,
      senderType: senderType,
      message: phoneOrEmail,
      messageType: 'Field',
      userAction: errand?.action?.userActionId,
      intendedAudience: errand?.recipients?.join(',') || '',
      accessType: !isPublic ? AccessType.internal : errandContext.morphType === MorphType.PrivateChat ? AccessType.private : AccessType.public,
    };

    if (honeyPot) {
      data.a_password = honeyPot;
    }

    const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data: data };

    /* Message is sent here! */
    try {
      let response = await axiosCall(payload);
      addMessageIdToMessagesArray(response);
      console.log('handleChoosePhoneOrEmail was successful: ' + response);
    } catch (error) {
      console.error('handleChoosePhoneOrEmail failed: ' + error);
    }
    return resetStates();
  };

  const handleMorphInviteFriend = (morphPhoneOrEmail) => {
    if (morphPhoneOrEmail === 'MORPH PHONE') {
      return (
        <Stack flexDirection="row" gap={1} sx={{ ml: 0.5, mr: 1 }}>
          <Button
            onClick={() => cancelAction(undefined, true)}
            variant="contained"
            sx={{ color: 'var(--gray000)', margin: '3px 0' }}
          >
            {t('inviteUserDialogCancel')}
          </Button>
          <Button
            onClick={() => handleChoosePhoneOrEmail(`${t('inviteUserSwitchToEmail')}`)}
            variant="outlined"
            sx={{ margin: '3px 0', minWidth: '75px' }}
          >
            {t('inviteUserDialogEmail')}
          </Button>
        </Stack>
      );
    } else if (morphPhoneOrEmail === 'MORPH EMAIL') {
      return (
        <Stack flexDirection="row" gap={1} sx={{ ml: 0.5, mr: 1 }}>
          <Button
            onClick={() => cancelAction(undefined, true)}
            variant="contained"
            sx={{ color: 'var(--gray000)', margin: '3px 0' }}
          >
            {t('inviteUserDialogCancel')}
          </Button>
          <Button
            onClick={() => handleChoosePhoneOrEmail(`${t('inviteUserSwitchToPhone')}`)}
            variant="outlined"
            sx={{ margin: '3px 0', minWidth: '75px' }}
          >
            {t('inviteUserDialogPhone')}
          </Button>
        </Stack>
      );
    }
  };

  const handleOTP = () => {
    return (
      <FooterIcon>
          <PulseIcon
            icon1={<EnterOTPIcon width="28px" height="28px" />}
            icon2={<ResendOTPButton errand={errand} />}
          />
      </FooterIcon>
    )
  }

  // const handleSkippable = () => {
  //   return (
  //     <FooterIcon>
  //         <SkipButton errand={errand} resetStates={resetStates}/>
  //     </FooterIcon>
  //   )
  // }

  const handleCalendar = () => {
    return (
      <FooterIcon>
        <Button onClick={handleShowCalendar} variant='outlined'>
          {hideCalendarMonth ? t('tShow') : t('tHide')}
        </Button>
      </FooterIcon>
    )
  }

  const handleTime = () => {
    return (
      <FooterIcon>
        <Button onClick={handleShowTime} variant='outlined'>
          {hideTime ? t('tShow') : t('tHide')}
        </Button>
      </FooterIcon>
    )
  }

  const getUserPromptsMenuPlaceholder = () => {
    if(errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_NOT_SELECTED) {
      return t("UserPromptsMenuStart");
    } else if(errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_SELECTED){
      return '';
    } else if (errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_FETCH_LOADING) {
      return t('UserPromptsMenuFetchLoading');
    }
    else {
      return t('UserPromptsMenuFetchError');
    }
  }

  const getCreditRepairAccountTypePlaceholder = () => {
    if(errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_NOT_SELECTED) {
      return t("creditRepairDisputeAccountTypeSelect");
    } else if(errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_SELECTED){
      return '';
    } else if (errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_FETCH_LOADING) {
      return t('creditRepairDisputeAccountTypeLoading');
    }
    else {
      return t('tError');
    }
  }

  const getVideoListMenuPlaceholder = () => {
    if(errandContext.videoListMenuState === VideoListMenuState.VIDEO_FETCH_ERROR){
      return t('VideoListMenuFetchError');
    } else if (errandContext.videoListMenuState === VideoListMenuState.VIDEO_FETCH_LOADING) {
      return t('VideoListMenuFetchLoading');
    } else {
      return '';
    }
  }

  const triggerWelcomeUser = async () => {
      await sendWorkflow('', WORKFLOW_NAMES.WELCOME_USER, errand._id, [_id], AccessType.public, _id, isOperator, null);
  }

  const handleDefaultCloseMorph = (clickHandler = null, btnText = null) => {
    const btnClickHandler = clickHandler ?? resetMorph;
    const additionalHandler = (e) => {
      btnClickHandler(e);
      setTimeout(() => {
        sendButtonRef.current?.update('');
      }, 100)
      chatInputFieldRef.current.update('');
      if (['CreditRepairWelcome', 'RefinanceCalculatorWelcome'].includes(errand.lastMessageData.messageType)){
        triggerWelcomeUser();
      }
    }



    return (
      <FooterIcon>
        <Button size="small" variant="outlined" sx={{ textTransform: 'none' }} onClick={additionalHandler}>
          {btnText ?? t('cancelButton')}
        </Button>
      </FooterIcon>
    )
  }

  const handleDisplayActionIcon = () => {
    /**
     * NOTE
     * A) when a handleClickEdit is called in MessageContentWrapper, 
     * errand.action gets changed to a structure that is different 
     * from the one that is being used in actionDialog B) edit click handler.
     * 
     * In option A) errand.action doesn't have nested attr "action"
     * While option B) does.
     * In other words A) errand.action.action is undefined (errand.action has everything needed)
     * while B) errand.action.action is defined and hass all the needed attributes
     * 
     * THUS, the following code resolves this difference.
     * 
     * TODO: resolve this discrepancy between different structures
     *  */
    // check if nested action is undefined
    // active is always at errand.action
    const activeRef = errand.action?.active;

    let actionRef = errand?.action?.action;
    // if so, use first level action, else leave nested one.
    if (!actionRef) {
      actionRef = errand?.action;
    }
    // use the calculated reference to access needed props
    const actionId = actionRef?._id;
    const animatedIcon = actionRef?.animatedIcon || "";
    return (
      <>
        {activeRef === true &&
          actionRef?.fieldName === 'password' &&
          isUser && (
            <ForgotPasswordButton
              errand={errand}
              setErrands={rootContext.setErrands}
            />
          )}
          {/* Prevent cropped UI display to user on mobile OR widget view (cropped input box issue fix) */}
          {(windowDimensions.isDesktop === true || (windowDimensions.isDesktop === false && isCurrActionRelatedToPassword === false))
            && <FooterActionIcon
                actionId={actionId}
                base64Icon={errand?.icon}
                _invertedBase64Icon={animatedIcon}
              />}
      </>
    )
  };

  const renderLeftOfInput = () => {
    if (errandContext.isPopupOpen) return handlePopup();
    if (errandContext.morphType === MorphType.FormInsertSignature && errandContext.wetInitial && errandContext.wetSignature) {
      return (
        <Button
          size="small"
          variant="outlined"
          sx={{ 
            color: 'var(--gray000)',
            textTransform: 'none',
            background: 'var(--green700)',
            '&:hover': {
              color: 'var(--green700)',
              borderColor: 'var(--green700)',
            }
          }}
          onClick={() => errandContext.newFormEvent(ElectronicSignatureEventType.GoToInsertSignatureView)}
        >
          {t('loginFormSubmit')}
        </Button>
      );
    }

    if (errandContext.morphType === MorphType.Edit) {
      return (
        <FooterIcon>
          <span style={{ fontSize: '0.9rem', fontWeight: '600' }}>
            {t('MorphEdit_EditingLabel')}
          </span>
        </FooterIcon>
      );
    } else if (errandContext.morphType === MorphType.UserPromptsMenu || errandContext.morphType === MorphType.CreditRepairDisputeAccountType) {
      return (
        <FooterIcon>
          <ElementPlus style={{ fontSize: '1rem'}} />
        </FooterIcon>
      );
    } else if (errandContext.morphType === MorphType.VideoListMenu) {
      return (
        <FooterIcon style={{ border: '1px solid var(--orange700)', borderRadius: '10px' }}>
          <SongPlayerPlay style={{ height: '25px', width: '25px', margin: '0px 3px 0px 4px'}} />
        </FooterIcon>
      )
    } else if (fieldAttribute?.description === 'BOOLEAN' || fieldAttribute?.description === 'CONFIRM') {
      if (windowDimensions.isDesktop) {
        return (
          <LangPicker
            isDesktop={windowDimensions.isDesktop}
            setDispFilterMode={setDispFilterMode}
          />
        );
      } else {
        return (
          <LanguagePickerCarousel
            isDesktop={windowDimensions.isDesktop}
            setDispFilterMode={setDispFilterMode}
          />
        );
      }
    } else if (fieldAttribute?.description === 'DOCUMENT') {
      return handleDocument();
    } else if (['PHOTO', 'MAINPHOTO', 'SKIPPABLE PHOTO'].includes(fieldAttribute?.description)) {
      return handlePhoto();
    } else if (fieldAttribute?.description === 'CALENDAR') {
      return handleCalendar();
    } else if (fieldAttribute?.description === 'TIME') {
      return handleTime();
    } else if (fieldAttribute?.description === 'MORPH PHONE' || fieldAttribute?.description === 'MORPH EMAIL') {
      return handleMorphInviteFriend(fieldAttribute?.description);
    } else if (fieldAttribute?.description === 'OTP') {
      return handleOTP();
    } else if (isRecording) {
      return (
        <FooterIcon>
          <Mic />
        </FooterIcon>
      );
    } else if (errandContext.morphType === MorphType.Contacts) {
      return (
        <FooterIcon>
          <InviteFriendFilled />
        </FooterIcon>
      );
    } else if ([MorphType.ShareCustomLink, MorphType.CreditRepairWelcome, MorphType.RefinanceCalculatorWelcome].includes(errandContext.morphType)){
      return handleDefaultCloseMorph();
    } else if (fieldAttribute?.description === 'MORPH LOAN PRODUCTS') {
      return (
        <FooterIcon>
          <LeftButtonMorphLoanProducts />
        </FooterIcon>
      )
    } else if (fieldAttribute?.description === 'MORPH BORROWER SELECTOR') {
      return (
        <FooterIcon>
          <SelectAllBorrowersButton sendButtonRef={sendButtonRef}/>
        </FooterIcon>
      )
    } else if (fieldAttribute?.description && errand?.icon) {
      return handleDisplayActionIcon();
    } else if (errandContext.morphType === MorphType.Attachment || errandContext.morphType === MorphType.PhotoMain || errandContext.morphType === MorphType.PhotoPlain) {
      // Render the Paper Clip / Notepad SVG from Figma when the attachment menu is open
      return (
        <FooterIcon>
          <PaperClipNotepad />
        </FooterIcon>
      );
    } else if (windowDimensions.isDesktop) {
      return (
        <LangPicker
          isDesktop={windowDimensions.isDesktop}
          setDispFilterMode={setDispFilterMode}
        />
      );
    } else {
      return (
        <LanguagePickerCarousel
          isDesktop={windowDimensions.isDesktop}
          setDispFilterMode={setDispFilterMode}
        />
      );
    }
  };

  /**
   * Footer hide condition, we can hide/show footer with this logic
   * @returns true/false
   * true - hide footer
   * false - show footer
   */
  const footerHideCondition = () => {
    if (errand.type === ChatType.Form) return false;

    if (!participant) return true;
    if (!Array.isArray(errand?.messages)) return true;
    return false;
  }

  const handleCreditRepairWelcomeMessage = () => {
    // set input field manually
    chatInputFieldRef.current?.update(t('helpMeRepairMyCredit'))
    sendButtonRef.current?.update(t('helpMeRepairMyCredit'));

    // Submit the message
    handleSubmit(null);
  }

  const handleRefinanceCalculatorWelcomeMessage = () => {
    // Set the value of the input field
    chatInputFieldRef.current?.update(t('refinanceCalculatorWelcomeMessage'))
    sendButtonRef.current?.update(t('refinanceCalculatorWelcomeMessage'));

    // Submit the message
    handleSubmit(null);
  }

  // Function for sending the action or workflow from the use prompt list
  // Same function used in the Prompt list
  // itself when clicked, passed in from rootContext
  const sendActionWorkflow = useCallback(() => {
     if (errandContext.morphType === MorphType.ShareCustomLink) {
      rootContext.handleActionWorkflow(customLinkContext.shareCustomLinkInfoRef.current._id, customLinkContext.shareCustomLinkInfoRef.current.type, errand?._id)
    } else if(errandContext.morphType === MorphType.UserPromptsMenu) {
      if(rootContext.userSelectedPromptRef.current === null) return;
      rootContext.handleActionWorkflow(rootContext.userSelectedPromptRef.current?._id, rootContext.userSelectedPromptRef.current?.type, errand?._id)
    }
    errandContext.setMorphType(MorphType.None);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errandContext.morphType,
    customLinkContext.customLink,
    errand?._id,
    errandContext.morphType,
    rootContext.handleActionWorkflow,
    errandContext.setMorphType
  ])

  const sendVideoMessageFromMenu = useCallback( async () => {
    await axiosCall({
      url: `message/${errand._id}/video/${rootContext.userSelectedVideoRef.current?.videoId}`,
      method: 'get'
    })
    errandContext.setMorphType(MorphType.None)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandContext.morphType, errandContext.setMorphType, errand?._id])

  // const handleMorphOnLoad = useCallback(() => {
  //   // Form morphTypes do not have to be reset upon reconnecting
  //   if (errand.type === 'form') return;
  //   if (rootContext.rootMorphType === MorphType.None) {
  //     // TODO: calling rootContext.setErrands onload in this function is causing a bad setState error.
  //     // Comenting out the setErrands fixes the error but cuases the footer to not change when the user
  //     // comes back to the chat. Send Action, change chats, send text as operator, navigate back, footer 
  //     // should not be setup to reply to the action.
  //     resetStates();
  //     // handleClosePrivateChat();
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [rootContext.rootMorphType, errand?.type, resetStates, handleClosePrivateChat]);

  // const handleConsentOnLoad = useCallback(() => {
  //   // Open the consent when user joins the conversation
  //   if (isUser && getUserConsentIntro() !== 'true') {
  //     return eventBus.dispatch('showConsentIntro');
  //   }
  // }, []);

  // handleConsentOnLoad();

  useInitialMount(() => {
    // handle consent on load
    // Open the consent when user joins the conversation
    if (isUser && getUserConsentIntro() !== 'true') {
      return eventBus.dispatch('showConsentIntro');
    }

    // handleMorphOnLoad
    // Form morphTypes do not have to be reset upon reconnecting
    if (errand.type === 'form') return;
    if (rootContext.rootMorphType === MorphType.None) {
      // TODO: calling rootContext.setErrands onload in this function is causing a bad setState error.
      // Comenting out the setErrands fixes the error but cuases the footer to not change when the user
      // comes back to the chat. Send Action, change chats, send text as operator, navigate back, footer 
      // should not be setup to reply to the action.
      resetStates();
      // handleClosePrivateChat();
    }

    // set the random peekaboo image for russell peters
    const peekabooImages = [
      [rphead1, rphead2, rphead3, rphead4]
    ]

    const randomIndex = Math.floor(Math.random() * peekabooImages[0].length);
    setRandomPeekabooImage(peekabooImages[0][randomIndex]);
  });

  useEffect(() => {
    if(i18n.language === 'en' 
      || LanguageUtils.fetchTranslationEnabledSetting() === false 
      || isOperator) 
      return;
    // fetch current language translations for the userPromptsMenu component
    UPM_TranslationModule.processLanguage(abortController.get(), i18n.language as TLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, isOperator])

  // This use effect checks to ensure value and in scope held
  // formatted values are in sync when changing actions that
  // utilize the same input. Using different input components
  // for password and phone-numbers for example, would remove
  // the need for this useEffect
  useEffect(() => {
    if (
      // if unformatted IS empty AND formatted is not empty.
      !chatInputFieldRef.current?.unformattedValue?.trim() &&
      chatInputFieldRef.current?.formattedValue?.trim()
    ) {
      // update value, unformatted and formatted in chatInputField component
      chatInputFieldRef.current?.update('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatInputFieldRef.current?.unformattedValue, chatInputFieldRef.current?.formattedValue]);


  /**
   * When the SpeechRecognition starts recognizing text from audio
   * it will fill the transcript buffer. Use that buffer to insert
   * the words in the message input box.
   */
  useEffect(() => {
    let currentRef = chatInputFieldRef.current;
    if (isRecording && transcript !== '') {
      currentRef?.update(`${transcript.toLowerCase()}`);
    }
    if (!isRecording) {
      resetTranscript();
    }

    return () => {
    currentRef?.update('');
      setValue('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcript, isRecording]);

  useEffect(() => {
    if (errand.lastMessageData?.messageType === "CreditRepairWelcome" && !operatorData){
      errandContext.setMorphType(MorphType.CreditRepairWelcome);
    }
    if (errand.lastMessageData?.messageType === "RefinanceCalculatorWelcome" && !operatorData){
      errandContext.setMorphType(MorphType.RefinanceCalculatorWelcome);
    }
  }, [   
    errand?.lastMessageData,
    bannerAppeared
    ])

  /**
   * This useEffect runs whenever ANY of the following changes:
   * - Current Errand ActionID
   * - Current Errand Action ActionID (nested one)
   * - Current Errand Last Message Data
   * - Edit Message ID
   * - Number of Opened Screens (splitScreen etc.)
   * - Current User ID
   */
  useEffect(() => {
    // FORM type
    const chatTypeInitiator = errand?.lastMessageData?.action?.chatTypeInitiator;
    // check if last message type is ACTION and action chat type initiator is FORM
    if (
      errand.lastMessageData?.messageType === 'Action' &&
      chatTypeInitiator === ChatType.Form &&
      rootContext.selectedIndex?.length !== 2
    ) {
      // last userAction is form related
      // check if curr user is the one that the form action is adressed to.
      if(shouldCurrUserReplyTo(errand.lastMessageData, _id) === true) {
        errandContext.setMorphType(MorphType.FormOpen);
      }
    } 
    // if only FormOpen is currently active close it
    else {
      errandContext.setMorphType((prev): MorphType => {
        if (prev === MorphType.FormOpen) {
          return MorphType.None;
        }
        return prev;
      });
    }
    // ALL OTHER TYPES
    
    // Main Function that fetches the field data and sets it (fieldAttribute).
    // Based on fetched fieldData, it performs some additional logic based on it's description and other values.
    async function fetchAndSetFieldAttribute(fieldData) {
      // if some field attribute is about to be set
      // AND curr motphType is UserPromptsMenu, set it to None 
      // Because user received new action.
      // ### covers case when new action is incoming BUT the userPromptsMenu morphType is in place.  
      if(errandContext.morphType === MorphType.UserPromptsMenu 
        || errandContext.morphType === MorphType.VideoListMenu) {
        errandContext.setMorphType((prev) => MorphType.None);
      }
      //Ideally this data is not a string, and it was delivered from core as a populated field attribute.
      //TODO: track down where in UI (possibly actiondialog.tsx) we are resetting fieldAttribute data to a string, and do not do that.
      if (typeof fieldData === 'string') {
        fieldData = await axiosCall({
          url: `fieldAttribute/${fieldData}`,
        });
        setFieldAttribute(fieldData);
      } else if(fieldData) {
        setFieldAttribute(fieldData);
      }
      const fieldDataDescription = fieldData?.description;
      if (fieldDataDescription === 'CALENDAR'){
        errandContext.setMorphType(MorphType.CalendarMonth)
      }
      if (fieldDataDescription === 'DOB'){
        errandContext.setMorphType(MorphType.DOB)
      }
      if (fieldDataDescription === 'TIME'){
        errandContext.setMorphType(MorphType.Time)
      }
      if (fieldDataDescription === 'MAINPHOTO'){
        //we will use the mask variable to determine the limit of the photos.
        if (fieldData.mask.length) {errandContext.setPhotoLimit(Number(fieldData.mask));}
        else {errandContext.setPhotoLimit(5)}
        //reset the main photo
        errandContext.setPhotoSelectorIndex(null);
        errandContext.setMainPhoto(null);
        errandContext.setMorphType(MorphType.PhotoMain);
        textInputEnabled.current = false;
      }
      else if (fieldDataDescription === 'PHOTO' || fieldDataDescription === 'SKIPPABLE PHOTO'){
        //we will use the mask variable to determine the limit of the photos.
        if (fieldData.mask.length) {errandContext.setPhotoLimit(Number(fieldData.mask));}
        else {errandContext.setPhotoLimit(5)}
        errandContext.setPhotoSelectorIndex(null);
        errandContext.setMorphType(MorphType.PhotoPlain);
        textInputEnabled.current = false;
      }
      else if (fieldDataDescription === 'DOCUMENT'){
        errandContext.setMorphType(MorphType.Attachment);
        textInputEnabled.current = false;
      }
      //the slot machine action needs the field attribte with the description 'SLOT MACHINE'
      else if (fieldDataDescription === 'SLOT MACHINE' && !getUserPlayedToday()){
        errandContext.setMorphType(MorphType.SlotMachine);
      }
      else if (fieldDataDescription === 'PAYMENT'){
        //the below endpoint functions both as a check for whether
        //the payment has been made already AND as a fetch for price data
        textInputEnabled.current = false;
        const res = await axiosCall({url: `pay/field`, method: 'POST', data: { chat: errandContext.errandId, context: errand.activeContext }});
        if (res.data !== 'payment already made'){
          errandContext.setMorphType(MorphType.Payment);
          //set the payment component to the first step.
          errandContext.setPaymentActionState(PaymentActionStateType.Preview);
        }
        else {
          errandContext.setMorphType(MorphType.None);
        }
      }
      else if (fieldDataDescription === 'MORPH BORROWER SELECTOR'){
        errandContext.setMorphType(MorphType.BorrowerSelector)
      }
      else if (fieldDataDescription === 'MORPH LOAN PRODUCTS') { 
        errandContext.setMorphType((prev) => {
          if (prev !== MorphType.LoanProductPriceTable) {
            return MorphType.LoanProducts;
          }
          return prev;
        });
      }
      else if (fieldDataDescription === 'MORPH CREDIT REPAIR DISPUTE ACCOUNT TYPE'){
        errandContext.setMorphType(MorphType.CreditRepairDisputeAccountType)
      }
    };

    // Edit Message Data is incomplete CHECK
    const foundEditMessage = errand.messages?.find((m) => m?._id === editMessageId);
    if (!errand // errand is null
      || (!errand?.action // action is null
        && !editMessageId // edit message id is null
        && foundEditMessage?.messageType !== 'Field') // 
      ) {
      setFieldAttribute(null);
    }

    // chatTypeInitiator is either Page or Activity CHECK
    if (chatTypeInitiator === ChatType.Page 
      || chatTypeInitiator === ChatType.Activity) {
      return;
    }

    rootContext.setErrands((prev) => {
      // check index and return if not found
      const chatObj = prev.find((e) => e._id === errand._id);

      if (chatObj) {
        // check if current morphType is of PrivateChat type
        const isPrivate = errandContext.morphType === MorphType.PrivateChat;

        // lastMessage data
        let lastMessage = chatObj.messages?.[chatObj.messages?.length - 1] as IMessage;

        // if private get the last message from privateMessages
        if (isPrivate) {
          lastMessage = chatObj.privateMessages?.[chatObj.privateMessages?.length - 1] as IMessage;
        }

        // CHECK last message action related data
        const isCurrErrandActionExists = ValidatorFunctions.isNotUndefinedNorNull(chatObj.action) === true;
        const isCurrentActionActive = chatObj.action?.active;
        const lastMessageAction = lastMessage?.action;

        // IF Errand Action Data Exists AND last message is NOT ACTION type.
        if (isCurrErrandActionExists && !isCurrentActionActive && lastMessage?.messageType !== 'Action') {
          // clear field attribute data
          setFieldAttribute(null);
        }

        /*
         * 01/04/2024 Mykyta:
         * Next check serves for the following:
         * no active actions and the latest message contains an action
         * !chatObjaction?.active gives true if action is undefiend or null.
         * Bug that happened due to this is that when user starts editing the message, it fetches the fieldAttribute
         * of the last message in chat WHICH is not the needed fieldAttribute data.
         */
        // IF Errand Action Data Exists AND Last message is ACTION type.
        else if (isCurrErrandActionExists && !isCurrentActionActive && lastMessage?.messageType === 'Action') {
          // fetch the fieldAttribute data of the Last Message
          fetchAndSetFieldAttribute(lastMessageAction?.fieldAttribute);
        }
        // if current errand action IS active.
        // get the fieldAttribute for the currently active errand action
        else if (isCurrentActionActive) {
          // if user is editing some message.
          if (editMessageId) {
            const editTargetMessageAction = chatObj.messages.find((m) => m._id === editMessageId)?.action;
            // get currently editing message fieldAttribute data
            fetchAndSetFieldAttribute(editTargetMessageAction?.fieldAttribute);
          } else {
            const currAction = chatObj.action?.action;
            // get current errand active action fieldAttribute data
            fetchAndSetFieldAttribute(currAction?.fieldAttribute);
          }
        }

        // Remember, this whole function runs After dependancies changed in this useEffect call.
        // Check if last message is ACTION and IN-PROGRESS and Current user is target user for this action.
        // ALSO check if lastMessageID did not change, then it means that this useEffect was triggered by some other change
        //  therefore, if that's the case (if prevlastMessageID is the same as current), it means that the actionID changed but not the last message,
        //  and thus, we should not update the curr action to be of the last message. (EDGE CASE FIX)
        const hasLastMessageChanged =
          prevLastMessageIdRef.current === null ||
          prevLastMessageIdRef.current === undefined ||
          prevLastMessageIdRef.current !== lastMessage?._id;
        if (
          hasLastMessageChanged === true &&
          lastMessage?.messageType === 'Action' &&
          errand.lastMessageData?.userAction?.status === 'in-progress' &&
          shouldCurrUserReplyTo(lastMessage, _id)
        ) {
          // _id variable here holds current user id.
          // Set new action data as the current errand action
          chatObj.action = {
            ...lastMessage?.userAction,
            action: lastMessage?.action,
            userActionId: lastMessage?.userAction?._id,
            active: true,
          };
          chatObj.placeholder = lastMessage?.action?.description;
          chatObj.icon = lastMessage?.icon;
          chatObj.recipients = lastMessage.intendedAudience
            ? [lastMessage.sender._id, ...lastMessage.intendedAudience].sort()
            : [];
        }

        // IF curr action is NOT active.
        else if (!isCurrentActionActive) {
          ResetFooterUserAction(chatObj);
        }

        // update last message id data ref.
        prevLastMessageIdRef.current = lastMessage?._id;
      }

      return [...prev];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    errand?.action?._id,
    errand?.lastMessageData,
    editMessageId,
    errand?.action?.action?._id,
    rootContext.selectedIndex?.length,
    _id,
    //1/25/24: Icy: Having this dependency caused infinite loop issues for morphType
    // errandContext.morphType
  ]);

  useEffect(() => {
    async function getTranslatedPlaceholder(newPlaceholder, language, dispFilterMode) {
      let placeholder = dispFilterMode === 'None' ? newPlaceholder : dispFilterMode;
      let translatedPlaceholder = t(placeholder);
      if (i18n.language !== 'en') {
        if (translatedPlaceholder === placeholder && LanguageUtils.fetchTranslationEnabledSetting()) {
          try {
            translatedPlaceholder = await LanguageUtils.translateOne(placeholder, language);
          } catch (err) {
            console.error(
              `Could not translate placeholder "${placeholder}" to ${language}`,
              err
            );
            translatedPlaceholder = placeholder;
          }
        }
      }
      setPlaceholder(translatedPlaceholder);
    };
    getTranslatedPlaceholder(errand?.placeholder, i18n.language, dispFilterMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errand?.placeholder, i18n.language, dispFilterMode]);

  // on chat errand id change.
  useEffect(() => {
    // fetch from storage the new updated errand id
    const fetchedInputState = FooterInput.fetch(errand._id);
    const valueToSet = fetchedInputState !== null ? fetchedInputState.value : '';

    // we have a fetched input state
    if (fetchedInputState !== null) {
      // check for address related action
      // if address was put in, in order to make it work properly, double set the footer inut value
      chatInputFieldRef.current?.update(fetchedInputState);
    }
    // no input state in storage
    else {
      chatInputFieldRef.current?.update("");
    }

    setValue(valueToSet);
    setEditMessageId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errand?._id]);

  // on value change
  useEffect(() => {
    chatInputFieldRef.current?.update(value || '');
    sendButtonRef.current?.update(value || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (selectedFilesLengthRef.current === 0 && selectedFiles.length > 0) {
      selectedFilesLengthRef.current = selectedFiles.length;
      sendButtonRef.current?.startAnimation();
    } else {
      selectedFilesLengthRef.current = selectedFiles.length;
    }
    if (isRecording) {
      sendButtonRef.current?.startAnimation();
      // Use morphed footer when recording is turned on and set the topic to MorphRecording component
      errandContext.setMorphType(MorphType.Recording)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles, isRecording]);

  useEffect(() => {
    if (errand?.action?.action){
      if (errand?.action?.action?.description === 'Slot Machine' && !getUserPlayedToday()) {
        errandContext.setMorphType(MorphType.SlotMachine);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errand?.action])

  // ensure that only the primary chat gets the morphed footer when creating/editing errand
  useEffect(() => {
    if (errand._id === rootContext.morphedId?.current) {
      errandContext.setMorphType(rootContext.rootMorphType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootContext?.rootMorphType]);

  const TIMER_ANIMATION_WAIT_DURATION = 5000; // Milliseconds after initial render to begin timer
  const showDownloadAppBanner = () => {
    const TIMER_ANIMATION_BEGIN_TIME = new Date().getTime();
    // Only show the banner on iOS and Android browser
    const user = UAParser();
    const os = user.os.name;

    // return if device is not Android or iOS or banner has been shown recently or
    // device is using thin client app or user is on the widget
    if (
      (os !== 'Android' && os !== 'iOS') ||
      // ThinClientUtils.validateBannerTimeToLive() ||
      ThinClientUtils.isThinClient() ||
      isWidget
    ) {
      return;
    }

    // Calculate the time when the animation should begin:
    const currentTicks = new Date().getTime();

    const showTmID = setTimeout(() => {
      errandContext.setMorphType(MorphType.DownloadAppBanner);
    }, TIMER_ANIMATION_BEGIN_TIME - currentTicks);
    const hideTmID = setTimeout(() => {
      if (errandContext.morphType === MorphType.DownloadAppBanner){
        errandContext.setMorphType(MorphType.None);
      }
      setBannerAppeared((prev) => prev + 1);
    }, TIMER_ANIMATION_BEGIN_TIME - currentTicks + 10000);

    // save banner timeout ids
    downloadBannerTimeoutsRef.current.push(showTmID, hideTmID);
    ThinClientUtils.resetBannerTimeToLive();
  }
  useEffect(() => {
    if (!isEventsConnected) return;

    const onShowDownloadAppBannerEvent = () => {
      showDownloadAppBanner();
    };

    console.log('Events Socket - ConversationFooter - (on)');
    eventsSocket.current?.on('show-download-app-banner', onShowDownloadAppBannerEvent);
    eventBus.on('consentGiven', () => {
      // if the footer is morphed or if last message type is 
      // 'CreditRepairWelcome' or 'RefinanceCalculatorWelcome' then return
      // otherwise, show the download app banner
      if (errandContext.morphType !== MorphType.None) return;
      if (["CreditRepairWelcome", "RefinanceCalculatorWelcome"].includes(errand.lastMessageData?.messageType)) return;
      if (isRussellPeters) return;
      showDownloadAppBanner();
    });

    return () => {
      console.log('Events Socket - ConversationFooter - (off)');
      eventsSocket.current?.off('show-download-app-banner', onShowDownloadAppBannerEvent);
      eventBus.remove('consentGiven');
      clearDownloadBannerTimeouts();
    };
  }, [isEventsConnected, isWidget]);

  const handleContactsConsentGranted = () => {
    // Thin Client only
    // Send contacts permission request to user with postMessage
    // As soon as permission is enabled, sync contacts
    localStorage.setItem('contactConsent', JSON.stringify(true))
    window.dispatchEvent(new StorageEvent('storage', {
      key: 'contactConsentYes',
      newValue: JSON.stringify(true),
      oldValue: localStorage.getItem('contactConsent'),
      url: window.location.href,
      storageArea: localStorage
    }));
    setShowContactsConsent(false)
  }

  const openSlotMachine = async () => {

      let actionRef: IUserChatAction = errand?.action?.action;
      // if so, use first level action, else leave nested one.
      if (!actionRef) {
        actionRef = errand?.action;
      }
      //if it has been less than a day that the userAction has been resolved, the slot machine was played.

      //As a protection we will set a localStorage played today var to determine cross-errand play history.
      //Please note that slot machine can still be abused if a user continuously clears cache.

      // Harrison 8/22/23 - Both the network calls and localStorage can be abused, BUT we intend
      // in the future to adjust the workflow to only be played by Authed users, which is why we are utilizing both methods
      if (actionRef?._id) {
        const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';
        let { preventPlay } = await shouldPreventPlaySlotMachine(errand, actionRef, timeZone)

        if (!preventPlay) {
          triggerSlotMachine(errand._id, errand.action?.userActionId);
          return false;
        } else {
          return true;
        }
      }
  }

  const getInputContainerStyleObj = useCallback(() => {
    let paddingTopVal;

    if(errandContext.morphType) {
      if (errandContext.morphType === MorphType.Wallet) {
        return { border: `3px solid ${WalletColor.Blue}`, borderRadius: 5 };
      } else if (errandContext.morphType === MorphType.Edit) {
        paddingTopVal = 3;
      } else {
        paddingTopVal = errandContext.morphIndent.current?.clientHeight || 19;
      }
    } else {
      paddingTopVal = 3;
    }

    if(errandContext.morphType === MorphType.UserSuggestions) {
      paddingTopVal = 7;
    }
    return {
      paddingTop: `${paddingTopVal}px`
    }
  }, [errandContext.morphType, errandContext.morphIndent])

  const russellPetersImg = () => {
    return(
      <div className={windowDimensions.isDesktop === true ? 'russellContainer' : 'russellContainerMobile'}>
      <img
          className={showRussellPeters ? 'showRussellPeters' : 'hideRussellPeters'}
          src={randomPeekabooImage}
          alt='Russell Peters'
        ></img>
      </div>
    )
  }

  // only when multipleFooterTypingAnimations is null 
  // (meaning there is no on-going multiple footer animation)
  const renderFooterTypingAnimationWrapper = (errandContext.multipleFooterTypingAnimations === null && 
    (
      (errandContext.morphType === MorphType.VideoListMenu) && 
      (errandContext.videoListMenuState === VideoListMenuState.VIDEO_SELECTED)) || 
      ([MorphType.CreditRepairWelcome, MorphType.RefinanceCalculatorWelcome].includes(errandContext.morphType)
    )
  );
  const animatedText = 
    errandContext.morphType === MorphType.VideoListMenu ? `${t('VideoListMenuSendToStart')} ${rootContext.videoMenuTitle}` : 
    errandContext.morphType === MorphType.CreditRepairWelcome ? t('helpMeRepairMyCredit') : 
    errandContext.morphType === MorphType.RefinanceCalculatorWelcome ? t('refinanceCalculatorWelcomeMessage') : '';

  return (
    <FooterContext.Provider
      value={{
        textInputEnabled,
        selectedContact,
        sendButtonRef,
        needToShowFormConsentRef,
        sentimentPreview,
        setSentimentPreview,
        chatInputFieldRef,
        handleOtpError,
        setHandleOtpError,
        setTypingWrapperHeight
      }}
    >
      <ChatFooterModal
        action1={null}
        action2={null}
        contentText={t('permissionReminderText')}
        disableClickAway={false}
        handleAction1={null}
        handleAction2={null}
        iconType={iconToShow}
        isOpen={showPermissionReminder}
        modalType={'permissionReminder'}
        setIsOpen={setShowPermissionReminder}
        showCloseButton={true}
        title={t('permissionReminderTitle')}
      />
      <ChatFooterModal
        action1={t('no')}
        action2={t('yes')}
        contentText={t('contactsConsentText')}
        disableClickAway={false}
        handleAction1={() => setShowContactsConsent(false)}
        handleAction2={handleContactsConsentGranted}
        iconType={null}
        isOpen={showContactsConsent}
        modalType={'consent'}
        setIsOpen={setShowContactsConsent}
        showCloseButton={false}
        title={t('contactsConsentTitle')}
      />
      {isUser && errand.type === ChatType.Form && (
        <FormConsentContent userConsent='form' setUserConsent={(string) => {}} />
      )}
      {errandContext.messageFilter.length > 0 && (
        <Timeline
          latestDate={errand?.updatedAt}
          result={errand?.messages}
          startDate={errand?.createdAt}
        />
      )}
      {/* 
        Used another context to set the selectedContact state to prevent unnecessary re-rendering.
        See section 7.3 (Preventing Context re-renders: splitting data into chunks) from: 
        https://www.developerway.com/posts/react-re-renders-guide
      */}
      <MorphContext.Provider
        value={{
          contextSetSelectedContact: setSelectedContact,
          selectedBorrowers: selectedBorrowers,
          setSelectedBorrowers: setSelectedBorrowers,
          borrowerList: borrowerList,
          setBorrowerList: setBorrowerList,
          canSendText,
          sendButtonRef,
          openSlotMachine: openSlotMachine,
          loanProducts: loanProducts,
          setLoanProducts: setLoanProducts,
          hoveredLoanProduct: hoveredLoanProduct,
          setHoveredLoanProduct: setHoveredLoanProduct,
          chosenLoanProduct,
          setChosenLoanProduct,
          msgReqStateRef,
          setFieldAttribute,
          selectedPrices,
          setSelectedPrices,
          hideCalendarMonth,
          setHideCalendarMonth,
          hideTime,
          setHideTime,
          selectedTimezoneIndex,
          setSelectedTimezoneIndex,
          setIsInvalidWorkshopDate,
          errandType: errand.type,
          isSending,
          selectedFiles,
          setSelectedFiles,
          setAttachmentTabText,
          attachmentTabText
        }}
      >
        {appContext.showReloadButton && <ReloadBanner />}
        {errandContext.morphType !== MorphType.None && (
          <>
            <Snackbar
              open={isWarningActive ? true : false}
              onClose={() => setIsWarningActive(null)}
              message={isWarningActive}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            />
          <MorphedConversationFooter
            filterName={filterName}
            operatorData={operatorData}
            action={Boolean(errand.action?.action)}
            parentId={errand.parentId || ''}
            handleOpenContacts={handleOpenContacts}
            setShowContactsConsent={setShowContactsConsent}
            setIconToShow={setIconToShow}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            cancelAction={cancelAction}
            handleSubmit={handleSubmit}

            // Private Chat
            editMessageId={editMessageId}
            errand={errand}
            dispFilterMode={dispFilterMode}
            setEditMessageId={setEditMessageId}
            setPreviewUrl={setPreviewUrl}
            setValue={setValue}
            showSentiment={showSentiment}
            isTyping={isTyping}
            setIsTyping={setIsTyping}
          />
          </>
        )}

        <ConversationFooterStyle
          className={
            (footerHideCondition() ? 'shouldHideFooter ' : '') +
            (fieldAttribute?.description === 'DROPDOWN' ? 'isDropdown ' : '')
          }
        >
          {/* {(errandContext.morphType !== MorphType.PhotoMain && errandContext.morphType !== MorphType.PhotoPlain) && <PreviewSelectedFiles
            isSending={isSending}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />} */}
          {errandContext.morphType === MorphType.None && (
            <TypingIndicator
              errand={errand}
              isTyping={isTyping}
              setIsTyping={setIsTyping}
              operatorData={operatorData}
            />
          )}
          <div className={`
          ConversationFooter${errandContext.morphType === MorphType.Attachment || 
            (errandContext.morphType === MorphType.PhotoMain || 
            errandContext.morphType === MorphType.PhotoPlain || 
            errandContext.morphType === MorphType.MessageOptions || 
            errandContext.morphType === MorphType.UserPromptsMenu ||
            errandContext.morphType === MorphType.UserSuggestions ||
            errandContext.morphType === MorphType.VideoListMenu ||
            errandContext.morphType === MorphType.CreditRepairDisputeAccountType) ? ' isAttachmentMenuOpen' : ''}
            ${errandContext.isMorphedFooterCloseButtonOnLeft ? ' isMorphedFooterCloseButtonOnLeft' : ''}
            ${fieldAttribute?.description === 'DROPDOWN' ? ' isDropdown' : ''}`}
            ref={rootContext.conversationFooterRef}
          >
            {showRPContainer && russellPetersImg()}
            <div
              className={`inputContainer ${(errandContext.morphType === MorphType.ErrandNew || errandContext.morphType === MorphType.ErrandEdit) && 'inputContainerHide'} ${(isFocused || (errandContext.morphType !== MorphType.None && errandContext.morphType !== MorphType.Reply && errandContext.morphType !== MorphType.Edit)) ? 'focused' : ''}`}
              ref={inputContainerRef}
              style={getInputContainerStyleObj()}
            >
              {isUser && <HoneyPot honeyPot={honeyPot} setHoneyPot={setHoneyPot} />}
              {fieldAttribute?.description === 'DROPDOWN' ? (
                <MultiSelectField
                  errand={errand}
                  ref={multiSelectFieldRef}
                  fieldAttribute={fieldAttribute}
                  handleSubmit={handleSubmit}
                  cancelAction={cancelAction}
                  msgReqStateRef={msgReqStateRef}
                  isPrivate={errandContext.morphType === MorphType.PrivateChat}
                />
              ) : (
                <form className='inputForm' onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} onSubmit={(event) => {
                  if (errandContext.morphType === MorphType.PhotoMain || errandContext.morphType === MorphType.PhotoPlain || errandContext.morphType === MorphType.BorrowerSelector) {
                    //prevent the form behavior of loading the page.
                    //we want the icon displayed here to be the main photo selector
                    event.preventDefault();
                  } else {
                    handleSubmit(event);
                  }
                }}>
                  {renderLeftOfInput()}
                  {/* {['SKIPPABLE', 'SKIPPABLE PHOTO'].includes(fieldAttribute?.description) && handleSkippable()} */}
                  {/* always render on desktop. IF mobile, render this only when the action is not related to password (cropped input box issue fix) */}
                  {(windowDimensions.isDesktop === true || (windowDimensions.isDesktop === false && isCurrActionRelatedToPassword === false)) && <CFDivider orientation='vertical' />}

                  {/* Render appropriate input box based on action type */}
                  <div className='inputFieldContainer'>
                    <FooterTypingAnimationWrapper isShown={renderFooterTypingAnimationWrapper}>
                      <TypingAnimation text={animatedText} speed={35}/>
                    </FooterTypingAnimationWrapper>
                    {
                      errandContext.multipleFooterTypingAnimations !== null && <MultipleFooterTypingAnimations {...errandContext.multipleFooterTypingAnimations} />
                    }
                    <ChatInputField
                      style={{ backgroundColor: 'red'}}
                      honeyPot={honeyPot}
                      cancelAction={cancelAction}
                      errand={errand}
                      fieldAttribute={fieldAttribute}
                      handleSubmit={
                        errandContext.morphType === MorphType.ShareCustomLink ? sendActionWorkflow : 
                        errandContext.morphType === MorphType.VideoListMenu ? sendVideoMessageFromMenu : 
                        errandContext.morphType === MorphType.CreditRepairWelcome ? handleCreditRepairWelcomeMessage : 
                        errandContext.morphType === MorphType.RefinanceCalculatorWelcome ? handleRefinanceCalculatorWelcomeMessage : handleSubmit}
                      isPublic={isPublic}
                      operatorData={operatorData}
                      participantId={participant?._id}
                      placeholder={
                        errandContext.morphType === MorphType.UserPromptsMenu ? getUserPromptsMenuPlaceholder()
                          : errandContext.morphType === MorphType.VideoListMenu ? getVideoListMenuPlaceholder()
                            : errandContext.morphType === MorphType.ShareCustomLink ? `${t("sharePlaceholder")} ${customLinkContext.shareCustomLinkMethod === 'email' ? t("smsEmailComposerEmail") : t("smsEmailComposerSMS")}`
                              : errandContext.morphType === MorphType.CreditRepairDisputeAccountType ? getCreditRepairAccountTypePlaceholder()
                              : errandContext.morphType === MorphType.CreditRepairWelcome ? ''
                              : errandContext.morphType === MorphType.RefinanceCalculatorWelcome ? ''
                                : placeholder
                      }
                      selectedAddress={selectedAddress}
                      showPassword={showPassword}
                      setSelectedFiles={setSelectedFiles}
                      setFilterName={setFilterName}
                      editMessageId={editMessageId}
                    />

                    {/* Public/Internal toggle, lets operator send internal messages without user seeing */}
                    {isOperator && errandContext.morphType !== MorphType.PrivateChat && (
                      <PublicInternalToggle isPublic={isPublic} setIsPublic={setIsPublic} />
                    )}

                    {/* If this is an action, show the cancel button and password visibility toggle if needed */}
                      {errand?.action?.active &&
                        errandContext.morphType !== MorphType.ShareCustomLink &&
                        errandContext.morphType !== MorphType.Edit &&
                        errandContext.morphType !== MorphType.CreditRepairDisputeAccountType && 
                        fieldAttribute?.description !== 'BOOLEAN' &&
                        fieldAttribute?.description !== 'CONFIRM' && (
                      <ActionControls
                        cancelAction={cancelAction}
                        fieldAttribute={fieldAttribute}
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                        isPrivate={errandContext.morphType === MorphType.PrivateChat}
                      />
                    )}
                  </div>
                </form>
              )}
                {!errand?.action?.active && windowDimensions.isDesktop && errandContext.morphType === MorphType.Edit && (
                      <EmojiSelector handleEmoji={handleEmoji} operatorData={operatorData} />
                )}
                {
                fieldAttribute?.description === 'BOOLEAN' ? handleBoolean() : 
                fieldAttribute?.description === 'DROPDOWN' || 
                fieldAttribute?.description === 'ENVELOPE' || 
                errandContext.morphType === MorphType.VideoListMenu || 
                errandContext.morphType === MorphType.UserPromptsMenu ||
                errandContext.morphType === MorphType.UserSuggestions ||
                errandContext.morphType === MorphType.CreditRepairDisputeAccountType || 
                errandContext.morphType === MorphType.CalendarMonth || errandContext.morphType === MorphType.DOB ||
                errandContext.morphType === MorphType.Time ||
                errandContext.morphType === MorphType.Edit ||
                errandContext.morphType === MorphType.Contacts ||
                errandContext.morphType === MorphType.BorrowerSelector ||
                errandContext.morphType === MorphType.Attachment ||
                errandContext.morphType === MorphType.ShareCustomLink ||
                errandContext.morphType === MorphType.PhotoMain || 
                errandContext.morphType === MorphType.PhotoPlain ? (
                  <ChatSendButton
                      disabled={errandContext.morphType === MorphType.VideoListMenu || 
                        errandContext.morphType === MorphType.UserPromptsMenu ||
                        errandContext.morphType === MorphType.CreditRepairDisputeAccountType}
                      handleSubmit={
                        (errandContext.morphType === MorphType.ShareCustomLink) ? sendActionWorkflow :
                        errandContext.morphType === MorphType.VideoListMenu ? sendVideoMessageFromMenu : handleSubmit}                      
                        ref={sendButtonRef}
                      userActions={errand?.participants?.[0]?.userActions}
                      workflow={errand?.workflow}
                    />
                ) :
                  fieldAttribute?.description === 'CONFIRM' ? handleConfirmFieldAttribute() : (
                  <>
                    {(windowDimensions.isDesktop || [MorphType.CreditRepairWelcome].indexOf(errandContext.morphType) === -1) && (<>
                    {/* {[MorphType.Contacts, MorphType.PrivateChat].indexOf(errandContext.morphType) !== -1 && (
                      <Tooltip title={errand.action ? t('conversationFooterActionRequestCancel') : t('closePrivateChat')} placement='top'>
                        <IconButton
                          className="contactsCloseButton"
                          onClick={() => {
                            errandContext.setMorphType((prev) => {
                              if (errandContext.morphType === MorphType.Contacts) {
                                handleCloseContacts()
                                return MorphType.None;
                              }
                              if (errand.action) {
                                cancelAction(undefined, true);
                                return prev;
                              }
                              handleClosePrivateChat();
                              return MorphType.None;
                            });
                          }}
                        >
                          <HighlightOffOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )} */}
                    {[MorphType.Reply, MorphType.Errand, MorphType.PrivateChat].indexOf(errandContext.morphType) !== -1 && (
                      <Button className='closeIcon'>
                        <HighlightOffOutlinedIcon onClick={handleMorphClose} />
                      </Button>
                    )}
                    {!errand?.action?.active && windowDimensions.isDesktop && (
                      <EmojiSelector handleEmoji={handleEmoji} operatorData={operatorData} />
                    )}
                    {!errand?.action?.active && 
                    (errandContext.morphType !== MorphType.UserPromptsMenu && 
                      errandContext.morphType !== MorphType.UserSuggestions && 
                      errandContext.morphType !== MorphType.VideoListMenu && 
                      errandContext.morphType !== MorphType.CreditRepairDisputeAccountType && 
                      errandContext.morphType !== MorphType.WorkflowHistory &&
                      errandContext.morphType !== MorphType.Attachment &&
                      errandContext.morphType !== MorphType.Wallet) &&
                      !isWidget && (
                        <>
                          
                          <FileSelector
                            selectedFilesLength={selectedFiles?.length || 0}
                            setSelectedFiles={setSelectedFiles}
                            setIconToShow={setIconToShow}
                            setShowPermissionReminder={setShowPermissionReminder}
                            setShowContactsConsent={setShowContactsConsent}
                            handleCloseContacts={handleCloseContacts}
                            handleOpenContacts={handleOpenContacts}
                            operatorData={operatorData}
                          />
                        </>
                      )}
                    </>)}
                    {errandContext.morphType !== MorphType.Wallet && (
                      <AudioRecorder
                        errandId={errand?._id}
                        isRecording={isRecording}
                        operatorData={operatorData}
                        recipients={errand?.recipients}
                        setErrands={rootContext.setErrands}
                        setIconToShow={setIconToShow}
                        setIsRecording={setIsRecording}
                        setShowPermissionReminder={setShowPermissionReminder}
                        resetMorph={resetMorph}
                        selectedFiles={selectedFiles}
                      />
                    )}
                    {(errandContext.morphType === MorphType.Recording || chatInputFieldRef.current?.formattedValue !== '' || selectedFiles.length > 0) &&
                      <ChatSendButton
                        disabled={errandContext.morphType === MorphType.VideoListMenu || 
                          errandContext.morphType === MorphType.UserPromptsMenu ||
                          errandContext.morphType === MorphType.UserSuggestions ||
                          errandContext.morphType === MorphType.CreditRepairDisputeAccountType}
                          // !isRecording && 
                          // selectedFiles?.length === 0 && 
                          // errandContext.morphType !== MorphType.ShareCustomLink && 
                          // errandContext.morphType !== MorphType.VideoListMenu &&
                          // fieldAttribute?.description !== 'DROPDOWN'}
                        handleSubmit={
                            errandContext.morphType === MorphType.ShareCustomLink ? sendActionWorkflow :
                            errandContext.morphType === MorphType.VideoListMenu ? sendVideoMessageFromMenu : handleSubmit}
                        ref={sendButtonRef}
                        userActions={errand?.participants?.[0]?.userActions}
                        workflow={errand?.workflow}
                      />
                    }
                                      </>
                )}
              </div>
            {(
              errandContext.morphType === MorphType.Attachment || 
              errandContext.morphType === MorphType.MessageOptions || 
              errandContext.morphType === MorphType.UserPromptsMenu ||
              errandContext.morphType === MorphType.VideoListMenu ||
              errandContext.morphType === MorphType.CreditRepairDisputeAccountType) && fieldAttribute?.description !== 'PHOTO' && fieldAttribute?.description !== 'SKIPPABLE PHOTO' && fieldAttribute?.description !== 'MAINPHOTO' && fieldAttribute?.description !== 'DOCUMENT' && (
              <AttachmentMenuCloseButton
                cancelAction={cancelAction}
                errand={errand}
                setSelectedFiles={setSelectedFiles}
              />
            )}
          </div>
        </ConversationFooterStyle>
      </MorphContext.Provider>
    </FooterContext.Provider>
  );
};

export default ConversationFooter;