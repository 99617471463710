/**
 * @file LeftButtonMorphLoanProducts.tsx
 * @description This component controls the buttons shown left of input when
 * the MorphType is LoanProducts.
 */

import { MorphType } from '@common/MorphType';
import { useErrandContext } from '@contexts/ErrandContext';
import { useMorphContext } from '@contexts/MorphContext';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import Styles from '@styles/ChooseLoanProduct/LeftButtonMorphLoanProducts.module.css';
import React from 'react';
import axiosCall from '@services/axios';
import { useRootContext } from '@contexts/RootContext';
import { IMessage } from '@interfaces/Conversation';
import { useTranslation } from 'react-i18next';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';

const LeftButtonMorphLoanProducts = () => {
  /**
   * If the price table is open and nothing is selected, render the [Back] button
   *    - on click, table should be unmounted
   * 
   * If the price table is open and cell is selected, render the [Done] button
   *
   * If there is 1 loan product being compared, render the [Finish] button
   *    - on click, product should be sent and action marked resolved
   *
   * If there is >1 loan product being compared,
   *    If the currently hovered product is being compared, render the [Remove]
   *      - on click, currently viewed loan product will be removed from compare
   *
   *    If the currently hovered product is not compared, render the [Add]
   *      - on click, product will be added to the compare message bubble
   */
  const { comparedLoanProducts, setComparedLoanProducts, morphType, setMorphType, errand } = useErrandContext();
  const {
    hoveredLoanProduct,
    setHoveredLoanProduct,
    setChosenLoanProduct,
    msgReqStateRef,
    setFieldAttribute,
    selectedPrices,
    setSelectedPrices,
  } = useMorphContext();
  const rootContext = useRootContext();
  const { t } = useTranslation();
  const { _id } = useUserContext();

  const addMessageIdToMessagesArray = (response) => {
    if (!response || !response._id) return;
    rootContext.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let index = prev.findIndex((e) => e._id === errand._id);
      if (index === -1) return prev;
      let messageIndex = prev[index].messages.findIndex((e) => e._id === response._id);
      if (messageIndex !== -1) return prev;
      if (errand.recipients?.length > 0) {
        prev[index].privateMessages = [...(prev[index].privateMessages || []), { _id: response._id } as IMessage];
      } else {
        prev[index].messages = [...prev[index].messages, { _id: response._id } as IMessage];
      }
      return [...prev];
    });
  };

  const resetStates = () => {
    setMorphType(MorphType.None);
    setHoveredLoanProduct(null);
    setChosenLoanProduct(null);
    setSelectedPrices(null);
    setFieldAttribute(null);
  };

  const handleClickFinish = async () => {
    // choose loan product
    let chosenLoanProduct: LoanProduct = {
      ...hoveredLoanProduct,
    };

    if (selectedPrices[chosenLoanProduct.id]) {
      chosenLoanProduct.lockPeriod = selectedPrices[chosenLoanProduct.id].lockPeriod;
      chosenLoanProduct.rate = selectedPrices[chosenLoanProduct.id].rate;
      chosenLoanProduct.price = selectedPrices[chosenLoanProduct.id].price;
    }
    setChosenLoanProduct(chosenLoanProduct);

    // set chooseLoanProdcut action as inactive so the footer doesn't morph again
    rootContext.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let index = prev.findIndex((e) => e._id === errand._id);
      if (index === -1) return prev;
      let lastMessage = prev?.[index]?.messages?.[prev?.[index]?.messages?.length - 1] as IMessage;
      prev[index].action.active = false;
      return [...prev];
    });

    // send the loan product as Field message
    const data = {
      sender: _id,
      senderType: 'User',
      message: `${chosenLoanProduct.productDescription} ${chosenLoanProduct.paymentDescription}
      Rate: ${chosenLoanProduct.rate}%
      Lock Period: ${chosenLoanProduct.lockPeriod} days
      Price: ${chosenLoanProduct.price.toFixed(3)}
      <separator/>
      ${JSON.stringify(chosenLoanProduct)}`,
      accessType: AccessType.public,
      messageType: 'Field',
      userAction: errand?.action?.userActionId,
    };
    const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data: data };
    try {
      // mark current message post request as sent
      msgReqStateRef.current.sent();
      let response = await axiosCall(payload);
      addMessageIdToMessagesArray(response);
      // once response is received, mark as finished
      msgReqStateRef.current.finished();
      console.log(`Message sent: `, response);
    } catch (error: any) {
      // mark current message post request to have errors
      msgReqStateRef.current.finished(error as Error);
      console.error(`Message Error: ${error.message}`);
    }
    resetStates();
  };

  const handleRemoveHovered = () => {
    // remove it from comparedLoanPrdocuts
    setComparedLoanProducts((prev) => prev.filter((compared) => compared.id !== hoveredLoanProduct.id));
  };

  const handleAddHovered = () => {
    // remove it from comparedLoanPrdocuts
    setComparedLoanProducts((prev) => [...prev, hoveredLoanProduct]);
  };

  const handleBack = () => {
    // change back to LoanProduct morph type
    setMorphType(MorphType.LoanProducts);
  };

  const handleDone = () => {
    // update hovered loan product then
    // replace it in comparedLoanProducts
    const updatedLoanProduct = {
      ...hoveredLoanProduct,
      ...selectedPrices[hoveredLoanProduct.id],
    };

    setComparedLoanProducts((prev) => {
      const index = prev.findIndex((compared) => compared.id === hoveredLoanProduct.id);
      prev[index] = updatedLoanProduct;
      return prev;
    });

    setMorphType(MorphType.LoanProducts);
  };

  if (!hoveredLoanProduct) {
    return;
  }

  if (morphType === MorphType.LoanProductPriceTable) {
    if (selectedPrices[hoveredLoanProduct.id]) {
      return (
        <button onClick={handleDone} className={Styles.leftButton}>
          {t('doneButton')}
        </button>
      );
    }
    return (
      <button onClick={handleBack} className={Styles.leftButton}>
        {t('tBack')}
      </button>
    );
  }

  if (
    comparedLoanProducts.length === 1 &&
    comparedLoanProducts.find((compared) => compared.id === hoveredLoanProduct.id)
  ) {
    return (
      <button onClick={handleClickFinish} className={Styles.leftButton}>
        {t('buttonFinish')}
      </button>
    );
  }

  if (comparedLoanProducts.find((compared) => compared.id === hoveredLoanProduct.id)) {
    return (
      <button onClick={handleRemoveHovered} className={Styles.leftButton}>
        {t('tRemove')}
      </button>
    );
  } else {
    return (
      <button onClick={handleAddHovered} className={Styles.leftButton}>
        {t('genericTableAdd')}
      </button>
    );
  }
};

export default LeftButtonMorphLoanProducts;
