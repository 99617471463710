/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 *
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 *
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphUserSuggestionsSkeleton.module.css';
import useLazyComponent from '@common/hooks/useLazyComponent';
import { IErrand } from '@interfaces/Conversation';

const MorphUserSuggestionsSkeletonFallback = () => {
    return <div className={Skeleton.loader + ' ' + Styles.style} />;
};

// The render component below lazy loads the corresponding component using the skeleton above

type TRenderMorphUserSuggestionsSkeletonProps = {
    errand: IErrand;
   cancelAction: (key: any, clear: boolean) => void;
};

const MorphUserSuggestionsSkeleton = ({
    errand, cancelAction
}: TRenderMorphUserSuggestionsSkeletonProps) => {
    const MorphUserSuggestionsSkeletonProps = {
        errand,
        cancelAction
    };

    const MorphUserSuggestionsSkeleton = useLazyComponent(
        import('@components/MorphUserSuggestions/MorphUserSuggestions'),
        <MorphUserSuggestionsSkeletonFallback />,
        `MorphUserSuggestionsSkeleton: ${JSON.stringify(MorphUserSuggestionsSkeletonProps)}`
    );

    return <MorphUserSuggestionsSkeleton {...MorphUserSuggestionsSkeletonProps} />;
};

export default MorphUserSuggestionsSkeleton;
