import { useTranslation } from 'react-i18next';
import React, { PropsWithChildren } from 'react';

import { Styles } from '../Styles/PublicInternalToggleStyles';

const PublicInternalToggle: React.FC<PropsWithChildren<any>> = (props) => {
  const { t } = useTranslation();

  return (
    <Styles
      className={props.isPublic ? 'public' : 'internal'}
      onClick={() => props.setIsPublic((prev) => !prev)}>
      {props.isPublic ? t('conversationFooterPublic') : t('conversationFooterInternal')}
    </Styles>
  );
};

export default PublicInternalToggle;
