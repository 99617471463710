import React, { useEffect, useState, useContext, useCallback, useRef, useMemo } from 'react';

import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { isMobile, isMobileOrTablet } from '@common/deviceTypeHelper';
import useAbortController from '@common/hooks/useAbortController';
import Styles from '@styles/MorphUserSuggestions.module.css';
import { useFooterContext } from '@contexts/FooterContext';
import { useTranslation } from 'react-i18next';
import { SwiperClass } from 'swiper/react';

import { ValidatorFunctions } from '@common/Validators';
import { MainSwiper } from './MainSwiper'
import { _customDebounce } from '@components/MorphUserPromptsMenu/utils';
import { useAppContext } from '@contexts/AppContext';
import LanguageUtils from '@common/LanguageUtils';
import { MorphType } from '@common/MorphType';
import { IErrand } from '@interfaces/Conversation';

const translationTools = (function () {
    const prepareStrArrData = (strArr) => strArr.map((str) => ({ message: str, source: 'en' }))
    const translateArrOfStr = async (strArr, targetLn, abortController) => await LanguageUtils.translateMany(prepareStrArrData(strArr), targetLn, abortController);
    return {
        translateArrOfStr
    }
})();

const getInitSlideListItemsState = () => {
    return [
        { text: "Can we do FHA Cash out loans in TX?" },
        { text: "Can I use departing residence income in VA loans?" },
        { text: "What is the minimum FICO for FHA loan?" },
        { text: "What is the county limit for Los Angeles county?" },
        { text: "What kind of DPA options do you have for CA?" }
    ]
}

const MorphUserSuggestions = React.forwardRef((props: { errand: IErrand, cancelAction: (key: string | null, clear: boolean, withMorphTypeChange: boolean) => void }, ref) => {
    const errandContext = useContext<IErrandContext>(ErrandContext);
    const footerContext = useFooterContext();
    const [slideListItems, setSlideListItems] = useState(getInitSlideListItemsState());

    const { t, i18n } = useTranslation();
    const abortController = useAbortController();

    const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null);
    const [hasMounted, setHasMounted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const tmsRef = useRef<NodeJS.Timeout[]>([]);
    const swiperSectionRef = useRef(null);
    // includes tablet
    const isMobileDevice = isMobileOrTablet();
    const { unmountStarted } = errandContext.welcomeUserMessageState.userSuggestions;
    const alreadyClickedRef = useRef(false);
    const tmIdsRef = useRef([]);

    const focusElement = useCallback(
        (idx) => {
            if (ValidatorFunctions.isNotUndefinedNorNull(idx) && !isNaN(idx)) {
                const htmlEl = swiperInstance?.el?.children[0]?.children[idx] as HTMLElement;
                htmlEl?.focus();
            }
        },
        [swiperInstance]
    );

    const resetState = useCallback(
        (unmount = false) => {
            if (!swiperInstance) return;
            footerContext.sendButtonRef.current?.update('');
            if (!unmount) {
                setTimeout(() => {
                    focusElement(swiperInstance?.activeIndex);
                }, 0);
            }
        },
        [swiperInstance, focusElement]
    );

    const clearTimeouts = () => {
        if (tmsRef.current.length === 0) return;
        for (const tID of tmsRef.current) {
            clearTimeout(tID);
        }
        // clear arr
        tmsRef.current = [];
    };

    const handleTabForward = useCallback(
        (disableNavigationBeyondBoundaries = false) => {
            // if reached left Edge and or beyond

            // not yet reached an edge
            swiperInstance?.slideNext();
        },
        [swiperInstance]
    );

    const handleTabBackward = useCallback(
        (disableNavigationBeyondBoundaries = false) => {
            // if we did not reach an edge
            swiperInstance?.slidePrev();
        },
        [swiperInstance]
    );

    const onSingleSlideClickHandler = useCallback(
        async (e, idx, text) => {

            if (alreadyClickedRef.current === true) return;
            if (!swiperInstance) return;

            // mark clicked already flag
            alreadyClickedRef.current = true;

            // run "unmount started" animation
            errandContext.setWelcomeUserMessageState((prevState) => {
                return {
                    ...prevState,
                    userSuggestions: {
                        unmountStarted: true
                    }
                }
            })

            // in one second run all the other events
            // (populate the input box with the value from the clicked suggestion)
            // (send that text in chat)
            tmIdsRef.current.push(
                setTimeout(() => {
                    footerContext.sendButtonRef.current?.update(text);
                    footerContext.chatInputFieldRef.current?.update(text);
                    // setTimeout is used to ensure that the sendButton gets focused and focus is not lost by browser.
                    // without it, it does not focus sendButton. Bahavior may vary on different browsers.
                    setTimeout(() => {
                        // Send directly in chat
                        footerContext.sendButtonRef.current?.handleSubmit();
                    }, 350);
                }, 1000)
            );
        },
        [
            swiperInstance,
            focusElement
        ]
    );

    // ==================== UseEffects START ====================
    // initial fetch
    useEffect(() => {
        setHasMounted(true);
        setIsLoading(false);

        // cancel on mount
        if (
            ValidatorFunctions.isNotUndefinedNorNull(props?.errand) &&
            ValidatorFunctions.isNotUndefinedNorNull(props?.errand?.action)
        ) {
            if (props?.cancelAction) props.cancelAction(null, true, false);
        }
        // set body to unscrollable
        // change the style
        document.body.style.overflow = 'hidden';

        // on desktops only
        if (isMobileOrTablet() === false) errandContext.setMultipleFooterTypingAnimations({ items: ['Hit "Escape" to exit the menu'], delay: 2000, speed: 30, currentActiveItem: -1 });

        // Unmount Handler
        return () => {
            clearTimeouts();
            abortController.abort();
            resetState(true);
            // make page scrollable again
            document.body.style.overflow = '';
            // reset unmount started for next animation cycle. appear/hide
            errandContext.setWelcomeUserMessageState((prevState) => {
                return {
                    ...prevState,
                    userSuggestions: {
                        unmountStarted: false
                    }
                }
            })

            tmIdsRef.current.forEach((tmId, idx) => clearTimeout(tmId));
        };
    }, []);

    const close = () => {
        errandContext.setWelcomeUserMessageState((prevState) => {
            return {
                ...prevState,
                userSuggestions: {
                    unmountStarted: true
                }
            }
        })
        errandContext.setMultipleFooterTypingAnimations(null);
        tmIdsRef.current.push(setTimeout(() => errandContext.setMorphType(MorphType.None), 1000));
    }

    useEffect(() => {
        // Function to handle the Escape key press
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                close(); // Call the close function when Escape is pressed
            }
        };

        // Add event listener for keydown events
        window.addEventListener('keydown', handleEsc);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [close]);


    // Translations
    const slideListItemsDataToArrOfStr = () => slideListItems.map((itemData) => itemData.text);
    const translatedArrOfStrToSlideListItems = (translatedArrOfStr) => {
        return translatedArrOfStr.map((translatedStr) => {
            return {
                text: translatedStr
            }
        });
    }
    useEffect(() => {
        if (i18n.language === 'en') {
            // set the init state
            setSlideListItems(getInitSlideListItemsState())
        } else {
            try {
                (async () => {
                    const translatedArrOfStr = await translationTools.translateArrOfStr(slideListItemsDataToArrOfStr(), i18n.language, abortController)
                    setSlideListItems(translatedArrOfStrToSlideListItems(translatedArrOfStr));
                })()
            } catch (err) {
                console.error(err);
                setSlideListItems(getInitSlideListItemsState());
            }
        }

        return () => {
            abortController.abort();
        }
    }, [i18n.language]);
    // ==================== UseEffects END ====================

    const mainWrapperClassStr = `
        ${Styles.wrapper} 
        ${isLoading ? Styles.loadingWrapper : ''}
    `;

    return (
        <>
            <section className={mainWrapperClassStr} ref={swiperSectionRef}>
                <MainSwiper
                    onSingleSlideClickHandler={onSingleSlideClickHandler}
                    setSwiperInstance={setSwiperInstance}
                    handleTabBackward={handleTabBackward}
                    handleTabForward={handleTabForward}
                    slideListItems={slideListItems}
                    swiperInstance={swiperInstance}
                    focusElement={focusElement}
                    unmountStarted={unmountStarted}
                    style={{ paddingBottom: '8px' }}
                    isLoading={isLoading}
                />
                <div className={Styles.accessibilityDescDiv}>
                    {t('UPM_First_Item_in_the_list')}
                </div>
                <div className={Styles.accessibilityDescDiv}>
                    {t('UPM_Last_Item_in_the_list')}
                </div>
            </section>
            <div
                aria-hidden="true"
                className={
                    Styles.bottomBorder +
                    ' ' +
                    (errandContext.isMorphedFooterCloseButtonOnLeft === true ? Styles.isMorphedFooterCloseButtonOnLeft : '')
                }
            ></div>
        </>
    );
});

export default MorphUserSuggestions;
