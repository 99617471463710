import React, { Dispatch, PropsWithChildren, SetStateAction, useEffect, useRef, useState } from 'react';
import ErrorBoundary from '@components/ErrorBoundary';
import axiosCall from '@services/axios';
import InfiniteScroll from 'react-infinite-scroller';
import useAbortController from "@common/hooks/useAbortController";
import useDebounce from '@common/hooks/useDebounce';
import Styles from '@styles/UserPromptList.module.css';
import getImageSource from '@common/getImageSource';
import UserPromptListItem from '@components/UserPromptListItem';
import UserPromptListHeader from '@components/UserPromptListHeader';
import UserPromptListFooter from '@components/UserPromptListFooter';
import { useTranslation } from 'react-i18next';
import LanguageUtils from '@common/LanguageUtils';


// Defined type for fetched array of actions
type Action = {
  _id: string;
  type: string;
  message: string;
  fieldName: string;
  displayName: string;
  description: string;
  actionIcon: string;
}
// Defined type for fetched array of workflows
type Workflow = {
  _id: string;
  displayName: string;
  description: string;
  name: string;
  workflowIcon: string;
}

type TUserPromptListProps = {
  setShowUserPromptList: Dispatch<SetStateAction<boolean>>;
  showUserPromptList: boolean;
}

const UserPromptList: React.FC<PropsWithChildren<any>> = ({
  setShowUserPromptList, showUserPromptList,
}: TUserPromptListProps) => {
  const { t } = useTranslation();
  // Ref for dictating offset of network calls 
  const offsetRef = useRef(0);
  // Ref for monitoring if List is full or not, to prevent issues where users can't load more items
  const promptRef = useRef(null);
  // Value to inform InfiniteScroller if to fire loadMore function and specific action call
  const [hasMoreActions, setHasMoreActions] = useState(true);
  // Value to inform InfiniteScroller if to fire loadMore function and specific workflow call
  const [hasMoreWorkflows, setHasMoreWorkflows] = useState(true)
  // Array holding actions and workflows
  const [actionsAndWorkflows, setActionsAndWorkflows] = useState([]);
  // Variable monitoring when network calls are occurring, to prevent over firing
  const [fetching, setFetching] = useState(false);
  // String used for searching via network calls
  const [searchString, setSearchString] = useState('');
  // Variable used to display loading spinner when searching
  const [loading, setLoading] = useState(true);
  const abortController = useAbortController();
  // Variable for handling rendering when search bar is open or not
  const [isSearchOpen, setIsSearchOpen] = useState(false);


  // Function for sending the action or workflow into a chat

  /** Isolated function for the search of specific actions fetching network call promise to be done at the same time as the workflows promise
  *  @function
  * @param {string} searchString - Flag to let function know we no longer want to use search strings, and wipe the current state actions/workflows, when to ignore the wipe
  */
  const searchActions = (searchString: string) => {
    return new Promise<Array<Action>>(async (resolve, reject) => {
      try {
        const config = abortController.reset();
        let actions = await axiosCall({
          url: 'action/db/search?active=true&visibility.user=true&type=Field',
          method: 'post',
          data: {
            search: searchString
          }
        }, config);
        resolve(actions);
      } catch (e) {
        reject(e);
      }
    })
  }

  /** Isolated function for the search of specific workflows fetching network call promise to be done at the same time as the actions promise
  *  @function
  * @param {string} searchString - Flag to let function know we no longer want to use search strings, and wipe the current state actions/workflows, when to ignore the wipe
  */
  const searchWorkflows = (searchString: string) => {
    return new Promise<Array<Workflow>>(async (resolve, reject) => {
      try {
        const config = abortController.get();
        let workflows = await axiosCall({
          url: 'workflow/db/search?active=true&visibility.user=true&fields=_id,displayName,description,name,workflowIcon',
          method: 'post',
          data: {
            search: searchString
          }
        }, config);
        resolve(workflows);
      } catch (e) {
        reject(e);
      }
    })
  }

  /** Isolated function for the specific actions fetching network call promise to be done at the same time as the workflows promise
   *  @function 
  * @param {boolean} overwrite - Flag to let function know we no longer want to use search strings, and wipe the current state actions/workflows, when to ignore the wipe
  */
  const fetchActions = (overwrite: boolean) => {
    return new Promise<Array<Action>>(async (resolve, reject) => {
      // If we know we don't have any actions, we return an empty array
      if (!hasMoreActions && !overwrite) {
        resolve([])
      }
      // Retrieve at most 12 actions, only active, and visible to users
      try {
        const config = abortController.get();
        let actions = await axiosCall({ url: `action?limit=12&offset=${offsetRef.current}&fields=actionIcon,description,displayName,fieldName,message,type,_id&type=Field&active=true&visibility.user=true` },
          config);
        resolve(actions);
      } catch (e) {
        reject(e)
      }
    })
  }

  /** Isolated function for the specific workflows fetching network call promise to be done at the same time as the actions promise
   *  @function
  * @param {boolean} overwrite - Flag to let function know we no longer want to use search strings, and wipe the current state actions/workflows, when to ignore the wipe
   * 
  */
  const fetchWorkflows = (overwrite: boolean) => {
    return new Promise<Array<Workflow>>(async (resolve, reject) => {
      // If we know we don't have any workflows, we return an empty array
      if (!hasMoreWorkflows && !overwrite) {

        resolve([]);
      }
      // Retrieve at most 12 workflows, only active, and visible to operators and user
      try {
        const config = abortController.get();
        let workflows = await axiosCall({
          url: `workflow?limit=12&offset=${offsetRef.current}&fields=_id,displayName,description,name,workflowIcon&active=true&visibility.user=true`
        },
          config);
        resolve(workflows);
      } catch (e) {
        reject(e)
      }
    })
  }

  /**  Function for retrieving actions and workflows at the same time
  * @function
  * @param {boolean} overwrite - Flag to let function know we no longer want to use search strings, and wipe the current state actions/workflows, when to ignore the wipe
  */
  const fetchAll = async (overwrite: boolean = false) => {
    // If we are already searching for actions and workflows, we return
    if (fetching) {
      return;
    }
    abortController.reset();
    setFetching(true);
    try {
      let [actions, workflows]: [Array<Action>, Array<Workflow>] = await Promise.all([fetchActions(overwrite), fetchWorkflows(overwrite)]);
      const moreActions = actions?.length === 12;
      const moreWorkflows = workflows?.length === 12;
      // If actions are equal to the limit, we can assume we have more items for the next request
      setHasMoreActions(moreActions);
      // If workflows are equal to the limit, we can assume we have more items for the next request
      setHasMoreWorkflows(moreWorkflows);
      const translatedPrompts = await translatePrompts([...actions.slice(0, 6), ...workflows.slice(0, 6), ...actions.slice(6, 12), ...workflows.slice(6, 12)])
      if (overwrite) {
        setActionsAndWorkflows([...translatedPrompts]);
      } else {
        setActionsAndWorkflows(prev => [...prev, ...translatedPrompts]);
      }
      if (moreActions || moreWorkflows) {
        // If we have more items, we increase the offset so we can get the next set of actions or workflows
        offsetRef.current += 12;
      }
    } catch (err) {
      console.error('Error grabbing workflows and actions:', err);
    } finally {
      // In the case that the list of actions and workflows isn't log enough to fill infinite scroll, and
      // allow a scroll to trigger another fetch, we keep grabbing actions and workflows until the list is full      
      if (promptRef.current?.scrollHeight === 0 && (hasMoreActions || hasMoreWorkflows) && showUserPromptList) {
        fetchAll();
      }
      // Finally block we always make sure to set fetching and loading to false, as we are done with the function/network calls
      setFetching(false);
      setLoading(false);
    }
  }

  // LoadMore function for InfiniteScroller, by itself for readability
  const loadMore = () => {
    fetchAll();
  };

  const translatePrompts = async (prompts) => {
    try {
      const language = LanguageUtils.fetchLocalizationLanguageSetting() || 'en';
      if (!LanguageUtils.fetchTranslationEnabledSetting()) return prompts;
      if (language === 'en') return prompts;
      const translatedPrompts = [];
      for await (const prompt of prompts) {
        const translatedPrompt = { ...prompt };
        const name = prompt.displayName || prompt.name || prompt.fieldName || '';
        if (name) {
          try {
            const displayName = await LanguageUtils.translateOne(name, language);
            if (displayName) {
              translatedPrompt.displayName = displayName;
            }
          } catch (err) {
            console.log(err);
          }
        }

        const description = prompt.description;
        if (description && prompt.description === t(prompt.description)) {
          try {
            const tDescription = await LanguageUtils.translateOne(description, language);
            if (tDescription) {
              translatedPrompt.description = tDescription;
            }
          } catch (err) {
            console.log(err);
          }
        }
        translatedPrompts.push(translatedPrompt);
      }
      return translatedPrompts;
    } catch (err) {
      console.error(`Could not translate prompts: `, prompts, err);
      return prompts;
    }
  }

  // UseEffect for monitoring change in search string to fire separate network call specific for using the search string
  // entered in the search field
  const handleSearch = async () => {
    setLoading(true);
    abortController.reset();
    // If there is a searchString, we execute a search at the end of the typing timer
    if (searchString.length > 0) {
      // Pass our search string into our search promises
      let [actions, workflows]: [Array<Action>, Array<Workflow>] = await Promise.all([searchActions(searchString), searchWorkflows(searchString)]);
      const translatedPrompts = await translatePrompts([...actions, ...workflows]);
      // set this result
      setActionsAndWorkflows([...translatedPrompts]);
      setLoading(false);
      // If the typing ends with an empty search string, we reset the offset, and regrab our initial actions and workflows
    } else if (searchString === '') {
      offsetRef.current = 0;
      fetchAll(true);
    }
  }

  const handleSearchClose = () => {
    setIsSearchOpen(false);
    setSearchString('');
  };

  useEffect(() => {
    if (actionsAndWorkflows.length === 0 && showUserPromptList && searchString === '') {
      handleSearch();
    }
  }, [showUserPromptList, actionsAndWorkflows, searchString])

  useDebounce(handleSearch, 600, [searchString]);

  return (
    <ErrorBoundary debug={`./src/Components/UserPromptList.tsx: level 0`}>
      <aside className={[
        Styles.slide,
        ...(showUserPromptList ? [Styles.showUserPromptList] : [])
      ].join(' ')}>
        <UserPromptListHeader
          searchString={searchString}
          setSearchString={setSearchString}
          isSearchOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
          handleSearchClose={handleSearchClose}
        />
        <ul ref={promptRef}>
          <>
            {loading ? (
              <div>
                <UserPromptListItem
                  _id=''
                  src=''
                  type='loading'
                  name={t('userPromptListLoadingName')}
                  description={t('userPromptListLoadingDescription')}
                  setShowUserPromptList={setShowUserPromptList}
                  showUserPromptList={showUserPromptList}
                  handleSearchClose={handleSearchClose}
                />
              </div>
            ) : !actionsAndWorkflows.length ? (
              <div>
                <UserPromptListItem
                  _id=''
                  src=''
                  type='empty'
                  name={t('userPromptListEmptyName')}
                  description={t('userPromptListEmptyDescription')}
                  setShowUserPromptList={setShowUserPromptList}
                  showUserPromptList={showUserPromptList}
                  handleSearchClose={handleSearchClose}
                />
              </div>
            ) : (
              <InfiniteScroll
                initialLoad={false}
                loadMore={loadMore}
                hasMore={!fetching && (hasMoreActions || hasMoreWorkflows)}
                threshold={50}
                useWindow={false}
                pageStart={0}
                isReverse={true}
              >
                {actionsAndWorkflows.map(
                  (action, index) => {
                    return (
                      <ErrorBoundary key={index} debug={`./src/Components/UserPromptList.tsx: level 1`}>
                        <UserPromptListItem
                          _id={action._id}
                          src={getImageSource(action.actionIcon || action.workflowIcon)}
                          type={action.type ? 'action' : 'workflow'}
                          name={action.displayName || action.name || action.fieldName || ''}
                          description={action.description || ''}
                          setShowUserPromptList={setShowUserPromptList}
                          showUserPromptList={showUserPromptList}
                          handleSearchClose={handleSearchClose}
                        />
                      </ErrorBoundary>
                    );
                  }
                )}
              </InfiniteScroll>
            )}
          </>
        </ul>
        <UserPromptListFooter
          setShowUserPromptList={setShowUserPromptList}
          showUserPromptList={showUserPromptList}
        />
      </aside>
    </ErrorBoundary >
  );
};

export default UserPromptList;