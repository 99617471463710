import { MorganTheme, styled, useTheme} from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ConsoleLoading from '@components/ConsoleLoading';
import ConsoleWrapperComponent from '@components/ConsoleWrapper';
import { UserProvider } from '@contexts/user';
import { SocketProvider } from '@contexts/socket';
import Modal from '@mui/material/Modal';

const queryClient = new QueryClient();
interface consoleProps {
  theme: MorganTheme,
  isReady: boolean,
}

interface contentProps {
  isReady: boolean,
}

const ConsoleLoadingWrapper = styled(Modal)<consoleProps>((props) => ({
  maxHeight: props.isReady ? 0 : '100vh',
  overflow: props.isReady ? 'hidden' : 'unset',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: props.theme.palette.peach['010'],
  width: '100vw',
  zIndex: 9999,
  position: 'absolute',
  zIndex: '1301 !important',
  '&>div[aria-hidden]': {
    display: 'none'
  }
}));

const ContentWrapper = styled('div')<contentProps>(({ isReady }) => ({
  maxHeight: isReady ? '100vh' : 0,
  overflow: isReady ? 'unset' : 'hidden',
}));

const Console : React.FC<{}> = () => {
  const theme : MorganTheme = useTheme();
  const [progress, setProgress] = useState(0);
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (progress >= 100) {
      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return (
    <UserProvider>
      <SocketProvider>
        <ConsoleLoadingWrapper theme={theme} open={!isReady}>
          <ConsoleLoading progress={progress} />
        </ConsoleLoadingWrapper>
        <ContentWrapper isReady={isReady}>
        <QueryClientProvider client={queryClient}>
          <ConsoleWrapperComponent
            progress={progress}
            setProgress={setProgress}
          />
        </QueryClientProvider>
        </ContentWrapper>
      </SocketProvider>
    </UserProvider>
  );
};

export default Console;