/**
 * @file MorphChooseLoanProducts.tsx
 * @description Renders the component that allows the user to choose a loan
 * product from the pricing engine. Part of Lock The Loan workflow.
 * @author Eric Velepucha
 */

import { MorphType } from '@common/MorphType';
import useAbortController from '@common/hooks/useAbortController';
import useInitialMount from '@common/hooks/useInitialMount';
import { useErrandContext } from '@contexts/ErrandContext';
import { useMorphContext } from '@contexts/MorphContext';
import { useRootContext } from '@contexts/RootContext';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import axiosCall from '@services/axios';
import Styles from '@styles/ChooseLoanProduct/MorphChooseLoanProducts.module.css';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
const Eye = process.env.REACT_APP_MORGAN_CDN + '/Images/Eye.png';

type TProps = {
  setText: Dispatch<SetStateAction<string>>;
};

const MorphChooseLoanProducts = ({ setText }: TProps) => {
  const { loanProducts, setLoanProducts, setHoveredLoanProduct, hoveredLoanProduct } = useMorphContext();
  const { comparedLoanProducts, setComparedLoanProducts, setMorphType, errandId } = useErrandContext();
  const { setErrands } = useRootContext();
  const abortController = useAbortController();
  const { t } = useTranslation();

  // make a fieldEndpoint request
  // store loan products as state in context
  const updateInputPlaceholder = useCallback(
    (value: string) => {
      setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let index = prev.findIndex((e) => e._id === errandId);
        if (index === -1) return prev;
        prev[index].placeholder = value;
        return prev;
      });
    },
    [setErrands, errandId]
  );

  const getLoanProducts = useCallback(async () => {
    const chatId = errandId;
    const config = abortController.get();
    const url = `fieldEndpoint/loanProducts/?chatId=${chatId}`;
    const request = {
      url: url,
    };
    const availableLoanProducts: LoanProduct[] = await axiosCall(request, config);
    setLoanProducts(availableLoanProducts);
    setHoveredLoanProduct(hoveredLoanProduct || availableLoanProducts[0]);
    updateInputPlaceholder(t('chooseALoanProduct'));
    setText(t('chooseYourProduct'));
  }, [
    abortController,
    errandId,
    hoveredLoanProduct,
    setHoveredLoanProduct,
    setLoanProducts,
    setText,
    t,
    updateInputPlaceholder,
  ]);

  const handleSlideClick = (product: LoanProduct) => {
    // add to compared loan products arr if not already there
    // update the message on the DB to reflect the same
    if (!comparedLoanProducts.find((compared) => compared.id === product.id)) {
      setComparedLoanProducts((prev) => [...prev, product]);
    }
  };

  const handleSlideChange = (swiper) => {
    const hoveredIndex = swiper.activeIndex;
    const hoveredLoanProduct = loanProducts[hoveredIndex];
    setHoveredLoanProduct(hoveredLoanProduct);
  };

  const handleEditProduct = () => {
    setMorphType(MorphType.LoanProductPriceTable);
    updateInputPlaceholder(t('priceTableInstruction'));
    setText(t('chooseYourRate'));
  };

  const isBeingCompared = (product: LoanProduct) => {
    return comparedLoanProducts.find((compared) => compared.id === product.id);
  };

  useInitialMount(getLoanProducts);

  return (
    <Swiper
      slidesPerView={'auto'}
      centeredSlides={true}
      spaceBetween={10}
      slideToClickedSlide={true}
      className={Styles.swiperContainer}
      onSlideChange={handleSlideChange}
      initialSlide={hoveredLoanProduct?.id || 0}
    >
      {loanProducts.map((product, index) => (
        <SwiperSlide
          key={index}
          onClick={() => handleSlideClick(product)}
          className={Styles.productSlide + ' ' + (isBeingCompared(product) ? Styles.comparedSlide : '')}
        >
          <button className={Styles.editProductButton} style={{backgroundImage: `url(${Eye})`}} onClick={handleEditProduct}></button>
          <div className={Styles.text}>
            <p className={Styles.productDescription}>{product.productDescription}</p>
            <p className={Styles.rate}>
              {t('rate')}: {product.rate}%
            </p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MorphChooseLoanProducts;
