import React from 'react';
import { StaticClassNames } from './constants';
import { AnimatedArrow } from './AnimatedArrow';
import { useTranslation } from 'react-i18next';
const UserPromptsMenuArrowStatic = process.env.REACT_APP_MORGAN_CDN + '/Images/UserPromptsMenuArrowStatic.png';

const StaticArrow = (props) => {
  const { t } = useTranslation();

  return (
    <img
      className={StaticClassNames.DefaultArrow[props.side]}
      src={UserPromptsMenuArrowStatic}
      alt={t('UPM_User_prompts_menu_navigation_Arrow') + ` ${props.side === 'left' ? t('UPM_Left') : t('UPM_Right')}`}
    />
  );
};

export const DynamicArrow = (props) => {
  return props.renderAnimatedArrow === true ? (
    <AnimatedArrow
      onAnimationEnd={props.onAnimationEnd}
      side={props.side}
      animationFinishedRef={props.animationFinishedRef}
    />
  ) : (
    <StaticArrow side={props.side} />
  );
};