import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Allign from '@styles/Allign';
import { AccessType } from '@common/AccessType';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import { CalculatorResultMessage } from '@mTypes/Conversation';
import Sanitized from '@components/Sanitized';
import MessageTextStyle from '@styles/MessageTextStyle';
import MessageTime from '@components/MessageTime';
import Styles from '@styles/MessageContent/CreditRepairDisputeAccountsContent.module.css';
import axiosCall from '@services/axios';
import LanguageUtils from '@common/LanguageUtils';
import { useSocketContext } from '@contexts/socket';

type TCreditRepairDisputeAccountsContentProps = {
  message: CalculatorResultMessage;
};

const CreditRepairDisputeAccountsContent = ({ message: messageProp }: TCreditRepairDisputeAccountsContentProps) => {
  const { i18n } = useTranslation();
  const { messagesSocket, isMessagesConnected } = useSocketContext();

  const [message, setMessage] = useState(messageProp);
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  // Translates the different components of this message to the target language. If the target language is not defined
  // or it is English, the message is not translated and the original (English) value of the message is used to update
  // the UI.
  const translateMessage = useCallback(async () => {
    try {
      let targetLang = LanguageUtils.fetchLocalizationLanguageSetting();
      if (!targetLang || targetLang === 'en') {
        // No need to translate, use the original message
        setMessage(messageProp);
        return;
      }

      const messagesToTranslate = [
        ...(messageProp.result.openingMessage ? [{ message: messageProp.result.openingMessage, source: 'en' }] : []),
        ...(!!messageProp.result.itemsList?.length
          ? messageProp.result.itemsList.map((item) => ({
              message: item,
              source: 'en',
            }))
          : []),
        ...(messageProp.result.closingMessage ? [{ message: messageProp.result.closingMessage, source: 'en' }] : []),
      ];
      if (messagesToTranslate.length === 0) {
        return;
      }

      const translationResults = await axiosCall({
        url: 'google/lang/translate',
        method: 'POST',
        data: {
          data: messagesToTranslate,
          language: targetLang,
        },
      });
      if (!Array.isArray(translationResults) || translationResults?.length !== messagesToTranslate.length) {
        console.error('google/lang/translate endpoint did not respond correctly');
        return;
      }

      const newMessageResult: {
        openingMessage?: string;
        itemsList?: string[];
        closingMessage?: string;
      } = {};
      // Determine which components of the message to update based on which ones are defined in the message
      if (message.result.openingMessage) {
        newMessageResult.openingMessage = translationResults[0].translatedMessage;
      }
      if (translationResults.length > 1) {
        let startIndex = message.result.openingMessage ? 1 : 0;
        let endIndex = message.result.closingMessage ? translationResults.length - 1 : translationResults.length;
        newMessageResult.itemsList = translationResults
          .slice(startIndex, endIndex)
          .map((result) => result.translatedMessage);
      }
      if (message.result.closingMessage) {
        newMessageResult.closingMessage = translationResults[translationResults.length - 1].translatedMessage;
      }

      // Update message with translated values
      setMessage((prev) => ({
        ...prev,
        result: {
          ...prev.result,
          ...newMessageResult,
        },
      }));
    } catch (error) {
      console.error(error);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (!isMessagesConnected) return;

    const chatEventHandler = (payload) => {
      if (
        !Array.isArray(message.result.itemsList) ||
        !payload.data?.message
      ) {
        return;
      }
      const newChatMessage = Number(payload.data.message);
      if (!isNaN(newChatMessage)) {
        const index = newChatMessage - 1; // Get the 0-based index
        if (index >= 0 && index < message.result.itemsList.length) {
          setSelectedItemIndex(index);
        }
      }
    };

    messagesSocket.current?.on('chat-message-update', chatEventHandler);

    return () => {
      messagesSocket.current?.off('chat-message-update', chatEventHandler);
    };
  }, [isMessagesConnected, message]);

  // This useEffect tranlsates the different components of this message when the i18.language changes
  useEffect(() => {
    translateMessage();
  }, [translateMessage]);

  return (
    <Allign className={Styles.outerContainer}>
      <ChatBubbleStyle
        sx={{
          border: 1,
          borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
          background: message.accessType === AccessType.internal ? 'var(--peach020)' : 'var(--peach600)',
        }}
        className={Styles.chatBubbleContainer}
      >
        {/* Opening Message */}
        <div className={Styles.openingAndClosingMessage}>
          {message.result.openingMessage && (
            <div style={!!message.result.itemsList?.length ? { marginBottom: '4px' } : {}}>
              <MessageTextStyle>
                <Sanitized
                  highlight={message?.searchWords}
                  html={message.result.openingMessage}
                  visible={message?.visible}
                />
              </MessageTextStyle>
            </div>
          )}
          {/* Items List */}
          {!!message.result.itemsList?.length && (
            <div className={Styles.itemsListContainer}>
              {message.result.itemsList.map((item, index) => (
                <div key={index}>
                  <MessageTextStyle>
                    <span
                      className={[
                        Styles.numberBubble,
                        ...(!message.result.selectable || selectedItemIndex === index ? [Styles.selected] : []),
                      ].join(' ')}
                    >
                      {index + 1}
                    </span>{' '}
                    <MessageTextStyle className={Styles.itemText}>
                      <Sanitized highlight={message?.searchWords} html={item} visible={message?.visible} />
                    </MessageTextStyle>
                  </MessageTextStyle>
                </div>
              ))}
            </div>
          )}
          {/* Closing Message */}
          {message.result.closingMessage && (
            <div className={Styles.openingAndClosingMessage}>
              <MessageTextStyle>
                <Sanitized
                  highlight={message?.searchWords}
                  html={message.result.closingMessage}
                  visible={message?.visible}
                />
              </MessageTextStyle>
            </div>
          )}
        </div>
        <MessageTime message={message} />
      </ChatBubbleStyle>
      {/* // TODO: add the translation tab and add fade in/out logic + component */}
    </Allign>
  );
};

export default CreditRepairDisputeAccountsContent;
