import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { styled } from '@mui/material/styles';

import axiosCall from '@services/axios';
import { APIServiceType } from '@common/APIServiceType';
import StandardTablePagination from '@components/StandardTablePagination';
import TableHeaderBar from '@components/TableHeaderBar';
import AIFunctionDialogEdit from '@components/AIFunctionDialogEdit';
import useControllerCallback from '@common/hooks/useControllerCallback';

const AIFunctions = () => {

  //adjust the styling based on if we are in the console view
  const isConsoleView = window.location.pathname.indexOf('/console') !== -1;

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: isConsoleView && 'var(--gray010)',
      align: 'left',
    },
    [`&.${tableCellClasses.root}`]: {
      borderBottom: isConsoleView && '1px solid var(--gray010)',
      padding: '8px',
    },
  }));
  interface StepsProps {
    edit?: boolean;
    open?: boolean;
    data?: any;
    name?: any;
  }

  const paginationSteps = [15, 50, 100];
  const { t } = useTranslation();
  const pageRef = useRef(0);
  const rowsPerPageRef = useRef(isConsoleView ? paginationSteps[0] : 20);
  const showInactiveRef = useRef(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editAIFunctionState, setEditAIFunctionState] = useState({
    edit: false,
    open: false,
    data: null,
  } as StepsProps);
  const [AIFunctionData, setAIFunctionData] = useState([]);
  const noResults = AIFunctionData.length === 0;

  function getStatusEmoji(status) {
    switch(status) {
        case 'Review Pending':
            return '⏳'; // Hourglass emoji for Review Pending
        case 'Changes Requested':
            return '🔄'; // Loop emoji for Changes Requested
        case 'Approved':
            return '✅'; // Check mark emoji for Approved
        case 'Denied':
            return '❌'; // Cross mark emoji for Denied
        default:
            return ''; // Return an empty string if status is not recognized
    }
}

  const loadFunctionData = useCallback(async (controller?: AbortController) => {    
    let url = searchValue ? 'functions/search' : 'functions';
    url += '?';

    let offset = rowsPerPageRef.current === -1 ? 0 : pageRef.current * rowsPerPageRef.current;
    // Attempt to load one more document to check if there are more available
    let limit = rowsPerPageRef.current + 1;
    url += `offset=${offset}`;
    if (rowsPerPageRef.current !== -1) {
      url += `&limit=${limit}`;
    }
    if (!showInactiveRef.current) {
      url += '&active=true';
    }

    const reqBody = searchValue ? { search: searchValue } : {};
    const request = {
      service: APIServiceType.cells,
      url,
      method: searchValue ? 'post' : 'get',
      data: reqBody,
    };
    const config = controller ? { signal: controller.signal } : {};
    
    setLoading(true);
    try {
      const result = await axiosCall(request, config);
      if (!Array.isArray(result)) {
        throw new Error('Server responded with unexpected data:', result);
      }
      setAIFunctionData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [searchValue]);

  const handleAddAIFunctionOpen = async () => {
    setEditAIFunctionState({ edit: false, open: true, data: null });
  };

  const handleEditAIFunctionOpen = (AIFunction) => {
    const data = {
      name: AIFunction?.name,
      description: AIFunction?.description,
      function: AIFunction?.function,
      reviewState: AIFunction?.reviewState,
      reviewedBy: AIFunction?.reviewedBy,
      reviewComment: AIFunction?.reviewComment,
      active: AIFunction?.active,
      _id: AIFunction?._id,
    };

    setEditAIFunctionState({ edit: true, open: true, data: data });
    setErrorMessage('');
  };

  const handleEditAIFunctionClose = async () => {
    setEditAIFunctionState({ open: false, data: null });
    setErrorMessage('');
  };

  const handleDeleteAIFunction = async (aiFunction) => {
    try {
      const result = await axiosCall({
        service: APIServiceType.cells,
        url: `functions/${aiFunction.name}`,
        method: 'DELETE',
      });
      if (result) {
        await loadFunctionData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditAIFunctionSubmit = async (data, closeDialog) => {
    if (await editAIFunctions(data)) {
      setEditAIFunctionState({ open: false, data: null });
      setErrorMessage('');
      if (closeDialog){
        setEditAIFunctionState({ open: false, data: null });
        setErrorMessage('');
      } else {
        setEditAIFunctionState({ open: true, data, edit: true });
      }
    }
  };

  const handleAddAIFunctionSubmit = async (data, closeDialog) => {
    const result = await addAIFunctions(data);
    if (result) {
      if (closeDialog){
        setEditAIFunctionState({ open: false, data: null });
        setErrorMessage('');
      } else {
        setEditAIFunctionState({ open: true, data: {...data, _id: result._id}, edit: true });
      }
    }
  };

  const editAIFunctions = async (data) => {
    const { ...sendData } = data;
    try {
      let result = await axiosCall({
        service: APIServiceType.cells,
        url: 'functions',
        method: 'PUT',
        data: sendData,
      });

      if (result) {
        await loadFunctionData();
        return result;
      }
    } catch (error) {
      setErrorMessage(error.message || 'Error');
      return false;
    }
  };

  const addAIFunctions = async (data) => {
    try {
      let result = await axiosCall({
        service: APIServiceType.cells,
        url: 'functions',
        method: 'POST',
        data: data,
      });

      if (result) {
        await loadFunctionData();
        return true;
      }
    } catch (error) {
      console.log('addAIFunctions: ERROR - ' + error);
      return false;
    }
  };

  const handleShowInactiveClick = () => {
    showInactiveRef.current = !showInactiveRef.current;
    pageRef.current = 0;
    loadFunctionData();
  };

  const handlePageChange = (_, newPage) => {
    pageRef.current = newPage;
    loadFunctionData();
  };

  const handleRowsPerPageChange = (e) => {
    rowsPerPageRef.current = e.target.value;
    pageRef.current = 0;
    loadFunctionData();
  };

  const handleSearchChange = (newSearchValue: string) => {
    // We want to reset back to the first page when searching so we don't miss any documents (for example, we don't want
    // to begin our search on the second page as we would miss those on the first)
    pageRef.current = 0;
    // Now we can update the search value
    setSearchValue(newSearchValue);
  };

  useControllerCallback(loadFunctionData);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} height={isConsoleView ? "calc(100vh - 175px)": "100vh"}>
      <TableHeaderBar
        onAdd={handleAddAIFunctionOpen}
        onRefresh={loadFunctionData}
        onExport={undefined}
        shouldHaveSearch={true}
        searchValue={searchValue}
        setSearch={handleSearchChange}
        addLabel={t('addFunction')}
        showInactiveClick={handleShowInactiveClick}
        isActive={!showInactiveRef.current}
      />
      <TableContainer sx={{ border: isConsoleView && '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : noResults ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <Paper sx={{ p: 1.5 }}>
              <Typography>{t('noData')}</Typography>
            </Paper>
          </Stack>
        ) : (
          <Table size="small" stickyHeader>
            <TableHead sx={{ backgroundColor: isConsoleView && 'var(--gray000)' }}>
              <TableRow sx={{ backgroundColor: isConsoleView && 'var(--gray000)' }}>
                <StyledTableCell width="300px">{t('nameTranslation')}</StyledTableCell>
                <StyledTableCell width="400px">{t('descriptionTranslation')}</StyledTableCell>
                <StyledTableCell width="200px">{t('reviewState')}</StyledTableCell>
                <StyledTableCell width="200px">{t('reviewedBy')}</StyledTableCell>
                <StyledTableCell width="100px">{t('actionTranslation')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {AIFunctionData.map((aiFunction, index) => {
                // If we are in paginated mode and there is more data available than the page limit (because we attempt
                // to fetch one more row than the limit to check if more data is available), don't display the last row
                if (
                  rowsPerPageRef.current !== -1 &&
                  AIFunctionData.length > rowsPerPageRef.current &&
                  index === rowsPerPageRef.current
                ) {
                  return null;
                }

                return (
                  <TableRow
                    sx={{ backgroundColor: aiFunction.active ? isConsoleView && 'var(--gray000)' : isConsoleView && 'var(--peach030)' }}
                    hover
                    key={aiFunction?._id}
                  >
                    <StyledTableCell>{aiFunction.name}</StyledTableCell>
                    <StyledTableCell>{aiFunction.description}</StyledTableCell>
                    <StyledTableCell>{isConsoleView ? aiFunction.reviewState: getStatusEmoji(aiFunction.reviewState)}</StyledTableCell>
                    <StyledTableCell>{aiFunction.reviewedByFullName}</StyledTableCell>

                    <StyledTableCell>
                      <Tooltip
                        title={t('editAIFunction')}
                        placement="top-start"
                        enterDelay={1000}
                        enterNextDelay={1000}
                      >
                        <BorderColorOutlinedIcon
                          onClick={() => handleEditAIFunctionOpen(aiFunction)}
                          fontSize="small"
                          sx={{ cursor: 'pointer', marginLeft: '3px', color: 'var(--blue210)' }}
                        />
                      </Tooltip>
                      <DeleteOutlinedIcon
                        onClick={() => handleDeleteAIFunction(aiFunction)}
                        fontSize="small"
                        sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                      />
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StandardTablePagination
                  steps={isConsoleView ? paginationSteps : [20, 50, 100]}
                  rowsPerPage={rowsPerPageRef.current}
                  page={pageRef.current}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  isMore={rowsPerPageRef.current === -1 ? false : AIFunctionData.length > rowsPerPageRef.current}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {editAIFunctionState.open && !loading && (
        <AIFunctionDialogEdit
          edit={editAIFunctionState.edit}
          open={editAIFunctionState.open}
          AIFunction={editAIFunctionState.data}
          allFunctions={AIFunctionData}
          error={errorMessage}
          onClose={handleEditAIFunctionClose}
          onSubmit={handleEditAIFunctionSubmit}
          onAdd={handleAddAIFunctionSubmit}
        />
      )}
    </Box>
  );
};

export default AIFunctions;
