import Styles from '@styles/MorphCreditRepairAccountType.module.css';
import { ArrowDirection } from '../types';

export const StaticClassNames = {
  DefaultArrow: {
    right: [Styles.arrowImg, Styles.arrowImgRight, Styles.posAbs].join(' '),
    left: [Styles.arrowImg, Styles.arrowImgLeft, Styles.posAbs].join(' '),
  },
  InvisibleArrow: {
    right: [Styles.arrowImg, Styles.arrowImgRight, Styles.invisibleArrow].join(' '),
    left: [Styles.arrowImg, Styles.arrowImgLeft, Styles.invisibleArrow].join(' '),
  },
};
export const ARROW_DIRS: Record<ArrowDirection, ArrowDirection> = {
  left: 'left',
  right: 'right',
};

export const SWIPER_SLIDE_WIDTH = 90;
export const SPACE_BETWEEN_SLIDES = 15;
export const SWIPER_SLIDE_HEIGHT = 90;
export const SWIPER_SLIDE_LOADING_WIDTH = 100;