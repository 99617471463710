import React from 'react';
import { useTranslation } from 'react-i18next';

import MessageTime from '@components/MessageTime';
import Sanitized from '@components/Sanitized';

import type { IMessage } from '@interfaces/Conversation';

import tStyles from '@styles/Typography.module.css';
import mcStyles from '@styles/MessageContent/MessageContent.module.css';
import { AccessType } from '@common/AccessType';

type TDeletedMessageContentProps = {
  message: IMessage;
}

const DeletedMessageContent = ({
  message, 
}: TDeletedMessageContentProps) => {
  const { t } = useTranslation();

  return message && (
    <div
      className={[
        mcStyles.style,
        mcStyles.peNone,
        ...(message.accessType === AccessType.internal ? [mcStyles.internal] : []),
        ...(message.sentByCurrentUser ? [mcStyles.sentByCurrentUser] : [])
      ].join(' ')}
    >
      <Sanitized
        className={tStyles.base}
        html={t('messageDeleted')}
        visible={message.visible}
        tag="p"
      />
      <MessageTime message={message} />
    </div>
  );
};

export default DeletedMessageContent;
