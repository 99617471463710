import { MorganTheme, Tabs } from '@mui/material';
import { styled } from '@mui/system';

type TStyleProps = {
  theme?: MorganTheme; // NOTICE: Included by default with mui styled
};

export const Style = styled('div')<TStyleProps>(
  ({ theme }) => `
  width: 100%;
  max-height: calc(100vh - 108px);
  .CCWStack {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: calc(100vh - 177px);
    padding-top: 16px;
    gap: 16px;
    .CCWBox {
      display: flex;
      flex-direction: column;
      min-width: 284px;
      max-width: 15%;
      height: 100%;
    }
    .CCWMine {
      min-height: fit-content;
      display: flex;
      flex: 1 1 auto;
    }
  }
  `
);

export const CustomTabs = styled(Tabs)<TStyleProps>(({ theme }) => ({
  '&& .Mui-selected, && .Mui-selected:hover': {
    color: theme.palette.blue['200'],
    '&, & .MuiTab-root': {
      color: theme.palette.blue['200'],
    },
  },
}));