import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useState, useRef, useCallback } from 'react';
import useControllerCallback from '@common/hooks/useControllerCallback';

import Avatar from './Avatar';
import DialogHeader from './DialogHeader';
import ScrollBox from './ScrollBox';
import axiosCall from '../Services/axios';
import getImageSource from '../Common/getImageSource';
import { useTranslation } from 'react-i18next';

/*
 *  This component is the popup dialog that the user interacts with when creating
 * a team chat in the operator console on the chat module under the team
 * subheader.
 *
 *  This component has the following properties:
 *    - open: The state of the modal, whether or not to be open
 *    - onClose: the function to call when closing, since state managment is out of
 *      the scope of this component.
 */

const UserStripe = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container sx={{ display: "flex", alignItems: "center", padding: "8px 0px 8px 16px" }}>
        <Grid item xs={2}>
          <Avatar sx={{ width: "40px" }} status={props.data.status} alt={props.data.name} img={getImageSource(props.data.avatar)} />
        </Grid>
        <Grid item xs={6}>
          <Typography> {props.data.name} </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => { props.addRemoveOperator(props) }}>
            {props.add ? t('createTeamAdd') : t('createTeamRemove')}
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginLeft: "15px", marginRight: "15px" }} />
    </>
  );
};

const CreateTeamModal = (props) => {
  const [operators, setOperators] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const selectedUsers = useRef([])
  // const [teamName, setTeamName] = useState("");
  const [search, setSearch] = useState("");

  const { t } = useTranslation();

  const fetchData = useCallback(async (controller) => {
    let config = controller
      ? {
          signal: controller.signal,
        }
      : {};

    try {
      const ops = await axiosCall({
        url: `operator/?order=asc&orderBy=lastname&active=1`,
      }, config);

      const data = ops
        .filter((op) => op?._id !== props.data.currentOp?._id)
        .map((op) => ({
          name: `${op.firstname} ${op.lastname}`,
          status: op.status,
          avatar: op.avatar,
          _id: op?._id,
          visible: true,
        }));

      setOperators(data);
      setDataToDisplay(data);
    } catch (error) {
      console.error(error);
    }
  },[]);

  // Use the custom hook instead of useEffect
  useControllerCallback(fetchData);

  const addRemoveOperator = (p) => {
    console.log("AddRemove");
    let arr = selectedUsers.current.slice();

    if (p.add && !arr.includes(p.data)) {
      arr.push(p.data);
      let dtd = dataToDisplay.slice();
      let index = dtd.indexOf(p.data);
      if (index > -1) dtd[index].visible = false;
      setDataToDisplay(dtd)
    }
    else {
      let index = arr.indexOf(p.data);
      if (index > -1) arr.splice(index, 1);

      let dtd = dataToDisplay.slice();
      let dtdIndex = dtd.indexOf(p.data);
      if (dtdIndex > -1) dtd[dtdIndex].visible = true;
      setDataToDisplay(dtd)
    }

    selectedUsers.current = arr;
    if (arr.length >= 1){
      //if we selected somebody then create the chat
      handleSubmit();
    }
  }

  const handleSetSearch = (value) => {
    setSearch(value);
    if (value) {
      setDataToDisplay(operators.filter((el) => {
        return (
          el?.name?.toLowerCase()?.includes(value?.toLowerCase())
        );
      }));
    }
    else {
      setDataToDisplay(operators);
    }
  }

  const handleSubmit = () => {

    const users = selectedUsers.current.slice();
    users.push(props.data.currentOp);
    const data = {
      // teamName: teamName,
      operators: users
    }
    props.onSubmit({ ...data });
  }

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('createTeamTitle')} onClose={() => props.onClose()} />
      <DialogContent>
              <Box sx={{ border: "1px solid var(--gray210)" }}>
                <TextField
                  sx={{ margin: "8px 0 8px 16px", paddingRight: "32px" }}
                  fullWidth
                  label={t('createGroupSearch')}
                  variant="outlined"
                  size="small"
                  value={search}
                  onChange={(e) => { handleSetSearch(e.target.value) }}
                />
                <ScrollBox sx={{ borderTop: "1px solid var(--gray210)" }}>
                  {dataToDisplay?.map((op) => {
                    return (
                      <Fragment key={op?._id}>
                        {op.visible && <UserStripe data={op} add addRemoveOperator={addRemoveOperator} />}
                      </Fragment>
                    );
                  })}
                </ScrollBox>
              </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CreateTeamModal;