import React, { useCallback, useRef, useState, useEffect, PropsWithChildren } from 'react';
import ConversationBody from './ConversationBody';
import ConversationFooter from './ConversationFooter';
import ConversationTitle from './ConversationTitle/ConversationTitle';
import Page from './Page';
import LoanConditionsViewer from './LoanConditionsViewer';
import Captcha from './Captcha';
import { Stack, Backdrop, Grow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LinkPreview from './LinkPreview';
import { styled } from '@mui/system';
import { useSocketContext } from '@contexts/socket';
import { useRootContext } from '@contexts/RootContext';
import { CustomLinkContext } from '@contexts/CustomLinkContext';
import { ErrandContext } from '@contexts/ErrandContext';
// ErrandType imports
import ElectronicSignatureWrapper from './ElectronicSignatureWrapper';
import { getCurrentParticipant, getOperatorDisplayString, shouldShowInactive, getOperatorParticipants, getPrimaryParticipant, prepareErrands } from '@common/errandUtils';
import { morphIndentType } from '@common/MorphType';
import { IErrand } from '@interfaces/Conversation';
import { setChatId } from '@storage/userStorage';
import { useTranslation } from 'react-i18next';
import StyledRopeAnimation from './StyledRopeAnimation'
import ErrorBoundary from '@components/ErrorBoundary';
import axiosCall from '../Services/axios';
import { useDrop } from 'react-dnd';
import ConfirmResendWorkflowDialog from './ConfirmResendWorkflowDialog';
import { ElectronicSignatureEventType, FormBodyType, FormBodyScrollStateType, EsignSignatureType, FormBodySizeType, FormBottomButtonType, IElectronicSignatureState, PaymentActionStateType, EsignActionStateType, EsignFormType, FormTypeDetector } from '../Forms/commonForms';
import { ElectronicSignatureStateManager } from '../Forms/ElectronicSignatureStateManager';
import { MorphType } from '@common/MorphType';
import CreateSignatureMobileConversationTitle from './CreateSignatureMobileConversationTitle';
import { FormPhoneFlip2 } from '@assets/Icons';
import { ChatType } from '@common/ChatType';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import ThinClientUtils from '@common/ThinClientUtils';
import { useEventsListener } from '@common/hooks/useEventsSocket';
import { SocketListenerType, TNotificationBannerUpdate, TShowUserWallet } from '@mTypes/TSocket';
import type { TChatStatusClosed } from '@mTypes/TSocket';
import { useLocation } from 'react-router-dom';
import Styles from '@styles/ThemeShift.module.css';
import type YouTubePlayer from 'react-player/youtube';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import { TVideoListMenuState, VideoListMenuState } from './MorphVideoListMenu/types';
import { TUserPromptsMenuState, UserPromptsMenuState } from './MorphUserPromptsMenu/types';
import { useUserContext } from '@contexts/user';
import { TMultipleFooterTypingAnimationsState } from './FooterTypingAnimation/types';


/*
 *  This component renders A morgan conversation. It is the entire big conversation box seen
 *  on the left side of the screen
 *
 *  This component has the following properties:
 *    - messages - An array of messages to be rendered by the ChatMessage component
 *    - operator - The name of the operator passed to the Conversations component
 *    - operatorView - Whether or not this component is in the operator console
 */

const StyledChatContainer: React.FC<PropsWithChildren<any>> = styled(Stack, {
  // shouldForwardProp allows props to be appended to the html if they pass the filter
  // isDekstop, isOnline, and operatorView are not valid html props so they should be filtered out.
  shouldForwardProp: (prop) =>
    prop !== 'isDesktop' && prop !== 'isOnline' && prop !== 'isTyping' && prop !== 'operatorView' && prop !== 'isCreateSignatureMobile' && prop !== 'previewUrl',
})<any>(({ theme, isDesktop, isOnline, isTyping, operatorView, isCreateSignatureMobile, previewUrl }) => ({
  // theme can be accessed intrinsically here through MUI without having to pull useTheme.
  // the props can be destructured for use in conditional styles as shown below.
  height: '100%',
  width: '100%',
  justifyContent: isCreateSignatureMobile ? 'start' : 'space-between',
  boxShadow: operatorView
    ? isOnline
      ? isTyping
        ? '0px 0px 3px 3px var(--blue920)'
        : '0px 0px 3px 3px var(--peach900)'
      : '0px 0px 0px 1px var(--gray040)'
    : '0px 0px 0px 1px var(--gray040)',
  backgroundColor: theme.palette.peach['000'],
  borderRadius: isDesktop ? '8px' : '0',
  position: 'relative',
  overflow: operatorView ? 'visible' : 'hidden',
  flexDirection: isCreateSignatureMobile ? 'row' : 'column'
}));

const StyledTooltip = styled('div')({
  position: 'absolute',
  display: 'flex',
  height: '56px',
  width: '228px',
  right: '66px',
  bottom: '61px',
  backgroundColor: 'var(--orange910)',
  zIndex: '9999',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  color: 'var(--gray000)'
})

const StyledTip = styled('div')({
  position: 'relative',
  display: 'flex',
  height: '20px',
  width: '25px',
  right: '-189px',
  bottom: '-25px',
  backgroundColor: 'var(--orange910)',
  zIndex: '100',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'rotate(45deg)'
})

const ErrandTypesHandler = (props) => {
  const {
    errand,
    editMessageId,
    setEditMessageId,
    setValue,
    dispFilterMode,
    triggerSlotMachine,
    showBouncyRope,
    showSentiment,
  } = props;

  switch (props.errand.type) {
    case "undefined":
    case undefined:
      return (<></>);
    case ChatType.Conditions:
      return (
        <>
          <LoanConditionsViewer
            errand={props.errand}
          />
        </>
      )
    case ChatType.Form:
      return (
        <>
          <ElectronicSignatureWrapper setValue={setValue} isDesktop={props.isDesktop} errand={errand} />
          <ConversationBody
            dispFilterMode={dispFilterMode}
            editMessageId={editMessageId}
            errand={props.errand}
            isPrivate={false}
            operatorData={props.operatorData}
            setEditMessageId={setEditMessageId}
            setPreviewUrl={props.setPreviewUrl}
            setValue={setValue}
            showBouncyRope={showBouncyRope}
            showSentiment={showSentiment}
          />
        </>
      );
    case ChatType.Page:
      console.log("rendering page errandType");
      return (
        <Page
          link={props.errand?.displayName.split('<>')[1]}
          errandId={props.errand?._id}
        />
      );
    case ChatType.Activity:
      console.log("rendering activity errandType");
      return (<></>);
    default:
      /**
       * Types: conversation, errand, team, or group.
       * Influences operator action buttons. See MessageContentWrapper.
       */
      console.log(`rendering chat of type ${props.errand.type}`);
      return (
        <>
          {props.errand && (
            <ConversationBody
              dispFilterMode={dispFilterMode}
              editMessageId={editMessageId}
              errand={props.errand}
              isPrivate={false}
              operatorData={props.operatorData}
              setEditMessageId={setEditMessageId}
              setPreviewUrl={props.setPreviewUrl}
              setValue={setValue}
              showBouncyRope={showBouncyRope}
              showSentiment={showSentiment}
            />
          )}
        </>
      );
  };
};

const Conversation: React.FC<PropsWithChildren<any>> = (props) => {
  const location = useLocation();
  const { eventsSocket, messagesSocket, isEventsConnected, isMessagesConnected } = useSocketContext();
  const { _id, isOperator, isUser } = useUserContext();
  const rootContext = useRootContext();
  const isWidget = rootContext.isWidget;
  // TODO: All of these useStates and useRefs need to be added to ErrandContext
  const [value, setValue] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [editMessageId, setEditMessageId] = useState(null);
  const [messageFilter, setMessageFilter] = useState('');
  const [dispFilterMode, setDispFilterMode] = useState("NONE");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMorganTyping, setIsMorganTyping] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [showSentiment, setShowSentiment] = useState(false);
  const [clickedConsentIntro, setClickedConsentIntro] = useState(false);
  const replyToRef = useRef({ chat: props.errand._id, originalMessage: '', originalText: '' });
  const [comparedLoanProducts, setComparedLoanProducts] = useState<Array<LoanProduct>>([]);
  const [userPromptsMenuState, setUserPromptsMenuState] = useState<TUserPromptsMenuState>(UserPromptsMenuState.WORKFLOW_NOT_SELECTED);
  const morphUserPromptsMenuRef = useRef(null);
  const [videoListMenuState, setVideoListMenuState] = useState<TVideoListMenuState>(VideoListMenuState.VIDEO_NOT_SELECTED);
  const [multipleFooterTypingAnimations, setMultipleFooterTypingAnimations] = useState<TMultipleFooterTypingAnimationsState>(null);
  const { t } = useTranslation();
  const { isDesktop } = useWindowDimensions();
  // Flag to trigger morph wallet directly to begin NFT minting after log in
  const [bypassToMintFlag, setBypassToMintFlag] = useState(false);
  const [nftData, setNftData] = useState(null);
  
  const theme = useTheme();


  // Display the tooltip for adding friends from contact list if user clicks on 
  // the add-friend button on top left corner of conversation
  const [showAddFriendTooltip, setShowAddFriendTooltip] = useState(false)

  //for the photo selector, to keep track of current photo view and selected main photo.
  const [mainPhoto, setMainPhoto] = useState<number | null>(null);
  const [photoLimit, setPhotoLimit] = useState<number | null>(null);
  const [photoSelectorIndex, setPhotoSelectorIndex] = useState<number | null>(null);
  // Values for dictating to the conversation title when and what to display in the notification banner
  const [notificationBannerEnabled, setNotificationBannerEnabled] = useState(String(sessionStorage.getItem('notificationBannerEnabled')).toLowerCase() === 'true' ? true : false);
  const [notificationBannerMessage, setNotificationBannerMessage] = useState('');


  const [songPlayerData, setSongPlayerData] = useState({
    id: '',
    artist: '',
    title: '',
    thumbnail: '',
    url: '',
    pause: false,
    mute: false,
    show: false,
    closing: false,
  });

  // YouTube iframe player set to state for managing in page player
  const playerRef = useRef<YouTubePlayer>(null);
  // Controls when and how the ConversationFooter shows additional content and actions for the user.
  const [morphType, setErrandMorphType] = useState<MorphType>(MorphType.None)
  // ScrollHandler wrapping all messages, used for scrolling
  const bodyRef = useRef(null);
  // Wrapper for this entire chat, used for drag and drop / canceling edits
  const boundaryRef = useRef(null);
  // Wrapper for dynamic conversation wrapper, it wraps remaining space on errands
  const dynamicConversationWrapperRef = useRef<HTMLDivElement>(null);
  // ConversationTitle reference
  const titleContainerRef = useRef(null);
  // Boolean to allow manual rotation of esign form
  const rotationLockedRef = useRef(false)
  // The primary input field in the ConversationFooter, used for focusing
  const footerInputRef = useRef(null);
  // The ConversationFooter, used for canceling edits
  const footerRef = useRef(null);
  // An array of messages with dates, used for scrolling to specific times in the Timeline
  const searchRefs = useRef<[] | { _id: string, htmlElement: HTMLDivElement }[]>([]);
  // The bottomBox for the MorphedConversationFooter used to add padding to the top of the ConversationFooter to prevent the box from overlapping with the input.
  const morphIndent = useRef(null);
  // The QR img for user's custom link
  const [qrImgSrc, setQrImgSrc] = useState('')
  // The custom link url
  const [customLink, setCustomLink] = useState('')
  // Custom link share method
  const [shareCustomLinkMethod, setShareCustomLinkMethod] = useState("email")
  // Ref to hold share custom link info
  const shareCustomLinkInfoRef = useRef(null);

  const [welcomeUserMessageState, setWelcomeUserMessageState] = useState({
    userSuggestions: { unmountStarted: false },
    welcomeUserMessage: { inView: false, userChosenHintIndex: -1, userChosenHintIndexLockedRef: React.useRef(false) }
  })

  const [isUserWatchingLive, setIsUserWatchingLive] = useState<boolean>(false);

  const setMorphType = useCallback((newContext) => {
    if (props.errand._id === rootContext.morphedId?.current) {
      rootContext.setRootMorphType(newContext);
    }
    setErrandMorphType(newContext);
  }, [props.errand._id]);

  /* Required for drag and drop action/workflows */
  // eslint-disable-next-line
  const [, drop] = useDrop(() => ({
    accept: 'userPrompt',
    drop: (item) => {
      rootContext.handleActionWorkflow(item['id'], item['type'], props.errand._id, props.errand.recipients);
    }
  }));

  // prevent the footer placeholder from rendering on history and live tabs
  const [esignActionState, setEsignActionState] = useState<EsignActionStateType>(EsignActionStateType.Unsigned);
  const [paymentActionState, setPaymentActionState] = useState<PaymentActionStateType>(PaymentActionStateType.Preview);
  const [formCurrentPage, setFormCurrentPage] = useState<number>(1); //used for PDFSign.tsx where there exist multiple pages
  const [formTotalPages, setFormTotalPages] = useState<number>(null); //used for PDFSign.tsx to track # of pages
  const [formBodyScrollState, setFormBodyScrollState] = useState<FormBodyScrollStateType>(FormBodyScrollStateType.Start);
  const [wetSignature, setWetSignature] = useState(undefined);
  const [wetInitial, setWetInitial] = useState(undefined);
  const [formBody, setFormBody] = useState<FormBodyType>(FormBodyType.None);
  const [formBodySize, setFormBodySizeType] = useState<FormBodySizeType>(FormBodySizeType.None);
  const [formBottomButton, setFormBottomButton] = useState<FormBottomButtonType>(FormBottomButtonType.None);
  const [mobileIndentType, setMobileIndentType] = useState<morphIndentType>(morphIndentType.FormClickToClear);
  const [messageOptionsIndex, setMessageOptionsIndex] = useState<number>(-1);
  const [isMorphedFooterCloseButtonOnLeft, setIsMorphedFooterCloseButtonOnLeft] = useState<boolean>(false);
  const messageOptionsRef = useRef<HTMLDivElement>(null);

  const signatureTypeRef = useRef<EsignSignatureType>(EsignSignatureType.Signature);
  const canvasBoundaryRef = useRef({
    [EsignSignatureType.Signature]: {
      x: {
        min: Infinity,
        max: -Infinity,
      },
      y: {
        min: Infinity,
        max: -Infinity,
      },
    },
    [EsignSignatureType.Initials]: {
      x: {
        min: Infinity,
        max: -Infinity,
      },
      y: {
        min: Infinity,
        max: -Infinity,
      },
    },
  });

  const newFormEvent = (event: ElectronicSignatureEventType) => {
    if (props.errand.type !== ChatType.Form) return;

    let currentState: IElectronicSignatureState = {
      body: formBody,
      bodySize: formBodySize,
      bottomButton: formBottomButton,
      morph: morphType,
    }

    const formName = FormTypeDetector(props.errand.name);
    currentState = ElectronicSignatureStateManager(formName, currentState, event);

    // Don't show MorphedConversationFooter on operator view
    if (isOperator) {
      currentState.morph = MorphType.None;
    }

    setFormBody(currentState.body);
    setFormBodySizeType(currentState.bodySize);
    setFormBottomButton(currentState.bottomButton);
    setMorphType(currentState.morph);
  }

  const getPrimaryParticipantFullName = () => {
    return getPrimaryParticipant(props.errand);
  }

  const shouldShowFooter = () => {
    // chat type form should have conversationFooter
    if (props.errand.type === ChatType.Form || props.errand.type === ChatType.Conditions) return true;

    return (
      location.state?.tab !== 'live' &&
      props.errand?.status !== 'closed' &&
      (location.state?.tab !== 'history' || props.errand.participants?.[0]?._id) &&
      props.errand.messages !== null
    );
  };

  /**
   * Create chat type form handler, All form creation related logic lives in backend
   * @returns
   */
  const createFormHandler = async (index: number) => {
    // if index is not defined take last message index
    if (typeof index !== 'number') {
      index = props.errand.messages.length - 1;
    }

    let action = props.errand.messages[index]?.action
    //template id if it's aisign. otherwise that fieldname represents our old hardcoded forms.
    let formName = action?.templateId || action?.fieldName.replaceAll('_', '-');

    //for signatureConfirmationMessages in AISign, the formname will be in the message.
    if (!formName) {
      formName = JSON.parse(props.errand.messages[index]?.message)?.templateId;
    }

    //for AI-SIGN we're going to switch the errand if there's an already initiated form.
    if (formName && formName.includes('TEMPLATE')) {
      const errandIndex = props.errands.findIndex((errand) => {
        return errand.name === formName;
      });

      if (errandIndex !== -1) {
        // Set selected index to the index of the found errand
        if (isDesktop) {
          props.setSelectedIndex((prevIndex) => [prevIndex[0], errandIndex]);
        } else {
          props.setSelectedIndex([errandIndex]);
        }
        return; // Stop further execution as we have set the selected index
      }
    }

    // if formName is undefined at this point that means createFormHandler function could be called from
    // signatureConfirmation message
    if (formName === undefined) {
      const signatureConfirmationMessage = JSON.parse(props.errand.messages[index].message);
      formName = FormTypeDetector(signatureConfirmationMessage.formName);
    }

    // errand name overwritten in chat list
    if (formName === EsignFormType.BCERTA) formName = EsignFormType.BCERTA_DESCRIPTION;

    // Prepare backend call payload
    const payload = {
      url: 'chat/create/form',
      method: 'post',
      data: {
        chatId: props.errand._id,
        displayName: formName,
        position: rootContext.getNewChatPosition(),
      },
    };

    try {
      const formChat = await axiosCall(payload);
      if (!formChat || !formChat._id) {
        console.error(`Conversation (Componenent).createFormHandler.error !formChat`);
        return;
      }

      props.setErrands((prev: IErrand[]) => {
        const foundIndex = prev.findIndex((x) => x._id === formChat._id);

        if (foundIndex === -1) {
          // if form doesn't exist in errands list ---> add it at tail
          prev.push({
            _id: formChat._id,
            name: formChat.displayName,
            isDefault: false,
            preview: formChat.preview,
            status: formChat.status,
            type: formChat.type,
            lastUpdated: formChat.updatedAt,
            icon: '',
            action: null,
            previewAllowed: true,
            participants: formChat.participants,
            lastMessageData: formChat.lastMessageData,
            position: formChat.position,
            parentId: formChat.parentId,
            color: formChat.color,
            placeholder: 'Type here...',
          } as IErrand);

          // put form on view
          if (isDesktop) {
            // desktop ---> choose second window as a added chat
            props.setSelectedIndex((prevIndex) => [prevIndex[0], prev.length - 1]);
          } else {
            // mobile ---> select created chat
            props.setSelectedIndex([prev.length - 1]);
          }

          // force re-render
          return [...prev];
        } else {
          // chat already exists -> move to already created chat
          props.setSelectedIndex((prevIndex) => {
            if (prevIndex.length === 2) {
              // if form is already on show don't do anything
              if (foundIndex === prevIndex[0] || foundIndex === prevIndex[1]) {
                return prevIndex;
              }

              // change secondary conversation to form
              return [prevIndex[0], foundIndex];
            } else if (prevIndex.length === 1) {
              // if it's single screen change current conversation to form
              return [foundIndex];
            } else {
              console.info('ActionDialog setSelectedIndex has more than 2 elements?!');
            }
          });
          return prev;
        }
      });
    } catch (error) {
      console.error(`Conversation (Componenent).createFormHandler.caught`, error);
    }
  };

  useEffect(() => {
    if (!isMessagesConnected) return;

    const onChatEventEmitted = (payload) => {
      let shouldLog = false;
      if (payload.data.type === 'typing') return; // guard statement
      // 8/4/2024 - Dennis, this logic is handled in TypingIndicator.tsx
      /* if (payload?.data?.type === 'typing' && payload?.data?.chat === props.errand._id && payload.data.operatorId !== undefined) {
        shouldLog = true;
        setMorganTyping(payload.data.message === 'true');
      }*/
      if (payload?.data?.type === 'analyzing' && payload?.data?.chat === props.errand._id) {
        shouldLog = true;
        if (payload?.data?.message === 'true') {
          setIsAnalyzing(true);
        } else {
          setIsAnalyzing(false);
        }
      }
      if (payload?.data?.type === 'show_sentiment' && payload?.data?.chat === props.errand._id) {
        shouldLog = true;
        setShowSentiment(true);
        setTimeout(() => {
          setShowSentiment(false);
        }, 10000); // turn off the sentiment after 10 seconds.
      }
      if (shouldLog) {
        console.log(`Messages Socket - ConversationComponent - (chat-event-emitted)`, payload);
      }
    };

    console.log(`Messages Socket - ConversationComponent - (on)`);
    messagesSocket.current?.on('chat-event-emitted', onChatEventEmitted);
    return () => {
      console.log(`Messages Socket - ConversationComponent - (off)`);
      messagesSocket.current?.off('chat-event-emitted', onChatEventEmitted);
    }
  }, [isMessagesConnected, props.errand._id])

  useEffect(() => {
    if (!isEventsConnected) return;
   
    const handleNotificationBannerUpdate = (payload) => {
      const data = payload.data;

      if(!data.enabled){
        setNotificationBannerEnabled(false);
        setNotificationBannerMessage(null);
        sessionStorage.setItem('notificationBannerEnabled', 'clear');
        sessionStorage.removeItem('notificationBannerMessage');
      } else {
        if(!Boolean(sessionStorage.getItem('notificationBannerEnabled')) || sessionStorage.getItem('notificationBannerEnabled') === 'clear'){
          setNotificationBannerEnabled(true);
          sessionStorage.setItem('notificationBannerEnabled', data.enabled.toString());
        }
  
        if(!sessionStorage.getItem('notificationBannerMessage') || sessionStorage.getItem('notificationBannerMessage') !== data.message){
          setNotificationBannerMessage(data.message);
          sessionStorage.setItem('notificationBannerMessage', data.message);
        }
      }
    }
  
    console.log(`Events Socket - ConversationComponent - (on)`);
    eventsSocket.current?.on('notification-banner-update', handleNotificationBannerUpdate);
    return () => {
      console.log(`Events Socket - ConversationComponent - (off)`);
      eventsSocket.current?.off('notification-banner-update', handleNotificationBannerUpdate);
    }
  }, [isEventsConnected, props.errand._id])

  useEffect(() => {
    const { _id: errandId, type } = props.errand;

    if (!errandId) {
      console.log(`Messages Socket - ConversationComponent - (no errand._id)`);
      return;
    }

    if (!isMessagesConnected) {
      console.log(`Messages Socket - ConversationComponent - (no messages socket)`);
      return;
    }
    
    // Function to join the conversation
    const joinConv = () => {
      messagesSocket.current?.emit('join-conversation', { chatId: errandId }, (payload: any) => {
        console.log(`Messages Socket - ConversationComponent - (join-${type}) ${errandId}`, payload);
        // join-conversation sets all related notifications to read in the database.
        // this function will remove all corresponding notifications from the UI.
        props.setNotifications((prev) => prev.filter((x) => ![x.chat?._id, x.messageId?.chat].includes(errandId)));
        props.setErrands((prev) => {
          const index = prev.findIndex((e) => e?._id === errandId);

          if (index === -1) return prev;

          const toUpdate = { ...prev[index], ...payload?.data, messages: prev[index].messages };

          if (isOperator) {
            toUpdate.displayString = getOperatorDisplayString(toUpdate, props.operatorData);
            toUpdate.showInactive = shouldShowInactive(toUpdate, props.operatorData);
            toUpdate.operators = getOperatorParticipants(toUpdate, props.operatorData);
          }

          prev[index] = toUpdate;
          prev[index].workflow = undefined;

          return [...prev];
        });
      });
    };

    if (ThinClientUtils.isThinClient()) {
      const oldValue = localStorage.getItem('chatId');
      window.dispatchEvent(new StorageEvent('storage', {
        key: 'chatId',
        newValue: errandId,
        oldValue: oldValue,
        url: window.location.href,
        storageArea: localStorage
      }));
      setChatId(errandId);
    }

    joinConv();

    return () => {
      if (errandId) {
        messagesSocket.current?.emit('leave-conversation', { chatId: errandId }, (payload: any) => {
          console.log('Messages Socket - Left ' + type, errandId, payload?.message);
        });

        if (isUser) {
          props.setErrands((prev) => {
            const chatObj = prev.find((e) => e._id === errandId);

            if (chatObj) {
              const participant = getCurrentParticipant(chatObj, _id);

              if (chatObj.status !== 'closed' && participant) return prev;
            }

            return [...prev.filter((e) => e._id !== errandId)];
          });
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, props.errand._id]);

  useEffect( () => {
      const checkSystemData  = async () => {
        if(!!Boolean(sessionStorage.getItem('notificationBannerEnabled'))){
          return;
        }

        let lenderName = theme.system.name;
        /** @type {import('../../jsdocs').SystemData} */ // @ts-ignore
        const result = await axiosCall({ url: `system?lender=${lenderName}`});
        if (result) {
          if((!Boolean(sessionStorage.getItem('notificationBannerEnabled')) || sessionStorage.getItem('notificationBannerEnabled') !== 'clear') && !!result.notificationBannerEnabled){
            setNotificationBannerEnabled(true);
            setNotificationBannerMessage(result.message);
          }
        }
      }
      checkSystemData();
  }, [])

  const chatStatusClosedHandler = useCallback(async (data?: TChatStatusClosed) => {
    if (isOperator) return;
    const localChildErrands = await prepareErrands(_id, false);
    if (!Array.isArray(localChildErrands) || localChildErrands?.length === 0) return;
    rootContext.setChildErrands(localChildErrands);
  }, [props.errand._id, _id]);

  useEventsListener('ConversationComponent', SocketListenerType.chatStatusClosed, chatStatusClosedHandler);

  const handleWalletEvent = useCallback(async (payload?: { message: string, data: TShowUserWallet }) => {
    console.log('handleWalletEvent', payload);
    if (!payload?.data) {
      console.error('handleWalletEvent: !payload?.data');
    }
    const data = payload.data;
    setMorphType(MorphType.Wallet);
    if(data.workflow){
      setBypassToMintFlag(true);
      if (data.nftData) {
        setNftData(data.nftData);
      }
    }
  }, [props.errand._id, _id])

  useEffect(() => {
    eventsSocket.current?.on(SocketListenerType.showUserWallet, handleWalletEvent);
    return () => {
      eventsSocket.current?.off(SocketListenerType.showUserWallet, handleWalletEvent);
    };
  }, [isEventsConnected, props.errand._id, _id]);

  const handleNotificationBannerUpdate = useCallback(async (data?: TNotificationBannerUpdate) => {
    if(!data.enabled){
      setNotificationBannerEnabled(false);
      setNotificationBannerMessage(null);
      sessionStorage.setItem('notificationBannerEnabled', 'clear');
      sessionStorage.removeItem('notificationBannerMessage');
    } else {

      if(!Boolean(sessionStorage.getItem('notificationBannerEnabled')) || sessionStorage.getItem('notificationBannerEnabled') === 'clear'){
        setNotificationBannerEnabled(true);
        sessionStorage.setItem('notificationBannerEnabled', data.enabled.toString());
      }

      if(!sessionStorage.getItem('notificationBannerMessage') || sessionStorage.getItem('notificationBannerMessage') !== data.message){
        setNotificationBannerMessage(data.message);
        sessionStorage.setItem('notificationBannerMessage', data.message);
      }

    }
  }, [props.errand._id, _id])

  useEventsListener('System', SocketListenerType.notificationBannerUpdate, handleNotificationBannerUpdate)

  const closeCreateSignatureMobile = () => {
    newFormEvent(ElectronicSignatureEventType.CloseCreateSignatureMobile);
    rootContext.setCreateSignatureMobileIsOn(false);
  };

  return (
    <ErrorBoundary debug={`./src/Components/Conversation.tsx: level 0`}>
      <CustomLinkContext.Provider value={{
        qrImgSrc,
        setQrImgSrc,
        customLink,
        setCustomLink,
        shareCustomLinkMethod,
        setShareCustomLinkMethod,
        shareCustomLinkInfoRef,
      }}>
        <ErrandContext.Provider value={{
          bodyRef,
          boundaryRef,
          titleContainerRef,
          dynamicConversationWrapperRef,
          footerInputRef,
          footerRef,
          searchRefs,
          isSearchOpen,
          isPopupOpen,
          setIsPopupOpen,
          setValue,
          morphUserPromptsMenuRef,

          multipleFooterTypingAnimations,
          setMultipleFooterTypingAnimations,

          errand: props.errand,
          errandId: props.errand._id,
          errandType: props.errand.type,

          showAddFriendTooltip,
          setShowAddFriendTooltip,
          mainPhoto,
          setMainPhoto,
          photoLimit,
          setPhotoLimit,
          photoSelectorIndex,
          setPhotoSelectorIndex,
          createFormHandler,
          morphIndent,
          signatureTypeRef,
          canvasBoundaryRef,
          esignActionState,
          setEsignActionState,
          paymentActionState,
          setPaymentActionState,
          formCurrentPage,
          setFormCurrentPage,
          formTotalPages,
          setFormTotalPages,
          formBody,
          formBodySize,
          formBottomButton,
          morphType,
          setFormBody,
          setMorphType,
          newFormEvent,
          mobileIndentType,
          setMobileIndentType,
          wetSignature,
          setWetSignature,
          wetInitial,
          setWetInitial,
          formBodyScrollState,
          setFormBodyScrollState,
          getPrimaryParticipantFullName,
          isUserWatchingLive,
          setIsUserWatchingLive,
          rotationLockedRef,
          isAnalyzing,
          setIsAnalyzing,
          isMorganTyping,
          setIsMorganTyping,
          showSentiment,
          setShowSentiment,
          editMessageId,
          setEditMessageId,
          messageOptionsRef,
          messageOptionsIndex,
          setMessageOptionsIndex,
          isMorphedFooterCloseButtonOnLeft,
          setIsMorphedFooterCloseButtonOnLeft,
          songPlayerData,
          setSongPlayerData,
          playerRef,
          replyToRef,
          comparedLoanProducts,
          setComparedLoanProducts,
          videoListMenuState,
          setVideoListMenuState,
          userPromptsMenuState,
          setUserPromptsMenuState,
          clickedConsentIntro,
          setClickedConsentIntro,
          welcomeUserMessageState,
          setWelcomeUserMessageState,
          bypassToMintFlag,
          setBypassToMintFlag,
          nftData,
          setNftData,
          messageFilter,
          setMessageFilter,
        }}>
          <StyledChatContainer
            className="styledChatContainer"
            ref={el => { drop(el); boundaryRef.current = el; }}
            isDesktop={isDesktop}
            isOnline={
              props.errand.participants?.length > 0
                ? props.errand.participants.filter(
                  (x: any) => x.active && x.ai && x.userData?.status !== 'offline'
                ).length > 0
                : false
            }
            isTyping={isMorganTyping}
            operatorView={props.operatorView === true}
            isCreateSignatureMobile={formBody === FormBodyType.CreateSignatureMobile}
            previewUrl={previewUrl}
          >
            {showAddFriendTooltip &&
              <Grow in={showAddFriendTooltip} timeout={500}>
                <StyledTooltip><StyledTip />{t('addFriendTooltip')}</StyledTooltip>
              </Grow>
            }
            {rootContext?.openedCaptcha &&
              <Backdrop
                sx={{ zIndex: 1000 }}
                open={true}
                onClick={() => { }}
              >
                <Captcha onSuccess={() => rootContext?.setOpenedCaptcha(false)} />
              </Backdrop>
            }
            {previewUrl?.length > 0 && (
              <LinkPreview errand={props.errand} url={previewUrl} setPreviewUrl={setPreviewUrl} chatId={props.errand._id} />
            )}
            <ErrorBoundary debug={`./src/Components/Conversation.tsx: level 1`}>
              {formBody === FormBodyType.CreateSignatureMobile && window.innerWidth > window.innerHeight && <CreateSignatureMobileConversationTitle />}
              {formBody !== FormBodyType.CreateSignatureMobile && <ConversationTitle
                showSlotMachine={props.showSlotMachine}
                errand={props.errand}
                setErrands={props.setErrands}
                isDesktop={isDesktop}
                onlineOperators={props.onlineOperators}
                onlineUsers={props.onlineUsers}
                operatorData={props.operatorData}
                operators={props.operators}
                setParticipants={props.setParticipants}
                isSearchOpen={isSearchOpen}
                setIsSearchOpen={setIsSearchOpen}
                workflow={props.workflow}
                editMessageId={editMessageId}
                notificationBannerMessage={notificationBannerMessage}
                notificationBannerEnabled={notificationBannerEnabled}
                setNotificationBannerEnabled={setNotificationBannerEnabled}
              />}
            </ErrorBoundary>
            <Stack ref={dynamicConversationWrapperRef}
              minHeight="0"
              width='100%'
              sx={{ flexGrow: 1 }}
            >
              {/* do not play animation on widget */}
              {!isDesktop && props.notificationAlert && !isWidget && (

                // This is the notification alert for mobile where the
                // rope bounces out a little bit
                <StyledRopeAnimation />
              )}
              <ErrorBoundary debug={`./src/Components/Conversation.tsx: level 2`}>
                <ErrandTypesHandler
                  {...props}
                  errand={props.errand}
                  editMessageId={editMessageId}
                  setEditMessageId={setEditMessageId}
                  setValue={setValue}
                  dispFilterMode={dispFilterMode}
                  setPreviewUrl={setPreviewUrl}
                  exitSlotMachine={props.exitSlotMachine}
                  triggerSlotMachine={props.triggerSlotMachine}
                  showSlotMachine={props.showSlotMachine}
                  showBouncyRope={props.notificationAlert}
                  showSentiment={showSentiment}
                  isDesktop={isDesktop}
                />
              </ErrorBoundary>
            </Stack>
            <ErrorBoundary debug={`./src/Components/Conversation.tsx: level 3`}>
              <Stack className={morphType === MorphType.PrivateChat ? Styles.isPrivate : ''} ref={footerRef} style={{ position: 'static', display: shouldShowFooter() ? 'flex' : 'none' }}>
                {formBody !== FormBodyType.CreateSignatureMobile && <ConversationFooter
                  dispFilterMode={dispFilterMode}
                  editMessageId={editMessageId}
                  errand={props.errand}
                  operatorData={props.operatorData}
                  setDispFilterMode={setDispFilterMode}
                  setEditMessageId={setEditMessageId}
                  setPreviewUrl={setPreviewUrl}
                  setValue={setValue}
                  showSentiment={showSentiment}
                  triggerSlotMachine={props.triggerSlotMachine}
                  value={value}
                />}
              </Stack>
            </ErrorBoundary>
            {/* SVG element for the curved line */}
            {formBody === FormBodyType.CreateSignatureMobile && window.innerWidth > window.innerHeight &&
              <div style={{ position: 'relative' }}>
                <svg
                  style={{
                    position: 'absolute',
                    top: '50%',
                    // transform: 'rotate(-90deg) translateX(29px)',
                    transform: 'rotate(-90deg) translate(-37.5%, -50%)',
                    transformOrigin: 'left',
                    zIndex: '1',
                  }}
                  width="104"
                  height="31"
                >
                  <path d="M 0 31 l 104 0" stroke="var(--peach000)" strokeWidth="2" />
                  <path
                    d="M 0 31 a 30 30 0 0 0 26 -15 a 30 30
                  0 0 1 52 0 a 30 30 0 0 0 26 15"
                    stroke="var(--orange700)"
                    strokeWidth="2"
                    fill="var(--peach000)"
                  />
                </svg>
                <FormPhoneFlip2
                  onClick={closeCreateSignatureMobile}
                  style={{
                    position: 'relative',
                    top: '50%',
                    transform: 'translate(-15px, -50%)',
                    zIndex: '1',
                  }} />
              </div>
            }
          </StyledChatContainer>
        </ErrandContext.Provider>
      </CustomLinkContext.Provider>
      {props.showConfirmResendDialog.open && (
        <ConfirmResendWorkflowDialog
          open={props.showConfirmResendDialog.open}
          onClose={() => {
            /**
             * On closing/canceling the resend dialog we want to unset the states related to 
             * this specific call (ID, chat, type, recicpients), set open to false so that the 
             * dialog is closed, and finally set check to true so that the next time a workflow
             * is sent we are checking for duplicates.
             */
            props.setShowConfirmResendDialog({
              open: false,
              chat: undefined,
              type: undefined,
              id: undefined,
              recipients: [],
              check: true,
            });
          }}
          onResend={async () => {
            /**
             * On resending we want to just recall the handle action workflow. Because the 
             * sendWorkflow function now returns the showConfirmResendDialog state we do not
             * need to manually reset the state any more.
             */
            await props.handleActionWorkflow(
              props.showConfirmResendDialog.id,
              props.showConfirmResendDialog.type,
              props.showConfirmResendDialog.chat
            );
          }}
        />
      )}
    </ErrorBoundary>
  );
};
Conversation.defaultProps = {
  showSlotMachine: false
}
export default Conversation;
