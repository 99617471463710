import { styled } from '@mui/system';
import {
  TableCell,
  FormControl,
  InputLabel,
  Button,
  tableCellClasses,
  CircularProgress,
  Table,
  Paper,
  TableRow,
} from '@mui/material';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

export const StyledTable = styled(Table)({
  size: 'small',
});

export const StyledProgressCircle = styled(CircularProgress)({
  color: 'consoleColor',
});

export const StyledPaper = styled(Paper)({
  p: 1.5,
});

export const StyledButton = styled(Button)({
  minWidth: '200px',
  height: '40px',
  whiteSpace: 'nowrap',
  width: '25%',
});

export const StyledInputLabel = styled(InputLabel)({
  backgroundColor: 'white',
  width: '100px',
  textAlign: 'center',
});

export const StyledFormControl = styled(FormControl)({
  minWidth: '330px',
});
