import React from 'react';
import { Button } from '@mui/material';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import { Submit, Erase } from '@assets/Icons';
import { FormClickEventType, ElectronicSignatureEventType } from '../Forms/commonForms';
import { morphIndentType } from '@common/MorphType';
import eventBus from '../Common/eventBus';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/CreateSignatureMobileConvTitle.module.css'
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi.png';
const logo = process.env.REACT_APP_MORGAN_CDN + '/Images/logo2.png';

const CreateSignatureMobileConversationTitle = () => {
  const { t } = useTranslation();

  const btnActiveColor = 'var(--gray000)'
  const btnPassiveColor = 'var(--orange960)';    
  const errandContext = useErrandContext();
  const rootContext = useRootContext();


  const clearHandler = () => {
    errandContext.setMobileIndentType(morphIndentType.FormGenerate);
    eventBus.dispatch(FormClickEventType.SigClear);
  }

  const generateHandler = () => {
    errandContext.setMobileIndentType(morphIndentType.FormClickToClear);
    eventBus.dispatch(FormClickEventType.SigAutoGen);
  }

  return (
    <div className={Styles.createSignatureTitleContainer}>
      <Button className={Styles.buttonStyle}>
        <img src={logo} alt="AngelAi logo" width="40" />
        AngelAi
      </Button>
      <Button className={Styles.buttonStyle} size="small" onClick={clearHandler}>
        <Erase style={{ transform: 'scale(1.25)', marginBottom: '7px' }} />
        {t('buttonClear')}
      </Button>
      <Button className={Styles.buttonStyle} size="small" onClick={generateHandler}>
        <img src={AngelAi} alt="AngelAi logo" width="62.93" style={{marginBottom: '7px'}} />
        {t('generateSignature')}
      </Button>
      {errandContext.wetInitial && errandContext.wetSignature && (
        <Button
          className={`${Styles.buttonStyle} ${Styles.submitBox}`}
          sx={{
            color: !errandContext.wetInitial || !errandContext.wetSignature ? btnPassiveColor : btnActiveColor,
          }}
          size="small"
          disabled={!errandContext.wetInitial || !errandContext.wetSignature}
          onClick={() => errandContext.newFormEvent(ElectronicSignatureEventType.GoToInsertSignatureView)}
        >
          <Submit className={Styles.submitButton} />
          <div className={Styles.submitText}>{t('loginFormSubmit')}</div>
        </Button>
      )}
    </div>
  );
};

export default CreateSignatureMobileConversationTitle;
