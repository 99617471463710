/*
This component renders a prompt in the Conversation Footer to ask the user for consent for humans to monitor their chats.
In the future, this component will need to be generalized to match any other text that uses this format.
*/
import React, { useMemo, useRef, useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../Common/hooks/useWindowDimensions';
import { useRootContext } from '@contexts/RootContext';
import Styles from '@styles/SlideUpDialog.module.css';
import { useErrandContext } from '@contexts/ErrandContext';
import { isMobileOrTablet } from '@common/deviceTypeHelper';

enum ConsentType {
  None,
  Intro,
  Terms,
}

type TConsentContentProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  consentText: string;
  rightButtonText?: string;
  leftButtonText?: string;
}

const SlideUpDialog = ({
  isOpen, setIsOpen, consentText, rightButtonText, leftButtonText
}: TConsentContentProps) => {
  const rootContext = useRootContext();
  const isWidget = rootContext.isWidget;
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const windowDimensions = useWindowDimensions();

  // renders the scroll button or no / yes as needed
  const [consentState, setConsentState] = useState<ConsentType>(ConsentType.None);
  const [dialogStyle, setDialogStyle] = useState({});

  const consentContentRef = useRef<HTMLDivElement>(null);
  const paragraph = useRef<HTMLDivElement>(null);
  const checkedParagraphHeight = useRef<boolean>(false);

  const isMobileWidget: boolean = useMemo(() => isMobileOrTablet() && isWidget, [isWidget]);
  const isLandscape = window.innerHeight < window.innerWidth

  const handleResize = useCallback(() => {
    if (!paragraph.current) return;
    if (paragraph.current.scrollTop + paragraph.current.clientHeight >= paragraph.current.scrollHeight - 10) {
    } else {
    }
  }, []);

  useWindowDimensions(handleResize);

  useEffect(() => {
    const updateDialogPosition = () => {
      const footer = errandContext.footerRef.current;
      if (footer && consentContentRef.current) {
        const footerRect = footer.getBoundingClientRect();
        setDialogStyle({
          bottom: `${footer.clientHeight + (windowDimensions.isDesktop? 7 : -5)}px`,
          left: `${footerRect.left}px`,
          width: `${footer.clientWidth}px`,
        });
      }
    };

    updateDialogPosition();
    window.addEventListener('resize', updateDialogPosition);
    return () => window.removeEventListener('resize', updateDialogPosition);
  }, [errandContext.footerRef, windowDimensions.isDesktop]); // Depend on footerRef to recalculate when it changes

  useEffect(() => {
    setTimeout(() => {
      setConsentState(ConsentType.Intro);
    }, 0)
  }, []);

  useEffect(() => {
    if (!checkedParagraphHeight.current && paragraph.current && paragraph.current?.scrollHeight !== paragraph.current?.clientHeight) {
      checkedParagraphHeight.current = true;
      paragraph.current.focus();
    }
  });

  const renderTopContent = () => {
    return (
      <div
        className={[
          Styles.terms,
          ...(isMobileWidget ? [Styles.isMobileWidget] : []),
          ...(isMobileOrTablet && isLandscape ? [Styles.isMobileLandscape] : [])
        ].join(' ')}
      >
        <h1>AngelAi</h1>
        {/*IMAGE REMOVED <div className={Styles.imgWrapper}>
          <div className={Styles.placeholder}>
          </div>
          <div className={Styles.angelLogo}>
            <img src={Logo} alt='' aria-hidden='true' />
          </div>
        </div> */}
        <p ref={paragraph}>{consentText}</p>
        <button className={Styles.hideButton} onClick={() => setIsOpen(false)}>
          {t('hide')}
        </button>
      </div>
    );
  };

  return (
    <section
      style={dialogStyle}
      className={[
        Styles.wrapper,
        Styles[`type${consentState}`],
        // !errandContext.isPopupOpen && Styles.slideDown 
        !isOpen && Styles.slideDown 
      ].filter(Boolean).join(' ')}
      ref={consentContentRef}>
      <div className={Styles.content}>
        <div style={{ width: errandContext.footerRef.current?.clientWidth - (windowDimensions.isDesktop ? 60 : 40)}} className={Styles.consentTop}>
          {renderTopContent()}
        </div>
      </div>
    </section>
  );
};
export default SlideUpDialog;