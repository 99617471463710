/**
 * @file This component is used for generating and previewing
 * messages that contain audio recordings.
 *
 * It had been extracted from where it previously was
 * being rendered; within the ConversationMessage component.
 *
 * @author Harrison Fales <harrison.fales@swmc.com>
 * Original Component Author
 * @author Allan Tham <allan.tham@swmc.com>
 */
import React from 'react';

import Sanitized from '@components/Sanitized';
import PreviewAudio from '@components/PreviewAudio';
import { useQuery } from '@tanstack/react-query';
import axiosCall from '@services/axios';
import { IErrand, IMessage } from '@interfaces/Conversation';
import MessageTime from '@components/MessageTime';
import tStyles from '@styles/Typography.module.css';
import Styles from '@styles/MessageContent/AudioMessageContent.module.css';
import mcStyles from '@styles/MessageContent/MessageContent.module.css';
import { AccessType } from '@common/AccessType';

type TAudioMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const AudioMessageContent = ({
  errand, message, 
}: TAudioMessageContentProps) => {
  const response = useQuery([message.documents?.[0]], async ({ signal }) => {
    let request = {
      url: `chat/${errand._id}/document/${message.documents?.[0]}`,
    };
    let config = {
      signal,
    };
    return await axiosCall(request, config);
  });

  return (
    <div
      aria-label={response?.data?.filename}
      className={[
        Styles.wrapper,
        mcStyles.peAll,
        ...(message.accessType === AccessType.internal ? [Styles.internal] : []),
        ...(message?.sentByCurrentUser ? [Styles.sentByCurrentUser] : []),
      ].join(' ')}
    >
      <PreviewAudio
        sentByCurrentUser={message.sentByCurrentUser}
        src={`data:${response?.data?.mimeType};base64,${response?.data?.data}`}
        visible={message.visible}
      />
      <div className={Styles.container}>
        <Sanitized
          className={tStyles.base}
          html={message.message}
          visible={message.visible}
          tag="p"
        />
        <MessageTime message={message} />
      </div>
    </div>
  );
};

export default AudioMessageContent;
