/**
 * @file    This file contains the definition of the `UserType` enum class.
 * @author  Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

/**
 * This class is an enum class, the members of which represent all supported user types.
 */
class UserType {
  name : string = '';
  static USER = new UserType("USER");
  static OPERATOR = new UserType("OPERATOR");

  constructor(name: string) {
    this.name = name;
  }
}

export default UserType;