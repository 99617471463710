import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from "@mui/material";

import { Box } from "@mui/system";
import React, { useState } from "react";

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    consoleColor: true;
  }
}

// To test this locally, pull the latest code from Morgan-Widget and do a build:dev.
// Copy the resulting index.js to Morgan's public/Widget folder, you will need to make the folder
// This will allow the widget test to run your local morgan environment, assuming port 3000 for Morgan

const WidgetTest = () => {
  const [shown, setShown] = useState(false);
  const [mobileEmulator, setMobileEmulator] = useState(true);
  const [width, setWidth] = useState(350);
  const [height, setHeight] = useState(600);
  const [side, setSide] = useState('right');
  const [bottomTabPadding, setBottomTabPadding] = useState(10);
  const [sideTabPadding, setSideTabPadding] = useState(10);
  const [bottomChatPadding, setBottomChatPadding] = useState(10);
  const [sideChatPadding, setSideChatPadding] = useState(10);
  ///////////////////////USER INFO///////////////////////
  const [FirstName, setFirstName] = useState('');
  const [Username, setUserName] = useState('');
  const [Email, setEmail] = useState('TEST.USER@swmc.com');
  const [LoanNo, setLoanNumber] = useState(122299000100);

  const onUpdateClick = () => {
    let el = document.getElementById('mini-Morgan-chat-widget');
    el.parentNode.removeChild(el);

    let s = document.getElementById('mmcScript');
    s.parentNode.removeChild(s);

    window['mmc'] = null;

    onButtonClick();
  }

  let MSI = {}
  const updateMorganUserInfo = (key: string, val: string) => {
    MSI[key] = val;
    sessionStorage.setItem("MORGAN_SESSION_INFO", JSON.stringify(MSI));
    window.postMessage(sessionStorage.MORGAN_SESSION_INFO, '*');
  }
  const updateLoanNo = (val: number) => {
    window.postMessage(val, '*');
  }
  const onButtonClick = () => {
    /////////////////////WIDGET EMBED///////////////////
    try {
      function isEmbedded(){
        let urlObj = new URL(window.location.href);
        let paramValue = urlObj.searchParams.get('embedded');
        // If 'embedded' is 'true', return true. Else, return false
        if (paramValue === 'true') {
            return true;
        } else {
            return false;
        }
      }
      if (!isEmbedded()){
      setShown(true);
      console.log("Calling ", process.env.REACT_APP_MORGAN_WIDGET);
      ((w, d, s, a, g, e, o) => {
        w[g] = w[g] || function () { (w[g].q = w[g].q || []).push(arguments) };
        let l = d.createElement('div'); l.id = 'mini-Morgan-chat-widget'; d.getElementsByTagName('body')[0].appendChild(l);
        e = d.createElement(s); o = d.getElementsByTagName(s)[0]; e.async = 1; e.src = a; e.id = 'mmcScript'; e.setAttribute('type', "text/javascript"); o.parentNode.insertBefore(e, o);
      })(window, document, 'script', process.env.REACT_APP_MORGAN_WIDGET, 'mmc');
      
      window['mmc'](width, height, side, bottomTabPadding, sideTabPadding, 100, mobileEmulator, bottomChatPadding, sideChatPadding);
      }
    } catch {
      console.log('Error Rendering Morgan Widget')
    }
  }

  return (
    <Box sx={{backgroundColor:"var(--gray000)"}} paddingLeft={5}>
      <h1>Widget Test</h1>
      <Stack width={300} spacing={3}>
        <TextField
          type="number"
          size="small"
         
          variant="outlined"
          label="Width"
          value={width}
          onChange={(e) => {
            const elem = e.target as HTMLInputElement;
            setWidth(elem.valueAsNumber)
          }}
        />
        <TextField
          type="number"
          size="small"
         
          variant="outlined"
          label="Height"
          value={height}
          onChange={(e) => {
            const elem = e.target as HTMLInputElement;
            setHeight(elem.valueAsNumber)
          }}
        />
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Side</FormLabel>
          <RadioGroup
            defaultValue="right"
            name="radio-buttons-group"
            onChange={(e) => { setSide(e.target.value) }}
          >
            <FormControlLabel value="left" control={<Radio />} label="Left" />
            <FormControlLabel value="right" control={<Radio />} label="Right" />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Version</FormLabel>
          <RadioGroup
            defaultValue="true"
            name="radio-button-group"
            onChange={(e) => { setMobileEmulator(e.target.value === "true") }}
          >
            <FormControlLabel value="true" control={<Radio />} label="Original" />
            <FormControlLabel value="false" control={<Radio />} label="Split Screen" />
          </RadioGroup>
        </FormControl>
        <TextField
          type="number"
          size="small"
         
          variant="outlined"
          label="Bottom Tab Padding"
          value={bottomTabPadding}
          onChange={(e) => {
            const elem = e.target as HTMLInputElement;
            setBottomTabPadding(elem.valueAsNumber)
          }}
        />
        <TextField
          type="number"
          size="small"
         
          variant="outlined"
          label="Side Tab Padding"
          value={sideTabPadding}
          onChange={(e) => {
            const elem = e.target as HTMLInputElement;
            setSideTabPadding(elem.valueAsNumber)
          }}
        />
        <TextField
          type="number"
          size="small"
         
          variant="outlined"
          label="Bottom Chat Padding"
          value={bottomChatPadding}
          onChange={(e) => {
            const elem = e.target as HTMLInputElement;
            setBottomChatPadding(elem.valueAsNumber)
          }}
        />
        <TextField
          type="number"
          size="small"
         
          variant="outlined"
          label="Side Chat Padding"
          value={sideChatPadding}
          onChange={(e) => {
            const elem = e.target as HTMLInputElement;
            setSideChatPadding(elem.valueAsNumber)
          }}
        />
        <TextField
          type="text"
          size="small"
         
          variant="outlined"
          label="Sunsoft 4 Char Username"
          defaultValue=""
          inputProps={{ maxLength: 4 }}
          onChange={(e) => {
            setUserName(e.target.value)
          }}
        />
        {<button onClick={() => updateMorganUserInfo('userId', Username)}>Set Sunsoft Username</button>}
        <TextField
          type="text"
          size="small"
         
          variant="outlined"
          label="First Name"
          defaultValue=""
          onChange={(e) => {
            setFirstName(e.target.value)
          }}
        />
        {<button onClick={() => updateMorganUserInfo('firstname', FirstName)}>Set First Name</button>}
        <TextField
          type="text"
          size="small"
         
          variant="outlined"
          label="Sunsoft Email"
          defaultValue="test.user@swmc.com"
          onChange={(e) => {
            const elem = e.target as HTMLInputElement;
            setEmail(elem.value)
          }}
        />
        {<button onClick={() => updateMorganUserInfo('email', Email)}>Set Email</button>}
        <TextField
          type="number"
          size="small"
         
          variant="outlined"
          label="Loan Number"
          value={LoanNo}
          onChange={(e) => {
            const elem = e.target as HTMLInputElement;
            setLoanNumber(elem.valueAsNumber)
          }}
        />
        {<button onClick={() => updateLoanNo(LoanNo)}>Set Loan Number</button>}
        {!shown && <button onClick={onButtonClick}>Click to show widget</button>}
        {shown && <button onClick={onUpdateClick}>Click to update widget</button>}
      </Stack>
    </Box>

  )
}

export default WidgetTest;
