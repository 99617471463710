import React from 'react';
import { Box } from '@mui/material';

import { TwoUserProfile } from '@assets/Icons';
import { uiTranslationController } from '@common/common';
import Allign from '@styles/Allign';

import Bubble from './Bubble';
import IconBackground from './Icon/Background';
import IconForeground from './Icon/Foreground';
import Rope from './Rope';

import type { FC } from 'react';
import type { IMessage } from '@interfaces/Conversation';

type ReplyDialogProps = {
  message: IMessage;
}

const ReplyDialog: FC<ReplyDialogProps> = ({ message }) => {
  const { isTranslated} = uiTranslationController(message);

  const { originalText } = message.replyTo;

  const alignIcon = message.alignByCurrentUser 
    ? {left: 0, transform: 'translate(-40%, -40%)'} 
    : {right: 0, transform: 'translate(40%, -40%)'};

  const alignRope = message.alignByCurrentUser
    ? {right: '15px'}
    : {left: '15px'};

  if (!originalText || message.messageStatus === 'deleted') return null;

  
  return (
    <Allign
      data-message-id={message._id}
      sx={{
        position: 'relative',
        minWidth: isTranslated ? '220px' : '85px',
        justifyContent: message.alignByCurrentUser ? 'flex-end' : 'flex-start',
        paddingBottom: '15px',
        marginTop: '16px',
      }}
    >
      <Box sx={{position: 'relative', maxWidth: 'min(90%, calc(100vw - 50px))'}}>
        <IconBackground sx={alignIcon} />
        <Rope sx={alignRope} />
        <Bubble message={message} originalText={originalText} />
        <IconForeground sx={alignIcon}>
          <TwoUserProfile />
        </IconForeground>
      </Box>
    </Allign>
  )
}

export default ReplyDialog;