import { ValidatorFunctions } from '@common/Validators';
import { Timeout } from 'react-number-format/types/types';
import { INITIAL_ALL_ACTIVITIES_ARR_STATE } from './Config/States';
import { ActivityOptionalData, IActivityID } from './interfaces';
import { copyObj } from '@common/userMessagesUtils';

let LOG_LEVEL = "DEFAULT"; // set to "DEBUG" for full logs

type LockedActivity = {
  locked: boolean;
  lastReceivedValue: null | boolean;
  withNavigation?: boolean;
  optionalData?: null | ActivityOptionalData;
  optionalCallback?: null | (() => void);
}

type LockedActivitiesState = {
  [key in IActivityID]: LockedActivity
}


// Takes care of delayed changes of any component in activity tracker.
// meaning, if handleActivityChange(true) called and then called with false during a small time span, then
// This controller will handle these changes (future UI changes)
export const DelayedActivityController = (function () {
  const log = (mark, ...args) => LOG_LEVEL === "DEBUG" ? console.log(`÷ DelayedController [ ${mark} ]`, ...args) : null;
  // initial value for activity delayed data.
  const INITIAL_LOCKED_ACTIVITIES_STATE: LockedActivitiesState = INITIAL_ALL_ACTIVITIES_ARR_STATE.reduce((resObj, activityData) => {
    resObj[activityData.id] = { locked: false, lastReceivedValue: null };
    return resObj;
  }, {} as LockedActivitiesState);
  // in milliseconds - 10 seconds
  const _ACTIVITY_CHANGE_TRACKER_DURATION = 10000;
  const _ACTIVITY_CHANGE_TRACKER_MAX_CHANGES_NUM = 3;
  let _lockedActivitiesState = { ...INITIAL_LOCKED_ACTIVITIES_STATE };
  const _changeCounter = { value: 0 };
  const _timeoutData = { id: null };

  const fullReset = () => {
    _lockedActivitiesState = copyObj(
      INITIAL_ALL_ACTIVITIES_ARR_STATE.reduce((resObj, activityData) => {
        resObj[activityData.id] = { locked: false, lastReceivedValue: null };
        return resObj;
      }, {} as LockedActivitiesState)
    );
    log("fullReset _lockedActivitiesState", _lockedActivitiesState);
  }

  // starts a timeout and returns ID of that timeout.
  const startResetChangeCounter = () => {
    return setTimeout(() => {
      // clear change counter if duration finished.
      clearChangeCounter();
    }, _ACTIVITY_CHANGE_TRACKER_DURATION);
  };
  const assignTimeoutID = (id) => {
    _timeoutData.id = id;
  };
  const clearTimeoutID = () => {
    if (getTimeoutID() !== null) {
      clearTimeout(_timeoutData.id);
      _timeoutData.id = null;
    }
  };
  const getTimeoutID = () => {
    return _timeoutData.id;
  };
  const checkAllowedNumOfChanges = () => {
    // too many changes detected
    if (_changeCounter.value > _ACTIVITY_CHANGE_TRACKER_MAX_CHANGES_NUM) {
      // IF THIS RUNS, it means that too many state changes occured in a matter of 10 seconds, which means that something is wrong with
      // chatContext OR authToken OR userConsent props/state logic becase it should not produce more than 3 delayed state changes under 10 seconds.
      console.error('TOO MANY CHANGES DETECTED FOR ActivityTracker component elements!');
    }
  };
  const increaseChangeCounter = () => {
    // check to see if the timeout is going on already
    if (getTimeoutID() !== null) {
      // if there is an ongoing timeout
      // clear it out and start the new one.
      clearTimeoutID();
    }
    // if no ongoing timeout, just start the new one
    assignTimeoutID(startResetChangeCounter());
    // increase counter
    _changeCounter.value += 1;
    // check if the counter is > than the max allowed
    // take care of too many changes.
    checkAllowedNumOfChanges();
  };
  const clearChangeCounter = () => {
    _changeCounter.value = 0;
  };

  const lockActivity = (targetActivity: IActivityID) => {
    log("lockActivity targetActivity", targetActivity);
    _lockedActivitiesState[targetActivity].locked = true;
  };
  const unlockActivity = (targetActivity: IActivityID) => {
    _lockedActivitiesState[targetActivity].locked = false;
  };
  const isActivityLocked = (targetActivity: IActivityID) => {
    log("isActivityLocked targetActivity", targetActivity);
    log("isActivityLocked _lockedActivitiesState", copyObj(_lockedActivitiesState));
    return _lockedActivitiesState[targetActivity].locked;
  };
  const setDelayedValue = (targetActivity: IActivityID, value, withNavigation:boolean | null = true, optionalData?: ActivityOptionalData | null, optionalCallback ?: () => void | null) => {
    _lockedActivitiesState[targetActivity].lastReceivedValue = value;
    _lockedActivitiesState[targetActivity].withNavigation = withNavigation;
    if (optionalData) _lockedActivitiesState[targetActivity].optionalData = optionalData;
    if (optionalCallback) _lockedActivitiesState[targetActivity].optionalCallback = optionalCallback;
  };
  const getDelayedValue = (targetActivity: IActivityID) => {
    return {
      lastReceivedValue: _lockedActivitiesState[targetActivity].lastReceivedValue,
      withNavigation: _lockedActivitiesState[targetActivity].withNavigation,
      optionalData: _lockedActivitiesState[targetActivity].optionalData || null,
    };
  };

  type DelayedActivityData = [IActivityID, LockedActivity][];

  const getDelayedActivitiesDataTuples = (): DelayedActivityData => {
    const ActivityIDs = Object.keys(_lockedActivitiesState) as IActivityID[];
    const resTuples: DelayedActivityData = ActivityIDs.map((activityID) => {
      return [activityID, _lockedActivitiesState[activityID]];
    })

    return resTuples;
  };
  const clearDelayedActivityValues = () => {
    const ActivityIDs = Object.keys(_lockedActivitiesState) as IActivityID[];
    for(const aID of ActivityIDs) {
      clearDelayedValue(aID);
    }
  }
  const clearDelayedValue = (targetActivity: IActivityID) => {
    setDelayedValue(targetActivity, null, null, null, null);
  };
  const hasDelayedValue = (targetActivity: IActivityID) => {
    return ValidatorFunctions.isNotUndefinedNorNull(_lockedActivitiesState[targetActivity].lastReceivedValue);
  };

  const onUnmountHandler = () => {
    clearTimeoutID();
    clearDelayedActivityValues();
  };

  return {
    lockActivity,
    unlockActivity,
    isActivityLocked,
    setDelayedValue,
    getDelayedActivitiesDataTuples,
    clearDelayedValue,
    hasDelayedValue,
    increaseChangeCounter,
    onUnmountHandler,
    getDelayedValue,
    fullReset
  };
})();

export const TimeoutController = (function () {
  const _timeouts = {};

  const _checkIfExists = (targetActivity: IActivityID) => {
    return targetActivity in _timeouts;
  };

  const get = (targetActivity: IActivityID): number | null => {
    if (_checkIfExists(targetActivity)) {
      return _timeouts[targetActivity];
    } else {
      return null;
    }
  };

  const set = (targetActivity: IActivityID, timeoutId: Timeout) => {
    // clear the on going timeout if there is one
    checkAndClear(targetActivity)
    // set the new one.
    _timeouts[targetActivity] = timeoutId;
  };

  const checkAndClear = (targetActivity: IActivityID) => {
    const tmOutId = get(targetActivity);
    // only if timeout is not null, clear it.
    if (tmOutId) clearTimeout(tmOutId);
    // delete the field on _timeouts storage.
    delete _timeouts[targetActivity];
  };

  const onUnmountHandler = () => {
    const timeoutidArr = Object.values(_timeouts) as number[];
    if (timeoutidArr.length > 0) {
      for (const tID of timeoutidArr) {
        clearTimeout(tID);
      }
    }
  };

  const durations = {
    action: 800,
    order: 8000
  };

  return {
    checkAndClear,
    get,
    set,
    durations,
    onUnmountHandler,
  };
})();
