/**
 * @file This file contains the definition of the `MobileDeviceType` enum class. This class contains enumerators for all
 * major phone device types (Android, iPhone, etc.) which might be used to view Morgan from.
 * @author Kristoffer A. Wright
 */

/**
 * This class is an enum class, the members of which represent mobile device types (Android, iPhone, etc.) which might
 * be used by a user to access Morgan.
 */
class MobileDeviceType {

  name : string = '';
  static ANDROID = new MobileDeviceType("ANDROID");
  static BLACKBERRY = new MobileDeviceType("BLACKBERRY");
  static IOS = new MobileDeviceType("IOS");
  static NONE = new MobileDeviceType("NONE");

  constructor(name: string) {
    this.name = name;
  }
}

export default MobileDeviceType;