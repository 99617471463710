import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { DialogPropsWithChildren } from '@mTypes/Console';
import React, { FC } from 'react';
import DialogHeader from './DialogHeader';
import { useTranslation } from 'react-i18next';
import { StyledTableCell } from '@styles/DashboardTab';
import Styles from '@styles/DashboardAiViewStepsDialog.module.css';

const ViewStepsDialog: FC<DialogPropsWithChildren> = (props) => {
  const { onClose, allActions, allWorkflows, allHelpTips, allHelpTipGroups, taskSingle } = props;

  const { t } = useTranslation();

  return (
    <Dialog maxWidth="lg" open={true} onClose={() => onClose()}>
      <DialogHeader title={t('AI Actions for ') + taskSingle.name} onClose={() => onClose()} />
      <DialogContent>
        <TableContainer className={Styles.ContainerTable}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('stepCol')}</StyledTableCell>
                <StyledTableCell>{t('action')}</StyledTableCell>
                <StyledTableCell>{t('workflow')}</StyledTableCell>
                <StyledTableCell>{t('helpTipTranslation')}</StyledTableCell>
                <StyledTableCell>{t('helpTipGroupTranslation')}</StyledTableCell>
                <StyledTableCell>{t('answer')}</StyledTableCell>
                <StyledTableCell>{t('active')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskSingle.actions.map((step, index) => (
                <TableRow hover key={step?._id}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>
                    {allActions?.filter((fa) => fa?._id === step.action)[0]?.description ?? ''}
                  </StyledTableCell>
                  <StyledTableCell>
                    {allWorkflows?.filter((fa) => fa?._id === step.workflow)[0]?.name ?? ''}
                  </StyledTableCell>
                  <StyledTableCell>
                    {allHelpTips?.filter((fa) => fa?._id === step.helpTip)[0]?.name ?? ''}
                  </StyledTableCell>
                  <StyledTableCell>
                    {allHelpTipGroups?.filter((fa) => fa?._id === step.helpTipGroup)[0]?.name ?? ''}
                  </StyledTableCell>
                  <StyledTableCell>{step.answer}</StyledTableCell>
                  <StyledTableCell>{step.active ? 'Active' : 'Inactive'}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="info" onClick={() => onClose()}>
          {t('closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ViewStepsDialog;
