import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { io, Socket } from 'socket.io-client';
import { useSocketContext } from '@contexts/socket';
import { SocketListenerType } from '@mTypes/TSocket';
import type { TAuthActivityUpdate, TChatEventEmitted, TEventPayload, TJoinedChat, TLeftChat, TSocketCallback, TSocketPayload } from '@mTypes/TSocket';
import useDocumentVisibility from '@common/hooks/useDocumentVisibility';

/**
 * Custom React hook for managing socket.io messages from AngelAi-Messages.
 * @param {string} _id - User ID.
 * @param {string} accessToken - Authentication accessToken.
 * @returns {Socket | null} - Socket instance or null if _id or accessToken are not provided.
 */
const useMessagesSocket = (_id: string, accessToken: string, accessLevel?: string): { isMessagesCreated: boolean, isMessagesConnected: boolean, isMessagesReconnecting: boolean, messagesSocket: MutableRefObject<Socket | null>, disconnectMessageSocket: any } => {
  const [isMessagesCreated, setIsMessagesCreated] = useState<boolean>(false);
  const [isMessagesConnected, setIsMessagesConnected] = useState<boolean>(false);
  const [isMessagesReconnecting, setIsMessagesReconnecting] = useState<boolean>(false);
  const messagesSocket = useRef<Socket | null>(null);
  const navigate = useNavigate();

  // Function to disconnect the socket using the ref
  const disconnectMessageSocket = useCallback(() => {
    console.warn(`Messages Socket - useMessagesSocket - (disconnected)`);

    messagesSocket.current?.disconnect();

    setIsMessagesCreated(false);
    setIsMessagesConnected(false);
  }, []);

  useEffect(() => {
    if (!_id) return console.warn(`messagesSocket invalid _id ${_id}`);
    if (!accessToken) return console.warn(`messagesSocket invalid accessToken ${_id}`);

    console.info(`Messages Socket - useMessagesSocket - (connecting)`);

    try {
      const config = {
        transports: ['websocket', 'transport'],
        reconnection: true,
        auth: {
          token: accessToken,
        },
        query: {
          userId: !accessLevel? _id : '',
          operatorId: accessLevel? _id : '',
          accessLevel: accessLevel, // required for operator specific messages
        },
      };

      messagesSocket.current = io(process.env.REACT_APP_MORGAN_MESSAGES || '', config) || null;
      setIsMessagesCreated(true);
    } catch (err) {
      console.error(`Messages Socket - useMessagesSocket - (caught-error)`, err);
    }

    // Clean up the socket connection when the component unmounts
    return () => {
      disconnectMessageSocket();
    };
  }, [_id, accessToken]);

  useEffect(() => {    
    if (!isMessagesCreated) return;

    const handleConnect = () => {
      console.info(`messagesSocket manager connected`);

      setIsMessagesConnected(true);
    }
    
    const handleDisconnect = (data) => {
      console.info(`messagesSocket manager disconnected`, data);

      setIsMessagesConnected(false);

      if (messagesSocket.current?.active) {
        // temporary failure, the socket will automatically try to reconnect
      } else {
        // the connection was denied by the server
        // in that case, `socket.connect()` must be manually called in order to reconnect
        messagesSocket.current?.connect();
      }
    }

    const handleReconnect = (data) => {
      console.info(`messagesSocket manager reconnected`, data);
      setIsMessagesConnected(true);

      setIsMessagesReconnecting(false);
    }

    const handleReconnecting = (data) => {
      console.info(`messagesSocket manager reconnecting`, data);
      setIsMessagesConnected(false);

      setIsMessagesReconnecting(true);
    }

    const handleError = (error) => {
      console.error(`messagesSocket manager error`, error);

      if (error === 'Invalid ip or device') {
        if (accessLevel !== undefined) {
          navigate(`/console/login`, {
            state: {
              systemMessage: "The system is not available right now. Please try again later.",
            },
          });
        } else if (accessLevel === undefined) {
          navigate(`/notices`, {
            state: {
              systemMessage: "The system is not available right now. Please try again later.",
            },
          });
        }
      }
    }

    console.info(`Messages Socket - useMessagesSocket listeners - (connected)`);

    messagesSocket.current?.on('connect', handleConnect);
    messagesSocket.current?.on('disconnect', handleDisconnect);
    messagesSocket.current?.on("connect_error", handleError);
    messagesSocket.current?.io?.on('reconnect', handleReconnect);
    messagesSocket.current?.io?.on('reconnect_attempt', handleReconnecting);

    return () => {
      console.warn(`Messages Socket - useMessagesSocket listeners - (disconnected)`);

      messagesSocket.current?.off('connect', handleConnect);
      messagesSocket.current?.off('disconnect', handleDisconnect);
      messagesSocket.current?.off("connect_error", handleError);
      messagesSocket.current?.io?.off('reconnect', handleReconnect);
      messagesSocket.current?.io?.off('reconnect_attempt', handleReconnecting);
    };
  }, [isMessagesCreated]);

  const reconnectMessages = (visibilityState: string) => {
    if(visibilityState === 'visible') {
      if (isMessagesCreated  && !isMessagesReconnecting) {
        messagesSocket.current?.connect();
      }
    }
  };
  
  useDocumentVisibility(reconnectMessages);

  return {
    isMessagesCreated,
    isMessagesConnected,
    isMessagesReconnecting,
    messagesSocket,
    disconnectMessageSocket,
  };
};

export const useMessagesListener = <T extends SocketListenerType>(
  origin: string,
  event: T,
  callback: TSocketCallback<T>,
) => {
  const socketContext = useSocketContext();

  const validatePayloadData = useCallback((data: TSocketPayload<T>) => {
    try {
      switch (event) {
        case SocketListenerType.chatEventEmitted:
          const chatEventEmittedData = data as TChatEventEmitted;
          if (typeof chatEventEmittedData !== 'object') return false;
          if ('type' in chatEventEmittedData && typeof chatEventEmittedData.type === 'string') return true;
          return true;
        case SocketListenerType.joinedChat:
        case SocketListenerType.leftChat:
          const joinedChatData = data as TJoinedChat;
          if (typeof joinedChatData !== 'string') return false;
          if (joinedChatData.length !== 24) return false;
          return true;
        case SocketListenerType.authActivityUpdate:
          const authActivityUpdateData = data as TAuthActivityUpdate;
          if (typeof authActivityUpdateData !== 'object') return false;
          if (!('_id' in authActivityUpdateData && typeof authActivityUpdateData._id === 'string' && authActivityUpdateData._id.length === 24)) return false;
          if (!('ssAccessToken' in authActivityUpdateData && typeof authActivityUpdateData.ssAccessToken === 'string')) return false;
          return true;
        default:
          return false;
      }
    } catch {
      return false;
    }
  }, [event]);

  const eventHandler = useCallback((payload: TEventPayload<T>) => {
    const data = payload?.data;
    const message = payload?.message;
    const isPayloadValid = validatePayloadData(data);
    if (!isPayloadValid) {
      return console.warn(`useMessagesListener.${origin} (${event}): Invalid Payload`, payload);
    }

    console.log(`useMessagesListener.${origin} (${event})` +
      (message ? `: ${message}` : ''), data);

    callback(data);
  }, [callback, validatePayloadData, origin]);

  useEffect(() => {
    if (!socketContext?.isMessagesCreated) return;
    const socket: Socket = socketContext?.messagesSocket?.current;
    if (!socket || !socket.connected) return;

    // @ts-ignore
    socket?.on(event, eventHandler);
    console.log(`useMessagesListener.${origin} (on) ${event}`);

    return () => {
      // @ts-ignore
      socket?.off(event, eventHandler);
      console.log(`useMessagesListener.${origin} (off) ${event}`);
    };
  }, [eventHandler, socketContext?.isMessagesCreated]);
};

export default useMessagesSocket;