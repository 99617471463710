import { containsPotentialUrl } from "./containsUrl";
import axiosCall from '../Services/axios';

const containsYoutubeLink = async (url) => {
  //Test message string for match of youtube string. Must start with either youtube, or youtu.be
  let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
  let videoId = regex.exec(url)?.[3];
  if (videoId) {
    // We check to ensure the videoId is valid
    let response = await axiosCall({
      url: 'url/youtube',
      method: 'post',
      data: {
        videoId
      }
    });
    // either return youtube data or false so that it falls back to a URL message type
    return response?.youtubeData?.items?.[0] || false;
  }
  return false;
};

const getVideoMetadata = async (url, operatorView) => {
  let response = await axiosCall({ url: 'url/video', method: "post", data: { url } });
  return response?.videoData || false;
}

const containsVideo = async (string) => {
  // Quick empty string and type validation
  if(!string || typeof string !== 'string'){
    return;
  }
  // Don't match strings that have been edited
  if (string.includes('<i class="messageArrow"/>')) {
    string = string.split('<i class="messageArrow"/>')[1];
  }
  // Return false if we detect any spaces, so it can be detected as a URL
  if (string?.trim()?.split(' ')?.length > 1) {
    return false;
  }
  // Make sure the message only contains a video link
  if (string?.trim()?.split(" ").length > 1 || !containsPotentialUrl(string.trim())) {
    return false;
  }

  // Extract YouTube URL from message.
  let youtubeLink = await containsYoutubeLink(string.trim());
  // if we find valid YouTube info, return youtube data
  if (youtubeLink?.kind) {
    return youtubeLink;
  } else {
    // Otherwise we hit core to see if we can retrieve video data from the
    // noembed endpoint and then return either said data or false;
    let videoLink = await getVideoMetadata(string.trim()).catch(e => { return false })
    if (videoLink?.title) {
      return videoLink;
    }
  }
  return false;
};

export default containsVideo;