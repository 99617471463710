/**
 * @file LoanProductComparisonMessageContent.tsx
 * @description Dynamic message bubble that lists the loan products available
 * based on their loan scenario. Part of the Lock the Loan workflow.
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */

import { IMessage } from '@interfaces/Conversation';
import React, { useEffect } from 'react';
import Styles from '@styles/ChooseLoanProduct/LoanProductComparisonMessageContent.module.css';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import { useErrandContext } from '@contexts/ErrandContext';
import { useTranslation } from 'react-i18next';

type TProps = {
  message: IMessage;
};

const LoanProductComparisonMessageContent = ({ message }: TProps) => {
  const { comparedLoanProducts, setComparedLoanProducts } = useErrandContext();
  const loanProductsArr: LoanProduct[] = JSON.parse(message.message);
  const { t } = useTranslation();

  const renderProductContent = (product: LoanProduct) => {
    return (
      <p className={Styles.loanProductText}>
        {product.productDescription} {product.paymentDescription} <br />
        <b>{t('rate')}:</b> {product.rate}%<br />
        <b>{t('estAPR')}:</b> {product.aprEstimated}% <br />
        <b>{t('lockPeriod')}:</b> {product.lockPeriod} {t('tDays')}<br />
        <b>{t('expiryDate')}:</b> {product.prodExpDate} <br />
        <b>{t('price')}:</b> {product.price.toFixed(3)} <br />
        <b>{t('monthlyP&I')}:</b> ${product.PI.toFixed(2)} <br />
      </p>
    );
  };

  useEffect(() => {
    setComparedLoanProducts(loanProductsArr);
  }, []);
  return (
    <div className={Styles.wrapper}>
      {comparedLoanProducts.map((product, index) => (
        <div className={Styles.loanProduct} key={index}>
          {renderProductContent(product)}
        </div>
      ))}
    </div>
  );
};

export default LoanProductComparisonMessageContent;
