import React from 'react';
import { LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as CoreUtils from '@common/CoreUtils';
import FormStyles from '@styles/Form.module.css';
import * as PullCreditMenu from '@components/PullCreditMenu/PullCreditMenu';
import Input from '@components/PullCreditMenu/Input';
import Select from '@components/PullCreditMenu/Select';
import Checkbox from '@components/PullCreditMenu/Checkbox';
import SubmitButton from '@components/PullCreditMenu/SubmitButton';
import useEmail from '@common/hooks/useEmail';

export const pullTypeEnum = {
  newSubmit: 'new submit',
  reIssue: 're-issue',
};
export const inquiryTypeEnum = {
  hardPull: 'Hard Pull',
  softPull: 'Soft Pull',
};
const loanNumberRequiredLength = 12;

/**
 * Parses the application options to make things easier to work with.
 */
function getApplicationOptions(applications: CoreUtils.Application[]): PullCreditMenu.Option[] | null {
  const apps = [];

  for (const application of applications) {
    if (!application.applicationNumber) {
      return null;
    } else if (!application.namesForTitle) {
      return null;
    }

    const title = `${application.applicationNumber} - ${application.namesForTitle}`;

    const app = { title, value: JSON.stringify(application) };

    apps.push(app);
  }

  return apps;
}

/**
 * Gets the latest complete credit pull.
 */
function getLatestCompleteCreditPull(creditPulls: any): any {
  for (let i = creditPulls.length - 1; i >= 0; i--) {
    if (creditPulls[i].runStatusKeyword === 'COMPLETE') {
      return creditPulls[i];
    }
  }
}

interface Props {
  togglePullCreditMenu: () => void;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setActionListSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Form sub component. Used to make things cleaner.
 */
export default function Form({ setSnackbarMessage, setActionListSnackbarMessage, togglePullCreditMenu }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { email, setEmail } = useEmail(setLoading);
  const [loanNumber, setLoanNumber] = React.useState<string>('');
  const [application, setApplication] = React.useState<string>('');
  const [applicationOptions, setApplicationOptions] = React.useState<PullCreditMenu.Option[]>([]);
  const [verbalAuthorization, setVerbalAuthorization] = React.useState<boolean>(false);
  const [pullType, setPullType] = React.useState<string>('');
  const [pullTypeOptions, setPullTypeOptions] = React.useState<PullCreditMenu.Option[]>([]);
  const [inquiryType, setInquiryType] = React.useState<string>('');
  const [verbalAuthorizationNeeded, setVerbalAuthorizationNeeded] = React.useState<boolean>(false);
  const latestCompleteCreditPullRef = React.useRef<CoreUtils.CreditPull | undefined>();
  const { t } = useTranslation();

  /**
   * Determines if the email is disabled. If loading keep it disabeld.
   */
  function getEmailDisabled() {
    if (loading) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines if the loan number is disabled. If loading keep it disabled.
   */
  function getLoanNumberDisabled() {
    if (loading) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines if the application is disabled. If there are no options yet or
   * loading keep it disabled.
   */
  function getApplicationDisabled() {
    if (loading || !applicationOptions.length) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines if the verbal authorization is disabled. If verbal authorization
   * is not needed or loading keep it disabled.
   */
  function getVerbalAuthorizationDisabled() {
    if (loading || !verbalAuthorizationNeeded) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines if the pull type is disabled. If there are no options or loading
   * keep it disabled.
   */
  function getPullTypeDisabled() {
    if (loading || !pullTypeOptions.length) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines if the injury type is disabled. If the selected pull type is not
   * new submit or loading keep it disabled.
   */
  function getInquiryTypeDisabled() {
    if (loading || pullType !== pullTypeEnum.newSubmit) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines if the email is missing.
   */
  function getEmailMissing(): boolean {
    if (!email) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Used to determine if the loan number is missing. This field is required.
   */
  function getLoanNumberMissing(): boolean {
    if (loanNumber.length < loanNumberRequiredLength) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Used to determine if the application is missing. This field is required.
   */
  function getApplicationMissing(): boolean {
    if (!application) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Used to determine if the verbal authorization is missing.
   */
  function getVerbalAuthorizationMissing(disabled: boolean): boolean {
    if (!disabled && !verbalAuthorization) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Used to determine if the pull type is missing. This field is required.
   */
  function getPullTypeMissing(): boolean {
    if (!pullType) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Used to determine if the inquiry type is missing.
   */
  function getInquiryTypeMissing(disabled: boolean): boolean {
    if (!disabled && !inquiryType) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Handles loan number change.
   */
  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  /**
   * Handles loan number change.
   */
  function handleLoanNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!Number.isInteger(Number(event.target.value))) {
      // do not change if the value is not an integer.
      return;
    } else if (event.target.value.length > loanNumberRequiredLength) {
      // do not change if the value is greater than the required length
      return;
    } else {
      setLoanNumber(event.target.value);
    }
  }

  /**
   * Handles the application change.
   */
  function handleApplicationChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setApplication(event.target.value);
  }

  /**
   * Handles the pull type change.
   */
  function handlePullTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setPullType(event.target.value);
  }

  /**
   * Handles the inquiry type change.
   */
  function handleInquiryTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setInquiryType(event.target.value);
  }

  /**
   * Handles verbal authorization change.
   */
  function handleVerbalAuthorizationChange() {
    setVerbalAuthorization((verbalAuthorization) => !verbalAuthorization);
  }

  /**
   * Renders the linear progress bar. Only show the determinate version when
   * loading.
   */
  function renderLinearProgress() {
    if (loading) {
      return <LinearProgress />;
    } else {
      return <LinearProgress variant="determinate" value={100} />;
    }
  }

  /**
   * If these values ever change reset the application state.
   */
  React.useEffect(() => {
    setApplication('');
    setApplicationOptions([]);
  }, [loanNumber]);

  /**
   * If these values ever change reset the verbal authorization state.
   */
  React.useEffect(() => {
    setVerbalAuthorization(false);
    setVerbalAuthorizationNeeded(false);
  }, [application]);

  /**
   * If these values ever change reset the pull type state.
   */
  React.useEffect(() => {
    setPullType('');
    setPullTypeOptions([]);
  }, [loanNumber, application]);

  /**
   * If these values ever change reset the inquiry type state.
   */
  React.useEffect(() => {
    setInquiryType('');
  }, [pullType]);

  /**
   * Listens to the loan number. When the required length fetch the application
   * options.
   */
  React.useEffect(() => {
    async function getApplications() {
      if (loanNumber.length < loanNumberRequiredLength) {
        return;
      }

      const log = { function: getApplications.name };
      console.info({ ...log, message: 'getting applications' });
      setLoading(true);

      // get applications
      const applications = await CoreUtils.getApplications(loanNumber);
      console.debug({ ...log, message: 'applications declared', applications });

      if (!applications) {
        setSnackbarMessage(t('applicationsNotFound'));
        setLoanNumber('');
        setLoading(false);
        console.warn({ ...log, message: 'applications not found' });
        return;
      } else if (!Array.isArray(applications)) {
        setSnackbarMessage(t('applicationsNotFound'));
        setLoanNumber('');
        setLoading(false);
        console.warn({ ...log, message: 'applications is not an array' });
        return;
      } else if (!applications.length) {
        setSnackbarMessage(t('applicationsNotFound'));
        setLoanNumber('');
        setLoading(false);
        console.warn({ ...log, message: 'no applications' });
        return;
      }

      // get application options
      const applicationOptions = getApplicationOptions(applications);
      console.debug({ ...log, message: 'application options declared', applicationOptions });

      // set application options
      setApplicationOptions(applicationOptions);

      // continue
      setLoading(false);
      console.info({ ...log, message: 'application options found' });
    }
    getApplications();
  }, [loanNumber, setSnackbarMessage, t]);

  /**
   * When the application number is selected fetch the applications. This is
   * used to check for the need for verbal authorization as well as update
   * it later on.
   */
  React.useEffect(() => {
    async function handleEffect() {
      if (loanNumber.length < loanNumberRequiredLength || !application) {
        return;
      }

      const log = { function: handleEffect.name };
      console.info({ ...log, message: 'handling effect' });
      setLoading(true);

      // get application
      const app = JSON.parse(application);

      const borrowerNumbers = [app.borrowerNumber, app.coBorrowerNumber];
      const borrowersConsent = new Set();

      for (const borrowerNumber of borrowerNumbers) {
        if (!borrowerNumber) {
          continue;
        }

        // get borrower
        const borrower = await CoreUtils.getBorrower(loanNumber, borrowerNumber);
        console.debug({ ...log, message: 'borrower declared', borrower });

        // check borrower
        if (!borrower) {
          setSnackbarMessage(t('borrowerNotFound'));
          setLoading(false);
          console.warn({ ...log, message: 'borrower not found' });
          return;
        }

        borrowersConsent.add(borrower.brwrAuthorizedPullCredit);
      }

      // if any of the borrowers do not consent require verbal authorization
      if (borrowersConsent.has(false)) {
        setVerbalAuthorizationNeeded(true);
      }

      // get credit pulls
      const creditPulls = await CoreUtils.getCreditPulls(loanNumber, app.applicationNumber);
      console.debug({ ...log, message: 'credit pulls declared', creditPulls });

      // check credit pulls
      if (!creditPulls) {
        setSnackbarMessage(t('pullTypesNotFound'));
        setLoading(false);
        console.warn({ ...log, message: 'credit pulls not found' });
        return;
      } else if (!Array.isArray(creditPulls)) {
        setSnackbarMessage(t('pullTypesNotFound'));
        setLoading(false);
        console.warn({ ...log, message: 'credit pulls is not an array' });
        return;
      }

      // get latest complete credit pull
      const latestCompleteCreditPull = getLatestCompleteCreditPull(creditPulls);
      console.debug({ ...log, message: 'latest complete credit pull declared', latestCompleteCreditPull });

      // check latest complete credit pull
      if (latestCompleteCreditPull) {
        const typeOptions = [
          { title: t('newSubmit'), value: pullTypeEnum.newSubmit },
          { title: t('reIssue'), value: pullTypeEnum.reIssue },
        ];
        setPullTypeOptions(typeOptions);
        latestCompleteCreditPullRef.current = latestCompleteCreditPull;
      } else {
        const typeOptions = [{ title: t('newSubmit'), value: pullTypeEnum.newSubmit }];
        setPullTypeOptions(typeOptions);
      }

      // continue
      console.info({ ...log, message: 'handled effect' });
      setLoading(false);
    }
    handleEffect();
  }, [loanNumber, application, setSnackbarMessage, t]);

  const inquiryTypeOptions = [
    { title: t('hardPull'), value: inquiryTypeEnum.hardPull },
    { title: t('softPull'), value: inquiryTypeEnum.softPull },
  ];
  const emailDisabled = getEmailDisabled();
  const loanNumberDisabled = getLoanNumberDisabled();
  const applicationDisabled = getApplicationDisabled();
  const verbalAuthorizationDisabled = getVerbalAuthorizationDisabled();
  const pullTypeDisabled = getPullTypeDisabled();
  const inquiryTypeDisabled = getInquiryTypeDisabled();
  const emailMissing = getEmailMissing();
  const loanNumberMissing = getLoanNumberMissing();
  const applicationMissing = getApplicationMissing();
  const verbalAuthorizationMissing = getVerbalAuthorizationMissing(verbalAuthorizationDisabled);
  const pullTypeMissing = getPullTypeMissing();
  const inquiryTypeMissing = getInquiryTypeMissing(inquiryTypeDisabled);
  const missingOrLoading = new Set([
    loading,
    emailMissing,
    loanNumberMissing,
    applicationMissing,
    verbalAuthorizationMissing,
    pullTypeMissing,
    inquiryTypeMissing,
  ]);

  return (
    <form className={FormStyles.form}>
      <Input
        title={t('email')}
        value={email}
        onChange={handleEmailChange}
        disabled={emailDisabled}
        missing={emailMissing}
      />
      <Input
        title={t('loanNumber')}
        value={loanNumber}
        onChange={handleLoanNumberChange}
        disabled={loanNumberDisabled}
        missing={loanNumberMissing}
      />
      <Select
        title={t('application')}
        options={applicationOptions}
        value={application}
        onChange={handleApplicationChange}
        disabled={applicationDisabled}
        missing={applicationMissing}
      />
      <Checkbox
        title={t('verbalAuthorization')}
        checked={verbalAuthorization}
        onChange={handleVerbalAuthorizationChange}
        disabled={verbalAuthorizationDisabled}
        missing={verbalAuthorizationMissing}
      />
      <Select
        title={t('pullType')}
        options={pullTypeOptions}
        value={pullType}
        onChange={handlePullTypeChange}
        disabled={pullTypeDisabled}
        missing={pullTypeMissing}
      />
      <Select
        title={t('inquiryType')}
        options={inquiryTypeOptions}
        value={inquiryType}
        onChange={handleInquiryTypeChange}
        disabled={inquiryTypeDisabled}
        missing={inquiryTypeMissing}
      />
      {renderLinearProgress()}
      <SubmitButton
        email={email}
        loanNumber={loanNumber}
        application={application}
        verbalAuthorization={verbalAuthorization}
        pullType={pullType}
        inquiryType={inquiryType}
        loading={loading}
        verbalAuthorizationNeeded={verbalAuthorizationNeeded}
        missingOrLoading={missingOrLoading}
        latestCompleteCreditPullRef={latestCompleteCreditPullRef}
        setLoading={setLoading}
        setSnackbarMessage={setSnackbarMessage}
        setActionListSnackbarMessage={setActionListSnackbarMessage}
        togglePullCreditMenu={togglePullCreditMenu}
      />
    </form>
  );
}
