import React, { useRef, useEffect, useCallback, useMemo } from 'react';

import Styles from '@styles/MorphCreditRepairAccountType.module.css';
import { ARROW_DIRS } from './constants';

import { ArrowsProps, ArrowDirection } from '../types';
import { ButtonUp } from './ButtonUp';
import { Arrow } from './Arrow';

const Arrows = (props: ArrowsProps) => {
  const rightArrowRef = useRef<HTMLDivElement | null>(null);
  const leftArrowRef = useRef<HTMLDivElement | null>(null);
  const isSomeWfSelected = props.selectedIdx !== null;
  
  const onArrowClick = useCallback(
    (dir: ArrowDirection) => {
      if (!props.swiperInstance) return;
      const lastElementIdx = props.slideListItemsLength - 1;
      if (dir === ARROW_DIRS.left) {
        // if focused at the left most element
        if (props.addon + props.swiperInstance.activeIndex === 0) {
          // focus the left most element on left arrow click.
          props.focusElement(0);
          return;
        }
        // right arrow is focused right now
        if (props.focusedArrowIndex === 1) {
          // focus on the right most element
          props.focusElement(lastElementIdx);
        } else {
          props.handleTabBackward(true);
        }
      } else {
        // if focused at the right most element
        if (props.addon + props.swiperInstance.activeIndex === lastElementIdx) {
          // focus the right most element on right arrow click.
          props.focusElement(lastElementIdx);
          return;
        }
        // right arrow is focused right now
        if (props.focusedArrowIndex === 0) {
          // focus on the right most element
          props.focusElement(0);
        } else {
          props.handleTabForward(true);
        }
      }
    },
    [props.swiperInstance, props.focusedArrowIndex, props.handleTabForward, props.handleTabBackward, props.addon]
  );

  const focusArrow = useCallback((dir: ArrowDirection) => {
    if (dir === ARROW_DIRS.left) {
      leftArrowRef?.current?.focus();
    }

    if (dir === ARROW_DIRS.right) {
      rightArrowRef?.current?.focus();
    }
  }, []);

  const onLostFocus = useCallback(() => {
    props.setFocusedArrowIndex(null);
  }, [props.setFocusedArrowIndex]);

  const onLeftArrowClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onArrowClick(ARROW_DIRS.left);
    },
    [onArrowClick]
  );

  const onRightArrowClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onArrowClick(ARROW_DIRS.right);
    },
    [onArrowClick]
  );

  useEffect(() => {
    if (props.focusedArrowIndex === 0) {
      focusArrow(ARROW_DIRS.left);
    }

    if (props.focusedArrowIndex === 1) {
      focusArrow(ARROW_DIRS.right);
    }
  }, [props.focusedArrowIndex]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>, side: ArrowDirection) => {
      if (e.code === 'Tab') {
        if (!e.shiftKey) {
          // forwards
          if (side === ARROW_DIRS.left) {
            props.setFocusedArrowIndex(-1);
            e.preventDefault();
          }
          return;
        } else if (e.shiftKey) {
          // backwards
          if (side === ARROW_DIRS.right) {
            props.setFocusedArrowIndex(-1);
            e.preventDefault();
          }
          return;
        }
      }
    },
    [props.setFocusedArrowIndex]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLDivElement, Element>, side: ArrowDirection) => {
      e.preventDefault();

      if (!props.swiperInstance) return;
      let arrowWasFocused =
        false || props.focusedArrowIndex === 0 || props.focusedArrowIndex === 1 || props.focusedArrowIndex === null;

      if (side === ARROW_DIRS.right) {
        if (props.addon + props.swiperInstance.activeIndex !== props.slideListItemsLength - 1) {
          arrowWasFocused = true;
          props.setFocusedArrowIndex(1);
        }
      }

      if (side === ARROW_DIRS.left) {
        if (props.addon + props.swiperInstance.activeIndex !== 0) {
          arrowWasFocused = true;
          props.setFocusedArrowIndex(0);
        }
      }

      if (arrowWasFocused === false)
        setTimeout(() => props.focusElement(props.swiperInstance.activeIndex + props.addon), 0);
    },
    [
      props.addon,
      props.swiperInstance,
      props.focusedArrowIndex,
      props.slideListItemsLength,
      props.focusElement,
      props.setFocusedArrowIndex,
    ]
  );

  const keyDownHandlers = useMemo(() => {
    return {
      left: ((e) => handleKeyDown(e, ARROW_DIRS.left)) as React.KeyboardEventHandler<HTMLDivElement>,
      right: ((e) => handleKeyDown(e, ARROW_DIRS.right)) as React.KeyboardEventHandler<HTMLDivElement>,
    };
  }, [handleKeyDown]);

  const focusHandlers = useMemo(() => {
    return {
      left: ((e) => handleFocus(e, ARROW_DIRS.left)) as React.FocusEventHandler<HTMLDivElement>,
      right: ((e) => handleFocus(e, ARROW_DIRS.right)) as React.FocusEventHandler<HTMLDivElement>,
    };
  }, [handleFocus]);

  // on load, return nothing AND if not yet mounted. (after first render)
  if (props.isLoading || !props.hasMounted || props.slideListItemsLength === 0) return null;

  return (
    <div className={Styles.arrowContainer}>
      <Arrow
        workflowWasSent={props.workflowWasSent}
        isSomeWfSelected={isSomeWfSelected}
        onKeyDown={keyDownHandlers.left}
        onFocus={focusHandlers.left}
        onClick={onLeftArrowClick}
        onLostFocus={onLostFocus}
        side={ARROW_DIRS.left}
        ref={leftArrowRef}
      />
      {/* <ButtonUp isSomeWfSelected={isSomeWfSelected} onClick={props.resetState} /> */}
      <Arrow
        workflowWasSent={props.workflowWasSent}
        isSomeWfSelected={isSomeWfSelected}
        onKeyDown={keyDownHandlers.right}
        onFocus={focusHandlers.right}
        onClick={onRightArrowClick}
        onLostFocus={onLostFocus}
        side={ARROW_DIRS.right}
        ref={rightArrowRef}
      />
    </div>
  );
};

export { Arrows };