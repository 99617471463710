import { IActivityID } from '../interfaces';

export const ELEMENTS_ADDITIONAL_STYLES: Record<IActivityID, any> = {
  Esign: {
    backgroundColor: 'var(--blue990)',
  },
  Consent: {
    cursor: 'pointer',
  },
  ExpandFullMode: {
    cursor: 'pointer',
  },
  Auth: null,
  Context: null,
  Action: null,
  PrivateMode: null,
  EditingMode: null,
  Order: null,
  SlotMachine: null,
  Live: null,
  PropertyListing: null,
};
