import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';


const SlotMachineHeaderBar = (props) => {
    const { t } = useTranslation();

    const SaveButton = styled(Button)({
        backgroundColor: 'var(--blue210)',
        color: 'var(--gray000)',
        boxShadow: `
        0px 3px 1px -2px var(--shadow100), 
        0px 2px 2px 0px var(--shadow114), 
        0px 1px 5px 0px var(--shadow112)
        `,
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'var(--blue962)',
            boxShadow: `
            0px 2px 4px -1px var(--shadow100), 
            0px 4px 5px 0px var(--shadow114), 
            0px 1px 10px 0px var(--shadow112)`
        }
    })

    return (
        <Stack marginBottom={1} direction="row" spacing={2}>
            {props.incorrectSum ?
                <Tooltip
                    arrow
                    placement="top"
                    title={<span>Rates will be auto-adjusted, sum is not 100%</span>}
                >
                    <Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
                        <SaveButton onClick={() => props.handleSave()}>
                            {t('saveButton')}
                        </SaveButton>
                    </Box>
                </Tooltip>
                :
                <Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
                    <SaveButton onClick={() => props.handleSave()}>
                        {t('saveButton')}
                    </SaveButton>
                </Box>
            }
            <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                <Typography display="inline-block">Prizes</Typography>
            </Box>

            <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
                <Tooltip title={t('refreshButtonTooltip')} placement="bottom">
                    <CachedIcon
                        sx={{ marginRight: '15px', cursor: 'pointer' }}
                        onClick={() => {
                            props.onRefresh();
                        }}
                    />
                </Tooltip>
            </Box>
        </Stack>
    )
}

export default SlotMachineHeaderBar