import { Box, styled } from '@mui/system';
import { Stack, Button, IconButton } from '@mui/material';
import { LeftDoubleArrow, RightDoubleArrow, AboutToSign, SignedDocument, SignFailed, Download, Finish, MiddleButtonIcon } from '@assets/Icons';
import eventBus from '../Common/eventBus';
import { FormClickEventType, EsignActionStateType, FormBodyScrollStateType, EsignFormType, FormTypeDetector } from '../Forms/commonForms';
import { IRootContext, RootContext } from '@contexts/RootContext';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/MorphFormReadyToSend.module.css';
import { Ballerina6 } from '@assets/Icons';
const SigningDocument = process.env.REACT_APP_MORGAN_CDN + '/Images/handshake3.gif';

const StyledButton = styled(Button)<any>(
  ({ fontSize }) => `
  position: relative;
  z-index: 1001;
  font-size: ${fontSize ? fontSize : '0.8rem'};
  font-weight: normal;
  align-content: center;
  height: 1rem;
  color: var(--orange900);
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  box-shadow: none;
  &:hover: {
    color: black;
  }`
);

const StyledDiv = styled('div')({
  zIndex: '1001',
  fontSize: '0.7rem',
  fontWeight: 'normal',
  alignContent: 'center',
  height: '1rem',
  textTransform: 'none',
  color: 'var(--orange900)',
});

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'var(--gray000)',
  border: '0.0625rem solid var(--peach800)',
  borderRadius: '0.5rem',
  height: '50px',
  overflow: 'hidden',
});

const MorphFormReadyToSendTopBox = ({ parentId, errand }) => {
  const { t } = useTranslation();
  const rootContext = useContext<IRootContext>(RootContext);
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const isAiSign = FormTypeDetector(errand.name) === EsignFormType.AISIGN;

  const leftButton = () => {
    switch (errandContext.esignActionState) {
      case EsignActionStateType.Signed:
        return (
          <div className={Styles.buttonWordsLeftContainer}>
            <StyledButton
              className={Styles.onScreenWords}
              onClick={() => eventBus.dispatch(FormClickEventType.FormDownload)}
            >
              {t('monitoringDownload')}
            </StyledButton>
            <StyledButton
              className={Styles.offScreenWords}
              onClick={() => eventBus.dispatch(FormClickEventType.NavPrev)}
            >
              {t('previousButton')}
            </StyledButton>
          </div>
        );
      case EsignActionStateType.Downloading:
        return <StyledDiv>{t('buttonDownloading')}</StyledDiv>;
      default:
        return errandContext.formBodyScrollState !== FormBodyScrollStateType.Start ? (
          <>
            <StyledButton onClick={() => eventBus.dispatch(FormClickEventType.NavPrev)}>
              {t('previousButton')}
            </StyledButton>
          </>
        ) : (
          <>
            <div className={Styles.angelText}>{t('thankYou')}</div>
          </>
        );
    }
  };

  const leftIcon = () => {
    switch (errandContext.esignActionState) {
      case EsignActionStateType.Signed:
        return (
          <IconButton
            className={Styles.iconsContainer}
            onClick={() => eventBus.dispatch(FormClickEventType.FormDownload)}
          >
            <Download height={32} />
            <LeftDoubleArrow className={Styles.offScreenIcons} />
          </IconButton>
        );
      case EsignActionStateType.Downloading:
        return (
          <IconButton onClick={() => eventBus.dispatch(FormClickEventType.FormDownload)}>
            <Download height={32} />
          </IconButton>
        );
      default:
        return errandContext.formBodyScrollState !== FormBodyScrollStateType.Start ? (
          <>
            <IconButton onClick={() => eventBus.dispatch(FormClickEventType.NavPrev)}>
              <LeftDoubleArrow />
            </IconButton>
          </>
        ) : (
          <>
            <Ballerina6 height={40} className={Styles.iconLeft} />
          </>
        );
    }
  };

  const middleTopButton = (force=false) => {
    // Active handler when EsignActionStateType is Unsigned or Signed

    //for aisign we will always have the go back to chat button.
    if (!force && errand && errand.name && FormTypeDetector(errand.name) === EsignFormType.AISIGN){
      return (
      <IconButton onClick={() => rootContext.leaveFormGoToParentChat(parentId)}>
        <MiddleButtonIcon />
      </IconButton>
      )
    }
    switch (errandContext.esignActionState) {
      case EsignActionStateType.Unsigned:
        return (
          <IconButton onClick={() => eventBus.dispatch(FormClickEventType.FormSign)}>
            <AboutToSign height={32} />
          </IconButton>
        );
      case EsignActionStateType.Signing:
        return <img src={SigningDocument} alt={t('buttonSubmitting')} className={Styles.signing} />;
      case EsignActionStateType.Signed:
        return (
          <StyledDiv className={Styles.signedHands}>
            <SignedDocument height={32} />
          </StyledDiv>
        );
      case EsignActionStateType.Failed:
        return (
          <IconButton onClick={() => eventBus.dispatch(FormClickEventType.FormSign)}>
            <SignFailed height={32} />
          </IconButton>
        );
      default:
        return (
          <StyledDiv className={Styles.signedHands}>
            <SignedDocument height={32} />
          </StyledDiv>
        );
    }
  };

  //if we are at the end, then we want to show submit signed
  //document. Make it say finish. On finish, it will close the errand.
  const renderAISignRightSide = () => {
    const { esignActionState } = errandContext;
    const isAtTheEnd = errandContext.formCurrentPage === errandContext.formTotalPages;
    switch (esignActionState) {
      case isAtTheEnd && EsignActionStateType.Unsigned:
        return <StyledButton className={Styles.greenGuide}>{t('buttonFinish')}{middleTopButton(true)}</StyledButton>;
      case isAtTheEnd && EsignActionStateType.Signing:
        return <StyledButton className={Styles.greenGuide} disabled>{t('buttonSubmitting')}{middleTopButton(true)}</StyledButton>;
      case isAtTheEnd && EsignActionStateType.Signed:
      case isAtTheEnd && EsignActionStateType.Downloading:
        return <StyledButton className={Styles.greenGuide} disabled>{t('buttonSubmitted')}{middleTopButton(true)}</StyledButton>;
      case isAtTheEnd && EsignActionStateType.Failed:
        return <StyledButton>{t('buttonRequestFailed')}{middleTopButton(true)}</StyledButton>;
      default:
        return(
        <StyledButton onClick={() => eventBus.dispatch(FormClickEventType.NavNext)}>
              {t('nextButton')}
        </StyledButton>)
    }
  };
  
  const rightButton = () => {
    switch (errandContext.esignActionState) {
      case EsignActionStateType.Signed:
        return (
          <div className={Styles.buttonWordsRightContainer}>
            <StyledButton
              className={`${Styles.onScreenWords} ${!isAiSign ? Styles.greenGuide : ''}`}
              onClick={() => rootContext.leaveFormGoToParentChat(parentId)}
            >
              {isAiSign ? t('buttonSubmitted') : t('buttonFinish')}
            </StyledButton>
            {isAiSign && errandContext.esignActionState !== EsignActionStateType.Signed ? (renderAISignRightSide()) : (<StyledButton
              className={Styles.offScreenWords}
              onClick={() => rootContext.leaveFormGoToParentChat(parentId)}
            >
              {t('buttonFinishWithoutSign')}
            </StyledButton>)}
          </div>
        );
      case EsignActionStateType.Downloading:
        return (
          <StyledButton onClick={() => rootContext.leaveFormGoToParentChat(parentId)}>{t('buttonFinish')}</StyledButton>
        );
      default:
        return (
          isAiSign ? (
            renderAISignRightSide()
          ) : (<StyledButton
            className={Styles.offScreenWords}
            onClick={() => rootContext.leaveFormGoToParentChat(parentId)}
          >
            {t('buttonFinishWithoutSign')}
          </StyledButton>)
        );
    }
  };

  const rightIcon = () => {
    const isAtTheEnd = errandContext.formCurrentPage === errandContext.formTotalPages;
    switch (errandContext.esignActionState) {
      case EsignActionStateType.Signed:
        return (
          <IconButton className={Styles.iconsContainer} onClick={() => rootContext.leaveFormGoToParentChat(parentId)}>
            {isAiSign ? <SignedDocument color="secondary" height={32} />:<Finish height={32} />}
            <RightDoubleArrow className={Styles.offScreenIcons} />
          </IconButton>
        );
      case EsignActionStateType.Downloading:
        return (
          <IconButton onClick={() => rootContext.leaveFormGoToParentChat(parentId)}>
            <Finish height={32} />
          </IconButton>
        );
      default:
        return (
          !isAtTheEnd && <IconButton onClick={() => eventBus.dispatch(FormClickEventType.NavNext)}>
            <RightDoubleArrow />
          </IconButton>
        );
    }
  };

  return (
    <StyledWrapper className={Styles.morphFormReadyToSendWrapper}>
      <Stack
        className={`${Styles.sideArea} ${Styles.sideLeft} ${
          errandContext.esignActionState === EsignActionStateType.Signed ? Styles.slideDown : null
        }`}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        style={{ flex: 1, position: 'relative' }}
      >
        {leftIcon()}
        {leftButton()}
      </Stack>
      <Stack className={Styles.middleButton}>
        {middleTopButton()}
      </Stack>
      <Stack
        className={`${Styles.sideArea} ${Styles.sideRight} ${
          errandContext.esignActionState === EsignActionStateType.Signed ? Styles.slideDown : null
        }`}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={0}
        style={{ flex: 1, position: 'relative' }}
      >
        {rightButton()}
        {rightIcon()}
      </Stack>
    </StyledWrapper>
  );
};

const MorphFormReadyToSendIndent = (errand, parentId) => {
  const { t } = useTranslation();
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const isAiSign = FormTypeDetector(errand.errand.name) === EsignFormType.AISIGN;
  const rootContext = useContext<IRootContext>(RootContext);

  const handler = (): void => {
    if (isAiSign){
      rootContext.leaveFormGoToParentChat(parentId)
    }
    if (
      errandContext.esignActionState === EsignActionStateType.Unsigned ||
      errandContext.esignActionState === EsignActionStateType.Signed
    ) {
      eventBus.dispatch(FormClickEventType.FormSign);
    }
  };

  const description = (): string => {
    //for aisign we will always have the go back to chat button.
    if (errand && errand.errand && errand.errand.name && isAiSign){
      return t('buttonGoBackToChat')
    }

    switch (errandContext.esignActionState) {      
      case EsignActionStateType.Unsigned:
        return t('buttonSubmitSignedDocument');
      case EsignActionStateType.Signing:
        return t('buttonSubmitting');
      case EsignActionStateType.Signed:
        return t('buttonSubmitted');
      case EsignActionStateType.Downloading:
        return t('buttonSubmitted');
      case EsignActionStateType.Failed:
        return t('buttonRequestFailed');
      default:
        console.error('MorphFormReadyToSend.1');
    }
  };

  if (errandContext.esignActionState === EsignActionStateType.Signed) {
    return <StyledDiv className={Styles.morphFormReadyToSendWrapper}>{description()}</StyledDiv>;
  } else {
    return (
      <div className={Styles.morphFormReadyToSendWrapper}>
        <StyledButton
          fontSize="0.7rem"
          onClick={() => handler()}
          sx={{color: isAiSign ? 'var(--orange900) !important' : ''}}
          className={
            (errandContext.esignActionState === EsignActionStateType.Unsigned ||
              errandContext.esignActionState === EsignActionStateType.Signing) &&
            Styles.greenGuide
          }
        >
          {description()}
        </StyledButton>
      </div>
    );
  }
};

export { MorphFormReadyToSendTopBox, MorphFormReadyToSendIndent };
