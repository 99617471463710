const InViewController = (function () {
    const _ids = [];

    return {
        checkIfExistsAtLeastOneElement() {
            return _ids.length > 0;
        },

        add(_idStr) {
            if (!_ids.includes(_idStr)) {
                _ids.push(_idStr);
            }
        },

        remove(_idStr) {
            const index = _ids.indexOf(_idStr);
            if (index > -1) {
                _ids.splice(index, 1);
            }
        }
    };
})();


export { InViewController };