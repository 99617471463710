// React
import React, { memo, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

// Misc
import { ValidatorFunctions } from '@common/Validators';
import { MeasureElements } from '../../Common/MeasureElementsTools';

// Constants
import {
  CHECK_DELAYED_TIMEOUT,
  DEFAULT_CLASS_LIST,
  HIDDEN_ACTIVITY_CLASS,
  HIDE_ANIMATION_TIMEOUT,
  REVEAL_ANIMATION_TIMEOUT,
  swipeableTrackMouseValue,
  VISIBLE_ACTIVITY_CLASS,
} from './Constants';
import { CONSTANTS } from './Constants';

// UI
import { SingleActivityElementContainer } from './SingleActivityContainer';
import { SingleActivityElement } from './SingleActivity';
import Tracker from '../../Styles/ActivityTrackerStyle';

// Swipable lib
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { useSwipeable } from 'react-swipeable';

// Controllers: Timeout, Delayed.
import { DelayedActivityController, TimeoutController } from './Controllers';

// interfaces/types, initial values
import { ActivityOptionalData, ActivityTrackerProps, ActivityData, ActivityDataAttr, IActivityID } from './interfaces';
import { getInitialArrState } from './Config/States';

// additional handlers for taps/clicks etc.
import { tapHandlers } from './Elements/Handlers';
import { Mappers } from './misc';
import { TrackerFather } from './Trackers/Father';
import { ALL_ELEMENTS } from './Config/Layouts';
import { copyObj } from '@common/userMessagesUtils';

// Make const from let variables to make sure that MAX_WIDTH and VISIBLE_ELEMENT_PADDING_CSS_VALUE cannot be changed in this file.
const { VISIBLE_ELEMENT_PADDING_CSS_VALUE, MAX_WIDTH, ACTIVITY_WRAPPER_HEIGHT, ACTIVITY_FONT_SIZE } = CONSTANTS;

const TrackerUI = (props: {
  getActivityElementDimension: (targetActivity: IActivityID, activityTempData?: ActivityData) => JSX.Element;
  setCurrentActivityIndex: React.Dispatch<React.SetStateAction<number>>;
  getActivityAttr: (idx: number, attr: ActivityDataAttr) => any;
  currentActivityIndexRef: React.MutableRefObject<number>;
  activityArrRef: React.MutableRefObject<ActivityData[]>;
  getNextGreaterIndex: (currIndex: number) => number;
  getNextLesserIndex: (currIndex: number) => number;
  updateCurrActivityIdxRef: (idx: number) => void;
  measureSingleDimensionData: ActivityData;
  currentActivityIndex: number;
  isSearchOpen: boolean;
  children: ReactNode;
}) => {
  // Genreate swipeable handlers for Swipe Parent Component
  const handlers = useSwipeable({
    onSwipedUp: () => {
      props.setCurrentActivityIndex((prevIndex) => {
        const idxToNavigate = props.getNextGreaterIndex(prevIndex);
        props.updateCurrActivityIdxRef(idxToNavigate);
        return idxToNavigate;
      });
    },
    onSwipedDown: () => {
      props.setCurrentActivityIndex((prevIndex) => {
        const idxToNavigate = props.getNextLesserIndex(prevIndex);
        props.updateCurrActivityIdxRef(idxToNavigate);
        return idxToNavigate;
      });
    },
    onTap: (e) => {
      const eventTarget = e.event.target as any;
      let elementHasWrapperClass = eventTarget?.classList?.contains('wrapper') ?? false;
      // check for any custom click/tap handlers of any elements.
      // get handler by activityID
      const handler = tapHandlers[props.getActivityAttr(props.currentActivityIndexRef.current, 'id') as IActivityID];
      // if null, then no handler.
      if (handler) {
        // if handler exists, run it and skip navigation.
        handler();
        return;
      }

      const performNavigation = () => {
        props.setCurrentActivityIndex((prevIndex) => {
          const idxToNavigate = props.getNextGreaterIndex(prevIndex);
          props.updateCurrActivityIdxRef(idxToNavigate);
          return idxToNavigate;
        });
      };

      if (props.getActivityAttr(props.currentActivityIndexRef.current, 'id') === ALL_ELEMENTS.Context) {
        if (elementHasWrapperClass == true) {
          performNavigation();
        }

        return;
      }

      // if event target is an element
      // AND it has a 'wrapper' class, and the clicked el is truly clickable
      if (eventTarget instanceof Element) {
        performNavigation();
      }
    },
    preventScrollOnSwipe: true,
    trackMouse: swipeableTrackMouseValue,
  });

  const trackerContainerProps = {
    activityWrapperHeight: ACTIVITY_WRAPPER_HEIGHT,
    arrayLength: props.activityArrRef.current.length,
    visibleElemPadding: VISIBLE_ELEMENT_PADDING_CSS_VALUE,
    maxWidth: MAX_WIDTH,
    activityFontSize: ACTIVITY_FONT_SIZE,
    isSearchOpen: props.isSearchOpen,
  };

  return (
    <>
      <Tracker
        // Swipe handlers
        {...handlers}
        // All style props that should be passed to container
        {...trackerContainerProps}
      >
        <SwipeableViews
          slideStyle={{ marginLeft: 'auto', width: 'fit-content', overflowX: 'hidden' }}
          style={{
            height: ACTIVITY_WRAPPER_HEIGHT,
          }}
          disabled={true}
          index={props.currentActivityIndex}
          axis="y"
          animateHeight
          animateTransitions
        >
          {props.children}
          {/* For single measurements of separate components */}
          {props.measureSingleDimensionData ? (
            props.getActivityElementDimension(props.measureSingleDimensionData.id, props.measureSingleDimensionData)
          ) : (
            <></>
          )}
        </SwipeableViews>
      </Tracker>
    </>
  );
};

/**
 * This component is imported into the conversation title and is used
 * to provide any chat activity information in form of indicators to the user.
 *
 * TO see what indicators it can show to user, please refer to SingleActivity.tsx element.
 *
 * There are Trackers and Elements
 * Trackers contain SingleActivityContainer which contains SingleActivity which essentially returns one of the Elements to render.
 * This way we have the following structure:
 * ActivityTracker ---> TrackerFather ---> <Current>Tracker ---> SingleActivityContainer ---> (multiple) SingleActivity (based on Layouts.ts configuration for each particular Tracker)
 *
 * TrackerFather contains common logic for elements that should be present in all trackers regardless. (to change common elements, add/remove from Layouts.ts from the desired tracker)
 *
 * Each indicator(SingleActivity Element) hide/show logic is handled in their Tracker in useEffects..
 *
 * Also, this component has a remeasuring logic for all AND any single element (In case of resizing the window and etc.) for fancy animations and their proper work.
 *
 * There are additional controllers present in this component such as DelayedActivityController, AbortController and TimeoutController.
 * For more details on each controller, refer to the file where they are defined and look for comments.
 */

let LOG_LEVEL = "DEFAULT"; // set to "DEBUG" for full logs
const log = (mark, ...args) => LOG_LEVEL === "DEBUG" ? console.log(`÷ ActivityTracker [ ${mark} ]`, ...args) : null;
let mem = new Set();
const logOnce = (mark, ...args) => {
  if(LOG_LEVEL === "DEBUG") {
    if (!mem.has(mark)) {
      console.log(`÷ ActivityTracker [ ${mark} ]`, ...args)
      mem.add(mark);
    }
  }
};

const ActivityTracker = (props: ActivityTrackerProps) => {
  // Main State variables.
  // They have their corresponding ref variables because some of the useEffects/Timeoutes need
  //      the most updated data to avoid stale state values.
  // curr activity index --- used for navigation.

  // If this component is rendered within widget, get specific arr state.
  const INITIAL_ACTIVITY_ARR_STATE = getInitialArrState(props.isWidget);
  logOnce("INITIAL_ACTIVITY_ARR_STATE", getInitialArrState(props.isWidget));
  // Calc indexes once, obj has the following structure
  // {activityID: (index within activityArr) val}
  const INITIAL_INDEXES_OBJ = INITIAL_ACTIVITY_ARR_STATE.reduce((activityIndexesObj, currElement, index) => {
    activityIndexesObj[currElement.id] = index;
    return activityIndexesObj;
  }, {} as { [key in IActivityID]: number });
  logOnce("INITIAL_INDEXES_OBJ", INITIAL_INDEXES_OBJ);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const currentActivityIndexRef = useRef(0);
  // activity arr --- main state variable that contains all UI info of each element.
  const [activityArr, setActivityArr] = useState(INITIAL_ACTIVITY_ARR_STATE);
  const activityArrRef = useRef(INITIAL_ACTIVITY_ARR_STATE);
  const activityIndexes = useRef(INITIAL_INDEXES_OBJ);

  // AbortControllers Tools
  // Used for making sure that none of the on-going/future requests are completed AFTER ActivityTracker component unmounts.
  const AbortControllersRef = useRef<AbortController[]>([]);
  const abortAllRequests = () => {
    for (const abortController of AbortControllersRef.current) {
      abortController.abort();
    }
  };

  // *** DELAYED ACTIVITY CHANGES ***
  const {
    lockActivity,
    unlockActivity,
    isActivityLocked,
    setDelayedValue,
    getDelayedActivitiesDataTuples,
    clearDelayedValue,
    hasDelayedValue,
    increaseChangeCounter,
    getDelayedValue,
  } = DelayedActivityController;

  // Used for handling quick changes in states. (showActivity -> hideActivity -> showActivity) all that happens during 500ms.
  const [checkDelayedChanges, setCheckDelayedChanges] = useState(false);

  const checkDelayedActivityChanges = () => {
    setCheckDelayedChanges(true);
  };
  const uncheckDelayedActivityChanges = () => {
    setCheckDelayedChanges(false);
  };

  /**
   *
   * @param { IActivityID } targetActivity
   * @param { boolean } delayedVal
   * @summary This function applies delayed value for the targetActivity (if false -> hide activity, else reveal it)
   */
  const runDelayedActivityChange = (
    targetActivity: IActivityID,
    delayedVal: boolean,
    delayedWithNavigation: boolean = true,
    delayedOptionalData?: ActivityOptionalData,
    delayedCb?: () => void
  ) => {
    // clear delayed value for given activity.
    clearDelayedValue(targetActivity);

    if (ValidatorFunctions.isNotUndefinedNorNull(delayedVal) && typeof delayedVal === 'boolean') {
      handleActivityChange(targetActivity, delayedVal, delayedWithNavigation, delayedOptionalData, delayedCb);
    }
  };

  /**
   * @summary Main function that goes over all delyed activities values, and processes them,
   * meaning, if delayed activity last received value (isShown) is false -> hide that particular activity, else reveal it.
   */
  const runDelayedActivityChanges = () => {
    const delayedActivitiesData = getDelayedActivitiesDataTuples();

    for (const delayedActivity of delayedActivitiesData) {
      const [activityID, delayedVal] = delayedActivity;
      // check if activity is unlocked, if it is locked, delayed activity change will run after animation is played
      if (isActivityLocked(activityID) === false) {
        const optionalData = delayedVal.optionalData || null;
        if (delayedVal && delayedVal?.lastReceivedValue !== undefined) {
          runDelayedActivityChange(
            activityID,
            delayedVal.lastReceivedValue,
            delayedVal.withNavigation,
            optionalData,
            delayedVal.optionalCallback
          );
        }
      }
    }
    // uncheck delayed changes to finish running them
    uncheckDelayedActivityChanges();
  };
  // *** DELAYED ACTIVITY CHANGES END ***

  // Used for single element dynamic measurements (Actions only for now)
  // if measureSingleDimensionData is not null, the ActivityTracker automatically measures the width
  // of given (ActivityData) which is contained in measureSingleDimensionData and updates the relevant width value
  // in measuredDimensions by ActivityID which as a result updates the UI of the remeasured activity and provides
  // the correct value for width when animation starts playing.
  // ( Currently only Action activity is using this because action activity data is dynamic and can thus have different values for width for each action message in chat )
  const [measureSingleDimensionData, setMeasureSingleDimensionData] = useState<null | ActivityData>(null);

  /**
   *
   * Main UI Element Logic.
   *
   */

  // retrieve index of activity in our activity state array
  const getActivityIndex = useCallback((targetActivity: IActivityID) => {
    return activityIndexes.current[targetActivity];
  }, []);

  // Get only visible activities function
  const getVisibleAcitivityArr = () => {
    return activityArrRef.current.filter((el) => el.isShown === true);
  };

  // get any activity data attribute by index
  const getActivityAttr = useCallback(
    (idx: number, attr: ActivityDataAttr) => {
      return activityArr[idx][attr];
    },
    [activityArr]
  );

  // main function that changes the shown state of a single activity
  /**
   *
   * @param { IActivityID } targetActivity
   * @param { boolean } value
   */
  const setShowActivity = (targetActivity: IActivityID, value: boolean) => {
    setActivityArr((prevActivityArr) => {
      // get activity index
      const activityIndex = getActivityIndex(targetActivity);
      // get copy of activity arr state
      const copy = [...prevActivityArr];
      // change that activity data obj at found activityIndex
      copy[activityIndex] = {
        // copy over whatever activity data we have
        ...copy[activityIndex],
        // set target activity attribute isShown to { value }
        isShown: value,
      };
      // return copied activity state arr
      return copy;
    });
  };

  /**
   *
   * @summary Used for setting an optional data (specifically for 'action' activity element) and
   * can be used for any other element has dynamically changing UI appearance.
   */
  const setOptionalDataActivity = (targetActivity: IActivityID, value: ActivityOptionalData) => {
    setActivityArr((prevActivityArr) => {
      // get activity index
      const activityIndex = getActivityIndex(targetActivity);
      // get copy of activity arr state
      const copy = [...prevActivityArr];
      // change that activity data obj at found activityIndex
      copy[activityIndex] = {
        // copy over whatever activity data we have
        ...copy[activityIndex],
        // set target activity attribute data to value
        data: { ...value },
      };
      // return copied activity state arr
      return copy;
    });
  };

  // reveal animation logic of a single given activity
  /**
   *
   * @param { IActivityID } targetActivity
   */
  const revealActivity = (targetActivity: IActivityID) => {
    log("revealActivity START", targetActivity);
    setActivityArr((prevActivityArr) => {
      const activityIndex = getActivityIndex(targetActivity);
      const copy = [...prevActivityArr];
      // change the activtity at the found activity index
      copy[activityIndex] = {
        ...copy[activityIndex],
        // set default classlist + visible class in order to play reveal animation
        classList: [...DEFAULT_CLASS_LIST, VISIBLE_ACTIVITY_CLASS],
        // set full width to true to make width: fit-content
        fullWidth: true,
      };

      return copy;
    });
  };

  // hide animation of a single given activity
  /**
   *
   * @param { IActivityID } targetActivity
   */
  const hideActivity = (targetActivity: IActivityID) => {
    setActivityArr((prevActivityArr) => {
      const activityIndex = getActivityIndex(targetActivity);
      const copy = [...prevActivityArr];

      copy[activityIndex] = {
        ...copy[activityIndex],
        // set default classlist + hidden class to play hide animation
        classList: [...DEFAULT_CLASS_LIST, HIDDEN_ACTIVITY_CLASS],
        // set to false to make width: 0
        fullWidth: false,
      };

      return copy;
    });
  };

  // Actual function that plays reveal animation of a single activity
  /**
   *
   * @param { IActivityID } targetActivity
   */
  const doRevealAnimationOf = (targetActivity: IActivityID, optionalData?: ActivityOptionalData) => {
    log("doRevealAnimationOf START", targetActivity, optionalData);
    // set activity as shown immidiately to make it navigatable for the user
    setShowActivity(targetActivity, true);

    // if optional Data was passed, set the optional data value to the relevant targetActivity.
    if (optionalData) {
      setOptionalDataActivity(targetActivity, optionalData);
    }

    setTimeout(() => {
      log("doRevealAnimationOf [Timeout] START");
      // reveal after 500ms a given activity
      revealActivity(targetActivity);
      // run delayed activities checker in 500ms when animation is done
      setTimeout(() => {
        // unlock activity changes.
        log("doRevealAnimationOf [Timeout nested] unlockActivity!", targetActivity);
        unlockActivity(targetActivity);
        checkDelayedActivityChanges();
      }, CHECK_DELAYED_TIMEOUT);
    }, REVEAL_ANIMATION_TIMEOUT);
  };

  // Actual function that plays hide animation of a single activity
  /**
   *
   * @param { IActivityID } targetActivity
   */
  const doHideAnimationOf = (targetActivity: IActivityID, optionalData?: ActivityOptionalData) => {
    // play hide animation
    hideActivity(targetActivity);
    // make it after 500ms as not shown to make sure it is not navigatable
    setTimeout(() => {
      setShowActivity(targetActivity, false);
      // set optional Data
      if (optionalData) {
        setOptionalDataActivity(targetActivity, optionalData);
      }
      // unlock activity changes.
      unlockActivity(targetActivity);
      // run delayed activities checker
      checkDelayedActivityChanges();
    }, HIDE_ANIMATION_TIMEOUT);
  };

  const updateCurrActivityIdxRef = useCallback((idx) => {
    currentActivityIndexRef.current = idx;
  }, []);

  // Function that navigates user to a targetActivity
  /**
   *
   * @param { IActivityID } targetActivity
   */
  const navigateToActivity = (targetActivity: IActivityID) => {
    // set delay of 200ms to make sure that the navigation is smooth
    setTimeout(() => {
      // append index and get the needed activity
      const activityToNavigateIdx = getActivityIndex(targetActivity);
      setCurrentActivityIndex(activityToNavigateIdx);
      updateCurrActivityIdxRef(activityToNavigateIdx);
    }, 200);
  };

  // check if the given activity is navigated at the current moment
  /**
   * @param { IActivityID } targetActivity
   */
  const isActivityNavigated = (targetActivity: IActivityID) => {
    // get targetActivity from arr
    return currentActivityIndexRef.current === getActivityIndex(targetActivity);
  };

  // Returns current 'isShown' attr value of given targetActivity.
  const checkIfShown = (targetActivity: IActivityID) => {
    const idx = getActivityIndex(targetActivity);
    return activityArrRef.current[idx]['isShown'] as boolean;
  };

  /**
   *
   * @param { number } currIndex - current Activity Index (can be taken from the parent scope as reference but I pass it here so that it makes more sense)
   * @returns the first next available activity index (LOCATED TO THE RIGHT OF THE CURRENT ACTIVITY INDEX) that the user can navigate to.
   */
  const getNextGreaterIndex = useCallback((currIndex: number) => {
    // Get the [false, true, ...] that indicates if activities[activityIndex] is shown or now.
    const activityArrCopy = [...activityArrRef.current];
    const visibleActivitiesBoolArr = activityArrRef.current.map((el) => el.isShown);
    const numberOfVisibleActivities = visibleActivitiesBoolArr.filter((el) => el === true).length;

    // Check if there is only one activity present in the activity tracker
    // if so return the same index
    if (numberOfVisibleActivities === 1) {
      return currIndex;
    }

    // Slice from curr index to get next available ones.
    // map to booleans (isShown) and filter only those that are true
    // -> get the length of res arr -> if its > 0 it means that there is some next activity that is avalable
    const isThereAnyNextShownActivity =
      activityArrCopy
        .slice(currIndex + 1)
        .map((el) => el.isShown)
        .filter((isShown) => isShown === true).length > 0;

    // Return the first visible element if there are no next shown activity
    if (isThereAnyNextShownActivity === false) {
      const firstVisibleElementIndex = visibleActivitiesBoolArr.indexOf(true);
      return firstVisibleElementIndex;
    }

    // If there is some next activity that is navigatable
    // map to resulting arr structure: [{idx: 0, isShown: false}, {idx: 1, isShown: true}, ...]
    let mappedActivityCopyArr = activityArrCopy.map((el, idx) => {
      return { idx: idx, isShown: el.isShown };
    });

    // Slice the needed ones after the current index element and filter only shown ones
    mappedActivityCopyArr = mappedActivityCopyArr.slice(currIndex + 1).filter((el) => el.isShown === true);

    // Map to arr of indexes of shown elements
    // resulting structure: [0, 3, ...]
    const shownIndexesArr = mappedActivityCopyArr.map((el) => el.idx);

    // If its not empty, return the first index in this arr
    if (shownIndexesArr.length > 0) {
      return shownIndexesArr[0];
    }

    // Return the same activity index if we don't find any activity that is navigatable
    return currIndex;
  }, []);

  /**
   *
   * @param {number} currIndex - current Activity Index (can be taken from the parent scope as reference but I pass it here so that it makes more sense)
   * @returns the first next available activity index (LOCATED TO THE LEFT OF THE CURRENT ACTIVITY INDEX) that the user can navigate to.
   */
  const getNextLesserIndex = useCallback((currIndex: number) => {
    const numberOfShownActivities = activityArrRef.current.map((el) => el.isShown).filter((el) => el === true).length;
    const activityArrCopy = [...activityArrRef.current];

    // Check if there is only one activity present in the activity tracker
    // if so return the same index
    if (numberOfShownActivities === 1) {
      return currIndex;
    }

    // check if there is any available activity before our current activity index
    // slice from 0 - currIndex ---> map to bool array (isShown) ---> filter only true ---> get length ---> if that's > 0, it means that there is some previous isShown activity
    const isThereAnyPreviousShownActivity =
      activityArrCopy
        .slice(0, currIndex)
        .map((el) => el.isShown)
        .filter((el) => el === true).length > 0;
    // if there is 0 number of previous shown activities
    if (isThereAnyPreviousShownActivity === false) {
      // see what is first available shown activity from the end
      // map to append indexes ---> slice from currIndex to the end ---> reverse the mapped arr ---> filter only shown ones ---> get the first index from resulting arr.
      const indexOfFirstShownActivityFromTheEnd = activityArrCopy
        .map(Mappers.appendIndex)
        .slice(currIndex + 1)
        .reverse()
        .filter((el) => el.isShown === true)[0].idx;
      return indexOfFirstShownActivityFromTheEnd;
    }

    // if there is something before current activity index
    // [0] will be the first available activity from the curr shown activity to the left.
    // map to add indexes ---> slice from 0 to curr (all prev elements) ---> filter only shown ones ---> reverse ---> get the first one and get its index (that we mapped via Mappers.appendIndex)
    const indexOfFirstLesserActivity = activityArrCopy
      .map(Mappers.appendIndex)
      .slice(0, currIndex)
      .filter((el) => el.isShown === true)
      .reverse()[0].idx;
    return indexOfFirstLesserActivity;
  }, []);

  // Main function that takes care of incoming activity to make it appear and being able to navigate to it
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleIncomingActivity = (incomingActivityID, withNavigation, optionalData) => {
    log('handleIncomingActivity START', incomingActivityID, withNavigation, optionalData);
    const isIncomingActivityAlreadyShown = checkIfShown(incomingActivityID);
    log("isIncomingActivityAlreadyShown", isIncomingActivityAlreadyShown);
    // if the incoming activity is already present and shown, do nothing
    if (isIncomingActivityAlreadyShown === true) {
      // if optionalData is present, it can be coming from either delayed change OR curr.
      // only run if delayed value is present, which means that it runs after delay.
      if (optionalData) {
        setOptionalDataActivity(incomingActivityID, optionalData);
      }
      unlockActivity(incomingActivityID);
      clearDelayedValue(incomingActivityID);
      return;
    }
    if (withNavigation) {
      // else proceed to navigate to it and reveal it
      // navigate visually to the new activity
      navigateToActivity(incomingActivityID);
    }
    // do animation of reveal
    doRevealAnimationOf(incomingActivityID, optionalData);
  };

  // Main function that takes care of removing an activity to make it not visible and not navigatable
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRemovingActivity = (
    activityToRemoveID,
    withNavigation: boolean = true,
    optionalData?: ActivityOptionalData
  ) => {
    const activityToRemoveIsAlreadyRemoved = checkIfShown(activityToRemoveID) === false;
    if (activityToRemoveIsAlreadyRemoved === true) {
      unlockActivity(activityToRemoveID);
      clearDelayedValue(activityToRemoveID);
      return;
    }

    doHideAnimationOf(activityToRemoveID, optionalData);
    const moreThanOneActivityShown = getVisibleAcitivityArr().length > 1;
    // if there was some activities before this removing one
    if (moreThanOneActivityShown === true && withNavigation) {
      // if this activity is currently navigated
      if (isActivityNavigated(activityToRemoveID) === true) {
        const shownActivitiesWithIndexes = [...activityArrRef.current]
          .map(Mappers.appendIndex)
          .filter((el) => el.isShown === true);
        // if we are at the last navigated activity
        if (getActivityIndex(activityToRemoveID) === [...shownActivitiesWithIndexes].reverse()[0].idx) {
          const nextLesserIndex = getNextLesserIndex(currentActivityIndexRef.current);
          navigateToActivity(activityArrRef.current[nextLesserIndex].id);
        }
        // if we are at the first navigated activity
        else if (getActivityIndex(activityToRemoveID) === shownActivitiesWithIndexes[0].idx) {
          const nextGreaterIndex = getNextGreaterIndex(currentActivityIndexRef.current);
          navigateToActivity(activityArrRef.current[nextGreaterIndex].id);
        } else {
          // we are somewhere in the middle
          navigateToActivity(activityArrRef.current[getNextLesserIndex(currentActivityIndexRef.current)].id);
        }
      }
    }
  };

  /**
   *
   * @param { IActivityID } targetActivity
   * @param { boolean } shouldBeShown
   * @summary This function controls when to HIDE/REVEAL activity based on recieved value <shouldBeShown> for it as well as takes care of locking it from the other future mutations.
   * (these mutations are saved and played after the animation is finished)
   */
  const handleActivityChange = (
    targetActivity: IActivityID,
    shouldBeShown: boolean,
    withNavigation: boolean = true,
    optionalData?: ActivityOptionalData,
    optionalCallback?: () => void
  ) => {
    log('handleActivityChange',
      targetActivity,
      shouldBeShown,
      withNavigation,
      optionalData,
      optionalCallback
    );
    // check activity to be unlocked
    // AUTH elements is an edge case due to the fact that after authentication process is completed, the props are being
    // updated a lot and it causes the lock/unlock functionality to basically fail.
    if (isActivityLocked(targetActivity) === true && targetActivity !== ALL_ELEMENTS.Auth) {
      log('handleActivityChange: Activity Is Locked');
      // locked
      // can be already true or false, check if it has some val
      // if it has already some val and its not the same as incoming delayed value (shouldBeShown)
      if (
        hasDelayedValue(targetActivity) === true &&
        getDelayedValue(targetActivity).lastReceivedValue !== shouldBeShown
      ) {
        // increase change counter
        increaseChangeCounter();
      }
      // set last recieved delayed value even if it already has some
      setDelayedValue(targetActivity, shouldBeShown, withNavigation, optionalData, optionalCallback);
    } else {
      log('handleActivityChange: Not Locked');
      // unlocked, so lock it first
      lockActivity(targetActivity);
      if (shouldBeShown === true) {
        log('handleActivityChange: calling handleIncomingActivity()!');
        handleIncomingActivity(targetActivity, withNavigation, optionalData);
      } else {
        log('handleActivityChange: calling handleRemovingActivity()!');
        handleRemovingActivity(targetActivity, withNavigation, optionalData);
      }
    }
  };

  // Sync activityArr with ref because of the same reason mentioned in above useEffect.
  useEffect(() => {
    activityArrRef.current = activityArr;
    log('(activityArr)', copyObj(activityArr));
  }, [activityArr]);

  /**
   * This handles running delayed activity changes. (values that has been recieved during the on-going animation)
   */
  useEffect(() => {
    if (checkDelayedChanges === true) {
      // run checks and uncheck in those runs
      // check if still locked
      runDelayedActivityChanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDelayedChanges]);

  // Component did unmount hook
  useEffect(() => {
    return () => {
      log('UNMOUNT')
      DelayedActivityController.onUnmountHandler();
      TimeoutController.onUnmountHandler();
      abortAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Form swipeable view elements, returns React.JSXElement[]
  // Components are rendered in order they are inserted in renderElements and it should be the same order as above in INITIAL_ACTIVITY_ARR_STATE
  const formActivityTrackerElements = () => {
    // iterate through INITIAL_ACTIVITY_ARR_STATE in order and map it to elements to be rendered
    const renderElements = activityArr.map(({ id }, _, iterArr) => {
      // get all activity data first
      const activityData = iterArr[getActivityIndex(id)];
      const activityStyle = getElementStyleObject(id);
      // push to render arr
      return (
        <SingleActivityElementContainer
          wereDimensionsMeasured={true}
          activityStyle={activityStyle}
          activityData={activityData}
          key={id}
        >
          <SingleActivityElement
            activityData={activityData}
            targetActivity={id}
            // Used for triggering a remeasure of an action component
            setMeasureSingleDimensionData={setMeasureSingleDimensionData}
            remeasureElement={remeasureElement}
            // Default
            errand={props.errand}
            operatorData={props.operatorData}
          />
        </SingleActivityElementContainer>
      );
    });

    return renderElements;
  };

  // Measure/Style helpers
  const getMeasuredWidthOf = (componentID, addon = 0) => {
    // exclusion overrides of measured width for particular components.
    if (componentID === ALL_ELEMENTS.Context) return MAX_WIDTH;

    if (ValidatorFunctions.isNotUndefinedNorNull(props.measuredDimensions[componentID])) {
      return props.measuredDimensions[componentID].width + addon;
    } else {
      console.warn(`${componentID} width is not present on measuredDimensions object. falling back to MAX_WIDTH`);
      return MAX_WIDTH;
    }
  };

  // extract css style object from activity state arr.
  const getElementStyleObject = (componentID: IActivityID) => {
    const activityIndex = getActivityIndex(componentID);
    const isFullWidth = getActivityAttr(activityIndex, 'fullWidth');
    // additionalStyles is declared in InitialValues.ts
    // this can also be an empty object if no additional style value is needed for curr component
    const additionalStyles = getActivityAttr(activityIndex, 'additionalStyles') as Record<string, any> | {};
    const paddingAddon = Math.floor(VISIBLE_ELEMENT_PADDING_CSS_VALUE * 2);
    const widthStyleVal = `${isFullWidth ? getMeasuredWidthOf(componentID, paddingAddon) : '0'}px`;
    const isCurrentlyNavigated = activityIndex === currentActivityIndex;

    return {
      width: widthStyleVal,
      // Set visibility to fix weird overflow issue
      visibility: isCurrentlyNavigated ? 'visible' : 'hidden',
      ...additionalStyles,
    };
  };

  // Measuring Dimension of a single Activity tools.
  const singleMeasureTakenHandler = (measuresData) => {
    // append already existing dimensions
    props.setMeasuredDimensions((prev) => {
      return {
        ...prev,
        ...measuresData,
      };
    });
    setMeasureSingleDimensionData(null);
  };

  const getActivityElementDimension = (targetActivity: IActivityID, activityTempData?: ActivityData) => {
    const elementsToMeasure = [
      <SingleActivityElementContainer wereDimensionsMeasured={false} activityStyle={{}} activityData={activityTempData}>
        <SingleActivityElement activityData={activityTempData} targetActivity={targetActivity} {...props} />
      </SingleActivityElementContainer>,
    ];

    return <MeasureElements targetElements={elementsToMeasure} onCompletedHandler={singleMeasureTakenHandler} />;
  };

  const remeasureElement = (givenData: ActivityData) => {
    // this check is needed because in measuring single component logic
    // setMeasureSingleDimensionData is not passed to children SingleElement.
    // remeasure the action element to update to correct width.
    const activityTempData: ActivityData = {
      ...givenData,
    };

    setMeasureSingleDimensionData(activityTempData);
  };

  return (
    <TrackerFather
      {...props}
      UI={
        <TrackerUI
          activityArrRef={activityArrRef}
          updateCurrActivityIdxRef={updateCurrActivityIdxRef}
          currentActivityIndexRef={currentActivityIndexRef}
          getActivityElementDimension={getActivityElementDimension}
          measureSingleDimensionData={measureSingleDimensionData}
          setCurrentActivityIndex={setCurrentActivityIndex}
          currentActivityIndex={currentActivityIndex}
          getNextGreaterIndex={getNextGreaterIndex}
          getNextLesserIndex={getNextLesserIndex}
          getActivityAttr={getActivityAttr}
          isSearchOpen={props.isSearchOpen}
        >
          {formActivityTrackerElements()}
        </TrackerUI>
      }
      activityArrRef={activityArrRef}
      currentActivityIndex={currentActivityIndex}
      AbortControllersRef={AbortControllersRef}
      handleActivityChange={handleActivityChange}
      checkIfShown={checkIfShown}
      getActivityIndex={getActivityIndex}
    />
  );
};
export default ActivityTracker;
