import React from 'react';
import themeFile from '@assets/Themes/default.json';
import { styled } from '@mui/system';

function MessageBadgeSkeleton() {

    const UserBox = styled('div')({
        height: '100%',
        width: '100%',
    })

    const BadgeContainer = styled('div')({
        display: 'flex',
        alignItems: 'start',
        height: '50%', //100%
        width: '100%',
        overflow: 'hidden',
        padding: '5px',
    })

    const PlaceholderBadge = styled('div')({
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        minHeight: '110px',
        width: '100%',
        backgroundColor: 'var(--gray000)',
        borderRadius: '8px'
    })

    // The left side holds the circle profile icon
    const BadgeLeft = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        backgroundColor: 'var(--gray000)',
        height: '110px',
        width: '89px',
        borderRadius: '8px'
    })

    // This is the skeleton for the circle profile icon
    const CircleIconSkeleton = styled('div')({
        minHeight: '30px',
        minWidth: '30px',
        backgroundColor: themeFile.palette.gray['100'],
        borderRadius: '50%',
        margin: '16px 2px 0px 15px'

    })

    // This is the skeleton for the user's name
    const NameSkeleton = styled('div')({
        height: '12px',
        width: '70%',
        borderRadius: '8px',
        backgroundColor: themeFile.palette.gray['100'],
        marginTop: '17px',
        marginLeft: '15px'
    })

    // This is the skeleton for message
    const MessageSkeleton = styled('div')({
        height: '10px',
        width: '85%',
        borderRadius: '8px',
        backgroundColor: themeFile.palette.gray['100'],
        marginTop: '8px',
        marginLeft: '15px'
    })


    // The right side holds the date and time
    const BadgeRight = styled('div')({
        display: 'flex',
        alignItems: 'end',
        flexDirection: 'column',
        justifyContent: 'start',
        backgroundColor: 'var(--gray000)',
        height: '100px',
        width: '85px',
    })

    //This is the skeleton for the date
    const DateSkeleton = styled('div')({
        height: '15px',
        width: '100%',
        borderRadius: '8px',
        backgroundColor: themeFile.palette.gray['100'],
        marginTop: '27px',
        position: 'relative',
        right: '12px'
    })

    return (
        <UserBox>
        <BadgeContainer>
            <PlaceholderBadge>
                <BadgeLeft>
                    <CircleIconSkeleton />
                    <NameSkeleton />
                    <MessageSkeleton />    
                </BadgeLeft>
                <BadgeRight>
                    <DateSkeleton />
                </BadgeRight>
            </PlaceholderBadge>
        </BadgeContainer>
        </UserBox>
    )
}

export default MessageBadgeSkeleton
