import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

import { IErrand } from '@interfaces/Conversation';
import Sanitized from '@components/Sanitized';

import Styles from '@styles/MorphEdit.module.css';
import { useTranslation } from 'react-i18next';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import { isMobile } from '@common/deviceTypeHelper';
import { ValidatorFunctions } from '@common/Validators';

type TMorphEditProps = {
  errand: IErrand;
  cancelAction: (key: any, clear: boolean) => void;
};

const initStyleObj: { cancelBtn: CSSProperties; content: CSSProperties } = {
  cancelBtn: {
    opacity: '0',
  },
  content: {},
};

const _floor = (num) => Math.floor(num);

const MorphEdit = (props: TMorphEditProps) => {
  const { t } = useTranslation();
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const cancelBtnRef = useRef<HTMLDivElement | null>(null);
  const [stylesObj, setStylesObj] = useState(initStyleObj);

  // Takes care of proper dimension calculations for cancelBtn top css style value
  // and original text content dimension as well.
  // Runs once
  useEffect(() => {
    if (sectionRef.current === null || cancelBtnRef.current === null) return;
    const sectionDimensions = sectionRef.current.getBoundingClientRect();
    const cancelBtnDimensions = cancelBtnRef.current.getBoundingClientRect();
    const rightPaddingSpace = isMobile() ? 70 : 80;

    // calc cancel btn top and right vals
    const cancelBtnTopVal = _floor(sectionDimensions.height / 2) - _floor(cancelBtnDimensions.height / 2);
    const cancelBtnRightVal = 8;

    // calc max width in pixels for content
    const contentMaxWidthVal = sectionDimensions.width - (cancelBtnDimensions.width + 10) - rightPaddingSpace;

    setStylesObj({
      cancelBtn: {
        top: `${cancelBtnTopVal}px`,
        right: `${cancelBtnRightVal}px`,
        opacity: '1',
      },
      content: {
        maxWidth: `${contentMaxWidthVal}px`,
      },
    });
  }, []);

  const getOriginalText = () => {
    const idxOfCurrErrand = rootContext.errands.findIndex((e) => e._id === props.errand._id);
    const messages = rootContext.errands[idxOfCurrErrand].messages || [];
    const currMessage = messages.find((m) => m._id === errandContext.editMessageId) || { message: '', editedMessageData: { second: '' } };
    const editMessageText = currMessage.message || t('MorphEdit_NoText');
    const [firstSection, secondSection] = editMessageText?.split(`<i class="messageArrow"/>`);

    // if it has editedMessageData
    // (edge case for the edited Field message that has QID type of answer)
    if (ValidatorFunctions.isNotUndefinedNorNull(currMessage.editedMessageData) === true) {
      return currMessage.editedMessageData?.second ?? secondSection;
    }
    // if there any are previous message present, return the newer one.
    if (firstSection && secondSection) {
      return secondSection;
    } else {
      return firstSection;
    }
  };

  const onCancelClickHandler = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      props.cancelAction(undefined, true);
    },
    [props.cancelAction]
  );

  return (
    <section className={Styles.section} ref={sectionRef}>
      {/* TOP TEXT */}
      <div className={Styles.content} style={stylesObj.content}>
        <Sanitized html={getOriginalText()} />
      </div>
      {/* MIDDLE TEXT */}
      <p className={Styles.placeholder}>{t('MorphEdit_OriginalTextLabel')}</p>
      {/* translate */}
      {/* TAB RING */}
      <div className={Styles.tabRing}>
        <div className={Styles.tabBackground}>
          <EditIcon />
        </div>
      </div>
      {/* CANCEL BUTTON */}
      <div
        onTouchEnd={onCancelClickHandler}
        className={Styles.cancelButton}
        onClick={onCancelClickHandler}
        style={stylesObj.cancelBtn}
        ref={cancelBtnRef}
      >
        <CloseIcon />
      </div>
    </section>
  );
};

export default MorphEdit;
