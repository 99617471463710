import { Button, ButtonGroup } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useState, useEffect, useCallback } from 'react';
import ESign from './ESign';
import { EsignSignatureType, ElectronicSignatureEventType, FormClickEventType } from '../Forms/commonForms';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import useInitialMount from '@common/hooks/useInitialMount';
import eventBus from '../Common/eventBus';
import { useTranslation } from 'react-i18next';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import useDebounce from '@common/hooks/useDebounce';
import { morphIndentType } from '@common/MorphType';
import Styles from '@styles/MorphFormInsertSignature.module.css';
import PulseStyle from '@styles/ChatBubbleAnimations.module.css';

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'var(--gray000)',
  border: '1px solid var(--peach800)',
  borderRadius: '8px',
  position: 'relative',
});

const StyledButton = styled(Button)<any>(() => ({
  textTransform: 'none',
  width: '100%',
  height: '50px',
  color: 'black',
  borderRadius: '8px',
}));

const btnGroupWidth = 80;

const MorphFormInsertSignatureTopBox = ({ indentType, setIndentType }) => {
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const windowDimensions = useWindowDimensions();
  const { t } = useTranslation();

  const [saveCounter, setSaveCounter] = useState(0);
  const [, setForceRenderCounter] = useState(0);

  const canvasBoundaryRef = errandContext.canvasBoundaryRef;
  const signatureTypeRef = errandContext.signatureTypeRef;

  const openCreateSignatureMobile = () => {
    errandContext.newFormEvent(ElectronicSignatureEventType.OpenCreateSignatureMobile);
    rootContext.setCreateSignatureMobileIsOn(true);
    errandContext.setMobileIndentType(morphIndentType.FormGenerate);
  };

  const handleRotation = () => {
    if (window.innerHeight < window.innerWidth && isMobileOrTablet()) {
      openCreateSignatureMobile();
    }
  };

  useDebounce(() => handleRotation(), 200, [windowDimensions]);

  const ButtonCommonHandler = () => {
    // Force re-render
    setForceRenderCounter((prev) => ++prev);

    if (canvasBoundaryRef.current[signatureTypeRef.current].x.min !== Infinity) {
      setIndentType(morphIndentType.FormClickToClear);
    } else {
      setIndentType(morphIndentType.FormGenerate);
    }
  };

  const SignatureButtonHandler = () => {
    if (signatureTypeRef.current !== EsignSignatureType.Signature) {
      // Here we do tab change (Initials ---> Signature)
      signatureTypeRef.current = EsignSignatureType.Signature;
      ButtonCommonHandler();
    } else {
      // Tab is already selected so we will perform save operation
      setSaveCounter((prev) => ++prev);
    }
  };

  const InitialsButtonHandler = () => {
    if (signatureTypeRef.current !== EsignSignatureType.Initials) {
      // Here we do tab change (Signature ---> Initials)
      signatureTypeRef.current = EsignSignatureType.Initials;
      ButtonCommonHandler();
    } else {
      // Tab is already selected so we will perform save operation
      setSaveCounter((prev) => ++prev);
    }
  };

  const buttonIconWrapper = (type: EsignSignatureType) => {
    if (type === EsignSignatureType.Signature) {
      if (errandContext.wetSignature) {
        return (
          <img
            alt={`${t('tUser')} ${t('tSignature')}`}
            src={errandContext.wetSignature}
            style={{ position: 'relative', height: '100%', width: '100%' }}
          />
        );
      }

      if (signatureTypeRef.current === EsignSignatureType.Signature && indentType === morphIndentType.FormClickToClear) {
        return t('saveButton');
      } else {
        return t('tSignature');
      }
    } else if (type === EsignSignatureType.Initials) {
      if (errandContext.wetInitial) {
        return (
          <img
            alt={`${t('tUser')} ${t('tInitials')}`}
            src={errandContext.wetInitial}
            style={{ position: 'relative', height: '100%', width: '100%' }}
          />
        );
      }
      if (signatureTypeRef.current === EsignSignatureType.Initials && indentType === morphIndentType.FormClickToClear) {
        return t('saveButton');
      } else {
        return t('tInitials');
      }
    } else {
      console.error('wrong EsignSignatureType');
    }
  };

  // Component init
  const syncSignStateToIndentButtonState = useCallback(() => {
    if (errandContext.wetSignature !== undefined) {
      setIndentType(morphIndentType.FormClickToClear);
    } else {
      setIndentType(morphIndentType.FormGenerate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInitialMount(syncSignStateToIndentButtonState);

  return (
    <StyledWrapper>
      {/* 10/25/23 -Icy: Commenting out the maximize icon as it would allow the user to rotate their screen to 
      the horizontal mobile sign view even when their device has auto-rotate disabled. Removing this will only 
      allow the user to rotate the view only if they can actually physically rotate their phone. */}
      {/* {isMobileOrTablet() && (
        <IconButton sx={{ position: 'absolute', right: `${btnGroupWidth}px`, top: 0, zIndex: '1' }}>
          <Maximize onClick={openCreateSignatureMobile} />
        </IconButton>
      )} */}
      <ESign
        saveCounter={saveCounter}
        btnGroupWidth={btnGroupWidth}
        setIndentType={setIndentType}
      />
      <ButtonGroup
        className={Styles.morphFormButtonGroup}
        orientation="vertical"
        sx={{ position: 'absolute', maxWidth: `${btnGroupWidth}px`, minWidth: `${btnGroupWidth}px`, right: '0' }}
      >
        <StyledButton
          onClick={SignatureButtonHandler}
          className={`${
            signatureTypeRef.current === EsignSignatureType.Signature
              ? Styles.signatureButtonPressed
              : Styles.signatureButton
          }
          ${errandContext.wetSignature && Styles.greenAndDone}
            ${
              indentType === morphIndentType.FormClickToClear &&
              signatureTypeRef.current === EsignSignatureType.Signature &&
              !(errandContext.wetInitial && errandContext.wetSignature)
                ? Styles.green
                : null
            }
              `}
        >
          {buttonIconWrapper(EsignSignatureType.Signature)}
        </StyledButton>
        <StyledButton
          onClick={InitialsButtonHandler}
          className={`${
            signatureTypeRef.current === EsignSignatureType.Initials ? Styles.initialsButtonPressed : Styles.initialsButton
          }
          ${errandContext.wetInitial && Styles.greenAndDone}
            ${
              indentType === morphIndentType.FormClickToClear &&
              signatureTypeRef.current === EsignSignatureType.Initials &&
              !(errandContext.wetInitial && errandContext.wetSignature)
                ? Styles.green
                : null
            }
            `}
        >
          {buttonIconWrapper(EsignSignatureType.Initials)}
        </StyledButton>
      </ButtonGroup>
    </StyledWrapper>
  );
};

const MorphFormInsertSignatureIndent = ({ indentType, setIndentType }) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const handler = (): void => {
    if (indentType === morphIndentType.FormClickToClear) {
      eventBus.dispatch(FormClickEventType.SigClear);
      setIndentType(morphIndentType.FormGenerate);
    } else if (indentType === morphIndentType.FormGenerate) {
      eventBus.dispatch(FormClickEventType.SigAutoGen);
      setIndentType(morphIndentType.FormClickToClear);
    }
  };

  //if the user changed their name, change the indent type to offer re-generation of the signature.
  useEffect(() => {
    if (errandContext?.footerInputRef?.current?.value){
      setIndentType(morphIndentType.FormGenerate);
    }
    //using this goes against react principles but it seems to just work.
  },[errandContext?.footerInputRef?.current?.value])

  const description = (): string => {
    if (indentType === morphIndentType.FormClickToClear) {
      return t('buttonClear');
    } else if (indentType === morphIndentType.FormGenerate) {
      return t('generateSignature');
    }
  };

  return (
    <Button
      className={indentType === morphIndentType.FormGenerate ? PulseStyle.pulsing : ''}
      variant="text"
      size="small"
      style={{
        minHeight: '15px',
        maxHeight: '15px',
        textTransform: 'none',
        fontSize: '0.7rem',
        color: 'var(--gray000)',
        background: 'var(--orange500)',
        padding: '10px',
        top: '2px',
      }}
      onClick={() => handler()}
    >
      {description()}
    </Button>
  );
};
export { MorphFormInsertSignatureTopBox, MorphFormInsertSignatureIndent };
