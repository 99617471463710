import React, { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';
import { useTranslation } from 'react-i18next';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import Styles from '@styles/MessageContent/BlockchainDownloadAppMessageContent.module.css';
import tStyles from '@styles/Typography.module.css';
import Allign from '@styles/Allign';
import MessageTextStyle from '@styles/MessageTextStyle';
import Sanitized from '@components/Sanitized';
import axiosCall from '@services/axios';
import ThinClientUtils from '@common/ThinClientUtils';
import { AccessType } from '@common/AccessType';

const AndroidDownloadUrl =
  'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.swmc.morganthinclientandroid';
const IosDownloadUrl = 'https://apps.apple.com/us/app/angelai/id1667401139';
const QRCodeBackgroundGif = process.env.REACT_APP_MORGAN_CDN + '/Images/QRCodeGradient.gif';
const AppStoreIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/AppStoreLogo.svg';
const GooglePlayIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/GooglePlayLogo.svg';
const IosText = 'iOS';
const AndroidText = 'Android';

export default function BlockchainDownloadAppMessageContent({ message }) {
  const { t } = useTranslation();

  let isAndroid = false;
  let isIos = false;
  // Use UAParser to detect OS and navigate user to the right storefront
  const userDetails = UAParser();
  if (userDetails.os.name === 'Android') {
    isAndroid = true;
  } else if (userDetails.os.name === 'iOS') {
    isIos = true;
  }
  const isDesktop = !isIos && !isAndroid;

  if (!message) {
    console.log('message is not defined');
    return <></>;
  }

  if (ThinClientUtils.isThinClient()) {
    console.log('in thin client, not displaying the message');
    return <></>;
  }

  function handleClick() {
    if (isAndroid) {
      window.open(AndroidDownloadUrl, '_blank');
    } else if (isIos) {
      window.open(IosDownloadUrl, '_blank');
    }
  }

  function handleIosClick() {
    window.open(IosDownloadUrl, '_blank');
  }

  function handleAndroidClick() {
    window.open(AndroidDownloadUrl, '_blank');
  }

  return (
    <Allign
      sx={{
        display: 'flex',
        width: 'fit-content',
        flexDirection: 'column',
        maxWidth: 'min(90%, calc(100vw - 50px))',
        position: 'relative',
        alignItems:
          // On the user side, Team, and Group chats: only sender is on the right side
          // On the operator side, only sender (operator) and Morgan is on the right side
          message?.alignByCurrentUser ? 'flex-end' : 'flex-start',
      }}
    >
      <ChatBubbleStyle
        sx={{
          pointerEvents: 'none',
          width: 'fit-content',
          maxWidth: '100%',
          borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
          background:
            message.accessType === AccessType.internal
              ? 'var(--peach020)'
              : message.sentByCurrentUser
              ? 'var(--gray000)'
              : 'var(--peach600)',
        }}
      >
        <div className={Styles.messageContainer}>
          {/* Message Text */}
          <div className={Styles.textWrapper}>
            <MessageTextStyle>{message.message}</MessageTextStyle>
          </div>
          {/* Buttons */}
          <div
            style={{ backgroundImage: `url(${QRCodeBackgroundGif})` }}
            className={Styles.buttonsSectionContainer}
            onClick={handleClick}
          >
            <MessageTextStyle className={Styles.downloadNow}>{t('downloadNow')}</MessageTextStyle>
            <div className={Styles.buttonsContainer}>
              {(isDesktop || (!isDesktop && isIos)) && (
                <div className={Styles.link} onClick={handleIosClick}>
                  <img src={AppStoreIcon} alt="App Store link" />
                  {isDesktop && <MessageTextStyle>{IosText}</MessageTextStyle>}
                </div>
              )}
              {isDesktop && <div className={Styles.separator}></div>}
              {(isDesktop || (!isDesktop && isAndroid)) && (
                <div className={Styles.link} onClick={handleAndroidClick}>
                  <img src={GooglePlayIcon} alt="Google Play link" />
                  {isDesktop && <MessageTextStyle>{AndroidText}</MessageTextStyle>}
                </div>
              )}
            </div>
          </div>
        </div>
      </ChatBubbleStyle>
    </Allign>
  );
}
