import { ValidatorFunctions } from '@common/Validators';
import { TTranslationsData, TWorkflowDataFetched_UPmenu, TWorkflowData_UPmenu } from './types';

// contains the names of the workflows in the desired order to be shown in user prompts menu component
const cleanNamesOrderArr = [
  'New User',
  'Sign In',
  'Workshop Registration',
  'Get Mortgage Approval',
  'Calculator',
  'Calculator',
  'Calculator',
  'Calculator',
  'Invite Friend',
  'Free T-shirts',
  'Influencer Sign Up',
  'Get Open Conditions',
].map(cleanString);

function cleanString(inputString) {
  // Remove spaces and special characters using regex, and convert to lowercase
  const resultString = inputString
    .replace(/[^\w\s]/gi, '')
    .replace(/\s/g, '')
    .toLowerCase();

  return resultString;
}

export function isFirstDecimalDigitGreaterThan(number: number, compareVal: number): boolean {
  try {
    // Convert the number to a string to access the first decimal digit
    const numberStr = new Number(number).toString();
    // does not have .
    if (numberStr.includes('.') === false) {
      return false;
    }
    const numberStrRightSide = numberStr.split('.')[1];
    if (ValidatorFunctions.isUndefinedOrNull(numberStrRightSide) === true) {
      return false;
    }
    // has . in str
    let digitRightSideStr = new String(numberStrRightSide);
    // in case if no right side OR 0 or not string
    if (
      ValidatorFunctions.isUndefinedOrNull(digitRightSideStr) === true ||
      ValidatorFunctions.isTypeOfString(digitRightSideStr) === false
    ) {
      return false;
    }
    digitRightSideStr = digitRightSideStr[0];

    const firstDecimalDigitNumber = parseInt(digitRightSideStr.toString(), 10);
    if (isNaN(firstDecimalDigitNumber) === true) {
      return false;
    }
    // Check if the first decimal digit is greater than given compareVal
    return firstDecimalDigitNumber > compareVal;
  } catch (err) {
    console.error(
      `Error occured during isFirstDecimalDigitGreaterThan execution! Arguments: number is ${number}, compareVal is ${compareVal}`,
      err
    );
    return false;
  }
}

// returns new array with reordered parsed workflows
const reorderWorkflows = (workflows: TWorkflowData_UPmenu[]): TWorkflowData_UPmenu[] => {
  // return same if not arr OR empty OR one el
  if (Array.isArray(workflows) === false || workflows.length <= 1) return workflows;

  const orderedWorkflowList = [];
  const cleanNamesOrderArrCopy = [...cleanNamesOrderArr];
  const workflowsArrCopy = [...workflows];
  const workflowsCleanNames = workflowsArrCopy.map((_wf) => cleanString(_wf.name.en));

  while (cleanNamesOrderArrCopy.length !== 0) {
    const currOrderName = cleanNamesOrderArrCopy.shift();
    // find if there is such name present among workflows names
    const wfIdx = workflowsCleanNames.findIndex((_wfName) => _wfName.includes(currOrderName));
    // if found, push to res to the end
    if (wfIdx !== -1) {
      orderedWorkflowList.push(workflowsArrCopy[wfIdx]);
      workflowsArrCopy.splice(wfIdx, 1);
      workflowsCleanNames.splice(wfIdx, 1);
    }
  }

  // spread res and then what's left in workflowsArrCopy
  return [...orderedWorkflowList, ...workflowsArrCopy];
};

// extracts proper name string from workflow data.
// if there is displayName ---> then use it
// else if there is name ---> use it if its string
//                           if not string but an object, get en translation of it and use as a final name
// else empty string will be returned
export const getDisplayNameFrom = (workflowData: TWorkflowDataFetched_UPmenu | TWorkflowData_UPmenu) => {
  let finalNameStr = '';

  if (ValidatorFunctions.isNotUndefinedNorNull(workflowData?.displayName) && workflowData?.displayName !== '')
    finalNameStr = workflowData.displayName;
  else if (ValidatorFunctions.isNotUndefinedNorNull(workflowData?.name)) {
    if (typeof workflowData?.name === 'string' && workflowData?.name !== '') {
      finalNameStr = workflowData.name;
    } else {
      const name = workflowData.name as TTranslationsData;
      finalNameStr = name.en;
    }
  }

  return finalNameStr;
};

const WF_NO_NAME = 'Untitled';
export const parseFetchedWorkflowsData = (fetchedWorkflows): TWorkflowData_UPmenu[] => {
  const parsedWorkflowsArr = fetchedWorkflows.map((wfData: TWorkflowDataFetched_UPmenu) => {
    let parsedName = getDisplayNameFrom(wfData);

    // if no parsed name ('' empty string)
    if (parsedName === '') {
      return {
        ...wfData,
        name: {
          en: WF_NO_NAME,
          es: WF_NO_NAME,
          ko: WF_NO_NAME,
        } as TTranslationsData,
      };
    }

    return {
      ...wfData,
      name: {
        en: parsedName,
        es: parsedName,
        ko: parsedName,
      } as TTranslationsData,
    };
  });

  const orderedParsedWorkflowArr = reorderWorkflows(parsedWorkflowsArr);
  return orderedParsedWorkflowArr;
};

export const removeQuotesAndSpaces = (inputString) => {
  // Remove quotes (single and double) and empty spaces
  return inputString.replace(/['"\s]/g, '');
};

// filter cb fn
export const notNull = (el) => el !== null;

export const _customDebounce = (fn, wait) => {
  let tm;

  return (e) => {
    const later = () => {
      clearTimeout(tm);
      fn(e);
    };

    clearTimeout(tm);
    tm = setTimeout(later, wait);
  };
};