/**
 * @file This file contains the definition of the `ThinClientUtils` utility class.
 * @author Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */


 import { getIsThinClient } from '../Storage/userStorage';

 /*
 ========================================================================================================================
 Module CONSTANTS
 ========================================================================================================================
 */
 
 const TIME_TO_LIVE = 60 * 60 * 24 * 5; // 5 Days
 
 /*
 ========================================================================================================================
 Class definition
 ========================================================================================================================
 */
 
 /**
  * @summary Thin Client utilities.
  * @description This class provides a static API for performing operations related to interfacing with the Morgan Thin
  *              Client.
  */
 class ThinClientUtils {
 
   /*
   ======================================================================================================================
   Static CONSTANTS
   ======================================================================================================================
   */
   
   /**
    * @summary Test for Thin Client.
    * @description Determine whether or not Morgan is currently being viewed from the Morgan Thin Client. 
    * @returns {Boolean} Returns true if the browser being used to view Morgan is the Morgan Thin Client mobile app.
    */
   static isThinClient() {
     if (navigator.userAgent.match(/morganclient/i) || getIsThinClient() === "true") { // Keep user agent matching for older versions of thin client
       return true;
     }
     return false;
   }
 
   /**
    * @summary Banner TTL accessor
    * @description Fetch the value of the `thincClientBannerTimeToLive` local storage item. This value is the time when 
    *              it will be appropriate to render the "download now" banner once again.
    * @returns {Int} Returns the value of the `thinClientBannerTimeToLive` local storage item. 
    */
   static getBannerTimeToLive() {
     return localStorage.getItem('thinClientBannerTimeToLive');
   }
 
   /**
    * @summary Banner TTL mutator
    * @description Reset the TTL on the "download banner". This will set the `thinClientBannerTimeToLive` local stoarage 
    *              item to the current UNIX timestamp, plus the TTL value, in seconds.
    */
   static resetBannerTimeToLive() {
     let ttl = Math.floor(Date.now() / 1000) + TIME_TO_LIVE;
     localStorage.setItem('thinClientBannerTimeToLive', ttl);
   }
 
   /**
    * @summary Banner TTL validator
    * @description Test whether or not the TTL for the banner lockout has expired.
    * @returns {Boolean} Returns true if the TTL for the banner lockout has NOT expired--meaning the banner must NOT be
    *                    rendered--or false otherwise.
    */
   static validateBannerTimeToLive() {
     let ttl = ThinClientUtils.getBannerTimeToLive();
     let currentTime = Math.floor(Date.now() / 1000);
     if ((ttl === null) || (ttl === undefined) || (ttl < currentTime)) {
       return false;
     }
     return true;
   }
 }
 
 export default ThinClientUtils;
 