import { styled } from '@mui/system';
import getSvgFilter from '../Common/GetSvgFilter';

export const Styles: React.FC<any> = styled('div')<any>(
  ({ theme }) => `
  .ESIconButton {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    overflow: visible;
    color: var(--peach500);
    &:hover, &:focus {
      color: var(--orange600);
      img {
        filter: ${getSvgFilter(theme.palette.orange['600'])} !important;
      }
    }
    svg {
      font-size: 25px;
      pointer-events: none;
      height: 25px;
      width: 25px;
    }
    img {
      height: 25px;
      width: 25px;
      filter: ${getSvgFilter(theme.palette.orange['500'])}
    }
    &.ESColor {
      color: var(--peach500);
      img {
        filter: ${getSvgFilter(theme.palette.orange['600'])}
      }
      &:hover, &:focus {
        color: var(--orange600);
      }
    }
  }
  .ESMenu {
    z-index: 1050;
    &> div {
      background: transparent;
    }
    .ESMenuItem {
      padding: 0;
      margin-bottom: 5px;
      :hover {
        svg {
          color: var(--peach500);
          background-color: var(--gray000);
        }
      }
      label {
        cursor: pointer;
        display: block;
        width: 35px;
        height: 35px;   
      }
      input {
        display: none;
      }
      svg {
        pointer-events: none;
        cursor: pointer;
        color: var(--gray000);
        border-radius: 50%;
        background-color: var(--orange600);
        padding: 5px;
        width: 100%;
        height: 100%;    
      }
    }
  }
  `
);
