/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 *
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 *
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphTimeTabSkeleton.module.css';
import useLazyComponent from '@common/hooks/useLazyComponent';

const MorphTimeTabFallback = () => {
  return <div className={Skeleton.loader + ' ' + Styles.style} />;
};

// The render component below lazy loads the corresponding component using the skeleton above

const MorphTimeTabSkeleton = () => {
  const MorphTimeTabSkeletonProps = {};

  const MorphTimeTab = useLazyComponent(
    import('@components/MorphTime').then(module => ({ default: module.MorphTimeTab })),
  <MorphTimeTabFallback />,
  `./src/Components/Skeletons/MorphTimeTabSkeleton.tsx`
);

  return <MorphTimeTab {...MorphTimeTabSkeletonProps} />;
};

export default MorphTimeTabSkeleton;
