import { MorganTheme, Stack } from '@mui/material';
import React, { MouseEvent, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { useErrandContext } from '@contexts/ErrandContext';
import { IFormContext, FormContext } from '@contexts/FormContext';
import {
  regularTextStyle,
  listTextStyle,
  headerStyle,
  listItemStyle,
  signatureStyleWrapper,
  formWrapper,
  StyledSignatureButton,
  EsignActionStateType,
} from './commonForms';
import { useUserContext } from '@contexts/user';

const StyledStack = (props) => {
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
      {props.children}
    </Stack>
  );
};

const BCERTA = (props) => {
  const { bcertaData, setBcertaData, formNavInsertSignatureHandler } = props;
  const { isOperator } = useUserContext();

  const errandContext = useErrandContext();
  const formContext = useContext<IFormContext>(FormContext);
  const map = formContext.signatureMapRef.current;

  // We won't allow action of form
  const actionDisable = isOperator;

  const theme: MorganTheme = useTheme();

  const initialHere = (e: MouseEvent<HTMLButtonElement>) => {
    const timeStamp = Math.floor(Date.now() / 1000);
    const target = e.target as HTMLButtonElement;

    // Initials modified so re-activate submit button again
    if (errandContext.esignActionState === EsignActionStateType.Signed) {
      errandContext.setEsignActionState(EsignActionStateType.Unsigned);
    }

    setBcertaData((prevState) => {
      // force re-render
      let updatedForm = JSON.parse(JSON.stringify(prevState));

      // updated signature state
      updatedForm.initials[target.id].signed = true;
      updatedForm.initials[target.id].signedDate = timeStamp;

      return updatedForm;
    });
  };

  const signButtonWrapper = (input: string) => {
    return (
      <div style={{ height: '40px' }}>
        {formContext.mainSignatureInserted ? (
          <img alt="user Signature" id={input} src={errandContext.wetSignature} style={signatureStyleWrapper} />
        ) : (
          <StyledSignatureButton
            id={input}
            disabled={actionDisable}
            onClick={() => formContext.mainSignatureInsertedEventHandler()}
            variant="outlined"
          >
            Signature
          </StyledSignatureButton>
        )}
      </div>
    );
  };

  const moveScrollToNextUnsignedSignatureAndPaintGreen = (e) => {
    // find current index
    let index = map.findIndex((x) => x === e.target.id);

    // make sure index is found and not last element
    if (index !== -1 && index !== map.length - 1) {
      // next element index
      index++;

      // get element
      const element = document.getElementById(map[index]);

      // if next element tagName is BUTTON then paint field to white on green background
      if (element && element.tagName === 'BUTTON') {
        element.style.background = theme.palette.green['700'];
        element.style.color = 'var(--gray000)';
      }

      // scroll to next element
      formNavInsertSignatureHandler(map[index]);
    }
  };

  const signatureWrapper = (input: string) => {
    return (
      <div style={{ height: '40px' }}>
        {bcertaData.initials[input].signed === false ? (
          <StyledSignatureButton
            id={input}
            disabled={actionDisable}
            onClick={(e) => {
              initialHere(e);
              moveScrollToNextUnsignedSignatureAndPaintGreen(e);
            }}
            variant="outlined"
            // green paint on first signature input box
            sx={{
              ...(input === map[0] && { background: theme.palette.green['700'], color: 'var(--gray000)' }),
            }}
          >
            Initials
          </StyledSignatureButton>
        ) : (
          <img alt="user initial" id={input} src={errandContext.wetInitial} style={signatureStyleWrapper} />
        )}
      </div>
    );
  };

  return (
    <div style={formWrapper}>
      <h3 style={headerStyle}>BORROWER'S CERTIFICATION AND AUTHORIZATION</h3>
      <h5 style={headerStyle}>Certification</h5>
      <h6 style={headerStyle}>The Undersigned certify the following:</h6>
      <ol>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              I am applying for a mortgage loan or requesting for a pre-qualification or pre-approval for a mortgage
              loan from the above named Lender ({theme.system.companyName}). In applying for the loan or
              pre-qualification or pre-approval, I will provide various information on the purpose of the loan, the
              amount and source of the down payment, employment and income information, the assets and liabilities
              and/or completed a loan application. I certify that all of the information provided will be true and
              complete. I will make no misrepresentations in the loan application or other documents, nor will I omit
              any pertinent information.
            </p>
            {signatureWrapper('auth1')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              I understand and agree that {theme.system.companyName} reserves the right to change the pre-approval or
              pre-qualification and/or the mortgage loan review processes to a full documentation program. This may
              include verifying the information provided on the application with the employer and/or the financial
              institution.
            </p>
            {signatureWrapper('auth2')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              I fully understand that it is a Federal crime punishable by fine or imprisonment, or both, to knowingly
              make any false statements when applying for this mortgage, as applicable under the provisions of Title 18,
              United States Code, Section 1014.
            </p>
            {signatureWrapper('auth3')}
          </StyledStack>
        </li>
      </ol>
      <h5 style={headerStyle}>Authorization to Release Information</h5>
      <h6 style={headerStyle}>To Whom It May Concern:</h6>
      <ol>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              I am applying for a mortgage loan or requesting for a pre-qualification or pre-approval for a mortgage
              loan from the above named Lender {theme.system.companyName} As part of the pre-qualification or
              pre-approval and/or application process, Lender may verify information contained in my loan application
              and in other documents required in connection with the loan, either before the loan is closed or as part
              of its post closing quality control program.
            </p>
            {signatureWrapper('auth4')}
          </StyledStack>
        </li>
        {map.find((x) => x === 'auth16') && (
          <li style={listItemStyle}>
            <StyledStack>
              <p style={listTextStyle}>
                If this is a request for TRU Approval, I understand that under the TILA-RESPA Integrated Disclosure
                (TRID) Rule, {theme.system.companyName} is not permitted to require me to provide verification of
                underwriting information until I have been provided with a "Loan Estimate". I understand that this is
                not a Loan Estimate. I understand that I have the right to ask {theme.system.companyName} about
                providing me with a Loan Estimate. I hereby voluntarily submit any and all documents that
                {theme.system.companyName} has advised me to provide the TRU Approval Notice I have requested. I
                understand that signing this document or providing any information and documentation does not obligate
                me to obtain a loan from or through {theme.system.companyName} I understand that TRU Approval can take
                minimum of 1 business day.
                {theme.system.companyName} is requested to advise if additional information or documents are appropriate
                to complete the TRU Approval review process and I will submit any such additional information or
                documents voluntarily.
              </p>
              {signatureWrapper('auth16')}
            </StyledStack>
          </li>
        )}
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              I authorize you to provide to {theme.system.companyName} and to any investor to whom you may sell my
              mortgage, and to the mortgage guaranty insurer (if any), any and all information and documentation. Such
              information includes, but is not limited to, employment history and income; bank, money market, and
              similar account balances; credit history and copies of income tax returns.
            </p>
            {signatureWrapper('auth5')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              {theme.system.companyName} or any investor that purchases the mortgage may address this authorization to
              any party named in the loan application.
            </p>
            {signatureWrapper('auth6')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              {theme.system.companyName} and its vendors may submit my IRS Form 4506-T to the Internal Revenue Service
              to obtain my tax returns and that {theme.system.companyName} and its vendor will review my tax returns in
              connection with my loan application.
            </p>
            {signatureWrapper('auth7')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              I agree and acknowledge that {theme.system.companyName} may use unaffiliated third party vendors to verify
              the borrower(s) information and disclosures made in the loan application, including without limitation,
              the borrower(s) identity, social security number, employment, financial disclosures, bank accounts, assets
              and liabilities.
            </p>
            {signatureWrapper('auth8')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>A copy of this authorization may be accepted as an original.</p>
            {signatureWrapper('auth9')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              Your prompt reply to {theme.system.companyName} or the investor that purchased the mortgage loan is
              appreciated.
            </p>
            {signatureWrapper('auth10')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              I consent to the use of the information provided by us for any purpose relating to origination, servicing,
              loss mitigation, and disposition of the Mortgage or Property securing the Mortgage and relating to any
              insurance claim and ultimate resolution of such claims by the lender/servicer and FHA.
            </p>
            {signatureWrapper('auth11')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              I authorize {theme.system.companyName} to assess my loan qualification by pulling a credit report and by
              using financial information that I have provided to {theme.system.companyName}
            </p>
            {signatureWrapper('auth13')}
          </StyledStack>
        </li>
        <li style={listItemStyle}>
          <StyledStack>
            <p style={listTextStyle}>
              Whenever I speak to a {theme.system.companyName} representative / agents / affiliates / partners / vendors
              I agree and accept that my call may be recorded.
            </p>
            {signatureWrapper('auth15')}
          </StyledStack>
        </li>
        {map.find((x) => x === 'auth17') && (
          <li style={listItemStyle}>
            <StyledStack>
              <p style={listTextStyle}>
                I expressly consent to having {theme.system.companyName} contact me about my inquiry by text message or
                phone (including automatic telephone dialing system or an artificial or prerecorded voice) to the
                residential or cellular telephone number(s) I have provided, even if that telephone number(s) is (are)
                on a corporate, state, or national Do Not Call Registry. I do not have to agree to receive such calls or
                messages as a condition of getting any services from {theme.system.companyName} or its affiliates. I can
                also opt out at any time by calling (844) 786-9378.
              </p>
              {signatureWrapper('auth17')}
            </StyledStack>
          </li>
        )}
        {map.find((x) => x === 'auth12') && (
          <li style={listItemStyle}>
            <StyledStack>
              <p style={listTextStyle}>
                I authorize {theme.system.companyName}, to apply the unused escrow funds from my/our existing mortgage
                for reducing and/or adjusting and/or using towards the following: Late charges, deferred interest, extra
                MIP, payoff amount of my/our existing mortgage Closing costs, pre-paid expenses, principal balance for
                the new mortgage applied with {theme.system.companyName} Delinquent taxes.
              </p>
              {signatureWrapper('auth12')}
            </StyledStack>
          </li>
        )}
        {map.find((x) => x === 'auth14') && (
          <li style={listItemStyle}>
            <StyledStack>
              <p style={listTextStyle}>
                I agree and acknowledge that if I am working through a mortgage loan broker, that (i) such mortgage loan
                broker is my agent, (ii) my mortgage loan broker may share my personal information with{' '}
                {theme.system.companyName}, (iii) {theme.system.companyName} may share my personal information with my
                mortgage loan broker, (iv) {theme.system.companyName} shall have no liability to me in connection with
                any failure on the part of my mortgage loan broker to properly secure my personal information, and (v) I
                will release and hold harmless {theme.system.companyName} from sharing any details of my loan file with
                my mortgage loan broker.
              </p>
              {signatureWrapper('auth14')}
            </StyledStack>
          </li>
        )}
      </ol>
      <h5 style={headerStyle}>Right of Financial Privacy Act of 1978 Notice</h5>
      <p style={regularTextStyle}>
        The Department of Housing and Urban Development (HUD) and the Department of Veterans Affairs (VA) have the right
        to access financial information held by a financial institution in determining whether to qualify a prospective
        applicant under their respective loan programs. If you are applying for HUD or VA loan, your financial records
        will be made available to the requesting government agency without further notice to or authorization from you;
        provided, however, that financial information shall not be disclosed or released to HUD or the VA more than
        three months from the date that you execute this Borrower's Certification and Authorization. Note that your
        financial information will not be disclosed or released outside the requesting agency except as required or
        permitted by law. Prior to the time that your financial records are disclosed, you may revoke this authorization
        at any time; however, your refusal to provide the information may cause your application to be delayed or
        rejected. If you believe that your financial records have been disclosed improperly, you may have legal rights
        under the Right to Financial Privacy Act of 1978 (12 U.S.C. 3400 et seq.).
      </p>
      {signButtonWrapper('authLast')}
    </div>
  );
};

export default BCERTA;
