/**
 * @file This component is a stylized Box component
 * for styling the Mini Chat Menu.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import getSvgFilter from '../Common/GetSvgFilter';


// NOTICE: theme.palette variables should follow the below format
// "componentName" { "emmetShortcutForAttributeAndTargetClassName": "hex or rgba only" }
// Get emmetShortcut from this cheat sheet: https://docs.emmet.io/cheat-sheet/
// Try to avoid passing props and use dynamic classes instead so that the css will be static
export const SendButton = styled(IconButton)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  padding: 0;
  border-radius: 50%;
  position: relative;
  display: flex;
  cursor: pointer;
  color: var(--peach500);
  &:hover, &:focus {
    color: var(--orange600);
    img {
      filter: ${getSvgFilter(theme.palette.orange['600'])} !important;
    }
  }
  &.disabled {
    display: none !important;
  }
  & svg.sendRounded {
    color: var(--orange600);
    width: 100%;
    height: 100%;
    margin-left: 4px;
    margin-top: 5px;
  }
  `
);

export const HiddenButton = styled(IconButton)(
  ({ theme }) => `
  width: 40px;
  height: 40px;
  disply: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 50%;
  position: relative;
  max-width: 0;
  overflow: hidden;
  &:focus {
    max-width: unset;
  }
  &:focus-within {
    max-width: unset;
  }
  & svg {
    color: var(--orange600);
    width: 100%;
    height: 100%;
  }
  `
);