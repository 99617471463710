import React from 'react';

import { Box } from '@mui/system';
import { regularTextStyle, formWrapper } from './commonForms';

const CreateSignatureDesktop = () => {
  return (
    <Box sx={formWrapper}>
      <p style={regularTextStyle}>
        <b>To effortlessly generate your signature with AngelAI, follow these instructions:</b>
      </p>
      <p style={regularTextStyle}>
        Click on the "Generate Signature" option. Your name will be transformed into a professional signature automatically. No
        need for manual input. Use this convenient feature whenever you need to sign legal documents. Let AngelAI handle
        the process, providing you with a polished signature. Enjoy the simplicity and ease of generating your signature
        with just a few clicks using the "Generate Signature" option.
      </p>
      <p style={regularTextStyle}>
        Click below to create your own custom signature manually using your screen. This feature allows you to
        personalize your signature for use on all your documents. Take control of your signature's appearance and make
        it uniquely yours. With just a few clicks, you can create a signature that reflects your style and
        professionalism. Use this option to enhance the authenticity and personal touch of your documents.
      </p>
    </Box>
  );
};

export default CreateSignatureDesktop;
