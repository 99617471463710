import React, { memo, useContext, useCallback } from 'react';

import { SPACE_BETWEEN_SLIDES, SWIPER_SLIDE_WIDTH, SWIPER_SLIDE_HEIGHT, SWIPER_SLIDE_LOADING_WIDTH } from './constants';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { Edges, MainSwiperProps, UserPromptsMenuState } from '../types';
import Styles from '@styles/MorphUserPromptsMenu.module.css';
import { SingleSlideElement } from '../SingleSlideElement';
import { FreeMode, Mousewheel, Keyboard } from 'swiper';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/react';

const staticSwiperProps = {
  modules: [FreeMode],
  spaceBetween: SPACE_BETWEEN_SLIDES,
  slideToClickedSlide: true,
  slidesPerView: 'auto' as 'auto',
  centeredSlides: true,
};

const staticSwiperSlideStyle = {
  cursor: 'pointer',
  width: `${SWIPER_SLIDE_WIDTH}px`,
  height: `${SWIPER_SLIDE_HEIGHT}px`,
};

const staticLoadingSwiperSlideStyle = { width: `${SWIPER_SLIDE_LOADING_WIDTH}px`, height: `fit-content` };

const MessageText = ({ text }) => {
  return (
    <p
      style={{
        fontSize: '0.8rem',
        color: 'var(--gray000)',
        textAlign: 'center',
        marginTop: '5px',
        lineHeight: '13px',
        overflowWrap: 'break-word',
        maxWidth: '100%',
        padding: '16px',
      }}
    >
      {text}
    </p>
  );
};
const EmptyListMessage = () => {
  const { t } = useTranslation();

  return <MessageText text={t('UPM_No_Available_Options')} />;
};
const ErrorOccuredMessage = () => {
  const { t } = useTranslation();

  return <MessageText text={t('UPM_Some_error_occurred_on_our_end')} />;
};
const MainSwiper = (props: MainSwiperProps) => {
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const { t, i18n } = useTranslation();

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>, idx: number) => {
      if (e.code === 'Enter' || e.code === 'Space') {
        props.onSingleSlideClickHandler(e, idx);
        e.preventDefault();
        return;
      }

      // Handle TAB KEY
      if (e.code === 'Tab') {
        if (!e.shiftKey) {
          props.handleTabForward();
        } else if (e.shiftKey) {
          props.handleTabBackward();
        }

        e.preventDefault();
        return;
      }
    },
    [props.onSingleSlideClickHandler, props.handleTabForward, props.handleTabBackward]
  );

  const renderSlides = useCallback(() => {
    if (props.isLoading === true) {
      return (
        <SwiperSlide style={staticLoadingSwiperSlideStyle}>
          <SingleSlideElement isLoadingElement={true} name={t('userPromptsMenuLoadingElementsText')} />
        </SwiperSlide>
      );
    }

    return props.slideListItems.map((currWfData, idx) => {
      const wfName = currWfData.name[i18n.language];
      return (
        <SwiperSlide
          tabIndex={-1}
          aria-label={
            `${t('UPM_Select_this_workflow_to_start_initiating_it_in_your_chat._Current_workflow_name_is')} ` +
            `${wfName}`
          }
          aria-describedby={
            idx === props.slideListItems.length - 1 ? 'UPM_EndOfList' : idx === 0 ? 'UPM_StartOfList' : ''
          }
          role={'button'}
          key={wfName}
          onClick={(e) => props.onSingleSlideClickHandler(e, idx)}
          className={Styles.SingleSwiperSlide}
          onKeyDown={(e) => {
            handleKeyDown(e, idx);
          }}
          style={staticSwiperSlideStyle}
        >
          <SingleSlideElement workflowIcon={currWfData.workflowIcon} name={wfName} />
        </SwiperSlide>
      );
    });
  }, [props.isLoading, props.slideListItems, i18n.language, i18n.t, props.onSingleSlideClickHandler, handleKeyDown]);

  // when done loading
  if (props.isLoading === false) {
    // if no items available
    if (
      // render empty options if in WORKFLOW_NOT_SELECTED state and list is empty
      errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_NOT_SELECTED &&
      props.slideListItems.length === 0
    ) {
      return <EmptyListMessage />;
    }
  }

  // if error occured regardless of isLoading
  if (errandContext.userPromptsMenuState === UserPromptsMenuState.WORKFLOW_FETCH_ERROR) {
    return <ErrorOccuredMessage />;
  }

  return (
    <Swiper
      onSlideChange={props.handleSlideChange}
      className={Styles.swiperContainer}
      onSwiper={props.setSwiperInstance}
      // dynamic style rules
      style={props.style}
      {...staticSwiperProps}
    >
      {renderSlides()}
    </Swiper>
  );
};

export { MainSwiper };
