import React from 'react';
import Styles from '@styles/PreviewAudio.module.css';

type TPreviewAudioProps = {
  src: string;
  sentByCurrentUser: boolean;
  visible: boolean;
}

const PreviewAudio = ({
  src, sentByCurrentUser, visible, 
}: TPreviewAudioProps) => {
  return (
    <>
      {src?.length > 35 ? (
        <audio
          className={[
            Styles.audio,
            ...(sentByCurrentUser ? [Styles.sentByCurrentUser] : [])
          ].join(' ')}
          controls
          // preload="none"  <-- Icy 7/17/23: caused the initial duration of audio to be set to 0:00
        >
          {visible &&
            <source src={src} type="audio/mp4" />
          }
        </audio>
      ) : (
        <>
          <div className={Styles.bars}>
            <div className={Styles.bar} />
            <div className={Styles.bar} />
            <div className={Styles.bar} />
            <div className={Styles.bar} />
            <div className={Styles.bar} />
            <div className={Styles.bar} />
            <div className={Styles.bar} />
            <div className={Styles.bar} />
            <div className={Styles.bar} />
            <div className={Styles.bar} />
          </div>
        </>
      )}
    </>
  );
};

export default PreviewAudio;
