import React from 'react';

import { IMessage } from "@interfaces/Conversation";
import ErrorBoundary from '@components/ErrorBoundary';
import { Suspense } from "react";

export const getDebugStr = (messageType) => {
  return `./src/Components/Suspense/ErrBoundaryWithSuspenseWrapper.tsx: ${messageType}`;
};

export const getMessageDebugString = (allProps: any, message: IMessage, isDeleted = null, isPrivate = null) => {
  const { ErrandProp, MessageProp, ErrandMessageProps, IndexMessageProps, ErrandIndexMessageProps } = allProps;
  let correctProps;
  if (isDeleted) {
    return getDebugStr('DeletedMessage');
  } else if (isPrivate) {
    return getDebugStr('PrivateMessage');
  }
  switch (message?.messageType) {
    case 'Action':
      switch (message?.action?.description) {
        case 'Slot Machine':
          correctProps = ErrandMessageProps;
          break;
        case 'TCPA':
          correctProps = ErrandMessageProps;
          break;
        default:
          correctProps = ErrandIndexMessageProps;
          break;
      }
    case 'Audio':
      correctProps = ErrandMessageProps;
      break;
    case 'CalculatorResult':
      // @ts-ignore @hunter Please fix this ts error regarding conflicting message types.
      correctProps = ErrandMessageProps;
      break;
    case 'Coupon':
      correctProps = MessageProp;
      break;
    case 'Disclaimer':
      correctProps = MessageProp;
      break;
    case 'Document':
      correctProps = ErrandMessageProps;
      break;
    case 'CustomLink':
      correctProps = ErrandMessageProps;
      break;
    case 'Appointment':
      correctProps = ErrandMessageProps;
      break;
    case 'Errand':
      correctProps = ErrandProp;
      break;
    case 'Field':
      if (message.documents) correctProps = ErrandMessageProps;
      else correctProps = MessageProp;
      break;
    case 'HelpTip':
    case 'HelpTipGroup':
      // @ts-ignore @eric Please fix this ts error regarding conflicting message types.
      correctProps = MessageProp;
      break;
    case `ImagingUploadStatus`:
      correctProps = ErrandMessageProps;
      break;
    case 'Invite':
      // @ts-ignore @Icy Please fix this ts error regarding conflicting message types.
      correctProps = MessageProp;
      break;
    case 'LockConfirmation':
      // @ts-ignore @eric Please fix this ts error regarding conflicting message types.
      correctProps = MessageProp;
      break;
    case 'Notification':
      correctProps = MessageProp;
      break;
    case 'Order':
      correctProps = MessageProp;
      break;
    case 'PermissionRejected':
      correctProps = {};
      break;
    case 'PropertyListing':
      correctProps = ErrandMessageProps;
      break;
    case 'Referrer':
      correctProps = ErrandMessageProps;
      break;
    case 'SignatureConfirmation':
      correctProps = IndexMessageProps;
      break;
    case 'Song':
      correctProps = ErrandIndexMessageProps;
      break;
    case 'Text':
    case 'Greeting':
      correctProps = ErrandMessageProps;
      break;
    case 'Url':
      correctProps = MessageProp;
      break;
    case 'Video':
      correctProps = ErrandMessageProps;
      break;
    case 'LoanProductComparison':
      correctProps = MessageProp;
      break;
    case 'LoanConditions':
      correctProps = MessageProp;
      break;
    case 'VideoListMenu':
      correctProps = ErrandMessageProps;
      break;
    case 'UserPromptsMenu':
      correctProps = ErrandMessageProps;
      break;
    case 'RateInfo':
      correctProps = ErrandMessageProps;
      break;
    case 'ProfileBubble':
      correctProps = MessageProp;
      break;
    default:
      correctProps = null;
  }
  return getDebugStr(message.messageType);
};

export const ErrBoundaryWithSuspenseWrapper = ({ debugString, fbComponent, children }) => {
  return (
    <ErrorBoundary debug={debugString}>
      <Suspense fallback={fbComponent}>{children}</Suspense>
    </ErrorBoundary>
  );
};
