// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.0/8 are considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export async function register(config?: Config) {
  try {
    console.log("Starting service worker registration...", config);
    // Check if the browser supports service workers
    if ("serviceWorker" in navigator) {
      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL(process.env.REACT_APP_PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebook/create-react-app/issues/2374
        console.warn(`publicUrl.origin !== window.location.origin: ${publicUrl.origin} != ${window.location.origin}`);
        return;
      }
      // Don't register the service worker until the page has fully loaded.
      // avoids bandwidth contention if the service worker precaches anything.
      window.addEventListener('load', async () => {
        const swUrl = `${process.env.REACT_APP_PUBLIC_URL}/service-worker.js`;
        if (isLocalhost) {
          // This is running on localhost. Let's check if a service worker still exists or not.
          await checkValidServiceWorker(swUrl, config);

          // Add some additional logging to localhost, pointing developers to the
          // service worker/PWA documentation. To learn more, visit https://cra.link/PWA'
          navigator.serviceWorker.ready.then(() => {
            console.log(
              'This web app is being served cache-first by a service worker');
          });
        } else {
          // Is not localhost. Just register service worker
          await registerValidSW(swUrl, config);
        }
      });
    } else {
      console.warn("Browser does not support service-workers.");
    }
  } catch (err) {
    console.error(err.stack);
    console.error(`Service worker - Error registering: ${JSON.stringify(err.message)}`);
  }
}

async function registerValidSW(swUrl: string, config?: Config) {
  try {
    const registration = await navigator.serviceWorker.register(swUrl);

    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }

      installingWorker.onstatechange = () => {
        console.debug("sw.state:", installingWorker.state);
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            // See https://cra.link/PWA.
            console.log(
              'New content is available and will be used when the page is reloaded'
            );

            if (config && config.onUpdate) {
              console.debug('Update available:', registration);
              config.onUpdate(registration);
            }
          } else {
            console.log('Content is cached for offline use.');

            if (config && config.onSuccess) {
              console.debug('Service worker registration successful:', registration);
              config.onSuccess(registration);
            }
          }
          // Subscribe for push notifications only after the service worker installed.
          // subscribeForPushNotifications();
        }
      };

      installingWorker.onerror = (error) => {
        console.error('Error installing service worker:', error);
      };
    };
  } catch (err) {
    console.error(err);
    console.error(`Error registering service worker: ${swUrl}, ${JSON.stringify(err.message)}`);
  }
}

/**
 * Initial set up for push notifications
 * https://www.npmjs.com/package/web-push next step
 */
// function subscribeForPushNotifications () {
//   navigator.serviceWorker.getRegistration().then(reg => {
//     reg.pushManager.subscribe({
//       userVisibleOnly: true
//     })
//     .then(sub => {
//       console.log("Subscribed for push notifications", sub);
//     })
//     .catch((err) => {
//       console.error('Error subscribing for push notifications:', err);
//     });
//   });
// }

async function checkValidServiceWorker(swUrl: string, config?: Config) {
  try {
    // Check if the service worker can be found. If it can't, reload the page.
    const response = await fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    });
    // Ensure service worker exists and that we are really getting a JS file.
    const contentType = response.headers.get('content-type');
    if (
      response.status === 404 ||
      (contentType != null && contentType.indexOf('javascript') === -1)
    ) {
      // No service worker found. Probably a different app. Reload the page.
      console.warn("No service worker found.");
      const registration = await navigator.serviceWorker.ready;
      await registration.unregister();
      window.location.reload();
    } else {
      // Service worker found. Proceed as normal.
      console.log("Compatible service-worker found");
      await registerValidSW(swUrl, config);
    }
  } catch (error) {
    console.log('No internet connection found. App is running in offline mode.');
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
