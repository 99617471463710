import React from 'react';
import themeFile from '@assets/Themes/default.json';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ShimmerEffect from '@components/ShimmerEffect';

function MessagesSkeletonLoader() {

// Use MUI's media query to have different margins depending on screen size
    const theme = useTheme()

// This creates the skeleton for the ConversationBody box which holds all the messages
    const MessageBox = styled('div')({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    })
    
// This is the div that holds the bubble skeletons
    const MessagesWrapper = styled('div')({
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
})

// This creates the skeleton for the speech bubble from Morgan
    const MorganBubbleSkeleton = styled('div')({
        backgroundColor: themeFile.palette.peach['200'],
        minHeight: '35px',
        minWidth: '100px',
        width: '20%',
        marginBottom: '20px',
        marginLeft: `${useMediaQuery(theme.breakpoints.up('md')) ? '60px' : '25px'}`,
        borderRadius: '8px',
        alignSelf: 'start',
        overflow: 'hidden',
    })

// This creates the skeleton for the speech bubble from the User
    const UserBubbleSkeleton = styled('div')({
        backgroundColor: themeFile.palette.peach['200'],
        minHeight: '35px',
        minWidth: '100px',
        width: '10%',
        marginBottom: '20px',
        marginRight: `${useMediaQuery(theme.breakpoints.up('md')) ? '60px' : '25px'}`,
        borderRadius: '8px',
        alignSelf: 'end',
        marginLeft: 'auto',
        overflow: 'hidden'
    })

    // Varying lengths of bubbles here on the left and right side of the screen to mimic a conversation
    return (
        <MessageBox>

            <MessagesWrapper>
                <MorganBubbleSkeleton><ShimmerEffect color='var(--shadow602)' width='50%'/></MorganBubbleSkeleton>
                <MorganBubbleSkeleton sx={{width: '25%'}}><ShimmerEffect color='var(--shadow602)' width='50%'/></MorganBubbleSkeleton>
                    <UserBubbleSkeleton><ShimmerEffect color='var(--shadow602)'width='50%'/></UserBubbleSkeleton>
                <MorganBubbleSkeleton sx = {{width: '50%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></MorganBubbleSkeleton>
                    <UserBubbleSkeleton sx={{width: '30%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></UserBubbleSkeleton>
                <MorganBubbleSkeleton sx={{width: '45%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></MorganBubbleSkeleton>
                    <UserBubbleSkeleton sx={{width: '35%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></UserBubbleSkeleton>
                <MorganBubbleSkeleton sx={{width: '25%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></MorganBubbleSkeleton>
                    <UserBubbleSkeleton sx={{width: '50%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></UserBubbleSkeleton>
                    <UserBubbleSkeleton sx={{width: '20%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></UserBubbleSkeleton>
                <MorganBubbleSkeleton sx={{width: '40%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></MorganBubbleSkeleton>
                    <UserBubbleSkeleton sx={{width: '15%'}}><ShimmerEffect color='var(--shadow602)'width='50%'/></UserBubbleSkeleton>
            </MessagesWrapper>
            
            
        </MessageBox>
    )
}

export default MessagesSkeletonLoader