import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';


export const GlobeStyles = styled(Box, {
})(() => `

    .canvas{
        width: 140px;
        height: 140px;
    }

    .globe{
        width: 140px;
        height: 140px;
    }

    .flagContainer{
        width: 279px;
        height: 138px;
        top: 231px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .additionalFlag1, .additionalFlag2, .additionalFlag3{
        border: var(--orange400) solid 1px;
        border-radius: 4px;
        width: 28px;
        height: 19px;
        position: absolute;
        cursor: pointer;
    }
    .additionalFlag1{
        // Example positioning, adjust as needed
        top: 42px;
        left: 48px;
    }
    .additionalFlag2{
        // Example positioning, adjust as needed
        top: 42px;
        right: 54px;
    }

    .additionalDot1, .additionalDot2, .additionalDot3{
        position: absolute;
        border-radius: 50%;
        z-index: 5;
        background-color: var(--orange700);
    }
    .additionalDot1{
        width: 8px;
        height: 8px;
        top: 30px;
        left: -50px;
    }
    .additionalDot2{
        width: 8px;
        height: 8px;
        top: 30px;
        right: -50px;
    }

    .topFlag{
        border-radius: 10px;
        height: 45px;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        color: var(--orange800);
        flex-direction: column-reverse;
        top: -19px;
    }

    .topFlag svg{
        border-radius: 4px;
        border: 2px solid var(--orange700);
        width: 34px;
        margin: auto;
    }

    .languageBadge{
        margin: auto;
        font-family: Poppins;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.6;
        font-size: 13px;
        position: relative;
        bottom: 58px;
        z-index: 6;
        top: 0;
    }

    .rightFlag{
        border: var(--orange400) solid 1px;
        border-radius: 4px;
        width: 28px;
        height: 19px;
        position: absolute;
        right: 33px;
        bottom: 8px;
        cursor: pointer;
    }

    .leftFlag{
        border: var(--orange400) solid 1px;
        border-radius: 4px;
        width: 28px;
        height: 19px;
        position: absolute;
        left: 33px;
        bottom: 8px;
        cursor: pointer;
    }

    .globeContainer{
        position: absolute;
        bottom: 0;
        height: 48px;
    }

    .dotContainer{
        position: absolute;
        top: 61px;
    }

    .dot{
        position: absolute;
        border-radius: 50%;
        z-index: 5;
        background-color: var(--orange700);
    }

    .dotTopOuter{
        width: 10px;
        height: 10px;
        top: -29px;
        left: -5px;
    }

    .dotTopInner{
        width: 6px;
        height: 6px;
        background-color: var(--gray000);
        top: -27px;
        left: -3px;
    }

    .dotLeft{
        width: 8px;
        height: 8px;
        top: 52px;
        left: -74px;
    }

    .dotRight{
        width: 8px;
        height: 8px;
        top: 52px;
        right: -74px;
    }

    @media (max-width: 620px){
        .topFlag{
            margin-right: 20px;
        }
        .leftFlag{
            bottom: 15px;
        }
        .rightFlag{
            bottom: 15px;
            right: 48px;
            z-index: 3;
        }
        .dotTopOuter{
            top: -29px;
            left: -14px;
        }
        .dotTopInner{
            top: -27px;
            left: -12px;
        }
        .dotLeft{
            top: 47px;
            left: -74px;
        }
        .dotRight{
            top: 47px;
            right: -57px;
        }
    }

    @media (max-width: 500px){
        .topFlag{
            margin-right: 35px;
        }
        .leftFlag{
            bottom: 23px;
            left: 29px;
        }
        .rightFlag{
            bottom: 23px;
            right: 63px;
        }
        .dotTopOuter{
            left: -22px;
        }
        .dotTopInner{
            top: -27px;
            left: -20px;
        }
        .dotLeft{
            top: 41px;
            left: -73px;
        }
        .dotRight{
            top: 41px;
            right: -44px;
        }
    }

    @media (max-width: 400px){
        .topFlag{
            margin-right: 35px;
        }
        .leftFlag{
            bottom: 28px;
            left: 34px;
        }
        .rightFlag{
            bottom: 28px;
            right: 70px;
        }
        .dotTopOuter{
            left: -22px;
        }
        .dotTopInner{
            top: -27px;
            left: -20px;
        }
        .dotLeft{
            top: 35px;
            left: -73px;
        }
        .dotRight{
            top: 35px;
            right: -37px;
        }
    }
`);
