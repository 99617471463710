import React from 'react';
const ropeKnot = process.env.REACT_APP_MORGAN_CDN + '/Images/rope_knot.png';

const RopeKnot = (props) => {

  return (
    <div
      style={{
        background: `url(${ropeKnot}) no-repeat`,
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '20px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 20px',
        userSelect: 'none',
        userDrag: 'none',
        '&WebkitUserDrag': 'none',
        '&MozUserSelect': 'none',
        '&WebkitUserSelect': 'none',
        '&MsUserSelect': 'none',
        cursor: 'grab',
        transform: 'translateY(5px)'
      }}
      {...props.handlers}>
    </div>
  )
}

export default RopeKnot;
