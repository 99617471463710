import React from 'react';
import { isMobile } from '@common/deviceTypeHelper';

type DefaultElementProps = {
  text?: string;
  children?: JSX.Element | JSX.Element[];
  icon?: JSX.Element | null;
};

export const DefaultElement = (props: DefaultElementProps) => {
  // Text OR Children
  const renderContent = () => {
    if (props.text) {
      return props.text;
    } else if (props.children) {
      return props.children;
    } else {
      return null;
    }
  };

  return (
    <>
      {props.icon || null}
      <p className="wrapper">
        <span className={'activityText'} style={{ fontSize: isMobile() ? '11px' : '14px' }}>
          {renderContent()}
        </span>
      </p>
    </>
  );
};
