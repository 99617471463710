/**
 * @file This component is a stylized Box component
 * for styling the Errand Message Content.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import getSvgFilter from '@common/GetSvgFilter';
import React, { PropsWithChildren } from 'react';


// NOTICE: theme.palette variables should follow the below format
// "componentName" { "emmetShortcutForAttributeAndTargetClassName": "hex or rgba only" }
// Get emmetShortcut from this cheat sheet: https://docs.emmet.io/cheat-sheet/

const ConversationFooterStyle: React.FC<any> = styled('div')<any>(
  ({ theme, width }) => `
  height: fit-content;
  position: relative;
  .ConversationFooter {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-top: auto;
    min-height: ${useMediaQuery(theme.breakpoints.up('md')) ? '50px' : '42px'};
    padding: ${useMediaQuery(theme.breakpoints.up('md')) ? '0 30px' : '0 20px'};
    width: 100%;
  }
  .ConversationFooter.isAttachmentMenuOpen {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2px;
  }
  .ConversationFooter.isMorphedFooterCloseButtonOnLeft {
    grid-template-columns: auto 1fr;
  }
  .ConversationFooter.isMorphedFooterCloseButtonOnLeft > div {
    order: 2;
  }
  .placeholderBox {
    display: block;
    height: 24px;
    width: 40px;
    background-color: transparent;
  }
 
  .StyledOptionIcon {
    width: 25px;
    height: 25px;
    color: var(--gray000);
    background-color: var(--orange700);
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 5px;
    z-index: 999999;
    left: 0;
    top: 34px;
    padding: 3px;
    :hover, :focus {
      color: var(--orange600);
      img {
        filter: ${getSvgFilter(theme.palette.orange['600'])} !important;
      }
    }
  }
  .scrollOption {
    display: none;
    width: 100%;
  }
  .scrollOption:nth-of-type(1) .scrollOptionBackground {
    margin: 0 0 -3px ${useMediaQuery(theme.breakpoints.up('md')) ? '63px' : 'calc(2.5% + 49px)'};
  }
  .scrollOptionBackground {
    background-color: var(--gray100);
    border-radius: 8px;
    box-shadow: 0 -2px 5px 0 var(--shadow222);
    display: block;
    margin: ${useMediaQuery(theme.breakpoints.up('md')) ? '-12px 0 0 63px' : '-6px 0 0 calc(2.5% + 49px)'};
    padding-left: 10px;
    width: calc(${useMediaQuery(theme.breakpoints.up('md')) ? '100% - 134px' : '100% - 98.75px'});
    p:empty {
      display: none;
    }
  }

  @keyframes russell-animation {
    0% {
      top: 100px;
    }
    100% {
      top: 0px;
    }
  }

  @keyframes hide-russell-animation {
    0% {
      top: 0px;
    }
    100% {
      top: 100px;
    }
  }

  @keyframes hide-russell-container {
    0% {
      margin-top: 0px;
    }
    100% {
      margin-top: -300px;
      display: none;
    }
  }

  .showRussellPeters {
    width: auto;
    height: 145px;
    position: absolute;
    right: 10vw;
    animation: russell-animation 0.5s linear forwards;
  }

  .hideRussellPeters {
    width: auto;
    height: 145px;
    position: absolute;
    right: 10vw;
    animation: hide-russell-animation 0.5s linear forwards;
  }

  .russellContainerMobile {
    overflow: hidden;
    height: 85px;
    width: 100%;
    position: relative;
    top: 4px;
  }

  .russellContainer {
    overflow: hidden;
    height: 85px;
    width: 100%;
    position: relative;
    top: 0px;
    animation : hide-russell-container 4s linear forward;
  }

  .hideRussell {
    display: none;
    animation : hide-russell-container 1s linear forward;
  }

  .inputContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    margin-top: ${useMediaQuery(theme.breakpoints.up('md')) ? '0' : '3px'};
    border: 1px solid var(--peach400);
    border-radius: 8px;
    background-color: var(--gray000);
    padding: 5px;

    .contactsCloseButton {
      width: 40px;
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      z-Index: 2; // set to be higher than z-index of morphRecording of 1 
      overflow: visible;
      color: var(--peach500);
      :hover, :focus {
        color: var(--orange600);
        img {
          filter: ${getSvgFilter(theme.palette.orange['600'])} !important;
        }
      }
    }

    .inputForm {
      align-items: center;
      border-radius: ${useMediaQuery(theme.breakpoints.up('md')) ? '8px' : '0px'};
      border: none;
      color: var(--shadow870);
      display: flex;
      height: fit-content;
      padding: ${useMediaQuery(theme.breakpoints.up('md')) ? '3px 0px 3px 8px' : '3px 0px 3px 4px'};
      position: relative;
      width: 100%;
      background-color: var(--gray000);
      z-index: initial;
    }
    .inputFieldContainer {
      align-items: center;
      display: flex;
      border-radius: ${useMediaQuery(theme.breakpoints.up('md')) ? '0px' : '8px'};
      padding: ${useMediaQuery(theme.breakpoints.up('md')) ? '0px' : '3px 0px 3px 4px'};
      width: 100%;
      position: relative;
      overflow: hidden;
    }
  }

  .inputContainer.focused {
    border: 1px solid var(--orange700);
  }

  .inputContainerHide {
    padding-top: 3px !important;
    position: absolute;
    transform: scale(0.95);
    animation: hideInput 1.5s;
    animation-fill-mode: forwards;

    @keyframes hideInput {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
        scale: 0.8;
        display: none;
      }
    }
  }

  .closeIcon {
    color: var(--peach500);
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;

    &:hover, &:focus {
      color: var(--orange600);
    }
  }


  .isAttachmentMenuOpen .inputContainer {
    width: 100%;
  }
  .isDropdown .inputContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: ${useMediaQuery(theme.breakpoints.up('md')) ? '10px' : '3px'};
    margin-top: ${useMediaQuery(theme.breakpoints.up('md')) ? '0' : '3px'};
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: transparent;
    padding: 5px;
  }
  &.isDropdown {
    .ConversationFooter {
      background: none;
      min-height: fit-content;
    }
    .scrollOption {
      display: flex;
    }
    .inputContainer {
      .inputForm {
        border: ${useMediaQuery(theme.breakpoints.up('md'))
      ? `1px solid var(--orange700)`
      : `1px solid var(--peach200)`
    };
        z-index: 99999;
      }
      .inputFieldContainer {
        border: ${useMediaQuery(theme.breakpoints.up('md'))
      ? 'none'
      : `1px solid var(--orange700)`
    };
      }
    }
  }
  &.shouldHideFooter > * {
    display: none;
  }
 `
);

export const FooterIcon = styled(Icon)(
  ({ theme }) => `
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
  padding-left: 3px;
  padding-right: 3px;
  width: fit-content;
  color: var(--orange600);
  svg {
    font-size: 25px;
    pointer-events: none;
  }
  img {
    height: 25px;
    width: auto;
    transform: translateY(-1px);
    filter: ${getSvgFilter(theme.palette.orange['600'])}
  }
  @media (max-width: 900px) {
    svg {
      width: 25.9px;  // 50% of the original 37px on PaperClipNotepad.svg
      height: 27.3px; // 50% of the original 39px on PaperClipNotepad.svg
    }
    img {
      width: 25.9px;  // adjust for img as well
      height: 27.3px; 
    }
  }
  `
);


export const FooterAnimatedIconWrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "pulseDuration" && prop !== "animationStarted"
})<any>(({pulseDuration, animationStarted}) => `
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  opacity: 0;
  ${animationStarted === true ? `
  animation: opacity-animation ${pulseDuration} infinite;
  @keyframes opacity-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }  ` : ``}
  
`)

export const FooterIconButton: React.FC<PropsWithChildren<any>> = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'hover',
})<any>(
  ({ theme, color, hover }) => `
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  overflow: visible;
  color: var(--peach500);
  :hover, :focus {
    color: var(--orange600);
    img {
      filter: ${getSvgFilter(hover || theme.palette.orange['600'])} !important;
    }
  }
  svg {
    font-size: 25px;
    pointer-events: none;
    height: 25px;
    width: 25px;
  }
  img {
    height: 25px;
    width: 25px;
    filter: ${getSvgFilter(hover || theme.palette.orange['500'])}
  }
  `
);

export const FooterAttachmentMenuItem = styled(MenuItem)(
  ({ theme }) => `
  padding: 0;
  margin-bottom: 5px;
  :hover {
    svg {
      color: var(--orange600);
      background-color: var(--gray000);
    }
  }
  label {
    cursor: pointer;
    display: block;
    width: 35px;
    height: 35px;   
  }
  input {
    display: none;
  }
  svg {
    pointer-events: none;
    cursor: pointer;
    color: var(--gray000);
    border-radius: 50%;
    background-color: var(--orange600);
    padding: 5px;
    width: 100%;
    height: 100%;    
  }
  `
);

export const CFDivider: React.FC<any> = styled(Divider)<any>(
  ({ theme }) => `
  height: 22px;
  margin: 0 8px;
  background-color: var(--peach600);
  `
);

export default ConversationFooterStyle;
