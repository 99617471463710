import { DialogPropsWithChildren } from '@mTypes/Console';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
import { styled /*, useTheme*/ } from '@mui/material/styles';
//import { Tooltip } from '@mui/material';
import { Close } from '../Assets/Icons';
//import { useDropzone } from 'react-dropzone';
import React, { FC, useEffect, useState } from 'react';
import useInitialMount from '@common/hooks/useInitialMount'
import { useTranslation } from 'react-i18next';
import axiosCall from '../Services/axios';
//import getImageSource from '../Common/getImageSource';
import themeFile from '@assets/Themes/default.json';

const mt = {
  mt:"12px"
};

const StyledStepper = styled(Stepper)({
  '& .MuiStepIcon-root.Mui-completed': {
    color: themeFile.palette.blue['210'],
  },
  '& .MuiStepIcon-root.Mui-active': {
    display: "none",
    color: themeFile.palette.blue['210'],
  },
});

const EditOrderDialog: FC<DialogPropsWithChildren> = (props) => {
  // const theme = useTheme();
  const { t } = useTranslation();
  const activeStep = 0;
  const [size, setSize] = useState(props?.Order?.unitSize || '');
  const [shippingAddressLine1, setShippingAddressLine1] = useState(props?.Order?.shippingAddressLine1 || "");
  const [shippingAddressLine2, setShippingAddressLine2] = useState(props?.Order?.shippingAddressLine2 || "");
  const [color, setColor] = useState(props?.Order?.color || "");
  const [statusesList, setStatusesList] = useState([])
  const [carrierList, setCarrierList] = useState([])
  const [shippingCity, setShippingCity] = useState(props?.Order?.shippingCity || "");
  const [shippingState, setShippingState] = useState(props?.Order?.shippingState || "");
  const [shippingZip, setShippingZip] = useState(props?.Order?.shippingZip || "");
  const [status, setStatus] = useState(props?.Order?.status || "");
  const [quantity, setQuantity] = useState(props?.Order?.quantity || 0);
  const [trackingNumber, setTrackingNumber] = useState(props?.Order?.trackingNumber || "");
  const [carrier, setCarrier] = useState(props?.Order?.carrier || "");

  const steps = [t('orderDetails')] //, t('OrderBody')];

  /*************************************
   * Submit Function
   *************************************/
  const handleGetOrderStatuses = async () => {
    let result = null;
    let statuses = [];
    try {
      result = await axiosCall({ url: `Order/db/orderStatuses` });
    } catch (error) {
      console.log(error);
    }
      if (result) {
        statuses = result;
      }

    return statuses;

  }



  const handleGetOrderCarriers = async () => {
    let result = null;
    let carriers = [];
    try {
      result = await axiosCall({ url: `Order/db/orderCarriers` });
    } catch (error) {
      console.log(error);
    }
      if (result) {
        carriers = result;
      }

    return carriers;

  }

  const handlePopulateOrderData = async () => {
    const orderStatuses = await handleGetOrderStatuses();
    const orderCarriers = await handleGetOrderCarriers();
    setStatusesList(orderStatuses);
    setCarrierList(orderCarriers);
  }

  useInitialMount(handlePopulateOrderData);


  const handleSubmit = () => {
    let data = {
      id: '',
      size: size,
      shippingAddressLine1: shippingAddressLine1,
      shippingAddressLine2: shippingAddressLine2,
      status: status,
      shippingCity: shippingCity,
      shippingState: shippingState,
      shippingZip: shippingZip,
      quantity: quantity,
      carrier: carrier,
      color: color,
      trackingNumber: trackingNumber

    };
    data = { ...data, id: props.Order.id };
    props.onSubmit(data);
    props.onClose();

  };


  return (
    <Dialog maxWidth="lg" open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {props.edit ? t('editOrder') : t('createOrder')}
          </Typography>
          <Close
            style={{ width: '12px', cursor: 'pointer' }}
            color="var(--gray210)"
            onClick={() => props.onClose()}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '40vw' }}>
        <StyledStepper
          // themecolor={theme.palette.blue['210']}
          activeStep={activeStep}
          alternativeLabel>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </StyledStepper>
          <Stack>
            <TextField
              sx={{...mt}}
              select
              size="small"
              color="info"
              variant="outlined"
              label={t('status')}
              onChange={(e) => setStatus(e.target.value)}
              value={status}>
              {statusesList.map((al) => {
                return (
                  <MenuItem key={al} value={al}>
                    {al}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              autoFocus
              color="info"
              label={`${t('shippingAddressLine1')}*`}
              onChange={(e) => setShippingAddressLine1(e.target.value)}
              size="small"
              sx={{...mt}}
              value={shippingAddressLine1}
              variant="outlined"
            />
            <TextField
              color="info"
              label={`${t('shippingAddressLine2')}*`}
              multiline
              onChange={(e) => setShippingAddressLine2(e.target.value)}
              rows={1}
              size="small"
              sx={{...mt}}
              value={shippingAddressLine2}
              variant="outlined"
            />
            <TextField
              color="info"
              label={`${t('shippingCity')}`}
              multiline
              onChange={(e) => setShippingCity(e.target.value)}
              rows={1}
              size="small"
              sx={{...mt}}
              value={shippingCity}
              variant="outlined"
            />
            <TextField
              color="info"
              label={`${t('shippingState')}`}
              multiline
              onChange={(e) => setShippingState(e.target.value)}
              rows={1}
              size="small"
              sx={{...mt}}
              value={shippingState}
              variant="outlined"
            />
            <TextField
              color="info"
              label={`${t('shippingZip')}`}
              multiline
              onChange={(e) => setShippingZip(e.target.value)}
              rows={1}
              size="small"
              sx={{...mt}}
              value={shippingZip}
              variant="outlined"
            />
            <br/>
          <TextField
            size="small"
            color="info"
            variant="outlined"
            label={t("unitSizeCol")}
            value={size}
            onChange={(e) => {
              setSize(e.target.value);
            }}
          />
          <br/>
          <TextField
            size="small"
            color="info"
            variant="outlined"
            label={t("unitColorCol")}
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
            }}
          />
          <br/>
          <TextField
            type="number"
            size="small"
            color="info"
            variant="outlined"
            label={t("quantityCol")}
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
          <TextField
            sx={{...mt}}
            select
            size="small"
            color="info"
            variant="outlined"
            label={t('carrierCol')}
            onChange={(e) => setCarrier(e.target.value)}
            value={carrier}>
            {carrierList.map((al) => {
              return (
                <MenuItem key={al} value={al}>
                  {al}
                </MenuItem>
              );
            })}
          </TextField>
            <br/>
           <TextField
            size="small"
            color="info"
            variant="outlined"
            label={t("trackingNumberCol")}
            value={trackingNumber}
            onChange={(e) => {
              setTrackingNumber(e.target.value);
            }}
          />

          </Stack>
      </DialogContent>
      <DialogActions>
          <Button
            disabled={quantity < 0 || quantity === ''}
            variant="contained" color="info"
            onClick={() => handleSubmit()}>
            {t('doneButton')}
          </Button>

      </DialogActions>
    </Dialog>
  );
};

export default EditOrderDialog;
