import React from 'react';
import * as PullCreditMenu from '@components/PullCreditMenu/PullCreditMenu';
import InputStyles from '@styles/Input.module.css';
import PullCreditMenuStyles from '@styles/PullCreditMenu.module.css';

interface Props {
  title: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  missing: boolean;
  disabled: boolean;
}

/**
 * Input sub component.
 */
export default function Input(props: Props) {
  return (
    <div className={PullCreditMenuStyles.formItem}>
      <h2 className={PullCreditMenuStyles.h2}>{props.title}</h2>
      <input
        className={PullCreditMenu.getClassName([InputStyles.input], props.disabled, props.missing)}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </div>
  );
}
