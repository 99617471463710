import React, { useEffect, useRef, useState } from 'react';

import {
    BannerMiddleCircleSinglePoint,
    BannerMiddleCircleDoublePoint,
    BannerBaseMobile,
    dialogPopupFinal,
    angelTalkGif,
    AngelCircle
} from './images';

import '@styles/ReloadBanner/main.css';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import { useAppContext } from '@contexts/AppContext';
import { styles } from './styles';
import { PatentButton } from './PatentButton';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';

// Starter Banner Component
const ReloadBanner = (props) => {
    const { serviceWorkerWrapperRef } = useAppContext();
    const { morphType, setMorphType } = useErrandContext();
    const [fadingOut, setFadingOut] = useState(false);
    const bannerImageRef = useRef(null);
    const wasClicked = useRef(false);
    const tmRef = useRef(null);

    // mount
    useEffect(() => {
        // unmount
        return () => {
            clearTimeout(tmRef.current);
        }
    }, [])

    useEffect(() => {
        if (morphType !== MorphType.None) setMorphType(MorphType.None);
    }, [morphType])

    const onInstallClick = () => {
        if (serviceWorkerWrapperRef.current.reloadPage) {
            // for smooth fading animation
            setFadingOut(true);
            // check to avoid double/multiple spam clicks
            if (wasClicked.current === false) {
                wasClicked.current = true;
                if (tmRef.current) clearTimeout(tmRef.current);
                tmRef.current = setTimeout(() => {
                    serviceWorkerWrapperRef.current.reloadPage();
                }, 1200);
            }
        } else {
            console.error('ReloadBanner.tsx: serviceWorkerWrapperRef.current.reloadPage is undefined/null!');
        }
    }

    return (
        <div style={styles.bannerContainer} className={`reveal ${fadingOut ? 'fadeOutLateLate' : ''}`}>
            <img
                style={styles.bannerImage}
                src={BannerBaseMobile}
                ref={bannerImageRef}
                className={['reveal-item', fadingOut ? 'fadeOutLateLate' : ''].join(' ')}
            />
            <div style={styles.mainContainer} className={'reveal-item-patent'}>
                <PatentButton text="Patent Info" isMobile={isMobileOrTablet()} />
                {/* Uncomment when needed */}
                {/* <p style={{ fontWeight: 'bold', fontFamily: 'Poppins' }}>
                    Need Help?
                </p> */}
            </div>
            {/* Middle Logo */}
            <div style={styles.circleImageContainer} className={
                isMobileOrTablet()
                    // mobile view
                    ? ['reveal-item-late-mobile mobile-circle-image-container', fadingOut ? 'fadeOutLateMobile' : ''].join(' ')
                    // desktop view
                    : ['reveal-item-late', fadingOut ? 'fadeOutLate' : null].join(' ')
            }>
                <div style={styles.popupContainer}>
                    <img src={dialogPopupFinal} style={styles.popup} />
                    <p style={styles.popupText}>Great news!<br />A new version<br /> has been<br /> released!</p>
                </div>
                <img src={angelTalkGif} style={styles.angel} />
                <div style={styles.circleBackgroundImage} />
                <img style={styles.circleImage} src={AngelCircle} />
                <img style={{ ...styles.middleCirclePoints, ...styles.middleCircleSinglePoints }} src={BannerMiddleCircleSinglePoint} />
                <img style={{ ...styles.middleCirclePoints, ...styles.middleCircleTwoPoints }} src={BannerMiddleCircleDoublePoint} />
                <button onClick={onInstallClick} className='tapToInstallButton' style={styles.clickMeButton}>
                    {isMobileOrTablet() ? "Tap to Reload" : "Click to Reload"}
                </button>
            </div>
        </div>
    );
};

export default ReloadBanner;
