/**
 * @file This file handles and renders the UI for the blockchain wallet.
 * @author Timur Bickbau
 */

import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Styles from '@styles/MorphWallet.module.css';
import { ArrowBack, ArrowDropDown, Clear, Error as ErrorIcon, Refresh, Warning } from '@mui/icons-material';
import { Page, Tab, Color, UI, Event, NftType, NftUserType, NftList, Token, MorphWalletUtils } from '@components/MorphWallet/MorphWalletUtils';
import axiosCall from '@services/axios';
import { useSocketContext } from '@contexts/socket';
import { isMobile } from '@common/deviceTypeHelper';
import { IErrand } from '@interfaces/Conversation';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import ThinClientUtils from '@common/ThinClientUtils';
import { LinearProgress } from '@mui/material';
import LanguageUtils from '@common/LanguageUtils';
import { UiText } from '@components/MorphWallet/MorphWalletUiText';
import { useUserContext } from '@contexts/user';

type MorphWalletProps = {
  errand: IErrand;
};

/**
 * Returns the full CDN URL for the gif to be displayed for ticket NFTs
 */
const getGifUrl = (eventName: string) => `${process.env.REACT_APP_MORGAN_CDN}/Images/${eventName}NftEventAngel.gif`;

const MorphWallet: React.FC<React.PropsWithChildren<MorphWalletProps>> = ({ errand }) => {
  /**
   * Testing Variables (be careful committing any changes to this section!)
   * */

  // if you want to test the mobile app flow in the web view (for mobile styling on desktop, open browser console and select the mobile view)
  const TESTING_MOBILE_APP_FLOW_ON_WEB = false; // expected value: false

  // if you want to preserve the wallet keys between web sessions (i.e. store in local storage instead of session storage)
  const PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS = false; // expected value: false

  /**
   * Enums and Interfaces
   * */

  // whether an asynchronous function is still in progress, has succeeded, or has failed
  enum AsyncStatus {
    Waiting,
    Success,
    Failure
  }

  // the reason we are entering the access PIN
  enum AccessPinFunction {
    CreateAccount,
    RecoverAccount,
    ViewRecoveryPhrase,
    Login,
    UpdatingEnterOldPin,
    UpdatingEnterNewPin,
    AddUser,
    RemoveUser,
    ToggleNftPublicness,
    BurnNft,
    MintBidNft,
    AcceptBid
  }
  const accessPinFunction = useRef<AccessPinFunction>(null);

  /**
   * Variables for Account Keys
   * */

  // the blockchain account's mnemonic phrase (should almost always be null), private key, and public address
  const keys = useRef<{ mnemonicPhrase: string, privateKey: string, publicAddress: string }>(null);

  // the blockchain account keys, encrypted behind the user's access PIN
  const encryptedKeys = useRef<string>(null);

  const mnemonicInputIds = MorphWalletUtils.getInputIds(MorphWalletUtils.NUM_MNEMONIC_WORDS);

  /**
   * Variables for Code Entry
   * */

  // the access PIN entered by the user
  const accessPinInput = useRef<number[]>(new Array(MorphWalletUtils.ACCESS_PIN_LENGTH));

  const accessPinInputIds = MorphWalletUtils.getInputIds(MorphWalletUtils.ACCESS_PIN_LENGTH);

  // which text we show above the access PIN entry
  const [accessPinText, setAccessPinText] = useState<UiText>(null);

  // we may want to revert to the previous access PIN text (from 'Please wait...') if there is an error after the access PIN is entered
  const prevAccessPinText = useRef<UiText>(null);

  // whether to show the "Reset PIN" button
  const [showResetPin, setShowResetPin] = useState<boolean>(false);

  // the link device code entered by the user
  const linkDeviceCodeInput = useRef<number[]>(new Array(MorphWalletUtils.LINK_DEVICE_CODE_LENGTH));

  // code for linking wallet to another device
  const linkDeviceCode = useRef<string[]>([]);

  const linkDeviceCodeInputIds = MorphWalletUtils.getInputIds(MorphWalletUtils.LINK_DEVICE_CODE_LENGTH);

  // the progress of the link device timer (note that maximum must be 100)
  const MAX_TIMER_PROGRESS = 100;
  const [linkDeviceTimerProgress, setLinkDeviceTimerProgress] = useState<number>(MAX_TIMER_PROGRESS);
  
  /**
   * Variables for NFT List View
   * */

  // all the NFTs of which the user is an owner or approved user
  const myNfts = useRef<Token[]>([]);

  // all the NFTs on which a Purchase Bid can be made
  const biddableNfts = useRef<Token[]>([]);

  // all the NFTs which are bids on the currently selected NFT
  const bidsOnNft = useRef<Token[]>(null);

  // which NFT types we are currently displaying in the list view
  const [displayedNftTypes, setDisplayedNftTypes] = useState<string[]>(Object.keys(NftType));

  // array of preview fields that will be shown for each NFT
  interface PreviewFields {
    nftType: string,
    fields: string[]
  }
  const previewFields = useRef<PreviewFields[]>([]);
  const previewFieldsStatus = useRef<AsyncStatus>(AsyncStatus.Waiting);

  // whether we are currently refreshing the NFT list view
  const [refreshingNftList, setRefreshingNftList] = useState<boolean>(false);

  /**
   * Variables for NFT Single View
   * */

  // the QR code for the NFT
  const [qrCode, setQrCode] = useState<string>('');

  // the currently selected My NFT
  const selectedMyNft = useRef<Token>(null);

  // the currently selected Bid on NFT
  const selectedBidOnNft = useRef<Token>(null);

  // the currently selected Biddable NFT
  const selectedBiddableNft = useRef<Token>(null);

  // the accepted TRUBID NFT being viewed from a TRUAPP NFT
  const acceptedBidNft = useRef<Token>(null);

  // the attached TRUAPP NFT being viewed from a TRUBID NFT
  const attachedNft = useRef<Token>(null);

  // whether we are showing certain fields in the single-view metadata (as opposed to hiding them behind a "Show" button)
  const [showSmartContractAddress, setShowSmartContractAddress] = useState<boolean>(false);
  const [showConfirmationCode, setShowConfirmationCode] = useState<boolean>(false);

  // which text shows on the 'Make NFT Public' button
  const [toggleNftPublicnessText, setToggleNftPublicnessText] = useState<UiText>(null);

  // which text shows on the 'Burn NFT' button
  const [burnNftText, setBurnNftText] = useState<UiText>(null);

  // the list of approved users for the currently selected loan
  const [approvedUsers, setApprovedUsers] = useState<string[]>([]);

  // the currently selected approved user for removing
  const [userToBeRemoved, setUserToBeRemoved] = useState<string>(null);

  // the ID of the input used for adding approved users
  const ADD_USER_INPUT_ID = 'add-user-input';
  // the entered user for approving on a token
  const userToBeAdded = useRef<string>(null);

  // which text shows on the 'View Bids' button
  const [viewBidsText, setViewBidsText] = useState<UiText>(null);

  // which text shows on the 'View Accepted Bid' button
  const [viewAcceptedBidText, setViewAcceptedBidText] = useState<UiText>(null);

  // which text shows on the 'View Attached NFT' button
  const [viewAttachedNftText, setViewAttachedNftText] = useState<UiText>(null);

  // which text shows on the 'Accept Bid' button
  const [acceptBidText, setAcceptBidText] = useState<UiText>(null);

  /**
   * Variables for Bidding
   * */

  // the NFT on which the user is about to bid
  const nftToBid = useRef<Token>(null);

  // the text which appears when or after making a bid
  const [purchaseOfferText, setPurchaseOfferText] = useState<UiText>(null);

  // the purchase price offered for bidding
  const purchaseOffer = useRef<number>(null);

  /**
   * Other Variables
   * */

  const socketContext = useSocketContext();
  const errandContext = useContext<IErrandContext>(ErrandContext);

  const userId: string = useUserContext()?._id;

  // the translated text in the UI
  const currentLanguage = useRef<string>(null);
  const [translatedUiText, setTranslatedUiText] = useState<{ name: string, text: string }[]>([]);

  // the current page in the wallet UI
  const [activePage, setActivePage] = useState<Page>(Page.Welcome);

  // the current tab in the wallet UI
  const [activeTab, setActiveTab] = useState<Tab>(null);

  // whether all buttons should be disabled (usually when waiting for something to load)
  const [allButtonsDisabled, setAllButtonsDisabled] = useState<boolean>(false);

  // error string for display in UI
  const [displayedError, setDisplayedError] = useState<UiText>(null);

  // whether we are on a small mobile screen (less than 400px width)
  const smallMobileScreen = isMobile() && window.innerWidth < 410;

  // holds active loan numbers held in context to use for the minting of NFTs via workflow
  const loanNumbers = useMemo(() => {
    if (!errand.context || errand.context?.length === 0) {
      return [];
    }
    
    return errand.context?.filter((x: any) => x?.indexField?.name === 'loanNumber').map((x: any) => x?.indexField?.value);
  }, [errand.context]);

  /**
   * Functions
   * */

  /**
   * @description Get the token being viewed in the single NFT view.
   * @param {NftList} nftList The list from which that NFT was selected; if null, assume viewing NFT from elsewhere (e.g., an attached NFT).
   * */
  const getSingleViewNft = useCallback((nftList: NftList = null) => {
    switch (activePage) {
      case Page.AcceptedBidSingleView:
        return acceptedBidNft.current;
      case Page.AttachedNftSingleView:
        return attachedNft.current;
      default:
        return getSelectedNft(nftList);
    }
  }, [activePage]);

  /**
   * @description Get the array of IDs for the input elements containing the digits of a code (e.g., access PIN).
   * */
  const getInputIds = useCallback(() => {
    switch (activePage) {
      case Page.AccessPin:
        return accessPinInputIds;
      case Page.LinkDeviceMobile:
        return linkDeviceCodeInputIds;
    }
  }, [activePage, accessPinInputIds, linkDeviceCodeInputIds]);

  /**
   * @description Get the array containing the user input for a code (e.g., access PIN).
   * */
  const getCodeInput = () => {
    switch (activePage) {
      case Page.AccessPin:
        return accessPinInput.current;
      case Page.LinkDeviceMobile:
        return linkDeviceCodeInput.current;
    }
  }

  /**
   * @description Reset the entered code (e.g., access PIN) when the user presses the 'Reset' button.
   * */
  const handleResetCode = useCallback(() => {
    const inputIds = getInputIds();

    // clear the UI
    for (const inputId of inputIds) {
      const codeInputElement = document?.getElementById(inputId) as HTMLInputElement;
      if (codeInputElement) {
        codeInputElement.value = '';
      }
    }

    // clear the code input
    switch (activePage) {
      case Page.AccessPin:
        accessPinInput.current = new Array(MorphWalletUtils.ACCESS_PIN_LENGTH);
        setShowResetPin(false);
        break;
      case Page.LinkDeviceMobile:
        linkDeviceCodeInput.current = new Array(MorphWalletUtils.LINK_DEVICE_CODE_LENGTH);
        break;
    }
    
    // refocus on the first digit (setTimeout necessary or refocus won't work)
    setTimeout(() => document.getElementById(inputIds[0])?.focus(), 1);
  }, [getInputIds, activePage]);

  const goToAccessPin = useCallback(() => {
    // if updating PIN
    if (activePage === Page.AccessPin) {
      handleResetCode();

      // need to reset variables as useLayoutEffect() will not trigger when staying on same page
      setDisplayedError(null);
      setAllButtonsDisabled(true);
    }

    switch (accessPinFunction.current) {
      case AccessPinFunction.UpdatingEnterOldPin:
        setAccessPinText(UiText.EnterOldPin);
        break;
      case AccessPinFunction.UpdatingEnterNewPin:
      case AccessPinFunction.CreateAccount:
      case AccessPinFunction.RecoverAccount:
        setAccessPinText(UiText.EnterNewPin);
        break;
      default:
        setAccessPinText(UiText.EnterYourPin);
    }

    setShowResetPin(false);
    setActivePage(Page.AccessPin);
  }, [activePage, handleResetCode, AccessPinFunction]);

  /**
   * @description Get the translated version of a given UiText. Only call this from React components.
   * @param {UiText} text The translated text to be retrieved.
   * */
  const tWallet = useCallback((text: UiText) => {
    if (!text) {
      return '';
    }

    if (LanguageUtils.fetchLocalizationLanguageSetting() === 'en') {
      return text;
    }

    const uiTextKey = Object.keys(UiText).find(uiText => UiText[uiText] === text);
    return translatedUiText.find(translatedText => translatedText.name === uiTextKey)?.text || text;
  }, [translatedUiText]);

  /**
   * @description Go to the single view of a listed NFT.
   * @param {NftList} nftList The NFT list which contains that NFT.
   * @param {Token} token The token being viewed; unnecessary if already retrievable from getSelectedNft().
   * */
  const goToNftSingleView = useCallback(async (nftList: NftList, token: Token = null) => {
    // sometimes this function is called from components which cannot be disabled, so this makes them act disabled
    if (allButtonsDisabled) {
      return;
    }

    setAllButtonsDisabled(true);

    const handleError = () => {
      setDisplayedError(UiText.errAccessToken);
      setAllButtonsDisabled(false);
    }

    // this function is only applicable to single views of NFTs associated with an NftList
    if (nftList === null) {
      console.error('goToNftSingleView.1: No NFT list to come from.');
      handleError();
      return;
    }

    // if coming directly from the list view (i.e., not coming 'back' from another NFT like an approved bid)
    if (token) {
      setSelectedNft(nftList, token);
    }

    if (!getSelectedNft(nftList)) {
      console.error('goToNftSingleView.2: No selected NFT.');
      handleError();
      return;
    }

    // find the token we are going to
    const selectedNft = getSelectedNft(nftList);

    if (!selectedNft) {
      console.error('goToNftSingleView.3: Selected NFT not found.');
      handleError();
      return;
    }

    if (!selectedNft?.contractAddress || !selectedNft?.nftType || !selectedNft?.owner) {
      console.error(`goToNftSingleView.4: Missing data for token ${selectedNft.tokenId}.`);
      handleError();
      return;
    }

    // check if the NFT has an accepted bid
    if (nftList === NftList.MyNfts && selectedNft.nftType === NftType.TRUAPP && activePage !== Page.AcceptedBidSingleView) {
      acceptedBidNft.current = null;

      try {
        const { bidTokenId, bidContractAddress, error } = await axiosCall({
          url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getAcceptedBid`,
          method: 'POST',
          data: {
            ...selectedNft
          }
        });

        // if the NFT has an accepted bid
        if (!error && (bidTokenId || bidTokenId === 0) && bidContractAddress) {
          acceptedBidNft.current = {
            tokenId: bidTokenId,
            contractAddress: bidContractAddress,
            name: MorphWalletUtils.getNftName(bidContractAddress, bidTokenId),
            nftType: NftType.TRUBID,
            userType: null,
            owner: null,
            metaData: null,
            isPublic: true,
            isBurned: false
          }
        }
      } catch (err) {
        console.error('goToNftSingleView.5: No accepted bid found.');
      }
    }

    // proceed to single view
    switch (nftList) {
      case NftList.MyNfts:
        setToggleNftPublicnessText(selectedNft.isPublic ? UiText.errNftPrivate : UiText.errNftPublic);
        setBurnNftText(selectedNft.isBurned ? UiText.NftBurned : UiText.BurnNft);
        setViewBidsText(UiText.ViewBids);
        setViewAcceptedBidText(UiText.ViewAcceptedBid);
        setViewAttachedNftText(UiText.ViewAttachedNft);
        setActivePage(Page.MyNftsSingleView);
        break;
      case NftList.BidsOnNft:
        switch (activePage) {
          case Page.BidsOnNftListView:
            setAcceptBidText(UiText.AcceptBid);
            setActivePage(Page.BidsOnNftSingleView);
            break;
          case Page.AccessPin:
            setActivePage(Page.BidsOnNftSingleView);
            break;
        }
        break;
      case NftList.BiddableNfts:
        switch (activePage) {
          case Page.BiddableNftsListView:
            setPurchaseOfferText(UiText.EnterPurchaseOffer);
            setActivePage(Page.BiddableNftsSingleView);
            break;
          case Page.AccessPin:
            setActivePage(Page.BiddableNftsSingleView);
            break;
        }
        break;
    }

    setAllButtonsDisabled(false);
  }, [activePage, allButtonsDisabled]);

  /**
   * @description Accept a Purchase Bid on the selected TRU-Approval NFT.
   * */
  const acceptBid = useCallback(async () => {
    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/acceptBid`,
        method: 'POST',
        data: {
          privateKey: keys.current?.privateKey,
          ...getSelectedNft(NftList.BidsOnNft)
        }
      });

      if (error) {
        console.error(`acceptBid.1: ${error}.`);
        setDisplayedError(UiText.errAcceptBid);
        return;
      }
    } catch (err) {
      console.error(`acceptBid.2: ${err.message}`);
      setDisplayedError(UiText.errAcceptBid);
      return;
    }

    setAcceptBidText(UiText.BidAccepted);
    await goToNftSingleView(NftList.MyNfts);
  }, [goToNftSingleView]);

  /**
   * @description Burn an NFT.
   * */
  const burnNft = useCallback(async () => {
    const handleError = () => {
      setDisplayedError(UiText.errBurnNft);
      setBurnNftText(UiText.BurnNft);
    }

    const token = selectedMyNft.current;

    if (!token) {
      console.error('burnNft.1: Token not found.');
      handleError();
      return;
    }

    // burn the NFT
    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/burnNft`,
        method: 'POST',
        data: {
          privateKey: keys.current?.privateKey,
          ...token
        }
      });

      if (error) {
        console.error(`burnNft.2: ${error}`);
        handleError();
        return;
      }
    } catch (err) {
      console.error(`burnNft.3: ${err.message}`);
      handleError();
      return;
    }

    // show as burned in UI (no need to pull the token from smart contract anymore since it's burned)
    token.isBurned = true;
    setBurnNftText(UiText.NftBurned);
    await goToNftSingleView(NftList.MyNfts);
  }, [goToNftSingleView]);

  /**
   * @description Take the user to the NFT marketplace.
   * */
  const goToMarketplace = useCallback(() => {
    setActiveTab(Tab.Marketplace);
    setActivePage(Page.Marketplace);
  }, []);

  /**
   * @description Pull the most recent version of the given NFT list.
   * @param {NftList} nftList The NFT list being pulled.
   * */
  const getNfts = useCallback(async (nftList: NftList) => {
    // get the NFT list from Malcom
    const requestNftList = async () => {
      try {
        switch (nftList) {
          case NftList.MyNfts:
            // get the list of My NFTs
            return await axiosCall({
              url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getMyNfts`,
              method: 'POST',
              data: {
                privateKey: keys.current?.privateKey,
                publicAddress: keys.current?.publicAddress
              }
            });
          case NftList.BidsOnNft:
            // get the bids on the currently selected My NFT
            return await axiosCall({
              url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getBidsOnNft`,
              method: 'POST',
              data: {
                publicAddress: keys.current?.publicAddress,
                ...selectedMyNft.current
              }
            });
          case NftList.BiddableNfts:
            // get the list of biddable NFTs
            return await axiosCall({
              url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getBiddableNfts`,
              method: 'POST'
            });
        }
      } catch (err) {
        return { error: `requestNfts: ${err.message}` };
      }
    }

    const listResponse = await requestNftList();

    if (listResponse?.error) {
      console.error(`getNfts: ${listResponse.error}`);
      return false;
    }

    // where in the list response to look for the NFT list
    const getResponseNftList = () => {
      switch (nftList) {
        case NftList.MyNfts:
          return listResponse.nftIds;
        case NftList.BidsOnNft:
          return listResponse.bidTokenIds;
        case NftList.BiddableNfts:
          return listResponse.nftList;
      }
    }

    // get data for each token and prepare to add it to the UI list
    const nfts: Token[] = [];
    for (const responseNftData of getResponseNftList()) {
      // get the NFT data
      const nftData = await MorphWalletUtils.getNftData(listResponse, responseNftData, nftList, keys.current?.privateKey, keys.current?.publicAddress);

      // perform validations
      const validationResponse = MorphWalletUtils.validateNftData(nftData, nftList);
      if (validationResponse?.error) {
        console.error(`requestNftList: Token with ID ${nftData?.tokenId} returned error '${validationResponse.error}'. Continuing without token.`);
        continue;
      }

      // get event data from Rest for event ticket NFT
      if (nftData?.nftType === NftType.TICKET) {
        const eventName = nftData?.metaData?.eventName;
        const response = await MorphWalletUtils.getTicketData(eventName);

        if (response?.error || !response?.data) {
          console.error(`requestNftList: ${response?.error || 'No ticket data found.'}`);
          continue;
        }

        nftData.metaData.eventDisplay = response.data?.eventDisplay || eventName;
        nftData.metaData.eventDate = response.data?.eventDate;
      }

      // prepare to add the NFT to the UI list
      nfts.push({
        ...nftData,
        tokenId: Number(nftData?.tokenId),
        name: MorphWalletUtils.getNftName(nftData?.contractAddress, nftData?.tokenId),
        isBurned: false
      });
    };

    // clear out the current list; do not do this earlier or the user will briefly see an empty list when refreshing
    getNftList(nftList).splice(0, getNftList(nftList).length);

    // add the token to the UI list
    getNftList(nftList)?.push(...nfts);

    // sort by token name
    if (getNftList(nftList)?.length > 1) {
      getNftList(nftList)?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    return true;
  }, []);

  /**
   * @description Go to the single view of a listed NFT.
   * @param {NftList} nftList The NFT list being viewed.
   * @param {boolean} reloadNfts Whether we need to request the NFT list from the blockchain.
   * */
  const goToNftListView = useCallback(async (nftList: NftList, reloadNfts: boolean = false) => {
    setAllButtonsDisabled(true);

    if (nftList === NftList.BidsOnNft) {
      setViewBidsText(UiText.GettingBids);
    }

    const handleError = () => {
      setAllButtonsDisabled(false);
      switch (nftList) {
        case NftList.MyNfts:
          setDisplayedError(UiText.errAccessWallet);
          return;
        case NftList.BidsOnNft:
          setDisplayedError(UiText.errAccessBidsOnNft);
          setViewBidsText(UiText.ViewBids);
          return;
        case NftList.BiddableNfts:
          setDisplayedError(UiText.errAccessMarketplace);
          return;
      }
    }

    // make sure user is logged in to a blockchain account (in case we are accessing this page from outside the UI)
    if (!keys.current?.privateKey || !keys.current?.publicAddress) {
      // for some reason, pressing "Continue" (but not back button) in Page.ViewRecoveryPhrase triggers this erroneously
      console.error('goToNftListView.1: No keys found.');
      handleError();
      return false;
    }
    
    // wait up to 5 seconds for asynchronous getPreviewFields() to finish
    for (let i = 0; i < 10; i++) {
      if (previewFieldsStatus.current !== AsyncStatus.Waiting) {
        break;
      }

      // if not finished, wait 0.5 seconds
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    // pull the most recent version of the given NFT list
    if (reloadNfts) {
      if (!(await getNfts(nftList))) {
        console.error('goToNftListView.2: Failed to load user NFTs.');
        handleError();
        return false;
      }
    }

    // proceed to list view
    switch (nftList) {
      case NftList.MyNfts:
        // remove token from list, if just burned in single view
        if (activePage === Page.MyNftsSingleView && selectedMyNft.current?.isBurned) {
          const index = myNfts.current.findIndex(token => token.isBurned);
          if (index !== -1) {
            myNfts.current.splice(index, 1);
          }
        }

        setActiveTab(Tab.MyWallet);
        setActivePage(Page.MyNftsListView);
        break;
      case NftList.BidsOnNft:
        setActivePage(Page.BidsOnNftListView);
        break;
      case NftList.BiddableNfts:
        setActiveTab(Tab.Marketplace);
        setActivePage(Page.BiddableNftsListView);
        break;
    }

    setAllButtonsDisabled(false);
    setDisplayedNftTypes(Object.keys(NftType));
    return true;
  }, [AsyncStatus.Waiting, activePage, getNfts]);

  /**
   * @description Mint a Purchase Bid NFT, for the selected TRU-Approval NFT.
   * */
  const mintBidNft = useCallback(async () => {
    if (!nftToBid.current) {
      console.error('mintBidNft.1: Missing NFT on which to bid.');
      setDisplayedError(UiText.errMintBidNft);
      return;
    }

    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/mintBidNft`,
        method: 'POST',
        data: {
          nftType: NftType.TRUBID,
          publicAddress: keys.current?.publicAddress,
          purchaseOffer: purchaseOffer.current,
          ...nftToBid.current
        }
      });

      if (error) {
        console.error(`mintBidNft.2: ${error}.`);
        setDisplayedError(UiText.errMintBidNft);
        return;
      }
    } catch (err) {
      console.error(`mintBidNft.3: ${err.message}`);
      setDisplayedError(UiText.errMintBidNft);
      return;
    }

    setPurchaseOfferText(UiText.BidSubmitted);
    await goToNftSingleView(NftList.BiddableNfts);
  }, [goToNftSingleView]);

  /**
   * @description Mint a TRU-Approval NFT.
   * */
  const mintNftFromLoanNumber = useCallback(async () => {
    try {
      await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/mintNft`,
        method: 'POST',
        data: {
          nftType: 'TRUAPP',
          privateKey: keys.current?.privateKey,
          publicAddress: keys.current?.publicAddress,
          loanNo: loanNumbers[0],
          chatId: errand._id
        }
      });

      errandContext.setBypassToMintFlag(false);
    } catch (err) {
      console.error(`mintNftFromLoanNumber: ${err.message}`);
    }
  }, [errand._id, errandContext, loanNumbers]);

  /**
   * @description Make a private NFT public, or vice versa.
   * */
  const toggleNftPublicness = useCallback(async () => {
    const token = selectedMyNft.current;

    const handleError = () => {
      if (!token) {
        setDisplayedError(UiText.errRequest);
      } else {
        setDisplayedError(token.isPublic ? UiText.MakeNftPrivate : UiText.MakeNftPublic);
      }
    }

    if (!token) {
      console.error('toggleNftPublicness.1: Token not found.');
      handleError();
      return;
    }
    
    // toggle the NFT publicness (make it public if currently private, or vice versa)
    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/${token.isPublic ? 'makeNftPrivate' : 'makeNftPublic'}`,
        method: 'POST',
        data: {
          privateKey: keys.current?.privateKey,
          ...token
        }
      });

      if (error) {
        console.error(`toggleNftPublicness.2: ${error}`);
        handleError();
        return;
      }
    } catch (err) {
      console.error(`toggleNftPublicness.3: ${err.message}`);
      handleError();
      return;
    }

    // we could instead reload all the NFTs to pull the updated value but this would take a while for the same effect
    token.isPublic = !token.isPublic;
    setToggleNftPublicnessText(token.isPublic ? UiText.MakeNftPrivate : UiText.MakeNftPublic);
    await goToNftSingleView(NftList.MyNfts);
  }, [goToNftSingleView]);

  /**
   * @description Add or remove a user from the approved list of an NFT.
   * @param {boolean} adding Whether we are adding a 
   * */
  const updateApprovedUser = useCallback(async (addingUser: boolean) => {
    setAllButtonsDisabled(true);

    const handleError = () => {
      setDisplayedError(addingUser ? UiText.errAddUser : UiText.errRemoveUser);
      setActivePage(Page.ApprovedUsers);
    }

    const token = selectedMyNft.current;

    if (!token) {
      console.error('updateApprovedUser.1: Token not found.');
      handleError();
      return;
    }

    if (addingUser ? !userToBeAdded.current : !userToBeRemoved) {
      console.error(`updateApprovedUser.2: No user ${addingUser ? 'entered' : 'selected'}.`);
      handleError();
      return;
    }

    try {
      const { error } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/updateApprovedUser`,
        method: 'POST',
        data: {
          privateKey: keys.current?.privateKey,
          publicAddress: addingUser ? userToBeAdded.current : userToBeRemoved,
          addingUser,
          ...token
        }
      });

      if (error) {
        console.error(`updateApprovedUser.3: ${error}`);
        handleError();
        return;
      }
    } catch (err) {
      console.error(`updateApprovedUser.4: ${err.message}`);
      handleError();
      return;
    }

    // manually update the approved users list in the UI instead of pulling it again
    setApprovedUsers(addingUser ? [...approvedUsers, userToBeAdded.current] : approvedUsers.filter(user => user !== userToBeRemoved));

    userToBeAdded.current = null;
    setUserToBeRemoved(null);

    setActivePage(Page.ApprovedUsers);
  }, [approvedUsers, userToBeRemoved]);

  /**
   * @description Mint or assign an event ticket NFT (depending on the event configuration)
   * */
  const handleTicketNft = useCallback(async (nftData: any) => {
    try {
      const { ticketHash, eventName, eventConfig, numAdditionalGuests } = nftData;

      // Determine if a new NFT should be minted or an existing one should be transferred
      if (eventConfig?.valTicketMint === true) {
        const request = {
          ownerAddress: keys.current.publicAddress,
          eventName,
          eventDisplay: eventConfig.eventDisplay || eventName,
          hashString: ticketHash,
          guestPrivateKey: keys.current.privateKey,
          chatId: errand._id,
        };

        // Mint and transfer a ticket to the user's wallet
        await axiosCall({
          url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/mintTicketNft`,
          method: 'POST',
          data: request,
        });
      } else {
        const request: Record<string, any> = {
          eventName,
          ticketHash,
          guestPrivateKey: keys.current.privateKey,
          eventDisplay: eventConfig.eventDisplay || eventName,
          contractAddress: eventConfig.contractAddress,
          chatId: errand._id,
        };
        if (typeof numAdditionalGuests === 'number') {
          request.additionalGuests = numAdditionalGuests;
        }

        // Transfer an already minted ticket to the user's wallet
        await axiosCall({
          url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/event-tickets/assignEventTicketAndHash`,
          method: 'POST',
          data: request,
        });
      }
    } catch (e) {
      console.error(`Error occurring attempting to transfer NFT: ${e}`);
      if (e instanceof window.Error) {
        const message = e.message.toLowerCase();
        if (message.includes("no tokens remaining")) {
          setDisplayedError(UiText.errNoTicketsRemaining);
        } else if (message.includes("user already owns nft")) {
          setDisplayedError(UiText.errUserAlreadyOwnsNft);
        } else {
          setDisplayedError(UiText.errTransferEventNft);
        }
      } else {
        setDisplayedError(UiText.errTransferEventNft);
      }
    } finally {
      errandContext.setBypassToMintFlag(false);
      errandContext.setNftData(null);
    }
  }, [errand._id, errandContext]);

  /**
   * @description Prepare to view the user's recovery phrase.
   * */
  const goToViewRecoveryPhrase = useCallback((requirePin: boolean = true) => {
    if (requirePin) {
      accessPinFunction.current = AccessPinFunction.ViewRecoveryPhrase;
      goToAccessPin();
    } else {
      setActivePage(Page.ViewRecoveryPhrase);
    }
  }, [AccessPinFunction.ViewRecoveryPhrase, goToAccessPin]);

  /**
   * @description Go to next page from the Recovery Phrase page, depending on where they are viewing the page from.
   * */
  const continueFromViewRecoveryPhrase = useCallback(() => {
    if (!encryptedKeys.current) {
      // if we are seeing the keys right after creating the wallet, set an access PIN before going to list
      accessPinFunction.current = AccessPinFunction.CreateAccount;
      goToAccessPin();
    } else {
      // if we are going back after having accessed this page from My NFTs, clear the mnemonic phrase
      keys.current.mnemonicPhrase = null;
      goToNftListView(NftList.MyNfts);
    }
  }, [AccessPinFunction.CreateAccount, goToAccessPin, goToNftListView]);

  /**
   * @description Attempt to decrypt or encrypt keys using the access PIN, and proceed to next page if successful.
   * */
  const submitAccessPin = useCallback(async (accessPin: string) => {
    setDisplayedError(null);

    switch (accessPinFunction.current) {
      case AccessPinFunction.CreateAccount:
      case AccessPinFunction.RecoverAccount:
      case AccessPinFunction.UpdatingEnterNewPin:
        // encrypt the wallet keys behind the provided access PIN
        const encryptResponse = await MorphWalletUtils.encryptKeys(keys.current, accessPin);

        if (encryptResponse?.error || !encryptResponse?.encryptedKeys) {
          setDisplayedError(UiText.errSetAccessPin);
          return { error: `submitAccessPin.1: ${encryptResponse.error || 'No encrypted wallet keys.'}` };
        }

        encryptedKeys.current = encryptResponse.encryptedKeys;

        // store keys in local storage (mobile app) or session storage (web view)
        if (ThinClientUtils.isThinClient() || PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS) {
          localStorage.setItem(MorphWalletUtils.BLOCKCHAIN_KEYS, encryptedKeys.current);
          if (ThinClientUtils.isThinClient()) {
            window.dispatchEvent(new StorageEvent('storage', {
              key: MorphWalletUtils.BLOCKCHAIN_KEYS,
              newValue: encryptedKeys.current,
              oldValue: localStorage.getItem(MorphWalletUtils.BLOCKCHAIN_KEYS),
              url: window.location.href,
              storageArea: localStorage
            }));
          }
        } else {
          sessionStorage.setItem(MorphWalletUtils.BLOCKCHAIN_KEYS, encryptedKeys.current);
        }

        // store the user's public address in the database
        try {
          const { error } = await axiosCall({
            url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/addPublicAddress/${userId}`,
            method: 'POST',
            data: {
              publicAddress: keys.current?.publicAddress
            }
          });

          if (error) {
            setDisplayedError(UiText.errSetAccessPin);
            return { error: `submitAccessPin.2: ${error}` };
          }
        } catch (err) {
          setDisplayedError(UiText.errSetAccessPin);
          return { error: `submitAccessPin.3: ${err.message}` };
        }

        break;
      default:
        // decrypt the existing wallet keys using the provided access PIN
        const decryptResponse = await MorphWalletUtils.decryptKeys(encryptedKeys.current, accessPin);

        if (decryptResponse?.error || !decryptResponse?.decryptedKeys) {
          if (decryptResponse?.error?.includes('bad decrypt')) {
            setDisplayedError(UiText.errIncorrectAccessPin);
          } else {
            setDisplayedError(UiText.errSubmitAccessPin);
          }

          return { error: `submitAccessPin.4: ${decryptResponse?.error || 'No decrypted wallet keys.'}` };
        }

        const { decryptedKeys } = decryptResponse;

        if (decryptedKeys?.mnemonicPhrase && decryptedKeys?.privateKey && decryptedKeys?.publicAddress) {
          keys.current = {
            ...decryptedKeys
          };
        } else {
          setDisplayedError(UiText.errSubmitAccessPin);
          return { error: 'submitAccessPin.5: No keys found.' };
        }
    }

    prevAccessPinText.current = accessPinText;
    setAccessPinText(UiText.PleaseWait);

    // keep the mnemonic phrase out of memory whenever possible
    switch (accessPinFunction.current) {
      case AccessPinFunction.ViewRecoveryPhrase:
      case AccessPinFunction.UpdatingEnterOldPin:
        break;
      default:
        keys.current.mnemonicPhrase = null;
    }

    // continue to intended function
    switch (accessPinFunction.current) {
      case AccessPinFunction.CreateAccount:
      case AccessPinFunction.RecoverAccount:
      case AccessPinFunction.Login:
        if (errandContext.bypassToMintFlag) {
          if (errandContext.nftData) {
            await handleTicketNft(errandContext.nftData);
          } else {
            await mintNftFromLoanNumber();
          }
        }
        await goToNftListView(NftList.MyNfts, true);
        break;
      case AccessPinFunction.ViewRecoveryPhrase:
        goToViewRecoveryPhrase(false);
        break;
      case AccessPinFunction.UpdatingEnterOldPin:
        accessPinFunction.current = AccessPinFunction.UpdatingEnterNewPin;
        goToAccessPin();
        break;
      case AccessPinFunction.UpdatingEnterNewPin:
        await goToNftListView(NftList.MyNfts);
        break;
      case AccessPinFunction.AddUser:
        await updateApprovedUser(true);
        break;
      case AccessPinFunction.RemoveUser:
        await updateApprovedUser(false);
        break;
      case AccessPinFunction.ToggleNftPublicness:
        await toggleNftPublicness();
        break;
      case AccessPinFunction.BurnNft:
        await burnNft();
        break;
      case AccessPinFunction.AcceptBid:
        await acceptBid();
        break;
      case AccessPinFunction.MintBidNft:
        await mintBidNft();
        break;
    }

    handleResetCode();
  }, [AccessPinFunction, acceptBid, accessPinText, handleResetCode, burnNft, errandContext,
      goToAccessPin, goToNftListView, mintBidNft, goToViewRecoveryPhrase, handleTicketNft, userId,
      mintNftFromLoanNumber, toggleNftPublicness, updateApprovedUser, PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS]);

  /**
   * @description Perform operations whenever the page changes.
   * */
  useLayoutEffect(() => {
    switch (activePage) {
      // don't reset error on pages with on-screen input as the page refreshes after wrong input (which resets the error)
      case Page.AccessPin:
      case Page.LinkDeviceMobile:
      case Page.RecoverAccount:
      case Page.ApprovedUsers:  // don't reset error if updating approved user fails, since we immediately go from access PIN to approved users (which resets the error)
        break;
      default:
        setDisplayedError(null);
    }

    setShowSmartContractAddress(false);
    setShowConfirmationCode(false);
    prevAccessPinText.current = null;
  }, [activePage, AccessPinFunction.UpdatingEnterOldPin, errand._id]);

  /**
   * @description Load the wallet and check if we should proceed to account setup or login.
   * */
  const loadWallet = () => {
    // translate and set the UI text
    translateUiText();

    // if keys exist in web localStorage (from previously setting PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS), remove
    if (!ThinClientUtils.isThinClient() && !PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS) {
      localStorage.removeItem(MorphWalletUtils.BLOCKCHAIN_KEYS);
    }

    // check if the user already has keys in storage
    const checkKeys = () => {
      if (ThinClientUtils.isThinClient() || PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS) {
        // remove keys encrypted with CryptoJS, which has been deprecated; applies to all wallets made on or before 11/05/24
        if (!localStorage.getItem(MorphWalletUtils.BLOCKCHAIN_KEYS)?.includes(':')) {
          localStorage.removeItem(MorphWalletUtils.BLOCKCHAIN_KEYS);
        }

        encryptedKeys.current = localStorage.getItem(MorphWalletUtils.BLOCKCHAIN_KEYS);
      } else {
        encryptedKeys.current = sessionStorage.getItem(MorphWalletUtils.BLOCKCHAIN_KEYS);
      }

      // this check is necessary for web view, even though we normally clear keys, because of PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS
      if (!encryptedKeys.current) {
        console.log('loadWallet.1: No keys found.');

        if (ThinClientUtils.isThinClient() || TESTING_MOBILE_APP_FLOW_ON_WEB) {
          const getPublicAddresses = async () => {
            // in mobile app, check if the user has previously created a wallet on another device
            try {
              const { walletAddresses } = await axiosCall({
                url: `user/${userId}`,
                method: 'GET'
              });

              if (walletAddresses?.length > 0) {
                setActivePage(Page.RecoverAccount);
                return;
              }
            } catch (err) {
              console.error(`getPublicAddresses: ${err.message}`);
            }

            handleCreateWallet();
          }

          getPublicAddresses();
          return;
        }

        // in web view, generate and set code for linking device
        const generateLinkDeviceCode = async () => {
          // stop trying to generate a new link device code if we have already logged in
          if (encryptedKeys.current) {
            return;
          }

          linkDeviceCode.current = [];
          for (let i = 0; i < MorphWalletUtils.LINK_DEVICE_CODE_LENGTH; i++) {
            linkDeviceCode.current.push(String(Math.floor(Math.random() * 9)));
          }

          // generate new code every numSeconds seconds
          const numSeconds = 30;
          for (let seconds = 0; seconds < numSeconds; seconds++) {
            await new Promise(resolve => setTimeout(resolve, 1000));

            // update the progress timer every second
            setLinkDeviceTimerProgress(prevLinkDeviceTimerProgress => prevLinkDeviceTimerProgress - MAX_TIMER_PROGRESS/numSeconds);
          }

          generateLinkDeviceCode();
          setLinkDeviceTimerProgress(MAX_TIMER_PROGRESS);
        }

        generateLinkDeviceCode();

        // receive data from mobile app
        const receiveMobileData = (e: any) => {
          const { linkDeviceInput, encryptedMobileKeys } = e?.data;
          
          const getError = () => {
            if (!linkDeviceInput) {
              return 'No link device code found.';
            } else if (!encryptedMobileKeys) {
              return 'No keys found.';
            } else if (linkDeviceInput !== linkDeviceCode.current.join('')) {
              return UiText.errLinkDeviceCode;
            }
          }

          const error = getError();

          // return response to the mobile app
          socketContext.messagesSocket.current?.emit('emit-blockchain-response', { error });

          if (error) {
            console.error(`receiveMobileData: ${error}`);
            return;
          }

          encryptedKeys.current = encryptedMobileKeys;

          // store the encrypted keys in the browser's storage
          if (PRESERVE_KEYS_BETWEEN_DESKTOP_SESSIONS) {
            localStorage.setItem(MorphWalletUtils.BLOCKCHAIN_KEYS, encryptedMobileKeys);
          } else {
            sessionStorage.setItem(MorphWalletUtils.BLOCKCHAIN_KEYS, encryptedMobileKeys);
          }

          accessPinFunction.current = AccessPinFunction.Login;
          goToAccessPin(); 
        }

        // listen for data from mobile app
        socketContext.messagesSocket.current?.on('blockchain-data-emitted', receiveMobileData);

        setActivePage(Page.LinkDeviceDesktop);
        return;
      }

      // if keys found, proceed to login
      console.log('loadWallet.2: Keys found.');
      accessPinFunction.current = AccessPinFunction.Login;
      goToAccessPin();
    }

    checkKeys();

    // begin asynchronously loading the preview fields for NFT list view
    getPreviewFields();
  }

  /**
   * @description Get the list of preview fields to show for each NFT type, which will appear in the list view.
   * */
  const getPreviewFields = async () => {
    previewFieldsStatus.current = AsyncStatus.Waiting;
    previewFields.current = await MorphWalletUtils.loadPreviewFields();
    previewFieldsStatus.current = AsyncStatus.Success;
  }

  /**
   * @description Recover the user's blockchain account, given their mnemonic recovery phrase.
   * */
  const handleRecoverAccount = useCallback(() => {
    setDisplayedError(null);
    setAllButtonsDisabled(true);

    // clear error indicators from previous input
    for (const mnemonicInputId of mnemonicInputIds) {
      document.getElementById(mnemonicInputId).style.borderColor = Color.Violet;
    }

    // check if any mnemonic words are missing
    for (const mnemonicInputId of mnemonicInputIds) {
      const element = document?.getElementById(mnemonicInputId) as HTMLInputElement;
      if (!element?.value) {
        // mark earliest missing word with red border
        element.style.borderColor = Color.Red;
        setDisplayedError(UiText.errMissingWord);
        setAllButtonsDisabled(false);
        return;
      }
    }

    // get all the mnemonic words as one (lowercase) string, separated by spaces
    const getMnemonicPhrase = () => {
      let mnemonicPhrase = [];

      for (const mnemonicInputId of mnemonicInputIds) {
        if (document?.getElementById(mnemonicInputId)) {
          mnemonicPhrase.push((document?.getElementById(mnemonicInputId) as HTMLInputElement)?.value?.toLowerCase());
        }
      }

      return mnemonicPhrase.join(' ');
    }

    const mnemonicPhrase = getMnemonicPhrase();

    // derive private key and public address from mnemonic phrase
    const response = MorphWalletUtils.getPrivateKeyFromPhrase(mnemonicPhrase);

    if (response?.error || !response?.privateKey || !response?.publicAddress) {
      const { error } = response;

      if (error?.startsWith(MorphWalletUtils.INVALID_WORD)) {
        // mark earliest invalid word (i.e. non-BIP39-compatible word) with red border
        document.getElementById(mnemonicInputIds[Number(error?.split(MorphWalletUtils.INVALID_WORD)[1])]).style.borderColor = Color.Red;
        setDisplayedError(UiText.errIllegalWord);
      } else if (error === MorphWalletUtils.INVALID_CHECKSUM) {
        setDisplayedError(UiText.errIncorrectPhrase);
      } else if (error) {
        console.error(`handleRecoverAccount.1: ${error}`);
      } else {
        console.error('handleRecoverAccount.2: Missing keys.');
      }

      setAllButtonsDisabled(false);
      return;
    }

    keys.current = {
      mnemonicPhrase,
      privateKey: response.privateKey,
      publicAddress: response.publicAddress
    };

    accessPinFunction.current = AccessPinFunction.RecoverAccount;
    setAllButtonsDisabled(false);
    goToAccessPin();
  }, [AccessPinFunction.RecoverAccount, goToAccessPin, mnemonicInputIds]);

  /**
   * @description Create a new blockchain wallet.
   * */
  const handleCreateWallet = useCallback(() => {
    // generate a new mnemonic phrase, private key, and public address
    const response = MorphWalletUtils.generateWallet();

    if (response?.error || !response?.mnemonicPhrase || !response?.privateKey || !response?.publicAddress) {
      console.error(`handleCreateWallet: ${response.error || 'No keys found.'}`);
      setDisplayedError(UiText.errCreateWallet);
      return;
    }

    keys.current = {
      mnemonicPhrase: response.mnemonicPhrase,
      privateKey: response.privateKey,
      publicAddress: response.publicAddress
    };
    
    goToViewRecoveryPhrase(false);
  }, [goToViewRecoveryPhrase]);

  /**
   * @description Handle entry of mnemonic recovery phrase and navigation through input fields.
   */
  const handleEnterMnemonic = (e: any) => {
    if (e.key === 'Enter') {
      const currentInputNum = Number(document?.activeElement?.id.split('-').pop());

      // go to next square if not on last square, otherwise continue
      if (currentInputNum < MorphWalletUtils.NUM_MNEMONIC_WORDS - 1) {
        document.getElementById(mnemonicInputIds[currentInputNum + 1])?.focus();
      } else {
        handleRecoverAccount();
      }
    }
  }

  /**
   * @description Render the input boxes in which the user will enter their mnemonic recovery phrase.
   * */
  const renderMnemonicInputs = () => {
    let mnemonicInputRows = [];
    const mnemonicInputsPerRow = isMobile() ? (smallMobileScreen ? 2 : 3) : 6;
    const mnemonicInputWidth = smallMobileScreen ? 100 : 80;

    for (let row = 0; row < MorphWalletUtils.NUM_MNEMONIC_WORDS / mnemonicInputsPerRow; row++) {
      mnemonicInputRows.push(
        <div key={`mnemonic-input-row-${String(row)}`}
             className={Styles.row + ' ' + Styles.rightSlide}>
          {mnemonicInputIds.slice(row * mnemonicInputsPerRow, (row + 1) * mnemonicInputsPerRow).map((mnemonicInputId, index) => (
            <div key={mnemonicInputId}
                 className={Styles.row}
                 style={{ marginBottom: 5, alignItems: 'center' }}>
              <p style={{ color: Color.Orange, fontSize: '0.8rem', textAlign: 'center', width: 21, padding: 3, margin: 2 }}>
                {row * mnemonicInputsPerRow + index + 1}
              </p>
              <input
                id={mnemonicInputId}
                autoComplete='off'
                autoCorrect='off'
                autoFocus={mnemonicInputId === mnemonicInputIds[0]}
                onKeyDown={handleEnterMnemonic}
                className={Styles.input}
                style={{ borderColor: Color.Violet, width: mnemonicInputWidth }}
              />
            </div>
          ))}
        </div>
      )
    }

    return (
      <div className={Styles.column}>
        {mnemonicInputRows}
      </div>
    );
  }

  /**
   * @description Render the boxes in which the mnemonic recovery phrase will be displayed.
   * */
  const renderMnemonicDisplay = () => {
    let mnemonicDisplayRows = [];
    const mnemonicWordsPerRow = isMobile() ? 3 : 6;

    for (let row = 0; row < MorphWalletUtils.NUM_MNEMONIC_WORDS / mnemonicWordsPerRow; row++) {
      mnemonicDisplayRows.push(
        <div key={`mnemonic-display-row-${String(row)}`}
             className={Styles.row + ' ' + Styles.rightSlide}>
          {keys.current?.mnemonicPhrase?.split(' ').slice(row * mnemonicWordsPerRow, (row + 1) * mnemonicWordsPerRow).map((word, index) => (
            <div key={word}
                 className={Styles.mnemonicWord}>
              <p style={{ color: Color.Orange, fontSize: '0.8rem', marginRight: 5 }}>
                {row * mnemonicWordsPerRow + index + 1}
              </p>
              <p style={{ color: Color.White, fontSize: '0.8rem' }}>
                {word}
              </p>
            </div>
          ))}
        </div>
      )
    }
    
    return (
      <div className={Styles.column}>
        {mnemonicDisplayRows}
      </div>
    );
  }

  /**
   * @description Move to the previous square in the code input, i.e., the one to the left.
   * */
  const goToPreviousCodeSquare = () => {
    // get the zero-indexed number of the currently focused code digit
    const currentInputNum = Number(document.activeElement.id.split('-').pop());

    // go to previous square (if not on first square)
    if (currentInputNum > 0) {
      document.getElementById(getInputIds()[currentInputNum - 1])?.focus();
    }
  }

  /**
   * @description Move to the next square in the code input, i.e., the one to the right.
   * */
  const goToNextCodeSquare = () => {
    // get the zero-indexed number of the currently focused code digit
    const currentInputNum = Number(document.activeElement.id.split('-').pop());

    // go to next square (if not on last square)
    if (currentInputNum < getInputIds()?.length - 1) {
      document.getElementById(getInputIds()[currentInputNum + 1])?.focus();
    }
  }

  /**
   * @description Handle deleting the code input or navigating through it with the arrow keys.
   * */
  const handleCodeKeyDown = (e: any) => {
    switch (e?.key) {
      case 'Backspace':
        // this seems to be the most intuitive way for the UI to handle backspace
        const activeElement = document?.activeElement as HTMLInputElement;
        const currentInputNum = Number(activeElement.id.split('-').pop());
        if (activeElement?.value) {
          activeElement.value = null;
          getCodeInput()[currentInputNum] = undefined;
        } else {
          goToPreviousCodeSquare();
          if (currentInputNum > 0) {
            getCodeInput()[currentInputNum - 1] = undefined;
          }
        }
        break;
      case 'ArrowLeft':
        goToPreviousCodeSquare();
        break;
      case 'ArrowRight':
        goToNextCodeSquare();
        break;
    }
  }

  /**
   * @description Handle entering code (i.e., access PIN); this only runs after the input has already been registered.
   * */
  const handleEnterCode = async (e: any) => {
    const input = e?.nativeEvent?.data;
    if (input) {
      // numeric characters only
      if (input >= '0' && input <= '9') {
        getCodeInput()[Number(document.activeElement.id.split('-').pop())] = input;
        
        // replace digit with bullet point in UI, for privacy
        (document.activeElement as HTMLInputElement).value = '\u2022';

        goToNextCodeSquare();
      } else {
        // remove newly entered non-numeric character, if one was entered
        const activeElement = document.activeElement as HTMLInputElement;
        if (activeElement) {
          activeElement.value = activeElement?.value?.replace(input, '');
        }
      }
    }

    if (activePage === Page.AccessPin) {
      // show reset button when there is something entered; otherwise don't show
      setShowResetPin(getCodeInput().filter(digit => digit !== undefined).length !== 0);
    }

    // continue only if user has finished entering code
    if (getCodeInput().includes(undefined)) {
      return;
    }

    setAllButtonsDisabled(true);

    // get all the code digits as one string
    const code = getCodeInput().join('');

    // submit the code wherever needed
    const submitCode = async () => {
      switch (activePage) {
        case Page.AccessPin:
          // submit access PIN for encryption/decryption
          return await submitAccessPin(code);
        case Page.LinkDeviceMobile:
          // send link device code to web app
          return await handleLinkDevice(code);
      }
    }

    const response = await submitCode();

    if (response?.error) {
      handleResetCode();
      setAllButtonsDisabled(false);
      console.error(`handleEnterCode: ${response.error}`);
      return;
    }

    setAllButtonsDisabled(false);
  }

  /**
   * @description Translate and set the UI text.
   * */
  const translateUiText = useCallback(async () => {
    const language = LanguageUtils.fetchLocalizationLanguageSetting() || 'en';

    if (language === currentLanguage.current || !LanguageUtils.fetchTranslationEnabledSetting()) {
      return;
    }

    currentLanguage.current = language;

    // always translate from the set of predetermined English texts so the meaning is not corrupted through multiple translations
    const englishTexts = Object.keys(UiText);
    const englishTextArr = [];

    // get all the UI text that we intend to translate
    englishTexts.forEach((text, index) => {
      englishTextArr.push({ name: text, text: Object.values(UiText)[index] });
    });

    if (language === 'en') {
      setTranslatedUiText(englishTextArr);
      return;
    }

    try {
      const translatedTextArr = [];

      // translate the UI text
      for (const englishText of englishTextArr) {
        translatedTextArr.push({
          name: englishText.name,
          text: await LanguageUtils.translateOne(englishText.text, language)
        });
      }

      // store the translated UI text
      setTranslatedUiText(translatedTextArr);
    } catch (err) {
      console.error(`translateUiText: ${err.message}.`);
      setTranslatedUiText(englishTextArr);
    }
  }, []);

  /**
   * @description Handle errors from operations performed after the access PIN is entered and display the QR code for the NFT.
   * */
  useEffect(() => {
    /** Displays the QR code for the NFT if applicable */
    function getQrCode() {
      if (activePage === Page.MyNftsSingleView) {
        const token = getSingleViewNft(NftList.MyNfts);
        const showQrCode = token && token.nftType === NftType.TICKET;
        if (showQrCode) {
          const { metaData: { hashString } } = token;
          // Decode the NFT hashkey to get the event URL
          axiosCall({
            url: `hashkey/${hashString}`
          }).then((decoded) => {
            // Do not generate a QR code if there is no URL for the event
            if (!decoded?.parameters?.eventUrl) {
              setQrCode("");
              return;
            }
            // Generate a QR code for the url, passing the hash string, token id, and contract address
            return axiosCall({ url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/qrCode`, method: "POST", data: {
              url: `${decoded?.parameters?.eventUrl}/?nft=${hashString}&tokenId=${token.tokenId}&contractAddress=${token.contractAddress}`
            }});
          }).then((data) => {
            if (data?.qrCode) {
              // Show the generated QR code
              setQrCode(data.qrCode);
            }
          })
        } else {
          setQrCode("");
        }
      }
    }
    getQrCode();

    // reset the access PIN and revert the text above the access PIN if an error occurs during access PIN entry
    if (activePage === Page.AccessPin && displayedError && accessPinText === UiText.PleaseWait) {
      setAccessPinText(prevAccessPinText.current);
      handleResetCode();
    }

    translateUiText();
  }, [activePage, displayedError, accessPinText, getSingleViewNft, handleResetCode, translateUiText]);

  /**
   * @description Go to previous page from access PIN input, depending on where they are viewing the page from.
   * */
  const goBackFromAccessPin = async () => {
    setAllButtonsDisabled(true);
    switch (accessPinFunction.current) {
      case AccessPinFunction.CreateAccount:
        goToViewRecoveryPhrase(false);
        break;
      case AccessPinFunction.RecoverAccount:
        setActivePage(Page.RecoverAccount);
        break;
      case AccessPinFunction.ViewRecoveryPhrase:
        await goToNftListView(NftList.MyNfts);
        break;
      case AccessPinFunction.UpdatingEnterOldPin:
      case AccessPinFunction.UpdatingEnterNewPin:
        await goToNftListView(NftList.MyNfts);
        break;
      case AccessPinFunction.AddUser:
      case AccessPinFunction.RemoveUser:
        setActivePage(Page.ApprovedUsers);
        break;
      case AccessPinFunction.ToggleNftPublicness:
      case AccessPinFunction.BurnNft:
        await goToNftSingleView(NftList.MyNfts);
        break;
      case AccessPinFunction.AcceptBid:
        await goToNftSingleView(NftList.BidsOnNft);
        break;
      case AccessPinFunction.MintBidNft:
        await goToNftSingleView(NftList.BiddableNfts);
        break;
    }

    setAllButtonsDisabled(false);
  }

  /**
   * @description Get the array of NFTs contained within a given NFT list.
   * @param {NftList} nftList The NFT list.
   * */
  const getNftList = (nftList: NftList) => {
    switch (nftList) {
      case NftList.MyNfts:
        return myNfts.current;
      case NftList.BidsOnNft:
        return bidsOnNft.current;
      case NftList.BiddableNfts:
        return biddableNfts.current;
    }
  }

  /**
   * @description Get the currently selected NFT.
   * @param {NftList} nftList The NFT list which contains that token.
   * */
  const getSelectedNft = (nftList: NftList) => {
    switch (nftList) {
      case NftList.MyNfts:
        return selectedMyNft.current;
      case NftList.BidsOnNft:
        return selectedBidOnNft.current;
      case NftList.BiddableNfts:
        return selectedBiddableNft.current;
    }
  }

  /**
   * @description Set the NFT currently selected from a list of tokens.
   * @param {NftList} nftList The NFT list which contains that token.
   * @param {Token} token The Token object.
   * */
  const setSelectedNft = (nftList: NftList, token: Token) => {
    switch (nftList) {
      case NftList.MyNfts:
        selectedMyNft.current = token;
        break;
      case NftList.BidsOnNft:
        selectedBidOnNft.current = token;
        break;
      case NftList.BiddableNfts:
        selectedBiddableNft.current = token;
        break;
    }
  }

  /**
   * @description Begin the flow for changing the user's access PIN.
   * */
  const handleUpdatePin = useCallback(() => {
    accessPinFunction.current = AccessPinFunction.UpdatingEnterOldPin;
    goToAccessPin();
  }, [AccessPinFunction.UpdatingEnterOldPin, goToAccessPin]);

  /**
   * @description Validate the code entered for linking a device (usually desktop), send it to the recipient device, and await possible errors.
   * @param {string} linkDeviceInput The code that was entered by the user to link the device. 
   * */
  const handleLinkDevice = useCallback(async (linkDeviceInput: string) => {
    setAllButtonsDisabled(true);

    // validate provided link device code
    const response = MorphWalletUtils.validateCode(linkDeviceInput, MorphWalletUtils.LINK_DEVICE_CODE_LENGTH);
    if (response?.error) {
      setDisplayedError(UiText.errIllegalAccessPin);
      setAllButtonsDisabled(false);
      return { error: `handleLinkDevice.1: ${response.error}` };
    }

    if (!encryptedKeys.current) {
      setDisplayedError(UiText.errLinkDevice);
      setAllButtonsDisabled(false);
      return { error: 'handleLinkDevice.2: No keys found.' };
    }

    // send code to device
    socketContext.messagesSocket.current?.emit('emit-blockchain-data', {
      linkDeviceInput,
      encryptedMobileKeys: encryptedKeys.current
    });

    // clear error from previous failed attempt
    setDisplayedError(null);

    // whether we have received a response from the device
    let responseReceived = false;

    // receive a response from the device
    const receiveResponse = (e: any) => {
      responseReceived = true;
      const { error } = e?.data;

      if (error) {
        // when the wallet is hardcoded to appear in ConversationFooter this may momentarily show before the correct code is received
        console.error(`receiveResponse: ${error}`);
        setDisplayedError(error === UiText.errLinkDeviceCode ? UiText.errLinkDeviceCode : UiText.errLinkDevice);
      } else {
        setAllButtonsDisabled(false);
        setActivePage(Page.LinkDeviceMobileSuccess);
      }
    }

    // listen for error from device
    socketContext.messagesSocket.current?.on('blockchain-response-emitted', receiveResponse);

    // wait up to 10 seconds for response
    for (let i = 0; i < 20; i++) {
      if (responseReceived) {
        break;
      }

      // if no response, wait 0.5 seconds
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    if (!responseReceived) {
      console.error('handleLinkDevice.3: No response from device.');
      setDisplayedError(UiText.errLinkDevice);
    }
    
    handleResetCode();
    setAllButtonsDisabled(false);
  }, [handleResetCode, socketContext.messagesSocket]);

  /**
   * @description Receive messages sent from the chat box.
   * */
  const receiveChatSend = useCallback((e: any) => {
    if (e?.type !== Event.ChatSend) {
      return;
    }

    const input: string = e?.detail?.input?.toLowerCase();

    if (!input) {
      return;
    }

    switch (activePage) {
      case Page.ViewRecoveryPhrase:
        if (input.includes(tWallet(UiText.pContinue))) {
          continueFromViewRecoveryPhrase();
        } else if (input.includes(tWallet(UiText.pLogin))) {
          setActivePage(Page.RecoverAccount);
        } else {
          break;
        }
        return;
      case Page.RecoverAccount:
        if (input.includes(tWallet(UiText.pContinue))) {
          handleRecoverAccount();
        } else if (input.includes(tWallet(UiText.pCreate))) {
          handleCreateWallet();
        } else if (input.includes(tWallet(UiText.pDontHave)) || input.includes(tWallet(UiText.pCantFind))) {
          setDisplayedError(UiText.errLostPhrase);
        } else {
          break;
        }
        return;
      case Page.MyNftsListView:
        if (ThinClientUtils.isThinClient() || TESTING_MOBILE_APP_FLOW_ON_WEB) {
          if (input.includes(tWallet(UiText.pSecret)) || input.includes(tWallet(UiText.pAccess)) || input.includes(tWallet(UiText.pRecover)) ||
              input.includes(tWallet(UiText.pPhrase)) || input.includes(tWallet(UiText.pWords)) || input.includes(tWallet(UiText.pMnemonic))) {
            goToViewRecoveryPhrase();
          } else if (input.includes(tWallet(UiText.pPin)) || input.includes(tWallet(UiText.pChange)) || input.includes(tWallet(UiText.pUpdate))) {
            handleUpdatePin();
          } else if (input.includes(tWallet(UiText.pLink)) || input.includes(tWallet(UiText.pConnect)) ||
                     input.includes(tWallet(UiText.pDevice)) || input.includes(tWallet(UiText.pDesktop))) {
            setActivePage(Page.LinkDeviceMobile);
          } else if (input.includes(tWallet(UiText.pRefresh))) {
            handleRefreshNftList();
          } else {
            break;
          }
        }
        return;
      case Page.BiddableNftsSingleView:
        if (purchaseOfferText === UiText.BidSubmitted) {
          return;
        }

        setAllButtonsDisabled(true);
        const token = getSingleViewNft(NftList.BiddableNfts);

        if (token.nftType === NftType.TRUAPP) {
          if (!input) {
            setDisplayedError(UiText.errNoPurchasePrice);
            setAllButtonsDisabled(false);
            return;
          }
      
          // check that offer only includes digits, period, commas, and dollar sign
          if (input?.match(/[^\d.,$]/)) {
            setDisplayedError(UiText.errInvalidPurchasePrice);
            setAllButtonsDisabled(false);
            return;
          }
      
          // remove commas and dollar sign from offer
          purchaseOffer.current = Number(input.replace(/[,$]/g, ''));
      
          nftToBid.current = token;
          accessPinFunction.current = AccessPinFunction.MintBidNft;
          goToAccessPin();
        }

        setAllButtonsDisabled(false);
        return;
    }

    if (input?.includes(tWallet(UiText.pBack))) {
      const backFunc = getBackFunc();

      if (backFunc) {
        backFunc();
      } else {
        return;
      }
    }

    setDisplayedError(UiText.errUnrecognizedPrompt);
  }, [activePage, purchaseOfferText, AccessPinFunction.MintBidNft,getSingleViewNft, goToAccessPin, continueFromViewRecoveryPhrase,
      goToViewRecoveryPhrase, handleCreateWallet, handleRecoverAccount, handleUpdatePin, tWallet, TESTING_MOBILE_APP_FLOW_ON_WEB]);

  /**
   * @description Watch messages typed in the chat box.
   * */
  const receiveChatInput = useCallback((e: any) => {
    if (e?.type !== Event.ChatInput) {
      return;
    }

    const input: string = e?.detail?.input?.toLowerCase();

    if (!input) {
      return;
    }

    switch (activePage) {
      case Page.AccessPin:
        if (input[0] >= '0' && input[0] <= '9') {
          setDisplayedError(UiText.errTypePin);
        }
        return;
      case Page.LinkDeviceMobile:
        if (input[0] >= '0' && input[0] <= '9') {
          setDisplayedError(UiText.errTypeCode);
        }
        return;
      case Page.ViewRecoveryPhrase:
        // make sure the user has not accidentally started typing their recovery phrase; if so, warn them and delete it
        for (const mnemonicWord of keys.current?.mnemonicPhrase?.split(' ')) {
          if (input?.includes(mnemonicWord)) {
            setDisplayedError(UiText.errTypePhrase);
            return;
          }
        }
        return;
    }
  }, [activePage]);

  /**
   * @description Handle use of the chat box with the wallet.
   */
  useEffect(() => {
    // listen for text sent from the chat box
    window.addEventListener(Event.ChatSend, receiveChatSend);

    // listen for text input from the chat box
    window.addEventListener(Event.ChatInput, receiveChatInput);

    return () => {
      window.removeEventListener(Event.ChatSend, receiveChatSend);
      window.removeEventListener(Event.ChatInput, receiveChatInput);
    };
  }, [receiveChatSend, receiveChatInput, setDisplayedError]);

  /**
   * @description Determine whether the 'Approved Users' button should be disabled.
   * */
  const isApprovedUsersDisabled = () => {
    return allButtonsDisabled || selectedMyNft.current?.isBurned;
  }

  /**
   * @description Get the users approved to view an NFT and then proceed to the Approved Users page.
   * */
  const goToApprovedUsers = async () => {
    setAllButtonsDisabled(true);
    setDisplayedError(null);
    setApprovedUsers([]);

    const handleError = () => {
      setDisplayedError(UiText.errGetApprovedUsers);
      setAllButtonsDisabled(false);
    }
    
    if (activePage !== Page.AccessPin) {
      userToBeAdded.current = null;
    }

    const token = selectedMyNft.current;

    if (!token) {
      console.error('goToApprovedUsers.1: Token not found.');
      handleError();
      return;
    }

    // get the users approved on the NFT
    try {
      const { error, userList } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getApprovedUsers`,
        method: 'POST',
        data: {
          ...token
        }
      });

      if (error) {
        console.error(`goToApprovedUsers.2: ${error}`);
        handleError();
        return;
      }

      const approvedUserList = [];
      userList.forEach((user: any) => {
        if (user?.userApproved && user?.userAddress) {
          approvedUserList.push(user.userAddress);
        }
      });

      setApprovedUsers(approvedUserList);
    } catch (err) {
      console.error(`goToApprovedUsers.3: ${err.message}`);
      handleError();
      return;
    }

    setActivePage(Page.ApprovedUsers);
    setAllButtonsDisabled(false);
  }

  /**
   * @description Determine whether the 'Add User' and 'Remove User' buttons should be disabled.
   * */
  const isUpdateUsersDisabled = () => {
    return allButtonsDisabled || selectedMyNft.current?.userType !== NftUserType.OWNER
  }

  /**
   * @description Select or deselect an approved user in the list of removable approved users.
   * */
  const selectUserToBeRemoved = (e: any) => {
    setUserToBeRemoved(userToBeRemoved === e.target.id ? null : e.target.id);
  }

  /**
   * @description Begin the flow for removing an approved user.
   * */
  const handleRemoveUser = () => {
    accessPinFunction.current = AccessPinFunction.RemoveUser;
    goToAccessPin();
  }

  /**
   * @description Begin the flow for adding an approved user.
   * */
  const handleAddUser = () => {
    // set this now, because once we go to access pin it will be lost
    userToBeAdded.current = (document?.getElementById(ADD_USER_INPUT_ID) as HTMLInputElement)?.value;

    accessPinFunction.current = AccessPinFunction.AddUser;
    goToAccessPin();
  }

  /**
   * @description Determine whether the 'Make NFT Public' and 'Make NFT Private' buttons should be disabled.
   * */
  const isToggleNftPublicnessDisabled = () => {
    return allButtonsDisabled
           || selectedMyNft.current?.userType !== NftUserType.OWNER
           || selectedMyNft.current?.isBurned;
  }

  /**
   * @description Begin the flow for making a private NFT public, or vice versa.
   * */
  const handleToggleNftPublicness = () => {
    accessPinFunction.current = AccessPinFunction.ToggleNftPublicness;
    goToAccessPin();
  }

  /**
   * @description Determine whether the 'Burn NFT' button should be disabled.
   * */
  const isBurnNftDisabled = () => {
    return allButtonsDisabled
           || selectedMyNft.current?.userType !== NftUserType.OWNER
           || selectedMyNft.current?.isBurned
           || selectedMyNft.current?.nftType === NftType.TICKET;
  }

  /**
   * @description Begin the flow for burning an NFT.
   * */
  const handleBurnNft = () => {
    accessPinFunction.current = AccessPinFunction.BurnNft;
    goToAccessPin();
  }

  /**
   * @description Render the error message that may appear on a page.
   * */
  const renderError = () => {
    return (
      <div style={{ fontSize: '0.8rem', marginTop: 10, marginBottom: 10 }}>
        {displayedError && (
          <div className={Styles.row}
               style={{ alignItems: 'center', border: `1px solid ${Color.Red}`, borderRadius: 5, padding: 3 }}>
            <ErrorIcon style={{ color: Color.Red, marginRight: 5 }}/>
            <div className={Styles.column}>
              {tWallet(displayedError)?.split('|')?.map((errorSentence) => (
                <p style={{ color: Color.White, textAlign: 'center', padding: 1 }}>
                  {errorSentence}
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  /**
   * @description Render the UI header with text and refresh button.
   * */
  const renderHeader = () => {
    const getHeaderText = () => {
      switch (activeTab) {
        case Tab.MyWallet:
          if (!isMobile() || activePage === Page.MyNftsListView) {
            return UiText.MyWallet;
          }
          break;
        case Tab.Marketplace:
          return UiText.Marketplace;
      }

      return null;
    }

    // get the name and onClick function of the header button; if null, won't show header button
    const getHeaderButtonData = () => {
      if (!encryptedKeys.current) {
        switch (activePage) {
          case Page.ViewRecoveryPhrase:
            return { name: UiText.LogIn, func: () => setActivePage(Page.RecoverAccount) };
          case Page.RecoverAccount:
            return { name: UiText.CreateWallet, func: handleCreateWallet };
        }
      }

      return null;
    }

    const headerButtonData = getHeaderButtonData();

    return (
      <div style={MorphWalletUtils.getHeaderStyling(activeTab)}>
        {renderBackButton()}
        {renderExitButton()}
        <p style={{ fontSize: '1.2rem', position: 'absolute', color: Color.White, left: getBackFunc() ? UI.BackButtonWidth + 15 : 40, top: 9 }}>
          {tWallet(getHeaderText())}
        </p>
        {headerButtonData && (
          <div style={{ position: 'absolute', right: 30, top: 11, backgroundColor: Color.Violet, padding: '4px 6px 3px 6px', fontSize: '0.6rem', borderRadius: 5 }}>
            <button onClick={headerButtonData.func}
                    disabled={allButtonsDisabled}
                    style={{ color: Color.White, opacity: allButtonsDisabled && 0.5 }}>
              {tWallet(headerButtonData.name)}
            </button>
          </div>
        )}
        {getRefreshNftListFunction() && (
          <div style={{ position: 'absolute', top: 5, right: 20, opacity: allButtonsDisabled && 0.5 }}>
            {refreshingNftList ? (
              <p style={{ paddingTop: 6 }}>
                {tWallet(UiText.Refreshing)}
              </p>
            ) : (
              <Refresh onClick={handleRefreshNftList}
                       className={Styles.refreshButton}
                       style={{ pointerEvents: allButtonsDisabled ? 'none' : 'auto' }}/>
            )}
          </div>
        )}
      </div>
    );
  }

  /**
   * @description Render the footer with the "Wallet" and "Marketplace" tabs.
   * */
  const renderFooter = () => {
    // whether we should show the buttons in the footer
    const showFooterButtons = () => {
      switch (activePage) {
        case Page.MyNftsListView:
        case Page.MyNftsSingleView:
        case Page.ApprovedUsers:
        case Page.BidsOnNftListView:
        case Page.BidsOnNftSingleView:
        case Page.AttachedNftSingleView:
        case Page.AcceptedBidSingleView:
        case Page.ViewMyBids:
        case Page.Marketplace:
        case Page.BiddableNftsListView:
        case Page.BiddableNftsSingleView:
          return true;
      }
      return false;
    }

    // whether the given tab should be disabled
    const isTabDisabled = (tab: Tab) => {
      return allButtonsDisabled || activeTab === tab;
    }

    return (
      <>
        {!isMobile() && errandContext.morphType === MorphType.Wallet && (
          <>
            <div style={{ position: 'absolute', backgroundColor: Color.Blue, width: MorphWalletUtils.getMorphWalletWidth() * 0.8,
                          height: 30, bottom: -10, left: 0, right: 0, marginInline: 'auto', zIndex: 20 }}/>
            <div style={{ position: 'absolute', backgroundColor: Color.Blue, width: 20, borderRadius: '100%',
                          height: 30, bottom: -10, left: -MorphWalletUtils.getMorphWalletWidth() * 0.8, right: 0, marginInline: 'auto', zIndex: 20 }}/>
            <div style={{ position: 'absolute', backgroundColor: Color.Blue, width: 20, borderRadius: '100%',
                          height: 30, bottom: -10, right: -MorphWalletUtils.getMorphWalletWidth() * 0.8, left: 0, marginInline: 'auto', zIndex: 20 }}/>
          </>
        )}
      </>
    );

    // integrate this with above when we are ready to release the marketplace
    return (
      <div style={MorphWalletUtils.getFooterStyling()}>
        {showFooterButtons() && [
          { name: UiText.MyWallet, func: () => goToNftListView(NftList.MyNfts, true) },
          { name: UiText.Marketplace, func: goToMarketplace }
         ].map((tab) => (
          <button onClick={tab.func}
                  className={Styles.footerButton}
                  disabled={isTabDisabled(Tab[tab.name])}
                  style={{ opacity: isTabDisabled(Tab[tab.name]) && 0.5 }}>
            {tWallet(tab.name)}
          </button>
        ))}
      </div>
    );
  }

  /**
   * @description Get the function that will run when the back button is pressed.
   * */ 
  const getBackFunc = () => {
    switch (activePage) {
      case Page.ViewRecoveryPhrase:
        // only show back button if we are seeing keys from wallet, not from creation
        return encryptedKeys.current ? continueFromViewRecoveryPhrase : null;
      case Page.AccessPin:
        return accessPinFunction.current === AccessPinFunction.Login ? null : goBackFromAccessPin;
      case Page.LinkDeviceMobile:
      case Page.LinkDeviceMobileSuccess:
        return () => goToNftListView(NftList.MyNfts);
      case Page.MyNftsSingleView:
        return () => activeTab === Tab.MyWallet ? goToNftListView(NftList.MyNfts) : setActivePage(Page.ViewMyBids);
      case Page.BidsOnNftListView:
      case Page.AcceptedBidSingleView:
      case Page.AttachedNftSingleView:
      case Page.ApprovedUsers:
        return () => goToNftSingleView(NftList.MyNfts);
      case Page.BidsOnNftSingleView:
        return () => goToNftListView(NftList.BidsOnNft);
      case Page.ViewMyBids:
      case Page.BiddableNftsListView:
        return goToMarketplace;
      case Page.BiddableNftsSingleView:
        return () => goToNftListView(NftList.BiddableNfts);
    }
  }

  /**
   * @description Render the back button on the top left of the blockchain UI.
   * */
  const renderBackButton = () => {
    // do not show back button if no function is defined
    if (!getBackFunc()) {
      return null;
    }

    return (
      <div onClick={getBackFunc()}
           className={Styles.backButton}
           style={{ borderTopLeftRadius: MorphWalletUtils.getUiBorderTopRadii(activeTab), borderBottomRightRadius: MorphWalletUtils.getUiBorderTopRadii(activeTab),
                    minWidth: UI.BackButtonWidth, opacity: allButtonsDisabled && 0.5, pointerEvents: allButtonsDisabled ? 'none' : 'auto' }}>
        <ArrowBack/>
      </div>
    );
  }

  /**
   * @description Render the exit button on the top of the blockchain UI.
   */
   const renderExitButton = () => {
    const handleExit = () => {
      errandContext.setMorphType(MorphType.None);
      window.removeEventListener(Event.ChatSend, receiveChatSend);
      window.removeEventListener(Event.ChatInput, receiveChatInput);
    }

    return (
      <div onClick={handleExit}
           className={Styles.exitButton}>
        <ArrowDropDown style={{ margin: -5, zIndex: 3, fontSize: '1.6rem' }}/>
        <div style={{ backgroundColor: Color.White, borderColor: Color.DarkBlue, borderLeft: '1px solid',
                      borderRadius: '100%', width: 6, height: 6, position: 'absolute', top: 0, left: -2 }}/>
        <div style={{ backgroundColor: Color.White, borderColor: Color.DarkBlue, borderRight: '1px solid',
                      borderRadius: '100%', width: 6, height: 6, position: 'absolute', top: 0, right: -2 }}/>
        <div style={{ backgroundColor: Color.White, borderColor: Color.DarkBlue, borderTop: '1px solid', width: 50, height: 6, position: 'absolute', top: 0 }}/>
        <div style={{ backgroundColor: Color.DarkBlue, width: 18, height: 15, position: 'absolute', bottom: 0, left: -2, borderTopRightRadius: '100%' }}/>
        <div style={{ backgroundColor: Color.DarkBlue, width: 18, height: 15, position: 'absolute', bottom: 0, right: -2, borderTopLeftRadius: '100%' }}/>
        <div style={{ backgroundColor: Color.DarkBlue, width: 52, height: 8, position: 'absolute', bottom: -1, left: -1 }}/>
        <div style={{ backgroundColor: Color.White, width: 12, height: 6, position: 'absolute',
                      bottom: 3.5, left: 14, borderBottomLeftRadius: '100%', zIndex: 1 }}/>
        <div style={{ backgroundColor: Color.White, width: 12, height: 6, position: 'absolute',
                      bottom: 3.5, right: 14, borderBottomRightRadius: '100%', zIndex: 1 }}/>
      </div>
    );
  }

  /**
   * @description Render the given list of NFTs.
   * @param {NftList} nftList The NFT list to render.
   * */
  const renderNftListView = (nftList: NftList) => {
    // whether the NFT list has any tokens in it
    const isNftListPopulated = () => {
      return getNftList(nftList)?.length > 0;
    }

    // the text to show above (or instead of) the NFT list
    const getListHeaderText = () => {
      switch (nftList) {
        case NftList.MyNfts:
          switch (activePage) {
            case Page.MyNftsListView:
              return isNftListPopulated() ? UiText.HeaderMyNfts : UiText.HeaderNoMyNfts;
            case Page.ViewMyBids:
              return isNftListPopulated() ? UiText.HeaderMyBids : UiText.HeaderNoMyBids;
          }
          break;
        case NftList.BidsOnNft:
          return isNftListPopulated() ? UiText.HeaderBids : UiText.HeaderNoBids;
        case NftList.BiddableNfts:
          return isNftListPopulated() ? UiText.HeaderPublicNfts : UiText.HeaderNoPublicNfts;
      }
    }

    // check whether the given token should be shown in the list
    const showToken = (token: Token) => {
      // only show tokens with NFT type selected in the filter
      if (!displayedNftTypes.includes(token.nftType)) {
        return false;
      }

      // prevent user from seeing their own NFT in the marketplace
      if (nftList === NftList.BiddableNfts && token.owner === keys.current?.publicAddress) {
        return false;
      }

      return true;
    }

    const renderScrollableNftList = () => {
      if (!isNftListPopulated() || !displayedNftTypes) {
        return <></>;
      }

      const nftsToDisplay = getNftList(nftList)?.filter(token => showToken(token));
      const numNftsPerRow = isMobile() ? 1 : 2;
      const nftWidth = isMobile() ? (MorphWalletUtils.getMorphWalletWidth() - UI.PaddingSide * 2) * 0.85 : 300;
      const nftRows = [];

      // render the rows of NFTs in the scrollable list
      let i = 0;
      while (i < nftsToDisplay.length) {
        const nftRow = [];

        // add each NFT to the row of NFTs
        do {
          const token = nftsToDisplay[i++];

          // render the individual NFT as it will appear in the list view
          const renderIndividualNft = () => {
            switch (token.nftType) {
              case NftType.TICKET:
                const metaData = MorphWalletUtils.compileMetaData(token.metaData, null);
                const scanned = metaData.find(metaData => metaData.name === UiText.mdScanned)?.value;
                const ticketNftHeight = 100;

                // TODO: add image next to event name
                return (
                  <div key={`token-${token.tokenId}`}
                       onClick={() => goToNftSingleView(nftList, token)}
                       className={Styles.listTicketItem}
                       style={{ minWidth: nftWidth, maxWidth: nftWidth, minHeight: ticketNftHeight, maxHeight: ticketNftHeight }}>
                    <div className={Styles.Column}>
                      <div style={{ width: nftWidth * 0.9, textAlign: 'left', padding: 10 }}>
                        <p style={{ fontSize: '0.7rem' }}>
                          {tWallet(UiText.mdEventName)}
                        </p>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <p style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
                            {token.metaData.eventDisplay}
                          </p>
                          <img src={getGifUrl(token.metaData.eventName)} style={{ width: '65px', height: '65px' }} alt="NFT gif" />
                        </div>
                      </div>
                      <hr style={{ border: 'dashed 1px', color: '#4263CF', marginLeft: 12, marginRight: 12 }}/>
                      <div className={Styles.row}
                           style={{ fontSize: '0.8rem', marginTop: 2, paddingLeft: 8 }}>
                        <div className={Styles.row}
                             style={{ width: nftWidth * 0.5 }}>
                          <p style={{ padding: 6, opacity: 0.5 }}>
                            {tWallet(UiText.TokenId)}
                          </p>
                          <p style={{ padding: 6, minWidth: 50 }}>
                            {token.tokenId}
                          </p>
                        </div>
                        {scanned !== null && (
                          <div className={Styles.row}>
                            <p style={{ padding: 6, opacity: 0.5 }}>
                              {tWallet(UiText.mdScanned)}
                            </p>
                            <p style={{ padding: 6 }}>
                              {scanned}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div style={{ position: 'absolute', zIndex: 1, width: 15, height: 15, backgroundColor: Color.Blue,
                                  left: -5, top: 56.5, borderRadius: '100%', cursor: 'pointer', userSelect: 'none' }}/>
                    <div style={{ position: 'absolute', zIndex: 1, width: 15, height: 15, backgroundColor: Color.Blue,
                                  right: -5, top: 56.5, borderRadius: '100%', cursor: 'pointer', userSelect: 'none' }}/>
                  </div>
                );
            }

            return (
              <div key={`token-${token.tokenId}`}
                   onClick={() => goToNftSingleView(nftList, token)}
                   className={Styles.listItem}
                   style={{ minWidth: nftWidth, maxWidth: nftWidth }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>
                  <p style={{ fontSize: '0.6rem' }}>
                    {MorphWalletUtils.getNftUserTypeDisplay(token.userType)}
                  </p>
                  <p style={{ fontSize: '0.6rem' }}>
                    {token.name}
                  </p>
                </div>
                <p style={{ marginBottom: 10 }}>
                  {MorphWalletUtils.getNftTypeDisplay(token.nftType)}
                </p>
                {previewFields.current?.length > 0 && (
                  renderNftMetaData(nftList, token, previewFields.current?.find(previewFields => previewFields.nftType === token.nftType)?.fields, true)
                )}
              </div>
            );
          }

          nftRow.push(renderIndividualNft());

          // TODO: after last NFT, maybe show a dotted line "blank" NFT to incentivize minting more NFTs
        } while (i % numNftsPerRow !== 0 && i < nftsToDisplay.length);

        nftRows.push(
          <div key={`nft-row-${i}`}
               className={Styles.row}
               style={{ marginRight: 3 }}>
            {nftRow}
          </div>
        );
      }

      return (
        <div className={Styles.scrollableList}
             style={{ marginLeft: 15 }}>
          {nftRows}
        </div>
      );
    }

    return (
      <div className={Styles.column}
           style={{ opacity: allButtonsDisabled && 0.5 }}>
        <p style={{ fontSize: '1.0rem', marginTop: 12 }}>
          {tWallet(getListHeaderText())}
        </p>
        {activePage === Page.MyNftsListView && isNftListPopulated() && (
          renderNftFilter()
        )}
        {renderScrollableNftList()}
        {renderError()}
        {(ThinClientUtils.isThinClient() || TESTING_MOBILE_APP_FLOW_ON_WEB) && (
          <div className={Styles.helpBubbleDiv + ' ' + Styles.helpBubble}>
            {tWallet(UiText.HelpBubblePrompts)}
          </div>
        )}
      </div>
    );
  }

  /**
   * @description Get the function that is run when the refresh button is pressed in the header.
   * */
  const getRefreshNftListFunction = () => {
    switch (activePage) {
      case Page.MyNftsListView:
        return () => goToNftListView(NftList.MyNfts, true);
      case Page.BiddableNftsListView:
        return () => goToNftListView(NftList.BiddableNfts, true);
      case Page.BidsOnNftListView:
        return () => goToNftListView(NftList.BidsOnNft, true);
    }
    return null;
  }

  /**
   * @description Refresh the NFT list when the refresh button is pressed.
   * */
  const handleRefreshNftList = async () => {
    setRefreshingNftList(true);

    const refreshNftListFunction = getRefreshNftListFunction();
    if (refreshNftListFunction) {
      await refreshNftListFunction();
    }

    setRefreshingNftList(false);
  }

  /**
   * @description Set up and render the filter for the NFT list view.
   * */
  const renderNftFilter = () => {
    const options = {};

    // count how many times each NFT type appears
    myNfts.current.forEach((token) => {
      options[token.nftType] ? options[token.nftType]++ : (options[token.nftType] = 1);
    });

    const ALL_NFTS_TEXT = tWallet(UiText.FilterAllNfts);
    const ALL_ID = 'ALL_ID';

    // apply the filter to the NFT list
    const filterNftList = (e: any) => {
      const selectedOption = e?.target?.value;
      const nftTypeDisplay = selectedOption?.substring(0, selectedOption?.lastIndexOf(' '));

      if (!nftTypeDisplay || nftTypeDisplay === ALL_NFTS_TEXT) {
        // show all NFTs
        setDisplayedNftTypes(Object.keys(NftType));
      } else {
        // get NFT type from display name and set it
        Object.keys(NftType).forEach((nftType) => {
          if (MorphWalletUtils.getNftTypeDisplay(nftType) === nftTypeDisplay) {
            setDisplayedNftTypes([nftType]);
          }
        })
      }
    }
    
    return (
      <div style={{ marginBottom: 5, marginTop: 10 }}>
        <select onChange={filterNftList}
                disabled={allButtonsDisabled}
                className={Styles.listFilter}
                style={{ opacity: allButtonsDisabled && 0.5 }}>
          <option id={ALL_ID}
                  style={{ color: Color.White }}>
            {ALL_NFTS_TEXT} ({myNfts.current.length})
          </option>
          {Object.keys(options).map((nftType, index) => (
            <option id={nftType}
                    key={nftType}
                    style={{ color: Color.White }}>
              {`${MorphWalletUtils.getNftTypeDisplay(nftType)} (${Object.values(options)[index]})`}
            </option>
          ))}
        </select>
      </div>
    );
  }

  /**
   * @description Get and render the metadata for an NFT.
   * @param {NftList} nftList The list from which that NFT was selected; if null, assume viewing NFT from elsewhere (e.g., an attached NFT).
   * @param {Token} token The token whose metadata is being displayed.
   * @param {string[]} selectedFields Any specific fields that should be shown. If provided, all other fields will be ignored.
   * @param {boolean} listView Whether we are viewing the metadata from an NFT list view.
   * */
  const renderNftMetaData = (nftList: NftList, token: Token, selectedFields: string[] = null, listView: boolean = false) => {
    // add the smart contract address for viewing in metadata
    token.metaData.smartContractAddress = token.contractAddress;

    const compiledMetaData = MorphWalletUtils.compileMetaData(token.metaData, selectedFields);

    // may be manually overwritten by compileMetaData() for certain longer values
    const fontSize = listView ? '0.7rem' : '0.8rem';

    // whether to show the field names and values side-by-side; otherwise, will show value below field
    const flexDirection = listView ? 'row' : 'column';

    // list of hidden metadata (i.e., those behind "Show" buttons) and the state variables for that metadata
    const hiddenMetaData: { name: UiText, shown: boolean, setShown: Dispatch<SetStateAction<boolean>> }[] = [
      { name: UiText.mdSmartContractAddress, shown: showSmartContractAddress, setShown: setShowSmartContractAddress },
      { name: UiText.mdHashString, shown: showConfirmationCode, setShown: setShowConfirmationCode }
    ]

    return (
      <div key={`token-${token?.tokenId}-data`}
           onClick={() => goToNftSingleView(nftList, token)}
           style={{ padding: 5 }}>
        {/* TODO: when not in list view, organize all the TRUAPP interviewer fields in their own section, and remove 'interviewer' from field names */}
        {compiledMetaData.filter(data => !(token?.nftType === NftType.TICKET && data?.name === UiText.mdEventName)).map(data => (
          <div id={`${token?.tokenId}-${data?.name}`}
               key={`${token?.tokenId}-${data?.name}`}
               style={{ display: 'flex', flexWrap: 'wrap', flexDirection }}>
            <p style={{ textAlign: 'left', margin: 3, height: 12, fontSize }}>
              {tWallet(data?.name)}
            </p>
            {hiddenMetaData?.find(metaData => metaData?.name === data?.name)?.shown === false ? (
              <button onClick={() => hiddenMetaData?.find(metaData => metaData?.name === data?.name)?.setShown(true)}
                      style={{ color: Color.LightGray, textAlign: 'center', backgroundColor: Color.Violet,
                               width: 80, height: 14, borderRadius: 2, padding: 1, margin: 2, fontSize: '0.6rem' }}>
                {tWallet(UiText.Show)}
              </button>
            ) : (
              <p style={{ textAlign: 'left', margin: 3, height: 12, maxWidth: (MorphWalletUtils.getMorphWalletWidth() - UI.PaddingSide * 2) * 0.8,
                          fontSize: data.fontSize || fontSize, color: Color.Orange, wordBreak: 'break-word' }}>
                {data?.value}
              </p>
            )}
          </div>
        ))}
      </div>
    );
  }

  /**
   * @description Set up and render the single view for a currently-selected NFT.
   * @param {NftList} nftList The list from which that NFT was selected; if null, assume viewing NFT from elsewhere (e.g., an attached NFT).
   * */
  const renderNftSingleView = (nftList: NftList = null) => {
    const token = getSingleViewNft(nftList);

    // height of div which contains read-only data like the NFT type and token ID
    const getNftDataHeight = () => {
      if (activePage === Page.AcceptedBidSingleView || activePage === Page.AttachedNftSingleView) {
        return 20;
      }

      switch (nftList) {
        case NftList.MyNfts:
          switch (token.nftType) {
            case NftType.TRUAPP:
              return 80;
            case NftType.TRUBID:
              return 50;
          }
          break;
        case NftList.BidsOnNft:
          return 20;
        case NftList.BiddableNfts:
          return 20;
      }
    }

    // height of div which contains the NFT metadata (labeled in the UI as "NFT Data")
    const getNftMetaDataHeight = () => {
      switch (token?.nftType) {
        case NftType.TICKET:
          return qrCode ? 275 : 120;
      }
    }

    const singleViewWidth = MorphWalletUtils.getMorphWalletWidth() - UI.PaddingSide * 2;
    const itemWidth = singleViewWidth * (smallMobileScreen ? 0.8 : 0.42);

    return (
      <div className={Styles.column}
           style={{ textAlign: 'center', fontSize: '0.8rem', marginTop: 10, marginBottom: isMobile() ? 20 : 5,
                    border: `1px solid ${Color.Violet}`, borderRadius: 5, backgroundColor: Color.DarkBlue }}>
        <div className={smallMobileScreen ? Styles.column : Styles.rowSpaceBetween}
             style={{ width: singleViewWidth * 0.9, height: getNftDataHeight(), marginBottom: 15, marginTop: 15 }}>
          {/* left column */}
          <div className={Styles.columnSpaceBetween}>
            {/* NFT type */}
            <div className={Styles.singleViewItem}
                 style={{ width: token.nftType === NftType.TICKET && !smallMobileScreen ? itemWidth * 1.25 : itemWidth }}>
              <p>
                {tWallet(UiText.NftType)}
              </p>
              <p style={{ color: Color.Orange }}>
                {token.nftType}
              </p>
            </div>
            {/* event name (TICKET) */}
            {nftList === NftList.MyNfts && token.nftType === NftType.TICKET && (
              <div className={Styles.singleViewItem}
                   style={{ width: token.nftType === NftType.TICKET && !smallMobileScreen ? itemWidth * 1.25 : itemWidth }}>
                <p>
                  {tWallet(UiText.Event)}
                </p>
                <p style={{ color: Color.Orange }}>
                  {MorphWalletUtils.convertToTitleCase(token.metaData?.eventDisplay)}
                </p>
              </div>
            )}
            {/* user type (e.g. owner, approved user) */}
            {nftList === NftList.MyNfts && token.nftType !== NftType.TICKET && (
              <div className={Styles.singleViewItem}
                   style={{ width: itemWidth }}>
                <p>
                  {tWallet(UiText.UserType)}
                </p>
                <p style={{ color: Color.Orange }}>
                  {token.userType}
                </p>
              </div>
            )}
            {/* approved users (TRUAPP) */}
            {nftList === NftList.MyNfts && token.nftType === NftType.TRUAPP && (
              <button onClick={goToApprovedUsers}
                      disabled={isApprovedUsersDisabled()}
                      className={Styles.nftSingleViewSmallButton}
                      style={{ width: itemWidth, opacity: isApprovedUsersDisabled() && 0.5 }}>
                {tWallet(UiText.ApprovedUsers)}
              </button>
            )}
          </div>
          {/* right column */}
          <div className={Styles.columnSpaceBetween}>
            {/* token ID */}
            <div className={Styles.singleViewItem}
                 style={{ width: token.nftType === NftType.TICKET && !smallMobileScreen ? itemWidth * 0.75 : itemWidth }}>
              <p>
                {tWallet(UiText.TokenIdColon)}
              </p>
              <p style={{ color: Color.Orange }}>
                {token.tokenId}
              </p>
            </div>
            {/* event date (TICKET) */}
            {nftList === NftList.MyNfts && token.nftType === NftType.TICKET && token.metaData?.eventDate && (
              <div className={Styles.singleViewItem}
                   style={{ width: token.nftType === NftType.TICKET && !smallMobileScreen ? itemWidth * 0.75 : itemWidth }}>
                <p>
                  {tWallet(UiText.Date)}
                </p>
                <p style={{ color: Color.Orange }}>
                  {token.metaData?.eventDate}
                </p>
              </div>
            )}
            {/* make NFT public / private */}
            {nftList === NftList.MyNfts && token.nftType === NftType.TRUAPP && (
              <button onClick={handleToggleNftPublicness}
                      disabled={isToggleNftPublicnessDisabled()}
                      className={Styles.nftSingleViewSmallButton}
                      style={{ width: itemWidth, opacity: isToggleNftPublicnessDisabled() && 0.5 }}>
                {tWallet(toggleNftPublicnessText)}
              </button>
            )}
            {/* burn NFT */}
            {nftList === NftList.MyNfts && token.nftType !== NftType.TICKET && (
              <button onClick={handleBurnNft}
                      disabled={isBurnNftDisabled()}
                      className={Styles.nftSingleViewSmallButton}
                      style={{ width: itemWidth, opacity: isBurnNftDisabled() && 0.5 }}>
                {tWallet(burnNftText)}
              </button>
            )}
          </div>
        </div>
        <div style={{ position: 'relative', width: 0, height: 0 }}>
          <div style={{ position: 'absolute', color: Color.Violet, backgroundColor: Color.DarkBlue, zIndex: 1, left: -singleViewWidth * 0.38, top: -8, minWidth: 65, opacity: 1 }}>
            <p>
              {tWallet(UiText.NftData)}
            </p>
          </div>
        </div>
        <div className={Styles.scrollableList}
             style={{ border: `1px solid ${Color.Violet}`, borderColor: Color.Violet, borderRadius: 5, display: !isMobile() && 'flex',
                      flexDirection: isMobile() ? 'column' : 'row', width: singleViewWidth * 0.9, minHeight: getNftMetaDataHeight() }}>
          {renderNftMetaData(nftList, token)}
          {qrCode && (
            <img style={{ width: 150, height: 150, margin: 'auto', marginBottom: 10, marginTop: 10 }}
                 src={qrCode}
                 alt='NFT QR code'/>
          )}
        </div>
        {nftList === NftList.MyNfts && token.nftType === NftType.TRUAPP && (
          <button onClick={acceptedBidNft.current ? goToViewAcceptedBid : () => goToNftListView(NftList.BidsOnNft, true)}
                  disabled={acceptedBidNft.current ? isViewAcceptedBidDisabled() : isViewBidsDisabled()}
                  className={Styles.nftSingleViewLargeButton}
                  style={{ color: Color.White, backgroundColor: Color.Blue, borderColor: Color.Violet, width: singleViewWidth * 0.6,
                           opacity: (acceptedBidNft.current ? isViewAcceptedBidDisabled() : isViewBidsDisabled()) && 0.5 }}>
            {tWallet(acceptedBidNft.current ? viewAcceptedBidText : viewBidsText)}
          </button>
        )}
        {nftList === NftList.MyNfts && token.nftType === NftType.TRUBID && (
          <button onClick={goToViewAttachedNft}
                  disabled={isViewAttachedNftDisabled()}
                  className={Styles.nftSingleViewLargeButton}
                  style={{ color: Color.White, backgroundColor: Color.Blue, width: singleViewWidth * 0.6,
                           borderColor: Color.Violet, opacity: isViewAttachedNftDisabled() && 0.5 }}>
            {tWallet(viewAttachedNftText)}
          </button>
        )}
        {nftList === NftList.BidsOnNft && (
          <button onClick={handleAcceptBid}
                  disabled={isAcceptBidDisabled()}
                  className={Styles.nftSingleViewLargeButton}
                  style={{ color: Color.Blue, backgroundColor: Color.Orange, width: singleViewWidth * 0.6,
                           border: 'none', opacity: isAcceptBidDisabled() && 0.5 }}>
            {tWallet(acceptBidText)}
          </button>
        )}
        {nftList === NftList.BiddableNfts && token.nftType === NftType.TRUAPP && (
          <p>
            {tWallet(purchaseOfferText)}
          </p>
        )}
        {renderError()}
      </div>
    );
  }

  /**
   * @description Determine whether the 'View Bids' button should be disabled.
   * */
  const isViewBidsDisabled = () => {
    return allButtonsDisabled
           || !selectedMyNft.current?.isPublic
           || selectedMyNft.current?.isBurned
           || viewBidsText === UiText.GettingBids;
  }

  /**
   * @description Determine whether the 'Accept Bid' button should be disabled.
   * */
  const isAcceptBidDisabled = () => {
    return allButtonsDisabled || selectedMyNft.current?.userType !== NftUserType.OWNER;
  }

  /**
   * @description Begin the flow for accepting a Purchase Bid on the selected TRU-Approval NFT.
   * */
  const handleAcceptBid = () => {
    accessPinFunction.current = AccessPinFunction.AcceptBid;
    goToAccessPin();
  }

  /**
   * @description Determine whether the 'View Accepted Bid' button should be disabled.
   * */
  const isViewAcceptedBidDisabled = () => {
    return allButtonsDisabled || viewAcceptedBidText === UiText.GettingBid;
  }

  /**
   * @description Go to view the accepted Purchase Bid on the currently selected TRU-Approval NFT
   * */
  const goToViewAcceptedBid = async () => {
    const handleError = () => {
      setDisplayedError(UiText.errAccessToken);
      setViewAcceptedBidText(UiText.ViewAcceptedBid);
      setAllButtonsDisabled(false);
    }

    setAllButtonsDisabled(true);
    setViewAcceptedBidText(UiText.GettingBid);

    if (!acceptedBidNft.current) {
      console.error('goToViewAcceptedBid.1: No accepted bid found.');
      handleError();
      return;
    }

    // get the rest of the data for the accepted bid
    if (!acceptedBidNft.current?.owner || !acceptedBidNft.current?.metaData) {
      const { error, nftOwner, nftMetaData } = await axiosCall({
        url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getNft`,
        method: 'POST',
        data: {
          publicAddress: keys.current?.publicAddress,
          ...acceptedBidNft.current
        }
      });

      if (error || !nftOwner || !nftMetaData) {
        console.error(`goToViewAcceptedBid.2: ${error || 'NFT data not found.'}`);
        handleError();
        return;
      }

      // set the accepted bid data
      acceptedBidNft.current.owner = nftOwner;

      const bidMetaData = nftMetaData?.bidData;

      // instead of showing the ID and contract address of the TRU-Approval NFT, show the simplified name
      bidMetaData.truTokenName = MorphWalletUtils.getNftName(bidMetaData?.truContractAddress, bidMetaData?.truTokenId);
      bidMetaData.bidStatus = 'Accepted';

      acceptedBidNft.current.metaData = bidMetaData;
    }
    
    setActivePage(Page.AcceptedBidSingleView);
    setAllButtonsDisabled(false);
  }

  /**
   * @description Determine whether the 'View Attached NFT' button should be disabled.
   * */
  const isViewAttachedNftDisabled = () => {
    return allButtonsDisabled || viewAttachedNftText === UiText.GettingNft;
  }

  /**
   * @description View the attached TRU-Approval NFT from the selected Purchase Bid NFT.
   * */
  const goToViewAttachedNft = async () => {
    setAllButtonsDisabled(true);
    setViewAttachedNftText(UiText.GettingNft);

    // get attached NFT data
    const { error, tokenId, contractAddress, nftOwner, nftMetaData, isPublicData } = await axiosCall({
      url: `${MorphWalletUtils.BLOCKCHAIN_PATH}/getNft`,
      method: 'POST',
      data: {
        contractAddress: selectedMyNft.current?.metaData?.truContractAddress,
        nftType: NftType.TRUAPP,
        tokenId: selectedMyNft.current?.metaData?.truTokenId,
        ...keys.current
      }
    });

    if (error) {
      console.error(`goToViewAttachedNft: ${error || `Missing metadata for token ${selectedMyNft.current?.metaData?.truTokenId}.`}`);
      setViewAttachedNftText(UiText.ViewAttachedNft);
      setDisplayedError(UiText.errAccessToken);
      setAllButtonsDisabled(false);
      return;
    }

    // set the attached NFT data
    attachedNft.current = {
      tokenId,
      contractAddress,
      name: MorphWalletUtils.getNftName(contractAddress, tokenId),
      nftType: NftType.TRUAPP,
      userType: null,
      owner: nftOwner,
      metaData: nftMetaData?.truApprovalObjJson,
      isPublic: isPublicData,
      isBurned: false
    };

    setActivePage(Page.AttachedNftSingleView);
  }

  return (
    <div className={Styles.morphWalletWrapper}
         style={MorphWalletUtils.getMorphWalletStyling(activeTab)}>
      {renderHeader()}
      {/* welcome */}
      {activePage === Page.Welcome && (
        <>
          {loadWallet()}
        </>
      )}
      {/* recover account */}
      {activePage === Page.RecoverAccount && (
        <div className={Styles.column}>
          {renderError()}
          <p style={{ fontSize: '1.0rem', fontWeight: 500, marginBottom: 10 }}>
            {tWallet(UiText.EnterPhrase)}
          </p>
          {renderMnemonicInputs()}
          <div className={Styles.helpBubbleDiv + ' ' + Styles.helpBubble}
               style={{ marginTop: 5 }}>
            {tWallet(UiText.HelpBubbleTypePhrase)}
          </div>
        </div>
      )}
      {/* view recovery phrase */}
      {activePage === Page.ViewRecoveryPhrase && (
        <div className={Styles.column}>
          {renderError()}
          <p style={{ fontSize: '1.2rem', fontWeight: 500, marginBottom: 12, marginTop: -2 }}>
            {tWallet(UiText.YourPhrase)}
          </p>
          <div className={isMobile() ? Styles.column : Styles.row}
               style={{ marginBottom: isMobile() && 10 }}>
            {renderMnemonicDisplay()}
          </div>
          <div className={Styles.helpBubbleDiv}>
            <div className={Styles.helpBubble + ' ' + Styles.row}
                style={{ border: `2px solid ${Color.Red}`, alignItems: 'center' }}>
              <Warning style={{ color: Color.Red, marginRight: 3 }}/>
              {tWallet(UiText.HelpBubblePhrase)}
            </div>
            {!encryptedKeys.current && (
              <div className={Styles.helpBubble}>
                {tWallet(UiText.HelpBubblePhraseContinue)}
              </div>
            )}
          </div>
        </div>
      )}
      {/* enter access PIN */}
      {activePage === Page.AccessPin && (
        <div className={Styles.column}>
          {renderError()}
          <p style={{ fontSize: '1.2rem', marginBottom: 12 }}>
            {tWallet(accessPinText)}
          </p>
          <div style={{ marginBottom: 15 }}>
            {accessPinInputIds.map((accessPinInputId) => (
              <input
                key={accessPinInputId}
                id={accessPinInputId}
                type='tel'
                autoComplete='off'
                autoFocus={accessPinInputId === accessPinInputIds[0]}
                onKeyDown={handleCodeKeyDown}
                onChange={handleEnterCode}
                disabled={allButtonsDisabled}
                className={Styles.codeInput}
                style={{ marginLeft: accessPinInputId === accessPinInputIds[0] ? 0 : 5, opacity: allButtonsDisabled && 0.5 }}
              />
            ))}
          </div>
          <div style={{ minHeight: 24, marginBottom: isMobile() ? 15 : 5 }}>
            {showResetPin && (
              <div onClick={handleResetCode}
                  className={Styles.row}
                  style={{ color: Color.LightGray, cursor: 'pointer', userSelect: 'none', alignItems: 'center', fontSize: '0.8rem',
                            pointerEvents: allButtonsDisabled ? 'none' : 'auto', opacity: allButtonsDisabled && 0.5 }}>
                
                  <Clear style={{ width: 15, marginRight: 5 }}/>
                {tWallet(UiText.ResetPin)}
              </div>
            )}
          </div>
          {(accessPinText === UiText.EnterNewPin || prevAccessPinText.current === UiText.EnterNewPin) && (
            <div className={Styles.helpBubble + ' ' + Styles.helpBubbleDiv}>
              {tWallet(UiText.HelpBubbleAccessPin)}
            </div>
          )}
        </div>
      )}
      {/* link device (mobile app view) */}
      {activePage === Page.LinkDeviceMobile && (
        <div className={Styles.column}>
          {renderError()}
          <div style={{ marginBottom: 20 }}>
            {linkDeviceCodeInputIds.map((linkDeviceCodeInputId) => (
              <input
                key={linkDeviceCodeInputId}
                id={linkDeviceCodeInputId}
                type='tel'
                autoComplete='off'
                autoFocus={linkDeviceCodeInputId === linkDeviceCodeInputIds[0]}
                onKeyDown={handleCodeKeyDown}
                onChange={handleEnterCode}
                disabled={allButtonsDisabled}
                className={Styles.codeInput}
                style={{ marginLeft: linkDeviceCodeInputId === linkDeviceCodeInputIds[0] ? 0 : 5, opacity: allButtonsDisabled && 0.5 }}
              />
            ))}
          </div>
          <div className={Styles.helpBubbleDiv}>
            <div className={Styles.helpBubble}>
              {tWallet(UiText.HelpBubbleLinkDevice)}
            </div>
            <div className={Styles.helpBubble}>
              {tWallet(UiText.HelpBubbleSameAccount)}
            </div>
          </div>
        </div>
      )}
      {/* link device (mobile app view, after success) */}
      {activePage === Page.LinkDeviceMobileSuccess && (
        <div className={Styles.column}>
          <p style={{ fontSize: '1.0rem', marginBottom: 10, marginTop: 15 }}>
            {tWallet(UiText.LinkSuccess)}
          </p>
          <p style={{ fontSize: '1.0rem', marginBottom: 20 }}>
            {tWallet(UiText.ReturnToDevice)}
          </p>
        </div>
      )}
      {/* link device (web app view, usually desktop) */}
      {activePage === Page.LinkDeviceDesktop && (
        <div className={Styles.column}>
          <p style={{ color: Color.Orange, fontSize: '1.4rem', fontWeight: 500, marginTop: 10, marginBottom: 20 }}>
            {tWallet(UiText.AngelWallet)}
          </p>
          <p style={{ textAlign: 'center', fontSize: '1.0rem', marginBottom: 20, lineHeight: 1.5 }}>
            {tWallet(UiText.OpenMobile)}
          </p>
          <p style={{ fontSize: '1.0rem', marginBottom: 10 }}>
            {tWallet(UiText.EnterCode)}
          </p>
          <p style={{ color: Color.Orange, fontWeight: 500, fontSize: '2.0rem', marginBottom: 5}}>
            {linkDeviceCode.current.join(' ')}
          </p>
          <LinearProgress variant='determinate'
                          value={linkDeviceTimerProgress}
                          sx={{ backgroundColor: Color.Blue, '& .MuiLinearProgress-bar': { backgroundColor: Color.Orange }, width: 120, height: 3, marginBottom: 2 }}/>
          <p style={{ fontSize: '0.8rem', marginBottom: isMobile() ? 20 : 10 }}>
            {tWallet(UiText.Waiting)}
          </p>
        </div>
      )}
      {/* list of NFTs on which the user is an owner or approved user */}
      {activePage === Page.MyNftsListView && (
        renderNftListView(NftList.MyNfts)
      )}
      {/* single NFT view from MY_NFTS list */}
      {activePage === Page.MyNftsSingleView && (
        renderNftSingleView(NftList.MyNfts)
      )}
      {/* see and update approved users */}
      {activePage === Page.ApprovedUsers && (
        <div className={Styles.column}>
          <p style={{ fontSize: '1.0rem', marginBottom: 12 }}>
            {tWallet(approvedUsers?.length > 0 ? UiText.SelectUser : UiText.NoApprovedUsers)}
          </p>
          {approvedUsers?.length > 0 && (
            <div className={Styles.scrollableList}
                 style={{ marginBottom: 15 }}>
              {approvedUsers.map((user) => (
                <button id={user}
                        key={user}
                        onClick={selectUserToBeRemoved}
                        disabled={isUpdateUsersDisabled()}
                        style={{ color: Color.White, backgroundColor: Color.Blue,
                                 borderColor: user === userToBeRemoved ? Color.Orange : Color.Violet,
                                 fontSize: '0.7rem', opacity: isUpdateUsersDisabled() && 0.5 }}>
                  {user}
                </button>
              ))}
            </div>
          )}
          {approvedUsers?.length > 0 && (
            <button onClick={handleRemoveUser}
                    disabled={isUpdateUsersDisabled()}
                    style={{ color: Color.Blue, backgroundColor: Color.Orange, border: 'none',
                             fontWeight: 550, opacity: isUpdateUsersDisabled() && 0.5 }}>
              {tWallet(UiText.RemoveUser)}
            </button>
          )}
          <hr style={{ borderColor: Color.Violet, width: 300, marginBottom: 15 }}/>
          <p style={{ fontSize: '1.0rem', marginBottom: 12 }}>
            {tWallet(UiText.EnterAddress)}
          </p>
          <input
            id={ADD_USER_INPUT_ID}
            value={userToBeAdded.current}
            autoComplete='off'
            autoFocus
            className={Styles.input}
            style={{ fontSize: '0.7rem', width: 300, marginBottom: 15 }}
          />
          <button onClick={handleAddUser}
                  disabled={allButtonsDisabled}
                  className={Styles.nftSingleViewLargeButton}
                  style={{ color: Color.Blue, backgroundColor: Color.Orange, border: 'none',
                           fontWeight: 550, opacity: allButtonsDisabled && 0.5 }}>
            {tWallet(UiText.AddUser)}
          </button>
          {renderError()}
        </div>
      )}
      {/* see the TRUAPP NFT attached to a TRUBID NFT */}
      {activePage === Page.AttachedNftSingleView && (
        renderNftSingleView()
      )}
      {/* see the bids made on a token */}
      {activePage === Page.BidsOnNftListView && (
        renderNftListView(NftList.BidsOnNft)
      )}
      {/* see a bid made on a token */}
      {activePage === Page.BidsOnNftSingleView && (
        renderNftSingleView(NftList.BidsOnNft)
      )}
      {/* see the accepted bid on a token */}
      {activePage === Page.AcceptedBidSingleView && (
        renderNftSingleView()
      )}
      {/* marketplace */}
      {activePage === Page.Marketplace && (
        <div className={Styles.column}>
          <div style={{ display: 'flex', marginTop: 15, marginBottom: 15 }}>
            {/* TODO: add image icons to buttons */}
            {[
              { name: tWallet(UiText.ViewMyBids), func: () => setActivePage(Page.ViewMyBids) },
              { name: tWallet(UiText.ViewPublicNfts), func: () => goToNftListView(NftList.BiddableNfts, true) }
              ].map((button) => (
              <div key={button.name}
                   className={Styles.marketplaceOption}>
                <div style={{ textAlign: 'center' }}>
                  <button
                    onClick={button.func}
                    disabled={allButtonsDisabled}
                    className={Styles.marketplaceButton}
                    style={{ opacity: allButtonsDisabled && 0.5 }}
                  />
                </div>
                <p className={Styles.marketplaceText}
                   style={{ opacity: allButtonsDisabled && 0.5 }}>
                  {button.name}
                </p>
              </div>
            ))}
          </div>
          {renderError()}
        </div>
      )}
      {/* view my bids */}
      {activePage === Page.ViewMyBids && (
        renderNftListView(NftList.MyNfts)
      )}
      {/* list of NFTs on which the user can place a bid */}
      {activePage === Page.BiddableNftsListView && (
        renderNftListView(NftList.BiddableNfts)
      )}
      {/* single NFT view from BiddableNfts list */}
      {activePage === Page.BiddableNftsSingleView && (
        renderNftSingleView(NftList.BiddableNfts)
      )}
      {renderFooter()}
    </div>
  );
};

export default MorphWallet;