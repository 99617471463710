const IS_FIRST_MOUNT_STORAGE_KEY = 'wumc_is_first_mount';
const WERE_SUGGESTIONS_ALREADY_SHOWN_KEY = 'wumc_were_suggestions_already_shown';

function getFromLocalStorage(key) {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
}

function setInLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

const KEYS = {
    IS_FIRST_MOUNT_STORAGE_KEY,
    WERE_SUGGESTIONS_ALREADY_SHOWN_KEY
}

const strBoolean = (strBool) => {
    if (typeof strBool === 'string') {
        if (strBool === 'true') return true;
        else return false;
    } else {
        return strBool;
    }
}

const _localStorageTools = {
    getFromLocalStorage,
    setInLocalStorage,
    KEYS,
    strBoolean
}

export {
    _localStorageTools
}