import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import Styles from '@styles/MessageContent/DisclaimerMessageContent.module.css';
import mcStyles from '@styles/MessageContent/MessageContent.module.css';
import Sanitized from '@components/Sanitized';
import patentList from '@assets/patents.json';
import { Close } from '@assets/Icons';
import { IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import { useMessageContext } from '@contexts/MessageContext';
import { uiTranslationController } from '@common/common';
import { useRootContext } from '@contexts/RootContext';

type TDiclaimerMessageContentProps = {
  message: IMessage;
}

const DisclaimerMessageContent = ({
  message, 
}: TDiclaimerMessageContentProps) => {
  const errandContext = useErrandContext();
  const messageContext = useMessageContext();
  const rootContext = useRootContext();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const disclaimerMessageRef = useRef(null);
  const { dispMessage } = uiTranslationController(message);

  const targetMessageId = message._id;
  const errandId = errandContext.errandId;
  
  // Find the errand with the given errandId
  const targetErrand = rootContext.errands.find(errand => errand._id === errandId);
  
  let messageIndex;
  
  // If the errand is found, find the index of the disclaimer message within the messages array
  if (targetErrand) {
      messageIndex = targetErrand.messages.findIndex(message => message._id === targetMessageId);
  }

  if (messageIndex !== 0){
    console.log(`DisclaimerMessageContent: Disclaimer attempted to be inserted at messages index of ${messageIndex} but stopped successfully.`)
  }

  useEffect(() => {
    const handleLink = (e) => {
      if (e.target.tagName === 'A') {
        let href = e.target.getAttribute('href');
        if (href.indexOf('https://www.celligence.com/patents') !== -1) {
          e.preventDefault();
          setOpen(true);
        }
        if (href.startsWith('https://www.swmc.com/')){
          e.preventDefault();
          messageContext.setPreviewUrl(href);
        }
      }
    };
    // fetch the disclaimer height on mount
    const disclaimerHeight = disclaimerMessageRef.current?.getBoundingClientRect().height || 0;
    if(disclaimerHeight === 0) {
      console.warn("[DisclaimerMessageContent.tsx]: disclaimerHeight is 0, something is wrong!")
    }
    messageContext.setDisclaimerHeight(disclaimerHeight);
    const msg = disclaimerMessageRef.current;
    msg.addEventListener('click', handleLink);

    // remove all event listeners on unmount
     return () => {
       msg.removeEventListener('click', handleLink);
     };
  }, []);

  // if the disclaimer is not the first message, don't show it
  if (messageIndex !== 0){
    return (
      <div ref={disclaimerMessageRef}/>
    );
  }else{
    return (
      <>
        <div
          ref={disclaimerMessageRef}
          className={[
            mcStyles.peAll,
            Styles.wrapper,
            // ...(isFirstMessage ? [Styles.isFirstMessage] : []),
            ...(messageContext.isDisclaimerSeparate ? [Styles.isSeparate] : []),
            ...(!message.visible ? [Styles.blur] : []),
          ].join(' ')}
        >
          <Sanitized
            highlight={message.searchWords}
            html={dispMessage}
            visible={message.visible}
          />
        </div>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: 0,
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: '1.5rem',
              lineHeight: '1.334',
            }}>
            <span>{t('patentTitle')}</span>
            <Close
              style={{ width: 1 + 'em', cursor: 'pointer', pointerEvents: 'all' }}
              color="var(--red400)"
              onClick={(e) => {
                e?.stopPropagation();
                setOpen(false);
              }}
            />
          </DialogTitle>
          <DialogContent>
            <List>
              {patentList &&
                patentList.map((patent, index) => (
                  <ListItem key={index} sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Typography>
                      <strong>{patent.title}</strong> {` ${patent.appNumber}`}
                    </Typography>
                  </ListItem>
                ))}
            </List>
          </DialogContent>
        </Dialog>
      </>
    );
  }

};

export default DisclaimerMessageContent;