import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/UserPromptListFooter.module.css';

type TUserPromptListFooterProps = {
  setShowUserPromptList: Dispatch<SetStateAction<boolean>>;
  showUserPromptList: boolean;
}

const UserPromptListFooter = ({
  setShowUserPromptList, showUserPromptList, 
}: TUserPromptListFooterProps) => {
  const { t } = useTranslation();

  return (
    <footer className={Styles.footer}>
      <button onClick={() => setShowUserPromptList(false)} tabIndex={showUserPromptList ? undefined : -1}>
        {t('userPromptListFooterButton')}
      </button>
    </footer>
  );
};
export default UserPromptListFooter;