import React, { PropsWithChildren } from 'react';

import { Styles } from '../Styles/HoneyPotStyles';

const HoneyPot: React.FC<PropsWithChildren<any>> = (props) => {
  return (
    <Styles
      autoComplete='new-password'
      name='a_password'
      onChange={props.setHoneyPot}
      tabIndex={-1}
      type='password'
      value={props.honeyPot}
    />
  );
};

export default HoneyPot;
