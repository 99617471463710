import { Box } from '@mui/material';
import React, { forwardRef, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';

const VerticalScrollRegion = styled(Box)(() => ({
  overflowY: 'scroll',
  overflowX: 'hidden',
  width: '100%',
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px var(--shadow000)',
    webkitBoxShadow: 'inset 0 0 6px var(--shadow000)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'var(--shadow110)',
    outline: '1px solid slategrey',
    borderRadius: '0.2em',
  },
}));

const HorizontalScrollRegion = styled(Box)(() => ({
  overflowX: 'scroll',
  overflowy: 'hidden',
  maxWidth: '100%',
  '&::-webkit-scrollbar': {
    height: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px var(--shadow000)',
    webkitBoxShadow: 'inset 0 0 6px var(--shadow000)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'var(--shadow110)',
    outline: '1px solid slategrey',
    borderRadius: '0.2em',
  },
}));

const ScrollBox: React.FC<PropsWithChildren<any>> = forwardRef((props, ref) => {
  if (props.horizontal) {
    return (
      <HorizontalScrollRegion id={props.id} sx={{ width: props.width || 500, ...props.sx }} ref={ref} onScroll={props.onScroll}>
        {props.children}
      </HorizontalScrollRegion>
    );
  } else {
    return (
      <VerticalScrollRegion id={props.id} sx={{ height: props.height || 500, ...props.sx }} ref={ref} onScroll={props.onScroll}>
        {props.children}
      </VerticalScrollRegion>
    );
  }
});

export default ScrollBox;
