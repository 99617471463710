import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Step,
  MenuItem,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
// import { styled , useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import themeFile from '@assets/Themes/default.json';

import { Close } from '../Assets/Icons';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';

// const StyledStepper = styled(Stepper)((themecolor) => ({
const StyledStepper = styled(Stepper)({
  '& .MuiStepIcon-root.Mui-completed': {
    color: themeFile.palette.blue['210'], //themecolor,
  },
  '& .MuiStepIcon-root.Mui-active': {
    display: "none",
    color: themeFile.palette.blue['210'], //themecolor,
  },
});

const mt = {
  mt:"12px"
};

const EditEmailTemplateDialog: FC<DialogPropsWithChildren> = (props) => {
  // const theme = useTheme();
  const { t } = useTranslation();
  const activeStep = 0;
  const [EmailTemplateName, setEmailTemplateName] = useState(props?.EmailTemplate?.name || '');
  const [EmailTemplateDescription, setEmailTemplateDescription] = useState(
    props?.EmailTemplate?.description || ''
  );
  const [visible, setVisible] = useState(props?.EmailTemplate?.visible !== undefined ? props?.EmailTemplate?.visible : true);
  const [currWorkflow, setCurrWorkflow] = useState(props?.EmailTemplate?.workflow || '');
  const [emailTemplateSubject, setEmailTemplateSubject] = useState(props?.EmailTemplate?.subject || '');
  const [defaultRecipient, setDefaultRecipient] = useState(props?.EmailTemplate?.defaultRecipient || '');
  const [emailTemplateText, setEmailTemplateText] = useState(
    props?.EmailTemplate?.text || ''
  );
  const steps = [t('Email Template Details')] //, t('emailTemplateBody')];


  /*************************************
   * Submit Function
   *************************************/
  const handleSubmit = () => {
    let data = {
      id: '', //MMMH
      name: EmailTemplateName,
      description: EmailTemplateDescription,
      workflow: currWorkflow === ''? null : currWorkflow,
      subject: emailTemplateSubject,
      text: emailTemplateText,
      visible: visible,
      defaultRecipient: defaultRecipient
    };

    if (!props.edit) {
      props.onAdd(data);
    } else {
      data = { ...data, id: props.EmailTemplate.id };
      props.onSubmit(data);
    }
  };

  return (
    <Dialog maxWidth="md" open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {props.edit ? t('editEmailTemplate') : t('createEmailTemplate')}
          </Typography>
          <Close
            style={{ width: '12px', cursor: 'pointer' }}
            color="var(--gray210)"
            onClick={() => props.onClose()}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '40vw' }}>
        <StyledStepper
          // themecolor={ themeFile.palette.blue['200'] }
          activeStep={activeStep}
          alternativeLabel>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </StyledStepper>
        <Stack>
          <FormControlLabel
              sx={{ marginLeft: '0' }}
              control={
                <Checkbox
                  color="info"
                  checked={visible}
                  onChange={(e) => {
                    setVisible(e.target.checked);
                  }}
                />
              }
              label={t('visibleCol')}
              labelPlacement="end"
            />
          <TextField
            autoFocus
            color="info"
            label={`${t('nameCol')}*`}
            onChange={(e) => setEmailTemplateName(e.target.value)}
            size="small"
            sx={{...mt}}
            value={EmailTemplateName}
            variant="outlined"
          />
          <TextField
            color="info"
            label={`${t('descriptionCol')}*`}
            multiline
            onChange={(e) => setEmailTemplateDescription(e.target.value)}
            rows={2}
            size="small"
            sx={{...mt}}
            value={EmailTemplateDescription}
            variant="outlined"
          />
            <TextField
            autoFocus
            color="info"
            label={`${t('To')}`}
            onChange={(e) => setDefaultRecipient(e.target.value)}
            size="small"
            sx={{...mt}}
            value={defaultRecipient}
            variant="outlined"
          />
          <TextField
            autoFocus
            color="info"
            label={`${t('SubjectTranslation')}*`}
            onChange={(e) => setEmailTemplateSubject(e.target.value)}
            size="small"
            sx={{...mt}}
            value={emailTemplateSubject}
            variant="outlined"
          />
          <TextField
            label={`${t('workflowTranslation')}*`}
            select
            sx={{...mt}}
            size="small"
            color="info"
            value={currWorkflow}
            onChange={(e) => setCurrWorkflow(e.target.value)}
          >
            <MenuItem key={0} value=''>
              &nbsp;{' '}
            </MenuItem>
            {props.workflows.map((w) => {
              //MAP ALL OF THE NORMAL MORGAN WORKFLOWS TO THE SELECT
              return (
                <MenuItem key={w?._id} value={w?._id}>
                  {w.name}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            color="info"
            label={`${t('Body')}*`}
            multiline
            onChange={(e) => setEmailTemplateText(e.target.value)}
            rows={8}
            size="small"
            sx={{...mt}}
            value={emailTemplateText}
            variant="outlined"
          />

        </Stack>
        <Typography color={themeFile.palette.red['400']}>{props.error}</Typography>
      </DialogContent>
      <DialogActions>
          <Button
            disabled={EmailTemplateName === '' || EmailTemplateDescription === ''}
            variant="contained" color="info"
            onClick={() => handleSubmit()}>
            {t('doneButton')}
          </Button>

      </DialogActions>
    </Dialog>
  );
};

export default EditEmailTemplateDialog;
