/*
This component renders a prompt in the Conversation Footer to ask the user for consent for humans to monitor their chats.
In the future, this component will need to be generalized to match any other text that uses this format.
*/
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../Common/hooks/useWindowDimensions';
import { useRootContext } from '@contexts/RootContext';
import Styles from '@styles/FormConsentContent.module.css';
import eventBus from '../Common/eventBus';
import { useFooterContext } from '@contexts/FooterContext';

enum ConsentType {
  None,
  Form,
}

type TFormConsentContentProps = {
  userConsent: string,
  setUserConsent: any
}

// @ts-ignore
const FormConsentContent = (props: TFormConsentContentProps) => {
  const footerContext = useFooterContext();
  const rootContext = useRootContext();
  const isWidget = rootContext.isWidget;
  const { t } = useTranslation();

  // renders the scroll button or no / yes as needed
  const [consentState, setConsentState] = useState<ConsentType>(ConsentType.None);
  const [hasClickedConsent, setHasClickedConsent] = useState<boolean>(false);
  const [showScrollButton, setShowScrollButton] = useState<boolean>(false);

  const FormconsentContentRef = useRef<HTMLDivElement>(null);
  const paragraph = useRef<HTMLDivElement>(null);
  const scrollTopRef = useRef<number>(0);
  const isScrollingUp = useRef<boolean>(false);

  const acceptConsent = useCallback(() => {
    setHasClickedConsent(true);
    setConsentState(ConsentType.None);
    footerContext.needToShowFormConsentRef.current = false;
    if (isWidget) {
      window.parent.postMessage({eventType: 'closeConsentPopup'}, '*');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWidget]);

  const denyConsent = useCallback(() => {
    setHasClickedConsent(true);
    setConsentState(ConsentType.None);
    if (isWidget) {
      window.parent.postMessage({eventType: 'closeConsentPopup'}, '*');
    }
  }, [isWidget]);

  const handleResize = useCallback(() => {
    if (!paragraph.current) return;

    if (paragraph.current.scrollTop + paragraph.current.clientHeight >= paragraph.current.scrollHeight - 10) {
      setShowScrollButton(false);
    } else {
      setShowScrollButton(true);
    }
  }, []);

  useWindowDimensions(handleResize);

  const handleScroll = useCallback((e = null) => {
    if (e === true) {
      paragraph.current.scrollTo({behavior: 'smooth', top: paragraph.current.scrollHeight - paragraph.current.offsetHeight });
      return setShowScrollButton(false);
    }

    const deltaY = (e?.target?.scrollTop || 0) - scrollTopRef.current;
    scrollTopRef.current = e?.target?.scrollTop || 0;
    isScrollingUp.current = deltaY < 0;
    if (isScrollingUp) return setShowScrollButton(true);

    if (paragraph.current.scrollTop + paragraph.current.clientHeight >= paragraph.current.scrollHeight - 10) {
      return setShowScrollButton(false);
    }
  }, []);

  useEffect(() => {
    const container = paragraph.current;
    if (!container || consentState === ConsentType.None) return;

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll, consentState]);

  useEffect(() => {
    const handleFormConsent = (e) => {
      setConsentState(ConsentType.Form);
      setHasClickedConsent(false);
    };

    eventBus.on('showFormConsent', handleFormConsent);
    return () => {
      eventBus.remove('showFormConsent', handleFormConsent);
    }
  }, [isWidget, denyConsent]);

  const renderTopContent = () => {
    switch (consentState) {
      default: 
        return (
          <div
            className={Styles.terms}
            onAnimationEnd={() => {
              setShowScrollButton(paragraph.current?.scrollHeight !== paragraph.current?.clientHeight)
              paragraph.current.focus();
            }}
          >
            <h1>AngelAi</h1>
            <p ref={paragraph}>{t('consentForm')}</p>
          </div>
        );
    }
  };

  const renderBottomContent = () => {
    switch (consentState) {
      default:
        return (
          <>
            <button onClick={denyConsent} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
              {t('denyConsent')}
            </button>
            <>
              {showScrollButton ? (
                <button onClick={() => handleScroll(true)} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
                  {t('scrollDown')}
                </button>
              ) : (
                <button onClick={acceptConsent} tabIndex={consentState === ConsentType.None ? -1 : undefined}>
                  {t('iConsent')}
                </button>
              )}
            </>
          </>
        );
    }
  };

  return (
    <section 
      className={[
        Styles.wrapper,
        Styles[`type${consentState}`],
        ...(hasClickedConsent ? [Styles.hasClickedConsent] : []),
      ].join(' ')}
      ref={FormconsentContentRef}>
      <div className={Styles.content}>
        <div className={Styles.consentTop}>
          {renderTopContent()}
        </div>
        <div className={Styles.consentBottom}>
          <div>
            {renderBottomContent()}
          </div>
        </div>
      </div>
    </section>
  );
};
export default FormConsentContent;