/**
 * This file serves the common functions used in decrypting hashkeys and
 * extracting its data to perform special operations depending on its
 * hashkey type. For example, it can be an imagingUploads hashkey or it could
 * be a imagingPreview hashkey
 * @author Eric Velepucha
 */
import axiosCall from '@services/axios';


interface IDecodedHashKey {
  type: string;
  parameters: IParameters;
}
interface IParameters {
  workflow: string;
}

export const decodeHash = async (hashkey: string): Promise<IDecodedHashKey> => {
  return await axiosCall({
    url: `hashkey/${hashkey}`,
  });
};
