import { useEffect, useState, useCallback, MutableRefObject } from "react";
import { useTranslation } from 'react-i18next';
import { getDropdownDescription } from '@common/StringUtils';
import axiosCall from '@services/axios';
import useInitialMount from '@common/hooks/useInitialMount';
import LanguageUtils from '@common/LanguageUtils.js';
import useDebounce from '@common/hooks/useDebounce';
import useControllerCallback from "@common/hooks/useControllerCallback";
import useAbortController from "@common/hooks/useAbortController";
import { IChatAction, IField } from "@interfaces/Conversation";
import { useSocketContext } from "@contexts/socket";

const LIMIT = 15;

const fetchActions = async (config: AbortController) => {
  const data = await axiosCall(
    { url: `action?limit=${LIMIT}&type=Field&active=true&visible=true&fields=_id,description,fieldName,actionIcon` },
    config
  );
  return data;
};

const searchActions = async (searchString: string, config: AbortController) => {
  const searched = await axiosCall(
    {
      url: 'action/db/search?active=true&visible=true&fields=_id,description,fieldName,actionIcon',
      method: 'post',
      data: {
        search: searchString
      }
    },
    config
  );
  return searched;
}

const fetchActionFields = async (chatId: string, config: AbortController) => {
  const fields = await axiosCall(
    { url: `chat/${chatId}/field` },
    config
  );
  return fields;
}

/**
 * This hook is intended to load the actions for the action list on the 
 * console. It handles, searching and updating of field entries which appear
 * under each action that has a response. 
 * @param chatId 
 * @param parentChatFields 
 * @param setChatFields 
 * @param socketContext 
 * @param selectedTab 
 * @returns 
 */
export default function useLoadActions(
  chatId: string,
  parentChatFields: IField[],
  setChatFields,
  selectedTab: string) {
  const { messagesSocket, isMessagesConnected } = useSocketContext();
  const [actions, setActions] = useState([]); // The searched results of to display
  const [hasMoreActions, setHasMoreActions] = useState(true);
  const [actionsOffset, setActionsOffset] = useState(1);
  const [actionSearchString, setActionSearchString] = useState('');
  // Contains the names of the actions, translated into the current language --
  // will be empty if the current language is set to English.
  const [translatedActionNames, setTranslatedActionNames] = useState([]);
  const { i18n } = useTranslation();
  const abortController = useAbortController();

  /**
   * @description This function accepts the list of actions and the chatFields for the chatId then
   * maps the fields to the actions. Allowing for the operators to see the values that were entered for the actions/fields.
   * The fields are categorized based on the contexts within the chat. If there aren't any context but there are values for
   * an action, then they will be stored under a 'foster' field. 
   * @param {*} actions 
   * @param {*} chatFields 
   * @returns 
   */
  const loadFieldValuesIntoActions = (actions: IChatAction[], chatFields: any = null) => {
    // Use chatFields directly if it's provided, otherwise default to null
    chatFields = chatFields ? chatFields : parentChatFields;
    if (chatFields && Array.isArray(actions)) {
      // step through each action and populate the contextField data
      actions.forEach((action: IChatAction) => {
        let contextFields = [];
        if (action.fieldName in chatFields) { // if the quick request / action is listed in the fields returned that have values then
          // assign those values to the quick request and format it properly for display.
          action['fields'] = chatFields[action?.fieldName];
          // Iterate the fields 
          (action['fields'])?.forEach((fieldData: IField) => {
            fieldData.value = getDropdownDescription(fieldData.value);
            if (fieldData.context) {
              contextFields[fieldData.context] ?
                contextFields[fieldData.context].push(fieldData) :
                contextFields[fieldData.context] = [fieldData];
            } else {
              // Extract the foster fields 
              contextFields['fosterFields'] ?
                contextFields['fosterFields'].push(fieldData) :
                contextFields['fosterFields'] = [fieldData];
            }
          });
        }
        action['contextFields'] = contextFields;
      });
    }
    return actions;
  }

  /**
    * This function requests the list of fields for a specific chatId 
    * The fields are then used to populate the values of the Quick Requests for that chat.
    * @param {*} chatId 
    * @returns 
    */
  const loadActionFields = async (chatId: string, actions: IChatAction[]) => {
    const config = abortController.get();
    if (!chatId || !actions) return;
    const fields = await fetchActionFields(chatId, config);
    if (Object.keys(fields).length > 0) {
      setChatFields(fields);
      const updatedActions = loadFieldValuesIntoActions(actions, fields);
      setActions(updatedActions);
    } else {
      // set to empty if there is none
      setChatFields({});
    }
    return fields;
  }

  async function loadActions() {
    const config = abortController.get();
    // Load the actions
    console.log('Loading actions');
    // Get all actions
    try {
      // TODO: Integrate the action field retrieval into the CORE endpoint
      const actionList = await fetchActions(config);
      if (actionList) {
        setActions(actionList);
        await loadActionFields(chatId, actionList);
        setActionsOffset(1);
        setHasMoreActions(true);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useInitialMount(() => {
    (async () => {
      if (actions.length === 0) {
        await loadActions();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  // Update Action Fields based on Field input
  useEffect(() => {
    if (!isMessagesConnected) return;

    /**
     * @description If a new field value is submitted to the chat then it should update the relevant field list.
     * This means either adding to an existing list or initiating the list as an array with 1 value.
     * @param {*} payload 
     */
    const insertNewFieldData = async (payload: { data: IField; }) => {
      console.log(`Messages Socket - ActionList - (field-value-update) ${chatId}`, payload);
      if (!payload || !payload.data) {
        return;
      }
      const fieldData = payload.data;
      fieldData.value = getDropdownDescription(fieldData?.value);
      // Get the previous Chat Fields and add the new one where it should be
      // Set parent chat fields as well.
      setChatFields((prevChatFields) => {
        // Also need to update the chatFields to allow for the data to update properly when they scroll or search.
        prevChatFields[fieldData.name] ? prevChatFields[fieldData.name].push(fieldData) :
          prevChatFields[fieldData.name] = [fieldData];
        return prevChatFields;
      });

      // Step through the actions and append the new field to the contextFields list for this action.\
      setActions((prevActions) => {
        let updatedActions = [...prevActions];
        updatedActions.forEach((action) => {
          //  Find the action that matches the one that was updated
          if (action.fieldName === fieldData.name) {
            if (!('contextFields' in action)) { // if the action does not contain the context fields attribute add it.
              action.contextFields = [];
            }
            if (fieldData.context) {
              // the data belongs to one of the existing context fields and 
              //  should be added to the object accordingly.
              action.contextFields[fieldData.context] ?
                action.contextFields[fieldData.context].push(fieldData) :
                action.contextFields[fieldData.context] = [fieldData];
            } else { // it's a foster field and it should be added if it already exists or created if not.
              action.contextFields['fosterFields'] ?
                action.contextFields['fosterFields'].push(fieldData) :
                action.contextFields['fosterFields'] = [fieldData];
            }
          }
        });
        return updatedActions;
      });
    }

    console.log('Messages Socket - ActionList - (on)');
    messagesSocket.current?.on('field-value-update', insertNewFieldData);
    return () => {
      console.log('Messages Socket - ActionList - (off)');
      messagesSocket.current?.off('field-value-update', insertNewFieldData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected])

  // Search Actions 
  const handleActionSearch = async () => {
    const config = abortController.get();
    if (actionSearchString.length > 0) {
      // We have a search string
      if (selectedTab === 'consoleConversationsQuickRequests') {
        // TODO: Integrate the action field retrieval into the CORE endpoint
        let searched = await searchActions(actionSearchString, config);

        if (searched) {
          setActions(searched);
          setChatFields({});
          setActionsOffset(1);
          setHasMoreActions(true);
        }
      }
    } else {
      // There is no search, display regular
      setActions(actions);
    }
  };

  // Use the custom debounce hook
  useDebounce(handleActionSearch, 500, [actionSearchString]);

  /**
   * If the chat id changes or the actions length changes 
   * appends the fields to the actions
   * The actions remain the same but the field values change 
   */
  useEffect(() => {
    (async () => {
      // retrieve the action field values when mounted
      await loadActionFields(chatId, actions);
    })();
    return () => {
      setChatFields({}); // clear the fields when the chatId changes.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId])

  // Translate action names - using callback reduces unnecessary re-renders.
  const translateActions = useCallback(async (controller) => {
    let origMsgs = [];
    for (let i = 0; i < actions.length; i++) {
      origMsgs.push({message: actions[i]?.description, source: 'en'});   // only push if the action exist
    }
    const config = controller ? {
      signal: controller.signal
    } : {};
    const currentLang = i18n.language.split('-')[0].toLowerCase();

    // Assume if the current language is not English, the action names must
    // be translated:
    if (currentLang !== 'en' && origMsgs?.length > 0) {
      try {
        let translatedList = await LanguageUtils.translateMany(origMsgs, currentLang, config);
        setTranslatedActionNames(translatedList);
      } catch (err) {
        setTranslatedActionNames([]);
      }
    } else {
      setTranslatedActionNames([]);
    }
    // eslint-disable-next-line
  }, [i18n.language, actions.length]);

  useControllerCallback(translateActions);

  return {
    actions,
    setActions,
    loadActions,
    hasMoreActions,
    setHasMoreActions,
    actionsOffset,
    setActionsOffset,
    translatedActionNames,
    setActionSearchString,
  };
};
