import { IconButton, Snackbar, Stack, MorganTheme, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoginWrapper from '@styles/LoginStyle';
import LangPicker from '@components/LanguagePickerSelect';
import LoginForm from '@components/LoginForm';
import Brandmark from '@components/Brandmark';
import DisabledNotificationDialog from '@components/DisabledNotificationDialog';
import { UserWayAccessibilityMenu } from '@components/UserWayAccessibilityMenu';
import useInitialMount from '@common/hooks/useInitialMount';
import useAbortController from '@common/hooks/useAbortController';

// This is the login page, it renders the Morgan logo on the left side and the login form on the right side.
const Login: React.FC<{}> = () => {
  const theme: MorganTheme = useTheme();
  const { t } = useTranslation();
  const [error, setError] = useState({ message: '', active: false });
  const [systemMessage, setSystemMessage] = useState('');
  const [systemStatus, setSystemStatus] = useState('');
  const [notificationModal, showNotificationModal] = useState(false);
  const abortController = useAbortController();

  const systemStatusesArr = ['Demo', 'Normal', 'Downtime', 'Maintenance'];
  const checkSystemStatusValue = (value: string) => systemStatusesArr.includes(value) === false && console.warn(`Given value for system status is invalid! It has to be one of the following: ${systemStatusesArr.join(', ')}`);

  const requestNotificationPermission = useCallback(() => {
    if ('Notification' in window && Notification.permission !== 'granted') {
      //Browser supports notifications and permission not granted
      showNotificationModal(true);
      Notification.requestPermission();
    }
  }, []);

  const fetchSystemSettings = useCallback(async (controller: AbortController) => {
    try {
      const system = await axios({
        url: `${process.env.REACT_APP_MORGAN_CORE}/system?lender=${theme.system.name}&active=true&fields=message,status`,
        signal: controller.signal,
      });

      // get system status
      // if undefined, set as Downtime
      const status = system?.data?.status ?? '';
      // Give warning if status value is not valid.
      checkSystemStatusValue(status);
      // If invalid, set as empty string since the request didn't fail,
      // but the value is invalid, it has to do something with system value from DB 
      // Reference (file SystemModel.js - status type).
      setSystemStatus(status);
      if (system?.data?.message !== '') {
        setSystemMessage(system?.data.message);
      }
    } catch (e) {
      // in case of error during request, 
      // set as Downtime
      setSystemStatus('Downtime');
      setError({
        message: t('systemUnavailable'),
        active: true,
      });
      console.warn(e);
    }
  }, []);

  const handleCloseSnackbar = () => {
    setError({
      message: '',
      active: false,
    });
  };

  const handleCloseNotificationModal = () => {
    showNotificationModal(false);
  };

  useInitialMount(requestNotificationPermission);

  useEffect(() => {
    const config = abortController.reset();
    fetchSystemSettings(config);
    if (['Downtime', 'Maintenance'].indexOf(systemStatus) !== -1) {
      const interval = setInterval(fetchSystemSettings, 2 * 60 * 1000, config);
      return () => clearInterval(interval);
    }
  }, [systemStatus]);

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <LoginWrapper>
      <section>
        <Snackbar
          open={error?.active}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          message={error?.message}
        />
        <Brandmark systemMessage={systemMessage} />
        <Stack alignItems="center" justifyContent="center" width="50vw">
          <LoginForm systemStatus={systemStatus} setError={setError}/>
        </Stack>
      </section>
      <div className="languagePicker" style={{ display: 'flex', alignContent: 'center', gap: 10 }}>
        <LangPicker color={theme.palette.gray['950']} />
        <UserWayAccessibilityMenu color="black" height={30} />
      </div>
      {notificationModal && (
        <DisabledNotificationDialog open={notificationModal} onClose={handleCloseNotificationModal} />
      )}
    </LoginWrapper>
  );
};

export default Login;
