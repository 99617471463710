/**
 * @file This component is a stylized Box component
 * for rendering display button for rotating screen. 
 * 
 * Extracted from ConversationMessage.js 10/11/2022
 * 
 * @author Harrison Fales <harrison.fales@swmc.com>
 */

import { Box, styled } from '@mui/system';

const RotateButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  flexWrap: 'wrap',
   marginTop: '-1px',
  borderRadius: '0px 0px 30px 30px',
  boxShadow: 'var(--shadow100) 0px 5px 35px',
  position: 'relative',
  backgroundColor: "var(--gray000)",
  width: "60px",
  height: "35px",
  borderBottom: "1px solid var(--orange700)",
  borderLeft: "1px solid var(--orange700)",
  borderRight: "1px solid var(--orange700)",
  borderTop: "2px solid var(--gray000)"
});

export default RotateButtonContainer