import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import DialogHeader from './DialogHeader';
import React, { FC, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';

const mt = {
  mt:"12px"
};

const EditActionDialog: FC<DialogPropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [description, setDescription] = useState(props.fieldAttribute?.description || '');
  const [type, setType] = useState(props.fieldAttribute?.type || '');
  const [format, setFormat] = useState(props.fieldAttribute?.format || '');
  const [mask, setMask] = useState(props.fieldAttribute?.mask || '');
  const [validationRule, setValidationRule] = useState(props.fieldAttribute?.mask || '');

  // Todo: get this from endpoint
  const typeList = {
    data: [{ name: 'String' }, { name: 'Number' }, { name: 'Date' }],
  };

  const handleSubmit = () => {
    let faData = {
      id: '',
      description: description,
      type: type,
      format: format,
      mask: mask,
      validationRule: validationRule
    };
    if (!props.edit) {
      props.onAdd(faData);
    } else {
      faData = { ...faData, id: props.fieldAttribute?._id };
      props.onSubmit(faData);
    }
  };

  return (
    <Dialog maxWidth="md" open={props.open} onClose={() => props.onClose()}>
      <DialogHeader
        title={props.edit ? t('editFieldAttribute') : t('addFieldAttribute')}
        onClose={() => props.onClose()}
      />
      <DialogContent>
        <Stack width="400px">
          <TextField
            sx={{...mt}}
            autoFocus
            select
            size="small"
           
            variant="outlined"
            label={`${t('typeCol')}*`}
            value={type}
            onChange={(e) => setType(e.target.value)}>
            {typeList.data.map((item) => {
              return (
                <MenuItem key={item.name} value={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            sx={{...mt}}
            size="small"
           
            variant="outlined"
            label={t('descriptionCol')}
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          <TextField
            sx={{...mt}}
            size="small"
           
            variant="outlined"
            label={t('formatCol')}
            value={format}
            onChange={(e) => setFormat(e.target.value)}
          />
          <TextField
            sx={{...mt}}
            size="small"
            color="info"
            variant="outlined"
            label={t('maskCol')}
            value={mask}
            onChange={(e) => setMask(e.target.value)}
          />
          <TextField
            sx={{...mt}}
            multiline
            rows={3}
            size="small"
            color="info"
            variant="outlined"
            label={t('validationRuleCol')}
            value={validationRule}
            onChange={(e) => setValidationRule(e.target.value)}
          />
          <Typography color={theme.palette.red['400']}>{props.error}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={() => props.onClose()}>
          {t('cancelButton')}
        </Button>
        <Button disabled={type === ''} variant="contained" color="info" onClick={() => handleSubmit()}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditActionDialog;