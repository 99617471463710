/**
 *                               *** HIGHLY CONFIDENTIAL ***
 * 
 * All code written here is confidential and proprietary of Celligence International, LLC.
 * The algorithms on this file are considered undisclosed material information and should
 * not be disclosed to any third party without proper legal advise. Any misuse, including
 * but not limited to the disclosures of such algorithms contained herein to third parties
 * are subject to legal actions. Only executive members are allowed to review and update
 * parts of this code.
 * 
 *                    *** Copyright© Celligence International, LLC - 2024 ***
 * 
*/

enum APIServiceType {
    core = "MORGAN_CORE",
    cells = "MORGAN_CELLS",
    rest = "REST_SERVER"
}

export { APIServiceType }