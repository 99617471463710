/**
 * @file This component is a stylized Box component
 * for rendering text. 
 * 
 * Extracted from ConversationMessage.js 10/11/2022
 * 
 * @author Harrison Fales <harrison.fales@swmc.com>
 * Original Component Author
 * @author Allan Tham <allan.tham@swmc.com>
 */

import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

const MessageTextStyle = styled(Typography)(
  ({theme}) => `
  font-family: ${theme.typography.fontFamily};
  font-size: ${useMediaQuery(theme.breakpoints.up('md')) ? '0.75rem' : '0.8rem'};
  font-weight: 400;
  color: var(--blue999);
  letter-spacing: 0.00938em;
  overflow-wrap: break-word;
  word-break: break-word;
  margin-right: 5;
  white-space: pre-line;
  width: fit-content;
  max-width: 100% !important;
  text-align: left;
  & a {
    pointer-events: all;
  }
  `
);

export default MessageTextStyle;