import { DoubleTick, Tick } from '@assets/Icons';

import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { IMessage } from '@interfaces/Conversation';
import tStyles from '@styles/Typography.module.css';

type TMessageTimeProps = {
  message: IMessage;
  options?: Partial<Intl.DateTimeFormatOptions>;
}

const MessageTime = ({
  message, options = { hour: '2-digit', minute: '2-digit' }
}: TMessageTimeProps) => {
  const { i18n } = useTranslation();

  const machineTime = useMemo(() => {
    return new Date(message?.createdAt)?.toISOString() || '';
  }, [message?.createdAt]);

  const visibleTime = useMemo(() => {
    return new Date(message.createdAt).toLocaleTimeString(i18n.language, options ) || '';
  }, [message?.createdAt, options, i18n.language]);

  const showDoubleTick = useMemo(() => {
    return Boolean(message?.notifications?.length > 0 && message.notifications?.findIndex((x) => x?.status === 'read') !== -1);
  }, [message?.notifications]);

  return message && (
    <time
      dateTime={machineTime}
      className={[
        tStyles.base,
        tStyles.fsSmallest,
        tStyles.dFlex,
        ...(!options.month ? [tStyles.mlAuto] : [])
      ].join(' ')}
    >
      {visibleTime}{' '}
      {message.sentByCurrentUser ? (
        <>
          {showDoubleTick ? (
            <DoubleTick />
          ) : (
            <Tick />
          )}
        </>
      ) : <></>}
    </time>
  );
};

export default MessageTime;
