import React, { memo } from 'react';
import { isMobile } from '@common/deviceTypeHelper';
import ContextDropdown from '@components/ContextDropdown';
import { CONSTANTS } from '../Constants';
import { IErrand, IUserData } from '@interfaces/Conversation';

const { MAX_WIDTH, ACTIVITY_WRAPPER_HEIGHT } = CONSTANTS;

export const ContextElement = memo((props: {
  operatorData: IUserData | undefined | null;
  errand: IErrand;
}) => {

  return (
    <div>
      <ContextDropdown
        errand={props.errand}
        operatorData={props.operatorData}
        elementStyle={{
          heightValue: ACTIVITY_WRAPPER_HEIGHT,
          fontSizeValue: isMobile() ? 12 : 14,
          svgHeightValue: isMobile() ? 18 : 20,
          widthValue: MAX_WIDTH,
        }}
      />
    </div>
  );
});
