import React, { createContext, Dispatch, SetStateAction, MutableRefObject, useContext } from 'react';
import { IPrompt } from '@interfaces/Conversation';


/**
 * This context will store data that unique to each errand 
 * and specifically used for sharing custom link
 * 
 */

export interface ICustomLinkContext {
  qrImgSrc?: string;
  setQrImgSrc?: Dispatch<SetStateAction<string>>;
  customLink?: string;
  setCustomLink?: Dispatch<SetStateAction<string>>;
  shareCustomLinkMethod?: string;
  setShareCustomLinkMethod?: Dispatch<SetStateAction<string>>;
  shareCustomLinkInfoRef?: MutableRefObject<IPrompt> | null;
}

export const CustomLinkContext = createContext<ICustomLinkContext | null>(null);

export const useCustomLinkContext = () => useContext<ICustomLinkContext>(CustomLinkContext);
