/**
 * @file This component is a stylized Box component
 * for styling the animated notification container.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { Box, styled } from '@mui/system';

/* Styles for mobile chat notification animation */
const NotificationContainer = styled(Box)(
  ({ theme }) => `
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
  gap: calc(100% / 66);
  background-color: red;
  transform: rotate(180deg);
  div {
    height: calc(100% / 66);
    background-color: var(--peach900);
    width: 100%;
    animation: spinner 1000ms linear forwards;
    :nth-of-type(2) { animation-delay: 60ms; }
    :nth-of-type(3) { animation-delay: 120ms; }
    :nth-of-type(4) { animation-delay: 180ms; }
    :nth-of-type(5) { animation-delay: 240ms; }
    :nth-of-type(6) { animation-delay: 300ms; }
    :nth-of-type(7) { animation-delay: 360ms; }
    :nth-of-type(8) { animation-delay: 420ms; }
    :nth-of-type(9) { animation-delay: 480ms; }
    :nth-of-type(10) { animation-delay: 540ms; }
    :nth-of-type(11) { animation-delay: 600ms; }
    :nth-of-type(12) { animation-delay: 660ms; }
    :nth-of-type(13) { animation-delay: 720ms; }
    :nth-of-type(14) { animation-delay: 780ms; }
    :nth-of-type(15) { animation-delay: 840ms; }
    :nth-of-type(16) { animation-delay: 900ms; }
    :nth-of-type(17) { animation-delay: 960ms; }
    :nth-of-type(18) { animation-delay: 1020ms; }
    :nth-of-type(19) { animation-delay: 1080ms; }
    :nth-of-type(20) { animation-delay: 1140ms; }
    :nth-of-type(21) { animation-delay: 1200ms; }
    :nth-of-type(22) { animation-delay: 1260ms; }
    :nth-of-type(23) { animation-delay: 1320ms; }
    :nth-of-type(24) { animation-delay: 1380ms; }
    :nth-of-type(25) { animation-delay: 1440ms; }
    :nth-of-type(26) { animation-delay: 1500ms; }
    :nth-of-type(27) { animation-delay: 1560ms; }
    :nth-of-type(28) { animation-delay: 1620ms; }
    :nth-of-type(29) { animation-delay: 1680ms; }
    :nth-of-type(30) { animation-delay: 1740ms; }
    :nth-of-type(31) { animation-delay: 1800ms; }
    :nth-of-type(32) { animation-delay: 1860ms; }
    :nth-of-type(33) { animation-delay: 1920ms; }
  }
  @keyframes spinner {
    0%, 100% {
      width: 0px;
    }
    30%, 70% {
      width: 32px;
    }
    50% {
      width: 8px;
    }
  }
`
);

export default NotificationContainer;