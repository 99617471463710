import { useRef } from 'react';

export default function useInterval(callback: () => void, delay: number) {
  // timer reference
  const timerRef = useRef<NodeJS.Timeout>();
  const delayRef = useRef<number>(Number.MAX_SAFE_INTEGER);

  // if delay is not valid
  if (delay === undefined || delay === null || delay <= 0) {
    // clear current interval
    clearInterval(timerRef.current);
    delayRef.current = Number.MAX_SAFE_INTEGER;
    return;
  }

  // we have valid delay here
  if (delayRef.current !== delay) {
    // clear current interval
    clearInterval(timerRef.current);

    // set new interval
    timerRef.current = setInterval(callback, delay);
    delayRef.current = delay;
  }
}
