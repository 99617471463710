/**
 * @file This file contains the definition of the `MorphType` enum. This contains enumerators for all
 * the possible morph types which might be used.
 * @author Icy Parkinson
 */

enum MorphType {
  None,
  Attachment,
  Calendar,
  CalendarMonth,
  DOB,
  Time,
  PhotoMain,
  PhotoPlain,
  Payment,
  Contacts,
  Errand,
  ErrandNew,
  ErrandEdit,
  FormActiveChat,
  FormInsertSignature,
  FormNavigateInitials,
  FormOpen,
  FormReadyToSend,
  Recording,
  SlotMachine,
  DownloadAppBanner,
  SongOfTheDay,
  Reply,
  MessageOptions,
  PrivateChat,
  BorrowerSelector,
  ShareCustomLink,
  Edit,
  LoanProducts,
  LoanProductPriceTable,
  UserPromptsMenu,
  VideoListMenu,
  Wallet,
  CreditRepairDisputeAccountType,
  CreditRepairWelcome,
  RefinanceCalculatorWelcome,
  UserSuggestions
}

enum morphIndentType {
  FormClickToClear,
  FormGenerate,
}

export { MorphType, morphIndentType };
