/**
 * @file This file contains the definition of the `ChatType` enum. This contains enumerators for all
 * the possible chat types which might be used.
 * @author Bold Chinbat
 */

/**
 * All valid chat type defined here
 */
enum ChatType {
  Conversation = 'conversation',
  Errand = 'errand',
  Form = 'form',
  Group = 'group',
  Activity = 'activity',
  Page = 'page',
  Team = 'team',
  Conditions = 'conditions'
}

// Set that contains all valid ChatType
const validChatSet = new Set(Object.values(ChatType));

/**
 *
 * @param type check string if it's valid ChatType
 * @returns true/false
 */
const isValidChatType = (type: any): boolean => {
  return validChatSet.has(type);
};

export { ChatType, isValidChatType };
