/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { Dispatch, SetStateAction, useMemo } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphMessageOptionSkeleton.module.css';
import useLazyComponent from '@common/hooks/useLazyComponent';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { MorphType } from '@common/MorphType';
import { isNotOtpNorPassword } from '@common/StringUtils';

const MorphMessageOptionSkeleton = () => {
  return (
    <div className={Skeleton.loader + ' ' + Styles.style} />
  );
};

// The render component below lazy loads the corresponding component using the skeleton above
type TMorphMessageOptionSkeletonProps = {
  errand: IErrand;
  handleClose: (morphType?: MorphType) => void;
  message: IMessage;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
};

const MorphMessageOptionConvertSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderConvert = useMemo(() => {
    try {
      // guard statements required for handleClickMenu to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing users or operators to Convert messages
      if (!message.operatorView) return false;
      if (message.senderType !== 'User') return false;
      if (message.messageType !== 'Text') return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderCopy.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.operatorView, message?.senderType, handleClose]);

  const MorphMessageOptionConvertProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionConvert = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionConvert'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionConvert`
  );

  return renderConvert ? (
    <MorphMessageOptionConvert {...MorphMessageOptionConvertProps} />
  ) : <></>
};

const MorphMessageOptionCopySkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderCopy = useMemo(() => {
    try {
      // guard statements required for handleClickCopy to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business requirements for editing messages
      if (!message.visible && !message.operatorView) return false;
      if (message.messageType === 'Field') {
        if (!isNotOtpNorPassword(message)) return false;
      } else {
        // opt in for editing
        const copyableMessageTypes = ['Action', 'Audio', 'Text', 'Field', 'helptip', 'Video', 'Url'];
        if (copyableMessageTypes.indexOf(message.messageType) === -1) return false;
      }
  
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderCopy.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.messageType, message?.visible, message?.operatorView, handleClose]);

  const MorphMessageOptionCopyProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionCopy = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionCopy'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionCopy`
  );

  return renderCopy ? (
    <MorphMessageOptionCopy {...MorphMessageOptionCopyProps} />
  ) : <></>
};

const MorphMessageOptionDeleteSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderDelete = useMemo(() => {
    try {
      // guard statements required for handleClickDelete to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing users or operators to delete messages
      if (message.messageType === 'Field') return false;
      if (!message.visible && !message.operatorView) return false;
      if (!message.sentByCurrentUser) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderDelete.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.visible, message?.operatorView, message?.alignByCurrentUser, handleClose]);

  const MorphMessageOptionDeleteProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionDelete = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionDelete'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionDelete`
  );

  return renderDelete ? (
    <MorphMessageOptionDelete {...MorphMessageOptionDeleteProps} />
  ) : <></>
};

const MorphMessageOptionEditSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderEdit = useMemo(() => {
    try {
      // guard statements required for handleClickEdit to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message?._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business requirements for editing messages
      if (message.operatorView) {
        if (!message.alignByCurrentUser && message.messageType !== 'Field') return false;
        if (message.messageType === 'Field' && message.action?.fieldName?.toLowerCase()?.indexOf('password') !== -1) return false;
      } else {
        if (!message.sentByCurrentUser) return false;
      }
  
      // opt in for editing
      const editableMessageTypes = ['Text', 'Field', 'Video', 'Url'];
      if (editableMessageTypes.indexOf(message.messageType) === -1) return false;
  
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderEdit.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.messageType, message?.visible, message?.operatorView, handleClose]);

  const MorphMessageOptionEditProps = {
    errand,
    handleClose,
    message,
    setEditMessageId,
    setValue,
  };

  const MorphMessageOptionEdit = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionEdit'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionEdit`
  );

  return renderEdit ? (
    <MorphMessageOptionEdit {...MorphMessageOptionEditProps} />
  ) : <></>
};

const MorphMessageOptionJoinSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderJoin = useMemo(() => {
    try {
      // guard statements required for handleClickMenu to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message?._id || !message?.messageType || !message?.message || !message?.userId) return false;
  
      // business logic for allowing users or operators to join chat invites
      if (message?.messageType !== 'Text') return false;
      const chatId = (message?.message?.split('data-chat-id="')?.[1] || '')?.split('"')[0];
      if (!chatId) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderJoin.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.messageType, message?.message]);

  const MorphMessageOptionJoinProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionJoin = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionJoin'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionJoin`
  );

  return renderJoin ? (
    <MorphMessageOptionJoin {...MorphMessageOptionJoinProps} />
  ) : <></>
};

const MorphMessageOptionPrivateModeSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderPrivateMode = useMemo(() => {
    try {
      // guard statements required for handleClickPrivateMode to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id || !message.userId || !message.sender || !message.sender?._id) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing private messages
      if (errand.type === 'team') return false;
      if (message.sender._id === message.userId) return false;
      if (!message.operatorView) {
        if (!message.visible) return false;
        if (message.senderType === 'User') return true;
        const numberOfUserParticipants = errand?.participants?.filter((p) => p?.active && p?.userType === 'User')?.length || 0;
        if (numberOfUserParticipants < 2) return false;
      }
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderPrivateMode.catch`, error);
      return false;
    }
  }, [errand?._id, errand?.participants, message?._id, handleClose]);

  const MorphMessageOptionPrivateModeProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionPrivateMode = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionPrivateMode'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionPrivateMode`
  );

  return renderPrivateMode ? (
    <MorphMessageOptionPrivateMode {...MorphMessageOptionPrivateModeProps} />
  ) : <></>
};

const MorphMessageOptionRemoveUserSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderRemoveUser = useMemo(() => {
    try {
      // guard statements required for handleClickRemoveUser to function properly
      if (!errand || !errand._id || !Array.isArray(errand.participants)) return false;
      if (!message || !message._id || !message.sender) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing users to remove others
      if (!message.operatorView) return false;
      if (message.senderType !== 'User' && message.sender.accessLevel !== 'Level 0') return false;
      const senderIsPrimary = errand.participants.find((x) => x?.active && x.userData?._id === message?.sender?._id)?.primary;
      if (senderIsPrimary) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderRemoveUser.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, errand?.participants, message?.sender?._id, message?.sender?.accessLevel, handleClose]);

  const MorphMessageOptionRemoveUserProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionRemoveUser = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionRemoveUser'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionRemoveUser`
  );

  return renderRemoveUser ? (
    <MorphMessageOptionRemoveUser {...MorphMessageOptionRemoveUserProps} />
  ) : <></>
};

const MorphMessageOptionReplySkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderReply = useMemo(() => {
    try {
      // guard statements required for handleClickReply to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id || !message.userId) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for allowing users to remove others
      const replyableMessageTypes = ['Action', 'Audio', 'Field', 'Text', 'Greeting', 'Url'];
      if (replyableMessageTypes.indexOf(message.messageType) === -1) return false;
      if (message.messageType === 'Action') {
        // opt out of replies
        const unreplyableActionDescriptions = ['TCPA', 'Slot Machine'];
        if (unreplyableActionDescriptions.indexOf(message.action?.description) !== -1) return false;
        if (message.action?.description?.toLowerCase()?.indexOf('password') !== -1) return false;
      }
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderReply.catch`, error);
      return false;
    }
  }, [errand._id, message, handleClose]);

  const MorphMessageOptionReplyProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionReply = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionReply'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionReply`
  );

  return renderReply ? (
    <MorphMessageOptionReply {...MorphMessageOptionReplyProps} />
  ) : <></>
};

const MorphMessageOptionSentimentSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderSentiment = useMemo(() => {
    try {
      if (!message || !message.operatorView) return false; // on user side, don't render sentiment, or if the message renders on the left
      // guard statements required for handleClickSentiment to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id || !message.userId) return false;
      if (typeof handleClose !== 'function') return false;
  
      // business logic for sentiment
      if (!message.operatorView && !message.alignByCurrentUser) return false;
      const messagesWithSentiment = ['Text', 'Field', 'Document', 'Audio', 'Url'];
      if (messagesWithSentiment.indexOf(message.messageType) === -1) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderSentiment.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id, message?.messageType, message?.userId, handleClose]);

  const MorphMessageOptionSentimentProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionSentiment = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionSentiment'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionSentiment`
  );

  return renderSentiment ? (
    <MorphMessageOptionSentiment {...MorphMessageOptionSentimentProps} />
  ) : <></>
};

const MorphMessageOptionSetInternalSkeleton = ({
  errand, setEditMessageId, setValue, message, handleClose
}: TMorphMessageOptionSkeletonProps) => {
  const renderSetInternal = useMemo(() => {
    try {
      if (!message.operatorView) return false; // on user side, don't render whether the msg interal
      // guard statements required for handleClickSentiment to function properly
      if (!errand || !errand._id) return false;
      if (!message || !message._id) return false;
      if (typeof handleClose !== 'function') return false;
      if (!message.operatorView) return false;
      return true;
    } catch (error) {
      console.error(`MorphMessageOptionSkeleton.renderSetInternal.catch`, error);
      return false;
    }
  }, [errand?._id, message?._id]);

  const MorphMessageOptionSetInternalProps = {
    errand,
    handleClose,
    message,
  };

  const MorphMessageOptionSetInternal = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptionSetInternal'),
    <MorphMessageOptionSkeleton />,
    `./src/Components/Skeletons/MorphMessageOptionSkeleton.tsx: MorphMessageOptionsSetInternal`
  );

  return renderSetInternal ? (
    <MorphMessageOptionSetInternal {...MorphMessageOptionSetInternalProps} />
  ) : <></>
};

export {
  MorphMessageOptionConvertSkeleton,
  MorphMessageOptionCopySkeleton,
  MorphMessageOptionDeleteSkeleton,
  MorphMessageOptionEditSkeleton,
  MorphMessageOptionJoinSkeleton,
  MorphMessageOptionPrivateModeSkeleton,
  MorphMessageOptionRemoveUserSkeleton,
  MorphMessageOptionReplySkeleton,
  MorphMessageOptionSentimentSkeleton,
  MorphMessageOptionSetInternalSkeleton
};