import i18n from "i18next";
import parseHiddenMessage from "@common/parseHiddenMessage";
import { getDropdownDescription } from "@common/StringUtils";

import type { IMessage, IParticipant } from "@interfaces/Conversation";

// Action Related Messages Tools
const actionTypes = {
  password: 'password',
  address: 'address',
  otp: 'otp'
} as const;

type ActionType = keyof typeof actionTypes;

const lowerCaseIncludes = (target: string, substring: string): boolean => {
  if (typeof target !== 'string' || typeof substring !== 'string') return false;
  return target.toLowerCase().includes(substring.toLowerCase());
};

const containsValue = (target: string | undefined | null, actionType: ActionType) => {
  if (target && typeof target === 'string') {
    return lowerCaseIncludes(target, actionType);
  } else {
    return false;
  }
};

const containsPassword = (target) => containsValue(target, actionTypes.password);
const containsAddress = (target) => containsValue(target, actionTypes.address);
const containsOTP = (target) => containsValue(target, actionTypes.otp);

const isActionRelatedBy = (action, containFn) => {
  // get fields to check
  const description = action?.description;
  const fieldName = action?.fieldName;
  const fieldAttrDescription = action?.fieldAttribute?.description;

  // check if anything contains password
  return containFn(description) || containFn(fieldName) || containFn(fieldAttrDescription);
};

const isActionPasswordRelated = (action) => isActionRelatedBy(action, containsPassword);
const isActionAddressRelated = (action) => isActionRelatedBy(action, containsAddress);
const isActionOTPRelated = (action) => isActionRelatedBy(action, containsOTP);

export { isActionPasswordRelated, isActionAddressRelated, isActionOTPRelated };


export const REQ_STATES = {
  NOT_STARTED: "NOT_STARTED",
  FINISHED: "FINISHED",
  LOADING: "LOADING",
  ERROR: "ERROR"
} as const;

export type TReqState = keyof typeof REQ_STATES;
// General Messages Tools/Interfaces/Types
// This class can be used with any http request to track its state.
//  F.e. Right Before request, create instance of this class and call sent()
//  After req is complete and response is received, call finished()
//  IF catch(err) fired, call finished(err) within that catch block.
// use getState to check in which state is the request currently in
// possible states: NOT_STARTED, FINISHED, LOADING, ERROR
export class CReqState {
  isFinished: boolean;
  loading: boolean;
  error: Error | null;

  constructor() {
    this.isFinished = false;
    this.loading = false;
    this.error = null;
  }

  // Marks the current req as being sent BUT not finished
  // sets loading to true
  sent() {
    // if isFinished is true, it means that it is a prev req info
    // thus, reset state
    if(this.isFinished === true) {
      this.resetState()
    } 

    this.loading = true;
  }

  // Marks the current req as being finished.
  finished(error?: Error) {
    this.isFinished = true;
    this.loading = false;
    this.error = error ?? null;
  }

  // You can also add methods to the class as needed
  // For example, a method to reset the state
  resetState() {
    this.isFinished = false;
    this.loading = false;
    this.error = null;
  }

  getState(): TReqState {
     if(this.isFinished === true && this.loading === false) {
      return REQ_STATES.FINISHED;
    }
    else if(this.isFinished === false && this.loading === true) {
      return REQ_STATES.LOADING;
    }
    else if(this.isFinished === false && this.loading === false) {
      return REQ_STATES.NOT_STARTED;
    }
    else {
      return REQ_STATES.ERROR;
    }
  }
}

const sanitizeMessageText = (msg: string) => {
  if (msg.indexOf('<data/>') !== -1) {
    // Replace correction tag with acceptedData
    return msg.replace('<data/>', i18n.t('acceptedData')) + ')';
  }

  return msg;
}

export const constructFieldMessageText = (message: IMessage) => {
    const msg = sanitizeMessageText(message.message);
    
    return parseHiddenMessage(message) || getDropdownDescription(msg);
}

export const parseLocalDateTime = (message: IMessage) => {
  // Replace the datetime tag with the user's local with the time the message is created
  let result = message.message;
  if (message.message.indexOf('<datetime/>') !== -1) {
    // 1. Extract the datetime from the message
    const utcDate = new Date(message.createdAt);
    // 2. Convert to local 
    const localDateTime = utcDate.toLocaleString([], {  // Empty array for options, object for formatting
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short' // Include time zone name
    });
    result = message.message.replace('<datetime/>', localDateTime);
  }
  return result;
}

export const CheckIsAiInAudience = (intendedAudience: string[], aiOperatorIds: string[], messageUserId: string, messageSenderId: string, messageSenderType: string, messageOperatorView: boolean, userAudience: string[]): boolean => {
  // isAiInAudience doesn't include current operator or sender
  const isAiInAudience = Boolean((intendedAudience || []).find((userId) => aiOperatorIds.findIndex((operatorId) => operatorId === userId) !== -1 && userId !== messageUserId && userId !== messageSenderId));
  const isAiInSenderOrCurrentOperator = Boolean(aiOperatorIds.find((userId) => userId === messageUserId || userId === messageSenderId));
  if (messageOperatorView) {
    // Operator sending messages to or recieving messages from
    if (messageSenderType === 'Operator') {
      // Not a private message between two operators, get everyone involved
      if (intendedAudience?.length > 1) return false;
      // don't include the current operator or sender as an ai in 1:1 chats
      if (intendedAudience?.length === 1) return isAiInSenderOrCurrentOperator;
      // private messages between two operators who may or may not be ai
      return false;
    } else {
      // private messages between user and ai
      if (isAiInSenderOrCurrentOperator) return true;
      // private messages between user and non-ai operator
      return false;
    }
  } else {
    // User sending messages to or recieving messages from
    if (messageSenderType === 'Operator') {
      if (userAudience.length > 1) return true;
      // private messages between user and ai
      if (isAiInSenderOrCurrentOperator || isAiInAudience) return  true;
      // private messages between user and non-ai operator
      return false;
    } else {
      // private messages between two users and ai
      return false;
    }
  }
}

export const getAiOperatorIds = (participants: IParticipant[]): string[] => {
  return (participants || []).filter((p) => {
    // reduce optional chaining
    if (!p || !p.userData || !p.userData._id || p.userType === 'User') return false;
    // operator is AngelAi
    if (p.userData.accessLevel === 'Level 0') return true;
    // operator has AngelAi nickname
    const formattedNickname = (p.userData?.nickname || '').replace(/\s+/g, '').toLowerCase();
    if (formattedNickname === 'angelai') return true;
    return false;
  }).map((p) => p?.userData?._id).sort();
}

export const getUserAudience = (participants: IParticipant[], audience: string[]): IParticipant[] => {
  return (participants || []).filter((p, i, a) => {
    // reduce optional chaining
    if (!p || !p.userData || !p.userData._id || p.userType !== 'User') return false;
    // audience includes userId
    if (!(audience || []).find((userId) => p.userData._id === userId)) return false;
    // userId is unique
    if (a.findIndex((participant) => participant.userData._id === p.userData._id) !== i) return false;
    return true
  });
};

export const getOperatorAudience = (participants: IParticipant[], audience: string[]): IParticipant[] => {
  return (participants || []).filter((p, i, a) => {
    // reduce optional chaining
    if (!p || !p.userData || !p.userData._id || p.userType === 'User') return false;
    // audience includes operatorId
    if (!(audience || []).find((operatorId) => p.userData._id === operatorId)) return false;
    // operatorId is unique
    if (a.findIndex((participant) => participant.userData._id === p.userData._id) !== i) return false;
    return true;
  });
};

export const getAiAudience = (participants: IParticipant[], audience: string[]): IParticipant[] => {
  return (participants || []).filter((p, i, a) => {
    // reduce optional chaining
    if (!p || !p.userData || !p.userData._id || p.userType === 'User') return false;
    // audience includes operatorId
    if (!(audience || []).find((operatorId) => p.userData._id === operatorId)) return false;
    // operatorId is unique
    if (a.findIndex((participant) => participant.userData._id === p.userData._id) !== i) return false;
    // operator is AngelAi
    if (p.userData.accessLevel === 'Level 0') return true;
    // operator presents as AngelAi
    const formattedNickname = (p.userData?.nickname || '').replace(/\s+/g, '').toLowerCase();
    if (formattedNickname === 'angelai') return true;
    return false;
  });
};

export const getAudience = (message: IMessage, aiOperatorIds: string[], isAiInAudience: boolean): string[] => {
  const messageAudience = [message.userId, message.sender._id, ...(message.intendedAudience || [])].filter((v, i, a) => a.indexOf(v) === i).sort();
  const aiAudience = [...messageAudience, ...aiOperatorIds].filter((v, i, a) => a.indexOf(v) === i).sort();
  if (isAiInAudience) {
    return aiAudience;
  }
  return messageAudience;
}

export const getAudienceString = (senderId: string, audience: string[], participants: IParticipant[], type: string, operatorView: boolean, hideCurrentName: boolean, id: string, appendIds: boolean): string => {
  if (!audience) {
    audience = [];
  }
  const intendedAudience = [...(audience.length === 0 ? [id] : audience), ...(senderId ? [senderId] : [])];
  const userAudience = getUserAudience(participants, intendedAudience);
  const operatorAudience = getOperatorAudience(participants, intendedAudience);
  const aiAudience = getAiAudience(participants, [...(audience.length === 0 && !operatorView ? getAiOperatorIds(participants) : audience)]);
  const isAiInAudience = Boolean((aiAudience.length > 0 && userAudience.length > 0) || userAudience.length > 1);

  if (type === 'name') {
    const userNames = userAudience.map((p) => {
      if (!p || !p.userData || !p.userData.firstname) return '';
      if (p.userData._id === id) return '';
      return `${p.userData.firstname || ''} ${p.userData.lastname || ''}`.trim();
    }).filter((name) => name !== '').join(' / ');
    const operatorNames = isAiInAudience ? '' : operatorAudience.map((p) => {
      if (!p || !p.userData || !p.userData.firstname) return '';
      if (p.userData._id === id) return '';
      return `${p.userData.firstname || ''} ${p.userData.lastname || ''}`.trim();
    }).filter((name) => name !== '').join(' / ');
    const currentName = (
      hideCurrentName
        ? []
        : operatorView
          ? (isAiInAudience ? [] : operatorAudience)
          : userAudience
    ).map((p) => {
      if (!p || !p.userData || !p.userData.firstname) return '';
      if (p.userData._id !== id) return '';
      return `${p.userData.firstname || ''} ${p.userData.lastname || ''}`.trim();
    }).filter((name) => name !== '').join(' / ');
    return [
      ...(!operatorView && isAiInAudience ? ['AngelAi'] : []),
      ...(operatorNames ? [operatorNames] : []),
      ...(userNames ? [userNames] : []),
      ...(currentName ? [currentName] : []),
      ...(operatorView && isAiInAudience ? ['AngelAi'] : []),
    ].join(' / ');
  }

  if (userAudience.length === 0) {
    return operatorAudience.map((p) => p.userData._id).sort().join(',');
  }
  if (userAudience.length > 1) {
    const temp = (appendIds ? [...userAudience, ...aiAudience] : userAudience).map((p) => p.userData._id).sort().join(',') + (appendIds ? '' : ',ai');
    console.error(temp)
    return temp;
  }
  if (isAiInAudience) {
    return (appendIds ? [...userAudience, ...aiAudience] : userAudience).map((p) => p.userData._id).sort().join(',') + (appendIds ? '' : ',ai');
  }
  return [...userAudience, ...operatorAudience].map((p) => p.userData._id).sort().join(',');
}
