import React from 'react';
import { useLocation } from 'react-router-dom';
import * as CoreUtils from '@common/CoreUtils';

/**
 * Parses the participants and returns the primary.
 */
function getPrimaryParticipant(participants: CoreUtils.Participant[]) {
  for (const participant of participants) {
    if (participant.primary) {
      return participant;
    }
  }
}

interface UseEmail {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * A hook to manage the state of a users email. Has a useEffect that tries to
 * set it based on the primary participants email. Takes a setLoading prop so
 * that it is set to true while the fetch is being done.
 */
export default function useEmail(setLoading: React.Dispatch<React.SetStateAction<boolean>>): UseEmail {
  const [email, setEmail] = React.useState<string>('');
  const location = useLocation();

  React.useEffect(() => {
    async function getPrimaryParticipantEmail() {
      setLoading(true);
      const log = { function: getPrimaryParticipantEmail.name };
      console.info({ ...log, message: 'getting primary participant email' });

      // get participants
      const participants = await CoreUtils.getParticipants(location.state.chat);
      console.debug({ ...log, message: 'participants declared', participants });

      // check participants
      if (!participants) {
        console.warn({ ...log, message: 'participants not found' });
        setLoading(false);
        return;
      } else if (!Array.isArray(participants)) {
        console.warn({ ...log, message: 'participants is not an array' });
        setLoading(false);
        return;
      } else if (!participants.length) {
        console.warn({ ...log, message: 'participants 0 length' });
        setLoading(false);
        return;
      }

      // get primary participant
      const primaryParticipant = getPrimaryParticipant(participants);
      console.debug({ ...log, message: 'primaryParticipant declared', primaryParticipant });

      // check primary participant
      if (!primaryParticipant) {
        console.warn({ ...log, message: 'primaryParticipant not found' });
        setLoading(false);
        return;
      } else if (!primaryParticipant.userData) {
        console.warn({ ...log, message: 'primaryParticipant.userData not found' });
        setLoading(false);
        return;
      } else if (!primaryParticipant.userData._id) {
        console.warn({ ...log, message: 'primaryParticipant.userData._id not found' });
        setLoading(false);
        return;
      }

      // get user
      const user = await CoreUtils.getUser(primaryParticipant.userData._id);
      console.debug({ ...log, message: 'user declared', user });

      // check user
      if (!user) {
        console.warn({ ...log, message: 'user not found' });
        setLoading(false);
        return;
      } else if (!user.email) {
        console.warn({ ...log, message: 'user.email not found' });
        setLoading(false);
        return;
      }

      // set email
      setEmail(user.email);
      setLoading(false);
    }
    getPrimaryParticipantEmail();
  }, [location.state.chat, setLoading]);

  return { email, setEmail };
}
