import React from "react";

const darkBackgroundDivStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
    pointerEvents: "all",
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2
}


const DarkUnclickableBackground = (props) => {
    return (
        <div style={darkBackgroundDivStyle}></div>
    )
}

export { DarkUnclickableBackground };