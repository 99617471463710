import React from 'react';
import { CouponMessageContentFallback, MessageContentFallback } from '.';


export const MessageFallbackSelector = ({ message }) => {
  if (message?.messageType === 'Coupon') {
    return <CouponMessageContentFallback />;
  } else {
    return <MessageContentFallback message={message} />;
  }
};
