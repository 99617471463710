import React from 'react';
import CustomLinkDialog from '@components/CustomLinkDialog';
import type { IErrand, IMessage } from '@interfaces/Conversation';

type TCustomLinkMessageContentProps = {
  errand: IErrand;
  message: IMessage;
};

const CustomLinkMessageContent = ({
  errand, message, 
}: TCustomLinkMessageContentProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {message.documents?.map((document, index) => (
        <CustomLinkDialog key={`${index}`} errand={errand} message={message} document={document} />
      ))}
    </div>
  );
};

export default CustomLinkMessageContent;
