import axiosCall from '@services/axios';

export interface Application {
  applicationNumber: string;
  borrowerNumber: string;
  coBorrowerNumber: string;
  namesForTitle: string;
}

export interface LoanDocument {
  loanNumber: string;
}

export interface Borrower {
  brwrAuthorizedPullCredit: boolean;
}

export interface CreditPull {
  runStatusKeyword: string;
  creditCompany: string;
}

export interface CreditPullCreated {
  error?: string;
  status?: string;
}

export interface Participant {
  primary: boolean;
  userData: {
    _id: string;
  };
}

export interface User {
  userId: string;
  email: string;
}

interface Message {
  sender: string;
  senderType: string;
  message?: string;
  accessType: string;
}

interface WhoIsUser {
  userType?: string;
  ID?: string;
  longID?: string;
  nameFirst?: string;
  nameLast?: string;
  companyCode?: string;
  duty?: string;
  dutyCodes?: string;
  title?: string;
  nmlsID?: string;
  email?: string;
  isActive?: boolean;
  webUserID?: string;
}

/**
 * Gets applications.
 */
export async function getApplications(loanNumber: string): Promise<Application[] | null> {
  const log = { function: getApplications.name };
  console.info({ ...log, message: 'getting applications', loanNumber });

  try {
    // get res
    const res = await axiosCall({ url: `restServer/loans/${loanNumber}/applications` });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'got applications' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Gets application.
 */
export async function getApplication(loanNumber: string, applicationNumber: string): Promise<Application | null> {
  const log = { function: getApplication.name };
  console.info({ ...log, message: 'getting application', loanNumber });

  try {
    // get res
    const res = await axiosCall({ url: `restServer/loans/${loanNumber}/applications/${applicationNumber}` });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'got application' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Gets loan documents.
 */
export async function getLoanDocuments(loanNumber: string): Promise<LoanDocument | null> {
  const log = { function: getLoanDocuments.name };
  console.info({ ...log, message: 'getting loanDocuments', loanNumber });

  try {
    // get res
    const res = await axiosCall({ url: `restServer/loans/${loanNumber}/documents` });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'got loan documents' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Gets credit pulls.
 */
export async function getCreditPulls(loanNumber: string, applicationNumber: string): Promise<CreditPull | null> {
  const log = { function: getCreditPulls.name };
  console.info({ ...log, message: 'getting credit pulls', loanNumber, applicationNumber });

  try {
    // get res
    const res = await axiosCall({
      url: `restServer/loans/${loanNumber}/applications/${applicationNumber}/creditPulls`,
    });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'got credit pulls' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Creates credit pull.
 */
export async function createCreditPull(
  loanNumber: string,
  applicationNumber: string,
  pullType: string,
  preQual: boolean,
  loUID: string
): Promise<CreditPullCreated> {
  const log = { function: createCreditPull.name };
  console.info({ ...log, message: 'created credit pull', loanNumber });

  try {
    // get res
    const data = {
      pullType,
      preQual,
      loUID,
    };
    const res = await axiosCall({
      url: `restServer/loans/${loanNumber}/applications/${applicationNumber}/creditPulls`,
      method: 'POST',
      data,
    });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'credit pull created' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return { error: err.message };
  }
}

/**
 * Gets borrower.
 */
export async function getBorrower(loanNumber: string, borrowerNumber: string): Promise<Borrower | null> {
  const log = { function: getBorrower.name };
  console.info({ ...log, message: 'getting borrower', loanNumber, borrowerNumber });

  try {
    // get res
    const res = await axiosCall({ url: `restServer/loans/${loanNumber}/borrowers/${borrowerNumber}` });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'got borrower' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Updates borrower.
 */
export async function updateBorrower(loanNumber: string, borrowerNumber: string, data: any): Promise<any | null> {
  const log = { function: updateBorrower.name };
  console.info({ ...log, message: 'updating borrower', loanNumber, borrowerNumber, data });

  try {
    // get res
    const res = await axiosCall({
      url: `restServer/loans/${loanNumber}/borrowers/${borrowerNumber}`,
      method: 'PATCH',
      data,
    });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'updated borrower' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Gets participants.
 */
export async function getParticipants(chatId: string): Promise<Participant[] | null> {
  const log = { function: getParticipants.name };
  console.info({ ...log, message: 'getting participants', chatId });

  try {
    // get res
    const res = await axiosCall({
      url: `chat/${chatId}/participant`,
    });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'got participant' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Gets user.
 */
export async function getUser(userId: string): Promise<User | null> {
  const log = { function: getUser.name };
  console.info({ ...log, message: 'getting user', userId });

  try {
    // get res
    const res = await axiosCall({
      url: `user/${userId}`,
    });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'got user' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Create message.
 */
export async function createMessage(chatId: string, data: Message): Promise<Message | null> {
  const log = { function: createMessage.name };
  console.info({ ...log, message: 'creating message', chatId });

  try {
    // get res
    const res = await axiosCall({
      url: `chat/${chatId}/message`,
      method: 'POST',
      data,
    });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'created user' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}

/**
 * Gets a user using their email.
 */
export async function getUserByEmail(email: string): Promise<WhoIsUser | null> {
  const log = { function: getUserByEmail.name };
  console.info({ ...log, message: 'get user by email', email });

  try {
    // get res
    const data = {
      email,
    };
    const res = await axiosCall({
      url: `restServer/session/whois`,
      method: 'POST',
      data,
    });
    console.debug({ ...log, message: 'res declared', res });

    // continue
    console.info({ ...log, message: 'got user by email' });
    return res;
  } catch (err) {
    console.error({ ...log, message: err.message });
    return null;
  }
}
