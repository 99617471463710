/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */
import React from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/EmojiPickerReact.module.css';

const EmojiPickerReactSkeleton = () => {
  return (
    <div className={Skeleton.loader + ' ' + Styles.emojiPickerReact} />
  );
};

export default EmojiPickerReactSkeleton;