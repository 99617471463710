import { Box, styled } from '@mui/system';
import { Button, IconButton } from '@mui/material';
import { ReturnToDocument } from '@assets/Icons';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import React, { useContext } from 'react';
import { ElectronicSignatureEventType } from '../Forms/commonForms';
import { useTranslation } from 'react-i18next';

const StyledButton = styled(Button)({
  position: 'relative',
  fontSize: '0.7rem',
  fontWeight: 'normal',
  zIndex: '2',
  alignContent: 'center',
  minWidth: '10rem',
  width: '100%',
  height: '1.5625rem',
  backgroundColor: 'var(--orange500)',
  color: 'var(--gray000)',
  borderRadius: '0.3125rem',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  boxShadow: 'none',
  bottom: '0.375rem',
  '&:hover': {
    backgroundColor: 'orange',
    color: 'var(--gray000)',
  },
});

const MorphFormActiveChatTopBox = () => {
  const errandContext = useContext<IErrandContext>(ErrandContext);

  return (
      <IconButton onClick={() => errandContext.newFormEvent(ElectronicSignatureEventType.ArrowDown)}>
      <ReturnToDocument style={{position: 'relative', bottom: '0.25rem'}} />
      </IconButton>
  );
};

const MorphFormActiveChatIndent = () => {
  const { t } = useTranslation();
  const errandContext = useContext<IErrandContext>(ErrandContext);
  return (
    <StyledButton onClick={() => errandContext.newFormEvent(ElectronicSignatureEventType.ArrowDown)}>
      {t('buttonReturnToDocument')}
    </StyledButton>
  );
};

export { MorphFormActiveChatTopBox, MorphFormActiveChatIndent };
