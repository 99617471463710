/**
 * @file This component is a stylized Box component
 * for generating Chat Bubbles.
 *
 * Extracted from ConversationMessage.js 10/11/2022
 *
 * @author Harrison Fales <harrison.fales@swmc.com>
 * Original Component Author
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { Box, styled } from '@mui/system';
import { Button } from '@mui/material';

export const ChatBubbleStyle = styled(Box)<any>(
  () => `
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
  gap: 0 4px;
  border: 1px solid;
  border-radius: 10px;
  box-shadow: var(--shadow100) 0px 5px 35px;
  position: relative;
  & q {
    text-indent: 20px;
    quotes: none;
    border-bottom: 1px solid var(--orange700);
    position: relative;
    color: var(--orange700);
    width: calc(100% + 16px);
    transform: translateX(-8px);
    padding-left: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  };
  & s {
    color: red;
    word-wrap: break-word;
    width: fit-content;
    text-decoration-color: var(--red400);
    text-decoration-line: line-through;
    &::after: {
      content: "";
      color: var(--orange700);
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml;base64;${btoa(
        '<svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.20.2.51 0 .71l-2.79 2.79c-.31.31-.85.09-.85-.36" fill="currentColor"></path></svg>'
      )}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateY(8px);
      margin-top: -10px;
    }
  };
  & i {
    word-wrap: break-word;
    font-style: italic;
  },
`);

export const MessageClickableBubbleStyle = styled(Button)<any>(
  () => `
  display: flex;
  flex-wrap: multi-line;
  border: 1px solid;
  border-radius: 10px;
  box-shadow: var(--shadow100) 0px 5px 35px;
  position: relative;
  color: black;
  text-transform: none;
  font-size: 1em;
  text-align: left;
  & q {
    quotes: none;
    border-bottom: 1px solid var(--orange700);
    position: relative;
    color: var(--orange700);
    width: calc(100% + 16px);
    transform: translateX(-8px);
    padding-left: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    &::before: {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background-image: url(${Selection});
      background-size: 100% 100%;
      margin-right: 4px
    };
  };
  & s {
    color: red;
    word-wrap: break-word;
    width: fit-content;
    text-decoration-color: var(--red400);
    text-decoration-line: line-through;
    &::after: {
      content: "";
      color: var(--orange700);
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml;base64;${btoa(
        '<svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"> <path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.20.2.51 0 .71l-2.79 2.79c-.31.31-.85.09-.85-.36" fill="currentColor"></path></svg>'
      )}");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: translateY(8px);
      margin-top: -10px;
    }
  };
  & i {
    word-wrap: break-word;
    font-style: italic;
  },
`);
