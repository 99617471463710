/**
 * @file This module contains the definition of the `LanguagePickerCarousel`
 * component. This component is used in lieu of the `LanguagePickerButton`
 * component when in mobile view, and is identical to it except that the
 * filter modes are switched by swiping instead of pressing arrow buttons.
 * @author Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

import React, { PropsWithChildren, useEffect, useState, memo } from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Box from '@mui/material/Box';
import { US as FlagIconUS } from 'country-flag-icons/react/3x2';
import { flagIcons } from '@common/LocationInformation';
import { FlagStyles } from '@styles/LanguagePickerButtonStyles';
import { setPreselectedLanguage } from '@storage/userStorage';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import InsertLink from '@mui/icons-material/InsertLink';
import { Keyboard } from '@assets/Icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { map } from '@common/LocationInformation';
import { useSocketContext } from '../Contexts/socket';
import { useUserContext } from '@contexts/user';

const StyledDiv = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '2px',
}));

const LanguagePickerCarousel: React.FC<PropsWithChildren<any>> = memo((props) => {

  //////////////////////////// C O N S T A N T S /////////////////////////////

  const { i18n } = useTranslation();
  const dispModes = [
    "FLAGS",
    "DOCUMENTS",
    "AUDIO",
    "ACTIONS",
    "RESPONSES",
    "NOTIFICATIONS",
    "URLS"
  ];

  //////////////////////////////// S T A T E /////////////////////////////////

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [dispMode, setDispMode] = useState(0);
  const [isTyping, setIsTyping] = useState(false);

  const { _id } = useUserContext();
  const { messagesSocket, isMessagesConnected } = useSocketContext();

  /////////////////////// E V E N T    H A N D L E R S ///////////////////////

  /**
   * Invoked when the language picker menu is closed.
   */
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  /**
   * Invoked when the language is changed.
   */
  const handleLangChange = (event) => {
    setMenuAnchor(null);
    i18n.changeLanguage(map[event.currentTarget.dataset.itemIndex].lang);
    setPreselectedLanguage(map[event.currentTarget.dataset.itemIndex].lang);
  };

  /**
   * Invoked when the language picker menu is opened.
   */
  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  //////////////////////////// U S E - E F F E C T S ///////////////////////////

  useEffect(() => {
    switch (dispModes[dispMode]) {
      case "FLAGS":
        props.setDispFilterMode("None");
        return;
      case "DOCUMENTS":
        props.setDispFilterMode("Document");
        return;
      case "AUDIO":
        props.setDispFilterMode("Audio");
        return;
      case "ACTIONS":
        props.setDispFilterMode("Action");
        return;
      case "RESPONSES":
        props.setDispFilterMode("Text");
        return;
      case "NOTIFICATIONS":
        props.setDispFilterMode("Notification");
        return;
      case "URLS":
        props.setDispFilterMode("Url");
        return;
      default:
        props.setDispFilterMode("None");
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispMode]);

  useEffect(() => {
    if (!isMessagesConnected) return;
    
    const chatEventHandler = (payload) => {
      const data = payload?.data;
      const type = data?.type;
      const userId = data?.userId;
      const message = data?.message;
      const newTypingState = message === 'true' ? true : false;

      if (type !== 'typing' || userId !== _id) return;

      setIsTyping(newTypingState);
    };

    messagesSocket.current?.on('chat-event-emitted', chatEventHandler);
    return () => {
      messagesSocket.current?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected]);

  ////////////////////////////// C A L L B A C K S /////////////////////////////

  const handleUpdateDispMode = (index, indexLatest, meta) => {
    setDispMode(index);
  };

  /////////////////////////// R E T U R N    D A T A ///////////////////////////

  return (
    <div>
      <Box
        sx={{
          width: '41.5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxHeight: '33px',
          marginRight: '1px',
          marin: props.isDesktop ? '-1px 0 0 0' : '0 0 -1px 0',
          padding: '3px',
        }}
      >
        {
          isTyping ?
          <Keyboard
            style={{
              height: '25px',
              width: 'auto',
              color: 'var(--peach500)'
            }}
          />
        :
          <SwipeableViews
            onChangeIndex={handleUpdateDispMode}
            axis="y"
            animateHeight
            style={{height: '25px'}}
          >
            <StyledDiv>
            <>
                {flagIcons[i18n.language.split('-')[0]] ? (
                React.cloneElement(flagIcons[i18n.language.split('-')[0]], {
                  style: { ...FlagStyles },
                  onClick: handleOpenMenu,
                })
              ) : (
                <FlagIconUS // Default flag if the language is not found in the dictionary
                  style={{ ...FlagStyles }}
                  onClick={handleOpenMenu}
                />
              )}
            </>
            </StyledDiv>
            <StyledDiv>
              <InsertDriveFileIcon
                style={{
                  width: "auto",
                  height: "25px",
                  color: 'var(--peach500)'
                }}
              />
            </StyledDiv>
            <StyledDiv>
              <SpatialAudioOffIcon
                style={{
                  width: "auto",
                  height: "25px",
                  color: 'var(--peach500)'
                }}
              />
            </StyledDiv>
            <StyledDiv>
              <AssignmentIcon
                style={{
                  width: "auto",
                  height: "25px",
                  color: 'var(--peach500)'
                }}
              />
            </StyledDiv>
            <StyledDiv>
              <AssignmentTurnedInIcon
                style={{
                  width: "auto",
                  height: "25px",
                  color: 'var(--peach500)'
                }}
              />
            </StyledDiv>
            <StyledDiv>
              <NotificationsIcon
                style={{
                  width: "auto",
                  height: "25px",
                  color: 'var(--peach500)'
                }}
              />
            </StyledDiv>
            <StyledDiv>
              <InsertLink
                style={{
                  width: "auto",
                  height: "25px",
                  color: 'var(--peach500)'
                }}
              />
            </StyledDiv>
          </SwipeableViews>
        }
      </Box>
      <Menu
        open={Boolean(menuAnchor)}
        anchorEl={menuAnchor}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          transform: 'translate(-5px, -15px);'
        }}
        keepMounted
      >
        {
          map.map((item, i) => (
            <MenuItem 
              key={item.lang}
              value={item.lang}
              onClick={handleLangChange}
              data-item-index={i}
            >
            {item.nativeName}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
});

export default LanguagePickerCarousel;
