/**
 * @file HelpTipGroups.tsx
 * @description This file defines the admin UI for viewing HelpTipGroups. This was created as part of
 * the MRGN-246 task.
 * @author Ryan Buxton <ryan.buxton@swmc.com>
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */
import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Tooltip,
  Typography,
  Paper,
} from '@mui/material';
import TableHeaderBar from './TableHeaderBar';
import React, { useState } from 'react';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import StandardTablePagination from './StandardTablePagination';
import { useTranslation } from 'react-i18next';
import EditHelpTipGroupDialog from './EditHelpTipGroupDialog';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import useDebounce from '@common/hooks/useDebounce';
import useInitialMount from '@common/hooks/useInitialMount';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

const HelpTipGroups = (props) => {
  // States and hooks
  const [groups, setGroups] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [active, setActive] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tipGroupData, setTipGroupData] = useState({});
  const [allTips, setAllTips] = useState([]);

  const { t } = useTranslation();

  const loadHelpTipGroups = async (isActive) => {
    setLoading(true);

    // Prep some query values
    const reqOffset = page < 0 ? 0 : page * rowsPerPage;
    const reqLimit = rowsPerPage > 0 ? `&limit=${rowsPerPage + 1}` : '';
    const reqActive = isActive ? '&active=1' : '';

    // Make a request
    const data = await axiosCall({
      url: `helptipgroup${search.length > 0 ? '/search' : ''}?offset=${reqOffset}${reqLimit}${reqActive}`,
      method: search.length > 0 ? 'POST' : 'GET',
      data: {
        search: search.length > 0 ? search : undefined,
      },
    });

    // Update states
    setHasMore(rowsPerPage > 0 ? data.length > rowsPerPage : false);
    setGroups(rowsPerPage > 0 ? data.slice(0, rowsPerPage) : data); // Only display the number specified
    //setNoResults(false);
    setLoading(false);
  };

  const loadAllTips = async () => {
    if (allTips.length === 0) {
      const tips = await axiosCall({
        url: 'helptip',
      });
      setAllTips(tips);
    }
  };

  // Handlers
  const handleToggleInactive = async () => {
    setActive((prev) => {
      loadHelpTipGroups(!prev);
      return !prev;
    });
    setPage(0);
  };
  const handleDownload = () => {
    // console.log('DEBUG this would have downloaded. Do we need that?');
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleAddHelpTip = () => {
    setIsOpen(true);
    setIsEditing(false);
    setTipGroupData(null);
  };
  const handleCloseDialog = () => {
    setIsOpen(false);
  };
  const handleEdit = async (group) => {
    const tipIds = group.tips;
    const fetchedTips = await axiosCall({
      url: `helptip/bulk`,
      method: 'post',
      data: {
        tips: tipIds,
      },
    });

    setIsOpen(true);
    setIsEditing(true);
    setTipGroupData({ ...group, tips: fetchedTips });
  };
  const handleDeactivate = async (tip) => {
    try {
      await axiosCall({
        url: `helptipgroup/${tip._id}`,
        method: 'PUT',
        data: {
          active: false,
        },
      });
      await loadHelpTipGroups(active);
    } catch (err) {
      console.error(err);
    }
  };
  const handleReactivate = async (tip) => {
    try {
      await axiosCall({
        url: `helptipgroup/${tip._id}`,
        method: 'PUT',
        data: {
          active: true,
        },
      });
      await loadHelpTipGroups(active);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSearchChange = (newSearchValue) => {
    // We want to reset to the first page when searching so we don't miss any documents 
    // (for example, we don't want to begin our search on the second page 
    // as we would miss those on the first)
    setPage(0);
    // Now we can update the search value
    setSearch(newSearchValue);
  };

  // useEffects

  // useEffect(() => {
  //   loadHelpTipGroups();
  //   loadAllTips();
  //   // eslint-disable-next-line
  // }, []);

  useInitialMount(() => {
    loadHelpTipGroups(active);
    loadAllTips();
  });

  // following useEffects are deprecated
  // useEffect(() => {
  //   loadHelpTipGroups();
  //   // eslint-disable-next-line
  // }, [active]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     loadHelpTipGroups();
  //   }, 500);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  //   // eslint-disable-next-line
  // }, [search]);

  useDebounce(() => loadHelpTipGroups(active), 500, [search]);

  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 175px)" padding={1}>
      <TableHeaderBar
        onAdd={handleAddHelpTip}
        setSearch={handleSearchChange}
        setPage={setPage}
        onRefresh={() => loadHelpTipGroups(active)}
        onExport={handleDownload}
        shouldHaveSearch={true}
        addLabel={t('addHelpTipGroup')}
        searchValue={search}
        showInactiveClick={handleToggleInactive}
        isActive={active}
        uploadIcon={null}
      />
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : !loading && groups.length === 0 ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <Paper sx={{ p: 1.5 }}>
              <Typography> {t('noData')} </Typography>
            </Paper>
          </Stack>
        ) : (
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('nameCol')}</StyledTableCell>
                <StyledTableCell>{t('actionTranslation')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((group, index) => {
                // Map out each tip
                return (
                  <TableRow sx={{ backgroundColor: group.active ? 'var(--gray000)' : 'var(--peach030)' }} hover key={group._id}>
                    <StyledTableCell width={'90%'}>{group.name}</StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title={t('editTipGroup')} placement="top-start" enterDelay={1000} enterNextDelay={1000}>
                        <BorderColorOutlinedIcon
                          onClick={() => handleEdit(group)}
                          fontSize="small"
                          sx={{ cursor: 'pointer', marginLeft: '3px', color: 'var(--blue210)' }}
                        />
                      </Tooltip>
                      {group.active ? (
                        <Tooltip
                          title={t('deactivateTipGroup')}
                          placement="top-start"
                          enterDelay={1000}
                          enterNextDelay={1000}
                        >
                          <RestoreFromTrashOutlinedIcon
                            onClick={() => handleDeactivate(group)}
                            fontSize="small"
                            sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={t('activateTipGroup')}
                          placement="top-start"
                          enterDelay={1000}
                          enterNextDelay={1000}
                        >
                          <RestoreFromTrashOutlinedIcon
                            onClick={() => handleReactivate(group)}
                            fontSize="small"
                            sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                          />
                        </Tooltip>
                      )}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StandardTablePagination
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  isMore={hasMore}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {isOpen && (
        <EditHelpTipGroupDialog
          data={tipGroupData}
          open={isOpen}
          onClose={handleCloseDialog}
          reload={() => loadHelpTipGroups(active)}
          isEditing={isEditing}
          allTips={allTips}
        />
      )}
    </Box>
  );
};

export default HelpTipGroups;
