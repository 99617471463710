import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '../Styles/MorphDownloadAppBanner.module.css';
import { UAParser } from 'ua-parser-js';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAiDownloadAppBanner.png';

// URLs
const ANDROID_DOWNLOAD_URL =
  'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.swmc.morganthinclientandroid'; // URL of the android store download page for the thin client.
const IOS_DOWNLOAD_URL = 'https://apps.apple.com/us/app/angelai/id1667401139'; // URL of the apple store download page for the thin client.

const MorphDownloadAppBanner = ({errand}) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();

  const wrapperRef = useRef(null);
  // const [path, setPath] = useState('');
  // const setPaths = useCallback((dimensions?: TWindowDimensions) => {
  //   const containerWidth = wrapperRef.current?.offsetWidth;
  //   const containerHeight = wrapperRef.current?.offsetHeight;
  //   // M = move stroke to absolute x, y
  //   // L = draw line to absolute x, y
  //   // C = draw curve with 2 absolute bezier points then 1 absolute destination point
  //   // H = horizontal line to absolute X
  //   // c = draw curve with 2 relative bezier points then 1 relative destination point
  //   // Learn more about SVG syntax here: https://css-tricks.com/svg-path-syntax-illustrated-guide/
  //   setPath(`
  //     M 1,${containerHeight + 10}
  //     L 1,10
  //     C 0,5 , 5,0 , 10,1
  //     H ${containerWidth / 2 - 60}
  //     c31.593,0 , 26.296,24 , 60,23    c33.704,0 , 28.407,-23 , 60,-23
  //     H ${containerWidth - 9}
  //     c 4,0 , 8,4 , 8,8
  //     L ${containerWidth - 1}, ${containerHeight + 10}
  //   `);
  // }, []);

  const handleClickYes = () => {
    // Use UAParser to detect OS and navigate user to the right storefront
    const userDetails = UAParser();
    if (userDetails.os.name === 'Android') {
      window.open(ANDROID_DOWNLOAD_URL, '_blank');
    }
    if (userDetails.os.name === 'iOS') {
      window.open(IOS_DOWNLOAD_URL, '_blank');
    }
  };

  const handleClickNo = () => {
    if (errand.lastMessageData?.messageType === "CreditRepairWelcome"){
      errandContext.setMorphType(MorphType.CreditRepairWelcome);
    } else if (errand.lastMessageData?.messageType === "RefinanceCalculatorWelcome") {
      errandContext.setMorphType(MorphType.RefinanceCalculatorWelcome);
    }else{
      errandContext.setMorphType(MorphType.None);
    }
  };

  // useEffect(() => {
  //   setPaths();
  // }, []);

  // setPaths is called when window dimension changes
  // useWindowDimensions(setPaths);

  return (
    <div className={Styles.wrapper} ref={wrapperRef}>
      {/* <svg className={Styles.svg}>
        <path className={Styles.path} d={path} pathLength={10}></path>
      </svg> */}
      {/* <div className={Styles.curve} /> */}
      <div className={Styles.labels}>
        <h3 className={Styles.label1}>{t('downloadThe')}</h3>
        <div>&nbsp;</div>
        <h3 className={Styles.label2}>{t('AngelAiApp')}</h3>
      </div>
      <button className={Styles.button} onClick={handleClickYes}>
        <img src={AngelAi} alt="AngelAi logo" className={Styles.image} />
      </button>
      <div className={Styles.actionButtonContainer}>
        <button onClick={handleClickYes}>
          <p>{t('yes')}</p>
        </button>
        <button onClick={handleClickNo}>
          <p>{t('no')}</p>
        </button>
      </div>
    </div>
  );
};

export default MorphDownloadAppBanner;
