import React from 'react';
import './main.css';

/**
 * @description This component wraps whatever children you pass 
 * in its own wrapper that has a rainbow animation effect around 
 * the borders of the wrapper on all sides
 * @param props
 * @returns Wrapped Children
 */
const RainbowEffect = (props) => {
    return (
        <div className="rainbowWrapper">
            {props.children}
        </div>
    )
}


export { RainbowEffect };