import React from 'react';
import styled from '@emotion/styled';
const RopeWithBump = process.env.REACT_APP_MORGAN_CDN + '/Images/rope-with-bump.png';

// This is the animation for mobile view when Morgan speaks or when the conversation
// first loads. The rope will bounce out a little bit a few times. The parent 
// component for this is Components/Conversation.tsx

const StyledRopeAnimation = () => {


    const StyledRope = styled.div`
        display: flex;
        width: 20px;
        height: auto;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        pointerEvents: none;
        animation: bounceLeft 4s 1;

    @-webkit-keyframes bounceLeft {
        0% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        20% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        50% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        80% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        100% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        40% {
            -ms-transform: translateX(20px);
            transform: translateX(20px);
        }
        60% {
            -ms-transform: translateX(10px);
            transform: translateX(10px);
        }
    }

    @-moz-keyframes bounceLeft {
        0% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        20% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        50% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        80% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        100% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        40% {
            -ms-transform: translateX(20px);
            transform: translateX(20px);
        }
        60% {
            -ms-transform: translateX(10px);
            transform: translateX(10px);
        }
    }
        
        
    @keyframes bounceLeft {
        0% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        20% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        50% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        80% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        100% {
            -ms-transform: translateX(0);
            transform: translateX(0);
        }
        40% {
            -ms-transform: translateX(20px);
            transform: translateX(20px);
        }
        60% {
            -ms-transform: translateX(10px);
            transform: translateX(10px);
        }
    }
    `;

    return (
        <StyledRope>
            <img
            className='bouncyRope'
            src={RopeWithBump}
            alt="chatDrawer"
            style={{
                zIndex: '10',
                display: 'flex',
                width: '20px',
                height: '120vh',
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                pointerEvents: 'none',
                }}
            />
        </StyledRope>
    )
}

export default StyledRopeAnimation