// Activity Tracker Elements
import { IErrand, IUserData } from '@interfaces/Conversation';
import React, { memo } from 'react';
import { ALL_ELEMENTS, WIDGET_ELEMENTS } from './Config/Layouts';
import {
  PropertyListingElement,
  ExpandFullModeElement,
  EditingModeElement,
  PrivateModeElement,
  SlotMachineElement,
  ContextElement,
  ConsentElement,
  ActionElement,
  OrderElement,
  EsignElement,
  AuthElement,
  LiveElement,
} from './Elements/export';

// types/interfaces
import { ActivityData, IActivityID } from './interfaces';

// Default Elements
const { PropertyListing, EditingMode, PrivateMode, SlotMachine, Context, Consent, Action, Order, Esign, Auth, Live } =
  ALL_ELEMENTS;

// Widget Elements
const { ExpandFullMode } = WIDGET_ELEMENTS;

interface I_JSXMap {
  [key: string]: (props: any) => JSX.Element;
}

// declare only once
// static components
const JSX: I_JSXMap = {
  [PropertyListing]: PropertyListingElement,
  [ExpandFullMode]: ExpandFullModeElement, // WIDGET only
  [EditingMode]: EditingModeElement,
  [PrivateMode]: PrivateModeElement,
  [SlotMachine]: SlotMachineElement,
  [Consent]: ConsentElement,
  [Esign]: EsignElement,
  [Auth]: AuthElement,
  [Live]: LiveElement,
};

// Components that will have {...props} passed to them
const DYNAMIC_JSX: I_JSXMap = {
  [Context]: ContextElement,
  [Action]: ActionElement,
  [Order]: OrderElement,
};

const fetchComponent = (targetActivity: IActivityID, JSX_MAP: I_JSXMap) => {
  return JSX_MAP[targetActivity];
};

type TSingleActivityElementProps = {
  targetActivity: IActivityID;
  activityData: ActivityData;
  remeasureElement?: (givenData: ActivityData) => void;
  setMeasureSingleDimensionData?: React.Dispatch<React.SetStateAction<ActivityData>>;
  errand: IErrand;
  operatorData: IUserData | undefined | null;
};

/**
 *
 * @returns targetActivity component JSX
 * @summary Append code for additional activity tracker elements here by declaring [ActivityID] prop and setting JSX element as a value to it.
 */
export const SingleActivityElement = memo((props: TSingleActivityElementProps) => {
  // If target Activity id is present in JSX obj as a key.
  if (props.targetActivity in JSX) {
    const C = fetchComponent(props.targetActivity, JSX);
    return <C />;
  } else if (props.targetActivity in DYNAMIC_JSX) {
    const DC = fetchComponent(props.targetActivity, DYNAMIC_JSX); // dynamic component
    return <DC {...props} />;
  }
  // If given targetActivity does not have any UI element associated with it.
  else {
    console.warn(
      `Given props.targetActivity: ${props.targetActivity} does not have any associated UI element in JSX obj NOR in DYNAMIC_JSX map in SingleActivity.tsx file.`
    );
    return null;
  }
});
