// Asset Imports
import { Authenticated, NotAuthenticated } from '@assets/Icons';
import { SMLSIcon } from '@assets/Icons';
// MUI Imports
import { Box } from '@mui/system';
import {
  Button,
  IconButton,
  InputBase,
  Stack,
  Tooltip
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useCallback, useEffect, useMemo, useRef, useState, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UAParser } from 'ua-parser-js';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import { MorganTheme, useTheme } from '@mui/material';
// Other Imports
import axiosCall from '@services/axios';
import { useSocketContext } from '@contexts/socket';
import { getUserAccessToken, getUserConsent, getUserConsentIntro, getWindowLocationPath } from '@storage/userStorage';
import { IContext } from '@interfaces/Conversation';
// Common imports
import useDebounce from '@common/hooks/useDebounce';
import { sendWorkflow } from '@common/WorkflowsUtils';
// import eventBus from '@common/eventBus.js';
import { getStatusColors } from '@common/StatusColors';
// Component Imports
import IFrameDialog from '@components/IFrameDialog';
import AddOperatorToConvDialog from '@components/AddOperatorToConvDialog';
import ConsoleDialog from '@components/ConsoleDialog';
import ConfirmResendWorkflowDialog from '@components/ConfirmResendWorkflowDialog';
import ParticipantsDialog from '@components/ParticipantsDialog';
import RenameGroupDialog from '@components/RenameGroupDialog';
import RenameUserDialog from '@components/RenameUserDialog';
import SMLSDialog from '@components/SMLSDialog';
import { useErrandContext } from '@contexts/ErrandContext';
import { StyledTitleContainer, StyledGroupIcon, StyledName, StyledNameButton } from '@styles/ConversationTitleStyles';
import { OperatorHandlerPermissions } from '../Permissions/HandlerPermissionsController';
import { getOperatorDisplayString, shouldShowInactive, getCurrentParticipant, getPrimaryParticipant } from '@common/errandUtils';
import { useRootContext } from '@contexts/RootContext';
import ActivityTrackerWrapper from '../ActivityTracker/ActivityTrackerWrapper';
import SongPlayerViewBox from '../SongPlayerViewBox';
import { ChatType } from '@common/ChatType';
import { handleTranslations } from '@common/loadPreview';
import { compareAsc } from 'date-fns';
import { ValidatorFunctions } from '@common/Validators';
import { AccessType } from '@common/AccessType';
import { getAiAudience } from '@common/msgUtils';
import Styles from '@styles/ConversationTitle.module.css';
import { isMobile, isMobileOrTablet } from '@common/deviceTypeHelper';
import { useUserContext } from '@contexts/user';
import { getOperatorAccessToken } from '@storage/operatorStorage';
import ThinClientUtils from '@common/ThinClientUtils';
import useAbortController from "@common/hooks/useAbortController";
import ConversationTitleMenu from './ConversationTitleMenu';
import { IUserData } from '@interfaces/Conversation';
import { ValidUserDataFields } from '@common/common';
import { WORKFLOW_NAMES } from '@constants/WorkflowNames';

const { isNotUndefinedNorNull, isNotEmptyString } = ValidatorFunctions;
/*
 *  This component renders a the title bar at the top of the conversations page. It includes the
 *  Operator name, icons, search bar and langauge picker
 *
 *  This component has the following properties:
 *    - operator - the name of the operator rendered at the top left of the screen
 */


const ConversationTitle: React.FC<PropsWithChildren<any>> = (props) => {
  const errandContext = useErrandContext();
  const navigate = useNavigate();
  const rootContext = useRootContext();
  const isWidget = rootContext.isWidget;
  const theme: MorganTheme = useTheme();
  const { t, i18n } = useTranslation();
  const { _id, isOperator, isUser, ssAccessToken, setUser } = useUserContext();
  const searchArea = useRef(null);
  const { messagesSocket, isMessagesConnected } = useSocketContext();
  const [searchString, setSearchString] = useState('');
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);
  const [addOperatorDialog, setAddOperatorDialog] = useState(false);
  const [participantsDialog, setParticipantsDialog] = useState(false);
  const [consoleDialog, setConsoleDialog] = useState(false);
  const [editingParticipant, setEditingParticipant] = useState(null);
  const [renameGroupDialog, setRenameGroupDialog] = useState(false);
  const abortController = useAbortController();
  const [userAccessData, setUserAccessData] = useState([
    {
      user: "", // userId for this user
      address: "", // User's IP Address
      userBrowser: "", //User's Browser
      isMobile: false, //Desktop or Mobile
      httpReferer: "", //URL of morgan access
      timezone: "America/Los_Angeles",
      userTime: "", //User time and date based on timezone
      userHour: 12, //Hour of the user
      ssAccessToken: "",
    }
  ]);
  const [smlsOpen, setSmlsOpen] = useState(false);
  const [iframeSource, setIframeSource] = useState(undefined);
  const [showConfirmResendDialog, setShowConfirmResendDialog] = useState({
    open: false,
    chat: undefined,
    type: undefined,
    id: undefined,
    recipients: [],
    check: true
  });
  const [overflowNames, setOverflowNames] = useState(false);
  const isMessageHistoryAllowedForCurrentParticipant = getCurrentParticipant(props.errand, _id)?.messageHistoryAllowed;
  const userId = _id;
  const dispSearch = useMemo(() => { 
    return props.operatorData || isMessageHistoryAllowedForCurrentParticipant
  }, [
    props.operatorData,
    isMessageHistoryAllowedForCurrentParticipant
  ]);
  
  const updateNotificationBanner = () => {
    sessionStorage.setItem('notificationBannerEnabled', 'clear');
    props.setNotificationBannerEnabled(false);
  }
  
  const [showOpenAppButton, setShowOpenAppButton] = useState(
    isMobileOrTablet() && !ThinClientUtils?.isThinClient() && isUser
  );
  // const userAgent = navigator.userAgent;

  // EVENT HANDLERS
  const shieldClickHandler = async (user) => {
    if (
      props.errand?.status !== 'inactive' &&
      props.errand?.status !== 'closed'
    ) {
      // send the workflow privetly to the primary participant
      const recipientsToUse = [user];

      setShowConfirmResendDialog(await sendWorkflow('', WORKFLOW_NAMES.AUTHENTICATE, props.errand?._id, recipientsToUse, AccessType.public, props.operatorData !== undefined ? props.operatorData?._id : userId, props.operatorData !== undefined, showConfirmResendDialog.check));
    }
  }

  const onClickMenu = (e: React.SyntheticEvent) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchClose = () => {
    props.setIsSearchOpen(false);
    errandContext.setMessageFilter("");
    setSearchString('');
    errandContext.searchRefs.current = [];
    errandContext?.bodyRef?.current?.scrollTo(
      { left: 0, top: errandContext?.bodyRef?.current?.scrollHeight, behavior: 'smooth' }
    );
  };


  const handleOpen = (userAccessIndex) => {
    if (userAccessIndex === -1) return;
    // Update the user's time shown on tooltip open and the clock color
    setUserAccessData((prev) => {
      prev[userAccessIndex].userTime = getTimeAtLoc(prev[userAccessIndex].timezone);
      prev[userAccessIndex].userHour = getHourAtLoc(prev[userAccessIndex].timezone);
      return [...prev];
    });
  }

  // When "unmark as suspicious" is clicked, toggle the state of flagged or not
  const handleToggleFlag = () => {
    // if so, update the isFlagged for the corresponding errand
    props.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      // get the index for the current errand
      let index = prev.findIndex((e) => e._id === props.errand?._id);
      // check if the current errand is in the array
      if (index === -1) return prev;
      // if so, update the isFlagged for the corresponding index
      const isNowFlagged = !prev[index].isFlagged;
      (async (isFlagged) => {
        await axiosCall({
          url: `chat/${props.errand?._id || props.chatId}`,
          method: 'PUT',
          data: { isFlagged: isFlagged },
        });
      })(isNowFlagged);
      prev[index].isFlagged = isNowFlagged;
      return [...prev];
    });
  }

  const handleContentCopy = useCallback(async () => {
    try {
      const array = [];
      const users = props.errand.participants.filter((p) => p.active && p.userType === 'User').map((u) => ((u.userData?.firstname || '') + " " + (u.userData?.lastname || ''))?.trim()).join(', ');
      const operators = props.errand.participants.filter((p) => p.active && p.userType === 'Operator').map((u) => ((u.userData?.firstname || '') + " " + (u.userData?.lastname || '') + " " + (u.userData?.accessLevel ? `(${u.userData?.accessLevel})` : ''))?.trim()).join(', ');
      const userIds = props.errand.participants.map((p) => p.active && p.userData._id);
      array.push(`${users}${props.errand.displayName ? ` (${props.errand.displayName})` : ''} assisted by ${operators}\n${props.errand.type} ${props.errand._id} in status ${props.errand.status}`);
      const messages = [...props.errand.messages, ...(props.errand.privateMessages || [])].sort((a, b) => compareAsc(new Date(a.createdAt), new Date(b.createdAt)));
      for await(const messageObj of messages) {
        const message = `\n${await handleTranslations(messageObj, true)}`;
        const messageId = messageObj._id || '';
        const senderId = messageObj.sender?._id || '';
        const messageType = messageObj.messageType ? `${messageObj.messageType} Message` : 'Message';
        const sender = `sent by ${(((messageObj.sender?.firstname || '') + " " + (messageObj.sender?.lastname || '') + " " + (messageObj.sender?.email || ''))?.trim() || t('deletedUser')) + (userIds.indexOf(senderId) === -1 ? ` (${t('tInactive')})` : '')}`;
        const recipients = `and received by ${messageObj.accessType === AccessType.internal ? 'operators' : messageObj.recipients?.length > 0 ? `[${messageObj.recipients?.join(', ')}]` : 'everyone'}.`;
        const createdAt = new Date(messageObj.createdAt).toLocaleTimeString(i18n.language, { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: "UTC", timeZoneName: "short" } ) || '';
        const updatedAt = new Date(messageObj.createdAt).toLocaleTimeString(i18n.language, { month: '2-digit', day: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: "UTC", timeZoneName: "short" } ) || '';
        const wasCorrected = messageObj.message?.indexOf(t('acceptedData')) !== -1;
        const wasUpdated = createdAt !== updatedAt;
        const updated =  wasUpdated 
          ? wasCorrected
            ? `, corrected ${updatedAt}` 
            : `, updated ${updatedAt}`
          : ''
        const sent = `on ${createdAt}${updated}.`;
        const base = [messageType, messageId, sender, senderId, sent, recipients, message].filter((v) => v !== '').join(' ');
        array.push(base);
      }
      const string = array.join('\n\n');
      navigator.clipboard.writeText(string);
    } catch (e) {
      console.error(`ConversationTitle.handleContentCopy encountered an error: ${e.message}`)
    } finally {
      setAnchorEl(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errand?.messages, i18n.language, props.errand?._id]);

  // Search Bar rendering logic func
  // Handles if searchbar is expanded or not.
  // Displays the correct elements accordingly
  const renderSearchBar = () => {
    // If the song player is open, for sake of space, we disable searchbar/searchicon
    if (errandContext.songPlayerData?.show) return null;

    // If not operator and consent is not true
    // hide.
    if (!props.operatorData && (getUserConsentIntro() !== 'true' || getUserConsent() !== 'true')) {
      return null;
    }

    return (props.errand?.messages !== null && props.errand?.messages?.length >= 0 &&
      <>
        {props.isSearchOpen ? (
          <>
            <Box className='CTSearchBar'>
              <SearchIcon className='CTSearchIcon' />
              <InputBase
                inputRef={searchArea}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                className='CTInputBase'
                placeholder={t('conversationTitleSearchPlaceholder')}
                autoComplete='off'
              />
              <IconButton
                sx={{ color: theme.palette.blue['999'] }}
                onClick={handleSearchClose}>
                <CloseIcon sx={{ width: '.5em', height: '.5em' }} />
              </IconButton>
            </Box>
            {/* Search Expander */}
          </>
        ) : (
          (
            !isWidget && (<>
              {/* Search Expander */}
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                className='CTClosedSearch'
              >
                <IconButton
                  size="small"
                  className='CTIconButton'
                  aria-label="search"
                  onClick={() => props.setIsSearchOpen(!props.isSearchOpen)}
                >
                  <SearchIcon />
                </IconButton>
              </Stack>
            </>)
          )
        )}
      </>)
  }
  // handles logic for rendering SMLS button, Context, SearchBar, .... , Menu(3 verical dots) function. [i.e. Action Icons/Buttons].
  // Multiple Renders inside depend on many variables and contain complex logic.
  /**
   * 
    isSearchOpen: boolean;
    editingMode: boolean;
    isSlotMachineShown: boolean;
    errand: IErrand;
    operatorData: IUserData;
    isWidget: boolean;
   */
  const renderActionBar = () => {
    const editingMode: boolean = isNotEmptyString(props.editMessageId) && isNotUndefinedNorNull(props.editMessageId);

    return (
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} style={{marginRight: isWidget ? '28px' : '0px'}}>
        {errandContext.songPlayerData?.show && (
          <SongPlayerViewBox />
        )}
        {/* 
          Activity Tracker
          Hide if User invites friend OR search is open OR in widget. 
        */}
        {(!errandContext.songPlayerData?.show) && !isOperator &&
          <ActivityTrackerWrapper
            isSearchOpen={props.isSearchOpen}
            authToken={isUser ? ssAccessToken : userAccessData?.find((a) => a.ssAccessToken && a.user === getPrimaryParticipant(props.errand)?.userData?._id)?.ssAccessToken}
            errand={props.errand || null}
            operatorData={props.operatorData}
            editingMode={Boolean(props.editMessageId)}
            isSlotMachineShown={props.showSlotMachine}
            isWidget={isWidget}
          />}
        {/* SMLS button - only to be shown in chats that are authenticated. */}
        {
          props.operatorData &&
          props.errand?.messages !== null &&
          props.errand?.messages?.length >= 0 &&
          userAccessData?.find((a) => a.ssAccessToken && a.user === getPrimaryParticipant(props.errand)?.userData?._id)?.ssAccessToken &&
          ![ChatType.Team, ChatType.Group].includes(props.errand.type) &&
          <>
            <IconButton /*variant="contained"*/ sx={{ padding: 0.5, backgroundColor: 'var(--gray000)', '&:hover': { backgroundColor: 'var(--gray000)', }, }} onClick={OperatorHandlerPermissions.filter(() => setSmlsOpen(true), props)}>
              <SMLSIcon className='CTSmlsIcon' />
            </IconButton>
            <SMLSDialog open={smlsOpen} onClose={() => { setSmlsOpen(false); }} setIframeSource={setIframeSource} errand={props.errand} operatorData={props.operatorData} />
            <IFrameDialog open={iframeSource !== undefined} onClose={() => setIframeSource(undefined)} src={iframeSource} />
          </>
        }

        {/* mini chat, disabled since private chats are prefered for now */}
        {/* {props.errand.type === ChatType.Team && props.errand?.participants?.length > 0 && (
          <IconButton onClick={(e) => {
            navigate("/console", {
              state: {
                module: "conversations",
                tab: "team"
              },
            });
          }}>
            <Tooltip title={t('openInPopUp')}>
              <BrandingWatermarkOutlinedIcon sx={{ color: 'var(--gray000)' }} />
            </Tooltip>
          </IconButton>
        )} */}

        {/* Open App button & Search bar */}
        {showOpenAppButton && 
          <a
            className={Styles.openAppButton}
            onClick={() => setShowOpenAppButton(false)}
            href={`https://app.askangel.ai/${getWindowLocationPath().slice(1)}`}
            target="_blank"
            rel="noreferrer"
          >
            <p>{t('openApp')}</p>
          </a>
        }
        {/* User AND Operator */}
        {!showOpenAppButton && dispSearch && renderSearchBar()}

        {/* Operator */}
        {props.operatorData && props.errand?.participants?.length > 0 && (
          <>
            {/* Confirm that the operator is in the participant list */}
            {props.errand?.participants?.find((p) => p?.active && p?.userData?._id === props.operatorData._id) ? (
              <>
                {/* Operator is a participant and the chat is not closed. */}
                {props.errand?.status !== 'closed' && (
                  <IconButton onClick={onClickMenu}>
                    <MoreVertIcon sx={{ color: 'var(--blue010)' }} />
                  </IconButton>
                )}
                {/* Conversation title 'three dots' menu on the right side component. */}
                <ConversationTitleMenu
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  setAddOperatorDialog={setAddOperatorDialog}
                  setAnchorEl={setAnchorEl}
                  handleToggleFlag={handleToggleFlag}
                  handleContentCopy={handleContentCopy}
                  setConsoleDialog={setConsoleDialog}
                  errand={props.errand}
                  operatorData={props.operatorData}
                  setErrands={props.setErrands}
                />
              </>
            ) : (
              <>
                {/* Chat not closed or in the inactive state then allow them to join */}
                {props.errand?.status !== 'inactive' && props.errand?.status !== 'closed' && props.errand?.status !== 'archived' && (
                    <Button
                      className='CTJoinConvoBtn'
                      onClick={async () => {
                        console.log(`User is trying to join conversation ${props.errand?._id}`);
                        let tab = 'mine';
                        if ([ChatType.Team, ChatType.Group].includes(props.errand.type)) {
                          tab = props.errand.type;
                        }
                        // Add the operator as a participant
                        var res = await axiosCall({
                          url: `chat/${props.errand?._id}/participant`,
                          method: 'post',
                          data: {
                            userId: props.operatorData?._id,
                            userType: 'Operator',
                          },
                        });
                        // If successful then navigate to the chat / reload
                        if (res) {
                          navigate('/console', {
                            state: {
                              module: 'conversations',
                              tab: tab,
                              chat: props.errand?._id,
                            },
                          });
                        }
                      }}>
                      {t('joinConversation')}
                    </Button>
                  )}
              </>
            )}
            {/* If we are on the history tab (chat is inactive or closed) - show the restore button */}
            {(['inactive', 'closed'].includes(props.errand.status) && ![ChatType.Team, ChatType.Group].includes(props.errand.type)) && (
              <Button
                className='CTRestoreConvoBtn'
                onClick={async () => {
                  console.log(`User is trying to restore conversation ${props.errand?._id}`);
                  var res = await axiosCall({
                    url: `chat/${props.errand?._id}`,
                    method: 'put',
                    data: {
                      status: 'active',
                      position: -10000,
                    },
                  });
                  if (res) {
                    // If participant then move to my conversations, if not participant then move to live
                    navigate('/console', {
                      state: {
                        module: 'conversations',
                        // if the operator is a participant in the chat then move to my conv else move to live tab when restored.
                        tab: props.errand?.participants?.filter(p => p?.active && p?.userData?._id === props.operatorData._id)?.[0]?._id ? 'mine' : 'live',
                        chat: props.errand?._id,
                      },
                    });
                  }
                }}>
                {t('restoreConversation')}
              </Button>
            )}
            {props.errand.status === 'archived' && (
              <Button
                className='CTRestoreConvoBtn'
              >{t('cannotRestore')}</Button>
            )
            }
          </>
        )}
      </Stack>
    )
  }

  const onEditParticipantHandler = (participant) => {
    if (
      props.errand?.status !== 'inactive' &&
      props.errand?.status !== 'closed' &&
      props.operatorData
    ) {
      setEditingParticipant(participant);
    }
  }

  const displayParticipants = useMemo(() => {
    if (!props.errand || !Array.isArray(props.errand.participants)) return [];
    if (props.errand.type === ChatType.Group) return [];

    const participants = props.errand.participants.filter((p) => p?.active && p?.userData?._id && p?.userData?.firstname);
    if (props.errand.type === ChatType.Team) {
      return participants.filter((p) => p?.active && p?.userData?._id !== props?.operatorData?._id);
    }
    if (isOperator) {
      const users = participants.filter((p) => p.userType === 'User');
      if (users.length > 0) return users;
      const activeUsers = props.errand.participants.filter((p) => p.active && p.userType === 'User');
      if (activeUsers.length > 0) return activeUsers;
      return [];
    }
    const aiAudience = getAiAudience(participants, participants.map((p) => `${p?.userData?._id}`))
    if (aiAudience.length === 0) {
      const operators = participants.filter((p) => p.userType === 'Operator');
      if (operators.length > 0) return operators;
      const activeOperators = props.errand.participants.filter((p) => p.active && p.userType === 'Operator');
      if (activeOperators.length > 0) return activeOperators;
      return [];
    }
    const ai = participants?.find((p) => p?.active && p?.userData?.accessLevel === 'Level 0') ||
      participants?.find((p) => aiAudience.indexOf(p?.userData?._id) !== -1);
    const otherAudience = participants.filter((p) => {
      if (aiAudience.indexOf(p.userData?._id) !== -1) return false;
      if (p.userType !== 'Operator') return false;
      return true;
    });
    if (!ai) return otherAudience;
    ai.nickname = 'AngelAi';
    return [ai, ...otherAudience];
  }, [props.errand?._id, props.errand?.participants, isOperator]);

  const getUserAccessData = useCallback(async() => {
    const config = abortController.get();
    const participantRequest = {
      url: `chat/${props.errand._id}/participant`
    }
    const participantResult = await axiosCall(participantRequest, config)
    const accessRecords:any = [];
    try {
      const users = participantResult?.filter((p) => p.userType === 'User');

      if (!Array.isArray(users)) return;

      for await (const participant of users) {
        try {
          const accessToken = isUser ? getUserAccessToken() : getOperatorAccessToken();

          if (!accessToken) continue;
          if (isUser && participant.userData?._id !== _id) continue;
          // This request endpoint helps us get the most current userData regardless of
          // whether the user has authenticated or not
          const request = {
            url: `user/${participant.userData?._id}/access?order=desc&limit=1`,
          }

          const userAccessArray = await axiosCall(request, config);
          const userAccess = userAccessArray[0]

          const address = userAccess.address ? ` - ${userAccess.address}` : '';
          const userTime = getTimeAtLoc(userAccess.timezone);
          const userHour = getHourAtLoc(userAccess.timezone);
          const userBrowser = UAParser(userAccess.userAgent)?.browser?.name ? UAParser(userAccess.userAgent)?.browser?.name : t('unknownDevice');
          const mobile = isMobile(userAccess.userAgent);
          const httpReferer = (userAccess.httpReferer || '').includes('//') ?  userAccess.httpReferer.split('//')[1] : userAccess.httpReferer;

          // Use this validRequest endpoint to check if the user has an ssAccesstoken 
          // or not, which determines if they are authenticated (and what color shield to render)
          const validRequest = {
            url: `user/${participant.userData?._id}/valid/access`
          }
          let ssAccessToken = userAccess.ssAccessToken;
          // Only use the second endpoint here if the first one doesn't have
          // an ssAccessToken already (to avoid redundancy)
          if (!userAccess.ssAccessToken){
            const userAccessAuth = await axiosCall(validRequest, config);
            if (userAccessAuth){
              ssAccessToken = userAccessAuth.ssAccessToken
            }
          }

          accessRecords.push({
            ...userAccess,
            ssAccessToken,
            address,
            userTime,
            userHour,
            userBrowser,
            isMobile: mobile,
            httpReferer,
          });
        } catch (error) {
        }
      }
    } catch (error) {
      console.error(`ConversationTitle.callback.error`, error);
    } finally {
      setUserAccessData(accessRecords);

      props.setErrands((prev) => {
        const chatObj = prev.find((x) => x._id === props.errand._id);

        if (ValidatorFunctions.isUndefinedOrNull(chatObj)) {
          return prev;
        }

        chatObj.participants = participantResult;

        return [...prev];
      })
    }
  }, [isUser, _id, props.errand._id])

  useEffect(() => {
    getUserAccessData();
  }, [getUserAccessData])


  // Title Info Icons Render Function
  // Handles whatever needs to be rendered on either Operator/User side.
  const renderTitleInfoIcons = () => {
    return (props.operatorData ? (
      // Operator Side Btns.
      <>
        {props.errand?.type === ChatType.Group
          ?
          <button className={Styles.nameButton} onClick={() => setRenameGroupDialog(true)}>
            {props.errand?.displayName}
            {props.errand?.showInactive && ` (${t('tInactive')})`}
          </button>
          : displayParticipants.length === 0
            ? (
              <span className={Styles.userInfo}>
                {/* Shield */}
                {props.errand?.type !== ChatType.Team && (
                  <Tooltip title={"Not Authenticated"}>
                    <NotAuthenticated className='CTNotAuthShield' />
                  </Tooltip>
                )}
                {/* Status indicator */}
                <Tooltip title={'offline'}>
                  <div
                    className={Styles.status}
                    style={{
                      height: '0.5em',
                      width: '0.5em',
                      borderRadius: '0.5em',
                      margin: '0 .25em 0 .5em',
                      cursor: 'pointer',
                      backgroundColor: getStatusColors('offline', theme).main,
                    }}
                  />
                </Tooltip>
                {/* Name */}
                <Tooltip title={t('conversationListPlaceholderName')}>
                  <button
                    onClick={() => {}}
                    className={[
                      Styles.nameButton,
                      Styles[props.errand?.status],
                      ...(props.operatorData ? [Styles.operatorData] : []),
                    ].join(' ')}
                  >
                    {t('conversationListPlaceholderName')}
                  </button>
                </Tooltip>
              </span>
            )
            : displayParticipants.map((p, i, a) => {
              const pStatus = ((p.userType === 'Operator'
                ? props.onlineOperators?.find((operator) => operator._id === p.userData?._id)
                : props.onlineUsers?.find((user) => user._id === p.userData?._id)) || { status: 'offline' }).status;
              const firstname = p.userData?.firstname?.toLowerCase() || '';
              const lastname = a.length === 1 ? ' ' + (p.userData?.lastname?.toLowerCase() || '') : '';
              const fullname = (firstname + lastname).trim();
              const active = !p.active ? ` (${t('tInactive')})` : '';
              const localName = (fullname.length > 0 ? fullname : t('deletedUser')) + active;
              const userAccessIndex = p.userType === 'Operator' ? -1 : (userAccessData || [])?.findIndex((uad) => uad.user === p.userData?._id);
              const record = userAccessIndex === -1 ? {} : userAccessData[userAccessIndex];
              if (overflowNames && i > 0) return <span key={i}></span>;
              return (
                <span className={Styles.userInfo} key={i}>
                  {/* Shield */}
                {props.errand?.type !== ChatType.Team && (
                  <Tooltip title={record.ssAccessToken ? "Authenticated" : "Not Authenticated"}>
                    {record.ssAccessToken
                      ? <Authenticated className='CTAuthShield' />
                      : <NotAuthenticated onClick={() => shieldClickHandler(p.userData?._id)} className='CTNotAuthShield' />}
                  </Tooltip>
                )}
                  {/* Status indicator */}
                  <Tooltip title={pStatus}>
                    <div
                      className={Styles.status}
                      style={{
                        height: '0.5em',
                        width: '0.5em',
                        borderRadius: '0.5em',
                        margin: '0 .25em 0 .5em',
                        cursor: 'pointer',
                        backgroundColor: getStatusColors(pStatus, theme).main,
                      }}
                    />
                  </Tooltip>
                  {/* Name */}
                  <Tooltip title={fullname}>
                    <button
                      onClick={() => onEditParticipantHandler(p)}
                      className={[
                        Styles.nameButton,
                        Styles[props.errand?.status],
                        ...(props.operatorData ? [Styles.operatorData] : []),
                      ].join(' ')}
                    >
                      {localName}
                    </button>
                  </Tooltip>
                  {![ChatType.Team, ChatType.Group].includes(props.errand.type) && (
                  <>
                    {/* Device */}
                    <Tooltip
                      title={`${t('userConnected')} ${record.httpReferer ? record.httpReferer : process.env.REACT_APP_PUBLIC_URL
                        } via ${record.userBrowser || t('unknownDevice')}${record.address || ''}`}>
                      {record.isMobile ? (
                        <SmartphoneOutlinedIcon
                          sx={{ height: '1.125rem', width: '1.125rem', color: 'var(--blue010)' }}
                        />
                      ) : (
                        <DesktopWindowsOutlinedIcon
                          sx={{ height: '1.125rem', width: '1.125rem', color: 'var(--blue010)' }}
                        />
                      )}
                    </Tooltip>
                    {/* Time */}
                    <Tooltip onOpen={() => handleOpen(userAccessIndex)} title={record.userTime ? `${t('userTime')} ${record.userTime}` : `${t('userTimeUnavailable')}`}>
                      <AccessTimeOutlinedIcon
                        sx={{
                          marginLeft: '0.5rem',
                          height: '1.125rem',
                          width: '1.125rem',
                          color: `${record.userHour >= 6 && record.userHour < 18
                            ? 'var(--blue010)'
                            : 'var(--red400)'
                            }`,
                        }}
                      />
                    </Tooltip>
                  </>
                  )}
                </span>
              )
            })}

        {overflowNames && displayParticipants.length > 1 && (
          <button
            onClick={() => setParticipantsDialog(true)}
            className={Styles.nameButton}
            style={{
              cursor:
                props.errand?.status !== 'inactive' &&
                  props.errand?.status !== 'closed'
                  ? 'pointer'
                  : 'unset',
            }}>
            {`(+${displayParticipants.length - 1})`}
          </button>
        )}

        {/* Show Participants Dialog Btn */}
        {props.errand?.participants?.length > 2 && (props.errand?.type === ChatType.Group || displayParticipants?.length > 0) && (
          <IconButton
            sx={{ marginLeft: '0rem !important' }}
            onClick={() => setParticipantsDialog(true)}>
            <StyledGroupIcon />
          </IconButton>
        )}

        {/* Copy group name to clipboard btn */}
        {props.errand?.participants?.find((p) => p.active && p.primary && p.userData) && props.errand?.type === ChatType.Errand && props.errand?.displayName !== '' && (
          <Tooltip title={props.errand?.displayName}>
            <StyledNameButton
              onClick={() => {
                navigator.clipboard.writeText(props.errand?.displayName);
              }}>
              {` (${props.errand?.displayName})`}
            </StyledNameButton>
          </Tooltip>
        )}
      </>
    ) : (
      // User Side Btns.
      <>
        {/* Invite Btn, Conversation Title */}
        {props.errand?.participants?.length > 0 && (props.isDesktop || !props.isSearchOpen) && (
          <>
            <div>
              <StyledName
                sx={{
                  cursor: 'default',
                  maxWidth:
                    props.errand.context?.length > 0
                      ? 'calc(100vw - 15rem)'
                      : 'calc(100vw - 8.125rem)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {/* generates a unique list of nicknames (firstnames when unavailable) */}
                {
                  // check if Morgan is in the chat
                  props.errand?.participants?.filter((p) => p?.active && p?.ai)
                    .length > 0
                    ? // If so, the name at the top should be Morgan
                    'AngelAi'
                    : // If not, the name at the top should be the first operator
                    props.errand?.participants?.filter((p) => p?.active && p?.userType === 'Operator')
                      .slice(0, 1)
                      .map((obj) => {
                        return obj.userData?.nickname
                          ? obj.userData?.nickname.toLowerCase().trim()
                          : obj?.userData?.firstname.toLowerCase().trim();
                      })[0]
                }
                {props.errand?.type === ChatType.Errand && (
                  <small style={{ fontSize: '.75rem' }}> (Errand)</small>
                )}
              </StyledName>
              <StyledName
                sx={{
                  cursor: 'default',
                  maxWidth:
                    isWidget ? 'calc(100vw - 5.625rem)'
                      : !props.isDesktop && props.errand.context?.length > 0
                        ? 'calc(100vw - 19.9375rem)'
                        : props.errand.context?.length > 0
                          ? 'calc(100vw - 15rem)'
                          : 'calc(100vw - 8.125rem)',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '.5rem',
                }}>
                {/* generates a unique list of nicknames (firstnames when unavailable) */}
                {
                  // check if Morgan is in the chat
                  props.errand?.participants?.filter((p) => p?.active && p?.ai)
                    .length > 0
                    ? // If so, the name at the top should be Morgan
                    props.errand?.participants?.filter((p) => p?.active && p?.userType === 'Operator')
                      .map((obj) => {
                        return obj.userData?.nickname
                          ? obj.userData?.nickname.toLowerCase().trim()
                          : obj?.userData?.firstname.toLowerCase().trim();
                      })
                      .filter(
                        (name, index, array) =>
                          array.indexOf(name) === index && name.replace(/\s/, '').toLowerCase() !== 'angelai'
                      )
                      .join(', ')
                    : // If not, the name at the top should be the first operator
                    props.errand?.participants?.filter((p) => p?.active && p?.userType === 'Operator')
                      .slice(1)
                      .map((obj) => {
                        return obj.userData?.nickname
                          ? obj.userData?.nickname.toLowerCase().trim()
                          : obj?.userData?.firstname.toLowerCase().trim();
                      })
                      .filter((name, index, array) => // review this to see how it can be simplified
                        array.indexOf(name) === index &&
                          props.errand?.participants?.filter((p) => p?.active && p?.userType === 'Operator')[0]?.userData?.nickname
                          ? name !==
                          props.errand?.participants?.filter((p) => p?.active && p?.userType === 'Operator')[0]?.userData?.nickname?.toLowerCase()?.trim()
                          : name !==
                          props.errand?.participants?.filter((p) => p?.active && p?.userType === 'Operator')[0]?.userData?.firstname?.toLowerCase()?.trim()
                      )
                      .join(', ')
                }
              </StyledName>
            </div>
          </>
        )}
      </>
    ));
  }

  /**
   * helper Fn for useDebounce that properly updates the seached parent prop OR properly sets the message filter.
   * Depends of whether the searchString is empty or not.
   */
  const updateMessageFilter = () => {
    const search = searchString.trim();
    if (search === "") {
      errandContext?.bodyRef?.current?.scrollTo(
        { left: 0, top: errandContext?.bodyRef?.current?.scrollHeight, behavior: 'smooth' }
      );
    }
    errandContext.setMessageFilter(search);
  };

  useDebounce(() => updateMessageFilter(), 350, [searchString]);

  useEffect(() => {
    const titleObserver = new ResizeObserver(
      (entries, _) => {
        for (const entry of entries) {
          if (overflowNames) {
            return;
          }
          let newOverflowNames = false;
          if ((entry.target as HTMLElement).offsetWidth < entry.target.scrollWidth) {
            newOverflowNames = true;
          }
          setOverflowNames((prev) => {
            if (!prev && newOverflowNames) {
              return newOverflowNames
            }
            return prev;
          });
        }
      });
    const current: Element | null = errandContext.titleContainerRef.current;
    if (current) {
      titleObserver.observe(current);
    }
    return () => { 
      if (current) titleObserver.unobserve(current);
      setOverflowNames(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errand?._id, props.errand?.activeContext, userAccessData]);

  useEffect(() => {
    // get user access data when the operator switches between chats
    if (!isUser){
      getUserAccessData();
    }
    if (searchArea.current) {
      handleSearchClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errand?._id]);

  useEffect(() => {
    // using useEffect, since the searchArea ref does not exist
    // until search is opened.
    // So when it does get opened, we'll fire this function.

    if (props.isSearchOpen && searchArea?.current !== null) {
      (searchArea?.current as unknown as HTMLElement).focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSearchOpen]);

  // adds listener to messages socket to handle user name updates
  useEffect(() => {
    if (!isMessagesConnected) return;
    /* Register to receive real time active context updated in this chat */
    const onActiveContextUpdate = (payload) => {
      console.log(`Messages Socket - ConversationTitle - (active-context-update) ${props.errand?._id}`, payload);

      if (payload.data?._id === props.errand._id) {
        props.setErrands((prev) => {
          if (!Array.isArray(prev)) {
            console.warn('setErrands prev is not an array');
            prev = [];
          }
          let index = prev.findIndex((e) => e._id === props.errand._id);
          if (index === -1) return prev;
          prev[index].activeContext = payload.data?.activeContext;
          return [...prev];
        });
      }
    };

    // Register to receive real time authentication activity on this chat
    const onAuthActivityUpdate = async (payload) => {
      if (isUser) return;
      // set a variable to indicate this is what happened.
      console.log(`Messages Socket - ConversationTitle - (auth-activity-update) ${props.errand._id}`, payload);
      getUserAccessData();
    };
  

    // Register to receive real time context updates on this chat
    const onContextStatusUpdate = (payload) => {
      console.log(`Messages Socket - ConversationTitle - (context-status-update) ${props.errand?._id}`, payload);
      let newContext: IContext = payload?.data;

      if (newContext?.chat === props.errand._id) {
        props.setErrands((prev) => {
          if (!Array.isArray(prev)) {
            console.warn('setErrands prev is not an array');
            prev = [];
          }
          let index = prev.findIndex((e) => e._id === props.errand._id);
          if (index !== -1) {
            if (!Array.isArray(prev[index].context)) {
              prev[index].context = [newContext];
              return [...prev];
            }
            // find the first index that has the same context id
            let contextIndex = prev[index].context.findIndex((x) => x?._id === newContext?._id);
            // if the context exits in the array, update the old context
            if (contextIndex !== -1) {
              // get a deep clone of the current state so that we can update the index and have state properly update
              let contexts: IContext[] = Object.values(JSON.parse(JSON.stringify(prev[index].context)));
              // update the context
              contexts[contextIndex] = { ...newContext };
              // remove internal contexts and set the state
              // add the context to the state
              prev[index].context = contexts;
            } else {
              prev[index].context = [...prev[index].context, newContext];
            }
          }
          return [...prev];
        });
      }
    };

    /**
     * Callback function to handle display name update events.
     *
     * @param {Object} payload - The payload object received from the socket event.
     */
    const onDisplayNameUpdates = (payload) => {
      console.log(`Messages Socket - ConversationTitle - (display-name-update) ${props.errand?._id}`, payload);

      // store the new chat status data in a variable
      let chatId = payload?.data?._id;
      let displayName = payload?.data?.displayName;

      // check if chat status exists
      if (!chatId || !displayName) {
        console.error('(display-name-update) invalid payload data', payload);
        return;
      }

      // update conversation list to show the new display name
      props.setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let index = prev.findIndex((c) => c._id === chatId);
        if (index === -1) return prev;
        prev[index].displayName = displayName;
        if (props.operatorData) {
          prev[index].displayString = getOperatorDisplayString(prev[index], props.operatorData);
          prev[index].showInactive = shouldShowInactive(prev[index], props.operatorData);
        }
        return [...prev];
      });
    };
    
    // Find the index of a participant in the array based on parent ID
    const findParticipantIndexByParentId = (participants, payload) => {
      return participants.findIndex(participant =>
          participant.userData?.parentId === payload.data.userData?._id
      );
    };

    // Register to receive real time participant activity on this chat
    const onParticipantActivityUpdate = (payload) => {
      console.log(`Messages Socket - ConversationTitle - (participant-activity-update) ${props.errand?._id}`, payload);
      if (payload.data.chat !== props.errand._id) return;
      // Update the preview if the user has message history now
      getUserAccessData();
    };

    const onUserNameUpdate = (payload) => {
      const data = payload?.data;
      const chatId = data?.chat;
      const userId = data?._id;
      const updatedFields = ValidUserDataFields(data);
      const errandId = props?.errand?._id;
      const operatorData = props?.operatorData;

      // Check if the conversation ID matches the one in props
      if (errandId !== chatId) return;
    
      console.log(`Messages Socket - ConversationTitle - (user-name-update) ${errandId}`, payload);
    
      // Update the state of errands
      props.setErrands((prev) => {   
        // Find the index of the errand in the array
        const chatObj = prev.find((e) => e._id === chatId);

        if (chatObj) {
          // Find the index of the participant whose name is being updated
          const participantObj = chatObj.participants.find((p) => p?.userData?._id === userId);

          if (participantObj) {
            // Update the userData in the participants array
            participantObj.userData = {...participantObj.userData, ...updatedFields};
        
            // Optionally, update displayString and showInactive based on operatorData
            if (operatorData) {
              chatObj.displayString = getOperatorDisplayString(chatObj, operatorData);
              chatObj.showInactive = shouldShowInactive(chatObj, operatorData);
            }
          }    
        }

        // if user-name-update occured on current active user, update it accordingly
        if (_id === userId) {
          setUser((prev) => ({ ...prev, ...updatedFields }));
        }

        // Return a new array to update the state
        return [...prev];
      });
    }

    console.log('Messages Socket - ConversationTitle - (on)');
    messagesSocket.current?.on('active-context-update', onActiveContextUpdate);
    messagesSocket.current?.on('auth-activity-update', onAuthActivityUpdate);
    messagesSocket.current?.on('context-status-update', onContextStatusUpdate);
    messagesSocket.current?.on('display-name-update', onDisplayNameUpdates);
    messagesSocket.current?.on('participant-activity-update', onParticipantActivityUpdate);
    messagesSocket.current?.on('user-name-update', onUserNameUpdate);
    return () => {
      console.log('Messages Socket - ConversationTitle - (off)');
      messagesSocket.current?.off('active-context-update', onActiveContextUpdate);
      messagesSocket.current?.off('auth-activity-update', onAuthActivityUpdate);
      messagesSocket.current?.off('context-status-update', onContextStatusUpdate);
      messagesSocket.current?.off('display-name-update', onDisplayNameUpdates);
      messagesSocket.current?.off('participant-activity-update', onParticipantActivityUpdate);
      messagesSocket.current?.off('user-name-update', onUserNameUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, props.errand?._id]);

  // Helper Functions

  function getHourAtLoc(loc, date = new Date()) {
    try {
      let f = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false, timeZone: loc });
      return Number(f.formatToParts(date)[0].value);
    } catch (e) {
      return 12;
    }
  }

  const getTimeAtLoc = (loc) => {
    try {
      const formatter = new Intl.DateTimeFormat(i18n.resolvedLanguage, {
        timeZone: loc,
        timeZoneName: 'short',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });
      return formatter.format(new Date());
    } catch (e) {
      return null;
    }
  }

  return (
    <StyledTitleContainer isWidget={isWidget} isDesktop={props.isDesktop}>
      <Stack alignItems="center" justifyContent="flex-start" direction="row" spacing={1} overflow={'hidden'} ref={errandContext.titleContainerRef}>
        {/* Render title info icons (Operator / User) */}
        {renderTitleInfoIcons()}
      </Stack>

      {/* Action bar (SMLS btn, Context btn, SearchBar etc.) */}
      {/* For more info peek into the comment of renderActionBar fn*/}
      {renderActionBar()}

      {/* Dialogs */}
      {/* Operator */}
      {props.operatorData && (
        <>
          <>
            {addOperatorDialog && (
              <AddOperatorToConvDialog
                open={addOperatorDialog}
                onClose={() => setAddOperatorDialog(false)}
                setErrands={props.setErrands}
                errand={props.errand}
                operatorData={props.operatorData}
              />
            )}
            {participantsDialog && (
              <ParticipantsDialog
                open={participantsDialog}
                onClose={() => setParticipantsDialog(false)}
                setErrands={props.setErrands}
                errand={props.errand}
                operatorData={props.operatorData}
                onlineUsers={props.onlineUsers}
                onlineOperators={props.onlineOperators}
                userAccessData={userAccessData}
                shieldClickHandler={shieldClickHandler}
              />
            )}
            {consoleDialog && (
              <ConsoleDialog
                open={consoleDialog}
                onClose={() => setConsoleDialog(false)}
                errand={props.errand}
              />
            )}
          </>
          <RenameUserDialog
            userId={userId}
            open={editingParticipant !== null}
            onClose={() => setEditingParticipant(null)}
            errand={props.errand}
            participant={editingParticipant}
            setParticipants={props.setParticipants}
            setErrands={props.setErrands}
            operatorData={props.operatorData}
          />
          <RenameGroupDialog
            errand={props.errand}
            open={renameGroupDialog}
            onClose={() => setRenameGroupDialog(false)}
            operatorData={props.operatorData}
          />
          {/* isFlagged Alert */}
          {props.errand.isFlagged && (
            <Stack
              style={{
                background: 'red',
                color: 'var(--gray000)',
                fontWeight: 'bold',
                padding: '0.5rem 1rem',
                borderBottomLeftRadius: '1rem',
                borderBottomRightRadius: '1rem',
                position: 'absolute',
                top: '3.5rem',
                left: '50%',
                transform: 'translateX(-50%)',
                textAlign: 'center',
                width: 'fit-content',
                zIndex: 5,
              }}>
              {t('suspiciousConversation')}
            </Stack>
          )}
        </>
      )}
      {props.notificationBannerEnabled && props.notificationBannerMessage && (
        <button
          onClick={updateNotificationBanner}
          style={{
            background: 'var(--blue120)',
            color: 'var(--blue999)',
            fontWeight: 'bold',
            padding: '0.5rem 1rem',
            borderBottomLeftRadius: '1rem',
            borderBottomRightRadius: '1rem',
            border: 'none',
            position: 'absolute',
            top: '3.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            width: 'calc(100% - 50px)',
            maxWidth: '570px',
            zIndex: 5,
            display: 'block'
          }}>
          {props.notificationBannerMessage} <CancelIcon sx={{ display: 'inline', fontSize: '1em', transform: 'translateY(2px)' }} />
        </button>
      )}
      {
        showConfirmResendDialog.open && (
          <ConfirmResendWorkflowDialog
            open={showConfirmResendDialog.open}
            onClose={() => {
              setShowConfirmResendDialog({ open: false, chat: undefined, type: undefined, id: undefined, recipients: [], check: true });
            }}
            onResend={async () => {
              // send the workflow privetly to the primary participant
              const recipients = showConfirmResendDialog.recipients?.length > 0 ? showConfirmResendDialog.recipients : [getPrimaryParticipant(props.errand)?.userData?._id];
              setShowConfirmResendDialog(await sendWorkflow(showConfirmResendDialog.id, '', props.errand?._id, recipients, AccessType.public, userId, props.operatorData !== undefined, showConfirmResendDialog.check));
            }}
          />
        )
      }
    </StyledTitleContainer>
  );
};

export default ConversationTitle;