import { styled } from '@mui/system';
import React, { useRef, useState, useEffect, useCallback } from 'react';
// import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useTranslation } from 'react-i18next';
// import { insertField } from '@common/useUpdateField';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import axiosCall from '@services/axios';
import { useMorphContext } from '@contexts/MorphContext';
import { useUserContext } from '@contexts/user';

const StyledContactCircle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hovered' && prop !== 'selected',
})<any>(({ hovered, selected }) => ({
  position: 'relative',
  minWidth: '45px',
  maxWidth: '45px',
  minHeight: '45px',
  backgroundColor: selected ? 'var(--peach900)' : 'var(--gray000)',
  border: hovered || selected ? '3px solid var(--blue030)' : '3px solid var(--peach900)',
  borderRadius: '50%',
  margin: '5px auto',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  fontSize: '1.1rem',
}));

const ContactCircle = ({ contact, hoveredContact, selectedContact }) => {
  const isSelected = selectedContact && contact._id === selectedContact._id;
  const isHovered = contact._id === hoveredContact._id;
  return (
    <StyledContactCircle hovered={isHovered} selected={isSelected}>
      {contact.givenName ? contact.givenName[0] : ''}
      {contact.familyName ? contact.familyName[0] : ''}
    </StyledContactCircle>
  );
};

const MorphContact = ({ filterName, setText }) => {
  const { t } = useTranslation();
  const CONTACTS_LIMIT = 15;
  const CONTACTS_ORDER_BY = 'displayName';

  const { _id } = useUserContext();
  const { contextSetSelectedContact, canSendText, sendButtonRef } = useMorphContext();

  const [contacts, setContacts] = useState([]);
  const [hoveredContact, setHoveredContact] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [hasMoreContacts, setHasMoreContacts] = useState(null);

  const contactsOffsetRef = useRef(0);

  // 64909b1bb187c902ecf0f615 <-- userId for testing, replace _id
  const getContactsList = useCallback(
    async (offset) => {
      const data = {
        userId: _id,
        filterName: filterName,
      };

      let request = {
        url: `contact/search?offset=${offset}&limit=${CONTACTS_LIMIT}&orderBy=${CONTACTS_ORDER_BY}`,
        method: 'POST',
        data: data,
      };
      try {
        const fetchedContacts = await axiosCall(request);
        return fetchedContacts;
      } catch (error) {
        console.log(error);
      }
    },
    [filterName]
  );

  const handleReachEnd = async (swiper) => {
    if (!contacts.length) return;
    if (hasMoreContacts === false) {
      console.log(`No more contacts to load`);
      return;
    }
    console.log(`Reached end`);
    // load more
    contactsOffsetRef.current += CONTACTS_LIMIT;
    const fetchedContacts = await getContactsList(contactsOffsetRef.current);
    handleFetchedContacts(fetchedContacts);
  };

  const handleHoverContact = (swiper) => {
    const hoveredIndex = swiper.activeIndex;
    const hoveredContact = contacts[hoveredIndex];
    setHoveredContact(hoveredContact);
    setText(hoveredContact?.displayName || '');
  };

  const handleSlideClick = (index: number) => {
    const clickedContact = contacts[index];
    setSelectedContact(clickedContact);
    contextSetSelectedContact(clickedContact);
    sendButtonRef.current.update(clickedContact.displayName);
  };

  const handleFetchedContacts = (fetchedContacts) => {
    // if less than 15 contacts were fetched, we've reached end of list
    if (fetchedContacts.length < 15) {
      setHasMoreContacts(false);
    }
    if (fetchedContacts.length > 0) {
      setHasMoreContacts(true);
      setHoveredContact((prev) => {
        setText(`${prev?.displayName || '&nbsp;'}`);
        return prev;
      });
      setContacts((prevContacts) => [...prevContacts, ...fetchedContacts]);
      console.log('Contacts fetched');
    }
  };

  useEffect(() => {
    const initFetch = async () => {
      const fetchedContacts = await getContactsList(contactsOffsetRef.current);
      setHoveredContact(fetchedContacts[0]);
      if (fetchedContacts.length === 1) {
        const onlyContact = fetchedContacts[0];
        setSelectedContact(onlyContact);
        contextSetSelectedContact(onlyContact);
        sendButtonRef.current.update(onlyContact.displayName);
      } else if (fetchedContacts.length === 0) {
        setText(canSendText.current ? t('sendInvite') : t('noResults'));
      }
      handleFetchedContacts(fetchedContacts);
    };

    setContacts([]);
    setSelectedContact(null);
    contextSetSelectedContact(null);
    sendButtonRef.current.update('');
    contactsOffsetRef.current = 0;
    initFetch();

    const containsOnlyNonAlphabetical = /^[^A-Za-z]+$/i.test(filterName);
    const hasExactlyTenDigits = /^\d{10}$/.test(filterName.replace(/\D/g, ''));
    if (containsOnlyNonAlphabetical && hasExactlyTenDigits) {
      canSendText.current = true;
      sendButtonRef.current.update('1');
    } else {
      canSendText.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName]);

  return (
    <>
      {contacts && hoveredContact && (
        <Swiper
          centeredSlides={true}
          slidesPerView={7}
          modules={[FreeMode]}
          onReachEnd={handleReachEnd}
          onInit={handleHoverContact}
          onSlideChange={handleHoverContact}
          slideToClickedSlide={true}
          freeMode={{
            enabled: true,
            sticky: true,
            momentumBounce: false,
            momentumRatio: 0.25,
          }}
          style={{
            display: 'flex',
            width: '100%',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            overflowY: 'hidden',
            scrollbarWidth: 'none', //hide scrollbar for Firefox
            msOverflowStyle: 'none', // hide scrollbar for IE and Edge
          }}
        >
          {contacts.map((contact, index) => (
            <SwiperSlide key={index} onClick={() => handleSlideClick(index)}>
              <ContactCircle contact={contact} hoveredContact={hoveredContact} selectedContact={selectedContact} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
};

export default MorphContact;
