import { IMessage } from "@interfaces/Conversation";
import { ValidatorFunctions } from "./Validators";

const copyObj = (obj) => {
  if(objHasFunction(obj)) {
    console.error('Not able to copy given object, because it has a function inside.')
  } else {
    return JSON.parse(JSON.stringify(obj));
  }
}

function objHasFunction(obj) {
  if (typeof obj === 'function') {
    return true;
  }

  if (typeof obj === 'object' && obj !== null) {
    for (let key in obj) {
      if (objHasFunction(obj[key])) {
        return true;
      }
    }
  }

  return false;
}

const filters = {
  isSenderUser: (msg) =>  msg.senderType === 'User'
}

/**
 * Determine whether the conversation footer should change for the current user.
 * Only users that are part of the intendedAudience should respond to actions.
 * 
 * @param message the message object
 * @returns {boolean}
 */
const shouldCurrUserReplyTo = (message: IMessage, _id: string): boolean => {
  try {
    if (!message || !message.userId) return false;
    if (message.operatorView 
      || (message.messageType !== 'Action' 
        && message.messageType !== 'UserPromptsMenu' 
        && message.messageType !== 'VideoListMenu')) 
    {
          return false;
    }
    // check the userAction owner to be equal to current user id.
    if (message.userAction.owner === _id) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error(`userMessagesUtils.shouldCurrentUserReplyTo.catch: `, error?.stack || error?.message);
    return false;
  }
}


const isMessageOfTypeAction = (msg) => {
  if(msg.messageType === "Action") {
    return true;
  } 

  return false;
}

const getLastMsg = (chatMessages) => {
  return chatMessages[chatMessages.length - 1];
}

const isUserActionLastInChat = (userActionId, chatMessages) => {
  const lastMsgUserActionId = getLastMsg(chatMessages)?.userAction?._id;

  if(lastMsgUserActionId === undefined || lastMsgUserActionId === null) {
    return false;
  }

  if(lastMsgUserActionId === userActionId) {
    return true;
  }

  return false;
}

const isMessageOfType = (msg: IMessage, msgType: string) => {
  if(msg.messageType === msgType) {
    return true;
  }

  return false;
}

const strRelatedToSlotMachine = (str: string) => {
  if(!str || str === '' || typeof str !== 'string') {
    return false;
  }
  return str.toLowerCase().includes('slot machine')
}

const isSlotMachineRelatedAction = (action) => {
  if(action && action?.description && action?.description !== '' && ValidatorFunctions.isTypeOfString(action?.description)) {
    if(strRelatedToSlotMachine(action.description)) {
      return true;
    }
  }
  return false;
}

const isSlotMachineRelatedMsg = (msg: IMessage) => {
  return isSlotMachineRelatedAction(msg?.action) && strRelatedToSlotMachine(msg?.placeholder);
}

const findSlotMachineActionMessageIn = (msgArr: IMessage[]): IMessage => {
  return msgArr.find((msg) => isMessageOfType(msg, 'Action') && isSlotMachineRelatedMsg(msg));
}

const findMessageOfTypeWithin = (msgArr: IMessage[], msgType: string) => {
  return msgArr.find((msg) => isMessageOfType(msg, msgType));
}

const findMessageOfTypeOrder = (msgArr: IMessage[]) => {
  return findMessageOfTypeWithin(msgArr, 'Order');
}

const findMessageOfTypePropertyListing = (msgArr: IMessage[]) => {
  return findMessageOfTypeWithin(msgArr, 'PropertyListing');
}

const MINUTE_TO_MS = 60000;
const DAY_TO_MS = 86400000;

/**
 * Checks if 2 timestamps are on same date based on current timezone
 * @param first 
 * @param second 
 * @returns 
 */
const datesAreOnSameDay = (first, second) => {
  try {
    // get current timezone different to UTC time (we will offset this to server time because server uses UTC timestamp)
    const currentTime = new Date();
    const timezoneDiffByMin = currentTime.getTimezoneOffset();

    // convert it to Date obj
    first = new Date(first);
    second = new Date(second);

    // conver inputs to millieconds epoch to 
    const firstEpochToCurrentTimezone = first.getTime() - timezoneDiffByMin * MINUTE_TO_MS;
    const secondEpochToCurrentTimezone = second.getTime() - timezoneDiffByMin * MINUTE_TO_MS;

    // convert inputs to whole day
    const firstByDay = Math.floor(firstEpochToCurrentTimezone / DAY_TO_MS);
    const secondByDay = Math.floor(secondEpochToCurrentTimezone / DAY_TO_MS);

    return firstByDay === secondByDay;
  } catch (err) {
    console.error('userMessagesUtils.ts: datesAreOnSameDay', err.message);
    return false;
  }
};

/**
 * Converts the `createdAt` timestamp of an `IMessage` object to a whole-day epoch value
 * that accounts for timezone offset. This function effectively normalizes the date to the 
 * start of the day in UTC time, ensuring consistent day-based comparisons across time zones.
 *
 * @param {IMessage} obj - The message object containing a `createdAt` timestamp.
 * @returns {number} - The whole-day epoch value in UTC, representing the day of `createdAt`.
 */
const CreatedAtToWholeDay = (obj: IMessage): number => {
  // get createdAt field
  const createdAt = obj.createdAt;

  // current time
  const currentTime = new Date();

  // calculate timezone difference by minutes from UTC
  const timezoneDiffByMin = currentTime.getTimezoneOffset();

  // calculate stamp time
  const stampTime = new Date(createdAt);

  // convert stamp time to local time
  const stampLocalTime = stampTime.getTime() - timezoneDiffByMin * MINUTE_TO_MS;

  // convert stamp time to whole day since UTC start
  const stampDay = Math.floor(stampLocalTime / DAY_TO_MS);

  return stampDay;
}

/**
 * Converts the current date and time to a whole-day epoch value in UTC, adjusted for timezone offset.
 * This function normalizes the current time to the start of the day in UTC, allowing for consistent
 * day-based comparisons across time zones.
 *
 * @returns {number} - The whole-day epoch value in UTC, representing the current day.
 */
const CurrentTimeToWholeDay = (): number => {
  // current time
  const currentTime = new Date();

  // calculate timezone difference by minutes from UTC
  const timezoneDiffByMin = currentTime.getTimezoneOffset();

  // calculate current local time
  const currentLocalTime = currentTime.getTime() - timezoneDiffByMin * MINUTE_TO_MS;

  // convert current time to whole day since UTC start
  const currentDay = Math.floor(currentLocalTime / DAY_TO_MS);

  return currentDay;
}

export {
  shouldCurrUserReplyTo,
  copyObj,
  isMessageOfTypeAction,
  getLastMsg,
  isUserActionLastInChat,
  findMessageOfTypeOrder,
  findMessageOfTypePropertyListing,
  findSlotMachineActionMessageIn,
  isSlotMachineRelatedAction,
  datesAreOnSameDay,
  CreatedAtToWholeDay,
  CurrentTimeToWholeDay,
};