import { Card, CardContent, Divider, Grid, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { compareAsc, differenceInDays, differenceInHours, differenceInMinutes, format } from 'date-fns';
import { enUS, es, ko } from 'date-fns/locale';
import React, { useEffect, useState, Fragment } from 'react';

import AttendDialog from './AttendDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { NoData as NoDataIcon } from '../Assets/Icons';
import ScrollBox from './ScrollBox';
import UserBadge from './UserBadge';
import axiosCall from '../Services/axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserBadgeSkeleton from './UserBadgeSkeleton';
import { ChatType } from '@common/ChatType';
import { useUserContext } from '@contexts/user';

/*
 *  This component is the list of user badges seen three times across the center
 * of the console dashboard screen. For more see the Figma:
 * https://www.figma.com/file/5HrQkz2QKGTb97ZvTsqfOC/Dashbord?node-id=613%3A71
 *
 *  This component uses the following properties:
 *    - title: This is the title of the list above the divider
 *    - users: This is the array of users to be displayed in this list. A sample
 *      list is provided in /src/Assets/testusers.json.
 *
 */

// TODO: Enforce strict typing
const UserBadgeList = (props: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { _id, accessLevel } = useUserContext();
  const [users, setUsers] = useState([]);
  const [menu, setMenu] = useState(null);
  const [dialog, setDialog] = useState('');
  const [locale, setLocale] = useState<Locale>(enUS);

  const onAttendClick = async (user) => {
    let attend = await axiosCall({
      url: `chat/${user.chatId}/attend`,
      method: 'post',
      data: {
        userId: _id,
        userType: 'Operator',
        chat: user.chatId,
      },
    });
    if (attend?.attend === true) {
      navigate('/console', {
        state: {
          module: 'conversations',
          tab: 'mine',
          chat: user.chatId,
        },
      });
    } else {
      // There are other operators so just open the dialog explaining this
      setDialog(user.chatId);
    }
  };

  useEffect(() => {
    const mapToBadges = async () => {
      if (props.data?.length > 0) {
        // let sorted = props.data.sort((a, b) =>
        //   compareAsc(new Date(a.updatedAt), new Date(b.updatedAt))
        // );

        let locale = enUS;
        if (i18n.resolvedLanguage === 'es') {
          locale = es;
        } else if (i18n.resolvedLanguage === 'ko') {
          locale = ko;
        }
        setLocale(locale);

        let formatted = props.data.map((conversation: any) => {
          let now = Date.now();
          let minutes = differenceInMinutes(now, new Date(conversation.updatedAt));
          let hours = differenceInHours(now, new Date(conversation.updatedAt));
          let days = differenceInDays(now, new Date(conversation.updatedAt));
          let formattedTime = `${days > 0 ? days + t('userBadgeWaitDayAbbreviation') : ''} ${
            hours > 0 ? hours - days * 24 + t('userBadgeWaitHourAbbreviation') : ''
          } ${minutes > 1 ? minutes - hours * 60 + t('userBadgeWaitMinuteAbbreviation') : ''}`;

          let primaryUsers = conversation.participants?.filter((p) => p.primary === true);
          let mainUser = primaryUsers.length > 0 ? primaryUsers[0] : conversation?.participants[0];
          let name = mainUser && (mainUser?.userData?.firstname || mainUser?.userData?.lastname)
            ? `${mainUser?.userData?.firstname || ''} ${mainUser?.userData?.lastname || ''}`
            : t('conversationListPlaceholderName');
          name = name.replace(
            /\w\S*/g,
            (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
          );

          let timeString = '';
          try {
            timeString = format(new Date(conversation.updatedAt), 'p', {
              locale: locale as Locale,
            });
          } catch (e) {
            console.error('Error while calculating UserBadgeList timeString:', e);
            timeString = format(new Date(), 'p', { locale: locale as Locale });
          }

          let ret = {
            name:
              conversation.type === ChatType.Errand
                ? conversation.displayName
                  ? `${name} (${conversation.displayName})`
                  : name
                : name,
            img: '',
            date: conversation.updatedAt,
            waitTime:
              formattedTime.length > 2
                ? formattedTime
                : '< 1' + t('userBadgeWaitMinuteAbbreviation'),
            time: timeString, // Not surrounded in a try catch because the '|| null' would over take any invalid time
            chatId: conversation?._id,
            chatStatus: conversation.status,
          };

          return ret;
        });

        setUsers(formatted);
      } else {
        // no users with unattended chats or the list got cleared.
        setUsers([]);
      }
    };

    mapToBadges();
    // eslint-disable-next-line
  }, [props.data, i18n.resolvedLanguage]);

  return (
    <Card>
      <CardContent>
        <Stack>
          {/* title bar */}
          <Grid container>
            <Grid item xs={11}>
              <Typography sx={{ alignSelf: 'left', fontSize: 16, fontWeight: 600 }}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {accessLevel === 'Admin' && (
                <>
                  <IconButton
                    onClick={(e) => {
                      if (accessLevel === 'Admin') {
                        setMenu(e.currentTarget);
                      }
                    }}>
                    <MoreVertIcon color='primary' />
                  </IconButton>
                  <Menu
                    open={Boolean(menu)}
                    onClose={() => {
                      setMenu(null);
                    }}
                    anchorEl={menu}>
                    <MenuItem
                      onClick={() => {
                        props.onClearClick();
                        setMenu(null);
                      }}>
                      {t('userBadgeListClearButton')}
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Grid>
          </Grid>

          <Divider />

          <ScrollBox
            // onLoadMore={() => {
            //   /* Does this need to happen? */
            // }}
            sx={{
              height: 'calc(100vh - 432px)',
            }}
            static={true}>
            {/* If data is still the default of null, display skeleton loader*/}
            {props.data === null ? (
              <UserBadgeSkeleton />
            ) : //Otherwise, check if there is data. If so, display it
            props.data?.length > 0 ? (
              users
                .sort((a, b) => compareAsc(new Date(a.date), new Date(b.date)))
                .map((user, index) => (
                  <Fragment key={index}>
                    <UserBadge
                      accessLevel={accessLevel}
                      alt={user.name}
                      img={user.img}
                      name={user.name}
                      date={format(new Date(user.date || null), 'iii, LLL do, Y', {
                        locale: locale,
                      })}
                      waitTime={user.waitTime}
                      time={user.time}
                      onClick={() => {
                        onAttendClick(user);
                      }}
                    />
                    <Divider />
                  </Fragment>
                ))
            ) : (
              //If there is no data, display "No Data"
              <Stack sx={{ minHeight: '100%' }} justifyContent='center' alignItems='center'>
                <NoDataIcon />
                <Typography fontWeight='bold' fontSize={20} mt={2}>
                  {t('userBadgeListNoData')}
                </Typography>
              </Stack>
            )}
          </ScrollBox>
        </Stack>
      </CardContent>

      <AttendDialog attendedChat={dialog} setAttendedChat={setDialog} />
    </Card>
  );
};

export default UserBadgeList;
