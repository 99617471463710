import { ElementsIDs } from '../interfaces';

export const DEFAULT_ELEMENTS: ElementsIDs = {
  Consent: 'Consent',
  Action: 'Action',
};

export const MAIN_ELEMENTS: ElementsIDs = {
  ...DEFAULT_ELEMENTS,
  Auth: 'Auth',
  Context: 'Context',
  PrivateMode: 'PrivateMode',
  EditingMode: 'EditingMode',
  Order: 'Order',
  SlotMachine: 'SlotMachine',
  Live: 'Live',
  PropertyListing: 'PropertyListing',
  Esign: 'Esign',
};

export const WIDGET_ELEMENTS: ElementsIDs = {
  ...DEFAULT_ELEMENTS,
  ExpandFullMode: 'ExpandFullMode',
};

export const ALL_ELEMENTS = {
  ...MAIN_ELEMENTS,
  ...WIDGET_ELEMENTS,
};
