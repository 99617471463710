import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { register } from '../serviceWorkerRegistration';

/**
 * This component is responsible for showing the update popup when 
 * a change occurs in the codebase. 
 * @returns {React.FC} The ServiceWorkerWrapper component.
 */
interface Props { 
  setShowReloadButton: Dispatch<SetStateAction<boolean>>; 
  showReloadButton: boolean;
};

const ServiceWorkerWrapper = React.forwardRef((props: Props, ref) => {
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

  /**
   * Callback function called when a service worker update is available.
   * @param {ServiceWorkerRegistration} registration - The service worker registration.
   */
  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    console.log("SW Update triggered");
    // Set the state to show the reload notification
    props.setShowReloadButton(true);
    // Store the waiting service worker for update
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    // Register the service worker updates 
    if (process.env.REACT_APP_SERVICE_WORKER_ENABLED === 'true') {
      console.log("SW Update registered");
      register({ onUpdate: onSWUpdate });
    }
  }, []); // Empty dependency array to ensure only run on initial render

  /**
   * Reloads the page and skips waiting for the new service worker.
   */
  const reloadPage = () => {
    // Send a message to the waiting service worker to skip waiting and activate a new SW immediately
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    // Hide the reload notification after they click 'Reload'
    props.setShowReloadButton(false);
    // Reload the page to activate the new service worker
    window.location.reload();
  };

  // Assign the handler to the ref
  React.useImperativeHandle(ref, () => ({
    reloadPage,
  }));

  return (
    <>
    </>
  );
})

export default ServiceWorkerWrapper;
