import React from 'react';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import BannerStyles from '@styles/Banner.module.css';
import PullCreditMenuStyles from '@styles/PullCreditMenu.module.css';

interface Props {
  togglePullCreditMenu: React.MouseEventHandler<HTMLButtonElement>;
}

/**
 * Banner sub component. Used to make things cleaner.
 */
export default function Banner(props: Props) {
  const { t } = useTranslation();

  return (
    <div className={BannerStyles.banner}>
      <h1 className={PullCreditMenuStyles.h1}>{t(`pullCredit`)}</h1>
      <button className={BannerStyles.closeButton} onClick={props.togglePullCreditMenu}>
        <Close />
      </button>
    </div>
  );
}
