// Auto-generated constants file
const WORKFLOW_NAMES = {
    "USER_PROMPTS": "User Prompts",
    "APPRAISAL_CALCULATOR": "Appraisal Calculator",
    "FIXED_RATE_CALCULATOR": "Fixed Rate Calculator",
    "AFFORDABILITY_CALCULATOR": "Affordability Calculator",
    "RENT_VS_BUY_CALCULATOR": "Rent Vs Buy Calculator",
    "LOAN_OFFICER_INFO_POPULATE_BASED_ON_EMAIL": "Loan Officer Info Populate Based On Email",
    "LOAN_OFFICER_INFO_POPULATE": "Loan Officer Info Populate",
    "REALTOR_INFO_POPULATE_BASED_ON_EMAIL": "Realtor Info Populate Based On Email",
    "REALTOR_INFO_POPULATE": "Realtor Info Populate",
    "PROPERTY_INFO_POPULATE": "Property Info Populate",
    "PROPERTY_LISTING_INFO_POPULATE": "Property Listing Info Populate",
    "REGISTER_LEAD": "Register Lead",
    "CALL_LEAD_PROSPECTS": "Call Lead Prospects",
    "INFLUENCER_WORKFLOW": "Influencer Workflow",
    "PAY_FOR_APPRAISAL": "Pay For Appraisal",
    "CREATE_PROPERTY_LISTING": "Create Property Listing",
    "BROKER_LANDING_PAGE": "Broker Landing Page",
    "CREATE_ACCOUNT": "Create Account",
    "AUTHENTICATE_ACCOUNT": "Authenticate Account",
    "AUTHENTICATE": "Authenticate",
    "INVITE_FRIEND": "Invite Friend",
    "CUSTOM_LINK": "Custom Link",
    "CUSTOM_LINK_FOR_SELF": "Custom Link For Self",
    "CUSTOM_LINK_FOR_OTHER": "Custom Link For Other",
    "SHARE_CUSTOM_LINK": "Share Custom Link",
    "SHARE_CUSTOM_LINK_WITH_EMAIL": "Share Custom Link With Email",
    "SHARE_CUSTOM_LINK_WITH_SMS": "Share Custom Link With SMS",
    "WORKSHOP_REGISTRATION": "Workshop Registration",
    "RESCHEDULE_WORKSHOP": "Reschedule Workshop",
    "CREDIT_PULL": "Credit Pull",
    "CE_WORKSHOP_WORKFLOW": "CE Workshop Workflow",
    "SHOW_SENTIMENT": "Show Sentiment",
    "ALLOW_HISTORY": "Allow History",
    "UNBLUR_HISTORY": "Unblur History",
    "APPROVE_UNBLUR_HISTORY": "Approve Unblur History",
    "SONG_OF_THE_DAY": "Song of the Day",
    "ORDER_T-SHIRT": "Order T-Shirt",
    "GET_LOAN_CONDITIONS": "Get Loan Conditions",
    "ZESTIMATE": "Zestimate",
    "RESET_PASSWORD": "Reset Password",
    "REFINANCE_CALCULATOR": "Refinance Calculator",
    "TCPA_CONSENT": "TCPA Consent",
    "KNOW_YOUR_RATE": "Know Your Rate",
    "LOCK_THE_LOAN": "Lock the Loan",
    "SEND_UPLOAD_LINK": "Send Upload Link",
    "IMAGING_UPLOAD": "Imaging Upload",
    "INVITE_CONTACT": "Invite Contact",
    "ASSOCIATE_LOAN_TO_DOCUMENT": "Associate Loan To Document",
    "WELCOME_BACK": "Welcome Back",
    "WELCOME_REFEREE": "Welcome Referee",
    "WELCOME_USER": "Welcome User",
    "SHOW_DISCLAIMER": "Show Disclaimer",
    "WELCOME_CALCULATORS": "Welcome Calculators",
    "WELCOME_LOAN_APPLICATION": "Welcome Loan Application",
    "WELCOME_WORKSHOP_REGISTRATION": "Welcome Workshop Registration",
    "LOAN_APPLICATION": "Loan Application",
    "GETLOANAPPLICATIONBASICINFORMATION": "GetLoanApplicationBasicInformation",
    "GETLOANAPPLICATIONADDRESSINFORMATION": "GetLoanApplicationAddressInformation",
    "GETLOANAPPLICATIONEMPLOYMENTINFORMATION": "GetLoanApplicationEmploymentInformation",
    "GETLOANAPPLICATIONVAINFORMATION": "GetLoanApplicationVAInformation",
    "GETLOANAPPLICATIONLOANPURPOSEINFORMATION": "GetLoanApplicationLoanPurposeInformation",
    "GETLOANAPPLICATIONOTHERPROPERTIESINFORMATION": "GetLoanApplicationOtherPropertiesInformation",
    "GETLOANAPPLICATIONLOANDOCUMENTS": "GetLoanApplicationLoanDocuments",
    "INVITE_REFERRER": "Invite Referrer",
    "CE40_WORKSHOP_WORKFLOW": "CE40 Workshop Workflow",
    "CREATE_SLOT_MACHINE_ORDER": "Create Slot Machine Order",
    "VIDEO_LIST_MENU": "Video List Menu",
    "CREDIT_REPAIR": "Credit Repair",
    "CREDIT_BOOST": "Credit Boost",
    "CREDIT_REPAIR_CREDIT_PULL": "Credit Repair Credit Pull",
    "WELCOME_CREDIT_REPAIR": "Welcome Credit Repair",
    "WELCOME_CREDIT_BOOST": "Welcome Credit Boost",
    "LINK_EXPIRED": "Link Expired",
    "RESEND_OTP": "Resend OTP",
    "CANCEL_CREDIT_REPAIR_SERVICE": "Cancel Credit Repair Service",
    "SUBSCRIBE_EMAIL_NOTIFICATIONS": "Subscribe Email Notifications",
    "END_WORKFLOW": "End Workflow",
    "DOWNLOAD_THE_APP": "Download the App",
    "EMIT_SHOW_DOWNLOAD_APP_BANNER": "Emit Show Download App Banner",
    "PAVAN_INSTAGRAM_GIVEAWAY": "Pavan Instagram Giveaway",
    "SHOW_PROPERTY_LISTING_LINK": "Show Property Listing Link",
    "GETINTERVIEWERUID": "GetInterviewerUid",
    "OPEN_USER_WALLET": "Open User Wallet",
    "MINT_TRU_APPROVAL_NFT": "Mint Tru Approval NFT",
    "WELCOME_REFINANCE_CALCULATOR": "Welcome Refinance Calculator",
    "MANAGE_TICKETS": "Manage Tickets",
    "CLAIM_EVENT_TICKET": "Claim Event Ticket",
    "CLAIM_EVENT_TICKET_PLUS_ONE": "Claim Event Ticket Plus One",
    "TRANSFER_EVENT_NFT": "Transfer Event NFT",
    "WELCOME_RELAX_TSHIRT": "Welcome Relax TShirt",
    "RUSSELL_PETERS_NO_PAYMENT": "Russell Peters No Payment",
    "DIWALI": "Diwali",
    "WELCOME_RELAX_WIN": "Welcome Relax Win",
    "WELCOME_RELAX": "Welcome Relax",
    "RUSSELL_PETERS_NFT_TRANSFER": "Russell Peters NFT Transfer"
  } as const;
  
  export { WORKFLOW_NAMES };
  