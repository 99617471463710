import { useSwipeable } from 'react-swipeable';
import { styled } from '@mui/system';
import { Typography, IconButton, MorganTheme, useTheme } from '@mui/material';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { IFormContext, FormContext } from '@contexts/FormContext';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';
import {
  FormBottomButtonType,
  ElectronicSignatureEventType,
  FormBodySizeType,
  FormBodyType,
} from '../Forms/commonForms';
import { DownArrow, FormPhoneFlip2 } from '@assets/Icons';

const StylizedTicks = styled(Typography)(
  () => `
  position: relative;
  &::after {
    position: absolute;
    display: block;
    background: var(--orange700);
    width: 2px;
    height: 6px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
  }
`
);

const bottomButton = {
  size: 40,
  padding: 5,
  offset: -25,
};

const ESignSliderElement = (props) => {
  const { divWidthOffset, divHeightOffset, handleCustomScroll } = props;

  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const formContext = useContext<IFormContext>(FormContext);

  const esignPageNumberRef = useRef(null);

  const [totalTicks, setTotalTicks] = useState<number>(null);
  const [activeTick, setActiveTick] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const theme: MorganTheme = useTheme();

  // React-Swipeable setup for moving the slider
  const handlers: any = useSwipeable({
    onSwipedLeft: () => {
      const divWidth = formContext.documentReaderRef.current?.getBoundingClientRect().width - divWidthOffset;
      const divHeight = formContext.documentReaderRef.current?.getBoundingClientRect().height - divHeightOffset;

      setActiveTick((prevState) => {
        if (prevState < totalTicks - 1) {
          return prevState + 1;
        } else {
          return totalTicks - 1;
        }
      });
      esignPageNumberRef.current.scrollTo({
        top: 0,
        left: (divWidth * activeTick) / 5,
        behavior: 'smooth',
      });
      handleCustomScroll(activeTick * divHeight);
    },
    onSwipedRight: () => {
      const divWidth = formContext.documentReaderRef.current?.getBoundingClientRect().width - divWidthOffset;
      const divHeight = formContext.documentReaderRef.current?.getBoundingClientRect().height - divHeightOffset;

      setActiveTick((prevState) => {
        if (prevState > 0) {
          return prevState - 1;
        } else {
          return 0;
        }
      });
      esignPageNumberRef.current.scrollTo({
        top: 0,
        left: (divWidth * activeTick) / 5,
        behavior: 'smooth',
      });
      handleCustomScroll(activeTick * divHeight);
    },
    trackMouse: true,
  });

  // controller for click/tapping page numbers
  const handleTickScrollClick = (tick) => {
    const divWidth = formContext.documentReaderRef.current?.getBoundingClientRect().width - divWidthOffset;

    esignPageNumberRef.current?.scrollTo({ top: 0, left: (divWidth * tick) / 5, behavior: 'smooth' });
    setActiveTick(tick);
  };

  const closeCreateSignatureMobile = () => {
    errandContext.newFormEvent(ElectronicSignatureEventType.CloseCreateSignatureMobile);
    rootContext.setCreateSignatureMobileIsOn(false);
  };

  const ButtonTypeMap = () => {
    if (errandContext.formBottomButton === FormBottomButtonType.None) {
      return null;
    } else if (errandContext.formBottomButton === FormBottomButtonType.ArrowUp) {
      return (
        <IconButton
          sx={{
            maxHeight: bottomButton.size + 2 * bottomButton.padding,
            minHeight: bottomButton.size + 2 * bottomButton.padding,
            maxWidth: bottomButton.size + 2 * bottomButton.padding,
            minWidth: bottomButton.size + 2 * bottomButton.padding,
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            zIndex: '1',
            bottom: `${bottomButton.offset - bottomButton.padding}px`,
          }}
          onClick={() => errandContext.newFormEvent(ElectronicSignatureEventType.ArrowUp)}
        >
          <DownArrow
            style={{
              transform: 'rotate(180deg)',
              minHeight: bottomButton.size,
              maxHeight: bottomButton.size,
              minWidth: bottomButton.size,
              maxWidth: bottomButton.size,
            }}
          />
        </IconButton>
      );
    } else if (errandContext.formBottomButton === FormBottomButtonType.ArrowDown) {
      return (
        <IconButton
          sx={{
            maxHeight: bottomButton.size + 2 * bottomButton.padding,
            minHeight: bottomButton.size + 2 * bottomButton.padding,
            maxWidth: bottomButton.size + 2 * bottomButton.padding,
            minWidth: bottomButton.size + 2 * bottomButton.padding,
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            zIndex: '1',
            bottom: `${bottomButton.offset - bottomButton.padding}px`,
          }}
          onClick={() => errandContext.newFormEvent(ElectronicSignatureEventType.ArrowDown)}
        >
          <DownArrow
            style={{
              minHeight: bottomButton.size,
              maxHeight: bottomButton.size,
              minWidth: bottomButton.size,
              maxWidth: bottomButton.size,
            }}
          />
        </IconButton>
      );
    } else if (errandContext.formBottomButton === FormBottomButtonType.Rotate) {
      return (
        <IconButton
          sx={{
            maxHeight: bottomButton.size + 2 * bottomButton.padding,
            minHeight: bottomButton.size + 2 * bottomButton.padding,
            maxWidth: bottomButton.size + 2 * bottomButton.padding,
            minWidth: bottomButton.size + 2 * bottomButton.padding,
            position: 'absolute',
            transform: 'translateX(-50%)',
            left: '50%',
            zIndex: '1',
            bottom: `${bottomButton.offset - bottomButton.padding}px`,
          }}
          onClick={closeCreateSignatureMobile}
        >
          <FormPhoneFlip2
            style={{
              minHeight: bottomButton.size,
              maxHeight: bottomButton.size,
              minWidth: bottomButton.size,
              maxWidth: bottomButton.size,
            }}
          />
        </IconButton>
      );
    } else if (errandContext.formBottomButton === FormBottomButtonType.Scroller) {
      return (
        <svg
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: `${bottomButton.offset}px`,
            zIndex: '1',
          }}
          width={bottomButton.size.toString()}
          height={bottomButton.size.toString()}
          viewBox="0 0 45 45"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...handlers}
        >
          <rect x="0.5" y="0.5" width="44" height="44" rx="22" fill="var(--orange050)" />

          {/* Can wrap the bottom 3 in a css class and run transform: translateX(?) */}
          {/* To give the illusion of it scrubbing as you're scrolling */}

          <rect x="14.5" y="12.5" width="2" height="20" fill="var(--gray040)" />
          <rect x="21.5" y="12.5" width="2" height="20" fill="var(--gray040)" />
          <rect x="28.5" y="12.5" width="2" height="20" fill="var(--gray040)" />

          <rect x="0.5" y="0.5" width="44" height="44" rx="22" stroke="var(--orange700)" />
        </svg>
      );
    } else {
      console.error(`wrong FormBottomButtonType ${errandContext.formBottomButton}`);
    }
  };

  useEffect(() => {
    const divHeight = formContext.documentReaderRef.current?.getBoundingClientRect().height - divHeightOffset;
    const docHeight = formContext.documentRef.current?.children[0]?.getBoundingClientRect().height;

    const numTicks = Math.floor(docHeight / divHeight);
    if (!isNaN(numTicks)) {
      if (numTicks < 0) {
        setTotalTicks(1);
      } else {
        setTotalTicks(numTicks + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandContext.formBody]);

  useEffect(() => {
    const divHeight = formContext.documentReaderRef.current?.getBoundingClientRect().height - divHeightOffset;
    const docHeight = formContext.documentRef.current?.children[0]?.getBoundingClientRect().height;

    let numTicks = Math.floor(docHeight / divHeight);

    if (!isNaN(numTicks)) {
      if (numTicks < 0) {
        numTicks = 1;
      } else {
        numTicks += 1;
      }
      setTotalTicks(numTicks);
    }

    let pageNumber = Math.round(formContext.scrollPosition / divHeight);

    // last page won't fit in 1 whole pagination
    if (formContext.scrollPosition > docHeight - divHeight) {
      pageNumber = Math.ceil(formContext.scrollPosition / divHeight);
    }

    handleTickScrollClick(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.scrollPosition, totalTicks]);

  useEffect(() => {
    if (errandContext.formBodySize === FormBodySizeType.Small) {
      setIsVisible(false);
    } else {
      setTimeout(() => {
        // After 500ms, set the component to be visible
        setIsVisible(true);
      }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errandContext.formBodySize]);

  return (
    <>
      {isVisible && (
        <div
          ref={esignPageNumberRef}
          style={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'start',
            flexFlow: 'row nowrap',
            overflowX: 'hidden',
            marginTop: '5px',
            padding: '0 40% 0 40%',
          }}
        >
          {(props.formName?.startsWith('TEMPLATEMGAI') && errandContext.formTotalPages)? (<StylizedTicks
            style={{
              width: '100%',
              minWidth: '100%',
              height: '30px',
              maxHeight: '30px',
              textAlign: 'center',
              userSelect: 'none',
            }}
          >
            {/** current selected page index is bold */}
            {errandContext.formCurrentPage + ' of ' + errandContext.formTotalPages}
          </StylizedTicks>) : ([...Array(totalTicks).keys()].map(
            (tick) =>
              errandContext.formBody !== FormBodyType.CreateSignatureMobile && (
                <StylizedTicks
                  style={{
                    width: '100%',
                    minWidth: '100%',
                    height: '30px',
                    maxHeight: '30px',
                    textAlign: 'center',
                    userSelect: 'none',
                  }}
                  key={tick}
                  onClick={(e) => handleTickScrollClick(tick)}
                >
                  {/** current selected page index is bold */}
                  {activeTick === tick ? <b>{tick + 1}</b> : tick + 1}
                </StylizedTicks>
              )
          ))}
        </div>
      )}
      <svg
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: '-2px',
        }}
        width="104"
        height="31"
      >
        <path d="M 0 31 l 104 0" stroke={theme.palette.peach['000']} strokeWidth="2" />
        <path
          d="M 0 31 a 30 30 0 0 0 26 -15 a 30 30
           0 0 1 52 0 a 30 30 0 0 0 26 15"
          stroke={theme.palette.orange['700']}
          strokeWidth="2"
          fill={theme.palette.peach['000']}
        />
      </svg>
      {ButtonTypeMap()}
    </>
  );
};

export default ESignSliderElement;
