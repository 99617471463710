import React, { useEffect, useRef, useState } from "react";

const MeasureSingleElement = (props) => {
  const { updateMeasure, El, elemRenderedHandler } = props;
  const elementID = El.props.activityData.id;
  const [wasMarked, setWasMarked] = useState(false);
  const myRef = useRef(null);    

  useEffect(() => {
    // if we have this element rendered and not yet marked as rendered.
    if(myRef.current && !wasMarked) {
      const data = {width: myRef.current.offsetWidth};
      // once mounted update measures of current element
      updateMeasure(elementID, data);
      // set was rendered in parent
      elemRenderedHandler();
      // mark as rendered locally
      setWasMarked(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myRef, wasMarked]);

  return <div ref={myRef}>{El}</div>;
}

/**
 * MeasureElements Component
 * @param {{ targetElements: JSX.Element[], onCompletedHandler: (measuresData: any) => any }} props 
 * @returns {JSX.Element} once measures are taken, onCompletedHandler is being called.
 * @summary The main idea behind this component is to measure dimensions of 
 *          each component element in passed React components array and save that data
 *          into an object of the following structure { [activityID]: {width: value} } 
 *          and return them to whatever component needs those.
 * Make sure you are passing activityID prop in each element of targetElements. 
 * Right now it measures width.
 * 
 * The way it takes those measurements is via rendering them each in their own separate hidden div and taking their offsetWidth via ref.
 * We have to make sure to remove them from UI after measurements were finished via onCompletedHandler.
 */
const MeasureElements = (props) => {
  // targetElements MUST have activityID field and each id should be unique is some way
  const {targetElements, onCompletedHandler} = props;
  const [currentRenderingElementIndex, setCurrentRenderingElementIndex] = useState(0);
  // to track whether all elements were already rendered or not.
  // elemsRendered has the following structure: boolean[] (arr of booleans)
  const [elemsRendered, setElemsRendered] = useState(new Array(targetElements.length).fill(false))
  // dims contains data about elements' dimensions in the following structure:
  // for now this only has width value measures.
  // { [activityID]: { width: value } }
  const [dims, setDims] = useState({});
  // single element rendered and measured handler
  const markElemRendered = (idx) => {
    setElemsRendered((prev) => {
      const copy = [...prev];
      copy[idx] = true;
      return copy;
    })
  }
  // main function that saves measurements data to this parent (MeasureElements) element.
  const handleMeasureUpdate = (elID, data) => {
    setDims((prev) => {
      return {
        ...prev,
        [elID]: data
      }
    });
  }
  // call on completed handler once we don't have anything else to render and take measures.
  useEffect(() => {
    if(elemsRendered.filter((value) => value === false).length === 0) {
      onCompletedHandler(dims);
    } else {
      // if there is some more elements to render
      // get the first false elem, set it as index for currently rendering element
      setCurrentRenderingElementIndex(
        elemsRendered.map((el, idx) => {
          return{ isRendered: el, idx: idx }
        }).filter((el) => el.isRendered === false)[0].idx
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elemsRendered])
  // style={{visibility: "hidden"}}
  return (
    <>
      {targetElements.map((el, idx) => {
        if(currentRenderingElementIndex === idx) {
          return (
            <div key={idx} style={{visibility: "hidden"}}>
              <MeasureSingleElement El={el} updateMeasure={handleMeasureUpdate} elemRenderedHandler={() => markElemRendered(idx)}/>
            </div>
          );
        } else {
          return (<div key={idx} style={{visibility: "hidden"}}></div>);
        }
      })}
    </>
  )
}



export {
  MeasureElements
}