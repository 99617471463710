import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { Close } from '../Assets/Icons';
import React, {FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';
import themeFile from '@assets/Themes/default.json';

const mt = {
  mt:"12px"
};

const StyledStepper = styled(Stepper)((/*{ themecolor: string }*/) => ({
  '& .MuiStepIcon-root.Mui-completed': {
    color: themeFile.palette.blue['210'],
  },
  '& .MuiStepIcon-root.Mui-active': {
    display: "none",
    color: themeFile.palette.blue['210'],
  },
}));

const EditSMSTemplateDialog: FC<DialogPropsWithChildren> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const activeStep = 0;
  const [SMSTemplateName, setSMSTemplateName] = useState(props?.SMSTemplate?.name || '');
  const [SMSTemplateVisible, setVisible] = useState(props?.SMSTemplate?.visible !== undefined ? props?.SMSTemplate?.visible : true);
  const [SMSTemplateDescription, setSMSTemplateDescription] = useState(
    props?.SMSTemplate?.description || ''
  );
  const [SMSTemplateText, setSMSTemplateText] = useState(
    props?.SMSTemplate?.text || ''
  );
  const steps = [t('SMSTemplateDetails')] //, t('SMSTemplateBody')];

  /*************************************
   * Submit Function
   *************************************/
  const handleSubmit = () => {
    let data = {
      id: '',
      name: SMSTemplateName,
      description: SMSTemplateDescription,
      text: SMSTemplateText,
      visible: SMSTemplateVisible
    };

    if (!props.edit) {
      props.onAdd(data);
    } else {
      data = { ...data, id: props.SMSTemplate.id };
      props.onSubmit(data);
    }
  };

  return (
    <Dialog maxWidth="md" /*sx={{maxWidth:'60vw'}}*/ open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {props.edit ? t('editSMSTemplate') : t('createSMSTemplate')}
          </Typography>
          <Close
            style={{ width: '12px', cursor: 'pointer' }}
            color="var(--gray210)"
            onClick={() => props.onClose()}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '40vw' }}>
        <StyledStepper
          // themecolor={theme.palette.blue['210']}
          activeStep={activeStep}
          alternativeLabel>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </StyledStepper>
          <Stack>
            <FormControlLabel
                sx={{ marginLeft: '0' }}
                control={
                  <Checkbox
                    color="info"
                    checked={SMSTemplateVisible}
                    onChange={(e) => {
                      setVisible(e.target.checked);
                    }}
                  />
                }
                label={t('visibleCol')}
                labelPlacement="end"
              />
            <TextField
              autoFocus
              color="info"
              label={`${t('nameCol')}*`}
              onChange={(e) => setSMSTemplateName(e.target.value)}
              size="small"
              sx={{...mt}}
              value={SMSTemplateName}
              variant="outlined"
            />
            <TextField
             
              label={`${t('descriptionCol')}*`}
              multiline
              onChange={(e) => setSMSTemplateDescription(e.target.value)}
              rows={1}
              size="small"
              sx={{...mt}}
              value={SMSTemplateDescription}
              variant="outlined"
            />
            <TextField
             
              label={`${t('TextTranslation')}*`}
              multiline
              onChange={(e) => setSMSTemplateText(e.target.value)}
              rows={8}
              size="medium"
              sx={{...mt}}
              value={SMSTemplateText}
              variant="outlined"
            />

          </Stack>
        <Typography color={theme.palette.red['400']}>{props.error}</Typography>
      </DialogContent>
      <DialogActions>
          <Button
            disabled={SMSTemplateName === '' || SMSTemplateDescription === ''}
            variant="contained" color="info"
            onClick={() => handleSubmit()}>
            {t('doneButton')}
          </Button>

      </DialogActions>
    </Dialog>
  );
};

export default EditSMSTemplateDialog;
