import React from 'react';

export const Gradient = () => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          zIndex: '-1',
          top: '50%',
          left: '50%',
          width: '200vmax',
          height: '200vmax',
          animation: 'gradient 30s infinite linear',
          transformOrigin: 'center',
          borderRadius: '50%',
          backgroundImage: 'linear-gradient(var(--orange010) 30%, var(--gray000), var(--blue040) 70%)',
        }}
      />
    </>
  );
};
