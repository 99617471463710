import React from 'react';
import Sanitized from '@components/Sanitized';

interface Props {
  title: string;
}

const COMPONENT = 'Title';

/**
 * The title of the message.
 */
export default function Title(props: Props): React.ReactElement {
  return (
    <span data-component={COMPONENT}>
      <Sanitized html={props.title} />
    </span>
  );
}
