/**
 * @file LockConfirmationMessageContent.tsx
 * @description This file defines the LockConfirmationMessageContent component used to
 * display the loan loch confirmation as the last step of the Lock the Loan
 * workflow
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */
import React from 'react';
import { LockConfirmationMessage } from '@mTypes/Conversation';
import { Typography } from '@mui/material';
import MessageTextStyle from '@styles/MessageTextStyle';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/MessageContent/LockConfirmationMessageContent.module.css';
const LockAnimation = process.env.REACT_APP_MORGAN_CDN + '/Images/LoanLockAnimation.gif';

interface TLockConfirmationMessageContentProps {
  message: LockConfirmationMessage;
}

const LockConfirmationMessageContent = ({
  message, 
}: TLockConfirmationMessageContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={[Styles.wrapper, ...(message.operatorView ? [Styles.operatorView] : [])].join(' ')}>
        <div className={Styles.chatBubble}>
          <Typography variant="h6">
            {t('Lock')}: {message.loanNumber}
          </Typography>
          <MessageTextStyle textTransform="capitalize">{message.borrowerName.toLowerCase()}</MessageTextStyle>
          <MessageTextStyle>
            {t('rate')}: {Number(message.rate)}%
          </MessageTextStyle>
          <MessageTextStyle>
            {t('price')}: {message.price}
          </MessageTextStyle>
          <MessageTextStyle>
            {message.loanTypeKeyword} {message.paymentDescription}
          </MessageTextStyle>
          <img alt="Lock Animation" src={LockAnimation} className={Styles.lockAnimation}></img>
        </div>

        <div className={Styles.footer}>
          <MessageTextStyle>
            {t('expiration')}: {message.expDate}
          </MessageTextStyle>
        </div>
      </div>
    </>
  );
};

export default LockConfirmationMessageContent;
