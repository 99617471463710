// UI Elements of ActivityTracker
import { ValidatorFunctions } from '@common/Validators';
import React, { memo, useEffect, useState } from 'react';
import { DefaultElement } from './templates/DefaultElement';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CONSTANTS, IconStyles } from '../Constants';
import { ActivityData, DefaultOptionalData, SingleActivityProps } from '../interfaces';
import { useTranslation } from 'react-i18next';
import { useHasMounted } from '@common/hooks/useHasMounted';
import LanguageUtils from '@common/LanguageUtils';
import { DefaultIcon } from './templates/DefaultIcon';
import useWindowDimensions from '@common/hooks/useWindowDimensions';

const removeRedundantCharsFrom = (str: string) => {
  return str.replace(/[!?]/g, '');
};

// Action element icon component
const ActionIcon = (props) => {
  // if it has a valid action icon
  if (props.icon && props.icon !== '' && ValidatorFunctions.isTypeOfString(props.icon)) {
    return <DefaultIcon alt={props?.alt} icon={props?.icon} />;
  }
  // else, return edit icon if not an element was provided
  else {
    if (React.isValidElement(props.icon)) {
      return props.icon;
    } else {
      return <EditOutlinedIcon style={{ ...IconStyles.default }} />;
    }
  }
};

const ActionText = (props) => {
  const debouncedWindowDimensions = useWindowDimensions();
  const { t, i18n } = useTranslation();
  const hasMounted = useHasMounted();
  const DEFAULT_ACTION_TEXT = t('AT_defActionText');
  const initialText = props?.activityData?.data?.text
    ? filterText(props?.activityData?.data?.text)
    : DEFAULT_ACTION_TEXT;
  // Use state var for future translation logic.
  const [text, setText] = useState(initialText);

  const handleUpdateText = (givenText: string) => {
    setText((prev) => givenText);
    doRemeasureElement(givenText);
  };

  function filterText(givenText: string) {
    const processedText = removeRedundantCharsFrom(givenText);
    if (processedText.length <= CONSTANTS.MAX_LETTERS_IN_SINGLE_ACTIVITY) {
      return processedText;
    } else {
      return DEFAULT_ACTION_TEXT;
    }
  }

  const doRemeasureElement = (givenText?: string) => {
    // this check is needed because in measuring single component logic
    // setMeasureSingleDimensionData is not passed to children SingleElement.
    if (props?.remeasureElement) {
      // remeasure the action element to update to correct width.
      const activityTempData: ActivityData = {
        ...props.activityData,
        data: {
          icon: props?.activityData?.data?.icon,
          text: givenText ?? DEFAULT_ACTION_TEXT,
        },
      };
      props.remeasureElement(activityTempData);
    }
  };

  const handleTextTranslation = async (givenText: string) => {
    try {
      const resTranslation = await LanguageUtils.translateOne(givenText, i18n.language);
      // filter translationText
      const resText = filterText(resTranslation);
      // rerender text locally
      handleUpdateText(resText);
    } catch (e) {
      handleUpdateText(DEFAULT_ACTION_TEXT);
      console.error(
        `Translation of action text failed, tried to translate: '${givenText}' to lang: ${i18n.language} , error: `,
        e
      );
    }
  };

  useEffect(() => {
    if (hasMounted === false) return;
    if (props.activityData && props.activityData.data) {
      // handle text
      if (props.activityData.data?.text) {
        const givenText = props.activityData.data.text;
        // handles the long text by setting to DEFAULT_ACTION_TEXT
        const processedText = filterText(givenText);

        if (processedText !== DEFAULT_ACTION_TEXT && i18n.language !== 'en') {
          // if lang is not en, get translation
          handleTextTranslation(processedText);
        } else {
          // just set it
          handleUpdateText(processedText);
        }
      }
    }
  }, [
    props?.activityData?.data?.text,
    props?.activityData?.data?.icon,
    i18n.language
  ]);

  useEffect(() => {
    doRemeasureElement(text);
  }, [debouncedWindowDimensions.innerWidth])

  return <>{text}</>;
};

// 'Action' El
export const ActionElement = memo(
  (props: { activityData: ActivityData; remeasureElement: (givenData: ActivityData) => void }) => {
    const activityUIData = props.activityData.data as DefaultOptionalData;
    return (
      <DefaultElement icon={<ActionIcon icon={activityUIData?.icon} alt={'Activity Tracker action icon element.'} />}>
        <ActionText activityData={props.activityData} remeasureElement={props?.remeasureElement} />
      </DefaultElement>
    );
  }
);
