/**
 * @file EditHelpTipDialog.tsx
 * @description This file describes the UI element that allows an operator to edit HelpTips from the
 * admin UI. This component was created as part of the MRGN-246 task.
 * @author Ryan Buxton <ryan.buxton@swmc.com>
 * @author Eric Velepucha <eric.velepucha@swmc.com
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Close } from '../Assets/Icons';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axiosCall from '../Services/axios';

const EditHelpTipDialog = (props) => {
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  const defaultRadio = props.data?.image ? 'animation' : 'text';
  const [isText, setIsText] = useState(!props.data?.image);
  const [selectedFile, setSelectedFile] = useState<File>(null);
  const [storedHelpTipImageUrl, setStoredHelpTipImageUrl] = useState('');
  const { t } = useTranslation();

  const handleSubmit = async () => {
    let helpTipImageId = '';
    if (selectedFile) {
      // Upload image file
      // Receive image document _id
      // put _id in HelpTip data payload
      const formData = new FormData();
      formData.append('file', selectedFile, selectedFile.name);

      const requestParams = {
        url: `helptipimage`,
        method: 'POST',
        data: formData,
      };
      const { _id } = await axiosCall(requestParams);
      helpTipImageId = _id;
    }

    const textData = selectedFile ? '' : text;
    const imageData = text ? null : helpTipImageId;
    const payload = {
      url: `helptip/${props.data?.edit ? props.data._id : ''}`,
      method: props.data?.edit ? 'PUT' : 'POST',
      data: {
        name: name,
        text: textData,
        image: imageData,
      },
    };
    await axiosCall(payload);
    await props.reload();
    props.onClose();
  };

  const handleSelectedRadio = (e) => {
    const radioVal = e.target.value;
    if (radioVal === 'text') {
      setIsText(true);
      return;
    }
    setIsText(false);
    setText('');
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    setName(props.data?.name || '');
    setText(props.data?.text || '');
    const wrapped = async () => {
      const helpTipImageDoc = await axiosCall({ url: `helptipimage/${props.data?.image}`, method: 'GET' });
      const url = helpTipImageDoc.storageLocation;
      setStoredHelpTipImageUrl(url);
    };
    if (props.data?.image) {
      wrapped();
    }
  }, [props.data]);

  return (
    <Dialog maxWidth="md" open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{props.data?.edit ? t('editTip') : t('addHelpTip')}</Typography>
          <Close style={{ width: '12px', cursor: 'pointer' }} color="var(--gray210)" onClick={() => props.onClose()} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '40vw' }}>
        <Stack spacing={1}>
          <TextField
           
            label={t('nameCol')}
            onChange={(e) => setName(e.target.value)}
            value={name}
            sx={{ marginTop: '12px' }}
            variant="outlined"
          />
          {!isText && props.data?.image && (
            // <Typography>Current image preview: </Typography>
            <Stack>
              <Typography>{t('currentImagePreview')}</Typography>
              <img
                alt="help tip image preview"
                src={storedHelpTipImageUrl}
                style={{
                  minHeight: '101px',
                  minWidth: '280px',
                  objectFit: 'contain',
                  maxHeight: '108px',
                  maxWidth: '300px',
                }}
              />
            </Stack>
          )}
          <RadioGroup row defaultValue={defaultRadio}>
            <FormControlLabel value={'text'} control={<Radio />} label={t('text')} onChange={handleSelectedRadio} />
            <FormControlLabel
              value={'animation'}
              control={<Radio />}
              label={t('imageCol')}
              onChange={handleSelectedRadio}
            />
          </RadioGroup>
          {isText && (
            <TextField
             
              label={t('text')}
              onChange={(e) => setText(e.target.value)}
              value={text}
              sx={{ marginTop: '12px' }}
              variant="outlined"
              multiline
              rows={3}
            />
          )}
          {!isText && (
            <>
              <Stack direction="row" alignItems="center">
                <Button
                  startIcon={<FileDownloadIcon />}
                  sx={{ minWidth: '200px', height: '40px', marginRight: '15px', whiteSpace: 'nowrap', width: '260px' }}
                  variant="contained"
                  color="info"
                  component="label"
                >
                  {t('uploadPicture')}
                  <input type="file" hidden onChange={handleFileSelect} />
                </Button>
                <Typography>{selectedFile?.name}</Typography>
              </Stack>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={!name || (!selectedFile && !text)} variant="contained" onClick={handleSubmit}>
          {t('doneButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHelpTipDialog;
