import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Public } from '@mui/icons-material';

import { IErrand } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import Sanitized from '@components/Sanitized';

import Styles from '@styles/MorphReply.module.css';
import { useTranslation } from 'react-i18next';

type TMorphReplyProps = {
};

const MorphReply = () => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const [replySubstring, setReplySubstring] = useState<string>('');
  const originalTextRef = useRef(errandContext.replyToRef.current.originalText);
  const sanitizedRef = useRef<HTMLDivElement | null>(null);

  const handleEnd = useCallback(() => {
    try {
      const substring = window.getSelection()?.getRangeAt(0)?.toString();
      if (!substring || originalTextRef.current.indexOf(substring) === -1) {
        return setReplySubstring('');
      }

      setReplySubstring(substring);

      if (typeof window.getSelection().empty === 'function') {
        window.getSelection().empty(); // Chromium
      } else if (typeof window.getSelection().removeAllRanges === 'function') {
        window.getSelection().removeAllRanges(); // Webkit
      }
    } catch {
      setReplySubstring('');
    }
  }, []);

  /**
   * This useEffect exists to ensure that the replyToRef and sanitizedRef
   * content are perfectly matched. While this should be theoretically possible
   * by parsing the window selection object and piecing together the selection
   * to mark it and surround it with the original message content, this method
   * requires far less code while only causing 1 additional render since there
   * are no additional state changes. While not ideal, this achieves the goal
   * with minimal impact on the rest of the code base.
   */
  useEffect(() => {
    const firstChild = sanitizedRef.current.firstChild;
    if (firstChild instanceof HTMLElement) {
      errandContext.replyToRef.current.originalText = firstChild.innerHTML;
    }
  }, [replySubstring]);

  return (
    <>
      <section className={Styles.section} onMouseUp={handleEnd} onTouchEnd={handleEnd} ref={sanitizedRef}>
        <Sanitized className={Styles.content} html={originalTextRef.current} highlight={replySubstring} />
        <p className={Styles.placeholder}>{t('highlightTextAboveToClarifyReply')}</p>
        <div className={Styles.tabRing}>
          <div className={Styles.tabBackground}>
            <Public />
          </div>
        </div>
      </section>
    </>
  );
};

export default MorphReply;
