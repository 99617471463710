import React, { useRef, useEffect, useContext, useCallback } from 'react';
import useDebounce from '@common/hooks/useDebounce';
import { IFormContext, FormContext } from '@contexts/FormContext';
import useInitialMount from '@common/hooks/useInitialMount';
import { useErrandContext } from '@contexts/ErrandContext';
import { useSocketContext } from '@contexts/socket';

// left and right side border pixel size has to be substracted from parent component dimention
// in order to make slider work perfectly
const divHeightOffset = 72;

const ElectronicSignatureOperator = () => {
  const errandContext = useErrandContext();
  const formContext = useContext<IFormContext>(FormContext);
  const { messagesSocket, isMessagesConnected } = useSocketContext();

  const userViewPointPercentage = useRef<number>(undefined);

  const syncView = () => {
    const percentage = userViewPointPercentage.current;

    if (percentage === null || percentage === undefined) return;

    const docHeight = formContext.documentRef.current?.children[0]?.getBoundingClientRect().height;
    const divHeight = formContext.documentReaderRef.current?.getBoundingClientRect().height - divHeightOffset;

    const viewPointMiddle = percentage * docHeight;
    const scrollTop = viewPointMiddle - divHeight / 2;

    formContext.handleCustomScroll(scrollTop);
  };

  useDebounce(() => syncView(), 500, [
    formContext.documentRef.current,
    errandContext.formBodySize,
    userViewPointPercentage.current,
  ]);

  useEffect(() => {
    if (!isMessagesConnected) return;

    const chatEventHandler = (payload) => {
      const data = payload.data;

      if (data.type !== 'form_operator_view_sync') return;

      formContext.signatureMapRef.current = data.signatureMapRef;
      userViewPointPercentage.current = data.viewPointPercentage;

      formContext.setEConsentRadioButtonState(data.eConsentRadioButtonState);
      errandContext.setWetInitial(data.wetInitial);
      errandContext.setWetSignature(data.wetSignature);
      errandContext.setFormBody(data.formBody);
      formContext.setBcertaData(data.bcertaData);
      formContext.setMainSignatureInserted(data.mainSignatureInserted);
    };

    messagesSocket.current?.on('chat-event-emitted', chatEventHandler);
    return () => {
      messagesSocket.current?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errandContext.errandId]);

  const formViewFetch = () => {
    messagesSocket.current?.emit('emit-chat-event', {
      chatId: errandContext.errandId,
      data: {
        type: 'form_operator_view_fetch',
      },
      recipients: [],
    });
  };

  // Component init
  const resetFormViewAndRequestUserView = useCallback(() => {

    formViewFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInitialMount(resetFormViewAndRequestUserView);

  return formContext.EsignBody();
};

export default ElectronicSignatureOperator;
