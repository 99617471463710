import { Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import Home from '@styles/OrderAndUnsubscribeStyle';
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

const OrderUpdate : React.FC<{}> = () => {
  const [orderData, setOrderData] = useState(undefined);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('');
  const [updated, setUpdated] = useState(false);

  const { hashkey } = useParams();

  const initialize = async () => {
    let order;
    try {
      // Attempt to get the data from the hashkey 
      order = await axios({
        url: `${process.env.REACT_APP_MORGAN_CORE}/order/hashkey/${hashkey}`
      });
    } catch (error : any) {
      // If there was an error display this to the user
      order = { data: { message: error.message } };
    }
    
    setOrderData(order.data);
  };

  const updateOrder = async () => {
    await axios({
      url: `${process.env.REACT_APP_MORGAN_CORE}/order/hashkey/${hashkey}`,
      method: 'put',
      data: {
        status: orderData?.targetStatus,
        trackingNumber: trackingNumber !== '' ? trackingNumber : undefined,
        carrier: carrier !== '' ? carrier : undefined,
      }
    });
    setUpdated(true);
  }

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Home>
      <span/>
      <span/>
      <main>
        <Card sx={{ width: '40%' }}>
          <CardContent>
            <Stack spacing={2} alignItems="center">
              <img src={AngelAi} className="morgan-logo" alt="AngelAi logo" />
              {/* Horizontal stack */}
              {
                orderData !== undefined && !updated && orderData?.message === undefined &&
                <Stack spacing={2}>
                  <Typography fontSize="large" fontWeight="bold" textAlign="center"> Order {orderData?._id} </Typography>
                  <Stack direction="row" justifyContent="space-evenly" spacing={2}>
                    {/* First stack for address */}
                    <Stack>
                      <Typography fontSize="large" fontWeight="bold" textAlign="center"> Shipping Address </Typography>
                      <Typography textAlign="center"> {orderData?.shippingAddressLine1} </Typography>
                      <Typography textAlign="center"> {orderData?.shippingAddressLine2} </Typography>
                      <Typography textAlign="center"> {orderData?.shippingCity} </Typography>
                      <Typography textAlign="center"> {orderData?.shippingState} </Typography>
                      <Typography textAlign="center"> {orderData?.shippingZip} </Typography>
                    </Stack>
                    {/* Second stack for product */}
                    <Stack>
                      {/* { 
                        orderData?.product?.picture !== '' &&
                        <img src={AngelAi} alt="Product" />
                      } */}
                      <Typography fontSize="large" fontWeight="bold" textAlign="center"> Product Details </Typography>
                      <Typography textAlign="center"> {orderData?.product?.name} </Typography>
                      <Typography textAlign="center"> {orderData?.product?.description} </Typography>
                      <Typography textAlign="center"> <b>Size</b>: {orderData?.product?.unitSize} </Typography>
                      <Typography textAlign="center"> <b>Color</b>: {orderData?.product?.unitColor} </Typography>
                    </Stack>
                  </Stack>
                  {
                    orderData?.targetStatus === 'completed' &&
                    <>
                      <TextField 
                      label="Tracking Number" 
                      variant="outlined" 
                      value={trackingNumber} 
                      onChange={(event) => {
                        setTrackingNumber(event.target.value);
                      }}/>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Carrier</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Carrier"
                          value={carrier}
                          onChange={(event) => {
                            setCarrier(event.target.value);
                          }}
                        >
                          <MenuItem value="FedEx">FedEx</MenuItem>
                          <MenuItem value="UPS">UPS</MenuItem>
                          <MenuItem value="USPS">USPS</MenuItem>
                          <MenuItem value="DHL">DHL</MenuItem>
                        </Select>
                      </FormControl>
                    </>
                  }
                  <Button 
                    disabled={orderData?.targetStatus === 'completed' && (trackingNumber === '' || carrier === '')} 
                    variant="contained"
                    sx={{ color: 'var(--gray000)' }}
                    onClick={updateOrder}> Update Status to {orderData?.targetStatus} </Button>
                </Stack>
              }
              {
                updated &&
                <Stack spacing={2}>
                  <Typography fontSize="large" fontWeight="bold" textAlign="center"> Order {orderData?._id} </Typography>
                  <Typography> The order status has been updated and the update email has been sent to the customer. </Typography>
                </Stack>
              }
              {
                orderData?.message  &&
                <Stack spacing={2}>
                  <Typography fontSize="large" fontWeight="bold" textAlign="center"> Error </Typography>
                  <Typography> The order ID is invalid or no longer exists. ({orderData?.message}) </Typography>
                </Stack>
              }
            </Stack>
          </CardContent>
        </Card>
      </main>
    </Home>
  );
}

export default OrderUpdate;
