/*********************************
 * This function take a base64 image string
 * and uses the beginning of it to determine
 * the image type to display properly
 *
 * The only image types currently supported are
 * .jpg/.jpeg, .png, .svg
 *********************************/

const buildString = (type, image) => {
  return `data:image/${type};base64,${image}`;
};

const getImageSource = (base64Image) => {
  if (base64Image?.startsWith('/9j/')) {
    return buildString('jpg', base64Image);
  }

  if (base64Image?.startsWith('iVBOR')) {
    return buildString('png', base64Image);
  }

  if (base64Image?.startsWith('PHN2Z')) {
    return buildString('svg+xml', base64Image);
  }

  return "";
}

export default getImageSource;
