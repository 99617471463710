import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import axiosCall from '@services/axios';
import { APIServiceType } from '@common/APIServiceType';
import StandardTablePagination from '@components/StandardTablePagination';
import TableHeaderBar from '@components/TableHeaderBar';
import EditIntentDialog from '@components/EditIntentDialog';
import useControllerCallback from '@common/hooks/useControllerCallback';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '8px',
  },
}));

const AIIntents = () => {
  const paginationSteps = [15, 50, 100];
  const { t } = useTranslation();

  const [editIntentState, setEditIntentState] = useState({
    edit: false,
    open: false,
    data: null,
  });
  const [intentsData, setIntentsData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modelNames, setModelNames] = useState([]);
  const [selectedModelName, setSelectedModelName] = useState('');
  const [buildResultMessage, setBuildResultMessage] = useState('');
  const [buildingModel, setBuildingModel] = useState(false);
  const [functionsData, setFunctionsData] = useState([]);
  
  const pageRef = useRef(0);
  const rowsPerPageRef = useRef(paginationSteps[0]);
  const showInactiveRef = useRef(false);

  const noResults = intentsData.length === 0;

  /**
  * Fetches the list of model names from the cells engine to populate the dropdown at the top of the table
  */
  const loadIntentModelNames = async (controller?: AbortController) => {
    setLoading(true);
    const url = 'intents/model-names';
    const config = controller ? { signal: controller.signal } : {};
    try {
      const modelNamesResult = await axiosCall({ url, service: APIServiceType.cells }, config);
      if (!Array.isArray(modelNamesResult)) {
        throw new Error('Server responded with unexpected data:', modelNamesResult);
      } else if (modelNamesResult.length === 0) {
        throw new Error('Server responded with an empty list of entity model names');
      }
      setModelNames(modelNamesResult)
      setSelectedModelName(modelNamesResult[0]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const loadIntentsData = useCallback(async (controller?: AbortController) => {
    let method = 'get';
    let reqBody = {};
    let url = `intents/${selectedModelName}`;

    if (searchValue) {
      url = `intents/${selectedModelName}/search`;
      method = 'post';
      reqBody = { search: searchValue };
    }

    url += '?';

    let offset = rowsPerPageRef.current === -1 ? 0 : pageRef.current * rowsPerPageRef.current;
    // Attempt to load one more document to check if there are more available
    let limit = rowsPerPageRef.current + 1;
    url += `offset=${offset}`;
    if (rowsPerPageRef.current !== -1) {
      url += `&limit=${limit}`;
    }
    if (!showInactiveRef.current) {
      url += '&active=true';
    }

    let config = controller ? { signal: controller.signal } : {};
    setLoading(true);
    try {
      const result = await axiosCall({ url, method, data: reqBody, service: APIServiceType.cells }, config);
      if (!Array.isArray(result)) {
        throw new Error('Server responded with unexpected data:', result);
      }
      setIntentsData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [selectedModelName, searchValue]);

  const loadFunctionsData = useCallback(async (controller?: AbortController) => {
    const url = 'functions';
    const config = controller ? { signal: controller.signal } : {};

    setLoading(true);
    try {
      const result = await axiosCall({ url, service: APIServiceType.cells }, config);
      if (!Array.isArray(result)) {
        throw new Error('Server responded with unexpected data:', result);
      }
      setFunctionsData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadData = useCallback(async (controller?: AbortController) => {
    if (!selectedModelName) {
      // Load the names of the available models if we haven't already
      await loadIntentModelNames(controller);
    } else {
      await loadFunctionsData(controller);
      await loadIntentsData(controller);
    }
  }, [loadIntentsData, loadFunctionsData, selectedModelName]); 

  /**
   * This function calls the build endpoint for the intents model to rebuild the model based on the intents currently in
   * the database
   */
  const rebuildModel = async () => {
    const url = 'build/intents';
    const method = 'post';
    setBuildResultMessage('');
    setBuildingModel(true);
    try {
      const result = await axiosCall({ url, method, service: APIServiceType.cells });
      console.log('Build model result:', result);
      setBuildResultMessage(t('buildModelSuccess'));
    } catch (error) {
      console.error(error);
      setBuildResultMessage(t('tError'));
    } finally {
      setBuildingModel(false);
    }
  };

  const handleAddIntentOpen = () => {
    setEditIntentState({ edit: false, open: true, data: null });
    setErrorMessage('');
  };

  const handleEditIntentOpen = (intentData) => {
    setEditIntentState({ edit: true, open: true, data: intentData });
    setErrorMessage('');
  };

  const handleEditIntentClose = () => {
    setEditIntentState({ edit: false, open: false, data: null });
    setErrorMessage('');
  };

  const handleAddIntentSubmit = async (intentData) => {
    if (await addIntent(intentData)) {
      setEditIntentState({ edit: false, open: false, data: null });
    }
  };

  const handleEditIntentSubmit = async (intentData) => {
    if (await editIntent(intentData)) {
      setEditIntentState({ edit: false, open: false, data: null });
    }
  };

  /*************************************
   * CRUD Functions
   ************************************/
  const addIntent = async (intentData) => {
    try {
      let result = await axiosCall({
        url: 'ai/intent',
        method: 'POST',
        data: { ...intentData, model: selectedModelName },
      });

      if (result) {
        await loadIntentsData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message ?? t('tError'));
      return false;
    }
  };

  const editIntent = async (intentData) => {
    const { _id, ...sendData } = intentData;
    try {
      const result = await axiosCall({
        url: `ai/intent/${_id}`,
        method: 'PUT',
        data: sendData,
      });

      if (result) {
        await loadIntentsData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message ?? t('tError'));
      return false;
    }
  };

  const handleReactivate = async (id) => {
    try {
      let result = await axiosCall({
        url: `ai/intent/${id}`,
        method: 'PUT',
        data: {
          active: true,
        },
      });
      if (result) {
        await loadIntentsData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const result = await axiosCall({
        url: `ai/intent/${id}`,
        method: 'DELETE',
      });
      if (result) {
        await loadIntentsData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModelNameSelector = (e) => {
    setSelectedModelName(e.target.value);
  };

  const handleShowInactiveClick = () => {
    showInactiveRef.current = !showInactiveRef.current;
    pageRef.current = 0;
    loadIntentsData();
  };

  const handlePageChange = (_, newPage) => {
    pageRef.current = newPage;
    loadIntentsData();
  };

  const handleRowsPerPageChange = (e) => {
    rowsPerPageRef.current = e.target.value;
    pageRef.current = 0;
    loadIntentsData();
  };

  const handleSearchChange = (newSearchValue: string) => {
    // We want to reset back to the first page when searching so we don't miss any documents (for example, we don't want
    // to begin our search on the second page as we would miss those on the first)
    pageRef.current = 0;
    // Now we can update the search value
    setSearchValue(newSearchValue);
  };

  const handleSnackbarClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (buildingModel || reason === 'clickaway') {
      return;
    }

    setBuildResultMessage('');
  };

  useControllerCallback(loadData);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} height="calc(100vh - 175px)">
      {/* Snackbar for messages regarding the progress/success/failure of rebuilding the model */}
      <Snackbar
        open={buildingModel || !!buildResultMessage} // Snacbkar open if the model is being built or has just built
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={buildingModel ? t('buildingModel') : buildResultMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <TextField
              sx={{ marginBottom: 1, minWidth: '260px' }}
              label={t('tModel')}
              select
              size="small"
             
              value={selectedModelName}
              SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500 } } } }}
              onChange={(e) => {
                if (loading) {
                  return;
                }
                handleModelNameSelector(e);
              }}
            >
              {modelNames.map((modelName) => (
                <MenuItem key={modelName} value={modelName}>{modelName}</MenuItem>
              ))}
            </TextField>
            <Button
              variant="contained"
              color="info"
              sx={{ marginBottom: 1, minWidth: '260px' }}
              onClick={rebuildModel}
            >
              {t('rebuildModel')}
            </Button>
          </Box>
        <Box sx={{ flexGrow: 1 }}>
          <TableHeaderBar
            onAdd={handleAddIntentOpen}
            onRefresh={loadIntentsData}
            shouldHaveSearch={true}
            searchValue={searchValue}
            setSearch={handleSearchChange}
            onExport={undefined}
            addLabel={t('addIntent')}
            showInactiveClick={handleShowInactiveClick}
            isActive={!showInactiveRef.current}
          />
        </Box>
      </Box>
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : noResults ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <Paper sx={{ p: 1.5 }}>
              <Typography>{t('noData')}</Typography>
            </Paper>
          </Stack>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell width="300px">{t('intentCol')}</StyledTableCell>
                <StyledTableCell width="300px">{t('microIntentCol')}</StyledTableCell>
                <StyledTableCell width="300px">{t('descriptionCol')}</StyledTableCell>
                <StyledTableCell width="300px">{t('payloadCol')}</StyledTableCell>
                <StyledTableCell width="50px">{t('actionsCol')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {intentsData.map((intentData, index) => {
                // If we are in paginated mode and there is more data available than the page limit (because we attempt
                // to fetch one more row than the limit to check if more data is available), don't display the last row
                if (
                  rowsPerPageRef.current !== -1 &&
                  intentsData.length > rowsPerPageRef.current &&
                  index === rowsPerPageRef.current
                ) {
                  return null;
                }
                const { _id, active, intent, microIntent, description, payload } = intentData;
                return (
                  <TableRow sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }} hover key={_id}>
                    <StyledTableCell>{intent}</StyledTableCell>
                    <StyledTableCell>{microIntent}</StyledTableCell>
                    <StyledTableCell>{description}</StyledTableCell>
                    <StyledTableCell>{payload ?? ''}</StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title={t('editIntent')} placement="top-start" enterDelay={1000} enterNextDelay={1000}>
                        <BorderColorOutlinedIcon
                          onClick={() => handleEditIntentOpen(intentData)}
                          fontSize="small"
                          sx={{ cursor: 'pointer', color: 'var(--blue210)' }}
                        />
                      </Tooltip>
                      {active ? (
                        <Tooltip
                          title={t('deleteIntent')}
                          placement="top-start"
                          enterDelay={1000}
                          enterNextDelay={1000}
                        >
                          <DeleteOutlinedIcon
                            onClick={() => handleDelete(_id)}
                            fontSize="small"
                            sx={{ cursor: 'pointer', color: 'red' }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={t('reactivateIntent')}
                          placement="top-start"
                          enterDelay={1000}
                          enterNextDelay={1000}
                        >
                          <RestoreFromTrashOutlinedIcon
                            onClick={() => handleReactivate(_id)}
                            fontSize="small"
                            sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                          />
                        </Tooltip>
                      )}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StandardTablePagination
                  steps={paginationSteps}
                  rowsPerPage={rowsPerPageRef.current}
                  page={pageRef.current}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  isMore={rowsPerPageRef.current === -1 ? false : intentsData.length > rowsPerPageRef.current}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {editIntentState.open && (
        <EditIntentDialog
          edit={editIntentState.edit}
          open={editIntentState.open}
          intentData={editIntentState.data}
          onAdd={handleAddIntentSubmit}
          onEdit={handleEditIntentSubmit}
          onClose={handleEditIntentClose}
          error={errorMessage}
          refreshData={loadIntentsData}
          functions={functionsData}
        />
      )}
    </Box>
  );
};

export default AIIntents;
