import React, { useEffect, useState } from 'react';
import useTimeout from '@common/hooks/useTimeout';
import Styles from '@styles/PulseIcon.module.css';

const PulseIcon = ({ icon1, icon2 }) => {
  const [showIcon1, setShowIcon1] = useState(true);
  const { clear } = useTimeout(() => setShowIcon1(false), 2000);

  useEffect(() => {
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div className={Styles.wrapper}>{showIcon1 ? <span className={Styles.icon1}>{icon1}</span> : icon2}</div>;
};

export default PulseIcon;
