import { FooterAnimatedIconWrapper } from '@styles/ConversationFooterStyles';
import { IRootContext, RootContext } from '@contexts/RootContext';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { FooterIcon } from '../Styles/ConversationFooterStyles';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import getImageSource from '@common/getImageSource';

// CONSTANTS
const PULSE_DURATION = '1.5s'; // should be in this format because its being injected into @keyframes
const ANIMATION_DURATION = 4450; // in milliseconds. This is being used in Timeout for animated icon removal. (Ideally should not have any remainder [ANIMATION_DURATION % PULSE_DURATION !== 0])
// 4500 is 3 ticks (each 1.5s);

// helpers
const checkNotNullNorUndefined = (val) => val !== undefined && val !== null;
const checkTypeOfString = (val) => typeof val === 'string';

// css styles 
const defaultIconStyle = { width: '24px' };
const defaultIconContainerStyle = {
  height: '33px',
  width: '33px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface AnimatedIconProps {
  setAnimationFinished: (val: boolean) => any;
  iconBase64: string;
  alt?: string;
}
/**
 * Child AnimatedIcon component that takes care of all animation above the original icon component.
 */
const AnimatedIcon = (props: AnimatedIconProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>(null);
  const { messagesAreLoading } = useContext<IRootContext>(RootContext);
  const [isSelfMounted, setIsSelfMounted] = useState(false);

  const iconContainerStyle = {
    ...defaultIconContainerStyle,
    backgroundColor: 'var(--gray050)',
    borderRadius: '16px',
    alignSelf: 'center',
  };

  useEffect(() => {
    setIsSelfMounted(true);

    return () => {
      if(timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [])

  useEffect(() => {
      // clear if there is an on-going one (which means that some icon didn't finish animation).
      if(timeoutRef.current !== null) {
        // clear old timeout
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }    
      if(isSelfMounted === true && messagesAreLoading === false) {
      // animation started, so set up a timeout.
      timeoutRef.current = setTimeout(() => {
        props.setAnimationFinished(true);
      }, ANIMATION_DURATION);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelfMounted, messagesAreLoading, props.iconBase64])

  return (
    <FooterAnimatedIconWrapper pulseDuration={PULSE_DURATION} animationStarted={isSelfMounted}>
      <div style={{ ...iconContainerStyle }}>
        <img
          style={{ ...defaultIconStyle, transform: 'translateY(-1px)' }}
          alt={props.alt || 'Inverted Action Icon'}
          src={props.iconBase64}
        />
      </div>
    </FooterAnimatedIconWrapper>
  );
};

interface DefaultIconProps {
  iconBase64: string;
  alt?: string;
}
/**
 * 
 * Default Original Icon Component
 * 
 */
const DefaultIcon = (props: DefaultIconProps) => {
  return (
    <div style={{ ...defaultIconContainerStyle }}>
      <img style={defaultIconStyle} alt={props.alt || 'Footer Action Icon'} src={props.iconBase64} />
    </div>
  );
};

interface FooterActionIconProps {
  base64Icon: string;
  _invertedBase64Icon?: string;
  actionId?: string; // used for detecting changes.
}

// val checker helper
const hasSomeValButNotString = (val: any) => {
  return checkNotNullNorUndefined(val) === true && checkTypeOfString(val) === false;
}

/**
 * 
 * Main Parent Component that renders Footer action Icon. 
 * Controls Animation and Shows some placeholder icon when invalid value is provided.
 */
const FooterActionIcon = (props: FooterActionIconProps) => {
  const [animationFinished, setAnimationFinished] = useState(false);
  const [changeDetected, setChangeDetected] = useState(false);

  /**
   * Takes care of a change detected logic.
   * Whenever an icon is quickly changed, changeDetected is true.
   * If change is detected, the state should be restored to initial values for the animation to properly work.
   * After state is restored to initial values, changeDetected is set back to false.
   */
  useEffect(() => {
    if(changeDetected === true) {
      setTimeout(() => {
        setChangeDetected(false);
        setAnimationFinished(false);
      }, 10);
    }
  }, [changeDetected])

  /**
   * Currently, change is detected whenever the invertedBase64Icon is changed.
   * This logic takes care of remounting the AnimatedIcon component in order to achieve proper animation. (even if icon is changed quickly)
   */
  useEffect(() => {
    // make sure if another action was triggered, restart the animation for new inverted action icon (restart the whole state).
    setChangeDetected(true);
  }, [props.actionId])

  // extract default and inverted icon in base64 string from props
  const { base64Icon, _invertedBase64Icon, actionId } = props;
  const invertedBase64Icon = getImageSource(_invertedBase64Icon);

  // value guard
  if (
    // original Icon check vals
    hasSomeValButNotString(base64Icon) === true || base64Icon === 'temp.img' || base64Icon === '' ||
    // inverted Icon check vals
    hasSomeValButNotString(invertedBase64Icon) === true ||
    // actionId checks
    hasSomeValButNotString(actionId) === true || actionId === '' 
  ) {
    console.warn('Wrong prop value/type provided!');

    return (
      <FooterIcon>
        <QuestionMarkIcon />
      </FooterIcon>
    );
  }

  const renderAnimatedIcon = () => {
    // change is detected, render nothing.
    if(changeDetected === true) {
      return null;
    }
    // if empty string is provided it means that we still don't have the oppositeIcon set in db for the current icon.
    if(invertedBase64Icon === '') {
      return null;
    }

    // render only when animation is playing and not finished.
    if(animationFinished === false) {
      return (
        <AnimatedIcon iconBase64={invertedBase64Icon} setAnimationFinished={setAnimationFinished} />
      )
    } else {
      return null;
    }
  }

  return (
    <FooterIcon>
      {/* Main Icon */}
      <DefaultIcon iconBase64={base64Icon} />
      {/* Animated Overlayed Icon, shows only when animation is not finished */}
      {renderAnimatedIcon()}
    </FooterIcon>
  );
};

export { FooterActionIcon };
