/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

 import React, { useMemo } from 'react';
 import Skeleton from '@styles/Skeletons/Skeletons.module.css';
 // Uses MorphReply styles because it is simillar component to this one
 import Styles from '@styles/Skeletons/MorphEditSkeleton.module.css';
 import useLazyComponent from '@common/hooks/useLazyComponent';
 import { IErrand } from '@interfaces/Conversation';
 
 const MorphEditFallback = () => {
   return (
     <div className={Skeleton.loader + ' ' + Styles.style} />
   );
 };
 
 type TRenderMorphReplyProps = {
   errand: IErrand;
   cancelAction: (key: any, clear: boolean) => void;
 };
 
 const MorphEditSkeleton = ({
   errand, cancelAction
 }: TRenderMorphReplyProps) => {
   const renderMorphEdit = useMemo(() => {
     if (!errand || !errand._id) return false;
     return true;
   }, [errand?._id]);
 
   const MorphEditProps = {
     errand,
     cancelAction
   };
 
   const MorphEdit = useLazyComponent(
     import('@components/MorphEdit'), 
     <MorphEditFallback />, 
     `./src/Components/Skeletons/MorphEditSkeleton.tsx`
   );
 
   return renderMorphEdit ? (
     <MorphEdit {...MorphEditProps} />
   ) : <></>
 };
 
 export default MorphEditSkeleton;