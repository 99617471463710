import ThinClientUtils from './ThinClientUtils';
import axios from 'axios';

const recaptcha = async (token, action) => {
  let result = false;
  
  await axios({
    url: `${process.env.REACT_APP_MORGAN_CORE}/recaptcha`,
    method: "POST",
    data: {
      token: token,
    }
  })
  .then((r) => {

    // TODO Remove check for TC from conditional when TC recaptcha is fixed:
    
    // 3/8/2023 - Dennis, lower the score until we figure out why it fails on some devices

    // Ensure the action matches what we expect and the score is high enough.
    // Also, allow TC attempts to implicitly pass until TC recaptcha is
    // fixed:
    if(
        (r.data.data.action === action && r.data.data.score > 0.3) || 
        ThinClientUtils.isThinClient()) {
      result = true;
    }
  })
  .catch((error) => {
    console.warn("Error with recaptcha", error);
  });

  return result;
}

export default recaptcha;
