import { Box, styled } from '@mui/system';
import { Stack, Button, IconButton } from '@mui/material';
import { LeftDoubleArrow, RightDoubleArrow, MiddleButtonIcon } from '@assets/Icons';
import eventBus from '../Common/eventBus';
import { IRootContext, RootContext } from '@contexts/RootContext';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import React, { useContext } from 'react';
import { FormClickEventType, FormBodyScrollStateType, ElectronicSignatureEventType } from '../Forms/commonForms';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/MorphFormNavigateInitials.module.css';
import { Ballerina6 } from '@assets/Icons';


const StyledButton = styled(Button)<any>(
  ({ fontSize }) => `
  position: relative;
  z-index: 1001;
  font-size: ${fontSize ? fontSize : '0.8rem'};
  font-weight: normal;
  align-content: center;
  height: 1rem;
  color: var(--orange900);
  text-transform: none;
  white-space: nowrap;
  text-align: center;
  box-shadow: none;
  &:hover: {
    color: black;
  }`
);

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'var(--gray000)',
  border: '0.0625rem solid var(--peach800)',
  borderRadius: '0.5rem',
});

const MorphFormNavigateInitialsTopBox = ({ parentId }) => {
  const { t } = useTranslation();
  const rootContext = useContext<IRootContext>(RootContext);
  const errandContext = useContext<IErrandContext>(ErrandContext);

  const leftNavButton = () => {
    switch (errandContext.formBodyScrollState) {
      case FormBodyScrollStateType.StartEditSignature:
        return (
          <div className={`${Styles.sideArea} ${Styles.sideLeft}`}>
            <IconButton>
              <LeftDoubleArrow />
            </IconButton>
            <StyledButton className={Styles.navLeftButton}>{t('editSignature')}</StyledButton>            
          </div>
        )
      case FormBodyScrollStateType.Start:
        return (
          <div className={`${Styles.sideArea} ${Styles.sideLeft}`}>
            <Ballerina6 height={40} className={Styles.iconLeft} />
            <div className={`${Styles.navLeftButton} ${Styles.angelText}`}>{t('tWelcome')}</div>
          </div>
        )
      default: 
        return (
          <div className={`${Styles.sideArea} ${Styles.sideLeft}`}>
            <IconButton>
              <LeftDoubleArrow />
            </IconButton>
            <StyledButton className={Styles.navLeftButton}>{t('previousButton')}</StyledButton>
          </div>
        )
    }
  }

  const handleLeftNavClick = () => {
    if (errandContext.formBodyScrollState === FormBodyScrollStateType.StartEditSignature) {
      errandContext.newFormEvent(ElectronicSignatureEventType.EditSign);
    } else {
      eventBus.dispatch(FormClickEventType.NavPrev)
    }
  }

  return (
    <StyledWrapper className={Styles.morphFormNavInitialsWrapper}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={0}
        onClick={handleLeftNavClick}
        style={{ flex: 1 }}
      >
        {leftNavButton()}
      </Stack>

      <IconButton className={Styles.middleButton} onClick={() => rootContext.leaveFormGoToParentChat(parentId)}>
        <MiddleButtonIcon />
      </IconButton>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={0}
        onClick={() => eventBus.dispatch(FormClickEventType.NavNext)}
        style={{ flex: 1 }}
      >
        {errandContext.formBodyScrollState !== FormBodyScrollStateType.End ? (
          <div className={`${Styles.sideArea} ${Styles.sideRight}`}>
            <StyledButton className={Styles.navRightButton}>{t('nextButton')}</StyledButton>
            <IconButton>
              <RightDoubleArrow />
            </IconButton>
          </div>
        ) : (
          <div className={`${Styles.sideArea} ${Styles.sideRight}`}>
            <div className={`${Styles.navRightButton} ${Styles.angelText}`}>{t('reviewAgreement')}</div>
            <Ballerina6 height={40} className={Styles.iconRight} />
          </div>
        )}
      </Stack>
    </StyledWrapper>
  );
};

const MorphFormNavigateInitialsIndent = ({ parentId }) => {
  const { t } = useTranslation();
  const rootContext = useContext<IRootContext>(RootContext);
  return (
    <StyledButton fontSize='0.7rem' onClick={() => rootContext.leaveFormGoToParentChat(parentId)}>
      {t('buttonGoBackToChat')}
    </StyledButton>
  );
};

export { MorphFormNavigateInitialsTopBox, MorphFormNavigateInitialsIndent };
