import React from 'react';
import { useTheme } from '@mui/material';

import Sanitized from '@components/Sanitized';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import MessageTextStyle from '@styles/MessageTextStyle';

import type { FC } from 'react';
import type { MorganTheme } from '@mui/material';
import type { IMessage } from '@interfaces/Conversation';
import { AccessType } from '@common/AccessType';

type BubbleProps = {
  message: IMessage;
  originalText: string;
}

const Bubble: FC<BubbleProps> = ({ message, originalText }) => {
  const { palette } = useTheme<MorganTheme>();

  const rightIndentAlign = !(message.alignByCurrentUser)
    ? { direction: 'rtl', textAlign: 'left' }
    : {};

  return (
    <ChatBubbleStyle
      sx={{
        width: 'calc(fit-content + 15px)',
        justifyContent: message.alignByCurrentUser ? 'flex-end' : 'flex-start',
        borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
        background: message.accessType === AccessType.internal
        ? 'var(--peach020)'
        : 'var(--peach600)',
        p: '6px 7px 8px 9px',
        textIndent: '15px',
        ...rightIndentAlign,
      }}
      >
      <MessageTextStyle
        sx={{
          wordWrap: 'break-word',
          fontStyle: 'italic',
          color: palette.gray['600']
        }}
        >
        <Sanitized
          highlight={message?.searchWords ? message?.searchWords : undefined}
          html={originalText}
          />
      </MessageTextStyle>
    </ChatBubbleStyle>
  );
}

export default Bubble;