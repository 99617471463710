import React, {PropsWithChildren, useState } from 'react';
import { Stack} from '@mui/material';
import { useTranslation } from 'react-i18next';
import eventBus from '@common/eventBus';
import { getUserConsent, getUserConsentIntro } from '@storage/userStorage';
import { useRootContext } from '@contexts/RootContext';
import { MorphType } from '@common/MorphType';
import Styles from '@styles/ErrandsFooter.module.css';
import { Delete } from '@mui/icons-material';
import { SplitScreenIcon, SingleScreenIcon, PromptsIcon, AddIcon, ArchiveIcon, SettingsGearIcon } from '@assets/Icons';
import { UserWayAccessibilityMenu } from '@components/UserWayAccessibilityMenu';
import { updateChildErrands } from './MorphErrand';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import ThinClientUtils from '@common/ThinClientUtils';
import { useUserContext } from '@contexts/user';

const ErrandsFooter: React.FC<PropsWithChildren<any>> = (props: any) => {
  const { t } = useTranslation();
  const { _id } = useUserContext();
  const rootContext = useRootContext();
  const [isHoveringAccess, setIsHoveringAccess] = useState(false);
  const [isHoveringMenu, setIsHoveringMenu] = useState(false);
  const isShowingSplitScreenIcon = !isMobileOrTablet() && rootContext.getErrandsLength() > 1

  const handleSplitScreen = () => {
    if (getUserConsentIntro() !== "true") {
      eventBus.dispatch('showConsentIntro');
      return;
    }
    if (getUserConsent() !== "true") {
      eventBus.dispatch('showConsentContent');
      return;
    }

    // 5/4/2023 - bold: splitscreen removed
    if (props.selectedIndex.length === 1) {
      if (props.selectedIndex[0] !== 0) {
        // if the currently selected chat is not the main chat, set it to the main chat
        // set the secodnary chat to the old primary chat
        // set the selected indexes as needed
        props.setSelectedIndex((prev) => [0, prev[0]]);
      } else if (rootContext.getErrandsLength() > 1) {
        // set the secondary chat to the first errand
        // console.log({'props': props})
        props.setSelectedIndex([0, 1]);
      }
    } else if (props.selectedIndex.length === 2) {
      // remove splitscreen, so set the chatId to the main conversation
      // set the main conversation selector to the 0
      props.setSelectedIndex([0]);

      // remove previous errand messages
      // remove errand id
    } else {
      console.error("selectedIndex has more than 2 elements!");
    }
  };

  const handleOpenErrandNew = () => {
    if (getUserConsentIntro() !== "true") {
      eventBus.dispatch('showConsentIntro');
      return;
    }
    if (getUserConsent() !== 'true') {
      eventBus.dispatch('showConsentContent');
      return;
    } else {
      // hide the errands drawer on mobile devices. On desktop it is always visible
      rootContext.drawerRef.current?.click();
      rootContext.setRootMorphType((prev): MorphType => {
        // Get the ID of the errand that is currently selected or use the id of the primary errand if split screen
        // so that we know which screen to have the footer be morphed on
        const errand = props.errands[props.selectedIndex[props.selectedIndex.length - 1]];
        if (!errand) return prev;
        const primaryErrand = props.errands[0];
        if (!primaryErrand) return errand._id;
        rootContext.morphedId.current = props.selectedIndex.length === 2 ? primaryErrand._id : errand._id;
        if (prev === MorphType.ErrandNew) {
          return MorphType.None;
        }
        return MorphType.ErrandNew;
      });
    }
  };

  const handleOpenClosedErrands = async () => {
    if (getUserConsentIntro() !== "true") {
      eventBus.dispatch('showConsentIntro');
      return;
    }
    if (getUserConsent() !== 'true') {
      eventBus.dispatch('showConsentContent');
      return;
    } else {
      // hide the errands drawer on mobile devices. On desktop it is always visible
      rootContext.drawerRef.current?.click();

      // Update childErrands so it is most up to date when opening archive
      updateChildErrands(props.errands[0]._id, rootContext.setChildErrands, true, _id);

      rootContext.setRootMorphType((prev): MorphType => {
        // Get the ID of the errand that is currently selected or use the id of the primary errand if split screen
        // so that we know which screen to have the footer be morphed on
        const errand = props.errands[props.selectedIndex[props.selectedIndex.length - 1]];
        if (!errand) return prev;
        const primaryErrand = props.errands[0];
        if (!primaryErrand) return errand._id;
        rootContext.morphedId.current = props.selectedIndex.length === 2 ? primaryErrand._id : errand._id;
        if (prev === MorphType.Errand) {
          return MorphType.None;
        }
        return MorphType.Errand;
      });
    }
  };

  const handleThinClientSettings = async () => {
    window.dispatchEvent(
      new CustomEvent('openPreferencesEvent', {
        detail: {
          key: 'openPreferencesEvent',
        },
      })
    );
  }

  return (
    <Stack className={Styles.errandsFooterContainer}>
      <Stack
        className={Styles.errandsHoverBox}
        onMouseOver={() => setIsHoveringMenu(true)}
        onMouseOut={() => setIsHoveringMenu(false)}
      >
        <div className={Styles.centerContainer}>
          <div className={Styles.circle} onClick={() => {
            if (props.checkedErrands) return props.closeCheckedErrands();

            // if there is no selected index we need to open the user's default
            // conversation so that this function can work as expcted.
            props.setSelectedIndex((prev) => {
              const isNumber = typeof prev[0] === 'number';
              const isInRange = prev[0] > -1 && prev[0] < props.errands.length;
              if (isNumber && isInRange) {
                handleOpenErrandNew();
                return prev;
              }
              return [0];
            });
          }}>
            {props.checkedErrands ? (
              <Delete
                className={
                  rootContext.rootMorphType === MorphType.ErrandNew
                    ? Styles.cross
                    : Styles.plus
                }
              />
            ) : (
              <AddIcon
                className={
                  rootContext.rootMorphType === MorphType.ErrandNew
                    ? Styles.cross
                    : Styles.plus
                }
              />
            )}
          </div>
        </div>
        <div className={Styles.buttonsContainer}>
          {isShowingSplitScreenIcon && (
            <label
              className={`${Styles.label} ${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}
              onClick={handleSplitScreen}
            >
              {props.selectedIndex.length === 2 ? <SingleScreenIcon height={26} /> : <SplitScreenIcon height={26} />}
              <p className={isHoveringMenu ? Styles.moveUp : Styles.moveDown}>
                {props.selectedIndex.length === 2
                  ? t('conversationErrandsFooterSingle')
                  : t('conversationErrandsFooterSplit')}
              </p>
            </label>
          )}
          <label
            className={`${Styles.label} ${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}
            onClick={() => {
              // if there is no selected index we need to open the user's default
              // conversation so that this function can work as expcted.
              props.setSelectedIndex((prev) => {
                const isNumber = typeof prev[0] === 'number';
                const isInRange = prev[0] > -1 && prev[0] < props.errands.length;
                if (isNumber && isInRange) {
                  handleOpenClosedErrands();
                  return prev;
                }
                return [0];
              });
            }}
          >
            <ArchiveIcon height={26} />
            <p className={isHoveringMenu ? Styles.moveUp : Styles.moveDown}>{t('tArchive')}</p>
          </label>
          <label
            className={`${Styles.label} ${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}
            onClick={() => {
              if (getUserConsentIntro() !== "true") {
                eventBus.dispatch('showConsentIntro');
                return;
              }
              if (getUserConsent() !== 'true') {
                eventBus.dispatch('showConsentContent');
                return;
              }
              props.setShowUserPromptList(true);
            }}
          >
            <PromptsIcon height={26} />
            <p className={isHoveringMenu ? Styles.moveUp : Styles.moveDown}>{t('userPromptListTitle')}</p>
          </label>

          <label
            className={`${Styles.label} ${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}
            onMouseOver={() => setIsHoveringAccess(true)}
            onMouseOut={() => setIsHoveringAccess(false)}
          >
            {isMobileOrTablet() ? (
              <UserWayAccessibilityMenu color="inherit" height={26} />
            ) : isHoveringAccess ? (
              <UserWayAccessibilityMenu color="inherit" height={26} />
            ) : (
              <UserWayAccessibilityMenu color="inherit" height={26} />
            )}
            <p className={`${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}>
              {isMobileOrTablet() ? t('tAccess') : t('tAccessibility')}
            </p>
          </label>
          {ThinClientUtils.isThinClient() && (
            <label
              className={`${Styles.label} ${isHoveringMenu ? Styles.moveUp : Styles.moveDown}`}
              onClick={handleThinClientSettings}
            >
              <SettingsGearIcon />
              <p className={isHoveringMenu ? Styles.moveUp : Styles.moveDown}>{t('tSettings')}</p>
            </label>
          )}
        </div>
      </Stack>
    </Stack>
  );
};

export default ErrandsFooter;
