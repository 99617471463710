import React, { memo } from 'react';

import { DefaultElement } from './templates/DefaultElement';

import { DefaultIcon } from './templates/DefaultIcon';
import { isMobile } from '@common/deviceTypeHelper';
import { nonDraggableStyle } from '../Constants';
const EsignLogo = process.env.REACT_APP_MORGAN_CDN + '/Images/EsignLogo.png';

// '163px' desktop
// '125px' mobile
// width of the esign logo img on mobile and desktop
const imgWidth = isMobile() ? '73px' : '85px';

export const EsignElement = memo(() => {
  return (
    <DefaultElement
      icon={
        <DefaultIcon icon={EsignLogo} skipExtractSource={true} imgConfig={{ width: imgWidth, ...nonDraggableStyle }} />
      }
    />
  );
});
