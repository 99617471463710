import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { JoinInner } from '@mui/icons-material';

import { IErrand, IMessage } from '@interfaces/Conversation';
import { useRootContext } from '@contexts/RootContext';
import axiosCall from '@services/axios';
import useAbortController from '@common/hooks/useAbortController';

import Styles from '@styles/MorphMessageOptions.module.css';

type TMorphMessageOptionJoinProps = {
  errand: IErrand;
  message: IMessage;
};

const MorphMessageOptionJoin = ({
  errand, message
}: TMorphMessageOptionJoinProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const abortController = useAbortController();
  const rootContext = useRootContext();

  const handleAddParticipant = useCallback(async (chatId, message) => {
    try {
      // setup axios call with controller
      const request = {
        url: `chat/${chatId}/participant`,
        method: 'post',
        data: {
          userId: message.userId,
          userType: message.operatorView ? 'Operator' : 'User'
        }
      };
      const controller = abortController.reset();
      const config = { signal: controller.signal };
      await axiosCall(request, config);

      if (message.operatorView) {
        navigate('/console', {
          state: {
            module: 'conversations',
            tab: 'mine',
            chat: chatId,
          },
        });
      }
    } catch (error) {
      console.error(`MorphMessageOptionJoin.handleClickJoin`, error);
    }
  }, []);

  const handleClickJoin = useCallback(async (event) => {
    try {
      const chatId = (message.message.split('data-chat-id="')[1] || '').split('"')[0];

      if (message.operatorView) {
        handleAddParticipant(chatId, message);
        return;
      }

      rootContext.setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        const errandIndex = prev.findIndex((e) => e._id === chatId);
        if (errandIndex === -1 || typeof rootContext.setSelectedIndex !== 'function') {
          handleAddParticipant(chatId, message);
        } else {
          rootContext.setSelectedIndex((prev) => {
            if (!Array.isArray(prev) || prev?.length < 2) return [errandIndex];
            return [prev[0], errandIndex];
          });
        }
        return prev;
      });
    } catch (error) {
      console.error(`MorphMessageOptionJoin.handleClickJoin`, error);
    }
  }, [errand?._id, message?._id, message?.messageType, message?.message, handleAddParticipant]);

  return (
    <>
      <button className={Styles.btn} onClick={handleClickJoin}>
        <div className={Styles.btnBorder}>
          <JoinInner />
          <p className={Styles.label}>{t('tJoin')}</p>
        </div>
      </button>
    </>
  );
};

export default MorphMessageOptionJoin;
