import { Badge, Box } from '@mui/material';
import { useSocketContext } from '@contexts/socket';
import React, { useRef, useState, useEffect } from 'react';
import LinkPreviewStyle from '../Styles/LinkPreviewStyle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useUserContext } from '@contexts/user';
import axiosCall from '@services/axios';
import { AccessType } from '@common/AccessType';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { useRootContext } from '@contexts/RootContext';
import { ResetFooterUserAction } from '@common/common';

const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';
const desktopTab = `url("data:image/svg+xml,%3Csvg width='67' height='210' viewBox='0 0 68 211' fill='none' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cpath d='M0.5 106.623C7.50243 52.7488 58.0003 79.3756 67.5 0.5C67.5 210.5 67.5 210.5 67.5 210.5C58.0024 131.624 7.50244 158.251 0.5 106.623Z' fill='%23FF541C'/%3E%3C/svg%3E%0A")`;
const mobileTab = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='210' height='67' viewBox='0 0 210 67' fill='none' preserveAspectRatio='none'%3E%3Cpath d='M103.877 0C157.751 7.00243 131.124 57.5003 210 67C-2.98023e-06 67 -2.98023e-06 67 -2.98023e-06 67C78.8756 57.5024 52.2488 7.00244 103.877 0Z' fill='%23FF541C'/%3E%3C/svg%3E")`;

// react component to be exported
const LinkPreview = (props) => {
  const user = useUserContext();
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const { messagesSocket, isMessagesConnected } = useSocketContext();
  const isMobile = useMediaQuery('(max-width:900px)');
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [initial, setInitial] = useState({ x: 0, y: 0 });
  const [current, setCurrent] = useState(0);
  const [close, setClose] = useState(false);
  const zIndex = 290000000;
  const chatRef = useRef(props.chatId);

  //tell the child iframe that we don't want to display the morgan widget
  const appendEmbedded = (url) => {
    let urlObj = new URL(url);
    // Append the 'nomorgan' parameter
    urlObj.searchParams.append('embedded', 'true');
    urlObj.searchParams.append('angelai', 'true');
    return urlObj.toString();
}

  // defines the style for the Morgan logo, badge, and tab
  const getTagStyle = () => {
    const sharedStyles = {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'absolute',
      userSelect: 'none',
      zIndex: zIndex,
    }

    if (isMobile) {
      return {
        ...sharedStyles,
        alignItems: 'center',
        bottom: `0px`,
        cursor: 'pointer',
        flexDirection: 'column',
        height: `${current > 0 ? (current > 48 ? 48 + 42 : current + 42) : 42}px !important`,
        left: `0px`,
        opacity: close ? 0 : 1,
        padding: `10px 0 ${current > 0 ? (current > 48 ? 48 * .75 + 5 : current * .75 + 5) : 5}px 0`,
        transition: 'all .5s linear, opacity .5s linear 1.8s',
        width: '100%',
        '&:before': {
          backgroundImage: mobileTab,
          backgroundPosition: 'bottom center',
          backgroundSize: '100% 100%',
          bottom: '10px',
          content: '""',
          height: 'calc(100% - 10px)',
          left: '50%',
          position: 'absolute',
          transform: 'translateX(-50%)',
          width: '222px',
          zIndex: zIndex - 2,
        },
        '&:after': {
          backgroundColor: 'var(--peach900)',
          backgroundPosition: 'bottom left',
          backgroundSize: '100% 100%',
          bottom: '0',
          content: '""',
          height: '10px',
          left: `0px`,
          position: 'absolute',
          width: '100%',
          zIndex: zIndex - 1,
        },
        '& > span': {
          width: '42px',
          height: '42px',
          cursor: 'pointer',
          '& > div': {
            backgroundImage: `url(${AngelAi})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            height: '42px',
            width: '42px',
            zIndex: zIndex,
          },
          '& > span': {
            zIndex: zIndex,
            transform: 'translate(6px, -5px)',
          }
        },
      };
    }

    return {
      ...sharedStyles,
      flexDirection: 'row',
      height: '100%',
      opacity: close ? 0 : 1,
      padding: `0 ${current > 0 ? (current > 48 ? 48 + 5 : current + 5) : 5}px 0 10px`,
      right: `0px`,
      top: `0`,
      transition: 'all .5s linear, opacity .5s linear 1.8s',
      userSelect: 'none',
      width: `42px !important`,
      '&:before': {
        backgroundImage: desktopTab,
        backgroundPosition: 'center',
        backgroundSize: '100% 100%',
        content: '""',
        height: '222px',
        position: 'absolute',
        right: '9px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 'calc(100% - 10px)',
        zIndex: zIndex - 2,
      },
      '&:after': {
        backgroundColor: 'var(--peach900)',
        backgroundPosition: 'bottom left',
        backgroundSize: '100% 100%',
        content: '""',
        height: '100vh',
        position: 'absolute',
        right: `0px`,
        top: `0px`,
        width: '10px',
        zIndex: zIndex - 1,
      },
      '& > span': {
        cursor: 'pointer',
        height: '42px',
        userSelect: 'none',
        width: '42px',
        '& > div': {
          backgroundImage: `url(${AngelAi})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          height: '42px',
          width: '42px',
          zIndex: zIndex
        },
        '& > span': {
          zIndex: zIndex,
          transform: 'translate(6px, -5px)',
        }
      },
    };
  };

  // on touch start or on mouse down, initialize chat and set initial x, y coordinates.
  const handleStart = (x, y) => {
    setInitial({ x, y });
  };

  // on touch move or mouse move, define current value based on delta from initial.
  const handleMove = (x, y) => {
    if (isMobile) {
      setCurrent(initial.y - y);
      return;
    }
  };

  // on touch end or mouse up, open chat if current value is large or small enough.
  const handleEnd = (x, y) => {
    setInitial({ x: 0, y: 0 });
    setCurrent(0);
    if (initial.y === y || initial.y - y > 48) {
      setClose(true);
      setTimeout(() => {
        setUnreadMessages([]);
        props.setPreviewUrl('');
      }, 2300);
    }
    return;
  };

  useEffect(() => {
    const findLastEnvelopeMessage = () => {
      for (const message of props.errand.messages.slice().reverse()) {
        if (message?.action?.fieldAttribute?.description === 'ENVELOPE') {
          return message;
        }
      }
    };

    const resetFooter = () => {
      errandContext.setMorphType((prev) => {
        if (prev === MorphType.PrivateChat) return prev;
        return MorphType.None;
      });

      rootContext.setErrands((prev) => {
        const chatObj = prev.find((e) => e._id === props.errand?._id);

        if (chatObj) {
          ResetFooterUserAction(chatObj);
        }

        return [...prev];
      });
    };

    const handleChildIframeMessage = async ({ data }) => {
      console.log('Received data from child iframe:', data);

      if (data?.formName && data?.success === true) {
        const data = {
          sender: user?._id,
          senderType: "User",
          accessType: AccessType.system,
          message: "Signed",
          messageType: 'Field',
          userAction: findLastEnvelopeMessage()?.userAction?._id,
        };

        const payload = { url: `chat/${props.errand?._id}/message`, method: 'POST', data };

        // Inform the workflow that the document has been signed
        await axiosCall(payload);
        // Reset the conversation footer
        resetFooter();
        // Close the link preview
        setTimeout(() => {
          setClose(true);
          setUnreadMessages([]);
          props.setPreviewUrl('');
        }, 200);
      }
    };

    /*
    This event listener is for ai-sign. When the form is signed, the child iframe (ai-sign) sends a message to the
    parent (AngelAi) in the following format: { formName: string, loan: string, success: boolean, created: Date }.
    When 'success' is true, this means that the document has been successfully signed and we can relay that information
    back to the current workflow.
    */
    window.addEventListener('message', handleChildIframeMessage);

    return () => {
      window.removeEventListener('message', handleChildIframeMessage);
    };
  }, []);

  // when the chat changes, we must close the link preview
  useEffect(() => {
    if (props.chatId !== chatRef.current) {
      setClose(true);
      setTimeout(() => {
        setUnreadMessages([]);
        props.setPreviewUrl('');
      }, 2300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatId]);

  // socket event watchers to track unread messages
  useEffect(() => {
    if (!isMessagesConnected) return;

    const handleUnreadMessages = (payload) => {
      setUnreadMessages((prev) => {
        let index = prev.findIndex((x) => x?._id === payload?.data?._id);
        if (index === -1) {
          return [...prev, payload?.data];
        }
        return prev;
      })
    }

    messagesSocket.current?.on('chat-message-update', handleUnreadMessages);
    return () => {
      messagesSocket.current?.off('chat-message-update', handleUnreadMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, props.chatId]);

  // desktop mouse event watchers that allow users to move their mouse off the tab.
  useEffect(() => {
    const handleMouseUp = (event) => {
      if (initial.x === 0 && initial.y === 0) return;
      setInitial({ x: 0, y: 0 });
      setCurrent(0);
      if (initial.x === event.clientX || initial.x - event.clientX > 96) {
        setClose(true);
        setTimeout(() => {
          setUnreadMessages([]);
          props.setPreviewUrl('');
        }, 2300);
      }
    };

    // This event listener is listening for an event sent by morgan
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initial]);

  // return default tab first to maintain compatibility
  return (
    <LinkPreviewStyle className={close ? 'close' : ''}>
      <iframe
        className={close ? 'close' : ''}
        title="Morgan Link Preview"
        src={appendEmbedded(props.url)}
      />
      <Box
        sx={getTagStyle()}
        onMouseDown={(e) => handleStart(e.clientX, e.clientY)}
        onTouchStart={(e) => handleStart(e.changedTouches[0].clientX, e.changedTouches[0].clientY)}
        onTouchMove={(e) => handleMove(e.changedTouches[0].clientX, e.changedTouches[0].clientY)}
        onTouchEnd={(e) => handleEnd(e.changedTouches[0].clientX, e.changedTouches[0].clientY)}>
        <Badge
          badgeContent={[...new Set(unreadMessages)].length}
          max={9}
          color='error'
          sx={{ '& .MuiBadge-badge': { fontSize: 12, right: 4, top: 4 } }}>
          <div />
        </Badge>
      </Box>
    </LinkPreviewStyle>
  );
}

export default LinkPreview;
