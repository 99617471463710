import React, { useCallback, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { IErrand } from '@interfaces/Conversation';
import useInitialMount from '@common/hooks/useInitialMount';
import Styles from '@styles/LoanConditionsViewer.module.css';

type TLoanConditionsViewerProps = {
  errand: IErrand;
}


const LoanConditionsViewer = ({
  errand,
}: TLoanConditionsViewerProps) => {

  const [loading, setLoading] = useState(true);
  const [slowNetworkMessage, setSlowNetworkMessage] = useState(null);

  const displayNetworkMessage = useCallback(() => {
    setTimeout(() => {
      if (loading) {
        setSlowNetworkMessage("(Slow network detected. Please wait.)")
      }
    }, 4000)
  }, [loading]);

  useInitialMount(() => displayNetworkMessage())

  return (
    <div className={Styles.wrapper}>
      {errand?.preview ?
        <>
          {loading ?
            <div className={Styles.loadingStack}>
              <span>Fetching your loan conditions . . .</span>
              <span style={{ fontSize: '10px' }}>{slowNetworkMessage}</span>
              {slowNetworkMessage ?
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={Styles.right}
                    d="M2.0835 18.75L6.25016 22.9167C16.6043 12.5625 33.396 12.5625 43.7502 22.9167L47.9168 18.75C35.271 6.10417 14.7502 6.10417 2.0835 18.75Z"
                    fill="var(orange510)"
                  />
                  <path
                    className={Styles.mid}
                    d="M10.4165 27.0835L14.5832 31.2502C17.3466 28.489 21.0933 26.938 24.9998 26.938C28.9063 26.938 32.653 28.489 35.4165 31.2502L39.5832 27.0835C31.5415 19.0418 18.479 19.0418 10.4165 27.0835Z"
                    fill="var(orange510)"
                  />
                  <path
                    className={Styles.left}
                    d="M18.667 35.1513L24.917 41.4013L31.167 35.1513C30.347 34.3292 29.3729 33.6769 28.3004 33.2318C27.2279 32.7867 26.0782 32.5576 24.917 32.5576C23.7558 32.5576 22.6061 32.7867 21.5336 33.2318C20.4611 33.6769 19.487 34.3292 18.667 35.1513Z"
                    fill="var(orange510)"
                  />
                </svg> : null}
            </div>
            :
            null}
          <iframe
            title="Loan Conditions Viewing Window"
            onLoad={() => setLoading(false)}
            src={errand?.preview}
            className={loading ? Styles.loadingHide : Styles.loadingVisible}
          >
          </iframe>
        </>
        :
        <CircularProgress size="2em" />
      }
    </div>
  )
};

export default LoanConditionsViewer;