import React from 'react';
import Styles from '@styles/AngelAiLogoStyle.module.css';
const logo = process.env.REACT_APP_MORGAN_CDN + '/splash/AngelAiSplashLogo.png';

export const AngelAiLogo = () => {
  return (
    <>
      <img className={Styles.logo} src={logo} alt="AngelAi logo" />
    </>
  );
};
