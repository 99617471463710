import React, { useEffect } from 'react';
// Interfaces, Constants
import { WIDGET_ELEMENTS } from '../Config/Layouts';
import { IBaseTrackerProps } from '../interfaces';
import { DelayedActivityController } from '../Controllers';

let LOG_LEVEL = "DEFAULT"; // set to "DEBUG" for full logs
const log = (mark, ...args) => LOG_LEVEL === "DEBUG" ? console.log(`÷ WidgetTracker [ ${mark} ]`, ...args) : null;
export const WidgetTracker = (props: IBaseTrackerProps) => {
  // Expand Full Mode element.
  useEffect(() => {
    log("On Mount", "Calling props.handleActivityChange(WIDGET_ELEMENTS.ExpandFullMode, true)!");
    // Show indefinetely
    props.handleActivityChange(WIDGET_ELEMENTS.ExpandFullMode, true);

    return () => {
      log("unmount running! DelayedActivityController.fullReset()");
      DelayedActivityController.fullReset();
    }
  }, []);

  return props.UI;
};
