import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconStyles } from '../Constants';
import { DefaultElement } from './templates/DefaultElement';
const Shush = process.env.REACT_APP_MORGAN_CDN + '/Images/Shush.png';

export const PrivateModeElement = memo(() => {
  const { t } = useTranslation();

  return <DefaultElement text={t('AT_privateMode')} icon={<img src={Shush} style={{ ...IconStyles.privateMode }} />} />;
});
