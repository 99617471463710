import React from 'react';
import * as PullCreditMenu from '@components/PullCreditMenu/PullCreditMenu';
import SelectStyles from '@styles/Select.module.css';
import PullCreditMenuStyles from '@styles/PullCreditMenu.module.css';

interface Props {
  title: string;
  options: PullCreditMenu.Option[];
  value: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  missing: boolean;
  disabled: boolean;
}

/**
 * Select sub component.
 */
export default function Select(props: Props) {
  return (
    <div className={PullCreditMenuStyles.formItem}>
      <h2 className={PullCreditMenuStyles.h2}>{props.title}</h2>
      <select
        className={PullCreditMenu.getClassName([SelectStyles.select], props.disabled, props.missing)}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        <option value=""></option>
        {props.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
}
