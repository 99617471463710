/**
 * @file This component serves as an entry-point and parent-component of the various splash screens used by Morgan. It
 * is responsible for determining which splash screen should be rendered for the user. It also contains the
 * `onClick` event-handler callback for the various splash screens' 'Engage' button.
 *
 * @author Prakhar Paw
 * @author Christos Federigos
 * @author Ryan Buxton
 * @author Maverick Manasco
 * @author Dennis Borrero
 * @author Harrison Fales
 * @author Aaron Webb
 * @author Kristoffer A. Wright
 */

/*
========================================================================================================================
Imports
========================================================================================================================
*/

import { Splash } from '@components/Splash/Splash';
import Scheduler from '@components/Scheduler';
import Snowing from '@components/Snowing';
import React, { useState } from 'react';

/*
========================================================================================================================
Module constants
========================================================================================================================
*/
// const SPLASH_SCREEN_WIDTH_THRESHOLD = 900; // Screens of smaller width will use the mobile splash screen

/*
========================================================================================================================
Component definition
========================================================================================================================
*/

/**
 * This component is the parent-component of all splash screen components, and is the entry-point for the Morgan web
 * application.
 *
 * @returns The TSX for the `MorganWebHome` component.
 */
const MorganWebHome: React.FC<{}> = () => {
  /*
  ======================================================================================================================
  Component TSX
  ======================================================================================================================
  */

  return (
    <>
      {/* snowing dec 1 to dec 31 */}
      <Scheduler startDate={{month: 12, day: 1}} endDate={{month: 12, day: 31}}>
        <Snowing numSnowflakes={400} />
      </Scheduler>
      <Splash />
    </>
  );
};

export default MorganWebHome;
