import React, { useState } from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import getImageSource from '@common/getImageSource';
import DialogHeader from '@components/DialogHeader';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
    textTransform: 'capitalize',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: '1px solid var(--gray010)',
  },
}));

const ViewStepsDialog = (props) => {
  const { t } = useTranslation();

  const [filterOnlyFields, setFilterOnlyFields] = useState(false);

  const handleToggleFilterOnlyFields = () => setFilterOnlyFields(state => !state);

  return (
    <Dialog maxWidth="60vw" open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('statusLogTitle')} onClose={() => props.onClose()} />
      <DialogContent sx={{ paddingBottom: '0px' }}>
        <TableContainer
          sx={{ width: 'auto', maxWidth: '60vw', maxHeight: '80vh', border: '1px solid var(--gray010)' }}>
          <Table sx={{ height: 'auto' }} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('stepCol')}</StyledTableCell>
                <StyledTableCell>{t('icon')}</StyledTableCell>
                <StyledTableCell>{t('typeCol')}</StyledTableCell>
                <StyledTableCell>{t('fieldNameCol')}</StyledTableCell>
                <StyledTableCell>{t('fieldAttributeCol')}</StyledTableCell>
                <StyledTableCell>{t('descriptionCol')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(() => {
                const steps = filterOnlyFields
                  ? props.steps.filter(step => step.type === 'Field')
                  : props.steps;

                return steps.map((step, index) => (
                  <TableRow hover key={step?._id}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {step.actionIcon ? (
                        <img src={getImageSource(step.actionIcon)} alt="Field Icon" style={{ width: '24px', aspectRatio: '1/1'}} />
                      ) : (
                        <QuestionMarkIcon />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>{step.type}</StyledTableCell>
                    <StyledTableCell>{step.fieldName}</StyledTableCell>
                    <StyledTableCell>
                      {props.fieldAttributes?.filter((fa) => fa?._id === step.fieldAttribute)[0]
                        ?.description ?? ''}
                    </StyledTableCell>
                    <StyledTableCell>{step.description}</StyledTableCell>
                  </TableRow>
                ))
              })()}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleToggleFilterOnlyFields}>
          {t('filterOnlyFields')}
        </Button>
        <Button variant="console" onClick={() => props.onClose()}>
          {t('closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ViewStepsDialog;
