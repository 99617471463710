import { styled } from '@mui/system';
import getSvgFilter from '../Common/GetSvgFilter';
import { IconButton } from '@mui/material';

const style = ({ theme }) => `
cursor: pointer;
width: 40px;
min-width: 40px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
padding: 0;
overflow: visible;
border: none;
background-color: transparent;
color: var(--peach500);
&:hover, &:focus {
  color: var(--orange600);
  img {
    filter: ${getSvgFilter(theme.palette.orange['600'])} !important;
  }
}
svg {
  font-size: 25px;
  pointer-events: none;
  height: 25px;
  width: 25px;
}
img {
  height: 25px;
  width: 25px;
  filter: ${getSvgFilter(theme.palette.orange['500'])}
}
.label {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: var(--orange930);
  height: 72px;
  border-radius: 15px 15px 0 0;
  gap: 16px;
  &.open {
    display: flex;
  }
  & > label {
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border-radius: 6px;
    border: var(--orange020) 1px solid;
    cursor: pointer;
    background-color: ${theme.palette.peach['000']};
    & > input {
      display: none;
    }
  }
  & > button {
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    border-radius: 6px;
    border: var(--orange020) 1px solid;
    cursor: pointer;
    background-color: ${theme.palette.peach['000']};
  }
  & p {
    font-size: 7px;
    font-family: 'Poppins';
  }
}
`
export const Style: React.FC<any> = styled(IconButton)<any>(style);
export const Styles: React.FC<any> = styled('button')<any>(style);
