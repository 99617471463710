import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Sanitized from '@components/Sanitized';
import Styles from '@styles/ReferrerMessageContent.module.css';
import axiosCall from '@services/axios';
import { IErrand, IMessage } from '@interfaces/Conversation';
const dialer = process.env.REACT_APP_MORGAN_CDN + '/Images/dialer.gif';
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

type TReferrerMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const ReferrerMessageContent = ({
  errand, message, 
}: TReferrerMessageContentProps) => {
  const { t, i18n } = useTranslation();
  const data = useMemo(() => message.message.split('<>'), [message.message]);
  const fullname = useMemo(() => ((data[0] ? data[0].charAt(0).toUpperCase() + data[0].slice(1).toLowerCase() : '') + ' ' + (data[1] ? data[1].charAt(0).toUpperCase() + data[1].slice(1).toLowerCase() : '')).trim(), [data]);
  const label = useMemo(() => data[2] || '', [data]);
  // const lenderCompany = useMemo(() => data[3] || 'SUNWST000', [data]);
  const referrerId = useMemo(() => data[4], [data]);
  const referrerUserId = useMemo(() => data[5], [data]);
  const theme = useMemo(() => data[8], [data]);
  const invited = useMemo(() => data[9], [data]);
  const referrer = useMemo(() => errand.participants.find((p) => p?.active &&  p?.userData?._id === referrerId), [data, referrerId, errand.participants]);

  const response = useQuery([referrerUserId], async ({ signal }) => {
    if (!referrerUserId) return '';
    const request = {
      url: `user/loanOfficerDetailsAndImage/${referrerUserId}`,
    };
    const config = {
      signal,
    };
    return await axiosCall(request, config);
  });

  const messageVal = useMemo(() => {
    if (message.theme) {
      return message.theme;
    }
    if (theme) {
      return theme;
    }
    return t('referrerGreeting').replace(/{Referee}/, fullname);
  }, [fullname, theme, message?.theme, i18n.language]);

  return (
    <div
      className={Styles.wrapper + ' ' + (message.operatorView ? Styles.operator : '')}>
      {label && (
        <div className={Styles.badge + ' ' + (label.length === 2 ? Styles.animated : referrer || invited ? Styles.hide : '' )}>
          {/* <div className={Styles.invite}>{referrer || invited ? label : t('inviteUserDialogInvite')}</div> */}
          <div className={Styles.invite}>{label}</div>
          <div className={Styles.label}>{label}</div>
        </div>
      )}
      <span className={Styles.content}>
        <p className={Styles.sender}>AngelAi</p>
        <div className={Styles.container}>
          <Sanitized
            className={Styles.message}
            highlight={message.searchWords}
            html={messageVal}
            visible={message.visible}
            tag="p"
          />
        </div>
      </span>
      <span className={Styles.border}>
        <div className={Styles.image}>
          <link rel='preload' as='image' href={dialer} />
          <link rel='preload' as='image' href={AngelAi} />
          <img
            className={Styles.img + ' ' + (invited ? Styles.dialer : '')}
            src={
              invited ? dialer : response?.data?.image
                ? response?.data?.image
                : AngelAi
            }
            alt='Referrer portrait'
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null;
              target.src = AngelAi;
            }}
          />
        </div>
      </span>
    </div>
  );
};

export default ReferrerMessageContent;
