import React, { ForwardedRef, useCallback, useEffect, useRef, useState } from 'react';
import Styles from '@styles/MorphUserPromptsMenu.module.css';
import { ArrowDirection } from '../types';
import { notNull } from '../utils';
import { StaticClassNames } from './constants';
import { DynamicArrow } from './DynamicArrow';
import { useTranslation } from 'react-i18next';
const UserPromptsMenuArrowStatic = process.env.REACT_APP_MORGAN_CDN + '/Images/UserPromptsMenuArrowStatic.png';

type ArrowProps = {
  side: ArrowDirection;
  workflowWasSent: boolean;
  isSomeWfSelected: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onFocus: React.FocusEventHandler<HTMLDivElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
  onLostFocus: React.FocusEventHandler<HTMLDivElement>;
};

const InvisibleArrow = (props) => {
  const { t } = useTranslation();

  return (
    <img
      className={StaticClassNames.InvisibleArrow[props.side]}
      src={UserPromptsMenuArrowStatic}
      alt={t('UPM_User_prompts_menu_navigation_Arrow') + ` ${props.side === 'left' ? t('UPM_Left') : t('UPM_Right')}`}
    />
  );
};

export const Arrow = React.forwardRef((props: ArrowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const [renderAnimatedArrow, setRenderAnimatedArrow] = useState(false);
  const animationFinishedRef = useRef(true);
  const setAnimationFinishedRef = (val) => (animationFinishedRef.current = val);

  const { t } = useTranslation();

  const onAnimationEnd = () => {
    setRenderAnimatedArrow((_) => false);
  };

  const classArr = [
    props.workflowWasSent ? Styles.hidden : null,
    props.side === 'left' ? Styles.leftArrow : Styles.rightArrow,
    Styles.arrow,
  ].filter(notNull);
  const className = classArr.join(' ');

  const playAnimation = useCallback(() => {
    if (animationFinishedRef.current === true) {
      // start animation
      setAnimationFinishedRef(false);
      setRenderAnimatedArrow((_) => true);
    }
  }, []);

  useEffect(() => {
    playAnimation();
  }, []);

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    props.onKeyDown(e);

    if (e.code === 'Enter' || e.code === 'Space') playAnimation();
  };

  return (
    <div
      ref={ref}
      className={className}
      onClick={props.onClick}
      onMouseEnter={(e) => {
        playAnimation();
      }}
      onFocus={(e) => {
        props.onFocus(e);
        playAnimation();
      }}
      onBlur={props.onLostFocus}
      onKeyDown={onKeyDown}
      role={'button'}
      tabIndex={0}
      aria-label={
        `${t('UPM_Navigate_to_the_element_that_is_positioned_on_the_following_side')}` +
        ` ${props.side === 'left' ? t('UPM_Left') : t('UPM_Right')}`
      }
    >
      <InvisibleArrow side={props.side} />
      <DynamicArrow
        side={props.side}
        onAnimationEnd={onAnimationEnd}
        animationFinishedRef={animationFinishedRef}
        renderAnimatedArrow={renderAnimatedArrow}
      />
    </div>
  );
});
