import React from 'react';
import themeFile from '@assets/Themes/default.json';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// This component is used as a the loader for the Infinite Scroller in ConversationBody.
// Only two bubbles are displayed.


function MessagesSkeletonLoaderSmall() {

// Use MUI's media query to have different margins depending on screen size
    const theme = useTheme()

// This creates the skeleton for the ConversationBody box which holds all the messages
    const MessageBox = styled('div')({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'auto',
        width: '100%',
        zIndex: '5'
    })
    
// This is the div that holds the bubble skeletons
    const MessagesWrapper = styled('div')({
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
})

// This creates the skeleton for the speech bubble from Morgan
    const MorganBubbleSkeleton = styled('div')({
        backgroundColor: 'var(--peach200)',
        minHeight: '35px',
        minWidth: '100px',
        width: '20%',
        marginBottom: '20px',
        marginLeft: `${useMediaQuery(theme.breakpoints.up('md')) ? '60px' : '25px'}`,
        borderRadius: '8px',
        alignSelf: 'start',
    })

// This creates the skeleton for the speech bubble from the User
    const UserBubbleSkeleton = styled('div')({
        backgroundColor: 'var(--peach200)',
        minHeight: '35px',
        minWidth: '100px',
        width: '10%',
        marginBottom: '20px',
        marginRight: `${useMediaQuery(theme.breakpoints.up('md')) ? '60px' : '25px'}`,
        borderRadius: '8px',
        alignSelf: 'end',
        marginLeft: 'auto'
    })

    // Varying lengths of bubbles here on the left and right side of the screen to mimic a conversation
    return (
        <MessageBox>

            <MessagesWrapper>
                <MorganBubbleSkeleton sx={{width: '25%'}}/>
                                                                    <UserBubbleSkeleton sx={{width: '25%'}}/>
            </MessagesWrapper>
        </MessageBox>
    )
}

export default MessagesSkeletonLoaderSmall