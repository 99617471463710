/**
* @file This component is a stylized Typography component
* for generating Icons. 
* 
* Extracted from ConversationMessage.js 10/11/2022
* 
* @author Harrison Fales <harrison.fales@swmc.com>
* Original Component Author
* @author Kristoffer Wright <kristoffer.wright@swmc.com>
*/

import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const TranslationInfoStyle = styled(Typography)({
  cursor: 'pointer',
  paddingTop: '5px',
  fontSize: '0.50em',
  font: 'Poppins',
  alignSelf: 'center',
});

export default TranslationInfoStyle;