/**
* @file This component is a stylized Stack
* component used to render the button on the
* end of the Video component.
* 
* Extracted from ConversationMessage.js 10/11/2022
* 
* @author Harrison Fales <harrison.fales@swmc.com>
*/

import { Stack } from '@mui/material';
import { styled } from "@mui/system";

const WatchJoinButton = styled(Stack)({
  display: "flex",
  flexDirection: "column",
  minWidth: "75px",
  border: "1px solid lightgrey",
  textAlign: "center",
  justifyContent: "space-around",
  borderRadius: "10px",
  fontSize: "12px",
  padding: "15px 5px 15px 5px",
  cursor: "pointer",
  '&:hover': {
    border: "1px solid black"
  },
  '&:active': {
    border: "1px solid darkgrey"
  }
});

export default WatchJoinButton;