import React from 'react';
import Modal from '@mui/material/Modal';
import themeFile from '@assets/Themes/default.json';
import { styled } from '@mui/system';
import ParticipantsSkeletonLoader from './ParticipantsSkeletonLoader';
import MessagesSkeletonLoader from './MessagesSkeletonLoader';
import ErrandsSkeletonLoader from './ErrandsSkeletonLoader';

function ConversationSkeletonLoader() {

    const isDesktop = window.innerWidth > 900;
    const isDesktopTextField = window.innerWidth > 900;

// This creates the container for the entire box which holds all the skeletons
    const SkeletonContainer = styled(Modal)({
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        padding: `${isDesktop ? '5px' : '0px'}`,
        paddingTop: `${isDesktop ? '10px' : '0px'}`,
        zIndex: '1301 !important',
        background: 'var(--gray000) !important',
        // backgroundColor: `${isDesktop ? 'transparent' : `${themeFile.palette.peach['000']}`}`,
        '&>div[aria-hidden]': {
          display: 'none'
        }
    })

// This creates the container for everything on the left side of the conversation screen:
// ConversationTitle, ConversationBody, and Input Text Field
    const MessageSide = styled('div')({
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        margin: `${isDesktop ? '0px 10px 0px 5px' : '0px'}`,
        borderRadius: '0px 0px 8px 8px',
        overflow: 'hidden',
        backgroundColor: themeFile.palette.peach['000'],
    })

// This creates the skeleton for the conversationTitle (orange bar at the top)
    const ConversationTitle = styled('div')({
        backgroundColor: themeFile.palette.peach['800'],
        display: 'flex',
        alignItems: 'center',
        minHeight: `${isDesktop ? '59px' : '54px'}`,
        width: `${isDesktop ? '100%' : '112%'}`,
        // border: '2px solid red',
        borderBottomLeftRadius: '25px',   //25 and 16
        margin: `${isDesktop ? '-4px 0px 0px 0px' : '0px 0px 0px 58px'}`,
        position: 'relative',
        zIndex: '9999'
    })

// This creates the skeleton for the message box
    const MessageBox = styled('div')({
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        position: 'relative',
        bottom: '100px'
    })

// This creates the skeleton for the errand side of the screen
    const ErrandSide = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minWidth: '300px',
        alignSelf: 'start',
        marginRight: '5px',
        backgroundColor: 'var(--gray000)',
        borderLeft: `1px solid ${themeFile.palette.peach['800']}`,
        borderRight: `1px solid ${themeFile.palette.peach['800']}`

    })

    // This creates the skeleton for the errand banner
    const ErrandBanner = styled('div')({
        backgroundColor: themeFile.palette.peach['800'],
        height: '56px',
        minWidth: '300px',
        marginBottom: '1px'
    })

   
    

//There are 22 different layouts of Morgan that affects the size of the text field
// 1: > 900px -- Errand sidebar is displayed -- use isDesktop
// 3: < 900px -- Errand sidebar disappears

// This creates the skeleton for the typing text field
    const TextFieldWrapper = styled('div')({
        display: 'flex',
        position: 'absolute',
        bottom: '0px',
        left: '5px',
        alignItems: 'center',
        height: '90px',
        width: '100%',
        overflow: 'hidden',
        marginLeft: '5px',
        justifyContent: `${isDesktopTextField ? 'inherit' : 'end'}`,
    })
    
// This creates the skeleton for the typing text field
    const TextFieldSkeleton = styled('div')({
        backgroundColor: 'var(--gray000)',
        height: '44px',
        width: '100%',
        marginTop: `${isDesktopTextField ? '0' : isDesktop ? '13px' : '29px'}`,
        marginLeft: `${isDesktopTextField ? '31px' : isDesktop ? '14px' : '8px'}`,
        marginRight: `${isDesktopTextField ? '361px' : isDesktop ? '345px' : '29px'}`,
        borderRadius: '8px',
    })

    return (
        <SkeletonContainer open={true}>
          <>
            <MessageSide>
                <ConversationTitle>
                    <ParticipantsSkeletonLoader />
                </ConversationTitle>
                <MessageBox>
                    <MessagesSkeletonLoader />
                </MessageBox>
                <TextFieldWrapper>
                    <TextFieldSkeleton />
                </TextFieldWrapper>
                
            </MessageSide>
            {isDesktop && <ErrandSide>
                <ErrandBanner />
                <ErrandsSkeletonLoader />
            </ErrandSide>}
          </>
        </SkeletonContainer>
    )
}

export default ConversationSkeletonLoader