/**
 * @file    This file contains the definition of the `InvalidUserTypeError` exception class.
 * @author  Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

/*
========================================================================================================================
Imports
========================================================================================================================
*/

import MorganError from "./MorganError";

/*
========================================================================================================================
Class definition
========================================================================================================================
*/

/**
 * This exception should be thrown if a requested operation cannot be performed because the requester is not the right
 * user type.
 */
class InvalidUserTypeError extends MorganError {}

export default InvalidUserTypeError;