import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Styles from '@styles/MessageContent/PrivateMessageContent.module.css';
import { loadPreview } from '@common/loadPreview';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import useControllerCallback from '@common/hooks/useControllerCallback';
import { MorphType } from '@common/MorphType';
import { IErrand, IMessage } from '@interfaces/Conversation';
import Sanitized from '@components/Sanitized';
import { getAudienceString } from '@common/msgUtils';
const Shush = process.env.REACT_APP_MORGAN_CDN + '/Images/Shush.png';

type TPrivateMessageContent = {
  errand: IErrand;
  message: IMessage;
}

const PrivateMessageContent = ({
  errand, message
}: TPrivateMessageContent) => {
  const { t, i18n } = useTranslation();
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const [messagePreview, setMessagePreview] = useState('');

  const displayName = useMemo(() => {
    return getAudienceString(message.sender._id, message.intendedAudience, errand.participants, 'name', Boolean(message.operatorView), true, message.userId, false);
  }, [errand?.participants]);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    errandContext.setMorphType(MorphType.PrivateChat);
    rootContext.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let index = prev.findIndex((e) => e._id === message.chat);
      if (index === -1) return prev; // state was not modified, do not spread
      const audience = getAudienceString(message.sender._id, message.intendedAudience, errand.participants, 'id', Boolean(message.operatorView), false, message.userId, true).split(',').filter((v) => v !== '');
      if (audience.length === 0) return prev;
      prev[index].recipients = audience;
      return [...prev]; // spread to trigger dependency arrays as state was modified
    });
    errandContext.footerInputRef.current?.focus();
  }, [message?.updatedAt]);

  const getMessagePreview = useCallback(async (controller: AbortController) => {
    const preview = await loadPreview(message.chat, message.userId, controller);
    setMessagePreview(preview || '');
  }, [message?.updatedAt, i18n.language]);
  useControllerCallback(getMessagePreview);

  return (
    <button
      className={
        (Styles.wrapper + ' ') +
        (message.sentByCurrentUser ? Styles.sentByCurrentUser + ' ' : '') +
        (message.alignByCurrentUser ? Styles.alignByCurrentUser + ' ' : '')
      }
      onClick={handleClick}
    >
      <div className={Styles.message}>
        <div>{t('lastMessage')}</div>
        <Sanitized
          highlight={message.searchWords}
          html={messagePreview}
          visible={message.visible}
          tag="p"
        />
      </div>
      <div className={Styles.badge}>
        <img src={Shush} alt="Angel with index finger on lips asking you to keep her secrets" />
        <div className={Styles.name}>
          <div>{displayName}</div>
          <div>{t('openPrivateChat')}</div>
        </div>
        <time className={Styles.time} dateTime={new Date(message?.createdAt)?.toISOString()}>
          <div>{new Date(message?.createdAt)?.toLocaleString(i18n.language, { day: '2-digit', month: 'short' })}</div>
          <div>{new Date(message?.createdAt)?.toLocaleString(i18n.language, { hour: '2-digit', minute: '2-digit' })}</div>
        </time>
      </div>
    </button>
  );
};

export default PrivateMessageContent;
