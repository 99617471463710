import {
  Badge,
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { differenceInDays, format } from "date-fns";
import { enUS, es, ko } from "date-fns/locale";
import React, { useEffect, useState } from "react";

import Avatar from "./Avatar";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

/*
 *  This component is the message bubble seen on the right column of the console
 * dashboard screen. See the Figma for more: https://www.figma.com/file/5HrQkz2QKGTb97ZvTsqfOC/Dashbord?node-id=613%3A113
 *
 *  This component uses the following props:
 *    - img: URL to the users profile picture. Leave blank if no profile picture,
 *      the MUI avatar component will make a letter avatar with the users name.
 *    - alt: URL to an alt image or an alt description.
 *    - time: The time displayed in the top right corner of this component
 *    - name: The name of the user whose chat this belongs to
 *    - preview: The preview text of the last sent message
 */

const MessageBadgeMessages = (props) => {
  const { time, num, status, name, img, preview, onClick } = props;

  const { t, i18n } = useTranslation();
  const [timeString, setTimeString] = useState("");

  useEffect(
    () => {
      let days = differenceInDays(new Date(), new Date(time));
      let date = new Date(time);

      let locale;
      switch (i18n.resolvedLanguage) {
        case "en":
          locale = enUS;
          break;
        case "es":
          locale = es;
          break;
        case "ko":
          locale = ko;
          break;
        default:
          if (!locale) locale = enUS;
          break;
      }
      try {
        if (differenceInDays(new Date(), date) > 7) {
          // Month day, time
          setTimeString(format(date, "LLL do, p", { locale: locale }));
        } else if (days <= 7 && days > 1) {
          // day of week
          setTimeString(format(date, "E do, p", { locale: locale }));
        } else if (days === 1) {
          setTimeString(
            `${t("conversationBadgeYesterday")}, ${format(date, "p", { locale: locale })}`,
          );
        } else {
          setTimeString(format(date, "p", { locale: locale }));
        }
      } catch (e) {
        console.error("Error while calculating MessageBadge timeString: ", e);
        setTimeString("");
      }
    },
    // eslint-disable-next-line
    [time, i18n.resolvedLanguage],
  );

  return (
    <Card sx={{ width: "100%", marginTop: 2 }}>
      <CardContent sx={{ paddingBottom: "16px !important" }}>
        <Stack spacing={1}>
          {/* Top half, icon and date/time */}
          <Stack sx={{ display: "flex", flexDirection: "row" }}>
            <Stack sx={{ display: "flex", width: "fit-content" }}>
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={num > 1 ? num : 0}
                color="error"
              >
                <Avatar
                  status={status}
                  alt={name}
                  img={img}
                  sx={{ width: "30px", height: "30px" }}
                />
              </Badge>
            </Stack>
            <Stack
              sx={{
                display: "flex",
                flex: "1 1 auto",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "8px", color: "var(--gray200)" }}>
                {timeString}
              </Typography>
            </Stack>
          </Stack>

          {/* Bottom half, stack for name and preview and then a chevron! */}
          <Stack
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 40px",
              width: "inherit",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flex: "1 1 auto",
                alignItems: "flex-start",
                justifyContent: "center",
                maxWidth: "100%",
              }}
            >
              <Tooltip title={name}>
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: "12px",
                    width: "100%",
                    minWidth: `calc((100vw - 136px) / 4 - 114px)`,
                    maxWidth: `calc((100vw - 136px) / 4 - 114px)`,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {name.toLowerCase()}
                </Typography>
              </Tooltip>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "10px",
                  color: "var(--gray200)",
                  width: "100%",
                  minWidth: `calc((100vw - 136px) / 4 - 114px)`,
                  maxWidth: `calc((100vw - 136px) / 4 - 114px)`,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {preview}
              </Typography>
            </Stack>
            <Stack sx={{ display: "flex", width: "fit-content" }}>
              <IconButton onClick={onClick}>
                <ChevronRightIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MessageBadgeMessages;
