import { IErrand } from '@interfaces/Conversation';
import { SwiperClass } from 'swiper/react';
import React from 'react';

export const enum UserPromptsMenuState {
  WORKFLOW_NOT_SELECTED = 'WORKFLOW_NOT_SELECTED',
  WORKFLOW_SELECTED = 'WORKFLOW_SELECTED',
  WORKFLOW_FETCH_ERROR = 'WORKFLOW_FETCH_ERROR',
  WORKFLOW_FETCH_LOADING = 'WORKFLOW_FETCH_LOADING',
}
export type TUserPromptsMenuState = keyof typeof UserPromptsMenuState;
export type TUserPromptsMessageParsed = {
  message: string;
};

export type TTranslationsData = {
  en: string;
  es: string;
  ko: string;
};

export type TWorkflowData_UPmenu = {
  description: string;
  displayName: string;
  name: TTranslationsData;
  workflowIcon: string;
  _id: string;
};

export type TWorkflowDataFetched_UPmenu = {
  description: string;
  displayName: string;
  name: string;
  workflowIcon: string;
  _id: string;
};

// General
export interface MorphCreditRepairAccountTypeProps {
  cancelAction: (key: any, clear: boolean, withMorphTypeChange: boolean) => void;
  errand: IErrand;
}

// Arrows
export interface ArrowsProps {
  handleTabBackward: (disableNavigationBeyondBoundaries?: boolean) => void;
  handleTabForward: (disableNavigationBeyondBoundaries?: boolean) => void;
  focusElement: (idx: number) => void;
  addon: number;
  swiperInstance: SwiperClass | null;
  hasMounted: boolean;
  isLoading: boolean;
  selectedIdx: number | null;
  slideListItemsLength: number;
  resetState: () => void;
  focusedArrowIndex: number | null;
  setFocusedArrowIndex: React.Dispatch<number>;
  workflowWasSent: boolean;
}
export type ArrowDirection = 'left' | 'right';

// Swiper
export interface MainSwiperProps {
  addon: number;
  setAddon: React.Dispatch<React.SetStateAction<number>>;
  swiperInstance: SwiperClass | null;
  onSingleSlideClickHandler: (
    e: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
    idx: number
  ) => void;
  setFocusedArrowIndex: React.Dispatch<1 | -1 | 0 | null>;
  focusElement: (idx: number) => void;
  lastFocusedIndexRef: React.MutableRefObject<number | null>;
  isLoading: boolean;
  reachedEdge: TEdge;
  slideListItems: TWorkflowData_UPmenu[];
  setSwiperInstance: React.Dispatch<SwiperClass>;
  setReachedEdge: React.Dispatch<TEdge>;
  handleTabForward: (disableNavigationBeyondBoundaries?: boolean) => void;
  handleTabBackward: (disableNavigationBeyondBoundaries?: boolean) => void;
  style: {
    height: string;
    opacity: string;
  };
  handleSlideChange: (e: SwiperClass) => void;
}

export type TEdge = null | 'left' | 'right';

export const Edges = { left: 'left', right: 'right' } as const;