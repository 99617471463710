import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { FC }from 'react';

import { DialogPropsWithChildren } from '@mTypes/Console';
import DialogHeader from './DialogHeader';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AttendDialog: FC<DialogPropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open={props.attendedChat !== ''} onClose={() => props.setAttendedChat('')}>
      <DialogHeader title={t('attendDialogTitle')} onClose={() => props.setAttendedChat('')} />
      <DialogContent>  {/* p={5}  is this a paragraph */ }
        <Typography>{t('attendDialogMessage') }</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={() => props.setAttendedChat('')}>
          {t('closeButton')}
        </Button>
        <Button
          variant="contained" color="info"
          onClick={() => {
            navigate('/console', {
              state: {
                module: 'conversations',
                tab: 'live',
                chat: props.attendedChat,
              },
            });
            props.setAttendedChat('');
          }}>
          {t('attendDialogOpen')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AttendDialog;
