import { TextField } from "@mui/material";
import { PatternFormat } from "react-number-format";
import React, { FC } from 'react';

// Formatted phone number, using PatternFormat library component instead
// Can add more props as needed to customize this component
// Note that the format, mask, customInput, defaultValue, attributes are native to React-Number-Format
// More info found here: https://s-yadav.github.io/react-number-format/docs/props
// Almost everything else is from MUI (https://mui.com/material-ui/api/input-label/)

// export const PhoneNumberPatternField = ({ defaultValue, label, inputRef, onChange, required, disabled, onBlur}) => {
//   return (
//     <PatternFormat
//       format="(###) ###-####"
//       mask="_"
//       getInputRef={(ref) => {
//         if(ref && inputRef){ inputRef(ref.querySelector('input')); }
//       }}
//       customInput={TextField}
//       sx={{ marginTop: "12px" }}
//       required={required}
//       disabled={disabled}
//       size="small"
//       color="info"
//       variant="outlined"
//       label={label}
//       defaultValue={defaultValue}
//       onChange={onChange}
//       onBlur={onBlur}
//       InputLabelProps={{ shrink: true }}
//     />
//   );
// };


////////////////////////////////////////////////
//Everything below this is the Typescript version:

export interface PhoneNumberPatternFieldProps {
  defaultValue: number | string;
  value?: number | string;
  label: string;
  type?: phoneNumberType;
  inputRef?: any;
  onChange?: any;
  required?: boolean;
  disabled?: boolean;
  onBlur?: any;
}

type phoneNumberType =  'text' | 'tel' | 'password';

export const PhoneNumberPatternField: FC<PhoneNumberPatternFieldProps> = ({defaultValue, value, label, type, inputRef, onChange, required, disabled, onBlur }) => {
  return (
    <PatternFormat
      format="(###) ###-####"
      mask="_"
      getInputRef={(ref) => 
        {if(ref && inputRef){ inputRef(ref.querySelector('input')); }
      }}
      customInput={TextField}
      sx={{ marginTop: "12px" }}
      required={required}
      disabled={disabled}
      size="small"
      color="info"
      variant="outlined"
      label={label}
      defaultValue={defaultValue}
      value={value}
      type={type}
      onChange={onChange}
      onBlur={onBlur}
      InputLabelProps={{ shrink: true }}
    />
  );
};