import { Box } from '@mui/system';
import React, { forwardRef } from 'react';
import styled from '@emotion/styled';

const StyledDiv = styled.div`
box-shadow: var(--shadow100) 0px 5px 35px;
  align-self: center;
  font-size: 12px;
  @media (max-width: 540px) {

  }
  .optionContainer {
    display: ${(props) => (props.$wheelScrolled ? 'block' : 'none')};
    color: ${(props) => props.theme.wheelColor};
    opacity: 0.35;
    position: absolute;
    padding: 2.5px 10px;
    box-shadow: var(--shadow100) 0px 5px 35px;
    background: linear-gradient(90deg, var(--shadow502) 0%, var(--shadow000) 100%);
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (min-width: 541px) {
      left: 145px;
    }
    @media (max-width: 540px) {
      left: 50px;
      width: 300px;
    }
  }
  .optionTop {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (min-width: 541px) {
      bottom: 85px;
      max-width: 300px;
    }
    @media (max-width: 540px) {
      bottom: 90px;
      max-width: 200px;
    }
  }
  .optionBottom {
    bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (min-width: 541px) {
      max-width: 300px;
    }
    @media (max-width: 540px) {
      max-width: 200px;
    }
  }
  .wheelHolder {
    display: flex;
    align-items: center;
    padding: 0px;
  }
  .row {
    display: flex;
    flex-direction: row;
  }
  .side {
    cursor: text;
    width: ${(props) => (props.isDekstop ? '4px' : '3px')};
    @media (min-width: 541px) {
      height: 36px;
    }
    @media (max-width: 540px) {
      height: 32px;
    }
    background: linear-gradient(180deg, var(--gray520) 0%,  var(--gray520) 25%, var(--gray000) 40%,  var(--gray520) 55%, var(--gray520) 100%);

  }
  .lineWrapper {
    overflow: hidden;
    z-index: 999;
    position: relative;
  }
  .lines {
    cursor: grab;
    border-top: 1px solid;
    border-bottom: 1px solid;
    overflow-y: scroll;
    @media (min-width: 541px) {
      height: 36px;
    }
    @media (max-width: 540px) {
      height: 32px;
    }
    background: linear-gradient(180deg, var(--gray402) 0%, var(--gray000) 45%, var(--gray402) 100%);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .lines::-webkit-scrollbar {
    display: none;
  }
  .line {
    width: ${(props) => (props.isDesktop ? '10px' : '8px')};
    height: 2px;
    border-radius: 30px;
  }
`;

const LeftSide = styled(Box)({
  borderTopLeftRadius: '50% ',
  borderBottomLeftRadius: '50%',
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px'
});

const RightSide = styled(Box)({
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%'
});

const lines = new Array(1000).fill(0);

const ScrollWheel = forwardRef((props, ref) => {
  return (
    <StyledDiv id="Select" className="preventCancel" >
      <span id="optionTop" className="optionContainer optionTop preventCancel">
      </span>
      <span className="preventCancel wheelHolder">
        <div className="row preventCancel">
          <LeftSide className="side preventCancel" />
          <div className="lineWrapper preventCancel">
            <Box ref={ref} className="lines preventCancel" id="scroll">
              {lines?.map((line, index) => {
                return (
                  index % 2 !== 0 ?
                    (
                      <div
                        key={index}
                        className="line preventCancel"
                        style={{ backgroundColor: 'var(--gray600)' }}
                      />
                    ) :
                    <div key={index} className="line preventCancel" style={{ backgroundColor: 'transparent' }} />
                )
              }
              )}
            </Box>
          </div>
          <RightSide className="side preventCancel" />
        </div>
      </span>
      <span
        id="optionBottom"
        className="optionContainer optionBottom preventCancel"
      >
      </span>
    </StyledDiv>
  );
})

export default ScrollWheel