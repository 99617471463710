/**
 * @file This component is a stylized Box component
 * for styling the Video Message Content.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 * @author Harrison Fales <harrison.fales@swmc.com>
 * 
 */

 import { Dialog, useMediaQuery } from '@mui/material';
 import { Box, styled } from '@mui/system';
 
 export const MessageContent = styled(Box, {
   shouldForwardProp: (prop) => prop !== 'alignWithCurrentUser' && prop !== 'vod',
 })<any>(
   ({ theme, alignWithCurrentUser, islive, speakerdata }) => `
    display: flex;
    flex-direction: row;
    font-family: ${theme.typography.fontFamily};
    background-color: none;
    justify-content: ${islive ? 'center' : alignWithCurrentUser ? 'flex-end' : 'flex-start'};
    & .spinnerContainer {
        min-height: 178.75px;
        display: flex;
        align-items: center;
    }
    & .contentWrapper {
      display: flex;
      flex-direction: column;
    }
    & .mt {
      margin-top: 1.125rem;
    }
    & .video{
     display: flex;
     flex-direction: column;
     min-height: 178.75px;
    }
    & .videoFrame{
     display: flex;
     flex-direction: column;
     width: 100%;
     max-width: 355px
     max-height: fit-content;
     & iframe {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      border-radius: 8px;
     }
    }
    & iframe {
      margin: ${alignWithCurrentUser ? '0 0 0 auto' : '0'}
    }
    & .StreamBubbleStyle {
     display: flex;
     flex-direction: row;
     min-height: 90px;
     height: 140px;
     border-radius: 10px;
     box-shadow: var(--shadow100) 0px 5px 35px;
     position: relative;
     overflow: hidden;
     background-color: var(--gray000);
     justify-content: space-between;
     padding: 5px 5px 10px;
     border: 1px solid var(--orange700);
     margin-top: 18px;
     min-width: 150px;
     & .titleThumbnailBubbleColumn {
       display: flex; 
       flex-direction: column; 
       width: 100%;
     }
     & .titleThumbnailBubbleRow {
       display: flex; 
       flex-direction: row; 
       flex-grow: 2;
       max-height: 100%;
     }
      & .streamThumbnail {
        border: 1px solid var(--gray920);
        border-radius: 10px;
        width: 100px;
        max-width: 100px;
        height: 100%;
        max-height: 100%;
        object-fit: cover; 
      }
      & .titleAndTimerStack {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        & .videoTitle {
          font-weight: 800;
          max-height: 75%;
          overflow-y: hidden;        
        }
        & .videoLive {
          color: red;
          text-align: center;
          margin-left: -25px;     
          margin-top: auto;   
        }
        & .videoCountDown {
          color:  var(--orange700);
          text-align: left;  
          margin-bottom: auto;    
          font-weight: 900;
          font-size: 18px;
        }
      }    
    }
   & .bubbleHolder {
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
     align-items: center;
     height: ${speakerdata ? '45px' : 'none'};
     flex-wrap: wrap;
     flex-grow: ${speakerdata ? '3' : '0'};
     & img {
       border-radius: 50%;
       width: 30px;
       height: 30px;
       margin: 1px;
       cursor: pointer;
       border: 1px solid grey;
     }
     & .extraSpeakerBubble {
       background-color: var(--gray600);
       color: var(--gray400);
       border-radius: 50%;
       width: 30px;
       height: 30px;
       margin: 1px;
       border: 1px solid grey;
       text-align: center;
       display: flex;
       justify-content: center;
       align-items: center;
       font-size: 16px;
       font-weight: 600;
       cursor: pointer;
     }
   }
   & .bubbleHolderFull {
     display: none;
     flex-direction: row;
     justify-content: flex-start;
     flex-wrap: wrap;
     padding-top: 4px;
     & img {
       border-radius: 50%;
       width: 30px;
       height: 30px;
       margin: 1px;
       cursor: pointer;
       border: 1px solid grey;
     }
   }
   & .WatchJoinButton {
      display: flex;
      flex-direction: column;
      min-width: 75px;
      border: 1px solid var(--gray600);
      text-align: center;
      align-items: center;
      justify-content: space-around;
      border-radius: 10px;
      font-size: 12px;
      padding: 15px 5px 15px 5px;
      cursor: pointer;
      &:hover {
        border: 1px solid var(--gray920);
      },
      &:active {
        border: 1px solid var(--gray600);
      }
    }
    & .RotateButtonContainer {
      display: flex;
      justify-content: center;
      margin: auto;
      flex-wrap: multi-line;
      margin-top: -1px;
      border-radius: 0px 0px 30px 30px;
      box-shadow: var(--shadow100) 0px 5px 35px;
      position: relative;
      background-color: var(--gray000);
      width: 60px;
      height: 35px;
      border-bottom: 1px solid var(--orange700);
      border-left: 1px solid var(--orange700);
      border-right: 1px solid var(--orange700);
      border-top: 2px solid var(--gray000);
      & div:nth-of-type(1) {
        position: absolute;
        top: -5px;
        left: 1px;
        height: 10px;
        width: 57px;
        background-color: var(--gray000);      
      }
      & div:nth-of-type(2) {
        display: flex;
        justify-content: center;
        margin: auto;
        flex-wrap: multi-line;
        margin-top: -10px;
        padding-top: 5px;
        cursor: pointer;
        border-radius: 50%;
        box-shadow: var(--shadow100) 0px 5px 35px;
        position: relative;
        background-color: var(--green510);
        width: 35px;
        height: 35px;      
      }
    }
  `
 );
 
 export const SpeakerModal = styled(Dialog)(
   ({ theme }) => `
   display: flex;
   justify-content: center;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   min-height: fit-content;
   background-color: var(--gray000);
   border-radius: 20px;
   border: 1px solid var(--orange700);
   border-bottom: none;
   box-shadow: 24;
   padding: 6px 7px 8px 9px;
   width: ${useMediaQuery(theme.breakpoints.up('md')) ? 'initial' : '88%'};
   height: ${useMediaQuery(theme.breakpoints.up('md')) ? 'initial' : '60%'};
   max-height: ${useMediaQuery(theme.breakpoints.up('md')) ? 'initial' : '60%'};
   margin-top: 0px;
   & div {
     margin: 0px 5px 5px 5px;
     position: relative;
     height: 100%;
     & div {
       width: 100%;
       box-shadow: none;
       overflow: inherit;
     }
     & .bottomNotch {
       pointer-events: none;
       position: fixed;
       left: calc(50% - 50px);
       &::before,
       &::after {
         content: "";
         display: block;
         position: fixed;
         bottom: -50px;
         height: 70px;
         background-color: var(--gray000);
       }
       &::before {
         border-bottom: 1px solid var(--orange700);
         border-left: 1px solid var(--orange700);
         left: 0;
         right: calc(50% + 39px);
         border-bottom-left-radius: 20px;
         margin-left: -1px;
         box-shadow: var(--shadow100) -10px 15px 15px;
       }
       &::after {
         border-bottom: 1px solid var(--orange700);
         border-right: 1px solid var(--orange700); 
         right: 0;
         left: calc(50% + 42px);
         border-bottom-right-radius: 20px;
         margin-right: -1px;
         box-shadow: var(--shadow100) 10px 15px 15px;
       }
       & .circle {
         display: block;
         width: 83px;
         height: 60px;
         bottom: -50px;
         left: calc(50% - 40px);
         overflow: hidden;
         position: fixed;
       }
       & .circle:before {
         content: '';
         width: 80px;
         height: 100px;
         border-radius: 100px;
         background: var(--shadow000);
         position: absolute;
         bottom: -100px;
         left: -39px;
         border: 40px solid var(--gray000);
       }
       & .circle:after {
         content: '';
         width: 80px;
         height: 100px;
         border-radius: 100px;
         background: var(--shadow000);
         position: absolute;
         bottom: -62px; 
         left: 0px;
         overflow: hidden;
         border: 1px solid var(--orange700);
       }
       & .speakerPhoto {
         height: 70px;
         width: 70px;
         border-radius: 50%;
         position: fixed;
         bottom: -88px;
         left: calc(50% - 34px);
         background-color: var(--gray000);
         border: 1px solid var(--orange700);
         box-shadow: var(--shadow100) 0px 5px 35px;
       }
     }
     & .modalContent {
       direction: flex;
       flex-direction: column;
       justify-content: space-around;
       align-items: center;
       text-align: center;
       position: relative;
       margin-bottom: 20px;
       border: none;
       height: 100%;
       & h2 {
         margin: 0px 0px 0px 0px;
         font-size: ${useMediaQuery(theme.breakpoints.up('md')) ? 'initial' : '16px'};
       }
       & h4 {
        font-size: ${useMediaQuery(theme.breakpoints.up('md')) ? 'initial' : '13px'};
         margin: 10px 0px 10px 0px;
         max-height: ${useMediaQuery(theme.breakpoints.up('md')) ? 'initial' : '34px'};
         overflow-y: auto;
         scrollbar-width: thin;
         padding-right: 5px;
         &::-webkit-scrollbar {
           width: 5px;
         }
         &::-webkit-scrollbar-track {
           box-shadow: inset 0 0 6px var(--shadow000);
           webkit-box-shadow: inset 0 0 6px var(--shadow000);
         }
         &::-webkit-scrollbar-thumb {
           background-color: var(--shadow110);
           outline: 1px solid slategrey;
           border-radius: 0.2em;
         }
        }
       & .detailsContainer {
         overflow-y: auto;
         height: fit-content;
         max-height: 80%;
         z-index: 999;
         scrollbar-width: thin;
         padding-right: 5px;
         margin-left: 0px;
         &::-webkit-scrollbar {
           width: 5px;
         }
         &::-webkit-scrollbar-track {
           box-shadow: inset 0 0 6px var(--shadow000);
           webkit-box-shadow: inset 0 0 6px var(--shadow000);
         }
         &::-webkit-scrollbar-thumb {
           background-color: var(--shadow110);
           outline: 1px solid slategrey;
           border-radius: 0.2em;
         }
         & p {
           font-size: 15px;
           margin-bottom: 0px;
           height: 100%;
           max-height: 100%;
           font-size: ${useMediaQuery(theme.breakpoints.up('md')) ? '15px' : '12px'};
         }
       }
       & .iconContainer {
         display: flex;
         justify-content: flex-end;
         margin: 0px;
         height: 15px;
         & svg {
           height: 20px;
         }
       }
     }
   }
   `
 );