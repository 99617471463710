import { Stack, TextField, Typography, useTheme } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {InputAdornment} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { LoaderIcon } from '@assets/Icons';
import containsEmail from '@common/containsEmail'
import { CheckLocationInformationExistenceInLocalStorage } from '@common/LocationInformation';
import { removeOperatorSession, setOperatorSession } from '@storage/operatorStorage';
import { getOperatorAccessLevel } from '../Storage/operatorStorage';

const Text = styled(Typography)(() => ({
  color: 'var(--gray000)',
  textAlign: 'left',
  marginLeft: '5%',
  fontSize: 20,
  fontWeight: 700,
  lineHeight: 1.5,
}));

const InputTextField = styled(TextField)(() => ({
  width: '90%',
  margin: '5% 5% 0 5%',
}));

const FormWrapper = styled.div`
  border-top-left-radius: 30px;
  border-bottom-right-radius: 30px;
  width: 80%;
  text-align: center;
  box-shadow: 0 0 10px 1px grey;
`;

const SubmitButton = styled.button`
  margin: 5%;
  width: 20%;
  height: 35px;
  color: var(--gray000);
  border: none;
  border-radius: 5px;
`;
const LoadIcon = styled(LoaderIcon)(() => ({
  height: '100%',
  width: 20,
}));

// This is the login form
const LoginForm = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { hashkey } = useParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const {setError, systemStatus} = props;

  // If system status is Downtime or Maintenance
  // button should be disabled and cursor 'not-allowed'
  const isSystemDown = getOperatorAccessLevel() !== 'Admin' && (systemStatus === 'Downtime' || systemStatus === 'Maintenance');

  /**
   * This function toggles the icon for show password
   */
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (user, password) => {
    try {
      setLoading(true);
      removeOperatorSession();

      const locationData = await CheckLocationInformationExistenceInLocalStorage();

      const result = await axios(`${process.env.REACT_APP_MORGAN_CORE}/authenticate/operator`, {
        method: 'POST',
        data: {
          email: containsEmail(user) ? user : undefined,
          username: containsEmail(user) ? undefined : user,
          password,
          ...locationData
        },
      });

      if (result?.data !== undefined || result?.data !== null) {
        // This function will store the login details inside localforage
        setOperatorSession(result.data.accessToken, result.data.operatorId, result.data.sessionToken, result.data.accessLevel);

        // if hashkey exists keep it in the URL
        navigate(`/console${hashkey ? `/${hashkey}` : ''}`, {
          state: {
            module: 'dashboard',
            tab: 'msg',
          },
        });
      }
      else {
        setError({
          active: true,
          message: t('loginErrorOther'),
        });
      }
    }
    catch (error) {
      // @ts-ignore
      switch (error?.response?.status) {
        case 401:
        case 402:
          setError({ active: true, message: t('loginErrorInvalid') });
          break;
        default:
          setError({ active: true, message: t('loginErrorOther') });
          break;
      }
      console.error(error);
    }
    finally {
      setLoading(false);
    }
  };

  const getCursorStyle = () => {
   let style = loading ? 'progress' : 'pointer';

   if(isSystemDown) {
    style = 'not-allowed';
   }

   return style;
  }

  return (
    <FormWrapper style={{ backgroundColor: theme.palette.blue['960'] }}>
      <Stack>
        <Stack>
          <Text variant="h6" sx={{ marginTop: '3%' }}>
            {t('loginFormMessageTop')}
          </Text>
          <Text variant="h6" sx={{ marginBottom: '3%' }}>
            {t('loginFormMessageBottom')}
          </Text>
        </Stack>
        <Stack sx={{ background: 'var(--gray000)', borderBottomRightRadius: '30px' }}>
          <form>
            <InputTextField
              value={username}
              autoComplete="on"
              onChange={(e) => setUsername(e.target.value)}
              size="small"
              label={t('loginFormUsername')}
            />
            <InputTextField
              value={password}
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  if(isSystemDown) return;

                  handleSubmit(username, password);
                }
              }}
              size="small"
              label={t('loginFormPassword')}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <Tooltip title={showPassword ? t('hidePassword') : t('showPassword')} placement="top">
                    <InputAdornment position="end"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </InputAdornment>
                  </Tooltip>
                )
              }}
            />
          </form>
          <SubmitButton
            disabled={loading || isSystemDown}
            style={{
              backgroundColor: theme.palette.blue['960'],
              cursor: getCursorStyle(),
            }}
            onClick={() => handleSubmit(username, password)}
            autoFocus>
            {loading ? <LoadIcon /> : t('loginFormSubmit')}
          </SubmitButton>
        </Stack>
      </Stack>
    </FormWrapper>
  );
};

export default LoginForm;
