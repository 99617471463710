import { Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';

import axios from 'axios';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Home from '@styles/OrderAndUnsubscribeStyle';
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

const Unsubscribe : React.FC<{}> = () => {
  const [smsNotifications, setSmsNotifications] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [message, setMessage] = useState(undefined);
  const { hashkey } = useParams();
  const { t } = useTranslation();

  const handleSubmit = async () => {
    axios({
      url: `${process.env.REACT_APP_MORGAN_CORE}/user/unsubscribe/${hashkey}`,
      method: 'post',
      data: {
        unsubscribeEmail: emailNotifications,
        unsubscribeSms: smsNotifications
      }
    }).then((res) => {
      if (res.data){
        setMessage(t('unsubscribeSuccessful'));
      } else {
        setMessage(t('unsubscribeFail'));
      }
    }).catch(() => {
      setMessage(t('unsubscribeFail'));
    });
  }

  return (
    <Home>
      <span/>
      <span/>
      <main>
        <Card>
          <CardContent>
            <Stack spacing={2} alignItems="center">
              <img src={AngelAi} className="morgan-logo" alt="AngelAi logo" />
              {
                message ?
                  <Typography> {message} </Typography>
                  :
                  <FormGroup>
                    <Typography> {t('unsubscribeTitle')} </Typography>
                    <FormControlLabel control={
                      <Checkbox
                        checked={smsNotifications}
                        onChange={(e) => setSmsNotifications(e.target.checked)} />
                    } label={t('unsubscribeSmsLabelUser')} />
                    <FormControlLabel control={
                      <Checkbox
                        checked={emailNotifications}
                        onChange={(e) => setEmailNotifications(e.target.checked)} />
                    } label={t('unsubscribeEmailLabelUser')} />
                    <Button
                      variant="contained"
                      sx={{ color: 'var(--gray000)' }}
                      onClick={handleSubmit}>
                      {t('unsubscribeSaveButton')}
                    </Button>
                  </FormGroup>
              }
            </Stack>
          </CardContent>
        </Card>
      </main>
    </Home>
  );
}

export default Unsubscribe;
