/**
 * This is a reusable Dialog component that allows for customization
 * depending on the props passed to it.
 * 
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */

import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  // Slide,
} from '@mui/material';
import Sanitized from './Sanitized';
import StyledDialog from '../Styles/StyledDialog'
import { CameraAltOutlined, Mic } from '@mui/icons-material';
import ThinClientUtils from '@common/ThinClientUtils';

const ChatFooterModal = ({
  // Destructured props to reduce the use of props.<whatever>
  iconType, // 'cam' || 'mic'
  modalType, // 'consent' || 'permissionReminder'
  isOpen, // boolean
  setIsOpen, // state setter
  disableClickAway, // boolean
  showCloseButton, // boolean
  title, // string
  contentText, // string
  action1, // string
  handleAction1, // func
  action2, // string
  handleAction2, // func
}) => {
  const { t } = useTranslation();

  /**
   * Closes modal conditionally
   * @param {Event} event
   * @param {string} reason
   * @returns {void}
   */
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' && disableClickAway) {
      // Don't close on backdrop click if disableClickAway is true
      return
    }
    // Close normally otherwise
    setIsOpen(false)
  }

  // Emits an event for thin client to open the user's settings app
  const handleClick = () => {
    window.dispatchEvent(
      new CustomEvent('openSettingsEvent', {
        detail: {
          key: 'openSettingsEvent',
        },
      })
    );
  };

  return (
    <StyledDialog
      disablePortal
      modalType={modalType}
      open={isOpen}
      // TransitionComponent={Transition} // caused the whole page to slide up with the dialog
      disableEscapeKeyDown={true}
      onClose={handleClose}
      style={{ position: 'absolute' }}
      BackdropProps={{ style: { position: 'absolute' } }}
    >
      <div>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {ThinClientUtils.isThinClient() && modalType === 'permissionReminder' ? (
              <>
                {t('grantPermission?')},{' '}
                <a href="javascript:void(0);" onClick={handleClick}>
                  {t('clickToOpenSettings')}
                </a>
              </>
            ) : (
              <Sanitized html={contentText} />
            )}
          </DialogContentText>
        </DialogContent>

        <hr width='100%' />

        <DialogActions>
          {showCloseButton && <Button onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="156" height="46" viewBox="0 0 156 46" fill="none">
              <path d="M151.47 17.25C151.47 15.7587 153.639 15.5687 154.7 16.6164C155.503 17.4092 156 18.5045 156 19.7143V36C156 41.5228 151.523 46 146 46H10C4.47715 46 0 41.5229 0 36V19.7143C0 17.2948 1.98665 15.3334 4.43733 15.3334H109.702C110.767 15.342 111.72 16.1968 111.72 17.25C112.696 27.2385 121.222 35.0476 131.595 35.0476C141.968 35.0476 150.493 27.2385 151.47 17.25V17.25Z" fill="var(--orange700)" />
              <rect width="31" height="31" rx="15.5" transform="matrix(-1 0 0 1 147.264 0)" fill="var(--orange700)" />
              {!['mic', 'cam'].includes(iconType) &&
                <>
                  {/* display the down arrow if iconType isn't set */}
                  <path d="M138.576 18.6325L132 25.2083L125.424 18.6325" stroke="var(--gray000)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M132 6.79166V25.0242" stroke="var(--gray000)" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />

                </>
              }
            </svg>
            {iconType === 'mic' && <Mic style={{ transform: 'translate(120px, 4px)' }}  htmlColor='var(--gray000)' />}
            {iconType === 'cam' && <CameraAltOutlined style={{ transform: 'translate(120px, 4px)' }}  htmlColor='var(--gray000)' />}

            <span>{t('closeButton')}</span>
          </Button>}
          {action1 && <Button sx={{textTransform: 'none'}} onClick={handleAction1}>{action1}</Button>}
          {action2 && <Button sx={{textTransform: 'none'}} type='submit' onClick={handleAction2}>{action2}</Button>}
        </DialogActions>
      </div>

    </StyledDialog>
  )
}

export default ChatFooterModal;