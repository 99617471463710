/**
 * @file This Component is used for generating notifications
 * within the ConversationBody component. It had been extracted from
 * where it previously was being rendered; within the ConversationMessage
 * component.
 *
 * @author Harrison Fales <harrison.fales@swmc.com>
 * Original Component Author
 * @author Christos Ferderigos <christos.ferderigos@swmc.com>
 */
import React from 'react';
import Sanitized from '@components/Sanitized';
import tStyles from '@styles/Typography.module.css';
import { IMessage } from '@interfaces/Conversation';

type TNotificationMessageContentProps = {
  message: IMessage;
};

const NotificationMessageContent = ({
  message, 
}: TNotificationMessageContentProps) => {
  return message && (
    <Sanitized
      className={[tStyles.base, tStyles.taCenter, tStyles.mbSmall, tStyles.mtLarge, tStyles.mAuto, tStyles.fsSmall, tStyles.lhNarrow].join(' ')}
      html={message.message}
      visible={message.visible}
      tag="p"
    />
  );
};

export default NotificationMessageContent;
