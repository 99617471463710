import React, { useState, useEffect, useRef } from 'react';
import Styles from '@styles/OTPInputStyles.module.css';
import useInitialMount from '@common/hooks/useInitialMount';
import { useFooterContext } from '@contexts/FooterContext';
import useDebounce from '@common/hooks/useDebounce';

function OTPInput({ handleSubmit }) {
  const localOtp = ['', '', '', ''];
  const [errorOn, setErrorOn] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const overInputRef = useRef(null);
  const [hideCaret, setHideCaret] = useState(false);
  const footerContext = useFooterContext();


  // Autofocus on the first input when the component is rendered
  const handleFirstFocus = () => {
    if (overInputRef.current) {
      overInputRef.current.focus();
    }
  };

  useInitialMount(() => handleFirstFocus());

  useEffect(() => {
    if (footerContext.handleOtpError && otpValue && otpValue.length !== 4) {
      handleError(otpValue);
    }
    else {
      if (otpValue?.length === 4) {
        footerContext.chatInputFieldRef.current?.update(otpValue || '');
        setTimeout(() => {
          handleSubmit();
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerContext.handleOtpError])

  // Clicking on the entire input bar should focus on the last filled input field
  const handleWrapperClick = () => {
    setActiveIndex(otpValue.length);
    overInputRef.current.focus();

    const inputValueLength = otpValue.length;
    overInputRef.current.setSelectionRange(inputValueLength, inputValueLength);

    if (otpValue.length === 4) {
      setActiveIndex(3);
      overInputRef.current.setSelectionRange(inputValueLength + 1, inputValueLength + 1);
    }
  };

  const handleError = (data) => {
    console.error('Invalid input: ' + data);
    setErrorOn(true);
    setTimeout(() => {
      setErrorOn(false);
      footerContext.setHandleOtpError(false);
    }, 300);
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain');
    const verifiedOtp = pastedData
      .trim()
      .split('')
      .filter((char) => !isNaN(Number(char)))
      .map(String);

    if (!/^\d+$/.test(pastedData.trim()) || (verifiedOtp && verifiedOtp.length !== 4)) {
      handleError(pastedData);
      setHideCaret(false);
      return;
    }
    setOtpValue(verifiedOtp.join(''));
    setActiveIndex(3);
    setHideCaret(true);
    footerContext.sendButtonRef.current?.update(verifiedOtp?.join('') || '');
  };

  const handleKeyDown = (e) => {
    const caretPosition = e.target.selectionStart;

    if (
      otpValue.length === 4 &&
      caretPosition >= 0 &&
      caretPosition <= 4 &&
      caretPosition !== activeIndex &&
      !isNaN(e.key)
    ) {
      setOtpValue((prevValue) => {
        const newValue = prevValue.substring(0, caretPosition - 1) + e.key + prevValue.substring(caretPosition);
        setActiveIndex(caretPosition < 4 ? caretPosition : 3);
        return newValue.substring(0, 4);
      });

      overInputRef.current.focus();
      setTimeout(() => {
        overInputRef.current.setSelectionRange(caretPosition + 1, caretPosition + 1);
      }, 50);
    }


    if (otpValue.length === 4 && caretPosition === activeIndex && !isNaN(e.key)) {
      switch (caretPosition) {
        case 0:
          setOtpValue((prevValue) => {
            return e.key + prevValue.substring(1, 4);
          });

          setTimeout(() => {
            overInputRef.current.setSelectionRange(2, 2);
            setActiveIndex(1);
          }, 50);
          break;
        
        case 1:
          setOtpValue((prevValue) => {
            return prevValue.substring(0, 1) + e.key + prevValue.substring(2, 4);
          });

          setTimeout(() => {
            overInputRef.current.setSelectionRange(3, 3)
            setActiveIndex(2);
          }, 50)
          break;
        
        case 2:
          setOtpValue((prevValue) => {
            return prevValue.substring(0, 2) + e.key + prevValue.substring(3, 4);
          });

          setTimeout(() => {
            overInputRef.current.setSelectionRange(4, 4)
            setActiveIndex(3);
          }, 50)
          break;
        
        case 3:
          setOtpValue((prevValue) => {
            return prevValue.substring(0, 3) + e.key;
          });

          setTimeout(() => {
            overInputRef.current.setSelectionRange(4, 4);
            setActiveIndex(3);
          }, 50)
          break;
        
        default:
          return;
      }
    }

    if (e.key === 'ArrowLeft' || e.key === 'ArrowDown') {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
      }
      if (otpValue.length === 4) {
        overInputRef.current.setSelectionRange(activeIndex + 1, activeIndex + 1);
      }
    }

    if ((e.key === 'ArrowRight' || e.key === 'ArrowUp') && activeIndex < otpValue.length) {
      setActiveIndex(activeIndex + 1);
      if (otpValue.length === 4) {
        overInputRef.current.setSelectionRange(activeIndex + 1, activeIndex + 1);
      }
    }

    if (e.key === 'Enter') {
      if (otpValue.length < 4) {
        handleError(otpValue);
        footerContext.setHandleOtpError(false);
      } else {
        if (otpValue.length === 4) {
          setTimeout(() => {
            handleSubmit();
          }, 200);
        }
      }
    }
  };

  const handleClick = (e) => {
    const clickPosition = e.target.selectionStart;
    if (otpValue.length === 4) {
      overInputRef.current.setSelectionRange(clickPosition + 1, clickPosition + 1);
    }
    setActiveIndex(clickPosition);
  };

  useDebounce(() => footerContext.chatInputFieldRef.current?.update(otpValue || ''), 400, [otpValue])

  const handleChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value)) {
      const caretPosition = e.target.selectionStart;
      setOtpValue(value);
      footerContext.sendButtonRef.current?.update(value || '');
      if (value && (value.length === 4 || caretPosition === 4)) {
        setHideCaret(true);
      } else {
        setHideCaret(false);
      }

      // Set the active index based on the caret position
      let newActiveIndex;

      if (caretPosition === 0) {
        newActiveIndex = 0;
      } else if (caretPosition === 1) {
        newActiveIndex = 1;
      } else if (caretPosition === 2) {
        newActiveIndex = 2;
      } else {
        newActiveIndex = 3;
      }
      setActiveIndex(newActiveIndex);
    } else {
      handleError(value);
    }
  };

  return (
    <div className={Styles.otpWrapper}>
      {localOtp.map((otp, index) => (
        <input
          key={index}
          type='tel'
          value=''
          maxLength={1}
          className={`${activeIndex === index ? Styles.focused : ''} ${Styles.otpSingleBox} ${
            errorOn && Styles.errorOn
          }`}
          readOnly
          tabIndex={-1}
        />
      ))}
      <input
        className={`${Styles.overInput} ${hideCaret && Styles.hideCaret}`}
        ref={overInputRef}
        type='tel'
        value={otpValue}
        maxLength={4}
        onChange={(event) => handleChange(event)}
        onKeyDown={handleKeyDown}
        onPaste={handleOnPaste}
        onClick={handleClick}
        onBlur={() => setActiveIndex(null)}
        autoFocus
      />
      <div className={Styles.rightOfOtp} onClick={handleWrapperClick} />
    </div>
  );
}

export default OTPInput;
