/**
 * @file ImagingUploadStatusMessageContent.tsx
 * @description Component for the last message bubble AngelAi send to the user
 * in the workflow for uploading docs to Sunsoft Imaging
 * @author Eric Velepucha
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { Edit2Icon, ExpandStatusesIcon, HideStatusesIcon } from '@assets/Icons';
import axiosCall from '@services/axios';
import Styles from '@styles/MessageContent/ImagingUploadStatusMessageContent.module.css';
import { useUserContext } from '@contexts/user';

const ImagingUploadStatusMessageContent = ({ errand, message }) => {
  const { ssAccessToken } = useUserContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [docList, setDocList] = useState(message.docList);
  const { t } = useTranslation();

  const handleInputChange = (index, event) => {
    const updatedDocList = [...docList];
    updatedDocList[index].filename = event.target.value;
    updatedDocList[index].changed = true;
    setDocList(updatedDocList);
  };

  const handleToggleBody = (e) => {
    e?.stopPropagation();
    setIsOpen((prev) => !prev);
  };
  const handleToggleEdit = (e) => {
    e?.stopPropagation();
    setIsEditing((prev) => !prev);
    setIsOpen(true);
  };
  const handleSaveAll = async (e) => {
    e?.stopPropagation();
    setIsEditing(false);
    const changed = docList.filter((doc) => doc.changed);

    let body = {};
    changed.forEach((doc, index) => {
      const key = `doc${index}`;
      body = {
        ...body,
        [key]: {
          endpoint: `document/${doc.docId}`,
          data: {
            documentId: doc.docId,
            description: doc.filename,
          },
        },
      };
    });

    await axiosCall({ url: 'imaging/update-many', method: 'POST', data: body }, null, {
      'Session-Token': ssAccessToken,
      'Content-Type': 'application/json',
    });
  };

  return (
    <div className={Styles.uploadStatusWrapper}>
      <div className={Styles.header}>
        {/* <br /> */}
        <button className={Styles.edit} onClick={handleToggleEdit}>
          <Edit2Icon />
        </button>
        <h3 className={Styles.title}>{t('documentUpload')}</h3>
        {!isOpen && (
          <button className={Styles.expand} onClick={handleToggleBody}>
            <ExpandStatusesIcon />
          </button>
        )}
      </div>

      {isOpen && (
        <div className={Styles.body}>
          <form style={{ width: '100%', justifyContent: 'center' }}>
            {docList.map((doc, index) => (
              <div className={Styles.document}>
                {!isEditing && (
                  <>
                    <p className={Styles.filename}>{doc.filename}</p>
                    {doc.uploadSuccess && (
                      <div className={Styles.success}>
                        <CheckIcon />
                      </div>
                    )}
                    {!doc.uploadSuccess && (
                      <div className={Styles.fail}>
                        <h2>i</h2>
                      </div>
                    )}
                  </>
                )}
                {isEditing && (
                  <Input
                    fullWidth
                    value={doc.filename}
                    onChange={(event) => handleInputChange(index, event)}
                    className={Styles.input}
                  ></Input>
                )}
              </div>
            ))}
            {isEditing && (
              // button submit changes
              <button className={Styles.submit} type="submit" onClick={handleSaveAll}>
                {t('saveChanges')}
              </button>
            )}
          </form>

          <button onClick={handleToggleBody} style={{ marginTop: 3 }}>
            <HideStatusesIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default ImagingUploadStatusMessageContent;
