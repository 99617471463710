import React, { useEffect, useState } from 'react';

const useIsPageVisible = (): boolean => {
  const [isPageVisible, setIsPageVisible] = useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = (): void => {
      setIsPageVisible(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Set the initial visibility state
    setIsPageVisible(document.visibilityState === 'visible');

    return (): void => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isPageVisible;
};

export default useIsPageVisible;

// Summary: Used for detecting if the browser on mobile is minimized.
// returns true if browser is not minimized (thus visible), else false (if minimized).
// Usage example:
// const isPageVisible: boolean = usePageVisibility();
// console.log(isPageVisible); // true if the page is visible, false otherwise
