import { Stack, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
/*
 *  This component renders a button that appears in the errand list, which opens a prompt list
 *
 */

const OpenUserPromptsButton: React.FC<PropsWithChildren<any>> = (props: any) => {

    return (
        <>
            <Stack>
                <Typography
                    sx={{
                        minWidth: props.isDesktop
                            ? '226px'
                            : '126px',
                        maxWidth:
                            props.isDesktop
                                ? '226px'
                                : '126px',
                        fontSize: props.isDesktop ? '12px' : '10px',
                        color: 'var(--shadow590)',
                        textTransform: 'capitalize',
                        padding: props.isDesktop ? '16px 0 4px' : '8px 0 4px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}>
                    AngelAi Prompts
                </Typography>
                <Typography
                    sx={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        fontSize: props.isDesktop ? '11px' : '9px',
                        color: 'var(--shadow400)',
                        minWidth: props.isDesktop ? '226px' : '126px',
                        maxWidth: props.isDesktop ? '226px' : '126px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        height: '33px',
                    }}>
                    View and select all of AngelAi prompts
                </Typography>
            </Stack>
        </>
    );
};

export default OpenUserPromptsButton;
