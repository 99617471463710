// @ts-check

import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useRef, useState } from 'react';

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgressIcon from '@mui/material/CircularProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import GenericDialog from './GenericDialog';
import TableHeaderBar from './TableHeaderBar';
import dayjs from 'dayjs';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import StandardTablePagination from './StandardTablePagination';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import clamp from 'lodash/clamp';
import cloneDeep from 'lodash/cloneDeep';

// @ts-ignore

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

/** A table component that follows the general styling of the rest of Morgan's admin panel.
  * ------
  * @param {import('../../jsdocs').GenericTableProps} props
  */
const GenericTable = (props) => {
  const { t } = useTranslation();

  const [addNewDialog, setAddNewDialog] = useState(/** @type {{ open: boolean, data: import('../../jsdocs').ItemData | null }} */({ open: false, data: null }));
  const [editDialog, setEditDialog] = useState(/** @type {{ open: boolean, data: import('../../jsdocs').ItemData | null }} */({ open: false, data: null }));

  const [filter, setFilter] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState((props.pagination?.defaultRowsPerPage) ? props.pagination.defaultRowsPerPage : 5);
  const hasMore = useRef(true);
  const showActive = useRef(true);
  

  const queryClient = useQueryClient();

  /* We have this dance between having items and data (and the rData useState) as a way to handle the continuous rerendering nature of the admin panel,
    * there might be a better solution. Ideally, the real fix is we remove the unnecessary rerenders.
    */
  const items = useRef(/** @type {import('../../jsdocs').ItemData[] | null} */(null));

  const { data, isLoading } = useQuery({
    queryKey: [`load-table-${props.tableName}`, { page, rowsPerPage }],
    queryFn: async ({ queryKey }) => {
      // @ts-ignore
      // eslint-disable-next-line
      const [_key, { page, rowsPerPage }] = queryKey;

      let d;
      // If rowsPerPage is -1, it means it's set to 'All'
      if(rowsPerPage === -1 || !props.pagination) {
        d = await props.fetchItems();
      } else {
        d = await props.fetchItems(0+page*rowsPerPage, rowsPerPage+page*rowsPerPage+1);
      }

      items.current = d;
      return d;
    },
  });

  // Get temp items from either items.current or data,
  // then determine if there's more rows by fetching one extra, seeing if it was fetched, then removing it
  let t_items = ((!items.current && data) ? data : items.current);
  if(rowsPerPage !== -1 && t_items?.length > rowsPerPage){
    hasMore.current = true;
    t_items = t_items?.slice(0, rowsPerPage);
  } else {
    hasMore.current = false;
  }

  // TODO - Currently it's fetching all items then filtering, maybe fetch only filtered items?
  const filteredItems = t_items?.filter((item) => {
    return matchFilter(filter, item);
  });

  ///

  /**
   * @param {string} filter
   * @param {import('../../jsdocs').ItemData} item
   * @returns {boolean}
   */
  function matchFilter(filter, item) {
    filter = filter.toLowerCase();
    for (let field of item.fields) {
      if ((field.value + '').toLowerCase().includes(filter)) {
        return true;
      }
    }
    return false;
  }

  // eslint-disable-next-line
  const [rData, setRData] = useState(0);
  /**
   * Refetches table data by calling fetchItems().
   * We use setRData to rerender the table. But there might be a better solution to do this.
   */
  function refetch() {
    items.current = null;
    // Invalidate the old fetched data, which will trigger a refetch.
    queryClient.invalidateQueries({ queryKey: [`load-table-${props.tableName}`] });
    // TODO - check that setRefetch actually rerenders the table
    setRData((prev) => { return prev + 1; });
  }

  /**
   * 
   * @param {string} value 
   * @returns {string}
   */
  const formatDateTime = (value) => {
    if (Date.parse(value)) {
      return dayjs(value).format('MM/DD/YY hh:mm:ss A');
    }
    return '';
  };

  /**
   * @param {import('../../jsdocs').FieldData[]} defaultFields
   */
  function buildHeaderRow(defaultFields) {
    let list = [];
    for (let field of defaultFields) {
      list.push(<StyledTableCell key={'header-' + field.key}>{field.key}</StyledTableCell>);
    }
    return list;
  }

  /**
   * @param {import('../../jsdocs').ItemData} item
   */
  function buildRow(item) {
    let list = [];
    for (let field of item.fields) {
      switch (field.type) {
        case 'date':
          list.push(<StyledTableCell key={item.id + '-' + field.key}>{formatDateTime(field.value+'')}</StyledTableCell>);
          break;
        default:
          list.push(<StyledTableCell key={item.id + '-' + field.key}>{field.display !== undefined ? field.display : field.value}</StyledTableCell>);
          break;
      }
    }
    return list;
  }
  const handleSearchChange = (newSearchValue) => {
    // We want to reset to the first page when searching so we don't miss any documents 
    // (for example, we don't want to begin our search on the second page 
    // as we would miss those on the first)
    setPage(0);
    // Now we can update the search value
    setFilter(newSearchValue);
  };
  return (
    <Box>
      {props.onItemAdd && (
        <TableHeaderBar
          addLabel={`${t("genericTableAdd")} ${props.tableName}`}
          onAdd={() => setAddNewDialog({ open: true, data: { id: '0', fields: props.defaultFields } })}
          setSearch={handleSearchChange}
          setPage={setPage}
          onRefresh={() => refetch()}
          searchValue={filter}
          uploadIcon={false}
          showInactiveClick={props.showInactiveClick
            ? () => {
              if (props.showInactiveClick) {
                props.showInactiveClick().then((active) => {
                  showActive.current = active;
                  setPage(0);
                  refetch();
                });
              }
            }
            : null}
          isActive={showActive.current}
        />
      )}
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {isLoading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgressIcon disableShrink color="info" />
          </Stack>
        ) : (
          <>
            {(filteredItems && filteredItems.length === 0 && items.current) ? (
              <Stack alignItems="center" justifyContent="center" height={clamp(items.current?.length, 1, 5) * 60 + 'px'}>
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    {buildHeaderRow(props.defaultFields)}
                    {(props.onItemUpdate || props.onItemDelete) && (
                      <StyledTableCell>{t('actionCol')}</StyledTableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems && filteredItems.map((item) => {
                    return (
                      <TableRow
                        sx={{ backgroundColor: (item.active === undefined || item.active) ? 'var(--gray000)' : 'var(--peach030)' }}
                        hover
                        key={item.id}>
                        {buildRow(item)}
                        {(props.onItemUpdate || props.onItemDelete) && (
                          <StyledTableCell align="center">
                            {props.onItemUpdate && (
                              <Tooltip
                                title={t('genericTableEdit')}
                                placement="top-start"
                                enterDelay={1000}
                                enterNextDelay={1000}>
                                <BorderColorOutlinedIcon
                                  onClick={() => {
                                    /** @type {import('../../jsdocs').ItemData} */
                                    let itemWithExtra = {
                                      id: item.id,
                                      fields: item.fields.map((field) => {
                                        let defaultField = props.defaultFields.find((f) => { return f.key === field.key });
                                        return {
                                          key: field.key,
                                          value: field.value,
                                          type: defaultField?.type,
                                          required: defaultField?.required,
                                          disabled: field?.disabled,
                                          hidden: defaultField?.hidden
                                        };
                                      }),
                                    }
                                    setEditDialog({ open: true, data: itemWithExtra });
                                  }}
                                  fontSize="small"
                                  sx={{ cursor: 'pointer', marginLeft: '15px', color: 'var(--blue210)' }}
                                />
                              </Tooltip>
                            )}
                            {props.onItemDelete && (
                              <>
                                {(item.active === undefined || item.active) ? (
                                  <Tooltip
                                    title={t('genericTableDelete')}
                                    placement="top-start"
                                    enterDelay={1000}
                                    enterNextDelay={1000}>
                                    <DeleteOutlinedIcon
                                      onClick={() => {
                                        if (props.onItemDelete) {
                                          props.onItemDelete(item.id).then(() => {
                                            refetch();
                                          });
                                        }
                                      }}
                                      fontSize="small"
                                      sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={t('genericTableReactivate')}
                                    placement="top-start"
                                    enterDelay={1000}
                                    enterNextDelay={1000}>
                                    <RestoreFromTrashOutlinedIcon
                                      onClick={() => {
                                        if (props.onItemReactivate) {
                                          props.onItemReactivate(item.id).then(() => {
                                            refetch();
                                          });
                                        }
                                      }}
                                      fontSize="small"
                                      sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                                    />
                                  </Tooltip>
                                  )
                                }
                              </>
                            )}
                          </StyledTableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    {props.pagination &&
                      <StandardTablePagination
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(event, newPage) => {
                          setPage(newPage);
                          refetch();
                        }}
                        onRowsPerPageChange={(event) => {
                          setRowsPerPage(parseInt(event.target.value, 10));
                          setPage(0);
                          refetch();
                        }}
                        isMore={hasMore.current}
                      />
                    }
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </>
        )}
      </TableContainer>
      <GenericDialog
        title={`${t("genericTableEdit")} ${props.tableName}`}
        isOpen={editDialog.open}
        item={cloneDeep(editDialog.data)}
        onClose={() => {
          setEditDialog({ open: false, data: null });
        }}
        onSubmit={
          /**
            * @param {import('../../jsdocs').ItemData | null} item
            */
          (item) => {
            setEditDialog({ open: false, data: null });
            if (item && props.onItemUpdate) {
              props.onItemUpdate(item.id, item.fields).then(() => {
                refetch();
              });
            }
          }}
      />
      <GenericDialog
        title={`${t("genericTableAdd")} ${props.tableName}`}
        isOpen={addNewDialog.open}
        item={cloneDeep(addNewDialog.data)}
        onClose={() => {
          setAddNewDialog({ open: false, data: null });
        }}
        onSubmit={
          /**
            * @param {import('../../jsdocs').ItemData | null} item
            */
          (item) => {
            setAddNewDialog({ open: false, data: null });
            if (item && props.onItemAdd) {
              props.onItemAdd(item.fields).then(() => {
                refetch();
              });
            }
          }}
      />
    </Box>
  );
};

export default GenericTable;