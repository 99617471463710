import React, { useCallback, useEffect } from 'react';

import { SPACE_BETWEEN_SLIDES, SWIPER_SLIDE_WIDTH, SWIPER_SLIDE_HEIGHT, SWIPER_SLIDE_LOADING_WIDTH, SWIPER_SLIDE_MAX_WIDTH } from './constants';
import Styles from '@styles/MorphUserSuggestions.module.css';
import { SingleSlideElement } from './SingleSlideElement';
import { Autoplay, FreeMode } from 'swiper';
import { useTranslation } from 'react-i18next';
import { SwiperSlide } from 'swiper/react';
import { Swiper } from 'swiper/react';

const staticSwiperProps = {
  modules: [Autoplay, FreeMode],
  spaceBetween: SPACE_BETWEEN_SLIDES,
  slideToClickedSlide: false,
  slidesPerView: 'auto' as 'auto',
  centeredSlides: false,
  speed: 2000, // Speed of transition between slides (in milliseconds)
  loop: false // Loop the slides
};

const staticSwiperSlideStyle = {
  cursor: 'pointer',
  minWidth: `${SWIPER_SLIDE_WIDTH}px`,
  maxWidth: `${SWIPER_SLIDE_MAX_WIDTH}px`,
  height: `${SWIPER_SLIDE_HEIGHT}px`,
};

const staticLoadingSwiperSlideStyle = { width: `${SWIPER_SLIDE_LOADING_WIDTH}px`, height: `fit-content` };

const MessageText = ({ text }) => {
  return (
    <p
      style={{
        fontSize: '0.8rem',
        color: 'var(--gray000)',
        textAlign: 'center',
        marginTop: '5px',
        lineHeight: '13px',
        overflowWrap: 'break-word',
        maxWidth: '100%',
        padding: '16px',
      }}
    >
      {text}
    </p>
  );
};
const EmptyListMessage = () => {
  const { t } = useTranslation();

  return <MessageText text={t('UPM_No_Available_Options')} />;
};
const ErrorOccuredMessage = () => {
  const { t } = useTranslation();

  return <MessageText text={t('UPM_Some_error_occurred_on_our_end')} />;
};
const MainSwiper = (props) => {
  const { t, i18n } = useTranslation();
  // Used for animation of reveal one by one.
  // false -> still hidden
  // true -> applies class to the element that brings that element up from the bottom edge of the screen
  // [false, false, false, ...] length of the props.slideListItems
  const [currentlyShownElements, setCurrentlyShownElements] = React.useState<boolean[]>(
    new Array(props.slideListItems.length).fill(false) // [false, false, false, ...] of length = props.slideListItems.length
  );
  const tmIdsRef = React.useRef([]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>, idx: number) => {
      if (e.code === 'Enter' || e.code === 'Space') {
        props.onSingleSlideClickHandler(e, idx);
        e.preventDefault();
        return;
      }

      // Handle TAB KEY
      if (e.code === 'Tab') {
        if (!e.shiftKey) {
          props.handleTabForward();
        } else if (e.shiftKey) {
          props.handleTabBackward();
        }

        e.preventDefault();
        return;
      }
    },
    [props.onSingleSlideClickHandler, props.handleTabForward, props.handleTabBackward]
  );

  const renderSlides = (): JSX.Element | JSX.Element[] => {
    if (props.isLoading === true) {
      return (
        <SwiperSlide style={staticLoadingSwiperSlideStyle}>
          <SingleSlideElement isLoadingElement={true} text={'Loading...'} className={''} />
        </SwiperSlide>
      );
    }

    return props.slideListItems.map((data, idx) => {
      const currElementClassName = `
        ${props.unmountStarted === true ? Styles.hideSingleSlideAnimation : ''}
        ${(currentlyShownElements[idx] && props.unmountStarted === false) ? `${Styles.showSingleSlideAnimation}` : ''}
      `;
      return (
        <SwiperSlide
          aria-label={
            `Suggestion prompt text: "${data.text}". Select this suggestion prompt by clicking on it to send it in chat.`
          }
          aria-describedby={
            idx === props.slideListItems.length - 1 ? 'UPM_EndOfList' : idx === 0 ? 'UPM_StartOfList' : ''
          }
          role={'button'}
          key={idx}
          onClick={(e) => props.onSingleSlideClickHandler(e, idx, data.text)}
          className={`${Styles.SingleSwiperSlide}`}
          onKeyDown={(e) => {
            handleKeyDown(e, idx);
          }}
          style={staticSwiperSlideStyle}
        >
          <SingleSlideElement text={data.text} className={currElementClassName} />
        </SwiperSlide>
      );
    });
  }

  // On Mount start animation
  useEffect(() => {
    props.slideListItems.forEach((_, index) => {
      tmIdsRef.current.push(
        setTimeout(() => {
          setCurrentlyShownElements((prevShownArr) => {
            // set all the elements to true untill index (including index) elemment
            return prevShownArr.map((_, idx) => (idx <= index ? true : false)) as boolean[];
          }); // Increment the active index
        }, index * 300)
      );
    });

    // On Unmount
    return () => {
      tmIdsRef.current.forEach((tmID) => {
        clearTimeout(tmID);
      });
    }
  }, [])

  return (
    <Swiper
      className={Styles.swiperContainer}
      onSwiper={props.setSwiperInstance}
      style={props.style}
      autoplay={{ delay: 4000, disableOnInteraction: true }}
      {...staticSwiperProps}
    >
      {renderSlides()}
    </Swiper>
  );
};

export { MainSwiper };
