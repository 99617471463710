import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import useDebounce from '@common/hooks/useDebounce';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Close } from '@assets/Icons';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import React, { FC, PropsWithChildren, useState, useEffect, useRef, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';
import { StyledTableCell } from '@styles/DashboardTab';
import Styles from '@styles/DashboardAiTasksDialogEdit.module.css';
import DashboardTabStyles from '@styles/DashboardTab.module.css';

enum TaskFieldType {
  actions = 'actions',
  active = 'active',
  category = 'category',
  instructions = 'instructions',
  name = 'name',
  subCategory = 'subCategory',
}

enum ActionFieldType {
  action = 'action',
  active = 'active',
  answer = 'answer',
  helpTip = 'helpTip',
  helpTipGroup = 'helpTipGroup',
  workflow = 'workflow',
}

const EditableFieldCell: FC<PropsWithChildren<any>> = (props) => {
  const { index, handleActionUpdate, actionObj } = props;

  const [localValue, setLocalValue] = useState(actionObj[ActionFieldType.answer]);

  useDebounce(
    () => {
      if (actionObj[ActionFieldType.answer] !== localValue) {
        handleActionUpdate(ActionFieldType.answer, localValue, index);
      }
    },
    200,
    [localValue]
  );

  // triggered when UP/DOWN arrow clicked
  useEffect(() => {
    if (actionObj[ActionFieldType.answer] !== localValue) {
      setLocalValue(actionObj[ActionFieldType.answer]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionObj]);

  return (
    <TextField
      className={Styles.ContainerTextField}
      multiline
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      color="info"
      size="small"
      variant="outlined"
    ></TextField>
  );
};

const AITasksDialogEdit: FC<DialogPropsWithChildren> = (props) => {
  const {
    createOrUpdateAiTask,
    handleAiTaskClose,

    allActions,
    allWorkflows,
    allHelpTips,
    allHelpTipGroups,

    taskSingle,
  } = props;

  const { t } = useTranslation();

  // We will get copy of task from parent component
  // local changes should not reflect to parent componet
  const [localTask, setLocalTask] = useState(JSON.parse(JSON.stringify(taskSingle)));

  const handleTaskUpdate = useCallback(async (field, value) => {
    setLocalTask((prev) => ({ ...prev, [field]: value }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActionUpdate = useCallback((field: ActionFieldType, value, index) => {
    const actions = taskGetField(TaskFieldType.actions);

    actions[index][field] = value;

    handleTaskUpdate(TaskFieldType.actions, actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActionGet = useCallback((field: ActionFieldType, action) => {
    return action[field] || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveStep = useCallback((index) => {
    const actions = taskGetField(TaskFieldType.actions);

    actions.splice(index, 1);

    handleTaskUpdate(TaskFieldType.actions, actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddStep = useCallback(() => {
    const actions = taskGetField(TaskFieldType.actions);

    actions.push({});

    handleTaskUpdate(TaskFieldType.actions, actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMoveStepUp = useCallback((index) => {
    const actions = taskGetField(TaskFieldType.actions);

    let indexA;
    let indexB;

    if (index > 0) {
      indexA = index;
      indexB = index - 1;
/**
   *************************************
   * Submit Function
   *************************************
  const handleSubmit = async () => {
    // console.log("AITaskDiaologEdit > handleSubmit", actionData);
    let data = {
      name: AITaskName,
      category: AITaskCategory,
      subCategory: AITaskSubcategory,
      instructions: instructions,
      //description: AITaskDescription,
      _id: props.AITasks.id,
      actions: actionData.map((act) => {
        return {
          action: act.action || null,
          workflow: act.workflow || null,
          helpTip: act.helpTip || null,
          helpTipGroup: act.helpTipGroup || null,
          answer: act.answer || null,
        };
      }),
    };
    // if (instructions !== undefined) {
    //   try{
    //     await operatorAxios({
    //       url: `ai/${props.AITasks.operatorId}/task/${props.AITasks.id}/trigger/${_id}`,
    //       method: 'PUT',
    //       data: {
    //         instructions: instructions
    //       }
    //     });
    //   } catch (error){
    //     console.log('the ai task does not exist.')
    //   }
    // }
    console.log(`${props.edit ? 'edi' : 'submit'}ting ai task with data`, data);
    if (data.name === '' || data.actions.length === 0){
      return;
    }
    if (!props.edit) {
      props.onAdd(data);
*/
    } else {
      indexA = 0;
      indexB = actions.length - 1;
    }

    [actions[indexA], actions[indexB]] = [actions[indexB], actions[indexA]];

    handleTaskUpdate(TaskFieldType.actions, actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMoveStepDown = useCallback((index) => {
    const actions = taskGetField(TaskFieldType.actions);

    let indexA;
    let indexB;

    if (index < actions.length - 1) {
      indexA = index;
      indexB = index + 1;
    } else {
      indexA = 0;
      indexB = actions.length - 1;
    }

    [actions[indexA], actions[indexB]] = [actions[indexB], actions[indexA]];

    handleTaskUpdate(TaskFieldType.actions, actions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const taskGetField = useCallback(
    (field: TaskFieldType) => {
      return localTask[field] || '';
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [localTask]
  );

  return (
    <Dialog maxWidth={false} open={true} onClose={() => handleAiTaskClose()}>
      <DialogTitle className={Styles.ContainerTitle}>
        <Typography variant="h6">{t('editTask')}</Typography>
        <Close className={Styles.ContainerExitButton} onClick={() => handleAiTaskClose()} />
      </DialogTitle>
      <DialogContent className={Styles.ContainerContent}>
        <TextField
          className={Styles.ContainerRow}
          autoFocus
         
          label={`${t('nameTranslation')}`}
          onChange={(e) => handleTaskUpdate(TaskFieldType.name, e.target.value)}
          size="small"
          value={taskGetField(TaskFieldType.name)}
          variant="outlined"
        />
        <TextField
          className={Styles.ContainerRow}
         
          label={`${t('categoryTranslation')}`}
          onChange={(e) => handleTaskUpdate(TaskFieldType.category, e.target.value)}
          size="small"
          value={taskGetField(TaskFieldType.category)}
          variant="outlined"
        />
        <TextField
          className={Styles.ContainerRow}
         
          label={`${t('subcategory')}`}
          onChange={(e) => handleTaskUpdate(TaskFieldType.subCategory, e.target.value)}
          size="small"
          value={taskGetField(TaskFieldType.subCategory)}
          variant="outlined"
        />
        <TextField
          className={Styles.ContainerRow}
         
          label={`${t('instructionsTranslation')}`}
          onChange={(e) => handleTaskUpdate(TaskFieldType.instructions, e.target.value)}
          size="small"
          value={taskGetField(TaskFieldType.instructions)}
          variant="outlined"
        />
        <div className={Styles.ContainerButtonAddAction}>
          <Button variant="outlined" color="info" onClick={() => handleAddStep()}>
            {t('addAction')}
          </Button>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t('stepTranslation')}</StyledTableCell>
                <StyledTableCell>{t('actionTranslation')}</StyledTableCell>
                <StyledTableCell>{t('workflowTranslation')}</StyledTableCell>
                <StyledTableCell>{t('helpTipTranslation')}</StyledTableCell>
                <StyledTableCell>{t('helpTipGroupTranslation')}</StyledTableCell>
                <StyledTableCell>{t('answerTranslation')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taskGetField(TaskFieldType.actions)?.map((action, index) => (
                <TableRow className={Styles.ContainerTableRow} hover key={index}>
                  <StyledTableCell>
                    <div className={Styles.ContainerStep}>
                      <Typography variant="h6">{index + 1}</Typography>
                      <div className={Styles.ContainerArrow}>
                        <IconButton
                          className={Styles.IconButtonArrow}
                          onClick={() => {
                            handleMoveStepUp(index);
                          }}
                        >
                          <ArrowDropUpIcon />
                        </IconButton>
                        <IconButton
                          className={Styles.IconButtonArrow}
                          onClick={() => {
                            handleMoveStepDown(index);
                          }}
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      className={Styles.ContainerTextField}
                      select
                      size="small"
                     
                      value={handleActionGet(ActionFieldType.action, action)}
                      SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500 } } } }}
                      onChange={(e) => handleActionUpdate(ActionFieldType.action, e.target.value, index)}
                    >
                      <MenuItem key={0} value={null}>
                        &nbsp;{' '}
                      </MenuItem>
                      {allActions.map((a) => {
                        //MAP ALL OF THE NORMAL MORGAN ACTIONS TO THE SELECT

                        return (
                          <MenuItem key={a?._id} value={a?._id}>
                            {a.description}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      className={Styles.ContainerTextField}
                      select
                      size="small"
                     
                      value={handleActionGet(ActionFieldType.workflow, action)}
                      SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500 } } } }}
                      onChange={(e) => handleActionUpdate(ActionFieldType.workflow, e.target.value, index)}
                    >
                      <MenuItem key={0} value={null}>
                        &nbsp;{' '}
                      </MenuItem>
                      {allWorkflows.map((w) => {
                        return (
                          <MenuItem key={w?._id} value={w?._id}>
                            {w.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      className={Styles.ContainerTextField}
                      select
                      size="small"
                     
                      value={handleActionGet(ActionFieldType.helpTip, action)}
                      SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500 } } } }}
                      onChange={(e) => handleActionUpdate(ActionFieldType.helpTip, e.target.value, index)}
                    >
                      <MenuItem key={0} value={null}>
                        &nbsp;{' '}
                      </MenuItem>
                      {allHelpTips.map((w) => (
                        <MenuItem key={w?._id} value={w?._id}>
                          {w.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      className={Styles.ContainerTextField}
                      select
                      size="small"
                     
                      value={handleActionGet(ActionFieldType.helpTipGroup, action)}
                      SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500 } } } }}
                      onChange={(e) => handleActionUpdate(ActionFieldType.helpTipGroup, e.target.value, index)}
                    >
                      <MenuItem key={0} value={null}>
                        &nbsp;{' '}
                      </MenuItem>
                      {allHelpTipGroups.map((w) => {
                        return (
                          <MenuItem key={w?._id} value={w?._id}>
                            {w.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className={Styles.ContainerAnswer}>
                      <EditableFieldCell
                        actionObj={taskGetField(TaskFieldType.actions)[index]}
                        handleActionUpdate={handleActionUpdate}
                        index={index}
                      />
                      <Tooltip title={t('deleteStep')} placement="top">
                        <DeleteOutlinedIcon
                          className={`${DashboardTabStyles.IconDeleteOutlined} ${DashboardTabStyles.IconTopTrash}`}
                          onClick={() => handleRemoveStep(index)}
                        />
                      </Tooltip>
                    </div>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={Styles.ContainerButtonDone}>
          <Button variant="contained" color="info" onClick={() => createOrUpdateAiTask(localTask)}>
            {t('doneButton')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AITasksDialogEdit;
