/**
 * @file    This file contains the definition of the `MorganCoreError` exception class.
 * @author  Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

/*
========================================================================================================================
Imports
========================================================================================================================
*/

import MorganError from "./MorganError";

/*
========================================================================================================================
Class definition
========================================================================================================================
*/

/**
 * This exception should be thrown whenever Morgan-Core serves a non-2XX response for any upstream requests.
 */
class MorganCoreError extends MorganError {}

export default MorganCoreError;