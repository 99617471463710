/**
 * @file This component is a stylized Box component
 * for styling the Errand Message Content.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 * @author Hunter Gordon <hunter.gordon@swmc.com>
 */

 import { Box, styled } from '@mui/system';
 import getSvgFilter from '../Common/GetSvgFilter';
 const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';

 const MessageContent = styled(Box)(
   ({ theme, }) => `
    display: block;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    width: fit-content;
    max-width: 100%;
    pointer-events: auto;
    .dottedLines {
      display: flex;
      height: 35px;
      border-color: var(--orange600);
      border-top: none;
      width: 100%;
      box-Shadow: none;
      border-right: 2px dashed var(--orange600);
      margin-right: 20px;
      margin-left: none;
      margin-top: 0px;
      border-left: none;
      border-bottom: none;
    }
    //operator view or someone elses
    .dottedLinesThem {
      display: flex;
      height: 35px;
      border-color: var(--orange600);
      border-top: none;
      width: 100%;
      box-Shadow: none;
      border-rhgt: none;
      border-left: 2px dashed var(--orange600);
      margin-right: none;
      margin-left: 20px;
      margin-top: 0px;
      border-bottom: none;
    }
    & s {
      color: red;
      word-wrap: break-word;
      width: fit-content;
      text-decoration-color: var(--red400);
      text-decoration-line: line-through;
    }
    & i {
      word-wrap: break-word;
      max-width: 99%;
      font-style: italic;
    }
    & img {
      height: 14px;
      width: 14px;
      filter: ${getSvgFilter(theme.palette.gray['000'])}
    }
    & > span {
      direction: ltr;
    }
    & > span:nth-of-type(1) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding: '0px 0px 0px 0px';
      max-width: 100%;
      position: relative;
      width: 100%;
      & > p {
        margin: 0;
        font-size: 0.75em;
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        letter-spacing: 0.00938em;
        overflow-wrap: break-word;
        word-break: break-word;
        margin-right: 0px;
        white-space: pre-line;
        width: fit-content;
        & > svg.MuiSvgIcon-root {
         color: var(--orange700);
         margin-right: 5px;
         transform: translateY(8px);
         margin-top: -12px;
         filter: none;
         width: 24px;
         height: 24px;
        }
      }
    }
    & > span:nth-of-type(2) {
      display: grid;
      height: 100%;
      align-items: stretch;
      justify-content: stretch;
      & > div {
        background-color: var(--orange700);
        background-image: ${theme.system.name === 'SUNWST000' ? `url(${WoodGrain})` : 'none'};
        color: var(--gray000);
        border: none;
        padding: 5px;
        cursor: pointer;
        display: flex;
        align-items: 'normal';
        justify-content: center;
        height: inherit;
        & svg {
          font-size: 14px;
          pointer-events: none;
          height: 14px;
          width: 14px;
          filter: ${getSvgFilter(theme.palette.gray['000'])} !improtant;
        }
      }
    }
  `
 );
 
 export default MessageContent;