import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import Styles from '../Styles/MorphTime.module.css';
import { useFooterContext } from '@contexts/FooterContext';
import { useMorphContext } from '@contexts/MorphContext';
import { useTranslation } from 'react-i18next';
import { currentTimezones } from '@common/getTimezoneAbbrev';
import { useErrandContext } from '@contexts/ErrandContext';

const timezones = currentTimezones();

type verticalCarouselProps = {
  possibleOptions: String[];
  type: string;
  setHour?: (text: string) => void;
  setPeriod?: (text: string) => void;
  setSelectedTimezoneIndex?: (index: number) => void;
  setTimezone?: (text: string) => void;
  defaultIndexTimezone?: number;
  hour?: string;
}

const VerticalCarousel: React.FC<React.PropsWithChildren<verticalCarouselProps>> = ({ possibleOptions, type, setHour, setPeriod, setSelectedTimezoneIndex, setTimezone, defaultIndexTimezone, hour }) => {
  const [currentSlide, setCurrentSlide] = useState(
    type === 'timezone' ? defaultIndexTimezone : type === 'hour' || type === 'min' ? Math.ceil(possibleOptions.length / 2) : 2
  );
  
  const handleUpdateTime = (direction) => {
    if (currentSlide > 2 && currentSlide < possibleOptions.length) {
      if (type === 'hour') {
        setHour(possibleOptions[currentSlide - (direction === 'up' ? 0 : 2)].toString());
      } else if (type === 'timezone') {
        let timezone = possibleOptions[currentSlide - (direction === 'up' ? 0 : 2)].toString()
        setTimezone(timezone);
        let index = timezones.indexOf(timezone)
        setSelectedTimezoneIndex(index)
      }
    } 
    if (currentSlide === 2 || currentSlide === 1){
      if (type === 'timezone'){
        if (direction === 'down' && currentSlide === 1){
          setSelectedTimezoneIndex(timezones.length-1)
        }else{
          setSelectedTimezoneIndex(direction === 'up' ? currentSlide === 2 ? 2 : 1 : 0)
        }
      }
      if (type === 'hour'){
        if (direction === 'down' && currentSlide === 1){
          setHour('12')
        }else{
          setHour(direction === 'up' ? currentSlide === 2 ? '03' : '02' : '01');
        }
      }
    }
    if (currentSlide === possibleOptions.length || currentSlide === possibleOptions.length +1){
      if (type === 'timezone'){
        setSelectedTimezoneIndex(direction === 'up' ? currentSlide === possibleOptions.length ? 0 : possibleOptions.length-1 : possibleOptions.length-2)
      }
      if (type === 'hour'){
        setHour(direction === 'up' ? currentSlide === possibleOptions.length ? hour === '12' ? '01' : '12' : '12': '11')
      }
    }
    if (type === 'period') {
      if (currentSlide === 1) {
        setPeriod('PM')
      } else {
        setPeriod('AM')
      }
    }
  }

  const handleSwipe = (direction) => {
    const totalSlides = possibleOptions.length;
    if (type === 'timezone') {
      setCurrentSlide(prev => {
        if (direction === 'up') {
          return prev === totalSlides ? 1 : prev + 1;
        } else if (direction === 'down') {
          return prev === 1 ? totalSlides : prev - 1;
        }
      });
    } else if (type === 'hour' || type === 'min') {
      setCurrentSlide(prev => {
        const nextSlide = direction === 'up' ? prev + 1 : prev - 1;
        if (nextSlide < 1) {
          return totalSlides;
        } else if (nextSlide > totalSlides) {
          return 1;
        } else {
          return nextSlide;
        }
      });
    } else {
      setCurrentSlide(prev => prev === 1 ? 2 : 1);
    }
    handleUpdateTime(direction);
  };

  const handleClick = (index) => {
    if (index > currentSlide) {
      // Clicking on bottom number is the same as sliding up
      handleSwipe('up')
    } else if (index < currentSlide) {
      // Clicking on the top number is the same as sliding down
      handleSwipe('down')
    }
  };

  const handleWheel = (e) => {
    if (e.deltaY > 0){
      handleSwipe('up')
    }else if (e.deltaY < 0){
      handleSwipe('down')
    }
  }

  const handlers = useSwipeable({
    onSwipedUp: (type) => handleSwipe('up'),
    onSwipedDown: (type) => handleSwipe('down'),
    trackMouse: true,
  });
  

  const getTransformValue = (index) => {
    const distance = currentSlide - index;
    switch (distance) {
      case 0:
        return 'translateY(0)';
      case 1:
        return 'translateY(-100%)';
      case -1:
        return 'translateY(100%)';
      default:
        return 'translateY(100%)';
    }
  };

  const renderSlides = () => {
    const slidesToShow = [currentSlide - 1, currentSlide, currentSlide + 1];

    return slidesToShow.map((index) => (
      <div
        key={index}
        className={`${Styles.carouselSlide} ${currentSlide === index && Styles.active}`}
        style={{ transform: getTransformValue(index) }}
        onClick={() => handleClick(index)}
        onWheel={handleWheel}
      >
        {index > 0 &&
          index <= possibleOptions.length &&
          possibleOptions[index - 1]}
      </div>
    ));
  };

  // Note: If using this component for other types of times that includes minutes other than '00',
  // will need to update the Styles.defaultCursor below so that it also shows the pointer for that morphType

  return (
    <div {...handlers} className={`${Styles.verticalCarousel} ${type === 'min' && Styles.defaultCursor} ${type === 'timezone' && Styles.widerCarousel}`} style={{ userSelect: 'none' }}>
      {renderSlides()}
    </div>
  );
};

const MorphTime = () => {
  const footerContext = useFooterContext();
  const errandContext = useErrandContext();
  const { t } = useTranslation();
  const [hour, setHour] = useState('12');
  const [min, setMin] = useState('00');
  const [period, setPeriod] = useState('PM');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const morphContext = useMorphContext();
  const validMorningHours = ['10', '11']
  const validAfternoonHours = ['12', '01', '02', '03', '04', '05']
  let formattedTime = `${hour}:${min} ${period}`

  useEffect(() => {
    if ((validMorningHours.includes(hour)) && (period === 'AM')){
      footerContext.chatInputFieldRef.current?.update(formattedTime + ' ' + timezones[morphContext.selectedTimezoneIndex] || '');
      footerContext.sendButtonRef.current?.update(formattedTime + ' ' + timezones[morphContext.selectedTimezoneIndex] || '');
      setOpenSnackbar(false);
    } else if ((validAfternoonHours.includes(hour)) && (period === 'PM')){
      footerContext.chatInputFieldRef.current?.update(formattedTime + ' ' + timezones[morphContext.selectedTimezoneIndex] || '');
      footerContext.sendButtonRef.current?.update(formattedTime + ' ' + timezones[morphContext.selectedTimezoneIndex] || '');
      setOpenSnackbar(false);
    }else{
      setSnackbarMessage(t('workshopTime'));
      setOpenSnackbar(true);
    }
    
  }, [hour, min, period, morphContext.selectedTimezoneIndex])

  const hours = [
    '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', 
  ];
  const minutes = [
    '00'
  ]
  const periods = [
    'AM', 'PM', 
  ]
  return(
    <>
      <div
        className={`${Styles.snackbarWrapper} ${openSnackbar ? Styles.openSnackbar : Styles.closedSnackbar} ${
          morphContext.hideTime && Styles.closedSnackbar
        }`}
      >
        {snackbarMessage}
      </div>
      <div className={`${Styles.timeWrapper} ${
        morphContext.hideTime ? Styles.hideTime : Styles.showTime
      }`}
      >
        <VerticalCarousel possibleOptions={hours} type='hour' setHour={setHour} hour={hour}/>
        <div className={Styles.unitHr}>{t('tHr')}</div>
        <VerticalCarousel possibleOptions={minutes} type='min'/>
        <div className={Styles.unitMin}>{t('tMin')}</div>
        <VerticalCarousel possibleOptions={periods} type='period' setPeriod={setPeriod}/>
      </div>
    </>
  )
}

const MorphTimeTab = () => {
  const { t } = useTranslation();
  const morphContext = useMorphContext();
  const [openTimezone, setOpenTimezone] = useState(false);
  const [timezone, setTimezone] = useState(timezones[morphContext.selectedTimezoneIndex])

  const handleToggleTimezoneWindow = () => {
    setOpenTimezone((prev) => {
      return !prev
    })
  }

  const toggleHide = () => {
    morphContext.setHideTime((prev) => {
      return !prev;
    });
  };

  useEffect(() => {
    let index = timezones.indexOf(timezone)
    morphContext.setSelectedTimezoneIndex(index)
  }, [timezone, morphContext.setSelectedTimezoneIndex])

  // find the default index of the timezone that the user is in or has selected
  let defaultIndexTimezone = -1;
  for (let i = 0; i < timezones.length; i++) {
    if (timezones[i] === timezone) {
      defaultIndexTimezone = i;
        break;
    }
  }

  const openTimeTab = () => {
    return (
      <button className={openTimezone? Styles.closeTimezoneButton : Styles.timezoneTabButton} onClick={handleToggleTimezoneWindow}>
        {openTimezone ? t('closeButton') : timezones[morphContext.selectedTimezoneIndex]}
      </button>
    )
  }

  const closeTimeTab = () => {
    return(
      <button className={Styles.showTimeButton} onClick={toggleHide}>
        {t('clickToShow')}
      </button>
    )
  }

  return (
    <div className={Styles.timezoneWrapper}>
      {morphContext.hideTime ? closeTimeTab() : openTimeTab()}
      {openTimezone && 
      <>
        <div className={Styles.timezoneWindow}>
          <VerticalCarousel possibleOptions={timezones} type='timezone' setSelectedTimezoneIndex={morphContext.setSelectedTimezoneIndex} setTimezone={setTimezone} defaultIndexTimezone={defaultIndexTimezone + 1}/>
        </div>
        <div className={Styles.timezoneBackground} onClick={handleToggleTimezoneWindow}/>
      </>
        }
    </div>
  );
};

export {MorphTime, MorphTimeTab}
