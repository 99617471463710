import dayjs from 'dayjs';
import writeXlsxFile from 'write-excel-file';

const downloadFieldAttributeReport = async (fieldAttributeData) => {
  const schema = [
    // Column #1
    {
      column: 'Type',
      type: String,
      value: (fieldAttribute) => fieldAttribute.type,
      width: 25,
    },
    {
      column: 'Description',
      type: String,
      value: (fieldAttribute) => fieldAttribute.description,
      width: 25,
    },
    {
      column: 'Format',
      type: String,
      value: (fieldAttribute) => fieldAttribute.format,
      width: 25,
    },
    {
      column: 'Mask',
      type: String,
      value: (fieldAttribute) => fieldAttribute.mask,
      width: 25,
    },
    {
      column: 'Validation Rule',
      type: String,
      value: (fieldAttribute) => fieldAttribute.validationRule,
      width: 25,
    }
  ];

  if (fieldAttributeData?.length > 0) {
    const objects = fieldAttributeData?.map((fieldAttribute) => {
      return {
        type: fieldAttribute?.type,
        description: fieldAttribute?.description,
        format: fieldAttribute?.format,
        mask: fieldAttribute?.mask,
        validationRule: fieldAttribute?.validationRule
      };
    });
    const date = dayjs(new Date()).format('DD/MM/YYYY');
    await writeXlsxFile(objects, {
      schema,
      fileName: `Morgan Field Attribute Report : ${date}.xlsx`,
    });
  }
}

export default downloadFieldAttributeReport;