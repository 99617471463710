import React, { useEffect, useState } from 'react';
import { useTheme, useMediaQuery, Fade } from '@mui/material';
import useMeasureOffScreen from './useMeasureOffScreen';
import { ConsentContainer } from './ConsentContainer';
import { HintOptionList } from './HintOptionList';
import { IErrand, IMessage } from '@interfaces/Conversation';

export type TDimensions = { height: number, width: number }[];

type ContentContainerProps = {
    options: {
        html: string;
    }[];
    currentActiveItem: number;
    suggestionTools: {
        open: () => void;
        close: () => void;
    };
    handleWorkflowOrMorphType: (hrefOrValue: any) => Promise<void>;
    numOfShownElements: number;
    allMounted: boolean;
    isFirstMount: boolean;
    dimensions: TDimensions;
    isCurrMsgLastInChat: boolean;
    isFading: boolean;
    isOperator: boolean;
    consentHtml: { accepted: string, initial: string } | null;
    errand: IErrand;
    message: IMessage;
}

const ContentContainer = ({
    options,
    currentActiveItem,
    suggestionTools,
    handleWorkflowOrMorphType,
    numOfShownElements,
    allMounted,
    isFirstMount,
    dimensions,
    isCurrMsgLastInChat,
    isFading,
    isOperator,
    consentHtml,
    errand,
    message
}: ContentContainerProps) => {
    const renderFinal = allMounted == true || isFirstMount == false;

    // IMPORTANT NOTE! 
    // FOR SMOOTH HOVERED ANIMATION (when multipleFooterAnimations plays for all the prompts)!!!!
    // dublicate the state here because animations do not work 
    // smoothly if currentActiveIdx accessed directly
    const [currActiveIdx, setCurrActiveIdx] = useState(currentActiveItem);
    const [showConsent, setShowConsent] = useState(false);
    const showConsentTmIdRef = React.useRef(null);
    // dublicate state var
    useEffect(() => {
        if (currentActiveItem !== null && currentActiveItem !== undefined) {
            setCurrActiveIdx(currentActiveItem);
        } else {
            setCurrActiveIdx(-1);
        }
    }, [currentActiveItem])

    // For proper show up animation timing of the Consent Component.
    useEffect(() => {
        // means we got to the last element
        if (numOfShownElements >= options.length - 1) {
            showConsentTmIdRef.current = setTimeout(() => {
                setShowConsent(true);
            }, 400);
        }
    }, [numOfShownElements])

    // mount/unmount
    useEffect(() => {
        return () => {
            clearTimeout(showConsentTmIdRef.current);
        }
    }, [])

    // Measuring Consent Part.
    const measureContainerAdditionalStyle = {
        maxWidth: window.innerWidth > 900
            ? 'min(60%, calc(100vw - 50px))'
            : 'min(90%, calc(100vw - 50px))'
    };
    const {
        dimensions: consentDimensions,
        isMeasured: isConsentDimensionsMeasured,
        renderOffScreen: measureConsentPart
    } = useMeasureOffScreen(
        // Only for measuring purposes
        <ConsentContainer
            errand={errand}
            measuring={true}
            message={message}
            renderFinal={true}
            html={consentHtml}
            isOperator={isOperator}
            parentMsgType={'WelcomeUser'}
        />,
        measureContainerAdditionalStyle
    );

    if (isConsentDimensionsMeasured === false) {
        return <>{measureConsentPart}</>;
    }

    return (
        <Fade in={isFading} appear={false}>
            <>
                {/* Render options list animated & final */}
                <HintOptionList
                    options={options}
                    dimensions={dimensions}
                    renderFinal={renderFinal}
                    currActiveIdx={currActiveIdx}
                    suggestionTools={suggestionTools}
                    numOfShownElements={numOfShownElements}
                    isCurrMsgLastInChat={isCurrMsgLastInChat}
                    handleWorkflowOrMorphType={handleWorkflowOrMorphType}
                />
                {/* Render Consent part Both animated & final */}
                <ConsentContainer
                    errand={errand}
                    message={message}
                    measuring={false} // because measuring takes place before actual rendering phase.
                    show={showConsent} // controls when to play mount animation (either slide in or expand)
                    html={consentHtml} // {accepted: string, initial: string}. (translations are already handled here)
                    isOperator={isOperator} // needed for business logic.
                    renderFinal={renderFinal} // if true: render static, otherwise animated.
                    parentMsgType={'WelcomeUser'} // curr welcome message type
                    measuredHeight={consentDimensions[0].height}
                />
            </>
        </Fade>
    );
};


export { ContentContainer };