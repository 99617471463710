import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  setPreselectedLanguage,
  getPreselectedLanguage,
  setCountryCode,
  setStateISO,
  setZipCode,
  setTimezone,
  setUserLocation,
} from '@storage/userStorage';

/**
 * Custom React hook for managing socket.io events from AngelAi-Messages.
 * @param {string} _id - User ID.
 * @param {string} token - Authentication token.
 * @returns {} - Socket instance or null if _id or token are not provided.
 */
const useWidget = () => {
  const { i18n } = useTranslation();
  const [ searchParams ] = useSearchParams();

  const [isWidget, setIsWidget] = useState(false);
  const [origin, setOrigin] = useState('');

  /**
   * Handle GET params
   */
  useEffect(() => {
    // set initial language in order of importance
    let language = searchParams.get('lang') || getPreselectedLanguage() || 'en';
    i18n.changeLanguage(language);
    setPreselectedLanguage(language);

    // Store Location Information in local storage, if it exists in URL
    if (searchParams.get('countryCode')) setCountryCode(searchParams.get('countryCode'));
    if (searchParams.get('stateISO')) setStateISO(searchParams.get('stateISO'));
    if (searchParams.get('zipCode')) setZipCode(searchParams.get('zipCode'));
    if (searchParams.get('timezone')) setTimezone(searchParams.get('timezone'));
    if (searchParams.get('userLocation')) setUserLocation(searchParams.get('userLocation'));

    if (isWidget) {
      return;
    }

    if ((searchParams.get('view') === 'desktop' || searchParams.get('view') === 'mobile') && searchParams.get('origin') && searchParams.get('widget')) {
      setIsWidget(true);
      // createErrandForWidget();
    }
    setOrigin(searchParams.get('origin'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isWidget]);

  return {
    isWidget,
    origin,
  };
};

export default useWidget;
