/**
 * @file This component is a stylized Dialog component
 * for styling any modal in Morgan
 * 
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */

import { Dialog, styled } from "@mui/material";

const StyledDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== 'modalType',
})(
  ({ theme, modalType }) => {
    if (modalType === 'permissionReminder') {
      return `
      & .MuiDialog-paper{ 
        box-sizing: border-box;
        margin: 0px;
        width: calc(100% + 40px);
        max-width: calc(100% + 40px);
        position: absolute;
        bottom: 0px; // must be changed when you want the compoennt to slide
        border-radius: 37px 37px 0 0;
        transition: all .5s linear;
        text-align: left;
        background-color: var(--gray000);
        z-index: 310000000; // forces element to appear over timesatmps
      }

      & .MuiDialog-paper > div {
      padding: 20px 40px 8px; // removes dead space above that was removed with negative bottom
        border-radius: 37px 37px 0 0;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      & .MuiDialogTitle-root { // h1
        padding: 0px;
        display: block;
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: var(--gray950);
        text-align: center;
        margin: 0;
        margin-bottom: 0px;
        font-family: ${theme.typography.fontFamily};
      }
      & .MuiDialogTitle-root:before{
        content: "";
        background-color: var(--orange700);
        display: block;
        width: 226px;
        height: 236px;
        border-radius: 50%;
        filter: blur(100px);
        position: absolute;
        top: -101px;
        left: -141px;
        pointer-events: none;
      }
      & .MuiDialogTitle-root:after{
        content: "";
        background-color: var(--orange700);
        display: block;
        width: 226px;
        height: 236px;
        border-radius: 50%;
        filter: blur(100px);
        position: absolute;
        top: -101px;
        right: -141px;
        pointer-events: none;
      }
      & .MuiDialogContent-root {
        padding: 0px;
      }
      & .MuiDialogContentText-root { // p
        color: var(--gray600);
        font-size: 12px;
        padding: 0 0 18px 0;
        font-family: ${theme.typography.fontFamily};
        display: block;
        text-align: center;
        position: relative;
        max-height: calc(50vh - 45px); // forces text to show propoerly on widget
        overflow: auto; // forces text to scroll if needed
        margin: 0; // required to reduce space between title and text
      }
      & .MuiDialogActions-root { // div > div
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
      }
      & .MuiDialogActions-root:after {
        content: "";
        background-color: var(--orange700);
        display: block;
        width: 226px;
        height: 236px;
        border-radius: 50%;
        filter: blur(100px);
        position: absolute;
        top: -101px;
        right: -141px;
        pointer-events: none;
      }
      & .MuiDialogActions-root > .MuiButton-root {
        width: 156px;
        height: 46px;
        padding: 15px 18px 0 8px;
        margin: auto;
        position: relative;
        border: none;
        background-color: transparent;
      }
      & .MuiDialogActions-root > .MuiButton-root > span {
        display: block;
        position: relative;
        color: var(--gray000);
        font-size: 14px;
        font-family: ${theme.typography.fontFamily}
      }
      & .MuiDialogActions-root > .MuiButton-root > svg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    `
    }
    if (modalType === 'consent') {
      return `
      & .MuiDialog-paper{ // consentNotification
        box-sizing: border-box;
        margin: 0px;
        width: calc(100% + 40px);
        max-width: calc(100% + 40px);
        position: absolute;
        bottom: 0px; // must be changed when you want the compoennt to slide
        border-radius: 37px 37px 0 0;
        transition: all .5s linear;
        text-align: center;
        background-color: var(--gray000);
        z-index: 310000000; // forces element to appear over timesatmps
      }
  
      & .MuiDialog-paper > div {
      padding: 20px 40px 8px; // removes dead space above that was removed with negative bottom
        border-radius: 37px 37px 0 0;
        position: relative;
        display: grid;
        grid-template-rows: auto auto auto;
        overflow: hidden;
      }
  
      & .MuiDialogTitle-root { // h1
        padding: 0px;
        display: block;
        position: relative;
        font-size: 20px;
        font-weight: bold;
        color: var(--gray950);
        text-align: center;
        margin: 0;
        margin-bottom: 0px;
        font-family: ${theme.typography.fontFamily};
      }
      & .MuiDialogTitle-root:before{
        content: "";
        background-color: var(--orange700);
        display: block;
        width: 226px;
        height: 236px;
        border-radius: 50%;
        filter: blur(100px);
        position: absolute;
        top: -101px;
        left: -141px;
      }
      & .MuiDialogTitle-root:after{
        content: "";
        background-color: var(--orange700);
        display: block;
        width: 226px;
        height: 236px;
        border-radius: 50%;
        filter: blur(100px);
        position: absolute;
        top: -101px;
        right: -141px;
      }
      & .MuiDialogContent-root {
        padding: 0px;
      }
      & .MuiDialogContentText-root { // p
        color: var(--gray600);
        font-size: 12px;
        font-family: ${theme.typography.fontFamily};
        display: block;
        position: relative;
        max-height: calc(50vh - 45px); // forces text to show propoerly on widget
        overflow: auto; // forces text to scroll if needed
        margin: 0; // required to reduce space between title and text
      }
      & .MuiDialogActions-root { // div > div
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        position: relative;
        max-width: 450px;
        width: 100%;
        margin: auto;
      }
      & .MuiDialogActions-root:after {
        content: "";
        background-color: var(--orange700);
        display: block;
        width: 226px;
        height: 236px;
        border-radius: 50%;
        filter: blur(100px);
        position: absolute;
        top: -101px;
        right: -141px;
      }
      & .MuiDialogActions-root > button {
        border: 1px solid var(--peach800);
        position: relative;
        border-radius: 6px;
        height: 32px;
        width: 85px;
        cursor: pointer;
        font-family: ${theme.typography.fontFamily};
      }
      & .MuiDialogActions-root > button:nth-of-type(1) {
        background-color: var(--gray210);
        color: black;
      }
      & .MuiDialogActions-root > button:nth-of-type(2) {
        background-color: var(--green700);
        color: var(--gray000);
      }
    `
    }
  }
);

export default StyledDialog;