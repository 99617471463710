/**
 * @file This file contains the definition of the `AccessType` enum. This contains enumerators for all
 * the possible access types which might be used.
 * @author Maverick Manasco
 */

/**
 * All valid access type defined here
 */
enum AccessType {
  internal = 'internal',
  private = 'private',
  public = 'public',
  system = 'system'
}

// Set that contains all valid AccessType
const validAcccessSet = new Set(Object.values(AccessType));

/**
 *
 * @param type check string if it's valid AccessType
 * @returns true/false
 */
const isValidAccessType = (type: any): boolean => {
  return validAcccessSet.has(type);
};

export { AccessType, isValidAccessType };
