/**
 * @file This component is a stylized file for the 
 * UserPromptList component and all classes within
 *
 * @author Harrison Fales <harrison.fales@swmc.com>
 * 
 */

import { List, ListItemButton, TextField } from '@mui/material';
import { Box, Stack, styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

export const StyledUserPromptListContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isDesktop',
})<any>(
    ({ isDesktop }) => `
    height: 100%;
    width: 100%;
    outline: 0.125rem solid var(--peach900);
    outline-offset: -0.0625rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    top: 0;
    left: 0;
    background-color: var(--gray000);
    z-index: 999;
    border-radius: ${isDesktop ? '0.5rem' : '0'};
    transition: all 1s;
    &.slideIn {
        left: 0rem;
    }
    &.slideOut {
        left: 18.75rem;
    }
  `
);

export const PromptList = styled(List)({
    width: '100%',
    height: '100%',
    paddingTop: 0,
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
});

export const PromptButtonContainer = styled(ListItemButton)({
    padding: '0.75rem 0.5rem 0.75rem 0.75rem',
    transform: 'translateX(-0.1875rem)',
    display: 'flex',
    background: 'var(--gray000)',
    borderLeft: '0.25rem solid var(--peach900)',
    paddingLeft: '0.75rem',
    fontFamily: 'var(--typographyFontFamily)',
    '--clr': 'var(--peach200)',
    '&:hover': {
        '--clr': 'var(--orange052)',
        background: 'var(--gray010)',
    }
});

export const ErrandsFooterStyledCard = styled(Box)({
    height: '3.625rem',
    border: '0.0625rem dashed var(--peach900)',
    borderRadius: "0.3125rem",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 0.5rem 0.5rem 0.5rem',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    transform: 'translateX(-0.1875rem)',
    minWidth: 'calc(100% - 0.1875rem)',
    background: 'var(--gray000)',
    marginLeft: '0.3125rem',
    cursor: 'pointer',
    '--clr': 'var(--peach200)',
    '&:hover': {
        '--clr': 'var(--orange052)',
        background: 'var(--gray010)',
    },
});

export const StyledCard = styled(Box)({
    height: '3rem',
    border: '0.0625rem dashed var(--peach900)',
    borderRadius: "0.3125rem",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
});

export const StyledStack = styled(Stack)({
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0.375rem 0 0.375rem',
})

export const Prompt = styled(Box)(
    ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--peach900);
    font-weight: 700;
    font-size: ${useMediaQuery(theme.breakpoints.up('md')) ? '0.875rem' : '0.625rem'};
    width: 100%;
    text-align: center;
`);

export const IconHolder = styled('img')({
    width: '1.875rem',
    height: '1.875rem',
    filter: 'invert(83%) sepia(54%) saturate(1873%) hue-rotate(316deg) brightness(104%) contrast(95%)'
});

export const StyledPlaceHolderStack = styled(Stack)({
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
    width: '100%',
    fontFamily: 'var(--typographyFontFamily)',
    color: 'var(--peach900)',
    fontWeight: '600'
});

export const StyledInput = styled(TextField)({
    width: '100%',
    backgroundColor: 'var(--peach600)',
    color: 'var(--gray000)'
})
