import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Card, ListItem, ListItemButton, ListItemText, MenuItem, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, useTheme } from '@mui/material/styles';

import useInitialMount from '@common/hooks/useInitialMount';
import AIAddDialog from '@components/AIAddDialog';
import axiosCall from '@services/axios';
import { sectionNameTranslationKeys, aiSectionNameTranslationKeys } from '../Constants/consoleConstants';

const operatorAxios = axiosCall;

const CustomListItem = styled(ListItem)(({ theme }) => ({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: 'var(--gray000)',
    '&, & .MuiListItemIcon-root': {
      color: theme.palette.blue['200'],
    },
  },
}));

/**
 * The navigation menu for this component. Generates links based on the
 * subsections of this component defined in {@link sectionNameTranslationKeys}
 */
const NavigationList = ({ location, navigate, t, theme, baseModule, closeMenu }) => {
  return (
    <Box>
      {Object.values(aiSectionNameTranslationKeys).map((subModule) => (
        <CustomListItem key={subModule} theme={theme} sx={{ padding: '0.1rem 0' }}>
          <ListItemButton
            sx={{ width: 'max-content' }}
            onClick={() => {
              navigate('/console', {
                state: {
                  module: `${baseModule}/${subModule}`,
                },
              });
              closeMenu();
            }}
            selected={location.state?.module === `${baseModule}/${subModule}`}
          >
            <ListItemText primary={t(subModule)} />
          </ListItemButton>
        </CustomListItem>
      ))}
    </Box>
  );
};

const baseModule = `${sectionNameTranslationKeys['Admin']}/${sectionNameTranslationKeys['AI']}`;
const AINavigationMenu = ({ selectedAI, setSelectedAI, closeMenu }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [addingAI, setAddingAI] = useState(false);
  const [aiOptions, setAIOptions] = useState([]);

  const loadAIData = async (firstLoad = false) => {
    setLoading(true);
    try {
      const result = await operatorAxios({
        url: 'ai?fields=operatorId',
      });
      if (Array.isArray(result) && result.length > 0) {
        const aiOptions = result.reduce((prev, curr) => [...prev, curr.operatorId], []);
        setAIOptions(aiOptions);
        /*
        We only want to select the first AI configuration returned by the endpoint if this is the first time loading the
        component and no AI was previously selected, or if there was an AI previously selected, we will also ignore it
        if the configurations returned by the endpoint no longer include it (due to data having changed on the backend).
        The second scenario is not very likely but should still be accounted for.
        */
        if ((firstLoad && !selectedAI) || (selectedAI && !aiOptions.includes(selectedAI))) {
          setSelectedAI(result[0].operatorId);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAI = () => {
    setAddingAI(true);
  };

  const handleAddAIClose = () => {
    setAddingAI(false);
  };

  const handleAISelector = (e) => {
    setSelectedAI(e.target.value);
  };

  useInitialMount(() => loadAIData(true));

  return (
    <>
      <Card sx={{ pt: 2, pb: 1, px: 2, minWidth: 'fit-content' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 1, gap: 1 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              sx={{ minWidth: 'auto', paddingX: 1 }}
              variant="contained"
              color="info"
              onClick={() => {
                handleAddAI();
              }}
            >
              <AddIcon sx={{ p: 0 }} />
            </Button>
          )}
          <TextField
            label={t('AI')}
            select
            sx={{ minWidth: '280px' }}
            fullWidth
            size="small"
           
            value={selectedAI}
            SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500 } } } }}
            onChange={(e) => {
              if (loading) {
                return;
              }
              handleAISelector(e);
            }}
          >
            {aiOptions.map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <NavigationList t={t} location={location} navigate={navigate} theme={theme} baseModule={baseModule} closeMenu={closeMenu} />
      </Card>
      <AIAddDialog open={addingAI} onClose={handleAddAIClose} />
    </>
  );
};

export default AINavigationMenu;
