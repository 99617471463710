import { CircularProgress, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Close } from '@mui/icons-material';
import ScreenRotation from '@mui/icons-material/ScreenRotation';
import YoutubeFrame from '@components/YoutubeFrame';
import containsVideo from '@common/containsVideo';
import axiosCall from '@services/axios';
import MessageSender from '@components/MessageSender';
import { MessageContent, SpeakerModal } from '@styles/MessageContent/VideoMessageContentStyle';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import useInitialMount from '@common/hooks/useInitialMount';
const sunWestLogo = process.env.REACT_APP_MORGAN_CDN + '/Images/SunWestLogo.jpg';

// Put time component into it's own functional component
// so that it will not affect or trigger additional
// rendering of the parent component, VideoMessageContent.js
const Timer = (props) => {

  const [countDown, setCountDown] = useState('');

  useEffect(() => {
    // Custom timer function to count down to the start data of a stream
    // timer variable is declared outside of the startCountDown function
    // so we can clear the interval on unmount
    let timer;
    const startCountDown = (startTime) => {
      if (!startTime) {
        return;
      }
      let end = new Date(startTime);
      let _second = 1000;
      let _minute = _second * 60;
      let _hour = _minute * 60;
      let _day = _hour * 24;
      const showRemaining = () => {
        let now = new Date();
        let distance = end.getTime() - now.getTime();
        if (distance < 0) {
          clearInterval(timer);
          return;
        }
        let days = Math.floor(distance / _day).toString();
        let hours = Math.floor((distance % _day) / _hour).toString();
        let minutes = Math.floor((distance % _hour) / _minute).toString();
        let seconds = Math.floor((distance % _minute) / _second).toString();
        setCountDown(`${days}:${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`);
      }
      timer = setInterval(showRemaining, 1000);
    };
    startCountDown(props.startTime);
    return () => {
      clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return new Date() > new Date(props.startTime) && props.actualStartTime !== undefined ? (
    <div className="videoLive">&#128308; Live</div>
  ) : (
    <div className={props.className}>{countDown === '' ? '00:00:00:00' : countDown}</div>
  );
};

type TVideoMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const VideoMessageContent = ({
  errand, message
}: TVideoMessageContentProps) => {
  const [isWatching, setIsWatching] = useState(false);
  const [speakerData, setSpeakerData] = useState([]);
  const [videoData, setVideoData] = useState({
    id: '',
    title: '',
    thumbnail: '',
    embedHtml: '',
    embedUrl: '',
    liveStreamStatus: '',
    scheduledStartTime: '',
    actualStartTime: '',
  });
  const [speakerDetailsIndex, setSpeakerDetailsIndex] = useState(0);
  const [speakerModalIsOpen, setSpeakerModalIsOpen] = useState(false);
  const [videoFrame, setVideoFrame] = useState('');
  const errandContext = useErrandContext();
  const bubbleHolder = useRef(null);
  const bubbleHolderFull = useRef(null);

  const setAndOpenSpeakerDetails = (index) => {
    setSpeakerDetailsIndex(index);
    setSpeakerModalIsOpen(true);
  };

  // onError function which resets the src of the image element
  // if the URL fails to load
  const setFallBackImage = (e) => {
    return (e.target.src = sunWestLogo);
  };

  // Logic for displaying different bubble holder uses useRefs
  // to prevent rerender, which interfers with the timer concurrency
  const showBubbleHolderFull = (e) => {
    e?.stopPropagation();
    bubbleHolderFull.current.style.display = 'flex';
    return (bubbleHolder.current.style.display = 'none');
  };

  //Function to extract embed URL from YouTube info,
  //and render an iframe element to display non-livestream
  const renderPlayer = useCallback((videoData) => {
    // NOTICE: Only use DOM Parser to pull data, do NOT use it to set html content.
    let parser = new DOMParser();
    let html = parser.parseFromString(videoData.embedHtml, 'text/html');
    let embedUrl = html.getElementsByTagName('iframe')[0].getAttribute('src');
    let params = '';
    try {
      const searchParams = new URLSearchParams(new URL(message.message.split('&amp;').join('&')).search);

      // Initialize an empty object to store the parameters
      const keyValuePairs = [];

      // Loop through each search parameter and store it in the object
      for (const [key, value] of searchParams.entries()) {
        if (key === 'v' || key === 'si') continue;
        if (key === 't') {
          keyValuePairs.push(`start=${value}`);
        }
        keyValuePairs.push(`${key}=${value}`);
      }

      // Join the array elements with '&' to form the URL string
      params = keyValuePairs.join('&');
    } catch (err) {
      console.warn(`Could not parse params, `, err);
      params = '';
    }
    let iframe = `
                <iframe 
                    type="text/html" 
                    width="200" 
                    height="200" 
                    modestbranding="1"
                    src="${embedUrl}?autoplay=0&enable_js=1${params ? `&${params}` : ''}"
                    frameborder="0"
                    allow="autoplay *; fullscreen *"
                    allowfullscreen="true">
                </iframe>`;
    setVideoFrame(iframe);
  }, [message._id, message.message]);

  const getYoutubeInfo = useCallback(async (videoId) => {
    //Return if no video ID
    if (!videoId) {
      return;
    }
    try {
      //Hit Morgan-Core using the videoId to grab needed YouTube info
      let response = await axiosCall({
        url: `url/youtube`,
        method: 'post',
        data: {
          videoId: videoId,
        },
      });
      // NOTICE: Only use DOM Parser to pull data, do NOT use it to set html content.
      //Create new DOM Parser to retrieve specifically Embed url
      let parser = new DOMParser();
      let embedHtml = response.youtubeData.items[0].player.embedHtml;
      let html = parser.parseFromString(embedHtml, 'text/html');
      let embedUrl = html.getElementsByTagName('iframe')[0].getAttribute('src');
      let youtubeDesc = response.youtubeData.items[0].snippet.description?.split('Speaker Information:')?.[1];
      // If we have speaker information from a Sun West livestream, we parse the text
      if (youtubeDesc && response.youtubeData.items[0].snippet.liveBroadcastContent !== 'none') {
        let trimmedArr = youtubeDesc.split(/\n/g).filter((line) => !!line.trim());
        let arrArr = [];
        for (let i = 0; i < trimmedArr.length; i += 4) {
          let speakerChunk = trimmedArr.slice(i, i + 4);
          arrArr.push({
            name: speakerChunk[0],
            occupation: speakerChunk[1],
            photo: speakerChunk[2].slice(speakerChunk[2].indexOf('(') + 1, speakerChunk[2].indexOf(')')),
            description: speakerChunk[3],
          });
        }
        setSpeakerData(arrArr);
      }
      //Set needed data to state.
      const localVideoData = {
        id: videoId,
        title: response.youtubeData.items[0].snippet.title,
        thumbnail: response.youtubeData.items[0].snippet.thumbnails.default.url,
        embedHtml,
        embedUrl,
        scheduledStartTime: response.youtubeData.items[0].liveStreamingDetails?.scheduledStartTime,
        actualStartTime: response.youtubeData.items[0].liveStreamingDetails?.actualStartTime,
        liveStreamStatus: response.youtubeData.items[0].snippet.liveBroadcastContent,
      }
      setVideoData(localVideoData);

      console.error(response.youtubeData.items[0].snippet.liveBroadcastContent === 'none')
      if (response.youtubeData.items[0].snippet.liveBroadcastContent === 'none') {
        renderPlayer(localVideoData);
      }
    } catch (err) {
      return console.log('No YouTube Data Found');
    }
  }, [renderPlayer]);

  const getVideoInfo = useCallback((videoInfo) => {
    //If there is no video info return
    if (!videoInfo) {
      return;
    }
    try {
      // NOTICE: Only use DOM Parser to pull data, do NOT use it to set html content.
      //Create new DOM Parser to retrieve specifically Embed url
      let parser = new DOMParser();
      let embedHtml = videoInfo.html;
      let html = parser.parseFromString(embedHtml, 'text/html');
      let embedUrl = html.getElementsByTagName('iframe')[0].getAttribute('src');
      //Set video data to state
      const localVideoData = {
        id: videoInfo.video_id,
        title: videoInfo.title,
        thumbnail: videoInfo.thumbnail_url,
        embedHtml,
        embedUrl,
        scheduledStartTime: '',
        actualStartTime: '',
        liveStreamStatus: 'none',
      }
      setVideoData(localVideoData);

      renderPlayer(localVideoData);
    } catch (err) {
      return console.log('No Video Data Found');
    }
  }, [renderPlayer]);

  // Dynamically request the embedded iframe element to go
  // full screen
  const openFullscreen = (e) => {
    e?.stopPropagation();
    //Disable fullscreen button if live stream isn't being watched
    if (!isWatching) {
      return;
    }
    // Using built in browser API to turn on fullscreen
    let player = document.getElementById(`ytplayer-${message._id}`);
    if (player.requestFullscreen) {
      player.requestFullscreen();
    } 
    /*
    These do not exist in TypeScript React. They will need to be implemented in their own respective
    dom.safari.d.ts
    dom.firefox.d.ts
    dom.ie.d.ts

    interface Element {
      {webkit|moz|ms}RequestFullscreen(): void;
    }
    else if (player.webkitRequestFullscreen) {
      player.webkitRequestFullscreen();
    } else if (player.mozRequestFullScreen) {
      player.mozRequestFullScreen();
    } else if (player.msRequestFullscreen) {
      player.msRequestFullscreen();
    }
    */
    //Set window screen orientation to landscape
    return window.screen.orientation.lock('landscape-primary');
  };

  // Custom function to play video when player is ready. Uses YouTube API
  // due to preventions of autoplay in mobile environments
  const onPlayerReady = (e) => {
    if(e.target){
      e.target.playVideo();
    }
  };

  //Placing logic for fetching video meta data
  //in this separate function in order to
  //utilize the async aspects of the network calls
  const fetchVideoMetaData = useCallback(async () => {
    let videoInfo = await containsVideo(message.message);
    if (videoInfo.kind === 'youtube#video') {
      getYoutubeInfo(videoInfo.id);
    } else {
      getVideoInfo(videoInfo);
    }
  }, [getYoutubeInfo, getVideoInfo, message.message]);

  useInitialMount(fetchVideoMetaData);

  const handleJoinWatchClick = (e) => {
    e?.stopPropagation();
    // Reflect isWatching on ErrandContext.
    // It is used withing ActivityTracker component, specifically for Live sub-component.
    // See components/ActivityTracker/Elements/Live for more info.
    errandContext.setIsUserWatchingLive(true);
    setIsWatching(true);
  }

  const isEditing = useMemo(() => {
    return errandContext.editMessageId === message?._id;
  }, [errandContext.editMessageId, message?._id]);

  return (
    <MessageContent
      islive={videoData?.liveStreamStatus === 'live' || videoData?.liveStreamStatus === 'upcoming' ? 'true' : undefined}
      alignWithCurrentUser={message?.alignByCurrentUser}
      speakerdata={speakerData?.length}
    >
      {!videoData?.id ? (
        <div className='spinnerContainer'>
          <CircularProgress />
        </div>
      ) : (
        <div className={"contentWrapper" + (videoData?.liveStreamStatus === 'none' ? "mt" : '')}>
          {/* This ternary is specific to rendering the author name, since streams do Not
          display this */}
          {videoData?.liveStreamStatus === 'none' && (
            <MessageSender errand={errand} message={message} isEditing={isEditing} />
          )}
          {videoFrame ? (
            <div className="videoFrame" dangerouslySetInnerHTML={{ __html: videoFrame }} />
          ) : (
            <div className="video">
              {/* If the user has clicked the Watch/Join button, the live
              stream component will be rendered */}
              {isWatching ? (
                <YoutubeFrame
                  title={videoData.title}
                  scheduledStartTime={videoData.scheduledStartTime}
                  actualStartTime={videoData.actualStartTime}
                  messageId={message._id}
                  embedUrl={videoData.embedUrl}
                  videoId={videoData.id}
                  onReady={onPlayerReady}
                />
              ) : (
                <div className="StreamBubbleStyle">
                  <SpeakerModal open={speakerModalIsOpen} hideBackdrop={true}>
                    <div className="modalContent">
                      <div className="iconContainer">
                        <Close style={{ cursor: 'pointer', pointerEvents: 'all' }} onClick={(e) => {
                          e?.stopPropagation();
                          setSpeakerModalIsOpen(false);
                        }} />
                      </div>
                      <h2>{speakerData?.[speakerDetailsIndex]?.name}</h2>
                      <h4>{speakerData?.[speakerDetailsIndex]?.occupation}</h4>
                      <div className="detailsContainer">
                        <p>{speakerData?.[speakerDetailsIndex]?.description}</p>
                      </div>
                    </div>
                    <div className="bottomNotch">
                      <span className="circle"></span>
                      <img className="speakerPhoto" src={speakerData?.[speakerDetailsIndex]?.photo} onError={(e) => setFallBackImage(e)} alt="Speaker" />
                    </div>
                  </SpeakerModal>
                  <div className="titleThumbnailBubbleColumn">
                    <div className="titleThumbnailBubbleRow">
                      <img className="streamThumbnail" src={videoData.thumbnail} alt="Stream Thumbnail" />
                      <div className="titleAndTimerStack">
                        <Tooltip title={videoData.title} placement="top-start" enterDelay={500} enterNextDelay={500}>
                          <div className="videoTitle">{videoData.title}</div>
                        </Tooltip>
                        <Timer
                          className="videoCountDown"
                          actualStartTime={videoData.actualStartTime}
                          startTime={videoData.scheduledStartTime}
                        />
                        {/* Default bubble holder, positioned inside the timer count for more 
                      compact styling */}
                        <div ref={bubbleHolder} className="bubbleHolder">
                          {speakerData?.map((bubble, index) =>
                            index > 2 ? null : (
                              <img
                                key={index}
                                src={bubble.photo}
                                style={{pointerEvents: 'all'}}
                                onError={(e) => setFallBackImage(e)}
                                alt="Speaker"
                                onClick={(e) => {
                                  e?.stopPropagation();
                                  setAndOpenSpeakerDetails(index);
                                }}
                              />
                            )
                          )}
                          {speakerData.length > 3 && (
                            <div className="extraSpeakerBubble" onClick={showBubbleHolderFull} style={{pointerEvents: 'all'}}>
                              <span>{`+${speakerData.length - 3}`}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Secondary bubbble holder positioned outside the div above for
                  ability to wrap bubbles if they exceed a certain number */}
                    <div ref={bubbleHolderFull} className="bubbleHolderFull">
                      {speakerData?.map((bubble, index) => (
                        <img
                          key={index}
                          src={bubble.photo}
                          style={{pointerEvents:'all'}}
                          onError={(e) => setFallBackImage(e)}
                          alt="Speaker"
                          onClick={(e) => {
                            e?.stopPropagation();
                            setAndOpenSpeakerDetails(index);
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="WatchJoinButton" style={{pointerEvents:'all'}} onClick={handleJoinWatchClick}>
                    <span style={{ fontWeight: '900' }}>+</span>
                    <span>
                      {new Date() > new Date(videoData.scheduledStartTime) || !!videoData.actualStartTime ? 'Watch' : 'Join'}
                    </span>
                  </div>
                </div>
              )}
              <div className="RotateButtonContainer">
                <div />
                <div>
                  <ScreenRotation onClick={openFullscreen} style={{pointerEvents:'all'}} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </MessageContent>
  );
};

export default VideoMessageContent;