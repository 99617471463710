import { Box } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import React, { PropsWithChildren } from 'react';
import ReactApexChart from 'react-apexcharts';

/*
 *  This component makes up the graph seen in the top left most bar across the
 * top of the monitoring page in the console. To see an example of data, see the
 * JSON at ../Assets/testmonitoring.json.
 *
 *  This component uses the following props:
 *    - series: this is the data that is displayed. For an example see the file
 *      mentioned above.
 */

const MonitoringChart : React.FC<PropsWithChildren<any>> = (props) => {
  const options : ApexOptions = {
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false,
      },
      selection: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 0,
    },
    xaxis: {
      categories: props.categories,
      tickPlacement: "between"
    },
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        formatter: function (value : number, { series, seriesIndex, dataPointIndex, w } : any) {
          return (value.toString());
        },
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
  };

  return (
    <Box sx={{
      width: 'auto',
      display: "flex",
      justifyContent: "center"
    }}>
      <ReactApexChart
        options={options}
        series={props.series}
        type="area"
        height={130}
        width={650} />
    </Box>
  );
}

export default MonitoringChart;
