import React, { memo } from 'react';
import { HttpsOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { DefaultElement } from './templates/DefaultElement';
import { IconStyles } from '../Constants';

export const AuthElement = memo(() => {
  const { t } = useTranslation();

  return (
    <DefaultElement
      text={t('verifiedTranslation')}
      icon={<HttpsOutlined style={{...IconStyles.auth}} />}
    />
  );
});
