import React from 'react';
import { styled } from '@mui/system';

import Icon from "./Icon";

const IconBackground = styled(Icon)(({ theme }) => `
  backgroundColor: ${theme.palette.peach['600']};
  border: 1px solid ${ theme.palette.orange['700'] };
`)

export default IconBackground;