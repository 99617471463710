/**
 * This is a helper file to easily access the document event functions. 
 * It is a simple wrapper for adding and removing event listeners as 
 * well as dispatching events
 */
const eventBus = {
    // 8/2/2023 - bold: Warning you must pass function pointer
    // Don't pass something like () => {}
    // Make sure once you're done with subscription, remove it
    // You have to pass same function pointer to remove fn
    on(event, callback) {
      document.addEventListener(event, callback);
    },
    dispatch(event, data) {
      document.dispatchEvent(new CustomEvent(event, { detail: data }));
    },
    remove(event, callback) {
      document.removeEventListener(event, callback);
    },
  };
  
export default eventBus;