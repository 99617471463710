import axiosCall from '@services/axios';
import { AccessType } from '@common/AccessType';

const ITEM_TYPE = {
  ACTION: "action",
  WORKFLOW: "workflow"
} as const;

export interface IConfirmationResult {
  open: boolean,
  chat: string,
  type: string,
  id: string,
  recipients: string[],
  check: boolean,
  subWorkflow?: boolean
}

export interface IActionWorkflow {
  _id: string;
  type: string;
  description: string;
  message: string;
  fieldName: string;
  fieldAttribute: string;
  fieldQid: string;
  fieldEndpoint: string;
  indexField: boolean;
  actionIcon: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  visibility: {
    ai: boolean;
    operator: boolean;
    user: boolean;
  };
  changedBy: string;
}

/**
 * This function will trigger an action in a conversation. 
 * 
 * @param actionID 
 * @param actionName 
 * @param chatId 
 * @param recipients 
 * @param userId 
 * @param isOperator 
 * @param checkDup 
 * @returns 
 */
export const sendAction = async (actionID: string, actionName: string, chatId: string, recipients: string[], accessType: AccessType, userId: string, isOperator: boolean, checkDup: boolean | null): Promise <IConfirmationResult> => {
  return await submitToChat(actionID, actionName, ITEM_TYPE.ACTION, chatId, recipients, accessType, userId, isOperator, checkDup);
}

/**
 * This function will trigger a workflow on a conversation.
 * 
 * @param actionID 
 * @param actionName 
 * @param chatId 
 * @param recipients 
 * @param userId 
 * @param isOperator 
 * @param checkDup 
 * @param subWorkflow
 * @returns 
 */
export const sendWorkflow = async (actionID: string, actionName: string, chatId: string, recipients: string[], accessType: AccessType, userId: string, isOperator: boolean, checkDup: boolean | null, subWorkflow: boolean = false): Promise <IConfirmationResult> => {
  return submitToChat(actionID, actionName, ITEM_TYPE.WORKFLOW, chatId, recipients, accessType, userId, isOperator, checkDup, subWorkflow);
}

const fetchActionOrWorkflow = async (type, nameValue) => {
  return await axiosCall({
    url: `${type}/db/search?active=true&fields=active,_id`,
    method: 'post',
    data: { search: nameValue },
  });
}

const fetchWorkflowByName = async (nameValue) => {
  return await axiosCall({
    url: `workflow/getByName/${nameValue}`,
    method: 'get',
  });
}

const fetchActionByName = async (nameValue) => {
  return await axiosCall({
    url: `action/getByName/${nameValue}`,
    method: 'get',
  });
}

const doSendActionOrWorkflow = async (chatId, type, actionID, checkDup, userId, isOperator, owner, accessType, intendedAudience, subWorkflow) => {
  return await axiosCall({
    url: `chat/${chatId}/${type}/${actionID}${type === 'workflow' && checkDup !== null && checkDup === true ? '?check=true': ''}`,
    method: 'POST',
    data: {
      senderId: userId,
      senderType: isOperator ? 'Operator': 'User',
      owner: owner,
      accessType: accessType,
      ...(intendedAudience ? {intendedAudience}: {}),
      subWorkflow: subWorkflow
    },
  });
}
/**
 * Function to initiate an action or workflow programatically and confirm before sending a duplicate
 *
 */
async function submitToChat (actionOrWorkflowID: string, actionOrWorkflowName: string, type: 'action' | 'workflow', chatId: string, recipients: string[], accessType: AccessType, userId: string, isOperator: boolean, checkDup: boolean | null, subWorkflow: boolean = false): Promise <IConfirmationResult> {
  let confirmResendDialogResult = {} as IConfirmationResult;

  if(!chatId || chatId === '') return confirmResendDialogResult;

  // get the action or Workflow data
  if(actionOrWorkflowName !== null && actionOrWorkflowName !== '') {
    let fetchedActionsOrWorkflows;

    // WORKFLOW type
    try {
      if(type === 'workflow') {
        fetchedActionsOrWorkflows = await fetchWorkflowByName(actionOrWorkflowName);
      } else {
        fetchedActionsOrWorkflows = await fetchActionByName(actionOrWorkflowName);
      }
    } catch(err) {
      console.error(err);
      fetchedActionsOrWorkflows = await fetchActionOrWorkflow(type, actionOrWorkflowName);
    }

    // if action id is not provided
    if(actionOrWorkflowID === '') {
      if(Array.isArray(fetchedActionsOrWorkflows)) {
        actionOrWorkflowID = fetchedActionsOrWorkflows.filter((af: IActionWorkflow) => af.active)[0]?._id;
      } else {
        actionOrWorkflowID = fetchedActionsOrWorkflows?._id; // workflow fetched by name.
      }
    }
  }
  const owner = isOperator ? recipients[0]: userId;
  const intendedAudience = recipients?.sort()?.join(',') || '';
  let res;
  if (actionOrWorkflowID) {
    // Send the action or workflow request if the check flag is set then it will not send the action or workflow if there is one in progress already
    res = await doSendActionOrWorkflow(chatId, type, actionOrWorkflowID, checkDup, userId, isOperator, owner, accessType, intendedAudience, subWorkflow);
  } else {
    console.error(`Invalid ${type} actionOrWorkflowName: ${actionOrWorkflowName} did not return any results`);
  }
  // If we specified checkDup at all we need to be returning the object.
  if(checkDup !== null) {
    if (res?.remaingingUserActions) {
      confirmResendDialogResult = {
          open: true, // Causes resend dialog to appear
          chat: chatId,
          type: type,
          recipients: recipients,
          check: false, // Overrides the check operation so if they click resend it will not attempt duplicate check
          id: actionOrWorkflowID
        }
    } else {
      confirmResendDialogResult = {
        open: false, // Dont open the dialog since there wasnt a duplicate
        chat: undefined,
        type: undefined,
        id: undefined,
        recipients: recipients,
        check: true, // Be ready to check on the next workflow sending attempt for duplicates 
      }
    }
  }
  return confirmResendDialogResult;
}