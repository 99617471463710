import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

type TBreadcrumbNavProps = {
  baseModuleName: string;
  enableClicks: boolean;
};

/**
 * This component displays the user's current navigation path as breadcrumbs, assuming the 'module' of location.state
 * follows the standard format: baseModule/subModule1/subModule2/etc. The last submodule will not be clickable (since it
 * represents the user's current location), and the first submodule may be clickable depending on the prop passed.
 */
const BreadcrumbNav = ({ baseModuleName, enableClicks = false }: TBreadcrumbNavProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const [modules, setModules] = useState<string[]>(location.state?.module.split('/') ?? []);

  /**
   * Calculates the module path to navigate to based on the link clicked by the user. For example, if the modules are
   * [admin, system, settings], and the index of the clicked link is 1 (system), the function will return admin/system
   */
  const calculateModulePath = (moduleIndex: number): string => {
    if (moduleIndex < 0 || moduleIndex >= modules.length) {
      throw new Error(`Module index (${moduleIndex}) is out of bounds (modules: ${modules})`);
    }
    return modules.slice(0, moduleIndex + 1).join('/');
  };

  // Update the list of modules each time there is a change in location.state
  useEffect(() => {
    if (location.state?.module) {
      setModules(location.state.module.split('/'));
    } else {
      setModules([]);
    }
  }, [location.state]);

  return (
    !!modules.length &&
    modules[0] === baseModuleName && (
      <Breadcrumbs sx={{ padding: '0.1rem 0 0.2rem 0', fontSize: '1.05rem', textTransform: 'uppercase' }}>
        {modules.map((module, index) => {
          // If the breadcrumbs are meant to function as links, then we will render the first (n - 1) parts of the
          // current navigation path as links. Otherwise, each part will simply be generated as a <p> tag. In both
          // cases, the color scheme of the breadcrumbs will remain the same.
          if (enableClicks) {
            if (index < modules.length - 1) {
              return (
                <Link
                  key={module}
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  to={location.pathname}
                  state={{ module: calculateModulePath(index) }}
                >
                  {t(module)}
                </Link>
              );
            }
            return (
              <Typography
                key={module}
                fontWeight="fontWeightRegular"
                color={theme.palette.peach['900']}
                fontSize="inherit"
              >
                {t(module)}
              </Typography>
            );
          }
          if (index < modules.length - 1) {
            return (
              <Typography key={module} fontWeight="fontWeightRegular" fontSize="inherit">
                {t(module)}
              </Typography>
            );
          }
          return (
            <Typography
              key={module}
              fontWeight="fontWeightRegular"
              color={theme.palette.peach['900']}
              fontSize="inherit"
            >
              {t(module)}
            </Typography>
          );
        })}
      </Breadcrumbs>
    )
  );
};

export default BreadcrumbNav;
