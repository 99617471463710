/**
 * This file aims to define all required types for working with sockets.
 * Keep everything structured in similar formats and ordered alphabetically
 * 
 * In order to add new events you must complete all the steps below.
 * 1. Add the new event to SocketListenerType like so eventName = 'event-name';
 * 2. Create a new type definition named TEventName at // Type Definitions
 * 3. Add the new event record and type to SocketListenerMap as follows:
 *      [SocketListenerType.eventNamet]: TEventName;
 * 4. Update the validatePayloadData function as needed.
 * 5. Import and use the corresponding hook as needed.

const eventNameHandler = useCallback((data: TEventName) => {
  // access memoized dependies here without using a setter or converting to refs!
  // Never setDependency(dependency)! Setters are still needed here
}, [dependency]);
useEventsListener('FileName', SocketListenerType.eventName, eventNameHandler);
useMessagesListener('FileName', SocketListenerType.eventName, eventNameHandler);
 */

export enum SocketListenerType {
  joinedChat = 'joined-chat',
  leftChat = 'left-chat',
  chatStatusClosed = 'chat-status-closed',
  chatEventEmitted = 'chat-event-emitted',
  authActivityUpdate = 'auth-activity-update',
  showUserWallet = 'show-user-wallet',
  notificationBannerUpdate = 'notification-banner-update'
}

export type SocketListenerMap = {
  [SocketListenerType.joinedChat]: TJoinedChat;
  [SocketListenerType.leftChat]: TLeftChat;
  [SocketListenerType.chatStatusClosed]: TChatStatusClosed;
  [SocketListenerType.chatEventEmitted]: TChatEventEmitted;
  [SocketListenerType.authActivityUpdate]: TAuthActivityUpdate;
  [SocketListenerType.showUserWallet]: TShowUserWallet;
  [SocketListenerType.notificationBannerUpdate]: TNotificationBannerUpdate;
};

// Type Definitions
export type TJoinedChat = string;
export type TLeftChat = string;
export type TChatStatusClosed = {
  _id: string;
  parentId: string;
};
export type TChatEventEmitted = {
  type: string
}
export type TAuthActivityUpdate = {
  _id: string;
  ssAccessToken: string;
  lastActiveChat: string;
}
export type TShowUserWallet = {
  chat: string;
  userId: string;
  workflow: boolean;
  nftData?: Record<string, any>;
}

export type TNotificationBannerUpdate = {
  message?: string,
  enabled?: string
}



// Ignore everything below this line
export type TSocketPayload<T extends SocketListenerType> = SocketListenerMap[T];

export type TSocketCallback<T extends SocketListenerType> =
  (payload: TSocketPayload<T>) => void

export type TEventPayload<T extends SocketListenerType> = {
  message: string;
  data: SocketListenerMap[T];
};
