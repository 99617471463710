import React, { memo } from 'react';

import { QuickActionIcon } from '@assets/Icons/index';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import Styles from '@styles/MorphCreditRepairAccountType.module.css';
import { useSwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

const getSingleSlideElementStyle = (isLoadingElement, swiperSlide) => {
  return {
    height: '100%',
    display: 'flex',
    flexDirection: isLoadingElement ? 'row' : 'column',
    // border: props?.isLoadingElement ? 'none' : '2px solid var(--blue950)',
    boxShadow: isLoadingElement ? 'none' : `0 0 6px var(--shadow300)`,
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '6px',
  };
};

const CreditRepairPiggyBank = process.env.REACT_APP_MORGAN_CDN + '/Images/CreditRepair/CreditRepairPiggyBank.gif';
const CreditRepairQuestionBubble = process.env.REACT_APP_MORGAN_CDN + '/Images/CreditRepair/CreditRepairQuestionBubble.gif';
const CreditRepairMeseum = process.env.REACT_APP_MORGAN_CDN + '/Images/CreditRepair/CreditRepairMuseum.gif';
const CreditRepairDollarSign = process.env.REACT_APP_MORGAN_CDN + '/Images/CreditRepair/CreditRepairDollarSign.gif';
const CreditRepairDocument = process.env.REACT_APP_MORGAN_CDN + '/Images/CreditRepair/CreditRepairDocument.gif';
const CreditRepairGroup = process.env.REACT_APP_MORGAN_CDN + '/Images/CreditRepair/CreditRepairGroup.gif';

const getIcon = (name: string): string => {
  switch (name) {
    case 'Late Payments':
      return CreditRepairMeseum
    case 'Chargeoffs':
      return CreditRepairQuestionBubble
    case 'Collections':
      return CreditRepairPiggyBank
    case 'Inquiries':
      return CreditRepairDollarSign
    case 'Bankruptcies':
      return CreditRepairDocument
    case 'Other':
      return CreditRepairGroup
  }
};

const SlideIcon = (props) => {
  const { t } = useTranslation();
  const imgSrc = props?.icon;
  return imgSrc ? (
    <img
      src={imgSrc}
      style={{
        width: '2rem',
        height: '2rem',
      }}
      alt={t('UPM_Icon_displaying_a_workflow')}
    />
  ) : (
    <QuickActionIcon style={{ color: 'var(--gray000)' }} />
  );
};

// default classes for SingleSlideElement
const defaultClasses = [Styles.UserSelectNone];
const finalClasses = [Styles.SingleSlideElementHover, ...defaultClasses];

const getPStyle = (isLoadingElement) => {
  return {
    fontSize: '0.8rem',
    color: 'var(--gray000)',
    textAlign: 'center',
    lineHeight: '13px',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    marginTop: '5px',
    marginLeft: isLoadingElement ? '8px' : '0px',
  };
};

interface Props {
  isLoadingElement?: boolean;
  name: string;
  t: (arg: string) => string;
}

const SingleSlideElement = memo((props: Props) => {
  const swiperSlide = useSwiperSlide();
  // turn on hovering effect only on desktop
  let classNameStr = (props?.isLoadingElement || isMobileOrTablet() === true ? defaultClasses : finalClasses).join(' ');

  let displayName = '';
  switch (props.name) {
    case 'Late Payments':
      displayName = props.t('creditRepairLatePayments');
      break;
    case 'Chargeoffs':
      displayName = props.t('creditRepairChargeoffs');
      break;
    case 'Collections':
      displayName = props.t('creditRepairCollections');
      break;
    case 'Inquiries':
      displayName = props.t('creditRepairInquiries');
      break;
    case 'Bankruptcies':
      displayName = props.t('creditRepairBankruptcies');
      break;
    case 'Other':
      displayName = props.t('creditRepairOther');
      break;
    default:
      displayName = props.name;
  }

  return (
    <div style={getSingleSlideElementStyle(props?.isLoadingElement, swiperSlide)} className={classNameStr}>
      {/* Icon */}
      {<SlideIcon icon={getIcon(props.name)} />}
      {/* Title */}
      <p style={getPStyle(props?.isLoadingElement)}>{displayName}</p>
    </div>
  );
});

export { SingleSlideElement };