import { MorganTheme } from '@mui/material';
import { styled } from '@mui/system';

type TStyleProps = {
  theme?: MorganTheme; // NOTICE: Included by default with mui styled
};

export const Style = styled('div')<TStyleProps>(
  ({ theme }) => `
  width: 100%;
  padding: 10px 0px 10px 0px;
  gap: 8px;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 16px;
  .CBChild {
    display: flex;
    gap: 4px;
    width: 100%;
    flex-direction: column;
    .CBNameWrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 10px;
      strong {
        font-family: ${theme.typography.fontFamily};
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-transform: capitalize;
        display: block;
      }
      p {
        font-family: ${theme.typography.fontFamily};
        font-size: 10px;
        font-weight: normal;
        text-align: left;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }
    .CBPreviewWrapper {
      display: flex;
      flex-direction: row;
      gap: 8px;
      p {
        width: 179px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 18px;
        font-family: ${theme.typography.fontFamily};
        font-size: 11px;
        font-weight: normal;
      }
    }
  }
  `
);