import React from 'react';
import { useState, useEffect, useRef } from 'react';

type TDimensions = {
    width: number;
    height: number;
}[];

function useMeasureOffScreen(children, additionalMeasuringContainerStyle) {
    const [dimensions, setDimensions] = useState<TDimensions>([]);
    const [isMeasured, setIsMeasured] = useState(false);
    const refs = useRef([]);

    useEffect(() => {
        if (refs.current.length > 0) {
            // Measure the dimensions of each child element
            const newDimensions = refs.current.map((ref) => {
                if (ref) {
                    const { width, height } = ref.getBoundingClientRect();
                    return { width, height };
                }
                return { width: 0, height: 0 };
            });
            setDimensions(newDimensions);
            // Set flag to true indicating measurement is done
            setIsMeasured(true);
        }
    }, []);

    // Conditionally render the off-screen elements only if measurement is not completed
    const renderOffScreen = !isMeasured ? (
        Array.isArray(children)
            ? children.map((child, index) => (
                <div
                    key={index}
                    ref={(el) => (refs.current[index] = el)}
                    style={{
                        position: 'absolute',
                        top: '-9999px',
                        left: '-9999px',
                        ...additionalMeasuringContainerStyle
                    }}
                >
                    {child}
                </div>
            ))
            : (
                <div
                    ref={(el) => (refs.current[0] = el)}
                    style={{
                        position: 'absolute',
                        top: '-9999px',
                        left: '-9999px',
                        ...additionalMeasuringContainerStyle
                    }}
                >
                    {children}
                </div>
            )
    ) : null; // Once measured, the elements will be removed

    return { dimensions, isMeasured, renderOffScreen };
}

export default useMeasureOffScreen;
