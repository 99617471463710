import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import AISettings from '@components/AISettings';
import AITasksTab from '@components/AITasksTab';
import AIIntents from '@components/AIIntents';
import AIEntities from '@components/AIEntities';
import AIFunctions from '@components/AIFunctions';
import { sectionNameTranslationKeys, aiSectionNameTranslationKeys } from '../Constants/consoleConstants';

const baseModule = `${sectionNameTranslationKeys['Admin']}/${sectionNameTranslationKeys['AI']}`;
const AITab = ({ selectedAI, setSelectedAI }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // If the route is simply admin/AI, redirect to the default section (admin/AI/Settings)
    if (location.state?.module === baseModule) {
      navigate('/console', { state: { module: `${baseModule}/${aiSectionNameTranslationKeys['Settings']}` } });
    }
  }, [location.state.module, navigate]);

  return (
    <Box padding={1}>
      {location.state.module === `${baseModule}/${aiSectionNameTranslationKeys['Settings']}` && (
        <AISettings selectedAI={selectedAI} setSelectedAI={setSelectedAI} />
      )}
      {location.state.module === `${baseModule}/${aiSectionNameTranslationKeys['Tasks']}` && <AITasksTab />}
      {location.state.module === `${baseModule}/${aiSectionNameTranslationKeys['Intents']}` && <AIIntents />}
      {location.state.module === `${baseModule}/${aiSectionNameTranslationKeys['Entities']}` && <AIEntities />}
      {location.state.module === `${baseModule}/${aiSectionNameTranslationKeys['Functions']}` && <AIFunctions />}
    </Box>
  );
};

export default AITab;
