/**
 * @file This component provides a style wrapper for the
 * corresponding component from the filename.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { styled } from '@mui/system';
import Button from '@mui/material/Button';

// NOTICE: theme.palette variables should follow the below format
// "componentName" { "emmetShortcutForAttributeAndTargetClassName": "hex or rgba only" }
// Get emmetShortcut from this cheat sheet: https://docs.emmet.io/cheat-sheet/
// Try to avoid passing props and use dynamic classes instead so that the css will be static
export const Styles = styled(Button)(
  ({ theme }) => `
  white-space: nowrap;
  border: 1px solid;
  border-color: var(--orange700);
  border-radius: 10px;
  background-color: var(--gray000);
  color: var(--gray920);
  font-size: 12px;
  padding: 3px 6px;
  min-width: 69px;
  &.internal {
    border-color: var(--gray400);
    background-color: var(--peach020);
  }
  `
);
