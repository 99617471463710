import React, { useCallback, useRef, useEffect } from 'react';
import useInitialMount from '@common/hooks/useInitialMount';

const useAbortController = () => {
  const controllerRef = useRef<AbortController>(new AbortController());

  useEffect(() => {
    if(controllerRef.current) controllerRef.current.abort()
    
    controllerRef.current = new AbortController();

    return () => controllerRef.current.abort();
  }, []);

  const get = useCallback(() => controllerRef.current, []);

  const set = useCallback((newAbortController: AbortController) => {
    controllerRef.current = newAbortController;
    return controllerRef.current;
  }, []);

  const reset = useCallback(() => {
    if (controllerRef.current) controllerRef.current.abort();
    controllerRef.current = new AbortController();
    return controllerRef.current;
  }, []);

  const abort = useCallback(() => {
    controllerRef.current.abort();
    return controllerRef.current;
  }, []);

  return { get, set, reset, abort };
}

export default useAbortController;