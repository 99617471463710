import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import axiosCall from '@services/axios';
import StandardTablePagination from '@components/StandardTablePagination';
import TableHeaderBar from '@components/TableHeaderBar';
import { APIServiceType } from '@common/APIServiceType';
import EditEntityDialog from '@components/EditEntityDialog';
import useControllerCallback from '@common/hooks/useControllerCallback';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '8px',
  },
}));

const AIEntities = () => {
  const paginationSteps = [15, 50, 100];
  const { t } = useTranslation();
  const [editEntityState, setEditEntityState] = useState({
    edit: false,
    open: false,
    data: null,
  });
  const [entitiesData, setEntitiesData] = useState([]);
  const [selectedModelName, setSelectedModelName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [modelNames, setModelNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buildResultMessage, setBuildResultMessage] = useState('');
  const [buildingModel, setBuildingModel] = useState(false);

  const pageRef = useRef(0);
  const rowsPerPageRef = useRef(paginationSteps[0]);
  const showInactiveRef = useRef(false);

  const noResults = entitiesData.length === 0;

  /**
   * Fetches the list of model names from the cells engine to populate the dropdown at the top of the table
   */
  const loadEntityModelNames = async (controller?: AbortController) => {
    setLoading(true);
    const url = 'entities/model-names';
    const config = controller ? { signal: controller.signal } : {};
    try {
      const modelNamesResult = await axiosCall({ url, service: APIServiceType.cells }, config);
      if (!Array.isArray(modelNamesResult)) {
        throw new Error('Server responded with unexpected data:', modelNamesResult);
      } else if (modelNamesResult.length === 0) {
        throw new Error('Server responded with an empty list of entity model names');
      }
      setModelNames(modelNamesResult)
      setSelectedModelName(modelNamesResult[0]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetches the entities from the cells engine using the currently selected model
   */
  const loadEntitiesData = useCallback(async (controller?: AbortController) => {
    let method = 'get';
    let reqBody = {};
    let url = `entities/${selectedModelName}`;

    if (searchValue) {
      url = `entities/${selectedModelName}/search`;
      method = 'post';
      reqBody = { search: searchValue };
    }

    url += '?';

    let offset = rowsPerPageRef.current === -1 ? 0 : pageRef.current * rowsPerPageRef.current;
    // Attempt to load one more document to check if there are more available
    let limit = rowsPerPageRef.current + 1;
    url += `offset=${offset}`;
    if (rowsPerPageRef.current !== -1) {
      url += `&limit=${limit}`;
    }
    if (!showInactiveRef.current) {
      url += '&active=true';
    }

    const config = controller ? { signal: controller.signal } : {};
    setLoading(true);
    try {
      const result = await axiosCall({ url, method, data: reqBody, service: APIServiceType.cells }, config);
      if (!Array.isArray(result)) {
        throw new Error('Server responded with unexpected data:', result)
      }
      setEntitiesData(result);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [searchValue, selectedModelName]);

  const loadData = useCallback(async (controller?: AbortController) => {
    if (!selectedModelName) {
      // Load the names of the available models if we haven't already
      await loadEntityModelNames(controller);
    } else {
      await loadEntitiesData(controller);
    }
  }, [loadEntitiesData, selectedModelName]); 

  /**
   * This function calls the build endpoint for the entities model to rebuild the model based on the entities currently
   * in the database
   */
  const rebuildModel = async () => {
    const url = 'build/entities';
    const method = 'post';
    setBuildResultMessage('');
    setBuildingModel(true);
    try {
      const result = await axiosCall({ url, method, service: APIServiceType.cells });
      console.log('Build model result:', result);
      setBuildResultMessage(t('buildModelSuccess'));
    } catch (error) {
      console.error(error);
      setBuildResultMessage(t('tError'));
    } finally {
      setBuildingModel(false);
    }
  };

  const handleAddEntityOpen = () => {
    setEditEntityState({ edit: false, open: true, data: null });
    setErrorMessage('');
  };

  const handleEditEntityOpen = (entityData) => {
    setEditEntityState({ edit: true, open: true, data: entityData });
    setErrorMessage('');
  };

  const handleEditEntityClose = () => {
    setEditEntityState({ edit: false, open: false, data: null });
    setErrorMessage('');
  };

  const handleAddEntitySubmit = async (entityData) => {
    if (await addEntity(entityData)) {
      setEditEntityState({ edit: false, open: false, data: null });
    }
  };

  const handleEditEntitySubmit = async (entityData) => {
    if (await editEntity(entityData)) {
      setEditEntityState({ edit: false, open: false, data: null });
    }
  };

  /*************************************
   * CRUD Functions
   ************************************/
  const addEntity = async (entityData) => {
    setErrorMessage('');
    try {
      let result = await axiosCall({
        url: `entities/${selectedModelName}`,
        method: 'POST',
        data: entityData,
        service: APIServiceType.cells,
      });

      if (result) {
        await loadEntitiesData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message ?? t('tError'));
      return false;
    }
  };

  const editEntity = async (entityData) => {
    setErrorMessage('');
    try {
      const result = await axiosCall({
        url: `entities/${selectedModelName}`,
        method: 'PUT',
        data: entityData,
        service: APIServiceType.cells,
      });

      if (result) {
        await loadEntitiesData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message ?? t('tError'));
      return false;
    }
  };

  const handleDelete = async (entityName: string) => {
    try {
      const result = await axiosCall({
        url: `entities/${selectedModelName}/${entityName}`,
        method: 'DELETE',
        service: APIServiceType.cells,
      });
      if (result) {
        await loadEntitiesData();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleModelNameSelector = (e) => {
    setSelectedModelName(e.target.value);
  };

  const handleShowInactiveClick = () => {
    showInactiveRef.current = !showInactiveRef.current;
    pageRef.current = 0;
    loadEntitiesData();
  };

  const handlePageChange = (_, newPage) => {
    pageRef.current = newPage;
    loadEntitiesData();
  };

  const handleRowsPerPageChange = (e) => {
    rowsPerPageRef.current = e.target.value;
    pageRef.current = 0;
    loadEntitiesData();
  };

  const handleSearchChange = (newSearchValue: string) => {
    // We want to reset back to the first page when searching so we don't miss any documents (for example, we don't want
    // to begin our search on the second page as we would miss those on the first)
    pageRef.current = 0;
    // Now we can update the search value
    setSearchValue(newSearchValue);
  };

  const handleSnackbarClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (buildingModel || reason === 'clickaway') {
      return;
    }

    setBuildResultMessage('');
  };

  useControllerCallback(loadData);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} height="calc(100vh - 175px)">
      {/* Snackbar for messages regarding the progress/success/failure of rebuilding the model */}
      <Snackbar
        open={buildingModel || !!buildResultMessage} // Snacbkar open if the model is being built or has just built
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={buildingModel ? t('buildingModel') : buildResultMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <TextField
            sx={{ marginBottom: 1, minWidth: '260px' }}
            label={t('tModel')}
            select
            size="small"
           
            value={selectedModelName}
            SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500 } } } }}
            onChange={(e) => {
              if (loading) {
                return;
              }
              handleModelNameSelector(e);
            }}
          >
            {modelNames.map((modelName) => (
              <MenuItem key={modelName} value={modelName}>{modelName}</MenuItem>
            ))}
          </TextField>
          <Button
            variant="contained"
            color="info"
            sx={{ marginBottom: 1, minWidth: '260px' }}
            onClick={rebuildModel}
          >
            {t('rebuildModel')}
          </Button>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <TableHeaderBar
            onAdd={handleAddEntityOpen}
            onRefresh={loadEntitiesData}
            onExport={undefined}
            shouldHaveSearch={true}
            searchValue={searchValue}
            setSearch={handleSearchChange}
            addLabel={t('addEntity')}
            showInactiveClick={handleShowInactiveClick}
            isActive={!showInactiveRef.current}
          />
        </Box>
      </Box>
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : noResults ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <Paper sx={{ p: 1.5 }}>
              <Typography>{t('noData')}</Typography>
            </Paper>
          </Stack>
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell width="200px">{t('nameCol')}</StyledTableCell>
                <StyledTableCell width="200px">{t('scoreCol')}</StyledTableCell>
                <StyledTableCell width="200px">{t('endpointCol')}</StyledTableCell>
                <StyledTableCell width="300px">{t('tSynonyms')}</StyledTableCell>
                <StyledTableCell width="300px">{t('tWords')}</StyledTableCell>
                <StyledTableCell width="50px">{t('actionsCol')}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entitiesData.map((entityData, index) => {
                // If we are in paginated mode and there is more data available than the page limit (because we attempt
                // to fetch one more row than the limit to check if more data is available), don't display the last row
                if (
                  rowsPerPageRef.current !== -1 &&
                  entitiesData.length > rowsPerPageRef.current &&
                  index === rowsPerPageRef.current
                ) {
                  return null;
                }
                const {
                  _id,
                  active,
                  name,
                  metadata,
                  synonyms,
                  words
                } = entityData;
                let endpoint;
                let score;
                if (metadata) {
                  endpoint = metadata.endpoint;
                  score = metadata.score;
                }
                return (
                  <TableRow sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }} hover key={_id}>
                    <StyledTableCell>{name}</StyledTableCell>
                    <StyledTableCell>{score ?? ''}</StyledTableCell>
                    <StyledTableCell>{endpoint ?? ''}</StyledTableCell>
                    <StyledTableCell>{synonyms.join(', ')}</StyledTableCell>
                    <StyledTableCell>
                      {Array.isArray(words) ? words.join(', ') : Object.keys(words ?? {}).join(', ')}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tooltip title={t('editEntity')} placement="top-start" enterDelay={1000} enterNextDelay={1000}>
                        <BorderColorOutlinedIcon
                          onClick={() => handleEditEntityOpen(entityData)}
                          fontSize="small"
                          sx={{ cursor: 'pointer', color: 'var(--blue210)' }}
                        />
                      </Tooltip>
                      <Tooltip title={t('deleteEntity')} placement="top-start" enterDelay={1000} enterNextDelay={1000}>
                        <DeleteOutlinedIcon
                          onClick={() => handleDelete(name)}
                          fontSize="small"
                          sx={{ cursor: 'pointer', color: 'red' }}
                        />
                      </Tooltip>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StandardTablePagination
                  steps={paginationSteps}
                  rowsPerPage={rowsPerPageRef.current}
                  page={pageRef.current}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  isMore={rowsPerPageRef.current === -1 ? false : entitiesData.length > rowsPerPageRef.current}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      {editEntityState.open && (
        <EditEntityDialog
          edit={editEntityState.edit}
          open={editEntityState.open}
          entityData={editEntityState.data}
          onAdd={handleAddEntitySubmit}
          onEdit={handleEditEntitySubmit}
          onClose={handleEditEntityClose}
          error={errorMessage}
        />
      )}
    </Box>
  );
};

export default AIEntities;
