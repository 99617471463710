import { IErrand, IUserChatAction } from '@interfaces/Conversation';
import { getUserPlayedToday } from '@storage/userStorage';
import axiosCall from '@services/axios';
import { t } from 'i18next';

export const shouldPreventPlaySlotMachine = async (errand: IErrand, action: IUserChatAction, timeZone: string) => {
  const payload = {
    url: `slotmachine/check/${getUserPlayedToday()}?fields=chat,status,action&status=resolved&chat=${
      errand._id
    }&action=${action._id}`,
    method: 'POST',
    data: {
      timeZone,
    },
  };
  return await axiosCall(payload);
};

function getRandomItem(arr) {
  if (arr.length === 0) {
    return '';
  }
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export const getBeforePlayMessage = () => {
  const messagesBeforePlaying = [
    t('slotMachineThrill'),
    t('getReadyToSpin'),
    t('luckIsOnYourSide'),
    t('bigWin'),
    t("letsSpin"),
    t('spinAndWin'),
  ];
  return getRandomItem(messagesBeforePlaying);
};

export const getAfterWinMessage = () => {
  const messagesAfterWinning = [
    t('youDidIt'),
    t('congratsOnSpin'),
    t('wellDoneWin'),
    t('struckGold'),
    t("hitItBig"),
  ];
  return getRandomItem(messagesAfterWinning);
};

export const getAfterLoseMessage = () => {
  const messagesAfterLosing = [
    t('slotMachineNoPrize'),
    t('betterLuck'),
    t('closeOne'),
    t('spinAgain'),
    t('notThisTime'),
    t('missedIt'),
  ];
  return getRandomItem(messagesAfterLosing);
};
