import { Stack, styled } from '@mui/system';
import React, { PropsWithChildren } from 'react';
const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';

export const PrivateModeTitle: React.FC<PropsWithChildren<any>> = styled(Stack, {
})<any>(() => `
      display: grid;
      width: 100%;
      background-color: var(--peach500);
      border-radius: 20px 20px 0 0;
      padding: 0 10px;
      margin-top: 20px;
      grid-template-columns: 1fr auto 1fr;
      div:nth-of-type(1),
      div:nth-of-type(3) {
        padding: 0;
        display: grid;
        align-items: center;
        width: 100%;
        margin: auto;
        color: black;
        p {
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: inherit;
          text-align: center;
        }
      }
      div:nth-of-type(2) {
        padding: 0;
        display: grid;
        width: 120px;
        height: 67px;
        color: black;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    `
  );
  
  export const PrivateModeRow = styled(Stack)(
    ({ theme }) => `
      width: 100%;
      background-color: var(--peach500);
      border-radius: 0;
      padding: 0 10px;
      p {
        color: black !important;
        font-weight: 400 !important;
      }
    `
  );
  
  export const PrivateModeFooter = styled(Stack)(
    ({ theme }) => `
      display: grid;
      width: 100%;
      background-color: var(--orange050);
      background-image: url(${WoodGrain});
      border-radius: 0 0 20px 20px;
      padding: 0 0 5px 0;
      cursor: pointer;
      text-align: center;
      margin-bottom: 20px;
      font-weight: 600;
      div {
          background-color: var(--peach500);
          height: 5px;
      }
      div:nth-of-type(2) {
          background-color: var(--gray000);
          border: none;
          border-left: 10px solid var(--peach500);
          border-right: 10px solid var(--peach500);
          height: 3px;
      }
    `
  );
  
  export const PrivateFooter = styled(Stack)(
    ({ theme }) => `
    width: 100%;
    background-color: var(--peach500);
    border-radius: 0 0 20px 20px;
    padding: 0 10px 10px;
    p {
      color: black !important;
      font-weight: 400 !important;
    }
    `
  );