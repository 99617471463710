import React from "react";
import {
  Box,
  Card,
  CardContent,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import MessageBadgeMessages from "./MessageBadgeMessages";
import MessageBadgeSms from "./MessageBadgeSms";
import axiosCall from "../Services/axios";
import { useTranslation } from "react-i18next";
import MessageBadgeSkeleton from "./MessageBadgeSkeleton";
import { ChatType } from "@common/ChatType";
import { useUserContext } from "@contexts/user";

/*
 *  This component is the list of messages seen on the right most column of the
 * console dashboard. See the Figma for more: https://www.figma.com/file/5HrQkz2QKGTb97ZvTsqfOC/Dashbord?node-id=613%3A113
 *
 *  This component has the following properties:
 *    - messages - an array of message objects to be displayed. The schema for
 *      this can be seen in IMessage
 */

const ScrollBox = styled(Box)(() => ({
  overflowY: "scroll",
  maxHeight: "calc(100vh - 224px)",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px var(--shadow000)",
    webkitBoxShadow: "inset 0 0 6px var(--shadow000)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "var(--shadow110)",
    outline: "1px solid slategrey",
    borderRadius: "0.2em",
  },
}));

const CustomCard = styled(Card)(() => ({
  backgroundColor: "var(--customCardColor)",
  borderRadius: "0.8em",
  height: "calc(100vh - 130px)",
}));

const CustomTabs = styled(Tabs)(() => ({
  "&& .MuiTabs-scroller > .MuiTabs-indicator": {
    backgroundColor: "var(--peach900) !important",
  },
  "&& .Mui-selected, && .Mui-selected:hover": {
    "&, & .MuiTab-root": {
      fontWeight: "bold",
      color: "black",
    },
  },
}));

const Placeholder = (props) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "calc(100vh - 225px)",
        width: "100%",
      }}
    >
      <Paper sx={{ p: 1.5 }}>
        <Typography> {props.message} </Typography>
      </Paper>
    </Stack>
  );
};

const MessageList = (props) => {
  const { notifications } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { _id } = useUserContext();

  const stack = {
    message: {},
    sms: {},
  };

  for (const elm of notifications) {
    const chatId = elm?.chat?._id;

    let targetStack = undefined;

    if (elm.messageId) {
      targetStack = stack.message;
    } else if (elm.smsId) {
      targetStack = stack.sms;
    } else {
      // console.error(`Unidentified notification |${JSON.stringify(elm, null, 2)}|`);
    }

    if (targetStack !== undefined) {
      if (targetStack[chatId] === undefined) {
        targetStack[chatId] = [elm];
      } else {
        targetStack[chatId].push(elm);
      }
    }
  }

  const messages = Object.values(stack.message).map((value) => {
    // first element created last because on core side it's sorted by notification createdAt: -1
    const el = value[value.length - 1];
    const chat = el?.chat;
    const messageId = el?.messageId;
    const message = messageId?.message;
    const sender = messageId?.sender;
    const firstname = sender?.firstname;
    const lastname = sender?.lastname;

    return {
      id: el?._id,
      name: `${firstname || "Anonymous"} ${lastname || "User"}`,
      img: "",
      time: el?.createdAt,
      status:
        props.onlineUsers?.findIndex((x) => sender?._id === x?._id) !== -1
          ? "online"
          : "offline",
      preview: message || "...",
      previous: value,
      chatId: chat?._id,
      chatType: chat?.type,
      displayName: chat?.displayName,
    };
  });

  const smses = Object.values(stack.sms).map((value) => {
    // first element created last because on core side it's sorted by notification createdAt: -1
    const lastSms = value[value.length - 1];
    const chat = lastSms?.chat;
    const smsId = lastSms?.smsId;
    const from = smsId?.from;

    return {
      id: lastSms?._id,
      name: from,
      img: "",
      time: lastSms?.createdAt,
      status: "offline",
      previous: value,
      chatId: chat?._id,
      chatType: chat?.type,
      displayName: chat?.displayName,
    };
  });

  return (
    <CustomCard sx={{ "--customCardColor": theme.palette.blue["010"] }}>
      <CardContent style={{ minHeight: "100%" }}>
        <Stack spacing={2} sx={{ alignItems: "center" }}>
          <CustomTabs
            value={location.state?.tab === "sms" ? "sms" : "msg"}
            onChange={(event, newVal) => {
              navigate("/console", {
                state: {
                  module: "dashboard",
                  tab: newVal,
                },
              });
            }}
          >
            <Tab label={t("dashboardMessagesListSMSButton")} value="sms" />
            <Tab label={t("dashboardMessagesListMessageButton")} value="msg" />
          </CustomTabs>

          <ScrollBox sx={{ width: "100%", marginTop: "5px!important" }}>
            {location.state?.tab === "sms" ? (
              smses?.length > 0 ? (
                smses.map((el, index) => {
                  return (
                    <MessageBadgeSms
                      key={index}
                      name={el.name.toLowerCase()}
                      img={el.img}
                      time={el.time}
                      sender={el.name}
                      previous={el.previous}
                      num={el.previous.length}
                      onMessageSend={async (message) => {
                        // dont allow blank messages
                        if (message === "") {
                          return;
                        }

                        /* Clear the notifications that we grouped with this latest one */
                        el.previous.map(async (m) => {
                          if (m?._id !== undefined)
                            await axiosCall({
                              url: `operator/${_id}/notification/${m?._id}`,
                              method: "PUT",
                              data: {
                                status: "read",
                              },
                            });
                        });

                        /* Send the message */
                        await axiosCall({
                          url: "sms/send",
                          method: "post",
                          data: {
                            message: message,
                            senderId: _id,
                            to: el.name,
                            chatId: el.chatId,
                          },
                        });

                        /* Clear UI side notifications */
                        // Setting the global one should render this component anyway...
                        props.setNotifications((prev) =>
                          prev.filter(
                            (e) => e?.chat?._id !== el.chatId, // remove if SMS is in the same chat as this one
                          ),
                        );
                      }}
                    />
                  );
                })
              ) : (
                <Placeholder
                  message={t("dashboardMessagesListSMSPlaceholder")}
                />
              )
            ) : // If props.notifications is null, display the badge skeleton
            props.notifications === null ? (
              <MessageBadgeSkeleton />
            ) : // Otherwise, if there are messages, display them
            messages?.length > 0 ? (
              messages?.map((el, index) => {
                return (
                  <MessageBadgeMessages
                    key={index}
                    name={el.displayName ? `${el.name} (${el.displayName})` : el.name}
                    img={el.img}
                    time={el.time}
                    preview={el.preview}
                    num={el.previous.length}
                    status={el.status}
                    onClick={async (e) => {
                      e.stopPropagation();
                      let tab = "mine";
                      if (
                        el.chatType === ChatType.Team ||
                        el.chatType === ChatType.Group
                      ) {
                        tab = el.chatType;
                      } else if (el.status === "offline") {
                        tab = "history";
                      }

                      navigate("/console", {
                        state: {
                          module: "conversations",
                          tab: tab,
                          chat: el.chatId,
                        },
                      });
                    }}
                  />
                );
              })
            ) : (
              //If there are no messages, display "No New Messages"
              <Placeholder
                message={t("dashboardMessagesListMessagePlaceholder")}
              />
            )}
          </ScrollBox>
        </Stack>
      </CardContent>
    </CustomCard>
  );
};

export default MessageList;
