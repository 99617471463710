import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';

import Avatar from './Avatar';
import DialogHeader from './DialogHeader';
import ScrollBox from './ScrollBox';
import axiosCall from '../Services/axios';
import getImageSource from '../Common/getImageSource';
import { useTranslation } from 'react-i18next';

/*
 *  This component is the popup dialog that the user interacts with when creating
 * a group chat in the operator console on the conversations page under the group
 * subheader.
 *
 *  This component has the following properties:
 *    - open: The state of the modal, whether or not to be open
 *    - onClose: the function to call when closing, since state managment is out of
 *      the scope of this component.
 */

const UserStripe = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid container sx={{ display: "flex", alignItems: "center", padding: "8px 0px 8px 16px" }}>
        <Grid item xs={2}>
          <Avatar sx={{ width: "40px" }} status={props.data.status} alt={props.data.name} img={getImageSource(props.data.avatar)} />
        </Grid>
        <Grid item xs={6}>
          <Typography> {props.data.name} </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => { props.addRemoveOperator(props) }}>
            {props.add ? t("createGroupAdd") : t("createGroupRemove")}
          </Button>
        </Grid>
      </Grid>
      <Divider sx={{ marginLeft: "15px", marginRight: "15px" }} />
    </>
  );
};

const CreateGroupModal = (props) => {
  const [operators, setOperators] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [search, setSearch] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const ops = await axiosCall({
        url: `operator/?order=asc&orderBy=lastname&active=1`,
      });

      const data = ops
        .filter((op) => op?._id !== props.data.currentOp?._id)
        .map((op) => {
          return {
            name: `${op.firstname} ${op.lastname}`,
            status: op.status,
            avatar: op.avatar,
            _id: op?._id,
            visible: true,
          };
        });

      setOperators(data);
      setDataToDisplay(data);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addRemoveOperator = (p) => {
    console.log("AddRemove");
    let arr = selectedUsers.slice();

    if (p.add && !arr.includes(p.data)) {
      arr.push(p.data);
      let dtd = dataToDisplay.slice();
      let index = dtd.indexOf(p.data);
      if (index > -1) dtd[index].visible = false;
      setDataToDisplay(dtd)
    }
    else {
      let index = arr.indexOf(p.data);
      if (index > -1) arr.splice(index, 1);

      let dtd = dataToDisplay.slice();
      let dtdIndex = dtd.indexOf(p.data);
      if (dtdIndex > -1) dtd[dtdIndex].visible = true;
      setDataToDisplay(dtd)
    }

    setSelectedUsers(arr);
  }

  const handleSetSearch = (value) => {
    setSearch(value);
    if (value) {
      setDataToDisplay(operators.filter((el) => {
        return (
          el?.name?.toLowerCase()?.includes(value?.toLowerCase())
        );
      }));
    }
    else {
      setDataToDisplay(operators);
    }
  }

  const handleSubmit = () => {

    const users = selectedUsers.slice();
    users.push(props.data.currentOp);
    const data = {
      groupName: groupName,
      operators: users
    }
    props.onSubmit({ ...data });
  }

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('createGroupTitle')} onClose={() => props.onClose()} />
      <DialogContent>
        <Stack height="540px" width="900px" marginTop="10px">
          {/* Main Content Area */}
          <Grid container spacing={3}>

            {/* Left panel */}
            <Grid item xs={5}>
              <Box sx={{ border: "1px solid var(--gray210)" }}>
                <TextField
                  sx={{ margin: "8px 0 8px 16px", paddingRight: "32px" }}
                  fullWidth
                  label={t('createGroupSearch')}
                  variant="outlined"
                  size="small"
                  value={search}
                  onChange={(e) => { handleSetSearch(e.target.value) }}
                />
                <ScrollBox sx={{ borderTop: "1px solid var(--gray210)" }}>
                  {dataToDisplay?.map((op) => {
                    return (
                      <Fragment key={op?._id}>
                        {op.visible && <UserStripe data={op} add addRemoveOperator={addRemoveOperator} />}
                      </Fragment>
                    );
                  })}
                </ScrollBox>
              </Box>
            </Grid>

            {/* Center Panel */}
            <Grid item xs={7}>
              <TextField
                label={t('createGroupNameHint')}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setGroupName(e.target.value)}
                value={groupName}
              />
              <Divider sx={{ marginTop: "8px" }} >
                <Typography fontWeight={600}>
                  {t('createGroupMemebers')}
                </Typography>
              </Divider>
              <ScrollBox>
                {
                  selectedUsers.length > 0 ?
                    selectedUsers.map((op) => {
                      return (
                        <Fragment key={op?._id}>
                          <UserStripe
                            data={op} addRemoveOperator={addRemoveOperator} />
                        </Fragment>
                      )
                    })
                    :
                    <Typography fontSize="12px" padding="20px 30px 0px 20px"> {t('createGroupNoMembers')} </Typography>
                }
              </ScrollBox>
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()} variant="outline"> {t('createGroupCancel')} </Button>
        <Button disabled={groupName === "" || selectedUsers.length === 0} onClick={() => handleSubmit()} variant="contained"> {t('createGroupCreate')} </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGroupModal;
