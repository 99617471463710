// @ts-check
import {
  Snackbar,
} from '@mui/material';

import React, { forwardRef, useImperativeHandle, useState } from 'react';

const GenericSnackbar = forwardRef((/** @type {import('../../jsdocs').GenericSnackbarProps} */ props, ref) => {

  const [snackbarMessage, setSnackbarMessage] = useState(/** @type {string | null} */(null));

  useImperativeHandle(ref, () => {
    return {
      /**
        * @param {string} msg
        */
      displayMessage(msg) {
        setSnackbarMessage(msg);
      },
    };
  }, []);

  /**
    * @param {React.SyntheticEvent | Event} event
    * @param {string | undefined} reason
    */
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage(null)
  };

  return (
    <Snackbar
      autoHideDuration={props.duration ? props.duration : 3000}
      open={snackbarMessage ? true : false}
      onClose={handleClose}
      message={snackbarMessage}
      anchorOrigin={{
        vertical: (props.locationVertical ? props.locationVertical : 'bottom'),
        horizontal: (props.locationHorizontal ? props.locationHorizontal : 'left'),
      }}
    />
  );
});

export default GenericSnackbar;