import { styled } from '@mui/material/styles';

export const SplashStyles = styled('div', {
})(() => `

  .splashContainer{
      position: relative;
      height: 100vh;
      width: 100%;
      overflow: hidden;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
  }

  .contentContainer{
      padding: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      width: 100%;
      gap: 4;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      background-color: var(--shadow500);
  }

  .globeWrapper{
    position: absolute;
    z-index: 1;
    top: 249px;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  .whiteBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 525px;
    width: 661px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0px 0px 50px 50px;
    position: relative;
    padding: 15px 7px 20px 7px;
    margin-bottom: 67px;
    margin-top: 45px;
    height: auto;
  }

  .white2{
    position: absolute;
  }

  .white3{
    position: absolute;
    display: none;
  }

  .themeLogo{
    height: clamp(40px, 5vh, 80px);
    padding-bottom: 10px;
    margin-bottom: -52px;
    position: relative;
    bottom: 73px;
  }

  .logoWrapper{
    margin-top: -40px;
  }

  .curves{
    width: 739px;
    display: flex;
    align-items: center;
    justify-items: center;
    top: 146px;
    position: relative;
  }

  .rightCorner{
    background-color: var(--gray000);
    height: 100%;
    width: 100%;
    position: relative;
    right: 39px;
    border-radius: 0px 50px 0px 0px;
  }

  .leftCorner{
    background-color: var(--gray000);
    height: 100%;
    width: 100%;
    position: relative;
    left: 39px;
    border-radius: 50px 0px 0px 0px;
  }

  @media (min-width: 620px){
    .white2{
      top: 3px;
      clip: rect(0, auto, 150px, 0);
    }
    .whiteTheme{
      display: flex;
      top: -29px;
    }
  }

  @media (max-width: 620px){
    .whiteBox{
      width: 500px;
      top: 62px;
      margin-top: 15px;
    }
    .white2{
      top: -23px;
    }
    .whiteTheme{
      top: -49px;
    }
    .themeLogo{
      bottom: 109px;
    }
    .engageButton {
      font-size: 1.2rem;
    }
    .logoWrapper{
      margin-top: -77px;
    }
    .globeWrapper{
      top: 283px;
      transform: scale(0.88);
      left: 7px;
    }
  }

  @media (max-width: 500px){
    .whiteBox{
      width: 400px;
      top: 100px;
      margin-top: 24px;
    }
    .white2{
      top: -65px;
    }
    .whiteTheme{
      top: -91px
    }
    .themeLogo{
      bottom: 148px
    }
    .engageButton {
      font-size: 1.2rem;
    }
    .logoWrapper{
      margin-top: -125px;
    }
    .logoWrapperTheme{
      margin-top: -113px;
    }
    .globeWrapper{
      top: 328px;
      transform: scale(0.79);
      left: 12px;
    }
  }

  @media (max-width: 400px){
    .whiteBox{
      width: 350px;
      top: 117px;
      margin-bottom: 68px;
      margin-top: 51px;
    }
    .white2{
      top: -72px;
    }
    .white3{
      display: flex;
      top: -121px;
    }
    .whiteTheme{
      top: -73px
    }
    .themeLogo{
      bottom: 175px
    }
    .engageButton {
      font-size: 1.2rem;
    }
    .logoWrapper{
      margin-top: -154px;
    }
    .logoWrapperTheme{
      margin-top: -136px;
    }
    .globeWrapper{
      top: 373px;
      transform: scale(0.74);
      left: 13px;
    }
  }

  `);