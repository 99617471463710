import { Box, styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';

// json format required for component
export const FlagStyles = {
  border: '2px solid',
  borderRadius: '5px',
  width: '25px',
  height: 'auto',
  margin: '2.5px auto 3.5px',
  cursor: 'pointer',
  color: 'var(--peach500)',
  '&:hover': {
    color: 'var(--orange600)',
  },
  '&:focus': {
    color: 'var(--orange600)',
  },
}

export const LanguagePickerButton = styled(Box)(
  ({theme}) => `
  position: relative;
  height: 33px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > div {
    position: relative;
    height: 25px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 25px;
    width: auto;
    color: var(--peach500);
  } 
  :hover {
    .LanguagePickerButtonArrow {
      display: ${useMediaQuery(theme.breakpoints.up('md')) ? 'flex !important' : 'none'};
    }
  }
  .LanguagePickerButtonArrow {
    font-size: 0.5rem;
    line-height: .4rem;
    color: var(--orange600);
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
    background-color: transparent;
    padding: 0;
    width: 100%;
  }
  `
);