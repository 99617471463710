import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReplyAllOutlined } from '@mui/icons-material';

import { IErrand, IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import { getDropdownDescription } from '@common/StringUtils';
import { MorphType } from '@common/MorphType';

import Styles from '@styles/MorphMessageOptions.module.css';

type TMorphMessageOptionReplyProps = {
  errand: IErrand;
  handleClose: (morphType?: MorphType) => void;
  message: IMessage;
};

const MorphMessageOptionReply = ({
  errand, handleClose, message
}: TMorphMessageOptionReplyProps) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const rootContext = useRootContext();

  const handleClickReply = useCallback(async () => {
    // set up editable message value
    let msg = message.message || '';
    // Remove replies
    if (msg.indexOf('</blockquote>') !== -1) {
      // Remove the old message.
      msg = msg.split(`</blockquote>`)[1];
    }
    // Remove previous edits
    if (msg.indexOf('<i class="messageArrow"/>') !== -1) {
      // Remove the old message.
      msg = msg.split(`<i class="messageArrow"/>`)[1];
    }
    // Remove user message from operator corrections
    if (msg.indexOf(t('acceptedData')) !== -1) {
      // Remove the user message
      msg = msg.split(t('acceptedData'))[1];
      // Remove the ) at the end of the message
      msg = msg.substring(0, msg.length - 1).trim();
    }
    // Remove html
    if (msg.indexOf('href=') !== -1) {
      msg = msg.replace(/<\/?a[^>]*>/g, '');
    }

    // Parse the field data to show the description by extracting it from the message. 
    msg = getDropdownDescription(msg);

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(msg, 'text/html');

    msg = htmlDoc.body.textContent;

    errandContext.replyToRef.current = { chat: errand._id, originalMessage: message._id, originalText: msg };
    rootContext.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let index = prev.findIndex((e) => e._id === errand._id);
      if (index === -1) return prev;
      prev[index].placeholder = 'Reply here...';
      return [...prev];
    });
    handleClose(MorphType.Reply);
  }, [errand._id, message, handleClose]);

  return (
    <button className={Styles.btn} onClick={handleClickReply}>
      <div className={Styles.btnBorder}>
        <ReplyAllOutlined />
        <p className={Styles.label}>{t('replyAll')}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionReply;
