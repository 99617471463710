/**
 * @file BubbleWithExpandableLower.tsx
 * @description This file defines the BubbleWithExpandableLower component
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */

import React, { useState } from 'react';
import Styles from './BubbleWithExpandableLower.module.css';
import { DoubleChevronPointingUp } from '@assets/Icons';

const BubbleWithExpandableLower = ({ upperContents, lowerContents }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickExpander = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={isExpanded ? Styles.containerExpanded : Styles.containerMinimized}>
      <div className={Styles.upperBubble}>
        {upperContents}
        <div className={Styles.expanderButton} onClick={handleClickExpander}>
          <div className={Styles.expanderButtonInner}>
            <DoubleChevronPointingUp />
          </div>
          <div className={Styles.buttonBorderHider}></div>
        </div>
      </div>
      {isExpanded && <div className={Styles.lowerBubble}>{lowerContents}</div>}
    </div>
  );
};

export default BubbleWithExpandableLower;
