import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { AvatarGroup, Box, Button, Card, CardContent, Chip, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip, Typography, tableCellClasses, } from '@mui/material';
import { DateRange } from 'react-date-range';
import { MorganTheme, styled, useTheme } from '@mui/material/styles';
import { enUS, es } from 'date-fns/locale';
import React, { useEffect, useState, memo, useContext, createContext} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { EditIcon } from '@assets/Icons/index';
import Avatar from '@components/Avatar';
import EditOperatorDialogMonitoring from '@components/EditOperatorDialogMonitoring';
import MonitoringChart from '@components/MonitoringChart';
import MoodMeter from '@components/MoodMeter';
import StandardTablePagination from '@components/StandardTablePagination';
import axiosCall from '@services/axios';
import downloadMonitoringReport from '@components/DownloadMonitoringReport';
import getImageSource from '@common/getImageSource';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    monitorDownloadButton: true;
  }
}
/*
 *  This component is the monitoring portion of the console, visible in the main content area.
 * This component was designed to fit within the Consoles frame, having its
 * visibility controllled by Consoles navigation system. For more about the visual
 * design see the Figma: https://www.figma.com/file/5HrQkz2QKGTb97ZvTsqfOC/Dashbord?node-id=613%3A71
 *
 */
const Block = styled(Box)(() => ({
  borderRadius: '0.4em',
  textAlign: 'center',
  display: 'inline-block',
}));

const OutlinedIconButton = styled(IconButton)(() => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: '5px 10px',
  borderRadius: '25px',
  borderColor: 'var(--gray040)',
}));

const CustomAvatarGroup = styled(AvatarGroup)(() => ({
  justifyContent: 'flex-end',
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

/* place object in context to use in GuestInfo page */
let selectedOperator: { Provider?: any; }, startDate :  Date, endDate :  Date;

export interface monitorContextType {
  operator: any;
  startDate: Date;
  endDate: Date;
}
const monitorContext = createContext<monitorContextType | null>({operator : undefined, startDate: new Date(),  endDate :  new Date()});

const Monitoring : React.FC<{}> = () => {
  const theme : MorganTheme = useTheme();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  // const [editDialogOpen, setEditDialogOpen] = useState(null);
  const navigate = useNavigate();
  const [accessLevels, setAccessLevels] = useState([]);
  const [editOperatorState, setEditOperatorState] = useState({ open: false, data: null });
  const monContext = useContext(monitorContext);

  monContext?.startDate.setDate(monContext?.endDate.getDate() - 2);
  const [dates, setDates] = useState([
    {
      startDate: monContext?.startDate,
      endDate: monContext?.endDate,
      key: 'selection'
    }
  ]);
  const [operators, setOperators] = useState([]);
  const [operatorsCount, setOperatorsCount] = useState(0);
  const [guests, setGuests] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartCategories, setChartCategories] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);

  const loadOperatorData = async () => {
    // Load one more row than required to test if more data exists
    const offset = page * rowsPerPage;
    const limit = rowsPerPage > 0 ? rowsPerPage : 0;
    selectedOperator = {};
    const payload = {
      url: `reports/monitoring?startDate=${dates[0].startDate.getTime()}&endDate=${dates[0].endDate.getTime()}&offset=${offset}&limit=${limit}`,
    }

    let res = await axiosCall(payload);
    if (res && res.monitoringData) {
      setOperatorsCount(res.operatorsCount);
      setOperators(res.monitoringData);
      setIsMoreDataAvailable(res.operatorsCount > page + 1 * rowsPerPage);
      // console.log("> Monitoring.loadOperatorData page: ", page, "rowsPerPage: ", rowsPerPage, "offset: ",offset,"limit: ",limit, "res.operatorsCount: ",res.operatorsCount,"operatorsCount: ",operatorsCount,"IsMoreDataAvailable: ", isMoreDataAvailable,);
    } else {
      setPage(page - 1);
      console.log(`end of data`);
    }
  }

  const areSameDay = (d1: Date, d2: Date) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

  // Typescript version of the getDaysArray function:
  // const getDaysArrayTs = function (start, end) {
  //   let arr: Date[]
  //   for (let dt:Date = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
  //     arr.push(new Date(dt));
  //   }
  //   return arr;
  // };

  const getDaysArray = function (start: string | number | Date, end: string | number | Date) {
    let arr = []
    for (let dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const loadGuestData = async () => {
    let res, data;
    if (areSameDay(dates[0].startDate, dates[0].endDate)) {
      const payload = {
        url: `reports/guestsStatistics?startDate=${dates[0].startDate.getTime()}&endDate=${new Date(dates[0].startDate.setHours(23, 59, 59)).getTime()}`
      }
      res = await axiosCall(payload);
      setGuests(res);

      // If they are the same day then we group by periods within the day
      let day = dates[0].startDate;
      let earlyMorning = res.filter(g => new Date(g.updatedAt) >= new Date(day.setHours(0, 0, 0)) && new Date(g.updatedAt) < new Date(day.setHours(5, 0, 0))).length;
      let morning = res.filter(g => new Date(g.updatedAt) >= new Date(day.setHours(5, 0, 0)) && new Date(g.updatedAt) < new Date(day.setHours(12, 0, 0))).length;
      let afternoon = res.filter(g => new Date(g.updatedAt) >= new Date(day.setHours(12, 0, 0)) && new Date(g.updatedAt) < new Date(day.setHours(18, 0, 0))).length;
      let evening = res.filter(g => new Date(g.updatedAt) >= new Date(day.setHours(18, 0, 0)) && new Date(g.updatedAt) <= new Date(day.setHours(23, 59, 59))).length;
      data = [
        {
          "name": "Users",
          "data": [
            earlyMorning,
            morning,
            afternoon,
            evening
          ]
        }
      ];

      setChartData(data);
      setChartCategories([
        '12:00 AM - 05:00 AM',
        '05:00 AM - 12:00 PM',
        '12:00 PM - 06:00 PM',
        '06:00 PM - 12:00 AM',
      ]);
    } else {
      // If they are not then we group by day.
      let startEarly = new Date(dates[0].startDate.setHours(0, 0, 0));
      let endLate = new Date(dates[0].endDate.setHours(23, 59, 59));
      const payload = {
        url: `reports/guestsStatistics?startDate=${startEarly.getTime()}&endDate=${endLate.getTime()}`
      }
      res = await axiosCall(payload);
      setGuests(res);

      let days = getDaysArray(startEarly, endLate);
      let array = [];
      let categories = [];
      days.forEach(day => {
        array.push(res.filter(g => new Date(g.updatedAt) >= new Date(day.setHours(0, 0, 0)) && new Date(g.updatedAt) <= day.setHours(23, 59, 59)).length);
        //var d = new Date().setDate(new Date(day).getDate() - 1);
        //new Date(d)
        categories.push(day.toLocaleString().split(',')[0]);
      });

      // console.log('categories', categories);

      data = [
        {
          "name": "Users",
          "data": array
        }
      ];

      setChartData(data);
      setChartCategories(categories);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownload = async () => {
    const payload = {
      url: `reports/monitoring?startDate=${dates[0].startDate.getTime()}&endDate=${dates[0].endDate.getTime()}`,
    }
    let res = await axiosCall(payload);
    let date = '';
    if (areSameDay(dates[0].startDate, dates[0].endDate)) {
      date = dayjs(new Date(dates[0].startDate)).format('DD/MM/YYYY');
    } else {
      date = `${dayjs(new Date(dates[0].startDate)).format('DD/MM/YYYY')} - ${dayjs(new Date(dates[0].endDate)).format('DD/MM/YYYY')}`
    }
    downloadMonitoringReport(res, `${t('monitoringFileName')} ${date}`);
  }

  const loadAccessLevels = async (controller) => {
    try {
      // Get all access levels, filter out level 0 since it is for AI
      let result = await axiosCall({ url: `operator/accessLevel` });
      result = result.filter(level => level !== 'Level 0');
      setAccessLevels(result);
    } catch (error) {
      console.log(error);
    }
  };

/*************************************
 * Edit Operator Dialog Functions
 *************************************/
  const editOperator = async (data) => {
    try {
      const result = await axiosCall({
        url: `operator/${data.id}`,
        method: 'PUT',
        data: {
          accessLevel: data?.accessLevel,
          team: data.team,
          subTeam: data.subTeam,
        },
      });
      if (result) {
        await loadData();
        return true;
      }
    } catch (error) {
      // setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  const handleEditOperatorOpen = (operator) => {
    setEditOperatorState({ open: true, data: operator });
  };

  const handleEditOperatorClose = () => {
    setEditOperatorState({ open: false, data: null });
  };

  const handleEditOperatorSubmit = async (data) => {
    if (editOperator(data)) {
      setEditOperatorState({ open: false, data: null });
    }
  };

  // load initial data on mount
  useEffect(() => {
    const controller = new AbortController();
    loadAccessLevels(controller);

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    if (location.state?.module === 'monitoring') {
      loadOperatorData();
      loadGuestData();
    }
  }

  // reload data when getActive, page or rowsPerPage changes
  useEffect(() => {
    // if (location.state?.module === 'monitoring') {
    //   loadOperatorData();
    //   loadGuestData();
    // }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, dates, location.state?.module]);

  return (
    <Grid container spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }} >

      {/* Top left most element, hero bar */}
      <Grid item xs={12} sm={12} md={7} lg={9} id="item1">
        <Card>
          <CardContent>
            <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row', xl: 'row' }} justifyContent="space-around" >
              {/* number block, label, and date range */}
              <Stack justifyContent="center">
                <Block sx={{ backgroundColor: theme.palette.blue['600'] }}>
                  <Typography sx={{ color: "var(--gray000)", fontSize: 18, padding: 2 }}> {guests?.length || 0} </Typography>
                </Block>
                <Typography align="center" fontWeight="bold"> {t("monitoringGuestsLabel")} </Typography>
                <Typography align="center"> {new Date(dates[0].startDate).toLocaleString(i18n.language).split(",")[0]} - {new Date(dates[0].endDate).toLocaleString(i18n.language).split(",")[0]} </Typography>
              </Stack>
              <Stack>
                {chartData[0]?.data?.length > 0 && chartCategories && (<MonitoringChart series={chartData} categories={chartCategories} />)}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      {/* Top right most block, mood meter */}
      <Grid item xs={12} sm={12} md={5} lg={3} id="item2">
        <Card>
          <CardContent>
            {/* TODO map the value */}
            <MoodMeter value={50} />
          </CardContent>
        </Card>
      </Grid>

      {/* Bottom left most block, Table area */}
      <Grid item xs={12} sm={12} md={7} lg={9} >
        <CardContent>
          <TableContainer sx={{ maxHeight: 450 }}>
            <Table size="small" stickyHeader >
              {/*Column names*/}
              <TableHead>
                <TableRow>
                  <StyledTableCell> {t("operatorName")} </StyledTableCell>
                  <StyledTableCell align="left"> {t("monitoringTableConversations")} </StyledTableCell>
                  <StyledTableCell> {t("monitoringTableGA")} </StyledTableCell>
                  <StyledTableCell> {t("monitoringTableAction")} </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {
                  operators.map((op, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Block sx={{ backgroundColor: theme.palette.blue['400'], color: "var(--gray000)" }}>
                            <Typography fontSize={12} p={1}> {op.operator.accessLevel || t('monitoringLevelPlaceholder')} </Typography>
                          </Block>
                          <IconButton
                            onClick={() => {
                              selectedOperator.Provider = op;
                              navigate('/console', {
                                state: {
                                  module: 'monitoring-guests',
                                },
                              });
                            }} >
                            <Typography fontWeight="bold" sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}> {`${op.operator.firstname} ${op.operator.lastname}`} </Typography>
                          </IconButton>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Chip label={op.numberOfChats} variant="outlined" sx={{ color: theme.palette.blue['600'] }} />
                      </TableCell>
                      <TableCell align="left">
                        <CustomAvatarGroup max={4} >
                          {op.guestList.map((guest, index) =>
                            <Tooltip key={index} title={`${guest.firstname} ${guest.lastname}`}>
                              <div>
                                <Avatar alt={`${guest.firstname} ${guest.lastname}`} img={getImageSource(guest.avatar)} />
                              </div>
                            </Tooltip>
                          )}
                        </CustomAvatarGroup>
                      </TableCell>
                      <TableCell>
                        <OutlinedIconButton
                          onClick={() => {
                            // setEditOperatorState({open: true, data: op});
                            // setEditDialogOpen(index);
                            handleEditOperatorOpen(op.operator);
                          }}>
                          <EditIcon />
                        </OutlinedIconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>

              <TableFooter>
                <TableRow>
                  <StandardTablePagination
                    dataSize={operatorsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    isMore={isMoreDataAvailable}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </CardContent>
      </Grid>

      {/* Bottom right most block, date picker! */}
      <Grid item xs={12} sm={12} md={5} lg={3} >
        <Stack direction="column" >
          {/* number block, label, and date range */}
          <Card>
            <CardContent>
              <DateRange
                editableDateInputs={true}
                maxDate={new Date()}
                ranges={dates}
                onChange={(item: { selection: { startDate: Date; endDate: Date; key: string; }; }) => setDates([item.selection])}
                locale={i18n.resolvedLanguage === 'en' ? enUS :
                  i18n.resolvedLanguage === 'es' ? es :
                    enUS} />
            </CardContent>
          </Card>
          <Button
            sx={{ color: "var(--gray000)" }}
            onClick={handleDownload} > {t("monitoringDownload")}
          </Button>
        </Stack>
      </Grid>

      {/*Modals*/}
      {editOperatorState.open && (
        <EditOperatorDialogMonitoring
          open={editOperatorState.open}
          operator={editOperatorState.data}
          accessLevels={accessLevels}
          // error={''}
          onClose={handleEditOperatorClose}
          onSubmit={handleEditOperatorSubmit}
        />
      )}
    </Grid>
  );
}

export default memo(Monitoring);
export { selectedOperator, startDate, endDate };
