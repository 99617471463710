import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Styles from '../Styles/MorphCalendarMonth.module.css';
import { ReactCalendarStyles } from '../Styles/ReactCalendarStyles';
import { useErrandContext } from '@contexts/ErrandContext';
import { useFooterContext } from '@contexts/FooterContext';
import { useMorphContext } from '@contexts/MorphContext';
import Calendar from 'react-calendar';
import { MorphType } from '@common/MorphType';

const MorphCalendarMonth = () => {
  const morphContext = useMorphContext();
  const footerContext = useFooterContext();
  const errandContext = useErrandContext();
  const { t, i18n } = useTranslation();
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [date, setDate] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [calendarHeight, setCalendarHeight] = useState(0);
  const calendarRef = useRef(null);

  // Determine if currently selected language is one where the date has more
  // than 2 characters (like korean) b/c this means the height needs to be adjusted for it
  const localLanguageDate = new Date();
  const getLocalDate = localLanguageDate.toLocaleDateString(i18n.language, {
    day: 'numeric',
  });
  const isLongerDate = getLocalDate.length > 2;

  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      if (entry.target === calendarRef.current) {
        setCalendarHeight(entry.contentRect.height);
      }
    }
  });

  if (calendarRef.current) {
    resizeObserver.observe(calendarRef.current);
  }

  // Function to check if a date is before today (excluding today)
  const isBeforeToday = useCallback((date) => {
    const today = new Date();
    return date < new Date(today.getFullYear(), today.getMonth(), today.getDate());
  }, []);

  const handleChange = useCallback((value) => {
    setDate(value);
    const selectedDate = new Date(value);
    const date = value;
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    if (isBeforeToday(date) && errandContext.morphType !== MorphType.DOB) {
      setSnackbarMessage(t('selectFuture'));
      setOpenSnackbar(true);
      morphContext.setIsInvalidWorkshopDate(true);
    } else if (
      (selectedDate.toString().includes('Sat') || selectedDate.toString().includes('Sun')) &&
      errandContext.morphType !== MorphType.DOB
    ) {
      setSnackbarMessage(t('selectWeekday'));
      setOpenSnackbar(true);
      morphContext.setIsInvalidWorkshopDate(true);
    } else {
      setOpenSnackbar(false);
      morphContext.setIsInvalidWorkshopDate(false);
      footerContext.chatInputFieldRef.current?.update(formattedDate || '');
      footerContext.sendButtonRef.current?.update(formattedDate || '');
      setTimeout(() => {
        footerContext.sendButtonRef.current?.handleSubmit();
      }, 100);
      console.log(formattedDate); // Logs the date in the format "01-16-2024"
    }
  }, [errandContext.morphType, isBeforeToday]);

  const tileDisabled = useCallback(({ date, view }) => {
    // Only disable tiles for CalendarMonth used for Workshop Registration, not DOB
    if (errandContext.morphType !== MorphType.CalendarMonth) return false;

    // Disable dates before today
    if (isBeforeToday(date)) return true;

    // Disable weekend dates
    if (view === 'month') {
      // If the date falls on a Saturday (6) or Sunday (0)
      if (date.getDay() === 6 || date.getDay() === 0) return true;
    }

    return false;
  }, [errandContext.morphType, isBeforeToday]);

  useEffect(() => {
    if (!footerContext.chatInputFieldRef.current) return;
    const typedDate = footerContext.chatInputFieldRef.current.unformattedValue;

    let month, day, year;
    // If the input has enough characters for the month and day
    if (typedDate.length >= 4) {
      month = parseInt(typedDate.substring(0, 2), 10);
      day = parseInt(typedDate.substring(2, 4), 10);
    }
    // If the input has enough characters for the year
    if (typedDate.length >= 8) {
      year = parseInt(typedDate.substring(4), 10);
    } else {
      // If the year is not specified, use the current year
      const currentDate = new Date();
      year = currentDate.getFullYear();
    }

    // Check if the extracted components represent a valid date
    if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
      const selectedDate = new Date(year, month - 1, day);
      // Update the selectedDate state
      setDate(selectedDate);

      if (isBeforeToday(selectedDate) && errandContext.morphType !== MorphType.DOB) {
        setSnackbarMessage(t('selectFuture'));
        setOpenSnackbar(true);
        morphContext.setIsInvalidWorkshopDate(true);
      } else if (
        (selectedDate.toString().includes('Sat') || selectedDate.toString().includes('Sun')) &&
        errandContext.morphType !== MorphType.DOB
      ) {
        setSnackbarMessage(t('selectWeekday'));
        setOpenSnackbar(true);
        morphContext.setIsInvalidWorkshopDate(true);
      } else {
        setOpenSnackbar(false);
        morphContext.setIsInvalidWorkshopDate(false);
      }
    }
  }, [footerContext?.chatInputFieldRef?.current, errandContext.morphType]);

  return (
    <>
      <div
        className={`${Styles.snackbarWrapper} ${openSnackbar ? Styles.openSnackbar : Styles.closedSnackbar} ${
          morphContext.hideCalendarMonth && Styles.closedSnackbar
        }`}
        style={{ '--calendarHeight': `${calendarHeight}px` } as React.CSSProperties}
      >
        {snackbarMessage}
      </div>
      <ReactCalendarStyles isLongerDate={isLongerDate}>
        <div
          className={`${Styles.calendarContainer} ${
            morphContext.hideCalendarMonth ? Styles.hideCalendar : Styles.showCalendar
          }`}
          ref={calendarRef}
        >
          <Calendar
            onChange={(value) => handleChange(value)}
            value={date}
            locale={i18n.language}
            tileDisabled={tileDisabled}
          />
        </div>
      </ReactCalendarStyles>

      <div className={Styles.bottomBorder}></div>
    </>
  );
};

const MorphCalendarMonthTab = () => {
  const { t } = useTranslation();
  const morphContext = useMorphContext();
  const errandContext = useErrandContext();

  const toggleHide = () => {
    morphContext.setHideCalendarMonth((prev) => {
      return !prev;
    });
  };

  return (
    <button
      className={morphContext.hideCalendarMonth ? Styles.showCalendarButton : Styles.hideCalendarButton}
      onClick={morphContext.hideCalendarMonth ? toggleHide : () => {}}
    >
      {morphContext.hideCalendarMonth ? t('clickToShow') : 
      errandContext.morphType === MorphType.DOB ? t('selectDOB') :
      t('selectADate')
    }
    </button>
  );
};

export { MorphCalendarMonth, MorphCalendarMonthTab };
