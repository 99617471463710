import MobileDeviceType from '@common/MobileDeviceType';
import BrowserType from '@common/BrowserType';
import ScreenType from '@common/ScreenType';
import axios from 'axios';

const EXTRA_SMALL_SCREEN_THRESHOLD = 768;   // Bootstrap threshold for extra small screen breakpoint
const SMALL_SCREEN_THRESHOLD = 992;         // Bootstrap threshold for small screen breakpoint
const MEDIUM_SCREEN_THRESHOLD = 1200;       // Bootstrap threshold for medium screen breakpoint
const DEFAULT_LENDER_COMPANY = 'SUNWST000'; // Default lender company when calling for the system settings

export interface ILoginProps {
  disableEngage: boolean;
  disableEngageReason: string;
  systemMessage : string;
}

/**
 * Return an instance of `BrowserType` which indicates the browser being used to access Morgan.
 * 
 * Note that if no other matches are found, `BrowserType.OTHER` is returned.
 * 
 * @returns The browser used to access Morgan from.
 */
export function getBrowserType() {

    if (navigator.userAgent.match(/Edge|Edg|EdgA|EdgiOS/i)) {
      return BrowserType.EDGE;
    
    } else if (navigator.userAgent.match(/Firefox|FxiOS/i)) {
      return BrowserType.FIREFOX;
    
    } else if (navigator.userAgent.match(/OPR/i)) {
      return BrowserType.OPERA;
    
    } else if (navigator.userAgent.match(/Safari/i) 
        && !navigator.userAgent.match(/Chrome|CriOS/i) 
        && !navigator.userAgent.match(/Firefox|FxiOS/i) 
        && !navigator.userAgent.match(/Edge|Edg|EdgA|EdgiOS/i)
        && !navigator.userAgent.match(/OPR/i)) {
      return BrowserType.SAFARI;
    
    } else if (navigator.userAgent.match(/Trident/i)) {
      return BrowserType.IE;
    
    } else if (navigator.userAgent.match(/SamsungBrowser/i)) {
      return BrowserType.SAMSUNG;
  
    } else if (navigator.userAgent.match(/Chrome/i)
        && !navigator.userAgent.match(/Edge|Edg|EdgA|EdgiOS/i)
        && !navigator.userAgent.match(/OPR/i)) {
      return BrowserType.CHROME;
    
    } else {
      return BrowserType.OTHER;
    }
  
  }
  
  /**
   * Return an instance of `ScreenType` which indicates the size of the user's screen.
   * 
   * @returns The size of the user's screen.
   */
  function getScreenType() {
  
    if (window.innerWidth <= EXTRA_SMALL_SCREEN_THRESHOLD) {
      return ScreenType.EXTRA_SMALL;
    
    } else if (window.innerWidth < SMALL_SCREEN_THRESHOLD) {
      return ScreenType.SMALL;
    
    } else if (window.innerWidth < MEDIUM_SCREEN_THRESHOLD) {
      return ScreenType.MEDIUM;
    
    } else {
      return ScreenType.LARGE;
    }
  }

  /**
 * Return an instance of `MobileDeviceType` which indicates the type of mobile device the user is accessing Morgan from.
 * 
 * Note that if no mobile device is detected, this method will return `MobileDeviceType.NONE`.
 * 
 * @returns The type of mobile device used to access Morgan.
 */
function getMobileDeviceType() {

    if (navigator.userAgent.match(/Android/i)) {
      return MobileDeviceType.ANDROID;
  
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      return MobileDeviceType.IOS;
    
    } else if (navigator.userAgent.match(/Blackberry/i)) {
      return MobileDeviceType.BLACKBERRY;
    
    } else {
      return MobileDeviceType.NONE;
    }
  }

  /**
   * This function will be called when the user homepage is loaded from Pages/Home/DesktopSplash.js or Pages/Home/MobileSplash.js. It will determine whenever the Engage button should be disabled or not.
   * 
   * 
   * @param disableEngage if this is true the user won't be able to access the conversation screen. Some reasons to disable Engage: user is banned, recaptcha failed, system under maintenance, or system down
   * @param disableEngageReason if the Engage button is disabled this shows the reason for been disabled. Some reasons include: user is banned, system under maintenance or system down
   * @param systemMessage message to display on screen. It can be a global notification message or a reason for the engage button to be disabled
   * @param lenderCompany Depending on the user we can load specific lender settings, useful for third party lenders renting the software
   * @param t translation function
   * @returns ILoginProps object
   */
  export const prepareEngage = async (disableEngage: boolean, disableEngageReason: string, systemMessage: string, lenderCompany: string, t: Function) => {
    let loginProps : ILoginProps = {disableEngage: disableEngage, disableEngageReason: disableEngageReason, systemMessage : systemMessage};

    // if we have a reason to disable engage then show message and return
    if (disableEngage) {
      if (disableEngageReason === 'Banned') {
        loginProps.systemMessage = t('userBlockedEngage');
        return loginProps;
      }
      if (disableEngageReason === 'Captcha') {
        loginProps.systemMessage = t('userCaptchaEngage');
        return loginProps;
      }
    }

    //window.history.replaceState({}, document.title);
    // ip blacklist
    try {
      const blacklist = await axios.post(`${process.env.REACT_APP_MORGAN_CORE}/blacklist/address`);

      if (!blacklist?.data?.allowed) {
        loginProps.disableEngage = true;
        loginProps.disableEngageReason = 'Banned';
        loginProps.systemMessage = t('userBlockedEngage');
        return loginProps;
      }
    }
    catch(e) {
      loginProps.disableEngage = true;
      loginProps.disableEngageReason = 'Downtime';
      loginProps.systemMessage = t('systemUnavailable');
      return loginProps;
    }

    try {
      // maintenance notification
      if (lenderCompany === undefined || lenderCompany === null || lenderCompany === '') {
        lenderCompany = DEFAULT_LENDER_COMPANY;
      }

      const system = await axios.get(`${process.env.REACT_APP_MORGAN_CORE}/system?lender=${lenderCompany}&active=true&fields=message,status`);

      if (system?.data?.status === 'Maintenance') {
        loginProps.disableEngage = true;
        loginProps.disableEngageReason = system?.data?.status;
        loginProps.systemMessage = t('systemUnderMaintenance');
      }
      // global notification 
      else if (system?.data?.message !== '') {
        loginProps.disableEngage = false;
        loginProps.disableEngageReason = system?.data?.status;
        loginProps.systemMessage = system?.data?.message;
      }
    }
    catch(e) {
      loginProps.disableEngage = true;
      loginProps.disableEngageReason = 'Downtime';
      loginProps.systemMessage = t('systemUnavailable');
    }
    return loginProps;
}

/**
 * This function will perform the navigation transition from the user home page to the user conversation screen after the Engage button is clicked.
 * 
 * @param hashkey hash key containing encrypted information to be used by Pages/Conversation/Conversation.tsx while authenticating the user
 * @param navigate navigation function to be called when the user clicks the Engage button
 */
export const Engage = async (hashkey: string, navigate: Function) => {
    if (hashkey !== undefined && hashkey !== null && hashkey !==  '') {
        navigate(`/conversation/${hashkey}?${window.location.search.substring(1)}&browserType=${getBrowserType().name}&screenType=${getScreenType().name}&`
        + `mobileDeviceType=${getMobileDeviceType().name}`);
    } else {
        navigate(`/conversation?${window.location.search.substring(1)}&browserType=${getBrowserType().name}&screenType=${getScreenType().name}&`
        + `mobileDeviceType=${getMobileDeviceType().name}`);
    }
  }