import { Stack } from '@mui/material';
import React, { useEffect, useState, memo, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import axiosCall from '@services/axios';
import WelcomeBar from '@components/WelcomeBar';
import UserBadgeList from '@components/UserBadgeList';
import NumberBadge from '@components/NumberBadge';
import MessageList from '@components/MessageList';
import { ChatType } from '@common/ChatType';
import { useSocketContext } from '@contexts/socket';
import { useUserContext } from '@contexts/user';

/*
 * This component is the dashboard of the console, visible in the main content area.
 * This component was designed to fit within the Consoles frame, having its
 * visibility controllled by Consoles navigation system. For more about the visual
 * design see the Figma: https://www.figma.com/file/5HrQkz2QKGTb97ZvTsqfOC/Dashbord?node-id=613%3A71
 *
 */

const Dashboard = (props: { onlineOperators: string | any[]; onlineUsers: string | any[]; notifications: any; setNotifications: any; }) => {
  const { t } = useTranslation();
  const { eventsSocket, isEventsConnected } = useSocketContext();
  const { firstname, lastname, accessLevel } = useUserContext();
  // Set default to "null" so that skeleton loader can be rendered when these aren't loaded yet
  // Ensure that these are not null before filtering through later and setting new values
  const [errandChats, setErrandChats] = useState(null);
  const [unattendedChats, setUnattendedChats] = useState(null);
  const [waitingChats, setWaitingChats] = useState(null);

  // updates chat arrays as needed for the eventsSocket unattended-conversation-alert event
  const dashboardUnattendedConversationAlert = (payload: { message: any; data: { _id: any; }; }) => {
    console.log(`Events Socket - (unattended-conversation-alert-dashboard) ${payload?.message}`, payload?.data);

    // 4/13/2023 - Dennis,  this needs to be removed from the backend. The backend should emit a message that tells from which card to remove the chat instead of just assuming that
    // the chat exists in the waiting chats card and now it needs to be removed before it can be set on the unattended or errands cards
    setWaitingChats((prev) => {
      if (prev === null) return prev;
      return [...prev.filter((wc) => wc?._id !== payload?.data?._id)];
    });
    
    // 4/13/2023 - Dennis, why we cannot simply add to the list? When a chat is attended an event needs to trigger to update the list.
    setUnattendedChats((prev) => {
      if (prev === null) {
        return [payload?.data];
      } else if (prev.findIndex((uc) => uc?._id === payload?.data?._id) === -1) {
        return [...prev, payload?.data];
      } else {
        return prev;
      }
    });
  };

  // updates chat arrays as needed for the eventsSocket unattended-errand-alert event
  const dashboardUnattendedErrandAlert = (payload: { message: any; data: { _id: any; }; }) => {
    console.log(`Events Socket - (unattended-errand-alert-dashboard) ${payload?.message}`, payload?.data);

    // 4/13/2023 - Dennis,  this needs to be removed from the backend. The backend should emit a message that tells from which card to remove the chat instead of just assuming that
    // the chat exists in the waiting chats card and now it needs to be removed before it can be set on the unattended or errands cards
    setWaitingChats((prev) => {
      if (prev === null) return prev;
      return [...prev.filter((wc) => wc?._id !== payload?.data?._id)];
    });

    // 4/13/2023 - Dennis, why we cannot simply add to the list? When a chat is attended an event needs to trigger to update the list.
    setErrandChats((prev) => {
      if (prev === null) {
        return [payload?.data];
      } else if (prev.findIndex((ec) => ec?._id === payload?.data?._id) === -1) {
        return [...prev, payload?.data];
      } else {
        return prev;
      }
    });
  };

  // updates chat arrays as needed for the eventsSocket waiting-updates-alert event
  const dashboardWaitingUpdatesAlert = (payload: { message: any; data: { _id: any; }; }) => {
    console.log(`Events Socket - (waiting-updates-alert-dashboard) ${payload?.message}`, payload?.data);
    // 4/13/2023 - Dennis,  this needs to be removed from the backend. The backend should emit a message that tells from which card to remove the chat instead of just assuming that
    // the chat exists in the waiting chats card and now it needs to be removed before it can be set on the unattended or errands cards
    setUnattendedChats((prev) => {
      if (prev === null) return prev;
      return [...prev.filter((uc) => uc?._id !== payload?.data?._id)];
    });


    setErrandChats((prev) => {
      if (prev === null) return prev;
      return [...prev.filter((ec) => ec?._id !== payload?.data?._id)];
    });


    // 4/13/2023 - Dennis, why we cannot simply add to the list? When a chat is attended an event needs to trigger to update the list.
    setWaitingChats((prev) => {
      if (prev === null) {
        return [payload?.data];
      } else if (prev.findIndex((wc) => wc?._id === payload?.data?._id) === -1) {
        return [...prev, payload?.data];
      } else {
        return prev;
      }
    });
  };

  // updates chat arrays as needed for the eventsSocket remove-chat-alert event
  const dashboardRemoveChatAlert = (payload: { message: any; data: { _id: any; }; }) => {
    // 4/10/2023 - Dennis, from the backend we should know which chat from which queue to remove instead of just hitting all
    console.log(`Events Socket - (remove-chat-alert-dashboard) ${payload?.message}`, payload?.data);

    setUnattendedChats((prev) => {
      if (prev === null) return prev;
      return [...prev.filter((uc) => uc?._id !== payload?.data?._id)];
    });

    setErrandChats((prev) => {
      if (prev === null) return prev;
      return [...prev.filter((ec) => ec?._id !== payload?.data?._id)];
    });

    setWaitingChats((prev) => {
      if (prev === null) return prev;
      return [...prev.filter((wc) => wc?._id !== payload?.data?._id)];
    });
  };

  // dashboard event management
  useEffect(() => {
    if (!isEventsConnected) return;
    
    // Reload all chat lists (unattended, errands, and waiting)
    eventsSocket.current?.emit('monitor-chat-events', (payload) => {
      console.log(`Events Socket - (monitor-chat-events) ${payload?.message}`, payload?.data);

      setUnattendedChats(payload?.data?.unattended);
      setErrandChats(payload?.data?.errands);
      setWaitingChats(payload?.data?.waiting);

      // bold-6/8/2023 subscribe to chat action event after initial Load!

      // Add a chat to the unattended conversations list
      console.log('Events Socket - Dashboard - (on)');
      eventsSocket.current?.on('unattended-conversation-alert', dashboardUnattendedConversationAlert);
      eventsSocket.current?.on('unattended-errand-alert', dashboardUnattendedErrandAlert);
      eventsSocket.current?.on('waiting-updates-alert', dashboardWaitingUpdatesAlert);
      eventsSocket.current?.on('remove-chat-alert', dashboardRemoveChatAlert);
    });

    // Clean up function
    return () => {
      // We need to remove listeners associated with this component only.
      console.log('Events Socket - Dashboard - (off)');
      eventsSocket.current?.off('unattended-conversation-alert', dashboardUnattendedConversationAlert);
      eventsSocket.current?.off('unattended-errand-alert', dashboardUnattendedErrandAlert);
      eventsSocket.current?.off('waiting-updates-alert', dashboardWaitingUpdatesAlert);
      eventsSocket.current?.off('remove-chat-alert', dashboardRemoveChatAlert);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected]);

  return (
    <>
      <Stack
        sx={{
          display: 'grid',
          gridTemplateColumns: '3fr 1fr',
          gridGap: '16px',
          height: 'fit-content',
          overflow: 'hidden',
        }}>
        <Stack
          sx={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr auto',
            gridGap: '16px',
            height: 'fit-content',
            margin: '0 0 1px 1px',
          }}>
          <WelcomeBar
            name={firstname + ' ' + lastname}
            accessLevel={accessLevel} 
            onClick={function (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>): void {
              throw new Error('Function not implemented.');
            } }            />
          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '16px',
              height: 'fit-content',
            }}>
            <NumberBadge
              bg="var(--peach910)"
              number={unattendedChats?.length}
              label={t('unattendedUsersText')}
              group single={undefined}            />
            <NumberBadge
              bg="var(--orange060)"
              number={props.onlineOperators?.length}
              label={t('onlineOperatorsText')}
              single group={undefined}            />
            <NumberBadge
              bg="var(--blue110)"
              number={props.onlineUsers?.length}
              label={t('onlineUsersText')}
              group single={undefined}            />
          </Stack>
          <Stack
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gridGap: '16px',
              height: 'calc(100vh - 362px)',
            }}>
            <UserBadgeList
              title={t('unattendedUsersText')}
              data={unattendedChats}
              onClearClick={async () => {
                let response = await axiosCall({
                  url: 'chat/bulk/update',
                  method: 'put',
                  data: {
                    prevStatus: 'unattended',
                    newStatus: 'inactive',
                    chatType: ChatType.Conversation,
                  },
                });

                if (response) {
                  setUnattendedChats([]);
                }
              }}
            />
            <UserBadgeList
              title={t('errandsListHeader')}
              data={errandChats}
              onClearClick={async () => {
                let response1 = await axiosCall({
                  url: 'chat/bulk/update',
                  method: 'put',
                  data: {
                    prevStatus: 'unattended',
                    newStatus: 'inactive',
                    chatType: ChatType.Errand,
                  },
                });

                let response2 = await axiosCall({
                  url: 'chat/bulk/update',
                  method: 'put',
                  data: {
                    prevStatus: 'unattended',
                    newStatus: 'inactive',
                    chatType: ChatType.Form,
                  },
                });

                if (response1 && response2) {
                  setErrandChats([]);
                }
              }}
            />
            <UserBadgeList
              title={t('waitingUpdatesListHeader')}
              data={waitingChats}
              onClearClick={async () => {
                let response = await axiosCall({
                  url: 'chat/bulk/update',
                  method: 'put',
                  data: {
                    prevStatus: 'waiting-updates',
                    newStatus: 'inactive',
                  },
                });

                if (response) {
                  setWaitingChats([]);
                }
              }}
            />
          </Stack>
        </Stack>
        <Stack sx={{ height: 'fit-content', width: '100%' }}>
          <MessageList
            onlineUsers={props.onlineUsers}
            notifications={props.notifications}
            setNotifications={props.setNotifications}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default memo(Dashboard);