const images = {
    BannerMiddleCircleSinglePoint: `${process.env.REACT_APP_MORGAN_CDN}/Images/ReloadBanner/banner_middle_circle_single_point.png`,
    BannerMiddleCircleDoublePoint: `${process.env.REACT_APP_MORGAN_CDN}/Images/ReloadBanner/banner_middle_circle_double_point.png`,
    BannerBaseMobile: `${process.env.REACT_APP_MORGAN_CDN}/Images/ReloadBanner/BannerBaseMobile.png`,
    dialogPopupFinal: `${process.env.REACT_APP_MORGAN_CDN}/Images/ReloadBanner/dialogPopupFinal.png`,
    angelTalkGif: `${process.env.REACT_APP_MORGAN_CDN}/Images/ReloadBanner/angelTalkGif.gif`,
    AngelCircle: `${process.env.REACT_APP_MORGAN_CDN}/Images/ReloadBanner/AngelCircle.png`,
}

const {
    BannerMiddleCircleSinglePoint,
    BannerMiddleCircleDoublePoint,
    BannerBaseMobile,
    dialogPopupFinal,
    angelTalkGif,
    AngelCircle
} = images;

export {
    BannerMiddleCircleSinglePoint,
    BannerMiddleCircleDoublePoint,
    BannerBaseMobile,
    dialogPopupFinal,
    angelTalkGif,
    AngelCircle
}
