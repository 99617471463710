import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";

import { Close } from '../Assets/Icons';
import DialogHeader from './DialogHeader';
import { Tooltip } from '@mui/material';
import getImageSource from '../Common/getImageSource';
import { useDropzone } from 'react-dropzone';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';
import { getBase64, getBase64Promise } from '@common/getBase64';
import { ChatType } from "@common/ChatType";


// Icon value type.
type IIcon = string | ArrayBuffer | "";
interface AnimatedIconUploadProps {
  animatedIcon: IIcon;
  setAnimatedIcon: (val: IIcon) => void
}

/**
 * @summary Takes care of upload logic for animated Icon that serves for the pulsing animation of actionIcon component.
 */
const AnimatedIconUpload: FC<AnimatedIconUploadProps> = (props) => {
  const { setAnimatedIcon, animatedIcon } = props;
  const { t } = useTranslation();

  const removeIcon = () => {
    setAnimatedIcon("");
  }

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      const fileDataRaw = await getBase64Promise(file);
      const fileData = fileDataRaw.split(',')[1];
      console.log(fileData);
      // getBase64(file, async (result) => {
      //   // Get the data portion of the DataURL
      //   const fileData = result?.split(',')[1];
      //   console.log(fileData);
      //   setAnimatedIcon(fileData);
      // });
      setAnimatedIcon(fileData);
    }
  };

  // Dropzone Controller Logic
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    accept: {
      'image/svg+xml': ['.svg'],
      'image/png': ['.png'],
    },
    maxFiles: 1,
    maxSize: 10000000,
    minSize: 1,
    onDropRejected: null,
  });

  return (
    <Stack direction="row" justifyContent="space-between">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Button variant="outlined" color="info" onClick={() => open()}>
          {t('uploadAnimatedIcon')}
        </Button>
      </div>
      {animatedIcon && (
        <Stack
          sx={{
            border: '1px solid var(--gray040)',
            borderRadius: '4px',
            marginLeft: "8px"
          }}
          width="150px"
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Tooltip title={t('removeIcon')} placement="left">
            <Close style={{ width: '12px', cursor: 'pointer' }} color="var(--gray210)" onClick={removeIcon} />
          </Tooltip>
          <Typography
            sx={{ marginTop: '2px', textTransform: 'Uppercase', color: 'var(--gray210)', fontSize: '14px', fontWeight: '500' }}
          >
            {t('currentIcon')}{' '}
          </Typography>
          <Box
            component="img"
            width="20px"
            color="black"
            height="20px"
            style={{ filter: 'grayscale(100%)' }}
            src={getImageSource(animatedIcon)}
          ></Box>
        </Stack>
      )}
    </Stack>
  );
};
// import themeFile from '@assets/Themes/default.json';

const mt = {
  mt: '12px'
}

const EditActionDialog: FC<DialogPropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [type, setType] = useState(props.action?.type || "");
  const [name, setName] = useState(props.action?.fieldName || "");
  const [displayName, setDisplayName] = useState(props.action?.displayName || "");
  const [attribute, setAttribute] = useState(props.action?.fieldAttribute || "");
  const [qid, setQid] = useState(props.action?.fieldQid || "");
  const [defaultQidOption, setDefaultQidOption] = useState(props.action?.defaultQidKeyword || "");
  const [command, setCommand] = useState(props.action?.command || null);
  const [templateId, setTemplateId] = useState(props.action?.templateId || "");
  const [endpoint, setEndpoint] = useState(props.action?.fieldEndpoint || "");
  const [chatTypeInitiator, setChatTypeInitiator] = useState(props.action?.chatTypeInitiator || "");
  const [description, setDescription] = useState(props.action?.description || "");
  const [message, setMessage] = useState(props.action?.message || "");
  const [visibility, setVisibility] = useState(props.action?.visibility || {
    ai: true,
    operator: false,
    user: false
  });
  const [scope, setScope] = useState(props.action?.scope || 'workflow');
  const [shared, setShared] = useState(props.action?.shared || false);
  const [index, setIndex] = useState(props.action?.index || false);
  const [actionIcon, setActionIcon] = useState(props.action?.actionIcon || "");
  const [animatedIcon, setAnimatedIcon] = useState<IIcon>(props.action?.animatedIcon || "");

  const onDrop = async (acceptedFiles) => {
    console.log(acceptedFiles);
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      getBase64(file, async (result) => {
        // Get the data portion of the DataURL
        const fileData = result?.split(',')[1];
        console.log(fileData);
        setActionIcon(fileData);

        // try {
        //   const result = await axios(
        //     {
        //       url: `operator/${operatorData?._id}`,
        //       method: 'PUT',
        //       data: {
        //         avatar: fileData
        //       }
        //     });
        //   setOperatorData(result);
        // } catch (error) {
        //   console.log(error)
        // }
      });
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    accept: {
      'image/svg+xml': ['.svg'],
      'image/png': ['.png'],
    },
    maxFiles: 1,
    maxSize: 10000000,
    minSize: 1,
    onDropRejected: null,
  });

  const handleSubmit = () => {
    let actionData =
    {
      id: null,
      type: type,
      fieldName: name,
      displayName: displayName,
      fieldAttribute: attribute.trim() || null,
      fieldQid: qid,
      defaultQidOption: defaultQidOption,
      fieldEndpoint: endpoint,
      chatTypeInitiator: chatTypeInitiator,
      templateId: templateId,
      description: description,
      command: command,
      message: message,
      actionIcon: actionIcon,
      animatedIcon: animatedIcon,
      visibility: visibility,
      shared: shared,
      scope: scope,
      index: index,
    }

    if (!props.edit) {
      props.onAdd(actionData);
    }
    else {
      actionData = { ...actionData, id: props.action?._id };
      props.onSubmit(actionData);
    }
  }

  const removeIcon = () => {
    setActionIcon("");
  }

  return (
    <Dialog maxWidth={false} open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={props.edit ? t("editAction") : t("addAction")} onClose={() => props.onClose()} />
      <DialogContent sx={{ width: '40vw' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <FormControlLabel
            sx={{ marginLeft: '0' }}
            control={
              <Checkbox
                color="info"
                checked={visibility.ai}
                onChange={(e) => {
                  setVisibility({ ...visibility, ai: e.target.checked });
                }}
              />
            }
            label={t('aiVisibleCol')}
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ marginLeft: '0' }}
            control={
              <Checkbox
                color="info"
                checked={visibility.operator}
                onChange={(e) => {
                  setVisibility({ ...visibility, operator: e.target.checked });
                }}
              />
            }
            label={t('operatorVisibleCol')}
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ marginLeft: '0' }}
            control={
              <Checkbox
                color="info"
                checked={visibility.user}
                onChange={(e) => {
                  setVisibility({ ...visibility, user: e.target.checked });
                }}
              />
            }
            label={t('userVisibleCol')}
            labelPlacement="end"
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
          <FormControlLabel
            sx={{ marginLeft: '0' }}
            control={
              <Checkbox
                color="info"
                checked={index}
                onChange={(e) => {
                  setIndex(e.target.checked);
                }}
              />
            }
            label={t('indexCol')}
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ marginLeft: '0' }}
            control={
              <Checkbox
                color="info"
                checked={shared}
                onChange={(e) => {
                  setShared(e.target.checked);
                }}
              />
            }
            label={t('sharedCol')}
            labelPlacement="end"
          />
        </div>
        <Stack>
          <TextField
            sx={{ ...mt }}
            select
            autoFocus
            size="small"
            color="info"
            variant="outlined"
            label={`${t("typeCol")}*`}
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            <MenuItem key="Field" value="Field">Field</MenuItem>
            <MenuItem key="Command" value="Command">Command</MenuItem>
          </TextField>
          <TextField
            sx={{ ...mt }}
            size="small"
            color="info"
            variant="outlined"
            label={`${t("descriptionCol")}*`}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            sx={{ ...mt }}
            multiline
            minRows={2}
            size="small"
            color="info"
            variant="outlined"
            label={`${t("messageCol")}*`}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <TextField
            sx={{ ...mt }}
            size="small"
            color="info"
            variant="outlined"
            label={t("fieldNameCol")}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            sx={{ ...mt }}
            size="small"
            color="info"
            variant="outlined"
            label={t("displayNameCol")}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <TextField
            sx={{ ...mt }}
            select
            size="small"
            color="info"
            variant="outlined"
            label={t("fieldAttributeCol")}
            value={attribute}
            SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500, } } } }}
            onChange={(e) => setAttribute(e.target.value)}
          >
            <MenuItem key={0} value=" ">&nbsp; </MenuItem>
            {
              props.fieldAttributes?.map(fa => {
                return <MenuItem key={fa?._id} value={fa?._id}>{fa.description}</MenuItem>
              })
            }
          </TextField>
          <TextField
            sx={{ ...mt }}
            select
            size="small"
            color="info"
            variant="outlined"
            label={t("tFunction")}
            value={command}
            SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500, } } } }}
            onChange={(e) => setCommand(e.target.value)}
          >
            <MenuItem key={0} value={null}>&nbsp; </MenuItem>
            {
              props.functions?.map(fx => {
                return <MenuItem key={fx?._id} value={fx?._id}>{fx.name}</MenuItem>
              })
            }
          </TextField>
          <TextField
            sx={{ ...mt }}
            size="small"
            color="info"
            variant="outlined"
            label={t("fieldQidCol")}
            value={qid}
            onChange={(e) => setQid(e.target.value)}
          />
          <TextField
            sx={{ ...mt }}
            size="small"
            color="info"
            variant="outlined"
            label={t("defaultQidOptionCol")}
            value={defaultQidOption}
            onChange={(e) => setDefaultQidOption(e.target.value)}
          />
          <TextField
            sx={{ ...mt }}
            size="small"
            //
            variant="outlined"
            label={t("fieldEndpointCol")}
            value={endpoint}
            onChange={(e) => setEndpoint(e.target.value)}
          />
          <TextField
            sx={{ ...mt }}
            select
            size="small"
            color="info"
            variant="outlined"
            label={t("chatTypeInitiator")}
            value={chatTypeInitiator}
            SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500, } } } }}
            onChange={(e) => setChatTypeInitiator(e.target.value)}
          >
            <MenuItem key={0} value="">&nbsp; </MenuItem>
            <MenuItem key={ChatType.Form} value={ChatType.Form}>{ChatType.Form}</MenuItem>
            <MenuItem key={ChatType.Activity} value={ChatType.Activity}>{ChatType.Activity}</MenuItem>
            <MenuItem key={ChatType.Page} value={ChatType.Page}>{ChatType.Page}</MenuItem>
          </TextField>
          <TextField
            sx={{ ...mt }}
            select
            size="small"
            color="info"
            variant="outlined"
            label={t("scopeCol")}
            value={scope}
            SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500, } } } }}
            onChange={(e) => setScope(e.target.value)}
          >
            <MenuItem key={0} value="">&nbsp; </MenuItem>
            {props.scopeOptions.map((scope) => {
              return(
                <MenuItem key={scope} value={scope}>{scope}</MenuItem>
              )
            })}
          </TextField>
          <TextField
            sx={{ ...mt }}
            size="small"
            color="info"
            variant="outlined"
            label={t("Template Id (ai-sign)")}
            value={templateId}
            onChange={(e) => setTemplateId(e.target.value)}
          />
        </Stack>
        <Typography>
          {props.error}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ width: '100%' }}>

        <Stack direction="column" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack flexDirection={'row'} justifyContent={'flex-start'} sx={{ marginBottom: '8px' }}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button variant="outlined" color="info" onClick={() => open()}>{t("uploadIcon")}</Button>
            </div>
            {actionIcon &&
              <Stack
                sx={{
                  border: "1px solid var(--gray040)",
                  borderRadius: "4px",
                  marginLeft: '8px'
                }}
                width="150px"
                direction="row"
                justifyContent="space-around"
                alignItems="center">
                <Tooltip title={t("removeIcon")} placement="left">
                  <Close style={{ width: "12px", cursor: "pointer" }} color="var(--gray210)" onClick={() => removeIcon()} />
                </Tooltip>
                <Typography sx={{ marginTop: "2px", textTransform: "Uppercase", color: "var(--gray210)", fontSize: "14px", fontWeight: "500" }} >{t("currentIcon")} </Typography>
                <Box component="img" width="20px" color="black" height="20px" style={{ filter: "grayscale(100%)" }} src={getImageSource(actionIcon)}>
                </Box>
              </Stack>
            }
          </Stack>
          <Stack flexDirection={'row'} justifyContent={'flex-start'} sx={{ marginBottom: '8px' }}>
            <AnimatedIconUpload setAnimatedIcon={setAnimatedIcon} animatedIcon={animatedIcon} />
          </Stack>
          <Stack flexDirection='row' justifyContent='flex-end'>
            <Button 
              sx={{ marginLeft: '8px' }} 
              disabled={type === "" || description === "" || message === ""} 
              variant="contained" 
              color="info" 
              onClick={() => handleSubmit()}>
              {t("okButton")}
            </Button>
          </Stack>
        </Stack>

      </DialogActions>
    </Dialog >
  )
}

export default EditActionDialog;