import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, Typography } from '@mui/material';

import { ReloadIcon } from '@assets/Icons';

import { useCaptchaContext } from '@contexts/captcha';
import { useNavigate, useParams } from 'react-router-dom';

const StyledDiv = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  z-index: 2;
  background: var(--gray000);
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px var(--shadow200), 0px 24px 38px 3px var(--shadow114),
    0px 9px 46px 8px var(--shadow112);
  .captchaContainer {
    padding: 10px 25px 25px 25px;
    border: 1px solid var(--gray110);
    border-radius: 8px;
    margin: 15px 25px 0px 25px;
    background: var(--gray000);
  }
  .captchaTitle {
    font-weight: 500;
    margin-bottom: 10px;
  }
  .captchaHeader {
    margin-top: 10px;
  }
  .captchaFooter {
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .reloadCaptchaIcon {
      width: 1em;
      height: 1em;
      cursor: pointer;
    }
  }
`;

const Captcha = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { referrer, hashkey } = useParams();
  const { failCaptcha, passCaptcha, resetCaptcha } = useCaptchaContext();
  const [value, setValue] = useState('');
  const [displayInvalidCaptchaMessage, setDisplayInvalidCaptchaMessage] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const captchaRef = useRef<string>('');
  const failCountRef = useRef<number>(0);
  const textFieldRef = useRef<HTMLDivElement | null>(null);

  /**
   * Ported over from the old Morgan project.
   * This file was brought in to be left as is.
   * 
   * Written by Peter Nelson.
   */
  const loadCaptchaEnginge = useCallback(() => {
    if (!canvasRef.current) return;
    setValue('');
    textFieldRef.current.querySelector('input')?.focus();
    let retVal = '';
    let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    let length = 6;

    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    captchaRef.current = retVal;

    let canvas = canvasRef.current,
      ctx = canvas.getContext('2d');
    let text = captchaRef.current;
    let lineheight = 30;

    let lines = text.split('\n');
    ctx.canvas.width = parseInt(String(length)) * 25;
    ctx.canvas.height = lines.length * lineheight;

    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.textBaseline = 'middle';
    ctx.font = 'bold italic 20px Verdana';
    ctx.fillStyle = 'black';

    // Adds letters to the canvas
    let num = 0;
    for (let i = 0; i < parseInt(String(length)); i++) {
      num = parseInt(String(num)) + 1;
      let heigt_num = 20 * num;
      ctx.fillText(retVal[i], heigt_num, Math.round(Math.random() * (15 - 12) + 12));
    }

    // Generates random RGB values
    const randomColor = () => {
      const r = Math.floor(Math.random()*256);
      const g = Math.floor(Math.random()*256);
      const b = Math.floor(Math.random()*256);
      return `rgb(${r},${g},${b})`;
    }

    // Adds random lines to the canvas
    for (let i = 0; i < 3; i++) {
      ctx.strokeStyle = randomColor();
      ctx.beginPath();
      ctx.moveTo(Math.random() * ctx.canvas.width, Math.random() * ctx.canvas.height);
      ctx.lineTo( Math.random() * ctx.canvas.width, Math.random() * ctx.canvas.height);
      ctx.stroke();
    }

    // Adds random dots to the background
    for (let i = 0; i < 30; i++) {
      ctx.strokeStyle = randomColor();
      ctx.beginPath();
      const x = Math.random() * ctx.canvas.width;
      const y = Math.random() * ctx.canvas.height;
      ctx.moveTo(x,y);
      ctx.lineTo(x+1, y+1);
      ctx.stroke();
    }
  }, []);

  useEffect(() => loadCaptchaEnginge(), [loadCaptchaEnginge])

  const handleSubmit = useCallback(() => {
    if (value === captchaRef.current) {
      setValue('');
      passCaptcha();
      failCountRef.current = 0;
    } else {
      loadCaptchaEnginge();
      failCaptcha();
      setDisplayInvalidCaptchaMessage(true);
      setValue('');
      failCountRef.current = failCountRef.current + 1;
      if (failCountRef.current < 1) return;
      failCountRef.current = 0;
      const notified = (window.location.pathname || '').toLowerCase().includes('notices');
      if (notified) return;
      resetCaptcha();
      navigate(`/notices${referrer ? `/${referrer}` : ''}${hashkey ? `/${hashkey}` : ''}`, {
        state: {
          disableEngage: true,
          disableEngageReason: 'Captcha',
          systemMessage: t('userCaptchaEngage'),
        },
      });
    }
  }, [value, referrer, hashkey, loadCaptchaEnginge, failCaptcha, passCaptcha, resetCaptcha]);

  const handleReload = () => {
    setDisplayInvalidCaptchaMessage(false);
    loadCaptchaEnginge();
  }

  return (
    <Backdrop sx={{ zIndex: 99999999999999 }} open={true} onClick={() => {}}>
      <StyledDiv>
        <div className="captchaTitle">{t('captchaHeading')}</div>
        <Divider />
        <div className="captchaContainer">
          <div className="captchaHeader">
            <canvas ref={canvasRef}></canvas>
          </div>
          <div className="captchaBody">
            <TextField label={t('captchaPlaceholder')} variant="standard" value={value} onChange={(e) => setValue(e.target.value)} ref={textFieldRef} />
          </div>
          <div className="captchaFooter">
            <Tooltip placement="top" title={t('reloadCaptcha')}>
              <ReloadIcon id="reloadIcon" className="reloadCaptchaIcon" onClick={handleReload} />
            </Tooltip>
            <Button onClick={handleSubmit} variant="contained">
            {t('verify')}
            </Button>
          </div>
          <div>
            {displayInvalidCaptchaMessage && <Typography color="red">{t('captchaDoesNotMatch')}</Typography>}
          </div>
        </div>
      </StyledDiv>
    </Backdrop>
  );
};

export default Captcha;