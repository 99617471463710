import { MorphType } from '@common/MorphType';
import { createValidator, Validators } from '@common/Validators';

// other helper functions
export const Mappers = {
  appendIndex: (el, idx) => {
    return {
      ...el,
      idx: idx,
    };
  },
};
// Validators
export const StringValueValidator = (val) =>
  createValidator(val, [Validators.isNotEmptyString, Validators.isNotUndefinedNorNull, Validators.isTypeOfString]);
export const ArrayValueValidator = (val) =>
  createValidator(val, [Validators.isTypeOfArray, Validators.isNotEmptyArray]);

// Used for determining whether the morph type (taken from ErrandContex) is relevant to Form.
// Relevant UI activity element is Esign.tsx 
export const isGivenMorphTypeFormRelated = (type: MorphType) => {
  if (
    type === MorphType.FormOpen ||
    type === MorphType.FormActiveChat ||
    type === MorphType.FormNavigateInitials ||
    type === MorphType.FormInsertSignature ||
    type === MorphType.FormReadyToSend
  ) {
    return true;
  }

  return false;
};
