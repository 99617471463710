/**
 * @file This component is a stylized Box component
 * for rendering display button for rotating screen. 
 * 
 * Extracted from ConversationMessage.js 10/11/2022
 * 
 * @author Harrison Fales <harrison.fales@swmc.com>
 */

import { Box, styled } from '@mui/system';

const RotateScreenButtonStyle = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
  flexWrap: 'multi-line',
  marginTop: '-10px',
  paddingTop: "5px",
  cursor: 'pointer',
  borderRadius: '50%',
  boxShadow: 'var(--shadow100) 0px 5px 35px',
  position: 'relative',
  backgroundColor: "var(--green430)",
  width: "35px",
  height: "35px",
});

export default RotateScreenButtonStyle