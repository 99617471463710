import React, { useEffect, useRef, useCallback } from 'react';
import useDebounce from '@common/hooks/useDebounce';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { EsignSignatureType, FormClickEventType } from '../Forms/commonForms';
import { useErrandContext } from '@contexts/ErrandContext';
import eventBus from '../Common/eventBus';
import { morphIndentType } from '@common/MorphType';
import { FormBodyType } from '../Forms/commonForms';

const outerFrameSize = 5;

// RGBA light gray watermark color
const LIGHT_GRAY = {
  red: 200,
  blue: 200,
  green: 200,
};

const FILTER_TOLERANCE = 20;

interface ITouchPointType {
  identifier: number;
  pageX: number;
  pageY: number;
}

const ESign = (props) => {
  const { saveCounter, btnGroupWidth, setIndentType } = props;

  const errandContext = useErrandContext();

  const primaryParticipantData = errandContext.getPrimaryParticipantFullName();
  const firstName = primaryParticipantData?.userData?.firstname;
  const lastName = primaryParticipantData?.userData?.lastname;

  const canvasBoundaryRef = errandContext.canvasBoundaryRef;
  const signatureTypeRef = errandContext.signatureTypeRef;

  const lastActiveTab = useRef<EsignSignatureType>(signatureTypeRef.current);

  // Used to set initial positioning for drawing with mouseDown
  const position = useRef({ x: 0, y: 0 });

  // Listener for when we have begun drawing on mouse
  const mouseDrawingPress = useRef(false);

  // Grabs the canvas HTML element
  const canvasRef = useRef(null);

  const canvasFrameRef = useRef(null);

  // Support for multiple fingers on the canvas
  const ongoingTouches = useRef<Array<ITouchPointType>>([]);

  // When text is auto generated, we have to manually calculate boundary coordinates
  const calculateGeneratedTextBoundary = (type: EsignSignatureType, name: string) => {
    const canvasCTX = canvasRef.current.getContext('2d');

    // Our approach is to measure the dimensions of the generated text and use
    // the measured values to calculate exactly what size of a canvas to draw the
    // text on.
    const textMetrics = canvasCTX.measureText(name);

    const calculatedHeight =
      Math.ceil(textMetrics.actualBoundingBoxAscent) + Math.ceil(textMetrics.actualBoundingBoxDescent);
    const textWidth = Math.ceil(textMetrics.actualBoundingBoxLeft) + Math.ceil(textMetrics.actualBoundingBoxRight);
    const misAlignmentY = (textMetrics.actualBoundingBoxAscent - textMetrics.actualBoundingBoxDescent) / 2;
    const misAlignmentX = (textMetrics.actualBoundingBoxLeft - textMetrics.actualBoundingBoxRight) / 2;

    // Position the text in the center
    const centerX = canvasCTX.canvas.width / 2;
    const centerY = canvasCTX.canvas.height / 2;

    // Calculate the four corner coordinates
    const x1 = centerX - textWidth / 2 - misAlignmentX - outerFrameSize;
    const y1 = centerY - calculatedHeight / 2 - misAlignmentY - outerFrameSize;
    const x2 = x1 + textWidth + outerFrameSize * 2;
    const y2 = y1 + calculatedHeight + outerFrameSize * 2;

    canvasBoundaryRef.current[type] = {
      x: {
        min: x1,
        max: x2,
      },
      y: {
        min: y1,
        max: y2,
      },
    };
  };

  const generateInitials = (restore: boolean) => {
    const canvasCTX = canvasRef.current.getContext('2d');
    const footerName = errandContext?.footerInputRef?.current?.value;
    const footerFirstInitial = footerName && footerName.split(' ')[0]?.[0]
    const footerLastInitial = footerName && footerName.split(' ')[1]?.[0]
    const initials = `${footerFirstInitial || firstName[0]}${footerFirstInitial ? footerLastInitial || '' : lastName[0]}`;

    canvasCTX.font = '48px "Honey Script"';
    canvasCTX.textAlign = 'center';
    canvasCTX.textBaseline = 'middle';
    canvasCTX.fillStyle = '#000000';

    clear(EsignSignatureType.Initials);
    canvasCTX.fillText(initials, canvasCTX.canvas.width / 2, canvasCTX.canvas.height / 2);
    calculateGeneratedTextBoundary(EsignSignatureType.Initials, initials);
    save(EsignSignatureType.Initials, restore);
  }

  const generateSignature = (restore: boolean) => {
    const canvasCTX = canvasRef.current.getContext('2d');
    const footerName = errandContext?.footerInputRef?.current?.value;
    const name = footerName || `${firstName} ${lastName}`;

    canvasCTX.font = '48px "Honey Script"';
    canvasCTX.textAlign = 'center';
    canvasCTX.textBaseline = 'middle';
    canvasCTX.fillStyle = '#000000';

    clear(EsignSignatureType.Signature);
    canvasCTX.fillText(name, canvasCTX.canvas.width / 2, canvasCTX.canvas.height / 2);
    calculateGeneratedTextBoundary(EsignSignatureType.Signature, name);
    save(EsignSignatureType.Signature, restore);
  }

  // Will be used to create a generated signature that matches the one generated
  // from sunsoft which is "Honey Script". This function will create the image
  // Which will be later fed into the calculateGeneratedTextBoundary function similar to
  // the other signature/initials
  const generateAuto = async () => {
    await document.fonts.load('3em "Honey Script"');

    if (signatureTypeRef.current === EsignSignatureType.Signature) {
      generateInitials(false);
      generateSignature(true);
    } else if (signatureTypeRef.current === EsignSignatureType.Initials) {
      generateSignature(false);
      generateInitials(true);
    }
  };

  // Used to identify mobile finger-positoning and adjusts for canvas dimensions
  const copyTouch = ({ identifier, pageX, pageY }, bounds): ITouchPointType => {
    // We have to adjust for canvas positioning. And we subtract by a whole integer
    // using Math.trunc
    const x = pageX - Math.trunc(bounds.left);
    const y = pageY - Math.trunc(bounds.top);

    return { identifier, pageX: x, pageY: y };
  };

  // Called when user/borrower first makes contact with the canvas
  // either with mouse or with finger. Logic is slightly different for mouse vs finger
  // as their event objects are different.
  const handleStart = (e) => {
    setIndentType(morphIndentType.FormClickToClear);

    // Grab the dimensions of the canvas and where it actually begins on the 'X' and 'Y'
    // Axis relative to the screen
    const bounds = e.target.getBoundingClientRect();
    const canvasCTX = canvasRef.current.getContext('2d');

    if (e.type === 'mousedown') {
      // User has held down the mouse active
      mouseDrawingPress.current = true;

      // Grabs the initial position where the cursor is placed on the canvas
      // Used later for when user starts drawing
      boundaryFinder(e, bounds);

      position.current = {
        x: e.clientX - Math.trunc(bounds.left),
        y: e.clientY - Math.trunc(bounds.top),
      };

      // Every draw function needs to begin with beginPath()
      canvasCTX.beginPath();
      // This allows the user to drop dots when they click/tap once on the canvas for when
      // They try to put dots on their 'i's or accents.
      canvasCTX.arc(position.current.x, position.current.y, 4, 0, 2 * Math.PI, false);

      // Determines line color
      canvasCTX.fillStyle = '#000000';

      // Actually fills in the stroke/dot
      canvasCTX.fill();

      // canvasCTX.canvas.width  = window.innerWidth;
      // canvasCTX.canvas.height = window.innerHeight;
    } else {
      // Grabs all contact points from fingers on the canvas
      const touches = e.changedTouches;
      // Similar to above function, but we run it for each touch origin point
      for (let i = 0; i < touches.length; i++) {
        boundaryFinder(e, bounds, i);

        canvasCTX.beginPath();
        canvasCTX.arc(
          touches[i].clientX - Math.trunc(bounds.left),
          touches[i].clientY - Math.trunc(bounds.top),
          4,
          0,
          2 * Math.PI,
          false
        );
        // Used to set the inital starting point for the draw function. In this case
        // we use an array, since there are potentially multiple contact points
        ongoingTouches.current.push(copyTouch(touches[i], bounds));

        // Determines line color
        canvasCTX.fillStyle = '#000000';

        canvasCTX.fill();
      }
    }
  };

  // Called when the user starts moving their mouse/finger while
  // mouse is pressed/finger has contact and is drawing on the canvas.
  const handleMove = (e) => {
    // Grab the dimensions of the canvas and where it actually begins on the 'X' and 'Y'
    // Axis relative to the screen
    const bounds = e.target.getBoundingClientRect();
    const canvasCTX = canvasRef.current.getContext('2d');

    if (e.type === 'mousemove' && mouseDrawingPress.current) {
      // console.log('e.pageX: ', e.pageX, '\te.pageY: ', e.pageY);
      boundaryFinder(e, bounds);
      // Every draw function needs to begin with beginPath()
      canvasCTX.beginPath();
      // Original starting coordinates
      canvasCTX.moveTo(position.current.x, position.current.y);
      // Connects original starting coordinates to new coordinate
      canvasCTX.lineTo(e.pageX - Math.trunc(bounds.left), e.clientY - Math.trunc(bounds.top));
      // Controls line thickness
      canvasCTX.lineWidth = 4;
      // Determines line color
      canvasCTX.strokeStyle = '#000000';
      // Draws the line
      canvasCTX.stroke();

      // Update new starting coordinate to new point
      position.current = {
        x: e.clientX - Math.trunc(bounds.left),
        y: e.clientY - Math.trunc(bounds.top),
      };
    } else if (e.type !== 'mousemove') {
      // Grabs all current finger contact points
      const touches = e.changedTouches;
      for (let i = 0; i < touches.length; i++) {
        const index = ongoingTouches.current.findIndex((x) => x.identifier === touches[i].identifier);

        if (index >= 0) {
          boundaryFinder(e, bounds, i);
          // Draw functions begin with beginPath()
          canvasCTX.beginPath();
          // Use the original starting coordinate for this particular 'touch'
          canvasCTX.moveTo(ongoingTouches.current[index].pageX, ongoingTouches.current[index].pageY);
          // Draws a line from the starting location of this 'touch' to the new
          // coordinate
          canvasCTX.lineTo(touches[i].pageX - Math.trunc(bounds.left), touches[i].pageY - Math.trunc(bounds.top));
          // Determines line size
          canvasCTX.lineWidth = 4;
          // Determines line color
          canvasCTX.strokeStyle = '#000000';
          // Draws the line
          canvasCTX.stroke();

          ongoingTouches.current.splice(index, 1, copyTouch(touches[i], bounds));
        } else {
          console.log('cannot determine which touch to continue');
        }
      }
    }
  };

  // Called when user lifts their finger off of the mouse/finger off of screen.
  const handleEnd = (e) => {
    e.preventDefault();

    const bounds = e.target.getBoundingClientRect();
    const canvasCTX = canvasRef.current.getContext('2d');

    if (e.type === 'mouseup') {
      mouseDrawingPress.current = false;
      boundaryFinder(e, bounds);
    } else {
      const touches = e.changedTouches;

      for (let i = 0; i < touches.length; i++) {
        const index = ongoingTouches.current.findIndex((x) => x.identifier === touches[i].identifier);

        if (index >= 0) {
          boundaryFinder(e, bounds, i);
          // Every draw function needs to begin with beginPath()
          canvasCTX.beginPath();
          // Original starting coordinates
          canvasCTX.moveTo(ongoingTouches.current[index].pageX, ongoingTouches.current[index].pageY);
          // Connects original starting coordinates to new coordinate
          canvasCTX.lineTo(touches[i].pageX - Math.trunc(bounds.left), touches[i].pageY - Math.trunc(bounds.top));
          // Controls line thickness
          canvasCTX.lineWidth = 4;
          // Determines line color
          canvasCTX.strokeStyle = '#000000';
          // Draws the line
          canvasCTX.stroke();

          // Removes the 'touch' for the finger that comes off
          ongoingTouches.current.splice(index, 1);
        } else {
          console.log('cannot determine which touch to end');
        }
      }
      // ALl fingers have come off, so reset to empty array
      ongoingTouches.current = [];
    }
  };

  const resetBoundary = (type: EsignSignatureType) => {
    // reset boudnary to default value
    canvasBoundaryRef.current[type] = {
      x: {
        min: Infinity,
        max: -Infinity,
      },
      y: {
        min: Infinity,
        max: -Infinity,
      },
    };
  };

  /**
   * Transform light gray color to white
   * @param data canvas color array
   */
  const filterCanvas = (data) => {
    // Filter out black and white, convert the rest to white
    for (let i = 0; i < data.length; i += 4) {
      let red = data[i];
      let green = data[i + 1];
      let blue = data[i + 2];

      // Check if the pixel color is close to the target color within the tolerance level
      if (
        Math.abs(red - LIGHT_GRAY.red) <= FILTER_TOLERANCE &&
        Math.abs(green - LIGHT_GRAY.green) <= FILTER_TOLERANCE &&
        Math.abs(blue - LIGHT_GRAY.blue) <= FILTER_TOLERANCE
      ) {
        // Set the pixel to white (R, G, and B all equal to 255)
        data[i] = 0;
        data[i + 1] = 0;
        data[i + 2] = 0;
        data[i + 3] = 0;
      }
    }
  };

  /**
   *
   * @param type EsignSignatureType Signature or Initials, draws watermark on canvas
   * @returns
   */
  const drawWaterMark = (latestType: EsignSignatureType) => {
    if (canvasRef.current === null || canvasRef.current === undefined) {
      return;
    }

    const canvasCTX = canvasRef.current.getContext('2d');

    if (canvasCTX === null || canvasCTX === undefined) return;

    canvasCTX.font = '28px Arial';
    canvasCTX.fillStyle = 'lightGray';
    let text;

    if (latestType === EsignSignatureType.Signature) text = 'Draw Signature';
    else if (latestType === EsignSignatureType.Initials) text = 'Draw Initials';
    else text = 'wrong signatureTypeRef';

    const textHeight = canvasCTX.measureText(text);

    // static vs relative html component different
    if (errandContext.formBody === FormBodyType.CreateSignatureMobile) {
      const frameSize = canvasFrameRef.current.getBoundingClientRect();

      canvasCTX.fillText(
        text,
        outerFrameSize,
        frameSize.height - frameSize.top - textHeight.fontBoundingBoxDescent - outerFrameSize
      );
    } else {
      canvasCTX.fillText(
        text,
        outerFrameSize,
        canvasRef.current.height - textHeight.fontBoundingBoxDescent - outerFrameSize
      );
    }
  };

  // If user is unsatisfied with their signature, they can clear the
  // Canvas and make a signature with a clean canvas again.
  const clear = (type: EsignSignatureType) => {
    const canvasCTX = canvasRef.current.getContext('2d');

    if (canvasCTX === null || canvasCTX === undefined) return;

    canvasCTX.clearRect(0, 0, canvasCTX.canvas.width, canvasCTX.canvas.height);

    resetBoundary(type);

    if (type === EsignSignatureType.Signature) errandContext.setWetSignature(undefined);
    else if (type === EsignSignatureType.Initials) errandContext.setWetInitial(undefined);
    else console.error('wrong signatureTypeRef');
  };

  // Function that will export signature to image in either type:
  // (image/png) or (image/jpeg) or (image/webp) and we can upload to
  // needed location after
  const save = (type: EsignSignatureType, restore: boolean) => {
    const croppedSignature = autoCrop(type);

    if (type === EsignSignatureType.Signature) {
      errandContext.setWetSignature(croppedSignature);
    } else if (type === EsignSignatureType.Initials) {
      errandContext.setWetInitial(croppedSignature);
    }

    if (restore) {
      restoreSign(type, croppedSignature);
    }
  };

  // Utility function to constantly update the x and y with min/max values
  const boundaryFinder = (e, bounds, index = -1) => {
    const desktopCommand = e.type.slice(0, 5);
    let lastActionRef = undefined;

    if (index >= 0 && desktopCommand === 'touch') {
      if (e.touches.length > 0) {
        lastActionRef = e.touches[index];
      }
    } else {
      lastActionRef = e;
    }

    if (lastActionRef) {
      const coordinateX = lastActionRef.clientX - Math.trunc(bounds.left);
      const coordinateY = lastActionRef.clientY - Math.trunc(bounds.top);

      const boundary = canvasBoundaryRef.current[signatureTypeRef.current];

      canvasBoundaryRef.current[signatureTypeRef.current] = {
        x: {
          min: Math.min(boundary.x.min, coordinateX),
          max: Math.max(boundary.x.max, coordinateX),
        },
        y: {
          min: Math.min(boundary.y.min, coordinateY),
          max: Math.max(boundary.y.max, coordinateY),
        },
      };

      // Following code will stop continuous drawing when mouse is out of canvas
      // if (
      //   canvasCTX.canvas.clientHeight - coordinateY < 5 ||
      //   canvasCTX.canvas.clientWidth - coordinateX < 5 ||
      //   coordinateX < 5 ||
      //   coordinateY < 5
      // ) {
      //   mouseDrawingPress.current = false;
      // }
    }
  };

  // Utility function to crop the white space surrounding the signature/initial
  // Does two things:
  //      1. This reduces the amount of memory space utilized (up to 75% in some extreme cases)
  //      2. Makes it easier to position signature/initial on form later, since we won't need
  //         to worry about variable white space size from different users.
  const autoCrop = (type: EsignSignatureType) => {
    const canvasCTX = canvasRef.current.getContext('2d');

    const boundary = canvasBoundaryRef.current[type];

    if (boundary.x.min === Infinity) return undefined;

    let canvas = canvasCTX.canvas;
    let height = canvas.clientHeight;
    let width = canvas.clientWidth;

    let cut = canvasCTX.getImageData(
      boundary.x.min < outerFrameSize ? 0 : boundary.x.min - outerFrameSize,
      boundary.y.min < outerFrameSize ? 0 : boundary.y.min - outerFrameSize,
      width - boundary.x.max < outerFrameSize ? width : boundary.x.max + outerFrameSize,
      height - boundary.y.max < outerFrameSize ? height : boundary.y.max + outerFrameSize
    );

    filterCanvas(cut.data);

    canvas.width = boundary.x.max - boundary.x.min + outerFrameSize * 2;
    canvas.height = boundary.y.max - boundary.y.min + outerFrameSize * 2;

    // Update the canvas with the modified image data
    canvasCTX.putImageData(cut, 0, 0);

    const croppedSignature = canvas.toDataURL('image/png');

    // restore original size
    canvas.height = height;
    canvas.width = width;

    return croppedSignature;
  };

  /**
   * If file string is defined, restore it from file string
   * if file string is not defined, try to restore it from parent component
   */
  const restoreSign = (type: EsignSignatureType, file: string) => {
    const canvasCTX = canvasRef.current.getContext('2d');

    let image = new Image();

    if (file === undefined) {
      // restore from parent component
      if (type === EsignSignatureType.Signature) image.src = errandContext.wetSignature;
      else if (type === EsignSignatureType.Initials) image.src = errandContext.wetInitial;
    } else {
      // restore from input file
      image.src = file;
    }

    canvasCTX.clearRect(0, 0, canvasCTX.canvas.width, canvasCTX.canvas.height);
    drawWaterMark(type);

    image.onload = function () {
      // Calculate the position to center the image
      const x = Math.ceil((canvasCTX.canvas.clientWidth - image.width) / 2);
      const y = Math.ceil((canvasCTX.canvas.clientHeight - image.height) / 2);

      // Update boundary based on image
      canvasBoundaryRef.current[type] = {
        x: {
          min: x + outerFrameSize,
          max: x - outerFrameSize + image.width,
        },
        y: {
          min: y + outerFrameSize,
          max: y - outerFrameSize + image.height,
        }
      }

      // draw image on canvas
      canvasCTX.drawImage(image, x, y);
    };
  };

  // Re-draws canvas according to latest view size
  const handleResize = () => {
    if (!canvasFrameRef.current) return;

    const canvas = canvasRef.current;
    canvas.height = canvasFrameRef.current.getBoundingClientRect().height;
    canvas.width = canvasFrameRef.current.getBoundingClientRect().width;

    restoreSign(signatureTypeRef.current, undefined);
  };

  // Debounced save
  useDebounce(
    () => {
      save(signatureTypeRef.current, true);
      // restore(signatureTypeRef.current);
    },
    200,
    [saveCounter]
  );

  // Following function will get triggered at page change
  useEffect(() => {
    const canvasCTX = canvasRef.current.getContext('2d');

    if (canvasCTX === null || canvasCTX === undefined) {
      return;
    }

    // If tab changes didn't occur ignore
    if (lastActiveTab.current === signatureTypeRef.current) {
      return;
    }

    // save current canvas info
    save(lastActiveTab.current, false);

    // restore new tab info
    restoreSign(signatureTypeRef.current, undefined);

    // update last active tab
    lastActiveTab.current = signatureTypeRef.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureTypeRef.current]);

  const handleResizeWrapper = useCallback(
    handleResize,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errandContext.wetInitial, errandContext.wetSignature]
  );

  useWindowDimensions(handleResizeWrapper);

  const ClearClicked = () => {
    clear(signatureTypeRef.current);
    drawWaterMark(signatureTypeRef.current);
  };

  useEffect(() => {
    // handle resize for mobile
    if (errandContext.formBody === FormBodyType.CreateSignatureMobile) {
      setTimeout(() => {
        handleResize();
      }, 600);
    } else {
      handleResize();
    }

    // subscribe event bus, we will subscribe to it only after canvasCTX is set
    // There were weird observation that it will subsribe to event bus when canvasCTX is null and
    // setting it later doesn't change null
    eventBus.on(FormClickEventType.SigAutoGen, generateAuto);
    eventBus.on(FormClickEventType.SigClear, ClearClicked);

    return () => {
      eventBus.remove(FormClickEventType.SigAutoGen, generateAuto);
      eventBus.remove(FormClickEventType.SigClear, ClearClicked);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={canvasFrameRef}
      style={{
        userSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width:
          errandContext.formBody === FormBodyType.CreateSignatureMobile ? '100%' : `calc(100% - ${btnGroupWidth}px)`,
        height: '100%',
        minHeight: '100px',
      }}
    >
      <canvas
        style={{
          background: 'var(--gray000)',
          position: errandContext.formBody === FormBodyType.CreateSignatureMobile ? 'static' : 'relative',
          left: '5px',
          touchAction: 'none',
          borderRadius: '8px'
        }}
        id="Canvas"
        ref={canvasRef}
        height={100}
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
      />
    </div>
  );
};

export default ESign;
