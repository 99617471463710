/**
 * The splash screen that loads on the index.
 * It is vital that mobile resolutions are taken into account.
 * All of the sizing is dependent on the vertical resolution except for the header.
 * In order to have the menu button and logo size correctly horizontal sizing is used
 */

// external
import React, { useCallback, useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { MorganTheme, useTheme } from '@mui/material';

// components
import LanguagePickerGlobe from '@components/LanguagePickerGlobe';
import Patents from '@components/Patents';
import { Terms } from '@components/Splash/Terms';
import { UserWayAccessibilityMenu } from '@components/UserWayAccessibilityMenu';
import { AngelAiLogo } from '@components/Splash/AngelAiLogo';
import { ErrorMessage } from '@components/Splash/ErrorMessage';
import { TagLine } from '@components/Splash/TagLine';
import Film from '@components/Splash/Film';

// other
import { useCaptchaContext } from '@contexts/captcha';
import { Engage, prepareEngage } from '@common/EngageUtils';
import { SplashStyles } from '@components/Splash/SplashStyle';
import Styles from '@styles/Splash.module.css';
import useInitialMount from '@common/hooks/useInitialMount';

const blurBg = process.env.REACT_APP_MORGAN_CDN + '/splash/landscapes/blur.jpg'
const WhiteBoxWithCurve = process.env.REACT_APP_MORGAN_CDN + '/Images/WhiteBoxWithCurve.svg';
const WhiteBoxLessCurve = process.env.REACT_APP_MORGAN_CDN + '/Images/WhiteBoxLessCurve.svg';

export const Splash = () => {
  // hooks
  const theme: MorganTheme = useTheme();
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { hashkey } = useParams();
  const [disableEngage, setDisableEngage] = useState(location.state?.disableEngage || false);
  const [disableEngageReason, setDisableEngageReason] = useState(location.state?.disableEngageReason || '');
  const [systemMessage, setSystemMessage] = useState(location.state?.systemMessage || '');
  const captchaContext = useCaptchaContext();
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const checkEngageRef = useRef({
    disableEngage: location.state?.disableEngage || false,
    disableEngageReason: location.state?.disableEngageReason || '',
    systemMessage: location.state?.systemMessage || '',
  });

  // Update background image's resolution based on the window size
  const getScreenSize = () => {
    if (window.innerWidth / window.innerHeight < 9 / 16) {
      return 'portrait';
    } else if (window.innerWidth < 900) {
      return 'small';
    } else if (window.innerWidth >= 900 && window.innerWidth < 1900) {
      return 'medium';
    } else {
      return 'large';
    }
  };

  const screenSize = getScreenSize();
  // Update background image to a random image from that selected country
  const NUM_OF_IMAGES = 10 //number of images for each country uploaded to CDN
  const [country, setCountry] = useState('us')
  const [landscapeImg, setLandscapeImg] = useState(process.env.REACT_APP_MORGAN_CDN + '/splash/landscapes/' + country + 4 + '_' + screenSize + '.jpg')
  useEffect(() => {
    let newCountry:string;
    if (i18n.resolvedLanguage === 'en') {
      newCountry = 'us'
    } else if (i18n.resolvedLanguage === 'ko') {
      newCountry = 'southKorea'
    } else if (i18n.resolvedLanguage === 'es') {
      newCountry = 'puertoRico'
    } else {
        return
    }
    const randomNum = Math.ceil(Math.random() * NUM_OF_IMAGES)
    const newLandscapeImg = process.env.REACT_APP_MORGAN_CDN + '/splash/landscapes/' + newCountry + randomNum + '_' + screenSize + '.jpg'
    setCountry(newCountry)
    setLandscapeImg(newLandscapeImg)

    // This is listening to make sure that the background image is fully loaded before setting isLoading to false
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && imageRef.current) {
          if (imageRef.current.complete) {
            setIsLoading(false);
            observer.unobserve(entry.target);
          } else {
            imageRef.current.addEventListener('load', handleImageLoad);
          }
        }
      });
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [i18n.resolvedLanguage, screenSize])

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  
  const checkEngage = useCallback(async () => {
    const ref = checkEngageRef.current;
    let lenderCompany = theme.system.name;
    let res = await prepareEngage(ref.disableEngage, ref.disableEngageReason, ref.systemMessage, lenderCompany, t);
    if (!res) return;
    if (ref.disableEngage === res.disableEngage && ref.disableEngageReason === res.disableEngageReason || ref.systemMessage === res.systemMessage) return;
    checkEngageRef.current = res;
    if (res.disableEngage !== disableEngage) {
      setDisableEngage(res.disableEngage);
    }
    if (res.disableEngageReason !== disableEngageReason) {
      setDisableEngageReason(res.disableEngageReason);
    }
    if (res.systemMessage !== systemMessage) {
      setSystemMessage(res.systemMessage);
    }
  }, [theme.system.name]);

  useInitialMount(checkEngage);

  const onCaptchaResult = useCallback((passed) => {
    if (!passed) return;
    const isDisabledByCaptcha = !checkEngageRef.current.disableEngageReason || checkEngageRef.current.disableEngageReason === 'Normal' || checkEngageRef.current.disableEngageReason  === 'Captcha';
    if (isDisabledByCaptcha || !checkEngageRef.current.disableEngage) {
      captchaContext.resetCaptcha();
      Engage(hashkey, navigate);
    }
  }, [disableEngageReason, hashkey, navigate]);

  // variables
  const themeLogoURL = process.env.REACT_APP_MORGAN_CDN + '/themes/Images/' + theme.system.name + '/logo.svg';

  // jsx
  return (
    <SplashStyles>
      {/* wraps all the content to put the gradient in the background */}
    <div className='landscapeBg' ref={containerRef} style={{
      backgroundImage: `url(${landscapeImg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}>
    {/* Create an image with display of none to indicate when the background image has loaded because background images
    do not have a 'load' event like images do to indicate when it is done loading. This will let us know that the loading 
    has finished and we can show the background image now */}
      <img src={landscapeImg}
        ref={imageRef}
        width='100%'
        height='100%'
        style={{
          display: 'none'
        }}
      />
      <div className='blurContainer' style={{
        backgroundImage: `url(${blurBg})`,
        opacity: isLoading ? '1' : '0',
        transition: 'opacity 0.5s ease',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
      }} />
      <div className='splashContainer' >
        {/* flex box that contains all the page content, allows vertical scroll only */}
        <div className='contentContainer' >
          {/* header that holds the logo and menu button, no padding on the bottom, too much with the animation, uses a grid for spacing */}
          <header
            style={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '40px auto 60px',
              alignItems: 'center',
              justifyItems: 'center',
              marginTop: '10px'
            }}
          >
            {/* needs an empty div for spacing */}
            <div />
            <div />
            <UserWayAccessibilityMenu color="orange" height={40} />
          </header>

          <div className='globeWrapper globeScale'>
            <LanguagePickerGlobe setIsLoading={setIsLoading} />
          </div>
          {/* Adding an additional box to contain the whiteBox and film helps to maintain margin between the two */}
          <div className='boxFilmContainer'>
            {/* // this is the container with the white background */}
            <div className={`whiteBox white3 ${theme.system.hasBrandMark ? 'whiteTheme2' : ''}`} style={{ backgroundImage: `url(${WhiteBoxLessCurve})` }}/>
              <div className={`whiteBox white2 ${theme.system.hasBrandMark ? 'whiteTheme' : ''}`} style={{ backgroundImage: `url(${WhiteBoxLessCurve})` }} />
            <div className='whiteBox' style={{backgroundImage: `url(${WhiteBoxWithCurve})`}}>
              {/* brand logo for licensed use, example on /?company=USMRBK&branch=000 */}
              {theme.system.hasBrandMark && (
                  <img
                    className='themeLogo'
                    src={themeLogoURL} alt="" />
                )}
              <div className={`logoWrapper ${theme.system.hasBrandMark ? 'logoWrapperTheme' : ''}`}>
                <AngelAiLogo />
              </div>
              <TagLine>{t('tagline')}</TagLine>
              {(disableEngage || systemMessage) && <ErrorMessage>{systemMessage}</ErrorMessage>}
              <Terms>
                <Trans i18nKey={theme.termsAndConditions.termsTextKey}>
                  terms
                  <a target="_blank" href={theme.termsAndConditions.termsConditionsURL} rel="noreferrer">
                    Terms and Conditions
                  </a>
                  <a target="_blank" href={theme.termsAndConditions.termsPrivacyPolicyURL} rel="noreferrer">
                    Security and Privacy Policy
                  </a>
                  <a target="_blank" href="https://www.celligence.com/terms" rel="noreferrer">
                    Terms and Conditions
                  </a>
                  <a target="_blank" href="https://privacy.celligence.com" rel="noreferrer">
                    Security and Privacy Policy
                  </a>
                </Trans>
              </Terms>
              <button className={Styles.engageButton} style={{ cursor: disableEngage && 'not-allowed' }} aria-hidden aria-label={t('continue')} onClick={() => {
                checkEngage();
                captchaContext.testCaptcha('engage', onCaptchaResult);
              }}>
                <div className={Styles.engageText}>{t('continue')}</div>
                <div className={Styles.engageContainer}>
                  <div className={Styles.engageTop}/>
                  <div className={Styles.engageBottom}/>
                </div>
              </button>
            </div>
            <Film />
          </div>
              <Patents />
          </div>
        </div>
      </div>
    </SplashStyles>
  );
};
