import React from 'react';
import getImageSource from "@common/getImageSource";
import { IconStyles } from "@components/ActivityTracker/Constants";

export const DefaultIcon = (props: { alt?: string; icon?: string; skipExtractSource?: boolean; imgConfig?: {width: string} }) => {
  return (
    <img
      style={props.imgConfig ? {...IconStyles.default, ...props.imgConfig} : {...IconStyles.svg}}
      alt={props.alt || 'icon'}
      src={props.skipExtractSource ? props.icon : getImageSource(props.icon)}
    />
  );
};
