import { Snackbar, Tooltip, Typography } from '@mui/material';
import { CameraIcon, PlayButton, SongPlayerPauseWhite } from '@assets/Icons';
import React, { useCallback, useState, useMemo } from 'react';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';
import {
  BrokenImageContainer,
  LargeSoundWave,
  MessageContent,
  PlaySongButton,
  RecordHolder,
  SongArtist,
  SongHeader,
  SongTitle,
  SmallSoundWave,
  ThumbnailHolder
} from '@styles/SongMessageContentStyle';
import { IErrand, IMessage } from '@interfaces/Conversation';
import useWindowDimensions from '@common/hooks/useWindowDimensions'
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { useQuery } from '@tanstack/react-query';
const Vinyl = process.env.REACT_APP_MORGAN_CDN + '/Images/Vinyl.png';

type TSongMessageContentProps = {
  errand: IErrand;
  message: IMessage;
}

const SongMessageContent = ({
  errand, message, 
}: TSongMessageContentProps) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const [isWarningActive, setIsWarningActive] = useState(false);
  const response = useQuery([message?.message], async ({ signal }) => {
    try {
      if (!videoId) return;
      //Hit Morgan-Core using the videoId to grab needed YouTube info
      let response = await axiosCall({
        url: `url/youtube`,
        method: 'post',
        data: {
          videoId: videoId,
        },
      }, { signal });
      if (!response.youtubeData.items.length) {
        // If we get an error, we reset song data
        return {
          id: '',
          artist: '',
          title: '',
          thumbnail: '',
          fullTitle: '',
          splitTitle: false,
          url: ''
        }
      }
      let videoTitle = response.youtubeData.items[0].snippet.title;
      // Make sure there isn't a dash in the title or artist name, as
      // it will interfer with the artist, title separation logic.
      let splitTitle = videoTitle?.split(' - ')?.length <= 2;
      //Set needed data to state.
      return {
        id: videoId,
        fullTitle: videoTitle,
        title: splitTitle ? videoTitle.split(' - ')?.[1]?.toUpperCase() : videoTitle?.toUpperCase(),
        artist: `${videoTitle?.toUpperCase() ? videoTitle.split(' - ')[0]?.toUpperCase() : ''}`,
        thumbnail: response.youtubeData.items[0].snippet.thumbnails.default.url,
        splitTitle,
        url: `https://www.youtube.com/watch?v=${message?.message}`
      }
    } catch (err) {
      // If we get an error, we reset song data
      return {
        id: '',
        artist: '',
        title: '',
        thumbnail: '',
        fullTitle: '',
        splitTitle: false,
        url: ''
      }
    }
  });
  // State values to ensure rerenders to the jsx for when the screen is resized.
  const { innerWidth } = useWindowDimensions();

  const videoId = useMemo(() => message?.message, [message?.message]);

  const belowSmallBreakpoint = useMemo(() => {
    return innerWidth < 600;
  }, [innerWidth]);

  const belowMediumBreakpoint = useMemo(() => {
    return innerWidth < 900;
  }, [innerWidth]);

  // Set player as open, and song data in conversation.js
  const openAndSetSongPlayer = useCallback((e) => {
    e?.stopPropagation();
    if (!response?.data?.id || message.operatorView) {
      return;
    }
    if (window.innerHeight < 600) {
      return window.open(response?.data?.url, "_blank")
    } else {
      if (response?.data?.id === errandContext.songPlayerData?.id) {
        errandContext.setSongPlayerData((prev) => {
          return { ...prev, pause: !prev.pause };
        });
      } else {
        errandContext.setSongPlayerData((prev) => { return {...prev, ...response.data, show: true} });
        errandContext.setMorphType(MorphType.SongOfTheDay);
      }
    }
  }, [response?.data?.id, message.operatorView, errand._id, errandContext.songPlayerData?.id]);

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        open={isWarningActive ? true : false}
        onClose={() => setIsWarningActive(null)}
        message={isWarningActive}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ position: 'absolute', top: '0px' }}
      />
      <MessageContent
        smallScreen={belowSmallBreakpoint}
        mediumScreen={belowMediumBreakpoint}
      >
        <div className='chatBubbleStyle'>
          <div className='chatStack'>
            <div className='dialog'>
              <div className='recordSection'>
                <RecordHolder
                  smallScreen={belowSmallBreakpoint}
                >
                  <img className='record' src={Vinyl} alt={'Vinyl Record'} />
                  <div className='recordArm' />
                  <div className='recordNeedle' />
                </RecordHolder>
              </div>
              <div className='songDetails'>
                <div className='songDetailsStack'>
                  <SongHeader>Song of the day</SongHeader>
                  <SongTitle>{response?.data?.title}</SongTitle>
                  <SongArtist>{response?.data?.artist}</SongArtist>
                  <div className='playSongContainer' onClick={openAndSetSongPlayer}>
                    <PlaySongButton>
                      {errandContext.songPlayerData?.id === response?.data?.id 
                        ? errandContext.songPlayerData?.pause 
                          ? t('playSong')
                          : t('pauseSong')
                        : t('playSong')}
                    </PlaySongButton>
                    <SmallSoundWave />
                    <LargeSoundWave />
                  </div>
                </div>
              </div>
              <ThumbnailHolder
                onClick={openAndSetSongPlayer}
                smallScreen={belowSmallBreakpoint}
                thumbnail={response?.data?.thumbnail}>
                {response?.data?.thumbnail ? (
                  errandContext.songPlayerData?.id === response?.data?.id ?
                    errandContext.songPlayerData?.pause ?
                      <PlayButton />
                      :
                      <SongPlayerPauseWhite />
                    :
                    <PlayButton />
                ) : (
                  <BrokenImageContainer>
                    <CameraIcon />
                  </BrokenImageContainer>
                )}
              </ThumbnailHolder>
            </div>
            <div className='urlStack'>
              <Tooltip title={t('copyToClipboard')}>
                <ContentCopyRoundedIcon
                  className='contentCopyRoundedIcon'
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(response?.data?.url);
                    setIsWarningActive(t('copiedToClipboard'));
                  }}
                />
              </Tooltip>
              {response?.data?.url && (
                <a
                  className='urlAnchor'
                  href={response?.data?.url?.indexOf('http') === 0 ? response?.data?.url : `//${response?.data?.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography variant="subtitle1" color="var(--gray000)">
                    {response?.data?.url}
                  </Typography>
                </a>
              )}
            </div>
          </div>
        </div>
      </MessageContent>
    </>
  );
};

export default SongMessageContent;
