import { isActionPasswordRelated } from "@common/msgUtils";

import type { IMessage } from "@interfaces/Conversation";

type ParseHiddenMessage = (messgae: IMessage) => string | null;

const parsePasswordMessage = (edited: boolean) => edited 
  ? '******** <i class="messageArrow"/> ********' 
  : '********';
  
const parseLast4SSNMessage = (edited: boolean) => edited 
  ? '**** <i class="messageArrow"/> ****' 
  : '****';
  
const parseSSNMessage = (edited: boolean) => edited
  ? '***-**-**** <i class="messageArrow"/> ***-**-****' 
  : '***-**-****';

const parseHiddenMessage: ParseHiddenMessage = (message) => {
  if (!message || !message.action || !message.action.fieldName) return '';
  const edited = message.messageStatus === 'edited';
  const fieldName = message.action.fieldName?.toLowerCase();

  if (isActionPasswordRelated(message.action)) return parsePasswordMessage(edited);

  const hiddenFieldNames = {
    password: parsePasswordMessage,
    newpassword: parsePasswordMessage,
    confirmpassword: parsePasswordMessage,
    brwrlast4ssn: parseLast4SSNMessage,
    borrowerssn: parseSSNMessage,
    userssn: parseSSNMessage,
    coborrowerssn: parseSSNMessage,
  }

  if (hiddenFieldNames[fieldName]) return hiddenFieldNames[fieldName](edited) as string
  return null;
}

export default parseHiddenMessage;