/**
 * @file This component is a stylized iframe
 * used to render embedded live stream youtube videos
 * for live streams.
 *
 * Extracted from ConversationMessage.js 10/11/2022
 *
 * @author Harrison Fales <harrison.fales@swmc.com>
 */
import React from 'react';

import ReactPlayer from 'react-player/youtube';

const YoutubeFrame = (props) => {

  return props.embedUrl ? (
    <div
      style={{
        padding: '5px 15px 15px 15px',
        backgroundColor: 'var(--gray000)',
        boxShadow: 'var(--shadow100) 0px 5px 35px',
        border: '1px solid var(--orange700)',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '18px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          textAlign: 'center',
          fontSize: '12px',
        }}
      >
        <span style={{ fontSize: '12px' }}>{props.title}</span>
        {props.actualStartTime !== undefined ? (
          <span style={{ color: 'var(--gray400)' }}>Stream Began: {new Date(props.actualStartTime).toLocaleString()}</span>
        ) : (
          <span style={{ color: 'var(--gray400)' }}>
            Stream Will Begin: {new Date(props.scheduledStartTime).toLocaleString()}
          </span>
        )}
      </div>
      <ReactPlayer
        id={`ytplayer-${props.messageId}`}
        title={props.title}
        url={`https://www.youtube.com/watch?v=${props.videoId}`}
        onReady={props.onReady}
        height='200px'
        width={props.isDesktop ? '350px' : '100%'}
        controls={true}
        config={{
          youtube: {
            playerVars: {
              showinfo: true,
              autoplay: true,
              enablejsapi: true,
              fs: true,
              modestbranding: false
            }
          }
        }}
      />
    </div>
  ) : null;
};

export default YoutubeFrame; 