import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IErrand, IMessage } from '@interfaces/Conversation';
import { Sentiments } from '@mTypes/TSentiment';
import { useSocketContext } from '@contexts/socket';
import SentimentEmoji from '@components/SentimentEmoji';

import Styles from '@styles/MorphMessageOptions.module.css';

type TMorphMessageOptionSentimentProps = {
  errand: IErrand;
  handleClose: () => void;
  message: IMessage;
};

const MorphMessageOptionSentiment = ({
  errand, handleClose, message
}: TMorphMessageOptionSentimentProps) => {
  const { t } = useTranslation();
  const { isMessagesConnected, messagesSocket } = useSocketContext();

  const handleClickSentiment = useCallback(() => {
    messagesSocket.current?.emit('emit-chat-event', {
      chatId: errand._id,
      data: {
        type: "show_sentiment",
      },
      recipients: [message.userId],
    });
    handleClose();
  }, [errand?._id, message?.userId, handleClose]);

  return isMessagesConnected && (
    <button className={Styles.btn} onClick={handleClickSentiment}>
      <div className={Styles.btnBorder}>
        <SentimentEmoji sentiment={Sentiments[message?.sentiment]} regularEmoji={true} />
        <p className={Styles.label}>{t(['Negative', 'Positive'].indexOf(message?.sentiment) === -1 ? 'tNeutral' : `t${message.sentiment.charAt(0).toUpperCase() + message.sentiment.slice(1).toLowerCase()}`)}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionSentiment;
