import { IBrowserNotification } from '@interfaces/Conversation';

const sendBrowserNotification = (data: IBrowserNotification) => {
  try {
    switch (data?.notificationType) {
      case 'unattended':
        const notification = new Notification(data?.title, {
          body: data?.message || '',
          silent: true,
          icon: '/favicon.ico',
        });
        const newUserAudio = new Audio(process.env.REACT_APP_MORGAN_CDN + '/sound/newuser.mp3');
        newUserAudio?.play();
        notification.onclick = () => {
          if (typeof data?.callback === 'function') {
            data?.callback(data);
          }
          notification.close();
          window.parent.focus();
        };
        break;
      default:
        if (document.visibilityState === 'hidden') {
          const notification = new Notification(data?.title, {
            body: data?.message || '',
            silent: true,
            icon: '/favicon.ico',
          });
          const newMessageAudio = new Audio(process.env.REACT_APP_MORGAN_CDN + '/sound/notification.mp3');
          newMessageAudio?.play();
          notification.onclick = () => {
            if (typeof data?.callback === 'function') {
              data?.callback(data);
            }
            notification.close();
            window.parent.focus();
          };
        }
        break;
    }
  } catch (e) {
    console.error(`Error ${e} was found while trying to send browser notifications!`);
  }
};

/**
 * Function for firing browser notifications and sound effects
 */
const triggerBrowserNotificationAlert = (data: IBrowserNotification) => {
  try {
    if (!('Notification' in window)) {
      return;
    } else if (Notification.permission === 'granted') {
      if (document.visibilityState === 'hidden') {
        sendBrowserNotification(data);
      }
    } else if (Notification.permission !== 'denied') {
      if (document.visibilityState === 'hidden') {
        Notification.requestPermission().then(() => {
          if (Notification.permission === 'granted' && document.visibilityState === 'hidden') {
            sendBrowserNotification(data);
          }
        });
      }
    }
  } catch (e) {
    console.error(`Error ${e} was found while trying to trigger browser notifications!`);
  }
};

export default triggerBrowserNotificationAlert;
