import { Card, Tab, MorganTheme, useTheme } from '@mui/material';
import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ConsoleConversation from './ConsoleConversation';
import ConversationsList from './ConversationsList';
import SMSEmailComposer from './SMSEmailComposer';
import ErrorBoundary from '@components/ErrorBoundary';
import { CustomTabs, Style } from '@styles/ConsoleConversationWrapperStyles';
import { useRootContext } from '@contexts/RootContext';

/*
 *  This component is the operator conversations panel as seen in the console of
 * the web app.
 *
 *  This component has the following properties:
 *    - onlineUsers: This is the array of online users from the console. It is
 *      displayed in the conversation lists to the side when requested.
 *    - messagesSocket: This is the websocket object that is created and connected
 *      in the console.
 *
 *  This component also accepts the following query parameters:
 *    - chat: if provided, the specified chat ID will be loaded into the conversation
 *      component in the center of the screen. This will be useful for things like
 *      notifications that need to switch to this page and open a chat on click.
 * 
 * UPDATE: 07/07/2023 - MRGN-507
 * React renders from top down. This file had a lot of state mangement when it should be used purely to 
 * separate the list of chats from the active errand and sync the errands array between both branches.
 * 1. Sockets or chat based state has been moved to the ConsoleConversation component
 * 2. List based or more general states that are optional to chats have been moved to the ConversationsList component
 * Loop heavy functions or changes that were made to all chats have been moved to the prepareOperatorErrand function
 * so that their respective operations may be performed once.
 * 
 * Before adding useEffects or other states, consider if the data is best suited further down the render tree.
 */

const ConsoleConversationWrapper = (props) => {
  // general purpose state information
  const location = useLocation();
  const navigate = useNavigate();
  const theme: MorganTheme = useTheme();
  const { t } = useTranslation();
  const rootContext = useRootContext();

  return (
    <ErrorBoundary debug={`./src/Components/ConsoleConversationWrapper.tsx}`}>
      <Style>
        <CustomTabs
          value={location.state?.tab || 'mine'}
          onChange={(event, newValue) =>
            navigate('/console', {
              state: {
                module: 'conversations',
                tab: newValue,
              },
            })
          }
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.blue['200'],
              color: theme.palette.blue['200'],
            },
          }}>
          <Tab label={t('consoleConversationsMine')} value='mine' />
          <Tab label={t('consoleConversationsLive')} value='live' />
          <Tab label={t('consoleConversationsHistory')} value='history' />
          <Tab label={t('consoleConversationsTeam')} value='team' />
          <Tab label={t('consoleConversationsGroups')} value='group' />
        </CustomTabs>

        {/* Main content area */}
        <div className="CCWStack">
          {/* Conversation lists */}
          <div className="CCWBox">
            <Card sx={{ height: '100%' }}>
              <ConversationsList  
                errands={rootContext.errands}
                notifications={props.notifications}
                onlineUsers={props.onlineUsers}
                setErrands={rootContext.setErrands}
              />
            </Card>

            {(!location.state?.tab || location.state?.tab === 'mine') && (
              <div className="CCWMine">
                <SMSEmailComposer chatId={location.state?.chat} />
              </div>
            )}
          </div>

          <ConsoleConversation
            errand={rootContext.errands?.find((e) => e._id === location.state?.chat)}
            errands={rootContext.errands}
            setErrands={rootContext.setErrands}
            onlineOperators={props.onlineOperators}
            onlineUsers={props.onlineUsers}
            notifications={props.notifications}
            setNotifications={props.setNotifications}
          />
        </div>
      </Style>
    </ErrorBoundary>
  );
};

export default memo(ConsoleConversationWrapper);
