import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useInitialMount from '@common/hooks/useInitialMount';
import DialogHeader from '@components/DialogHeader';
import axiosCall from '@services/axios';

import type { DialogPropsWithChildren } from '@mTypes/Console';

const mt = {
  mt:"12px"
};

const EditOperatorDialogAdmin: FC<DialogPropsWithChildren> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [accessLevel, setAccessLevel] = useState(props.operator?.accessLevel);
  const [team, setTeam] = useState(props.operator?.team || ''); //inconsistent state causes the label to overlap, default to blank
  const [subTeam, setSubTeam] = useState(props.operator?.subTeam || '');

  useInitialMount(() => {
    // we have to call this because what might come from the operator's data table might not be up to date
    async function refresh () {
      const operator = await axiosCall({url: `operator/${props.operator?._id}`});
      setTeam(operator?.team);
      setSubTeam(operator?.subTeam);
      setAccessLevel(operator?.accessLevel);
    }
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleSubmit = () => {
    //check if the operator level was previously 0
    //alert the user they cannot change it.
    if (props.operator.accessLevel === 'Level 0' && accessLevel !== props.operator.accessLevel){
      window.alert('Cannot change AngelAi access level');
      return;
    }
    const data = {
      id: props.operator?._id,
      accessLevel: accessLevel,
      team: team,
      subTeam: subTeam,
    };
    props.onSubmit(data);
  };

  return (
    <Dialog maxWidth="md" open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('editOperatorTooltip')} onClose={() => props.onClose()} />
      <DialogContent>
        <Stack width="400px">
          <small>_id: {props.operator._id}<br/></small>
          <TextField
            sx={{...mt}}
            select
            autoFocus
            size="small"
            color="info"
            variant="outlined"
            label={t('accessLevelCol')}
            onChange={(e) => setAccessLevel(e.target.value)}
            value={accessLevel}>
            {props.accessLevels.map((al) => {
              return (
                <MenuItem key={al} value={al}>
                  {al}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            sx={{...mt}}
            size="small"
            color="info"
            variant="outlined"
            label={t('teamCol')}
            value={team}
            onChange={(e) => setTeam(e.target.value)}
          />
          <TextField
            sx={{...mt}}
            size="small"
            color="info"
            variant="outlined"
            label={t('subTeamCol')}
            value={subTeam}
            onChange={(e) => setSubTeam(e.target.value)}
          />
          <Typography color={theme.palette.red['400']}>{props.error}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={() => props.onClose()}>
          {t('cancelButton')}
        </Button>
        <Button variant="contained" color="info" onClick={() => handleSubmit()}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditOperatorDialogAdmin;
