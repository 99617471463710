import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import React, { useRef, useState, memo, PropsWithChildren } from 'react';
import { Badge, Box, Card, CardContent, Stack, TextField, Tooltip, Typography, MorganTheme, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Avatar from '@components/Avatar';
import axiosCall from '@services/axios';
import getImageSource from '@common/getImageSource';
import { PhoneNumberPatternField } from '@components/FormattedTextField';
import { useUserContext } from '../Contexts/user';

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    profileSettingsEditColor: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    consoleColor: true;
  }
}
/*
 *  This component is the settings component seen in the center of the page on the
 * live Morgan website. To see more, see the Jira tickets associated with this
 * task: http://jira.swmc.com:8080/browse/MRGN-8
 *       http://jira.swmc.com:8080/browse/MRGN-19
 *
 */

const BadgeBox = styled(Box)(() => ({
  borderRadius: 12,
  height: 21,
  width: 21,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const CenterBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SquareEditIcon = styled(EditIcon)(() => ({
  height: 17,
  width: 17,
  color: 'var(--gray000)',
}));

const IconBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    height: 20,
    width: 20,
    borderRadius: 12,
  },
}));

const ProfileSettings : React.FC<PropsWithChildren<any>> = (props) => {
  const theme : MorganTheme = useTheme();
  const { t } = useTranslation();
  const selectedTeam = useRef(null);
  const selectedSubTeam = useRef(null);
  const email = useRef(null);
  const nickname = useRef(null);
  const iUserData = useUserContext();
  const [phone, setPhone] = useState(iUserData?.phone || '');
  const [editNickname, setEditNickname] = useState(false);
  const onDrop = async (acceptedFiles: string | any[]) => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      getBase64(file, async (result: string) => {
        // Get the data portion of the DataURL
        const fileData = result?.split(',')[1];
        try {
          const result = await axiosCall({
            url: `operator/${iUserData?._id}`,
            method: 'PUT',
            data: {
              avatar: fileData,
            },
          });
          iUserData.setUser((prev) => ({ ...prev, ...result }));
        } catch (error) {
          console.log(error);
        }
      });
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/svg+xml': ['.svg'],
    },
    maxFiles: 1,
    maxSize: 200000,
    minSize: 1,
    onDropRejected: undefined,
  });

  const clearAvatar = async () => {
    try {
      const result = await axiosCall({
        url: `operator/${iUserData?._id}`,
        method: 'PUT',
        data: {
          avatar: '',
        },
      });
      iUserData.setUser((prev) => ({ ...prev, ...result }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async (field: string) => {
    // This switch statement checks if a change was made to avoid sending needless requests
    let data;
    switch (field) {
      case 'email':
        data = iUserData?.email !== email.current?.value ? email.current?.value : null;
        break;
      case 'phone':
        data = iUserData?.phone !== phone ? phone : null;
        break;
      case 'team':
        data = iUserData?.team?.join(',') !== selectedTeam.current?.value ? selectedTeam.current?.value?.split(',') : null;
        break;
      case 'subTeam':
        data =
          iUserData?.subTeam?.join(',') !== selectedSubTeam.current?.value
            ? selectedSubTeam.current?.value?.split(',')
            : null;
        break;
      case 'nickname':
        data = iUserData?.nickname !== nickname.current?.value ? nickname.current?.value : null;
        break;
      default:
        return;
    }
    if (data || data === '') {
      try {
        const result = await axiosCall({
          url: `operator/${iUserData?._id}`,
          method: 'PUT',
          data: {
            [field]: data,
          },
        });

        iUserData.setUser((prev) => ({ ...prev, ...result }));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const clickOffNickname = () => {
    setEditNickname(false);
    handleSave('nickname');
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <CenterBox>
      <Card>
        <CardContent>
          <Stack spacing={2} alignItems="flex-start">
            {/* Top level items, avatar, name, work code */}
            <Stack direction="row" spacing={2} alignItems="center">
              <IconBadge
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={
                  iUserData?.avatar ? (
                    <Tooltip title={t('profileSettingsDeleteAvatarTooltip')} placement="left">
                      <ClearIcon
                        sx={{
                          marginTop: '1px',
                          cursor: 'pointer',
                          height: '18px',
                          width: '18px',
                        }}
                        onClick={() => {
                          clearAvatar();
                        }}
                      />
                    </Tooltip>
                  ) : (
                    0
                  )
                }
                color="error"
                overlap="circular">
                <IconBadge
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Tooltip
                        title={
                          <Box sx={{ whiteSpace: 'pre-line' }}>
                            {`${t('profileSettingsAvatarTooltip')}\n${t(
                              'maxProfilePicSizeTooltip'
                            )}`}
                          </Box>
                        }
                        placement="left">
                        <SquareEditIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            open();
                          }}
                        />
                      </Tooltip>
                    </div>
                  }
                  sx={{ 
                    '& .MuiBadge-badge': { 
                      backgroundColor: theme.palette.blue['200']
                    }
                  }}
                  overlap="circular">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Avatar
                      sx={{ width: 100, height: 100, fontSize: '80px' }}
                      alt={iUserData?.firstname}
                      img={getImageSource(iUserData?.avatar)}
                    />
                  </div>
                </IconBadge>
              </IconBadge>
              <Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                    {(
                      iUserData?.firstname +
                      ' ' +
                      iUserData?.lastname
                    ).toLowerCase()}
                  </Typography>
                  {editNickname ? (
                    <>
                      <TextField
                        sx={{ width: '100px' }}
                        id="nickname"
                        autoFocus
                        size="small"
                       
                        inputRef={nickname}
                        defaultValue={iUserData?.nickname || ''}
                        onBlur={() => {
                          clickOffNickname();
                        }}
                        onKeyPress={(e) => (e.key === 'Enter' ? clickOffNickname() : null)}
                      />
                    </>
                  ) : (
                    <>
                      <Typography paddingTop="3px" variant="caption">
                        "{iUserData?.nickname}"
                      </Typography>
                      <Tooltip title={t('profileSettingsNicknameTooltip')} placement="right">
                        <BadgeBox
                          onClick={() => {
                            setEditNickname(true);
                          }}
                          sx={{ backgroundColor: theme.palette.blue['200'] }}>
                          <SquareEditIcon />
                        </BadgeBox>
                      </Tooltip>
                    </>
                  )}
                </Stack>
                <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                  {iUserData?.dutyDescription
                    ? iUserData?.dutyDescription.toLowerCase()
                    : ''}
                </Typography>
              </Stack>
            </Stack>
            {/* Input region, first row */}
            <Stack direction="row" spacing={2}>
              <TextField
                id="email"
                label={t('profileSettingsEmail')}
                size="small"
               
                inputRef={email}
                defaultValue={iUserData?.email || ''}
                onBlur={() => {
                  handleSave('email');
                }}
              />
              <PhoneNumberPatternField
                defaultValue={phone}
                // value={phone}
                label={t('profileSettingsPhone')}
                onChange={(event: { target: { value: string; }; }) => setPhone(event.target.value.replace(/[^0-9]+/g, ''))}
                onBlur={() => {handleSave('phone');}} />
            </Stack>
            {/* Input region, second row */}
            <Stack direction="row" spacing={2} justifyItems="space-between">
              <TextField
                sx={{ width: '230px' }}
                size="small"
               
                inputRef={selectedTeam}
                defaultValue={iUserData?.team?.join(',') || ''}
                onBlur={() => {
                  handleSave('team');
                }}
                label={t('profileSettingsTeam')}
                id="teamSelectLabel"
              />
              <TextField
                sx={{ width: '230px' }}
                inputRef={selectedSubTeam}
                defaultValue={iUserData?.subTeam?.join(',') || ''}
               
                size="small"
                onBlur={() => {
                  handleSave('subTeam');
                }}
                label={t('profileSettingsSubTeam')}
                id="subTeamSelectLabel"
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </CenterBox>
  );
};

export default memo(ProfileSettings);
