import React, { useCallback, useEffect, useState } from 'react';
import useDebounceValue from './useDebounceValue';

export type TWindowDimensions = {
  innerWidth: number;
  innerHeight: number;
  isDesktop: boolean;
  isPortrait: boolean;
}

/**
 * Hook that returns the current window dimensions as an object with properties
 * `innerWidth` and `innerHeight`. The dimensions are updated when the window is
 * resized or its orientation is changed.
 * @returns {Object} - The current window dimensions
 * @returns {number} .innerWidth - The current width of the window's content area
 * @returns {number} .innerHeight - The current height of the window's content area
 * @returns {boolean} .isDesktop - Whether the innerWidth is greater than 900px
 * @returns {boolean} .isPortrait - Whether the innerWidth/innerHeight is more narrow than 16/10
 */
const useWindowDimensions = (onChange?: (dimensions: TWindowDimensions) => void) => {
  // Initialize the state with the current window dimensions
  const [windowDimensions, setWindowDimensions] = useState<TWindowDimensions>({
    innerWidth: (window.innerWidth || 0),
    innerHeight: (window.innerHeight || 0),
    isDesktop: (window.innerWidth || 0) > 900,
    isPortrait: window.innerHeight > 0 ? (window.innerWidth || 0) / window.innerHeight < 1.6 : false,
  });

  const debouncedWindowDimensions = useDebounceValue(windowDimensions, 200);

  // useCallback caches the function based on the dependencies so it isn't redefined each render 
  const handleChange = useCallback(() => {
    const dimensions: TWindowDimensions = {
      innerWidth: (window.innerWidth || 0),
      innerHeight: (window.innerHeight || 0),
      isDesktop: (window.innerWidth || 0) > 900,
      isPortrait: window.innerHeight > 0 ? (window.innerWidth || 0) / window.innerHeight < 1.6 : false,
    };
    setWindowDimensions(dimensions);

    if (typeof onChange === 'function') {
      onChange(dimensions);
    }
  }, [onChange]);

  // Update the state when the window is resized or its orientation is changed
  useEffect(() => {
    // Add event listeners for window resize and orientation change
    window.addEventListener('resize', handleChange);
    window.addEventListener('orientationchange', handleChange);

    // Remove event listeners when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleChange);
      window.removeEventListener('orientationchange', handleChange);
    };
  }, [handleChange]);

  return debouncedWindowDimensions;
}

export default useWindowDimensions;
