/**
 * @author maverick.manasco
 * @date 09/12/2023
 * 
 * Sentiment enum to be used throughout MessageContentContainer and ConversationFooter
 * Sentiment props to be used in SentimentEmoji.tsx
 */

export enum Sentiments {
  Negative = "Negative",
  Neutral = "Neutral",
  Positive = "Positive",
  Unvailable = "Unavailable",
}

export type SentimentEmojiProps = {
  sentiment: Sentiments;
  regularEmoji?: Boolean;
}