import { styled } from '@mui/system';
import getSvgFilter from '../Common/GetSvgFilter';

export const Styles: React.FC<any> = styled('div')<any>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  flex-direction: row;
  .ACPasswordToggle {
    margin-right: 3px;
  }
  .ACPasswordToggle,
  .ACCancelButton {
    min-width: 40px;
    width: 40px;
    height: 40px;
    overflow: visible;
    color: var(--peach500);
    display: flex;
    align-items: cener;
    justify-content: center;
    :hover, :focus {
      color: var(--orange600);
      img {
        filter: ${getSvgFilter(theme.palette.orange['600'])} !important;
      }
    }
    svg {
      font-size: 25px;
      pointer-events: none;
      height: 25px;
      width: 25px;
    }
    img {
      height: 25px;
      width: 25px;
      filter: ${getSvgFilter(theme.palette.orange['500'])}
    }
    &.padding {
      padding-right: 20px;
      padding-left: 15px;
    }
  }
  .ACPasswordToggle.isPrivate {
    margin-right: -8px;
  }
  `
);
