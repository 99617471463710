/**
 * @description This function checks if the given target is an HTMLElement by getting the bounding client.
 * Added tyoeof object check to prevent crashes in development as this works locally wihtout the check
 * @param {Window | HTMLElement} target - Required: The target to check.
 * @returns {boolean} - Whether or not the target is an HTML element.
 */
 const isHTMLElement = (target: Window | HTMLElement | null | undefined): boolean => {
  /**
   * Javascript typeof HTMLElement returns 'object'. 
   * Javascript also returns typeof 'object' for null values
   * so we must specifically check for that as well.
   */
  if (typeof target !== 'object' || target === null) {
    return false;
  }
  // Return true if the target's bounding rectangle is defined, indicating it's an HTML element.
  return (target as HTMLElement)?.getBoundingClientRect() !== undefined;
};

export const focusOnSelector = (selector: string) => {
  // Find the selected element for the given selector.
  let target = document.querySelector<HTMLTextAreaElement>(selector);
  // If the selected element is an HTML element, focus it.
  if (isHTMLElement(target)) {
    target.focus();
  }
};

/**
 * @description This function gets the bounding client rectangle of the element
 * with the given selector.
 * The object returned by the function has the following properties: 
 * bottom, height, left, right, top, width, x, and y. 
 * These properties represent different aspects of the element's position and 
 * size on the page. The function first tries to find the element using the 
 * query selector provided by the argument. If the element is found and it is 
 * a valid HTMLElement, the function returns the result of the 
 * getBoundingClientRect() method applied on the element, which returns the 
 * position and dimensions of the element relative to the viewport.
 * If the element is not found or it is not an HTMLElement, the function returns 
 * an object with all the properties defined but set to 0. This ensures that the 
 * object always has all the properties, regardless of whether the element is found 
 * or not, and makes it easier to handle cases where the element is missing.
 * Overall, the function is useful for getting the position and dimensions of
 * an element on the page, and was built in relation to MRGN-460 as it prevents
 * duplicating the work required to properly manage the disclaimer message content
 * 
 * @param {string} selector - Required: The selector of the element to get the bounding client rectangle of
 * @returns {DOMRect} The bounding client rectangle of the element.
 */
export const getBoundingClient = (selector: string): DOMRect => {
  // Find the element with the given selector.
  let element = document.querySelector<HTMLElement>(`${selector}`);
  // If the element is an HTML element, return its bounding client rectangle.
  if (isHTMLElement(element)) {
    return element.getBoundingClientRect();
  }
  // If the element is not an HTML element, return a new DOMRect object with all properties set to 0.
  return new DOMRect(0, 0, 0, 0);
};
