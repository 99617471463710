// @ts-check

import { Box, Button, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import React, { useRef, useState} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useTranslation } from 'react-i18next';
import useDebounce from '../Common/hooks/useDebounce';

/**
 * @param {import('../../jsdocs').TableHeaderProps} props
 */
const TableHeaderBar = (props) => {
  const { t } = useTranslation();
  const [localSearchValue, setLocalSearchValue] = useState(props.searchValue);
  useDebounce(() => props.setSearch(searchRef.current.value), props.debounceMS ? props.debounceMS : 350, [localSearchValue]);
  const searchRef = useRef(/** @type {HTMLInputElement} */ (null));
  const shouldHaveSearch = props.shouldHaveSearch !== undefined ? props.shouldHaveSearch : true;
  const shouldHaveActive = props.shouldHaveActive !== undefined ? props.shouldHaveActive : true;
  const shouldHaveRefresh = props.shouldHaveRefresh !== undefined ? props.shouldHaveRefresh : true;
  //adjust the styling based on if we are in the console view
  const isConsoleView = window.location.pathname.indexOf('/console') !== -1;

  return (
    <Stack sx={{backgroundColor: !isConsoleView && 'var(--gray940)'}} marginBottom={isConsoleView && 1} direction="row" spacing={2}>
      <Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
        {props.onAdd && (
          <Button
            startIcon={props.uploadIcon ? <DriveFolderUploadIcon /> : <AddIcon />}
            sx={{ marginRight: '15px', whiteSpace: 'nowrap', width: '260px' }}
            variant="contained"
            color="info"
            onClick={() => {
              if (props.onAdd) {
                props.onAdd();
              }
            }}
          >
            {props.addLabel}
          </Button>
        )}
        {shouldHaveSearch ? (
          <TextField
            sx={{ width: '240px' }}
            inputRef={searchRef}
            focused
            defaultValue={localSearchValue}
            onChange={(e) => {
              setLocalSearchValue(e.target.value);
            }}
            size="small"
            variant="outlined"
            color="info"
            label={t('searchLabel')}
            InputProps={{
              endAdornment: (
                <>
                  {localSearchValue !== '' && (
                    <InputAdornment
                      position="end"
                      onClick={() => {
                        if (searchRef.current) {
                          searchRef.current.value = '';
                        }
                        props.setSearch('');
                        setLocalSearchValue('');
                      }}
                    >
                      <CloseIcon fontSize="small" sx={{ cursor: 'pointer' }} />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        ) : (
          <div></div>
        )}
      </Box>
      <Box width="100%" display="flex" justifyContent="flex-end" alignItems="center">
        {shouldHaveActive && props.showInactiveClick && (
          <Button
            sx={{ marginRight: '15px' }}
            variant="outlined"
            onClick={() => {
              if (props.showInactiveClick) {
                props.showInactiveClick();
              }
            }}
          >
            {props.isActive ? t('showInactive') : t('hideInactive')}
          </Button>
        )}
        {shouldHaveRefresh && (
          <Tooltip title={t('refreshButtonTooltip')} placement="bottom">
            <CachedIcon
              sx={{ marginRight: '15px', cursor: 'pointer', color: !isConsoleView && 'var(--gray000)' }}
              onClick={() => {
                props.onRefresh();
              }}
            />
          </Tooltip>
        )}
        {/* <Button
          sx={{ height: '40px' }}
          variant="console"
          onClick={() => {
            onExport();
          }}>
          {t('downloadReportButton')}
        </Button> */}
      </Box>
    </Stack>
  );
};

export default TableHeaderBar;
