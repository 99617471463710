/**
 * @file This component is a stylized Box component
 * for styling the activity tacker within the conversation title.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { Box, styled } from '@mui/system';

const Tracker = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'arrayLength' &&
    prop !== 'visibleElemPadding' &&
    prop !== 'maxWidth' &&
    prop !== 'activityWrapperHeight' &&
    prop !== 'activityFontSize' &&
    prop !== 'isSearchOpen'
})(
  ({ theme, arrayLength, visibleElemPadding, maxWidth, activityWrapperHeight, activityFontSize, isSearchOpen }) => `
  & div.wrapper {
    display: ${isSearchOpen ? 'none' : 'flex'};
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--gray000);
    color: var(--orange700);
    max-width: ${maxWidth}px;
    transition: all 0.7s linear;
    & .activityText {
      margin-left: 5px;
    }
    &.hidden {
      width: 0px;
    }
    &.visible {
      padding: 0 ${visibleElemPadding}px;
    }
    height: ${activityWrapperHeight}px;
    border-radius: 4px;
    pointer-events: all;
    padding: 0 0;
    cursor: ${arrayLength > 1 ? 'cursor' : 'pointer'};
    & > p {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily};
      font-size: ${activityFontSize}px;
      -webkit-line-clamp: 1;
      word-break: break-all;
      pointer-events: none;
      user-select: none;
      & > svg {
        pointer-events: none;
        user-select: none;
      }
    }
  }
  `
);

export default Tracker;
