import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCopy } from '@mui/icons-material';

import { getDropdownDescription } from '@common/StringUtils';
import { IErrand, IMessage } from '@interfaces/Conversation';

import Styles from '@styles/MorphMessageOptions.module.css';

type TMorphMessageOptionCopyProps = {
  errand: IErrand;
  handleClose: () => void;
  message: IMessage;
};

const MorphMessageOptionCopy = ({
  errand, handleClose, message
}: TMorphMessageOptionCopyProps) => {
  const { t } = useTranslation();

  const handleClickCopy = useCallback(() => {
    // set up editable message value
    let msg = message.message || '';
    // Remove replies
    if (msg.indexOf('</blockquote>') !== -1) {
      // Remove the old message.
      msg = msg.split(`</blockquote>`)[1];
    }
    // Remove previous edits
    if (msg.indexOf('<i class="messageArrow"/>') !== -1) {
      // Remove the old message.
      msg = msg.split(`<i class="messageArrow"/>`).join(' -> ');
    }
    // Parse the field data to show the description by extracting it from the message. 
    msg = getDropdownDescription(msg);

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(msg, 'text/html');

    msg = htmlDoc.body.textContent;

    if(navigator.clipboard) {
      navigator.clipboard.writeText(msg);
    } else {
      console.warn('[MorphMessageOptionCopy]: Navigator.clipboard is undefined!');
    }

    // clean up
    handleClose();
  }, [errand?._id, message?._id, message?.message, handleClose]);

  return (
    <button className={Styles.btn} onClick={handleClickCopy}>
      <div className={Styles.btnBorder}>
        <ContentCopy />
        <p className={Styles.label}>{t('tCopy')}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionCopy;
