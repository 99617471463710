/// <reference lib="webworker" />
/* eslint-disable no-restricted-globals */

// This service worker can be customized!
// See https://developers.google.com/web/tools/workbox/modules
// for the list of available Workbox modules, or add any other
// code you'd like.
// You can also remove this file if you'd prefer not to use a
// service worker, and the Workbox build step will be skipped.

import { clientsClaim } from 'workbox-core';
import { precacheAndRoute, createHandlerBoundToURL } from 'workbox-precaching';
import { registerRoute, Route } from 'workbox-routing';
import { CacheFirst } from 'workbox-strategies';
import { ExpirationPlugin } from 'workbox-expiration';
// import packageData  from '../package.json';
/**
 * Available Strategies:
 * CacheFirst: Prioritizes serving content from the cache.
 * NetworkFirst: Prioritizes fetching content from the network.
 * StaleWhileRevalidate: Serves cached content immediately while fetching updates in the background.
 * NetworkOnly: Always fetches from the network, bypassing the cache.
 * CacheOnly: Always serves cached content, even if it's stale.
 */


declare const self: ServiceWorkerGlobalScope;
const swUrl = `${process.env.REACT_APP_PUBLIC_URL}/service-worker.js`;
// responsible for controlling the service worker's lifecycle
clientsClaim();

// Precache all of the assets generated by your build process.
// Their URLs are injected into the manifest variable below.
// This variable must be present somewhere in your service worker file,
// even if you decide not to use precaching. See https://cra.link/PWA
try {
  // Initialize for the self.__WB_MANIFEST requirement.
  let manifestFiles = self.__WB_MANIFEST;
  console.debug(manifestFiles);
  // const preReleaseVersion = "MRGN-2024-008"; // consider adding to env or config file.
  // const tempHash = `${packageData.version}+${preReleaseVersion}`;
  // const filesToPrecache = [
  //   // Essential files:
  //   // This is a hash placeholder for asset filenames. Provided by webpack.
  //   // This ensures that the revision changes whenever the content of the asset changes, 
  //   // triggering cache updates in your service worker.
  //   { url: '/index.html', revision: `${tempHash}` }, // [hash] is needed in the revision to avoid indefinite caching.
  //   { url: '/main.css', revision: `${tempHash}` }, // to update these files if changes are made.
  //   // Commented out till figure out how to get the [hash] to replace
  //   // { url: '/static/js/main.[hash].js', revision: null },  // React bundle
  //   // { url: '/static/css/main.[hash].css', revision: null },  // React stylesheet
  //   // Other critical HTML or JavaScript files (if any)

  //   // Resources:
  //   { url: '/static/media/logo.svg', revision: `${tempHash}` },  // Logo

  //   // Offline support:
  //   { url: '/service-worker.js', revision: `${tempHash}` },  // Service worker file itself
  // ];
  // Ignore the js files because we want these to load when needed.
  precacheAndRoute(manifestFiles || []);
}
catch (err) {
  console.warn('Error during precaching:', err);
  // Continue with service worker registration even if precaching fails.
  try {
    await navigator.serviceWorker.register(swUrl);
  } catch (registrationError) {
    console.error('Error during service worker registration:', registrationError);
  }
}

// Set up App Shell-style routing, so that all navigation requests
// are fulfilled with your index.html shell. Learn more at
// https://developers.google.com/web/fundamentals/architecture/app-shell
const fileExtensionRegexp = new RegExp('/[^/?]+\\.[^/]+$');

// Create a handler that serves index.html for navigation requests that match specific conditions.
// By providing only /index.html, you're defining that the handler 
// will serve index.html when the requested URL ends with /index.html.
async function shouldServeIndexHtmlWithFallback({ request, url }: { request: Request; url: URL }) {
  if (
    request.mode !== 'navigate' ||
    url.pathname.startsWith('/_') ||
    url.pathname.match(fileExtensionRegexp)
  ) {
    return false;
  }
  if (process.env.REACT_APP_ENVIRONMENT !== "local" && request.url.startsWith('http://')) {
    console.warn("Insecure request detected: ~ request:", request);
    // Handle insecure request (e.g., throw error, redirect, etc.)
    return false;
  }
  try {
    // Attempt to serve directly using createHandlerBoundToURL
    createHandlerBoundToURL('/index.html');
    console.log("Served from index.html", request.url);
    return true; // Success, no fallback needed
  } catch (error) {
    console.warn('Failed to serve index.html:', error.message);
    try {
      createHandlerBoundToURL(process.env.REACT_APP_PUBLIC_URL + '/index.html');
      return true;
    } catch (err) {
      console.error(err.stack);
    }
  }
}

// Register the route separately
try {
  registerRoute(
    // Return false to exempt requests from being fulfilled by index.html.
    ({ request, url }: { request: Request; url: URL }) => {
      shouldServeIndexHtmlWithFallback({ request, url });
    }
  );
} catch (err) {
  console.warn(err);
}

// Handle APIs
// const apiRoutes = new Route(({ url }) => {
//   return url.href.includes(`${process.env.REACT_APP_MORGAN_CORE}`);
// }, new NetworkFirst({
//   // ensures that the api requests come from Network if available else from cache
//   cacheName: 'api_responses'
// }));


//Handle audio:
const audioRoute = new Route(({ request }) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "local" && request.url.startsWith('http://')) {
    console.log("Insecure request detected: audioRoute ~ request:", request)
    // Handle insecure request (e.g., throw error, redirect, etc.)
    return false;
  }
  // if (!request.url.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
  //   console.warn("External resource requested and should not be served from service worker", request);
  //   return false;
  // } // Commented for now since some files are from external but the use of 
  // cachefirst removed the error from before.
  return request.destination === 'audio';
}, new CacheFirst({
  cacheName: 'angel_audio',
  plugins: [
    new ExpirationPlugin({
      // Only cache requests for a week
      maxAgeSeconds: 7 * 24 * 60 * 60,
      // Only cache 10 requests.
      maxEntries: 10,
    }),
  ],
}));

//Handle documents:
const documentsRoute = new Route(({ request }) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "local" && request.url.startsWith('http://')) {
    console.log("Insecure request detected: documentsRoute ~ request:", request)
    // Handle insecure request (e.g., throw error, redirect, etc.)
    return false;
  }
  // if (!request.url.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
  //   console.warn("External resource requested and should not be served from service worker", request);
  //   return false;
  // }
  return request.destination === 'document';
}, new CacheFirst({
  cacheName: 'angel_documents',
  plugins: [
    new ExpirationPlugin({
      // Only cache requests for a week
      maxAgeSeconds: 7 * 24 * 60 * 60,
      // Only cache 10 requests.
      maxEntries: 10,
    }),
  ],
}));

// Handle images:
const imageRoute = new Route(({ request }) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "local" && request.url.startsWith('http://')) {
    console.log("Insecure request detected: imageRoute ~ request:", request)
    // Handle insecure request (e.g., throw error, redirect, etc.)
    return false;
  }
  // if (!request.url.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
  //   console.warn("External resource requested and should not be served from service worker", request);
  //   return false;
  // }
  return request.destination === 'image';
}, new CacheFirst({
  cacheName: 'angel_images',
  plugins: [
    new ExpirationPlugin({
      // Only cache requests for a week
      maxAgeSeconds: 7 * 24 * 60 * 60,
      // Only cache 10 requests.
      maxEntries: 10,
    }),
  ],
}));

// Handle scripts:
const scriptsRoute = new Route(({ request }) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "local" && request.url.startsWith('http://')) {
    console.log("Insecure request detected: scriptsRoute ~ request:", request)
    // Handle insecure request (e.g., throw error, redirect, etc.)
    return false;
  }
  // if (!request.url.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
  //   console.warn("External resource requested and should not be served from service worker", request);
  //   return false;
  // }
  return request.destination === 'script';
}, new CacheFirst({
  cacheName: 'angel_scripts',
  plugins: [
    new ExpirationPlugin({
      // Only cache requests for a week
      maxAgeSeconds: 7 * 24 * 60 * 60,
      // Only cache 10 requests.
      maxEntries: 10,
    }),
  ],
}));

// Handle styles:
const stylesRoute = new Route(({ request }) => {
  if (process.env.REACT_APP_ENVIRONMENT !== "local" && request.url.startsWith('http://')) {
    console.log("Insecure request detected: stylesRoute ~ request:", request)
    // Handle insecure request (e.g., throw error, redirect, etc.)
    return false;
  }
  // if (!request.url.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
    // console.warn("External resource requested and should not be served from service worker", request);
  //   return false;
  // }
  return request.destination === 'style';
}, new CacheFirst({
  cacheName: 'angel_styles',
  plugins: [
    new ExpirationPlugin({
      // Only cache requests for a week
      maxAgeSeconds: 7 * 24 * 60 * 60,
      // Only cache 10 requests.
      maxEntries: 10,
    }),
  ],
}));

// Register routes
registerRoute(audioRoute);
registerRoute(documentsRoute);
registerRoute(imageRoute);
registerRoute(scriptsRoute);
registerRoute(stylesRoute);
// registerRoute(apiRoutes);

// This allows the web app to trigger skipWaiting via
// registration.waiting.postMessage({type: 'SKIP_WAITING'})
self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});

// Any other custom service worker logic can go here.