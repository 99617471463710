import { Box, Grid, IconButton, Stack, Tooltip, Typography, MorganTheme, useTheme  } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoIcon from '@mui/icons-material/Info';
import { QuickActionIcon } from '@assets/Icons';
import getImageSource from '@common/getImageSource';
import { useDrag } from 'react-dnd';
import React, { useState, useEffect } from 'react'; 
import ALBadge from '@styles/ActionListBadgeStyle';
import { maskMessage } from '@common/StringUtils';
/*
 *  This component makes up one of the Action badges seen in the Console Conversation
 * page. These badges represent the Actions as they come out of the API.
 *
 *  This component has the following props:
 *    - name: The name or description of the action that this is associated with.
 *    - icon: The link to the icon as sent out by the API.
 */

const ActionListBadge = (props) => {
  const theme: MorganTheme = useTheme();
  /* NOTE the use of the `item` value and that all information we will want to use
   * INSIDE the useDrop action is in there, this is crucial to making this work correctly.
   */
  const [selectedValue, setSelectedValue] = useState(null);  
  const [, dragRef] = useDrag(
    () => ({
      type: 'box',
      item: {
        chatId: props.chatId,
        id: props.id,
        type: props.workflow ? 'workflow' : 'action',
        onDrop: props.onDropActionWorkflow,
      },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          item.onDrop(item.id, item.type, item.chatId);
        }
      },
    }),
    [props]
  ); // Some props may be undefined and since we need them all we want to update on them.

  /**
   * This function just checks if the string contains only letters and numbers and 
   * is used as check to know if to format the field values. 
   * @param {*} str 
   * @returns 
   */
  const onlyLettersAndNumbers = (str: string) => {
    return /^[A-Za-z0-9]*$/.test(str);
  }

  /**
   * Accepts the field value and the format and formats the data for consistent display on the UI.
   * @param {*} value 
   * @param {*} format 
   * @returns 
   */
  const formatMessage = (value: string, format: string) => {
    if (!onlyLettersAndNumbers(value)) {
      return value;
    }
    if (format && format?.length > 0) {
      let res = [];
      for (let i = 0; i < value.length; i++) {
        // Step through the format and build the result 
        for (let j = 0; j < format.length; j++) {
          if (format[j] !== '#') {
            res.push(format[j]);
          } else {
            res.push(value[i]);
            i += 1;
          }
        }
      }
      return res.join('');
    } else {
      return value;
    }
  };

  
  const badgeClick = async (element, event) => {
    event.preventDefault();  // keeps the page from reloading 
    setSelectedValue(element);
  }
  useEffect(() => {
    // if there is no active context then we need to ensure that the default is selected
    if ( props.errand?.activeContext  ) {
      setSelectedValue({ _id: props.errand?.activeContext });
    } else {
      setSelectedValue({ _id: 'fosterFields' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errand?._id]);

  useEffect(() => {
    // Updates the selected values if the active context changes
    if (!props.errand?.activeContext) return; // this check is required because the use effect is run even if the dependency is undefined.
    setSelectedValue({ _id: props.errand?.activeContext });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errand?.activeContext]);

  return (
    <ALBadge ref={dragRef}>
      <Stack spacing={1} >
        {/* Top line, icon, name, and three dots */}
        <Grid container sx={{ padding: props.workflow ? '' : '5px 10px 0px 10px', minHeight: 30 }}>
          <Grid item xs={2}>
            <Box
              sx={{
                borderRadius: props.workflow ? '' : '.3em',
                backgroundColor: theme.palette.orange['110'],
                width: 30,
                height: props.workflow ? '100%' : 30,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <IconButton
                disabled={props.chatId === undefined}
                onClick={() => {
                  props.onDropActionWorkflow(
                    props.id,
                    props.workflow ? 'workflow' : 'action',
                    props.chatId
                  );
                }}>
                {props.icon !== '' && props.action ? (
                  <img
                    width={25}
                    height={25}
                    src={getImageSource(props.icon)}
                    style={{ filter: 'invert(1)' }}
                    alt="Action Icon"
                  />
                ) : props.action ? (
                  <QuestionMarkIcon sx={{ color: 'var(--gray000)' }} />
                ) : (
                  <QuickActionIcon style={{ color: 'var(--gray000)' }} />
                )}
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontSize={14} sx={{ width: '171px', overflowWrap: 'break-word' }}>
              {' '}
              {props.name}{' '}
            </Typography>
          </Grid>
        </Grid>
        {/* Bar below with the + button and the list of actions. */}
        {
          props.action && 
          <Stack>
            <Box className="ALBFields">
              {/* This is the badge representing the foster fields - fields without contexts */}
              {props.contextFields?.fosterFields?.length > 0 && (
                <Tooltip
                  arrow
                  placement="top"
                  key={0}
                  title={"Fields without a context"}
                >
                  <button
                    className={`ALBFieldSelector foster ${( selectedValue?._id  === 'fosterFields') ? "active" : ""}`}
                    onClick={(event) => {
                      badgeClick({ _id: 'fosterFields' }, event);
                    }}
                  >
                  </button>
                </Tooltip>
              )}
              {/* The list of context options should be shown there. only show if there are fields */}
              {props.errand.context?.map((el) => (
                <Tooltip
                  arrow
                  placement="top"
                  key={el?._id}
                  title={
                      <table>
                        <tbody>
                          <tr>
                            <td>{el?.indexField?.name}</td>
                            <td>{el?.indexField?.value}</td>
                          </tr>
                        </tbody>
                      </table>
                  }
                >
                  <button
                    className={`ALBFieldSelector ${
                      selectedValue
                        ? (
                            el._id === selectedValue?._id ? "active" : ""
                          ) 
                        : props.errand.context?.length > 0 
                          ? (
                              el._id === props.errand?.activeContext ? "active" : ""
                          ) : ""
                    }`}
                    onClick={(event) => {
                      badgeClick(el, event);
                    }}
                  >
                  </button>
                </Tooltip>
              ))}
              {/* This is the '+' icon which is used to trigger the quick request */}
              <Tooltip 
                arrow
                placement="bottom"
                title={"Ask"}>
                <span>
                  <IconButton
                    disabled={props.chatId === undefined}
                    size="small"
                    onClick={() => {
                      props.onDropActionWorkflow(
                        props.id,
                        props.workflow ? 'workflow' : 'action',
                        props.chatId
                      );
                    }}>
                    <AddIcon sx={{ color: 'var(--gray000)' }} />
                  </IconButton >
                </span>
              </Tooltip>
            </Box>
            {/* When hover over the icon it should show the list of options and the UI should show the most recent value for the field. */}
            {props.contextFields &&
              <Box className="ALBFields">
                <div className="ALBFieldValues">
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={
                        <table>
                          <thead>
                            <tr>
                              <th>Value</th>
                              <th>Timestamp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              (props.contextFields[selectedValue?._id])?.map((el) => {
                                return (
                                  <tr key={el?.createdAt}>
                                    <td>
                                      {
                                        maskMessage(
                                          el?.value,
                                          props.contextFields[selectedValue?._id][props.contextFields[selectedValue?._id].length - 1]?.attributes?.mask)
                                      }
                                    </td>
                                    <td>{new Date(el?.createdAt).toLocaleString()}</td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                        </table>
                    }>
                    <div>
                      {selectedValue && props.contextFields[selectedValue?._id]?.length > 1 && (
                        <InfoIcon sx={{ color: 'var(--gray000)' }} />
                      )}
                    </div>

                  </Tooltip>
                  <div className="ALBSelectedValueStyling">
                    {
                      selectedValue && props.contextFields[selectedValue?._id]?.length > 0
                        // if there is a selected value show the most recent value
                        ? maskMessage(
                          formatMessage(props.contextFields[selectedValue?._id][props.contextFields[selectedValue?._id].length - 1].value,
                            props.contextFields[selectedValue?._id][props.contextFields[selectedValue?._id].length - 1]?.attributes?.format),
                          props.contextFields[selectedValue?._id][props.contextFields[selectedValue?._id].length - 1]?.attributes?.mask)
                        // if the activeContext exists then use that else show nothing
                        : props.errand?.activeContext && (props.errand?.activeContext in props.contextFields) && (selectedValue?._id in props.contextFields)
                          ? maskMessage(
                            formatMessage(
                              props.contextFields[props.errand?.activeContext][0].value,
                              props.contextFields[props.errand?.activeContext][0]?.attributes?.format),
                            props.contextFields[props.errand?.activeContext][0]?.attributes?.mask)
                          : ''
                    }
                  </div>
                </div>
              </Box>
            }
          </Stack>
        }
      </Stack>
    </ALBadge>
  );
};

export default ActionListBadge;
