import React, { createContext, Dispatch, MutableRefObject, SetStateAction, useContext } from 'react';

// Define the shape of the context data
export interface AppContext {
    showReloadButton: boolean;
    setShowReloadButton: Dispatch<SetStateAction<boolean>>;
    serviceWorkerWrapperRef: MutableRefObject<{reloadPage: () => void}> | null;
}

// Create the context with default undefined value
export const AppContext = createContext<AppContext | null>(null);

export const useAppContext = () => useContext<AppContext>(AppContext);