import { useTranslation } from 'react-i18next';
import { AirplaneNoCircle } from '@assets/Icons';
import axiosCall from '@services/axios';
import { ActivityData, IOrderData } from '../interfaces';
import { DefaultElement } from './templates/DefaultElement';
import React, { memo, useEffect } from 'react';
import { IconStyles } from '../Constants';

// States mapping
const StatesMapping = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

// This function accepts the two letter string that represents any state f.e. "OH"
// and returns its full string representation f.e. "Ohio".
// IF not found, returns null.
const getStateFullStr = (stateKey): string | null => {
  if (stateKey in StatesMapping) {
    return StatesMapping[stateKey];
  } else {
    return null;
  }
};

// Parsing / Processing tools
export const getOrderIdFrom = (htmlStr): string | null => {
  // str is something like "<span></span>"
  const regex = /data-orderid="([^"]+)"/;
  const match = htmlStr.match(regex);

  // if there is a match
  if (match && match[1]) {
    // get the matched order id
    const orderId = match[1];
    return orderId;
  } else {
    return null;
  }
};

// Order Api Functions
export const fetchOrderData = async (orderId: string, abortController: AbortController): Promise<IOrderData> => {
  try {
    const data: IOrderData = await axiosCall(
      // req data
      {
        url: `order/${orderId}`,
      },
      // signal
      { signal: abortController.signal }
    );

    return data;
  } catch (e) {
    if (e instanceof Error) {
      if (e.name === 'AbortError') {
        console.log('Fetch operation was aborted.');
      } else {
        console.error('An error occurred:', e.message);
      }
    } else {
      console.error('Error occured while fetching the order data!', JSON.stringify(e));
    }
  }
};

export const OrderElement = memo((props: {
  activityData: ActivityData;
  remeasureElement: (givenData: ActivityData) => void;
}) => {
  const { t } = useTranslation();

  let stateStrToRender = '';

  // form order data and cast to its correct type
  const orderData = props.activityData.data as IOrderData;

  if ('shippingState' in orderData) {
    // only if order shipping state is not empty string
    if (orderData.shippingState.replace(' ', '') !== '') {
      // get full state string to render on UI
      const stateFullString = getStateFullStr(orderData.shippingState);
      // if full state is present
      if (stateFullString !== null) {
        stateStrToRender = stateFullString;
      }
    }
  }

  // if stateStrToRender was not set to any value, set it to default text
  if (stateStrToRender === '') {
    stateStrToRender = t('AT_OrderUser');
  }

  const textToRender = `${t('AT_OrderTo')} ${stateStrToRender}`;

  // Handles remeasurement when Order req is completed and new shippingState is received
  // which results in rerendering this component with a new text that needs to be remeasured to
  // get new width value.
  useEffect(() => {
    if (props.remeasureElement && props.activityData?.data?.shippingState) {
      props.remeasureElement({ ...props.activityData });
    }
  }, [props.activityData?.data?.shippingState]);

  return <DefaultElement text={textToRender} icon={<AirplaneNoCircle style={{ ...IconStyles.default }} />} />;
});
