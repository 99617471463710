import React, { ChangeEvent } from 'react';

const acceptedImageTypes = [
  'image/jpeg', 
  'image/jpg', 
  'image/png', 
  'image/gif', 
  'image/svg+xml', 
  'image/bmp'
]

const typeValidator = (file: File): boolean => {
  const accept = [
    'image',
    'pdf',
    'text/plain',
    'msword',
    'excel',
    'powerpoint',
    'officedocument',
    'opendocument',
    'xml',
    'fnm',
    'csv',
    'm4a',
    'wav',
    'mp3',
    'aac',
    'flac',
    'wma',
    'aiff',
    'mpeg',
  ];

  const acceptNoType = ['fnm'];
  if (file?.type && accept.some((x) => file.type.includes(x))) {
    return true;
  } else {
    // If file doesn't have a type, check file ending
    if (file?.name && file?.type === '') {
      let fileParts = file.name.split('.');
      let ending = fileParts[fileParts.length - 1].toLowerCase();
      if (acceptNoType.includes(ending)) {
        return true;
      }
    }
    return false;
  }
};

const handleFile = (event: ClipboardEvent | DragEvent | ChangeEvent, isInfluencer: boolean = false) => {  

  let files: File[] = [];
  let error: string = '';

  if (event instanceof ClipboardEvent) {
    if (!event?.clipboardData || !event?.clipboardData?.items) return [];
    if (event?.clipboardData?.getData('text/plain')) return [];
    for (let i = 0; i < event.clipboardData.items.length; i++) {
      if (!error) {
        let file = event.clipboardData.items[i].getAsFile();
        if (isInfluencer && file.type.includes('image') && !acceptedImageTypes.includes(file.type)){
          error = 'documentImageType'
        }
        else if (typeValidator(file)) {
          if (file.size < Number(process.env.REACT_APP_FILE_SIZE_LIMIT)) {
            files.push(file);
          } else {
            error = 'documentSizeLimit';
          }
        } else {
          error = 'documentTypeError';
        }
      }
    }
  } else if (event instanceof DragEvent) {
    if (!event?.dataTransfer || (!event?.dataTransfer?.items && !event?.dataTransfer?.files))
      return [];
    if (event?.dataTransfer?.items) {
      [...event.dataTransfer.items].forEach((item, i) => {
        if (!error) {
          const file = item.getAsFile();
          if (isInfluencer && file.type.includes('image') && !acceptedImageTypes.includes(file.type)){
            error = 'documentImageType'
          }
          else if (typeValidator(file)) {
            if (file.size < Number(process.env.REACT_APP_FILE_SIZE_LIMIT)) {
              files.push(file);
            } else {
              error = 'documentSizeLimit';
            }
          } else if (item.type !== 'application/json') {
            error = 'documentTypeError';
          }
        }
      });
    } else if (event?.dataTransfer?.files) {
      [...event.dataTransfer.files].forEach((file, i) => {
        if (!error) {
          if (isInfluencer && file.type.includes('image') && !acceptedImageTypes.includes(file.type)){
            error = 'documentImageType'
          }
          else if (typeValidator(file)) {
            if (file.size < Number(process.env.REACT_APP_FILE_SIZE_LIMIT)) {
              files.push(file);
            } else {
              error = 'documentSizeLimit';
            }
          } else {
            error = 'documentTypeError';
          }
        }
      });
    }
  } else {
    event?.preventDefault();
    let target = event?.target as HTMLInputElement;
    if (!target || !target?.files || target?.files?.length === 0) return [];
    for (let file of target.files) {
      if (!error) {
        if (isInfluencer && file.type.includes('image') && !acceptedImageTypes.includes(file.type)){
          error = 'documentImageType'
        }
        else if (typeValidator(file)) {
          if (file.size < Number(process.env.REACT_APP_FILE_SIZE_LIMIT)) {
            files.push(file);
          } else {
            error = 'documentSizeLimit';
          }
        } else {
          error = 'documentTypeError';
        }
      }
    }
    target.value = null;
  }

  if (error.length > 0) {
    return error;
  }

  files.forEach((x: File) => {
    let newName = x.name.replace(/[~` !@#$%^&*()+={}[\];:'"<>,/\\?-]/g, '');
    Object.defineProperty(x, 'name', {
      writable: true,
      value: newName,
    });
  });

  return files;
};

export default handleFile;
