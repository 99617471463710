import React,{ useEffect, useRef } from 'react';

const useHasMounted = (): boolean => {
  const hasMounted = useRef<boolean>(false);

  useEffect(() => {
    hasMounted.current = true;
    return () => {
      hasMounted.current = false;
    };
  }, []);

  return hasMounted.current;
};

export { useHasMounted };
