// Functions to get, set, and remove local storage related to the operator.
export const getOperatorAccessLevel = (): string =>
  localStorage.getItem('operatorAccessLevel');
export const getOperatorAccessToken = (): string =>
  localStorage.getItem('operatorAccessToken');
export const getOperatorId = (): string =>
  localStorage.getItem('operatorId');
export const getOperatorAuthenticated = (): string =>
  localStorage.getItem('operatorAuthenticated');
export const getOperatorSessionToken = (): string =>
  localStorage.getItem('operatorSessionToken');
export const getOperatorStatus = (): string =>
  localStorage.getItem('operatorStatus');

export const removeOperatorAccessLevel = (): void =>
  localStorage.removeItem('operatorAccessLevel');
export const removeOperatorAccessToken = (): void =>
  localStorage.removeItem('operatorAccessToken');
export const removeOperatorId = (): void =>
  localStorage.removeItem('operatorId');
export const removeOperatorAuthenticated = (): void =>
  localStorage.removeItem('operatorAuthenticated');
export const removeOperatorSessionToken = (): void =>
  localStorage.removeItem('operatorSessionToken');
export const removeOperatorStatus = (): void =>
  localStorage.removeItem('operatorStatus');

export const setOperatorAccessLevel = (value: string): void =>
  localStorage.setItem('operatorAccessLevel', value);
export const setOperatorAccessToken = (value: string): void =>
  localStorage.setItem('operatorAccessToken', value);
export const setOperatorId = (value: string): void =>
  localStorage.setItem('operatorId', value);
export const setOperatorAuthenticated = (value: string): void =>
  localStorage.setItem('operatorAuthenticated', value);
export const setOperatorSessionToken = (value: string): void =>
  localStorage.setItem('operatorSessionToken', value);
export const setOperatorStatus = (value: string): void =>
  localStorage.setItem('operatorStatus', value);


// remove the operator's storage
export const removeOperatorSession = (): void => {
  removeOperatorAccessToken();
  removeOperatorAuthenticated();
  removeOperatorId();
  removeOperatorSessionToken();
  removeOperatorStatus();
};

// set the operator's storage and their session info
export const setOperatorSession = (token: string, id: string, session_token: string, accessLevel: string): void => {
  localStorage.setItem('operatorAccessLevel', accessLevel);
  localStorage.setItem('operatorAccessToken', token);
  localStorage.setItem('operatorId', id);
  localStorage.setItem('operatorSessionToken', session_token);
  localStorage.setItem('operatorAuthenticated', 'true');
  localStorage.setItem('userConsent', 'true');
};
