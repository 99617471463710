import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  tableCellClasses,
  TextField,
  InputAdornment,
  Button,
  Checkbox,
  MenuItem,
  Select,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useInitialMount from '@common/hooks/useInitialMount';
import { AllInclusive, BorderColorOutlined, Check, Clear, DeleteOutlined, Remove } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

type Workflow = {
  _id: string;
  name: string;
}

export type Prize = {
  _id?: string;
  name: string;
  type: string;
  chance: number;
  colorSelection: boolean;
  sizeSelection: boolean;
  quantitySelection: boolean;
  maxQuantity: number;
  amountWinnableToday: number;
  workflow?: string;
  isGeoRestricted: boolean;
  longitude?: string;
  latitude?: string;
  radius?: number;
};

type EditingItems = {
  [key: string]: Prize;
};

const SlotMachinePrizes = (props) => {
  const { t } = useTranslation();
  const [prizes, setPrizes] = useState<Array<Prize>>([]);
  const [editingItems, setEditingItems] = useState<EditingItems>({});
  const [workflowsArray, setWorkflowsArray] = useState<Array<Workflow>>([])
  const [workflowsMap, setWorkflowsMap] = useState({})
  const minRate = 0;
  const maxRate = 100;

  const recalculatePercentages = (updatedItems: Prize[]) => {
    const totalPercentage = updatedItems.reduce((sum, item) => sum + item.chance, 0);

    // Handle the case where total percentage is 0
    if (totalPercentage === 0) {
      const equalPercentage = Math.round((100 / updatedItems.length) * 100) / 100;
      return updatedItems.map((item) => ({
        ...item,
        chance: equalPercentage, // Distribute equally
      }));
    }

    if (totalPercentage !== 100) {
      const scalingFactor = 100 / totalPercentage;
      return updatedItems.map((item) => ({
        ...item,
        // Scale and round to hundredths place
        chance: Math.round(item.chance * scalingFactor * 100) / 100,
      }));
    }
    return updatedItems;
  };

  const getPrizes = async () => {
    try {
      const req = {
        url: 'slotmachine/prizes',
      };
      const response: Prize[] = await axiosCall(req);
      return response;
    } catch (err) {
      console.error('Error fetching prizes:', err);
    }
  };

  const getWorkflows = async () => {
    try {
      const req = {
        url: 'workflow?orderBy=name&order=asc&active=true'
      }
      const response: Workflow[] = await axiosCall(req);
      return response
    } catch (err) {
      console.error('Error fetching workflows:', err);
    }
  }

  const upsertPrizes = async (prizeArr: Prize[]) => {
    try {
      const req = {
        url: `slotmachine/prizes/upsert`,
        method: 'POST',
        data: prizeArr,
      };
      await axiosCall(req);
    } catch (err) {
      console.error('Error saving items:', err);
    }
  };

  const deletePrize = async (prize: Prize) => {
    return await axiosCall({
      url: `slotmachine/prizes/${prize._id}`,
      method: 'DELETE',
    });
  };

  const savePrizes = async (prizeArr: Prize[]) => {
    const updatedItems = prizeArr.map((item, index) => (editingItems[index] ? editingItems[index] : item));
    const recalculatedItems = recalculatePercentages(updatedItems);
    setEditingItems({});
    await upsertPrizes(recalculatedItems);
    const latestPrizes = await getPrizes();
    setPrizes(latestPrizes);
  };

  const handleAdd = () => {
    const index = prizes?.length || 0;
    const newPrize: Prize = {
      name: `Prize #${index + 1}`,
      chance: 0,
    };
    setPrizes(() => {
      return [...prizes, newPrize];
    });
    setEditingItems({
      ...editingItems,
      [index]: newPrize, // Clone the item to allow cancel
    });
  };

  const handleEdit = (index: number) => {
    setEditingItems({
      ...editingItems,
      [index]: { ...prizes[index] }, // Clone the item to allow cancel
    });
  };

  const handleChange = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    let { name, value, valueAsNumber, type } = e.target;
    if (type === 'number' && name === "chance") {
      if (valueAsNumber > maxRate) valueAsNumber = maxRate;
      if (valueAsNumber < minRate) valueAsNumber = minRate;
    }

    setEditingItems({
      ...editingItems,
      [index]: {
        ...editingItems[index],
        [name]: type === 'number' ? valueAsNumber : value,
      },
    });
  };

  const handleCheckbox = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    let { name, checked } = e.target;
    setEditingItems({
      ...editingItems,
      [index]: {
        ...editingItems[index],
        [name]: checked,
      },
    });
  };

  const handleCancel = (index: number) => {
    const updatedEditingItems = { ...editingItems };
    delete updatedEditingItems[index];
    setEditingItems(updatedEditingItems);
  };

  const handleSaveAll = async () => {
    await savePrizes(prizes);
  };

  const handleDelete = async (indexToDelete: number) => {
    // stop editing prize if in edit state
    // delete the prize
    // determine list of prizes to upsert to DB done
    //  - only upsert prizes with _id
    // determine list of prizes to display
    //  - display prizes with and without _id
    //  - prizes with _id should be scaled
    //  - prizes wihtout _id should still appear in the edit state
    handleCancel(indexToDelete);
    let prizeToDelete = prizes[indexToDelete];
    const prizeArrAfterDelete = prizes.filter((prize, i) => i !== indexToDelete);
    const prizesWithId = prizeArrAfterDelete.filter((prize) => prize._id);
    const prizesWithoutId = prizeArrAfterDelete.filter((prize) => !prize._id);
    const prizesToUpsert = recalculatePercentages(prizesWithId);

    if (prizeToDelete._id) {
      await deletePrize(prizeToDelete);
      await upsertPrizes(prizesToUpsert);
    }

    // Adjust the editingItems state
    const updatedEditingItems = {};
    Object.keys(editingItems).forEach((key) => {
      const keyIndex = parseInt(key, 10);
      if (keyIndex < indexToDelete) {
        // Keep items with indices before the deleted one unchanged
        updatedEditingItems[keyIndex] = editingItems[keyIndex];
      } else if (keyIndex > indexToDelete) {
        // Decrement indices for items after the deleted one
        updatedEditingItems[keyIndex - 1] = editingItems[keyIndex];
      }
    });

    setPrizes([...prizesToUpsert, ...prizesWithoutId]);
    setEditingItems(updatedEditingItems);
  };

  const createWorkflowsMap = (workflows: Workflow[]) => {
    let map = {};
    for (const wf of workflows) {
      map[wf._id] = wf.name;
    }
    return map;
  }

  useInitialMount(async () => {
    const prizes = await getPrizes();
    const workflows = await getWorkflows();
    setPrizes(prizes);
    setWorkflowsArray(workflows);

    const workflowsMap = createWorkflowsMap(workflows);
    setWorkflowsMap(workflowsMap)
  });

  type SelectionCheckboxProps = {
    prize: Prize;
    index: number;
    checkBoxType: string;
  };

  const SelectionCheckbox = ({ prize, index, checkBoxType }: SelectionCheckboxProps) => {
    if (editingItems[index]) {
      return (
        <Checkbox
          checked={editingItems[index][checkBoxType]}
          name={checkBoxType}
          onClick={(e) => handleCheckbox(index, e)}
        />
      );
    }
    if (prize[checkBoxType]) {
      return <Check />;
    }
    return <Clear />;
  };

  return (
    <Box display="flex" flexDirection="column">
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell >{t('nameCol')}</StyledTableCell>
              <StyledTableCell >{t('tProduct')} {t('typeCol')}</StyledTableCell>
              <StyledTableCell >{t('winRate')}</StyledTableCell>
              <StyledTableCell >{t('unitColorCol')} {t('tSelection')}</StyledTableCell>
              <StyledTableCell >{t('unitSizeCol')} {t('tSelection')}</StyledTableCell>
              <StyledTableCell >{t('quantityCol')} {t('tSelection')}</StyledTableCell>
              <StyledTableCell >{t('tMax')} {t('quantityCol')}</StyledTableCell>
              <StyledTableCell >{t('Amount Winnable Today')}</StyledTableCell>
              <StyledTableCell >{t('Workflow')}</StyledTableCell>
              <StyledTableCell >{t('Geo-restricted')}</StyledTableCell>
              <StyledTableCell >{t('Latitude')} DD</StyledTableCell>
              <StyledTableCell >{t('Longitude')} DD</StyledTableCell>
              <StyledTableCell >{t('Radius (miles)')}</StyledTableCell>
              <StyledTableCell ></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prizes &&
              prizes.map((prize, index) => {
                return (
                  <TableRow sx={{ backgroundColor: 'var(--gray000)' }} hover key={index}>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <TextField
                          type="string"
                          name="name"
                          value={editingItems[index].name}
                          size="small"
                          onChange={(e) => handleChange(index, e)}
                        />
                      ) : (
                        <p>{prize.name}</p>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <TextField
                          type="string"
                          name="type"
                          value={editingItems[index].type}
                          size="small"
                          onChange={(e) => handleChange(index, e)}
                        />
                      ) : (
                        <p>{prize.type}</p>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <TextField
                          type="number"
                          name="chance"
                          size="small"
                          value={editingItems[index].chance}
                          // defaultValue={`Prize #${index+1}`}
                          onChange={(e) => handleChange(index, e)}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: { min: minRate, max: maxRate },
                          }}
                        />
                      ) : (
                        <p>{prize.chance} %</p>
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <SelectionCheckbox prize={prize} index={index} checkBoxType={'colorSelection'} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <SelectionCheckbox prize={prize} index={index} checkBoxType={'sizeSelection'} />
                    </StyledTableCell>
                    <StyledTableCell>
                      <SelectionCheckbox prize={prize} index={index} checkBoxType={'quantitySelection'} />
                    </StyledTableCell>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <TextField
                          type="number"
                          name="maxQuantity"
                          size="small"
                          value={editingItems[index].maxQuantity}
                          // defaultValue={`Prize #${index+1}`}
                          disabled={!editingItems[index].quantitySelection}
                          onChange={(e) => handleChange(index, e)}
                          InputProps={{
                            inputProps: { min: 0, max: 10 },
                          }}
                        />
                      ) : prize.quantitySelection ? (
                        <p>{prize.maxQuantity}</p>
                      ) : (
                        <Remove />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                    {editingItems[index] ? (
                        <TextField
                          type="number"
                          name="amountWinnableToday"
                          size="small"
                          value={editingItems[index].amountWinnableToday}
                          onChange={(e) => handleChange(index, e)}
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                        />
                      ) : prize.amountWinnableToday ? (
                        <p>{prize.amountWinnableToday}</p>
                      ) : (
                        <AllInclusive />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <Select
                          type="string"
                          name="workflow"
                          size="small"
                          value={editingItems[index].workflow}
                          defaultValue={''}
                          // defaultValue={`Prize #${index+1}`}
                          // disabled={!editingItems[index].workflow}
                          onChange={(e) => handleChange(index, e)}
                        >
                          <MenuItem value={null}>&nbsp;</MenuItem>
                          {workflowsArray.map((workflow, index) => (
                            <MenuItem key={index} value={workflow._id}>{workflow.name}</MenuItem>
                          ))}
                        </Select>
                      ) : prize.workflow ? (
                        <p>{workflowsMap[prize.workflow]}</p>
                      ) : (
                        <Remove />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      <SelectionCheckbox prize={prize} index={index} checkBoxType={'isGeoRestricted'} />
                    </StyledTableCell>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <TextField
                          type="string"
                          name="latitude"
                          size="small"
                          value={editingItems[index].latitude}
                          disabled={!editingItems[index].isGeoRestricted}
                          onChange={(e) => handleChange(index, e)}
                        />
                      ) : prize.isGeoRestricted ? (
                        <p>{prize.latitude}</p>
                      ) : (
                        <Remove />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <TextField
                          type="string"
                          name="longitude"
                          size="small"
                          value={editingItems[index].longitude}
                          disabled={!editingItems[index].isGeoRestricted}
                          onChange={(e) => handleChange(index, e)}
                        />
                      ) : prize.isGeoRestricted ? (
                        <p>{prize.longitude}</p>
                      ) : (
                        <Remove />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <TextField
                          type="number"
                          name="radius"
                          size="small"
                          value={editingItems[index].radius}
                          disabled={!editingItems[index].isGeoRestricted}
                          onChange={(e) => handleChange(index, e)}
                        />
                      ) : prize.isGeoRestricted ? (
                        <p>{prize.radius}</p>
                      ) : (
                        <Remove />
                      )}
                    </StyledTableCell>

                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>
                      {editingItems[index] ? (
                        <Button onClick={() => handleCancel(index)}>{t('cancelButton')}</Button>
                      ) : (
                        <Button onClick={() => handleEdit(index)}>
                          <BorderColorOutlined sx={{ color: 'var(--blue210)' }} />
                        </Button>
                      )}
                      <Button onClick={() => handleDelete(index)}>
                        <DeleteOutlined />
                      </Button>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
        <Button variant="contained" color="info" style={{ width: '100%' }} onClick={handleAdd}>
          + {t('genericTableAdd')}
        </Button>
      </TableContainer>
      {Object.keys(editingItems).length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <Button onClick={handleSaveAll} variant="contained" color="success" >{t('doneButton')}</Button>
        </div>
      )}
    </Box>
  );
};

export default SlotMachinePrizes;
