import { Box, styled } from '@mui/system';
import { Button, ButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import ESign from '@components/ESign';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import { Close } from '@assets/Icons';
import { EsignSignatureType, ElectronicSignatureEventType } from './commonForms';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import useDebounce from '@common/hooks/useDebounce';
import Styles from '@styles/CreateSignatureMobile.module.css'
import CreateSignatureMobileConversationTitle from '@components/CreateSignatureMobileConversationTitle';
import { morphIndentType } from '@common/MorphType';


const StyledButton = styled(Button)<any>(() => ({
  textTransform: 'none',
  width: '100%',
  height: '50px',
  color: 'black',
  borderRadius: '8px',
}));

const CreateSignatureMobile = () => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const windowDimensions = useWindowDimensions();
  const { t } = useTranslation();
  const [saveCounter, setSaveCounter] = useState(0);
  const [, setForceRenderCounter] = useState(0);

  const canvasBoundaryRef = errandContext.canvasBoundaryRef;
  const signatureTypeRef = errandContext.signatureTypeRef;

  const handleRotation = () => {
    if (window.innerHeight > window.innerWidth && isMobileOrTablet() && !errandContext.rotationLockedRef.current) {
      errandContext.newFormEvent(ElectronicSignatureEventType.CloseCreateSignatureMobile);
      rootContext.setCreateSignatureMobileIsOn(false);
      errandContext.setMobileIndentType(morphIndentType.FormGenerate);
    }

    if (window.innerHeight > window.innerWidth) {
      errandContext.rotationLockedRef.current = false
    }
  };

  useDebounce(() => handleRotation(), 200, [windowDimensions]);

  // const closeCreateSignatureMobile = () => {
  //   errandContext.newFormEvent(ElectronicSignatureEventType.CloseCreateSignatureMobile);
  //   rootContext.setCreateSignatureMobileIsOn(false);
  //   errandContext.rotationLockedRef.current = true

  //   if (window.innerHeight > window.innerWidth) {
  //     errandContext.rotationLockedRef.current = false
  //   }
  // };

  const ButtonCommonHandler = () => {
    // Force re-render
    setForceRenderCounter((prev) => ++prev);

    if (canvasBoundaryRef.current[signatureTypeRef.current].x.min !== Infinity) {
      errandContext.setMobileIndentType(morphIndentType.FormClickToClear);
    } else {
      errandContext.setMobileIndentType(morphIndentType.FormGenerate);
    }
  };

  const SignatureButtonHandler = () => {
    if (signatureTypeRef.current !== EsignSignatureType.Signature) {
      // Here we do tab change (Initials ---> Signature)
      signatureTypeRef.current = EsignSignatureType.Signature;
      ButtonCommonHandler();
    } else {
      // Tab is already selected so we will perform save operation
      setSaveCounter((prev) => ++prev);
    }
  };

  const InitialsButtonHandler = () => {
    if (signatureTypeRef.current !== EsignSignatureType.Initials) {
      // Here we do tab change (Signature ---> Initials)
      signatureTypeRef.current = EsignSignatureType.Initials;
      ButtonCommonHandler();
    } else {
      // Tab is already selected so we will perform save operation
      setSaveCounter((prev) => ++prev);
    }
  };

  const buttonIconWrapper = (type: EsignSignatureType) => {
    if (type === EsignSignatureType.Signature) {
      if (errandContext.wetSignature) {
        return (
          <img
            className={Styles.esignMobileImg}
            alt={`${t('tUser')} ${t('tSignature')}`}
            src={errandContext.wetSignature}
          />
        );
      }

      if (signatureTypeRef.current === EsignSignatureType.Signature && errandContext.mobileIndentType === morphIndentType.FormClickToClear) {
        return t('saveButton');
      } else {
        return t('tSignature');
      }
    } else if (type === EsignSignatureType.Initials) {
      if (errandContext.wetInitial) {
        return (
          <img
            className={Styles.esignMobileImg}
            alt={`${t('tUser')} ${t('tInitials')}`}
            src={errandContext.wetInitial}
          />
        );
      }
      if (signatureTypeRef.current === EsignSignatureType.Initials && errandContext.mobileIndentType === morphIndentType.FormClickToClear) {
        return t('saveButton');
      } else {
        return t('tInitials');
      }
    } else {
      console.error('wrong EsignSignatureType');
    }
  };

  return (
    <Box className={Styles.mobileSignContainer}>
      <div className={Styles.portraitTitle}>
        <CreateSignatureMobileConversationTitle />
      </div>
      <Box className={Styles.canvasContainer}>
        <ButtonGroup className={Styles.buttonGroup} size="large" orientation="horizontal">
          <StyledButton
            onClick={SignatureButtonHandler}
            className={`${
              signatureTypeRef.current === EsignSignatureType.Signature
                ? errandContext.wetSignature
                  ? Styles.selectedAndDone
                  : Styles.signatureButtonPressed
                : Styles.signatureButton
              }
              ${errandContext.wetSignature && Styles.greenAndDone}
              ${
                errandContext.mobileIndentType === morphIndentType.FormClickToClear &&
                signatureTypeRef.current === EsignSignatureType.Signature &&
                !(errandContext.wetInitial && errandContext.wetSignature)
                  ? Styles.green
                  : null
              }
            `}
          >
            {buttonIconWrapper(EsignSignatureType.Signature)}
          </StyledButton>
          <StyledButton
            onClick={InitialsButtonHandler}
            className={`${
              signatureTypeRef.current === EsignSignatureType.Initials
                ? errandContext.wetInitial
                  ? Styles.selectedAndDone
                  : Styles.initialsButtonPressed
                : Styles.initialsButton
              }
              ${errandContext.wetInitial && Styles.greenAndDone}
              ${
                errandContext.mobileIndentType === morphIndentType.FormClickToClear &&
                signatureTypeRef.current === EsignSignatureType.Initials &&
                !(errandContext.wetInitial && errandContext.wetSignature)
                  ? Styles.green
                  : null
              }
            `}
          >
            {buttonIconWrapper(EsignSignatureType.Initials)}
          </StyledButton>
        </ButtonGroup>
        <div className={Styles.signatureLineWrapper}>
          <Close className={Styles.closeIcon} />
          <Box className={Styles.signatureLine} />
        </div>
        {/* <IconButton className={Styles.minimizeIcon} onClick={closeCreateSignatureMobile}>
          <Minimize />
        </IconButton> */}
        <ESign
          saveCounter={saveCounter}
          setIndentType={errandContext.setMobileIndentType}
          btnGroupWidth={80}
        />
      </Box>
    </Box>
  );
};

export default CreateSignatureMobile;
