interface IValidatorFunctions {
  [key: string]: TrueValidatorFunc;
}
type TrueValidatorFunc = (val: any) => boolean;
type ValidatorFunc =
  | {
      (val: any): boolean;
    }
  | string;
interface Validator {
  run: () => boolean;
}

interface IValidatorFunctionsDefined {
  isNotUndefinedNorNull: (val: any) => boolean;
  isNotEmptyString: (val: any) => boolean;
  isUndefinedOrNull: (val: any) => boolean;
  isEmptyStr: (val: any) => boolean;
  isTypeOfString: (val: any) => boolean;
  isTypeOfArray: (val: any) => boolean;
  isNotEmptyArray: (val: any) => boolean;
  isTypeOfObject: (val: any) => boolean;
  isTypeOfNumber: (val: any) => boolean;
  isEmptyObject: (val: any) => boolean;
}

// const ValidatorFunctions: IValidatorFunctions = {};
// ValidatorFunctions.isEmptyStr = (val) => {
//   return val === '';
// };
// ValidatorFunctions.isTypeOfString = (val) => {
//   return typeof val === 'string';
// };
// ValidatorFunctions.isUndefinedOrNull = (val) => {
//   return val === undefined || val === null;
// };
// ValidatorFunctions.isNotUndefinedNorNull = function (val) {
//   return !this.isUndefinedOrNull(val);
// };
// ValidatorFunctions.isNotEmptyString = function (val) {
//   return !this.isEmptyStr(val);
// };
function bindMethodsToObject(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'function') {
      obj[key] = obj[key].bind(obj);
    }
  }
}

const ValidatorFunctions: IValidatorFunctions & IValidatorFunctionsDefined = {
  isNotUndefinedNorNull: function (val) {
    return !this.isUndefinedOrNull(val);
  },
  isNotEmptyString: function (val) {
    return !this.isEmptyStr(val);
  },
  isUndefinedOrNull: function (val) {
    return val === undefined || val === null;
  },
  isEmptyStr: function (val) {
    return typeof val === 'string' && val.length === 0;
  },
  isNotEmptyStr: function (val) {
    return typeof val === 'string' && val.length > 0;
  },
  isTypeOfString: function (val) {
    return typeof val === 'string' || typeof val === 'object' && val instanceof String;
  },
  isTypeOfArray: function (val) {
    return Array.isArray(val);
  },
  isNotEmptyArray: function(val) {
    return Array.isArray(val) && val.length > 0;
  },
  isTypeOfObject: function(val) {
    return typeof val === 'object';
  },
  isTypeOfNumber: function(val) {
    return typeof val === 'number';
  },
  isEmptyObject: function(val) {
    return Object.keys(val).length === 0;
  }
};

// bind all this vals to its enclosing object
bindMethodsToObject(ValidatorFunctions);

const Validators = {
  isEmptyStr: 'isEmptyStr',
  isTypeOfString: 'isTypeOfString',
  isUndefinedOrNull: 'isUndefinedOrNull',
  isNotUndefinedNorNull: 'isNotUndefinedNorNull',
  isNotEmptyString: 'isNotEmptyString',
  isTypeOfArray: 'isTypeOfArray',
  isNotEmptyArray: 'isNotEmptyArray'
};

const defErrHandler = (val, validationResArr, validators) => {
  // uncomment if precise debugging needed
  // console.warn('Value failed some step in validation process!', val, validationResArr, validators);
};

const createValidator = (val: any, validatorFuncs: ValidatorFunc[], errCb = defErrHandler): Validator => {
  // map all validator functions to TrueFunctions if 'string' is detected (which could be an id of a function)
  const mappedValidatorFuncsArr = validatorFuncs.map((func) => {
    if (typeof func === 'string') {
      if (func in ValidatorFunctions) {
        return ValidatorFunctions[func] as TrueValidatorFunc;
      } else {
        console.error('Wrong identifier passed to validator functions arr!');
        return () => null;
      }
    } else {
      return func as TrueValidatorFunc;
    }
  });

  /**
   *
   * @summary Function that runs all validator functions against 'value' and returns res of validation.
   */
  const run = () => {
    // validator func arr is empty
    if (validatorFuncs.length === 0) {
      console.error('No Validator Functions provided to ValidatorFuncs arr!');
    }

    let validatorsResArr = [];

    for(const func of mappedValidatorFuncsArr) {
      try {
        validatorsResArr.push(func(val))
      }
      catch(err) {
        validatorsResArr.push(false);
      }
    }
    // const validatorsResArr = mappedValidatorFuncsArr.map((func) => {
    //   return func(val);
    // });

    // check if there is invalid validator function (returning something else rather than boolean)
    if (validatorsResArr.filter((validatorResult) => typeof validatorResult !== 'boolean').length > 0) {
      console.error('Invalid validator function provided! Each validator function should return boolean value!');
      return false;
    }

    // if any of the res was false, it means validation failed
    if (validatorsResArr.includes(false)) {
      return false;
    } else {
      return true;
    }
  };

  return {
    run,
  };
};
export { ValidatorFunctions, createValidator, Validators };
