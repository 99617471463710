import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
  useTheme
} from '@mui/material'
import React, { useState, useCallback, useRef } from 'react';

import AddOperatorDialog from './AddOperatorDialog';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOperatorDialogAdmin from './EditOperatorDialogAdmin';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import StandardTablePagination from './StandardTablePagination';
import TableHeaderBar from './TableHeaderBar';
import dayjs from 'dayjs';
import downloadOperatorReport from './DownloadOperatorReport';
import { getStatusColors } from '../Common/StatusColors';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useControllerCallback from '@common/hooks/useControllerCallback';
import useInitialMount from '@common/hooks/useInitialMount';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

interface OverflowBoxProps {
  maxWidth: string | number;
}

const OverflowBox = styled(Box)<OverflowBoxProps>(({ maxWidth }) => ({
  display: 'block',
  maxWidth: typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

interface StatusBoxProps {
  statusColor: {
    main?: string,
    hover?: string,
    text?: string
  };
}
const StatusBox = styled(Box)<StatusBoxProps>(({ statusColor }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: statusColor.main,
  borderRadius: '16px',
  color: statusColor.text,
  height: '24px',
  fontWeight: '500'
}));

const Operators = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [addOperatorOpen, setAddOperatorOpen] = useState(false);
  const [editOperatorState, setEditOperatorState] = useState({ open: false, data: null });
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [accessLevels, setAccessLevels] = useState([]);

  const paginationSteps = [15, 50, 100];
  const [search, setSearch] = useState('');
  const pageRef = useRef(0);
  const rowsPerPageRef = useRef(paginationSteps[0]);
  const showInactiveRef = useRef(false);
  
  const [errorMessage, setErrorMessage] = useState('');
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [operatorCount, setOperatorCount] = useState(0);
  const noResults = dataToDisplay.length === 0;

  const loadData = useCallback(async (controller?: AbortController) => {
    setLoading(true);
    const data = search.length > 0 ? { search: search } : '';
    let offset = rowsPerPageRef.current === -1 ? 0 : pageRef.current * rowsPerPageRef.current;
    let limit = rowsPerPageRef.current + 1;

    let url = `operator/participants/access?order=asc&orderBy=lastname&offset=${offset}`;
    if (rowsPerPageRef.current !== -1) {
      url += `&limit=${limit}`;
    }
    // This determines if to show inactive operators or not.
    if (!showInactiveRef.current) {
      url += '&active=true';
    }
    //This is called from the AI tab, it will filter messages to level 0
    if (props.level0){
      url += `&accessLevel=Level 0`;
    }
    let request = undefined;
    request = {
      url,
      method: 'POST',
      data: data,
    };
    
    const config = controller ? { signal: controller.signal } : {};

    try {
      const result = await axiosCall(request, config);
      if (result?.length > 0) {
        // See if we were able to fetch the one additional row, therefore indicating more data is available
        setIsMoreDataAvailable(rowsPerPageRef.current > 0 ? result?.length > rowsPerPageRef.current : false);
        setDataToDisplay(rowsPerPageRef.current > 0 ? result?.slice(0, rowsPerPageRef.current) : result);
        setLoading(false);
        try {
          const a = showInactiveRef.current ? "" : "active=true"
          const result = await axiosCall({ url: `operator/count?${a}` ,method: 'GET',});
          if (result) {
            setOperatorCount(result)
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  },[search]);

  const formatDateTime = (value) => {
    if (Date.parse(value)) {
      return dayjs(value).format('MM/DD/YY hh:mm:ss A');
    }
    return '';
  };

  const loadAccessLevels = useCallback(async (controller?: AbortController) => {
    try {
      const config = controller ? { signal: controller.signal } : {};
      // Get all access levels, filter out level 0 since it is for AI
      let result = await axiosCall({ url: `operator/accessLevel` }, config);
      result = result.filter(level => level !== 'Level 0');
      setAccessLevels(result);
    } catch (error) {
      console.log(error);
    }
  }, [accessLevels]);

  const handleDownloadOperatorReport = async () => {
    try {
      const result = await axiosCall({ url: `operator/participants/access?order=asc&orderBy=lastname` });
      if (result) {
        downloadOperatorReport(result);
      }
    } catch (error) {
      console.log(error);
    }
  };


  /*************************************
   * Add Operator Dialog Functions
   *************************************/
  const handleAddOperatorOpen = () => {
    setAddOperatorOpen(true);
  };

  const handleAddOperatorClose = () => {
    setAddOperatorOpen(false);
    setErrorMessage('');
  };

  const handleAddOperatorSubmit = async ({ email }) => {
    if (await addOperator(email)) {
      setAddOperatorOpen(false);
      setErrorMessage('');
    }
  };

  const addOperator = async (email) => {
    try {
      const result = await axiosCall({ url: 'operator', method: 'POST', data: { email: email } });
      if (result) {
        await loadData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.message || 'Error');
      return false;
    }
  };

  /*************************************
   * Edit Operator Dialog Functions
   *************************************/
  const handleEditOperatorOpen = (operator) => {
    setEditOperatorState({ open: true, data: operator });
  };

  const handleEditOperatorClose = () => {
    setEditOperatorState({ open: false, data: null });
    setErrorMessage('');
  };

  const handleEditOperatorSubmit = async (data) => {
    if (editOperator(data)) {
      setEditOperatorState({ open: false, data: null });
      setErrorMessage('');
    }
  };

  const handleShowInactive = async () => {
    showInactiveRef.current = !showInactiveRef.current;
    pageRef.current = 0;
    await loadData();
  };

  const handleReactivate = async (id) => {
    try {
      let result = await axiosCall({
        url: `operator/${id}`,
        method: 'PUT',
        data: {
          active: true,
        },
      });

      if (result) {
        await loadData();
      }
    } catch (error) { }
  };

  const handleOperatorDeactivate = async (id) => {
    try {
      const result = await axiosCall({
        url: `operator/${id}`,
        method: 'DELETE',
      });
      if (result) {
        await loadData();
        setErrorMessage('');
      }
    } catch (error) {
      return false;
    }
  };

  const editOperator = async (data) => {
    try {
      const result = await axiosCall({
        url: `operator/${data.id}`,
        method: 'PUT',
        data: {
          accessLevel: data?.accessLevel,
          team: data.team,
          subTeam: data.subTeam,
        },
      });
      if (result) {
        await loadData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  /*************************************
   * Pagination Functions
   *************************************/
  const handleChangePage = (_, newPage) => {
    pageRef.current = newPage;
    loadData();
  };

  const handleChangeRowsPerPage = (e) => {
    rowsPerPageRef.current = e.target.value;
    pageRef.current = 0;
    loadData();
  };

  const handleSearchChange = (newSearchValue: string) => {
    // We want to reset to the first page when searching so we don't miss any documents 
    // (for example, we don't want to begin our search on the second page 
    // as we would miss those on the first)
    pageRef.current = 0;
    // Now we can update the search value
    setSearch(newSearchValue);
  };

  // load initial data on mount
  useControllerCallback(loadData);

  useInitialMount(loadAccessLevels);

  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 175px)" padding={1}>
      <TableHeaderBar
        onAdd={handleAddOperatorOpen}
        setSearch={handleSearchChange}
        onRefresh={loadData}
        onExport={handleDownloadOperatorReport}
        addLabel={t('addOperatorButton')}
        searchValue={search}
        showInactiveClick={handleShowInactive}
        isActive={!showInactiveRef.current}
      />
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="200px">{t('nameCol')}</StyledTableCell>
                    <StyledTableCell width="120px">{t('nicknameCol')}</StyledTableCell>
                    <StyledTableCell width="120px">{t('emailCol')}</StyledTableCell>
                    <StyledTableCell width="150px">{t('dutyCol')}</StyledTableCell>
                    <StyledTableCell width="100px">{t('departmentCol')}</StyledTableCell>
                    <StyledTableCell width="100px">{t('teamCol')}</StyledTableCell>
                    <StyledTableCell width="100px">{t('subTeamCol')}</StyledTableCell>
                    <StyledTableCell width="60px">{t('accessLevelCol')}</StyledTableCell>
                    <StyledTableCell width="50px">{t('statusCol')}</StyledTableCell>
                    <StyledTableCell width="130px">{t('lastAccessCol')}</StyledTableCell>
                    <StyledTableCell width="30px">{t('actionCol')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToDisplay?.map((operator) => {
                    const active = operator.active ? 1 : 0;
                    return (
                      <TableRow
                        sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }}
                        data-id={operator?._id}
                        hover
                        key={operator?._id}>
                        <StyledTableCell>
                          {operator.firstname} {operator.lastname}
                        </StyledTableCell>
                        <StyledTableCell>{operator.nickname}</StyledTableCell>
                        <StyledTableCell>{operator.email}</StyledTableCell>
                        <StyledTableCell>{operator.dutyDescription}</StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox maxWidth="150px">
                            {operator.departmentDescription}
                          </OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox maxWidth="250px">{operator.team}</OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>
                          <OverflowBox maxWidth="250px">{operator.subTeam}</OverflowBox>
                        </StyledTableCell>
                        <StyledTableCell>{operator?.accessLevel}</StyledTableCell>
                        <StyledTableCell>
                          <StatusBox
                            statusColor={getStatusColors(operator.status, theme)}>
                            {t(`${operator.status}Status`)}
                          </StatusBox>
                        </StyledTableCell>
                        <StyledTableCell width="40px">{formatDateTime(operator.lastAccess?.createdAt)}</StyledTableCell>
                        {operator.accessLevel !== 'Level 0' && <StyledTableCell align="center">
                          <Tooltip
                            title={t('editOperatorTooltip')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <BorderColorOutlinedIcon
                              onClick={() => handleEditOperatorOpen(operator)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '15px', color: 'var(--blue210)' }}
                            />
                          </Tooltip>
                          {active ? (
                            <Tooltip
                              title={t('deleteOperator')}
                              placement="top-start"
                              enterDelay={1000}
                              enterNextDelay={1000}>
                              <DeleteOutlinedIcon
                                onClick={() => handleOperatorDeactivate(operator?._id)}
                                fontSize="small"
                                sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t('reactivateOperator')}
                              placement="top-start"
                              enterDelay={1000}
                              enterNextDelay={1000}>
                              <RestoreFromTrashOutlinedIcon
                                onClick={() => handleReactivate(operator?._id)}
                                fontSize="small"
                                sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                              />
                            </Tooltip>
                          )}
                        </StyledTableCell>}
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      dataSize={search ? dataToDisplay.length : operatorCount}
                      steps={paginationSteps}
                      rowsPerPage={rowsPerPageRef.current}
                      page={pageRef.current}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </>
        )}
      </TableContainer>
      {addOperatorOpen && (
        <AddOperatorDialog
          open={addOperatorOpen}
          error={errorMessage}
          onClose={handleAddOperatorClose}
          onSubmit={handleAddOperatorSubmit}
        />
      )}
      {editOperatorState.open && (
        <EditOperatorDialogAdmin
          open={editOperatorState.open}
          operator={editOperatorState.data}
          accessLevels={accessLevels}
          error={errorMessage}
          onClose={handleEditOperatorClose}
          onSubmit={handleEditOperatorSubmit}
        />
      )}
    </Box>
  );
}

export default Operators;
