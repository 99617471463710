import React from 'react';
import { LinearProgress, Stack } from '@mui/material';
const animationGif = process.env.REACT_APP_MORGAN_CDN + '/Images/animation.gif';
const logoText = process.env.REACT_APP_MORGAN_CDN + '/Images/LogoText.png';

const ConsoleLoading = (props) => {

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: '100vh', overflow: 'hidden' }}>
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <img src={animationGif} alt="AngelAi animation" width="500" />
        <img src={logoText} alt="AngelAi logo" width="300" />

      </Stack>
      <LinearProgress variant="determinate" value={props.progress} sx={{ width: '300px' }} />
    </Stack>
  );
}

export default ConsoleLoading;
