import React, { useState, useEffect, useRef } from 'react';

import Styles from '../../Styles/TypingAnimation.module.css';

const hiddenClassStr = [Styles.letter, Styles.opacityZero].join(' ');
const shownClassStr = [Styles.letter, Styles.opacityFull].join(' ');

export const Letters = (props) => {
  return props.string.split('').map((char, idx) => {
    return (
      <span key={`${char}_${idx}`} className={idx < props.shownCount ? shownClassStr : hiddenClassStr}>
        {char === ' ' ? '\u00A0' : char}
      </span>
    );
  });
};

const TypingAnimation = (props) => {
  const [shownLettersCount, setShownLettersCount] = useState(0);
  const htmlRef = useRef(null);

  useEffect(() => {
    let currentCharIndex = 0;
    const typingInterval = setInterval(() => {
      if (currentCharIndex <= props.text.length) {
        setShownLettersCount((prev) => prev + 1);
        currentCharIndex += 1;
      } else {
        clearInterval(typingInterval);
      }
    }, props.speed);

    return () => clearInterval(typingInterval);
  }, [props.text, props.speed]);

  return (
    <p ref={htmlRef}>
      <Letters string={props.text} shownCount={shownLettersCount} />
    </p>
  );
};

export default TypingAnimation;
