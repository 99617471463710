/**
 * @file This component provides a style wrapper for the
 * corresponding component from the filename.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { styled } from '@mui/system';

// NOTICE: theme.palette variables should follow the below format
// "componentName" { "emmetShortcutForAttributeAndTargetClassName": "hex or rgba only" }
// Get emmetShortcut from this cheat sheet: https://docs.emmet.io/cheat-sheet/
// Try to avoid passing props and use dynamic classes instead so that the css will be static
export const Styles = styled('div')(
  ({ theme }) => `
    background-color: ${theme.palette.shadow['400']};
    border-radius: 0 5px 0 5px;
    bottom: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    height: fit-content;
    left: 0;
    max-width: calc(100% - 8px);
    padding: 10px;
    position: absolute;
    width: fit-content;
    z-index: 4;
    &:empty {
      display: none;
    }
    span {
      position: relative;
    }
    .PSFThumb {
      background-color: ${theme.palette.gray['000']};
      border-radius: 0 5px 0 5px;
      border: ${theme.palette.gray['040']};
      box-shadow: ${theme.palette.shadow['050']} 0 5px 35px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 5px;
      .PSFNail {
        width: fit-content;
        margin-left: auto;
        padding: 0;
        .PSFCloseIcon {
          width: 20px;
          height: 20px;
          cursor: pointer;
          color: var(--peach730);
          &:hover {
            color: var(--peach810);
          }
        }
      }
      .PSFFileHolder {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        .MuiCircularProgress-root {
          position: absolute;
        }
        img {
          height: 100px;
          object-fit: contain;
          padding: 5px;
          width: 120px;
        }
        p {
          font-size: 0.75rem;
          font-family: 'Poppins';
          max-width: 100px;
          overflow: hidden;
          padding: 2.5px 5px;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0;
        }
      }
    }
  `
);
