import React from 'react';

export const ErrorMessage = ({ children }) => {
  return (
    <>
      <div className='errorMessage'
        style={{
          color: 'var(--red400)',
          fontWeight: '500',
          textAlign: 'center',
          fontSize: '14px',
          padding: '0 6px',
          width: '85%',
          maxWidth: '661px',
        }}
      >
        {children}
      </div>
    </>
  );
};
