import React, { useState, useCallback, useEffect } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Styles from '../Config/ExpandFullMode.module.css';
import { useTranslation } from 'react-i18next';
import useInitialMount from '@common/hooks/useInitialMount';
import axiosCall from '@services/axios';
import { getChatId, getUserId } from '@storage/userStorage';

export const ExpandFullModeElement = () => {
  const { t } = useTranslation();
  const [href, setHref] = useState(`${process.env.REACT_APP_PUBLIC_URL}/`);
  
  // Run after mount
  useEffect(() => {
    const getHashkey = async () => {
      try {
        const chatId = getChatId();
        const _id = getUserId();
        if (!chatId || !_id) return;
  
        const response = await axiosCall({
          url: 'hashkey',
          method: 'post',
          data: { parameters: { chatId, _id } },
        });
  
        if (!response.key) return;
        setHref(`${process.env.REACT_APP_PUBLIC_URL}/invite/${response.key}`);
      } catch (error) {
        console.error(`Launch Full Mode encountered error - ${error.message}`);
      }
    }
    
    getHashkey();
  }, []);

  return (
    <>
      <a className={Styles.link} href={href} target="_blank">
        <OpenInNewIcon />
        <p>{t('AT_expandFullMode')}</p>
      </a>
    </>
  );
};
