import { Button, Dialog, DialogActions, DialogContent, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import DialogHeader from './DialogHeader';
import axiosCall from '../Services/axios';
import { useTranslation } from 'react-i18next';
import { IErrand } from '@interfaces/Conversation';

const RenameUserDialog = (props: { errand: IErrand; operatorData: { firstname: string; _id: any; }; onClose: () => void; open: boolean; }) => {
  const [name, setName] = useState('');
  const { t } = useTranslation();

  const onRename = async () => {
    let payload: {url: string; method: string; data: any} = {
      url: `chat/${props.errand?._id}`,
      method: 'put',
      data: {
        displayName: name
      }
    }
    await axiosCall(payload);

    // Notifications are translated by the system so using static english is ok here
    let msg = '[name] renamed the chat to [chat]'; //t('renameGroupNotification');
    let smallName = props.operatorData?.firstname.toLowerCase();
    msg = msg.replace('[name]', smallName.charAt(0).toUpperCase() + smallName.slice(1));
    msg = msg.replace('[chat]', name);

    payload = {
      url: `chat/${props.errand?._id}/message`,
      method: 'post',
      data: {
        message: msg,
        messageType: 'Notification',
        sender: props.operatorData?._id,
        senderType: 'Operator'
      }
    }
    await axiosCall(payload);

    props.onClose();
  }

  useEffect(() => {
    setName(props.errand?.displayName);
  }, [props.errand?.displayName]);

  return (
    <Dialog open={props.open} onClose={() => props.onClose()}>
      <DialogHeader title={t('renameGroupDialogTitle')} onClose={() => props.onClose()} />
      <DialogContent>
        <Stack spacing={2} pt={2}>
          <TextField
            label={t('renameGroupDialogName')}
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => props.onClose()}> {/**variant="consoleOutlined" */}
          {t('cancelButton')}
        </Button>
        <Button
          variant="contained"
          onClick={onRename}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RenameUserDialog;
