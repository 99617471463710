import { useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axiosCall from '@services/axios';
import useInitialMount from '@common/hooks/useInitialMount';
import LanguageUtils from '@common/LanguageUtils.js';
import useDebounce from '@common/hooks/useDebounce';
import useControllerCallback from "@common/hooks/useControllerCallback";
import useAbortController from "@common/hooks/useAbortController";

const LIMIT = 15;

/**
 * Retrieves the list of workflows that can be triggered in a chat.
 * @param config 
 * @returns 
 */
const fetchWorkflows = async (config: AbortController) => {
  let data = await axiosCall(
    { url: `workflow?limit=${LIMIT}&active=true&visibility.operator=true&fields=_id,name,workflowIcon` },
    config
  );
  return data;
}

const searchWorkflows = async (searchString: string, config: AbortController) => {
  const searched = await axiosCall(
    {
      url: 'workflow/db/search?active=true&visibility.operator=true&fields=_id,name,workflowIcon',
      method: 'post',
      data: {
        search: searchString
      }
    },
    config
  );
  return searched;
}

export default function useLoadWorkflows(selectedTab: string) {
  const [workflows, setWorkflows] = useState([]); // The searched retults to display
  const [hasMoreWorkflows, setHasMoreWorkflows] = useState(true);
  const [workflowsOffset, setWorkflowsOffset] = useState(1);
  const [workflowSearchString, setWorkflowSearchString] = useState('');
  // Contains the names of the workflows, translated into the current language
  // -- will be empty if the current language is set to English.
  const [translatedWorkflowNames, setTranslatedWorkflowNames] = useState([]);
  const { i18n } = useTranslation();
  const abortController = useAbortController();

  const loadWorkflows = async () => {
    const config = abortController.get();
    // Get all workflows
    try {
      let tempWorkflows = await fetchWorkflows(config);
      if (tempWorkflows) {
        setWorkflows(tempWorkflows);
        setWorkflowsOffset(1);
        setHasMoreWorkflows(true);
      }
    } catch (e) {
      console.error(e);
    }
  }

  // Get all workflows
  useInitialMount(() => {
    (async () => {
      if (workflows.length === 0) {
        await loadWorkflows();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  // Search Workflows
  const handleWorkflowSearch = async () => {
    const config = abortController.get();

    if (workflowSearchString.length > 0) {
      // We have a search string
      if (selectedTab !== 'consoleConversationsQuickRequests') {
        const searched = await searchWorkflows(workflowSearchString, config);
        if (searched){
          // set this result
          setWorkflows(searched);
        }
      }
    } else {
      // There is no search, display regular
      setWorkflows(workflows);
    }
  };
  // Use the custom debounce hook
  useDebounce(handleWorkflowSearch, 500, [workflowSearchString]);

  // This use-effect is used to translate the workflow names if necessary:
  const translateWorkflows = useCallback(async (controller) => {
    let origMsgs = [];
    for (let i = 0; i < workflows.length; i++) {
      origMsgs.push({message: workflows[i]?.name, source: 'en'});   // only push if the workflows exist
    }
    const currentLang = i18n.language.split('-')[0].toLowerCase();
    const config = controller ? {
      signal: controller.signal
    } : {};
    // Assume if the current language is not English, the action names must
    // be translated:
    if (currentLang !== 'en' && origMsgs?.length > 0) {
      try {
        let translatedList = await LanguageUtils.translateMany(origMsgs, currentLang, config);
        setTranslatedWorkflowNames(translatedList);
      } catch (err) {
        setTranslatedWorkflowNames([]);
      }
    }
    // eslint-disable-next-line
  }, [i18n.language, workflows.length]);

  useControllerCallback(translateWorkflows);

  return {
    workflows,
    setWorkflows,
    loadWorkflows,
    hasMoreWorkflows,
    setHasMoreWorkflows,
    workflowsOffset,
    setWorkflowsOffset,
    translatedWorkflowNames,
    setWorkflowSearchString
  };
};



