import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import DialogHeader from './DialogHeader';
import { useTranslation } from 'react-i18next';

/**
 * This component takes the following props:
 *  - open: A boolean for whether or not the dialog should be open 
 *  - onClose: The function that should be run when the user attempts to close the window 
 *  - onResend: The function that should be run when the user attemps to resend the workflow 
 */

const ConfirmResendWorkflowDialog = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogHeader title={t('confirmResendTitle')} onClose={props.onClose} />
      <DialogContent>
        <Typography>
          {t('confirmResendBody')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          {t('cancelButton')}
        </Button>
        <Button variant="contained" onClick={props.onResend}>
          {t('okButton')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmResendWorkflowDialog;
