/**
 * @file    This file contains the definition of the `MorganError` exception class.
 * @author  Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

/*
========================================================================================================================
Class definition
========================================================================================================================
*/

/**
 * This exception is the base-class for all other custom exceptions thrown throughout the Morgan project.
 * 
 * It is not necessarily meant to be thrown on its own--rather it exists for the purpose of semantic correctness, and
 * possibly for the purpose of differentiating custom exceptions from built-in ones.
 */
class MorganError extends Error {
  
  constructor(message) {
    super(message);
    console.error(`${this.constructor.name} was thrown: ${message}`);
  }
}

export default MorganError;