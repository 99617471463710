import React, { useRef } from 'react';

const useInitialMount = (callback: () => void) => {
  const isInitialMount = useRef<boolean>(true);

  if (isInitialMount.current) {
    isInitialMount.current = false;
    if (typeof callback === 'function') {
      callback();
    }
  }
};

export default useInitialMount;