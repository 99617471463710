import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultElement } from './templates/DefaultElement';
import { IconStyles } from '../Constants';
import { SlotMachine } from '@assets/Icons';

export const SlotMachineElement = memo(() => {
  const { t } = useTranslation();

  return <DefaultElement text={t('AT_slotMachine')} icon={<SlotMachine style={{ ...IconStyles.svg }} />} />;
});
