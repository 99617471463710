import { useRef } from 'react';

const useTimeoutStorage = () => {
  const timeouts = useRef<NodeJS.Timeout[]>([]);

  const addTimeout = (timeoutID: NodeJS.Timeout): void => {
    timeouts.current.push(timeoutID);
  };

  const clearAllTimeouts = (): void => {
    timeouts.current.forEach(clearTimeout);
    timeouts.current = [];
  };

  const getTimeouts = () => timeouts.current;

  return { addTimeout, clearAllTimeouts, getTimeouts };
};

export default useTimeoutStorage;
