/**
* @file This component is a stylized IconButton component
* for generating Icons. 
* 
* Extracted from ConversationMessage.js 10/11/2022
* 
* @author Harrison Fales <harrison.fales@swmc.com>
* Original Component Author
* @author Christos Ferderigos <christos.ferderigos@swmc.com>
*/

import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

const StyledIconButton = styled(IconButton)({
  width: '1.25em',
  height: '1.25em',
});

export default StyledIconButton;