import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';

import { Close } from '@assets/Icons';
import React, { FC, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';
import * as JSHINT from 'jshint';
import { useUserContext } from '../Contexts/user';

const beautify = require('js-beautify/js');

const AIFunctionDialogEdit: FC<DialogPropsWithChildren> = (props) => {
  const { t } = useTranslation();
  console.log('ai function is', props.AIFunction)
  const [AIFunctionName, setAIFunctionName] = useState(props.AIFunction?.name || '');
  const [AIFunctionDescription, setAIFunctionDescription] = useState(props.AIFunction?.description || '');
  const [AIFunction, setAIFunction] = useState(props.AIFunction?.function || '');
  const [AIFunctionActive, setAIFunctionActive] = useState(props.AIFunction?.active ?? true)
  const [reviewState, setReviewState] = useState(props.AIFunction?.reviewState || '')
  const [reviewedBy, setReviewedBy] = useState(props.AIFunction?.reviewedBy || '')
  const [reviewComment, setReviewComment] = useState(props.AIFunction?.reviewComment || '');
  const { _id } = useUserContext();

  const [isFullscreen, setIsFullscreen] = useState(false); // Fullscreen state for the Ace Editor
  const editorRef = useRef(null); // Ref for the Ace Editor container

  const toggleFullscreen = () => {
    if (!editorRef.current) return;

    const isCurrentlyFullscreen = document.fullscreenElement === editorRef.current ||
      document.mozFullScreenElement === editorRef.current ||
      document.webkitFullscreenElement === editorRef.current ||
      document.msFullscreenElement === editorRef.current;

    if (!isCurrentlyFullscreen) {
      // Request fullscreen
      if (editorRef.current.requestFullscreen) {
        editorRef.current.requestFullscreen();
      } else if (editorRef.current.mozRequestFullScreen) {
        editorRef.current.mozRequestFullScreen();
      } else if (editorRef.current.webkitRequestFullscreen) {
        editorRef.current.webkitRequestFullscreen();
      } else if (editorRef.current.msRequestFullscreen) {
        editorRef.current.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      // Check if the current fullscreen element is the editor container
      const isEditorFullscreen = (document.fullscreenElement === editorRef.current ||
        document.mozFullScreenElement === editorRef.current || // Firefox
        document.webkitFullscreenElement === editorRef.current || // Chrome, Safari and Opera
        document.msFullscreenElement === editorRef.current); // IE/Edge
      setIsFullscreen(isEditorFullscreen);
    };

    // Listen for fullscreen change events
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange); // Firefox
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // Chrome, Safari and Opera
    document.addEventListener('MSFullscreenChange', handleFullscreenChange); // IE/Edge

    // Cleanup the event listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []); // Empty array ensures this effect only runs once on mount

  const handleReviewComment = (e) => {
    setReviewComment(e.target.value)
    setReviewedBy(_id);
  }

  const handleReviewState = (e) => {
    setReviewState(e.target.value)
    setReviewedBy(_id);
  }

  const handleHotkey = (event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault();  // Prevent default behavior of the Enter key
      checkCodeForErrors();   
    }
    else if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();  // Prevent default behavior of the Enter key
      formatCode();    
    }
  };

  useEffect(() => {
    // Add event listener when component mounts
    document.addEventListener('keydown', handleHotkey);

    // Cleanup: remove event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleHotkey);
    };
  }, [AIFunction]);  // Dependency array ensures this effect runs again if AIFunction changes

  const formatCode = () => {
    const formattedCode = beautify.js(AIFunction, { indent_size: 2, space_in_empty_paren: true });
    setAIFunction(formattedCode);
  };
  
  function checkCodeForErrors() {
    const jshintConfig = {
      esversion: 12, 
    };
    // Need to wrap the code in an async function otherwise it'll yell at us
    const codeToCheck = `
      (async () => {
        ${AIFunction}
      })();
    `;
    JSHINT.JSHINT(codeToCheck, jshintConfig);
    const errors = JSHINT.JSHINT.errors;
    if (errors.length > 0) {
      const { line, reason } = errors[0]; // Taking the first error as an example
      alert(`Error at line ${line - 2}: ${reason}`);
    } else {
      alert("No errors found ✅");
    }
  }

  /*************************************
   * Submit Function
  *************************************/
 const handleSubmit = async (closeDialog) => {
    let data = {
      // _id: undefined,
      name: AIFunctionName,
      description: AIFunctionDescription,
      function: AIFunction,
      reviewState: reviewState,
      reviewedBy: reviewedBy,
      reviewComment: reviewComment,
      active: AIFunctionActive,
    };

    console.log(`${props.edit ? 'edi' : 'submit'}ting ai task with data`, data);
    if (data.name === '' || data.function.length === 0){
      return;
    }
    if (!props.edit) {
      props.onAdd(data, closeDialog);
    } else {
      data = { ...data, _id: props.AIFunction?._id };
      props.onSubmit(data, closeDialog);
    }
  };

  const reviewStates = ['Review Pending', 'Changes Requested', 'Approved', 'Denied']

  return (
    <Dialog maxWidth={false} open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{props.edit ? t('editFunction') : t('createFunction')}</Typography>
          <Close
            style={{ width: '12px', cursor: 'pointer' }}
            color="var(--gray210)"
            onClick={() => props.onClose()}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '70vw' }}>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <FormControlLabel
              sx={{ marginLeft: '0' }}
              control={
                <Checkbox
                  checked={AIFunctionActive}
                  onChange={(e) => {
                    setAIFunctionActive(e.target.checked);
                  }}
                />
              }
              label={t('activeTranslation')}
              labelPlacement="end"
            />
        </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <TextField
             
              autoFocus
              label={`${t('nameTranslation')}*`}
              onChange={(e) => setAIFunctionName(e.target.value)}
              size="small"
              value={AIFunctionName}
              variant="outlined"
            />
            <TextField
             
              label={`${t('descriptionTranslation')}*`}
              onChange={(e) => setAIFunctionDescription(e.target.value)}
              size="small"
              value={AIFunctionDescription}
              variant="outlined"
            />
            <div ref={editorRef} style={{ position: 'relative' }}>
            <AceEditor
              mode="javascript"
              theme="monokai"
              onChange={(newValue) => setAIFunction(newValue)}
              name="AIFunctionEditor"
              editorProps={{ $blockScrolling: true }}
              value={AIFunction}
              setOptions={{
                useWorker: false 
              }}
              style={{ width: '100%', height: isFullscreen ? '100vh' : '400px' }}
            />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="primary" onClick={formatCode}>Format Code (⌘ + k)</Button>
                <Button variant="contained" color="secondary" onClick={checkCodeForErrors}>Check for Errors (⌘ + ↵)</Button>
              </Stack>
              <Button
              variant="contained"
              onClick={toggleFullscreen}
              >{'full screen'}</Button>
            </div>
             {/* <TextField
             
              label={`${t('tFunction')}*`}
              multiline
              onChange={(e) => setAIFunction(e.target.value)}
              rows={7}
              size="small"
              sx={{...mt}}
              value={AIFunction}
              variant="outlined"
            /> */}
            <TextField
              select
              size="small"
              color="info"
              variant="outlined"
              label={`${t('reviewState')}*`}
              value={reviewState}
              SelectProps={{ MenuProps: { PaperProps: { sx: { maxHeight: 500, } } } }}
              onChange={handleReviewState}
            >
              <MenuItem key={0} value=" ">&nbsp; </MenuItem>
              {
                reviewStates.map((state, index) => {
                  return <MenuItem key={index} value={state}>{state}</MenuItem>
                })
              }
            </TextField>
            <TextField
              label={t('reviewComment')}
              multiline
              onChange={handleReviewComment}
              rows={3}
              size="small"
              value={reviewComment}
              variant="outlined"
            />
            <TextField
              label={`${t('reviewedBy')}`}
              size="small"
              value={reviewedBy}
              variant="outlined"
            />
          </Box>
        <Typography>{props.error}</Typography>
      </DialogContent>
      <DialogActions>
      <Button 
        disabled={AIFunctionName==="" || AIFunction==="" || reviewState==="" || AIFunctionDescription===""} 
        variant="contained" color="success" 
        onClick={() => handleSubmit(false)}
        >
            {t('saveButton')}
        </Button>
        <Button 
        disabled={AIFunctionName==="" || AIFunction==="" || reviewState==="" || AIFunctionDescription===""} 
        variant="contained" color="info" 
        onClick={() => handleSubmit(true)}
        >
            {t('saveButton')} & {t('closeButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AIFunctionDialogEdit;