import { Box } from '@mui/system';
import { IconButton, Button } from '@mui/material';
import useDebounce from '@common/hooks/useDebounce';
import React, { useState, useContext } from 'react';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { useTranslation } from 'react-i18next';
import Styles from '@styles/MorphFormOpen.module.css'
const ESignHandShake = process.env.REACT_APP_MORGAN_CDN + '/Images/ESignHandShake.png';


const MorphFormOpenTopBox = () => {
  const [debounceCounter, setDebounceCounter] = useState(0);
  const errandContext = useContext<IErrandContext>(ErrandContext);

  useDebounce(() => errandContext.createFormHandler(undefined), 200, [debounceCounter]);

  return (
    <Box className={Styles.wrapper}>
      <IconButton className={Styles.handshakeIcon}
        onClick={() => setDebounceCounter((prev) => ++prev)}>
        <img src={ESignHandShake} alt="success image of two hands shaking" />
      </IconButton>
    </Box>
  );
};

const MorphFormOpenIndent = () => {
  const { t } = useTranslation();
  const [debounceCounter, setDebounceCounter] = useState(0);
  const errandContext = useContext<IErrandContext>(ErrandContext);

  useDebounce(() => errandContext.createFormHandler(undefined), 200, [debounceCounter]);

  const ButtonHandler = () => {
    setDebounceCounter((prev) => prev + 1);
  };

  return (
    <Button className={Styles.button} variant="contained" onClick={ButtonHandler}>
      {t('buttonStartForm')}
    </Button>
  );
};

export { MorphFormOpenTopBox, MorphFormOpenIndent };
