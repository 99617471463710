import { Stack, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TranslateIcon from '@mui/icons-material/Translate';
import { setPreselectedLanguage } from '../Storage/userStorage';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { map, SetLanguageFromGeolocation } from '../Common/LocationInformation';

const LanguagePicker = (props) => {
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const [value, setValue] = useState(map[0].lang);
  const color = props.color ? props.color : theme.palette.peach['900'];

  const handleChange = (event) => {
    handleNewLanguage(event.target.value);
  };

  const handleNewLanguage = (input) => {
    setValue(input);
    i18n.changeLanguage(input);
    setPreselectedLanguage(input);
  }

  useEffect(() => {
    // Sets the language based on the users's location or from local storage
    SetLanguageFromGeolocation()
      .then((res) => {
        handleNewLanguage(map[res].lang);
      })
      .catch((error) => {
        console.error("endpoint location/stored API doesn't work" + error.message);
      });
    // eslint-disable-next-line
  }, [])

  return (
    <Tooltip title={t('appBarLanguage')} placement="left">
      <Stack direction="row" spacing={1} alignItems="center">
        <Select
          sx={{ color: color }}
          variant="standard"
          value={props.simple ? '' : value}
          onChange={handleChange}
          disableUnderline
          MenuProps={{
            ...(props.topMenu && {
              elevation: 0,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              sx: {
                transform: 'translate(-5px, -15px);',
              },
            }),
          }}
          IconComponent={() => (
            <TranslateIcon
              sx={{
                color: color,
                ...(props.simple && {
                  position: 'absolute',
                  left: '.5rem',
                  cursor: 'pointer',
                  pointerEvents: 'none',
                }),
              }}
            />
          )}>
          {map.map((item, i) => (
            <MenuItem key={i} value={item.lang}>
            {item.nativeName}
          </MenuItem>
          ))}
        </Select>
      </Stack>
    </Tooltip>
  );
};

export default LanguagePicker;