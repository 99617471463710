import { styled } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import getSvgFilter from '../Common/GetSvgFilter';
import Menu from '@mui/material/Menu';
import { IconButton } from '@mui/material';

export const Styles: React.FC<any> = styled(IconButton)<any>(
  ({ theme }) => `
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-Index: 2; // set to be higher than z-index of morphRecording of 1 
    overflow: visible;
    color: var(--peach500);
    &:hover, &:focus {
      color: var(--orange600);
      img {
        filter: ${getSvgFilter(theme.palette.orange['600'])} !important;
      }
    }
    svg {
      font-size: 25px;
      pointer-events: none;
      height: 25px;
      width: 25px;
    }
    img {
      height: 25px;
      width: 25px;
      filter: ${getSvgFilter(theme.palette.orange['500'])}
    }
    &.FSColor {
      color: var(--orange600)
      img {
        filter: ${getSvgFilter(theme.palette.orange['600'])}
      }
    }
  `
);

export const FSMenu: React.FC<any> = styled(Menu)<any>(
  ({ theme }) => `
  z-index: 1050;
  &>div {
    background: transparent;
    ul {
      background: transparent;
    }
  }
  `
);

export const FSMenuItem: React.FC<any> = styled(MenuItem)<{shouldHide?: boolean}>(
  ({ theme, shouldHide }) => `
  padding: 0;
  margin-bottom: 5px;
  :hover {
    svg {
      color: var(--orange600);
      background-color: var(--gray000);
    }
  }
  label {
    cursor: pointer;
    display: block;
    width: 35px;
    height: 35px;   
    input {
      display: none !important;
    }
    svg {
      pointer-events: none;
      cursor: pointer;
      color: var(--gray000);
      border-radius: 50%;
      background-color: var(--orange600);
      padding: 5px;
      width: 100%;
      height: 100%;    
    }
  }
  &.mobile {
    display: ${theme.breakpoints.up('md')} ? 'none' : 'block';
  }
  ${theme.breakpoints.up('lg')} {
    display: ${shouldHide ? 'none' : 'flex'};
  }
  &:hover {
    background-color: transparent;
  }
  `
);