import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useErrandContext } from '@contexts/ErrandContext';
import { IFormContext, FormContext } from '@contexts/FormContext';
import {
  EsignFormType,
  FormTypeDetector,
  ElectronicSignatureEventType,
  EsignActionStateType,
} from '../Forms/commonForms';

import useInitialMount from '@common/hooks/useInitialMount';
import useDebounce from '@common/hooks/useDebounce';
import { useSocketContext } from '@contexts/socket';
import axiosCall from '../Services/axios';

// left and right side border pixel size has to be substracted from parent component dimention
// in order to make slider work perfectly
const divHeightOffset = 72;

const ElectronicSignatureUser = (props) => {
  const { errand } = props;

  const [dummyCounter, setDummyCounter] = useState<number>(0);

  const formContext = useContext<IFormContext>(FormContext);
  const { isMessagesConnected, messagesSocket } = useSocketContext();
  const errandContext = useErrandContext();

  const map = formContext.signatureMapRef;

  const loadEConsent = async () => {
    try {
      // load default navigation index
      map.current = ['authLast'];

      const requestData = {
        url: `chat/${errandContext.errandId}/getsigneddocument`,
        method: 'post',
        data: {
          formName: 'econsent',
          trueTimeZone: formContext.timeZoneGrabber(),
          trueBrowser: formContext.browserTypeGrabber(),
        },
      };

      const signal = formContext.abortControllerWrapper();

      const response = await axiosCall(requestData, signal);

      if (response.documentData && response.documentData.docStatus === 'signed') {
        // Document Already signed
        formContext.mainSignatureInsertedEventHandler();
        errandContext.setEsignActionState(EsignActionStateType.Signed);
      }

      formContext.setFormData(response.documentData);
    } catch (error) {
      console.error('Error loading ECONSENT data', error);
    }
  };

  const loadBCERTA = async () => {
    try {
      // load default navigation index
      map.current = [
        'auth1',
        'auth2',
        'auth3',
        'auth4',
        'auth5',
        'auth6',
        'auth7',
        'auth8',
        'auth9',
        'auth10',
        'auth11',
        'auth13',
        'auth15',
      ];

      const requestData = {
        url: `chat/${errandContext.errandId}/getsigneddocument`,
        method: 'post',
        data: {
          formName: EsignFormType.BCERTA,
          trueTimeZone: formContext.timeZoneGrabber(),
          trueBrowser: formContext.browserTypeGrabber(),
        },
      };

      const signal = formContext.abortControllerWrapper();

      const response = await axiosCall(requestData, signal);

      const { purpose10_11_14, refinanceLoan, nonRetailLoan, correspondentLoan, allInitialsSigned, ...initials } =
        response.initialsInfo;
      const { initialImageData, signatureImageData, signedImageId, docStatus } = response.documentData;

      const grabbedFormMetaData = {
        purpose10_11_14,
        refinanceLoan,
        nonRetailLoan,
        correspondentLoan,
        allInitialsSigned,
        signedImageId,
        docStatus,
      };

      formContext.setFormMetaData(grabbedFormMetaData);

      // if document is already signed, go to Insert Signature view
      if (initialImageData && signatureImageData) {
        // update signature information if it's already signed
        errandContext.setWetInitial(initialImageData);
        errandContext.setWetSignature(signatureImageData);

        errandContext.newFormEvent(ElectronicSignatureEventType.GoToInsertSignatureView);

        formContext.mainSignatureInsertedEventHandler();
        errandContext.setEsignActionState(EsignActionStateType.Signed);
      }

      const initialLength = Object.keys(initials).length;

      formContext.setBcertaData((prevState) => {
        if (initialLength > 0) {
          return initials;
        } else {
          return prevState;
        }
      });

      if (grabbedFormMetaData['purpose10_11_14'] && map.current.indexOf('auth16') === -1) {
        map.current.splice(4, 0, 'auth16');
      }

      if (grabbedFormMetaData['correspondentLoan'] === false && map.current.indexOf('auth17') === -1) {
        map.current.push('auth17');
      }
      if (
        grabbedFormMetaData['refinanceLoan'] &&
        !grabbedFormMetaData['nonRetailLoan'] &&
        map.current.indexOf('auth12') === -1
      ) {
        map.current.push('auth12');
      }

      if (grabbedFormMetaData['nonRetailLoan'] && map.current.indexOf('auth14') === -1) {
        map.current.push('auth14');
      }

      if (map.current.indexOf('authLast') === -1) {
        map.current.push('authLast');
      }
    } catch (error) {
      console.error('Error loading BCERTA data', error);
    }
  };

  const triggerSocketEvent = () => {
    const divHeight = formContext.documentReaderRef.current?.getBoundingClientRect().height - divHeightOffset;
    const docHeight = formContext.documentRef.current?.children[0]?.getBoundingClientRect().height;

    messagesSocket.current?.emit('emit-chat-event', {
      chatId: errandContext.errandId,
      data: {
        type: 'form_operator_view_sync',
        viewPointPercentage: (formContext.scrollPosition + divHeight / 2) / docHeight,
        signatureMapRef: map.current,
        mainSignatureInserted: formContext.mainSignatureInserted,
        eConsentRadioButtonState: formContext.eConsentRadioButtonState,
        bcertaData: formContext.bcertaData,
        wetSignature: errandContext.wetSignature,
        wetInitial: errandContext.wetInitial,
        formBody: errandContext.formBody,
      },
      recipients: [],
    });
  };

  useDebounce(() => triggerSocketEvent(), 500, [
    errandContext.formBody,
    errandContext.wetInitial,
    errandContext.wetSignature,
    formContext.bcertaData,
    formContext.eConsentRadioButtonState,
    formContext.mainSignatureInserted,
    formContext.scrollPosition,
    dummyCounter,
  ]);

  // Initial call to sunsoft to get the state of the form
  const resetFormViewAndLoadForm = useCallback(() => {
    const formName = FormTypeDetector(errand.name);

    if (formName === EsignFormType.ECONSENT) loadEConsent();
    else if (formName === EsignFormType.BCERTA) loadBCERTA();
    else if (formName === EsignFormType.AISIGN) return;
    else console.error('Wrong formName');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInitialMount(resetFormViewAndLoadForm);

  useEffect(() => {
    if (!isMessagesConnected) return;

    const chatEventHandler = (payload) => {
      const data = payload.data;

      if (data.type !== 'form_operator_view_fetch') return;
      // force-re render
      setDummyCounter((prev) => ++prev);
    };

    messagesSocket.current?.on('chat-event-emitted', chatEventHandler);
    return () => {
      messagesSocket.current?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errandContext.errandId]);

  return formContext.EsignBody();
};

export default ElectronicSignatureUser;
