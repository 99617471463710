import {
  Box,
  Collapse,
  InputAdornment,
  ListItemButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState, memo } from 'react';

import Avatar from './Avatar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import eventBus from '../Common/eventBus';
import ScrollBox from './ScrollBox';
import SearchRounded from '@mui/icons-material/SearchRounded';
import getImageSource from '../Common/getImageSource';
import axiosCall from '../Services/axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChatType } from '@common/ChatType';
import { useUserContext } from '@contexts/user';

const OnlineOperatorList = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { _id } = useUserContext();
  const ref = useRef(null);
  const [open, setOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [width, setWidth] = useState(0);
  const [dimensions, setDimensions] = useState(0);

  // In order to resize the operator bar with a scroll element inside,
  // there must be an explicit width declared somewhere. These two
  // useEffects update the width of the operator bar based on the size of the window.
  useEffect(() => {
    setWidth(ref?.current?.offsetWidth);
  }, [open, dimensions]);

  useEffect(() => {
    function handleResize() {
      setDimensions(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  // This useEffect is to update the operators shown depending on the list
  // passed by props
  useEffect(() => {
    updateDataToDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onlineOperators]);

  // This useEffect is to keep the bar open when the use opened it, but have it auto collapse
  // when it opened due to searching
  useEffect(() => {
    if (search) {
      setOpen(true);
    } else if (!userOpen) {
      setOpen(false);
    }
    updateDataToDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const updateDataToDisplay = async () => {
    let realOps = props.onlineOperators;

    if (search) {
      setDataToDisplay(
        realOps.filter((op) => {
          return op?.firstname?.toLowerCase()?.includes(search?.toLowerCase());
        })
      );
    } else {
      setDataToDisplay(realOps);
    }
  };

  const createTeamChat = async (operator, currentOp) => {
    await axiosCall({
      url: 'chat',
      method: 'post',
      data: {
        participants: [
          {
            userId: operator?._id,
            userType: 'Operator',
            primary: true,
          },
          {
            userId: currentOp,
            userType: 'Operator',
            primary: true,
          },
        ],
        type: ChatType.Team,
        status: 'active',
        displayName: operator.firstname
      },
    })
      .then((r) => {
        console.log(`Created new chat with operators ${operator?._id} and ${currentOp}.`);
        navigateToChat(r?._id);
      })
      .catch((e) => {
        console.error(`Error creating new chat with ${operator?._id} and ${currentOp}.`, e);
      });
  };

  const loadOrCreateTeamChat = async (operator) => {
    const currentOp = _id;
    // Get all chats that the using operator is in
    await axiosCall({
      url: `operator/${currentOp}/chat?type=team`,
    })
      .then((r) => {
        // Filter the chats to find the one with the operator that was selected
        const temp = r
          ?.flatMap((d) => d.participants)
          .filter((c) => c.userData?._id === operator?._id);
        if (temp?.length > 0) {
          const theChat = temp[0].chat;
          navigateToChat(theChat);
        } else {
          // Chat with given operator not found, creating new team chat
          createTeamChat(operator, currentOp);
        }
      })
      .catch((e) => {
        console.error(`Getting chats for operator ${currentOp} failed`, e);
      });
  };

  const navigateToChat = (id) => {
    navigate('/console', {
      state: {
        module: 'conversations',
        tab: 'team',
        chat: id,
        join: false,
      },
    });
  };

  return (
    <Stack width="100%" direction="row" ref={ref}>
      <Collapse orientation="horizontal" in={open} timeout={0} sx={{ overflow: 'hidden' }}>
        {true && (
          <Box
            maxWidth={`${width - 300}px`}
            border="1px solid var(--orange600)"
            borderRight="0"
            borderRadius="20px 0px 0px 20px">
            <Box paddingLeft="11px" height="85px">
              {dataToDisplay?.length > 0 && (
                <ScrollBox horizontal sx={{ maxWidth: width, width: 'auto', paddingBottom: '5px' }}>
                  <Stack paddingTop="16px" direction="row" spacing={2}>
                    {dataToDisplay.map((op, index) => {
                      return (
                        <Box
                          key={index}
                          padding="5px"
                          border="1px solid var(--orange600)"
                          borderRadius="40px">
                          <Tooltip title={`${op.firstname} ${op.lastname}`} enterDelay={1000}>
                            <Stack
                              sx={{ cursor: 'pointer' }}
                              onClick={() => loadOrCreateTeamChat(op)}
                              align="center"
                              direction="row"
                              justifyContent="center"
                              alignItems="center">
                              <Avatar
                                status={op.status}
                                alt={op?.firstname}
                                img={op?.avatar?.length !== 0 ? getImageSource(op.avatar) : ''}
                              />
                              <Typography marginLeft="8px" fontWeight="600" fontSize={11}>
                                {op?.firstname}
                              </Typography>
                            </Stack>
                          </Tooltip>
                        </Box>
                      );
                    })}
                    <Stack width="1px" fontSize="0">
                      &nbsp;
                    </Stack>
                  </Stack>
                </ScrollBox>
              )}
              {dataToDisplay?.length === 0 && (
                <Stack height="100%" justifyContent="center" alignItems="center">
                  <Typography>{search ? t('noResults') : t('noOnline')}</Typography>
                </Stack>
              )}
            </Box>
          </Box>
        )}
      </Collapse>
      <Box
        border="1px solid var(--orange600)"
        borderLeft={`1px solid ${open ? 'var(--gray600)' : 'var(--orange600)'}`}
        borderRadius="0px 20px 20px 0px"
        marginRight="11px">
        <Stack
          paddingLeft="11px"
          width="300px"
          height="100%"
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Stack spacing={1}>
            <TextField
              size="small"
              variant="outlined"
              label={t('searchOperatorsLabel')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box>
            <ListItemButton
              sx={{ padding: '0px' }}
              onClick={() => {
                setOpen(!open);
                setUserOpen(!userOpen);
              }}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </ListItemButton>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export default memo(OnlineOperatorList);
