import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json'; // Import for JSON mode
import 'ace-builds/src-noconflict/theme-monokai'; // Import for dark theme, you can choose another if preferred

import { Droppable } from 'react-beautiful-dnd';
import { Close } from '../Assets/Icons';
import React, { FC, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogPropsWithChildren } from '@mTypes/Console';
import getImageSource from '@common/getImageSource';
import { getBase64 } from '@common/getBase64';
import { useDropzone } from 'react-dropzone';



//render the Droppable elements after an animation frame, strictMode causes Droppable to malfunction otherwise.
export const StrictModeDroppable = (props) => {
  const { children, ...rest } = props;
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...rest}>{children}</Droppable>;
};


const mt = {
  mt:"12px"
};


const EditWorkflowDialog: FC<DialogPropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const [workflowName, setWorkflowName] = useState(props.workflow?.name || '');
  const [workflowDisplayName, setWorkflowDisplayName] = useState(props.workflow?.displayName || '');
  const [workflowDescription, setWorkflowDescription] = useState(
    props.workflow?.description || ''
  );
  const [workflowJsonRules, setWorkflowJsonRules] = useState(props.workflow?.jsonRules || '');
  const [actionData, setActionData] = useState(props.workflow?.actions || []);
  const [visibility, setVisibility] = useState(props.workflow?.visibility || {
    ai: true,
    operator: false,
    user: false
  });
  const [workflowIcon, setWorkflowIcon] = useState(props.workflow?.workflowIcon || '');
  const idCounter = useRef(0);

  const [isFullscreen, setIsFullscreen] = useState(false); // Fullscreen state for the Ace Editor
  const editorRef = useRef(null); // Ref for the Ace Editor container

  const toggleFullscreen = () => {
    if (!editorRef.current) return;

    const isCurrentlyFullscreen = document.fullscreenElement === editorRef.current ||
      document.mozFullScreenElement === editorRef.current ||
      document.webkitFullscreenElement === editorRef.current ||
      document.msFullscreenElement === editorRef.current;

    if (!isCurrentlyFullscreen) {
      // Request fullscreen
      if (editorRef.current.requestFullscreen) {
        editorRef.current.requestFullscreen();
      } else if (editorRef.current.mozRequestFullScreen) {
        editorRef.current.mozRequestFullScreen();
      } else if (editorRef.current.webkitRequestFullscreen) {
        editorRef.current.webkitRequestFullscreen();
      } else if (editorRef.current.msRequestFullscreen) {
        editorRef.current.msRequestFullscreen();
      }
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      // Check if the current fullscreen element is the editor container
      const isEditorFullscreen = (document.fullscreenElement === editorRef.current ||
        document.mozFullScreenElement === editorRef.current || // Firefox
        document.webkitFullscreenElement === editorRef.current || // Chrome, Safari and Opera
        document.msFullscreenElement === editorRef.current); // IE/Edge
      setIsFullscreen(isEditorFullscreen);
    };

    // Listen for fullscreen change events
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange); // Firefox
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // Chrome, Safari and Opera
    document.addEventListener('MSFullscreenChange', handleFullscreenChange); // IE/Edge

    // Cleanup the event listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []); // Empty array ensures this effect only runs once on mount

  // Unique ID generator
  const getUniqueId = () => {
    idCounter.current += 1;
    return idCounter.current;
  }

  useEffect(() => {
    const dataWithIds = actionData.map((item) => {
      return { ...item, id: getUniqueId() };
    });

    setActionData(dataWithIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionData.length]);


  /*************************************
   * Submit Function
   *************************************/
  const handleSubmit = () => {
    let data = {
      id: '',
      name: workflowName,
      displayName: workflowDisplayName,
      description: workflowDescription,
      actions: actionData
        .filter((act) => !!act.action)
        .map((act) => {
          return act.action;
        }),
      visibility: visibility,
      workflowIcon: workflowIcon,
      jsonRules: workflowJsonRules
    };
    console.log(data)

    if (!props.edit) {
      props.onAdd(data);
    } else {
      data = { ...data, id: props.workflow.id };
      props.onSubmit(data);
    }
  };

  const beautifyJSON = () => {
    try {
      const current = JSON.parse(workflowJsonRules);
      setWorkflowJsonRules(JSON.stringify(current, null, 2));
    } catch (err) {
      // Assuming err.message is in the format: "Unexpected token <token> in JSON at position <position>"
      const positionMatch = err.message.match(/position (\d+)/);
      if (positionMatch) {
        const position = parseInt(positionMatch[1], 10);
        // Find the line corresponding to the position
        const lines = workflowJsonRules.substring(0, position).split('\n');
        const errorLine = lines.length;
        alert(`ERROR: Unable to parse text as JSON at line ${errorLine}: ${err.message}`);
      } else {
        // Fallback error message if the position cannot be determined
        alert(`ERROR: Unable to parse text as JSON: ${err.message}`);
      }
    }
  };


  /*************************************
 * Icon Upload Functions
 *************************************/

   const onDrop = async (acceptedFiles) => {
    console.log(acceptedFiles);
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      getBase64(file, async (result) => {
        // Get the data portion of the DataURL
        const fileData = result?.split(',')[1];
        console.log(fileData);
        setWorkflowIcon(fileData);

        // try {
        //   const result = await axios(
        //     {
        //       url: `operator/${operatorData?._id}`,
        //       method: 'PUT',
        //       data: {
        //         avatar: fileData
        //       }
        //     });
        //   setOperatorData(result);
        // } catch (error) {
        //   console.log(error)
        // }
      });
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onDrop,
    accept: {
      'image/svg+xml': ['.svg'],
      'image/png': ['.png'],
    },
    maxFiles: 1,
    maxSize: 10000000,
    minSize: 1,
    onDropRejected: null,
  });

  const removeIcon = () => {
    setWorkflowIcon("");
  }

  return (
    <Dialog /*sx={{maxWidth: "60vw"}}*/ maxWidth="md" open={props.open} onClose={() => props.onClose()}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {props.edit ? t('editWorkflow') : t('createWorkflow')}
          </Typography>
          <Close
            style={{ width: '12px', cursor: 'pointer' }}
            color="var(--gray210)"
            onClick={() => props.onClose()}
          />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '40vw' }}>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <FormControlLabel
              sx={{ marginLeft: '0' }}
              control={
                <Checkbox
                  checked={visibility.ai}
                  onChange={(e) => {
                    // Additional ternary to prevent workflows from being visible to users only and not operators
                    setVisibility({ ...visibility, ai: e.target.checked });
                  }}
                />
              }
              label={t('aiVisibleCol')}
              labelPlacement="end"
            />
        <FormControlLabel
              sx={{ marginLeft: '0' }}
              control={
                <Checkbox
                  checked={visibility.operator}
                  onChange={(e) => {
                    // Additional ternary to prevent workflows from being visible to users only and not operators
                    setVisibility({ ...visibility, operator: e.target.checked, user: e.target.checked ? visibility.user : false });
                  }}
                />
              }
              label={t('operatorVisibleCol')}
              labelPlacement="end"
            />
            <FormControlLabel
              sx={{ marginLeft: '0' }}
              control={
                <Checkbox
                  checked={visibility.user}
                  disabled={!visibility.operator}
                  onChange={(e) => {
                    setVisibility({ ...visibility, user: e.target.checked });
                  }}
                />
              }
              label={t('userVisibleCol')}
              labelPlacement="end"
            />
        </div>
          <Stack>
            <TextField
              autoFocus
              label={`${t('nameCol')}*`}
              onChange={(e) => setWorkflowName(e.target.value)}
              size="small"
              sx={{...mt}}
              value={workflowName}
              // variant="outlined"
            />
            <TextField
              label={`${t('displayNameCol')}`}
              onChange={(e) => setWorkflowDisplayName(e.target.value)}
              size="small"
              sx={{...mt}}
              value={workflowDisplayName}
              // variant="outlined"
            />
            <TextField
              label={`${t('descriptionCol')}`}
              multiline
              onChange={(e) => setWorkflowDescription(e.target.value)}
              rows={2}
              size="small"
              sx={{...mt}}
              value={workflowDescription}
              // variant="outlined"
            />
            <div ref={editorRef} style={{ position: 'relative' }}>
            <AceEditor
              mode="json"
              theme="monokai"
              name="workflowJsonRulesEditor"
              onChange={(newValue) => setWorkflowJsonRules(newValue)}
              fontSize={14}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={workflowJsonRules}
              setOptions={{
                useWorker: false,
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              style={{ width: '100%', height: isFullscreen ? '100vh' : '300px' }}
            />
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Button variant="contained" onClick={beautifyJSON}>Parse / Beautify</Button>
              <Button
              variant="contained"
              onClick={toggleFullscreen}
              >{'full screen'}
              </Button>     
            </div>
          </Stack>
        
        <Typography>{props.error}</Typography>
      </DialogContent>
      <DialogActions sx={{width: "100%"}}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} sx={{ width: '100%'}}>
            <Stack flexDirection={'row'} justifyContent={'flex-end'} sx={{ marginBottom: '8px'}}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button color="info" onClick={() => open()}>
                  {t("uploadIcon")}
                </Button>
              </div>
              {workflowIcon &&
                <Stack
                  sx={{
                    border: "1px solid var(--gray040)",
                    borderRadius: "4px",
                    marginLeft: '8px'
                  }}
                  width="150px"
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center">
                  <Tooltip title={t("removeIcon")} placement="left">
                    <Close style={{ width: "12px", cursor: "pointer" }} color="var(--gray210)" onClick={() => removeIcon()} />
                  </Tooltip>
                  <Typography sx={{ marginTop: "2px", textTransform: "Uppercase", color: "var(--gray210)", fontSize: "14px", fontWeight: "500" }} >{t("currentIcon")} </Typography>
                  <Box component="img" width="20px" color="black" height="20px" style={{ filter: "grayscale(100%)" }} src={getImageSource(workflowIcon)}>
                  </Box>
                </Stack>
              }
            </Stack>
            <Stack flexDirection='row' justifyContent='flex-end' sx={{marginLeft: '10px'}}>
              <Button sx={{marginLeft: '8px'}}variant="contained" color="info" onClick={() => handleSubmit()}>
                {t('doneButton')}
              </Button>
            </Stack>            
          </Stack>

        
      </DialogActions>
    </Dialog>
  );
};

export default EditWorkflowDialog;