import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonRemoveOutlined } from '@mui/icons-material';

import { IErrand, IMessage } from '@interfaces/Conversation';
import { useRootContext } from '@contexts/RootContext';
import axiosCall from '@services/axios';
import useAbortController from '@common/hooks/useAbortController';

import Styles from '@styles/MorphMessageOptions.module.css';

type TMorphMessageOptionRemoveUserProps = {
  errand: IErrand;
  handleClose: () => void;
  message: IMessage;
};

const MorphMessageOptionRemoveUser = ({
  errand, handleClose, message
}: TMorphMessageOptionRemoveUserProps) => {
  const { t } = useTranslation();
  const abortController = useAbortController();
  const rootContext = useRootContext();
  const participant = useMemo(() => {
    return (errand.participants || []).find((x) => x?.active && x?.userData?._id === message?.sender?._id)
  }, [errand?.participants, message?.sender?._id]);

  const handleClickRemoveUser = useCallback(async () => {
    try {
      if (!participant) return;

      // setup axios call with controller
      const request = {
        url: `chat/${errand._id}/participant/${participant?._id}`,
        method: 'delete',
        data: {
          removed: `${participant?.userData?.firstname?.charAt(0)?.toLocaleUpperCase() +
            participant?.userData?.firstname?.substr(1)?.toLocaleLowerCase()
            }`,
        },
      };
      const controller = abortController.reset();
      const config = { signal: controller.signal };
      await axiosCall(request, config);

      rootContext.setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        const index = prev.findIndex((e => e._id === errand._id));
        if (index === -1) return prev;
        prev[index].recipients = [];
        return [...prev];
      });

      // clean up
      handleClose();
    } catch (error) {
      console.error(`MorphMessageOptionsDelete.handleClickDelete`, error);
    }
  }, [errand?._id, message?._id, participant?._id, handleClose]);

  return (
    <button className={Styles.btn} onClick={handleClickRemoveUser}>
      <div className={Styles.btnBorder}>
        <PersonRemoveOutlined />
        <p className={Styles.label}>{participant ? t('tRemove') : t('tInactive')}</p>
      </div>
    </button>
  );
};

export default MorphMessageOptionRemoveUser;
