/**
 * @file This file contains the implementation of the `ThinClientDownloadBannerComponent` custom React component.
 * @author Kristoffer A. Wright
 */

/*
========================================================================================================================
FILE CONTENTS:

Since this file is rather large, it is organized into discrete sections marked with headings and horizontal-rules. To
help you find your way, here is an outline of all the major sections, in the order they appear:

- Imports.....................All imports, roughly organized by source package and usage)
- Module constants............All CONSTANT values belonging to the top-level scope of this module)
- Component definition........The beginning of the component definition
- State accessors/mutators....All `useState` invocations belong here
- Refs........................All `useRef` invocations belong here
- Private functions...........Private helper functions not meant to be called outside this module
- Event callbacks.............All event handler callback functions are defined here
- Animation callbacks.........Callback functions for handling changes to the animation state.
- Use-effect callbacks........Callbacks to be used with `useEffect` (Callbacks only! Invocationsin the following sect.)
- Use-effect configuration....All `useEffect` invokations belong here (Must use named callbacks from previous section!)
- Styles......................Styles to be applied to JSX component and sub-components.
- Component JSX...............The JSX definition of the component to be returned.

========================================================================================================================
*/

/*
========================================================================================================================
Imports
========================================================================================================================
*/

// MUI Imports
import {Box} from '@mui/material';
import {Typography} from '@mui/material';
import Slide from '@mui/material/Slide'

// React Imports
import React, {CSSProperties, useContext, useEffect} from 'react';
import {useState} from 'react';
import {useRef} from 'react';
import { useTranslation } from 'react-i18next';

// Common Imports
import { UAParser } from 'ua-parser-js';
import ThinClientUtils from '@common/ThinClientUtils';
import { ErrandContext } from '@contexts/ErrandContext';

/*
========================================================================================================================
Module constants
========================================================================================================================
*/

// SVG
const BannerBackgroundImage = process.env.REACT_APP_MORGAN_CDN + '/Images/ThinClientDownloadBanner-Back.svg';
const BannerButtonImage = process.env.REACT_APP_MORGAN_CDN + '/Images/ThinClientDownloadBanner-Button.svg';

// Fonts
const FONT_NAME = 'Poppins';                            // Font used for text on this component.
const FONT_WEIGHT = '400';                              // Font weight
const FONT_SIZE = '1.0em';                              // Font size
const FONT_SPACING = '.01em';                           // Letter spacing

// Durations
// const BANNER_LIFESPAN_DURATION = 10000;                 // Milliseconds to display the banner.
// const ENTRANCE_ANIMATION_DURATION = 1000;               // Milliseconds to play entrance animation.
// const EXIT_ANIMATION_DURATION = 1000;                   // Milliseconds to play unswiped exit animation.
const TIMER_ANIMATION_WAIT_DURATION = 11000;             // Milliseconds after initial render to begin timer


// URLs
const ANDROID_DOWNLOAD_URL = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.swmc.morganthinclientandroid"; // URL of the android store download page for the thin client.
const IOS_DOWNLOAD_URL = "https://apps.apple.com/us/app/morgan-empathetic-technology/id1667401139"; // URL of the apple store download page for the thin client.

// Raw Element sizes
const RAW_CONTAINER_SIZE = [375, 66];                   // Size of the original, unscaled container element.
const RAW_BACKGROUND_SIZE = [375, 51];                  // Size of the original, unscaled background image.
const RAW_BUTTON_SIZE = [51, 58];                       // Size of the original, unscaled button image.
// const TIMER_HEAD_RADIUS = 5;

// Raw Element positions
const RAW_BACKGROUND_TOP_MARGIN = 15;                   // Px offset the top of the bg is from the container top.
const RAW_BUTTON_BOTTOM_MARGIN = 8;                     // Px offset the bottom of button is from bottom of container.
const TEXT_MARGIN_SIDE = '10%';
const TEXT_MARGIN_TOP = '4%';

// Other
const TIMER_ANIMATION_BEGIN_TIME = (new Date()).getTime() + TIMER_ANIMATION_WAIT_DURATION;

/*
========================================================================================================================
Component definition
========================================================================================================================
*/

/**
 * This component is used to display a promotional banner which implores the user to download either the Android or
 * iOS native app, depending on which mobile user device is detected at the splash screen and passed into the
 * conversation page (and thereby this component) as the `mobileDeviceType` query parameter.
 */

interface BannerProps {
  setThinClientBannerShowing: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThinClientDownloadBannerComponent: React.FC<BannerProps> = ({
  setThinClientBannerShowing
}) => {
  const errandContext = useContext(ErrandContext);
  const { t } = useTranslation();

  /*
  ======================================================================================================================
  State accessors/mutators
  ======================================================================================================================
  */

  /**
   * The `animationState` state variable stores the current phase that the animation is in. 
   * 
   * The following states are used by this component: `AnimationState.UNINITIALIZED`, `AnimationState.TRANSITION_IN`, 
   * `AnimationState.PLAYING`, `AnimationState.PAUSED`, `AnimationState.TRANSITION_OUT`, and `AnimationState.FINISHED`.
   */
  const [animationState, setAnimationState] = useState("UNINITIALIZED");

  /**
   * The `timerTimeState` state variable gives the number of milliseconds that the banner has been visible to the user. 
   * This value is used to derive the return values for the private functions `getTimerPercentage` and `getTimerLength`.
   * 
   * Time begins when the transition-in animation completes and the main animation begins. That is, as soon as the
   * `animationState` is `AnimationState.PLAYING`. 
   * 
   * Note that the timer will not elapse if the component is in the `AnimationState.PAUSED` state. The timer will resume 
   * as soon as the component's state returns to `AnimationState.PLAYING`.
   */
  // const [timerTimeState, setTimerTimeState] = useState(0);

  /**
   * The `timerPathLengthState` state variable stores the length of the timer path, in unit pixels.
   */
  const [timerPathLengthState, setTimerPathLengthState] = useState(0);


  // const [isTimerDelayInit, setIsTimerDelayInit] = useState(false);

  const [timerHeadDelay, setTimerHeadDelay] = useState(0);

  const [isVisible, setIsVisible] = useState(false);

  // const [timerHeadRadius, setTimerHeadRadius] = useState(0);

  /*
  ======================================================================================================================
  Refs
  ======================================================================================================================
  */

  /**
   * The `pathDomRef` stores a reference to the underlying `svg` DOM element for the timer path.
   */
  const pathDomRef = useRef(null);

  const pathAnimationDomRef = useRef(null);

  /*
  ======================================================================================================================
  Private functions
  ======================================================================================================================
  */

  // /**
  //  * Return the percentage of the main timer animation that has already played.
  //  * 
  //  * This value is derived from the state variable `timerTime`, and is limited to values between `0.0` and `100.0`,
  //  * inclusive.
  //  */
  // const _getTimerPercentage = () => {
  //   // TODO Implement ThinClientDownloadBanner._getTimerPercentage
  // }

  // /**
  //  * Return the length of the timer path that is already colored in, in unit pixels.
  //  * 
  //  * This value is derived from the state variable `timerTime`, and is limited to values between `0.0` and the return
  //  * value of `getTimerTotalLength`, inclusive.
  //  */
  // const _getTimerColoredLength = () => {
  //   // TODO Implement ThinClientDownloadBanner._getTimerColoredLength
  // }

  // /**
  //  * Return the total length of the timer path, in unit pixels.
  //  */
  // const getTimerTotalLength = () => {
  //   // TODO Implement ThinClientDownloadBanner._getTimerTotalLength
  // }

  // /**
  //  * Return the total length of the raw timer path, before its image is scaled, in unit pixels.
  //  */
  // const getTimerRawTotalLength = () => {
  //   // TODO Implement ThinClientDownloadBanner._getTimerRawTotalLength
  // }

  // /**
  //  * Return the current position of the center of the timer head, relative to the timer image.
  //  * 
  //  * @returns The return data is always an array of size two, where the zeroth element contains the x-position and the 
  //  * first element contains the y-position.
  //  */
  // const getTimerHeadPosition = () => {
  //   // TODO Implement ThinClientDownloadBanner._getTimerHeadPosition
  // }

  /*
  ======================================================================================================================
  Event callbacks
  ======================================================================================================================
  */

  /**
   * This callback is invoked whenever the banner's button is clicked.
   * 
   * Its purpose is to redirect the user to the appropriate store download page for the Thin Client.
   */
  const handleButtonOnClickEvent = () => {
    // Use UAParser to detect OS and navigate user to the right storefront
    const userDetails = UAParser();
    if (userDetails.os.name === 'Android'){
      window.open(ANDROID_DOWNLOAD_URL, '_blank');
    }
    if (userDetails.os.name === 'iOS'){
      window.open(IOS_DOWNLOAD_URL, '_blank');
    }
  }

  const handleTimerPathAnimationOnBegin = () => {
    setThinClientBannerShowing(true);
    setIsVisible(true);
    setAnimationState("PLAYING");
    console.log('PLAYING')
    // setTimerHeadRadius(TIMER_HEAD_RADIUS);
  }

  const handleTimerPathAnimationOnEnd = () => {
    setThinClientBannerShowing(false);
    setAnimationState("FINISHED");
    setIsVisible(false);
    errandContext.setMorphType(null)
    // setTimerHeadRadius(0);
  }

  

  /*
  ======================================================================================================================
  Animation callbacks
  ======================================================================================================================
  */

  // /**
  //  * Begin playing the transition in animation, where the banner slides in from the top of the conversation footer.
  //  */
  // const beginTransitionInAnimation = () => {
  //   // TODO Implement ThinClientDownloadBanner.beginTransitionInAnimation
  // }

  // /**
  //  * Begin playing the main timer animation.
  //  */
  // const beginMainAnimation = () => {
  //   // TODO Implement ThinClientDownloadBanner.beginMainAnimation
  // }

  // /**
  //  * Pause the main timer animation. This is triggered when swiping begins.
  //  */
  // const pauseMainAnimation = () => {
  //   // TODO Implement ThinClientDownloadBanner.pauseMainAnimation
  // }

  // /**
  //  * Unpause the main timer animation. This is triggered if swiping is cancelled without closing the banner.
  //  */
  // const unpauseMainAnimation = () => {
  //   // TODO Implement ThinClientDownloadBanner.unpauseMainAnimation
  // }

  // /**
  //  * Begin playing the transition out animation, where the banner slides back into the top of the conversation footer.
  //  * 
  //  * This is triggered if the timer animation reaches the end before the banner is closed by the user or opened.
  //  */
  // const beginTransitionOutAnimation = () => {
  //   // TODO Implement ThinClientDownloadBanner.beginTransitionOutAnimation
  // }

  const getAnimationOffsetTime = () => {
    if (timerHeadDelay === 0) {
      let ret = (TIMER_ANIMATION_BEGIN_TIME - (new Date()).getTime()) / 1000;
      setTimerHeadDelay(ret);
      return ret;
    } else {
      return timerHeadDelay;
    }
  }

  /*
  ======================================================================================================================
  Use-effect callbacks
  ======================================================================================================================
  */

  /**
   * This use-effect callback handles all component initialization tasks.
   */
  const handleInitComponentState = () => {
    // Only show the banner on iOS and Android
    const user = UAParser();
    const os = user.os.name;
    if ((os !== 'Android' && os !== 'iOS') || ThinClientUtils.validateBannerTimeToLive() || ThinClientUtils.isThinClient()){
      // Don't display banner if not on ios or android, has been seen in last within TTL time or if user is using the thinclient
      return;
    }

    pathAnimationDomRef.current.addEventListener('beginEvent', handleTimerPathAnimationOnBegin);
    pathAnimationDomRef.current.addEventListener('endEvent', handleTimerPathAnimationOnEnd);
    

    // Calculate the length of the timer path:
    const timerPathLength = pathDomRef.current.getTotalLength();
    setTimerPathLengthState(timerPathLength);

    // Calculate the time when the animation should begin:
    const currentTicks = new Date().getTime();

    setTimeout(() => {setAnimationState("PLAYING");}, TIMER_ANIMATION_BEGIN_TIME - currentTicks);

    ThinClientUtils.resetBannerTimeToLive();
  }

  /*
  ======================================================================================================================
  Use-effect configuration
  ======================================================================================================================
  */

  useEffect(() => {
    handleInitComponentState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*
  ======================================================================================================================
  Styles
  ======================================================================================================================
  */

  // Style for the box component which contains all other sub-components.
  const containerStyle = {
    width: "100%",
    height: RAW_CONTAINER_SIZE[1],                            // Restrict the height to the original image height
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: '0px 0px 0px 0px',
    display: 'flex',
    position: 'fixed',
    left: 'calc(-50vw + 50%)',
    bottom: '60px',
    zIndex:3
  }

  // Style for the banner background image.
  const bannerBackgroundStyle = {
    backgroundImage: `url(${BannerBackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `100% 100%`,
    backgroundPosition: `center center`,
    backgroundColor: 'transparent',
    marginTop: `${RAW_BACKGROUND_TOP_MARGIN}px`,
    width: '100%',
    height: `${RAW_BACKGROUND_SIZE[1]}px`,
    position: 'absolute',
    justifyContent: 'space-between',
    display: 'flex'
  };

  // Style for the banner button image.
  const bannerButtonStyle = {
    backgroundImage: `url(${BannerButtonImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: `${RAW_BUTTON_SIZE[0]}px ${RAW_BUTTON_SIZE[1]}px`,
    backgroundPosition: `center center`,
    width: `${RAW_BUTTON_SIZE[0]}px`,
    height: `${RAW_BUTTON_SIZE[1]}px`,
    marginBottom: `${RAW_BUTTON_BOTTOM_MARGIN}px`,
    position: 'absolute',
    zIndex:9999
  };

  // Style for the banner timer SVG path:
  const bannerPathContainerStyle: CSSProperties = {
    backgroundColor: 'transparent',
    //marginTop: `${RAW_BACKGROUND_TOP_MARGIN - 10}px`,
    width: '100%',
    height: `${RAW_CONTAINER_SIZE[1]}px`,
    position: 'absolute'
  };

  const bannerPathImageStyle = {
    marginTop: `${RAW_BACKGROUND_TOP_MARGIN - 10}px`, 
    height: `${RAW_CONTAINER_SIZE[1]}px`,
    
  };

  // Style for the left-side text ("Download"):
  const leftTextStyle = {
    fontSize: FONT_SIZE,
    font: FONT_NAME,
    fontWeight: FONT_WEIGHT,
    letterSpacing: FONT_SPACING,
    color: `${'var(--gray000)'}`,
    backgroundColor: 'transparent',
    marginLeft: TEXT_MARGIN_SIDE,
    marginTop: TEXT_MARGIN_TOP,
    position: 'relative'
  };

  // Style for the right-side text ("AngelAi"):
  const rightTextStyle = {
    fontSize: FONT_SIZE,
    font: FONT_NAME,
    fontWeight: FONT_WEIGHT,
    letterSpacing: FONT_SPACING,
    color: `${'var(--gray000)'}`,
    backgroundColor: 'transparent',
    marginRight: TEXT_MARGIN_SIDE,
    marginTop: TEXT_MARGIN_TOP,
    position: 'relative'
  };

  /*
  ======================================================================================================================
  Component JSX
  ======================================================================================================================
  */

  // JSX return data
  const ret = (
    <Slide in={isVisible} direction="up">
    <Box sx={containerStyle} >
      <Box sx={bannerBackgroundStyle} >
        <Typography sx={leftTextStyle}>{t('thinClientBannerLeftText')}</Typography>
        <Typography sx={rightTextStyle}>{t('thinClientBannerRightText')}</Typography>
      </Box>
      <Box sx={bannerButtonStyle} onClick={handleButtonOnClickEvent} />
      
      {/* 
        The following component is the timer path. The svg image herein is inlined because it needs to be directly
        accessable from the DOM so that the svg API can be used to resolve the x/y position of the timer head given a
        linear distance along the path. 
      */}
      <div 
        id="bannerTimerPathContainer"
        style={bannerPathContainerStyle}
      >
        <svg 
          id="bannerTimerPathImage"
          width="100%" 
          height="55" 
          viewBox="0 -10 379 65" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
          preserveAspectRatio="none"
          style={bannerPathImageStyle}
        >
          <path
            id="bannerTimerPath"
            // fillRule="evenodd" 
            // clipRule="evenodd" 
            d={
              "M0 0" +                                            // Start at the origin, moving right
              "H122" +
              "C131.921 0 139.121 2.78918 144.847 7.03392" +
              "C150.501 11.225 154.633 16.782 158.491 22.0714" +
              "L158.909 22.6446" +
              "C162.67 27.8046 166.2 32.6472 170.778 36.2953" +
              "C175.434 40.0058 181.216 42.5 189.5 42.5" +
              "C197.784 42.5 203.566 40.0058 208.222 36.2953" +
              "C212.8 32.6472 216.33 27.8046 220.091 22.6447" +
              "L220.509 22.0714" +
              "C224.367 16.782 228.499 11.225 234.153 7.03392" +
              "C239.879 2.78918 247.079 0 257 0" +
              "H378"
            } 
            fill="none"
            strokeWidth="3"
            stroke={'var(--blue982)'}
            ref={pathDomRef}
          >
            {animationState === "PLAYING" && (
              <style>
                {`
                  #bannerTimerPath {
                    stroke-dasharray: ${timerPathLengthState}px;
                    stroke-dashoffset: ${timerPathLengthState}px;
                    animation: timerKeyframes 10s linear forwards;
                  }

                  @keyframes timerKeyframes {
                    0% {
                      stroke-dashoffset: ${timerPathLengthState}px;
                    }
                    100% {
                      stroke-dashoffset: 0px;
                    }
                  }
                `}
              </style>
            )}
            {animationState !== "PLAYING" && (
              <style>
                {`
                  #bannerTimerPath {
                    stroke-dasharray: ${timerPathLengthState}px;
                    stroke-dashoffset: ${timerPathLengthState}px;
                  }
                `}
              </style>
            )} 
          </path>
            <circle
              id="bannerTimerHead"
              cx="0"
              cy="0"
              // r={timerHeadRadius}
              // fill={'var(--blue982)'}
            >
              <animateMotion 
                dur="10.0s"
                path={
                  "M0 0" +                                            // Start at the origin, moving right
                  "H122" +
                  "C131.921 0 139.121 2.78918 144.847 7.03392" +
                  "C150.501 11.225 154.633 16.782 158.491 22.0714" +
                  "L158.909 22.6446" +
                  "C162.67 27.8046 166.2 32.6472 170.778 36.2953" +
                  "C175.434 40.0058 181.216 42.5 189.5 42.5" +
                  "C197.784 42.5 203.566 40.0058 208.222 36.2953" +
                  "C212.8 32.6472 216.33 27.8046 220.091 22.6447" +
                  "L220.509 22.0714" +
                  "C224.367 16.782 228.499 11.225 234.153 7.03392" +
                  "C239.879 2.78918 247.079 0 257 0" +
                  "H378"
                }
                begin={getAnimationOffsetTime()}
                ref={pathAnimationDomRef}
              />
            </circle>
        </svg>
      </div>
    </Box>
    </Slide>
  );

  return ret;

};

export default ThinClientDownloadBannerComponent;