import axiosCall from '../Services/axios';

const getWoodStickResumeData = async (workflowId, chatId, time) => {
  let workflow = await axiosCall({
    url: `workflow/${workflowId}`
  });
  let orderedActions = workflow.actions.map(a => a?._id);

  // lets get all the UserActions in the chat 
  let userActions = await axiosCall({
    url: `chat/${chatId}/action?workflow=${workflowId}&limit=${workflow.actions.length}&before=${time}&orderBy=createdAt&order=desc`
  });
  // And now lets match these up with their actions 
  userActions = await Promise.all(userActions.map(async (ua) => {
    // Get and set the action object 
    let action = await axiosCall({
      url: `action/${ua.action}`
    });
    ua.action = action;
    // Get and set the field object 
    if (ua?.field !== undefined) {
      let field = await axiosCall({
        url: `field/${ua.field}`
      });
      ua.field = field;
      // If this is not resolved and has a field we can assume that its an error 
      if (ua.status !== 'resolved') {
        ua.invalid = true;
        ua.resolved = false;
      }
    }
    return ua;
  }));

  // Since the UserActions were ordered by createdAt they are not necessarially in workflow order.
  // This section orders them according to the workflow.actions.
  userActions = await Promise.all(await userActions.reduce((ordered, nextItem) => {
    let correctPos = orderedActions.findIndex(a => a === nextItem.action?._id);
    if (correctPos > -1) {
      ordered[correctPos] = nextItem;
    }
    return ordered;
  }, Array(userActions.length - 1).fill({})));
  // console.log('DEBUG userActions after ordering', userActions.map(ua => `${ua.action?.description} (${ua.status})`));

  // Remove paused user actions as those are ones which morgan has skipped
  userActions = userActions.filter(ua => ua.status !== 'paused');

  // Cool!
  // Now lets group those UserActions 
  let grouped = await Promise.all(await userActions.reduce((groups, nextItem) => {
    let currentGroup = groups[groups.length - 1];
    let lastItem = currentGroup[currentGroup.length - 1];

    /**
     *  If there was a last UserAction, it was of type Command, and the next 
     * UserAction is a Field type then that means that this Action Command group
     * is ending and that a new one should be created with this newItem. Otherwise,
     * the UserAction is part of the current group and should be added to the end 
     * of the array.
     */
    if (lastItem && lastItem?.action?.type === 'Command' && nextItem?.action?.type === 'Field') {
      let newGroup = [nextItem];
      groups.push(newGroup);
    } else {
      groups[groups.length - 1].push(nextItem);
    }

    return groups;
    // Inital state of an empty 2d array 
  }, [[]]));
  console.log('DEBUG grouped', grouped.map(g => g.map(ua => `${ua.action?.description} (${ua.status})`)));
  let groupIndex = grouped.findIndex(g => g.findIndex(ac => ac.status === 'queued' || (ac.status === 'in-progress' && ac?.field !== undefined)) !== -1);
  // If there was a group found that means that we are resuming, if there wasnt then we are reviewing and should just show the data.
  grouped = groupIndex > -1 ? grouped.splice(0, groupIndex + 1) : grouped.map(g => g.filter(ua => ua.status === 'resolved'));

  // Flatten the groups 
  // Get action for each user action, match fields
  let flattenedUserActions = grouped.flat().filter(ua => ua.action.type === 'Field');
  console.log('DEBUG flattenedUserActions', flattenedUserActions.map(ua => `${ua.action.description} (${ua.status})`));

  let tempWorkflow = workflow;
  tempWorkflow.groupIndex = groupIndex;
  return {
    workflow: tempWorkflow,
    userActions: flattenedUserActions
  }
}

export default getWoodStickResumeData;