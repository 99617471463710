import React, { useState } from 'react';
import { AccessType } from '@common/AccessType';
import Allign from '@styles/Allign';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import MessageTextStyle from '@styles/MessageTextStyle';
import Styles from '@styles/MessageContent/NumberedListMessageContent.module.css';

export default function NumberedListMessageContent({ message }) {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  if (!message) {
    console.log('message is not defined');
    return <></>;
  }

  if (typeof message?.message?.title !== 'string' || !Array.isArray(message?.message?.options)) {
    console.log('Invalid NumberedList message:', message);
    return <></>;
  }

  const { title, options } = message.message;
  return (
    <Allign
      sx={{
        display: 'flex',
        width: 'fit-content',
        flexDirection: 'column',
        maxWidth: '350px',
        minWidth: '85px',
        position: 'relative',
        alignItems:
          // On the user side, Team, and Group chats: only sender is on the right side
          // On the operator side, only sender (operator) and Morgan is on the right side
          message?.alignByCurrentUser ? 'flex-end' : 'flex-start',
      }}
    >
      <ChatBubbleStyle
        sx={{
          pointerEvents: 'none',
          width: 'fit-content',
          minWidth: '85px',
          maxWidth: '100%',
          borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
          background:
            message.accessType === AccessType.internal
              ? 'var(--peach020)'
              : message.sentByCurrentUser
              ? 'var(--gray000)'
              : 'var(--peach600)',
        }}
      >
        <div className={Styles.titleContainer}>
          <MessageTextStyle>{title}</MessageTextStyle>
        </div>
        <div className={Styles.optionsContainer}>
          {options.map((option, idx) => (
            <div className={Styles.optionContainer} key={option}>
              <div className={Styles.optionNumber}>{idx + 1}</div>
              <MessageTextStyle>{option}</MessageTextStyle>
            </div>
          ))}
        </div>
      </ChatBubbleStyle>
    </Allign>
  );
}
