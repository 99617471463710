import React, { SyntheticEvent, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AddLink from '@mui/icons-material/AddLink';
import IconButton from '@mui/material/IconButton';
import Styles from '@styles/ReferrerModal.module.css';
import axiosCall from '@services/axios';
import { TReferrerModalProps } from '@mTypes/TReferrerModal';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ReferrerMessageContent from '@components/MessageContent/ReferrerMessageContent';
import { AxiosError } from 'axios';
import { getOperatorSessionToken } from '@storage/operatorStorage';
import { IErrand, IMessage } from '@interfaces/Conversation';
import useInitialMount from '@common/hooks/useInitialMount';
import useAbortController from '@common/hooks/useAbortController';

const ReferrerModal = (props: TReferrerModalProps) => {
  const { t } = useTranslation();
  const { reset } = useAbortController();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [error, setError] = useState('');
  const [path, setPath] = useState('');
  const [duty, setDuty] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [theme, setTheme] = useState('');
  const [themeFocused, setThemeFocused] = useState(false);
  const [userId, setUserId] = useState('');
  const [errand, setErrand] = useState({
    _id: '',
    messages: [{
      _id: "",
      message: "Maverick <>Manasco <><>SUNWST000<>63f002879504fcf0274207a3<>MM5W<>maverick.manasco@swmc.com<>8502074547<><>",
      operatorView: false,
    }],
    participants: [],
  });

  const toggleOpen = () => {
    setOpen((prev) => !prev);
    setError('');
    setErrand((prev) => {
      prev.messages[0].message = '';
      return { ...prev };
    });
    setIsLoading(false);
  };

  // prevent referrer paths from being entered as https://www.swmc.com/angelai/referrer rather than just referrer
  const getURLFriendlyPath = (providedPath = '') => {
    if (typeof providedPath !== 'string' || providedPath.trim() === '') return '';
    if (providedPath.indexOf('/') === -1) return providedPath.trim().toLowerCase();
    const cleanPath = providedPath.toLowerCase().replace(/[^a-z0-9\/._]/g, '').replace(/(angelai)/g, '');
    if (cleanPath.slice(-1) === '/') {
      return cleanPath.slice(0, -1).split('/').pop();
    }
    return cleanPath.split('/').pop();
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setError('');
    if (!isLoading) {
      setIsLoading(true);
      const pathValue = getURLFriendlyPath(path || '');
      const data = {
        duty: duty.toUpperCase() || '',
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
        path: pathValue,
        morganPath: pathValue.toLowerCase(),
        phone: phone || '',
        phoneNumber: phone || '',
        theme: theme || '',
        morganCustomTheme: theme || '',
        userId: userId || '',
        sessionToken: getOperatorSessionToken(),
      };

      const url = isUpdate ? 'referrer/update' : 'referrer/add';
      const method = isUpdate ? 'put' : 'post';

      try {
        const resp = await axiosCall({ url: url, method: method, data: { data } });
        if (resp.message) {
          setErrand((prev) => {
            prev.messages[0].message = resp.message;
            return { ...prev };
          });
          setPath(resp.morganPath);
        } else if (resp.email || resp.userId) {
          setDuty(resp.dutyCodeKeyword || '');
          setEmail(resp.email || '');
          setFirstName(resp.firstName || '');
          setLastName(resp.lastName || '');
          setPath(resp.morganPath || '');
          setPhone(resp.phoneNumber || '');
          setTheme(resp.morganCustomTheme || '');
          setUserId(resp.userId || '');
          setIsUpdate(true);
        }
      } catch (e) {
        const error = e as AxiosError;
        console.error({ e, error });
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleReset = (e: SyntheticEvent) => {
    e.preventDefault();
    setError('');
    setIsUpdate(false);
    setErrand((prev) => {
      prev.messages[0].message = '';
      return { ...prev };
    });
    setPath('');
    setDuty('');
    setEmail('');
    setFirstName('');
    setLastName('');
    setPhone('');
    setTheme('');
    setUserId('');
  };

  // Set a 48 character limit for Custom Greeting (theme)
  const charactersLeft = 48 - theme.length;
  let labelContent = themeFocused ? t('charactersLeft').replace('[number]', charactersLeft.toString()) : t('tTheme');
  let labelColor = '';

  if (charactersLeft === 0) {
    labelContent = themeFocused ? t('characterLimitReached') : t('tTheme');
    labelColor = themeFocused ? 'var(--red400)' : '';
  }

  const handleThemeFocus = () => {
    setThemeFocused(true);
  }

  const handleThemeBlur = () => {
    setThemeFocused(false);
  }

  const getUserData = useCallback(async () => {
    if (!props.user) return;
    setFirstName(props.user?.firstname || '');
    setLastName(props.user?.lastname || '');
    setPath(((props.user?.firstname || '') + (props.user?.lastname || '')).toLowerCase());
    try {
      const request = {
        url: `user/participants/access/`,
        method: 'POST',
        data: { search: props.user._id },
      };
      const config = reset();
      const response = await axiosCall(request, config);
      if (!response || !response[0] || response[0]._id !== props.user._id) return;
      const userId = response[0].userId;
      if (userId) setUserId(userId);
      const email = response[0].email;
      if (email) setEmail(email);
      const duty = response[0].roleDescription;
      if (duty) setDuty(duty);
      const phone = response[0].phone;
      if (phone) setPhone(phone);
    } catch (error) {
      console.error(`could not get user data`, error);
    }
  }, [props.user, reset]);

  useInitialMount(getUserData);

  return (
    <>
      {props.user ? (
        <Tooltip title={t('addReferrer')}>
          <IconButton onClick={toggleOpen}><AddLink /></IconButton>
        </Tooltip>
      ) : (
        <button onClick={toggleOpen}>+ {t('addReferrer')}</button>
      )}
      <Modal
        open={open}
        onClose={toggleOpen}
      >
        <div className={Styles.modal + ' ' + (errand.messages[0].message ? Styles.success : '')}>
          <h2 className={Styles.title}>{isUpdate ? t('confirmUpdate') : t('addReferrer')}</h2>
          <h2 className={Styles.title}>{t('tSuccess')}</h2>
          <div className={Styles.container}>
            <small className={Styles.error}>{error}</small>
            <form onSubmit={handleSubmit} className={Styles.form}>
              <TextField
                sx={{ width: '15rem' }}
                size="small"
                color="info"
                label={t('tDuty')}
                value={duty}
                onChange={(e) => setDuty(e.target.value)}
              />
              <TextField
                required
                sx={{ width: '15rem' }}
                size="small"
                color="info"
                label={t('tEmail')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                required
                sx={{ width: '15rem' }}
                size="small"
                color="info"
                label={t('tFirstName')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                required
                sx={{ width: '15rem' }}
                size="small"
                color="info"
                label={t('tLastName')}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextField
                required
                sx={{ width: '15rem' }}
                size="small"
                color="info"
                label={t('tCustomUrl')}
                value={path}
                onChange={(e) => setPath(e.target.value)}
              />
              <TextField
                sx={{ width: '15rem' }}
                size="small"
                color="info"
                label={t('tPhone')}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <TextField
                sx={{ width: '15rem' }}
                size="small"
                label={labelContent}
                value={theme}
                onChange={(e) => setTheme(e.target.value)}
                inputProps={{maxLength: 48}}
                InputLabelProps={{style: { color: labelColor, maxWidth: 'none' }}}
                onFocus={handleThemeFocus}
                onBlur={handleThemeBlur}
              />
              <TextField
                sx={{ width: '15rem' }}
                size="small"
                color="info"
                label={t('tUserIdCmp4')}
                inputProps={{ maxLength: 4, minLength: 4 }}
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                disabled={isUpdate}
              />
              <div>
                {isUpdate ? (
                  <>
                    <Button variant="contained" color="info" type="button" onClick={handleReset}>{t('tClear')}</Button>
                    &nbsp;
                    <Button variant="contained" color="info" type="submit" disabled={isLoading}>{t('tUpdate')}</Button>
                  </>
                ) : (
                  <Button variant="contained" color="info" type="submit" disabled={isLoading}>{t('tSubmit')}</Button>
                )}
              </div>
            </form>
          </div>
          <div className={Styles.container}>
            <div className={Styles.confirmation}>
              <ReferrerMessageContent errand={errand as IErrand} message={errand?.messages?.[0] as IMessage} />
              <small onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_PUBLIC_ASKANGEL_URL + '/' + path)} className={Styles.link}>{process.env.REACT_APP_PUBLIC_ASKANGEL_URL + '/' + path}</small>
              <div>
                <Button variant="contained" color="info" type="button" onClick={handleReset}>{t('tClear')}</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReferrerModal;
