/**
 * @file    This file contains the definition of the `UninitializedError` exception class.
 * @author  Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

/*
========================================================================================================================
Imports
========================================================================================================================
*/

import MorganError from "./MorganError";

/*
========================================================================================================================
Class definition
========================================================================================================================
*/

/**
 * This exception should be thrown if an attempt to fetch or manipulate a given value fails due to it not having been
 * initialized yet.
 */
class UninitializedError extends MorganError {}

export default UninitializedError;