import { Box, Chip, Stack, Typography } from '@mui/material';
import { MorganTheme, styled, useTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import React, { MouseEventHandler } from 'react';
const FlowerIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/FlowerIcon.png';

/*
 *  This component is the welcome bar seen across the top of the content area in
 * the console dashboard. For more see the Figma:
 * https://www.figma.com/file/5HrQkz2QKGTb97ZvTsqfOC/Dashbord?node-id=613%3A71
 *
 *  This component uses the following properties:
 *    - name: this is the name of the user being welcomed
 *    - onClick: this is for the admin button, although this implementation might
 *      change if its just a link.
 *
 */

const StyledBox = styled(Box)(() => ({
  borderRadius: '0.8em',
  width: '100%',
  verticalAlign: 'middle',
}));

const WelcomeBar = (props: { name: string; accessLevel: string; onClick: MouseEventHandler<HTMLDivElement>; }) => {
  const theme : MorganTheme = useTheme();
  const { t } = useTranslation();

  const getChipColor = (accessLevel: string) => {
    switch (accessLevel) {
      case 'Level 1':
        return theme.palette.blue['500'];
      case 'Level 2':
        return theme.palette.blue['700'];
      case 'Admin':
        return theme.palette.blue['900'];
      default:
        break;
    }
  };

  const getAccessString = (accessLevel : string) => {
    switch (accessLevel) {
      case 'Level 1':
        return t('level1Access');
      case 'Level 2':
        return t('level2Access');
      case 'Admin':
        return t('adminAccess');
      default:
        break;
    }
  }

  return (
    <StyledBox      
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingX: '20px',
        backgroundColor: theme.palette.blue['010']
        }}>
      <Stack sx={{ width: 'fit-content', flexDirection: 'row', alignItems: 'center' }}>
        <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="black" align="right">
          {t('welcomeBarText')}
        </Typography>
        <Typography
          sx={{ fontSize: 32, fontWeight: 600, marginLeft: '10px', textTransform: 'capitalize', color: "var(--peach900)" }}>
          {props.name.toLowerCase()}
        </Typography>
      </Stack>
      <Stack sx={{ width: 'fit-content', flexDirection: 'row', alignItems: 'center' }}>
        <Chip
          sx={{
            backgroundColor: getChipColor(props.accessLevel),
            color: 'var(--gray000)',
            pointerEvents: "none",
          }}
          label={getAccessString(props.accessLevel)}
          onClick={props.onClick}
        />
        <img src={FlowerIcon} aria-hidden="true"  />
      </Stack>

      {/* <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={8}>
          <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
            <Typography
              sx={{ fontSize: 18, fontWeight: 600, paddingLeft: '20px' }}
              color="black"
              align="right">
              {t('welcomeBarText')}
            </Typography>
            <Typography sx={{ fontSize: 32, fontWeight: 600 }} color="primary">
              {props.name.replace(
                /\w\S/g,
                (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
              )}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
            <Chip
              sx={{
                backgroundColor: getChipColor(props.accessLevel),
                color: 'var(--gray000)',
              }}
              label={props.accessLevel}
              onClick={props.onClick}
            />
            <img src={FlowerIcon} aria-hidden="true"  />
          </Stack>
        </Grid>
      </Grid> */}
    </StyledBox>
  );
};

export default WelcomeBar;
