/**
 * @file This file contains functions used for the blockchain wallet and UI.
 * @author Timur Bickbau
 */

import { ethers } from 'ethers';
import axiosCall from '@services/axios';
import { isMobile } from '@common/deviceTypeHelper';
import { UiText } from '@components/MorphWallet/MorphWalletUiText';

window.Buffer = window.Buffer || require('buffer').Buffer;

/**
 * Enums and Interfaces
 * */

// the pages in the wallet UI
enum Page {
  Welcome,
  RecoverAccount,
  ViewRecoveryPhrase,
  AccessPin,
  LinkDeviceDesktop,
  LinkDeviceMobile,
  LinkDeviceMobileSuccess,
  MyNftsListView,
  MyNftsSingleView,
  ApprovedUsers,
  AttachedNftSingleView,
  BidsOnNftListView,
  BidsOnNftSingleView,
  AcceptedBidSingleView,
  Marketplace,
  ViewMyBids,
  BiddableNftsListView,
  BiddableNftsSingleView
}

// the tabs that appear in the wallet footer
enum Tab {
  MyWallet = UiText.MyWallet,
  Marketplace = UiText.Marketplace
}

// TODO: these values should be obtained from the variables in MorphWallet.module.css
enum Color {
  White = '#FFFFFF',
  Orange = '#FF7400',
  Blue = '#010D2F',
  Violet = '#6050DC',
  DarkBlue = '#01011A',
  LightGray = '#D3D3D3',
  Gray = '#808080',
  Red = '#FF0000'
}

// constants relating to the UI styling
enum UI {
  PaddingSide = isMobile() ? window.innerWidth * 0.05 : 20,
  MarginSide = 20 + (isMobile() ? 0.5 : 5), // we should get this directly from the padding of the ConversationFooter
  BackButtonWidth = isMobile() ? 80 : 100
}

// the various events dispatched and received by the wallet UI
enum Event {
  ChatSend = 'walletChatSend',  // for receiving messages sent from the chat box
  ChatInput = 'walletChatInput' // for identifying whenever the user has typed any character in the chat box (i.e. onChange)
}

// the various NFT types
// TODO: pull this from the DB
enum NftType {
  TRUAPP = 'TRUAPP',  // TRU-Approval
  TRUBID = 'TRUBID',  // Purchase Bid
  TICKET = 'TICKET'   // Event Ticket
}

// the various user types that can exist on an NFT
// TODO: pull this from DB
enum NftUserType {
  OWNER = 'OWNER',
  APPROVED = 'APPROVED'
}

// the various NFT lists that can be pulled in the UI
enum NftList {
  MyNfts,
  BidsOnNft,
  BiddableNfts
}

// the "Token" type with all the data that can be expected on an NFT
interface Token {
  tokenId: number,
  contractAddress: string,
  name: string,
  nftType: string,
  userType: string,
  owner: string,
  metaData: any,
  isPublic: boolean,
  isBurned: boolean
}

/**
 * MorphWalletUtils
 * */

class MorphWalletUtils {
  /**
   * blockchain wallet keys are deterministically derived from a "mnemonic phrase", which
   * should consist of at least 12 words (128-bit entropy) for sufficient security; 24 words
   * is technically more secure, but is generally considered excessive and risks user error
   * */
  static readonly NUM_MNEMONIC_WORDS = 12;
  static readonly MNEMONIC_ENTROPY = this.NUM_MNEMONIC_WORDS === 12 ? 128 : 256;

  // error handling constants
  static readonly INVALID_WORD = 'INVALID_WORD';
  static readonly INVALID_CHECKSUM = 'INVALID_CHECKSUM';

  // passcode constants
  static readonly ACCESS_PIN_LENGTH = 6;
  static readonly LINK_DEVICE_CODE_LENGTH = 4;

  // other constants
  static readonly BLOCKCHAIN_PATH = 'blockchain';     // for calling Core endpoints
  static readonly BLOCKCHAIN_KEYS = 'blockchainKeys'; // for storage of encrypted blockchain keys

  /**
   * UI Functions
   * */

  /**
   * @description Get the width of the MorphWallet UI.
   * */
  static getMorphWalletWidth = () => {
    return isMobile() ? window.innerWidth - UI.MarginSide * 2 - 5 : window.innerWidth < 1000 ? 700 : 900;
  }

  /**
   * @description Get the border radius on top of the MorphWallet UI.
   * */
  static getUiBorderTopRadii = (activeTab: Tab) => {
    return isMobile() || !activeTab ? 40 : 15;
  }

  /**
   * @description Get the styling of the MorphWallet depending on whether we are on mobile or desktop.
   * */
  static getMorphWalletStyling = (activeTab: Tab) => {
    return { paddingLeft: UI.PaddingSide, paddingRight: UI.PaddingSide, paddingBottom: !isMobile() && 20,
             minWidth: this.getMorphWalletWidth(), maxWidth: this.getMorphWalletWidth(), marginBottom: isMobile() && -1,
             borderTopLeftRadius: this.getUiBorderTopRadii(activeTab), borderTopRightRadius: this.getUiBorderTopRadii(activeTab) };
  }

  /**
   * @description Get the styling of the header that is shown in the MorphWallet UI.
   * */
  static getHeaderStyling = (activeTab: Tab) => {
    return { backgroundColor: Color.DarkBlue, display: 'flex', justifyContent: isMobile() ? 'space-between' : 'center',
             marginLeft: -UI.PaddingSide, marginRight: -UI.PaddingSide, paddingLeft: 10, paddingRight: 10, paddingTop: 15, minHeight: 40,
             borderTopLeftRadius: this.getUiBorderTopRadii(activeTab), borderTopRightRadius: this.getUiBorderTopRadii(activeTab) };
  }

  /**
   * @description Get the styling of the footer that is shown in the MorphWallet UI.
   * */
  static getFooterStyling = () => {
    return { backgroundColor: Color.DarkBlue, display: 'flex', justifyContent: 'space-between', padding: 15, minHeight: 20,
             minWidth: 300 + UI.PaddingSide * 2, marginLeft: -UI.PaddingSide, marginRight: -UI.PaddingSide };
  }

  /**
   * Wallet Management Functions
   * */

  /**
   * @description Generate a new wallet with a pseudo-random mnemonic phrase.
   * */
  static generateWallet = () => {
    try {
      const bip39 = require('bip39');

      // generate the mnemonic phrase
      const mnemonicPhrase = bip39.generateMnemonic(this.MNEMONIC_ENTROPY);

      /**
       * the private key is a password needed for verifying any transactions from the wallet
       * the public address is an identifier that can be used, for example, to send funds to the wallet
       * */
      const { privateKey, publicAddress, error } = this.getPrivateKeyFromPhrase(mnemonicPhrase);

      return error ? { error: `generateWallet.1: ${error}` } : { privateKey, publicAddress, mnemonicPhrase };
    } catch (err) {
      return { error: `generateWallet.2: ${err.message}` };
    }
  }

  /**
   * @description Deterministically derive a wallet's private key and public address from their mnemonic phrase.
   * @param {string} mnemonicPhrase The wallet's mnemonic phrase.
   * */
  static getPrivateKeyFromPhrase = (mnemonicPhrase: string) => {
    try {
      // validate mnemonic phrase
      const response = this.validatePhrase(mnemonicPhrase);
      if (response?.error) {
        return { error: `getPrivateKeyFromPhrase.1: ${response.error}` };
      }
      
      // derive private key and public address from mnemonic phrase; be careful changing this as it may result in a different derivation path
      const wallet = ethers.Wallet.fromPhrase(mnemonicPhrase);
      const privateKey = wallet.privateKey;
      const publicAddress = wallet.address;

      return { privateKey, publicAddress };
    } catch (err) {
      if (err.message.includes('invalid mnemonic word at index')) {
        return { error: `${this.INVALID_WORD}${err.message.split(' ')[5]}` };
      } else if (err.message.includes('invalid mnemonic checksum')) {
        return { error: this.INVALID_CHECKSUM };
      } else {
        return { error: `getPrivateKeyFromPhrase.2: ${err.message}` };
      }
    }
  };

  /**
   * @description Encrypt the user's blockchain keys behind their access PIN.
   * @param {string} keys.mnemonicPhrase The wallet's recovery phrase.
   * @param {string} keys.privateKey The wallet's private key.
   * @param {string} keys.publicAddress The wallet's public address.
   * @param {string} accessPin The user's access PIN.
   * */
  static encryptKeys = async (keys: { mnemonicPhrase: string, privateKey: string, publicAddress: string}, accessPin: string) => {
    // validate the mnemonic phrase
    const mnemonicPhraseValidation = this.validatePhrase(keys.mnemonicPhrase);
    if (mnemonicPhraseValidation?.error) {
      return { error: `encryptKeys.1: ${mnemonicPhraseValidation.error}` };
    }

    if (!keys.privateKey) {
      return { error: 'encryptKeys.2: No private key provided.' };
    }

    if (!keys.publicAddress) {
      return { error: 'encryptKeys.3: No public address provided.' };
    }

    // validate the access PIN
    const accessPinValidation = this.validateCode(accessPin, this.ACCESS_PIN_LENGTH);
    if (accessPinValidation?.error) {
      return { error: `encryptKeys.4: ${accessPinValidation.error}` };
    }

    try {
      // encrypt the keys
      const { error, encryptedKeys } = await axiosCall({
        url: `${this.BLOCKCHAIN_PATH}/encryptKeys`,
        method: 'POST',
        data: {
          ...keys,
          accessPin
        }
      });

      if (error || !encryptedKeys) {
        return { error: `encryptKeys.5: ${error || 'No keys returned.'}` };
      }

      return { encryptedKeys };
    } catch (err) {
      return { error: `encryptKeys.6: ${err.message}` };
    }
  }

  /**
   * @description Get the user's decrypted blockchain keys given the encrypted keys and their access PIN.
   * @param {string} encryptedKeys The user's encrypted keys.
   * @param {string} accessPin The user's access PIN.
   * */
  static decryptKeys = async (encryptedKeys: string, accessPin: string) => {
    // validate the access PIN
    const accessPinValidation = this.validateCode(accessPin, this.ACCESS_PIN_LENGTH);
    if (accessPinValidation?.error) {
      return { error: `decryptKeys.1: ${accessPinValidation.error}` };
    }

    try {
      // decrypt the keys
      const { error, decryptedKeys } = await axiosCall({
        url: `${this.BLOCKCHAIN_PATH}/decryptKeys`,
        method: 'POST',
        data: {
          encryptedKeys,
          accessPin
        }
      });

      if (error || !decryptedKeys || !decryptedKeys?.mnemonicPhrase || !decryptedKeys?.privateKey || !decryptedKeys?.publicAddress) {
        return { error: `decryptKeys.2: ${error || 'No keys returned.'}` };
      }

      return { decryptedKeys };
    } catch (err) {
      return { error: `decryptKeys.3: ${err.message}` };
    }
  }

  /**
   * Validation Functions
   * */

  /**
   * @description Validate a mnemonic phrase used for recovering an account.
   * @param {string} mnemonicPhrase The mnemonic phrase to be validated.
   * */
  static validatePhrase = (mnemonicPhrase: string) => {
    if (!mnemonicPhrase) {
      return { error: 'validatePhrase.1: Mnemonic phrase not provided.' };
    }

    if (mnemonicPhrase.split(' ').length !== this.NUM_MNEMONIC_WORDS) {
      return { error: `validatePhrase.2: Mnemonic phrase does not contain ${this.NUM_MNEMONIC_WORDS} words.` };
    }

    // TODO: ideally we should put the BIP39 word list in a JSON and check against it (no security risk as word list is public)
  }

  /**
   * @description Validate a code input from the UI, such as the user's access PIN or link device code.
   * @param {string} code The code to be validated.
   * @param {number} length The expected length of the code.
   * */
  static validateCode = (code: string, length: number) => {
    if (!code) {
      return { error: 'validateCode.1: No code provided.' };
    }

    if (code.length !== length) {
      return { error: `validateCode.2: Code must be of length ${length}.` };
    }

    const regex = /^\d+$/;
    if (!regex.test(code)) {
      return { error: 'validateCode.3: Code must only contain numeric characters.' };
    }
  }

  /**
   * NFT Data Functions
   * */

  /**
   * @description Collect and return the data for an individual NFT from the response JSON of a Malcom list request, such as /getMyNfts.
   * @param {any} listResponse The response JSON from the Malcom list request.
   * @param {any} responseNftData The data of the individual NFT from the response JSON of the list request.
   * @param {NftList} nftList The type of NFT list.
   * @param {privateKey} privateKey The private key of the user.
   * @param {publicAddress} publicAddress The public address of the user.
   * */
  static getNftData = async (listResponse: any, responseNftData: any, nftList: NftList, privateKey: string, publicAddress: string) => {
    switch (nftList) {
      // NFTs in My Wallet
      case NftList.MyNfts:
        const nftData = {
          tokenId: responseNftData?.id?.tokenId,
          contractAddress: responseNftData?.id?.contractAddress,
          nftType: responseNftData?.id?.contractNameKeyword,
          userType: responseNftData?.id?.ownership,
          owner: responseNftData?.nft?.nftOwner
        }

        switch (nftData?.nftType) {
          // Ticket NFT
          case NftType.TICKET:
            return {
              ...nftData,
              metaData: responseNftData?.nft?.nftMetaData,
              isPublic: responseNftData?.nft?.isPublicData,
            };
          // TRU-Approval NFT in My NFTs
          case NftType.TRUAPP:
            return {
              ...nftData,               
              metaData: responseNftData?.nft?.nftMetaData?.truApprovalObjJson,
              isPublic: responseNftData?.nft?.isPublicData
            };
          // Purchase Bid NFT in My NFTs
          case NftType.TRUBID:
            const nftMetaData = responseNftData?.nft?.nftMetaData?.bidData;

            // instead of showing the ID and contract address of the TRU-Approval NFT, show the simplified name
            nftMetaData.truTokenName = this.getNftName(nftMetaData?.truContractAddress, nftMetaData?.truTokenId);

            // see if another bid has been accepted on this bid's attached NFT, so we can set the bid status
            try {
              // get the data of the accepted bid
              const { bidTokenId, bidContractAddress, error } = await axiosCall({
                url: `${this.BLOCKCHAIN_PATH}/getAcceptedBid`,
                method: 'POST',
                data: {
                  tokenId: nftMetaData?.truTokenId,
                  contractAddress: nftMetaData?.truContractAddress
                }
              });

              // check if the NFT has an accepted bid
              if (!error && (bidTokenId || bidTokenId === 0) && bidContractAddress) {
                // check if the accepted bid is the same as this bid
                if (bidTokenId === nftData?.tokenId) {
                  nftMetaData.bidStatus = 'Accepted';
                } else {
                  nftMetaData.bidStatus = 'Rejected';
                }
              }
            } catch (err) {
              console.log('getNftData.1: No accepted bid found on this bid\'s attached NFT.');
            }

            if (!nftMetaData?.bidStatus) {
              nftMetaData.bidStatus = 'Pending';
            }

            return {
              ...nftData,
              metaData: nftMetaData,
              isPublic: true
            };
        }
        
        break;
      // Purchase BID NFT on a TRU-Approval NFT
      case NftList.BidsOnNft:
        const bidTokenId = responseNftData;
        const bidContractAddress = listResponse?.bidContractAddress;

        try {
          // get the rest of the data for the Bid NFT
          const { error, nftMetaData, nftOwner} = await axiosCall({
            url: `${this.BLOCKCHAIN_PATH}/getNft`,
            method: 'POST',
            data: {
              contractAddress: bidContractAddress,
              nftType: NftType.TRUBID,
              tokenId: bidTokenId,
              privateKey,
              publicAddress
            }
          });

          if (error) {
            console.error(`getNftData.2: ${error}`);
            return null;
          }

          const bidMetaData = nftMetaData?.bidData;

          return {
            tokenId: bidTokenId,
            contractAddress: bidContractAddress,
            nftType: NftType.TRUBID,
            userType: null,
            owner: nftOwner,
            metaData: {
              ...bidMetaData,
              truTokenName: this.getNftName(bidMetaData?.truContractAddress, bidMetaData?.truTokenId)
            },
            isPublic: true
          };
        } catch (err) {
          console.error(`getNftData.3: ${err.message}`);
          return null;
        }
      // TRU-Approval NFT in Marketplace
      case NftList.BiddableNfts:
        const contractAddress = responseNftData?.contractAddr;
        const tokenId = responseNftData?.nftTokenId;
        const nftType = responseNftData?.contractNameKeyword;

        // pull metadata
        try {
          const { nftMetaData, error } = await axiosCall({
            url: `${this.BLOCKCHAIN_PATH}/getNft`,
            method: 'POST',
            data: {
              contractAddress,
              tokenId,
              nftType,
              privateKey,
              publicAddress,
            }
          });

          const metaData = nftMetaData?.truApprovalObjJson;

          if (error || !metaData) {
            console.error(`getNftData.4: ${error || `Missing metadata for token ${tokenId}`}`);
            return;
          }

          return {
            tokenId,
            contractAddress,
            nftType,
            userType: null,
            owner: responseNftData?.ownerAddr,
            metaData,
            isPublic: true
          };
        } catch (err) {
          console.error(`getNftData.5: ${err.message}`);
          return;
        }
    }
  }

  /**
   * @description Validate the NFT data returned by the above function.
   * @param {any} nftData The NFT data to be validated.
   * @param {NftList} nftList The type of NFT list.
   * */
  static validateNftData = (nftData: any, nftList: NftList) => {
    if (!nftData) {
      return { error: 'validateNftData.1: Missing token data.' };
    }

    if (!nftData?.tokenId && nftData?.tokenId !== 0) {
      return { error: 'validateNftData.2: Missing token ID.' };
    }

    if (!nftData?.contractAddress || !nftData?.nftType || !nftData?.owner) {
      return { error: `validateNftData.3: Missing data for token ${nftData?.tokenId}.` };
    }

    if (nftList === NftList.MyNfts) {
      if (!nftData?.userType || !nftData?.metaData || nftData?.isPublic === null) {
        return { error: `validateNftData.4: Missing data for token ${nftData?.tokenId}.` };
      }
    }
  }

  /**
   * @description Get the display name for an NFT, given its contract address and ID.
   * @param {string} contractAddress The contract address of the NFT.
   * @param {number} tokenId The ID of the NFT.
   * */
  static getNftName = (contractAddress: string, tokenId: number) => {
    return contractAddress.slice(-6) + '-' + tokenId;
  }

  /**
   * UI Helper Functions
   * */

  /**
   * @description Get the display name for an NFT type.
   * @param {string} nftType The NFT type.
   * */
  static getNftTypeDisplay = (nftType: string) => {
    switch (nftType) {
      case NftType.TRUAPP:
        return 'TRU-Approval';
      case NftType.TRUBID:
        return 'Purchase Bid';
      case NftType.TICKET:
        return 'Event Ticket';
    }
  }

  /**
   * @description Get the display name for an NFT user type.
   * @param {string} userType The NFT user type.
   * */
  static getNftUserTypeDisplay = (userType: string) => {
    switch (userType) {
      case NftUserType.OWNER:
        return 'Owner';
      case NftUserType.APPROVED:
        return 'Approved';
    }
  }

  /**
   * @description Get the data for an Event Ticket NFT, such as display name and date.
   * @param {string} eventName The event name as provided in the NFT's metadata.
   * */
  static getTicketData = async (eventName: string) => {
    try {
      const { data, error } = await axiosCall({
        url: `${this.BLOCKCHAIN_PATH}/getTicketData`,
        method: 'POST',
        data: {
          eventName
        }
      });

      if (error) {
        return { error: `getTicketData.1: Error getting data for ${eventName}.` };
      }

      return { data };
    } catch (err) {
      return { error: `getTicketData.2: ${err.message}` };
    }
  }

  /**
   * @description Get the IDs of a group of input squares (e.g., for access PIN or recovery phrase). Defined here to avoid hardcoding IDs elsewhere.
   * @param numInputs The number of inputs.
   * */
  static getInputIds = (numInputs: number) => {
    const inputIds = [];

    for (let index = 0; index < numInputs; index++) {
      inputIds.push(`input-${index}`);
    }

    return inputIds;
  }

  /**
   * @description Load the list-view metadata preview fields for each NFT type.
   * */
  static loadPreviewFields = async () => {
    const fields = [];

    const nftTypes = Object.keys(NftType);
    for (const nftType of nftTypes) {
      if (nftType === NftType.TICKET) {
        fields.push({ nftType, fields: ['eventName', 'scanned'] });
        continue;
      }

      try {
        const { error, previewFields } = await axiosCall({
          url: `${this.BLOCKCHAIN_PATH}/getPreviewFields`,
          method: 'POST',
          data: {
            nftType
          }
        });

        if (error) {
          console.error(`loadPreviewFields.1: Error getting preview fields for ${nftType}.`);
          continue;
        }

        if (previewFields) {
          fields.push({ nftType, previewFields });
        }
      } catch (err) {
        console.error(`loadPreviewFields.2: ${err.message}`);
        continue;
      }
    }

    return fields;
  }

  /**
   * @description Convert string to title case (e.g., "john doe" => "John Doe")
   * @param {string} text The string to be converted to title case.
   * */
  static convertToTitleCase = (text: string) => {
    if (!text) {
      return '';
    }

    const words = text.toLowerCase().split(' ');
    words.forEach((word, index) => words[index] = word[0].toUpperCase() + word.slice(1));
    return words.join(' ');
  }

  /**
   * @description Compile the metadata of an NFT into an array for display.
   * @param {object} metaData The metadata of the NFT.
   * @param {string[]} selectedFields The fields that should be displayed; to display all fields, leave this null.
   * */
  static compileMetaData = (metaData: object, selectedFields: string[]) => {
    if (!metaData) {
      return [];
    }

    /**
     * format: metaData key name = display name
     * data will appear in exact order below; rearrange as needed
     * */
    enum Field {
      // general metadata
      smartContractAddress = UiText.mdSmartContractAddress,
      // TICKET metadata
      eventName = UiText.mdEventName,
      scanned = UiText.mdScanned,
      additionalGuests = UiText.mdAdditionalGuests,
      hashString = UiText.mdHashString,
      // TRUBID metadata
      bidStatus = UiText.mdBidStatus,
      truTokenName = UiText.mdTruTokenName,
      // TRUAPP metadata
      truApprovalDateAsString = UiText.mdTruApprovalDate,
      borrowerHeaderLine = UiText.mdBorrowerName,
      propCode = UiText.mdPropCode,
      propDesc = UiText.mdPropDesc,
      loanAmount = UiText.mdLoanAmount,
      purchasePrice = UiText.mdPurchasePrice,
      interestRate = UiText.mdInterestRate,
      apr = UiText.mdApr,
      ltv = UiText.mdLtv,
      cltv = UiText.mdCltv,
      productCodeDesc = UiText.mdProductCodeDesc,
      retail = UiText.mdRetail,
      term = UiText.mdTerm,
      newHazIns = UiText.mdNewHazIns,
      newTaxes = UiText.mdNewTaxes,
      newMi = UiText.mdNewMi,
      newHoa = UiText.mdNewHoa,
      newFirstMortPi = UiText.mdNewFirstMortPi,
      newOtherMortPi = UiText.mdNewOtherMortPi,
      interviewerName = UiText.mdInterviewerName,
      interviewerTitle = UiText.mdInterviewerTitle,
      interviewerEmail = UiText.mdInterviewerEmail,
      interviewerPhone = UiText.mdInterviewerPhone,
      interviewerNmlsId = UiText.mdInterviewerNmlsId,
      interviewerCompCode = UiText.mdInterviewerCompCode,
      interviewerCompAddress = UiText.mdInterviewerCompAddress,
      interviewerCompCityStateZip = UiText.mdInterviewerCompCityStateZip,
      lenderCompCode = UiText.mdLenderCompCode,
      molCompUrl = UiText.mdMolCompUrl
    }

    // perform any necessary operations on the data
    const formatData = (data: any, titleCase: boolean, formatPhone: boolean, formatDollar: boolean, formatPercent: boolean) => {
      switch (typeof data) {
        case 'string':
          if (titleCase) {
            return this.convertToTitleCase(data);
          }
    
          if (formatPhone) {
            if (data.length === 10) {
              return '(' + data.slice(0, 3) + ') ' + data.slice(3, 6) + '-' + data.slice(6);
            }
          }

          break;
        case 'number':
          if (formatDollar) {
            return '$' + String(data).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }
    
          if (formatPercent) {
            return String(data) + '%';
          }

          break;
        case 'boolean':
          return data ? 'Yes' : 'No';
      }

      return data;
    }

    // get the Field enum in an array
    const fields = Object.keys(Field);
    const metaDataArr = [];

    // add field and value to metadata array
    fields.forEach((field, index) => {
      // if selectedFields provided, only return those fields for display
      if (selectedFields && !selectedFields.includes(field)) {
        return;
      }

      // if the field cannot be found in the metadata
      if (!(field in metaData)) {
        return;
      }

      const value = metaData[field];
      if (value !== null) {
        // if you want to set a smaller font size for a certain field because the value is too long
        let fontSize: string = null; // e.g., '0.7rem'

        // whether to convert the value to title case, e.g., JOHN DOE to John Doe
        let titleCase = false;
        // whether to convert a 10-digit number to (123) 456-7890 format
        let formatPhone = false;
        // whether to convert a number to dollar format, with $ and commas
        let formatDollar = false;
        // whether to append % to the number
        let formatPercent = false;

        /**
         * 1. all fields will be included as long as they are in the Field enum and have a value
         * 2. only need cases below if you want to add an operation, like format phone
         * 3. to hide a field from display, add a case with a return statement
         * */
        switch (Object.values(Field)[index]) {
          case Field.smartContractAddress:
            fontSize = window.innerWidth < 380 ? '0.55rem' : '0.6rem';
            break;
          case Field.borrowerHeaderLine:
            titleCase = true;
            break;
          case Field.propDesc:
            titleCase = true;
            break;
          case Field.loanAmount:
            formatDollar = true;
            break;
          case Field.purchasePrice:
            formatDollar = true;
            break;
          case Field.interestRate:
            formatPercent = true;
            break;
          case Field.apr:
            formatPercent = true;
            break;
          case Field.ltv:
            formatPercent = true;
            break;
          case Field.cltv:
            formatPercent = true;
            break;
          case Field.productCodeDesc:
            titleCase = true;
            break;
          case Field.newHazIns:
            formatDollar = true;
            break;
          case Field.newTaxes:
            formatDollar = true;
            break;
          case Field.newMi:
            formatDollar = true;
            break;
          case Field.newHoa:
            formatDollar = true;
            break;
          case Field.newFirstMortPi:
            formatDollar = true;
            break;
          case Field.newOtherMortPi:
            formatDollar = true;
            break;
          case Field.interviewerName:
            titleCase = true;
            break;
          case Field.interviewerPhone:
            formatPhone = true;
            break;
          case Field.interviewerTitle:
            titleCase = true;
            break;
          case Field.interviewerCompAddress:
            titleCase = true;
            break;
          case Field.interviewerCompCityStateZip:
            titleCase = true;
            break;
          case Field.eventName:
            titleCase = true;
            break;
          case Field.hashString:
            fontSize = '0.6rem';
            return;
        }

        metaDataArr.push({
          name: Object.values(Field)[fields.indexOf(field)],
          value: formatData(value, titleCase, formatPhone, formatDollar, formatPercent),
          fontSize
        });
      }
    });

    return metaDataArr;
  }
}

export {
  Page,
  Tab,
  Color,
  UI,
  Event,
  NftType,
  NftUserType,
  NftList,
  Token,
  MorphWalletUtils
}