import LinkPreview from '@components/LinkPreview';
import React, { useState } from 'react';
import axiosCall from '@services/axios';
import MessagesSkeletonLoader from '@components/MessagesSkeletonLoader';
import { useRootContext } from '@contexts/RootContext';
import { useErrandContext } from '@contexts/ErrandContext';

const Page = (props) => {
  // useContext to keep track of when messages have loaded for the skeleton loader
  const [previewUrl, setPreviewUrl] = useState(props.link);

  // parent component context usage
  const rootContext = useRootContext();
  const errandContext = useErrandContext();

  const clearPreviewAndCloseErrand = async (url) => {
    try {
      // don't allow url set from child component
      if (url) return;

      // clear preview url
      setPreviewUrl('');

      // Set status to waiting
      await axiosCall({
        url: `chat/${props.errandId}`,
        method: 'PUT',
        data: { status: 'closed' },
      });

      // go back to main conversation
      rootContext.setSelectedIndex([0]);

      const errands = rootContext.errands;

      // search current errand from errands array
      const index = errands.findIndex((elm) => elm._id === errandContext.errandId);

      // if found ---> remove it from errands array
      if (index !== -1) {
        errands.splice(index, 1);
        rootContext.setErrands(errands);
      }
    } catch (err) {
      if (err) {
        console.error(`LinkPreview - closeErrand`,err);
      }
    }
}

  return (
    <>
      <MessagesSkeletonLoader />
      {previewUrl && (
        <LinkPreview url={previewUrl} setPreviewUrl={clearPreviewAndCloseErrand} chatId={props.errandId} />
      )}
    </>
  );
};

export default Page;