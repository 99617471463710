// @ts-check

import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import GenericSnackbar from './GenericSnackbar';
import GenericTable from './GenericTable';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axiosCall from '../Services/axios';
import formatPhoneNumber from '../Common/formatPhoneNumber';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import PrizeRates from '@components/PrizeRates';
import ReferrerModal from '@components/ReferrerModal';
import { sectionNameTranslationKeys, settingsSectionNameTranslationKeys } from '../Constants/consoleConstants';
import { useSocketContext } from '@contexts/socket';

/**
 * The System component is responsible for handling and displaying data related to system status, contacts, management contacts, and blacklists.
 * It demonstrates two distinct approaches for handling and displaying data:
 *
 * 1. Server-Side Pagination for Blacklist Data:
 *    - The blacklist data is managed with server-side pagination (loadBlacklistData)
 *    - Only a specific subset of blacklist data is fetched from the server based on the current page requirements (offset and limit).
 *    - This approach optimizes performance for large datasets by reducing the load on both the network and client-side resources.
 *
 * 2. Client-Side Pagination for Contacts and Management Contacts:
 *    - Unlike the blacklist, contacts and management contacts data are handled with client-side pagination (getContactsForTable)
 *    - All contacts data is initially fetched from the server and then paginated on the client side.
 *    - This method is suitable for smaller datasets, where fetching the entire dataset at once doesn't cause performance issues.
 *
 */
const baseModule = `${sectionNameTranslationKeys['Admin']}/${sectionNameTranslationKeys['System']}`;
const System = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [systemData, setSystemData] = useState(/** @type {import('../../jsdocs').SystemData | null} */(null));
  const [blacklist, setBlacklist] = useState([]);
  const [systemStatusList, setSystemStatusList] = useState(/** @type {string[]} */([]));
  // eslint-disable-next-line
  const [showDateTimePickers, setShowDateTimePickers] = useState(false);

  const blacklistGetActive = useRef(true);

  const snackbarRef = useRef(/** @type {any} */(null));

  const { eventsSocket } = useSocketContext();

  const loadSystemData = async () => {

    /** @type {string} */ // @ts-ignore
    const lenderName = theme.system.name;
    try {
      /** @type {string[]} */ // @ts-ignore
      const list = await axiosCall({ url: `system/status` });
      if (list) {
        setSystemStatusList(list);
      }

      /** @type {import('../../jsdocs').SystemData} */ // @ts-ignore
      const result = await axiosCall({ url: `system/data?lender=${lenderName}&fields=message,status,lender,translationEnabled,contacts,managementHotline,notificationBannerEnabled` });
      if (result) {
        setSystemData(result);
      }
    } catch (error) {
      console.warn(error);
    }

    setBlacklist(await loadBlacklistData());
  };
  // This loadBlacklistData function supports pagination by accepting 'offset' and 'limit' parameters.
  // These parameters are used to construct the URL query string for the blacklist API call.
  const loadBlacklistData = async (offset, limit) => {
    try {
      const offsetQuery = offset !== undefined ? `offset=${offset}` : 'offset=0';
      const limitQuery = limit !== undefined ? `&limit=${limit}` : ''
      const active = blacklistGetActive.current ? '&active=1' : '';

      const blacklistData = await axiosCall({ url: `blacklist?${offsetQuery}${limitQuery}${active}` });

      if (blacklistData?.length > 0) {
        return blacklistData;
      } else {
        return [];
      }
    } catch (error) {
      snackbarRef.current?.displayMessage(error.message);
      console.warn(error);
      return [];
    }
  };

  const deleteBlacklist = async (id) => {
    try {
      const result = await axiosCall({
        url: `blacklist/${id}`,
        method: 'DELETE'
      });
      if (result) {
        snackbarRef.current?.displayMessage(`${t('blacklistUpdated')}`);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  const reactivateBlacklist = async (id) => {
    try {
      const result = await axiosCall({
        url: `blacklist/${id}`,
        method: 'PUT',
        data: {
          active: true
        }
      });
      if (result) {
        snackbarRef.current?.displayMessage(`${t('blacklistUpdated')}`);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  const createBlacklist = async (value) => {
    let result = null;
    if (value !== null) {
      try {
        result = await axiosCall({
          url: `blacklist`,
          method: 'POST',
          data: {
            address: value.address,
            expiresIn: value.expiresIn
          },
        });

        // @ts-ignore
        setBlacklist((prev) => {
          if (prev && prev.length > 1) {
            return [...prev, result];
          } else {
            return result;
          }
        });
        snackbarRef.current?.displayMessage(`${t('blacklistUpdated')}`);
      } catch (error) {
        snackbarRef.current?.displayMessage(error?.response?.data?.message);
        console.warn(error);
      }
    }
    return Promise.resolve(result);
  }

  const updateBlacklist = async (id, values) => {
    let result = null;
    if (values !== null) {
      try {
        result = await axiosCall({
          url: `blacklist/${id}`,
          method: 'PUT',
          data: {
            address: values[0].value,
            expiresIn: values[1].value
          }
        })
        snackbarRef.current?.displayMessage(`${t('blacklistUpdated')}`);
      } catch (error) {
        snackbarRef.current?.displayMessage(error?.response?.data?.message);
        console.warn(error);
      }
    }
    return Promise.resolve(result);
  }

  /**
   * Updates the database, providing the field and the value.
   * @param {'notificationBannerEnabled' | 'translationEnabled' | 'lender' | 'status' | 'message' | 'startDateTime' | 'endDateTime' | 'contacts' | 'managementHotline' | 'blacklist'} field
   * @param {any} value
   * @returns {Promise<any>} - The new value of the given field
   */
  const handleSave = async (field, value) => {
    if (!systemData) { return Promise.resolve(null); }

    let data = null;
    switch (field) {
      case 'notificationBannerEnabled':
        if (systemData.notificationBannerEnabled !== value) {
          data = value;
          snackbarRef?.current?.displayMessage(`${t('enableNotificationBannerLabel')} ${t('changedTo')} ${value ? t('enabled') : t('disabled')}`);
          eventsSocket.current?.emit('notification-banner-update', { message: `Notification banner status updated - ${value}`, data: { message: systemData.message, enabled: value }});
        }
        break;
      case 'translationEnabled':
        if (systemData.translationEnabled !== value) {
          data = value;
          snackbarRef?.current?.displayMessage(`${t('enableMessageTranslationLabel')} ${t('changedTo')} ${value ? t('enabled') : t('disabled')}`);
        }
        break;
      case 'lender':
        if (systemData.lender !== value) {
          data = value;
          snackbarRef?.current?.displayMessage(`${t('systemHostAddress')} ${t('changedTo')} "${value}"`);
        }
        break;
      case 'status':
        if (systemData.status !== value) {
          data = value;
          snackbarRef?.current?.displayMessage(`${t('systemStatusLabel')} ${t('changedTo')} ${value}`);
        }
        break;
      case 'message':
        if (systemData.message !== value) {
          data = value;
          snackbarRef?.current?.displayMessage(`${t('systemNotificationLabel')} ${t('changedTo')} "${value}"`);
          eventsSocket.current?.emit('notification-banner-update', { message: `Notification banner message updated - ${value}`, data: { message: value, enabled: systemData.notificationBannerEnabled }});
        }
        break;
      case 'startDateTime':
        if (systemData.startDateTime !== value) {
          data = value;
          let start = new Date(value?.startDateTime)?.toISOString();
          snackbarRef?.current?.displayMessage(`${t('systemMaintenanceStartTime')} ${t('changedTo')} ${start}`);
        }
        break;
      case 'endDateTime':
        if (systemData.endDateTime !== value) {
          data = value;
          let end = new Date(value?.endDateTime)?.toISOString();
          snackbarRef?.current?.displayMessage(`${t('systemMaintenanceEndTime')} ${t('changedTo')} ${end}`);
        }
        break;
      case 'contacts':
        if (systemData.contacts !== value) {
          data = value;
          snackbarRef?.current?.displayMessage(`${t('systemContactUpdated')}`);
        }
        break;
      case 'managementHotline':
        if (systemData.managementHotline !== value) {
          data = value;
          snackbarRef.current?.displayMessage(`${t('managementContactUpdated')}`);
        }
        break;
      case 'blacklist':
        if (blacklist !== value) {
          data = value;
        }
        break;
      default:
        return;
    }

    /** @type {import('../../jsdocs').SystemData | null} */
    let result = null;
    if (data !== null) {
      if (field === 'blacklist') {
        try {
          result = await axiosCall({
            url: `blacklist`,
            method: 'PUT',
            data: {
              address: data.address,
              expiresIn: data.expiresIn
            },
          });

          // @ts-ignore
          setBlacklist((prev) => {
            if (prev && prev.length > 1) {
              return [...prev, result];
            } else {
              return result;
            }
          });
          snackbarRef.current?.displayMessage(`${t('blacklistUpdated')}`);
        } catch (error) {
          snackbarRef.current?.displayMessage(error?.response?.data?.message);
          console.warn(error);
        }
      } else {
        try {
          /** @type {import('../../jsdocs').SystemData} */
          result = await axiosCall({
            url: `system`,
            method: 'PUT',
            data: {
              lender: systemData.lender,
              [field]: data,
            },
          });

          setSystemData(result);
        } catch (error) {
          console.warn(error);
        }
      }
    }
    return Promise.resolve(result);
  };

  // On load and tab switch, load system data
  useEffect(() => {
    // The default System view is System/Settings
    if (location.state?.module === `${sectionNameTranslationKeys['Admin']}/${sectionNameTranslationKeys['System']}`) {
      navigate('/console', { state: { module: `${baseModule}/${settingsSectionNameTranslationKeys['Settings']}` } });
      return;
    }

    // @ts-ignore
    if (location.state?.module.startsWith(baseModule) && systemData === null) {
      loadSystemData();
    }
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.module]);

  /**
   * This function is used for pagination of contacts and management contacts.
   * It slices the contacts array based on the provided startIndex and endIndex.
   * This is a client-side pagination approach, where all data is already loaded and just sliced for display.
   * @param {{ name: string, phone: string, email: string }[]} contacts
   */
  const getContactsForTable = (contacts, startIndex, endIndex) => {
    if (!Array.isArray(contacts)) return null;
    let count = 0;
    let paginatedContacts = contacts.slice(startIndex, endIndex);
    return paginatedContacts.map((contact) => {
      count++;
      return {
        id: count + '',
        fields: [{ key: t('nameCol'), value: contact.name }, { key: t('phoneCol'), value: formatPhoneNumber(contact.phone) }, { key: t('emailCol'), value: contact.email }],
      };
    });
  }

  /**
   * Formats contacts for the generic table
   * @param {import('../../jsdocs').ItemData[]} items
   */
  const getContactsForDB = (items) => {
    if (!Array.isArray(items)) return null;
    return items.map((item) => {
      return {
        name: item.fields[0].value,
        phone: item.fields[1].value,
        email: item.fields[2].value,
      };
    });
  }

  /**
   * @param {string | number | Date} updatedAtString
   * @param {number} expirationTime
   */
  function getRemainingTime(updatedAtString, expirationTime) {
    const updatedAt = new Date(updatedAtString); // Convert ISO string to Date
    const currentDate = new Date();
    const expirationDate = new Date(updatedAt.getTime() + expirationTime * 60 * 1000);

    const remainingTimeInMilliseconds = expirationDate.getTime() - currentDate.getTime();

    if (remainingTimeInMilliseconds > 0) {
      const remainingTimeInMinutes = Math.floor(remainingTimeInMilliseconds / (60 * 1000));
      return remainingTimeInMinutes; // Return minutes
    } else {
      return null; // Indicate expiration
    }
  }

  /**
   * Formats blacklist for the database
   * @param {any} blacklistData
   */
  const getBlacklistForTable = (blacklistData) => {
    if (!Array.isArray(blacklistData)) return null;
    return blacklistData.map((address) => {
      let formattedMinutes;
      const timeRemaining = getRemainingTime(address.updatedAt, address.expiresIn);
      if (address.expiresIn === 0) {
        formattedMinutes = t('indefinitely');
      } else if (timeRemaining === null) {
        formattedMinutes = t('expired');
      } else if (timeRemaining < 60) {
        formattedMinutes = `${timeRemaining} mins`;
      } else {
        let hours = Math.floor(timeRemaining / 60);
        let mins = address.expiresIn % 60;
        let days = Math.floor(hours / 24);
        formattedMinutes = `${days > 0 ? `${days} d` : ''} ${hours > 23 ? hours % 24 : hours} hr${hours > 1 ? 's' : ''}  ${mins} mins`;
      }

      return {
        id: address?._id,
        active: address.active,
        fields: [
          {
            key: t('addressCol'),
            value: address.address,
            type: 'string'
          },
          {
            key: t('expiresInCol'),
            value: timeRemaining,
            display: formattedMinutes,
            type: 'number',
          },
          {
            key: t('blacklistTime'),
            value: address.updatedAt,
            type: 'date',
            hidden: true,
          },
        ],
      };
    });
  };

  return (
    <>
      <GenericSnackbar ref={snackbarRef} />
      <Box padding={1}>
        {!systemData ? (
          // Loading
          <Box sx={{ marginLeft: 14 }}>
            <CircularProgress disableShrink />
          </Box>
        ) : (
          <Box>
            {/* Settings Section */}
            {location.state?.module === `${baseModule}/${settingsSectionNameTranslationKeys['Settings']}` && (
              <Box width="fit-content">
                <Box display="flex" flexDirection="column" gap={1} justifyContent="center">
                  <Card>
                    <CardContent>
                      <Stack alignItems="flex-start" spacing={2}>
                        <TextField
                          disabled
                          sx={{ width: '15rem' }}
                          size="small"
                          color="info"
                          variant="outlined"
                          label={t('systemHostAddress')}
                          defaultValue={systemData.lender}
                          onBlur={(e) => {
                            handleSave('lender', e.target.value);
                          }}
                        />
                        <FormControl fullWidth>
                          <InputLabel id="system-notification-banner" color="info">
                            {t('enableNotificationBannerLabel')}
                          </InputLabel>
                          <Select
                            labelId="system-notification-banner"
                            id="system-notification-banner-select"
                            color="info"
                            defaultValue={systemData.notificationBannerEnabled + ''}
                            label={t('enableNotificationBannerLabel')}
                            /**
                             * @param {SelectChangeEvent} e
                             */
                            onChange={(e) => {
                              handleSave('notificationBannerEnabled', e.target.value === 'true');
                            }}
                          >
                            <MenuItem value="true">{t('enabled')}</MenuItem>
                            <MenuItem value="false">{t('disabled')}</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          sx={{ width: '15rem' }}
                          size="small"
                          multiline
                          rows={2}
                          color="info"
                          variant="outlined"
                          label={t('systemNotificationLabel')}
                          defaultValue={systemData.message}
                          onBlur={(e) => {
                            handleSave('message', e.target.value);
                          }}
                        />
                        <FormControl fullWidth>
                          <InputLabel id="system-status" color="info">
                            {t('systemStatusLabel')}
                          </InputLabel>
                          <Select
                            labelId="system-status"
                            id="system-status-select"
                            color="info"
                            defaultValue={systemData.status}
                            label={t('systemStatusLabel')}
                            /**
                             * @param {SelectChangeEvent} e
                             */
                            onChange={(e) => handleSave('status', e.target.value)}
                          >
                            {systemStatusList &&
                              systemStatusList.map((status) => {
                                return (
                                  <MenuItem key={status} value={status}>
                                    {status}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>

                        <FormControl fullWidth>
                          <InputLabel id="system-message-translation" color="info">
                            {t('enableMessageTranslationLabel')}
                          </InputLabel>
                          <Select
                            labelId="system-message-translation"
                            id="system-message-translation-select"
                            color="info"
                            defaultValue={systemData.translationEnabled + ''}
                            label={t('enableMessageTranslationLabel')}
                            /**
                             * @param {SelectChangeEvent} e
                             */
                            onChange={(e) => {
                              handleSave('translationEnabled', e.target.value === 'true');
                            }}
                          >
                            <MenuItem value="true">{t('enabled')}</MenuItem>
                            <MenuItem value="false">{t('disabled')}</MenuItem>
                          </Select>
                        </FormControl>

                        {showDateTimePickers && (
                          <>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                renderInput={(props) => (
                                  <TextField sx={{ width: '15rem' }} size="small" color="info" {...props} />
                                )}
                                label={t('systemMaintenanceStartTime')}
                                inputFormat="yyyy/MM/dd hh:mm a"
                                value={new Date(systemData.startDateTime)}
                                onChange={() => {}}
                              />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DateTimePicker
                                renderInput={(props) => (
                                  <TextField sx={{ width: '15rem' }} size="small" color="info" {...props} />
                                )}
                                label={t('systemMaintenanceEndTime')}
                                inputFormat="yyyy/MM/dd hh:mm a"
                                value={new Date(systemData.endDateTime)}
                                onChange={() => {}}
                              />
                            </LocalizationProvider>
                          </>
                        )}
                      </Stack>
                    </CardContent>
                  </Card>
                  <ReferrerModal />
                </Box>
              </Box>
            )}
            {/* System Contacts Section */}
            {location.state?.module === `${baseModule}/${settingsSectionNameTranslationKeys['System Contacts']}` && (
              <Card sx={{ width: '100%', maxWidth: '1050px' }}>
                <CardContent>
                  <GenericTable
                    key={JSON.stringify(
                      systemData
                    )} /* this key will ensure that the table re-renders when systemData changes */
                    tableName={t('systemContactTableName')}
                    defaultFields={[
                      { key: t('nameCol'), value: '', required: true },
                      { key: t('phoneCol'), value: '', type: 'phone' },
                      { key: t('emailCol'), value: '', type: 'email' },
                    ]}
                    fetchItems={async (startIndex, endIndex) => {
                      return getContactsForTable(systemData.contacts, startIndex, endIndex);
                    }}
                    onItemAdd={async (fields) => {
                      await handleSave('contacts', [
                        ...systemData.contacts,
                        {
                          name: fields[0].value,
                          phone: fields[1].value,
                          email: fields[2].value,
                        },
                      ]);
                    }}
                    onItemUpdate={async (id, fields) => {
                      let contacts = getContactsForTable(systemData.contacts);
                      for (let contact of contacts) {
                        if (id === contact.id) {
                          // @ts-ignore
                          contact.fields = fields;
                        }
                      }
                      await handleSave('contacts', getContactsForDB(contacts));
                    }}
                    onItemDelete={async (id) => {
                      let contacts = getContactsForTable(systemData.contacts).filter((contact) => {
                        return contact.id !== id;
                      });
                      await handleSave('contacts', getContactsForDB(contacts));
                    }}
                    pagination={{ defaultRowsPerPage: 5 }}
                  />
                </CardContent>
              </Card>
            )}
            {/* Management Contacts Section */}
            {location.state?.module === `${baseModule}/${settingsSectionNameTranslationKeys['Management Contacts']}` && (
              <Card sx={{ width: '100%', maxWidth: '1050px' }}>
                <CardContent>
                  <GenericTable
                    key={JSON.stringify(
                      systemData
                    )} /* this key will ensure that the table re-renders when systemData changes */
                    tableName={t('managementContactTableName')}
                    defaultFields={[
                      { key: t('nameCol'), value: '', required: true },
                      { key: t('phoneCol'), value: '', type: 'phone' },
                      { key: t('emailCol'), value: '', type: 'email', required: true },
                    ]}
                    fetchItems={async (startIndex, endIndex) => {
                      return getContactsForTable(systemData.managementHotline, startIndex, endIndex);
                    }}
                    onItemAdd={async (fields) => {
                      await handleSave('managementHotline', [
                        ...(systemData.managementHotline || []),
                        {
                          name: fields[0].value,
                          phone: fields[1].value,
                          email: fields[2].value,
                        },
                      ]);
                    }}
                    onItemUpdate={async (id, fields) => {
                      let contacts = getContactsForTable(systemData.managementHotline);
                      for (let contact of contacts) {
                        if (id === contact.id) {
                          // @ts-ignore
                          contact.fields = fields;
                        }
                      }
                      await handleSave('managementHotline', getContactsForDB(contacts));
                    }}
                    onItemDelete={async (id) => {
                      let contacts = getContactsForTable(systemData.managementHotline).filter((contact) => {
                        return contact.id !== id;
                      });
                      await handleSave('managementHotline', getContactsForDB(contacts));
                    }}
                    pagination={{ defaultRowsPerPage: 5 }}
                  />
                </CardContent>
              </Card>
            )}
            {/* Blacklist Section */}
            {location.state?.module === `${baseModule}/${settingsSectionNameTranslationKeys['Blacklist']}` && (
              <Card sx={{ width: '100%', maxWidth: '1050px' }}>
                <CardContent>
                  <GenericTable
                    tableName={t('blacklistTableName')}
                    defaultFields={[
                      {
                        key: t('ipAddressCol'),
                        value: '',
                        type: 'string',
                        required: true,
                      },
                      {
                        key: t('expiresInCol'),
                        value: '',
                        type: 'number',
                        required: true,
                      },
                      {
                        key: t('blacklistTime'),
                        value: '',
                        type: 'date',
                        hidden: true,
                      },
                    ]}
                    fetchItems={async (startIndex, endIndex) => {
                      return getBlacklistForTable(await loadBlacklistData(startIndex, endIndex));
                    }}
                    onItemAdd={async (fields) => {
                      await createBlacklist({
                        address: fields[0].value,
                        expiresIn: fields[1].value,
                      });
                    }}
                    onItemUpdate={async (id, fields) => {
                      console.log('onUpdate', id, fields);
                      await updateBlacklist(id, fields);
                    }}
                    onItemDelete={async (id) => {
                      await deleteBlacklist(id);
                    }}
                    onItemReactivate={async (id) => {
                      await reactivateBlacklist(id);
                    }}
                    showInactiveClick={async () => {
                      blacklistGetActive.current = !blacklistGetActive.current;
                      await loadBlacklistData();
                      return blacklistGetActive.current;
                    }}
                    isActive={blacklistGetActive.current}
                    pagination={{ defaultRowsPerPage: 5 }}
                  />
                </CardContent>
              </Card>
            )}
            {/* Prize Rates Section */}
            {location.state?.module === `${baseModule}/${settingsSectionNameTranslationKeys['Prize Rates']}` &&
              <Box width="fit-content">
                <PrizeRates />
              </Box>
            }
          </Box>
        )}
      </Box>
    </>
  );
};

export default System;