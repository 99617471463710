import { Button, Dialog, DialogActions, DialogContent, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';

import { PhoneNumberPatternField } from '@components/FormattedTextField';
import axiosCall from '@services/axios';
import DialogHeader from '@components/DialogHeader';
import { prepareOperatorErrands } from '@common/errandUtils';
import { ChatType } from '@common/ChatType';

/*
 *  This component is the dialog that is seen when the plus button next to the Guest
 * list in console conversations.
 *
 *  This component takes the following props:
 *    - open: Whether or not this component is open
 *    - onClose: The function that should be called when the dialog is requested to close
 *    - onSubmit(name, email, phone): This is the function that is called when the
 *      ok button is hit, passes all state information along so this can be connected
 *      to an API easily.
 */

const InviteUserDialog = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [errorMessage, setErrorMessage] = useState(props.error);
  const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = async () => {
    setDisableButton(true);
    props.setConvIsLoading(true); // start the skeleton loader so the operator would know something is loading.
    const phoneDigits = phone.replace(/[^0-9]+/g, '');

    // Validate phone, 10 numbers required
    const phoneValid = phoneDigits.length === 0 || phoneDigits.length === 10;

    // Validate email, requires some text, '@', text, '.', text
    const emailValid = email.length === 0 || email.toLowerCase().match(/.+@.+\..+/gi);

    if (!phoneValid && !emailValid) {
      setErrorMessage('Please provide valid phone/email.');
      setDisableButton(false);
      return;
    } else if (!phoneValid) {
      setErrorMessage('Please provide valid phone.');
      setDisableButton(false);
      return;
    } else if (!emailValid) {
      setErrorMessage('Please provide valid email.');
      setDisableButton(false);
      return;
    }

    const data = {
      name: name,
      emailValid: emailValid && email.length > 0,
      phoneValid: phoneValid && phone.length > 0,
      email: email,
      phone: phone,
    };

    // Get Morgan
    let operators = await axiosCall({
      url: 'operator/participants/access',
      method: 'post',
      data: {
        search: 'AngelAi',
      },
    });
    let morgan = operators.filter((o) => o.accessLevel === 'Level 0')[0];

    // Make participants
    let participants = [
      {
        userId: props.operatorData?._id,
        userType: 'Operator',
      },
    ];
    if (morgan !== undefined)
      participants.push({
        userId: morgan?._id,
        userType: 'Operator',
      });

    // Make the chat that we are invititing them to
    const conv = await axiosCall({
      url: 'chat',
      method: 'post',
      data: {
        participants: participants,
        type: ChatType.Conversation,
        status: 'active', // add the user to the chat in the active state.
      },
    });

    // Get the correct templates to send
    let emailTemplates = await axiosCall({
      url: 'emailTemplate/search',
      method: 'post',
      data: {
        search: 'inviteUser',
      },
    });
    let etIndex = emailTemplates.findIndex((ets) => ets?.name === 'inviteUser');
    let smsTemplates = await axiosCall({
      url: 'smsTemplate/search',
      method: 'post',
      data: {
        search: 'inviteUser',
      },
    });
    let smstIndex = smsTemplates.findIndex((sts) => sts?.name === 'inviteUser');

    let names = name.split(' ');
    let hashkeyAccept = await axiosCall({
      url: 'hashkey',
      method: 'post',
      data: {
        parameters: {
          firstname: names.length > 0 ? names[0] : '',
          lastname: names.length > 1 ? names[1] : '',
          email: email,
          phone: phone,
          chatId: conv?._id,
          accepted: true,
          inviteSentById: props.operatorData?._id,
        },
      },
    });
    let hashkeyDecline = await axiosCall({
      url: 'hashkey',
      method: 'post',
      data: {
        parameters: {
          firstname: names.length > 0 ? names[0] : '',
          lastname: names.length > 1 ? names[1] : '',
          email: email,
          phone: phone,
          chatId: conv?._id,
          accepted: false,
          inviteSentById: props.operatorData?._id,
        },
      },
    });

    let payload,
      error = undefined;
    const publicUrl = process.env.REACT_APP_PUBLIC_URL;
    if (etIndex !== -1 && data.emailValid) {
      payload = {
        url: 'email/send',
        method: 'post',
        data: {
          senderId: props.operatorData?._id,
          senderType: 'Operator',
          to: data.email,
          chatId: conv?._id,
          templateId: emailTemplates[etIndex]?._id,
          templateAddtlVars: {
            fullName: name,
            senderName: props.operatorData?.firstname,
            acceptInviteUrl: `${publicUrl}/invite/${hashkeyAccept.key}`,
            declineInviteUrl: `${publicUrl}/invite/${hashkeyDecline.key}`,
          },
        },
      };
      try {
        await axiosCall(payload);
      } catch (err: any) {
        console.log('DEBUG err', err);
        error = err?.response?.data?.message;
      }
    }

    if (smstIndex !== -1 && data.phoneValid) {
      payload = {
        url: 'sms/send',
        method: 'post',
        data: {
          senderId: props.operatorData?._id,
          senderType: 'Operator',
          to: data.phone,
          chatId: conv?._id,
          templateId: smsTemplates[etIndex]?._id,
          templateAddtlVars: {
            fullName: name,
            senderName: props.operatorData?.firstname,
            acceptInviteUrl: `${publicUrl}/invite/${hashkeyAccept.key}`,
            declineInviteUrl: `${publicUrl}/invite/${hashkeyDecline.key}`,
          },
        },
      };
      try {
        await axiosCall(payload);
      } catch (err: any) {
        console.log('DEBUG err', err);
        error = err?.response?.data?.message;
      }
    }

    if (error === undefined) {
      setErrorMessage(undefined);

      // Add to the conversations list
      prepareOperatorErrands([conv], props.operatorData).then((errands) => {
        if (!Array.isArray(errands) || errands?.length === 0) return;
        errands[0].onlineStatus = 'offline';
        props.setErrands((prev) => {
          if (!Array.isArray(prev)) {
            console.warn('setErrands prev is not an array');
            prev = [];
          }
          return [errands[0], ...prev];
        });
        navigate('/console', {
          state: {
            module: 'conversations',
            tab: 'mine',
            chat: errands[0]?._id,
          },
        });
      });
      //Clean up!
      setDisableButton(false);
      setName('');
      setPhone('');
      setEmail('');
      props.onClose();
      props.setConvIsLoading(false);
    } else {
      setErrorMessage(error);
      setDisableButton(false);
      props.setConvIsLoading(false);
    }
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogHeader title={t('inviteUserDialogTitle')} onClose={props.onClose} />
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            autoFocus
            sx={{ marginTop: '10px' }}
            size="small"
            label={`${t('inviteUserDialogName')}*`}
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            size="small"
            label={`${t('inviteUserDialogEmail')}*`}
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <PhoneNumberPatternField
            defaultValue={phone}
            value={phone}
            type="tel"
            label={`${t('inviteUserDialogPhone')}*`}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
          />
          <Typography color={theme.palette.red['400']}>{errorMessage}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}> {t('inviteUserDialogCancel')} </Button>
        <Button
          variant="contained"
          disabled={name === '' || (email === '' && phone === '') || disableButton}
          onClick={handleSubmit}
        >
          {t('inviteUserDialogInvite')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;
