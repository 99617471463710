/**
 * Simple skeleton fallback to show something while the corresponding component loads.
 * Notice: these should be strictly presentational components!
 * 
 * The goal for these skeletons is to reduce layout shift as much as possible.
 * You want these to be <em>pixel perfect</em> to increase the percieved performance.
 * 
 * Create a separate style file for the skeleton to allow for better code splitting.
 */

import React, { Dispatch, SetStateAction, useMemo } from 'react';
import Skeleton from '@styles/Skeletons/Skeletons.module.css';
import Styles from '@styles/Skeletons/MorphMessageOptionsSkeleton.module.css';
import useLazyComponent from '@common/hooks/useLazyComponent';
import { IErrand } from '@interfaces/Conversation';

const MorphMessageOptionsFallback = () => {
  return (
    <div className={Skeleton.loader + ' ' + Styles.style} />
  );
};

// The render component below lazy loads the corresponding component using the skeleton above
type TRenderMorphMessageOptionsProps = {
  errand: IErrand;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
};

const MorphMessageOptionsSkeleton = ({
  errand, setEditMessageId, setValue,
}: TRenderMorphMessageOptionsProps) => {
  const renderOptions = useMemo(() => {
    // guard statements required for handleClickSentiment to function properly
    if (!errand || !errand._id) return false;
    if (typeof setEditMessageId !== 'function') return false;
    if (typeof setValue !== 'function') return false;
    return true;
  }, [errand?._id, setEditMessageId, setValue]);

  const MorphMessageOptionsProps = {
    errand, setEditMessageId, setValue,
  };

  const MorphMessageOptions = useLazyComponent(
    import('@components/MorphMessageOptions/MorphMessageOptions'), 
    <MorphMessageOptionsFallback />, 
    `./src/Components/Skeletons/MorphMessageOptionsSkeleton.tsx`
  );

  return renderOptions ? (
    <MorphMessageOptions {...MorphMessageOptionsProps} />
  ) : <></>
};

export default MorphMessageOptionsSkeleton;