import React from 'react';
import { Email, Phone } from "@mui/icons-material";
import { Tooltip } from '@mui/material';
import { 
  AuthorAlign,
  AuthorTypography,
  Bubble,
  BubbleWrapper, 
  ContactBox,
  ContactCircle, 
  ContactDivider,
  ContactInitials,
  DeclineCircle,
  DialingCircle,
  DividerLine,
  Pulse,
  PulseCircle,
  StyledIcon
} from '@styles/MessageContent/InviteMessageContentStyle';
import { InviteMessage } from "@mTypes/Conversation";
const dialer = process.env.REACT_APP_MORGAN_CDN + '/Images/dialer.gif';
const WorriedFaceEmoji = process.env.REACT_APP_MORGAN_CDN + '/Icons/WorriedFaceEmoji.svg';

// 
const FIRSTNAME_CHAR_LIMIT = 12;

type TInviteMessageContentProps = {
  message: InviteMessage;
}

const InviteMessageContent = ({
  message, 
}: TInviteMessageContentProps) => {
  // checks for required fields
  if(!message || !message.firstname) {
    console.info("message is undefined OR firstname is undefined");
    return <></>;
  }
  // reference variable to  message?.firstname
  const firstNamePropsRef = message?.firstname.toLowerCase();
  // calc if firstname is too long for the current window width
  const isTooLong = firstNamePropsRef.length > FIRSTNAME_CHAR_LIMIT;
  // Gets the needed string that complies with char limit.
  const getFirstNameStr = () => {
    // if firstname string is too long, then take a substring with length of given charLimit, else leave as is.
    if(isTooLong) return `${firstNamePropsRef.substring(0, FIRSTNAME_CHAR_LIMIT)}...`; 
    else return firstNamePropsRef;
  }
  // tooltip title text 
  // if firstname is too long, return the whole name, else return ""
  const getFirstNameTooltipTitle = () => {
    if(isTooLong) return firstNamePropsRef;
    else return "";
  }
//After invitation is sent and before the friend accepts or declines the invitation, a dialer dialog will appear
  if (message?.pending) {
    return(
      <BubbleWrapper>
        <ContactCircle sx={{ justifyContent: message?.accepted ? "space-around" : "" }}>
          <PulseCircle><Pulse /></PulseCircle>
          <DialingCircle component="img" src={dialer} alt="Dialing"></DialingCircle>
        </ContactCircle>
        <ContactBox>
          <div><span style={{display: "flex"}}>{'Dialing'}</span></div>
          <ContactDivider />
          <span style={{display: "flex", paddingBottom: "5px", letterSpacing: "3px"}}>.....</span>
          </ContactBox>
          <Bubble />
        </BubbleWrapper>
    )
//Once the invitation is accepted or declined, the resulting dialog will appear
  } else {
    return (
      <BubbleWrapper>
        <AuthorAlign sx={{ color: 'var(--orange050)' }}>
          <Tooltip placement="top" title={getFirstNameTooltipTitle()}>
            <AuthorTypography>
              {/* taken from message.firstname */}
              {getFirstNameStr()}
            </AuthorTypography>
          </Tooltip>
        </AuthorAlign>
        {/* Added ternery to dictate alignment, as defined components above require message */}
        <ContactCircle sx={{ justifyContent: message?.accepted ? "space-around" : "" }}>
          <>
            {
              message?.accepted ?
                <>
                  <ContactInitials>
                    {`${message.firstname?.substring(0, 1).toUpperCase()}${message.lastname?.substring(0, 1).toUpperCase()}`}
                  </ContactInitials>
                  <DividerLine />
                  <StyledIcon>
                    {message.phone.length >= 10 ? (<Phone sx={{ fontSize: "16px" }} />) : message.email ? (<Email sx={{ fontSize: "16px" }} />) : ''}
                  </StyledIcon>
                </>
                :
                <DeclineCircle component="img" src={WorriedFaceEmoji} alt="&#128543;"></DeclineCircle>
            }
          </>
        </ContactCircle>
        <ContactBox>
          <div><span>{message?.senderType === 'User' ? (message?.accepted? 'Friend Added' : 'Friend Declined') : (message?.accepted? 'User Added' : 'User Declined')}</span></div>
          <ContactDivider />
          {message.phone.length > 10 ? message.phone : message.email ? message.email : ''}
        </ContactBox>
        <Bubble />
      </BubbleWrapper>
    )
  }
};

export default InviteMessageContent;
