import React from 'react';
import styles from '@styles/MessageContent/ProfileBubbleMessageContent/Item.module.css';
import Sanitized from '@components/Sanitized';
import * as ProfileBubbleTypes from "@mTypes/ProfileBubbleTypes";

interface Props {
  value: ProfileBubbleTypes.Value;
}

const COMPONENT = 'Item';

/**
 * An item in the message. Contains an index and the value itself.
 */
export default function Item(props: Props): React.ReactElement {
  /**
   * Renders the text of the element. If a url is given render an a tag.
   */
  function renderText(): React.ReactNode {
    if (props.value.url) {
      return (
        <a href={props.value.url} target="_blank" rel="noreferrer">
          <Sanitized html={props.value.text} />
        </a>
      );
    } else {
      return <Sanitized html={props.value.text} />;
    }
  }

  return (
    <li data-component={COMPONENT} className={styles.item}>
      {renderText()}
    </li>
  );
}
