import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import { QuestionMark } from '@mui/icons-material';

import { IErrand, IMessage } from '@interfaces/Conversation';
import axiosCall from '@services/axios';
import useAbortController from '@common/hooks/useAbortController';

import Styles from '@styles/MorphMessageOptions.module.css';

type TMorphMessageOptionConvertProps = {
  errand: IErrand;
  handleClose: () => void;
  message: IMessage;
};

const MorphMessageOptionConvert = ({
  errand, handleClose, message
}: TMorphMessageOptionConvertProps) => {
  const { t, i18n } = useTranslation();
  const abortController = useAbortController();
  const [contextMenu, setContextMenu] = useState(null);
  const [userActions, setUserActions] = useState([]);

  const handleClickMenu = useCallback(async (event) => {
    try {
      // set the target for the userActions menu
      setContextMenu(event.currentTarget);

      // setup axios call with controller
      const request = {
        url: `chat/${message.chat}/userActions/${i18n.language}`
      };
      const controller = abortController.reset();
      const config = { signal: controller.signal };
      const response = await axiosCall(request, config);

      // response is pre-translated
      setUserActions(Array.from(new Map(response.map(obj => [obj?.action?._id, obj])).values()));
    } catch (error) {
      setUserActions([]);
      console.error(`MorphMessageOptionConvert.handleClickMenu`, error);
    }
  }, [errand?._id, message?._id, i18n.language, handleClose]);

  const handleClickConvert = useCallback(async (userAction) => {
    try {
      if (!userAction) return;

      // setup axios call with controller
      const request = {
        url: `chat/${errand._id}/message/${message._id}`,
        method: 'put',
        data: { messageType: 'Field', userAction },
      };
      const controller = abortController.reset();
      const config = { signal: controller.signal };
      const response = await axiosCall(request, config);

      console.log(
        `Set messageType for message ${message._id} to userAction ${userAction} in chat ${errand?._id}`,
        response
      );
      setContextMenu(null);
      handleClose();
    } catch (error) {
      console.error(`MorphMessageOptionConvert.handleClickConvert`, error);
    }
  }, [errand?._id, message?._id, handleClose]);

  return (
    <>
      <button className={Styles.btn} onClick={handleClickMenu}>
        <div className={Styles.btnBorder}>
          <QuestionMark />
          <p className={Styles.label}>{t('tActions')}</p>
        </div>
      </button>
      <Menu
        anchorEl={contextMenu}
        open={Boolean(contextMenu)}
        onClose={() => {
          setContextMenu(null);
        }}
        sx={{
          borderRadius: '10px',
        }}
      >
        {userActions?.map((v, i, a) => {
          if (!v || !v?.action || !v?.action?.description) return <span key={i}></span>;
          return (
            <MenuItem
              key={i}
              onClick={() => { handleClickConvert(v?._id) }}>
              {v?.action?.description}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  );
};

export default MorphMessageOptionConvert;
