const maxParticleCount = 250; //set max confetti count
const particleSpeed = 5; //set the particle animation speed

const colors = ["DodgerBlue", "OliveDrab", "Gold", "Pink", "SlateBlue", "LightBlue", "Violet", "PaleGreen", "SteelBlue", "SandyBrown", "Chocolate", "Crimson"]
let streamingConfetti = false;
let animationTimer = null;
let particles = [];
let waveAngle = 0;

type Particle = {
	color?: string;
	x?: number;
	y?: number;
	diameter?: number;
	tilt?: number;
	tiltAngleIncrement?: number;
	tiltAngle?: number;
}

const resetParticle = (particle: Particle, width: number, height: number) => {
	particle.color = colors[(Math.random() * colors.length) | 0];
	particle.x = Math.random() * width;
	particle.y = Math.random() * height - height;
	particle.diameter = Math.random() * 10 + 5;
	particle.tilt = Math.random() * 10 - 10;
	particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
	particle.tiltAngle = 0;
	return particle;
}

const startConfettiInner = () => {
	const width = window.innerWidth;
	const height = window.innerHeight;
	const reqAnimFrame = (function () {
		return window.requestAnimationFrame ||
			function (callback) {
				return window.setTimeout(callback, 16.6666667);
			};
	})();
	if (!document.getElementById('confetti-canvas')) {
		let canvas: HTMLCanvasElement = document.createElement("canvas");
		canvas.setAttribute("id", "confetti-canvas");
		canvas.setAttribute("style", "display:block;position:absolute;top:0;left:0;z-index:999999;pointer-events:none");
		document.body.appendChild(canvas);
		canvas.width = width;
		canvas.height = height;
		let context = canvas.getContext("2d");
		while (particles.length < maxParticleCount)
			particles.push(resetParticle({}, width, height));
		streamingConfetti = true;
		if (animationTimer === null) {
			(function runAnimation() {
				context.clearRect(0, 0, window.innerWidth, window.innerHeight);
				if (particles.length === 0)
					animationTimer = null;
				else {
					updateParticles();
					drawParticles(context);
					animationTimer = reqAnimFrame(runAnimation);
				}
			})();
		}
	} else {
		let canvas = document.getElementById('confetti-canvas') as HTMLCanvasElement ;
		let context = canvas.getContext("2d");
		while (particles.length < maxParticleCount)
			particles.push(resetParticle({}, width, height));
		streamingConfetti = true;
		if (animationTimer === null) {
			(function runAnimation() {
				context.clearRect(0, 0, window.innerWidth, window.innerHeight);
				if (particles.length === 0)
					animationTimer = null;
				else {
					updateParticles();
					drawParticles(context);
					animationTimer = reqAnimFrame(runAnimation);
				}
			})();
		} 
	}
}

const stopConfettiInner = () => {
	streamingConfetti = false;
}

const removeConfettiInner = () => {
	stopConfettiInner();
	particles = [];
}

const toggleConfettiInner = () => {
	if (streamingConfetti)
		stopConfettiInner();
	else
		startConfettiInner();
}

const drawParticles = (context: CanvasRenderingContext2D) => {
	let particle: Particle;
	let x: number;
	for (var i = 0; i < particles.length; i++) {
		particle = particles[i];
		context.beginPath();
		context.lineWidth = particle.diameter;
		context.strokeStyle = particle.color;
		x = particle.x + particle.tilt;
		context.moveTo(x + particle.diameter / 2, particle.y);
		context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
		context.stroke();
	}
}

const updateParticles = () => {
	const width = window.innerWidth;
	const height = window.innerHeight;
	let particle: Particle;
	waveAngle += 0.01;
	for (let i = 0; i < particles.length; i++) {
		particle = particles[i];
		if (!streamingConfetti && particle.y < -15)
			particle.y = height + 100;
		else {
			particle.tiltAngle += particle.tiltAngleIncrement;
			particle.x += Math.sin(waveAngle);
			particle.y += (Math.cos(waveAngle) + particle.diameter + particleSpeed) * 0.5;
			particle.tilt = Math.sin(particle.tiltAngle) * 15;
		}
		if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
			if (streamingConfetti && particles.length <= maxParticleCount)
				resetParticle(particle, width, height);
			else {
				particles.splice(i, 1);
				i--;
			}
		}
	}
}

export {
	resetParticle,
	startConfettiInner,
	stopConfettiInner,
	removeConfettiInner,
	toggleConfettiInner,
	drawParticles,
	updateParticles,
}