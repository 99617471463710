import React, { useEffect, useRef, useState } from 'react';
import { StaticClassNames } from './constants';
import { useTranslation } from 'react-i18next';
const UserPromptsMenuArrowStatic = process.env.REACT_APP_MORGAN_CDN + '/Images/UserPromptsMenuArrowStatic.png';

type TAnimationState = (typeof AnimationState)[keyof typeof AnimationState];
enum AnimationState {
  Start = 'Start',
  End = 'End',
}

const AnimatedArrowImg = (props) => {
  const { t } = useTranslation();

  return (
    <img
      className={StaticClassNames.DefaultArrow[props.side]}
      src={UserPromptsMenuArrowStatic}
      alt={
        `${t('UPM_User_prompts_menu_navigation_animated_Arrow')}` +
        ` ${props.side === 'left' ? t('UPM_Left') : t('UPM_Right')}`
      }
      style={props.style}
    />
  );
};

const getCurrAnimStyleOf = (arrowStyles, animationState) => {
  return animationState === AnimationState.Start ? arrowStyles.start : arrowStyles.end;
};

const getVisibleArrowStyle = (side) => {
  return {
    visibility: 'visible',
    opacity: '1',
    transform: `translateX(0px) scale(1) rotate(${side === 'left' ? '90deg' : '-90deg'})`,
  };
};

const getFirstArrowStyles = (side) => {
  return {
    start: getVisibleArrowStyle(side),
    end: {
      visibility: 'hidden',
      opacity: '0',
      transform: `translateX(${side === 'left' ? '-10px' : '10px'}) scale(0) rotate(${
        side === 'left' ? '90deg' : '-90deg'
      })`,
    },
  };
};

const getSecondArrowStyles = (side) => {
  return {
    start: {
      visibility: 'hidden',
      opacity: '0',
      transform: `translateX(${side === 'left' ? '8px' : '-8px'}) scale(0) rotate(${
        side === 'left' ? '90deg' : '-90deg'
      })`,
    },
    end: getVisibleArrowStyle(side),
  };
};

const allArrowStyles = {
  first: {
    left: getFirstArrowStyles('left'),
    right: getFirstArrowStyles('right'),
  },
  second: {
    left: getSecondArrowStyles('left'),
    right: getSecondArrowStyles('right'),
  },
};

export const AnimatedArrow = (props) => {
  const [animationState, setAnimationState] = useState<TAnimationState>(AnimationState.Start);

  const tmEndIdref = useRef(null);
  const tmStartIdref = useRef(null);

  const isAnimationPlaying = useRef(false);
  const getAnimationPaying = () => isAnimationPlaying.current;
  const setAnimationPlayingRef = (b: boolean) => (isAnimationPlaying.current = b);

  const firstArrowStyles = allArrowStyles.first[props.side];
  const secondArrowStyles = allArrowStyles.second[props.side];

  const doPlayAnimation = () => {
    if (getAnimationPaying() === true) return;

    setAnimationPlayingRef(true);
    // play actual
    setAnimationState(AnimationState.End);
    // set as ended in 500
    tmEndIdref.current = setTimeout(() => {
      setAnimationPlayingRef(false);
      props.onAnimationEnd();
      props.animationFinishedRef.current = true;
    }, 550);
  };

  useEffect(() => {
    // mount
    // queue
    tmStartIdref.current = setTimeout(() => {
      doPlayAnimation();
    }, 50);

    return () => {
      clearTimeout(tmEndIdref.current);
      clearTimeout(tmStartIdref.current);
    };
  }, []);

  const currStyles = {
    first: getCurrAnimStyleOf(firstArrowStyles, animationState),
    second: getCurrAnimStyleOf(secondArrowStyles, animationState),
  };

  return (
    <>
      <AnimatedArrowImg style={{ ...currStyles.first }} side={props.side} />
      <AnimatedArrowImg style={{ ...currStyles.second }} side={props.side} />
    </>
  );
};