import IconButton from '@mui/material/IconButton';
import React, { PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Styles from '@styles/PreviewSelectedFiles.module.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel, Keyboard } from 'swiper';
import { useMorphContext } from '@contexts/MorphContext';

const DocumentPreview = process.env.REACT_APP_MORGAN_CDN + '/Images/DOC.png';

const PreviewSelectedFiles: React.FC<PropsWithChildren<any>> = (props) => {

  const morphContext = useMorphContext();

  const handleCloseButton = (e, v) => {
    e?.stopPropagation();
    props.setSelectedFiles((prev) => prev.filter((x) => x !== v));
    if (props.selectedFiles.length === 1){
      props.setIsUploadingDocs(false);
      props.setIsUploadingImages(false);
      props.setIsTakingPic(false)
      morphContext.setAttachmentTabText('opt')
    }
  }

  return (
    <Swiper className={Styles.psfDiv}
      spaceBetween={3}
      slidesPerView={'auto'}
      freeMode={true}
      grabCursor={true}
      mousewheel={true}
      modules={[FreeMode, Mousewheel, Keyboard]}
    >
      {props.selectedFiles?.map((v, i, a) => (
        <SwiperSlide key={i} className={Styles.swiperSlide}>
          <div className={Styles.psfThumb}>
            <IconButton
              className={Styles.psfNail}
              onClick={(e) => handleCloseButton(e, v)}
            >
              <CloseIcon 
              className={Styles.psfCloseIcon} 
              />
            </IconButton>
            <div 
            className={Styles.psfFileHolder}
            >
              <img
                src={typeof v.type === 'string' && v.type.split('/')[0] === 'image' ? URL.createObjectURL(v) : DocumentPreview}
                alt={v.name}
              />
              {props.isSending && <CircularProgress />}
              <p>{v.name}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PreviewSelectedFiles;
