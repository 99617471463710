/**
 * @file EditHelpTipGroupDialog.tsx
 * @description This file describes the UI element that allows an operator to edit HelpTipGroups from the
 * admin UI. This component was created as part of the MRGN-246 task.
 * @author Ryan Buxton <ryan.buxton@swmc.com>
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '../Assets/Icons';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosCall from '../Services/axios';

const EditHelpTipGroupDialog = ({ data, open, onClose, reload, isEditing, allTips }) => {
  const [name, setName] = useState(data?.name || '');
  const [tips, setTips] = useState(data?.tips || []);
  const [selectedTip, setSelectedTip] = useState('');
  const { t } = useTranslation();

  const handleSelect = (event) => setSelectedTip(event.target.value);
  const handleAddTip = (event) => setTips((prev) => [...prev, selectedTip]);
  const handleRemoveTip = (id) =>
    setTips((prev) => {
      // Prev is const so a copy needs to be made and edited
      let temp = [...prev];
      temp.splice(id, 1);
      return temp;
    });
  const handleSubmit = async () => {
    let payload;
    let tipIds = tips.map((t) => t._id);
    if (isEditing) {
      payload = {
        url: `helptipgroup/${data._id}`,
        method: 'PUT',
        data: {
          name: name,
          tips: tipIds,
        },
      };
    } else {
      payload = {
        url: `helptipgroup`,
        method: 'POST',
        data: {
          name: name,
          tips: tipIds,
        },
      };
    }
    await axiosCall(payload);
    await reload();
    onClose();
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">{isEditing ? t('editTipGroup') : t('createTipGroup')}</Typography>
          <Close style={{ width: '12px', cursor: 'pointer' }} color="var(--gray210)" onClick={() => onClose()} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: '40vw' }}>
        <Stack spacing={1}>
          <TextField
           
            label={t('nameCol')}
            onChange={(e) => setName(e.target.value)}
            value={name}
            sx={{ marginTop: '12px' }}
            variant="outlined"
          />
          <Typography>{t('tipsCol')}</Typography>
          <List sx={{ maxHeight: '40vh' }}>
            <Divider />
            {tips?.length > 0 ? (
              tips.map((tip, i) => {
                return (
                  <>
                    <ListItem>
                      <ListItemText> {`${i + 1} - ${tip.name}`} </ListItemText>
                      <ListItemIcon>
                        <IconButton
                          onClick={() => {
                            handleRemoveTip(i);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemIcon>
                    </ListItem>
                    <Divider />
                  </>
                );
              })
            ) : (
              <Typography align="center">{t('noTips')}</Typography>
            )}
          </List>
          <Stack direction="row" spacing={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{t('tipsCol')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedTip}
                label="Help Tip"
                onChange={handleSelect}
              >
                {allTips.map((tip, i) => {
                  return <MenuItem value={tip}> {tip.name} </MenuItem>;
                })}
              </Select>
            </FormControl>
            <Button variant="outlined" color="info" onClick={handleAddTip}>
              {t('addHelpTip')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleSubmit} disabled={name.length === 0 || tips.length === 0}>
          {t('doneButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditHelpTipGroupDialog;
