import { MorganTheme, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import React, { useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  regularTextStyle,
  listTextStyle,
  headerStyle,
  listItemStyle,
  EsignActionStateType,
  formWrapper,
} from './commonForms';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { IFormContext, FormContext } from '@contexts/FormContext';
import { useUserContext } from '../Contexts/user';

const EConsent = () => {
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const formContext = useContext<IFormContext>(FormContext);
  const { isOperator } = useUserContext();

  // We won't allow action of form
  const actionDisable = isOperator || errandContext.esignActionState !== EsignActionStateType.Unsigned;

  const theme: MorganTheme = useTheme();

  const radioButtonHandler = (event) => {
    formContext.mainSignatureInsertedEventHandler();

    formContext.setEConsentRadioButtonState((event.target as HTMLInputElement).value);
  };

  return (
    <div style={formWrapper}>
      <h3 style={headerStyle}>ELECTRONIC RECORD AND SIGNATURE DISCLOSURE</h3>
      <p style={regularTextStyle}>
        From time to time, {theme.system.companyName} (we, us or Company) may be required by law to provide to you
        certain written notices or disclosures. In addition, we may need to send you agreements and other documents that you
        will need to sign. Described below are the terms and conditions for providing to you such notices, disclosures and
        documents electronically, and for obtaining your electronic signature. Please read the information below carefully and
        thoroughly. If you can access this information electronically to your satisfaction and agree to these terms and conditions,
        please confirm your agreement by clicking the 'I agree' button at the bottom of this document.
      </p>
      <h5 style={headerStyle}>All notices and disclosures will be sent to you electronically</h5>
      <p style={regularTextStyle}>
        Electronic Records may be provided to you via email, our website, or other electronic means. Unless you tell us
        otherwise in accordance with the procedures described below, you hereby consent to receive and utilize electronic
        records, including but not limited to required notices, authorizations, acknowledgements, agreements, contracts, notices,
        disclosures, and other documents that are required to be provided or made available to you during the course of our
        relationship with you ("Records"), in connection with your relationship with {theme.system.companyName}
      </p>
      <br />
      <p style={regularTextStyle}>
        All communications, transactions, and interactions with {theme.system.companyName} may be conducted
        electronically, unless otherwise specified. This includes but is not limited to the initiation, execution, and delivery of
        agreements and other legally binding documents.
      </p>
      <br />
      <p style={regularTextStyle}>
        To reduce the chance of you inadvertently not receiving any notice or disclosure, we prefer to provide all of the required
        notices and disclosures to you by the same method and to the same address. Thus, you can receive all the disclosures
        and notices either (1) electronically or (2) in paper format through the paper mail delivery system. If you click the 'I agree'
        button below, you will be agreeing to receive all notices, disclosures and documents electronically, and to sign
        agreements and other documents electronically. Please note that if you do not agree to electronic receipt and signing
        during this step of the application, we will send all notices, disclosures and documents in paper form.
      </p>
      <h5 style={headerStyle}>Getting paper copies</h5>
      <p style={regularTextStyle}>
        You have the right to request paper copies of any electronic Records provided to you by {theme.system.companyName}.
        Please note that there may be associated fees for the delivery of paper copies, and these fees will be communicated
        to you before processing your request. Even if you choose to receive and sign notices, disclosures and documents
        electronically, you may at any time request from us a paper copy of any material provided or made available
        electronically to you by us. You will also have the ability to download and print documents we send to you electronically.
        To request delivery from us of paper copies of the notices, disclosures or documents previously provided by us to you
        electronically, you must send us an e-mail to {theme.system.esignEmail}. In the email body of such request you must state your
        e-mail address, full name, US Postal address, and telephone number.
      </p>
      <h5 style={headerStyle}>Withdrawing your consent</h5>
      <p style={regularTextStyle}>
        If you choose to receive notices and disclosures from us electronically at this time, you may at any time change your
        mind and tell us that thereafter you want to receive required notices and disclosures only in paper format. To inform us
        that you no longer want to receive future notices and disclosures in electronic format you may send us an e-mail
        to {theme.system.esignEmail}. In the email body of such request you must state your e-mail address, full name, US Postal
        address, and telephone number.
      </p>
      <h5 style={headerStyle}>Consequences of changing your mind</h5>
      <p style={regularTextStyle}>
        If you elect to receive required notices and disclosures only in paper format, it may slow the speed at which we can
        complete certain steps in transactions with you and delivering services to you because we will need first to send the
        required notices or disclosures to you in paper format, and then wait until we receive back from you your
        acknowledgment of your receipt of such paper notices or disclosures.
      </p>
      <h5 style={headerStyle}>To advise {theme.system.companyName} of your new e-mail address</h5>
      <p style={regularTextStyle}>
        To let us know of a change in your e-mail address where we should send notices and disclosures electronically to you,
        you must send an email message to us at {theme.system.esignEmail} and in the body of such request you must state: your
        previous e-mail address, your new e-mail address. We do not require any other information from you to change your
        email address.
      </p>
      <h5 style={headerStyle}>How to contact {theme.system.companyName} about anything else</h5>
      <p style={regularTextStyle}>
        You may contact us to ask questions, seek assistance, update other contact information in addition to your
        email, or otherwise by sending an email message to {theme.system.esignEmail}.
      </p>
      <h5 style={headerStyle}>Required hardware and software Operating Systems</h5>
      <p style={regularTextStyle}>
        To access and retain electronic Records, you must have a computer or mobile device with internet access and a
        compatible web browser. You are responsible for ensuring the compatibility of your system and maintaining access to
        the necessary equipment and software required for electronic communications.
      </p>
      <br />
      <p style={regularTextStyle}>
        <span style={{ textDecoration: 'underline' }}>Operating System</span>: Windows&#8482; 2000, Windows&#8482; XP,
        Windows Vista&#8482;; Mac OS&#8482; X
      </p>
      <p style={regularTextStyle}>
        <span style={{ textDecoration: 'underline' }}>Browsers</span>: Final release versions of Internet
        Explorer&#8482; 6.0 or above (Windows only); Mozilla Firefox 2.0 or above (Windows and Mac); Safari&#8482; 3.0
        or above (Mac only)
      </p>
      <p style={regularTextStyle}>
        <span style={{ textDecoration: 'underline' }}>PDF Reader</span>: Acrobat&#8482; or similar software may be
        required to view and print PDF files
      </p>
      <p style={regularTextStyle}>
        <span style={{ textDecoration: 'underline' }}>Screen Resolution</span>: 800 x 600 minimum
      </p>
      <p style={regularTextStyle}>
        <span style={{ textDecoration: 'underline' }}>Enabled Security Settings</span>: Allow per session cookies
      </p>
      <p style={regularTextStyle}>
        ** These minimum requirements are subject to change. If these requirements change, you will be asked to
        re-accept the disclosure.
      </p>
      <h5 style={headerStyle}>Security and Confidentiality</h5>
      <p style={regularTextStyle}>
        {theme.system.companyName} takes reasonable measures to protect the security and confidentiality of electronic
        Records. However, it is your responsibility to maintain the security of your personal information and take appropriate
        precautions when accessing and storing electronic Records.
      </p>
      <h5 style={headerStyle}>Updating Contact Information</h5>
      <p style={regularTextStyle}>
        It is your responsibility to promptly update {theme.system.companyNameShort || theme.system.companyName} with any changes to your contact information, including
        email address and other relevant details, to ensure the uninterrupted delivery of electronic Records.
      </p>
      <h5 style={headerStyle}>Validity and Enforceability</h5>
      <p style={regularTextStyle}>
        You acknowledge and agree that electronic Records and signatures have the same legal effect as paper documents and
        handwritten signatures. You will not contest the validity or enforceability of electronic Records solely on the grounds that
        they are in electronic form.
      </p>
      <h5 style={headerStyle}>Acknowledging your access and consent to receive materials electronically</h5>
      <p style={regularTextStyle}>
        In order to provide you with notices, disclosures and documents electronically, we need to confirm that you are able to
        access these materials, and that you can print and/or store them electronically. To confirm to us that you can access this
        information electronically, which will be similar to other electronic notices and disclosures that we will provide to you,
        we ask that you verify that you were able to read this electronic disclosure and that you also were able to print on paper
        or electronically save this disclosure for your future reference and access.
      </p>
      <h5 style={headerStyle}>By clicking the 'I agree' button, I confirm that:</h5>
      <ul>
        <li style={listItemStyle}>
          <p style={listTextStyle}>
            I can access and read this Electronic "ELECTRONIC RECORD AND SIGNATURE DISCLOSURES" document; and
          </p>
        </li>
        <li style={listItemStyle}>
          <p style={listTextStyle}>
            I have the necessary technology and consent to receive and transact using electronic Records and signatures;
            and
          </p>
        </li>
        <li style={listItemStyle}>
          <p style={listTextStyle}>
            I agree to review and retain a copy of this Disclosure for my records; and
          </p>
        </li>
        <li style={listItemStyle}>
          <p style={listTextStyle}>
            If I have any questions or concerns regarding this Disclosure, I will contact {theme.system.companyNameShort || theme.system.companyName} for further
            assistance; and
          </p>
        </li>
        <li style={listItemStyle}>
          <p style={listTextStyle}>
            I can print the disclosure on paper, or save the disclosure to a location where I can store it and from which I can
            print it, for future reference and access; and
          </p>
        </li>
        <li style={listItemStyle}>
          <p style={listTextStyle}>
            Unless and until I notify {theme.system.companyName} in the manner described above that I no longer
            consent, I agree to receive exclusively through electronic means all notices, disclosures, authorizations,
            acknowledgements, and other documents that are required to be provided or made available to me by {theme.system.companyName} During
            the course of my relationship with it, and I agree that all documents
            requiring my signature may be signed electronically using the means provided by {theme.system.companyName} for doing so; and
          </p>
        </li>
        <li style={listItemStyle}>
          <p style={listTextStyle}>
            By continuing with the signing and execution process you hereby agree, acknowledge, and understand that the
            agreements and documents you electronically sign and execute through AngelAi constitute the entire
            understanding between the parties thereto with respect to the matters set forth in such agreements and
            documents, and supersede all prior or contemporaneous oral, chatbot, or written communications regarding
            same. You further agree, acknowledge, and understand and agree that none of AngelAi, any employee, agent
            or other representative of {theme.system.companyName} has any authority to bind such party with regard
            to any statement, representation, warranty or other expression unless said statement, representation, warranty
            or other expression is specifically included within the express terms of the agreement or document you sign
            and execute through AngelAi. By continuing to sign and execute the related agreement and documents
            presented by AngelAi you hereby affirm the above. If you do not agree to these terms you should hit the
            decline button set forth below.
          </p>
        </li>
      </ul>
      <RadioGroup
        id="authLast"
        name="e-consent-radio-button-group"
        value={formContext.eConsentRadioButtonState}
        onChange={radioButtonHandler}
      >
        <FormControlLabel
          disabled={actionDisable}
          value={'t'}
          control={<Radio />}
          label="I Agree"
        />
        <FormControlLabel
          disabled={actionDisable}
          value={'f'}
          control={<Radio />}
          label="I Do Not Agree"
        />
      </RadioGroup>
    </div>
  );
};

export default EConsent;
