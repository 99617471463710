import React from 'react';

import { Box, Chip, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';

import Avatar from './Avatar';
import { useTranslation } from 'react-i18next';

/*
 *  This component is the user badge which is seen in the three lists in the center
 * part of the console dashboard. See the Figma for more:
 * https://www.figma.com/file/5HrQkz2QKGTb97ZvTsqfOC/Dashbord?node-id=613%3A71
 *
 *  This component uses the following properties:
 *    - name: Users name as displayed on the badge
 *    - waitTime: Users wait time (XXD xxH xxM xxS)
 *    - date: Date presented on the badge
 *    - time: Time presented on the badge
 *    - onClick: Event to happen after clicking onz
 */
const AttendButton = styled(Chip)(() => ({
  color: 'var(--green400)',
}));

const ContentBox = styled(Box)(() => ({
  padding: '15px 2px',
}));

const UserBadge = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ContentBox>
      <Grid container justifyContent="space-evenly">
        <Grid item xs={2}>
          {/* Avatar */}
          <Avatar alt={props.alt} src={props.img} />
        </Grid>

        <Grid item xs={10}>
          <Stack>

            <Stack direction='row'>
              <Tooltip title={props.name} placement="top" arrow>
                <Typography
                  sx={{
                    textAlign: 'left',
                    fontSize: 16,
                    fontWeight: 'bold',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    textTransform: 'capitalize',
                    width: '60%'
                   }}>
                  {props.name}
                </Typography>
              </Tooltip>
              <Typography sx={{ textAlign: 'right', fontSize: 12, marginLeft: 'auto'}}> {props.date} </Typography>
            </Stack>

            <Stack direction='row'>
              <Typography sx={{ fontSize: 12 }}> {t('userBadgeWaitTime')} </Typography>
              <Typography
                sx={{ color: theme.palette.peach900, fontSize: 12, fontWeight: 'bold', paddingLeft: 1}}>
                {props.waitTime}
              </Typography>

              <Typography sx={{ textAlign: 'right', fontSize: 12, marginLeft: 'auto' }}> {props.time} </Typography>
            </Stack>

            {props.accessLevel !== 'Level 2' && (
              <AttendButton
                size="medium"
                label={t('userBadgeAttendButton')}
                sx={{
                  '--green400': theme.palette.green['400'],
                  backgroundColor: alpha(theme.palette.green['400'], 0.1),
                  width: '50%'
                }}
                onClick={props.onClick}
              />
            )}

          </Stack>
        </Grid>

      </Grid>
    </ContentBox>
  );
};

export default UserBadge;
