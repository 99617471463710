import * as ProfileBubbleTypes from '@mTypes/ProfileBubbleTypes';

const FILENAME = 'getProfileBubbleMessage.ts';

const log1 = { filename: FILENAME };

/**
 * Gets the message for the message type ProfileBubble.
 */
export default function getProfileBubbleMessage(message: string): ProfileBubbleTypes.Message {
  const log2 = { function: 'getProfileBubbleMessage' };

  try {
    // get parsedMessage
    const parsedMessage = JSON.parse(message);

    // check parsedMessage
    if (!parsedMessage.title) {
      const message = 'parsedMessage.title not found';
      console.warn({ ...log1, ...log2, message });
      return;
    } else if (!Array.isArray(parsedMessage.values)) {
      const message = 'parsedMessage.values not an array';
      console.warn({ ...log1, ...log2, message });
      return;
    }

    // the values should not be longer than 99
    if (parsedMessage.values.length > 99) {
      const message = 'parsedMessage.values should not be longer than 99';
      console.warn({ ...log1, ...log2, message });
    }

    // check message
    return parsedMessage;
  } catch (err) {
    console.error({ ...log1, ...log2, err });
  }
}
