const getTypingName = (obj, operatorView, isPrivate, _id) => {
  if (operatorView) {
    if (obj?.userData?._id !== _id) {
      return {
        name: obj?.userData?.firstname.toLowerCase(),
        isNickName: false,
        participantId: obj?._id,
      };
    }
  } else {
    if (obj?.userData?._id !== _id) {
      if (isPrivate) {
        return {
          name: 'angelai',
          isNickName: true,
          participantId: obj?._id,
        };
      }
      return obj.userData?.nickname
        ? {
            name: obj.userData?.nickname.toLowerCase().trim(),
            isNickName: true,
            participantId: obj?._id,
          }
        : {
            name: obj?.userData?.firstname.toLowerCase().trim(),
            isNickName: obj?.userData?.accessLevel === 'Level 0' ? true : false,
            participantId: obj?._id,
          };
    }
  }
  return {};
};

export default getTypingName;