import { useEffect, useRef, useCallback } from 'react';
import useTimeout from './useTimeout';

/**
 * A React hook that throttles the execution of a callback function.
 *
 * @param {useCallback} callback - The function to be throttled.
 * @param {number} [interval=500] - The time interval in milliseconds for throttling.
 *
 * @returns {void}
 */
export default function useThrottle(callback: () => void, interval = 500) {
  // Ref to store the timestamp of the last executed callback
  const lastExecuted = useRef(0);

  // Create a memoized version of the callback function
  const throttleCallback = useCallback(() => {
    // Update the last executed timestamp to the current time
    lastExecuted.current = Date.now();
    // Call the original callback
    callback();
  }, [callback]);

  const { reset } = useTimeout(throttleCallback, interval);

  // Effect to check the last execution timestamp and determine if
  // the callback should be executed immediately or delayed
  useEffect(() => {
    if (Date.now() >= lastExecuted.current + interval) {
      // If enough time has passed, execute the callback immediately
      throttleCallback();
    } else {
      // If not enough time has passed, reset the timeout to delay the callback
      reset();
    }
  }, [throttleCallback, interval, reset]);
}
