import React from 'react';

import Box from '@mui/material/Box';

const css = `
  .waveTop {
    animation: wave 10s linear infinite;
  }

  .wave1 {
    animation: wave1 17s linear infinite;
  }

  .wave2 {
    animation: wave2 25s linear infinite;
  }

  @keyframes wave {
    0% {
      transform: scaleY(1) translateX(0%);
    }
    
    50% {
      transform: scaleY(.55) translateX(70%)
    }

    100% {
      transform: scaleY(1) translateX(100%);
    }
  }

  @keyframes wave1 {
    0% {
      transform: scaleY(1.2) translateX(0%);
    }
    
    50% {
      transform: scaleY(1) translateX(30%)  
    }

    100% {
      transform: scaleY(1.2) translateX(100%);
    }
  }

  @keyframes wave2 {
    0% {
      transform: scaleY(.8) translateX(0%);
    }
    
    50% {
      transform: scaleY(1.1) translate(60%)
    }

    100% {
      transform: scaleY(.8) translateX(100%);
    }
  }
  `;

const Waves = (props: { color: string; }) => {
  return (
    <Box
      style={{
        width: '100%',
        height: '50px',
        position: 'absolute',
        bottom: '0',
        transform: 'rotate(180deg)',
      }}>
      <svg viewBox="0 50 1440 320" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <style type="text/css">{css}</style>
          <path
            id="sineWave"
            fill={props.color}
            fillOpacity="0.6"
            d="M0,160 C320,300,420,300,740,160 C1060,20,1120,20,1440,160 V0 H0"
          />
        </defs>
        <use className="waveTop" href="#sineWave" />
        <use className="waveTop" x="-100%" href="#sineWave" />
        <use className="wave1" href="#sineWave" />
        <use className="wave1" x="-100%" href="#sineWave" />
        <use className="wave2" href="#sineWave" />
        <use className="wave2" x="-100%" href="#sineWave" />
      </svg>
    </Box>
  );
};

export default Waves;
