import React from 'react';
import styled from '@emotion/styled';

const Progress = (props) => {
  const StyledDiv = styled.div`
    background-color: var(--gray000);
    border-radius: 3px;
    box-shadow: 0 0 0 1px var(--shadow010);
    height: 7.5px;
    border-radius: 7.5px;
    width: 225px;
    @-webkit-keyframes progress-bar-stripes {
      from {
        background-position: 40px 0;
      }
      to {
        background-position: 0 0;
      }
    }
    @keyframes progress-bar-stripes {
      from {
        background-position: 40px 0;
      }
      to {
        background-position: 0 0;
      }
    }
    .progress-bar {
      height: 7.5px;
      border-radius: 5px;
      background-color: ${props.bg};
      box-shadow: none;
      -webkit-animation: progress-bar-stripes 2s linear infinite;
      -o-animation: progress-bar-stripes 2s linear infinite;
      animation: progress-bar-stripes 2s linear infinite;
      background-image: -webkit-linear-gradient(
        45deg,
        var(--shadow227) 25%,
        transparent 25%,
        transparent 50%,
        var(--shadow227) 50%,
        var(--shadow227) 75%,
        transparent 75%,
        transparent
      );
      background-image: -o-linear-gradient(
        45deg,
        var(--shadow227) 25%,
        transparent 25%,
        transparent 50%,
        var(--shadow227) 50%,
        var(--shadow227) 75%,
        transparent 75%,
        transparent
      );
      background-image: linear-gradient(
        45deg,
        var(--shadow227) 25%,
        transparent 25%,
        transparent 50%,
        var(--shadow227) 50%,
        var(--shadow227) 75%,
        transparent 75%,
        transparent
      );
      background-size: 40px 40px;
    }
  `;

  return (
    <StyledDiv className="progress progress-striped active">
      <div style={{ width: props.completed + '%' }} className="progress-bar"></div>
    </StyledDiv>
  );
};

export default Progress;
