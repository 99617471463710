import React from 'react';
import themeFile from '@assets/Themes/default.json';
import ShimmerEffect from './ShimmerEffect';
import { styled } from '@mui/system';

function ErrandsSkeletonLoader() {

// This creates the skeleton for the Errands box which holds a single errand skeleton
    const Container = styled('div')({
        backgroundColor: themeFile.palette.peach['800'],
        borderRadius: '8px',
        height: '72px',
        width: '100%',
    })

// This creates the skeleton for the title of the errand
    const TitleSkeleton = styled('div')({
        backgroundColor: 'var(--gray000)',
        borderRadius: '8px',
        height: '20px',
        width: '20%',
        marginLeft: '61px',
        marginTop: '10px',
        overflow: 'hidden'
    })

// This creates the skeleton for the text preview in the errand
    const TextSkeleton = styled('div')({
        backgroundColor: 'var(--gray000)',
        borderRadius: '8px',
        height: '15px',
        width: '70%',
        marginTop: '3px',
        marginLeft: '61px',
        overflow: 'hidden'
    })

// This creates the skeleton for the circle indicating "selected errand"
    const Circle = styled('div')({
        position: 'absolute',
        backgroundColor: 'var(--gray000)',
        borderRadius: '50%',
        height: '20px',
        width: '20px',
        top: '92px',
        overflow: 'hidden'

    })

    return (
        <Container>
            <Circle><ShimmerEffect color='var(--shadow602)' width='70%'/></Circle>
            <TitleSkeleton><ShimmerEffect color='var(--shadow602)'width='70%'/></TitleSkeleton>
            <TextSkeleton><ShimmerEffect color='var(--shadow602)'width='35%'/></TextSkeleton>
            <TextSkeleton><ShimmerEffect color='var(--shadow602)'width='35%'/></TextSkeleton>
        </Container>
    )
}

export default ErrandsSkeletonLoader