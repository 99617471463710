import React from 'react';
import styles from '@styles/MessageContent/ProfileBubbleMessageContent/Header.module.css';
import axiosCall from '@services/axios';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';

interface Document {
  mimeType: string;
  data: string;
}

// TODO: make these mongoose object ids
interface Props {
  chatId: string;
  documentId: number;
}

const FILENAME = 'Header.tsx';
const COMPONENT = 'Header';

const log1 = { filename: FILENAME, component: COMPONENT };

/**
 * Gets the document. Sets state and loading state.
 */
async function getDocument(
  chatId: string,
  documentId: number,
  setState: React.Dispatch<React.SetStateAction<Document>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<undefined> {
  // set loading
  setLoading(true);

  // log
  const log2 = { function: 'getDocument' };
  console.info({
    ...log1,
    ...log2,
    message: 'getting document',
  });

  try {
    // get data
    const request = {
      url: `chat/${chatId}/document/${documentId}`,
    };
    const data = await axiosCall(request);
    console.debug({
      ...log1,
      ...log2,
      message: 'data declared',
      data,
    });

    // check res
    if (!data) {
      console.warn({
        ...log1,
        ...log2,
        message: 'data not found',
      });
      return;
    }

    // set state
    setState(data);
    setLoading(false);
  } catch (err) {
    console.error({ ...log1, ...log2, err });
    setLoading(false);
  }
}

/**
 * The header that contains the profile picture. Uses the same style as the
 * private mode header but is missing a few things. At some point they should
 * be combined but they can not right now as this one does not have the
 * button.
 */
export default function Header(props: Props): React.ReactElement {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [document, setDocument] = React.useState<Document | undefined>();

  /**
   * Fetches the image.
   */
  React.useEffect(() => {
    getDocument(props.chatId, props.documentId, setDocument, setLoading);
  }, [props.chatId, props.documentId]);

  /**
   * Renders the image. If its loading don't render anything. If fore some
   * reason the image could not be fetched load a default icon.
   */
  function renderImage(): React.ReactElement {
    if (loading) {
      return;
    } else if (!document || !document.mimeType || !document.data) {
      return <NoAccountsIcon className={styles.profilePicturePlaceholder} />;
    } else {
      return (
        <img className={styles.profilePicture} src={`data:${document.mimeType};base64,${document.data}`} alt="user" />
      );
    }
  }

  return (
    <div data-component={COMPONENT} className={styles.header}>
      {renderImage()}
    </div>
  );
}
