import React, { Dispatch, SetStateAction, useCallback, useMemo, useRef } from 'react';

import { IErrand, IMessage } from '@interfaces/Conversation';
import { MorphType } from '@common/MorphType';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';

import Styles from '@styles/MorphMessageOptions.module.css';
import {
  MorphMessageOptionConvertSkeleton,
  MorphMessageOptionCopySkeleton,
  MorphMessageOptionDeleteSkeleton,
  MorphMessageOptionEditSkeleton,
  MorphMessageOptionJoinSkeleton,
  MorphMessageOptionPrivateModeSkeleton,
  MorphMessageOptionRemoveUserSkeleton,
  MorphMessageOptionReplySkeleton,
  MorphMessageOptionSentimentSkeleton,
  MorphMessageOptionSetInternalSkeleton
} from '@components/Skeletons/MorphMessageOptionSkeleton';
import useInitialMount from '@common/hooks/useInitialMount';
import { isActionOTPRelated, isActionPasswordRelated } from '@common/msgUtils';
import { isMobile } from '@common/deviceTypeHelper';
import ErrorBoundary from '@components/ErrorBoundary';
import { ResetFooterUserAction } from '@common/common';

type TMorphMessageOptionsProps = {
  errand: IErrand;
  setEditMessageId: Dispatch<SetStateAction<string>>;
  setValue: Dispatch<SetStateAction<string>>;
};

const FILE_PATH: string = './src/Components/MorphMessageOptions/MorphMessageOptions.tsx';

const MorphMessageOptions = ({
  errand, setEditMessageId, setValue,
}: TMorphMessageOptionsProps) => {
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const isWidget = rootContext.isWidget;
  const isDragging = useRef(-1);
  const isDesktop = !(isMobile() || isWidget === true);

  const message: IMessage = useMemo(() => {
    return (errand.messages || [])[errandContext.messageOptionsIndex || 0]
  }, [errandContext.messageOptionsIndex]);

  const handleScroll = useCallback((e) => {
    e.currentTarget.scrollLeft += (e.deltaY);
  }, []);

  const handleStart = useCallback((e) => {
    let x = e?.changedTouches?.[0]?.clientX || e?.clientX
    isDragging.current = x;
  }, []);

  const handleEnd = useCallback((e) => {
    isDragging.current = -1;
  }, []);

  const handleMove = useCallback((e) => {
    if (isDragging.current !== -1) {
      let x = e?.changedTouches?.[0]?.clientX || e?.clientX
      e.currentTarget.scrollLeft -= (x - isDragging.current) / 8;
    }
  }, []);

  const doClearAction = useCallback(() => {
    rootContext.setErrands((prev) => {
      const chatObj = prev.find((e) => e._id === errand?._id);

      if (chatObj) {
        ResetFooterUserAction(chatObj);
      }

      return [...prev];
    });
  }, [])

  // close MorphMessageOptions
  const handleClose = useCallback((morphType: MorphType = MorphType.None, clearAction: boolean = true) => {
    errandContext.setIsMorphedFooterCloseButtonOnLeft(false);
    errandContext.setMorphType(morphType);
    errandContext.setMessageOptionsIndex(-1);
    if(clearAction) {
      doClearAction();
    }
  }, []);

  useInitialMount(() => {
    // on mobile (cropped input box issue fix)
    const actionData = errand?.action?.action ?? errand?.action;
    // if action is password related, clear the current action to prevent bad display to the user.
    // either password OR otp related action
    if(isDesktop === false && (isActionPasswordRelated(actionData) === true || isActionOTPRelated(actionData) === true)) {
      doClearAction();
    }
  })

  const MorphMessageOptionImportProps = {
    errand, handleClose, message, setEditMessageId, setValue
  };

  return (
    <>
      <section className={Styles.wrapper}>
        <div
          className={Styles.container}
          onWheel={(e) => handleScroll(e)}
          onMouseDown={(e) => handleStart(e)}
          onMouseUp={(e) => handleEnd(e)}
          onMouseMove={(e) => handleMove(e)}
        >
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionConvertSkeleton`}>
            <MorphMessageOptionConvertSkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionJoinSkeleton`}>
            <MorphMessageOptionJoinSkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionSentimentSkeleton`}>
            <MorphMessageOptionSentimentSkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionCopySkeleton`}>
            <MorphMessageOptionCopySkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionDeleteSkeleton`}>
            <MorphMessageOptionDeleteSkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionEditSkeleton`}>
            <MorphMessageOptionEditSkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionSetInternalSkeleton`}>
            <MorphMessageOptionSetInternalSkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionRemoveUserSkeleton`}>
            <MorphMessageOptionRemoveUserSkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionReplySkeleton`}>
            <MorphMessageOptionReplySkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
          <ErrorBoundary debug={FILE_PATH + `: MorphMessageOptionPrivateModeSkeleton`}>
            <MorphMessageOptionPrivateModeSkeleton {...MorphMessageOptionImportProps} />
          </ErrorBoundary>
        </div>
      </section>
      <div aria-hidden="true" className={Styles.bottomBorder + ' ' + (errandContext.isMorphedFooterCloseButtonOnLeft ? Styles.isMorphedFooterCloseButtonOnLeft : '')}>
        <div className={Styles.node} />
      </div>
    </>
  );
};

export default MorphMessageOptions;
