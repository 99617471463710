/**
 * @file    This file contains the definition of the `TranslationDisabledError` exception class.
 * @author  Kristoffer A. Wright <kristoffer.wright@swmc.com>
 */

/*
========================================================================================================================
Imports
========================================================================================================================
*/

import MorganError from "./MorganError";

/*
========================================================================================================================
Class definition
========================================================================================================================
*/

/**
 * This exception should be thrown if an attempt to translate a message fails due to the system setting flag
 * `translationEnabled` being set to `false`.
 */
class TranslationDisabledError extends MorganError {}

export default TranslationDisabledError;