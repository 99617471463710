import { Button, Typography, MorganTheme, useTheme } from '@mui/material';
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import axiosCall from "../Services/axios";
import {
  ColorButton,
  ColorSelection,
  OptionButton,
  QuantityButton,
  SlotMachineContainer,
  SlotMachineBody,
  PlayAndExit,
  MessageDisplay,
  MessageDisplayHolder
} from "../Styles/SlotMachineStyle";
import { getCountryCode, setUserPlayedToday } from "@storage/userStorage";
import { sendWorkflow } from "@common/WorkflowsUtils";
import SlotMachineNoPrizeDisplay from '@components/SlotMachineNoPrizeDisplay';
import { startConfettiInner, stopConfettiInner } from '@common/confetti';
import { IErrand } from '@interfaces/Conversation';
import useControllerCallback from '@common/hooks/useControllerCallback';
import { AccessType } from '@common/AccessType';
import Styles from "../Styles/SlotMachineStyles.module.css"
import { Prize } from './SlotMachinePrizes';
import { getAfterLoseMessage, getAfterWinMessage, getBeforePlayMessage } from '@common/SlotMachineUtils';
const slotsPlaceholder = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/SlotMachineScreenSpotlight.gif';

const selectedButtonColor = "#f5bb84";
const defaultOrangeColor = "#ffdebf";

const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';
const CoffeeGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Coffee.gif';
const CapGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Cap.gif';
const ShirtOneGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/ShirtOne.gif';
const ShirtTwoGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/ShirtTwo.gif';
const GiftBoxGif = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/GiftBox.gif';
const LeverGifSrc = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Lever.gif';
const LeverSVG = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Lever.svg';
const soundEffect = process.env.REACT_APP_MORGAN_CDN + '/sound/slotMachineWin.wav';
const DiwaliTicketsGif = process.env.REACT_APP_MORGAN_CDN + "/Images/SlotMachine/DiwaliTickets.gif";
const MindsetTicketsGif = process.env.REACT_APP_MORGAN_CDN + "/Images/SlotMachine/MindsetTickets.gif";
const RussellPetersGif = process.env.REACT_APP_MORGAN_CDN + "/Images/SlotMachine/RussellPeters.gif";

// Map for easy selectino of gif source
const PrizeGifs = {
  "No Prize": null,
  Coffee: CoffeeGif,
  "T-Shirt": ShirtOneGif,
  "Polo Shirt": ShirtTwoGif,
  Cap: CapGif,
  "Gift Box": GiftBoxGif,
  "Diwali Party ticket": DiwaliTicketsGif,
  "Mindset Summit ticket": MindsetTicketsGif,
  "Russell Peters T-Shirt": RussellPetersGif,
};

interface SlotMachineProps {
  errand?: IErrand;
  chatId?: string;
  userActionId?: string;
  userId?: string;
  exitSlotMachine?: (chatId: string, userAction: string, resolved: boolean) => void;
}

// Required props: exitSlotMachine function so that SlotMachine can exit itself
const SlotMachine = ({errand,
  chatId,
  userActionId,
  userId,
  exitSlotMachine,
}: SlotMachineProps) => {
  const { t } = useTranslation();
  const theme: MorganTheme = useTheme();
  // User chosen item quantity
  const [itemQuantity, setItemQuantity] = useState(1);
  // Control for size option choice
  const [selectedSize, setSelectedSize] = useState("S");
  // Control for color option choice
  const [selectedColor, setSelectedColor] = useState("Blue");
  // Gif of prize that plays upon win
  const [prizeGif, setPrizeGif] = useState("");
  // Boolean for triggering gif displaying lever pull
  const [playLeverGif, setPlayLeverGif] = useState(false);
  // Prize information, set when game is won
  const [prize, setPrize] = useState<Prize>();
  // Variable governing game state, 0 = to start, 1 = playing, 2 = ended
  const [playState, setPlayState] = useState(0);
  // Message to be displayed for prizes that do not allow size or color selections
  const [optionBlockingMessage, setOptionBlockingMessage] = useState(`${t("optionsAfterPrize")}`);
  // Boolean governing whether or not to block options
  const [blockOptions, setBlockOptions] = useState(false);
  // Text on play button
  const [playButtonText, setPlayButtonText] = useState("playButton");
  // Array of prizes used for picking prize
  const [prizeArray, setPrizeArray] = useState([]);
  // Integer tracking which quantity/prize/color slot machine is on
  const [choiceStep, setChoiceStep] = useState(0);
  // Boolean to trigger useEffect to regenerate prize
  const [retry, setRetry] = useState(true);
  const [message, setMessage] = useState(getBeforePlayMessage())

  // On load, load the prize rates from db
  const loadAndSetPrizes = useCallback( async (abortController: AbortController) => {
    //Retrieve current prize chances from database
    async function setPrizes() {
      const loadPrizeRateData = async () => {
        const lenderName = theme.system.name;
        try {
          const result = await axiosCall({
            url: `slotmachine/prizes`,
            method: 'GET',
            data: null
          },
          { 
            signal: abortController.signal
          });
          if (result) {
            console.log("System Settings Fetched. Results Below...", result);
            let prizeRates = result;
            // Grab and convert default prize data and incoporate it into the db prize rate data
            prizeRates.forEach(function (item) {
              // item.rate = prizeRates[prizeRates.map(r => r.name).indexOf(item.name)].chance || 0;
              item.gif = PrizeGifs[item.name];
            });
            setPrizeArray(prizeRates);
          }
        } catch (error) {
          console.log("Error retrieving Prize Data", error);
        }
      };
      await loadPrizeRateData();
    }
    setPrizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retry]);

  useControllerCallback(loadAndSetPrizes)

  // Function for displaying message text within slot machine panel inside JSX
  const updateMessage = () => {
    if (choiceStep === 4) {
      setMessage(t('slotMachineUnavailable'));
    }
    if (playState === 0 || playState === 1) {
      setMessage(getBeforePlayMessage())
    } else if (prize.name === 'No Prize') {
      setMessage(getAfterLoseMessage())
    } else {
      if (prize?.name !== 'No Prize' && !prize.sizeSelection && !prize.colorSelection){
        setPlayButtonText(t('claimPrize'))
      }
      setMessage(getAfterWinMessage())
    }
  }

  useEffect(() => {
    // Don't change pre-play message when user clicks play
    if (playState === 1) return;
    updateMessage();
  }, [choiceStep, playState])

  const restart = () => {
    // User chosen item quantity
    setItemQuantity(1);
    // Control for size option choice
    setSelectedSize("S");
    // Control for color option choice
    setSelectedColor("Blue");
    // Gif of prize that plays upon win
    setPrizeGif("");
    // Boolean for triggering gif displaying lever pull
    setPlayLeverGif(false);
    // Variable governing game state, 0 = to start, 1 = playing, 2 = ended
    setPlayState(0);
    // Message to be displayed for prizes that do not allow size or color selections
    setOptionBlockingMessage(`${t("optionsAfterPrize")}`);
    // Boolean governing whether or not to block options
    setBlockOptions(false);
    // Text on play button
    setPlayButtonText("playButton");
    // Array of prizes used for picking prize
    setPrizeArray([]);
    // Integer tracking which quantity/prize/color slot machine is on
    setChoiceStep(0);
    // Boolean to trigger useEffect to regenerate prize
    setRetry((prev) => !prev);
  }

  // Function for choosing prize for user
  const SpinForPrize = async () => {
    try{
      const result = await axiosCall({
        url: `slotmachine/prizes/spin`,
        method: 'POST',
        data: {
          countryCode: getCountryCode()
        }
      });
      console.log("result", result)
      return result.prizeResult;
    } catch (err) {
      console.error(`Error spinning: ${e}`);
      return {
        name: "No Prize"
      }
    }
  };

  // Function which starts the game
  const triggerGame = async () => {
    const prizeGifDuration = 5500;
    const unblockOptionsDuration = 1000;
    try {
      const tempPrize = await SpinForPrize();

      // Immediately set slot machine user action to resolved to prevent users from quickly
      // exiting to play again
      setSlotMachineToResolved();
      updateSlotMachineMessage(tempPrize?.name !== 'No Prize', tempPrize?.name);
  
      tempPrize.gif = PrizeGifs[tempPrize.name];
      setPrize(tempPrize);
      setPlayLeverGif(true);
      // Default Size and color
      setSelectedSize('M');
      setSelectedColor('Blue');
      // Way to get the gif to replay if SlotMachine is shown more than once without a page reload
      // Without appending the unique string, the gif renders on its final frame after the first render
      setPrizeGif(tempPrize["gif"] + "?a=" + Date.now());
      setPlayState(1);
      // Wait for the lever and play gif to play
      setTimeout(() => {
        if (tempPrize["name"] === "No Prize") {
          setOptionBlockingMessage(t("unluckyText"));
          setPlayButtonText("exitButton");
          setPlayState(2);
        } else {
          new Audio(soundEffect).play();
          startConfettiInner();
          setTimeout(() => {
            stopConfettiInner();
          }, 2000)
          // setBlockOptions(true);
          // setOptionBlockingMessage(`${t("prizeMessage")} ${tempPrize["name"]}!`);
          // Wait for the unblock options to go away
          setTimeout(() => {
            setBlockOptions(false);
            setPlayButtonText("tContinue");
            setPlayState(2);
            setChoiceStep(1); // shows the after win message
            setMessage(getAfterWinMessage())
          }, unblockOptionsDuration);
        }
      }, prizeGifDuration);
    } catch (err) {
      console.error(`Error getting winner: ${err}`);
      return false;
    }
  };

  const handlePlusButtonClick = () => {
    if (itemQuantity === prize['maxQuantity']) {
      return;
    }
    let newQuantity = itemQuantity + 1
    setItemQuantity(newQuantity);
  };

  const handleMinusButtonClick = () => {
    if (itemQuantity === 1) {
      return;
    }
    let newQuantity = itemQuantity - 1
    setItemQuantity(newQuantity);
  };

  const handleMinButtonClick = () => {
    setItemQuantity(1);
  };

  const handleMaxButtonClick = () => {
    setItemQuantity(prize["maxQuantity"]);
  };

  const handleSizeButtonClick = (event) => {
    const sizeButtonValue = event.currentTarget.value;
    setSelectedSize(sizeButtonValue);
  };

  const handleColorButtonClick = (event) => {
    const colorButtonValue = event.currentTarget.value;
    setSelectedColor(colorButtonValue);
  };

  const handleAcceptTickets = async () => {
    await createOrderAndBeginWorkflow();
  }

  const handleGetFreeTShirt = async () => {
    const req = {
      url: "slotmachine/prizes?type=tShirt"
    }
    const tShirtPrizeInArr = await axiosCall(req)
    const tShirtPrize = tShirtPrizeInArr[0]
    updateSlotMachineMessage(true, tShirtPrize.name)
    setPrize(tShirtPrize)
    setChoiceStep(2)
  }

  // Switch component for conditionally rendering each choice panel
  const renderChoiceComponent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <MessageDisplayHolder>
            <MessageDisplay>
              <Typography>
                {message}
              </Typography>
            </MessageDisplay>
          </MessageDisplayHolder>
        );
      case 1:
        return (
        <MessageDisplayHolder>
          <MessageDisplay>
            <Typography>
              {message}
            </Typography>
          </MessageDisplay>
        </MessageDisplayHolder>
        )
        // // Quantity Selection
        // return (
        //   <div className='quantityButtonGrid'>
        //     <OptionButton onClick={handleMinButtonClick} limitQuantity={itemQuantity === 1}>
        //       <span className='minButton' data-yeah='ok'>
        //         <Typography>Min</Typography>
        //       </span>
        //     </OptionButton>
        //     <div className='countSelector'>
        //       <span className='countSelectorFlex'>
        //         <QuantityButton onClick={handleMinusButtonClick}>-</QuantityButton>
        //         <span>
        //           <Typography>{itemQuantity}</Typography>
        //         </span>
        //         <QuantityButton onClick={handlePlusButtonClick}>+</QuantityButton>
        //       </span>
        //     </div>
        //     <OptionButton onClick={handleMaxButtonClick} limitQuantity={itemQuantity === prize['maxQuantity']}>
        //       <span className='maxButton'>
        //         <Typography>Max</Typography>
        //       </span>
        //     </OptionButton>
        //   </div>
        // );
      case 2:
        // Size Selection
        return (
          <div className='sizeSelection'>
            <OptionButton
              value="S"
              onClick={handleSizeButtonClick}
              style={{ backgroundColor: "S" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>S</Typography>
              </span>
            </OptionButton>
            <OptionButton
              value="M"
              onClick={handleSizeButtonClick}
              style={{ backgroundColor: "M" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>M</Typography>
              </span>
            </OptionButton>
            <OptionButton
              value="L"
              onClick={handleSizeButtonClick}
              style={{ backgroundColor: "L" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>L</Typography>
              </span>
            </OptionButton>
            <OptionButton
              value="XL"
              onClick={handleSizeButtonClick}
              style={{ backgroundColor: "XL" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>XL</Typography>
              </span>
            </OptionButton>
          </div>
        );
      case 3:
        // Color selection
        return (
          <ColorSelection>

            <ColorButton onClick={handleColorButtonClick} value="Blue" style={{ backgroundColor: "#263472" }}>
              {selectedColor === "Blue" && (
                <img
                  className='colorButton'
                  src={AngelAi}
                  alt='Morgan Logo'
                ></img>
              )}
              <div className='innerColorButton'></div>
            </ColorButton>
            <ColorButton onClick={handleColorButtonClick} value="Black" style={{ backgroundColor: "#000000" }}>
              {selectedColor === "Black" && (
                <img
                  className='colorButton'
                  src={AngelAi}
                  alt='Morgan Logo'
                ></img>
              )}
              <div className='innerColorButton'></div>
            </ColorButton>
            <ColorButton onClick={handleColorButtonClick} value="Red" style={{ backgroundColor: "#F84A25" }}>
              {selectedColor === "Red" && (
                <img
                  className='colorButton'
                  src={AngelAi}
                  alt='Morgan Logo'
                ></img>
              )}
              <div className='innerColorButton'></div>
            </ColorButton>
            <ColorButton onClick={handleColorButtonClick} value="White" style={{ backgroundColor: "#FFFFFF" }}>
              {selectedColor === "White" && (
                <img
                  className='colorButton'
                  src={AngelAi}
                  alt='Morgan Logo'
                ></img>
              )}
              <div className='innerColorButton'></div>
            </ColorButton>
          </ColorSelection>
        );
      case 5:
        return (
          <div className={Styles.diwaliTicketsWinPrompt}>
            <p>{t('ticketOrTshirt')}</p>
            <OptionButton
              value="Accept Tickets"
              onClick={handleAcceptTickets}
              style={{ backgroundColor: "lightgreen" }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>{t('claimTicket')}</Typography>
              </span>
            </OptionButton>
            <OptionButton
              value="Free T-Shirt"
              onClick={handleGetFreeTShirt}
              style={{ backgroundColor: "S" === selectedSize ? selectedButtonColor : defaultOrangeColor }}
            >
              <span style={{ transform: "skew(10deg)" }}>
                <Typography>Free T-Shirt</Typography>
              </span>
            </OptionButton>
          </div>
        )
      default:
        return (
          <MessageDisplayHolder>
            <MessageDisplay>
              <Typography>
                {message}
              </Typography>
            </MessageDisplay>
          </MessageDisplayHolder>
        );
    }
  }

  const handleNextButton = () => {
    switch (choiceStep) {
      case 0:
        return handlePlayButtonClick();
      case 1:
        if (prize.sizeSelection) {
          setChoiceStep(2);
        } else if (prize.type.toLowerCase().includes("ticket")){
          setChoiceStep(5)
        } else if (!prize.sizeSelection && prize.colorSelection) {
          setChoiceStep(3);
          setPlayButtonText('claimPrize')
        } else if (!prize.sizeSelection && !prize.colorSelection) {
          handlePlayButtonClick();
        }
        return;
      case 2:
        setPlayButtonText('claimPrize')
        if (prize.colorSelection) {
          setChoiceStep(3);
        } else {
          handlePlayButtonClick();
        }
        return;
      case 4:
        return restart();
      default:
        return handlePlayButtonClick();
    }
  }
  const createProductOrder = async () => {
    try {
      // Find the product that was won, with the chosen options
      const type = prize.type;
      const unitSize = prize.sizeSelection;
      const unitColor = prize.colorSelection;
      let url = `product/db/find?type=${type}`;
      url += unitSize ? `&unitSize=${unitSize}` : "";
      url += unitColor ? `&unitColor=${unitColor}` : "";

      const productId = await axiosCall({url});

      if (!productId.length) {
        return console.log('Could not create an order. Could not find a valid product');
      }
      // Create a new order with the found product, to be updated with the workflow in chat
      let order = await axiosCall({
        url: 'order',
        method: "POST",
        data: {
          customerId: userId,
          productId: productId[0]._id,
          chat: chatId,
          size: prize.sizeSelection ? selectedSize : '',
          color: prize.colorSelection ? selectedColor : '',
          quantity: itemQuantity,
          shippingAddressLine1: '',
          shippingAddressLine2: '',
          shippingCity: '',
          shippingState: '',
          shippingZip: '',
          status: 'open',
          trackingNumber: '',
          carrier: '',
          active: false
        }
      });
      return order;
    } catch (e) {
      console.error(`Error creating order: ${e}`);
      return false;
    }
  }

  async function createOrderAndBeginWorkflow() {
    if (prize.name === 'No Prize') {
      return exitSlotMachine(chatId, userActionId, true);
    } else {
      if (chatId && chatId !== '') {
        let recipients = [userId];
        let createdOrder = await createProductOrder();
        if (createdOrder) {
          const workflow = await axiosCall(
            {url: `workflow/${prize.workflow}`}
          )
          const workflowName = workflow.name
          exitSlotMachine(chatId, userActionId, true);
          await sendWorkflow('', workflowName, chatId, recipients, AccessType.public, userId, false, false);
        } else {
          setChoiceStep(4);
          setPlayButtonText('tRestart');
        }
      } else {
        console.log(`No conversation selected. Cannot send workflow. chat: ${chatId}`);
      }
    }
  }

  const setSlotMachineToResolved = () => {
    // sets the corresponding slot machine useraction status to resolved
    const payload = {
      url: `useraction/${userActionId}`,
      method: 'put',
      data: {
        status: 'resolved'
      },
    };
    axiosCall(payload);
  };

  const updateSlotMachineMessage = (win: boolean, prize: string) => {
    // Because dynamic message needs, we hit the DB to change the message text.
    const payload = {
      url: `slotmachine/update?chat=${chatId}&userAction=${userActionId}`,
      method: 'put',
      data: {
        win: win,
        prize: prize
      }
    };
    axiosCall(payload);
  }

  const handlePlayButtonClick = async () => {
    if (prizeArray.length === 0) {
      return;
    }
    const tempPlayState = playState;
    // Game has not started
    if (tempPlayState === 0) {
      setUserPlayedToday();
      await triggerGame();
      // setPlayState(1);
    } // Game is in Progress
    else if (tempPlayState === 1) {
      return;
    } // Game is finished
    else if (tempPlayState === 2) {
      await createOrderAndBeginWorkflow();
    }
  };

  return (
    <SlotMachineContainer playState={playState}>
      <div className="slotMachineShadow"></div>
      <div className="slotMachine">
        <div className="slotMachineWindow">
          {/* Lever svg and gif, when game is active, svg is swapped out for animated gif */}
          {playLeverGif ?
            (<img className="activeLever" src={LeverGifSrc} alt='Lever Gif'></img>)
            :
            (<img
              className="staticLever"
              onClick={handlePlayButtonClick}
              src={LeverSVG}
              alt="Lever"
            ></img>)}
          {/* Component responsible for the animation */}
          <div className='slotMachineAnimationHolder'>
            {playState > 0 ?
              prize.name === 'No Prize' ?
                <SlotMachineNoPrizeDisplay />
                :
                <img src={prizeGif} alt={slotsPlaceholder}/>
              :
              <div className='slotMachineSpotlight' />
            }
          </div>
          <div className="slotMachineScreenBackground">
          </div>
          <SlotMachineBody>
            <div className="bodyDropShadowBorder">
              <div className="bodyBackground">
                {/* Option blocking box with text */}
                {blockOptions && (
                  <div className='blockButtonBox'>
                    <Typography className='blockMessage'>{t(`${optionBlockingMessage}`)}</Typography>
                  </div>
                )}
                {renderChoiceComponent(choiceStep)}
                {/* Play and Exit Buttons */}
                {choiceStep !== 5 &&
                  <PlayAndExit>
                    {
                      choiceStep > 1 ?
                        <div className='exitBackground' >
                          <Button
                            className='exitButton'
                            onClick={() => {
                              setChoiceStep(choiceStep - 1); 
                              setPlayButtonText('tContinue')
                            }}
                          >
                            <Typography style={{ color: "#ffffff", fontWeight: "bold" }}>{t("goBack")}</Typography>
                          </Button>
                        </div>
                        :
                        playState === 0 ?
                          <div className='exitBackground' >
                            <Button
                              className='exitButton'
                              onClick={() => exitSlotMachine(chatId, userActionId, false)}
                            >
                              <Typography style={{ color: "#ffffff", fontWeight: "bold" }}>{t("exitButton")}</Typography>
                            </Button>
                          </div>
                          :
                          null
                    }
                    <div className='playBackground'>
                      <Button
                        onClick={handleNextButton}
                        className='playButton'
                      >
                        <Typography style={{ color: "#000000", fontWeight: "bold" }}>{t(playButtonText)}</Typography>
                      </Button>
                    </div>
                  </PlayAndExit>
                }
              </div>
            </div>
          </SlotMachineBody>
        </div>
      </div>
    </SlotMachineContainer>
  );
};

export default React.memo(SlotMachine);
