import { Theme } from '@mui/system';

type StatusColors = {
  main?: string,
  hover?: string,
  text?: string
}

export const getStatusColors = (status: string, theme: Theme): StatusColors => {
  // default value
  const result: StatusColors = {
    main: theme.palette.gray['300'],
    hover: theme.palette.gray['300'],
    text: theme.palette.gray['950']
  }
  if(status) //process only if we have a status
  switch (status) {
    case 'online':
      // return {
        result.main = theme.palette.green['400'];
        result.hover = theme.palette.green['500'];
        result.text = theme.palette.gray['950'];
        break;
    case 'busy':
      // return {
        result.main =  theme.palette.red['400'];
        result.hover = theme.palette.red['500'];
        result.text = theme.palette.gray['000'];
        break;
    case 'away':
      // return {
        result.main = theme.palette.yellow['400'];
        result.hover = theme.palette.yellow['500'];
        result.text = theme.palette.gray['950'];
        break;
    case 'offline':
    default:
        //Do nothing the default is set
        break;
  }
  return result;
}

export type { StatusColors }
