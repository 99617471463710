import { styled } from '@mui/system';
import Input from '@mui/material/Input';

export const Styles: React.FC<any> = styled(Input)<any>(
  ({ theme }) => `
  padding: 0;
  border: none;
  visibility: hidden;
  width: 100%;
  height: 100%;
  max-width: 0;
  max-height: 0;
  margin: 0;
  backgroundColor: ${theme.palette.red['400']};
  color: ${theme.palette.gray['920']};
  transition: all .5s linear;
  background-color: red;
  &:not(:placeholderShown) {
    max-height: 25px;
    max-width: fit-content;
    border-radius: 10px;
    margin-right: 7px;
    transition: all .5s linear;
  }
  `
);
