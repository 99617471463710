import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses
} from '@mui/material';
import React, { useState, useDeferredValue, useCallback } from 'react';

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import EditEmailTemplateDialog from './EditEmailTemplateDialog';
import StandardTablePagination from './StandardTablePagination';
import TableHeaderBar from './TableHeaderBar';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useControllerCallback from '@common/hooks/useControllerCallback'
import useInitialMount from '@common/hooks/useInitialMount';

const StyledTableCell = styled(TableCell)(({ active }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

const EmailTemplates = (props) => {
  const { t } = useTranslation();
  const [editEmailTemplateState, setEditEmailTemplateState] = useState({
    edit: false,
    open: false,
    data: null,
  });
  const [workflowData, setWorkflowData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [errorMessage, setErrorMessage] = useState('');
  const [getActive, setGetActive] = useState(true);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [EmailTemplateCount, setEmailTemplateCount] = useState(0);
  const deferredSearch = useDeferredValue(search);

  const loadEmailTemplateData = useCallback(async (controller) => {
    setLoading(true);
    // Load one more row than required to test if more data exists
    const offset = page < 0 ? 0 : page * rowsPerPage;
    const limit = rowsPerPage > 0 ? `&limit=${rowsPerPage + 1}` : '';
    const active = getActive ? '&active=1' : '';
    let data;
    if (search.length > 0) {
      data = { search: search };
    } else {
      data = "";
    }

    let request = {
      url: `emailTemplate/search?offset=${offset}${limit}${active}`,
      method: 'POST',
      data: data,
    };
    let config = controller
      ? {
          signal: controller.signal,
        }
      : {};

    try {
      const result = await axiosCall(request, config);

      if (result.length > 0) {
        // See if we were able to fetch the one additional row, therefore indicating more data is available
        setIsMoreDataAvailable(rowsPerPage > 0 ? result.length > rowsPerPage : false);
        setDataToDisplay(rowsPerPage > 0 ? result.slice(0, rowsPerPage) : result);
        setLoading(false);
        try {
          const a = active ? "active=true" : ""
          const result = await axiosCall({ url: `emailTemplate/count?${a}` ,method: 'GET',});
          if (result) {
            setEmailTemplateCount(result)
          }
        } catch (error) {
          console.log(error);
        }
        setNoResults(false);
      } else {
        setNoResults(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  },[page, rowsPerPage, getActive, deferredSearch]);

  const handleDownloadEmailTemplateReport = async () => {
    try {
      const result = await axiosCall({ url: `emailTemplate/?order=asc&orderBy=name` });
      if (result) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*************************************
   * Add/Edit EmailTemplate Dialog Functions
   *************************************/
  const handleAddEmailTemplateOpen = () => {
    setEditEmailTemplateState({ edit: false, open: true, data: null });
  };

  const handleEditEmailTemplateOpen = (EmailTemplate) => {
    const data = {
      name: EmailTemplate.name,
      description: EmailTemplate.description,
      subject: EmailTemplate.subject,
      workflow: EmailTemplate.workflow,
      text: EmailTemplate.text,
      id: EmailTemplate?._id,
      visible: EmailTemplate.visible,
      defaultRecipient: EmailTemplate.defaultRecipient
    };
    setEditEmailTemplateState({ edit: true, open: true, data: data });
    setErrorMessage('');
  };

  const handleEditEmailTemplateClose = () => {
    setEditEmailTemplateState({ open: false, data: null });
    setErrorMessage('');
  };

  const handleEditEmailTemplateSubmit = async (data) => {
    if (await editEmailTemplate(data)) {
      setEditEmailTemplateState({ open: false, data: null });
      setErrorMessage('');
    }
  };

  const handleAddEmailTemplateSubmit = async (data) => {
    if (await addEmailTemplate(data)) {
      setEditEmailTemplateState({ open: false, data: null });
      setErrorMessage('');
    }
  };

  const editEmailTemplate = async (data) => {
    const { id, ...sendData } = data;
    try {
      let result = await axiosCall({
        url: `EmailTemplate/${id}`,
        method: 'PUT',
        data: sendData,
      });

      if (result) {
        await loadEmailTemplateData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  const addEmailTemplate = async (data) => {
    try {
      let result = await axiosCall({
        url: `emailTemplate`,
        method: 'POST',
        data: data,
      });

      if (result) {
        await loadEmailTemplateData();
        return true;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  const handleDeactivate = async (id) => {
    try {
      const result = await axiosCall({
        url: `EmailTemplate/${id}`,
        method: 'PUT',
        data: {
          active : false
        }
      });
      if (result) {
        await loadEmailTemplateData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReactivate = async (id) => {
    try {
      const result = await axiosCall({
        url: `EmailTemplate/${id}`,
        method: 'PUT',
        data: {
          active : true
        }
      });
      if (result) {
        await loadEmailTemplateData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowInactive = () => {
    //Invert this value
    setGetActive(!getActive);
    setPage(0);
  };

  /*************************************
   * Pagination Functions
   *************************************/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleSearchChange = (newSearchValue) => {
    // We want to reset to the first page when searching so we don't miss any documents 
    // (for example, we don't want to begin our search on the second page 
    // as we would miss those on the first)
    setPage(0);
    // Now we can update the search value
    setSearch(newSearchValue);
  };

  // Reload data when getActive, page or rowsPerPage changes
  useControllerCallback(loadEmailTemplateData);
  // search 500ms after user gets done typing
  

  const fetchWorkflowData = async () => {
    let request = {
      url: `workflow?fields=_id,name`,
      method: 'GET'
    };
    try {
      const result = await axiosCall(request);
      setWorkflowData(result);
    } catch (error) {
      console.log(error);
    }
  };

  useInitialMount(fetchWorkflowData);

  return (

    <Box display="flex" flexDirection="column" height="calc(100vh - 175px)" padding={1}>
      <TableHeaderBar
        onAdd={handleAddEmailTemplateOpen}
        setSearch={handleSearchChange}
        setPage={setPage}
        onRefresh={loadEmailTemplateData}
        onExport={handleDownloadEmailTemplateReport}
        addLabel={t('addEmailTemplateLabel')}
        searchValue={search}
        showInactiveClick={handleShowInactive}
        isActive={getActive}
      />
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="15%">{t('nameCol')}</StyledTableCell>
                    <StyledTableCell width="60%">{t('descriptionCol')}</StyledTableCell>
                    <StyledTableCell width="80%">{t('visibleCol')}</StyledTableCell>
                    <StyledTableCell width="100px">{t('actionsCol')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToDisplay?.map((EmailTemplate) => {
                    const active = EmailTemplate.active ? 1 : 0;
                    return (
                      <TableRow
                        sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }}
                        data-id={EmailTemplate?._id}
                        hover
                        key={EmailTemplate?._id}>
                        <StyledTableCell>{EmailTemplate.name}</StyledTableCell>
                        <StyledTableCell>{EmailTemplate.description}</StyledTableCell>
                        <StyledTableCell>{EmailTemplate.visible ? "True" : "False" }</StyledTableCell>
                        <StyledTableCell>
                          <Tooltip
                            title={t('editEmailTemplate')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <BorderColorOutlinedIcon
                              onClick={() => handleEditEmailTemplateOpen(EmailTemplate)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'var(--blue210)' }}
                            />
                          </Tooltip>
                          {active ? (
                            <Tooltip
                            title={t('DeActivateEmailTemplate')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <RestoreFromTrashOutlinedIcon
                              onClick={() => handleDeactivate(EmailTemplate?._id)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                            />
                          </Tooltip>
                          ) : (
                            <Tooltip
                            title={t('ReActivateEmailTemplate')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <RestoreFromTrashOutlinedIcon
                              onClick={() => handleReactivate(EmailTemplate?._id)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                            />
                          </Tooltip>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      dataSize={EmailTemplateCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table >
            )}
          </>
        )}
      </TableContainer >
      {
        editEmailTemplateState.open && (
          <EditEmailTemplateDialog
            workflows={workflowData}
            edit={editEmailTemplateState.edit}
            open={editEmailTemplateState.open}
            EmailTemplate={editEmailTemplateState.data}
            error={errorMessage}
            onClose={handleEditEmailTemplateClose}
            onSubmit={handleEditEmailTemplateSubmit}
            onAdd={handleAddEmailTemplateSubmit}
          />
        )
      }
    </Box >
  );
};

export default EmailTemplates;
