import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses
} from '@mui/material';
import React, { useState, useDeferredValue, useCallback} from 'react';

import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import getImageSource from '../Common/getImageSource';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import EditProductDialog from './EditProductDialog';
import StandardTablePagination from './StandardTablePagination';
import TableHeaderBar from './TableHeaderBar';
import axiosCall from '../Services/axios';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useControllerCallback from '@common/hooks/useControllerCallback'
import useInitialMount from '@common/hooks/useInitialMount';

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    consoleColor: true;
  }
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--gray010)',
    align: 'left',
  },
  [`&.${tableCellClasses.root}`]: {
    borderBottom: '1px solid var(--gray010)',
    padding: '4px 8px',
  },
}));

const Products = () => {
  const { t } = useTranslation();
  const [editProductState, setEditProductState] = useState({
    edit: false,
    open: false,
    data: null,
  });
  const [ProductData, setProductData] = useState([]);
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [search, setSearch] = useState('');
  const deferredSearch = useDeferredValue(search);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [errorMessage, setErrorMessage] = useState('');
  const [getActive, setGetActive] = useState(true);
  const [isMoreDataAvailable, setIsMoreDataAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searched, setSearched] = useState(false);
  const [productCount, setProductCount] = useState(0);

  const loadProductData = useCallback(async (controller: AbortController) => {
    setLoading(true);
    // Load one more row than required to test if more data exists
    const offset = page < 0 ? 0 : page * rowsPerPage;
    const limit = rowsPerPage > 0 ? `&limit=${rowsPerPage + 1}` : '';
    const active = getActive ? '&active=1' : '';
    let data = {};
    if (search.length > 0) {
      data = { search: search };
      setSearched(true);
    } else {
      data = "";
      setSearched(false);
    }

    let request = {
      url: `product/?offset=${offset}${limit}${active}`,
      method: 'GET',
      data: data,
    };
    let config = controller
      ? {
          signal: controller.signal,
        }
      : {};

    try {
      const result = await axiosCall(request, config);

      if (result.length > 0) {
        // See if we were able to fetch the one additional row, therefore indicating more data is available
        setIsMoreDataAvailable(rowsPerPage > 0 ? result.length > rowsPerPage : false);
        setProductData(rowsPerPage > 0 ? result.slice(0, rowsPerPage) : result); // Only display the number specified
        setDataToDisplay(rowsPerPage > 0 ? result.slice(0, rowsPerPage) : result);
        setLoading(false);
        try {
          const a = active ? "active=true" : ""
          const result = await axiosCall({ url: `product/db/count?${a}` ,method: 'GET',});
          if (result) {
            setProductCount(result)
          }
        } catch (error) {
          console.log(error);
        }
        setNoResults(false);
      } else {
        setNoResults(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  },[page, rowsPerPage, getActive, deferredSearch]);

  const handleDownloadProductReport = async () => {
    try {
      const result = await axiosCall({ url: `Product/?order=asc&orderBy=name` });
      if (result) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  /*************************************
   * Add/Edit Product Dialog Functions
   *************************************/
  const handleAddProductOpen = () => {
    setEditProductState({ edit: false, open: true, data: null });
  };

  interface productProp  { 
    name: any; 
    description: any; 
    _id: any; 
    picture: any; 
    unitQuantity: any; 
    unitSize: any; 
    type: any; 
    unitColor: any; 
  }

  const handleEditProductOpen = (Product: productProp) => {
    const data = {
      name: Product.name,
      description: Product.description,
      id: Product?._id,
      picture: Product.picture,
      quantity: Product.unitQuantity,
      unitSize: Product.unitSize,
      type: Product.type,
      unitColor: Product.unitColor
    };
    setEditProductState({ edit: true, open: true, data: data });
    setErrorMessage('');
  };

  const handleEditProductClose = () => {
    setEditProductState({ edit: false, open: false, data: null });
    setErrorMessage('');
  };

  const handleEditProductSubmit = async (data: any) => {
    if (await editProduct(data)) {
      setEditProductState({edit: false, open: false, data: null });
      setErrorMessage('');
    }
  };

  const handleAddProductSubmit = async (data) => {
    if (await addProduct(data)) {
      setEditProductState({edit: false, open: false, data: null });
      setErrorMessage('');
    }
  };

  const editProduct = async (data: { [x: string]: any; id: any; }) => {
    const { id, ...sendData } = data;
    try {
      let result = await axiosCall({
        url: `Product/${id}`,
        method: 'PUT',
        data: sendData,
      });

      if (result) {
        await loadProductData(undefined);
        return true;
      }
    } catch (error : any) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  const addProduct = async (data) => {
    try {
      let result = await axiosCall({
        url: `Product`,
        method: 'POST',
        data: data,
      });

      if (result) {
        await loadProductData(undefined);
        return true;
      }
    } catch (error : any) {
      setErrorMessage(error.response?.data?.message || 'Error');
      return false;
    }
  };

  const handleDeactivate = async (id: string) => {
    try {
      const result = await axiosCall({
        url: `Product/${id}`,
        method: 'PUT',
        data: {
          active : false
        }
      });
      if (result) {
        await loadProductData(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReactivate = async (id) => {
    try {
      const result = await axiosCall({
        url: `Product/${id}`,
        method: 'PUT',
        data: {
          active : true
        }
      });
      if (result) {
        await loadProductData(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowInactive = () => {
    //Invert this value
    setGetActive(!getActive);
    setPage(0);
  };

  /*************************************
   * Pagination Functions
   *************************************/
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


    // load initial data on mount
  

    // reload data when getActive, page or rowsPerPage changes
    useControllerCallback(loadProductData);
  

  return (
    <Box display="flex" flexDirection="column" height="calc(100vh - 175px)" padding={1}>
      <TableHeaderBar
        onAdd={handleAddProductOpen}
        shouldHaveSearch={false}
        setSearch={setSearch}//@ts-ignore
        onRefresh={loadProductData}
        onExport={handleDownloadProductReport}
        addLabel={t('addProductLabel')}
        searchValue={search}
        showInactiveClick={handleShowInactive}
        isActive={getActive} uploadIcon={false}      />
      <TableContainer sx={{ border: '1px solid var(--gray010)' }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="300px">
            <CircularProgress disableShrink />
          </Stack>
        ) : (
          <>
            {noResults ? (
              <Stack alignItems="center" justifyContent="center" height="300px">
                <Paper sx={{ p: 1.5 }}>
                  <Typography> {t("noData")} </Typography>
                </Paper>
              </Stack>
            ) : (
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell width="0%">{t('imageCol')}</StyledTableCell>
                    <StyledTableCell width="15%">{t('nameCol')}</StyledTableCell>
                    <StyledTableCell width="30%">{t('descriptionCol')}</StyledTableCell>
                    <StyledTableCell width="40%">{t('quantityCol')}</StyledTableCell>
                    <StyledTableCell width="50%">{t('typeCol')}</StyledTableCell>
                    <StyledTableCell width="60%">{t('unitSizeCol')}</StyledTableCell>
                    <StyledTableCell width="70%">{t('unitColorCol')}</StyledTableCell>
                    <StyledTableCell width="100%">{t('actionsCol')}</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataToDisplay?.map((Product) => {
                    const active = Product.active ? 1 : 0;
                    return (
                      <TableRow
                        sx={{ backgroundColor: active ? 'var(--gray000)' : 'var(--peach030)' }}
                        hover
                        key={Product?._id}>
                        <StyledTableCell>
                        {Product.picture && (
                          <Box
                            component="img"
                            style={{ filter: 'grayscale(0%)' }}
                            width="100%"
                            src={getImageSource(Product.picture)}
                          />
                        )}
                        </StyledTableCell>
                        <StyledTableCell>{Product.name}</StyledTableCell>
                        <StyledTableCell>{Product.description}</StyledTableCell>
                        <StyledTableCell>{Product.unitQuantity}</StyledTableCell>
                        <StyledTableCell>{Product.type}</StyledTableCell>
                        <StyledTableCell>{Product.unitSize}</StyledTableCell>
                        <StyledTableCell>{Product.unitColor}</StyledTableCell>
                        <StyledTableCell>
                          <Tooltip
                            title={t('editProduct')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <BorderColorOutlinedIcon
                              onClick={() => handleEditProductOpen(Product)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'var(--blue210)' }}
                            />
                          </Tooltip> 
                          {active ? (
                            <Tooltip
                            title={t('deActivateProduct')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <RestoreFromTrashOutlinedIcon
                              onClick={() => handleDeactivate(Product?._id)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'red' }}
                            />
                          </Tooltip>
                          ) : (
                            <Tooltip
                            title={t('reActivateProduct')}
                            placement="top-start"
                            enterDelay={1000}
                            enterNextDelay={1000}>
                            <RestoreFromTrashOutlinedIcon
                              onClick={() => handleReactivate(Product?._id)}
                              fontSize="small"
                              sx={{ cursor: 'pointer', marginLeft: '3px', color: 'green' }}
                            />
                          </Tooltip>
                          )}
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <StandardTablePagination
                      dataSize={productCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      isMore={isMoreDataAvailable}
                    />
                  </TableRow>
                </TableFooter>
              </Table >
            )}
          </>
        )}
      </TableContainer >
      {
        editProductState.open && (
          <EditProductDialog
            edit={editProductState.edit}
            open={editProductState.open}
            Product={editProductState.data}
            error={errorMessage}
            onClose={handleEditProductClose}
            onSubmit={handleEditProductSubmit}
            onAdd={handleAddProductSubmit}
          />
        )
      }
    </Box >
  );
};

export default Products;
