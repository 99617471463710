import React from 'react';
import { useEffect, RefObject } from "react";

// Define the hook with types
const useInOutView = (
    ref: RefObject<Element>, // Ref to the element being observed
    visibleHandler: () => void, // Function to call when the element is in view
    lostHandler: () => void, // Function to call when the element is not in view
) => {
    useEffect(() => {
        if (!ref.current) return; // If the ref is not assigned, do nothing

        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    visibleHandler(); // Call the handler function when the component is visible
                } else {
                    lostHandler();
                }
            },
            { threshold: 0.3 } // Options for the IntersectionObserver (e.g., threshold, rootMargin)
        );

        observer.observe(ref.current);

        // Clean up the observer when the component unmounts
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref, visibleHandler, lostHandler]); // Re-run effect if ref, handlerFunction, or options change
};


export default useInOutView