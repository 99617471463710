import { Box, styled } from '@mui/system';

const IconBackground = styled(Box)(({ theme }) => `
  height: 50px;
  border: 1px dashed ${ theme.palette.orange['700'] };
  position: absolute;
  bottom: 0;
  transform: translateY(40px);
`)

export default IconBackground;