import React from 'react';
import styles from '@styles/MessageContent/ProfileBubbleMessageContent/Message.module.css';
import Title from '@components/MessageContent/ProfileBubbleMessageContent/Title';
import List from '@components/MessageContent/ProfileBubbleMessageContent/List';
import getProfileBubbleMessage from '@common/getProfileBubbleMessage';
import { uiTranslationController } from '@common/common';
import MessageTime from '@components/MessageTime';
import { IMessage } from '@interfaces/Conversation';

const COMPONENT = 'Message';

interface Props {
  message: IMessage;
}

/**
 * The message.
 */
export default function Message(props: Props): React.ReactElement {
  const { dispMessage } = uiTranslationController(props.message);

  function renderMessage(): React.ReactElement {
    // get message
    const message = getProfileBubbleMessage(dispMessage);

    // check message
    if (!message) {
      return;
    }

    // return
    return (
      <>
        <Title title={message.title} />
        <List values={message.values} />
      </>
    );
  }

  return (
    <div data-component={COMPONENT} className={styles.message}>
      {renderMessage()}
      <MessageTime message={props.message} />
    </div>
  );
}
