import { styled } from '@mui/system';
import Divider from '@mui/material/Divider';

export const Styles: React.FC<any> = styled('div')<any>(
  () => `
  cursor: ns-resize;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: center;
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  img {
    height: 38px;
    cursor: ns-resize;
  }
  .scrollWheelContainer {
    cursor: ns-resize;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--gray000);
    border-radius: 8px;
    margin-top: -4px;
    height: 50px;
    min-width: 25px;
    z-index: 9999;
    position: relative;
    :active {
      cursor: ns-resize;
    }
    img {
      left: 4px;
      position: absolute;
      height: 40px;    
    }
  }
  .isScrolling {
    border: 1px solid var(--orange700);
    border-right: none;
  }
  form {
    align-items: center;
    border-radius: 0px 8px 8px 0px;
    box-shadow: var(--shadow200) 0px 10px 40px;
    border: none;
    color: var(--shadow870);
    display: flex;
    height: fit-content;
    padding: 3px 4px 3px 8px;
    position: relative;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    background-color: var(--gray000);
  }
  .carousel-wrapper,
  .carousel figure {
    height: 36px;
    line-height: 36px;
  }

  .carousel figure:nth-of-type(1) {
    -webkit-transform: rotateX(0deg)   translateZ(36px); 
    transform: rotateX(0deg)   translateZ(36px);
  }
  .carousel figure:nth-of-type(2) {
    -webkit-transform: rotateX(60deg)  translateZ(36px);
    transform: rotateX(60deg)  translateZ(36px);
    }
  .carousel figure:nth-of-type(3) {
    -webkit-transform: rotateX(120deg) translateZ(36px); 
    transform: rotateX(120deg) translateZ(36px);
  }
  .carousel figure:nth-of-type(4) {
    -webkit-transform: rotateX(180deg) translateZ(36px); 
    transform: rotateX(180deg) translateZ(36px);
  }
  .carousel figure:nth-of-type(5) {
    -webkit-transform: rotateX(240deg) translateZ(36px); 
    transform: rotateX(240deg) translateZ(36px);
  }
  .carousel figure:nth-of-type(6) {
    -webkit-transform: rotateX(300deg) translateZ(36px); 
    transform: rotateX(300deg) translateZ(36px);
  }

  .carousel:not([data-state="1"]) figure:nth-of-type(1),
  .carousel:not([data-state="2"]) figure:nth-of-type(2),
  .carousel:not([data-state="3"]) figure:nth-of-type(3),
  .carousel:not([data-state="4"]) figure:nth-of-type(4),
  .carousel:not([data-state="5"]) figure:nth-of-type(5),
  .carousel:not([data-state="6"]) figure:nth-of-type(6) { 
    background-color: var(--gray100);
    border: none;
    clip-path: inset(-5px -5px -5px 0px);
  }

  .carousel-wrapper {
    height: 100px;
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    perspective: calc(100vw + 300px);
    z-index: 99;
    position: relative;
  }

  .carousel {
    width: calc(100% - 10px);
    position: absolute;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: all .6s;
    line-height: 10px;
    height: 45px;
  }

  .carousel figure {
    position: absolute;
    width: 100%;
    margin: 0;
    background-color: var(--gray000);
    clip-path: inset(-5px -5px -5px 0px);
    text-align: top;
    z-index: 1;
    border-radius: 12px 4px 4px 12px;
    transition: all .6s;
    -webkit-transition: all .6s;
    -moz-transition: all .6s;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    border: 1px solid var(--orange700);
    border-left: none;
    display:flex;
    flex-direction: row;
    align-items: center;
    color: var(--shadow870);
    height: 42px;
    padding: 3px 4px 3px 24px;
  }

  .carousel figure input {
    font-size: 16px;
    cursor: ns-resize;
  }
  @media (max-width: 375px) {
    .carousel {
      width: calc(100% - 8px);
    }
    .carousel figure {
      padding: 3px 4px 3px 24px;
    }
  }
  .StyledOptionIcon {
    margin: 0 !important;
  }

  .inputSpan {
    width: 100%;
    border: none;
    background-color: inherit;
    font-family: var(--typographyFontFamily);
  }
  .inputSpan:focus {
    outline: none;
  }
  
  .noCaret {
    caret-color: transparent !important;
  }

  .unSelectable {
    -webkit-user-select: none;  /* Safari */
    -moz-user-select: none;     /* Firefox */
    -ms-user-select: none;      /* Internet Explorer/Edge */
    user-select: none;          /* Standard syntax */
  }

  .inputSpan:disabled {
    opacity: 1 !important;
  }
  .figureForm {
    all: initial;
    width: 100%;
  }

  .WhiteIcon {
    filter: brightness(0) invert(1);
  }
  `
);

export const MSDivider: React.FC<any> = styled(Divider)<any>(
  () => `
  height: 22px;
  margin: 0 8px;
  background-color: var(--peach400);
  `
);
