import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';

// Import Swiper styles
import 'swiper/css';

import axiosCall from '@services/axios';
import { useMorphContext } from '@contexts/MorphContext';
import useInitialMount from '@common/hooks/useInitialMount';
import { useErrandContext } from '@contexts/ErrandContext';

import Styles from '@styles/MorphBorrowerSelector.module.css';
import { TBorrower } from '@mTypes/TBorrower';
import { getOperatorAccessToken } from '@storage/operatorStorage';

type BorrowerCircleProps = {
  borrower: TBorrower;
  isSelected: boolean;
};

const BorrowerCircle = ({ borrower, isSelected }: BorrowerCircleProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <div className={isSelected ? Styles.selectedBorrowerCircle : Styles.borrowerCircle}>
        <b>
          {borrower.firstName[0]}
          {borrower.lastName[0]}
        </b>
      </div>
    </div>
  );
};

const MorphBorrowerSelector = ({ setText }) => {
  // fetch borrower list from field endpoint
  // display borrower list
  // Allow user to select borrowers
  // Allow user to submit selected borrowers
  const errandContext = useErrandContext();
  const chatId = errandContext.errandId;
  const { setSelectedBorrowers, selectedBorrowers, setBorrowerList, borrowerList, sendButtonRef } =
    useMorphContext();

  const handleSlideChange = (swiper) => {
    const hoveredIndex = swiper.activeIndex;
    const hoveredBorrower = borrowerList[hoveredIndex];
    setText(`${hoveredBorrower.firstName} ${hoveredBorrower.lastName}`);
  };

  const handleSlideClick = (index: number) => {
    const clickedBorrower = borrowerList[index];
    let temp = new Set(selectedBorrowers);
    if (temp.has(clickedBorrower)) {
      temp.delete(clickedBorrower);
    } else {
      temp.add(clickedBorrower);
    }
    if (!temp.size) {
      sendButtonRef.current.update('');
    } else {
      sendButtonRef.current.update(clickedBorrower.firstName);
    }
    setSelectedBorrowers(temp);
  };

  const getBorrowerList = async () => {
    // get req fieldEndpoint
    const sessionToken = getOperatorAccessToken();
    const borrowers: Array<TBorrower> = await axiosCall(
      {
        url: `fieldEndpoint/borrowerList?chatId=${chatId}`,
      },
      { sessionToken: sessionToken }
    );
    return borrowers;
  };

  useInitialMount(() => {
    const wrapped = async () => {
      const fetchedBorrowerList = await getBorrowerList();
      setBorrowerList(fetchedBorrowerList);
      const firstBorrower = fetchedBorrowerList[0];
      setText(`${firstBorrower.firstName} ${firstBorrower.lastName}`);
    };
    wrapped();
  });

  return (
    <Swiper
      centeredSlides={true}
      slidesPerView={'auto'}
      modules={[FreeMode]}
      className={Styles.swiperContainer}
      onSlideChange={handleSlideChange}
      slideToClickedSlide={true}
    >
      {borrowerList.map((borrower, index) => (
        <SwiperSlide key={index} onClick={() => handleSlideClick(index)} className={Styles.slideWidthFixed}>
          <BorrowerCircle borrower={borrower} isSelected={selectedBorrowers.has(borrower)} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MorphBorrowerSelector;
