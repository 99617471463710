import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';

import { GuestsIcon } from '@assets/Icons';
import MoodMeter from '../MoodMeter';
import StarIcon from '@mui/icons-material/Star';
import { UserClock } from '@assets/Icons';
import { useTranslation } from 'react-i18next';

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    blue: true;
  }
}

const OperatorStats = ({ operatorData, moodMeterValue }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
      <Grid item xs={12} sm={12} md={6} lg={3} id="item1">
        <Card>
          <CardContent sx={{ height: '100%' }}>
            <GuestsIcon />
            <Typography>{t('guestChatsAnswered')}</Typography>
            <Typography fontWeight="bold">
              {operatorData?.Provider?.guestIdList
                ? operatorData?.Provider?.guestIdList.length
                : '-'}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3} id="item2">
        <Card>
          <CardContent>
            <StarIcon color="blue" />
            <Typography>{t('moodMeter')}</Typography>
            <MoodMeter value={50} />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3} id="item3">
        <Card>
          <CardContent>
            <UserClock color="secondary" />
            <Typography>{t('loanAccess')}</Typography>
            <Typography fontWeight="bold">
              {operatorData?.Provider?.totalLoanAccess
                ? operatorData?.Provider?.totalLoanAccess
                : ' - '}
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={3} id="item4">
        <Card>
          <CardContent>
            <UserClock color="secondary" />
            <Typography>{t('workFlowAccess')}</Typography>
            <Typography fontWeight="bold">
              {operatorData?.Provider?.totalWFAccess
                ? operatorData?.Provider?.totalWFAccess
                : ' - '}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default OperatorStats;
