import { Box, styled } from '@mui/system';
import { Stack, Button, IconButton } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import eventBus from '../Common/eventBus';
import { PaymentClickEventType, PaymentActionStateType } from '../Forms/commonForms';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import React, { useContext, useRef, useState } from 'react';
import useInitialMount from '@common/hooks/useInitialMount';
import useDebounce from '@common/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { MorphType } from '@common/MorphType';
import Styles from '@styles/MorphPayment.module.css';
import axiosCall from '@services/axios';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';

const Eye_movement = process.env.REACT_APP_MORGAN_CDN + '/Images/Eye_movement.gif';
const Eye_close_stopped = process.env.REACT_APP_MORGAN_CDN + '/Images/Eye_close_stopped.gif';
const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';
const amexImage = process.env.REACT_APP_MORGAN_CDN + '/Images/Payment/amex.png';
const dinersClubImage = process.env.REACT_APP_MORGAN_CDN + '/Images/Payment/dinersClub.png';
const discoverImage = process.env.REACT_APP_MORGAN_CDN + '/Images/Payment/discover.png';
const jcbImage = process.env.REACT_APP_MORGAN_CDN + '/Images/Payment/JCB.png';
const maestroImage = process.env.REACT_APP_MORGAN_CDN + '/Images/Payment/maestro.png';
const mastercardImage = process.env.REACT_APP_MORGAN_CDN + '/Images/Payment/mastercard.png';
const visaImage = process.env.REACT_APP_MORGAN_CDN + '/Images/Payment/visa.png';
const visaElectronImage = process.env.REACT_APP_MORGAN_CDN + '/Images/Payment/VisaElectron.png';
const CreditCardTemplate = process.env.REACT_APP_MORGAN_CDN + '/Images/CreditCard.svg';
const CreditCardTemplateBack = process.env.REACT_APP_MORGAN_CDN + '/Images/CreditCardBack.svg';
const PaymentIcon = process.env.REACT_APP_MORGAN_CDN + '/Images/PaymentIcon.svg';

const PAY_THROUGH_WORKFLOW_ITEMS = new Set(["Credit Report Order and Mailing Charges", "Russell Peters Products"]);

const StyledButton = styled(Button)<any>(
  ({ fontSize, isPayNowButton }) => `
    position: relative;
    z-index: 1001;
    margin-left: 14px;
    margin-right: 14px;
    font-size: ${fontSize ? fontSize : '0.8rem'};
    font-weight: normal;
    align-content: center;
    height: 1rem;
    color: var(--orange700); /* orange text color */
    background-color: var(--gray000); /* white background color */
    border-radius: 7px; /* rounded corners */
    text-transform: none;
    padding:15px;
    white-space: nowrap;
    text-align: center;
    box-shadow: none;
    &:hover {
      background-color: ${isPayNowButton ? "var(--green700)" : "var(--gray850)"};
    }`
);

const StyledWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  justifyContent: 'space-between',
  overflow: 'visible',
  alignItems: 'center',
  width: '100%',
  minWidth: '50vw',
  maxWidth: '70vw',
  backgroundColor: 'var(--orange700)',
  backgroundSize: '20%', //combat the weird tile line that shows up. IMO the woodgrain looks better like this too.
  backgroundImage: `url(${WoodGrain})`,
  backgroundPositionX: '50%', //align the tiling of the background image in the center
  borderRadius: '0.5rem',
  height: '50px',
});

const CreditCard = ({
  cardTypeRef,
  cardNumber,
  setCardNumber,
  setExpiration,
  setCVC,
  setHolderName,
  isFlipped,
  toggleFlip,
  address,
  zip,
  city,
  state,
  setAddress,
  setZip,
  setCity,
  setState,
}) => {
  const stateList = [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
    'AS',
    'GU',
    'MP',
    'PR',
    'VI',
  ];

  //custom image for the state dropdown
  const arrowSVG = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path fill="orange" stroke="orange" stroke-width="2" d="M6 5l4 4 4-4H6z"/>
    </svg>
    `;
  const orangeDropdownArrowDataURL = `data:image/svg+xml;base64,${btoa(arrowSVG)}`;

  const [isValid, setIsValid] = useState(false);
  const [cardTypeImage, setCardTypeImage] = useState(null);
  const [expectedCardLength, setExpectedCardLength] = useState(16); // default to 16
  const [currentGif, setCurrentGif] = useState(Eye_movement);
  const [options, setOptions] = useState([]);
  const debounceTimer = useRef(null); // using useRef to hold the timer ID
  const [isFocused, setIsFocused] = useState(false);
  const blurTimer = useRef(null);

  useDebounce(
    () => {
      if (address) {
        handleAutoCompletionSelectedWrapper(null, address);
      }
    },
    300,
    [address]
  );

  const handleBlur = () => {
    blurTimer.current = setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };

  const { t } = useTranslation();

  //these refs will be used to automatically go to the next
  //credit card input field when the user is done typing into one
  const cvcInputRef = useRef(null);
  const cardInput1Ref = useRef(null);
  const cardInput2Ref = useRef(null);
  const cardInput3Ref = useRef(null);
  const cardInput4Ref = useRef(null);
  const cardHolderRef = useRef(null);
  const validThruRef = useRef(null);
  //general ref to send via the onChange function to trigger the gif
  const gifActivationRef = useRef(null);

  /**
   * Event: Generated when auto completion option selected from dropdown
   */
  const handleAutoCompletionSelectedWrapper = (event, input) => {
    axiosCall({
      url: `google/place/autocomplete`,
      method: 'post',
      data: {
        input: input,
      },
    })
      .then((result) => {
        setOptions(result);
      })
      .catch((error) => {
        console.log("google place autocomplete API doesn't work" + error.message);
      });
  };

  const handleSetAddress = async (input) => {
    setAddress(input.label.replace(/^\s+/g, ''));
    axiosCall({
      url: `google/place/details`,
      method: 'post',
      data: {
        place_id: input.id,
      },
    }).then((result) => {
      if (result) {
        setAddress(result.addressLine1);
        setCity(result.city);
        setZip(result.postalCode);
        setState(result.state);
      }
    });
  };

  //credit card number verification
  const luhnAlgorithm = (cardNumber) => {
    const arr = cardNumber.split('').reverse();
    let sum = 0;
    for (let i = 0; i < arr.length; i++) {
      let n = parseInt(arr[i]);
      if (i % 2 !== 0) {
        n *= 2;
        if (n > 9) {
          n -= 9;
        }
      }
      sum += n;
    }
    return sum % 10 === 0;
  };

  const detectCardType = (cardNumber) => {
    const visaRegex = /^4/;
    const mastercardRegex = /^5[1-5]/;
    const amexRegex = /^3[47]/;
    const discoverRegex = /^6(?:011|5[0-9]{2})/;
    const dinersClubRegex = /^3(?:0[0-5]|[68][0-9])/;
    const jcbRegex = /^(?:2131|1800|35\d{3})/;
    const maestroRegex = /^(5018|5020|5038|5893|6304|6759|676[1-3])/;
    const visaElectronRegex = /^(4026|417500|4508|4844|491(3|7))/;

    if (maestroRegex.test(cardNumber)) {
      setExpectedCardLength(16);
      setCardTypeImage(maestroImage);
      cardTypeReF.current = 'MAST';
    } else if (visaElectronRegex.test(cardNumber)) {
      setExpectedCardLength(16);
      setCardTypeImage(visaElectronImage);
      cardTypeRef.current = 'VISA';
    } else if (visaRegex.test(cardNumber)) {
      setExpectedCardLength(16);
      setCardTypeImage(visaImage);
      cardTypeRef.current = 'VISA';
    } else if (mastercardRegex.test(cardNumber)) {
      setExpectedCardLength(16);
      setCardTypeImage(mastercardImage);
      cardTypeRef.current = 'MAST';
    } else if (amexRegex.test(cardNumber)) {
      setExpectedCardLength(15);
      setCardTypeImage(amexImage);
      cardTypeRef.current = 'AMEX';
    } else if (discoverRegex.test(cardNumber)) {
      setExpectedCardLength(16);
      setCardTypeImage(discoverImage);
      cardTypeRef.current = 'DISC';
    } else if (dinersClubRegex.test(cardNumber)) {
      setExpectedCardLength(14);
      setCardTypeImage(dinersClubImage);
      cardTypeRef.current = 'DINERS';
    } else if (jcbRegex.test(cardNumber)) {
      setExpectedCardLength(16);
      setCardTypeImage(jcbImage);
      cardTypeRef.current = 'JCB';
    } else {
      setExpectedCardLength(0);
      setCardTypeImage(null);
      cardTypeRef.current = '';
    }
  };

  const getInputStyle = () => {
    if (cardNumber.length < 16) {
      return `${Styles.inputField} ${Styles.error}`;
    }
    return isValid ? `${Styles.inputField} ${Styles.valid}` : `${Styles.inputField} ${Styles.invalid}`;
  };

  const handleKeyDown = (event, nextInputRef, prevInputRef) => {
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      prevInputRef?.current?.focus();
    }
  };

  const updateCardNumber = (newSegment, index) => {
    const newCardNumber = `${cardNumber.slice(0, index * 4)}${newSegment}${cardNumber.slice((index + 1) * 4)}`;
    setCardNumber(newCardNumber);
    detectCardType(newCardNumber);
    if (newCardNumber.length === expectedCardLength) setIsValid(luhnAlgorithm(newCardNumber));
    else {
      setIsValid(false);
    }
  };

  const handleInputChange = (event, nextInputRef, index) => {
    const input = event.target.value;
    let formattedValue = input;
    if (event.target.id === 'cvv') {
      formattedValue = formattedValue.replace(/[^0-9]/g, '');
      setCVC(event.target.value);
    }
    if (['cc1', 'cc2', 'cc3', 'cc4'].includes(event.target.id)) {
      formattedValue = formattedValue.replace(/[^0-9]/g, '');
    } else if (event.target.id === 'exp') {
      formattedValue = formattedValue.replace(/[^0-9]/g, '');
      if (formattedValue.length >= 2) {
        formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2)}`;
      }
      setExpiration(event.target.value);
    } else if (event.target.id === 'cardHolder') {
      setHolderName(event.target.value);
    }
    if (event.target.value.length >= event.target.maxLength) {
      nextInputRef?.current?.focus();
    }
    if (index !== null) updateCardNumber(event.target.value, index);

    setCurrentGif(Eye_close_stopped);
    // Set a new timeout to revert back to defaultGifUrl
    event.target.value = formattedValue;
  };

  return (
    <>
      <div className={`${Styles.flipContainer} ${isFlipped ? Styles.flip : ''}`}>
        <div className={Styles.flipper}>
          <div className={Styles.front}>
            <div className={Styles.CreditCard}>
              <img src={currentGif} alt="AngelAi watching" className={`${Styles.eyeMovement}`} />
              {cardTypeImage && <img src={cardTypeImage} alt="Card Type" className={`${Styles.cardTypeImage}`} />}
              <img className={Styles.CreditCardImage} src={CreditCardTemplate} alt="Credit Card" />
              <input
                autoComplete="cc-csc"
                id="cvv"
                ref={cvcInputRef}
                type="text"
                inputMode="numeric"
                maxLength={4}
                className={`${Styles.inputField} ${Styles.cvcInput}`}
                placeholder="CVC"
                onChange={(e) => handleInputChange(e, cardInput1Ref, null)}
                onKeyDown={(e) => handleKeyDown(e, cardInput1Ref, null)}
              />

              <div className={`${Styles.label}`} style={{ left: '5%', top: '32%' }}>
                {t('CARD NUMBER')}
              </div>
              <div className={`${Styles.label}`} style={{ bottom: '25%', left: '5%' }}>
                {t('CARD HOLDER')}
              </div>
              <div
                className={`${Styles.label}`}
                style={{ right: '19%', top: '15%', fontSize: '1.10em', color: 'var(--gray000)' }}
              >{`CVC`}</div>
              <input
                autoComplete="cc-number"
                ref={cardInput1Ref}
                type="text"
                inputMode="numeric"
                id="cc1"
                maxLength={4}
                className={`${getInputStyle()} ${Styles.cardNumberInput}`}
                style={{ left: '12%' }}
                placeholder="XXXX"
                onChange={(e) => handleInputChange(e, cardInput2Ref, 0)}
                onKeyDown={(e) => handleKeyDown(e, cardInput2Ref, cvcInputRef)}
              />

              <input
                ref={cardInput2Ref}
                type="text"
                inputMode="numeric"
                id="cc2"
                maxLength={4}
                className={`${getInputStyle()} ${Styles.cardNumberInput}`}
                style={{ left: '30%' }}
                placeholder="XXXX"
                onChange={(e) => handleInputChange(e, cardInput3Ref, 1)}
                onKeyDown={(e) => handleKeyDown(e, cardInput3Ref, cardInput1Ref)}
              />

              <input
                ref={cardInput3Ref}
                type="text"
                inputMode="numeric"
                maxLength={4}
                id="cc3"
                className={`${getInputStyle()} ${Styles.cardNumberInput}`}
                style={{ left: '48%' }}
                placeholder="XXXX"
                onChange={(e) => handleInputChange(e, cardInput4Ref, 2)}
                onKeyDown={(e) => handleKeyDown(e, cardInput4Ref, cardInput2Ref)}
              />

              <input
                ref={cardInput4Ref}
                type="text"
                inputMode="numeric"
                id="cc4"
                maxLength={cardTypeRef.current === "AMEX" ? 3 : cardTypeRef.current === "DINERS" ? 2 : 4}
                className={`${getInputStyle()} ${Styles.cardNumberInput}`}
                style={{ left: '66%' }}
                placeholder={cardTypeRef.current === "AMEX" ? "XXX" : cardTypeRef.current === "DINERS" ? "XX" : "XXXX"}
                onChange={(e) => handleInputChange(e, cardHolderRef, 3)}
                onKeyDown={(e) => handleKeyDown(e, cardHolderRef, cardInput3Ref)}
              />
              <input
                ref={cardHolderRef}
                id="cardHolder"
                type="text"
                maxLength={22} //generally this is the max
                className={`${Styles.inputField} ${Styles.cardHolderInput}`}
                placeholder="Card Holder Name"
                onChange={(e) => handleInputChange(e, validThruRef, null)}
                onKeyDown={(e) => handleKeyDown(e, validThruRef, cardInput4Ref)}
              />

              <input
                autoComplete="cc-exp"
                id="exp"
                ref={validThruRef}
                type="text"
                inputMode="numeric"
                maxLength={5} // MM/YY is 5 characters
                className={`${Styles.inputField} ${Styles.validThruInput}`}
                onChange={(e) => handleInputChange(e, null, null)}
                onKeyDown={(e) => handleKeyDown(e, null, cardHolderRef)}
                placeholder="MM/YY"
              />
            </div>
          </div>

          <div className={Styles.back}>
            <div className={Styles.CreditCard}>
              <img src={currentGif} alt="AngelAi watching" className={`${Styles.eyeMovementBack}`} />
              {cardTypeImage && <img src={cardTypeImage} alt="Card Type" className={`${Styles.cardTypeImage}`} />}
              <img className={Styles.CreditCardImage} src={CreditCardTemplateBack} alt="Credit Card" />
              <div className={`${Styles.label}`} style={{ bottom: '20%', left: '10%' }}>
                {t('State')}
              </div>
              <div className={`${Styles.label}`} style={{ bottom: '20%', left: '30%' }}>
                {t('Zip Code')}
              </div>
              <div className={`${Styles.label}`} style={{ top: '27%', width: '100%', left: '7%' }}>
                {t('Billing Street Address')}
              </div>
              <div className={`${Styles.label}`} style={{ top: '49%', width: '100%', left: '3%' }}>
                {t('Billing City')}
              </div>
              <div
                onClick={(e) => {
                  toggleFlip();
                }}
                className={`${Styles.backButtonReverse}`}
              >
                <div className={`${Styles.backButtonLabel}`} style={{ color: 'var(--gray000)' }}>
                  {t('Back to Card')}
                </div>
              </div>

              <input
                id="streetAddress"
                value={address}
                onFocus={() => setIsFocused(true)}
                onBlur={handleBlur}
                onChange={(e) => {
                  //this is debounced with a useEffect above
                  //in order to rate limit the google api calls
                  handleInputChange(e, gifActivationRef, null);
                  setAddress(e.target.value);
                }}
                tabIndex={1}
                type="text"
                className={`${Styles.inputField} ${Styles.streetAddress}`}
                placeholder="Billing Street Address"
              />
              {/* Displaying options */}
              {address !== '' && isFocused && options.length > 0 && (
                <ul className={Styles.optionsList}>
                  {options.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        handleSetAddress(option);
                        setOptions([]); // Hide options after selecting
                      }}
                    >
                      {option.label.replace(/^\s+/g, '')}
                    </li>
                  ))}
                </ul>
              )}
              <input
                id="city"
                value={city}
                tabIndex={2}
                type="text"
                className={`${Styles.inputField} ${Styles.city}`}
                placeholder="Billing City"
                //trigger the gif to activate using the valid ref (does not )
                onChange={(e) => {
                  setCity(e.target.value);
                  handleInputChange(e, gifActivationRef, null);
                }}
              />
              <input
                tabIndex={4}
                value={zip}
                id="zipCode"
                type="number"
                maxLength={8} //generally this is the max
                className={`${Styles.inputField} ${Styles.zipInput}`}
                placeholder="Zip Code"
                onChange={(e) => {
                  setZip(e.target.value);
                  handleInputChange(e, gifActivationRef, null);
                }}
              />
              <select
                className={`${Styles.inputField} ${Styles.select}`}
                tabIndex={3}
                value={state}
                id="state"
                style={{
                  backgroundImage: `url(${orangeDropdownArrowDataURL})`,
                }}
                onChange={(e) => {
                  setState(e.target.value);
                  handleInputChange(e, gifActivationRef, null);
                }}
              >
                {stateList.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MorphPayment = ({ errand, setText, cancelAction }) => {
  const { t } = useTranslation();
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const { _id, ssAccessToken } = useUserContext();
  const [cardNumber, setCardNumber] = useState('');
  const [expiration, setExpiration] = useState('');
  const [CVC, setCVC] = useState('');
  const [holderName, setHolderName] = useState('');
  const [address, setAddress] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [item, setItem] = useState(null);
  const [cost, setCost] = useState(null);
  const [isFlipped, setIsFlipped] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [isWarningActive, setIsWarningActive] = useState(false);
  const cardTypeRef = useRef('');

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  //get the cost of the item which is being paid for.
  const fetchPriceField = async (data) => {
    // Construct the query parameters

    // Prepare request information
    const request = {
      url: `pay/field`,
      method: 'POST',
      data,
    };
    const res = await axiosCall(request);
    if (res.data !== 'payment already made') {
      setItem(res.productDescription);
      setCost(res.amount);
    } else {
      errandContext.setMorphType(MorphType.None);
    }
  };

  useInitialMount(() => {
    fetchPriceField({ chat: errandContext.errandId, context: errand.activeContext });
    setText(t('Complete Your Payment'));
  });

  const findLastPaymentMessage = () => {
    for (const message of errand.messages.slice().reverse()) {
      if (message?.action?.fieldName === 'creditCardDetails') {
        return message;
      }
    }
  };
  const attemptPayment = async () => {
    try {
      // If the payment is meant to be processed in the workflow itself, send
      // payment info to the workflow through a system-only message
      if (PAY_THROUGH_WORKFLOW_ITEMS.has(item)) {
        const data = {
          sender: _id,
          senderType: "User",
          accessType: AccessType.system,
          message: JSON.stringify({
            cardNumber: cardNumber,
            holderName: holderName,
            cardExpiration: expiration,
            cvv: CVC,
            cardBillingStreetAddr: address,
            cardBillingCity: city,
            cardBillingState: state,
            cardBillingZip: zip,
          }),
          messageType: 'Field',
          userAction: findLastPaymentMessage()?.userAction?._id,
        };
        
        const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data };
        
        await axiosCall(payload);
        cancelAction(null, false);
      } else {
        let res = await axiosCall({
          url: 'pay',
          method: 'POST',
          data: {
            cardType: cardTypeRef.current,
            message: findLastPaymentMessage(),
            chat: errandContext.errandId,
            userId: _id,
            cardNumber: cardNumber,
            holderName: holderName,
            cardExpiration: expiration,
            cvv: CVC,
            cardBillingStreetAddr: address,
            cardBillingCity: city,
            cardBillingState: state,
            cardBillingZip: zip,
            token: ssAccessToken,
          },
        });
        //reset the morph on payment success
        if (res?.msg === 'success') {
          cancelAction(null, false);

          const data = {
            sender: _id,
            senderType: "User",
            intendedAudience: errand?.recipients?.join(',') || '',
            accessType: AccessType.internal,
            message: JSON.stringify(res),
            messageType: 'Field',
            userAction: findLastPaymentMessage()?.userAction?._id,
          };

          const payload = { url: `chat/${errand?._id}/message`, method: 'POST', data: data };

          await axiosCall(payload);
        } else {
          setPaymentInProgress(false);
          setIsWarningActive(true);
        }
      }
    } catch (error) {
      setPaymentInProgress(false);
      console.log('error with payment');
    }
  };

  const ReceiptBox = () => (
    <div className={Styles.receiptBox}>
      {/* Order Summary */}
      <div className={Styles.orderSummary}>{t(`Order Summary`)}</div>

      {/* Line Items */}
      <div className={Styles.lineItems}>
        <div className={Styles.lineItem}>
          <span>{`1. ${item} Flat Fee`}</span>
          <span>{`$${cost}`}</span>
          <div className={Styles.underline}></div>
        </div>
        {/* Optional second item */}
        {/* <div className={Styles.lineItem}>
                <span>Item 2</span> 
                <span>$20</span>
            </div> */}
      </div>

      {/* Terms and Conditions */}
      {/* <div className={Styles.terms}>
                {t('view terms & conditions')}
            </div> */}
    </div>
  );

  const leftButton = () => {
    switch (errandContext.paymentActionState) {
      case PaymentActionStateType.Preview:
        return <></>;
      //   case PaymentActionStateType.Preview:
      //     return <StyledDiv>{t('buttonDownloading')}</StyledDiv>;
      case PaymentActionStateType.InputInfo:
        return (
          <StyledButton
            onClick={(e) => {
              if (isFlipped) {
                toggleFlip();
              } else {
                e.stopPropagation();
                errandContext.setPaymentActionState(PaymentActionStateType.ViewReceipt);
              }
            }}
          >
            {t('Back')}
          </StyledButton>
        );
      default:
        return <StyledButton onClick={() => cancelAction()}>{t('Cancel')}</StyledButton>;
    }
  };

  const leftIcon = () => {
    switch (errandContext.paymentActionState) {
      case PaymentActionStateType.VerifyingInfo:
      case PaymentActionStateType.InputInfo:
      case PaymentActionStateType.ViewReceipt:
        return <></>;
      default:
        return <></>;
    }
  };

  const middleTopButton = () => {
    // Active handler when EsignActionStateType is Unsigned or Signed
    switch (errandContext.paymentActionState) {
      case PaymentActionStateType.Preview:
        return (
          <IconButton
            onClick={() => {
              item && setText(t('Grand Total'));
              item && errandContext.setPaymentActionState(PaymentActionStateType.ViewReceipt);
            }}
          >
            <img src={PaymentIcon} alt={t('buttonPayment')} className={Styles.signing} />
          </IconButton>
        );
      case PaymentActionStateType.InputInfo:
      case PaymentActionStateType.ViewReceipt:
        return (
          <div className={Styles.grandTotalContainer}>
            {/* this endpoint returns flat fees, hence .00 */}
            <span className={Styles.grandTotalPrice}>{`$${cost}.00`}</span>
          </div>
        );
      case PaymentActionStateType.VerifyingInfo:
        return <p></p>;
      default:
        return <></>;
    }
  };

  const rightButton = () => {
    switch (errandContext.paymentActionState) {
      case PaymentActionStateType.Preview:
        return <></>;
      case PaymentActionStateType.InputInfo:
        return paymentInProgress ? (
          <CircularProgress />
        ) : isFlipped ? (
          <StyledButton
            isPayNowButton
            sx={{ backgroundColor: 'var(--green410)', color: 'var(--gray000)', border: '1px solid var(--gray000)' }}
            onClick={(e) => {
              setPaymentInProgress(true);
              attemptPayment();
            }}
          >
            {t('Pay Now')}
          </StyledButton>
        ) : (
          <StyledButton
            onClick={(e) => {
              toggleFlip();
            }}
          >
            {t('Next')}
          </StyledButton>
        );
      default:
        return (
          <StyledButton
            onClick={(e) => {
              e.stopPropagation();
              setText(t('Grand Total'));
              errandContext.setPaymentActionState(PaymentActionStateType.InputInfo);
            }}
          >
            {t('Next')}
          </StyledButton>
        );
    }
  };

  const rightIcon = () => {
    switch (errandContext.paymentActionState) {
      case PaymentActionStateType.Preview:
        return <></>;
      case PaymentActionStateType.InputInfo:
        return (
          <></>
          //   <IconButton onClick={() => rootContext.leaveFormGoToParentChat(parentId)}>
          //     <Finish height={32} />
          //   </IconButton>
        );
      default:
        return <IconButton onClick={() => eventBus.dispatch(PaymentClickEventType.NavNext)}></IconButton>;
    }
  };

  return (
    <>
      <Snackbar
        open={isWarningActive ? true : false}
        onClose={() => setIsWarningActive(null)}
        message={t('Sorry, There was a problem processing your card information')}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      <div className={Styles.paymentContainer}>
        {errandContext.paymentActionState === PaymentActionStateType.ViewReceipt && <ReceiptBox />}
        {errandContext.paymentActionState === PaymentActionStateType.InputInfo && (
          <CreditCard
            cardTypeRef={cardTypeRef}
            setCardNumber={setCardNumber}
            cardNumber={cardNumber}
            setHolderName={setHolderName}
            setCVC={setCVC}
            setExpiration={setExpiration}
            isFlipped={isFlipped}
            toggleFlip={toggleFlip}
            address={address}
            zip={zip}
            city={city}
            state={state}
            setAddress={setAddress}
            setZip={setZip}
            setCity={setCity}
            setState={setState}
          />
        )}
        <StyledWrapper>
          <Stack
            className={errandContext.paymentActionState === PaymentActionStateType.Preview ? Styles.slideDown : null}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={0}
            style={{ flex: 1, position: 'relative' }}
          >
            {leftIcon()}
            {leftButton()}
          </Stack>
          {middleTopButton()}
          <Stack
            className={errandContext.paymentActionState === PaymentActionStateType.Preview ? Styles.slideDown : null}
            direction="row"
            justifyContent="flex-end"
            alignItems="right"
            spacing={0}
            style={{ flex: 1, position: 'relative' }}
          >
            {rightButton()}
            {rightIcon()}
          </Stack>
        </StyledWrapper>
      </div>
    </>
  );
};

export default MorphPayment;
