/**
 * @file PermissionRejectedMessageContent.tsx
 * @description This file defines the PermissionRejectedMessageContent component used to
 * display the message to the user when they reject a thin client permission
 * workflow
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */
import React from 'react';
import { ChatBubbleStyle } from "@styles/ChatBubbleStyle";
import MessageTextStyle from "@styles/MessageTextStyle";
import { useTranslation } from "react-i18next";
import Styles from '@styles/MessageContent/PermissionRejectedMessageContent.module.css';
import ThinClientUtils from '@common/ThinClientUtils';

const PermissionRejectedMessageContent = () => {
  const { t } = useTranslation();

  const handleClick = (e) => {
    if (ThinClientUtils.isThinClient()){
      e?.stopPropagation();
      window.dispatchEvent(
        new CustomEvent('openSettingsEvent', {
          detail: {
            key: 'openSettingsEvent',
          },
        })
      );
    }
  };

  return (
    <ChatBubbleStyle className={Styles.bubble}>
      <MessageTextStyle>
        {t('grantPermission?')},{' '}
        <a href="javascript:void(0);" onClick={handleClick}>
          {t('clickToOpenSettings')}
        </a>
      </MessageTextStyle>
    </ChatBubbleStyle>
  );
};

export default PermissionRejectedMessageContent;
