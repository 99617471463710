import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultElement } from './templates/DefaultElement';
import HomeIcon from '@mui/icons-material/Home';
import { IconStyles } from '../Constants';

export const PropertyListingElement = memo(() => {
  const { t } = useTranslation();

  return <DefaultElement icon={<HomeIcon style={{ ...IconStyles.default }} />} text={t('AT_propListing')} />;
});
