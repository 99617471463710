import { Box, Card, Paper, Stack, Typography } from '@mui/material';
import { useDrop } from 'react-dnd';
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ActionList from '@components/ActionList';
import ConfirmResendWorkflowDialog from '@components/ConfirmResendWorkflowDialog';
import Conversation from '@components/Conversation';
import UserDialog from '@components/UserDialog';
import axiosCall from '@services/axios';
import { sendAction, sendWorkflow } from '@common/WorkflowsUtils';
import { IErrand } from '@interfaces/Conversation';
import ErrorBoundary from '@components/ErrorBoundary';
import { getOperatorParticipants, getOperatorDisplayString, shouldShowInactive, getPrimaryParticipant } from '@common/errandUtils';
import { ChatType } from '@common/ChatType';
import { useSocketContext } from '@contexts/socket';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';
import { ValidatorFunctions } from '@common/Validators';

const ConsoleConversation: FC<PropsWithChildren<any>> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { eventsSocket, messagesSocket, isEventsConnected, isMessagesConnected } = useSocketContext();
  const iUserData = useUserContext();
  // Recipients of an action or workflow, used to ensure that recipients are sent after confirming resend
  const recipientsRef = useRef([]);
  /* Chat state information */
  const [chatFields, setChatFields] = useState({});
  const [userDialogData, setUserDialogData] = useState('');
  const [showConfirmResendDialog, setShowConfirmResendDialog] = useState({
    open: false,
    chat: undefined,
    type: undefined,
    id: undefined,
    recipients: [],
    check: true,
  });

  /* Required for drag and drop action/workflows */
  // eslint-disable-next-line
  const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
    accept: 'box',
    drop: () => ({ name: 'Console Conversation' }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  /**
   * Handle action/workflow for a given chat
   * @async
   * @function
   * @param {string} id - The id of the action/workflow to handle
   * @param {string} type - The type of the action/workflow
   * @param {string} chatId - The id of the chat where the action/workflow needs to be handled
   */
  const handleActionWorkflow = async (id, type, chatId, recipients = []) => {
    // make an API call to get the participants of the chat
    const participants = await axiosCall({
      url: `chat/${chatId}/participant`,
    });

    const userParticipants = participants?.filter((x) => x.userType === 'User' && x.active);
    // check if participants exist
    if (participants) {
      // check if there are more than 1 user participants and the confirm dialog is not open
      if (
        userParticipants?.length > 1 &&
        !showConfirmResendDialog.open
      ) {
        // show user dialog to confirm who the action/workflow should be sent to
        setUserDialogData(`${id}<>${type}<>${chatId}`);
      } else {
        if (!recipientsRef.current?.length) {
          if (recipients?.length) {
            recipientsRef.current = recipients;
          } else {
            const user = userParticipants.find((p) => p.active && p.primary);
            recipientsRef.current = [user.userId || user.userData._id];
          }
        }
        // check if the type is valid
        if (type !== null && type !== undefined && type !== '') {
          // check if the type is an action
          if (type === 'action') {
            // send action to the chat
            setShowConfirmResendDialog(
              await sendAction(
                id,
                '',
                chatId,
                recipientsRef.current,
                AccessType.public,
                iUserData?._id,
                true,
                showConfirmResendDialog.check
              )
            );
          }
          // check if the type is a workflow
          else if (type === 'workflow') {
            // send workflow to the chat
            setShowConfirmResendDialog(
              await sendWorkflow(
                id,
                '',
                chatId,
                recipientsRef.current,
                AccessType.public,
                iUserData?._id,
                true,
                showConfirmResendDialog.check
              )
            );
          }
          // reset recipients so that the next action can be sent publically if needed
          recipientsRef.current = [];
        }
      }
    }
  };

  // adds listener to events socket to handle notification updates and chat closings
  useEffect(() => {
    if (!isEventsConnected) return;
    /**
     * Callback function to handle chat status closed events.
     *
     * @param {Object} payload - The payload object received from the socket event.
     */
    const onChatStatusClosed = (payload) => {
      console.log(`Events Socket - ConsoleConversation - (chat-status-closed)`, payload);
  
      // store the new chat status data in a variable
      let chatStatus = payload?.data;
  
      // check if chat status exists
      if (!chatStatus) {
        console.info('(chat-status-closed) invalid payload data', payload);
        return;
      }
  
      props.setErrands((prev) => {
        const index = prev.findIndex((e) => e._id === chatStatus?._id);

        // if chat is not found don't re-render
        if (index === -1) return prev;

        prev.splice(index, 1);

        return [...prev];
      });
    };
  
    // When a new notification is received, update the conversation list last message on the lists that contain lastMessageData such as 'My Conv'
    const onNotificationsUpdate = (payload) => {
      console.log(`Events Socket - ConsoleConversation - (notifications-update)`, payload);

      // moved away from map approach as this method is more performant and prevents crashes when prev is not an array
      props.setErrands((prev) => {

        const chatObj = prev.find((e) => e._id === payload?.data?.chat?._id || e._id === payload?.data?.messageId?.chat);

        if (chatObj) {
          let operatorArr = getOperatorParticipants(chatObj, iUserData, true);
          let assistedBy;
          
          
          if (ValidatorFunctions.isNotEmptyArray(operatorArr)) {
            assistedBy = t('conversationListAssistedBy');
            operatorArr.forEach(
              (op) => (assistedBy += `${op?.userData?.firstname || ''} ${op?.userData?.lastname || ''}, `)
            );
            assistedBy = assistedBy?.slice(0, -2)?.trim();
          }
  
          chatObj.assistedBy = assistedBy || t('conversationListAssistedBy') + ' ' + t('conversationListNone');
          chatObj.lastMessageData = payload?.data?.messageId;
          chatObj.updatedAt = payload?.data?.messageId?.updatedAt;
        }

        return [...prev];
      });
    };

    // in here we can add listeners that can update the attributes on the individual conversation badge component such as online status, user's name, participants
    console.log('Events Socket - ConsoleConversation - (on)');
    eventsSocket.current?.on('chat-status-closed', onChatStatusClosed);
    eventsSocket.current?.on('notifications-update', onNotificationsUpdate);
    return () => {
      console.log('Events Socket - ConsoleConversation - (off)');
      eventsSocket.current?.off('chat-status-closed', onChatStatusClosed);
      eventsSocket.current?.off('notifications-update', onNotificationsUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEventsConnected]);

  // chat state management
  useEffect(() => {
    if (!isMessagesConnected) return;
    /**
     * Callback function called when a participant is removed from a chat.
     *
     * @param {Object} payload - The payload object received from the socket event.
     */
    const onRemoveParticipant = (payload) => {
      try {
        console.log(
          `Messages Socket - ConsoleConversation - (remove-participant) ${payload?.data?.chat || ''}`,
          payload
        );

        // store the participant data in a variable
        const removeData = payload?.data;
        const participantId = removeData?._id;
        const userId = removeData?.userId;
        const chatId = removeData?.chat;

        // check if the removeData is null or undefined
        if (!removeData) {
          console.info('(remove-participant) message is null', payload);
          return;
        }

        props.setErrands((prev: IErrand[]) => {
          const chatObj = prev.find((e) => e._id === chatId);

          if (chatObj === undefined) return prev;

          const participantObj = chatObj.participants.find((p) => p._id === participantId);

          if (participantObj === undefined) return prev;

          // remove the index from the list
          if (userId === iUserData._id) {
            // remove chatObj fron errands array
            prev = prev.filter((item) => item !== chatObj);
          } else if (
            chatObj.type === ChatType.Team ||
            chatObj.type === ChatType.Group ||
            (iUserData && getPrimaryParticipant(chatObj) === userId)
          ) {
            // update participant data
            participantObj.active = false;
            chatObj.showInactive = shouldShowInactive(chatObj, iUserData);
            chatObj.displayString = getOperatorDisplayString(chatObj, iUserData);
          } else {
            // remove user from participants array
            chatObj.participants = chatObj.participants.filter((item) => item !== participantObj);
          }

          // deep cloning to trigger re-render for all object sub-field
          return JSON.parse(JSON.stringify(prev));
        });
      } catch (err) {
        console.log(`ConsoleConversation.tsx - onRemoveParticipant(): ${err.message}`);
      }
    };

    // register to receive real time pariticipant activity on this chat
    console.log('Messages Socket - ConsoleConversation - (on)');
    messagesSocket.current?.on('remove-participant', onRemoveParticipant);
    return () => {
      console.log('Messages Socket - ConsoleConversation - (off)');
      messagesSocket.current?.off('remove-participant', onRemoveParticipant);
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected]);

  // notification state management
  useEffect(() => {
    if (!props.notifications) return;

    // if no errand or chat selected ---> noneed to clear any notifications
    if (ValidatorFunctions.isUndefinedOrNull(props.errand?._id)) return;

    // get all read notifications.
    const readNotifications = props.notifications.filter((x) => [x.chat?._id, x.messageId?.chat].includes(props.errand?._id));
    
    // prevent infinite loops by checking if there are read notifications.
    if (readNotifications?.length > 0) {
      console.log('Removing notifications: ', readNotifications);

      /**
       * Clears the notification by setting its status to 'read' using an Axios call.
       * @param {string} opId - The objectId of the operator.
       * @param {string} id - The objectId of the notification to be cleared.
       */
      async function clearNotification(opId, id) {
        await axiosCall({
          url: `operator/${opId}/notification/${id}`,
          method: 'put',
          data: {
            status: 'read',
          },
        });
      }

      // for each read notification, update its status to 'read'.
      for (const notification of readNotifications) {
        clearNotification(iUserData?._id, notification?._id);
      }

      // remove the corresponding notifications from the UI.
      props.setNotifications((prev) => prev.filter((x) => ![x.chat?._id, x.messageId?.chat].includes(props.errand?._id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notifications, props.errand?._id]);

  // TODO: Performance can be imporoved by convert MUI div rendering components to symantic html with static css
  return (
    <ErrorBoundary debug={`./src/Components/ConsoleConversation.tsx`}>
      <Stack sx={{ width: '100%', minWidth: '525px' }} direction='row' spacing={2}>
        {!props.errand ? (
          <Stack
            sx={{
            alignItems: 'center',
            backgroundColor: 'var(--gray010)',
            borderRadius: '8px',
            boxShadow: '0px 0px 0px 1px var(--gray040)',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          }}>
            <Paper sx={{ p: 1.5 }}>
              <Typography>{t('consoleConversationsSelectText')}</Typography>
            </Paper>
          </Stack>
        ) : (
          <Box sx={{ width: '100%', height: '100%' }} ref={dropRef}>
            <Conversation
              operatorView={true}
              isDesktop={true}
              operatorData={iUserData}
              onlineOperators={props.onlineOperators}
              onlineUsers={props.onlineUsers}
              errand={props.errand}
              errands={props.errands}
              setErrands={props.setErrands}
              notifications={props.notification}
              setNotifications={props.setNotifications}
              setShowConfirmResendDialog={setShowConfirmResendDialog}
              showConfirmResendDialog={showConfirmResendDialog}
            />
          </Box>
        )}
      </Stack>
      {(!location.state?.tab || location.state?.tab === 'mine') && props.errand && (
        <Card
          sx={{
            width: '300px',
            minWidth: '300px',
            display: 'flex',
          }}>
          <ActionList
            operatorData={iUserData?.firstname}
            onDropActionWorkflow={handleActionWorkflow}
            chatId={props.errand._id}
            errand={props.errand}
            setErrands={props.setErrands}
            chatFields={chatFields}
            setChatFields={setChatFields}
          />
        </Card>
      )}
      {showConfirmResendDialog.open && (
        <ConfirmResendWorkflowDialog
          open={showConfirmResendDialog.open}
          onClose={() => {
            /**
             * On closing/canceling the resend dialog we want to unset the states related to 
             * this specific call (ID, chat, type, recicpients), set open to false so that the 
             * dialog is closed, and finally set check to true so that the next time a workflow
             * is sent we are checking for duplicates.
             */
            setShowConfirmResendDialog({
              open: false,
              chat: undefined,
              type: undefined,
              id: undefined,
              recipients: [],
              check: true,
            });
          }}
          onResend={async () => {
            try {
              let id = showConfirmResendDialog.id;
              let type = showConfirmResendDialog.type;
              let chatId = showConfirmResendDialog.chat;
              if (!id || !type || !chatId) {
                console.error('Missing data to submit action or workflow: ', {id, type, chatId});
                return;
              }
              if (type === 'action') {
                await sendAction(
                  id,
                  '',
                  chatId,
                  recipientsRef.current || [],
                  AccessType.public,
                  iUserData?._id,
                  true,
                  null
                );
                recipientsRef.current = [];
              } else if (type === 'workflow') {
                await sendWorkflow(
                  id,
                  '',
                  chatId,
                  recipientsRef.current || [],
                  AccessType.public,
                  iUserData?._id,
                  true,
                  null
                );
                recipientsRef.current = [];
              }
            } catch (error) {
              console.error(`Could not send action or workflow`, error.stack);
            } finally {
              setShowConfirmResendDialog({
                open: false,
                chat: undefined,
                type: undefined,
                id: undefined,
                recipients: [],
                check: true,
              });
            }
          }}
        />
      )}
      {userDialogData !== '' && (
        <UserDialog
          errand={props.errand}
          open={userDialogData !== ''}
          onClose={() => {
            setUserDialogData('');
          }}
          handleSubmit={async (recipients) => {
            let id = userDialogData.split('<>')[0];
            let type = userDialogData.split('<>')[1];
            let chatId = userDialogData.split('<>')[2];

            // set recipients so that the intended users will recieve the action after confirm resend
            recipientsRef.current = recipients;
            let willShowConfirmResendDialog;

            if (type !== null && type !== undefined && type !== '') {
              if (type === 'action') {
                willShowConfirmResendDialog = await sendAction(
                  id,
                  '',
                  chatId,
                  recipients,
                  AccessType.public,
                  iUserData?._id,
                  true,
                  showConfirmResendDialog.check
                )
              } else if (type === 'workflow') {
                willShowConfirmResendDialog = await sendWorkflow(
                  id,
                  '',
                  chatId,
                  recipients,
                  AccessType.public,
                  iUserData?._id,
                  true,
                  showConfirmResendDialog.check
                )
              }
              setShowConfirmResendDialog(willShowConfirmResendDialog);
              // reset recipients if we aren't confirming resend
              if (!willShowConfirmResendDialog.open && recipients?.length > 0) {
                recipientsRef.current = [];
              }
            }
            setUserDialogData('');
          }}
        />
      )}
    </ErrorBoundary>
  );
};

export default ConsoleConversation;
