import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react';
import useAbortController from '@common/hooks/useAbortController';
import Styles from '@styles/MorphVideoListMenu.module.css';
import { useFooterContext } from '@contexts/FooterContext';
import { FreeMode, Mousewheel, Keyboard } from 'swiper';
import { PlayButton } from '@assets/Icons';
import axiosCall from '@services/axios';
import { VideoListMenuState } from './MorphVideoListMenu/types';
import { useRootContext } from '@contexts/RootContext';
import { isMobile } from '@common/deviceTypeHelper';
import { IVideo } from '@interfaces/Conversation';
import { MorphType } from '@common/MorphType';
import { SongPlayerPlay } from '@assets/Icons/index';
import { Arrows } from './MorphVideoListMenu/Arrows';


const fetchVideos = async (abortController) => {
  try {
    const config = abortController.get();
    // This playlist id is hard-coded for now, but will change based on
    // the user's user role in the future
    let playlistId = 'PL7eTBtWhXVySst9hg9zEeWdOHfYQuX-uK';
    let videos = await axiosCall(
      {
        url: `url/youtube/playlist/${playlistId}`,
      },
      config
    );
    return videos.youtubePlaylist;
  } catch (e) {
    return e;
  }
};

const SlideIcon = (props) => {
  return props.thumbnail ? (
    <>
      <img
        src={props.thumbnail}
        style={{
          display: 'block',
          width: 'auto',
          height: '100%',
          borderRadius: '6px',
          filter: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
        }}
        alt="YouTube Thumbnail"
      />
      <div
        style={{
          border: '1px solid var(--orange700)',
          backgroundColor: 'var(--gray000)',
          borderRadius: '50%',
          height: '20px',
          width: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '-7px',
        }}>
        <SongPlayerPlay style={{ height: '15px', width: '15px' }} />
      </div>

    </>
  ) : (
    <PlayButton style={{ color: 'var(--gray000)', width: '100%', height: '100%' }} />
  );
};

const SingleSlideElement = (props) => {
  const currentSlideData = useSwiperSlide();

  return (
    <div
      style={{
        height: '45px',
        display: 'flex',
        flexDirection: 'column',
        border: props.active ? '2px solid green' : '1px solid var(--orange900)',
        justifyContent: 'center',
        transition: 'all 0.3s ease',
        backgroundColor: props.isSelected ? 'var(--blue300)' : '',
        alignItems: 'center',
        borderRadius: '8px',
      }}
      className={`${Styles.SingleSlideElement} ${props.active ? Styles.activeSlide : ''}`}
    >
      {/* Icon */}
      {<SlideIcon thumbnail={props.thumbnail} />}
    </div>
  );
};

type TVideoData = {
  videoId: string;
  description: string;
  title: string;
  thumbnail: string;
};

const SWIPER_SLIDE_WIDTH = 90;
const SPACE_BETWEEN_SLIDES = 15;
const SWIPER_SLIDE_LOADING_WIDTH = 100;

const MorphVideoListMenu = (props) => {
  const errandContext = useContext<IErrandContext>(ErrandContext);
  const footerContext = useFooterContext();
  const abortController = useAbortController();
  const [slideListItems, setSlideListItems] = useState<TVideoData[]>([]);
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const swiperSectionRef = useRef(null);
  const tmsRef = useRef<NodeJS.Timeout[]>([]);
  const rootContext = useRootContext();
  const activeSlideIndexRef = useRef(null);
  const [hasMounted, setHasMounted] = useState(false);
  const isSomeVideoSelected = selectedIdx !== null;

  const updateRootUserSelectedVideoRef = (data: IVideo | null) => {
    rootContext.userSelectedVideoRef.current = data;
  };

  const resetState = () => {
    errandContext.setVideoListMenuState(VideoListMenuState.VIDEO_NOT_SELECTED);
    footerContext.sendButtonRef.current?.update('');
    updateRootUserSelectedVideoRef(null);
    setSelectedIdx(null);
  };

  const clearTimeouts = () => {
    for (const tID of tmsRef.current) {
      clearTimeout(tID);
    }
  }

  const onSingleSlideClickHandler = () => {
      return footerContext.sendButtonRef.current?.handleSubmit();
  };

  const renderSlideList = () => {
    return isLoading || slideListItems.length === 0 ? (
      <SwiperSlide style={{ width: `${SWIPER_SLIDE_LOADING_WIDTH}px`, height: 'fit-content' }}>
        <SingleSlideElement isLoadingElement={true} isSelected={false} name={'Loading...'} />
      </SwiperSlide>
    ) : Array.isArray(slideListItems) && slideListItems.length > 0 && (
      slideListItems.map((curr, idx) => (
        <SwiperSlide
          key={curr.title}
          onClick={(e) => onSingleSlideClickHandler()}
          style={{ width: '90px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
        >{({ isActive }) => {
          if (isActive) {
            // If we changed the active slide, we store it in a ref and
            // update all the contexts
            if (activeSlideIndexRef.current !== idx) {
              activeSlideIndexRef.current = idx;
              // prepare new root video info ref data
              const newVideoData = {
                videoId: curr.videoId,
                title: curr.title,
                thumbnail: curr.thumbnail,
                description: curr.description
              } as IVideo;
              errandContext.setVideoListMenuState(VideoListMenuState.VIDEO_SELECTED);
              rootContext.setVideoMenuTitle(curr.title);
              // update rootContext videoInfo
              updateRootUserSelectedVideoRef(newVideoData);
              footerContext.sendButtonRef.current?.update(curr?.title);
            }
          }
          return (<SingleSlideElement {...curr} active={isActive} isSelected={selectedIdx === idx} />)
        }}
        </SwiperSlide>
      ))
    );
  };

  // initial fetch
  useEffect(() => {
    setIsLoading((prev) => true);
    errandContext.setVideoListMenuState(VideoListMenuState.VIDEO_FETCH_LOADING);
    fetchVideos(abortController)
      .then((res) => {
        setSlideListItems(res);
      })
      .catch((err) => {
        console.error('Error occured while fetching video data', err);
        // show error message in placeholder
        errandContext.setVideoListMenuState(VideoListMenuState.VIDEO_FETCH_ERROR);
        tmsRef.current.push(
          setTimeout(() => {
            errandContext.setMorphType(MorphType.None);
            resetState();
          }, 2000) // 2 seconds
        );
        })
      .finally(() => {
        setIsLoading((prev) => false);
      });
    setHasMounted(true);
  }, []);

  // Automatic navigation to middle element in swiper
  const navigateToMiddleElement = useCallback(() => {

    if (swiperInstance === null) return;
    if (slideListItems.length < 3) return;

    let middleIdx = 3;
        // >= 3
    // is mobile

    if(isMobile() === true) {
      middleIdx = 1;
    } else {
      const SwiperWidth = swiperSectionRef.current.getBoundingClientRect().width;
      // divide by SWIPER_SLIDE_WIDTH
      // we get ceil (and not floor) because we allow the first element to be a bit cut off
      // 8 is margin rights
      let actualFittingElements = SwiperWidth / (SWIPER_SLIDE_WIDTH + SPACE_BETWEEN_SLIDES);
      let actualFittingElementsWhole = Math.ceil(actualFittingElements);
      // if the last element can be fit in for at least 0.5, leave it cut off, else make sure that it is fully visible.
      let corrector = 1


      middleIdx = Math.ceil(actualFittingElementsWhole / 2) - corrector;

    }

    rootContext.setVideoMenuTitle(slideListItems[middleIdx].title);
    swiperInstance.slideTo(middleIdx);
  }, [swiperInstance, slideListItems.length]);

  useEffect(() => {
    navigateToMiddleElement();
  }, [swiperInstance, slideListItems.length])

  // Unmount
  useEffect(() => {
    return () => {
      clearTimeouts();
      abortController.abort();
      resetState();
    };
  }, []);

  const mainWrapperClass = `${Styles.wrapper} ${isLoading ? Styles.loadingWrapper : ''}`
  return (
    <>
      <section className={mainWrapperClass} ref={swiperSectionRef}>
        <Arrows
          onSingleSlideClickHandler={onSingleSlideClickHandler}
          swiperInstance={swiperInstance}
          selectedIdx={selectedIdx}
          hasMounted={hasMounted}
          isLoading={isLoading}
        />
        <Swiper
          onSwiper={setSwiperInstance}
          style={{
            minWidth: '35vw',
            overflowX: 'hidden',
            overflowY: 'hidden',
            height: `${slideListItems.length === 0 || isLoading ? '80px' : isSomeVideoSelected ? '24px' : '80px'}`,
            opacity: `${isSomeVideoSelected ? '0' : '1'}`,           
            marginTop: '-10px',
            padding: '10px 0px 15px 0px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className={Styles.swiperContainer}
          keyboard={{
            enabled: true,
          }}
          spaceBetween={11}
          centeredSlides={true}
          slidesPerView={'auto'}
          mousewheel={true}
          modules={[FreeMode, Mousewheel, Keyboard]}
          slideToClickedSlide={true}
        >
          {renderSlideList()}
        </Swiper>
      </section>
      <div
        aria-hidden="true"
        className={
          Styles.bottomBorder +
          ' ' +
          (errandContext.isMorphedFooterCloseButtonOnLeft === true ? Styles.isMorphedFooterCloseButtonOnLeft : '')
        }
      ></div>
    </>
  );
};

export default MorphVideoListMenu;