import React, { lazy, Suspense, useCallback } from "react";

import ErrorBoundary from "@components/ErrorBoundary";

import type { ComponentType, ReactNode } from "react";


const useLazyComponent = <ComponentProps,>(
  importFn: Promise<{ default: ComponentType<ComponentProps>; }>,
  fallbackComponent: ReactNode,
  errorMessage: string,
  ref ?: any
) => {
  const Component = lazy(() => importFn);

  return useCallback((props: ComponentProps) => (
    <ErrorBoundary debug={errorMessage}>
      <Suspense fallback={fallbackComponent}>
        {/**
         * required to pass generics through the hook to the component props
         * and ensure type safety of returned component
         */
        
         /* @ts-ignore */}
        <Component ref={ref} {...props} />
      </Suspense>
    </ErrorBoundary> 

  /**
   * Should be only done once at component mount
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [])
}

export default useLazyComponent;