/**
 * @file This component is a stylized Box component
 * for styling the operator login page.
 *
 * @author Maverick Manasco <maverick.manasco@swmc.com>
 */

import { Box, styled } from '@mui/system';

const LoginWrapper = styled(Box)(
  ({ theme }) => `
  background: linear-gradient(135deg, var(--gray000), var(--gray050));
  position: relative;
  overflow: hidden;
  z-index: 0;
  & section {
    display: flex;
    height: 100vh;
    flex-direction: row;
  }
  & .languagePicker {
    position: fixed;
    top: .5em;
    right: .5em;
    z-index: 5;
  }
 `
);

export default LoginWrapper;
