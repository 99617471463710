import React from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';

/*
 *  This component makes up the circular graph seen in the top right most corner
 * of the monitoring page within the dashboard.
 *
 *  This component uses the following props:
 *    - value: this is a value from 0-100 representing a percentage.
 */

const MoodMeter = (props: { value: number; }) => {
  const options : ApexOptions = {
    colors: ['var(--green110)'],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '60%',
          background: 'var(--blue981)',
        },
        track: {
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            blur: 4,
            opacity: 0.15,
          },
        },
        dataLabels: {
          name: {
            offsetY: -6,
            color: 'var(--gray000)',
            fontSize: '12px',
          },
          value: {
            color: 'var(--gray000)',
            fontSize: '22px',
            show: true,
            offsetY: 6,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        gradientToColors: ['var(--blue120)'],
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['Mood Meter'],
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={[Math.round(props.value)]}
        type="radialBar"
        height={165}
      />
    </div>
  );
};
export default MoodMeter;
