import React from 'react';

/**
 * Scheduler
 * 
 * Scheduler is designed to conditionally render its children within a date range, based on the current date.
 * 
 * @param {Object} startDate - The start date for displaying children.
 * @param {Object} endDate - The end date for displaying children.
 * @param {React.ReactNode} children - The children components to render conditionally.
 * @returns The children components if within the date range, otherwise null.
 */

 interface SchedulerProps {
    startDate: { day: number, month: number }; // Month is 1-indexed (1-12)
    endDate: { day: number, month: number };
    children: React.ReactNode;
  }
  
 const Scheduler: React.FC<SchedulerProps> = ({ startDate, endDate, children }) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
  
    // Construct the full start and end date using the current year
    const adjustedStartDate = new Date(currentYear, startDate.month - 1, startDate.day);
    const adjustedEndDate = new Date(currentYear, endDate.month - 1, endDate.day);
  
    // Check if the current date is within the start and end dates (inclusive)
    if (currentDate >= adjustedStartDate && currentDate <= adjustedEndDate) {
      return <>{children}</>;
    } else {
      return null;
    }
  };

export default Scheduler;