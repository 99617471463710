/**
 * @file This component is a stylized Box component
 * for styling the Song of the Day Message Dialog.
 *
 * @author Harrison Fales <harrison.fales@swmc.com>
 * 
 */

import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';

export const MessageContent = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'smallScreen' &&
    prop !== 'mediumScreen'
})<any>(
  ({ theme, smallScreen, mediumScreen }) => `
    display: flex;
    width: fit-content;
    max-width: min(90%, calc(100vw - 50px));
    min-width: 85px;
    .chatBubbleStyle {
      display: flex;
      flex-wrap: multi-line;
      border: 1px solid;
      border-radius: 10px;
      box-shadow: var(--shadow100) 0px 5px 35px;
      position: relative;
      overflow: hidden;
      border-color: var(--orange700);
      background: var(--peach600);
      .chatStack {
        display: flex;
        flex-direction: column;
        width: 100%;
        .dialog {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          position: relative;
          max-width: 500px;
          width: 100%;
          .recordSection {
            width: 20%;
            min-width: ${!smallScreen ? '110px' : '74px'};
          }
          .songDetails {
            position: relative;
            text-align: left;
            word-break: break-word;
            max-width: ${!mediumScreen ? '40%' : '35%'};
            margin-right: auto;
            .songDetailsStack {
              padding-top: 5px;
              font-family: var(--typographyFontFamily);
              justify-content: flex-end;
              .playSongContainer {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                position: relative;
                align-items: center;
                pointer-events: all;
              }
            }
          }
        }
        .urlStack {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          gap: 1;
          background-color: var(--orange700);
          background-image: ${theme.system.name === 'SUNWST000' ? `url(${WoodGrain})` : 'none'};
          border: none;
          padding: 3px;
          cursor: pointer;
          .contentCopyRoundedIcon {
            color: var(--gray000);
            height: .75em;
            width: .75em;
            pointer-events: all;
          }
          .urlAnchor {
            text-decoration: none;
            word-break: break-all;
          }
        }
      }
    }
  `
);

export const RecordHolder = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'smallScreen'
})<any>(
  ({ smallScreen }) => `
  height: ${!smallScreen ? '110px' : '74px'};
  width: ${!smallScreen ? '110px' : '74px'};
  background-color: var(--gray000);
  border: 1px solid orange;
  border-radius: 50%;
  padding-top: 3px;
  padding-left: 4px;
  position: relative;
  .record {
    height: ${!smallScreen ? '101px' : '65px'};
    width: ${!smallScreen ? '101px' : '65px'};
  }
  .recordArm {
    width: ${!smallScreen ? '121px' : '85px'};
    height: ${!smallScreen ? '121px' : '85px'};
    border: solid 5px var(--orange700);
    border-color: var(--orange700) transparent transparent transparent;
    border-radius: 50%/50% 50px 0 0 ;
    transform: rotate(90deg);
    right: -2px;
    top: ${!smallScreen ? '-8px' : '-6px'};
    position: absolute;
  }
  .recordNeedle {
    width: ${!smallScreen ? '10px' : '8px'};
    height: ${!smallScreen ? '10px' : '8px'};
    background-color: var(--orange700);
    position: absolute;
    border-radius: 50%;
    top: ${!smallScreen ? '7px' : '6px'};
    right: ${!smallScreen ? '12px' : '7px'};
  }
`);

export const SongHeader = styled(Typography)({
  fontSize: '12px',
  paddingLeft: '5px'
});

export const SongTitle = styled('p')({
  fontSize: '12px',
  fontWeight: '800',
  paddingLeft: '5px',
  display: '-webkit-box',
  webkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const SongArtist = styled(Typography)({
  fontSize: '10px',
  color: 'var(--orange050)',
  paddingLeft: '5px'
});

export const PlaySongButton = styled(Box)({
  backgroundColor: 'var(--orange700)',
  color: 'var(--gray000)',
  borderRadius: '50px',
  padding: '2px',
  width: '80px',
  fontSize: '12px',
  textAlign: 'center',
  cursor: 'pointer',
});

export const SmallSoundWave = styled(Box)({
  width: '50px',
  height: '50px',
  border: 'solid 5px var(--orange700)',
  borderColor: 'var(--orange700) transparent transparent transparent',
  borderRadius: '50%/50% 30px 0 0 ',
  transform: 'rotate(90deg)',
  marginLeft: '-40px',
  pointerEvents: 'none',
});

export const LargeSoundWave = styled(Box)({
  width: '70px',
  height: '70px',
  border: 'solid 5px var(--orange700)',
  borderColor: 'var(--orange700) transparent transparent transparent',
  borderRadius: '50%/50% 30px 0 0 ',
  transform: 'rotate(90deg)',
  marginLeft: '-55px',
  pointerEvents: 'none',
});

export const LoaderHolder = styled(Box, {
  shouldForwardProp: (prop) =>
  prop !== 'smallScreen'
})<any>(
  ({ smallScreen }) => `
  position: relative;
  width: ${!smallScreen ? '30%' : '25%'};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  `);

export const ThumbnailHolder = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'thumbnail' && prop !== 'smallScreen',
})<any>(
  ({ thumbnail, smallScreen }) => `
  position: relative;
  width: ${!smallScreen ? '30%' : '25%'};
  height: 100%;
  background: url(${thumbnail}) no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: all;
`);

export const BrokenImageContainer = styled(Box)({
  position: 'relative',
  width: '30%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});