import React, { CSSProperties } from 'react';
import { MorphType } from '@common/MorphType';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

const regularTextStyle: CSSProperties = {
  fontSize: '0.75em',
  paddingLeft: '42px',
  paddingRight: '24px',
  paddingBottom: '5px',
  textAlign: 'left',
  width: '100%',
};

const listTextStyle: CSSProperties = {
  fontSize: '0.75em',
  paddingLeft: '6px',
  paddingRight: '24px',
  paddingBottom: '5px',
  textAlign: 'left',
  width: '100%',
};

const dateStyleWrapper = {
  height: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  padding: '10px',
  paddingBottom: '32px',
  borderRadius: '5px',
  // Add more styles as needed
};

const headerStyle: CSSProperties = {
  padding: '12px',
  margin: 0,
  textAlign: 'center',
  width: '100%',
};

const listItemStyle: CSSProperties = {
  paddingBottom: '5px',
  marginLeft: '36px',
};

const signatureStyleWrapper: CSSProperties = {
  height: '100%',
  width: '100%',
};

const StyledSignatureButton = styled(Button)({
  height: '100%',
  width: '100%',

  background: 'var(--gray040)',
  color: 'black',
  borderColor: 'var(--blue963)',
  border: '3px dotted',
  '&:hover': {
    backgroundColor: 'var(--gray000)',
    color: 'black',
    borderColor: 'var(--blue963)',
    border: '3px dotted',
  },
});

const formWrapper: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'left',
  width: '100%',
};

const buttonStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'none',
  color: 'var(--gray000)',
};

enum EsignSignatureType {
  Signature,
  Initials,
}

enum EsignFormType {
  AISIGN = 'AISIGN',
  ECONSENT = 'E-CONSENT',
  ECONSENT_1 = 'ECONSENT',
  ECONSENT_2 = 'econsent',
  ECONSENT_3 = 'E-Consent',
  BCERTA = 'BCERTA',
  BCERTA_DESCRIPTION = 'Borrower Certification Form',
}

/**
 * We have different form names everywhere so this tiny function will work as a map
 */
const FormTypeDetector = (input: string): EsignFormType => {
  if ((input || '').includes('TEMPLATE')){
    return EsignFormType.AISIGN
  }
  switch (input) {
    case EsignFormType.ECONSENT:
    case EsignFormType.ECONSENT_1:
    case EsignFormType.ECONSENT_2:
    case EsignFormType.ECONSENT_3:   
      return EsignFormType.ECONSENT;
    case EsignFormType.BCERTA:
    case EsignFormType.BCERTA_DESCRIPTION:
      return EsignFormType.BCERTA;
    default:
      // Default value ECONSENT
      return EsignFormType.ECONSENT;
  }
}

enum EsignActionStateType {
  Unsigned,
  Signing,
  Signed,

  Downloading,

  Failed,
}

enum FormBodyType {
  None,
  CreateSignatureDesktop,
  CreateSignatureMobile,
  InsertSignatureDefault,
  InsertSignatureReadyToSendDocument,
}

enum FormBodyScrollStateType {
  Start,
  Middle,
  End,
  StartEditSignature,
}

enum FormBodySizeType {
  None,
  Small,
  Full,
}

enum FormBottomButtonType {
  None,
  ArrowUp,
  ArrowDown,
  Scroller,
  Rotate,
}

enum ElectronicSignatureEventType {
  ArrowUp,
  ArrowDown,
  FormJustOpened,
  EditSign,
  GoToInsertSignatureView,
  ReadyToSendDocument,
  OpenCreateSignatureMobile,
  CloseCreateSignatureMobile,
}

enum FormClickEventType {
  NavPrev = 'NavPrev',
  NavNext = 'NavNext',
  SigAutoGen = 'SigAutoGen',
  SigClear = 'SigClear',
  FormDownload = 'FormDownload',
  FormSign = 'FormSign',
}

interface IElectronicSignatureState {
  body: FormBodyType;
  bodySize: FormBodySizeType;
  morph: MorphType;
  bottomButton: FormBottomButtonType;
}

interface IPaymentState {
  body: FormBodyType;
  bodySize: FormBodySizeType;
  morph: MorphType;
  bottomButton: FormBottomButtonType;
}

enum PaymentClickEventType {
  InitiatePaymentClick = 'InitiatePaymentClick',
  NavNext = 'NavNext',
  Cancel = 'Cancel',
  PayNow = 'PayNow',
}

enum PaymentActionStateType {
  Preview,
  ViewReceipt,
  InputInfo,
  VerifyingInfo
}

export {
  dateStyleWrapper,
  regularTextStyle,
  listTextStyle,
  headerStyle,
  listItemStyle,
  signatureStyleWrapper,
  formWrapper,
  buttonStyle,
  EsignSignatureType,
  EsignFormType,
  EsignActionStateType,
  FormBodyType,
  FormBodyScrollStateType,
  FormBodySizeType,
  FormBottomButtonType,
  ElectronicSignatureEventType,
  StyledSignatureButton,
  FormClickEventType,
  PaymentClickEventType,
  PaymentActionStateType,
  FormTypeDetector,
};

export type { IElectronicSignatureState, IPaymentState };
