import React from 'react';
import { Divider, Typography }  from '@mui/material';
import { endDate, monitorContextType, selectedOperator, startDate } from '../Monitoring';
import GuestListTable from './GuestListTable';
import Header from './GuestInfoHeader';
import OperatorStats from './GuestInfoStats';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  width: 100%;
`;

const GuestInfo : React.FC<{}> = () => {
  const { t } = useTranslation();
  const contxtProps : monitorContextType = {startDate : startDate, endDate : endDate, operator : selectedOperator};
  return (
    <StyledDiv>
      <Header props={contxtProps}/>
      <OperatorStats operatorData={selectedOperator} moodMeterValue={60} />
        <Typography sx={{fontsize: 18}} align="left" fontWeight="bold">{t('guestChatList')}</Typography>
        <Divider />
        <GuestListTable selectedOperator={selectedOperator} loading={true} />
    </StyledDiv>
  );
};
export default GuestInfo;
