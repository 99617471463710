import { useCallback, useEffect, useRef } from 'react';

// This class serves as a data window of wheel events (UP, DOWN)
// meaning that UP is whenever a curr_deltaY - previous_deltaY > 0 and DOWN otherwise.
// Also, it has an "another" swipe detection (for trackpad) which identifies the exact moment WHEN an additional trackpad swipe UP/DOWN was done DURING a stream of continuous 'wheel' events.
// It has a checking logic after EACH event push into the window, specifically it checks whether the first 4 events contain AT LEAST 2 DOWN type of events AND last two are UP, which means another scroll should be done on TRACKPAD.
class WindowArray {
  private maxSize: number;
  private array: number[];
  private swipeDetected: boolean;

  constructor(maxSize: number = 6) {
    this.maxSize = maxSize;
    this.array = [];
    this.swipeDetected = false;
  }

  push(element: number): void {
    if (this.array.length >= this.maxSize) {
      this.array.shift(); // Remove the first element
    }
    this.array.push(element); // Add the new element at the end

    if (this.array.slice(0, 4).filter((el) => el === 0).length >= 2 && this.array[4] === 1 && this.array[5] === 1) {
      this.swipeDetected = true;
    }
  }

  anotherSwipeDetected(): boolean {
    return this.swipeDetected;
  }

  reset(): void {
    this.resetSlope();
    this.clearArray();
  }

  resetSlope(): void {
    this.swipeDetected = false;
  }

  getArray(): number[] {
    return this.array;
  }

  clearArray(): void {
    this.array.length = 0;
  }
}

const useDebouncedScrollHandler = <T extends (...args: any[]) => void>(handler: T, delay: number): T => {
  const trackPadData = useRef({ window: new WindowArray() });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const actualScrollLockedTmRef = useRef(null);
  const actualScrollLockedRef = useRef(false);
  const handlerRef = useRef(handler);
  const prevDeltaYRef = useRef(null);
  const firstCallRef = useRef(true);

  // Update the ref each render so the debounced function has the latest handler
  handlerRef.current = handler;

  const checkForAnotherSwipeAndProcess = (e, handler) => {
    const prevDeltaY = Math.abs(Math.floor(prevDeltaYRef.current));
    const currDeltaY = Math.abs(Math.floor(e.deltaY));
    const difference = currDeltaY - prevDeltaY;
    if (difference > 0) {
      // if slope was detected meaning another trackpad swipe was detected
      if (trackPadData.current.window.anotherSwipeDetected() === true) {
        // clear out all trackpad data before next events chain
        trackPadData.current.window.reset();
        handler(e);
      }
      // 1 meaning diff is > 0
      trackPadData.current.window.push(1);
    } else if (difference < 0) {
      // 0 meaning diff is < 0
      trackPadData.current.window.push(0);
    }
    // save prev deltaY value for future comparison with current value
    prevDeltaYRef.current = currDeltaY;
    // if there is an on-going timeout
    if (timeoutRef.current) {
      // clear it
      clearTimeout(timeoutRef.current);
    }
    // Reset timeout, on completion reset window data as well.
    timeoutRef.current = setTimeout(() => {
      firstCallRef.current = true;
      prevDeltaYRef.current = null;
      trackPadData.current.window.reset();
    }, delay);
  };

  // Create the debounced function
  const debouncedHandler = useCallback(
    (...args: Parameters<T>) => {
      const [e, handleLongScroll, timeoutStorage] = args;
      const dir = e.deltaY < 0 ? 'down' : 'up';
      // Threshhold is 300 to separate mouse wheel scroll FROM trackpad swipes (scrolls).
      const threshHoldReached = Math.abs(e.deltaY) >= 300;
      // For mouse scroll wheel
      // if threshHold reached AND long scroll is not locked. (is not being executed currently)
      if (actualScrollLockedRef.current === true) {
        // clear the current timeout if there is an on-going one (to extend on each long scroll event)
        checkForAnotherSwipeAndProcess(e, handlerRef.current);
        // set another one after clearing
        if (actualScrollLockedTmRef.current) clearTimeout(actualScrollLockedTmRef.current);
        actualScrollLockedTmRef.current = setTimeout(() => {
          // unlock actual scroll
          timeoutStorage.clearAllTimeouts();
          actualScrollLockedRef.current = false;
        }, 200);
      }
      if (threshHoldReached) {
        // lock actual scroll processing (enter the long scroll processing phase)
        actualScrollLockedRef.current = true;
        // calculate the number of scroll options to skip (scroll over at once)
        // basically if user scrolls the mouse wheel really a lot, it will handle the skipping of needed elements.
        const optionsToScrollOver = Math.ceil(Math.abs(e.deltaY) / 500);
        // Main handling long scroll logic.
        setTimeout(handleLongScroll(optionsToScrollOver, dir), 0);
      } else {
        // TRACKPAD handling logic
        // if the first time call (for trackpad)
        if (actualScrollLockedRef.current === false) {
          if (firstCallRef.current === true) {
            // call the main handler that was passed
            handlerRef.current(e);
            firstCallRef.current = false;
            // set timeout to mark as another first time
            timeoutRef.current = setTimeout(() => {
              firstCallRef.current = true;
            }, delay);
          }
          // if subseaquent call (on trackpad) meaning if another swipe was detected
          // during the time when its still locked (firstCallRef.current === false)
          else {
            checkForAnotherSwipeAndProcess(e, handlerRef.current);
          }
        }
      }
    },
    [delay]
  );

  // Cleanup the debounced function on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedHandler as T;
};

export default useDebouncedScrollHandler;
