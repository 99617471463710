// This creates the shimmer animation effect on the skeletons
// (a thick white strip that runs across)
import React from 'react';
import { styled } from '@mui/system';

interface ShimmerEffectProps {
    color: string;
    width?: string;
    position?: string;
}

const ShimmerEffect = ({ color, width = '50%', position = 'relative' }: ShimmerEffectProps) => {
    const ShimmerWrapper = styled('div')`
        position: ${position};
        width: 100%;
        height: 100%;
        animation: loading 2.5s infinite;

        @keyframes loading {
            0% {transform: translateX(-150%);}
            50% {transform: translateX(-60%);}
            100% {transform: translateX(150%);}
        }
    `;

    const Shimmer = styled('div')`
        width: ${width};
        height: 100%;
        background-color: ${color};
        transform: skewX(-20deg);
    `;

    return (
        <ShimmerWrapper>
            <Shimmer />
        </ShimmerWrapper>
    );
};

export default ShimmerEffect;


