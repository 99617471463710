import { Badge, Avatar as MuiAvatar, useTheme } from '@mui/material';
import React, { memo } from 'react';

import { getStatusColors } from '../Common/StatusColors';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(Badge)(({ badgecolor }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .MuiBadge-badge': {
    backgroundColor: badgecolor,
    color: badgecolor,
  },
}));

const Avatar = (props) => {
  const theme = useTheme();
  const { status, alt, img, sx, ...others } = props;
  const color = getStatusColors(props.status, theme).main;

  let stringToColor = (string, saturation = 80, lightness = 50) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
    }
    return `hsl(${hash % 360}, ${saturation}%, ${lightness + (hash % 15)}%)`;
  };

  return (
    <StyledBadge
      {...others}
      sx={{ ...sx }}
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgecolor={color}
      variant="dot"
      badgeContent=" "
      invisible={!props.status}>
      {(img && img.length) > 0 ? (
        <>
          <MuiAvatar sx={{ ...sx, bgcolor: 'var(--gray000)' }} src={img} />
        </>
      ) : (
        <MuiAvatar sx={{ ...sx, bgcolor: stringToColor(alt) }}>{alt[0].toUpperCase()}</MuiAvatar>
      )}
    </StyledBadge>
  );
};

export default memo(Avatar);
