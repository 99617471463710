import { NumericFormat, PatternFormat } from 'react-number-format';
import { useSocketContext } from '@contexts/socket';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useEffect, useRef, PropsWithChildren, useImperativeHandle, useMemo } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import useDebounce from '@common/hooks/useDebounce';
import { useErrandContext } from '@contexts/ErrandContext';
import { useFooterContext } from '@contexts/FooterContext';
import { useUserContext } from '@contexts/user';
import FooterInput, { InputState } from './FooterInputStorage/storage';
import { MorphType } from '@common/MorphType';
import { StyledInputBase } from '../Styles/ChatInputFieldStyle';
import axiosCall from '../Services/axios';
import handleFile from '../Common/handleFile';
import { useHasMounted } from '@common/hooks/useHasMounted';
import { getAiOperatorIds, getAudienceString, isActionPasswordRelated } from '@common/msgUtils';
import { getLastMsg, isMessageOfTypeAction, isUserActionLastInChat } from '@common/userMessagesUtils';
import { FormBodyType } from '../Forms/commonForms';
import OTPInput from './OTPInput';
import { useMorphContext } from '@contexts/MorphContext';
import { getUserConsent } from '@storage/userStorage';
import eventBus from '@common/eventBus';
import Tooltip from '@mui/material/Tooltip';
import Styles from '@styles/ChatInputField.module.css';
import { ValidatorFunctions } from '@common/Validators';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import { Popper } from '@mui/material';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { Event as WalletEvent } from '@components/MorphWallet/MorphWalletUtils';

const { isTypeOfString } = ValidatorFunctions;

const allowedKeys = new Set(['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End', 'Enter', 'Control', 'Meta', 'Shift', ' ', '!', '"', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '-', '.', '/', ':', '@', '\'', '-', '_']);

const addressFields = ['addressLine1', 'addressLine2', 'city', 'state', 'country', 'postalCode'];

// User type delay for backend request
const TYPE_DEBOUNCE_MS = 200;

const ChatInputField: React.FC<PropsWithChildren<any>> = (props) => {
  const errandContext = useErrandContext();
  const { t } = useTranslation();
  const { messagesSocket } = useSocketContext();
  const { _id } = useUserContext();
  const footerContext = useFooterContext();
  const contactContext = useMorphContext();
  const [formattedValue, setFormattedValue] = useState('');
  const [isWarningActive, setIsWarningActive] = useState(false);
  const [isPercent, setIsPercent] = useState(true); //used only for the percentage-dollar amount field attribute
  const [unformattedValue, setUnformattedValue] = useState('');
  const [value, setValue] = useState('');
  // google places auto completion option value array
  const [options, setOptions] = useState([]);
  const typing = useRef<boolean>(false);
  // For footer input storage proper work
  const hasMounted = useHasMounted();
  const { isDesktop } = useWindowDimensions();


  useImperativeHandle(footerContext.chatInputFieldRef, () => {
    return {
      formattedValue: formattedValue,
      unformattedValue: unformattedValue,
      update: (givenValue: string | InputState) => {
        // update local value states
        if(typeof givenValue === 'string') {
          setFormattedValue(givenValue);
          setUnformattedValue(givenValue);
          helperValue(givenValue);
        } 
        // InputState type
        else {
          try {
            const { 
              formattedValue,
              unformattedValue,
              value } = givenValue;
            if(isTypeOfString(formattedValue) 
              && isTypeOfString(unformattedValue) 
              && isTypeOfString(value)
            ) {
              setFormattedValue(formattedValue);
              setUnformattedValue(unformattedValue);
              helperValue(value);
            }
          } catch(err) {
            console.error("Error occured in [ChatInputField.tsx]: something wrong in chatInputFieldRef.current.update method call! Error: ", err);
            // set as empty
            setFormattedValue('');
            setUnformattedValue('');
            helperValue('');
          }
        }
      },
    };
  });

  // Sets the current participant's isTyping status to true if it is currently false
  // Starts a timer to revert the current participant's isTyping status when the timer ends
  // If the user types before the timer ends, the timer is clears and a new one is started
  const handleTyping = (errandId, participantId) => {
    if (!typing.current) {
      messagesSocket.current?.emit('emit-chat-event', {
        chatId: errandId,
        data: {
          participantId: participantId,
          type: 'typing',
          message: 'true',
          userId: _id,
          isPrivate: errandContext.morphType === MorphType.PrivateChat,
        },
        recipients: props.errand?.recipients?.length > 0 ? props.errand.recipients : props.isPublic ? [] : [`${errandId}Operators`],
      });

      typing.current = true;
    }
  };

  const helperValue = (newValue: string) => {
    // if new value is empty don't generate typing event
    if (newValue) {
      handleTyping(props.errand?._id, props.participantId);
    }

    setValue(newValue || '');
  }

  // Handles the current participant's typing activity along with the chatTimer useRef
  // sets isTyping to false in the provided chat
  const revertTyping = (errandId, participantId) => {
    // bug fix, value was undefined sometimes
    if (typing.current || value?.trim().length === 0) {
      messagesSocket.current?.emit('emit-chat-event', {
        chatId: errandId,
        data: {
          participantId: participantId,
          type: 'typing',
          message: 'false',
          userId: _id,
          isPrivate: errandContext.morphType === MorphType.PrivateChat,
        },
        recipients: null,
      });

      typing.current = false;
    }
  };

  const handleChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.honeyPot) {
      return;
    }

    if (errandContext.morphType === MorphType.Wallet) {
      // send the full chat box contents to the MorphWallet, so we can auto-delete words from the recovery phrase if they are accidentally typed
      window.dispatchEvent(new CustomEvent(WalletEvent.ChatInput, {
        detail: {
          input: e.target?.value
        }
      }));
    }

    const description = props.fieldAttribute?.description;
    const targetValue = e.target?.value;

    if (description === 'ADDRESS' && targetValue === '') {
      setOptions([]);
    } else if (description === 'BOOLEAN' || description === 'CONFIRM') {
      props.cancelAction(null);
    }

    // input validation cases that will cancel action
    switch (description) {
      case 'EMAIL':
        // if user types anything that doesn't match this regex, cancel action
        const regex = /^[a-zA-Z0-9!\"#$%&'()*+,-./:=@'_-]*$/;
        if (!regex.test(targetValue)) {
          e.preventDefault();
          props.cancelAction(null);
        }
    }

    setFormattedValue(targetValue);
    setUnformattedValue(targetValue);
    helperValue(targetValue);

    if (errandContext.morphType === MorphType.Contacts) return;

    footerContext.sendButtonRef.current?.update(targetValue || '');
  };

  const handleValueChangeWrapper = (e) => {
    if (props.honeyPot) {
      return;
    }

    if (props.fieldAttribute?.description === 'OTP') {
      return;
    }

    setFormattedValue(e?.formattedValue);
    setUnformattedValue(e?.value);
    helperValue(e?.formattedValue);
    if (errandContext.morphType === MorphType.Contacts) return;
    footerContext.sendButtonRef.current?.update(e?.value || '');
  };

  const handleFiles = (e) => {
    let files = handleFile(e.nativeEvent, props.errand?.action?.action?.fieldName === 'influencerPhotograph');
    if (typeof files === 'string') {
      setIsWarningActive(t(files));
    }
    if (files.length > 0){
      e.preventDefault();
      props.setSelectedFiles((prev) => {
        let uniqueFiles = [...prev, ...files].filter((value: File, index: number, array: File[]) => array.findIndex((v: File) => v === value) === index);
        return uniqueFiles;
      });
    }
  }

  /**
   * Event: Generated when input field text changes
   */
  const handleAutoCompletionValueChangeWrapper = (e) => {
    if (e?.target?.value === '') {
      // if textfield is empty reset option
      setOptions([]);
    }
    setFormattedValue(e?.target?.value);
    setUnformattedValue(e?.target?.value);
    helperValue(e?.target?.value);
    if (errandContext?.morphType === MorphType.Contacts) return
    footerContext.sendButtonRef.current?.update(e?.target?.value || '');
  };

  /**
   * Event: Generated when auto completion option selected from dropdown
   */
  const handleAutoCompletionSelectedWrapper = (event, input) => {
    // once recommendation is selected reset option
    setOptions([]);
    axiosCall({
      url: `google/place/details`,
      method: 'post',
      data: {
        place_id: options[event.target.dataset.optionIndex]?.id,
      },
    })
      .then((result) => {
        // Once the value exists iterate the fields in order and build the string.
        if (result) {
          // this variable should be revisited
          var addressString = [];
          for (const val of addressFields) {
            if (result[val]) {
              addressString.push(result[val]);
            }
          }
        }

        let selectedAddress: string = addressString.join(', ');
        props.selectedAddress.current = selectedAddress;
        setFormattedValue(selectedAddress);
        setUnformattedValue(selectedAddress);
        helperValue(selectedAddress);
      })
      .catch((error) => {
        console.log("google place details API doesn't work" + error.message);
      });
  };

  const handleInput = (e) => {
    // If user currently has a selected contact, trying to edit the input field will
    // automatically unselect the contact and revert the input field to what it was prior
    // to the selection
    if (props.honeyPot) {
      return;
    }

    const description = props.fieldAttribute?.description;
    const targetValue = e.target?.value;

    if(description === 'BOOLEAN'){
      if (
        !e.nativeEvent.isTrusted &&
        [...targetValue].every(char => /[yesnoYESNO]/.test(char))
      ) {
        setFormattedValue(targetValue)
        setUnformattedValue(targetValue)
        helperValue(targetValue);
      } else if (
        !/[yesnoYESNO]/.test(String.fromCharCode(e.keyCode))
      ) {
        e.preventDefault();
        props.cancelAction(e.key);
      } else {
        helperValue(formattedValue);
      }
    }
    else if (
      description === 'NUMBER' ||
      description === 'PHONE' ||
      description === 'MORPH PHONE' || 
      description === 'LOAN NUMBER' ||
      description === 'DOLLAR AMOUNT' ||
      description === 'MULTI SELECT'
    ) {
      // Autocomplete event has isTrusted property set to false
      // check for autocomplete and if autocomplete contents
      // satisfies the input requirements
      if (
        !e.nativeEvent.isTrusted &&
        [...targetValue].every(char => /[acvACV0-9]/.test(char))
      ) {
        setFormattedValue(targetValue)
        setUnformattedValue(targetValue)
        helperValue(targetValue);
      } else if (
        !isFinite(e.key) &&
        !allowedKeys.has(e.key) &&
        !/[acvACV0-9]/.test(String.fromCharCode(e.keyCode))
      ) {
        e.preventDefault();
        props.cancelAction(e.key);
      } else {
        helperValue(formattedValue);
      }
    }

    // Code removed due to incompatibility with Chrome keyboard on Android.
    // `e.key` always returned Unidentified and `e.keyCode` returned 229 for
    // all alphanumeric keys. Use of the onChange property has been opted for
    // instead. This allows for the entire input value to be validated rather
    // than validate on keyDown, which users can cirvumvent anyway. - Eric
    // if (
    //   !e.nativeEvent.isTrusted &&
    //   [...e.target.value].every(char => /[a-zA-Z0-9]/.test(char) || allowedKeys.has(char)) &&
    //   props.fieldAttribute?.description === 'EMAIL'
    // ) {
    //   setFormattedValue(e.target.value)
    //   setUnformattedValue(e.target.value)
    //   helperValue(e.target.value)
    // } else if (
    //   !allowedKeys.has(e.key) &&
    //   !/[a-zA-Z0-9]/.test(String.fromCharCode(e.keyCode)) &&
    //   props.fieldAttribute?.description === 'EMAIL'
    // ) {
    //   e.preventDefault();
    //   props.cancelAction(e.key);
    // }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!isMobileOrTablet()) {
        props.handleSubmit();
      } else {
        const input = errandContext.footerInputRef.current;
        if (input) {
          const start = input.selectionStart;
          const end = input.selectionEnd;
          const value = input.value;
          
          // Insert a newline character at the current cursor position
          const newValue = value.substring(0, start) + '\n' + value.substring(end);

          // Update the input value and adjust the cursor position
          input.value = newValue;
          setFormattedValue(newValue);
          setUnformattedValue(newValue);
          helperValue(newValue);

          input.selectionStart = input.selectionEnd = start + 1;

          // Check if the cursor is out of view
          const lineHeight = parseFloat(window.getComputedStyle(input).lineHeight);
          const rowsToShow = 4;
          const visibleHeight = lineHeight * rowsToShow; // Calculate the height of visible area
          const scrollTop = input.scrollTop;
          const cursorTop = lineHeight * (start + 1);

          // Calculate the position of the cursor relative to the visible area
          const cursorRelativeToVisible = cursorTop - scrollTop;

          // If the cursor position is below the visible area, scroll down
          if (cursorRelativeToVisible > visibleHeight) {
            input.scrollTop = cursorTop - visibleHeight;
          // If cursor is above the visible area, scroll up
          } else if (cursorRelativeToVisible < 0) { 
            input.scrollTop = cursorTop;
          }
        }
      }
    };
  }


  /**
   * debounced function that waits user inactivity, sends request to Morgan-Core
   */
  const handleDebounceFn = (errandId, participantId) => {
    updateInputStorage();
    revertTyping(errandId, participantId);
    props.setFilterName(footerContext.chatInputFieldRef?.current?.unformattedValue)
    if (props.fieldAttribute?.description === 'ADDRESS' && value) {
      if (value.length === 0) {
        setOptions([]);
      } else {
        axiosCall({
          url: `google/place/autocomplete`,
          method: 'post',
          data: {
            input: value,
          },
        })
          .then((result) => {
            setOptions(result);
          })
          .catch((error) => {
            console.log("google place autocomplete API doesn't work" + error.message);
          });
      }
    }
  };

  // Handles an update operation on the FooterInput storage. Called from within handleDebounceFn whenever user stops typing and a TYPE_DEBOUNCE_MS time passed.
  const updateInputStorage = () => {
    try {
      // Case where messages is undefined or null OR an empty array (still loading)
      if(!props.errand.messages || props.errand.messages.length === 0) return;

      // Case where component not yet mounted. (this ensures that this useEffect runs only after first render AND not during the first render.)
      if(!hasMounted) return;

      if(props.editMessageId) {
        // clear input for this chat in storage.
        FooterInput.clear(props.errand._id);
        return;
      }

      // check for current message action to be relevant to password
      if(props.errand?.action && props.errand?.action?.action) {
        const action = props.errand?.action?.action;
        const userActionId = props.errand.action?.userActionId;
        // if action is related to password
        if(isActionPasswordRelated(action)) {
          FooterInput.clear(props.errand._id);
          // clear input in storage
          // do not save anything
          return;
        }

        if(userActionId === undefined || userActionId === null) {
          console.warn("An action is set but it doesn't have any userActionId");
          // clear input in storage
          FooterInput.clear(props.errand._id);
          // do not save input.
          return;
        }
        // if action is not password related, then check if the last message in chat is an action
        // if so, do not save anything because if we switch to another chat while answering an action and this chat has the last
        // message to be an action as well, we don't need the input to be saved because it will always be the last action message that
        // changes the footer and puts the user in state of inputting that last action message.
        // THIS also covers an edge case where we try to answer the last action in chat.
        // This is done by checking if the current action we are answering is actually a last message in chat, 
        // thus in this case we SHOULD save an input in storage.
        if( isMessageOfTypeAction(getLastMsg(props.errand.messages)) 
          && isUserActionLastInChat(userActionId, props.errand.messages) === false) {
          FooterInput.clear(props.errand._id);
          return;
        }
      }

      // if not editing and mounted
      // sync with footer input storage
      FooterInput.save({ value, unformattedValue, formattedValue }, props.errand._id);
    } catch(error) {
      // clear the storage for this chat.
      FooterInput.clear(props.errand._id);
      console.error(error);
    }
  }

  const checkConsent = (e) => {
    if (getUserConsent() !== 'true' && props.operatorData === undefined){
      errandContext.footerInputRef.current?.blur();
      return eventBus.dispatch('showConsentContent');
    }
  }
  
  /**
   * Following function make sure that Morgan-Core endpoint won't get spam requests, basically this debounce
   * function delays request until user stops typing and pre-defined amount of time pass.
   */
  useDebounce(() => handleDebounceFn(props.errand?._id, props.participantId), TYPE_DEBOUNCE_MS, [value]);

  // Revert the typing status for this participant when errandId changes
  useEffect(() => {
    return () => {
      revertTyping(props.errand?._id, props.participantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errand?._id]);


  useEffect(() => {
    const contactName = footerContext.selectedContact?.displayName;
    if (contactName) {
      helperValue(contactName);
    }
  }, [footerContext.selectedContact]);

  useEffect(() => {
    const selectedBorrowersArr = Array.from(contactContext.selectedBorrowers);
    const borrowerNames = selectedBorrowersArr.map((borrower) => `${borrower.firstName} ${borrower.lastName}`)
    const chatInputString = borrowerNames.join(', ')
    if (!chatInputString) return;
    helperValue(chatInputString);
    setFormattedValue(chatInputString);
    setUnformattedValue(chatInputString);
  }, [contactContext.selectedBorrowers]);

  // If the footer morphType gets set to ShareCustomLink, the footer is wiped to 
  // display the placeholder
  useEffect( () => {
    if(errandContext.morphType === MorphType.ShareCustomLink){
      setFormattedValue('');
      setUnformattedValue('');
      helperValue('');
    }
  }, [errandContext.morphType])

  useEffect(() => {
    if (!errandContext.footerInputRef.current) return;
    const canFocus = typeof errandContext.footerInputRef.current.focus === 'function';
    const canBlur = typeof errandContext.footerInputRef.current.blur === 'function';
    if (canFocus) return errandContext.footerInputRef.current.focus();
    if (canBlur) return errandContext.footerInputRef.current.blur();
  }, [props.errand?._id]);

  // variable for all the morphtypes where we want to disable typing in input
  const disableInputMorphTypes =        
  errandContext.morphType === MorphType.ShareCustomLink ||
  errandContext.morphType === MorphType.Time ||
  errandContext.morphType === MorphType.UserPromptsMenu ||
  errandContext.morphType === MorphType.CreditRepairDisputeAccountType ||
  errandContext.morphType === MorphType.VideoListMenu || 
  errandContext.morphType === MorphType.CreditRepairWelcome ||
  errandContext.morphType === MorphType.RefinanceCalculatorWelcome;

  // variable for all the field attributes where we want to disable typing in input
  const disableFieldAttributes = ['ENVELOPE', 'CONFIRM'].includes(props.fieldAttribute?.description);

  const disableForOperator = useMemo(() => {
    if (!props.operatorData) return false;
    if (errandContext.morphType !== MorphType.PrivateChat) return false;
    const audienceString = getAudienceString('', props.errand.recipients, props.errand.participants, 'ids', true, false, props.operatorData._id, false);
    if (audienceString.endsWith(',ai')) {
      const aiOperatorIds = getAiOperatorIds(props.errand.participants);
      return !aiOperatorIds.includes(props.operatorData._id);
    }
    return false;
  }, [props.operatorData, props.errand.participants, errandContext.morphType]);

  const CustomPopper = (props) => {
    return (
      <Popper
        {...props}
        placement="top" // This makes the suggestions appear above the input element
      />
    );
  };

  const handleBlur = (event) => {
    const newFocusElement = event?.relatedTarget;
    if (newFocusElement) {
      console.info(`Focus moved to: ${newFocusElement.tagName}`);
    } else {
      console.info('Focus moved out of the page or to a non-interactive element.');
    }
  };

  return (
    <>
      <Snackbar
        open={isWarningActive ? true : false}
        onClose={() => setIsWarningActive(null)}
        message={isWarningActive}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      />
      {props.fieldAttribute?.description === 'CHARACTER LIMIT' && <div className={[
        Styles.charCountWrapper,
        ...(isDesktop ? [Styles.charDesktop] : [Styles.charMobile]),
        ...(unformattedValue.length === 0 ? [Styles.noChar] : []),
        ...(unformattedValue.length === 48 ? [Styles.reachedLimit] : [])
        ].join(' ')}>
          <div className={Styles.charCount}>
            {unformattedValue.length}/48
          </div>
      </div>}
      {props.errand?.action?.active && props.fieldAttribute && props.fieldAttribute.description === 'OTP' ? (
        <OTPInput handleSubmit={props.handleSubmit} />
      ) : props.errand?.action?.active &&
        props.fieldAttribute &&
        props.fieldAttribute.description === 'DOLLAR AMOUNT' ? (
        <NumericFormat
          autoFocus
          customInput={StyledInputBase}
          disabled={!props.participantId || props.honeyPot !== '' || disableInputMorphTypes || disableForOperator}
          disableUnderline
          ref={errandContext.footerInputRef}
          onKeyDown={handleInput}
          onValueChange={handleValueChangeWrapper}
          placeholder={disableForOperator ? t('incorrectNickname') : props.placeholder}
          prefix='$'
          thousandSeparator=','
          type='tel'
          value={value}
        />
        ): props.errand?.action?.active &&
        props.fieldAttribute &&
        props.fieldAttribute.description === 'PERCENT' ? (
          <NumericFormat
            autoFocus
            customInput={StyledInputBase}
            disabled={!props.participantId || props.honeyPot !== '' || disableInputMorphTypes || disableForOperator}
            disableUnderline
            ref={errandContext.footerInputRef}
            onKeyDown={handleInput}
            onValueChange={(values) => {
              setValue(values.value);
              handleValueChangeWrapper(values);
            }}
            placeholder={disableForOperator ? t('incorrectNickname') : props.placeholder}
            suffix={value ? '%' : ''}
            thousandSeparator={value && value.length > 2 ? ',' : false}
            // Adjust the format or use a custom formatting function if needed
            format={value && value.length > 2 ? null : '##'}
            type='text' // 'tel' for numeric keypad on mobile. we switch to text so we can allow decimals.
            value={value}
          />
      ) :props.errand?.action?.active &&
      props.fieldAttribute &&
      props.fieldAttribute.description === 'PERCENT OR DOLLAR AMOUNT' ? (
        /* ANYTHING UNDER 100 IN VALUE IS A PERCENTAGE, OTHERWISE DOLLAR AMOUNT. USED FOR DOWN PAYMENTS */
        <NumericFormat
          autoFocus
          customInput={StyledInputBase}
          disabled={!props.participantId || props.honeyPot !== '' || disableInputMorphTypes || disableForOperator}
          disableUnderline
          ref={errandContext.footerInputRef}
          onKeyDown={handleInput}
          onValueChange={(values) => {
            const numericValue = parseFloat(values.value.replace(/[^\d.]/g, ''));
            const currentIsPercent = numericValue < 100 || values.value.includes('%');
            setIsPercent(currentIsPercent); // Update state based on current input
            setValue(values.value); // Set the raw value without formatting
            handleValueChangeWrapper(values);
          }}
          placeholder={disableForOperator ? t('incorrectNickname') : props.placeholder}
          prefix={!isPercent ? '$' : ''}
          suffix={isPercent ? '%' : ''}
          thousandSeparator={!isPercent}
          format={isPercent ? '##.##' : null} // Allow decimal for percent, no format for dollar
          type='text' // 'tel' for numeric keypad on mobile. we switch to text so we can allow decimals.
          value={value}
        />
    ) : props.errand?.action?.active &&
        props.fieldAttribute &&
        props.fieldAttribute.description === 'ADDRESS' ? (
        <Autocomplete
          disableClearable
          disabled={!props.participantId || props.honeyPot !== '' || disableInputMorphTypes || disableForOperator}
          filterOptions={(x) => x}
          freeSolo
          fullWidth
          onChange={handleAutoCompletionSelectedWrapper}
          options={options}
          size='small'
          value={value as any}
          // following field make sure that box size width is gonna be min 250
          componentsProps={{
            paper: {
              sx: {
                minWidth: 250,
              },
            },
          }}
          // following field is dynamic responsive font size implementation
          renderOption={(props, options) => (
            <Typography variant='body2' {...props}>
              {options.label}
            </Typography>
          )}
          renderInput={(params) => (
            <StyledInputBase
              autoFocus
              disabled={!props.participantId || props.honeyPot !== '' || disableInputMorphTypes || disableForOperator}
              disableUnderline
              inputProps={params.inputProps}
              onChange={handleAutoCompletionValueChangeWrapper}
              placeholder={disableForOperator ? t('incorrectNickname') : props.placeholder}
              ref={params.InputProps.ref}
              sx={{ flex: 1, paddingRight: '1px' }}
            />
          )}
          PopperComponent={CustomPopper}
        />
      ) : props.errand?.action?.active && props.fieldAttribute && props.fieldAttribute?.format && props.fieldAttribute?.format?.indexOf('S') === -1 ? (
        <PatternFormat
          autoFocus
          customInput={StyledInputBase}
          disabled={!props.participantId || props.honeyPot !== '' || disableInputMorphTypes || disableForOperator}
          disableUnderline
          format={props.fieldAttribute?.format}
          ref={errandContext.footerInputRef}
          mask='_'
          onKeyDown={handleInput}
          onValueChange={handleValueChangeWrapper}
          placeholder={errandContext.morphType === MorphType.DOB || errandContext.morphType === MorphType.CalendarMonth ? 'MM/DD/YYYY' : disableForOperator ? t('incorrectNickname') : props.placeholder}
          sx={{ flex: 1 }}
          type={props.fieldAttribute?.description === 'PASSWORD' ? 'password' : 'tel'}
          value={value}
        />
      ) : (
        <Tooltip
          open={props.fieldAttribute?.description === 'CHARACTER LIMIT' && unformattedValue.length === 48}
          title={t('characterLimitReached')}
          placement='top'
          >
          <StyledInputBase
            autoComplete='off'
            autoFocus={errandContext.formBody === FormBodyType.CreateSignatureDesktop ? false : true}
            disabled={!props.participantId || props.honeyPot !== '' || disableInputMorphTypes || disableForOperator || disableFieldAttributes}
            disableUnderline
            inputProps={{
              onPasteCapture: handleFiles,
              maxLength: props.fieldAttribute?.description === 'CHARACTER LIMIT' ? 48 : undefined
            }}
            onBlur={handleBlur}
            inputRef={errandContext.footerInputRef}
            maxRows={4}
            onChange={handleChangeWrapper}
            onFocus={props.inputOnFocus}
            onClick={checkConsent}
            onKeyDown={handleInput}
            placeholder={disableForOperator ? t('incorrectNickname') : props.placeholder}
            morphType={errandContext.morphType}
            value={value}
            multiline={
              props.fieldAttribute?.description !== 'PASSWORD' &&
              props.fieldAttribute?.description !== 'EMAIL'
                ? true
                : undefined
            }
            type={
              props.fieldAttribute?.description === 'PASSWORD'
                ? props.showPassword
                  ? 'text'
                  : 'password'
                : props.fieldAttribute?.description === 'EMAIL'
                ? 'email'
                : 'search'
            }
          />
        </Tooltip>
      )}
    </>
  );
};

export default ChatInputField;
