import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grow, Box, Typography, Divider, Grid, DialogActions, Button } from "@mui/material";
import SecurityIcon from '@mui/icons-material/Security';
import { useState, useRef } from "react";
import { styles } from "./styles";

import {
    Public as GlobeIcon,
    Gavel as LawIcon,
    Flag as FlagIcon,
    Drafts as DraftIcon,
} from '@mui/icons-material';
import { Description as DescriptionIcon } from '@mui/icons-material';

const groupedPatents = {
    "US Patents": [
        "US Patent No. 11,029,808 Application No. 15/909,928",
        "US Patent No. 10,469,986 Application No. 16/112,214",
        "US Patent No. 10,992,605 Application",
        "US Patent No. 10,963,218 Application No. 16/567,870",
        "US Patent No. 11,157,167 Application No. 16/867,458"
    ],
    "Utility Applications": [
        "Utility Application No. 15/706,566",
        "Utility Application No. 16/993,148",
        "Utility Application No. 15/909,916",
        "Utility Application No. 16/015,098",
        "Utility Application No. 16/137,460",
        "Utility Application No. 16/162,296",
        "Utility Application No. 16/419,526",
        "Utility Application No. 17/242,153",
        "Utility Application No. 16/812,245"
    ],
    "PCT Applications": [
        "PCT Application No. PCT/US2018/051220",
        "PCT Application No. PCT/US2018/051204",
        "PCT Application No. PCT/US2019/017130",
        "PCT Application No. PCT/US2019/017128",
        "PCT Application No. PCT/US2019/038338",
        "PCT Application No. PCT/US2019/047567",
        "PCT Application No. PCT/US2019/050688",
        "PCT Application No. PCT/US2019/056001",
        "PCT Application No. PCT/US2020/034373",
        "PCT Application No. PCT/US2020/049891",
        "PCT Application No. PCT/US2021/017294"
    ],
    "Canada Applications": [
        "Canada Application No. 3,073,214",
        "Canada Application No. 3092724",
        "Canada Application No. 3092723",
        "Canada Application No. 3104506",
        "Canada Application No. 3110483",
        "Canada Application No. 3113630",
        "Canada Application No. 3116664"
    ],
    "European Applications": [
        "European Application No. 18857088.1",
        "European Application No. 19760019.0",
        "European Application No. 19760376.4",
        "European Application No. 19822951.0",
        "European Application No. 19851180.0",
        "European Application No. 19861720.1",
        "European Application No. 19873693.6"
    ],
    "United Kingdom Applications": [
        "United Kingdom Application No. 2003904.6",
        "United Kingdom Application No. 2015599.0",
        "United Kingdom Application No. 2015603.0",
        "United Kingdom Application No. 2019733.1",
        "United Kingdom Application No. 102033.4",
        "United Kingdom Application No. 2104170.2",
        "United Kingdom Application No. 2106880.4"
    ],
    "India Applications": [
        "India Application No. 202037003799",
        "India Application No. 202017042789",
        "India Application No. 202017042790",
        "India Application No. 202017054738",
        "India Application No. 202117005884",
        "India Application No. 202117011974",
        "India Application No. 202117017213"
    ],
    "Provisional Applications": [
        "Provisional Application No. 63/252,565",
        "Provisional Application No. 63/252,565",
        "Provisional Application No. 63/252,565",
        "Provisional Application No. 63/252,565"
    ]
};

const getIconForCategory = (category) => {
    switch (category) {
        case "US Patents":
            return <LawIcon color="primary" />;
        case "PCT Applications":
            return <GlobeIcon color="primary" />;
        case "Canada Applications":
            return <FlagIcon color="primary" style={{ color: "#FF0000" }} />; // Red for Canada
        case "European Applications":
            return <FlagIcon color="primary" style={{ color: "#003399" }} />; // Blue for Europe
        case "United Kingdom Applications":
            return <FlagIcon color="primary" style={{ color: "#00247D" }} />; // Dark Blue for UK
        case "India Applications":
            return <FlagIcon color="primary" style={{ color: "#FF9933" }} />; // Orange for India
        case "Provisional Applications":
            return <DraftIcon color="primary" />;
        default:
            return <DescriptionIcon color="primary" />;
    }
};

const patentButtonStyle = {
    zIndex: 9,
    overflow: 'hidden',
    position: 'absolute',
    bottom: '7px',
    backgroundColor: '#150932',
    color: 'white',
    fontSize: '14px',
    padding: '4px 10px',
    fontFamily: 'Poppins',
    fontWeight: '500',
    borderRadius: '8px', // Keep the rounded corners
    border: '1px solid transparent', // Border thickness
    background: 'linear-gradient(#150932, #150932) padding-box, linear-gradient(to right, #FD9131, #8E2397) border-box', // Gradient border
    display: 'flex',
    justifyContext: 'center',
    alignItems: 'center'
};

const PatentButton = (props) => {
    const [open, setOpen] = useState(false);
    const [visibleItems, setVisibleItems] = useState(Array(Object.keys(groupedPatents).length).fill(false)); // State to track visibility of items
    const tmIDs = useRef([]);

    const delay = (ms) => new Promise((resolve) => tmIDs.current.push(setTimeout(resolve, ms)));

    const revealItems = async () => {
        const categories = Object.keys(groupedPatents); // Get the list of categories
        const totalItems = categories.length; // Count the number of categories

        for (let index = 0; index < totalItems; index++) {
            await delay(300); // Wait for 300ms before revealing the next item
            setVisibleItems((prev) => {
                const updated = [...prev];
                updated[index] = true; // Reveal each category sequentially
                return updated;
            });
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
        revealItems();
    };

    const handleClose = () => {
        setOpen(false);
        // clear out any on going timeouts from prev show
        tmIDs.current.forEach((tmID) => clearTimeout(tmID));
        setVisibleItems([]);
    };

    return (
        <>
            <button style={patentButtonStyle} className={"patentProtectedButton"} onClick={handleClickOpen}>
                <SecurityIcon sx={{fontSize: '16px', marginRight: '3px'}} /> {props.text}
            </button>

            {/* Patent List Popup */}
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}>
                <DialogTitle>Patents Owned by Celligence</DialogTitle>
                <DialogContent dividers={true}>
                    {Object.keys(groupedPatents).map((category, index) => (
                        <>
                            <Grow in={visibleItems[index]} timeout={500}>
                                <Box display="flex" alignItems="center" mb={1} mt={3}>
                                    {/* Category Icon next to the title */}
                                    {getIconForCategory(category)}
                                    <Typography variant="h6" sx={{ marginLeft: 1 }}>{category}</Typography>
                                </Box>
                            </Grow>
                            <Divider />
                            <Grow in={visibleItems[index]} timeout={500}>
                                <Grid container spacing={3}>
                                    <Grid item xs={props.isMobile === false ? 8 : 12}>
                                        {/* Render each patent as a compact item */}
                                        {groupedPatents[category].map((patent, idx) => (
                                            <Box key={idx} sx={{ marginTop: 1, marginBottom: 1 }}>
                                                <Typography variant="body1">{patent}</Typography>
                                            </Box>
                                        ))}
                                    </Grid>
                                    {/* For Desktop Only */}
                                    {(props.isMobile === false)
                                        ? (
                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Typography
                                                    variant="body1"
                                                    align="right"
                                                    sx={styles.numberTextStyle}
                                                >
                                                    {groupedPatents[category].length}
                                                </Typography>
                                            </Grid>
                                        )
                                        : null}
                                </Grid>
                            </Grow>
                        </>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export { PatentButton };