/**
 * @file This component is a stylized Box component
 * for allignment. 
 * 
 * Extracted from ConversationMessage.js 10/11/2022
 * 
 * @author Harrison Fales <harrison.fales@swmc.com>
 * Original Component Author
 * @author Allan Tham <allan.tham@swmc.com>
 */

import { Box, styled } from '@mui/system';

const Allign = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export default Allign