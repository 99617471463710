import { DoubleTick, Tick } from '../../Assets/Icons';
import { Box, Fade, Stack, Tooltip, Slide, Snackbar } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ChatBubbleStyle } from '@styles/ChatBubbleStyle';
import Sanitized from '@components/Sanitized';
import MessageTextStyle from '@styles/MessageTextStyle';
import OriginalLangStyle from '@styles/OriginalLangStyle';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import TranslationTabLeft from '@styles/TranslationTabLeft';
import TranslationTabRight from '@styles/TranslationTabRight';
import { useTranslation } from 'react-i18next';
import { uiTranslationController, getLangName } from '@common/common';
import { IErrand, IMessage } from '@interfaces/Conversation';
import Allign from '@styles/Allign';
import styles from '@styles/MessageContent/CouponMessageContent.module.css';
import mcStyles from '@styles/MessageContent/MessageContent.module.css';
import MessageTime from '@components/MessageTime';
import useInitialMount from '@common/hooks/useInitialMount';

type TCouponMessageContentProps = {
  message: IMessage;
}

const CouponMessageContent = ({
  message, 
}: TCouponMessageContentProps)  => {
  const { t, i18n } = useTranslation();
  const [isFading, setIsFading] = useState(true);
  const [revealEnabled, setRevealEnabled] = useState(false);
  const messageRef = useRef(null);
  const { dispMessage, isTranslated } = uiTranslationController(message);
  const showPointer = !message.operatorView && message.message.indexOf('data-chat-id') !== -1;
  const [parsedMessage, setParsedMessage] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const [codeCopied, setCodeCopied] = useState(false);
  const [isWarningActive, setIsWarningActive] = useState(false);

  const parseMessageAndCode = useCallback((dispMessage) => {
    if (!dispMessage) {
      return;
    } else {
      let splitMessage = dispMessage.split('<>');
      setParsedMessage(splitMessage[0]);
      setCouponCode(splitMessage[1]);
    }
  }, [dispMessage]);

  const copyCodeToClipboard = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(couponCode);
    setCodeCopied(true);
    setIsWarningActive(t('copiedCodeToClipboard'))
  }

  /**
   * This function is a handler callback for the Original Language component's
   * onClick event. It is responsible for temporarily revealing the origianl
   * message.
   */
  const handleClickOriginalLang = async (e) => {
    e?.stopPropagation();
    if (!message.visible) return;
    if (revealEnabled) {
      return;
    }
    const reset = messageRef.current.innerHTML;

    // Start the inital fadeout:
    setRevealEnabled(true);
    setIsFading(false);

    // Wait until the fadeout is done to fade in the original message:
    setTimeout((reset) => {
      let editedMessage = message.message;

      let [firstSection, secondSection] = message.message.split(`<i class="messageArrow"/>`).map((x) => x.replace(/(<([^>]+)>)/gi, ''));

      if (secondSection) {
        editedMessage = `<s>${firstSection}</s>&nbsp;<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1r4vxry-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowCircleRightRoundedIcon"><path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.31-.85.09-.85-.36z"></path></svg> <i>${secondSection}</i>`;
      }

      // Style the original message:
      editedMessage = `<div style="font-size: 0.8em; font-family: Poppins; font-weight: 400;">${editedMessage}</div>`;

      messageRef.current.innerHTML = editedMessage;
      setIsFading(true);

      // Allow the revealed message to display for some time, then begin fading
      // it out:
      setTimeout((reset) => {
        setIsFading(false);

        // Finally, wait until the fadeout is done, then fade in the translated
        // message:
        setTimeout((reset) => {
          messageRef.current.innerHTML = reset;
          setIsFading(true);
          setRevealEnabled(false);
        }, 500, reset);
      }, 3000, reset);
    }, 500, reset);
  };

  useInitialMount(() => parseMessageAndCode(dispMessage));

  return (
    <Allign
      className={`
    ${styles.dialogWrapper} 
    ${isTranslated ? styles.translated : ''}
    ${message.alignByCurrentUser ?
          styles.allignEnd : styles.allignStart} `}
    >
      <Stack flexDirection="row">
      <ChatBubbleStyle
          className={`
          ${styles.couponTextBubble} 
          ${showPointer ? styles.showPointer : styles.noPointer}
          `}
        >
          <Fade in={isFading} appear={false}>
            <Stack className={styles.fadeStack} ref={messageRef}>
              <MessageTextStyle>
                <Sanitized
                  highlight={message?.searchWords ? message?.searchWords : undefined}
                  html={parsedMessage}
                />
              </MessageTextStyle>
            </Stack>
          </Fade>
          <Stack className={styles.timeStack}>
            <MessageTime message={message} />
          </Stack>
        </ChatBubbleStyle>
        <Snackbar
          ContentProps={{
            classes: {
              root: isWarningActive ? styles.customSnackbar : ''
            }
          }}
          open={isWarningActive ? true : false}
          onClose={() => setIsWarningActive(false)}
          message={isWarningActive}
          autoHideDuration={1000}
          TransitionComponent={(props) => { return <Slide {...props} direction='up' /> }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        />
        <Tooltip
          title={t('copyCodeToClipboard')}
          placement='top'
          enterDelay={1000}
        >
          <ChatBubbleStyle
            className={`${mcStyles.peAll} ${styles.couponCodeBubble} 
            ${isTranslated ? styles.translated : ''}
            ${codeCopied ? styles.codeCopied : ''}
            `}
            onClick={copyCodeToClipboard}
          >
            <Box>
              <ContentCopyIcon />
              <span>{couponCode}</span>
              <span className={styles.clickToCopyText}>{codeCopied ? 'Copied!' : 'Click to Copy'}</span>
            </Box>
          </ChatBubbleStyle>
        </Tooltip>
      </Stack>
      {
        isTranslated &&
        (message.alignByCurrentUser ? (
          <TranslationTabRight>
            <OriginalLangStyle onClick={handleClickOriginalLang}>
              <u>{getLangName(message.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
          </TranslationTabRight>
        ) : (
          <TranslationTabLeft>
            <OriginalLangStyle onClick={handleClickOriginalLang}>
              <u>{getLangName(message.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
          </TranslationTabLeft>
        ))
      }
    </Allign >
  );
};

export default CouponMessageContent;
